import React, { useState, useEffect } from 'react';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Collapse from '@material-ui/core/Collapse';
import ReactPlayer from 'react-player';
import { getAllActiveExercises } from '../../services/ExerciseService';
import { useTranslation } from 'react-i18next';

const Exercises = () => {
  const { t } = useTranslation();

  const [exercises, setExercises] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchAllExercises();
  }, []);

  const fetchAllExercises = async () => {
    setIsLoading(true);
    const { success, exercises, error } = await getAllActiveExercises();
    setIsLoading(false);

    if (success) {
      setExercises(exercises.map((exercise) => ({ ...exercise, collapseStatus: false })));
    } else {
      console.log(error.message);
    }
  };

  const toggleCollapse = (index) => {
    setExercises((current) => {
      const aux = [...current];
      aux[index].collapseStatus = !aux[index].collapseStatus;
      return aux;
    });
  };

  const isVimeoURL = (url) => {
    const urlParsed = new URL(url);

    return urlParsed.hostname.includes('vimeo');
  };

  const parseVimeoURL = (url) => {
    const urlParsed = new URL(url);

    if (!urlParsed.hostname.startsWith('payer.')) {
      urlParsed.hostname = 'player.' + urlParsed.hostname;
    }

    if (!urlParsed.pathname.startsWith('/video')) {
      urlParsed.pathname = '/video' + urlParsed.pathname;
    }

    urlParsed.search = '';

    return urlParsed.href;
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('ExercisesPage.PageTitle')}</h2>
                    <p className='card_subtitle'>{t('ExercisesPage.PageSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    {isLoading && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!isLoading && exercises.length === 0 && (
                      <div className='card_details'>
                        <p style={{ textAlign: 'center', margin: 100 }}>{t('ExercisesPage.NoExercisesAvailable')}</p>
                      </div>
                    )}
                    {!isLoading &&
                      exercises.map((exercise, index) => (
                        <div className='card_list_turma' key={'exercise_' + exercise.name + exercise.id}>
                          <div className='content_card' style={{ display: 'flex', flexDirection: 'column' }}>
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                              }}
                              onClick={() => toggleCollapse(index)}
                            >
                              <span
                                className='card_details_label text_uppercase'
                                style={{ fontSize: '14px', color: '#3b3b3b' }}
                              >
                                {exercise.name}
                              </span>
                              {exercise.collapseStatus ? (
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='16'
                                  height='16'
                                  fill='currentColor'
                                  className='bi bi-caret-up-fill'
                                  viewBox='0 0 16 16'
                                >
                                  <path d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z' />
                                </svg>
                              ) : (
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='16'
                                  height='16'
                                  fill='currentColor'
                                  className='bi bi-caret-down-fill'
                                  viewBox='0 0 16 16'
                                >
                                  <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
                                </svg>
                              )}
                            </div>
                            <Collapse in={exercise.collapseStatus} style={{ width: '100%' }}>
                              <div style={{ width: '100%', marginTop: '20px' }}>
                                <div
                                  style={{
                                    width: exercise.description ? '70%' : '100%',
                                    padding: '5px 25px 25px 25px',
                                    height: exercise.description ? '500px' : undefined,
                                    minHeight: exercise.description ? undefined : '500px',
                                    display: 'inline-block',
                                    float: 'left',
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      minHeight: 'inherit',
                                      color: 'white',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      position: 'relative',
                                    }}
                                  >
                                    {exercise.type === 'IMAGE' ? (
                                      <img
                                        src={exercise.urlPath}
                                        alt={exercise.description}
                                        style={{ maxHeight: '100%', maxWidth: '100%' }}
                                      />
                                    ) : exercise.type === 'VIDEO' && isVimeoURL(exercise.urlPath) ? (
                                      <>
                                        <iframe
                                          src={parseVimeoURL(exercise.urlPath)}
                                          allow='autoplay; fullscreen; picture-in-picture'
                                          allowFullScreen
                                          style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                          }}
                                        />
                                        <script src='https://player.vimeo.com/api/player.js'></script>
                                      </>
                                    ) : (
                                      <ReactPlayer
                                        controls={true}
                                        width='100%'
                                        height='100%'
                                        url={exercise.urlPath}
                                        style={{ position: 'absolute', top: 0, left: 0 }}
                                      />
                                    )}
                                  </div>
                                </div>
                                {exercise.description}
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Exercises;
