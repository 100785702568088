import React, { useState, useEffect, useRef } from 'react';
import FooterClub from '../../components/club/Footer';
import FooterSchool from '../../components/school/Footer';
import Api from '../../utils/api';
import axios from 'axios';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import utils from '../../utils/utils';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri';

const ViewControl = () => {
  const listCSS = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    background: '#ffffff',
    boxShadow: '0px 5px 15px rgb(0 0 0 / 15%)',
    borderRadius: '0px 0px 10px 10px',
  };
  const headerCSS = {
    display: 'flex',
    width: '100%',
    boxShadow: '0px 0px 10px rgb(0 0 0 / 15%)',
    borderRadius: '10px 10px 0px 0px',
    borderBottom: 0,
    color: '#000',
  };
  const headerLabelCSS = {
    margin: 0,
    boxSizing: 'border-box',
    width: '-webkit-fill-available',
    padding: '15px',
    fontSize: '16px',
    borderRight: '1px solid #e5e5e5',
  };
  const headerActionCSS = {
    margin: 0,
    boxSizing: 'border-box',
    width: '220px',
    padding: '15px',
    fontSize: '16px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
  };
  const rowCSS = {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #e5e5e5',
    color: '#747272',
    justifyContent: 'start',
    alignItems: 'center',
  };
  const columnCSS = {
    display: 'flex',
    width: '100%',
    color: '#747272',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'column',
  };
  const rowLabelCSS = {
    color: '#747272',
    boxSizing: 'border-box',
    background: 'none',
    width: '-webkit-fill-available',
    padding: '15px',
    borderRight: '1px solid #e5e5e5',
    display: 'flex',
    alignItems: 'center',
  };
  const subRowLabelCSS = {
    color: '#747272',
    boxSizing: 'border-box',
    background: 'none',
    width: '-webkit-fill-available',
    padding: '15px',
    marginLeft: '55px',
    display: 'flex',
    alignItems: 'center',
  };
  const rowActionCSS = {
    color: '#747272',
    boxSizing: 'border-box',
    background: 'none',
    width: '220px',
    padding: '15px',
    borderRight: '1px solid #e5e5e5',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  };
  const subRowActionCSS = {
    color: '#747272',
    boxSizing: 'border-box',
    background: 'none',
    width: '220px',
    padding: '10px',
    borderRight: '1px solid #e5e5e5',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  };

  const { t } = useTranslation();
  const baseURL = Api();
  const { scrollTop } = utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();

  const messageScroll = useRef(window);

  const [schoolID, setSchoolID] = useState('');
  const [companieID, setCompanieID] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [permission, setPermission] = useState([]);
  const [permissionIds, setPermissionIds] = useState([]);
  const [roleId, setRoleId] = useState('');
  const [permissionRoles, setPermissionRoles] = useState([]);

  const [showSubMenu, setShowSubMenu] = useState([]);
  const [blokedMenu, setBlokedMenu] = useState(['Home', 'Controle de Acesso', 'Configurações']);

  useEffect(() => {
    (async function getSession() {
      const companySession = JSON.parse(await localStorage.getItem('company'));
      let schoolSession = await localStorage.getItem('school');

      if (schoolSession !== 'null') {
        schoolSession = JSON.parse(schoolSession);

        setSchoolID(schoolSession.id);
        setCompanieID(schoolSession.companie_id);
        getPermission(null, schoolSession.id);
      } else {
        setCompanieID(companySession.id);
        getPermission(companySession.id, null);
      }
    })();
  }, []);

  const getPermission = async (companieId, schoolId) => {
    setIsLoading(true);

    const endpoint = schoolId !== null ? 'get-permission-school' : 'get-permission-club';
    const id = schoolId !== null ? schoolId : companieId;
    const { data } = await axios.post(baseURL + endpoint, { id });

    setPermission(data.permission.map((o) => ({ ...o, id: `${o.id}` })));
    setPermissionRoles(
      data.permissionRole.map((o) => ({
        ...o,
        companie_id: `${o.companie_id}`,
        id: `${o.id}`,
        permission_id: `${o.permission_id}`,
        role_id: `${o.role_id}`,
        school_id: `${o.school_id}`,
      }))
    );
    setIsLoading(false);
  };

  const toggleParentAndChildren = (item, value) => {
    if (
     /*  (item.title === 'Home' && schoolID == '') || */
      (item.title === 'Controle de Acesso' && roleId === '6') ||
      (item.title === 'Configurações' && roleId === '6')
    )
      return;
    let newPermissionIds = [...permissionIds];
    if (value) {
      newPermissionIds = newPermissionIds.filter((id) => id !== item.id);
    } else {
      newPermissionIds.push(item.id);
    }

    if (item.children && item.children.length > 0) {
      const childIds = item.children.map((child) => child.id.toString());
      if (value) {
        newPermissionIds = newPermissionIds.filter((id) => !childIds.includes(id.toString()));
      } else {
        newPermissionIds = [...newPermissionIds, ...childIds];
      }
    }
    setPermissionIds(newPermissionIds);
  };

  const toggleChildren = (item, value) => {
    let newPermissionIds = [...permissionIds];
    if (item.father_id !== null) {
      if (value) {
        newPermissionIds = newPermissionIds.filter((id) => id != item.id);
      } else {
        newPermissionIds = [...newPermissionIds, item.id.toString()];
      }
    }
    setPermissionIds(newPermissionIds);
  };

  const fillPermissionIds = (e) => {
    if (e.target.checked) {
      const allPermissionIds = permission.flatMap((item) => {
        if (item.children && item.children.length > 0) {
          return [item.id, ...item.children.map((child) => child.id.toString())];
        }
        return item.id;
      });
      setPermissionIds(allPermissionIds);
    } else {
      if (roleId != '6') {
        setPermissionIds(permission.filter((item) => item.title === 'Home').map((item) => item.id.toString()));
        return;
      }
      const blokedMenuIds = permission
        .filter((item) => blokedMenu.includes(item.title))
        .map((item) => item.id.toString());
      const blokedChildrenIds = permission.flatMap((item) => {
        if (item.children && item.children.length > 0) {
          return item.children.filter((child) => blokedMenu.includes(child.title)).map((child) => child.id.toString());
        }
        return [];
      });
      blokedMenuIds.push(...blokedChildrenIds);
      setPermissionIds(blokedMenuIds);
    }
  };

  const loadPermissionIds = (e) => {
    setRoleId(e.target.value);
    setPermissionIds(permissionRoles.filter((p) => p.role_id === e.target.value).map((p) => p.permission_id));
  };

  const onPressKey = (e) => {
    e.preventDefault();
    if (e.key === 'Enter') handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      roleId: roleId,
      schoolId: schoolID !== '' ? schoolID : '',
      companieId: schoolID !== '' ? '' : companieID,
      permissions: permissionIds,
    };

    const endpoint = schoolID !== '' ? 'set-permission-school' : 'set-permission-club';

    setIsSending(true);
    const { data } = await axios.post(baseURL + endpoint, payload);
    setIsSending(false);

    if (data.success) {
      showSuccessMessage('SystemMessage.Success.SavedSuccessfully');
      setPermissionRoles(
        data.permissionRole.map((o) => ({
          ...o,
          companie_id: `${o.companie_id}`,
          id: `${o.id}`,
          permission_id: `${o.permission_id}`,
          role_id: `${o.role_id}`,
          school_id: `${o.school_id}`,
        }))
      );
    } else {
      showErrorMessage('SystemMessage.Fail.InternalError');
    }
  };

  const showSuccessMessage = (message) => {
    setMessage(message);
    setSuccessMessage(true);
    scrollTop(messageScroll);
    setTimeout(() => setSuccessMessage(false), 5000);
  };

  const showErrorMessage = (message) => {
    setMessage(message);
    setErrorMessage(true);
    scrollTop(messageScroll);
    setTimeout(() => setErrorMessage(false), 5000);
  };

  const toggleSubMenu = (id) => {
    if (showSubMenu.includes(id)) {
      setShowSubMenu(showSubMenu.filter((item) => item !== id));
    } else {
      setShowSubMenu([...showSubMenu, id]);
    }
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('ViewControlPage.PageTitle')}</h2>
                    <p className='card_subtitle'>{t('ViewControlPage.PageSubtitle')}</p>
                  </div>
                  <div ref={messageScroll} style={{ width: '100%' }}>
                    <Collapse in={successMessage}>
                      <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message)} />
                    </Collapse>

                    <Collapse in={errorMessage}>
                      <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                    </Collapse>
                  </div>
                  <div className='list_card'>
                    <form
                      action=''
                      onSubmit={handleSubmit}
                      method='post'
                      className='form_modal'
                      onKeyPress={onPressKey}
                    >
                      <select
                        className='form_control select'
                        style={{
                          width: '200px',
                          border: '1px solid #f4f0f0',
                          borderRadius: '5px',
                          marginTop: '5px',
                          marginBottom: '15px',
                        }}
                        value={roleId}
                        onChange={loadPermissionIds}
                      >
                        <option value='' disabled>
                          {t('Function')}
                        </option>

                        {schoolID !== '' ? (
                          <>
                            <option value='6'>{t('EmployeeModel.Occupations.Manager')}</option>
                            <option value='7'>{t('EmployeeModel.Occupations.Teacher')}</option>
                            <option value='8'>{t('EmployeeModel.Occupations.Secretary')}</option>
                            <option value='9'>{t('EmployeeModel.Occupations.Coordinator')}</option>
                            <option value='10'>{t('EmployeeModel.Occupations.Intern')}</option>
                            <option value='20'>{t('EmployeeModel.Occupations.Physiotherapist')}</option>
                            <option value='21'>{t('EmployeeModel.Occupations.MassageTherapist')}</option>
                            <option value='11'>{t('EmployeeModel.Occupations.Other')}</option>
                          </>
                        ) : (
                          <>
                            <option value='3'>{t('EmployeeModel.Occupations.Director')}</option>
                            <option value='12'>{t('EmployeeModel.Occupations.Manager')}</option>
                            <option value='13'>{t('EmployeeModel.Occupations.Teacher')}</option>
                            <option value='14'>{t('EmployeeModel.Occupations.Secretary')}</option>
                            <option value='15'>{t('EmployeeModel.Occupations.Coordinator')}</option>
                            <option value='16'>{t('EmployeeModel.Occupations.Intern')}</option>
                            <option value='18'>{t('EmployeeModel.Occupations.Physiotherapist')}</option>
                            <option value='19'>{t('EmployeeModel.Occupations.MassageTherapist')}</option>
                            <option value='17'>{t('EmployeeModel.Occupations.Other')}</option>
                          </>
                        )}
                      </select>
                      <div style={listCSS}>
                        <div style={headerCSS}>
                          <span style={headerLabelCSS}>{t('Module')}</span>
                          <div style={headerActionCSS}>
                            <input
                              type='checkbox'
                              checked={
                                permissionIds.length ===
                                permission.length + permission.flatMap((item) => item.children).length
                              }
                              onChange={fillPermissionIds}
                              disabled={roleId === ''}
                            />
                            <span style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>
                              {t('MarkAll_question')}
                            </span>
                          </div>
                        </div>
                        {isLoading && (
                          <p className='loading-card' style={{ margin: '5px' }}>
                            <Loading type='1' />
                          </p>
                        )}
                        {!isLoading &&
                          permission.map((item, i) => (
                            <div key={i} style={columnCSS}>
                              <div style={rowCSS}>
                                <span style={rowLabelCSS} onClick={() => toggleSubMenu(item.id)}>
                                  {t('SystemMenu.' + item.title)}
                                  {(item.children && item.children.length) > 0 && (
                                    <span style={{ fontSize: '15px', paddingLeft: '5px' }}>
                                      {showSubMenu.includes(item.id) ? <RiArrowDownSLine /> : <RiArrowRightSLine />}
                                    </span>
                                  )}
                                </span>

                                <div style={rowActionCSS}>
                                  <input
                                    type='checkbox'
                                    checked={permissionIds.includes(item.id)}
                                    onChange={() => toggleParentAndChildren(item, permissionIds.includes(item.id))}
                                    defaultValue={item.id}
                                    disabled={
                                      roleId === '' ||
                                      (['Controle de Acesso'].includes(item.title) && roleId === '6')
                                     /*  (['Home'].includes(item.title) && schoolID == '') */
                                    }
                                  />
                                  <span
                                    className='lowercase'
                                    style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}
                                  >
                                    {t('AllowAccess')}
                                  </span>
                                </div>
                              </div>
                              {showSubMenu.includes(item.id) && item.children && item.children.length > 0 && (
                                <ul style={{ width: '100%' }}>
                                  {item.children.map((itemChild, indexChild) => (
                                    <li key={indexChild} style={{ ...rowCSS, width: '100%' }}>
                                      <span style={subRowLabelCSS}>{t('SystemMenu.' + itemChild.title)}</span>
                                      <div style={subRowActionCSS}>
                                        <input
                                          type='checkbox'
                                          checked={permissionIds.includes(itemChild.id.toString())}
                                          onChange={() =>
                                            toggleChildren(itemChild, permissionIds.includes(itemChild.id.toString()))
                                          }
                                          defaultValue={itemChild.id}
                                          disabled={
                                            roleId === '' ||
                                            (['Controle de Acesso'].includes(itemChild.title) && roleId === '6') ||
                                            ['Home'].includes(itemChild.title)
                                          }
                                        />
                                        <span className='lowercase' style={{ marginLeft: 5, fontSize: '0.80rem' }}>
                                          {t('AllowAccess')}
                                        </span>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          ))}
                      </div>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        {!isSending && (
                          <button
                            className={`btn_card mx-auto ${roleId === '' ? 'disabled' : ''}`}
                            disabled={roleId === '' || isSending}
                          >
                            {t('Save')}
                          </button>
                        )}
                        {isSending && (
                          <button className='btn_card mx-auto disabled' disabled>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            {t('SystemMessage.Loading.Processing')}
                            <Loading type='1' style={{ marginLeft: '2%' }} />
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>

            {schoolID !== '' ? <FooterSchool /> : <FooterClub />}
          </div>
        </div>
      </div>
    </main>
  );
};

export default ViewControl;
