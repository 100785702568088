import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useLocation, useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { getPositions } from '../../../../services/SharedService';
import ButtonRounded from '../../../../components/buttons/ButtonRounded';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Footer from '../../../../components/admin/Footer';
import '../../../../utils/extendsType';
import { storeCategoriesSport } from '../../../../services/AdminService';

//Global handler functions --------------------------------------------------------------------------

const createFilterOptions = (options, params) => {
  const { inputValue } = params;

  const filteredOptions = options.filter((option) => {
    const { id, label } = option;
    const lowerSearchText = inputValue.toLowerCase();

    return label.toLowerCase().includes(lowerSearchText) || id.toString().includes(lowerSearchText);
  });

  return filteredOptions;
};

const FormSelect = ({ children }) => {
  //hooks states --------------------------------------------------------------------------------------
  const history = useHistory();

  const location = useLocation();

  const alertRef = React.useRef();

  const [isDone, setIsDone] = React.useState(false);

  //add border no sweet alerta
  React.useEffect(() => {
    //garanto que o elemento existe
    if (alertRef.current !== undefined && isDone) {
      // alertRef.current.style.cssText += 'border-top: solid 10px var(--main-color)';
      document.querySelector('div.sweet-alert ').style.cssText += 'border-top: 10px solid var(--main-color)';
    }
  }, [alertRef, isDone]);

  const handlerFinally = () => {
    history.push('/sports-categories');
    setIsDone(false);
  };

  return (
    <main>
      <SweetAlert
        ref={alertRef}
        show={isDone}
        success
        title='Pronto!'
        onConfirm={handlerFinally}
        customButtons={
          <ButtonRounded isBig={true} onClick={handlerFinally} className='w-100 m-4'>
            Pronto
          </ButtonRounded>
        }
      >
        <p>Sucesso! Esportes foram vinculados as Escolas selecionadas. </p>
      </SweetAlert>

      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row d-flex align-items-center justify-content-center pt-1 pb-5'>
            <img src='/img/clubes/fensor.svg' className='logo' alt='' />
          </div>
          <div className='row content_painel'>
            <FormSportCategories
              {...{
                handlerFinally,
                isDone,
                alertRef,
                setIsDone,
                location,
              }}
            />
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

//material ui custom styles --------------------------------------------------------------------------------
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: '#f4f0f0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
  },
});

export const FormSportCategories = ({ location, handlerFinally, alertRef, isDone, setIsDone, title = null }) => {
  const { t } = useTranslation();

  const classes = useStyles();

  //!gambiarra pra resetar o Autocomplete
  const [autokey, setAutoKey] = React.useState(moment().toISOString());
  const [sendSave, setSendSave] = React.useState(false);
  const [sports, setSports] = React.useState([]);
  const [selectedSports, setSelectedSports] = React.useState([]);
  //constructor -------------------------------------------------------------------------------------------
  React.useEffect(() => {
    (async () => {
      const { sports } = await getPositions();
      sports.unshift({ label: 'TODOS', id: '' });
      setSports(sports);
    })();
  }, []);

  React.useEffect(() => {
    const elmain = document.querySelector('main');

    if (alertRef.current !== undefined && elmain) {
      elmain.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }
  }, [alertRef]);

  React.useEffect(() => {
    if (location.state.isEdit) {
      setSelectedSports(location.state.sports || []);
    }
  }, [location]);

  //handler functions --------------------------------------------------------------------------------------
  const removeFromList = React.useCallback((dt) => {
    setSelectedSports(selectedSports.filter((d) => d.id !== dt));
  });

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    setSendSave(true);

    const form = new FormData();
    form.append('school_ids', location.state.school_ids.join(','));
    form.append('sports_ids', selectedSports.map(({ id }) => id).join(','));

    try {
      const result = await storeCategoriesSport(form);
      if (result.status === 'SUCCESS') {
        setIsDone(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSendSave(false);
    }
  };
  //Columns --------------------------------------------------------------------------------------------------
  const columns = [
    {
      id: 'label',
      label: t('SportsTypesPage.Columns.sports'),
      minWidth: 320,
      align: 'left',
    },
    {
      id: 'id',
      align: 'center',
      label: t('SportsTypesPage.Columns.remove'),
      minWidth: 100,
      format: (id) => (
        <BtnDelete
          onClick={(e) => {
            e.preventDefault();
            removeFromList(id);
          }}
        />
      ),
    },
  ];
  return (
    <main className='content_panel'>
      {isDone === false && (
        <div className='card p-0 flex-column'>
          <div className='card_header w-100 flex-column align-items-center pt-3'>
            <h2 className='card_title'>{title || 'Adicionar Esportes as escolas selecionadas'}</h2>
            <BtnBack
              onClick={handlerFinally}
              style={{
                float: 'right',
                position: 'absolute',
                left: 35,
                marginTop: 5,
              }}
            />
          </div>
          <hr />
          <div className='row mb-2 mx-3'>
            <div className='col'>
              <label className='card_details_label'>{title || 'Adicionar Esportes as escolas selecionadas'}</label>

              <Autocomplete
                key={autokey}
                classes={classes}
                options={sports.filter((p) => !selectedSports.find((v) => v.id === p.id))}
                getOptionLabel={(option) => option.label}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                filterOptions={createFilterOptions}
                onChange={(event, sp, detail) => {
                  if (detail === 'select-option') {
                    if (sp.id === '') {
                      setSelectedSports(sports.filter((v) => +v.id > 0));
                    } else {
                      setSelectedSports((prevState) => [...prevState, sp].unique());
                    }
                    setAutoKey(moment().toISOString());
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('SportsTypesPage.textField')} variant='outlined' />
                )}
              />
            </div>
          </div>

          <TableList rows={selectedSports} columns={columns} />
          <hr />
          <div className='row w-100 justify-content-center  my-3'>
            <div className='col-md-5 col-sm-10'>
              <ButtonRounded
                className='w-100'
                variant='outline'
                color='var(--main-color)'
                isBig={true}
                onClick={handlerFinally}
              >
                {t('Back')}
              </ButtonRounded>
            </div>
            <div className='col-md-5 col-sm-10'>
              <ButtonRounded
                isDisabled={selectedSports.length === 0}
                isLoading={sendSave}
                onClick={handleOnSubmit}
                className='w-100'
                isBig={true}
              >
                {t('Save')}
              </ButtonRounded>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};
//local Components --------------------------------------------------------------------------------------------------
//material ui custom styles --------------------------------------------------------------------------------
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'var(--main-color)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

//private components  --------------------------------------------------------------------------------------
const BtnDelete = ({ fcolor = '#FF2929', w = 16, h = 16, onClick }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer' }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z'
        fill={fcolor}
      />
    </svg>
  </span>
);

const BtnBack = ({ fcolor = '#1C1B1F', w = 16, h = 16, onClick, style }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer', ...style }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z' fill={fcolor} />
    </svg>
  </span>
);

const TableList = ({ columns, rows, mxh = 380 }) => {
  return (
    <TableContainer style={{ maxHeight: mxh, minHeight: mxh * 0.8 }}>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow hover tabIndex={-1} key={row.id}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {typeof column.format === 'function' ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FormSelect;
