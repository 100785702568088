import axios from 'axios'
import Api from '../utils/api'
const baseURL = Api()

export const getAllCourses = async () => {
  try {
    const { data } = await axios.get(baseURL + 'get-all-course-admin')
    return data
  } catch (error) {
    return { success: false, error: error }
  }
}

export const createCourse = async (course) => {
  try {
    const { data } = await axios.post(baseURL + 'create-course-admin', serializeCourseToFormData(course))
    return data
  } catch (error) {
    return { success: false, error: error }
  }
}

export const updateCourse = async (course) => {
  try {
    const { data } = await axios.post(baseURL + 'update-course-admin', serializeCourseToFormData(course))
    return data
  } catch (error) {
    return { success: false, error: error }
  }
}

const serializeCourseToFormData = (course) => {
  const courseFormData = new FormData()
  const { newFile, ...object } = course
  courseFormData.append('object', JSON.stringify(object))

  if (newFile)
    courseFormData.append('newFile', newFile)

  return courseFormData
}

export const getAllActiveCourses = async () => {
  try {
    const { data } = await axios.get(baseURL + 'get-all-active-courses-school')
    return data
  } catch (error) {
    return { success: false, error: error }
  }
}
