import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { handleOnChange } from '../../../src/utils/utils';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Footer from '../../components/club/Footer';
import { Success } from '../../components/svgs/Success';
import { Link, useHistory } from 'react-router-dom';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { fetchAllCategories } from '../../services/CategoriesService';
import { createLessonClub, getAllActiveLessons, getAllLessons, getAllLessonsClub, updateLessonClub, updatePlanningStatusClub } from '../../services/LessonPlanningService';
import ReactTooltip from 'react-tooltip'
import { useReactToPrint } from 'react-to-print';
import { fetchLessonSession } from '../../services/LessonSession';
import { BsChevronDown, BsDownload, BsEye, BsFillPencilFill } from 'react-icons/bs';
import { UserContext } from '../../Context/UserContext';

function Planning() {
  const history = useHistory();
  const { action, setContextData } = useContext(UserContext);
  const inputRef = useRef(null)
  const planningPdfRef = useRef(null)

  //filtros
  const [filters, setFilters] = useState({
    name: '',
    lesson: '',
    source: 'A',
    status: 'A',
  });
  // dados
  const [planning, setPlanning] = useState({
    id: undefined,
    name: '',
    status: 'A',
    classNumber: '',
    objective: '',
    category_id: 0
  });
  const [plannings, setPlannings] = useState([])
  const [planningsFiltered, setPlanningsFiltered] = useState([]);
  const [dropDownStatus, setDropDownStatus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([])
  const [lessons, setLessons] = useState([]);
  const [lesson, setLesson] = useState([])
  const [sessions, setSessions] = useState([]);
  const [lessonsFiltered, setLessonsFiltered] = useState([]);
  const [lessonsSelected, setLessonsSelected] = useState([]);
  const [lessonsLibrary, setLessonsLibrary] = useState([]);
  const [showLessonsFiltered, setShowLessonsFiltered] = useState(false)

  //interface
  const [display, setDisplay] = useState(false);
  const [displayView, setDisplayView] = useState(false);

  // process
  const [isLoading, setIsLoading] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [session, setSession] = useState('information');

  const ref = useOutsideClick(() => {
    setDropDownStatus(dropDownStatus?.map((value) => ({ ...value, status: false })));
  });

  const inputLessonRef = useOutsideClick(() => {
    setShowLessonsFiltered(false);
  });

  // libraries
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();

  useEffect(() => {
    if (action?.action === 'search') {
      setFilters({
        name: action.term,
        status: action.status
      });
      fetchAllLessonsClub().then((response) => {
        setPlanningsFiltered(
          response?.filter((planning) => action?.status === 'T' || planning.status === action?.status)
            .filter((planning) => planning.name?.toUpperCase()?.includes(action?.term?.toUpperCase())));
      }).catch((e) => console.log(e));
    } else {
      fetchAllLessonsClub();
    }
    action?.action === 'openAdd' && openAdd();
    setContextData({ action: {} });
    getAllCategories();
    getLessonSession();
    fetchAllLessons();

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeAdd();
    }
  };

  const onChange = handleOnChange(setPlanning);

  const onChangeFilters = handleOnChange(setFilters);

  const openAdd = () => {
    setPlanning({
      id: undefined,
      name: '',
      status: 'A',
      classNumber: '',
      objective: ''
    });
    clearMessage();
    setErrors({})
    setLessonsSelected([])
    setCategoriesSelected([])
    setSession('information')
    setDisplay(true);
  };

  const configValues = (value) => {
    setPlanning({
      id: value.id,
      name: value.name,
      status: value.status,
      objective: value.objective,
      classNumber: value.classNumber,
      category_id: value?.categories[0]?.id,
      lessons: value?.lessons
    });
    setCategoriesSelected(value?.categories);
    setLessonsSelected(value?.lessons);
  }

  const edit = (value) => {
    configValues(value);
    clearMessage();
    setSession('information')
    setDisplay(true);
  };

  const view = (value) => {
    configValues(value);
    clearMessage();
    setSession('information')
    setDisplayView(true);
  };

  const toPdf = (value) => {
    Promise.all([
      setPlanning({
        id: value.id,
        name: value.name,
        status: value.status,
        objective: value.objective,
        classNumber: value.classNumber,
      }),
      setLesson(value?.lessons?.filter((value) => value?.id)?.map((lesson) => {
        const find = lessonsLibrary?.find((l) => l.id === lesson.id);
        const lessonSessionLabel = sessions?.find((session) => session.id === lesson.lesson_session_id)?.name
        if (find) {
          return {
            ...find,
            lessonSessionLabel,
          }
        }
      })),
      setCategoriesSelected(value?.categories)
    ]).then(() => {
      handlePrint();
    })
  }

  const handlePrint = useReactToPrint({
    content: () => planningPdfRef.current
  })

  const closeAdd = () => {
    setDisplay(false);
    clearMessage();
  };

  const closeView = () => {
    setDisplayView(false);
  };

  const updateByStatus = async (values) => {
    const data = {
      id: values.id,
      status: values.status,
    }

    const {
      status,
      plannings,
      lessons,
      message,
    } = await updatePlanningStatusClub(data);

    if (status === 'SUCCESS') {
      setPlannings(plannings);
      setPlanningsFiltered(plannings);
      setLessonsLibrary(lessons);
      setDropDownStatus(plannings?.map((planning) => ({ id: planning.id, status: false })));
    } else {
      setErrorMessage(message);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();

    const formData = {
      id: planning.id,
      name: planning.name,
      status: planning.status,
      objective: planning.objective,
      classNumber: planning.classNumber,
      lessons: lessonsSelected?.map((lesson) => lesson?.id),
      categories: categoriesSelected?.map((category) => category?.id),
    };

    setIsLoading(true);
    const {
      status,
      plannings,
      lessons,
      message,
    } = planning.id === undefined ? await createLessonClub(formData) : await updateLessonClub(formData);
    setIsLoading(false);

    if (status === 'SUCCESS') {
      setPlannings(plannings);
      setPlanningsFiltered(plannings);
      setLessonsLibrary(lessons);
      setDropDownStatus(plannings?.map((planning) => ({ id: planning.id, status: false })));
      setSuccess(true);
    } else {
      setErrorMessage(message);
    }
  };

  const setErrorMessage = (message) => {
    setMessage(message);
    setError(true);
  };

  const clearMessage = () => {
    setSuccess(false);
    setError(false);
    setMessage('');
  };

  const getAllCategories = async () => {
    const response = await fetchAllCategories();
    setCategories(response?.categories);
  }

  const getLessonSession = async () => {
    const response = await fetchLessonSession();
    setSessions(response.sessions)
  }

  const fetchAllLessonsClub = async () => {
    setIsLoading(true);
    const { status, plannings, lessons, message } = await getAllLessonsClub();
    setIsLoading(false);
    if (status === 'SUCCESS') {
      setPlannings(plannings);
      setPlanningsFiltered(plannings);
      setLessonsLibrary(lessons);
      setDropDownStatus(plannings?.map((planning) => ({ id: planning.id, status: false })));
      return plannings;
    } else {
      console.log(message);
    }
  };

  const search = (e) => {
    e && e.preventDefault();

    if (filters.source === 'T' || filters.source === 'M') {
      setContextData({
        action: {
          action: 'search',
          term: filters.name,
          status: filters.status
        }
      })
      filters.source === 'T' ? history.push('/club-lesson', { replace: true }) : history.push('/club-methodology', { replace: true });
      return;
    }

    setPlanningsFiltered(
      plannings.filter((planning) => planning.status === 'T' || planning.status === filters.status)
        .filter((planning) => planning.name?.toUpperCase()?.includes(filters.name.toUpperCase()))
    );
  };

  const fetchAllLessons = async () => {
    const { status, lessons, message } = await getAllActiveLessons();
    if (status === 'SUCCESS') {
      setLessons(lessons);
      setLessonsFiltered(lessons);
    } else {
      console.log(message);
    }
  };

  const addCategories = useCallback((e) => {
    const id = Number(e.target.value)
    const name = e.target.selectedOptions[0].label
    if (id && !categoriesSelected?.find((value) => value.id === id)) setCategoriesSelected((old) => [...old, { id, name }])
  }, [categoriesSelected])

  const removeCategories = useCallback((value) => {
    const categories = categoriesSelected.filter((category) => category.id !== value.id)
    if (categories.length === 0) setPlanning((old) => { return { ...old, category_id: 0 } });
    setCategoriesSelected(categories)
  }, [categoriesSelected])

  const handleSelectLessons = (lesson) => {
    const find = lessonsSelected?.find((value) => value.id === lesson.id);
    !find && setLessonsSelected(old => [...old, lesson])
    setFilters({ lesson: '' })
  }

  const validateInformation = (e) => {
    e.preventDefault()
    const validate = {}
    if (!planning?.name) validate['name'] = 'Nome da aula é obrigatório';
    if (!planning?.objective) validate['objective'] = 'Objetivo é obrigatório';
    if (!planning?.classNumber) validate['classNumber'] = 'Número da aula é obrigatório';
    if (!planning?.status) validate['status'] = 'Status é obrigatório';
    if (categoriesSelected?.length === 0) validate['categories'] = 'Selecione pelo menos uma categoria';
    setErrors(validate);
    if (Object.keys(validate)?.length === 0) setSession('task')
  }

  const validateTask = (e) => {
    e.preventDefault()
    const validate = {}
    if (lessonsSelected?.length === 0) validate['lessons'] = 'Selecione pelo menos uma tarefa';
    setErrors(validate);
    ReactTooltip.show(inputRef.current);
    if (Object.keys(validate)?.length === 0) setSession('resume')
  }

  const categoriesSelect = useMemo(() => categories?.map((category) => <option key={category.id} value={category.id}>{category.name}</option>), [categories]);
  const categoriesSelectedList = useCallback((view = 'edit') => {
    return (
      <div className='d-flex align-items-center'>
        {categoriesSelected?.sort((a, b) => a.name - b.name)?.map((category) => {
          return (
            <div key={category?.id} className='d-flex justify-content-center align-items-center mr-3'>
              {view === 'edit' && <button type='button' onClick={() => removeCategories(category)} className='badge-items-button'>X</button>}
              <span className={view !== 'pdf' ? 'badge-items' : ''}>{category?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [categoriesSelected])
  const contentSportsSelectedList = useCallback((value) => {
    return (
      <div className='d-flex align-items-center'>
        {value?.sort((a, b) => a - b)?.map((contentSport) => {
          return (
            <div key={contentSport?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span>{contentSport?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [])
  const didacticStrategySelectList = useCallback((value) => {
    return (
      <div className='d-flex align-items-center'>
        {value?.sort((a, b) => a - b)?.map((didactic) => {
          return (
            <div key={didactic?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span>{didactic?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [])
  const materialsSelectedList = useCallback((value) => {
    return (
      <div className='d-flex align-items-center'>
        {value?.sort((a, b) => a - b)?.map((material) => {
          return (
            <div key={material?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span>{material?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [])
  const lessonsList = useMemo(() => {
    return lessonsFiltered?.map((lesson) => {
      return (
        <div className='autocomplete-input-list' onClick={() => handleSelectLessons(lesson)} key={lesson.id}>
          {lesson?.concept}
        </div>
      )
    })
  }, [lessonsFiltered])

  const lessonListSelected = useMemo(() => {
    return lessonsSelected?.map((lesson) => {
      return (
        <div className='d-flex flex-column justify-content-center border rounded shadow p-3 mb-2' key={lesson.id}>
          <div className='d-flex flex-row justify-content-between'>
            <div className='card_details_label' style={{ fontSize: '16px' }}>Tarefa:</div>
            <div className='pl-4 pr-4 pt-1 pb-1 rounded-pill bg-danger text-white' style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => setLessonsSelected(lessonsSelected?.filter((value) => value.id !== lesson.id))}>Remover</div>
          </div>
          <div className='mt-2 font-weight-bold'>Descrição</div>
          <div className=''>{lesson?.description}</div>
          <div className='mt-2 font-weight-bold'>{lesson?.videoURL}</div>
        </div>
      )
    })
  }, [lessonsSelected])

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='d-flex flex-row flex-1'>
            <div className='d-flex flex-column card mr-3'>
              <div className='card_header'>
                <h2 className='card_title'>Adicionar tarefa</h2>
                <p className='card_subtitle'>Clique no botão abaixo para adicionar tarefa.</p>
              </div>
              <div className='d-flex flex-1 mt-4 align-items-end'>
                <Link to={'club-lesson'} className='btn-secondary-color rounded-pill mr-3'>
                  Ver
                </Link>
                <button onClick={() => {
                  setContextData({
                    action: {
                      action: 'openAdd'
                    }
                  })
                  history.push('/club-lesson', { replace: true });
                }} className='btn-main-color rounded-pill'>
                  Criar
                </button>
              </div>
            </div>
            <div className='d-flex flex-column card mr-3'>
              <div className='card_header'>
                <h2 className='card_title'>Adicionar Aulas</h2>
                <p className='card_subtitle'>Clique no botão abaixo para adicionar uma aula.</p>
              </div>
              <div className='d-flex flex-1 mt-4 align-items-end'>
                <Link to={'club-planning'} className='btn-secondary-color rounded-pill mr-3'>
                  Ver
                </Link>
                <button onClick={openAdd} className='btn-main-color rounded-pill'>
                  Criar
                </button>
              </div>
            </div>
            <div className='d-flex flex-column card'>
              <div className='card_header'>
                <h2 className='card_title'>Metodologia Pronta</h2>
                <p className='card_subtitle'>Clique no botão abaixo para visualizar as metodologias prontas.</p>
              </div>
              <div className='d-flex flex-1 mt-4 align-items-end'>
                <Link to={'club-methodology'} className='btn-secondary-color rounded-pill mr-3'>
                  Ver
                </Link>
                <button onClick={() => {
                  setContextData({
                    action: {
                      action: 'openAdd'
                    }
                  })
                  history.push('/club-methodology', { replace: true });
                }} className='btn-main-color rounded-pill'>
                  Criar
                </button>
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='card_header'>
              <h2 className='card_title'>Pesquisar</h2>
              <p className='card_subtitle'>Busque abaixo por tarefas, aulas ou metodologias.</p>
            </div>
            <form action='' method='post' className='form_card'>
              <div className='d-flex flex-row align-items-center row'>
                <div className='col-sm-3'>
                  <input
                    required
                    type='text'
                    className='form_control mr-3'
                    name='name'
                    placeholder='Nome'
                    value={filters.name}
                    onChange={onChangeFilters}
                  />
                </div>
                <div className='col-sm-3'>
                  <select
                    required
                    className='form_control select mr-3'
                    name='source'
                    value={filters.source}
                    onChange={onChangeFilters}
                  >
                    <option disabled={true}>Selecionar</option>
                    <option value='T'>Tarefas</option>
                    <option value='A'>Aulas</option>
                    <option value='M'>Metodologias</option>
                  </select>
                </div>
                <div className='col-sm-3'>
                  <select
                    required
                    className='form_control select mr-3'
                    name='status'
                    value={filters.status}
                    onChange={onChangeFilters}
                  >
                    <option disabled={true}>Status</option>
                    <option value='T'>Tudo</option>
                    <option value='A'>Ativo</option>
                    <option value='I'>Inativo</option>
                  </select>
                </div>
                <div className='col-sm-3 my-auto'>
                  <button className='btn-secondary-color rounded-pill' onClick={search}>
                    Buscar
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className='content_painel'>
            <main className='content_panel'>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Aulas</h2>
                    <p className='card_subtitle'>Veja abaixo as aulas cadastradas.</p>
                    {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
                  </div>
                  <div className='list_card'>
                    <div className='d-flex justify-content-between flex-1 font-weight-bold' style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                      <div className='d-flex'>
                        <div style={{ width: '360px', marginRight: '5px' }}>&nbsp;</div>
                        <div style={{ width: '120px' }}>&nbsp;</div>
                        <div style={{ width: '120px', textAlign: 'center' }}>Ordem</div>
                        <div style={{ width: '120px', textAlign: 'center' }}>Categoria</div>
                      </div>
                      <div style={{ width: '120px', textAlign: 'center' }}>Ações</div>
                    </div>
                    {isLoading && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!isLoading &&
                      planningsFiltered.map((planning) => {
                        const status = dropDownStatus?.find((value) => value.id === planning.id && value.status);
                        return (
                          <div className='card_list_turma' key={`${planning.id}`}>
                            <div className='content_card'>
                              <div className='card_details_alternative'>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <p className='card_details_label text_uppercase my-auto' style={{ width: '360px' }}>
                                    <span style={{ color: '#3b3b3b' }}>{planning.name}</span>
                                  </p>
                                  <div ref={ref} style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '120px' }}>
                                    <div
                                      onClick={() => setDropDownStatus(dropDownStatus?.map((value) => (value.id === planning.id ? { ...value, status: !value.status } : { ...value })))}
                                      className={`${planning.status === 'A' ? 'badge badge-success' : 'badge badge-danger'} badge-button`}
                                      style={status ? { borderRadius: 8, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { borderRadius: 8, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
                                    >
                                      {planning.status === 'A' ? 'Ativo' : 'Inativo'}
                                      <div style={{ marginLeft: 10 }}><BsChevronDown color='#FFFFFF' /></div>
                                    </div>
                                    <div style={{ opacity: status ? 1 : 0 }} className='dropdown-shadow'>
                                      <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...planning, status: 'A' })}>Ativo</div>
                                      <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...planning, status: 'I' })}>Inativo</div>
                                    </div>
                                  </div>
                                  <div style={{ textAlign: 'center', width: '120px' }}>{planning?.classNumber}</div>
                                  <div style={{ textAlign: 'center', width: '120px' }} data-for={`lessonCategories-${planning?.id}`} data-tip>
                                    {planning?.categories[0]?.name}
                                  </div>
                                  <ReactTooltip id={`lessonCategories-${planning?.id}`}>
                                    {planning?.categories?.map((category, key, array) => `${category.name} ${key !== (array?.length - 1) ? ' | ' : ''}`)}
                                  </ReactTooltip>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 12, width: '120px' }}>
                                  <span onClick={() => edit(planning)}><BsFillPencilFill /></span>
                                  <span onClick={() => view(planning)}><BsEye /></span>
                                  <span onClick={() => toPdf(planning)}><BsDownload /></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      )}
                  </div>
                </div>
              </div>
              <div className='modal' style={display ? { display: 'flex', opacity: 1 } : undefined}>
                <div
                  className='modal_content' style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 20 }}>
                  <div className='modal_close' onClick={closeAdd}>
                    X
                  </div>
                  <h3 className='modal_title' style={{ padding: '0 30px' }}>
                    {planning.id === undefined ? 'Criar Aula' : 'Editar Aula'}
                  </h3>
                  {error && <MessageDangerMarginTop title='Atenção! ' description={message} style={{ padding: '0 30px' }} />}
                  {session === 'information' && (
                    <>
                      <div className='w-100'>
                        <div className='planning-header-buttons'>
                          <button className='btn-secondary-color mr-3'>Informação</button>
                          <button className='btn-disabled mr-3' disabled>Tarefa</button>
                          <button className='btn-disabled' disabled>Resumo</button>
                        </div>
                      </div>
                      <div className='form_modal'>
                        <div className='w-100' style={{ padding: '0 30px' }}>
                          <div className='row'>
                            <div className='col-sm-12'>
                              <div className='form_group'>
                                <label className='card_details_label'>Nome da Aula</label>
                                <input
                                  required
                                  type='text'
                                  className='form_control'
                                  name='name'
                                  value={planning.name}
                                  onChange={onChange}
                                  placeholder='Nome da Aula'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12'>
                              <div className='form_group'>
                                <label className='card_details_label'>Objetivo</label>
                                <input
                                  required
                                  type='text'
                                  className='form_control'
                                  name='objective'
                                  value={planning.objective}
                                  onChange={onChange}
                                  placeholder='Objetivo'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12'>
                              <div className='form_group'>
                                <label className='card_details_label'>Número da Aula</label>
                                <input
                                  required
                                  type='number'
                                  className='form_control'
                                  name='classNumber'
                                  value={planning.classNumber}
                                  onChange={onChange}
                                  placeholder='Número da Aula'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12'>
                              <div className='form_group'>
                                <label htmlFor='status' className='card_details_label'>
                                  Status
                                </label>
                                <select
                                  required
                                  name='status'
                                  className='form_control select'
                                  value={planning.status}
                                  onChange={onChange}
                                >
                                  <option value='' disabled hidden></option>
                                  <option value='A'>Ativo</option>
                                  <option value='I'>Inativo</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12'>
                              <div className='form_group'>
                                <label htmlFor='category_id' className='card_details_label'>
                                  Categoria
                                </label>
                                <select
                                  required
                                  name='category_id'
                                  className='form_control select'
                                  value={planning.category_id}
                                  onChange={(e) => {
                                    onChange(e);
                                    addCategories(e)
                                  }}
                                >
                                  <option value=''>Selecionar</option>
                                  {categoriesSelect}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 mb-2'>
                              <div className="d-flex align-items-center"><span className='bagde-title'>Categoria(s) selecionada(s)</span> {categoriesSelectedList()}</div>
                            </div>
                          </div>
                          <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                            <div className='main-pill mr-2'></div>
                            <div className='disable-pill mr-2'></div>
                            <div className='disable-pill'></div>
                          </div>
                          <div className='w-100 d-flex justify-content-center'>
                            <button type='button' className='btn-main-color mr-3' onClick={closeAdd}>
                              Voltar
                            </button>
                            <button className='btn-secondary-color' onClick={validateInformation}>
                              Avançar
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {session === 'task' && (
                    <>
                      <div className='w-100'>
                        <div className='planning-header-buttons'>
                          <button className='btn-disabled mr-3' disabled>Informação</button>
                          <button className='btn-secondary-color mr-3'>Tarefa</button>
                          <button className='btn-disabled' disabled>Resumo</button>
                        </div>
                      </div>
                      <div className='form_modal'>
                        <div className='w-100' style={{ padding: '0 30px' }}>
                          <div className='row'>
                            <div className='col-sm-12'>
                              <div className='form_group' ref={inputRef}>
                                <input
                                  type='text'
                                  className='form_control error'
                                  name='lesson'
                                  value={filters.lesson}
                                  onChange={(e) => {
                                    onChangeFilters(e);
                                    setLessonsFiltered(lessons?.filter((lesson) => lesson?.concept?.toUpperCase()?.includes(e?.target?.value?.toUpperCase() || '')))
                                  }}
                                  onClick={() => {
                                    setShowLessonsFiltered(true)
                                  }}
                                  placeholder='Procurar tarefa'
                                  style={{ position: 'relative' }}
                                  data-for='lessonTooltip'
                                  data-tip
                                  ref={inputLessonRef}
                                />
                                <ReactTooltip id='lessonTooltip' type='warning'>
                                  Escolha pelo menos uma tarefa
                                </ReactTooltip>
                                <div className='autocomplete-input' style={filters?.lesson || showLessonsFiltered ? { display: 'block' } : { display: 'none' }}>
                                  {lessonsList}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 d-flex flex-column mt-3 mb-3' style={{ height: '250px', overflowY: 'auto' }}>
                              {lessonListSelected}
                            </div>
                          </div>
                          <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                            <div className='disable-pill mr-2'></div>
                            <div className='main-pill mr-2'></div>
                            <div className='disable-pill'></div>
                          </div>
                          <div className='w-100 d-flex justify-content-center'>
                            <button type='button' className='btn-main-color mr-3' onClick={() => setSession('information')}>
                              Voltar
                            </button>
                            <button className='btn-secondary-color' onClick={validateTask}>
                              Avançar
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {session === 'resume' && (
                    <>
                      <div className='w-100'>
                        <div className='planning-header-buttons'>
                          <button className='btn-disabled mr-3' disabled>Informação</button>
                          <button className='btn-disabled mr-3'>Tarefa</button>
                          <button className='btn-secondary-color' disabled>Resumo</button>
                        </div>
                      </div>
                      <form onSubmit={handleSubmit} method='post' className='form_modal'>
                        <div className='w-100' style={{ padding: '0 30px' }}>
                          <div className='row'>
                            <div className='col-sm-12 text-center'>
                              <h4>Aula:</h4>
                            </div>
                          </div>
                          <div style={{ height: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
                            <div className='row mt-3'>
                              <div className='col-sm-12'>
                                <div className='font-weight-bold'>Nome da aula</div>
                                <div className=''>{planning?.name}</div>
                              </div>
                            </div>
                            <div className='row mt-3'>
                              <div className='col-sm-12'>
                                <div className='font-weight-bold'>Objetivo</div>
                                <div className=''>{planning?.objective}</div>
                              </div>
                            </div>
                            <div className='row mt-3'>
                              <div className='col-sm-12'>
                                <div className='font-weight-bold'>Número da aula</div>
                                <div className=''>{planning?.classNumber}</div>
                              </div>
                            </div>
                            <div className='row mt-3'>
                              <div className='col-sm-12'>
                                <div className='font-weight-bold'>Categoria selecionadas</div>
                                <div className='resume'>
                                  <ul>
                                    {categoriesSelected?.map((category) => <li key={category.id}>{category.name}</li>)}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className='row mt-3'>
                              <div className='col-sm-12'>
                                <div className='font-weight-bold'>Tarefa selecionadas</div>
                                <div className='resume'>
                                  <ul>
                                    {lessonsSelected?.map((lesson) => <li key={lesson.id}>{lesson.concept}</li>)}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                            <div className='disable-pill mr-2'></div>
                            <div className='disable-pill mr-2'></div>
                            <div className='main-pill'></div>
                          </div>
                          <div className='w-100 d-flex justify-content-center'>
                            <button type='button' className='btn-main-color mr-3' onClick={() => setSession('task')}>
                              Voltar
                            </button>
                            <button className='btn-secondary-color' onClick={handleSubmit}>
                              Salvar Alterações
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </div>
                <div className="modal_success" style={success ? { display: 'flex', opacity: 1 } : undefined}>
                  <div className='modal_content_success'>
                    <div className="modal_header_success">
                      <div className='modal_title_success'>{planning.id === undefined ? 'Aula criada' : 'Alterações salvas'}</div>
                      <div className='modal_close_success' onClick={closeAdd}>
                        X
                      </div>
                    </div>
                    <div className='modal_body_success'>
                      <div className='modal_icon_success'>
                        <Success style={{ color: '#24c782' }} />
                      </div>
                      <div className="">{planning.id === undefined ? 'Aula criada com sucesso!' : 'Aula editada com sucesso!'}</div>
                    </div>
                    <div className='modal_footer_success'>
                      <button type='button' onClick={closeAdd} className='btn btn-secondary-color w-100'>
                        Fechar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal' style={displayView ? { display: 'flex', opacity: 1 } : undefined}>
                <div
                  className='modal_content' style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 20 }}>
                  <div className='modal_close' onClick={closeView}>
                    X
                  </div>
                  <div id='planning'>
                    <div className='w-100' style={{ padding: '0 30px' }}>
                      <div className='row'>
                        <div className='col-sm-12 text-center'>
                          <h4>Aula:</h4>
                        </div>
                      </div>
                      <div style={{ height: '450px', overflowY: 'auto', overflowX: 'hidden' }}>
                        <div className='row mt-3'>
                          <div className='col-sm-12'>
                            <div className='font-weight-bold'>Nome da aula</div>
                            <div className=''>{planning?.name}</div>
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-12'>
                            <div className='font-weight-bold'>Objetivo</div>
                            <div className=''>{planning?.objective}</div>
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-12'>
                            <div className='font-weight-bold'>Número da aula</div>
                            <div className=''>{planning?.classNumber}</div>
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-12'>
                            <div className='font-weight-bold'>Categoria selecionadas</div>
                            <div className='resume'>
                              <ul>
                                {categoriesSelected?.map((category) => <li key={category.id}>{category.name}</li>)}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-12'>
                            <div className='font-weight-bold'>Tarefa selecionadas</div>
                            <div className='resume'>
                              <ul>
                                {lessonsSelected?.map((lesson) => <li key={lesson.id}>{lesson.concept}</li>)}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal_success" style={success ? { display: 'flex', opacity: 1 } : undefined}>
                  <div className='modal_content_success'>
                    <div className="modal_header_success">
                      <div className='modal_title_success'>{planning.id === undefined ? 'Aula criada' : 'Alterações salvas'}</div>
                      <div className='modal_close_success' onClick={closeAdd}>
                        X
                      </div>
                    </div>
                    <div className='modal_body_success'>
                      <div className='modal_icon_success'>
                        <Success style={{ color: '#24c782' }} />
                      </div>
                      <div className="">{planning.id === undefined ? 'Aula criada com sucesso!' : 'Aula editada com sucesso!'}</div>
                    </div>
                    <div className='modal_footer_success'>
                      <button type='button' onClick={closeAdd} className='btn btn-secondary-color w-100'>
                        Fechar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'none' }}>
                <div ref={planningPdfRef}>
                  {lesson?.map((value) => {
                    return (
                      <div key={value?.id} className='m-5' style={{ height: '100vh' }}>
                        <div className='d-flex flex-1 flex-column justify-content-center align-items-center'>
                          <img src="../../img/logo.png" alt="Marca" />
                          <h4 className='mt-3'>{planning?.name}</h4>
                          <h5 className='mt-1'>
                            {value?.concept}
                          </h5>
                        </div>
                        <div className='row'>
                          <div className='col-sm-9'>
                            <span style={{ fontWeight: 'bold' }}>Categorias</span>
                            {categoriesSelectedList('pdf')}
                          </div>
                          <div className='col-sm-3'>
                            <span style={{ fontWeight: 'bold' }}>Status: </span>
                            {value?.status === 'A' ? 'Ativo' : 'Inativo'}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-9'>
                            <span style={{ fontWeight: 'bold' }}>
                              Conteúdo
                            </span>
                            {contentSportsSelectedList(value?.lesson_content_sports)}
                          </div>
                          <div className='col-sm-3'>
                            <span style={{ fontWeight: 'bold' }}>Espaço: </span>
                            {value?.space}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-6'>
                            <span style={{ fontWeight: 'bold' }}>
                              Estratégia didática
                            </span>
                            {didacticStrategySelectList(value?.lesson_didactic_strategies)}
                          </div>
                          <div className='col-sm-3'>
                            <span style={{ fontWeight: 'bold' }}>Tempo: </span>
                            {value?.time}
                          </div>
                          <div className='col-sm-3'>
                            <span style={{ fontWeight: 'bold' }}>Sessão da aula/treino: </span>
                            {value?.lessonSessionLabel}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-9'>
                            <span style={{ fontWeight: 'bold' }}>
                              Material
                            </span>
                            {materialsSelectedList(value?.lesson_materials)}
                          </div>
                          <div className='col-sm-3'>
                            <span style={{ fontWeight: 'bold' }}>N. de jogadores: </span>
                            {value?.numberOfPlayers}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-12'>
                            <div style={{ fontWeight: 'bold' }}>Descrição</div>
                            {value?.description}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-6'>
                            <div style={{ fontWeight: 'bold' }}>Regras</div>
                            {value?.rules}
                          </div>
                          <div className='col-sm-6'>
                            <div style={{ fontWeight: 'bold' }}>Pontuação</div>
                            {value?.score}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-6'>
                            <div style={{ fontWeight: 'bold' }}>Variação</div>
                            {value?.variation}
                          </div>
                          <div className='col-sm-6'>
                            <div style={{ fontWeight: 'bold' }}>Feedback</div>
                            {value?.feedback}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-12'>
                            <div style={{ fontWeight: 'bold' }}>Exemplo</div>
                            <div className='border p-3 rounded-lg mt-3 d-flex justify-content-center'>
                              <img src={value?.artUrl} width='100%' alt='' />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Planning;
