import React from 'react';
import { getNestedPropByIndex } from '../../utils/utils';

const SelectRounded = ({
  label,
  name,
  value,
  onChange,
  children,
  style,
  styleContainer,
  styleWrapper,
  className,
  classNameContainer,
  classNameWrapper,
  iconPrefix,
  removeFormGroup = false,
  ...props
}) => {
  return (
    <div
      className={!removeFormGroup ? 'form_group ' + (classNameContainer || '') : classNameContainer}
      style={{ alignItems: 'center', ...styleContainer }}
    >
      <label className='card_details_label' style={{ fontSize: '1rem' }}>
        {label}
      </label>
      <label className={`w-100 input-rounded position-relative ${classNameWrapper || ''}`} style={{ ...styleWrapper }}>
        {iconPrefix && (
          <span
            className='position-absolute d-flex align-items-center'
            style={{ marginRight: '8px', top: 0, bottom: 0 }}
          >
            {iconPrefix}
          </span>
        )}
        <select
          {...props}
          className={`form_control select ${iconPrefix ? 'pl-4' : ''} ${className || ''}`}
          name={name}
          value={typeof value === 'object' && name ? getNestedPropByIndex(value, name) : value}
          onChange={onChange}
          style={{ border: 'none', marginTop: 0, ...style }}
        >
          {children}
        </select>
      </label>
    </div>
  );
};

export default SelectRounded;
