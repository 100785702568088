import api from './api';

export default {
  async getPlans() {
    const response = await api.get('get-signature-plans-admin');
    return response.data;
  },
  async getPlansAdmin() {
    const response = await api.get('get-signature-plans-admin');
    return response.data;
  },

  async getCreditAmount() {
    const response = await api.get('/get-company-signature-credit-amount');
    return response.data;
  },
  async buyPlan(plan, billingType) {
    const response = await api.post('/buy-signature-credit', { planId: plan.id, billingType});
    return response.data;
  },

  async createPlan(plan) {
    const response = await api.post('create-signature-plan-admin', plan);
    return response.data;
  },
  async updatePlan(plan) {
    const response = await api.put('update-signature-plan-admin', plan);
    return response.data;
  },
  async deletePlan(plan) {
    const response = await api.delete('delete-signature-plan-admin', { id: plan.id });
    return response.data;
  },

  async getSignatureTransactions(page, limit, filters) {
    const searchParams = new URLSearchParams({
      perPage: limit,
      page,
      ...filters,
    });
    const response = await api.get(`get-signature-credit-transactions?${searchParams}`);
    return response.data;
  },
  async requestSignatures(signers, schoolId, contractId) {
    await api.post('request-contract-signatures', {
      signers,
      schoolId,
      contractId,
    });
  },
  async getContractSignatureDetails(contractId) {
    return (await api.get('get-signature-request-data', { params: { contractId } })).data.data.autentiqueDocument;
  },
};
