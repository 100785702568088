import React, { useState, useEffect, useContext } from 'react';
import Footer from '../../components/school/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { atualizarQtdProdutoCarrinho, excluirProdutoCarrinho } from '../../redux/actions/Cart';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../Context/UserContext';
// import { BsCreditCard } from 'react-icons/bs';
// import { RiBarcodeLine } from 'react-icons/ri';

const MyCart = () => {
  const { t } = useTranslation();
  const dispach = useDispatch();
  const { Cart } = useSelector((state) => state);
  const { school, user, athlete, company } = useContext(UserContext);
  const [qtdTotal, setQtdTotal] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);
  const [identidade, setIdentidade] = useState({});

  useEffect(() => {
    let qtd = 0;
    let valor = 0;
    Cart.forEach((item) => {
      qtd += Number(item.qtd);
      valor += Number(item.qtd) * Number(item.preco);
    });

    setQtdTotal(qtd);
    setValorTotal(valor);
  }, [Cart]);

  useEffect(() => {
    async function getSession() {
      setIdentidade({
        school: school,
        user: user,
        athlete: athlete,
        company: company,
      });
    }

    getSession();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('StudentCartPage.PageTitle')}</h2>
                    <p className='card_subtitle'>{t('StudentCartPage.PageSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    {Cart.length === 0 && (
                      <div className='card_details'>
                        <p style={{ textAlign: 'center', margin: 100 }}>{t('StudentCartPage.EmptyCartMessage')}</p>
                      </div>
                    )}
                    <div
                      style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'fit-content' }}
                      className='flex-column flex-lg-row'
                    >
                      <div className='col-12 col-lg-9'>
                        {Cart.map((item) => (
                          <React.Fragment key={item.id}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                width: '100%',
                                justifyContent: 'start',
                                marginTop: 20,
                                marginBottom: 10,
                              }}
                            >
                              <div style={{ paddingRight: 0, marginRight: 0 }} className='col-12 col-sm-3'>
                                <img
                                  className=' mx-auto mx-sm-0'
                                  style={{ minWidth: '100px', height: 120, objectFit: 'cover' }}
                                  src={item.url + '/' + item.fotoCapa}
                                />
                              </div>

                              <div className='col-12 col-sm-6 col-lg-3  mt-2 mt-sm-0'>
                                <p
                                  style={{
                                    fontSize: 16,
                                    fontFamily: 'Montserrat, sans-serif!important',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {item.titulo}
                                </p>
                                <p style={{ fontSize: 14, fontFamily: 'Montserrat, sans-serif!important' }}>
                                  {t('Size')}: {item.tamanho}
                                </p>
                              </div>
                              <div className='row flex-column flex-sm-row align-items-center align-items-sm-start col-12 col-lg-6 justify-content-center justify-content-lg-end'>
                                <div style={{ marginTop: 15 }} className=''>
                                  <p
                                    style={{ fontSize: 16, fontFamily: 'Montserrat, sans-serif!important' }}
                                    className='text-center text-sm-left'
                                  >
                                    {t('Price')}
                                  </p>
                                  <p
                                    style={{ fontFamily: 'Montserrat, sans-serif!important' }}
                                    className='text-center text-sm-left'
                                  >
                                    {t('FormatFunction.intlCurrencyWithOptions', { value: item.preco })}
                                  </p>
                                </div>
                                <div style={{ marginTop: 15 }} className='ml-md-3 ml-1'>
                                  <p
                                    style={{
                                      fontSize: 16,
                                      fontFamily: 'Montserrat, sans-serif!important',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {t('Quantity')}
                                  </p>
                                  <p
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <button
                                      onClick={() => dispach(atualizarQtdProdutoCarrinho(item.id, -1))}
                                      style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        width: 40,
                                        height: 40,
                                        fontSize: 38,
                                        fontWeight: 'bold',
                                        color: '#B0B0B0',
                                        background: 'transparent',
                                        border: 0,
                                      }}
                                    >
                                      -
                                    </button>
                                    <input
                                      type='text'
                                      disabled
                                      value={item.qtd}
                                      style={{
                                        width: 40,
                                        height: 40,
                                        border: '1px solid #E3E3E3',
                                        textAlign: 'center',
                                      }}
                                    />
                                    <button
                                      onClick={() => dispach(atualizarQtdProdutoCarrinho(item.id, 1))}
                                      style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        width: 40,
                                        height: 40,
                                        fontSize: 38,
                                        fontWeight: 'bold',
                                        color: '#B0B0B0',
                                        background: 'transparent',
                                        border: 0,
                                      }}
                                    >
                                      +
                                    </button>
                                  </p>
                                  <p
                                    onClick={() => dispach(excluirProdutoCarrinho(item.id))}
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: 13,
                                      fontFamily: 'Montserrat, sans-serif!important',
                                      textAlign: 'center',
                                      marginTop: 5,
                                      color: 'brown',
                                    }}
                                  >
                                    {t('Delete')}
                                  </p>
                                </div>
                                <div style={{ marginTop: 15 }} className='ml-1 ml-md-3'>
                                  <p
                                    style={{ fontSize: 16, fontFamily: 'Montserrat, sans-serif!important' }}
                                    className='text-center text-sm-left'
                                  >
                                    {t('Subtotal')}
                                  </p>
                                  <p
                                    style={{ fontFamily: 'Montserrat, sans-serif!important' }}
                                    className='text-center text-sm-left'
                                  >
                                    <b>
                                      {t('FormatFunction.intlCurrencyWithOptions', { value: item.qtd * item.preco })}
                                    </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ width: '94%', height: 1, background: '#ddd', marginTop: 20, marginBottom: 10 }}
                            />
                          </React.Fragment>
                        ))}
                      </div>
                      {Cart.length > 0 && (
                        <div style={{ boxShadow: '0 3px 6px rgb(0 0 0 / 16%)' }} className='col-12 col-lg-3'>
                          <div
                            style={{ background: '#F1F1F1', padding: '20px 0', textAlign: 'center', marginBottom: 25 }}
                          >
                            <h3
                              className='uppercase'
                              style={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                fontFamily: 'Montserrat, sans-serif!important',
                              }}
                            >
                              {t('OrderSummary')}
                            </h3>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginBottom: 15,
                            }}
                          >
                            <span style={{ fontSize: 16, fontFamily: 'Montserrat, sans-serif!important' }}>
                              {t('Quantity')}
                            </span>
                            <span style={{ fontSize: 18, fontFamily: 'Montserrat, sans-serif!important' }}>
                              {qtdTotal} UN
                            </span>
                          </div>
                          <div
                            style={{
                              width: '92%',
                              marginLeft: 10,
                              height: 1,
                              background: '#ddd',
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          ></div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                              paddingLeft: 10,
                              paddingRight: 10,
                            }}
                          >
                            <span style={{ fontSize: 16, fontFamily: 'Montserrat, sans-serif!important' }}>
                              <b>{t('Total')}</b>
                            </span>
                            <span style={{ fontSize: 18, fontFamily: 'Montserrat, sans-serif!important' }}>
                              <b>{t('FormatFunction.intlCurrencyWithOptions', { value: valorTotal })}</b>
                            </span>
                          </div>

                          <Link
                            to={{
                              pathname: 'school-cart-payment',
                              state: {
                                produtos: Cart,
                                qtdTotal,
                                valorSubTotal: 0,
                                valorTotal,
                                identidade,
                              },
                            }}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: 4,
                              fontSize: 16,
                              fontFamily: 'Montserrat, sans-serif!important',
                              border: 0,
                              marginTop: 20,
                              height: 40,
                              width: '93%',
                              marginLeft: 10,
                              background: '#000',
                              color: 'white',
                            }}
                            onClick={() => { }}
                          >
                            {t('Checkout')}
                          </Link>

                          <Link
                            to='/list-products'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: 4,
                              fontSize: 16,
                              fontFamily: 'Montserrat, sans-serif!important',
                              border: '1.5px solid #B0B0B0',
                              marginTop: 20,
                              height: 40,
                              width: '93%',
                              marginLeft: 10,
                              background: '#fff',
                              color: '#B0B0B0',
                              marginBottom: 30,
                            }}
                            onClick={() => { }}
                          >
                            {t('KeepBuying')}
                          </Link>
                        </div>
                      )}
                    </div>

                    {Cart.length > 0 && (
                      <React.Fragment>
                        <div
                          style={{ width: '100%', height: 1, background: '#ddd', marginTop: 20, marginBottom: 10 }}
                        ></div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 20,
                            marginBottom: 10,
                          }}
                        >
                          {/* <span
                            style={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold', marginRight: 20 }}
                          >
                            {t('PaymentMethods')}:{' '}
                          </span>
                          <RiBarcodeLine style={{ marginLeft: 20 }} />
                          <span style={{ fontSize: 12, marginLeft: 10, fontWeight: '500' }}>{t('Bill')}</span>
                          <BsCreditCard style={{ marginLeft: 20 }} />
                          <span style={{ fontSize: 12, marginLeft: 10, fontWeight: '500' }}>{t('Card')}</span> */}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default MyCart;
