import React, { useEffect, useState } from 'react';
import Header from '../../components/club/Header';
import Footer from '../../components/club/Footer';
import { capitalize, exportToXLS, handleOnChange } from '../../../src/utils/utils';
import Message from '../../components/Message';
import { useTranslation } from 'react-i18next';
import CardIndicator from '../../components/cards/CardIndicator';
import ModalSimpleTable from '../../components/modal/ModalSimpleTable';
import moment from 'moment';
import DateIntervalRounded from '../../components/inputs/DateIntervalRounded';
import { FaSearch } from 'react-icons/fa';
import Loading from '../../components/Loading';
import { getContractReports, getStudentReports, getFinancialReports } from '../../services/ClubReportsService';
import { calcValueToReceive } from '../../utils/utils-financial';
import { cellPhoneFormatI18n, dateFormatI18n } from '../../utils/formatters';
import axios from 'axios';
import Api, { uploadFolder } from '../../utils/api';

const Dashboard = () => {
  const companyId = JSON.parse(localStorage.getItem('company')).id;

  const { MessageDanger } = Message();

  const { t, i18n } = useTranslation();

  const [isLoadingStudentsData, setIsLoadingStudentsData] = useState(true);
  const [isLoadingContractsData, setIsLoadingContractsData] = useState(true);
  const [isLoadingFinancialsData, setIsLoadingFinancialsData] = useState(true);

  const [errorStudents, setErrorStudents] = useState('');
  const [errorContracts, setErrorContracts] = useState('');
  const [errorFinancials, setErrorFinancials] = useState('');

  const [filters, setFilters] = useState({
    filterStartDate: moment().format('YYYY-MM'),
    filterEndDate: moment().format('YYYY-MM'),
  });

  const [students, setStudents] = useState(null);
  const [totalStudents, setTotalStudents] = useState(null);
  const [activeStudents, setActiveStudents] = useState(null);
  const [inactiveStudents, setInactiveStudents] = useState(null);
  const [noDefaultingStudents, setNoDefaultingStudents] = useState(null);
  const [defaultingStudents, setDefaultingStudents] = useState(null);
  const [monthlyPlans, setMonthlyPlans] = useState(null);
  const [quarterlyPlans, setQuarterlyPlans] = useState(null);
  const [annualPlans, setAnnualPlans] = useState(null);
  const [plansToExpire, setPlansToExpire] = useState(null);
  const [expiredPlans, setExpiredPlans] = useState(null);
  const [grossIncome, setGrossIncome] = useState(null);
  const [tuitionsIncome, setTuitionsIncome] = useState(null);
  const [incomeWithOrders, setIncomeWithOrders] = useState(null);
  // const [incomeWithUniforms, setIncomeWithUniforms] = useState(null);
  // const [incomeWithEvents, setIncomeWithEvents] = useState(null);
  // const [tuitionsFee, setTuitionsFee] = useState(null);
  const [activeFemaleStudents, setActiveFemaleStudents] = useState(null);
  const [activeMaleStudents, setActiveMaleStudents] = useState(null);

  const modalDataDefault = { isShow: false, title: null, filename: null, data: [] };
  const [studentsModalData, setStudentsModalData] = useState(modalDataDefault);
  const [contractsModalData, setContractsModalData] = useState(modalDataDefault);
  const [financesModalData, setFinancesModalData] = useState(modalDataDefault);

  const [schools, setSchools] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [financials, setFinancials] = useState([]);

  const financialReducer = (acc, finance) => {
    const { valueCalculated } = finance;
    return acc + (finance.type === 'Receita' ? valueCalculated : valueCalculated * -1);
  };

  const formatCurrencyWithSignal = (isPositive, currency) => {
    const signal = isPositive ? '' : '-';
    return currency.split(/\s/).join(` ${signal}`);
  };

  const indicatorColumnA = [
    {
      title: t('ClubDashboardPage.TotalStudents'),
      subtitle: t('ClubDashboardPage.TotalStudentsSubtitle'),
      valueIndicator: totalStudents?.length,
      onClick: () => openModal('students', 'totalStudents'),
    },
    {
      title: t('ClubDashboardPage.ActiveStudents'),
      subtitle: t('ClubDashboardPage.ActiveStudentsSubtitle'),
      valueIndicator: activeStudents?.length,
      onClick: () => openModal('students', 'activeStudents'),
    },
    {
      title: t('ClubDashboardPage.InactiveStudents'),
      subtitle: t('ClubDashboardPage.InactiveStudentsSubtitle'),
      valueIndicator: inactiveStudents?.length,
      onClick: () => openModal('students', 'inactiveStudents'),
    },
    {
      title: t('ClubDashboardPage.NoDefaultingStudents'),
      subtitle: t('ClubDashboardPage.NoDefaultingStudentsSubtitle'),
      valueIndicator: noDefaultingStudents?.length,
      onClick: () => openModal('students', 'noDefaultingStudents'),
    },
    {
      title: t('ClubDashboardPage.DefaultingStudents'),
      subtitle: t('ClubDashboardPage.DefaultingStudentsSubtitle'),
      valueIndicator: defaultingStudents?.length,
      onClick: () => openModal('students', 'defaultingStudents'),
    },
    {
      title: t('ClubDashboardPage.MaleStudents'),
      subtitle: t('ClubDashboardPage.MaleStudentsSubtitle'),
      valueIndicator: activeMaleStudents?.length,
      onClick: () => openModal('students', 'defaultingStudents'),
    },
    {
      title: t('ClubDashboardPage.FemaleStudents'),
      subtitle: t('ClubDashboardPage.FemaleStudentsSubtitle'),
      valueIndicator: activeFemaleStudents?.length,
      onClick: () => openModal('students', 'defaultingStudents'),
    },
  ];

  const indicatorColumnB = [
    {
      title: t('ClubDashboardPage.MonthlyPlans'),
      subtitle: t('ClubDashboardPage.MonthlyPlansSubtitle'),
      valueIndicator: monthlyPlans?.length,
      onClick: () => openModal('contracts', 'monthlyPlans'),
    },
    {
      title: t('ClubDashboardPage.QuarterlyPlans'),
      subtitle: t('ClubDashboardPage.QuarterlyPlansSubtitle'),
      valueIndicator: quarterlyPlans?.length,
      onClick: () => openModal('contracts', 'quarterlyPlans'),
    },
    {
      title: t('ClubDashboardPage.AnnualPlans'),
      subtitle: t('ClubDashboardPage.AnnualPlansSubtitle'),
      valueIndicator: annualPlans?.length,
      onClick: () => openModal('contracts', 'annualPlans'),
    },
    {
      title: t('ClubDashboardPage.PlansToExpire'),
      subtitle: t('ClubDashboardPage.PlansToExpireSubtitle'),
      valueIndicator: plansToExpire?.length,
      onClick: () => openModal('contracts', 'plansToExpire'),
    },
    {
      title: t('ClubDashboardPage.ExpiredPlans'),
      subtitle: t('ClubDashboardPage.ExpiredPlansSubtitle'),
      valueIndicator: expiredPlans?.length,
      onClick: () => openModal('contracts', 'expiredPlans'),
    },
  ];

  const indicatorColumnC = [
    {
      title: t('ClubDashboardPage.GrossIncome'),
      subtitle: t('ClubDashboardPage.GrossIncomeSubtitle'),
      valueIndicator: grossIncome
        ? t('FormatFunction.intlCurrencyWithOptions', { value: grossIncome.reduce(financialReducer, 0) })
        : null,
      onClick: () => openModal('financials', 'grossIncome'),
    },
    {
      title: t('ClubDashboardPage.TuitionsIncome'),
      subtitle: t('ClubDashboardPage.TuitionsIncomeSubtitle'),
      valueIndicator: tuitionsIncome
        ? t('FormatFunction.intlCurrencyWithOptions', {
          value: tuitionsIncome.reduce(financialReducer, 0),
        })
        : null,
      onClick: () => openModal('financials', 'tuitionsIncome'),
    },
    {
      title: t('ClubDashboardPage.IncomeWithOrders'),
      subtitle: t('ClubDashboardPage.IncomeWithOrdersSubtitle'),
      valueIndicator: incomeWithOrders
        ? t('FormatFunction.intlCurrencyWithOptions', {
          value: incomeWithOrders.reduce(financialReducer, 0),
        })
        : null,
      onClick: () => openModal('financials', 'incomeWithOrders'),
    },
    // {
    //   title: t('ClubDashboardPage.IncomeWithUniforms'),
    //   subtitle: t('ClubDashboardPage.IncomeWithUniformsSubtitle'),
    //   valueIndicator: incomeWithUniforms
    //     ? t('FormatFunction.intlCurrencyWithOptions', {
    //         value: incomeWithUniforms.reduce(financialReducer, 0),
    //       })
    //     : null,
    //   onClick: () => openModal('financials', 'incomeWithUniforms')
    // },
    // {
    //   title: t('ClubDashboardPage.IncomeWithEvents'),
    //   subtitle: t('ClubDashboardPage.IncomeWithEventsSubtitle'),
    //   valueIndicator: incomeWithEvents
    //     ? t('FormatFunction.intlCurrencyWithOptions', {
    //         value: incomeWithEvents.reduce(financialReducer, 0),
    //       })
    //     : null,
    //   onClick: () => openModal('financials', 'incomeWithEvents')
    // },
    // {
    //   title: t('ClubDashboardPage.TuitionsFee'),
    //   subtitle: t('ClubDashboardPage.TuitionsFeeSubtitle'),
    //   valueIndicator: tuitionsFee
    //     ? t('FormatFunction.intlCurrencyWithOptions', { value: tuitionsFee.reduce(financialReducer, 0) })
    //     : null,
    //   onClick: () => openModal('financials', 'tuitionsFee')
    // },
  ];

  useEffect(() => {
    i18n.changeLanguage('pt-BR');

    fetchReports();

    window.addEventListener('keydown', closeModalsOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalsOnEscape);
    };
  }, []);

  const fetchReports = async () => {
    fetchStudentsData(companyId, filters);
    fetchContractsData(companyId, filters);
    fetchFinancialsData(companyId, filters);
  };

  const searchByFilter = async () => {
    // fetchContractsData(companyId, filters);
    fetchFinancialsData(companyId, filters);
  };

  const fetchStudentsData = async (companyId, filters) => {
    setIsLoadingStudentsData(true);
    const { status, students } = await getStudentReports(companyId, filters);

    setIsLoadingStudentsData(false);
    if (status === 'SUCCESS') {
      setStudents(students);
      setTotalStudents(students);
      setActiveStudents(students.filter(({ usuStatus }) => usuStatus === 'A'));
      setInactiveStudents(students.filter(({ usuStatus }) => usuStatus === 'I'));
      setNoDefaultingStudents(students.filter(({ isDefaulting }) => !isDefaulting));
      setDefaultingStudents(students.filter(({ isDefaulting }) => isDefaulting));
      setActiveFemaleStudents(students.filter(({ usuGender }) => usuGender === 'F'));
      setActiveMaleStudents(students.filter(({ usuGender }) => usuGender === 'M'));
    } else {
      setErrorStudents('Não foi possível carregar o relatório de alunos. Por favor, tente novamente.');
    }
  };

  const fetchContractsData = async (companyId, filters) => {
    setIsLoadingContractsData(true);
    const { status, contracts } = await getContractReports(companyId, filters);

    setIsLoadingContractsData(false);
    if (status === 'SUCCESS') {
      const today = moment().format('YYYY-MM-DD');
      const limitDay = moment().add(30, 'days').format('YYYY-MM-DD');

      setContracts(contracts);

      setMonthlyPlans(contracts.filter(({ cyclo }) => cyclo === 'MONTHLY'));
      setQuarterlyPlans(contracts.filter(({ cyclo }) => cyclo === 'QUARTERLY'));
      setAnnualPlans(contracts.filter(({ cyclo }) => cyclo === 'YEARLY'));
      setPlansToExpire(contracts.filter(({ dateEnd }) => !!dateEnd && today <= dateEnd && dateEnd <= limitDay));
      setExpiredPlans(contracts.filter(({ dateEnd }) => !!dateEnd && dateEnd < today));
    } else {
      setErrorContracts('Não foi possível carregar o relatório de planos. Por favor, tente novamente.');
    }
  };

  const fetchFinancialsData = async (companyId, filters) => {
    setIsLoadingFinancialsData(true);
    const { status, financials } = await getFinancialReports(companyId, filters);

    setIsLoadingFinancialsData(false);
    if (status === 'SUCCESS') {
      const financialsMapped = financials.map(calcValueToReceive);

      setFinancials(financialsMapped);

      setGrossIncome(financialsMapped);
      setTuitionsIncome(financialsMapped.filter(({ contract_id }) => !!contract_id));
      setIncomeWithOrders(financialsMapped.filter(({ order_id }) => !!order_id));
      // setIncomeWithUniforms(financialsMapped.filter((finance) => true));
      // setIncomeWithEvents(financialsMapped.filter((finance) => true));
      // setTuitionsFee([]);
    } else {
      setErrorFinancials('Não foi possível carregar o relatório financeiro. Por favor, tente novamente.');
    }
  };

  const mountIndicatorColumn = (indicatorColumn) =>
    indicatorColumn.map((indicator, i) => (
      <>
        {i !== 0 && <hr />}
        <CardIndicator
          isLoading={indicator.isLoading}
          title={indicator.title}
          subtitle={indicator.subtitle}
          valueIndicator={indicator.valueIndicator}
          onClick={indicator.onClick}
        />
      </>
    ));

  const onChangeFilters = handleOnChange(setFilters);

  const openModal = (category, subcategory) => {
    handleData('MODAL', true, category, subcategory);
  };

  const closeModal = (category) => {
    handleData('MODAL', false, category);
  };

  const closeModalsOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeModal('students');
      closeModal('contracts');
      closeModal('financials');
    }
  };

  const exportData = (category) => {
    handleData('EXPORT', null, category);
  };

  const handleData = (type, show, category, subcategory) => {
    switch (category) {
      case 'students':
        if (type === 'MODAL') handleStudentsData(show, subcategory);
        else if (type === 'EXPORT') exportStudentData(studentsModalData.filename, studentsModalData.data);
        break;

      case 'contracts':
        if (type === 'MODAL') handleContractsData(show, subcategory);
        else if (type === 'EXPORT') exportContractData(contractsModalData.filename, contractsModalData.data);
        break;

      case 'financials':
        if (type === 'MODAL') handleFinancialsData(show, subcategory);
        else if (type === 'EXPORT') exportFinancialData(financesModalData.filename, financesModalData.data);
        break;
    }
  };

  const handleStudentsData = (show, category) => {
    if (!show) {
      setStudentsModalData(modalDataDefault);
    } else {
      switch (category) {
        case 'totalStudents':
          setStudentsModalData({
            isShow: true,
            title: t('ClubDashboardPage.TotalStudents'),
            filename: t('ClubDashboardPage.TotalStudentsFilename'),
            data: totalStudents,
          });
          break;

        case 'activeStudents':
          setStudentsModalData({
            isShow: true,
            title: t('ClubDashboardPage.ActiveStudents'),
            filename: t('ClubDashboardPage.ActiveStudentsFilename'),
            data: activeStudents,
          });
          break;

        case 'inactiveStudents':
          setStudentsModalData({
            isShow: true,
            title: t('ClubDashboardPage.InactiveStudents'),
            filename: t('ClubDashboardPage.InactiveStudentsFilename'),
            data: inactiveStudents,
          });
          break;

        case 'noDefaultingStudents':
          setStudentsModalData({
            isShow: true,
            title: t('ClubDashboardPage.NoDefaultingStudents'),
            filename: t('ClubDashboardPage.NoDefaultingStudentsFilename'),
            data: noDefaultingStudents,
          });
          break;

        case 'defaultingStudents':
          setStudentsModalData({
            isShow: true,
            title: t('ClubDashboardPage.DefaultingStudents'),
            filename: t('ClubDashboardPage.DefaultingStudentsFilename'),
            data: defaultingStudents,
          });
          break;
      }
    }
  };

  const handleContractsData = (show, category) => {
    if (!show) {
      setContractsModalData(modalDataDefault);
    } else {
      switch (category) {
        case 'monthlyPlans':
          setContractsModalData({
            isShow: true,
            title: t('ClubDashboardPage.MonthlyPlans'),
            filename: t('ClubDashboardPage.MonthlyPlansFilename'),
            data: monthlyPlans,
          });
          break;

        case 'quarterlyPlans':
          setContractsModalData({
            isShow: true,
            title: t('ClubDashboardPage.QuarterlyPlans'),
            filename: t('ClubDashboardPage.QuarterlyPlansFilename'),
            data: quarterlyPlans,
          });
          break;

        case 'annualPlans':
          setContractsModalData({
            isShow: true,
            title: t('ClubDashboardPage.AnnualPlans'),
            filename: t('ClubDashboardPage.AnnualPlansFilename'),
            data: annualPlans,
          });
          break;

        case 'plansToExpire':
          setContractsModalData({
            isShow: true,
            title: t('ClubDashboardPage.PlansToExpire'),
            filename: t('ClubDashboardPage.PlansToExpireFilename'),
            data: plansToExpire,
          });
          break;

        case 'expiredPlans':
          setContractsModalData({
            isShow: true,
            title: t('ClubDashboardPage.ExpiredPlans'),
            filename: t('ClubDashboardPage.ExpiredPlansFilename'),
            data: expiredPlans,
          });
          break;
      }
    }
  };

  const handleFinancialsData = (show, category) => {
    if (!show) {
      setFinancesModalData(modalDataDefault);
    } else {
      switch (category) {
        case 'grossIncome':
          setFinancesModalData({
            isShow: true,
            title: t('ClubDashboardPage.GrossIncome'),
            filename: t('ClubDashboardPage.GrossIncomeFilename'),
            data: grossIncome,
          });
          break;

        case 'tuitionsIncome':
          setFinancesModalData({
            isShow: true,
            title: t('ClubDashboardPage.TuitionsIncome'),
            filename: t('ClubDashboardPage.TuitionsIncomeFilename'),
            data: tuitionsIncome,
          });
          break;

        case 'incomeWithOrders':
          setFinancesModalData({
            isShow: true,
            title: t('ClubDashboardPage.IncomeWithOrders'),
            filename: t('ClubDashboardPage.IncomeWithOrdersFilename'),
            data: incomeWithOrders,
          });
          break;

        // case 'incomeWithUniforms':
        //   setFinancesModalData({
        //     isShow: true,
        //     title: t('ClubDashboardPage.IncomeWithUniforms'),
        //     filename: t('ClubDashboardPage.IncomeWithUniformsFilename'),
        //     data: incomeWithUniforms,
        //   });
        //   break;

        // case 'incomeWithEvents':
        //   setFinancesModalData({
        //     isShow: true,
        //     title: t('ClubDashboardPage.IncomeWithEvents'),
        //     filename: t('ClubDashboardPage.IncomeWithEventsFilename'),
        //     data: incomeWithEvents,
        //   });
        //   break;

        // case 'tuitionsFee':
        //   setFinancesModalData({
        //     isShow: true,
        //     title: t('ClubDashboardPage.TuitionsFee'),
        //     filename: t('ClubDashboardPage.TuitionsFeeFilename'),
        //     data: tuitionsFee,
        //   });
        //   break;
      }
    }
  };

  const exportStudentData = (filename, student) => {
    const studentMapped = student.map((s) => ({
      [t('StudentName')]: s.name,
      [t('ResponsiblePhoneNumber')]: cellPhoneFormatI18n(s.athlete?.athPhoneRespFin || '', i18n.language),
      [t('School')]: s.school.schFantasia,
    }));
    exportToXLS(filename, studentMapped);
  };

  const exportContractData = (filename, contract) => {
    const contractMapped = contract.map((c) => ({
      [t('StudentName')]: c.student.name,
      [t('InstallmentAmount')]: c.value,
      [t('ContractStart')]: c.contractDateStart ? dateFormatI18n(c.contractDateStart, i18n.language) : '',
      [t('ContractEnd')]: c.dateEnd ? dateFormatI18n(c.dateEnd, i18n.language) : '',
      [t('FinancialResponsible')]: c.student.athlete?.athNomeRespFin,
      [t('ResponsiblePhoneNumber')]: cellPhoneFormatI18n(c.student.athlete?.athPhoneRespFin || '', i18n.language),
      [t('BillingCycle')]: t(capitalize(c.cyclo)),
      [t('School')]: c.school.schFantasia,
    }));
    exportToXLS(filename, contractMapped);
  };

  const exportFinancialData = (filename, financial) => {
    const financialMapped = financial.map((f) => ({
      [t('StudentName')]: f.user_student?.name || '--',
      [t('InstallmentAmount')]: f.type === 'Receita' ? f.valueCalculated : f.valueCalculated * -1,
      [t('InstallmentDue')]: dateFormatI18n(f.dueDate, i18n.language),
      [`${t('Customer')}/${t('Provider')}`]: f.user_student?.athlete?.athNomeRespFin || f.client_fornecedor?.name,
      [t('ResponsiblePhoneNumber')]: cellPhoneFormatI18n(
        f.user_student?.athlete?.athPhoneRespFin || f.client_fornecedor?.phone || '',
        i18n.language
      ),
      [t('School')]: f.user.school.schFantasia,
    }));
    exportToXLS(filename, financialMapped);
  };

  const getSchools = async () => {
    const baseURL = Api();
    const res = await axios.post(baseURL + 'get-schools-club', {
      companie_id: companyId,
    });

    setSchools(res.data.schools);
  }

  const changeSchool = (schoolId) => {

    const today = moment().format('YYYY-MM-DD');
    const limitDay = moment().add(30, 'days').format('YYYY-MM-DD');

    if(schoolId == ""){
      setTotalStudents(students);
      setActiveStudents(students.filter(({ usuStatus }) => usuStatus === 'A'));
      setInactiveStudents(students.filter(({ usuStatus }) => usuStatus === 'I'));
      setNoDefaultingStudents(students.filter(({ isDefaulting }) => !isDefaulting));
      setDefaultingStudents(students.filter(({ isDefaulting }) => isDefaulting));
      setActiveFemaleStudents(students.filter(({ usuGender }) => usuGender === 'F'));
      setActiveMaleStudents(students.filter(({ usuGender }) => usuGender === 'M'));

      setMonthlyPlans(contracts.filter(({ cyclo }) => cyclo === 'MONTHLY'));
      setQuarterlyPlans(contracts.filter(({ cyclo }) => cyclo === 'QUARTERLY'));
      setAnnualPlans(contracts.filter(({ cyclo }) => cyclo === 'YEARLY'));
      setPlansToExpire(contracts.filter(({ dateEnd }) => !!dateEnd && today <= dateEnd && dateEnd <= limitDay));
      setExpiredPlans(contracts.filter(({ dateEnd }) => !!dateEnd && dateEnd < today));

      setGrossIncome(financials);
      setTuitionsIncome(financials.filter(({ contract_id }) => !!contract_id));
      setIncomeWithOrders(financials.filter(({ order_id }) => !!order_id));
    }else{
      setTotalStudents(students.filter(({ school_id }) => school_id == schoolId));
      setActiveStudents(students.filter(({ usuStatus, school_id }) => usuStatus === 'A' && school_id == schoolId));
      setInactiveStudents(students.filter(({ usuStatus, school_id }) => usuStatus === 'I' && school_id == schoolId));
      setNoDefaultingStudents(students.filter(({ isDefaulting, school_id }) => !isDefaulting && school_id == schoolId));
      setDefaultingStudents(students.filter(({ isDefaulting, school_id }) => isDefaulting && school_id == schoolId));
      setActiveFemaleStudents(students.filter(({ usuGender, school_id }) => usuGender === 'F' && school_id == schoolId));
      setActiveMaleStudents(students.filter(({ usuGender, school_id }) => usuGender === 'M' && school_id == schoolId));

      setMonthlyPlans(contracts.filter(({ cyclo, school_id }) => cyclo === 'MONTHLY' && school_id == schoolId));
      setQuarterlyPlans(contracts.filter(({ cyclo, school_id }) => cyclo === 'QUARTERLY' && school_id == schoolId));
      setAnnualPlans(contracts.filter(({ cyclo, school_id }) => cyclo === 'YEARLY' && school_id == schoolId));
      setPlansToExpire(contracts.filter(({ dateEnd, school_id }) => !!dateEnd && today <= dateEnd && dateEnd <= limitDay && school_id == schoolId));
      setExpiredPlans(contracts.filter(({ dateEnd, school_id }) => !!dateEnd && dateEnd < today && school_id == schoolId));

      setGrossIncome(financials.filter(({ school_id }) => school_id == schoolId));
      setTuitionsIncome(financials.filter(({ contract_id, school_id }) => !!contract_id && school_id == schoolId));
      setIncomeWithOrders(financials.filter(({ order_id, school_id }) => !!order_id && school_id == schoolId));
    }
  }

  useEffect( () => {
    getSchools();
  }, []);

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <form onSubmit={fetchReports} style={{ alignItems: 'center' }}>
              <div
                className='form_card row'
                style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}
              >
                <div className='d-flex flex-column pb-3'>
                  <label htmlFor='title' className='card_details_label'>
                    Nome da escola
                  </label>
                  <select
                    required
                    // value={companyType}
                    className='form_control select'
                    onChange={(e) => changeSchool(e.target.value)}
                  >
                    <option value="">Todas</option>
                    {schools.map((school) => (
                      <option key={school.id} value={school.id}>
                        {school.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='mx-2' style={{ width: '415px' }}>
                    <DateIntervalRounded
                      className={"form-control"}
                      styleWrapper={{ height: '26px' }}
                      type='month'
                      startDateName='filterStartDate'
                      startDateValue={filters}
                      startDateOnChange={onChangeFilters}
                      endDateName='filterEndDate'
                      endDateValue={filters}
                      endDateOnChange={onChangeFilters}
                    />
                  </div>
                  <button
                    type='button'
                    className='btn_card m-0 p-0'
                    style={{ minWidth: 'auto', width: '26px', height: '26px' }}
                    onClick={searchByFilter}
                    title='Filtrar o faturamento pela data de vencimento'
                  >
                    <FaSearch className='m-0 p-0' style={{ height: 'auto', width: 'auto' }} />
                  </button>
                </div>
              </div>
            </form>

            {errorStudents && <MessageDanger title='Atenção! ' description={errorStudents} />}
            {errorContracts && <MessageDanger title='Atenção! ' description={errorContracts} />}
            {errorFinancials && <MessageDanger title='Atenção! ' description={errorFinancials} />}

            <main className='content_panel' style={{ display: 'unset' }}>
              <div className='indicators-wrapper'>
                <div className='card indicators-column'>
                  {isLoadingStudentsData && (
                    <Loading type={6} style={{ alignItems: 'flex-start', marginTop: '115px' }} />
                  )}
                  {mountIndicatorColumn(indicatorColumnA)}
                </div>
                <div className='card indicators-column'>
                  {isLoadingContractsData && (
                    <Loading type={6} style={{ alignItems: 'flex-start', marginTop: '115px' }} />
                  )}
                  {mountIndicatorColumn(indicatorColumnB)}
                </div>
                <div className='card indicators-column'>
                  {isLoadingFinancialsData && (
                    <Loading type={6} style={{ alignItems: 'flex-start', marginTop: '115px' }} />
                  )}
                  {mountIndicatorColumn(indicatorColumnC)}
                </div>
              </div>
            </main>

            <ModalSimpleTable
              isShow={studentsModalData.isShow}
              handleCloseModal={() => closeModal('students')}
              title={studentsModalData.title}
              onExport={() => exportData('students')}
              columns={[
                { label: t('StudentName'), getter: ({ name }) => name },
                {
                  label: t('ResponsiblePhoneNumber'),
                  getter: ({ athlete }) => cellPhoneFormatI18n(athlete?.athPhoneRespFin || '', i18n.language),
                },
                { label: t('School'), getter: ({ school }) => school.schFantasia },
              ]}
              data={studentsModalData.data}
            />

            <ModalSimpleTable
              isShow={contractsModalData.isShow}
              handleCloseModal={() => closeModal('contracts')}
              title={contractsModalData.title}
              onExport={() => exportData('contracts')}
              columns={[
                { label: t('StudentName'), getter: ({ student }) => student.name },
                {
                  label: t('InstallmentAmount'),
                  getter: ({ value }) => t('FormatFunction.intlCurrencyWithOptions', { value: value }),
                },
                {
                  label: t('ContractStart'),
                  width: '90px',
                  getter: ({ contractDateStart }) =>
                    contractDateStart ? dateFormatI18n(contractDateStart, i18n.language) : '',
                },
                {
                  label: t('ContractEnd'),
                  width: '90px',
                  getter: ({ dateEnd }) => (dateEnd ? dateFormatI18n(dateEnd, i18n.language) : ''),
                },
                { label: t('FinancialResponsible'), getter: ({ student }) => student.athlete?.athNomeRespFin },
                {
                  label: t('ResponsiblePhoneNumber'),
                  width: '115px',
                  getter: ({ student }) => cellPhoneFormatI18n(student.athlete?.athPhoneRespFin || '', i18n.language),
                },
                { label: t('BillingCycle'), width: '80px', getter: ({ cyclo }) => t(capitalize(cyclo)) },
                { label: t('School'), getter: ({ school }) => school.schFantasia },
              ]}
              data={contractsModalData.data}
            />

            <ModalSimpleTable
              isShow={financesModalData.isShow}
              handleCloseModal={() => closeModal('financials')}
              title={financesModalData.title}
              onExport={() => exportData('financials')}
              columns={[
                { label: t('StudentName'), getter: ({ user_student }) => user_student?.name || '--' },
                {
                  label: t('InstallmentAmount'),
                  getter: ({ type, valueCalculated }) =>
                    formatCurrencyWithSignal(
                      type === 'Receita',
                      t('FormatFunction.intlCurrencyWithOptions', { value: valueCalculated })
                    ),
                },
                {
                  label: t('InstallmentDue'),
                  width: '90px',
                  getter: ({ dueDate }) => dateFormatI18n(dueDate, i18n.language),
                },
                {
                  label: `${t('Customer')}/${t('Provider')}`,
                  getter: ({ user_student, client_fornecedor }) =>
                    user_student?.athlete?.athNomeRespFin || client_fornecedor?.name,
                },
                {
                  label: t('ResponsiblePhoneNumber'),
                  width: '115px',
                  getter: ({ user_student, client_fornecedor }) =>
                    cellPhoneFormatI18n(
                      user_student?.athlete?.athPhoneRespFin || client_fornecedor?.phone || '',
                      i18n.language
                    ),
                },
                { label: t('School'), getter: ({ user }) => user.school.schFantasia },
              ]}
              data={financesModalData.data}
            />

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Dashboard;
