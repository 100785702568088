import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask';
import Header from '../../components/athlete/Header'
import Footer from '../../components/athlete/Footer'
import Loading from '../../components/Loading'
import Message from '../../components/Message'
import utils from '../../utils/utils'
import Api from '../../utils/api'
import axios from 'axios'
import { Redirect } from 'react-router-dom';

const Curriculo = () => {
   const { accordion, openCity, dateFormat } = utils()
   const { MessageDanger, MessageSuccess } = Message()
   const baseURL = Api()
   const [user, setUser] = useState({})
   const [athlete, setAthlete] = useState({})
   const [docs, setDocs] = useState([])

   // messages e error
   const [success, setSuccess] = useState(false)
   const [error, setError] = useState(false)
   const [message, setMessage] = useState("")
   const [errorUpload, setErrorUpload] = useState(false)
   const [successUpload, setSuccessUpload] = useState(false)

   // processos de loading
   const [sendTeam, setSendingTeam] = useState(false)
   const [sendState, setSendingState] = useState(false)
   const [sendCity, setSendingCity] = useState(false)
   const [sendCarreiras, setSendingCarreiras] = useState(false)
   const [sendPosition, setSendingPosition] = useState(false)
   const [sendSave, setSendingSave] = useState(false)
   const [sendCep, setSendingCep] = useState(false)
   const [sendDoc, setSendingDoc] = useState(false)

   // carregamentos
   const [teams, setTeams] = useState([])
   const [states, setStates] = useState([])
   const [citys, setCitys] = useState([])
   const [positions, setPositions] = useState([])

   // IDs
   const [user_id, setUserID] = useState(user.id)
   const [role_id, setRoleID] = useState(user.role_id)
   const [companie_id, setCompanieID] = useState(user.companie_id)
   const [athlete_id, setAthleteID] = useState(athlete.id)

   // aba 1
   const [athApelido, setAthApelido] = useState(athlete.athApelido)
   const [name, setName] = useState(user.name)
   const [usuBirth, setUsuBirth] = useState(user.usuBirth)
   const [athNacionalidade, setAthNacionalidade] = useState(athlete.athNacionalidade)
   const [athSexo, setAthSexo] = useState(user.usuGender)
   const [usuCPF, setUsuCPF] = useState(user.usuCPF)
   const [athAltura, setAthAltura] = useState(athlete.athAltura)
   const [athPeso, setAthPeso] = useState(athlete.athPeso)
   const [athNumeroBid, setAthNumeroBid] = useState(athlete.athNumeroBid)
   const [athLateralidade, setAthLateralidade] = useState(athlete.athLateralidade)
   const [position_id, setPositionID] = useState(athlete.position_id)
   const [position_id_alternativa, setPositionAlterID] = useState(athlete.position_id_alternativa)
   const [athChuteira, setAthChuteira] = useState(athlete.athChuteira)
   const [athCamisa, setAthCamisa] = useState(athlete.athCamisa)
   const [team_id, setTeamID] = useState("")
   const [teamDescricao, setTeamDescricao] = useState("")
   const [attInicioContrato, setAttInicioContrato] = useState("")
   const [attTerminoContrato, setAttTerminoContrato] = useState("")
   // aba 2
   const [athPerfilInstagran, setAthInstagram] = useState(athlete.athPerfilInstagran)
   const [athPerfilFacebook, setAthFacebook] = useState(athlete.athPerfilFacebook)
   const [athCanalYoutube, setAthYoutube] = useState(athlete.athCanalYoutube)
   const [athTwitter, setAthTwitter] = useState(athlete.athTwitter)
   const [athCep, setAthCep] = useState(athlete.athCep)
   const [athLogradouro, setAthLogradouro] = useState(athlete.athLogradouro)
   const [athNumero, setAthNumero] = useState(athlete.athNumero)
   const [athBairro, setAthBairro] = useState(athlete.athBairro)
   const [athComplemento, setAthComplemento] = useState(athlete.athComplemento || '')
   const [staStateSigla, setStaStateSigla] = useState(athlete.staState)
   const [city_id, setCityID] = useState(athlete.city_id)
   const [athPaiNome, setPaiNome] = useState(athlete.athPaiNome)
   const [athPaiCPF, setPaiCPF] = useState(athlete.athPaiCPF)
   const [athPaiTelefone, setPaiTelefone] = useState(athlete.athPaiTelefone)
   const [athIntermediario, setIntermediario] = useState(athlete.athIntermediario)
   const [athIntermediarioCPF, setIntermediarioCPF] = useState(athlete.athIntermediarioCPF)
   const [athIntermediarioTelefone, setIntermediarioTelefone] = useState(athlete.athIntermediarioTelefone)
   const [carreiras, setCarreira] = useState([])
   
   useEffect(()=>{

      const user_aux = JSON.parse(localStorage.getItem('user'))
      setUser(user_aux)
      setAthlete(JSON.parse(localStorage.getItem('athlete')))
      setDocs(JSON.parse(localStorage.getItem('docs')))
      
      getTeams()
      getStates()
      getPositions()
      getCitys(staStateSigla, '')
      getCarreiras()
   },[])

   const getTeams = async () => {
      setSendingTeam(true)
      const res = await axios.get(baseURL + 'get-teams')
      setSendingTeam(false)
      setTeams(res.data.teams)
   }

   const getStates = async () => {
      setCitys([])
      setSendingState(true)
      const res = await axios.get(baseURL + 'get-states')
      setSendingState(false)
      setStates(res.data.states)
   }

   const getCitys = async (staStateSigla, localidade = '') => {
      setCitys([])
      setStaStateSigla(staStateSigla)
      setSendingCity(true)
      const res = await axios.post(baseURL + 'get-citys-by-state-sigla',{
         staStateSigla: staStateSigla
      })
      setSendingCity(false)

      for (let i = 0; i < res.data.cidades.length; i++) {
         let citCity = res.data.cidades[i].citCity
         if ( citCity == localidade  ){
            setCityID(res.data.cidades[i].id)
         }
      }

      setCitys(res.data.cidades)
   }

   const getCarreiras = async () => {
      setSendingCarreiras(true)
      const res = await axios.post(baseURL + 'get-carreiras', {
         athlete_id
      })
      setSendingCarreiras(false)
      setCarreira(res.data.carreiras)
   }

   const getPositions = async () => {
      setPositions([])
      setSendingPosition(true)
      const res = await axios.get(baseURL + 'get-positions')
      setSendingPosition(false)
      setPositions(res.data.positions)
   }

   const addClube = (e) => {
      e.preventDefault()
      
      if ( team_id != undefined && attInicioContrato != undefined &&  attTerminoContrato != undefined )
      {
         const data = {
            team: {
               teaTeam: teamDescricao
            },
            team_id: team_id,
            attInicioContrato: attInicioContrato,
            attTerminoContrato: attTerminoContrato
         }

         const newCarreira = [...carreiras]
         newCarreira.push(data)
         setCarreira(newCarreira)

         setTeamID("")
         setTeamDescricao("")
         setAttInicioContrato("")
         setAttTerminoContrato("")
      }
   }

   const setTeam  = (target) => {
      setTeamID(target.value)

      let comboTeam = document.getElementById("team_id")
      setTeamDescricao(comboTeam.options[comboTeam.selectedIndex].text)
   }

   const buscaCEP = async () => {

      const cep = athCep.replace('-','')
      .replace('_','')
      .replace('_','')
      .replace('_','')
      .replace('_','')
      .replace('_','')
      .replace('_','')
      .replace('_','')
      .replace('_','')

      if ( cep.length == 8 ) {
         setAthLogradouro("")
         setAthBairro("")
         setAthComplemento("")
         setStaStateSigla("")
         setCityID("")

         setSendingCep(true)
         const res = await axios.get('https://viacep.com.br/ws/' + cep + '/json/')
         setSendingCep(false)
         
         if ( res.data.erro ) {
            setAthLogradouro("")
            setAthBairro("")
            setAthComplemento("")
            setStaStateSigla("")
            setCityID("")
         } else {
            setAthLogradouro(res.data.logradouro)
            setAthBairro(res.data.bairro)
            setAthComplemento(res.data.complemento)
            setStaStateSigla(res.data.uf)
            getCitys(res.data.uf, res.data.localidade.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
         }
      }
   }

   const handleSubmit = async (evt) => {
      evt.preventDefault()
      setMessage("")

      const cep = athCep !== undefined ? athCep.replace('-','')
      .replace('_','')
      .replace('_','')
      .replace('_','')
      .replace('_','')
      .replace('_','')
      .replace('_','')
      .replace('_','')
      .replace('_','') : ''

      const data = {
         athlete_id: athlete_id,
         user_id: user_id,
         role_id: role_id,
         companie_id: companie_id,
         athApelido: athApelido,
         name: name,
         usuBirth: usuBirth,
         athNacionalidade: athNacionalidade,
         athSexo: athSexo,
         usuCPF: usuCPF,
         athAltura: athAltura,
         athPeso: athPeso,
         athNumeroBid: athNumeroBid,
         athLateralidade: athLateralidade,
         position_id: position_id,
         position_id_alternativa: position_id_alternativa,
         athChuteira: athChuteira,
         athCamisa: athCamisa,
         team_id: team_id,
         athPerfilInstagran: athPerfilInstagran,
         athPerfilFacebook: athPerfilFacebook,
         athCanalYoutube: athCanalYoutube,
         athTwitter: athTwitter,
         athCep: cep,
         athLogradouro: athLogradouro,
         athNumero: athNumero,
         athBairro: athBairro,
         athComplemento: athComplemento,
         city_id: city_id,
         athPaiNome: athPaiNome,
         athPaiCPF: athPaiCPF,
         athPaiTelefone: athPaiTelefone,
         athIntermediario: athIntermediario,
         athIntermediarioCPF: athIntermediarioCPF,
         athIntermediarioTelefone: athIntermediarioTelefone,
         carreiras: carreiras
      }
      
      setSendingSave(true)
      const res = await axios.post(baseURL + 'athlete-save', data)
      setSendingSave(false)

      if (res.data.success == true) {
         setError(false)
         setSuccess(true)
         setMessage("Dados atualizados.")

         user.name = name
         user.usuBirth = usuBirth
         user.usuCPF = usuCPF.replace('.','').replace('.','').replace('-','')
         localStorage.setItem('user', JSON.stringify(user))
         setUser(user)

         athlete.athApelido = athApelido
         athlete.athNacionalidade = athNacionalidade
         athlete.athSexo = athSexo
         athlete.athAltura = athAltura
         athlete.athPeso = athPeso
         athlete.athNumeroBid = athNumeroBid
         athlete.athLateralidade = athLateralidade
         athlete.position_id = position_id
         athlete.city_id = city_id
         athlete.staState = staStateSigla
         athlete.position_id_alternativa = position_id_alternativa
         athlete.athChuteira = athChuteira
         athlete.athCamisa = athCamisa
         athlete.athPerfilInstagran = athPerfilInstagran
         athlete.athPerfilFacebook = athPerfilFacebook
         athlete.athCanalYoutube = athCanalYoutube
         athlete.athTwitter = athTwitter
         athlete.athCep = cep
         athlete.athLogradouro = athLogradouro
         athlete.athNumero = athNumero
         athlete.athBairro = athBairro
         athlete.athComplemento = athComplemento
         athlete.athPaiNome = athPaiNome
         athlete.athPaiCPF = athPaiCPF
         athlete.athPaiTelefone = athPaiTelefone
         athlete.athIntermediario = athIntermediario
         athlete.athIntermediarioCPF = athIntermediarioCPF
         athlete.athIntermediarioTelefone = athIntermediarioTelefone
         localStorage.setItem('athlete', JSON.stringify(athlete))
         setAthlete(athlete)

         document.getElementById('aba2').click();
      } else {

         setSuccess(false)
         setError(true)

         let prop = ''
         let msgs = ''
         for(prop in res.data.erro) {
            if (res.data.erro.hasOwnProperty(prop)) {
               msgs += res.data.erro[prop][0]
            }
         }

         setMessage(msgs)
      }

      window.scrollTo(0, 0);
   }

   const removeClub = (item) => {
      const result = carreiras.filter(e => e.team_id !== item.team_id)
      setCarreira(result)
   }

   const handleFile = async (e, documennt_id, athletedocument_id) => {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('documento', file)
      formData.append('document_id', documennt_id)
      formData.append('athletedocument_id', athletedocument_id)
      formData.append('id', athlete_id)
      
      setSendingDoc(true)
      const res = await axios.post(baseURL + 'upload-document-athlete', formData, {headers: { 'Content-Type': 'multipart/form-data'}})
      setSendingDoc(false)

      if ( res.data.success === false ) {
         setErrorUpload(true)
         setSuccessUpload(false)

         let prop = ''
         let msgs = ''
         for(prop in res.data.erro) {
            if (res.data.erro.hasOwnProperty(prop)) {
               msgs += res.data.erro[prop][0]
            }
         }

         setMessage(msgs)
      } else {
         setSuccessUpload(true)
         setMessage(res.data.msg)
         setDocs(res.data.docs)
         localStorage.setItem('docs', JSON.stringify(res.data.docs))
      }     
                        
      window.scrollTo(0, 0); 
   }

   return (
      <React.Fragment>
         
         <Header type="1" menu={true} />

         <main>

            <section className="bloco-principal">
               <div className="conteudo-bloco-principal">

                  <div className="bloco-1 cor-background">
                     <div className="curriculo">
                        <div className="aba-curriculo">
                           <button className="tablink w3-red" onClick={e=>{openCity(e,'aba-1')}}>Seu currículo</button>
                           <button id="aba2" className="tablink"  onClick={e=>{openCity(e,'aba-2')}}>Dados adicionais</button>
                        </div>
                        <form className="content-curriculo" onSubmit={handleSubmit} action="" method="">
                           <div id="aba-1" className="informacoes-curriculo city">
                              {
                                 error && <MessageDanger title="Ops! " description={message} />
                              }
                              <div className="coluna-curriculo">
                                 <label>Apelido ou Nome da camisa</label>
                                 <input required type="text" name="athApelido" value={athApelido} onChange={e => setAthApelido(e.target.value)} />
                              </div>

                              <div className="coluna-curriculo">
                                 <label>Nome completo</label>
                                 <input required type="text" name="name" value={name}  onChange={e => setName(e.target.value)} />
                              </div>

                              <div className="coluna-1-curriculo">
                                 <div className="sub-coluna-curriculo">
                                    <div className="info-curriculo">
                                       <label>Data de nascimento</label>
                                       <input required type="date" name="usuBirth" value={usuBirth}  onChange={e => setUsuBirth(e.target.value)}/>
                                    </div>

                                    <div className="info-curriculo">
                                       <label>Nacionalidade</label>
                                       <input required type="text" value="Brasileiro" value={athNacionalidade} name="athNacionalidade"  onChange={e => setAthNacionalidade(e.target.value)}/>
                                    </div>

                                    <div className="info-curriculo">
                                       <label>Gênero</label>
                                       <select required name="athSexo" value={athSexo}  onChange={e => setAthSexo(e.target.value)}>
                                          <option value="">-- Selecione --</option>
                                          <option value="M">Masculino</option>
                                          <option value="F">Feminino</option>
                                       </select>
                                    </div>

                                    <div className="info-curriculo">
                                       <label>CPF</label>
                                       <InputMask value={usuCPF} required mask="999.999.999-99" name="usuCPF" type="tel" disableunderline="true" onChange={e => setUsuCPF(e.target.value)} ></InputMask>
                                    </div>

                                    <div className="info-curriculo">
                                       <label>Altura</label>
                                       <InputMask value={athAltura} required mask="9.99" type="tel" disableunderline="true" name="athAltura" onChange={e => setAthAltura(e.target.value)}></InputMask>
                                    </div>

                                    <div className="info-curriculo">
                                       <label>Peso</label>
                                       <InputMask value={athPeso} required mask="99.9" type="tel" disableunderline="true" name="athPeso" onChange={e => setAthPeso(e.target.value)}></InputMask>
                                    </div>

                                    <div className="info-curriculo">
                                       <label>Número bid</label>
                                       <input value={athNumeroBid} type="text" name="athNumeroBid" onChange={e => setAthNumeroBid(e.target.value)}/>
                                    </div>

                                    <div className="info-curriculo">
                                       <label>Lateralidade</label>
                                       <select required name="athLateralidade" value={athLateralidade}  onChange={e => setAthLateralidade(e.target.value)}>
                                          <option value="">-- Selecione --</option>
                                          <option value="D">Destro</option>
                                          <option value="C">Canhoto</option>
                                          <option value="A">Ambidestro</option>
                                       </select>
                                    </div>
                                    

                                    <div className="info-curriculo">
                                       <label>
                                          Posição preferencial &nbsp;
                                          {
                                             sendPosition && 
                                             <Loading type="1" />
                                          }
                                       </label>
                                       {
                                          !sendPosition && 
                                          <select required name="position_id" value={position_id}  onChange={e => setPositionID(e.target.value)}>
                                             <option value="">-- Selecione --</option>
                                             {
                                                positions.map(item => <option value={item.id} key={item.id}>{item.posPosition}</option>)
                                             }
                                          </select>
                                       }

                                    </div>

                                    <div className="info-curriculo">
                                       <label>
                                          Posição alternativa &nbsp;
                                          {
                                             sendPosition && 
                                             <Loading type="1" />
                                          }
                                       </label>
                                       {
                                          !sendPosition && 
                                          <select name="position_id_alternativa" value={position_id_alternativa} onChange={e => setPositionAlterID(e.target.value)}>
                                             <option value="">-- Selecione --</option>
                                             {
                                                positions.map(item => <option value={item.id} key={item.id}>{item.posPosition}</option>)
                                             }
                                          </select>
                                       }
                                    </div>

                                    <div className="info-curriculo">
                                       <label>Chuteira</label>
                                       <select name="athChuteira" value={athChuteira} onChange={e => setAthChuteira(e.target.value)}>
                                          <option value="">-- Selecione --</option>
                                          <option value="32">32</option>
                                          <option value="33">33</option>
                                          <option value="34">34</option>
                                          <option value="35">35</option>
                                          <option value="36">36</option>
                                          <option value="37">37</option>
                                          <option value="38">38</option>
                                          <option value="39">39</option>
                                          <option value="40">40</option>
                                          <option value="41">41</option>
                                          <option value="42">42</option>
                                          <option value="43">43</option>
                                          <option value="44">44</option>
                                       </select>
                                    </div>

                                    <div className="info-curriculo">
                                       <label>Camisa (P,M,G)</label>
                                       <select name="athCamisa" value={athCamisa}  onChange={e => setAthCamisa(e.target.value)}>
                                          <option value="">-- Selecione --</option>
                                          <option value="P">Pequena</option>
                                          <option value="M">Média</option>
                                          <option value="G">Grande</option>
                                       </select>
                                    </div>
                                 </div>
                              </div>

                              <div className="coluna-1-curriculo">
                                 <p>Sua carreira</p>

                                 <div className="sub-coluna-curriculo">
                                    <div className="info-curriculo">
                                       <label>
                                          Clube &nbsp;
                                          {
                                             sendTeam && 
                                             <Loading type="1" />
                                          }
                                       </label>
                                       {
                                          !sendTeam && 
                                          <select name="team_id" id="team_id" value={team_id} onChange={e => setTeam(e.target)}>
                                             <option value="">-- Selecione --</option>
                                             {
                                                teams.map(item => <option value={item.id} key={item.id}>{item.teaTeam}</option>)
                                             }
                                          </select>
                                       }
                                    </div>
                                    <div className="info-1-curriculo">
                                       <div className="mini-info-curriculo">
                                          <label>Início do contrato</label>
                                          <input type="text" maxLength="4" placeholder="ano" name="attInicioContrato" value={attInicioContrato}  onChange={e => setAttInicioContrato(e.target.value)}/>
                                       </div>

                                       <div className="mini-info-curriculo">
                                          <label>Fim do contrato</label>
                                          <input type="text" maxLength="4" placeholder="ano" name="attTerminoContrato" value={attTerminoContrato} onChange={e => setAttTerminoContrato(e.target.value)}/>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="coluna-curriculo">
                                    <a href="#" onClick={addClube}><img src="../../img/icones/add.png" />Adicionar</a>
                                 </div>
                              </div>

                              <div className="coluna-1-curriculo" style={{padding: '2%'}}>
                                 {
                                    sendCarreiras &&
                                    <Loading type="3" />
                                 }
                                 {
                                    carreiras.length > 0 &&
                                    <table  style={{width: '100%', fontWeight: 100}}>
                                       <thead>
                                          <tr>
                                             <th style={{fontWeight: 300}}>Clube</th>
                                             <th style={{fontWeight: 300}}>Início do Contrato</th>
                                             <th style={{fontWeight: 300}}>Fim do Contrato</th>
                                             <th style={{fontWeight: 300}}></th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          {
                                             carreiras.map((e) => {
                                                return (<tr key={e.team_id}>
                                                         <td>{e.team.teaTeam}</td>
                                                         <td>{e.attInicioContrato}</td>
                                                         <td>{e.attTerminoContrato}</td>
                                                         <td><img className="cursor" onClick={() => removeClub(e)} style={{width: 20}} src="../../img/icones/delete.svg" /></td>
                                                      </tr>)
                                             })
                                          }
                                          
                                       </tbody>
                                    </table>
                                 }
                              </div>

                              <div className="botao-salvar-curriculo">
                                 {
                                    !sendSave &&
                                    <button type="submit">Salvar e continuar...</button>
                                 }
                                 {
                                    sendSave && <span>Salvando... <Loading type="1"/></span>
                                 }  
                              </div>
                           </div>
                        </form>

                        <form className="content-curriculo" onSubmit={handleSubmit} action="" method="">
                           <div id="aba-2" className="informacoes-curriculo city" style={{display: "none"}}>
                              {
                                 success && <MessageSuccess title="Sucesso! " description={message} />
                              }
                              <div className="coluna-1-curriculo">
                                 <p>Suas redes sociais</p>

                                 <div className="sub-coluna-curriculo">
                                    <div className="info-curriculo">
                                       <label>Instagram</label>
                                       <input type="text" name="athPerfilInstagran" value={athPerfilInstagran} onChange={e => setAthInstagram(e.target.value)} />
                                    </div>

                                    <div className="info-curriculo">
                                       <label>Facebook</label>
                                       <input type="text" name="athPerfilFacebook" value={athPerfilFacebook} onChange={e => setAthFacebook(e.target.value)} />
                                    </div>
                                    
                                    <div className="info-curriculo">
                                       <label>Youtube</label>
                                       <input type="text" name="athCanalYoutube" value={athCanalYoutube} onChange={e => setAthYoutube(e.target.value)} />
                                    </div>

                                    <div className="info-curriculo">
                                       <label>Twitter</label>
                                       <input type="text" name="athTwitter" value={athCanalYoutube}  onChange={e => setAthTwitter(e.target.value)} />
                                    </div>
                                 </div>
                              </div>

                              <div className="coluna-1-curriculo">
                                 <p>Seu endereço</p>

                                 <div className="sub-coluna-curriculo">
                                    <div className="info-curriculo">
                                       <label>CEP <a href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="blank">(Consultar CEP)</a></label>
                                       {
                                          sendCep && 
                                          <Loading type="1" />
                                       }
                                       {
                                          !sendCep &&
                                          <InputMask required value={athCep} mask="99999-999" name="athCep" type="tel" disableunderline="true" onBlur={buscaCEP} onChange={e => setAthCep(e.target.value)} ></InputMask>
                                       }
                                    </div>

                                    <div className="info-curriculo">
                                       <label>Endereço</label>
                                       <input required value={athLogradouro}  type="text" name="athLogradouro" onChange={e => setAthLogradouro(e.target.value)} />
                                    </div>

                                    <div className="info-2-curriculo">
                                       <div className="mini-info-curriculo-2">
                                          <label>Número</label>
                                          <input required value={athNumero}  type="tel" name="athNumero" onChange={e => setAthNumero(e.target.value)} />
                                       </div>

                                       <div className="mini-info-curriculo">
                                          <label>Bairro</label>
                                          <input required value={athBairro}  type="text" name="athBairro" onChange={e => setAthBairro(e.target.value)} />
                                       </div>

                                       <div className="mini-info-curriculo-2">
                                          <label>Complemento</label>
                                          <input value={athComplemento}  type="text" name="athComplemento" onChange={e => setAthComplemento(e.target.value)} />
                                       </div>
                                    </div>

                                    <div className="info-curriculo">
                                       <label>
                                          UF <span>(Unidade federal)</span>
                                          {
                                             sendState && 
                                             <Loading type="1" />
                                          }
                                       </label>
                                       {
                                          !sendState && 
                                          <select required value={staStateSigla} name="staStateSigla" onChange={e => getCitys(e.target.value, '')}>
                                             <option value="">-- Selecione --</option>
                                             {
                                                states.map(item => <option value={item.staStateSigla} key={item.staState}>{item.staState}</option>)
                                             }
                                          </select>
                                       }
                                    </div>

                                    <div className="info-curriculo">
                                       <label>
                                          Cidade
                                          {
                                             sendCity && 
                                             <Loading type="1" />
                                          }
                                       </label>
                                       {
                                          !sendCity && 
                                          <select required value={city_id}  name="city_id" onChange={e => setCityID(e.target.value)}>
                                             <option value="">-- Selecione --</option>
                                             {
                                                citys.map(item => <option value={item.id} key={item.citCity}>{item.citCity}</option>)
                                             }
                                          </select>
                                       }
                                    </div>
                                 </div>
                              </div>

                              <div className="coluna-1-curriculo">
                                 <p>Responsáveis</p>

                                 <div className="sub-coluna-curriculo">
                                    <div className="info-curriculo">
                                       <label>Nome do(a) responsável</label>
                                       <input required value={athPaiNome}  type="text" name="athPaiNome" onChange={e => setPaiNome(e.target.value)}/>
                                    </div>

                                    <div className="info-1-curriculo">
                                       <div className="mini-info-curriculo">
                                          <label>CPF</label>    
                                          <InputMask required value={athPaiCPF}  mask="999.999.999-99" name="athPaiCPF" type="tel" disableunderline="true" onChange={e => setPaiCPF(e.target.value)} ></InputMask>
                                       </div>

                                       <div className="mini-info-curriculo">
                                          <label>Celular</label>
                                          <InputMask required value={athPaiTelefone}  type="tel" mask="(99)99999-9999" name="athPaiTelefone" disableunderline="true" onChange={e => setPaiTelefone(e.target.value)} ></InputMask>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="sub-coluna-curriculo">
                                    <div className="info-curriculo">
                                       <label>Nome do(a) intermediário(a)</label>
                                       <input value={athIntermediario}  type="text" name="athIntermediario" onChange={e => setIntermediario(e.target.value)}/>
                                    </div>

                                    <div className="info-1-curriculo">
                                       <div className="mini-info-curriculo">
                                          <label>CPF</label>    
                                          <InputMask value={athIntermediarioCPF}  mask="999.999.999-99" name="athIntermediarioCPF" type="tel" disableunderline="true" onChange={e => setIntermediarioCPF(e.target.value)} ></InputMask>
                                       </div>

                                       <div className="mini-info-curriculo">
                                          <label>Celular</label>
                                          <InputMask value={athIntermediarioTelefone}  type="tel" mask="(99)99999-9999" name="athIntermediarioTelefone" disableunderline="true" onChange={e => setIntermediarioTelefone(e.target.value)} ></InputMask>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className="botao-salvar-curriculo">
                                 {
                                    !sendSave &&
                                    <button type="submit">Salvar</button>
                                 }
                                 {
                                    sendSave && <span>Salvando... <Loading type="1"/></span>
                                 }  
                              </div>
                           </div>
                        </form>	
                     </div>
                  </div>

                  <div className="bloco-2">
                     <div className="conteudo-generico">
                                 
                        {
                           errorUpload &&
                           <MessageDanger title="Atenção! " description={message} />
                        }

                        {
                           successUpload &&
                           <MessageSuccess title="Sucesso! " description={message} />
                        }

                        <form className="documento-curriculo">
                           <div className="coluna-documento-curriculo">
                              <h3>Documentos</h3>
                              <h4>Os documentos são importantes para avaliarmos sua condição.</h4>
                              <p>* A ausência dos documentos pode impedir sua inscrição nas seletivas.</p>
                           </div>
                           {
                              sendDoc &&
                              <Loading type="1" />
                           }
                           {
                              !sendDoc &&
                              docs.map(item => {
                                    if ( item.idDocumentAthlete == null )
                                       return <div className="coluna-documento-curriculo" key={"doc" + item.idDocument}>
                                                <h5>{item.docDocument}</h5>
                  
                                                <label className="carregar-arquivo">
                                                   Carregar arquivo...
                                                   <input type="file" name="file" onChange={e => handleFile(e, item.idDocument, '')}/>
                                                </label>
                                             </div>
                                    
                                    if ( item.idDocumentAthlete != null ) 
                                       return <div className="coluna-documento-curriculo" key={"doc" + item.idDocument}>
                                                <h5>{item.docDocument}</h5>
                                                
                                                <div className="ver-doc">
                                                   <a href={"http://hmg.labfut.com.br/upload/athletes/document/" + item.atdAthleteDocument} download={"http://hmg.labfut.com.br/upload/athletes/document/" + item.atdAthleteDocument} target="_blank">Ver o documento</a>
                                                </div>
                        
                                                <label className="atualizar-doc">
                                                   Atualizar documento
                                                   <input type="file" name="file[]" onChange={e => handleFile(e, item.idDocument, item.idDocumentAthlete)}/>
                                                </label>
                                             </div>
                              })
                           }
                        </form>

                     </div>
                  </div>
                  
               </div>
            </section>

         </main>

         <Footer />
      
      </React.Fragment>
   )
}

export default Curriculo