import React, { useEffect, useState } from 'react';
import PlanCard from '../../components/PlanCard';
import SignatureCreditService from '../../services/SignatureCreditService';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChoosePlanPaymentTypeModal from './ChoosePlanPaymentTypeModal';

const BuySignatureCredit = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [plans, setPlans] = useState([]);
  const [choosePaymentMethodsModalProps, setChoosePaymentMethodsModalProps] = useState(null);
  const [loadingBuyPlan, setLoadingBuyPlan] = useState(false);
  useEffect(() => {
    SignatureCreditService.getPlans().then(setPlans);
  }, []);

  const onBuyPlan = async (plan) => {
    try {
      setChoosePaymentMethodsModalProps({ plan });
      setLoadingBuyPlan(true);
      await SignatureCreditService.buyPlan(plan);
      push('signature-credit');
    } finally {
      setLoadingBuyPlan(false);
    }
  };

  return (
    <div style={{ padding: '0 30px' }}>
      <div>
        <Link
          to='signature-credit'
          style={{ display: 'flex', alignItems: 'center', gap: 10, border: 'none', color: 'black' }}
        >
          <FaArrowLeft /> {t('BuySignatureCreditPage.Back')}
        </Link>
      </div>
      <div style={{ textAlign: 'center', marginBottom: 20, fontSize: '1.125rem' }}>
        {t('BuySignatureCreditPage.ChoosePlanDescription')}
      </div>
      <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
        {plans.map((plan) => (
          <PlanCard key={plan.id} plan={plan} onBuyPlan={() => setChoosePaymentMethodsModalProps({plan})} loading={loadingBuyPlan} />
        ))}
      </div>
      {choosePaymentMethodsModalProps && <ChoosePlanPaymentTypeModal onCLose={() => {
        setChoosePaymentMethodsModalProps(null);
        push('signature-credit');
      }} {...choosePaymentMethodsModalProps}/>}
    </div>
  );
};

export default BuySignatureCredit;
