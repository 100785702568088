import React from 'react';
import { getNestedPropByIndex } from '../../utils/utils';
import Loading from '../Loading';

const BaseInput = ({
  classNameContainer,
  styleContainer,
  label,
  isLoading,

  noDefaultClassName,
  className,
  name,
  value,
  onChange,
  style,

  inputComponent,

  ...props
}) => {
  return (
    <div className={`form_group ${classNameContainer || ''}`} style={styleContainer}>
      <label className='card_details_label flex-row'>
        {label}
        {isLoading && <Loading type='1' style={{ marginLeft: '2%' }} />}
      </label>
      {inputComponent({
        className: `${!noDefaultClassName ? 'form_control' : ''} ${className || ''}`,
        name: name,
        value: typeof value === 'object' && value?.hasOwnProperty(name) ? getNestedPropByIndex(value, name) : value,
        onChange: onChange,
        style: { ...style },
        ...props,
      })}
    </div>
  );
};

export default BaseInput;
