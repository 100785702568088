export const PaymentErrors = {
  INVALID_CPF: 'INVALID_CPF',
  INVALID_CNPJ: 'INVALID_CNPJ',
  SCHOOL_ASAAS_CONFIG_INVALID: 'SCHOOL_ASAAS_CONFIG_INVALID',
  CLUB_ASAAS_CONFIG_INVALID: 'CLUB_ASAAS_CONFIG_INVALID',
  SERVER_COMMUNICATION_FAILED: 'SERVER_COMMUNICATION_FAILED',
  INVALID_API_AUTH: 'INVALID_API_AUTH',
  UNABLE_SEND_CUSTOMER: 'UNABLE_SEND_CUSTOMER',
  CUSTOMER_VALIDATION_ERROR: 'CUSTOMER_VALIDATION_ERROR',
  UNABLE_VALIDATE_PAYMENT: 'UNABLE_VALIDATE_PAYMENT',
  SUBSCRIBE_STATUS_ERROR: 'SUBSCRIBE_STATUS_ERROR',
  SUBSCRIBE_ERROR: 'SUBSCRIBE_ERROR',
  MONEY_SUBSCRIBE_ERROR: 'MONEY_SUBSCRIBE_ERROR',
  PROCESSING_ERROR: 'PROCESSING_ERROR',
  BILL_STATUS_ERROR: 'BILL_STATUS_ERROR',
  BILL_ERROR: 'BILL_ERROR',
  MONEY_PAYMENT_ERROR: 'MONEY_PAYMENT_ERROR',
};
