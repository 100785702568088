import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserProvider } from './Context/UserContext';
import MainRoutes from './routes';
import { getEnvironment } from './utils/api';
import(`moment/locale/${navigator.language.toLocaleLowerCase()}`).then();

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (getEnvironment() === 'DEV' || getEnvironment() === 'LOCALHOST') {
      let pressedKeys = [];
      const saveKey = (event) => (pressedKeys = [...pressedKeys, event.key]);

      const changeLanguage = () => {
        if (pressedKeys.length !== 0 && ['Shift', 'Control', '@'].every((key) => pressedKeys.includes(key))) {
          console.log("RUN changeLanguage('pt-BR')");
          i18n.changeLanguage('pt-BR');
        } else if (pressedKeys.length !== 0 && ['Shift', 'Control', '#'].every((key) => pressedKeys.includes(key))) {
          console.log("RUN changeLanguage('en-US')");
          i18n.changeLanguage('en-US');
        } else if (pressedKeys.length !== 0 && ['Shift', 'Control', '$'].every((key) => pressedKeys.includes(key))) {
          console.log("RUN changeLanguage('en-US-Arabic')");
          i18n.changeLanguage('en-US-Arabic');
        } else if (pressedKeys.length !== 0 && ['Shift', 'Control', ' '].every((key) => pressedKeys.includes(key))) {
          console.log(`RUN changeLanguage(${i18n.resolvedLanguage === 'en-US' ? 'pt-BR' : 'en-US'})`);
          i18n.changeLanguage(i18n.resolvedLanguage === 'en-US' ? 'pt-BR' : 'en-US');
        }
        pressedKeys = [];
      };

      window.addEventListener('keydown', saveKey);
      window.addEventListener('keyup', changeLanguage);
      return () => {
        window.addEventListener('keydown', saveKey);
        window.removeEventListener('keyup', changeLanguage);
      };
    }
  }, []);

  return (
    <UserProvider>
      <MainRoutes />
    </UserProvider>
  );
};

export default App;
