import Rotator from 'exif-auto-rotate';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import * as XLSX from 'xlsx';
import avatarDefault from '../assets/img/avatar-default.png';
import { thema as schoolThemaDefault } from '../config/school.thema.default';
import { getThemeBySubdomain } from '../services/CompanieService';
import { baseURL, uploadFolder } from './api';

const uploadURL = uploadFolder();

const Utils = () => {
  const accordion = () => {
    var acc = document.getElementsByClassName('accordion');
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        this.classList.toggle('active');
        var panel = this.nextElementSibling;
        if (panel.style.display === 'block') {
          panel.style.display = 'none';
        } else {
          panel.style.display = 'block';
        }
      });
    }
  };

  const openCity = (evt, cityName) => {
    var i, x, tablinks;
    x = document.getElementsByClassName('city');
    for (i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
    }
    tablinks = document.getElementsByClassName('tablink');
    for (i = 0; i < x.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' w3-red', '');
    }
    document.getElementById(cityName).style.display = 'block';
    evt.currentTarget.className += ' w3-red';
  };

  const openNav = () => {
    document.getElementById('mySidenav').style.width = '300px';
  };

  const closeNav = () => {
    document.getElementById('mySidenav').style.width = '0';
  };

  //Recebe uma data (string) e retorna a data no formato dd/mm/yyyy
  const dateFormat = (strDate) => {
    if (strDate === null || strDate === '') return '';

    var strSplitDate = String(strDate).split(' ');
    strSplitDate = String(strSplitDate[0]).split('-');
    var date = new Date(strSplitDate[0], strSplitDate[1] - 1, strSplitDate[2]);

    var dd = date.getDate();
    var mm = date.getMonth() + 1;

    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    date = dd + '/' + mm + '/' + yyyy;
    return date.toString();
  };

  //Recebe uma data (string) no formato dd/mm/yyyy e retorna no formato yyyy-mm-dd
  const dateFormatText = (strDate) => {
    if (strDate === null || strDate === '') return '';

    return strDate.split('-').reverse().join('/').toString();
  };

  function replaceAll(str, find, replace) {
    if (str == null) return '';

    return str.split(find).join(replace);
  }

  function validaCPF(cpf) {
    if (!cpf || cpf === '') return true;
    else if (cpf.length !== 11) return false;

    cpf = String(cpf).replace('.', '').replace('.', '').replace('-', '');

    var numeros, digitos, soma, i, resultado, digitos_iguais;
    digitos_iguais = 1;
    if (cpf.length < 11) return false;
    for (i = 0; i < cpf.length - 1; i++)
      if (cpf.charAt(i) != cpf.charAt(i + 1)) {
        digitos_iguais = 0;
        break;
      }
    if (!digitos_iguais) {
      numeros = cpf.substring(0, 9);
      digitos = cpf.substring(9);
      soma = 0;
      for (i = 10; i > 1; i--) soma += numeros.charAt(10 - i) * i;
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;
      numeros = cpf.substring(0, 10);
      soma = 0;
      for (i = 11; i > 1; i--) soma += numeros.charAt(11 - i) * i;
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;
      return true;
    } else return false;
  }

  function validaCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
  }

  function formatCNPJCPF(value) {
    if (value.length == 11) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
    }

    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  }

  function formatCNPJ(value) {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  }

  function formatCPF(value) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  function clearCNPJCPF(value) {
    return value
      .replace(/(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/g, '$1$2$3$4')
      .replace(/(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})-(\d{2})/g, '$1$2$3$4$5');
  }

  function formatTelefone(value) {
    if (!value) {
      return '';
    } else if (value.length == 11) {
      return value.replace(/(\d{2})(\d{5})(\d{4})/g, '($1)$2-$3');
    } else if (value.length == 10) {
      return value.replace(/(\d{2})(\d{4})(\d{4})/g, '($1)$2-$3');
    }

    return '';
  }

  function sumDaysDate(dias) {
    var now = new Date();
    return new Date(now.setDate(now.getDate() + dias)).toISOString().substr(0, 10); // Adiciona 3 dias
  }

  function diffDaysNow(data_ini) {
    const past = new Date(data_ini);
    const now = new Date();
    const diff = Math.abs(now.getTime() - past.getTime());
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    return days;
  }

  function formatCEP(value) {
    if (value.length == 8) return value.replace(/(\d{5})(\d{3})/g, '$1-$2');

    return value;
  }

  function scrollTop(ref) {
    if (!ref?.current) return;
    ref.current.scrollIntoView && ref.current.scrollIntoView({ behavior: 'smooth' });
    ref.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  const currencyFormatter = (value) => {
    if (Number.isNaN(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  };

  const percentageFormatter = (value) => {
    if (Number.isNaN(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
    }).format(value / 100);

    return `${amount}%`;
  };

  const currencyFormatterText = (value) => {
    if (Number.isNaN(value) && value !== 0 && value !== '0') return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);

    return `${amount}`;
  };

  const percentageFormatterText = (value) => {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'percent',
    }).format(value);

    return `${amount}`;
  };

  const valueFromMoneyString = (money) => {
    return money.includes('R$') ? money.replaceAll('.', '').replaceAll(',', '.').replaceAll('R$', '').trim() : money;
  };

  const compareStrings = (bigger, smaller) => {
    if (!bigger) return 1;
    if (!smaller) return -1;

    const biggerLowerCase = bigger.toLowerCase();
    const smallerLowerCase = smaller.toLowerCase();

    if (biggerLowerCase > smallerLowerCase) return 1;
    else if (smallerLowerCase > biggerLowerCase) return -1;
    else return 0;
  };

  const compareDate = (bigger, smaller) => {
    if (!bigger) return 1;
    if (!smaller) return -1;

    const b = bigger.split('-');
    const s = smaller.split('-');

    const biggerTime = new Date(b[0], b[1] - 1, b[2]).getTime();
    const smallerTime = new Date(s[0], s[1] - 1, s[2]).getTime();

    if (biggerTime > smallerTime) return 1;
    else if (smallerTime > biggerTime) return -1;
    else return 0;
  };

  //Recebe um objeto Date() e retorna a data no formato yyyy-mm-dd
  const getDateFormated = (date) => {
    let day = date.getDate();
    let mon = date.getMonth() + 1;
    let yea = date.getFullYear();

    day = day + 1 < 10 ? '0' + day : day;
    mon = mon + 1 < 10 ? '0' + mon : mon;

    return yea + '-' + mon + '-' + day;
  };

  const getThemaCompanie = async () => {
    let location = window.location.href;
    let domain = window.location.href.split('/')[2].split('.')[0];
    let thema = '';

    if (domain !== 'fensor' && domain !== 'www') {
      thema = !location.includes('fensor') ? schoolThemaDefault : domain;
    }

    if (thema === '') {
      return {
        companie_id: '',
        thema: 'athlete',
      };
    }

    const { theme } = await getThemeBySubdomain(thema);

    document.documentElement.style.setProperty('--main-color', theme.defaultColor);
    document.documentElement.style.setProperty('--main-color-alfa', theme.defaultColor + '20');
    document.documentElement.style.setProperty(
      '--background-image',
      `url(${uploadURL}upload/companie/background/${theme.mainBackground})`
    );

    return { companie_id: theme.id, comLogo: theme.comLogo };
  };

  const setThemeSchool = (school) => {
    if (document.querySelector('.modal'))
      document.querySelector('.modal').style.backgroundColor = hexToRGB(school.defaultColor, 0.9);

    document.documentElement.style.setProperty('--main-color', school.defaultColor);
    document.documentElement.style.setProperty('--main-color-alfa', school.defaultColor + '20');
    document.documentElement.style.setProperty(
      '--background-image',
      `url(${uploadURL}upload/schools/background/${school.mainBackground})`
    );
  };

  const setThemeSchoolDefault = () => {
    setThemeSchool({ defaultColor: '#ba8820', mainBackground: 'bg_login_qa.jpg' });
  };

  function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  function formatCycloContract(cycle) {
    switch (cycle) {
      case 'MONTHLY':
        return 'Mensal';
      case 'YEARLY':
        return 'Anual';
      case 'QUARTERLY':
        return 'Trimestral';
      case 'SEMIANNUALLY':
        return 'Semestral';
    }
  }

  return {
    formatCycloContract,
    accordion,
    openCity,
    openNav,
    closeNav,
    dateFormat,
    dateFormatText,
    replaceAll,
    validaCPF,
    validaCNPJ,
    formatCNPJCPF,
    formatCNPJ,
    formatCPF,
    clearCNPJCPF,
    formatTelefone,
    formatCEP,
    diffDaysNow,
    scrollTop,
    currencyFormatter,
    percentageFormatter,
    currencyFormatterText,
    percentageFormatterText,
    valueFromMoneyString,
    compareStrings,
    compareDate,
    getDateFormated,
    getThemaCompanie,
    setThemeSchool,
    setThemeSchoolDefault,
    sumDaysDate,
  };
};

export default Utils;

export function getUserPhoto(baseUrl, path) {
  return path ? baseUrl + path : avatarDefault;
}

export function getErrorMessageFromResponse(message) {
  return Object.keys(message).reduce(
    (msgs, prop) =>
      message[prop].message != undefined
        ? (msgs += message[prop].message + ', ')
        : (msgs += message[prop].description + ' '),
    ''
  );
}

export function getErrorMessageFromFileResponse(erro) {
  return Object.keys(erro).reduce((msgs, prop) => (msgs += erro[prop][0]), '');
}

export async function correctImageOrientation(image) {
  try {
    return await Rotator.createRotatedImageAsync(image, 'blob');
  } catch (error) {
    return image;
  }
}

export function range(start, end = null) {
  const arrayRange = [];
  let index = end ? start : 0;
  const range = end || start;

  for (index; index < range; index++) {
    arrayRange.push(index);
  }

  return arrayRange;
}

/**
 *
 * @param {object} data
 * @returns {FormData}
 */
export function buildFormData(data) {
  const formData = new FormData();
  appendFormData(formData, data, null);

  // Código para debug
  // console.log(formData);
  // for (const pair of formData) {
  //   console.log(`${pair[0]} >> `, pair[1]);
  // }

  return formData;
}

/**
 *
 * @param {FormData} formData
 * @param {object} data
 * @param {string | null} parentKey
 * @returns {void}
 */
export function appendFormData(formData, data, parentKey = null) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob) &&
    !(Array.isArray(data) && !data.length)
  ) {
    Object.keys(data).forEach((key) => {
      appendFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

export const getURLContractPDF = (contract, event) => {
  if (event) event.preventDefault();

  if ([272, 273, 274].includes(contract.school_id))
    return baseURL + `contract-default-schools/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 58) return baseURL + `contract-cr/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 59)
    return baseURL + `contract-america-quinto/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 79 || contract.school_id === 86)
    return baseURL + `contract-eurobarcelona-ararangua-criciuma/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 99)
    return baseURL + `contract-palmeiras-atibaia/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 109)
    return baseURL + `contract-america-sabara/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 126)
    return baseURL + `contract-aba-futebol-pampulha/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 147)
    return baseURL + `contract-eurobarcelona-itupeva/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 188)
    return baseURL + `contract-eurobarcelona-nucleo-maracaja/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 262) return baseURL + `contract-latent/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 271)
    return baseURL + `contract-athletico-paranaense/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 291)
    return baseURL + `contract-meninos-da-villa-guarulhos/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 335)
    return baseURL + `contract-guerreirinhos-manaus/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 356)
    return baseURL + `contract-meninos-da-villa-mogi-das-cruzes/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 108)
    return baseURL + `contract-america-nova-lima/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 391)
    return baseURL + `contract-nucleo-passe-certo/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 393)
    return baseURL + `contract-escola-furacao/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 277)
    return baseURL + `contract-escola-america-agua-branca/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 392)
    return baseURL + `contract-escola-cacique-xavante/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 399)
    return baseURL + `contract-escola-avante/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.school_id === 382)
    return baseURL + `contract-escola-chute-moleque/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if ([140, 339, 351].includes(contract.school_id))
    return baseURL + `contract-america/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 71)
    return baseURL + `contract-palmeiras/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 97)
    return baseURL + `contract-eurobarcelona/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 121)
    return baseURL + `contract-strelart/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 125) return baseURL + `contract-pgc/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 131)
    return baseURL + `contract-ldf-joinville/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 135)
    return baseURL + `contract-fair-play-academy/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 139)
    return baseURL + `contract-santana-livramento/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 154) return baseURL + `contract-ujc/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 175)
    return baseURL + `contract-arena-vozao/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 176)
    return baseURL + `contract-fominha/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 177) return baseURL + `contract-dta/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 159)
    return baseURL + `contract-assedam/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 170)
    return baseURL + `contract-campo-tubarao/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 186) return baseURL + `contract-ft10/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 188)
    return baseURL + `contract-focus/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else if (contract.companie_id === 206)
    return baseURL + `contract-pro-gol/pdf/${contract.id}/${contract.school_id}/.pdf`;
  else return baseURL + `contract-default/pdf/${contract.id}/${contract.school_id}/.pdf`;
};

export const handleOnChange = (setState) => {
  return (eventOrField, value = undefined) => {
    const field = value !== undefined ? eventOrField : eventOrField.target.name;
    const valueReceived = value !== undefined ? value : eventOrField.target.value;

    setState((prev) => setNestedPropByIndex({ ...prev }, field, valueReceived));
  };
};

export const AllMonths = [
  {
    number: 1,
    numberString: '01',
    name: 'Janeiro',
    nameKey: 'MonthName.January',
    nameKeyAbbreviated: 'MonthName.January_abbreviated',
  },
  {
    number: 2,
    numberString: '02',
    name: 'Fevereiro',
    nameKey: 'MonthName.February',
    nameKeyAbbreviated: 'MonthName.February_abbreviated',
  },
  {
    number: 3,
    numberString: '03',
    name: 'Março',
    nameKey: 'MonthName.March',
    nameKeyAbbreviated: 'MonthName.March_abbreviated',
  },
  {
    number: 4,
    numberString: '04',
    name: 'Abril',
    nameKey: 'MonthName.April',
    nameKeyAbbreviated: 'MonthName.April_abbreviated',
  },
  {
    number: 5,
    numberString: '05',
    name: 'Maio',
    nameKey: 'MonthName.May',
    nameKeyAbbreviated: 'MonthName.May_abbreviated',
  },
  {
    number: 6,
    numberString: '06',
    name: 'Junho',
    nameKey: 'MonthName.June',
    nameKeyAbbreviated: 'MonthName.June_abbreviated',
  },
  {
    number: 7,
    numberString: '07',
    name: 'Julho',
    nameKey: 'MonthName.July',
    nameKeyAbbreviated: 'MonthName.July_abbreviated',
  },
  {
    number: 8,
    numberString: '08',
    name: 'Agosto',
    nameKey: 'MonthName.August',
    nameKeyAbbreviated: 'MonthName.August_abbreviated',
  },
  {
    number: 9,
    numberString: '09',
    name: 'Setembro',
    nameKey: 'MonthName.September',
    nameKeyAbbreviated: 'MonthName.September_abbreviated',
  },
  {
    number: 10,
    numberString: '10',
    name: 'Outubro',
    nameKey: 'MonthName.October',
    nameKeyAbbreviated: 'MonthName.October_abbreviated',
  },
  {
    number: 11,
    numberString: '11',
    name: 'Novembro',
    nameKey: 'MonthName.November',
    nameKeyAbbreviated: 'MonthName.November_abbreviated',
  },
  {
    number: 12,
    numberString: '12',
    name: 'Dezembro',
    nameKey: 'MonthName.December',
    nameKeyAbbreviated: 'MonthName.December_abbreviated',
  },
];

export const DaysOfWeek = [
  { number: 1, numberString: '01', name: 'Domingo', nameKey: 'WeekdayName.Sunday' },
  { number: 2, numberString: '02', name: 'Segunda-Feira', nameKey: 'WeekdayName.Monday' },
  { number: 3, numberString: '03', name: 'Terça-Feira', nameKey: 'WeekdayName.Tuesday' },
  { number: 4, numberString: '04', name: 'Quarta-Feira', nameKey: 'WeekdayName.Wednesday' },
  { number: 5, numberString: '05', name: 'Quinta-Feira', nameKey: 'WeekdayName.Thursday' },
  { number: 6, numberString: '06', name: 'Sexta-Feira', nameKey: 'WeekdayName.Friday' },
  { number: 7, numberString: '07', name: 'Sábado', nameKey: 'WeekdayName.Saturday' },
];

/**
 * Um array com os dias do mês no formato DD
 * @param {string | Date | moment.Moment} date
 * @returns {string[]}
 */
export const getDaysInMonth = (date = undefined) =>
  range(1, moment(date).daysInMonth() + 1).map((day) => String(day).padStart(2, '0'));

export const getMonthsGrouped = () => {
  return AllMonths.reduce(
    (acc, month, i) => {
      if (0 <= i && i < 4) acc[0].push(month);
      else if (4 <= i && i < 8) acc[1].push(month);
      else if (8 <= i && i < 12) acc[2].push(month);

      return acc;
    },
    [[], [], []]
  );
};

/**
 * Capitalize a string
 * @param {string} text String to capitalize
 * @returns {string} String capitalized
 */
export const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const exportToXLS = (filename, data) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blob, filename);
};

/**
 * Função utilitária que permite ao Prettier formatar strings com HTML
 * @param {string} x
 * @returns {string}
 */
export const html = (strings, ...values) => String.raw(strings, ...values);

/**
 * Função utilitária que permite ao Prettier formatar strings com CSS
 * @param {string} x
 * @returns {string}
 */
export const css = (strings, ...values) => String.raw(strings, ...values);

export const formatPercentageOrFixed = (type, value) => {
  switch (type) {
    case 'PERCENTAGE':
      return +value / 100;

    case 'FIXED':
      return +value;

    default:
      return null;
  }
};

export const getNestedPropByIndex = (object, index) =>
  index.split('.').reduce((p, prop) => p.hasOwnProperty(prop) && p[prop], object);

export const setNestedPropByIndex = (object, index, value) =>
  index.split('.').reduce((p, prop, reduceIndex, array) => {
    if (reduceIndex + 1 === array.length) {
      p[prop] = value;
      return object;
    } else {
      return p[prop] || '';
    }
  }, object);

export const defaultCssStyleToPrint = () =>
  css`
    @page {
      margin: 5mm 5mm 0mm 5mm !important;
    }

    .btn_card {
      display: none !important;
    }

    #title-to-print {
      display: block !important;
      width: 100% !important;
    }

    form {
      margin: 0px !important;
    }

    * {
      color: #464646 !important;
      border-color: #464646 !important;
    }

    table,
    table * {
      box-shadow: unset !important;
    }

    table,
    th,
    td {
      border: 2px solid #464646 !important;
      border-collapse: collapse !important;
    }

    table td input {
      border: none !important;
    }

    .table-container {
      border-radius: 0px !important;
    }

    table th {
      font-size: 10pt !important;
      font-weight: bold !important;
    }

    thead,
    th {
      position: unset !important;
      background-color: unset !important;
    }

    .group_50 {
      width: 49% !important;
    }

    .group_50,
    .form_group,
    .form_group * {
      margin: 0px !important;
      margin-bottom: 1mm !important;
    }

    .form_control {
      height: 35px !important;
    }

    textarea.form_control {
      height: 50px !important;
      color: #464646 !important;
    }
    .max-to-print {
      max-height: 100% !important;
      height: 100% !important;
      overflow-x: hidden !important;
    }
    .no-border-pint {
      border: none !important;
      padding: 0px !important;
    }
  `;

export const copyToClipboard = async (text) => {
  try {
    if (navigator.clipboard != undefined) {
      await navigator.clipboard.writeText(text);
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

const formatByPatternRecursive = (textArray, patternArray, patterns, mask) => {
  if (!patterns || !patternArray.length || (!textArray.length && !mask)) {
    return '';
  }

  const charPattern = patternArray.shift();
  const regex = patterns[charPattern];

  if (!regex) {
    return charPattern + formatByPatternRecursive(textArray, patternArray, patterns, mask);
  }

  const char = textArray.shift();

  if (!char && !mask) return '';
  else if (!char) return mask + formatByPatternRecursive(textArray, patternArray, patterns, mask);
  else if (char.match(regex)) return char + formatByPatternRecursive(textArray, patternArray, patterns, mask);
  else if (mask) return mask + formatByPatternRecursive([], patternArray, patterns, mask);
  else return '';
};

export const formatByPattern = (value, pattern, mask) => {
  const patterns = {
    '#': /[0-9]/,
    $: /[a-zA-Z]/,
    '*': /./,
  };

  if ((!value && !mask) || !pattern) return value;
  else return formatByPatternRecursive([...value], [...pattern], patterns, mask);
};

export const sortProductSizes = (sizes = []) => {
  const letterOrder = { PP: 901, P: 902, M: 903, G: 904, GG: 905, XGG: 906, Único: 907 };

  return sizes.sort((a, b) => (Number.isNaN(+a) ? letterOrder[a] : +a) - (Number.isNaN(+b) ? letterOrder[b] : +b));
};

export const yearsRange = Array.from({ length: Number(moment().format('Y')) - 1995 + 1 }, (v, i) => 1995 + i);
