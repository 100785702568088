import React, { useEffect, useState, StyleSheet } from 'react'
import Header from '../../components/athlete/Header'
import Footer from '../../components/athlete/Footer'
import Loading from '../../components/Loading'
import Message from '../../components/Message'
import { Link } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap';
import Api from '../../utils/api'
import axios from 'axios'
import ReactPlayer from 'react-player'

const Vestiario = (props) => {
   const { MessageDanger, MessageSuccess } = Message()
   const baseURL = Api()

   // processos de loading
   const [sendUser, setSendUser] = useState(false)
   const [sendPhoto, setSendPhoto] = useState(false)
   const [sendVideo, setSendVideo] = useState(false)
   const [sendIndicate, setSendIndicate] = useState(false)

   // carregamentos
   const [user, setUser] = useState({})
   const [age, setAge] = useState("")
   const [photoPerfil, setPhotoPerfil] = useState("")
   const [photos, setPhotos] = useState([])
   const [photosDefault, setPhotosDefault] = useState([])
   const [videos, setVideos] = useState([])
   const [video, setVideo] = useState("")
   const [videosDefault, setVideosDefault] = useState([])
   const [dirPhoto, setDirPhoto] = useState(false)
   const [user_id, setUserID] = useState(Number(props.match.params.user_id))
   const [nameIndicate, setNameIndicate] = useState("")
   const [emailIndicate, setEmailIndicate] = useState("")
   const [fotoClick, setFotoClick] = useState("")
   const [videoClick, setVideoClick] = useState("")
   const [modalPhoto, setModalPhoto] = useState(false)
   const [modalVideo, setModalVideo] = useState(false)

   // messages e error
   const [message, setMessage] = useState("")
   const [errorUpload, setErrorUpload] = useState(false)
   const [successDeletePhoto, setSuccessDeletePhoto] = useState(false)
   const [errorDeletePhoto, setErrorDeletePhoto] = useState(false)
   const [successUpload, setSuccessUpload] = useState(false)
   const [errorUploadVideo, setErrorUploadVideo] = useState(false)
   const [successUploadVideo, setSuccessUploadVideo] = useState(false)
   const [classErrorVideo, setClassMarginBottom0] = useState("")
   const [errorIndicate, setErrorIndicate] = useState(false)
   const [successIndicate, setSuccessIndicate] = useState(false)
   const [successDeleteVideo, setSuccessDeleteVideo] = useState(false)
   const [errorDeleteVideo, setErrorDeleteVideo] = useState(false)
   
   // outros parametros
   const current    = window.location.href
   const userLogado   = JSON.parse(localStorage.getItem('user'))
   
   useEffect(() => {
      getUserPhotoVideo()
   },[sendPhoto, sendVideo])

   const clearStateMessage = () => {
      setMessage("")
      setErrorUpload(false)
      setSuccessDeletePhoto(false)
      setErrorDeletePhoto(false)
      setSuccessUpload(false)
      setErrorUploadVideo(false)
      setSuccessUploadVideo(false)
      setClassMarginBottom0("")
      setErrorIndicate(false)
      setSuccessIndicate(false)
   }

   const getUserPhotoVideo = async () => {
      
      setSendUser(true)
      const res = await axios.post(baseURL + 'get-user-photos-videos',{"user_id": user_id})
      setSendUser(false)
      
      setUser(res.data.athlete)
      setVideos(res.data.videos)
      setDirPhoto(res.data.urlPhotos)
      setPhotos(res.data.photos)
      montaFotosDefault(res.data.photos)
      montaVideosDefault(res.data.videos)
      initPhotoPerfil(res.data)
      
      if ( res.data.athlete.usuBirth != null ) {
         setAge(calcAge(new Date(res.data.athlete.usuBirth.split("/").reverse().join("-"))))
      }
   }

   const initPhotoPerfil = (data) => {
      if ( data.photos.length > 0 ) {
         let possuiCapa = false 
         data.photos.forEach(photo => {
            if ( photo.atpPerfil == 'S' ) {
               setPhotoPerfil(data.photos.urlPhotos + '/' + photo.atpAthletePhoto)
               possuiCapa = true
            }
         })

         if ( !possuiCapa )
            setPhotoPerfil(data.urlPhotos + '/' + data.photos[0].atpAthletePhoto)
      } else {
         setPhotoPerfil("../../img/perfil/perfil-vestiario.png")
      }
   }

   const montaFotosDefault = (fotos) => {
      let row = []
      for(let i=fotos.length; i < 10; i++) {
         row.push({id: 'photo_' + i, id2: i})
      }
      setPhotosDefault(row)
   }
   const montaVideosDefault = (videos) => {
      let rowVideo = []
      for(let i=videos.length; i < 5; i++) {
         rowVideo.push({id: 'video_' + i, id2: i})
      }
      setVideosDefault(rowVideo)
   }

   const delPhoto = async ( photo ) => {
      clearStateMessage()
      
      setSendPhoto(true)
      const res = await axios.post(baseURL + 'delete-photo-athlete', photo)
      setSendPhoto(false)

      if ( res.data.success === true ) {
         setSuccessDeletePhoto(true)
      } else {
         setErrorDeletePhoto(true)
         setMessage(res.data.msg)
      }
   }

   const setOpenModalPhoto = (dir) => {
      setModalPhoto(true)
      setFotoClick(dir)
   }

   const handleCloseModalPhoto = () => setModalPhoto(false)

   const setOpenModalVideo = (dir) => {
      setModalVideo(true)
      setVideoClick(dir)
   }

   const handleCloseModalVideo = () => setModalVideo(false)

   const handleFile = async (e, id, athletephoto_id) => {
      window.scrollTo(0, 0)
      clearStateMessage()

      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('foto', file)
      formData.append('id', user.id)

      if ( athletephoto_id !== "" )
         formData.append('athletephoto_id', athletephoto_id)
      
      setSendPhoto(true)
      const res = await axios.post(baseURL + 'upload-photo-athlete', formData, {headers: { 'Content-Type': 'multipart/form-data'}})
      setSendPhoto(false)

      if ( res.data.success === false ) {
         setErrorUpload(true)
         setSuccessUpload(false)
         setMessage(res.data.msg)
      } else {
         setSuccessUpload(true)
         setErrorUpload(false)
         setMessage(res.data.msg)
      }                 
   }

   const handleVideo = async (e) => {

      e.preventDefault()
      clearStateMessage()
      
      const athlete    = JSON.parse(localStorage.getItem('athlete'))
      const athlete_id = athlete.id
      let urlVideo     = ''
      if ( video !== '' ) {
         if ( video.indexOf('youtube.com') != -1 ) {
            urlVideo = video.replace('watch?v=','embed/')
         } else if ( video.indexOf('vimeo.com') != -1 ) {
            let stringVideo = video.split('/')
            urlVideo = 'https://player.vimeo.com/video/' + stringVideo[parseInt(stringVideo.length) - 1]
         }
      }

      if ( urlVideo.indexOf('youtube.com') != -1 || urlVideo.indexOf('vimeo.com') != -1 ) {
         setSendVideo(true)
         const res = await axios.post(baseURL + 'upload-video-athlete', {atvAthleteVideo: urlVideo, athlete_id: athlete_id})
         setSendVideo(false)
   
         if ( res.data.success === false ) {
            setErrorUploadVideo(true)
            setSuccessUploadVideo(false)

            let prop = ''
            let msgs = ''
            for(prop in res.data.erro) {
               if (res.data.erro.hasOwnProperty(prop)) {
                  msgs += res.data.erro[prop][0]
               }
            }

            setMessage(msgs)
         } else {
            setSuccessUploadVideo(true)
            setErrorUploadVideo(false)
            setMessage(res.data.msg)
         }
      } else {
         setErrorUploadVideo(true)
         setMessage("Informe o link do seu vídeo no youtube ou vimeo")
      }

      setVideo("")
      setClassMarginBottom0("margin-bottom0")  
   }

   const Photos = (props) => {
      return (
         <li>
            <img loading="lazy" onClick={e => setOpenModalPhoto(dirPhoto + '/' + props.data.atpAthletePhoto)} className="li-img" src={dirPhoto + '/' + props.data.atpAthletePhoto} id={`photo_` + (parseInt(props.id) + 1)} />
            {
               userLogado !== null && userLogado.id === user_id &&
               <img className="del" onClick={() => delPhoto(props.data)} src="../../img/icones/delete.svg" />
            }
         </li>
      )
   }

   const PhotosOff = (props) => {
      return (
         <li style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <label htmlFor={`select-file${(parseInt(props.data.id2) + 1)}`}>
               <img htmlFor={`select-file${(parseInt(props.data.id2) + 1)}`} loading="lazy" style={{ width: 100, margin: 'auto', height: 'auto', cursor: 'pointer'}} className="li-img" src="../../img/icones/noimage.png" id={`photo_` + (parseInt(props.data.id2) + 1)} />
            </label>
            {
               userLogado !== null && userLogado.id === user_id &&
               <input id={`select-file${(parseInt(props.data.id2) + 1)}`} className="hide" type="file" name="file[]" onChange={e => handleFile(e, (parseInt(props.data.id2) + 1), '')}/>
            }
         </li>
      )
   }

   const delVideo = async ( video ) => {
      clearStateMessage()
      
      setSendVideo(true)
      const res = await axios.post(baseURL + 'delete-video-athlete', video)
      setSendVideo(false)

      if ( res.data.success === true ) {
         setSuccessDeleteVideo(true)
      } else {
         setErrorDeleteVideo(true)
         setMessage(res.data.msg)
      }
   }

   const Videos = (props) => {
      let urlVideo = props.data.atvAthleteVideo
      urlVideo = urlVideo.indexOf('youtube') != -1 ? urlVideo.replace('watch?v=','embed/') : urlVideo

      return (
         <li className="ind-foto">
            <label className="arquivo-foto" style={{flexDirection: 'column'}}>
               <ReactPlayer onClick={() => setOpenModalVideo(urlVideo)} className="video" url={urlVideo} light pip="true"/>
               {
                  userLogado !== null && userLogado.id === user_id &&
                  <p style={{marginTop: '10%'}}><img className="delVideo" onClick={() => delVideo(props.data)} src="../../img/icones/delete.svg" /></p>
               }
            </label>
         </li>
      )
   }

   const VideosOff = (props) => {
      return (
         <li className="ind-foto">
            <label className="arquivo-foto">
               <img src="../../img/icones/no-video.svg" style={{ width: 90, margin: 'auto', height: 'auto', cursor: 'none'}} id={`video_` + (parseInt(props.data.id2) + 1)} />
            </label>
         </li>
      )
   }

   const handleIndicate = async (e) => {
      e.preventDefault()
      clearStateMessage()

      const athlete    = JSON.parse(localStorage.getItem('athlete'))
      const athlete_id = athlete.id
      
      setSendIndicate(true)
      const res = await axios.post(baseURL + 'athlete-indicate', {
         name: nameIndicate,
         email: emailIndicate,
         athlete_id: athlete_id,
         url: window.location.origin
      })
      setSendIndicate(false)

      if ( res.data.success === false ) {
         setErrorIndicate(true)
         setSuccessIndicate(false)
         setMessage(res.data.msg)
      } else {
         setSuccessIndicate(true)
         setErrorIndicate(false)
         setMessage("você acaba de indicar um amigo :)")
         setNameIndicate("")
         setEmailIndicate("")
      }  
   }

   const calcAge = (dateString) => {
      var birthday = +new Date(dateString);
      return ~~((Date.now() - birthday) / (31557600000));
   }

   return (
         <React.Fragment>
            {
               userLogado !== null && userLogado.id === user_id &&
               <Header type="2" menu={true} />
            }
            {
               userLogado !== null && userLogado.id !== user_id &&
               <Header type="2" menu={false} />
            }
            {
               userLogado === null &&
               <Header type="2" menu={false} />
            }
            <main>
               <section className="bloco-principal">
                  <div className="conteudo-bloco-principal">
                     <div className="bloco-2">
                        <div className="conteudo-generico conteudo-vestiario">
                           <div className="foto-perfil">
                              <div className="col-perfil">
                                 <div className="img-foto-perfil">
                                    <img className="img-radius" src={photoPerfil} />
                                 </div>
                              </div>
                              <div className="col-perfil">
                                 <h3>{user.name}</h3>
                                 <div className="info-perfil">
                                    <img src="../../img/icones/position/pd.png" />
                                    <p>{user.posPosition}</p>
                                 </div>
                              </div>
                           </div>
                           {
                              sendUser && <Loading type="1"/>
                           }
                           <ul className="dados-perfil">
                              <li className="info-dados-perfil">  
                                 <p>{user.name}</p>
                              </li>
                              <li className="info-dados-perfil">
                                 <p><b>Nascimento:</b></p>
                                 <div className="idade-perfil">
                                    <p>{user.usuBirth}</p>
                                 </div>
                              </li>
                              <li className="info-dados-perfil">
                                 <p><b>Altura:</b></p>
                                 <div className="altura-perfil">
                                    <p>{user.athAltura}m</p>
                                 </div>
                              </li>
                              <li className="info-dados-perfil">
                                 <p><b>Peso:</b></p>
                                 <div className="peso-perfil">
                                    <p>{user.athPeso}kg</p>
                                 </div>
                              </li>
                              <li className="info-dados-perfil">
                                 <p><b>Lateralidade:</b></p>
                                 <div className="lateralidade-perfil">
                                    <p>{user.lateralidade}</p>
                                 </div>
                              </li>
                              <li className="info-dados-perfil">
                                 <p><b>1ª posição:</b></p>
                                 <div className="posicao-1-perfil">
                                    <p>{user.posPosition}</p>
                                 </div>
                              </li>
                              <li className="info-dados-perfil">
                                 <p><b>2ª posição:</b></p>
                                 <div className="posicao-2-perfil">
                                    <p>{user.posPositionAlternativa}</p>
                                 </div>
                              </li>
                              <li className="info-dados-perfil">
                                 <p><b>Estado:</b></p>
                                 <div className="estado-perfil">
                                    <p>{user.staState}</p>
                                 </div>
                              </li>
                           </ul>
                           <div className="btn-editar-curriculo">
                              {
                                 userLogado !== null && userLogado.id === user_id &&
                                 <Link to="/curriculo-athlete">Editar currículo</Link>
                              }
                           </div>
                        </div>
                        <div className="info-extra-clube">
                           <div className="bloco-extra-clube">
                              <h3>Pontos acumulados</h3>
                              <div className="contador-extra-clube contador-vestiario">
                                 <div className="numero-contador-clube numero-contador-vest">
                                    <p>00000</p>
                                 </div>
                                 <a href="#">Ver benefícios</a>
                              </div>
                           </div>		             
                        </div>
                        <div className="conteudo-generico redes-vestiario">
                           <div className="info-link-compartilhar">
                              <p>Compartilhe com os amigos</p>
                              <div className="compartilhar-vestiario">
                                 <input type="url" readOnly name="" value={ current } />
                              </div>
                              <div className="redes-sociais">
                                 {
                                    user.athPerfilFacebook !== '' && 
                                    <a href={user.athPerfilFacebook} target="_blank"><img src="../../img/icones/facebook.png" /></a>
                                 }
                                 {
                                    user.athPerfilFacebook === '' && 
                                    <img src="../../img/icones/facebook.png" />
                                 }
                                 {
                                    user.usuPhone !== '' && 
                                    <a href={`https://api.whatsapp.com/send?phone=55`+ user.usuPhone + `&text=Ol%C3%A1!%20`} target="_blank"><img src="../../img/icones/whatsapp.png" /></a>
                                 }
                                 {
                                    user.usuPhone === '' && 
                                    <img src="../../img/icones/whatsapp.png" />
                                 }
                                 {
                                    user.athPerfilInstagran !== '' && 
                                    <a href={user.athPerfilInstagran} target="_blank"><img src="../../img/icones/instagram.png" /></a>
                                 }
                                 {
                                    user.athPerfilInstagran === '' && 
                                    <img src="../../img/icones/instagram.png" />
                                 }
                              </div>
                           </div>
                        </div>
                        <div className="conteudo-generico indique-amigo">
                           <div className="indicar-amigo">
                              <div className="btn-popup-vestiario">
                                 <a href="#popup1">Indique um amigo</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="bloco-1-secundario">
                        <div className="seletivas bloco-vestiario">
                           <div className="fotos-vestiarios">
                              <div className="titulo-seletivas titulo-vestiario-pg">
                                 <h3>Suas fotos</h3>
                                 <p>Escolha até 10 das suas melhores fotos e publique aqui no seu vestiário. 
                                 {
                                    sendUser && <Loading type="1"/>
                                 }
                                 </p>
                                 {
                                    successDeletePhoto &&
                                    <span><br /><MessageSuccess title="Sucesso!" description="Foto excluída com sucesso" /></span>
                                 }
                                 {
                                    errorDeletePhoto &&
                                    <span><br /><MessageDanger title="Atenção!" description={message}/></span>
                                 }
                                 {
                                    successUpload &&
                                    <span><br /><MessageSuccess title="Sucesso!" description="Foto enviada com sucesso" /></span>
                                 }
                                 {
                                    errorUpload &&
                                    <span><br /><MessageDanger title="Atenção!" description={message}/></span>
                                 }
                              </div>
                              {
                                 sendPhoto &&
                                 <p className="loading-center"><Loading type="3"/></p>
                              }
                              <div id="fotos">
                                 <ul className="galeria">
                                    {
                                       photos.map((e, i) => {
                                          return <Photos data={e} id={i} key={ `key_photo_` + i }/>
                                       })
                                    }
                                    {
                                       photosDefault.map(e => {
                                          return <PhotosOff data={e} key={ `key_photo_` + e.id2 } />
                                       })
                                    }
                                 </ul>
                              </div>
                              <ul className="conteudo-fotos">
                                 
                              </ul>
                           </div>
                           <div className="videos-vestiarios">
                              <div className={`titulo-seletivas titulo-vestiario-pg ${classErrorVideo}`}>
                                 <h3>Seus vídeos</h3>
                                 <p>Escolha até 5 dos seus melhores vídeos e publique aqui no seu vestiário. 
                                 {
                                    sendUser && <Loading type="1"/>
                                 }
                                 </p>
                                 {
                                    successDeleteVideo &&
                                    <span><br /><MessageSuccess title="Sucesso!" description="Vídeo excluído com sucesso" /></span>
                                 }
                                 {
                                    errorDeleteVideo &&
                                    <span><br /><MessageDanger title="Atenção!" description={message}/></span>
                                 }
                                 {
                                    successUploadVideo &&
                                    <span><br /><MessageSuccess title="Sucesso!" description="Vídeo enviado com sucesso" /></span>
                                 }
                                 {
                                    errorUploadVideo &&
                                    <span><br /><MessageDanger title="Atenção!" description={message}/></span>
                                 }
                              </div>
                              {
                                 sendVideo &&
                                 <p className="loading-center"><Loading type="3"/></p>
                              }
                              {
                                 userLogado !== null && userLogado.id === user_id &&
                                 <div className="coluna-1-curriculo">
                                    <div className="sub-coluna-curriculo">
                                       <div className="info-curriculo">
                                          <label>Link do vídeo</label>
                                          <input type="url" name="video" value={video} onChange={e => setVideo(e.target.value)} />
                                       </div>

                                       <div className="info-curriculo">
                                          <div className="coluna-curriculo margin-bottom4">
                                             <label>&nbsp;</label>
                                             <a href="#" onClick={handleVideo}><img src="../../img/icones/add.png" />Adicionar</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              }

                              <ul className="conteudo-fotos">
                                 {
                                    videos.map((e, i) => {
                                       return <Videos data={e} id={i} key={ `key_video_` + i }/>
                                    })
                                 }
                                 {
                                    videosDefault.map(e => {
                                       return <VideosOff data={e} key={ `key_video_` + e.id2 } />
                                    })
                                 }
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="popup1" className="overlay">
                     <div className="popup"> 
                        <h3>Indique um amigo</h3>
                        {
                           errorIndicate && <span><br /><MessageDanger title="Ops! " description={message} /></span>
                        }
                        {
                           successIndicate && <span><br /><MessageSuccess title="Parabéns! " description={message} /></span>
                        }
                        <form  className="conteudo-popup popup-indique" onSubmit={handleIndicate}>
                           <div className="popup-form-indique">
                              <label>Nome</label>
                              <input type="text" value={nameIndicate} required name="nameIndicate" onChange={e=>setNameIndicate(e.target.value)} />
                           </div>
                           <div className="popup-form-indique">
                              <label>E-mail</label>
                              <input type="email" value={emailIndicate} required name="emailIndicate" onChange={e=>setEmailIndicate(e.target.value)}/>
                           </div>
                           <div className="botao-salvar-curriculo btn-indique">
                              {
                                 sendIndicate &&
                                 <p className="loading-center">Aguarde... <Loading type="3"/></p>
                              }
                              {
                                 !sendIndicate &&
                                 <button type="submit">Salvar</button>
                              }
                           </div>                 
                        </form>
                        <a className="close" href="#"><img src="../../img/icones/exit.png" /></a>
                     </div>
                  </div>
                  <Modal show={modalPhoto} onHide={handleCloseModalPhoto}  animation={true} centered size="lg">
                     <Modal.Body style={{fontWeight: '200'}}><img src={fotoClick} /></Modal.Body>
                     <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModalPhoto}>Fechar!</Button>
                     </Modal.Footer>
                  </Modal>
                  <Modal show={modalVideo} onHide={handleCloseModalVideo}  animation={true} centered size="lg">
                     <Modal.Body style={{width: '100%'}}><ReactPlayer url={videoClick} style={{width: '100%'}} controls={true}/></Modal.Body>
                     <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModalVideo}>Fechar!</Button>
                     </Modal.Footer>
                  </Modal>
               </section>
            </main>
            
            <Footer />
          
         </React.Fragment>
   )
}

export default Vestiario