import React from 'react';
import { useTranslation } from 'react-i18next';
import { FootballIcon } from '../icons/FootballIcon';
import Loading from '../Loading';
import ButtonRounded from './ButtonRounded';

export const ButtonRoundedDefault = ({ icon, hideIcon, isLoading, children, ...props }) => {
  const { t } = useTranslation();

  return (
    <ButtonRounded isDisabled={isLoading || props.disabled} {...props}>
      {hideIcon ? '' : icon ? icon : <FootballIcon />}
      {isLoading ? (
        <>
          {t('SystemMessage.Loading.Processing')} <Loading type='1' style={{ marginLeft: '2%' }} />
        </>
      ) : (
        children
      )}
    </ButtonRounded>
  );
};
