import React from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Checkbox, TextField } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { activeStudents } from '../../../services/StudentService';
import '../../../utils/extendsType';

import useForm from '../../../hooks/useForm';
import Message from '../../../components/Message';
import ButtonRounded from '../../../components/buttons/ButtonRounded';
import Matricula from '../../Students/Forms/Matricula';
/* import { storeFinancial } from '../../../services/FinancialService'; */
import { StudentFormContext } from '../../../Context/StudentFormContext';
import InvoiceRevenueNotification from './InvoiceRevenueNotification';
import { getClassSchool } from '../../../services/ClassService';
import { CobrancaFaturarContext } from '../../../Context/CobrancaFaturarContext';
import { base_url } from '../../../services/api';
import { storeFinancial } from '../../../services/FinancialService';
import { getStandardizedInvoicePlan } from '../../../services/ContractStudentService';
import { getAsaasConfigSessionSchool } from '../../../services/PaymentService';

const sortByName = function (a, b) {
  var nameA = a.name.toLowerCase(),
    nameB = b.name.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

const createFilterOptions = (options, { inputValue }) => {
  const filteredOptions = options.filter((option) => {
    const { usuCPF, name, usuBirth, email } = option;
    const lowerSearchText = inputValue.toLowerCase();
    const _name = name.toString().toLowerCase();
    return (
      (_name && _name.includes(lowerSearchText)) ||
      (usuCPF && usuCPF.includes(lowerSearchText)) ||
      (usuBirth && usuBirth.includes(lowerSearchText)) ||
      (email && email.includes(lowerSearchText))
    );
  });
  return filteredOptions;
};
const createFilterOptionsTurma = (options, { inputValue }) => {
  const filteredOptions = options.filter((option) => {
    const { name } = option;
    const lowerSearchText = inputValue.toLowerCase();
    const _name = name.toString().toLowerCase();
    return _name && _name.includes(lowerSearchText);
  });
  return filteredOptions;
};

//component
const InvoiceRevenueForm = () => {
  //hooks states --------------------------------------------------------------------------------------
  const history = useHistory();

  const context = React.useContext(StudentFormContext);
  /*   const clientContext = React.useContext(CobrancaFaturarContext); */

  const { t } = useTranslation();

  const classes = useStyles();

  const formRefer = React.useRef();

  const alertRef = React.useRef();

  //!gambiarra pra resetar o Autocomplete
  const [autokey, setAutoKey] = React.useState(moment().toISOString());

  const [errors, setErrors] = React.useState([]);
  const [errorServer, setErrorsServe] = React.useState([]);

  const { MessageDangerMarginTop } = Message();

  const [sendSave, setSendSave] = React.useState(false);

  const [progress, setProgress] = React.useState([]);

  const [isDone, setIsDone] = React.useState(false);

  const [_students, setStudents] = React.useState([]);

  const [studentsList, setStudentsList] = React.useState(stateSchema);

  const [turmas, setClasses] = React.useState([]);

  const [selected, setSelected] = React.useState([]);

  const [tipo, setTipo] = React.useState(1);

  const [standContracts, setStandContracts] = React.useState([]);

  const [standDefault, setStandDefalt] = React.useState('');

  const handlerErrorServer = (err) => {
    setSendSave(false);
    if (err.response?.status === 422) {
      const { message = [] } = err.response.data;
      Object.keys(message).map((k) => {
        const element = document.querySelector(`[name=${k}]`);
        if (element) {
          matriculaForm.dispatchHtmlValidity(element, `[${k}] ${message[k]}`);
        }
      });
    } else {
      setErrorsServe([err.toString()]);
    }
  };

  const matriculaForm = useForm(
    stateMatriculaSchema,
    validationStateSchemaMatricula,
    (p, e, s) => {
      const btn = e.target.loader();

      btn.start();

      setSendSave(true);

      let counter = 0;

      if (p.paymentModo == 'Parcelado' && p.parcelas == 1) {
        p.parcelas = 2;
      }

      const clientContext = getAsaasConfigSessionSchool(context.school, context.company);

      const payload = {
        user_id_assas: clientContext.user_id || '',
        company_id: context.company.id,
        school_id: context.school.id,
        system_id: clientContext.system_id || '',
        token_assas: clientContext.token_assas || '',
        percentual: clientContext?.percentual || '',
        valor_config: clientContext?.valor || '',
        wallet: clientContext.wallet || '',
        ...p,
      };

      const promise = async (id) => {
        payload.students = id;
        return storeFinancial(payload)
          .then((data) => {
            setProgress((prev) => [...prev, data.data]);
          })
          .catch((error) => {
            counter = selected.length;
            handlerErrorServer(error);
          });
      };

      (async () => {
        while (counter < selected.length) {
          await promise(selected[counter]);
          counter++;
        }
      })();
    },
    formRefer,
    setErrors
  );

  const isSelected = (name) => selected.indexOf(name) !== -1;

  //handler funtions --------------------------------------------------------------------------------------

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = studentsList.students.value.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  async function _onSubmitForm(payload) {
    setIsDone(true);
  }

  async function getRequestClass() {
    const response = await activeStudents(context.school.id);

    const dados = response.data.students.sort(sortByName);

    dados.unshift({ name: 'TODOS', id: '' });
    // id = idAthlete, troco pelo id do usuario
    setStudents(dados.map((student) => ({ ...student, id: student.idUser })));
  }

  const removeStudentFromList = React.useCallback((id) => {
    setStudentsList((current) => ({
      ...current,
      students: { value: current.students.value.filter((student) => student.id !== id) },
    }));

    setSelected((current) => current.filter((student) => student !== id));
  });

  //constructor --------------------------------------------------------------------------------------
  React.useEffect(() => {
    setSelected([]);
    setStudentsList({ students: { value: [] } });
    try {
      getRequestClass();

      getClassSchool(context.school.id, undefined, true).then(async (resp) => {
        const dados = resp.classs.filter((v) => v.students.length > 0).sort(sortByName);
        setClasses(dados);
      });
      /*   (async () => {
        try {
          await getRequestClass();
          const data = await getStandardizedInvoicePlan(context.school.id);
          setStandContracts(data);
        } catch (error) {
          console.log(error);
        }
      })(); */
    } catch (error) {
      console.log(error);
    }
    return () => {
      setStudents([]);
      setClasses([]);
      setSelected([]);
      setStudentsList({ students: { value: [] } });
    };
  }, []);

  /*   React.useEffect(() => {
    if (standContracts.length > 0) {
      const { id = '' } = standContracts.find((plan) => plan.default_plan == 'S');
      handlerSetDefaultPlan(id);
    }
  }, [isDone]); */

  //const properties --------------------------------------------------------------------------------------
  const columnStudent = [
    {
      id: 'name',
      label: 'Alunos Incluídos',
      minWidth: 220,
      align: 'left',
      format: (value, row) => (
        <>
          <p>{value}</p>
          <small>{row.turma}</small>
        </>
      ),
    },
    {
      id: 'usuBirth',
      label: 'Data Aniversário',
      minWidth: 220,
      align: 'left',
      format: (value) => moment(value).format('DD/MM/YYYY'),
    },
    {
      id: 'athEmail',
      label: 'Email',
      align: 'left',
      format: (value, row) => row?.athEmail || row?.usuEmail || '-',
    },
    {
      id: 'id',
      align: 'center',
      label: 'Ação',
      minWidth: 100,
      format: (id) => (
        <BtnDelete
          onClick={(e) => {
            e.preventDefault();
            removeStudentFromList(id);
          }}
        />
      ),
    },
  ];

  const handlerFilter = (options, austate) => {
    return tipo === 1 ? createFilterOptions(options, austate) : createFilterOptionsTurma(options, austate);
  };

  const handlerSetDefaultPlan = (id) => {
    const contract = standContracts.find((plan) => plan.id == id);

    setStandDefalt(id);
    if (contract) {
      matriculaForm.setState({
        id: { value: contract.id, error: '' },
        name: { value: contract.name, error: '' },
        expirationDate: { value: contract.due_date, error: '' },
        paymentDate: { value: '', error: '' },
        paymentMethod: { value: contract.payment, error: '' },
        paymentModo: { value: contract.mode, error: '' },
        parcelas: { value: contract.amount, error: '' },
        valorOriginal: { value: contract.value, error: '' },
        discount_type: { value: contract.discount_type == null ? 'NONE' : contract.discount_type, error: '' },
        discount: { value: contract.discount, error: '' },
        late_interest_type: {
          value: contract.late_interest_type == null ? 'NONE' : contract.late_interest_type,
          error: '',
        },
        late_interest: { value: contract.late_interest, error: '' },
        late_fee_type: { value: contract.late_fee_type == null ? 'NONE' : contract.late_fee_type, error: '' },
        late_fee: { value: contract.late_fee, error: '' },
        billingType: { value: contract.type, error: '' },
        contaContabil: { value: contract.account_description, error: '' },
        faturado: { value: 'N', error: '' },
        receber: { value: 'S', error: '' },
        valorReceber: { value: 0, error: '' },
        remove_recevid: { value: true, error: '' },
        observation: { value: contract.observation ? contract.observation : '', error: '' },
      });
    } else {
      matriculaForm.setState(stateMatriculaSchema); //reset form
    }
  };

  return (
    <>
      {sendSave ? (
        <>
          <InvoiceRevenueNotification results={progress} total={selected.length} />
        </>
      ) : (
        <div className='card p-0 flex-column'>
          <div className='card_header w-100 flex-column align-items-center pt-3'>
            <h2 className='card_title'>Nova Cobrança</h2>
            <BtnBack
              onClick={(e) => {
                history.push('/financial-school');
              }}
              style={{
                float: 'right',
                position: 'absolute',
                left: 35,
                marginTop: 5,
              }}
            />
          </div>
          <hr />
          {errors.map((err, k) => (
            <MessageDangerMarginTop
              key={`err-${k}`}
              title='Atenção!'
              description={t(`${err.error.replace(/\[.*?\]/g, '').trim()}`)}
            />
          ))}
          {errorServer.map((item, k) => (
            <MessageDangerMarginTop title='Atenção!' description={item} key={`error_${k}`} />
          ))}
          {isDone === false ? (
            <>
              <div className='row mb-2 mx-3'>
                <div className='col-md-3 col-sm-12'>
                  <label className='card_details_label'>Buscar por:</label>
                  <select
                    className='form_control form-control  select'
                    defaultValue={tipo}
                    onChange={(e) => {
                      setTipo(e.target.value);
                    }}
                  >
                    <option value='1'>Alunos</option>
                    <option value='2'>Turmas</option>
                  </select>
                </div>
                <div className='col'>
                  <label className='card_details_label'>Selecionar Alunos: *</label>

                  <Autocomplete
                    key={autokey}
                    classes={classes}
                    options={
                      tipo == 1
                        ? _students.filter((u) => !studentsList.students.value.find((t) => t.id === u.id))
                        : turmas
                    }
                    getOptionLabel={(option) => option.name}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    filterOptions={handlerFilter}
                    onChange={(event, user, detail) => {
                      if (detail === 'select-option') {
                        const {
                          idUser = undefined,
                          name,
                          usuBirth = undefined,
                          athEmail = undefined,
                          usuEmail = undefined,
                          students = undefined,
                        } = user;
                        /*     if (studentsList.students.value.length > 30) {
                          setErrorsServe(['O limite de alunos atingido(30)!']);
                          return;
                        } */
                        if (name === 'TODOS') {
                          studentsList.students.value.push(..._students.filter((u) => u.name !== 'TODOS'));
                        }

                        if (Array.isArray(students)) {
                          studentsList.students.value.push(...students);
                        }

                        if (idUser) {
                          studentsList.students.value.push({ idUser, id: idUser, name, usuBirth, athEmail, usuEmail });
                        }

                        setSelected(
                          studentsList.students.value
                            .filter((u) => u.name !== 'TODOS')
                            .unique('id')
                            .map((n) => n.id)
                        );
                        setAutoKey(moment().toISOString());
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          tipo == 1
                            ? 'Pesquisar aluno por nome, cpf ou email ou data de nascimento...'
                            : 'Pesquisar turma por nome...'
                        }
                        variant='outlined'
                        helperText={
                          studentsList.students.value.length > 0 && selected.length === 0
                            ? 'Selecione os alunos para prosseguir há criação da cobrança!'
                            : ''
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <TableContainer style={{ maxHeight: 500, minHeight: 500 * 0.8 }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell padding='checkbox'>
                        <CheckboxCustom
                          indeterminate={selected.length > 0 && selected.length < studentsList.students.value.length}
                          checked={
                            studentsList.students.value.length > 0 &&
                            selected.length === studentsList.students.value.length
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                      </StyledTableCell>
                      {columnStudent.map((column) => (
                        <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentsList.students.value.unique('id').map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role='checkbox'
                          aria-checked={isItemSelected}
                          key={row.id}
                          selected={isItemSelected}
                          tabIndex={-1}
                        >
                          <TableCell padding='checkbox'>
                            <CheckboxCustom checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                          </TableCell>
                          {columnStudent.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {typeof column.format === 'function' ? column.format(value, row) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className='row align-items-center justify-content-center my-4'>
                {/*    <div className='col-md-5 col-sm-10'>
                  <ButtonRounded
                    className='w-100'
                    variant='outline'
                    color='var(--main-color)'
                    isBig={true}
                    onClick={handlerFinally}
                  >
                    {t('Back')}
                  </ButtonRounded>
                </div> */}
                <div className='col-md-5 col-sm-10'>
                  <ButtonRounded
                    isDisabled={selected.length === 0}
                    isLoading={sendSave}
                    onClick={_onSubmitForm}
                    className='w-100'
                    isBig={true}
                  >
                    {t('Continue')}
                  </ButtonRounded>
                </div>
              </div>
            </>
          ) : (
            <form ref={formRefer} action='' className='form_card' noValidate>
              <div className='row px-4'>
                <p className='lead'>Total de alunos selecionados: {selected.length}</p>
              </div>
              {/*  <div className='row p-3'>
                <div className='col-12'>
                  <label htmlFor='schedule' className='card_details_label'>
                    Usar um Plano Padrão
                  </label>
                  <select className={`form_control select`}   value={standDefault} onChange={(e) => handlerSetDefaultPlan(e.target.value)}>
                    <option value=''>Selecionar Plano Padrão</option>
                    {standContracts.map((item, i) => (
                      <option key={`stdplan${i}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div> */}
              <div className='row p-3'>
                <Matricula useForm={matriculaForm} />
              </div>
              <div className='row align-items-center justify-content-center my-5'>
                <div className='col-md-5 col-sm-10'>
                  <ButtonRounded
                    className='w-100'
                    variant='outline'
                    color='var(--main-color)'
                    isBig={true}
                    onClick={() => setIsDone(false)}
                  >
                    {t('Back')}
                  </ButtonRounded>
                </div>
                <div className='col-md-5 col-sm-10'>
                  <ButtonRounded
                    isDisabled={selected.length === 0}
                    onClick={matriculaForm.handleOnSubmit}
                    className='w-100'
                    isBig={true}
                  >
                    {t('Continue')}
                  </ButtonRounded>
                </div>
              </div>
            </form>
          )}
        </div>
      )}
    </>
  );
};

//form states ----------------------------------------------------------------------------------------------
const stateMatriculaSchema = {
  id: { value: 0, error: '' },
  paymentDate: { value: null, error: '' },
  expirationDate: { value: '', error: '' },
  paymentMethod: { value: '', error: '' },
  paymentModo: { value: 'A vista', error: '' },
  parcelas: { value: 1, error: '' },
  valorOriginal: { value: 0, error: '' },
  contaContabil: { value: '', error: '' },
  observation: { value: '', error: '' },
  payment_status: { value: '', error: '' },
  discount_type: { value: 'NONE', error: '' },
  discount: { value: '', error: '' },
  payment_id_asaas: { value: '', error: '' },
  late_interest_type: { value: 'NONE', error: '' },
  late_interest: { value: '', error: '' },
  late_fee_type: { value: 'NONE', error: '' },
  late_fee: { value: '', error: '' },
  receber: { value: 'N', error: '' },
  valorReceber: { value: '', error: '' },
  anexo: { value: '', error: '' },
  contract: { value: null, error: '' },
  billingType: { value: 'MONEY', error: '' },
  faturado: { value: 'N', error: '' },
};
const validationStateSchemaMatricula = {};

const stateSchema = {
  students: { value: [], error: '' },
};

const validationStateSchema = {};

//material ui custom styles --------------------------------------------------------------------------------
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'white',
    color: theme.palette.common.black,
    border: 'none',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: '#f4f0f0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
  },
});

const CheckboxCustom = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: 'var(--main-color)',
    },
  },
  checked: {},
}))(Checkbox);

//private components  --------------------------------------------------------------------------------------
const BtnDelete = ({ fcolor = '#FF2929', w = 16, h = 16, onClick }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer' }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z'
        fill={fcolor}
      />
    </svg>
  </span>
);

const BtnBack = ({ fcolor = '#1C1B1F', w = 16, h = 16, onClick, style }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer', ...style }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z' fill={fcolor} />
    </svg>
  </span>
);

/* function requestXHR(url, form) {
  return new Promise((resolve, reject) => {
    let lastResponseLength = false;
    const xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);

    //xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('@token')}`);

    xhr.onprogress = function (e) {
      let progressResponse;
      let response = e.currentTarget.response;

      progressResponse = lastResponseLength ? response.substring(lastResponseLength) : response;

      lastResponseLength = response.length;

      let parsedResponse = progressResponse.split('|');

      if (parsedResponse.includes('progress')) {
        setProgress((current) => [
          ...current,
          {
            progress: parsedResponse[0],
            total: parsedResponse[1],
            status: parsedResponse[2],
            name: parsedResponse[3],
            error: parsedResponse[4],
            cpf: parsedResponse[5],
            email: parsedResponse[6],
          },
        ]);
      }
    };

    xhr.onreadystatechange = function (callback) {
      if (xhr.readyState == 4) {
        if (this.status == 200) {
          return resolve(xhr.response);
        }
        if (this.status == 422) {
          const er = { response: { status: 422, data: JSON.parse(xhr.response) } };
          return reject(er);
        }
        return reject(new Error('Error Server'));
      }
    };

    xhr.send(form);
  });
} */
export default InvoiceRevenueForm;
