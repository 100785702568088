import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

const PhoneMask = ({ value, onChange, className = 'form_control',  ...rest }) => {
  const { t } = useTranslation();

  return (
    <NumberFormat
      {...rest}
      type='tel'
      className={className}
      format={t('Mask.CellPhone') || undefined}
      value={value}
      onValueChange={({ value }) => onChange(value)}
    />
  );
};

export default PhoneMask;
