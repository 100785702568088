import React from 'react';
import BaseInput from './BaseInput';

const Select = ({ children, ...props }) => {
  return (
    <BaseInput
      {...props}
      inputComponent={(inputProps) => (
        <select {...inputProps} className={`select ${inputProps.className}`}>
          {children}
        </select>
      )}
    />
  );
};

export default Select;
