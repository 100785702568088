import { buildFormData } from '../utils/utils';
import api from './api';

export const getAllExercises = async () => {
  try {
    const { data } = await api.get('get-all-exercise-admin');
    return data;
  } catch (error) {
    return { success: false, error: error };
  }
};

const serializeExerciseToFormData = (exercise) => {
  const { newFile, ...rest } = exercise;
  if (newFile) return buildFormData(exercise);
  else return buildFormData(rest);
};

export const createExercise = async (exercise) => {
  try {
    const { data } = await api.post('create-exercise-admin', serializeExerciseToFormData(exercise));
    return data;
  } catch (error) {
    return { success: false, error: error };
  }
};

export const updateExercise = async (exercise) => {
  try {
    const { data } = await api.post('update-exercise-admin', serializeExerciseToFormData(exercise));
    return data;
  } catch (error) {
    return { success: false, error: error };
  }
};

export const getAllActiveExercises = async () => {
  try {
    const { data } = await api.get('get-all-active-exercises-school');
    return data;
  } catch (error) {
    return { success: false, error: error };
  }
};
