import axios from 'axios';
import Api from '../utils/api';
import api from './api';

const baseURL = Api();

const getStates = async (country = 'pt-BR') => {
  const countries = {
    'pt-BR': 'BRA',
    'en-US': 'USA',
    'en-US-Arabic': 'SAU',
  };
  try {
    const { data } = await api.get('get-states/' + countries[country]);
    return data;
  } catch (error) {
    return { success: false };
  }
};

const getCitysByStateSigla = (estado, country = 'pt-BR') =>
  new Promise((response, reject) => {
    const countries = {
      'pt-BR': 'BRA',
      'en-US': 'USA',
      'en-US-Arabic': 'SAU',
    };

    const requestBody = {
      staStateSigla: estado,
      country: countries[country],
    };
    axios
      .post(baseURL + 'get-citys-by-state-sigla', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

/**
 *
 * @param {string} zipCode
 * @param {'pt-BR' | 'en-US' | 'en-US-Arabic'} country
 * @returns {{
 *  success: boolean;
 *  state: Record<string, unknown>;
 *  cityName: string;
 *  ibge: string;
 *  streetName: string;
 *  neighborhood: string;
 *  states: Record<string, unknown>[];
 *  cities: Record<string, unknown>[];
 *  city: Record<string, unknown>;
 * }}
 */
export const searchByZipCodeCountry = async (zipCode, country) => {
  const handlers = {
    'pt-BR': searchByBRAZipCode,
    'en-US': searchByUSAZipCode,
    'en-US-Arabic': searchBySAUZipCode,
  };

  return await handlers[country](zipCode);
};

const searchByZipCode = async (zipCode) => {
  try {
    const { data } = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`);
    return data;
  } catch (error) {
    return { success: false };
  }
};

export const searchByBRAZipCode = async (zipCode) => {
  try {
    const { data } = await api.get('search-by-bra-zip-code/' + zipCode);

    if (data.success)
      return {
        success: true,

        state: data.state,
        cityName: data.cityName,
        ibge: data.ibge,
        streetName: data.streetName,
        neighborhood: data.neighborhood,

        states: data.states,
        cities: data.cities,
        city: data.city,
      };
    else return { success: false };
  } catch (error) {
    return { success: false };
  }
};

const searchByUSAZipCode = async (zipCode) => {
  try {
    const { data } = await api.get('search-by-usa-zip-code/' + zipCode);

    if (data.success)
      return {
        success: data.success,

        state: data.state,
        cityName: data.cityName,

        states: data.states,
        cities: data.cities,
        city: data.city,

        streetName: '',
        neighborhood: '',
        ibge: '',
      };
    else return { success: false };
  } catch (error) {
    return { success: false };
  }
};

const searchBySAUZipCode = async (_) => {
  return { success: false };
};

const verifyEmailSchool = ({ email, schoolId }) =>
  new Promise((response, reject) => {
    const requestBody = {
      email,
      school_id: schoolId,
    };
    axios
      .post(baseURL + 'verify-email-school', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

const verifyEmailSchoolUserId = ({ email, schoolId, id }) =>
  new Promise((response, reject) => {
    const requestBody = {
      email,
      school_id: schoolId,
      id: id,
    };
    axios
      .post(baseURL + 'verify-email-school-userid', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

const verifyEmail = async ({ email, id }) => {
  try {
    const { data } = await api.post('verify-email', { email, id });
    return data;
  } catch (error) {
    return error;
  }
};
const verifyLogin = async ({ login, id }) => {
  try {
    const { data } = await api.post('verify-login-userid', { login, id });
    return data;
  } catch (error) {
    return error;
  }
};

const verifyCpfSchool = ({ cpf, schoolId }) =>
  new Promise((response, reject) => {
    const requestBody = {
      cpf,
      school_id: schoolId,
    };
    axios
      .post(baseURL + 'verify-cpf-school', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

const getQuestionnaires = (companieId) =>
  new Promise((response, reject) => {
    const requestBody = {
      companie_id: companieId,
    };
    axios
      .post(baseURL + 'get-questionnaire', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

const getPositions = () =>
  new Promise((response, reject) => {
    axios
      .get(baseURL + 'get-positions')
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

const getPositionsSchool = ({ school_id }) =>
  new Promise((response, reject) => {
    axios
      .get(baseURL + `get-sports-positions/${school_id}`)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });
  
const createSport = async (payload) => {
  const { data } = await api.post('create-sport', payload);
  if (data.status === 'SUCCESS') return data;
  throw data;
};

const getBenefits = (companieId) =>
  new Promise((response, reject) => {
    axios
      .get(baseURL + 'club/'+companieId+'/benefits')
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export {
  getStates,
  getCitysByStateSigla,
  searchByZipCode,
  searchByUSAZipCode,
  verifyEmailSchool,
  verifyCpfSchool,
  getQuestionnaires,
  getPositions,
  verifyEmail,
  verifyEmailSchoolUserId,
  verifyLogin,
  getPositionsSchool,
  createSport,
  getBenefits,
};
