import axios from 'axios';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Api, { getAccountFensorAsaas, getEnvironment } from '../../utils/api';
import utils from '../../utils/utils';

const PagamentoFotos = (props) => {

   // estados do formulario
   const [name, setName]   = useState(false)
   const [email, setEmail] = useState(false)
   const [usuPhone, setUsuPhone] = useState(false)
   const [usuCPF, setUsuCPF] = useState(false)
   const [numeroCartao, setNumeroCartao] = useState("")
   const [titularCartao, setTitularCartao] = useState("")
   const [mesValidade, setMesValidade] = useState("")
   const [anoValidade, setAnoValidade] = useState("")
   const [codSeguranca, setCodSeguranca] = useState("")
   const [parcelasPagamento, setParcelasPagamento] = useState("")
   const [postalCode, setPostalCode] = useState(false)
   const [addressNumber, setAddressNumber] = useState(false)
   const [anos, setAnos] = useState([])
   const [urlBoleto, setUrlBoleto] = useState("")
   const [tipoPagamento, setTipoPagamento] = useState("")

   // estados de processamento
   const [sendingPG, setSendingPG] = useState(false)

   // estados de mensagens
   const [errorFormClient, setErrorFormClient] = useState(false)
   const [errorFormCartao, setErrorFormCartao] = useState(false)
   const [errorFormBoleto, setErrorFormBoleto] = useState(false)
   const [message, setMessage] = useState("")

   // estados de redirecionamento
   const [goBack, setGoBack] = useState(false)

   // outras variaveis
   const { MessageDanger, MessageDangerMarginTop } = Message()
   const baseURL     = Api()
   const { replaceAll, validaCPF } = utils()
   const priceFinal  = props.location.state.priceFinal
   const photos      = props.location.state.photos
   const user        = JSON.parse(localStorage.getItem('user'))
   const athete      = JSON.parse(localStorage.getItem('athlete'))
   let currentTab    = 0; 

   // parametros API asaas
   const cnpj_asaas = getAccountFensorAsaas().cnpj;
   const pass_asaas = getAccountFensorAsaas().password;
   const token_asaas = getAccountFensorAsaas().token_assas;
   const user_asaas = getAccountFensorAsaas().user_id;
   const path_asaas = (getEnvironment() !== 'PROD') ? 'hmg-' : '';
   
   useEffect(() => {
      getAnos()
      if ( name === false ) {
         showTab(currentTab)
         setName(user.name)
      }

      if ( email === false )   
         setEmail(user.email)

      if ( usuPhone === false )   
         setUsuPhone(user.usuPhone)

      if ( usuCPF === false )   
         setUsuCPF(user.usuCPF)

      if ( postalCode === false )   
         setPostalCode(athete.athCep)

      if ( addressNumber === false )   
         setAddressNumber(athete.athNumero)

      setParcelasPagamento("1")
   })
   
   if ( props.location.state == undefined )
      return <Redirect to={{pathname: '/'}} />

   const getAnos = () => {
      if ( anos.length == 0 ) {
         const anos = []
         const anoAtual  = new Date().getFullYear()
         for (let i = anoAtual; i <= parseInt(anoAtual) + 8; i = i + 1)
            anos.push(i)
   
         setAnos(anos)
      }
   }

   const setCartao = (e) => {
      e.preventDefault(e)
      document.getElementById('item1').style.display = 'inline'
      document.getElementById('item2').style.display = 'none'
   }

   const setBoleto = (e) => {
      e.preventDefault(e)
      document.getElementById('item1').style.display = 'none'
      document.getElementById('item2').style.display = 'inline'
   }

   const showTab = (n) => {
      var x = document.getElementsByClassName("tab");
      
      x[n].style.display = "block";
      if (n == 0) {
         document.getElementById("prevBtn").style.display = "none";
      } else {
         document.getElementById("prevBtn").style.display = "inline";
      }

      fixStepIndicator(n)
   }

   const nextPrev = (n) => {
      var x = document.getElementsByClassName("tab");
      x[currentTab].style.display = "none";
      currentTab = currentTab + n;
      showTab(currentTab);
   }

   const fixStepIndicator = (n) => {
      var i, x = document.getElementsByClassName("step");
      for (i = 0; i < x.length; i++) {
         x[i].className = x[i].className.replace(" active", "");
      }
      x[n].className += " active";
   }

   const handleValidaForm = (e) => {
      e.preventDefault()

      const cpf = replaceAll(replaceAll(replaceAll(usuCPF, '.', ''), '-', ''),'_','')
      const phone = replaceAll(replaceAll(replaceAll(replaceAll(replaceAll(usuPhone,'(',''),')',''),' ',''),'-',''),'_','')
      
      if ( phone.length < 11 ) {
         setErrorFormClient(true)
         setMessage("O Telefone deve ter 11 dígitos")
      } else if ( cpf.length < 11 ) {
         setErrorFormClient(true)
         setMessage("O CPF deve ter 11 dígitos")
      } else if ( !validaCPF(cpf) ){
         setErrorFormClient(true)
         setMessage("CPF inválido")
      } else {
         nextPrev(1)
      }
   }

   const handleValidaFormCartao = async (e) => {
      e.preventDefault()
      setErrorFormClient(true)
      setErrorFormCartao(false)
      setErrorFormBoleto(false)
      setUrlBoleto("")
      setTipoPagamento(1)
                     
      const codSeg = replaceAll(codSeguranca, '_', '').trim()
      const numCar = replaceAll(replaceAll(numeroCartao, '_', ''), ' ', '').trim()

      if ( numCar.length < 16 ) {
         setErrorFormCartao(true)
         setMessage("Verifique o número do cartão")
      } else if ( codSeg.length < 3 ){
         setErrorFormCartao(true)
         setMessage("Verifique o cód. de segurança")
      } else {

         setSendingPG(true)
         
         // autentica na api de pgto
         const resAuth = await axios.post(baseURL + 'payment-auth',{"cnpj": cnpj_asaas, "password": pass_asaas})
                         
         if ( resAuth.status == 200 ) {
            if ( resAuth.connection == undefined ) {
               if ( resAuth.data.token != undefined ){

                  const dataCli = {
                     "cpf_cnpj": replaceAll(replaceAll(usuCPF,'.',''),'-',''),
                     "name": name,
                     "email": email,
                     "customer": "",
                     "system_id": 1,
                     "user_id": user_asaas,
                     "token_assas": token_asaas
                  }
      
                  // envia o cliente para obter o customer_id
                  const resCli = await axios.post(baseURL + `${path_asaas}cliente-save`, dataCli);

                  if ( resCli.status == 200 ) {
                     const date = new Date()
                     let day = date.getDate()
                     let mon = date.getMonth()
                     mon = mon + 1 < 10 ? '0'+(mon+1) : mon+1
                     let yea = date.getFullYear()
                     let dueDate = yea + '-' + mon + '-' + day


                     const data = {
                        "billingType": "CREDIT_CARD",
                        "customer": resCli.data.data.customer,
                        "value": parseFloat(priceFinal),
                        "dueDate": dueDate, 
                        "description": "Compra de fotos através de cartão de crédito a vista",
                        "installmentCount": "",
                        "installmentValue": "",
                        "postalService": false,
                        "client_id": resCli.data.data.id,
                        "token_assas": token_asaas,
                        "holderName": titularCartao,
                        "number": numCar,
                        "expiryMonth": mesValidade,
                        "expiryYear": anoValidade,
                        "ccv": codSeg,
                        "name": name,
                        "email": email,
                        "cpfCnpj": replaceAll(replaceAll(usuCPF,'.',''),'-',''),
                        "postalCode": postalCode,
                        "addressNumber": addressNumber,
                        "phone": usuPhone,
                        "photos": photos,
                        "user_id": user.id
                     }
         
                     // envia pagamento
                     const resPayment = await axios.post(baseURL + `${path_asaas}payment-cart-cred-vista`, data)
                     
                     if ( resPayment.data.data.paymentResponse != undefined ) {
                        if ( resPayment.data.data.paymentResponse.status == 'CONFIRMED' ) {

                           localStorage.removeItem('shoppingCart')
                           window.scrollTo(0, 950)
                           document.getElementsByClassName("tab")[1].style.display = "none"
                           showTab(2)

                         } else {

                           setErrorFormCartao(true)
                           setMessage('Pagamento não aprovado, tente novamente ou com outro cartão...')

                         }
                      } else if ( typeof resPayment.data.data.message != 'string' ) {
                         setErrorFormCartao(true)

                         let msgs = ''
                         let prop = ''
                         for(prop in resPayment.data.data.message) {
                            if (resPayment.data.data.message.hasOwnProperty(prop)) {
                               if ( resPayment.data.data.message[prop].message != undefined )
                                  msgs += resPayment.data.data.message[prop].message + ', '
                               else 
                                  msgs += resPayment.data.data.message[prop].description + ' '
                            }
                         }
                        
                         setMessage(msgs)

                     } else if ( resPayment.data.data.erro != undefined) {
                         setErrorFormCartao(true)
                         setMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...')
                         console.log('Nao foi possivel validar o pagamento.')
                     } 
                  } else {
                     setErrorFormCartao(true)
                     setMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...')
                     console.log('Nao foi possivel enviar o cliente.')
                  }
               } else {
                  setErrorFormCartao(true)
                  setMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...')
                  console.log('Autenticação com a API inválida.')
               }
            } else {
               setErrorFormCartao(true)
               setMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...')
               console.log('Erro ao comunicar com o servidor.')
            }
         } else {
            setErrorFormCartao(true)
            setMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...')
            console.log('Erro ao comunicar com o servidor.')
         }

         setSendingPG(false)
      }

      window.scrollTo(0, 950)
   }

   const handleValidaFormBoleto = async (e) => {
      e.preventDefault()
      setErrorFormClient(true)
      setErrorFormCartao(false)
      setErrorFormBoleto(false)
      setSendingPG(true)
      setUrlBoleto("")
      setTipoPagamento(2)
      
      // autentica na api de pgto
      const resAuth = await axios.post(baseURL + 'payment-auth',{"cnpj": cnpj_asaas, "password": pass_asaas})
                        
      if ( resAuth.status == 200 ) {
         if ( resAuth.connection == undefined ) {
            if ( resAuth.data.token != undefined ){

               const dataCli = {
                  "cpf_cnpj": replaceAll(replaceAll(usuCPF,'.',''),'-',''),
                  "name": name,
                  "email": email,
                  "customer": "",
                  "system_id": 1,
                  "user_id": user_asaas,
                  "token_assas": token_asaas
               }
   
               // envia o cliente para obter o customer_id
               const resCli = await axios.post(baseURL + `${path_asaas}cliente-save`, dataCli);

               if ( resCli.status == 200 ) {
                  const date = new Date()
                  date.setDate(date.getDate() + 1);
                  let day = date.getDate()
                  day = day < 10 ? '0'+day : day
                  let mon = date.getMonth()
                  mon = mon + 1 < 10 ? '0'+(mon+1) : mon+1
                  let yea = date.getFullYear()
                  let dueDate = yea + '-' + mon + '-' + day

                  const data = {
                     "billingType": "BOLETO",
                     "customer": resCli.data.data.customer,
                     "value": parseFloat(priceFinal),
                     "dueDate": dueDate,
                     "description": "Compra de fotos através de boleto a vista",
                     "installmentCount": "",
                     "installmentValue": "",
                     "postalService": false,
                     "client_id": resCli.data.data.id,
                     "token_assas": token_asaas,
                     "photos": photos,
                     "user_id": user.id
                  }
      
                  // envia pagamento
                  const resPayment = await axios.post(baseURL + `${path_asaas}payment-boleto-vista`, data)
                  
                  if ( resPayment.data.data.paymentResponse != undefined ) {
                     if ( resPayment.data.data.paymentResponse.status == 'PENDING' ) {

                        localStorage.removeItem('shoppingCart')
                        window.scrollTo(0, 950)
                        document.getElementsByClassName("tab")[1].style.display = "none"
                        setUrlBoleto(resPayment.data.data.paymentResponse.bankSlipUrl)
                        showTab(2)

                     } else {
                        setErrorFormBoleto(true)
                        setMessage('Não foi possível gerar o boleto, tente novamente mais tarde...')
                     }
                  } else if ( typeof resPayment.data.data.message != 'string' ) {

                     let msgs = ''
                     let prop = ''
                     for(prop in resPayment.data.data.message) {
                        if (resPayment.data.data.message.hasOwnProperty(prop)) {
                           if ( resPayment.data.data.message[prop].message != undefined )
                              msgs += resPayment.data.data.message[prop].message + ', '
                           else 
                              msgs += resPayment.data.data.message[prop].description + ' '
                        }
                     }
                  
                     setMessage(msgs)

                  } else if ( resPayment.data.data.erro != undefined) {
                        setErrorFormBoleto(true)
                        setMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...')
                        console.log('Nao foi possivel validar o pagamento.')
                  } 
               } else {
                  setErrorFormBoleto(true)
                  setMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...')
                  console.log('Nao foi possivel enviar o cliente.')
               }
            } else {
               setErrorFormBoleto(true)
               setMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...')
               console.log('Autenticação com a API inválida.')
            }
         } else {
            setErrorFormBoleto(true)
            setMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...')
            console.log('Erro ao comunicar com o servidor.')
         }
      } else {
         setErrorFormBoleto(true)
         setMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...')
         console.log('Erro ao comunicar com o servidor.')
      }

      setSendingPG(false)
      window.scrollTo(0, 950)
   }

   if ( goBack ) {
      return <Redirect to="/seu-carrinho" />
   }

   return (
         <React.Fragment>
            <header className="header-login">
            </header>

            <main>
               <section className="bloco-principal pg-pagamento-principal">
                  <div className="conteudo-bloco-principal">
                     <div className="bloco-2">
                        <div className="conteudo-generico bloco-pagamento-responsivo">
                           <div className="img-pg-pagamento">
                              <img src="../../favicon.png" className="img-radius" style={{border: '4px solid #303030'}} />
                              <div className="titulo-pg-pagamento">
                                 <h3>Fensor Flash</h3>
                                 <p style={{fontSize: '0.87em'}}>Confira se os dados estão todos corretos.</p>
                              </div>
                           </div>
                           <div className="texto-pg-pagamento">
                              <p style={{fontSize: '0.87em'}}>
                              Você escolheu fotos com alta definição.</p>
                              <p style={{fontSize: '0.87em'}}>
                                 <b>Observação:</b> para adicionar novas fotos a sua lista, volte para seu carrinho.
                              </p>
                           </div>
                           <div className="preco-pg-pagamento">
                              <hr />
                              <h4>Total {Intl.NumberFormat('pt-BR',{ style: 'currency', currency: 'BRL' }).format(priceFinal)}</h4>
                           </div>
                           <div className="botao-formulario">
                              <div className="botao-avancar-formulario" style={{justifyContent: 'center'}}>
                                 <button type="button" style={{backgroundColor: 'gainsboro'}} onClick={e => setGoBack(true)}>voltar para seu carrinho</button>
                              </div>
                           </div>
                           <div className="logo-pg-pagamento">
                              <p>Desenvolvido por</p>
                              <img src="../../img/logo-2.png" />
                           </div>
                        </div>
                     </div>
                     <div className="bloco-1-secundario">
                        <div className="dados-pagamento">
                           <div className="coluna-dados-pagamento">
                              <div className="tabs-titulos">
                                 <div className="guia-tab-pagamento">
                                    <p className="step">1</p>
                                    <span>Dados pessoais</span>
                                 </div>
                                 <div className="guia-tab-pagamento">
                                    <p className="step">2</p>
                                    <span>Pagamento</span>
                                 </div>
                                 <div className="guia-tab-pagamento">
                                    <p className="step">3</p>
                                    <span>Obrigado!</span>
                                 </div>
                              </div>
                              <div className="informacoes-pagamento">
                                 <form className="tab" onSubmit={handleValidaForm}>
                                    {
                                       errorFormClient &&
                                       <MessageDanger title="Atenção! " description={message} />
                                    }
                                    <input value={name} type="text" name="name" required placeholder="Seu nome completo" onChange={e => setName(e.target.value)} />
                                    <input value={email} type="email" name="email" required placeholder="Seu melhor email" onChange={e => setEmail(e.target.value)}/>
                                    <InputMask value={usuPhone} required type="tel" name="usuPhone" mask="(99)9 9999-9999" disableunderline="true" onChange={e => setUsuPhone(e.target.value)} placeholder="Seu celular"></InputMask>
                                    <InputMask value={usuCPF} required type="tel" name="usuCPF" mask="999.999.999-99" disableunderline="true" onChange={e => setUsuCPF(e.target.value)} placeholder="Seu CPF"></InputMask>
                                    <div className="botao-formulario">
                                       <div className="botao-avancar-formulario">
                                          <button type="submit" id="nextBtn">Próximo passo</button>
                                       </div>
                                    </div>
                                 </form> 
                              </div>
                              <div className="informacoes-pagamento">
                                 <div className="tab">
                                    <div className="forma-pagamento">   
                                       <div className="tipo-pagamento">
                                          <div className="aba-cartao">
                                             <a href="#" onClick={e => setCartao(e)}>Cartão</a>
                                          </div>
                                          <div className="aba-cartao">
                                             <a href="#" onClick={e => setBoleto(e)}>Boleto</a>
                                          </div>
                                       </div>
                                       <div className="item-tipo-pagamento">
                                          <form className="ind-tipo-pagamento" id="item1" onSubmit={handleValidaFormCartao}>
                                             <div className="estilo-cartao" style={{padding: '0px'}}>
                                                <div className="col-cartao">
                                                   <div className="input-forma-pagamento">  
                                                      {
                                                         errorFormCartao &&
                                                         <MessageDanger title="Atenção! " description={message} />
                                                      }
                                                      <input value={titularCartao} required type="text" name="titularCartao" placeholder="Títular do cartão" onChange={e => setTitularCartao(e.target.value)} />
                                                      <InputMask value={numeroCartao} required type="tel" name="numeroCartao" mask="9999 9999 9999 9999" disableunderline="true" onChange={e => setNumeroCartao(e.target.value)} placeholder="Número do cartão"></InputMask>
                                                   </div> 
                                                   <div className="bloco-cartao">
                                                      <div className="sobre-cartao">
                                                         <div className="col-1-val-cartao">
                                                            <label>Data validade</label>
                                                         </div>
                                                         <div className="col-2-val-cartao">
                                                            <div className="input-val-cartao">
                                                               <select name="mesValidade" required onChange={e => setMesValidade(e.target.value)}>
                                                                  <option value="">-- Selecione --</option>
                                                                  <option value="01">01</option>
                                                                  <option value="02">02</option>
                                                                  <option value="03">03</option>
                                                                  <option value="04">04</option>
                                                                  <option value="05">05</option>
                                                                  <option value="06">06</option>
                                                                  <option value="07">07</option>
                                                                  <option value="08">08</option>
                                                                  <option value="09">09</option>
                                                                  <option value="10">10</option>
                                                                  <option value="11">11</option>
                                                                  <option value="12">12</option>
                                                               </select>
                                                            </div>
                                                            <div className="input-val-cartao">
                                                               <select name="anoValidade" required onChange={e => setAnoValidade(e.target.value)}>
                                                                  <option value="">-- Selecione --</option>
                                                                  {
                                                                     anos.map((item,i) => <option value={item} key={"anos" + i}>{item}</option>)
                                                                  }
                                                               </select>
                                                            </div> 
                                                         </div>
                                                      </div>
                                                      <div className="sobre-cartao">
                                                         <label>Cód. de segurança</label>
                                                         <InputMask value={codSeguranca} required type="tel" name="anoValidade" mask="9999" disableunderline="true" onChange={e => setCodSeguranca(e.target.value)}></InputMask>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-cartao">
                                                   <div className="img-cartao">
                                                      <img src="../../img/pagamento/cartao.png" />
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="bloco-2-estilo-boleto" style={{paddingTop: '10px'}}>
                                                <div className="col-estilo-boleto" style={{marginRight: '1%'}}>
                                                   <label>Opções</label>
                                                   <select name="parcelasPagamento" required onChange={e => setParcelasPagamento(e.target.value)}>
                                                      <option value="1">1x de {Intl.NumberFormat('pt-BR',{ style: 'currency', currency: 'BRL' }).format(priceFinal)}</option>
                                                   </select>
                                                </div>
                                                <div className="col-estilo-boleto" style={{marginRight: '1%'}}>
                                                   <label>CEP</label>
                                                   <input type="text" maxLength="8"  name="postalCode" value={postalCode} placeholder="Seu cep" onChange={e => setPostalCode(e.target.value)}/> 
                                                </div>

                                                <div className="col-estilo-boleto" style={{marginRight: '1%'}}>
                                                   <label>Número</label>
                                                   <input type="number" name="addressNumber" value={addressNumber} placeholder="Número da sua residência" onChange={e => setAddressNumber(e.target.value)}/> 
                                                </div>
                                             </div>
                                             <div className="botao-formulario">
                                                <div className="botao-voltar-formulario">
                                                   <button type="button" id="prevBtn" onClick={e => nextPrev(-1)}>Voltar</button>
                                                </div>
                                                <div className="botao-avancar-formulario">
                                                   {
                                                      sendingPG && <span>Processando o pagamento... <Loading type="3" /></span>
                                                   }
                                                   {
                                                      !sendingPG && <button type="submit" id="nextBtn">Comprar agora</button>
                                                   }
                                                </div>
                                             </div>
                                          </form>
                                          <form className="ind-tipo-pagamento" id="item2" onSubmit={handleValidaFormBoleto}>
                                             <div className="estilo-boleto">
                                                <div className="bloco-1-estilo-boleto">
                                                   <h3>Não se esqueça!</h3>
                                                   <p>- Boleto (somente à vista)</p>
                                                   <p>- Pagamentos com boleto bancário levam até 3 dias úteis para serem compensados.</p>
                                                   <p>- Fique atento com o vencimento dos boletos. Você pode pagá-lo em qualquer banco ou casa lotérica.</p>
                                                   <p>- Depois do pagamento, verifique seu email para receber os dados de confirmação.</p>
                                                   {
                                                      errorFormBoleto && 
                                                      <MessageDangerMarginTop title="Atenção! " description={message} />
                                                   }
                                                </div>
                                             </div>
                                             <div className="botao-formulario">
                                                <div className="botao-voltar-formulario">
                                                   <button type="button" id="prevBtn" onClick={e => nextPrev(-1)}>Voltar</button>
                                                </div>
                                                <div className="botao-avancar-formulario">
                                                   {
                                                      sendingPG && <span>Processando o pagamento... <Loading type="3" /></span>
                                                   }
                                                   {
                                                      !sendingPG && <button type="submit" id="nextBtn">Comprar agora</button>
                                                   }
                                                </div>
                                             </div>
                                          </form>
                                       </div>
                                    </div> 
                                 </div>
                                 <div className="informacoes-pagamento">
                                    <div className="tab">
                                       <div className="final-formulario">
                                          {
                                             tipoPagamento == 1 && <span>
                                                <h3>Parabéns! Você adquiriu novas fotos :)</h3>
                                                <p style={{fontSize: '0.95em'}}>Agora você pode visualizar e/ou realizar os downloads das fotos, clique abaixo.</p>
                                             </span>
                                          }
                                          {
                                             tipoPagamento == 2 && <span>
                                                <h3>Atenção! Falta pouco para adquirir novas fotos :)</h3>
                                                <p style={{fontSize: '0.95em'}}>Agora você pode imprimir o boleto e realizar a compra, clique abaixo.</p>
                                             </span>
                                          }
                                          
                                       </div>
                                       <div className="botao-formulario">
                                          <div className="botao-avancar-formulario">
                                             {
                                                tipoPagamento == 1 && <Link to="/minhas-fotos">SUAS FOTOS</Link>
                                             }
                                             {
                                                tipoPagamento == 2 && <a href={urlBoleto} download={urlBoleto} target="_blank">IMPRIMIR BOLETO</a>
                                             }
                                          </div>
                                       </div>
                                    </div> 
                                 </div> 
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </main>
         </React.Fragment>
   )
}

export default PagamentoFotos