import React, { Component } from 'react'

import CardResgate from '../../components/card-resgate/CardResgate'
import CTA from '../../components/Buttons/btn-cta/BtnCTA'
import Footer from '../../components/footer/Footer'

import ImgCard from '../../assets/images/card_resgate.jpg'

const Resgate = (props) => {
    
    window.scrollTo(0, 0);

    return (
        <React.Fragment>
            <div className="mainContainer">
                <h1 className="title">resgates exclusivos assinantes fensor</h1>
                <p className="descript">
                    <b>Atenção:</b><br />
                        Verifique as regras de resgate de cada produto, serviço ou experiência. O benefício é limitado a 01 (um) resgate por dia.
                </p>
                
                <CardResgate
                
                title={props.location.state.title}
                tumblr={props.location.state.tumblr}
                subTitle={props.location.state.subTitle}
                descript={props.location.state.descript}
                points={props.location.state.points}
                color={props.location.state.color}
                id={props.location.state.id}
                athlete_id={props.location.state.athlete_id}

                />

                <p className="descript text-bold">
                    Garanta mais vantagens entrando no nosso Clube de Parceiros.
                </p>
                <CTA />
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Resgate