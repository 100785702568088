import axios from 'axios';
import Api from '../utils/api';
const baseURL = Api();

export const updateSchoolScreenConfig = async (schoolIds, permissionId) => {
  try {
    const { data } = await axios.post(baseURL + 'set-school-screen-config-admin', { schoolIds, permissionId });
    return data;
  } catch (error) {
    return { success: false, error: error };
  }
};
