import api from './api';

export async function getListAccounts(tokenAsaas) {
  try {
    return (
      await api.post('list-accounts-asaas', {
        tokenAsaas,
      })
    ).data;
  } catch (e) {
    return e.message;
  }
}

export async function saveAccount(data) {
  try {
    return await api.post('save-account-asaas', data);
  } catch (e) {
    return e.message;
  }
}

export async function getCountClubSchoolAsaas() {
  try {
    return (await api.get('count-club-school-asaas')).data;
  } catch (e) {
    return e.message;
  }
}

export async function saveTokenSchool({ token, cpfCnpjAsaas, loginEmail, walletID, id }) {
  try {
    return (await api.post('save-token-school', { token, cpfCnpjAsaas, loginEmail, walletID, id })).data;
  } catch (e) {
    return e.message;
  }
}

export async function saveTokenClub({ token, cpfCnpjAsaas, loginEmail, walletID, id }) {
  try {
    return (await api.post('save-token-club', { token, cpfCnpjAsaas, loginEmail, walletID, id })).data;
  } catch (e) {
    return e.message;
  }
}
