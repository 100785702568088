import React, { useEffect, useState, useRef } from 'react'
import InputMask from 'react-input-mask'
import Header from '../../components/club/Header'
import Footer from '../../components/club/Footer'
import Loading from '../../components/Loading'
import Message from '../../components/Message'
import Utils, { correctImageOrientation, getErrorMessageFromFileResponse, getUserPhoto } from '../../../src/utils/utils'
import { Link } from 'react-router-dom'
import Api from '../../utils/api'
import axios from 'axios'
import { getStates, verifyEmail as verifyUserEmail } from '../../services/SharedService'

const Employee = () => {

   const [name, setName] = useState("")
   const [birth, setBirth] = useState("")
   const [phone, setPhone] = useState("")
   const [cpf, setCPF] = useState("")
   const [email, setEmail] = useState("")
   const [cep, setCEP] = useState("")
   const [endereco, setEndereco] = useState("")
   const [bairro, setBairro] = useState("")
   const [estado, setEstado] = useState("")
   const [dataInicio, setDataInicio] = useState("")
   const [role, setRole] = useState("")
   const [sobre, setSobre] = useState("")
   const [states, setStates] = useState([])
   const [citys, setCitys] = useState([])
   const [city_id, setCityID] = useState("")
   const [id, setID] = useState(null)
   const [companie_id, setCompanie_id] = useState("")
   const [foto, setFoto] = useState("")

   // carregamento
   const [filterName, setFilterName] = useState("")
   const [filterFunction, setFilterFunction] = useState("")
   const [employees, setEmployees] = useState([])
   const [employeesFilter, setEmployeesFilter] = useState([])
   const [dirUsuFotoPrincipal, setDirUsuFotoPrincipal] = useState("")
   const [display, setDisplay] = useState({})

   // process
   const [process, setProcess] = useState(false)
   const [sendCity, setSendingCity] = useState(false)
   const [sendCep, setSendingCep] = useState(false)
   const [sendSave, setSendSave] = useState(false)
   const [sendEmail, setSendEmail] = useState(false)
   const [sendUpload, setSendingUpload] = useState(false)

   // messages
   const [error, setError] = useState(false)
   const [success, setSuccess] = useState(false)
   const [message, setMessage] = useState("")

   // libraries
   const baseURL = Api()
   const { replaceAll, scrollTop } = Utils()
   const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message()
   let modalScroll = useRef()

   useEffect(() => {

      async function getSession() {
         const companySession = JSON.parse(await localStorage.getItem('company'))
         setCompanie_id(companySession.id)
         getEmployees(companySession.id)
      }

      async function getEmployees(company_id) {

         setProcess(true)
         const res = await axios.post(baseURL + 'get-employees-club', {
            company_id
         })

         setDirUsuFotoPrincipal(res.data.dir_usuFotoPrincipal)
         setEmployees(res.data.employees)
         setEmployeesFilter(res.data.employees)
         setProcess(false)
      }

      async function fetchStates() {
         const res = await getStates()
         setStates(res.states)
      }

      getSession()
      fetchStates()

   }, [])

   const getCitys = async (estado, localidade = '') => {
      setCitys([])
      setEstado(estado)
      setSendingCity(true)
      const res = await axios.post(baseURL + 'get-citys-by-state-sigla', {
         staStateSigla: estado
      })
      setSendingCity(false)

      for (let i = 0; i < res.data.cidades.length; i++) {
         let citCity = res.data.cidades[i].citCity
         if (citCity == localidade) {
            setCityID(res.data.cidades[i].id)
         }
      }

      setCitys(res.data.cidades)
   }

   const buscaCEP = async () => {

      const cepAux = replaceAll(replaceAll(cep, '-', ''), '_', '')

      if (cepAux.length == 8) {
         setEndereco("")
         setBairro("")
         setEstado("")
         setCitys([])
         setCityID("")

         setSendingCep(true)
         const res = await axios.get('https://viacep.com.br/ws/' + cepAux + '/json/')
         setSendingCep(false)

         if (res.data.erro) {
            setEndereco("")
            setBairro("")
            setEstado("")
            setCitys([])
            setCityID("")
         } else {
            setEndereco(res.data.logradouro)
            setBairro(res.data.bairro)
            setEstado(res.data.uf)
            getCitys(res.data.uf, res.data.localidade.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
         }
      }
   }

   const search = (e) => {
      e.preventDefault()

      if (filterName !== '' && filterFunction !== '') {
         let registers = employees.filter((item) => {
            return item.name.toLowerCase().includes(filterName.toLowerCase()) && item.role_id == filterFunction
         })

         setEmployeesFilter(registers)
      } else if (filterName == '' && filterFunction !== '') {
         let registers = employees.filter((item) => {
            return item.role_id == filterFunction
         })

         setEmployeesFilter(registers)
      } else if (filterName !== '' && filterFunction === '') {
         let registers = employees.filter((item) => {
            return item.name.toLowerCase().includes(filterName.toLowerCase())
         })

         setEmployeesFilter(registers)
      } else {
         setEmployeesFilter(employees)
      }
   }

   const openAdd = () => {
      setError(false)
      setSuccess(false)
      setName("")
      setCPF("")
      setBirth("")
      setPhone("")
      setEmail("")
      setCEP("")
      setEndereco("")
      setBairro("")
      setEstado("")
      setDataInicio("")
      setRole("")
      setSobre("")
      setCitys([])
      setCityID("")
      setID(null)
      setFoto("")

      setDisplay({
         display: "flex",
         opacity: 1
      })
   }

   const closeAdd = () => {
      setDisplay({})
   }

   const verifyEmail = async (email) => {
      setSendEmail(true);
      const { error, message } = await verifyUserEmail({ email, id: id });
      setSendEmail(false);

      if (error !== false) {
         setEmail('');
         setError(true);
         setMessage(message);
         scrollTop(modalScroll);
      } else {
         setError(false);
         setMessage('');
         setEmail(email);
      }
   };

   const handleSubmit = async (e) => {
      e.preventDefault()
      setError(false)
      setSuccess(false)

      let data = {}
      let endpoint = ''
      if (id === null) {
         endpoint = 'save-employee-club'
         data = {
            companie_id,
            name,
            usuCPF: cpf,
            usuBirth: birth,
            usuPhone: phone,
            email: email,
            usuCEP: cep,
            usuEndereco: endereco,
            city_id,
            usuBairro: bairro,
            usuDataInicioSchool: dataInicio,
            role_id: role,
            usuSobre: sobre,
            password: email,
            usuStatus: 'A'
         }
      } else {
         endpoint = 'update-employee-club'
         data = {
            id,
            companie_id,
            name,
            usuCPF: cpf,
            usuBirth: birth,
            usuPhone: phone,
            email: email,
            usuCEP: cep,
            usuEndereco: endereco,
            city_id,
            usuBairro: bairro,
            usuDataInicioSchool: dataInicio,
            role_id: role,
            usuSobre: sobre,
            usuStatus: 'A'
         }
      }

      setSendSave(true)
      const res = await axios.post(baseURL + endpoint, data)
      setSendSave(false)
      setSuccess(true)
      setMessage(res.data.create.message)
      setID(res.data.create.id)
      setEmployees(res.data.employees)
      setEmployeesFilter(res.data.employees)
      scrollTop(modalScroll)
   }

   const handleFoto = async (e) => {
      const foto = await correctImageOrientation(e.target.files[0])
      const formData = new FormData()
      formData.append('company_id', companie_id)
      formData.append('foto', foto)
      formData.append('id', id)

      setError(false)
      setSuccess(false)
      setSendingUpload(true)
      const res = await axios.post(baseURL + 'upload-employee-club', formData, { headers: { 'Content-Type': 'multipart/form-data' } })

      if (res.data.success === false) {
         setError(true)
         setSuccess(false)
         if (res.data.erro != null) {
            const msgs = getErrorMessageFromFileResponse(res.data.erro)
            setMessage(msgs)
         } else {
            const msgs = res.data.msg
            setMessage(msgs)
         }
      } else {
         setSuccess(true)
         setMessage(res.data.msg)
         setFoto(res.data.arq)
         setEmployees(res.data.employees)
         setEmployeesFilter(res.data.employees)
      }

      setSendingUpload(false)

   }

   const edit = (e, user) => {
      setError(false)
      setSuccess(false)

      setID(user.id)
      setName(user.name)
      setCPF(user.usuCPF)
      setBirth(user.usuBirth)
      setPhone(user.usuPhone)
      setEmail(user.email)
      setCEP(user.usuCEP)
      setEndereco(user.usuEndereco)
      setBairro(user.usuBairro)
      setEstado(user.staStateSigla)
      setDataInicio(user.usuDataInicioSchool)
      setRole(user.role_id)
      setSobre(user.usuSobre)
      getCitys(user.staStateSigla, user.citCity)
      setCityID(user.city_id)
      setCompanie_id(user.companie_id)

      if (user.usuFotoPrincipal !== null)
         setFoto(dirUsuFotoPrincipal + '/' + user.usuFotoPrincipal)

      setDisplay({
         display: "flex",
         opacity: 1
      })
   }

   const setErrorFoto = () => {
      setError(true)
      setMessage('O funcionário precisa ser salvo antes do enviar da Foto!')
   }

   return (
      <main>
         <div className="container-fluid container_painel">
            <div className="container">
               <div className="row content_painel">



                  <main className="content_panel">
                     <div className="sidebar">
                        <div className="card">
                           <div className="card_header">
                              <h2 className="card_title">Adicionar Funcionário</h2>
                              <p className="card_subtitle">Clique abaixo para adicionar um funcionário.</p>
                           </div>
                           <button onClick={openAdd} className="btn_card mx-auto mt-4 btn_open_modal" data-modal='#add_funcionario'>
                              <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path
                                    d="M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z" />
                              </svg>
                              Adicionar funcionário
                           </button>
                        </div>
                        <div className="card">
                           <div className="card_header">
                              <h2 className="card_title">Procurar</h2>
                              <p className="card_subtitle">Filtrar os tipos de funcionários.</p>
                           </div>
                           <form action="" method="post" className="form_card">
                              <div className="form_group">
                                 <label htmlFor="name" className="card_details_label">Nome</label>
                                 <input type="text" className="form_control" onChange={e => setFilterName(e.target.value)} />
                              </div>
                              <div className="form_group">
                                 <label htmlFor="position" className="card_details_label">Função</label>
                                 <select className="form_control select" value={filterFunction} onChange={e => setFilterFunction(e.target.value)}>
                                    <option value="">Todas</option>
                                    <option value="3">Diretor(a)</option>
                                    <option value="12">Gestor(a)</option>
                                    <option value="13">Professor(a)</option>
                                    <option value="14">Secretário(a)</option>
                                    <option value="15">Coordenador(a)</option>
                                    <option value="16">Estagiário(a)</option>
                                    <option value="18">Fisioterapeuta</option>
                                    <option value="19">Marketing</option>
                                    <option value="17">Outro(a)</option>
                                 </select>
                              </div>
                              <button className="btn_card mx-auto" onClick={search}>
                                 <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                       d="M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z" />
                                 </svg>
                                 Buscar
                              </button>
                           </form>
                        </div>
                     </div>
                     <div className="content">
                        <div className="card">
                           <div className="card_header">
                              <h2 className="card_title">Funcionários</h2>
                              <p className="card_subtitle">Veja abaixo o seu quadro de funcionários. </p>
                           </div>
                           <div className="list_card">
                              {
                                 process &&
                                 <p className="loading-card"><Loading type="1" /></p>
                              }
                              {
                                 !process &&
                                 employeesFilter.map((item, i) => {
                                    return <div className="card_list" key={`employees_${i}`}>
                                       <div className="img_list_user">
                                          <img src={getUserPhoto(dirUsuFotoPrincipal + '/', item.usuFotoPrincipal)} alt="" />
                                       </div>
                                       <div className="content_card">
                                          <div className="card_details">
                                             <p className="card_details_title" title={item.name}>{(item.name.length > 20 ? item.name.substr(0, 20) + '...' : item.name)}</p>
                                          </div>
                                          <div className="card_details">
                                             <p className="card_details_label">Função: </p>
                                             <p className="card_details_text">{item.rolRole}</p>
                                          </div>
                                       </div>
                                       <Link
                                          style={{ marginBottom: 10, fontWeight: 500 }}
                                          to={{
                                             pathname: '/detail-employee-club',
                                             state: {
                                                employee: item,
                                                photo: dirUsuFotoPrincipal + '/' + item.usuFotoPrincipal
                                             }
                                          }} className="btn_card">
                                          <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path
                                                d="M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z" />
                                          </svg>
                                          Ver funcionário
                                       </Link>
                                       <span
                                          style={{ backgroundColor: 'orange', fontWeight: 500, border: '1px solid orange' }}
                                          onClick={e => edit(e, item)} className="btn_card">
                                          <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path
                                                d="M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z" />
                                          </svg>
                                          Edit funcionário
                                       </span>
                                    </div>
                                 })
                              }
                           </div>
                        </div>
                     </div>
                     <div className="modal" id="add_funcionario" style={display}>
                        <div className="modal_content" ref={modalScroll}>
                           <div className="modal_close" onClick={closeAdd}>X</div>
                           <h3 className="modal_title">Incluir funcionário</h3>
                           {
                              error &&
                              <MessageDangerMarginTop title="Atenção! " description={message} />
                           }
                           {
                              success &&
                              <MessageSuccessMarginTop title="Sucesso! " description={message} />
                           }
                           <form action="" onSubmit={handleSubmit} method="post" className="form_modal">
                              <div className="group_50">
                                 <div className="form_group">
                                    <label htmlFor="name" className="card_details_label">Nome</label>
                                    <input type="text" required className="form_control" value={name} onChange={e => setName(e.target.value)} />
                                 </div>
                                 <div className="form_group">
                                    <label htmlFor="data" className="card_details_label">Data de nascimento</label>
                                    <input type="date" className="form_control" value={birth} onChange={e => setBirth(e.target.value)} />
                                 </div>
                                 <div className="group_50">
                                    <div className="form_group">
                                       <div className="form_group">
                                          <label htmlFor="name" className="card_details_label">CPF</label>
                                          <InputMask value={cpf} className="form_control" mask="999.999.999-99" type="tel" disableunderline="true" onChange={e => setCPF(replaceAll(replaceAll(replaceAll(e.target.value, '.', ''), '-', ''), '-', ''))} ></InputMask>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="group_50">
                                    <div className="form_group">
                                       <label htmlFor="tel" className="card_details_label">Celular</label>
                                       <InputMask className="form_control" value={phone} required type="tel" mask="(99)9 9999-9999" disableunderline="true" onChange={e => setPhone(replaceAll(replaceAll(replaceAll(replaceAll(replaceAll(e.target.value, '(', ''), ')', ''), '-', ''), ' ', ''), '_', ''))}></InputMask>
                                    </div>
                                 </div>
                                 <div className="form_group">
                                    <label htmlFor="email" className="card_details_label" style={{ flexDirection: "row" }}>
                                       E-mail
                                       {
                                          sendEmail &&
                                          <Loading type="1" style={{ marginLeft: '2%' }} />
                                       }
                                    </label>
                                    <input type="email" required className="form_control" value={email} onChange={e => setEmail(e.target.value)} onBlur={e => verifyEmail(e.target.value)} />
                                 </div>
                              </div>
                              {
                                 sendUpload &&
                                 <div className="group_50" style={{ justifyContent: 'center', alignItems: 'center', background: '#218135', borderRadius: '10px', color: 'white', flexDirection: "column" }}>
                                    <label htmlFor="file">Aguarde, estamos enviando...</label>
                                    <Loading type="3" style={{ color: 'white', marginTop: 5 }} />
                                 </div>
                              }
                              {
                                 foto !== '' && !sendUpload &&
                                 <div className="group_50" style={{ justifyContent: 'space-around' }}>
                                    <img style={{ borderRadius: 10, marginBottom: 10, width: '90%' }} src={foto} />
                                    <label style={{ cursor: 'pointer', padding: '12px 22px', borderRadius: '50px', border: '1px solid rgb(2, 132, 44)', color: 'rgb(2, 132, 44)', fontSize: '13px', justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                       <input type="file" name="file[]" onChange={e => handleFoto(e)} />
                                       Alterar foto
                                    </label>
                                 </div>
                              }
                              {
                                 id !== null && foto == '' && !sendUpload &&
                                 <div className="group_50 upload_file">
                                    <label htmlFor="file"></label>
                                    <input type="file" name="file[]" id="file" onChange={e => handleFoto(e)} style={{ display: 'initial', opacity: 0, cursor: 'pointer' }} />
                                 </div>
                              }
                              {
                                 id === null && foto == '' && !sendUpload &&
                                 <div className="group_50 upload_file" onClick={() => setErrorFoto()}>
                                    <label htmlFor="file"></label>
                                 </div>
                              }
                              <div className="form_group">
                                 <label htmlFor="name" className="card_details_label" style={{ flexDirection: "row" }}>
                                    CEP
                                    {
                                       sendCep &&
                                       <Loading type="1" style={{ marginLeft: '2%' }} />
                                    }
                                 </label>
                                 <InputMask value={cep} className="form_control" mask="99999-999" type="tel" disableunderline="true" onBlur={buscaCEP} onChange={e => setCEP(replaceAll(replaceAll(e.target.value, '-', ''), '_', ''))} ></InputMask>
                              </div>
                              <div className="group_50">
                                 <div className="form_group">
                                    <label htmlFor="name" className="card_details_label">Endereço</label>
                                    <input type="text" value={endereco} className="form_control" onChange={e => setEndereco(e.target.value)} />
                                 </div>
                                 <div className="form_group">
                                    <label htmlFor="name" className="card_details_label" style={{ flexDirection: "row" }}>
                                       Cidade
                                       {
                                          sendCity &&
                                          <Loading type="1" style={{ marginLeft: '2%' }} />
                                       }
                                    </label>
                                    <select className="form_control select" value={city_id} onChange={e => setCityID(e.target.value)}>
                                       <option value="">-- Selecione --</option>
                                       {
                                          citys.map(item => <option value={item.id} key={item.citCity}>{item.citCity}</option>)
                                       }
                                    </select>
                                 </div>
                              </div>
                              <div className="group_50">
                                 <div className="form_group">
                                    <label htmlFor="tel" className="card_details_label">Estado</label>
                                    <select className="form_control select" value={estado} onChange={e => getCitys(e.target.value, '')}>
                                       <option value="">-- Selecione --</option>
                                       {
                                          states.map(item => <option value={item.staStateSigla} key={item.staState}>{item.staState}</option>)
                                       }
                                    </select>
                                 </div>
                                 <div className="form_group">
                                    <label htmlFor="tel" className="card_details_label">Bairro</label>
                                    <input type="text" value={bairro} className="form_control" onChange={e => setBairro(e.target.value)} />
                                 </div>
                              </div>
                              <div className="group_50">
                                 <div className="form_group">
                                    <label htmlFor="date" className="card_details_label">Data de inicio</label>
                                    <input type="date" required name="date" value={dataInicio} className="form_control" onChange={e => setDataInicio(e.target.value)} />
                                 </div>
                              </div>
                              <div className="group_50">
                                 <div className="form_group">
                                    <label htmlFor="position" className="card_details_label">Função</label>
                                    <select required className="form_control select" value={role} onChange={e => setRole(e.target.value)} >
                                       <option value="">-- Selecione --</option>
                                       <option value="3">Diretor(a)</option>
                                       <option value="12">Gestor(a)</option>
                                       <option value="13">Professor(a)</option>
                                       <option value="14">Secretário(a)</option>
                                       <option value="15">Coordenador(a)</option>
                                       <option value="16">Estagiário(a)</option>
                                       <option value="18">Fisioterapeuta</option>
                                       <option value="19">Marketing</option>
                                       <option value="17">Outro(a)</option>
                                    </select>
                                 </div>
                              </div>
                              <div className="form_group">
                                 <label htmlFor="descript" className="card_details_label">Descrição</label>
                                 <textarea className="form_control" value={sobre} id="" cols="30" rows="10" onChange={e => setSobre(e.target.value)}></textarea>
                              </div>
                              {
                                 !sendSave &&
                                 <button className="btn_card mx-auto">
                                    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path
                                          d="M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z" />
                                    </svg>
                                    {
                                       id === null &&
                                       'Incluir'
                                    }
                                    {
                                       id !== null &&
                                       'Alterar'
                                    } funcionário
                                 </button>
                              }
                              {
                                 sendSave &&
                                 <button className="btn_card mx-auto" style={{ background: '#ccc', border: 'none' }} disabled={sendSave}>
                                    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path
                                          d="M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z" />
                                    </svg>
                                    processando...<Loading type="1" style={{ marginLeft: '2%' }} />
                                 </button>
                              }
                           </form>
                        </div>
                     </div>
                  </main>

                  <Footer />
               </div>

            </div>
         </div>
      </main>
   )
}

export default Employee