import React from 'react';
import Table from 'react-bootstrap/Table';
import { BsClipboardCheck, BsPencil, BsTrash } from 'react-icons/bs';
import { RiFileUploadLine } from 'react-icons/ri';
import Utils from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const ProductsDetailsTable = ({ productsDetails, onClickStockAdjustment }) => {
    const { t } = useTranslation();
    const { dateFormat, currencyFormatterText } = Utils();

    const defaultColHeaderStyle = {
        border: 'none',
        fontSize: '12px',
        width: '200px',
        color: '#3b3b3b',
        fontWeight: 600,
        alignItems: 'center',
        padding: '0 30px',
        justifyContent: 'start'
    };

    const defaultColRowStyle = {
        alignItems: 'center',
        width: '200px',
        height: '100%',
        border: 'none',
        justifyContent: 'start',
        padding: '0 30px',
    };

    return (
        <Table responsive style={{ boxShadow: 'none', marginTop: '20px', minWidth: '625px' }}>
            <thead>
                <tr style={{
                    marginBottom: '5px',
                    borderRadius: '10px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'var(--main-color)'
                }}>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Tamanho
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Quantidade
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Valor unitário
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Valor Total
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Ação
                    </th>
                </tr>
            </thead>
            <tbody>
                {productsDetails && productsDetails.map((item, i) => (
                    <tr key={'ProducsTable_tbody_row' + i} style={{ marginBottom: '12px' }}>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.size_product}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.amount}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {currencyFormatterText(item.unitary_value)}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {currencyFormatterText(item.value_total)}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            <div className='d-flex' style={{ gap: '10px', cursor: 'pointer' }}>
                                <BsClipboardCheck onClick={() => onClickStockAdjustment(item)} color='#00A4EA' size={15} />
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default ProductsDetailsTable;