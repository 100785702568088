import React from 'react';
import Input from './Input';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const PasswordInput = ({ style, visibleState, ...props }) => {
  return (
    <div className='position-relative w-100'>
      <Input
        {...props}
        readOnly
        label='Token de acesso à API'
        style={{ paddingRight: '25px', ...style }}
        type={visibleState.visible ? 'text' : 'password'}
      />
      <span
        className='clickable position-absolute'
        onClick={() => visibleState.setVisible((current) => !current)}
        style={{ top: '36px', right: '4px' }}
      >
        {visibleState.visible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
      </span>
    </div>
  );
};

export default PasswordInput;
