import { defaultApiObject, handleFail } from '../utils/utils-api';
import api from './api';

export const getAllAvailableStudentDocuments = async (schoolId) => {
  try {
    const { data } = await api.get(`get-all-available-student-documents/${schoolId}`);

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const setMandatoryStudentsDocuments = async ({ schoolId, documentIds }) => {
  try {
    const { data } = await api.post('set-mandatory-students-documents', { schoolId, documentIds });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const getClubSchoolDocuments = async () => {
  try {
    const { data } = await api.get('club/club-school-documents');

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const createClubSchoolDocument = async ({ file }) => {
  try {
    const formData = new FormData();
    formData.append('document', file);

    const { data } = await api.post('club/club-school-documents', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const updateClubSchoolDocument = async ({ documents }) => {
  try {
    const { data } = await api.put('club/club-school-documents', { documents });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const deleteClubSchoolDocument = async (documentId) => {
  try {
    const { data } = await api.delete(`club/club-school-documents/${documentId}`);

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const getClubSchoolDocumentsBySchool = async () => {
  try {
    const { data } = await api.get('school/club-school-documents');

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};