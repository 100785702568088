import React, { useState, forwardRef, useCallback, useMemo } from 'react';
import { handleOnChange } from '../../../../src/utils/utils';
import { ModalWithMethods } from '../../../components/modal/Modal';
import { useTranslation } from 'react-i18next';
import { fetchAllCategories } from '../../../services/CategoriesService';
import { fetchLessonContentSportsBySport } from '../../../services/LessonContentSports';
import { fetchLessonDidacticStrategy } from '../../../services/LessonDidacticStrategy';
import { fetchLessonSession } from '../../../services/LessonSession';
import { fetchLessonMaterials } from '../../../services/LessonMaterials';
import { Upload } from '../../../components/svgs/Upload';
import { Success } from '../../../components/svgs/Success';
import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

const EditLesson = ({ lesson, setLesson, onSubmit, ...props }, ref) => {
  yup.setLocale(pt);
  const { t } = useTranslation();

  const [categories, setCategories] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([])
  const [contentSports, setContentSports] = useState([]);
  const [contentSportsSelected, setContentSportsSelected] = useState([]);
  const [didacticStrategy, setDidacticStrategy] = useState([]);
  const [didacticStrategySelected, setDidacticStrategySelected] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [materialsSelected, setMaterialsSelected] = useState([]);
  const [file, setFile] = useState();
  const [previewFile, setPreviewFile] = useState();

  // process
  const [success, setSuccess] = useState(false);

  const onShow = () => {
    setSuccess(false);
    ref.current.clearMessage();
    ref.current.scrollTop();
    getAllCategories()
    getLessonContentSportsBySport()
    getLessonDidacticStrategy()
    getLessonSession()
    getLessonMaterials()
    setCategoriesSelected(lesson?.categories || []);
    setContentSportsSelected(lesson?.contents || []);
    setDidacticStrategySelected(lesson?.strategies || []);
    setMaterialsSelected(lesson?.materials || []);
  };

  const yupObject = yup.object().shape({
    feedback: yup.string().required().label('Feedback'),
    variation: yup.string().required().label('Variação'),
    score: yup.string().required().label('Pontuação'),
    rules: yup.string().required().label('Regras'),
    description: yup.string().required().label('Descrição'),
    numberOfPlayers: yup.number().min(1).required().label('N. de jogadores'),
    materials: yup.array().min(1).required().label('Material'),
    lesson_session_id: yup.number().required().label('Sessão'),
    time: yup.string().required().label('Tempo'),
    strategies: yup.array().min(1).required().label('Estratégia'),
    space: yup.string().required().label('Espaço'),
    concept: yup.string().required().label('Conceito'),
    contents: yup.array().min(1).required().label('Conteúdo'),
    status: yup.string().required().label('Status'),
    categories: yup.array().min(1).required().label('Categoria'),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    ref.current.clearMessage();

    const data = {
      id: lesson.id,
      status: lesson.status,
      description: lesson.description,
      videoURL: lesson.videoURL,
      concept: lesson.concept,
      space: lesson.space,
      time: lesson.time,
      lesson_session_id: lesson.lesson_session_id,
      numberOfPlayers: lesson.numberOfPlayers,
      rules: lesson.rules,
      score: lesson.score,
      variation: lesson.variation,
      feedback: lesson.feedback,
      categories: categoriesSelected ? categoriesSelected?.map((category) => category.id) : [],
      contents: contentSportsSelected ? contentSportsSelected?.map((content) => content.id) : [],
      strategies: didacticStrategySelected ? didacticStrategySelected?.map((strategy) => strategy.id) : [],
      materials: materialsSelected ? materialsSelected?.map((material) => material.id) : []
    }

    try {
      await yupObject.validate(data);

      const dataFile = file?.name
        ? {
          artUrl: file,
        }
        : {};
      const request = { ...data, ...dataFile };
      const formData = new FormData();
      Object.keys(request).forEach((key) => {
        formData.append(key, request[key]);
      })

      const {
        status,
        messageKey,
      } = await onSubmit(lesson.id, formData);
      if (status === 'SUCCESS') {
        setSuccess(true);
      } else {
        showErrorMessage(messageKey);
      }
    } catch (error) {
      showErrorMessage(error.errors);
    }
  };

  const showErrorMessage = (message) => {
    ref.current.showErrorMessage(message);
    ref.current.scrollTop();
  };

  const getAllCategories = async () => {
    const response = await fetchAllCategories();
    setCategories(response?.categories);
  }

  const getLessonContentSportsBySport = async () => {
    const response = await fetchLessonContentSportsBySport();
    setContentSports(response?.contentSports);
  }

  const getLessonDidacticStrategy = async () => {
    const response = await fetchLessonDidacticStrategy();
    setDidacticStrategy(response.didacticStrategies)
  }

  const getLessonSession = async () => {
    const response = await fetchLessonSession();
    setSessions(response.sessions)
  }

  const getLessonMaterials = async () => {
    const response = await fetchLessonMaterials();
    setMaterials(response.materials)
  }

  const addCategories = useCallback((e) => {
    const id = Number(e.target.value)
    const name = e.target.selectedOptions[0].label
    if (id && !categoriesSelected?.find((value) => value.id === id)) setCategoriesSelected([{ id, name }])
  }, [categoriesSelected])

  const addContentSports = useCallback((e) => {
    const id = Number(e.target.value)
    const name = e.target.selectedOptions[0].label
    if (id && !contentSportsSelected?.find((value) => value.id === id)) setContentSportsSelected((old) => [...old, { id, name }])
  }, [contentSportsSelected])

  const removeContentSports = useCallback((value) => {
    const contentSports = contentSportsSelected.filter((contentSport) => contentSport.id !== value.id)
    if (contentSports.length === 0) setLesson((old) => { return { ...old, content_sport_id: 0 } });
    setContentSportsSelected(contentSports)
  }, [contentSportsSelected])

  const addDidacticStrategy = useCallback((e) => {
    const id = Number(e.target.value)
    const name = e.target.selectedOptions[0].label
    if (id && !didacticStrategySelected?.find((value) => value.id === id)) setDidacticStrategySelected((old) => [...old, { id, name }])
  }, [didacticStrategySelected])

  const removeDidacticStrategy = useCallback((value) => {
    const didacticStrategies = didacticStrategySelected.filter((didacticStrategy) => didacticStrategy.id !== value.id)
    if (didacticStrategies.length === 0) setLesson((old) => { return { ...old, didactic_strategy_id: 0 } });
    setDidacticStrategySelected(didacticStrategies)
  }, [didacticStrategySelected])

  const addMaterial = useCallback((e) => {
    const id = Number(e.target.value)
    const name = e.target.selectedOptions[0].label
    if (id && !materialsSelected?.find((value) => value.id === id)) setMaterialsSelected((old) => [...old, { id, name }])
  }, [materialsSelected])

  const removeMaterial = useCallback((value) => {
    const materials = materialsSelected.filter((material) => material.id !== value.id)
    if (materials.length === 0) setLesson((old) => { return { ...old, material_id: 0 } });
    setMaterialsSelected(materials)
  }, [materialsSelected])

  const onChangeLesson = handleOnChange(setLesson);

  const handleFile = useCallback(async (e) => {
    const target = e?.target;
    const files = target?.files;
    files && setFile(files[0]);
    files && setPreviewFile(URL.createObjectURL(files[0]));
  }, []);

  const categoriesSelect = useMemo(() => categories?.map((category) => <option key={category.id} value={category.id}>{category.name}</option>), [categories])
  const contentSportsSelect = useMemo(() => contentSports?.map((contentSport) => <option key={contentSport.id} value={contentSport.id}>{contentSport.name}</option>), [contentSports])
  const contentSportsSelectedList = useCallback((view = 'edit') => {
    return (
      <div className='d-flex align-items-center'>
        {contentSportsSelected?.sort((a, b) => a - b)?.map((contentSport) => {
          return (
            <div key={contentSport?.id} className='d-flex justify-content-center align-items-center mr-3'>
              {view === 'edit' && <button type='button' onClick={() => removeContentSports(contentSport)} className='badge-items-button'>X</button>}
              <span className={view !== 'pdf' ? 'badge-items' : ''}>{contentSport?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [contentSportsSelected])
  const didacticStrategySelect = useMemo(() => didacticStrategy?.map((didacticStrategy) => <option key={didacticStrategy.id} value={didacticStrategy.id}>{didacticStrategy.name}</option>), [didacticStrategy])
  const didacticStrategySelectList = useCallback((view = 'edit') => {
    return (
      <div className='d-flex align-items-center'>
        {didacticStrategySelected?.sort((a, b) => a - b)?.map((didactic) => {
          return (
            <div key={didactic?.id} className='d-flex justify-content-center align-items-center mr-3'>
              {view === 'edit' && <button type='button' onClick={() => removeDidacticStrategy(didactic)} className='badge-items-button'>X</button>}
              <span className={view !== 'pdf' ? 'badge-items' : ''}>{didactic?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [didacticStrategySelected])
  const sessionsSelect = useMemo(() => sessions?.map((session) => <option key={session.id} value={session.id}>{session.name}</option>), [sessions])
  const materialsSelect = useMemo(() => materials?.map((material) => <option key={material.id} value={material.id}>{material.name}</option>), [materials])
  const materialsSelectedList = useCallback((view = 'edit') => {
    return (
      <div className='d-flex align-items-center'>
        {materialsSelected?.sort((a, b) => a - b)?.map((material) => {
          return (
            <div key={material?.id} className='d-flex justify-content-center align-items-center mr-3'>
              {view === 'edit' && <button type='button' onClick={() => removeMaterial(material)} className='badge-items-button'>X</button>}
              <span className={view !== 'pdf' ? 'badge-items' : ''}>{material?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [materialsSelected])

  return (
    <ModalWithMethods
      {...props}
      ref={ref}
      title={lesson?.id ? "Editar Tarefa" : "Criar Tarefa"}
      size={'lg'}
      onShow={onShow}
    >
      <form onSubmit={handleSubmit} method='post' className='form_modal'>
        <div className='w-100'>
          <div className='row'>
            <div className='col-sm-9'>
              <div className='form_group'>
                <label htmlFor='category_id' className='card_details_label'>
                  Selecionar categoria
                </label>
                <select
                  name='category_id'
                  className='form_control select'
                  value={lesson?.category_id}
                  onChange={(e) => {
                    onChangeLesson(e);
                    addCategories(e)
                  }}
                >
                  <option value=''>Selecionar</option>
                  {categoriesSelect}
                </select>
              </div>
            </div>
            <div className='col-sm-3'>
              <div className='form_group'>
                <label htmlFor='status' className='card_details_label'>
                  Status
                </label>
                <select
                  name='status'
                  className='form_control select'
                  value={lesson?.status}
                  onChange={onChangeLesson}
                >
                  <option value=''>Selecionar</option>
                  <option value='A'>Ativo</option>
                  <option value='I'>Inativo</option>
                </select>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4'>
              <div className='form_group'>
                <label htmlFor='content_sport_id' className='card_details_label'>
                  Conteúdo
                </label>
                <select
                  name='content_sport_id'
                  className='form_control select'
                  value={lesson?.content_sport_id}
                  onChange={(e) => {
                    onChangeLesson(e);
                    addContentSports(e);
                  }}
                >
                  <option value=''>Selecionar</option>
                  {contentSportsSelect}
                </select>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form_group'>
                <label className='card_details_label'>Conceito</label>
                <input
                  type='text'
                  className='form_control'
                  name='concept'
                  value={lesson?.concept}
                  onChange={onChangeLesson}
                  placeholder='Inserir'
                />
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form_group'>
                <label className='card_details_label'>Espaço</label>
                <input
                  type='text'
                  className='form_control'
                  name='space'
                  value={lesson?.space}
                  onChange={onChangeLesson}
                  placeholder='Inserir'
                />
              </div>
            </div>
            <div className='col-sm-12 mb-2'>
              <div className="d-flex align-items-center"><span className='bagde-title'>Conteúdo(s) selecionado(s)</span> {contentSportsSelectedList()}</div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4'>
              <div className='form_group'>
                <label htmlFor='didactic_strategy_id' className='card_details_label'>
                  Estratégia didática
                </label>
                <select
                  name='didactic_strategy_id'
                  className='form_control select'
                  value={lesson?.didactic_strategy_id}
                  onChange={(e) => {
                    onChangeLesson(e);
                    addDidacticStrategy(e);
                  }}
                >
                  <option value=''>Selecionar</option>
                  {didacticStrategySelect}
                </select>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form_group'>
                <label className='card_details_label'>Tempo</label>
                <input
                  type='text'
                  className='form_control'
                  name='time'
                  value={lesson?.time}
                  onChange={onChangeLesson}
                  placeholder='Inserir'
                />
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form_group'>
                <label className='card_details_label'>Sessão da aula/treino</label>
                <select
                  name='lesson_session_id'
                  className='form_control select'
                  value={lesson?.lesson_session_id}
                  onChange={onChangeLesson}
                >
                  <option value=''>Selecionar</option>
                  {sessionsSelect}
                </select>
              </div>
            </div>
            <div className='col-sm-12 mb-2'>
              <div className="d-flex align-items-center"><span className='bagde-title'>Estratégia(s) selecionada(s)</span> {didacticStrategySelectList()}</div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='form_group'>
                <label htmlFor='material_id' className='card_details_label'>
                  Material
                </label>
                <select
                  name='material_id'
                  className='form_control select'
                  value={lesson?.material_id}
                  onChange={(e) => {
                    onChangeLesson(e);
                    addMaterial(e);
                  }}
                >
                  <option value=''>Selecionar</option>
                  {materialsSelect}
                </select>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='form_group'>
                <label className='card_details_label'>N. de jogadores</label>
                <input
                  type='number'
                  className='form_control'
                  name='numberOfPlayers'
                  value={lesson?.numberOfPlayers}
                  onChange={onChangeLesson}
                  placeholder='Inserir'
                />
              </div>
            </div>
            <div className='col-sm-12 mb-2'>
              <div className="d-flex align-items-center"><span className='bagde-title'>Material(ais) selecionado(s)</span> {materialsSelectedList()}</div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='form_group'>
                <label className='card_details_label'>Descrição</label>
                <textarea
                  className='form_control'
                  name='description'
                  value={lesson?.description}
                  onChange={onChangeLesson}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='form_group'>
                <label className='card_details_label'>Regras</label>
                <textarea
                  className='form_control'
                  name='rules'
                  value={lesson?.rules}
                  onChange={onChangeLesson}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='form_group'>
                <label className='card_details_label'>Pontuação</label>
                <textarea
                  className='form_control'
                  name='score'
                  value={lesson?.score}
                  onChange={onChangeLesson}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='form_group'>
                <label className='card_details_label'>Variação</label>
                <textarea
                  className='form_control'
                  name='variation'
                  value={lesson?.variation}
                  onChange={onChangeLesson}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='form_group'>
                <label className='card_details_label'>Feedback</label>
                <textarea
                  className='form_control'
                  name='feedback'
                  value={lesson?.feedback}
                  onChange={onChangeLesson}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='form_group'>
                <label className='card_details_label'>Link Youtube/Vimeo</label>
                <input
                  type='url'
                  className='form_control'
                  name='videoURL'
                  value={lesson?.videoURL}
                  onChange={onChangeLesson}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='form_group'>
                <span className='card_details_label mb-3'>Upload do desenho</span>
                <div className='mb-3 mx-auto'>
                  <img src={previewFile || lesson?.artUrl} width='100%' alt='' />
                </div>
                <div className='relative d-flex flex-column border border-danger-subtle rounded justify-content-center align-items-center gap-2 p-3'>
                  <input type='file' name='artURL' className='absolute top-0 left-0 opacity-0' onChange={(e) => handleFile(e)} />
                  <Upload />
                  <div>Clique aqui para fazer o upload do arquivo</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-1 mt-3'>
          <button type='button' className='btn-main-color mr-3' onClick={() => ref.current.closeModal()}>
            Voltar
          </button>
          <button className='btn-secondary-color'>
            {lesson?.id === undefined ? 'Criar Tarefa' : 'Editar Tarefa'}
          </button>
        </div>
      </form>
      <div className="modal_success" style={success ? { display: 'flex', opacity: 1 } : undefined}>
        <div className='modal_content_success'>
          <div className="modal_header_success">
            <div className='modal_title_success'>{lesson?.id === undefined ? 'Tarefa criada' : 'Alterações salvas'}</div>
            <div className='modal_close_success' onClick={() => ref.current.closeModal()}>
              X
            </div>
          </div>
          <div className='modal_body_success'>
            <div className='modal_icon_success'>
              <Success style={{ color: '#24c782' }} />
            </div>
            {lesson?.id === undefined ? 'Tarefa criada com sucesso!' : 'Tarefa editada com sucesso!'}
          </div>
          <div className='modal_footer_success'>
            <button type='button' onClick={() => ref.current.closeModal()} className='btn-secondary-color w-100'>
              Fechar
            </button>
          </div>
        </div>
      </div>
    </ModalWithMethods>
  );
};

export default forwardRef(EditLesson);
