import React, { useState } from 'react';
import './styles.css';

import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { dinamicImport } from './icons';
import { Link } from 'react-router-dom';

const MenuItem = ({ item, isOpen, onClick }) => {
  return (
    <div className={`menu-mobile-label ${isOpen ? 'active' : ''}`} onClick={onClick}>
      <span className='menu-mobile-icon'>{dinamicImport(item.icon)}</span>
      <span className='menu-mobile-label'>{item.title}</span>
      <div className='menu-mobile-arrow'>{isOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}</div>
    </div>
  );
};

const Submenu = ({ items, toggleMenuMobile, modalRef, modalCertificatesRef, modalRefClub }) => (
  <ul className='submenu-mobile'>
    {items.map((subItem, subIndex) => (
      <li key={subIndex} className='submenu-mobile-item'>
        {subItem.route === 'upload-signature-school' && (
          <a
            className='submenu-item'
            onClick={() => {
              modalRef.current.showModal();
              toggleMenuMobile();
            }}
          >
            {subItem.title}
          </a>
        )}
        {subItem.route === 'student-certificate' && (
          <a
            className='submenu-item'
            onClick={() => {
              modalCertificatesRef.current.showModal();
              toggleMenuMobile();
            }}
          >
            {subItem.title}
          </a>
        )}
        {subItem.route === 'api-settings' && (
          <a
            className='submenu-item'
            onClick={() => {
              modalRefClub.current.showModal();
              toggleMenuMobile();
            }}
          >
            {subItem.title}
          </a>
        )}
        {subItem.route !== 'upload-signature-school' &&
          subItem.route !== 'student-certificate' &&
          subItem.route !== 'api-settings' && (
            <Link to={`/${subItem.route}`} key={subIndex} className='submenu-item' onClick={toggleMenuMobile}>
              {subItem.title}
            </Link>
          )}
      </li>
    ))}
  </ul>
);

const MenuMobile = ({ menu, toggleMenuMobile, modalRef, modalCertificatesRef, modalRefClub }) => {
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);

  const toggleSubMenu = (index) => {
    setOpenSubmenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className='menu-mobile'>
      {menu.map((item, index) => (
        <div key={index} className='menu-mobile-item'>
          {item.children && item.children.length > 0 ? (
            <>
              <MenuItem
                item={item}
                isOpen={openSubmenuIndex === index}
                onClick={() => {
                  toggleSubMenu(index);
                }}
              />
              {openSubmenuIndex === index && (
                <Submenu
                  items={item.children}
                  toggleMenuMobile={toggleMenuMobile}
                  modalRef={modalRef}
                  modalCertificatesRef={modalCertificatesRef}
                  modalRefClub={modalRefClub}
                />
              )}
            </>
          ) : (
            <Link to={`/${item.route}`} key={index} onClick={toggleMenuMobile}>
              <span className='menu-mobile-icon'>{dinamicImport(item.icon)}</span>
              <span className='menu-mobile-label'>{item.title}</span>
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};

export default MenuMobile;
