import React from 'react';
import { RiEditLine, RiFile3Line, RiLockPasswordLine, RiLogoutBoxRLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../Context/UserContext';

const ProfileMenuDropdown = ({ clearContextData, logout }) => {
  const history = useHistory();

  const { user } = React.useContext(UserContext);

  const handleLogout = () => {
    clearContextData();
    logout();
    history.push('/');
  };

  return (
    <div className='profile-menu-dropdown'>
      {(user.role_id !== 2) && (
        <>
          <button className='logout-button' onClick={() => history.push('/evaluation-club')}>
            <RiFile3Line />
            <p>Avaliação</p>
          </button>
          <button className='logout-button' onClick={() => history.push('/view-control')}>
            <RiLockPasswordLine />
            <p>Controle de Acesso</p>
          </button>
          <button className='logout-button' onClick={() => history.push('/edit-password-school')}>
            <RiEditLine />
            <p>Alterar Senha</p>
          </button>
        </>)}
      <button className='logout-button text-danger' onClick={() => handleLogout()}>
        <RiLogoutBoxRLine />
        <p>Deslogar</p>
      </button>
    </div>
  );
};

export default ProfileMenuDropdown;
