import React from 'react';
import Message from '../../../components/Message';
import useForm from '../../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import ButtonRounded from '../../../components/buttons/ButtonRounded';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import moment from 'moment';
import { StudentFormContext } from '../../../Context/StudentFormContext';
import { getStudentClasses } from '../../../services/StudentService';
import { getClassSchool } from '../../../services/ClassService';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

const { MessageDangerMarginTop } = Message();

const normal = (state) => Object.keys(state).map((k) => ({ [k]: state[k].value }));

const createFilterOptions = (options, { inputValue }) => {
  const filteredOptions = options.filter((option) => {
    const { name, id } = option;
    const lowerSearchText = inputValue.toLowerCase();
    const _name = name.toLowerCase();
    return _name.includes(lowerSearchText) || id === lowerSearchText;
  });
  return filteredOptions;
};

const sortByName = function (a, b) {
  var nameA = a.name.toLowerCase(),
    nameB = b.name.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};
//TODO: implementar o intl para o componente
const Turmas = ({ title = 'Informações Aluno', nextTab }) => {
  const context = React.useContext(StudentFormContext);

  const formRefer = React.useRef(null);

  const history = useHistory();

  const [autokey, setAutoKey] = React.useState(moment().toISOString());

  const [errors, setErrors] = React.useState([]);

  const [showModal, setShowModal] = React.useState(false);

  const [sendSave, setSendSave] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [classs, setClasss] = React.useState([]);

  const { t } = useTranslation();

  const classes = useStyles();

  const onSubmitForm = (p, e, s, formData) => {
    const { tipo = undefined } = e.target.dataset;

    context.setState({ turmas: state });
    // /student/class/create
    setSendSave(true);
    formData.append('atleta', JSON.stringify(normal(context.atleta)));
    formData.append('turmas', JSON.stringify(normal(state)));

    context
      .requestValidate('/student/class/create', formData)
      .then((response) => {
        if (response.type === 'success') {
          if (tipo !== undefined && tipo === 'finalizar') {
            if (state.classs.value.length === 0) {
              context.handlerFinally();
              return null;
            }
            setShowModal(true);
            return null;
          }
          nextTab(5);
        }
      })
      .catch((err) => {
        setErrors([err.response.data.message.toString()]);
      })
      .finally(() => setSendSave(false));
  };

  const { state, handleOnSubmit, setState } = useForm(
    context.turmas,
    validationStateSchema,
    onSubmitForm,
    formRefer,
    setErrors
  );

  const removeFromList = React.useCallback((id) => {
    setState((current) => ({
      ...current,
      classs: { value: current.classs.value.filter((c) => c.id !== id) },
    }));
  });
  const handlerFinally = () => {
    context.handlerFinally();
  };

  React.useEffect(() => {
    const getClass = async () => {
      setLoading(true);

      let schoolSession = localStorage.getItem('school');
      const { id = 0 } = JSON.parse(schoolSession);
      const res = await getClassSchool(id);

      setClasss(res.classs.filter((c) => c.status === 'A').sort(sortByName));
      setLoading(false);
    };
    getClass();
  }, []);

  React.useEffect(() => {
    const geturma = async () => {
      const { classes } = await getStudentClasses(context.atleta.id.value);
      setState((prevState) => ({
        ...prevState,
        classs: { value: classes, error: '' },
      }));
    };

    try {
      if (context.atleta.id.value) {
        geturma();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const columns = [
    {
      id: 'name',
      label: 'Incluír nas Turmas',
      minWidth: 220,
      align: 'left',
    },
    {
      id: 'id',
      align: 'center',
      label: 'Ação',
      minWidth: 100,
      format: (id) => (
        <BtnDelete
          onClick={(e) => {
            e.preventDefault();
            removeFromList(id);
          }}
        />
      ),
    },
  ];

  const initialMessage = state.classs.value.length === 0 ? 'ADICIONADO A TURMA COM SUCESSO' : 'ATUALIZADO';
  return (
    <>
      <SweetAlert
        success
        showCancel
        confirmBtnBsStyle='danger'
        onConfirm={() => {
          nextTab(4);
        }}
        onCancel={handlerFinally}
        focusCancelBtn
        show={showModal}
        title={t('Success')}
        customButtons={
          <span className='w-100 d-flex justify-content-around align-items-center'>
            <ButtonRounded variant='outline' color='var(--main-color)' isBig={true} onClick={handlerFinally}>
            {t('Finalize')}
            </ButtonRounded>
          </span>
        }
      >
        ALUNO {initialMessage}!
      </SweetAlert>
      {errors.map((err, k) => (
        <MessageDangerMarginTop key={`err-${k}`} title='Atenção!' description={err.error} />
      ))}
      <div className='mx-3'>
        <form ref={formRefer} action='' className='form_card' noValidate>
          <div className='col p-0 w-100'>
            <div className='row mb-2 mx-3'>
              <div className='col'>
                <label className='card_details_label'>Escolher Turmas *</label>
                {/* <select value={state.classs.value} className='form_control form-control select' name='classs' onChange={handleOnChange}>
                  {classs.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select> */}
                <Autocomplete
                  key={autokey}
                  classes={classes}
                  options={classs.filter((u) => !state.classs.value.find((t) => t.id === u.id))}
                  getOptionLabel={(option) => option.name}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  filterOptions={createFilterOptions}
                  onChange={(event, user, detail) => {
                    if (detail === 'select-option') {
                      const { id, name } = user;
                      state.classs.value.push({ id, name });
                      setState((prevState) => ({
                        ...prevState,
                        classs: { value: state.classs.value, error: '' },
                      }));
                      setAutoKey(moment().toISOString());
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder='Pesquisar Turma por nome ou ID...'
                      variant='outlined'
                      helperText={'Selecione a turma que deseja incluir o aluno.'}
                    />
                  )}
                />
              </div>
            </div>
            <TableList rows={state.classs.value} columns={columns} loading={loading} />
          </div>
        </form>
      </div>

      <div className='row align-items-center justify-content-center my-4'>
        <div className='col-md-5 col-sm-10'>
          <ButtonRounded
            isLoading={sendSave}
            data-tipo='finalizar'
            onClick={handleOnSubmit}
            className='w-100'
            isBig={true}
          >
            {t('Save')} {t('And')} {t('Finalize')}
          </ButtonRounded>
        </div>
        <div className='col-md-5 col-sm-10'>
          <ButtonRounded
            isDisabled={state.classs.value.length === 0}
            isLoading={sendSave}
            onClick={handleOnSubmit}
            className='w-100'
            isBig={true}
          >
            {t('Save')} {t('And')} {t('Continue')}
          </ButtonRounded>
        </div>
      </div>
    </>
  );
};
const validationStateSchema = {};

//material ui custom styles --------------------------------------------------------------------------------
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'var(--main-color)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: '#f4f0f0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
  },
});

//private components  --------------------------------------------------------------------------------------
const BtnDelete = ({ fcolor = '#FF2929', w = 16, h = 16, onClick }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer' }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z'
        fill={fcolor}
      />
    </svg>
  </span>
);

const TableList = ({ columns, rows, mxh = 380, loading = false }) => {
  if (loading) {
    return (
      <TableContainer className='max-to-print' style={{ maxHeight: mxh, minHeight: mxh * 0.8 }}>
        <Table stickyHeader size='small' aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRowsLoader rowsNum={columns.length} />
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return (
    <TableContainer style={{ maxHeight: mxh, minHeight: mxh * 0.8 }}>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow hover tabIndex={-1} key={row.id}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {typeof column.format === 'function' ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableRowsLoader = ({ rowsNum }) => {
  return (
    <TableRow>
      {[...Array(rowsNum)].map((row, index) => (
        <TableCell component='th' scope='row' key={index}>
          <Skeleton animation='wave' variant='text' />
        </TableCell>
      ))}
    </TableRow>
  );
};
export default Turmas;
