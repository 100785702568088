import React, { useState, useRef } from 'react';

const DragDropUpload = ({parentRef, setState,persistFile=null}) => {

  const [dragging, setDragging] = useState(false);

  const [image, setImage] = useState(persistFile);

  const dropRef = useRef();

  const inputRef = useRef();

  const [parentWidth, setParentWidth] = useState(0);

  React.useEffect(() => {

    const updateParentWidth = () => {
      if (parentRef.current) {
        const width = parentRef.current.getBoundingClientRect().width;
        setParentWidth(width);
      }
    };
    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);

  React.useEffect(() => {
    if(persistFile &&  persistFile instanceof  File){
      setImage(persistFile);
      fileReader(persistFile);
    }
  }, [persistFile]);

  const fileReader = (file)=>{
   
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
        setState(file)
      };
      reader.readAsDataURL(file);
    
  }
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const file =  e.dataTransfer?.files[0] ||  e.target.files[0];
    if (file /* && file.type.startsWith('image/') */) {
      fileReader(file);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current.click();
  }

  return (
    <>  
        <input ref={inputRef} style={{display:'none'}} type='file' name='file' onChange={handleDrop} />
        <div
            ref={dropRef}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleClick}
            style={{
                width: `${parentWidth-55}px`,
                height: '230px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #f4f0f0',
                boxSizing: 'border-box',
                borderRadius: 5,
                background: dragging ? '#fff' : 'transparent',
                flexDirection: 'column',
                cursor: 'pointer'
            }}
            >
            {image ? (
                <img src={image} alt="Dropped" style={{ maxWidth: '100%', maxHeight: '100%', padding:5 }} />
            ) : (
                <>
                    <span className='d-flex'>Clique aqui para fazer upload da foto</span>
                    <IconUpload  />
                </>
            )}            
            </div>
    </>
  
  );
};

const IconUpload = ({ fcolor = '#1C1B1F', w = 22, h = 17, style }) => (
    <span  className='text-danger d-flex' style={{ cursor: 'pointer', ...style }} role='button'>
      <svg width={w} height={h} viewBox='0 0 22 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d="M5.5 16.5C3.98333 16.5 2.68767 15.975 1.613 14.925C0.537667 13.875 0 12.5917 0 11.075C0 9.775 0.391667 8.61667 1.175 7.6C1.95833 6.58333 2.98333 5.93333 4.25 5.65C4.66667 4.11667 5.5 2.875 6.75 1.925C8 0.975 9.41667 0.5 11 0.5C12.95 0.5 14.604 1.179 15.962 2.537C17.3207 3.89567 18 5.55 18 7.5C19.15 7.63333 20.1043 8.129 20.863 8.987C21.621 9.84567 22 10.85 22 12C22 13.25 21.5627 14.3127 20.688 15.188C19.8127 16.0627 18.75 16.5 17.5 16.5H12C11.45 16.5 10.9793 16.3043 10.588 15.913C10.196 15.521 10 15.05 10 14.5V9.35L8.4 10.9L7 9.5L11 5.5L15 9.5L13.6 10.9L12 9.35V14.5H17.5C18.2 14.5 18.7917 14.2583 19.275 13.775C19.7583 13.2917 20 12.7 20 12C20 11.3 19.7583 10.7083 19.275 10.225C18.7917 9.74167 18.2 9.5 17.5 9.5H16V7.5C16 6.11667 15.5127 4.93733 14.538 3.962C13.5627 2.98733 12.3833 2.5 11 2.5C9.61667 2.5 8.43767 2.98733 7.463 3.962C6.48767 4.93733 6 6.11667 6 7.5H5.5C4.53333 7.5 3.70833 7.84167 3.025 8.525C2.34167 9.20833 2 10.0333 2 11C2 11.9667 2.34167 12.7917 3.025 13.475C3.70833 14.1583 4.53333 14.5 5.5 14.5H8V16.5H5.5Z" fill={fcolor}/>
      </svg>
    </span>
  );

export default DragDropUpload;
