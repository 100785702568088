import React, { useState, useEffect } from 'react'
import Loading from '../../components/Loading'
import { Link, Redirect } from 'react-router-dom'
import Api from '../../utils/api'
import utils from '../../utils/utils'
import axios from 'axios'

const Events = (props) => {
   let user_id = ''
   const [logo, setLogo] = useState([])
   const [events, setEvents] = useState([])
   const [goDetails, setGoDetails] = useState(false)
   const [sendingEvents, setEventsSending] = useState(false)
   const [event, setEvent] = useState({})
   const [inscrito, setInscrito] = useState(false)
   const baseURL = Api()
   const { replaceAll } = utils()

   useEffect(() => {
      const user = JSON.parse(localStorage.getItem('user'))

      if ( user !== null ) {
         user_id = user.id
      }

      getEvents()
   },[props.data])

   const getEvents = async () => {
      setEvents([])
      setEventsSending(true)

      const res = await axios.post(baseURL + 'get-events',{
         "user_id": user_id,
         "eveCobrarInscricao": "S",
         "limit": props.limit,
         "search": props.data ? props.data.search : '',
         "companie_id": props.data ? props.data.companie_id : '',
         "state_id": props.data ? props.data.state_id : '',
         "city_id": props.data ? props.data.city_id : '',
         "myFuture": props.myFuture
      })
      
      if ( res.data.error === false ){
         setLogo(res.data.urlLogo)
         setEvents(res.data.data)
      }  

      setEventsSending(false)
   }

   const details = (e, event, inscrito) => {
      e.preventDefault(e)
      setEvent(event)
      setGoDetails(true)
      setInscrito(inscrito)
   }

   if(goDetails)
      return <Redirect to={{
         pathname: '/detalhes-seletiva-evento',
         state: {
            event: event, 
            url: logo,
            inscrito: inscrito
         }
      }} />

   return (
      <div className="eventos" style={{background: 'white'}}>
         <div className="titulo-seletivas">
            <h2>{props.title}</h2>
            <p>{props.description}</p>
         </div>

         <ul className="datas-eventos">
            {
               sendingEvents && <Loading type="1" />
            }
            {
               
               events.length == 0 && 
               sendingEvents === false &&
               <p>Nenhum evento encontrado.</p> 
            }
            {
               events.map((item, e) => {
                  return (
                     <li className="informacoes-eventos" key={item.data_hora}>
                        <div className="col-1-eventos">
                           <img src={`../../img/evento/evento-1.png`} />
                        </div>
         
                        <div className="col-2-eventos">
                           <img className="img-radius-club" src="../../../favicon.png"/>
                        </div>
         
                        <div className="data-btn-eventos">
                           <div className="col-3-eventos">
                              <div className="info-eventos">
                                 <div className="info-col-3-sel">
                                    <div className="nome-col-3">
                                       <h3>{item.comCompanie}</h3>
                                    </div>
                                 </div>
      
                                 <div className="info-col-3-sel">
                                    <div className="generico-col-3">
                                       <p><b>Pré requisitos</b></p>
                                    </div>
                                 </div>
      
                                 <div className="info-col-3-sel">
                                    <p><b>Categorias:</b></p>
      
                                    <div className="idade-col-3">
                                    <p>{replaceAll(item.categorias, ' - ', ', ')}</p>
                                    </div>
                                 </div>
      
                                 <div className="info-col-3-sel">
                                    <div className="genero-col-3">
                                       {
                                          item.eveSexo == 'F' && 
                                          <p>Futebol Feminino</p>
                                       }
                                       {
                                          item.eveSexo == 'M' && 
                                          <p>Futebol Masculino</p>
                                       }
                                    </div>
                                 </div>
      
                                 <div className="info-col-3-sel">
                                    <p><b>Data:</b></p>
      
                                    <div className="data-col-3">
                                       <p>{item.data_hora}</p>
                                    </div>
                                 </div>
      
                                 <div className="info-col-3-sel">
                                    <p><b>Local:</b></p>
      
                                    <div className="local-col-3">
                                       <p>{item.locLocal}</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           {
                              props.verDetalhes &&
                              <div className="col-4-eventos">
						               <h4>Até {item.data_termino_completa}</h4>
                                 <a href="#" onClick={(e) => details(e, item, true)}>Ver detalhes</a>
                              </div>
                           }
                           {
                              !props.verDetalhes &&
                              <div className="col-4-eventos">
                                 <h4>Até {item.data_termino_completa}</h4>
                                 <a href="#" onClick={(e) => details(e, item, false)}>Se inscrever</a>
                              </div>
                           }
                           
                        </div>
                     </li>
                  )
               })
            }
         </ul>

         {
            props.verTodos &&
            <div className="botao-seletivas botao-eventos-index">
               <Link to="/events-athlete">Ver todas</Link>
            </div>
         }

         {
            props.carregarMais &&
            <div className="carregar-mais-seletivas">
               <a href="#">Carregar mais</a>
            </div>
         }
         
      </div>
   )
}

export default Events