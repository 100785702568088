import React from 'react';
import Table from 'react-bootstrap/Table';
import { BsClipboardCheck, BsPencil, BsTrash } from 'react-icons/bs';
import Utils from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const ProductsOrderPurchase = ({ productsIncludes, onClickRemoveProduct, status }) => {

    const { dateFormat, currencyFormatter, currencyFormatterText } = Utils();

    const defaultColHeaderStyle = {
        border: 'none',
        fontSize: '14px',
        width: '200px',
        color: '#3b3b3b',
        fontWeight: 600,
        justifyContent: 'left'
    };

    const defaultColRowStyle = {
        alignItems: 'center',
        width: '200px',
        height: '100%',
        border: 'none',
        justifyContent: 'left'
    };

    return (
        <Table responsive style={{ boxShadow: 'none', marginTop: '20px', minWidth: '625px' }}>
            <thead>
                <tr style={{ marginBottom: '5px' }}>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Produto
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Quantidade
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Tamanho
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Valor Unit.
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Valor Total
                    </th>
                    {(!status || status === "Aberta") &&
                        <th style={{ ...defaultColHeaderStyle }}>
                            Açao
                        </th>
                    }
                </tr>
            </thead>
            <tbody>
                {productsIncludes && productsIncludes.map((item, i) => (
                    <tr key={'ProducsTable_tbody_row' + i} style={{
                        marginBottom: '12px',
                        borderRadius: '10px',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        height: '62px',
                        borderColor: 'var(--main-color)'
                    }}>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.titileProduct.length > 20 ? item.titileProduct.substr(0, 20) + '...' : item.titileProduct}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.amount}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.size_product}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {currencyFormatterText(item.unitary_value)}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {currencyFormatterText(item.value_total)}
                        </td>
                        {(!status || status === "Aberta") &&
                            <td className='text-dark' style={{ ...defaultColRowStyle }}>
                                <div className='d-flex' style={{ gap: '10px', cursor: 'pointer' }}>
                                    {/* <BsPencil onClick={(e) => onEdit(e, item)} style={{ color: 'var(--main-color)' }} size={15} /> */}
                                    <BsTrash onClick={(e) => onClickRemoveProduct(item.product_id, item.size_product)} color='red' size={15} />
                                </div>
                            </td>
                        }
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default ProductsOrderPurchase;