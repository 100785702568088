import axios from 'axios'
import Api from '../utils/api'
const baseURL = Api()

export const AddCustomContract = async (schoolId, contract) => {
    const formData = new FormData();
    formData.append('contract', contract);
    formData.append('schoolId', schoolId);
    try {
        const { data } = await axios.post(baseURL + 'add-contract-custom', formData);
        return data;
    } catch (error) {
        return { responseError: error };
    }
}