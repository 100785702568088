import React, { useState, forwardRef, useEffect, useMemo } from 'react';
import { ModalWithMethods } from '../../../components/modal/Modal';
import { fetchLessonSession } from '../../../services/LessonSession';

const ViewLesson = ({ lesson, ...props }, ref) => {
  const [sessions, setSessions] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([])
  const [contentSportsSelected, setContentSportsSelected] = useState([]);
  const [didacticStrategySelected, setDidacticStrategySelected] = useState([]);
  const [materialsSelected, setMaterialsSelected] = useState([]);

  useEffect(() => {
    setCategoriesSelected(lesson?.categories);
    setContentSportsSelected(lesson?.contents);
    setDidacticStrategySelected(lesson?.strategies);
    setMaterialsSelected(lesson?.materials);
  }, [lesson]);

  const onShow = () => {
    ref.current.scrollTop();
  };

  const getLessonSession = async () => {
    const response = await fetchLessonSession();
    setSessions(response.sessions)
  }

  useEffect(() => {
    getLessonSession()
  }, [])

  const categoriesSelectedList = useMemo(() => {
    return (
      <div className='d-flex align-items-center'>
        {categoriesSelected?.sort((a, b) => a.name - b.name)?.map((category) => {
          return (
            <div key={category?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span className='badge-items'>{category?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [categoriesSelected])

  const contentSportsSelectedList = useMemo(() => {
    return (
      <div className='d-flex align-items-center'>
        {contentSportsSelected?.sort((a, b) => a - b)?.map((contentSport) => {
          return (
            <div key={contentSport?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span className='badge-items'>{contentSport?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [contentSportsSelected])
  const didacticStrategySelectList = useMemo(() => {
    return (
      <div className='d-flex align-items-center'>
        {didacticStrategySelected?.sort((a, b) => a - b)?.map((didactic) => {
          return (
            <div key={didactic?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span className='badge-items'>{didactic?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [didacticStrategySelected])
  const materialsSelectedList = useMemo(() => {
    return (
      <div className='d-flex align-items-center'>
        {materialsSelected?.sort((a, b) => a - b)?.map((material) => {
          return (
            <div key={material?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span className='badge-items'>{material?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [materialsSelected])
  const lessonSessionLabel = useMemo(() => {
    return sessions?.find((session) => session.id === lesson?.lesson_session_id)?.name
  }, [lesson, sessions])

  return (
    <ModalWithMethods
      {...props}
      ref={ref}
      title={lesson?.concept ?? ''}
      size={'lg'}
      onShow={onShow}
    >
      <div id='lesson'>
        <div className='w-100'>
          <div className='row'>
            <div className='col-sm-9'>
              <label htmlFor='status' className='card_details_label'>
                Categorias
              </label>
              {categoriesSelectedList}
            </div>
            <div className='col-sm-3'>
              <div className='form_group'>
                <label htmlFor='status' className='card_details_label'>
                  Status
                </label>
                {lesson?.status === 'A' ? 'Ativo' : 'Inativo'}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-9'>
              <div className='form_group'>
                <label htmlFor='content_sport_id' className='card_details_label'>
                  Conteúdo
                </label>
                {contentSportsSelectedList}
              </div>
            </div>
            <div className='col-sm-3'>
              <div className='form_group'>
                <span className='card_details_label'>Espaço</span>
                {lesson?.space}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='form_group'>
                <label htmlFor='didactic_strategy_id' className='card_details_label'>
                  Estratégia didática
                </label>
                {didacticStrategySelectList}
              </div>
            </div>
            <div className='col-sm-3'>
              <div className='form_group'>
                <label className='card_details_label'>Tempo</label>
                {lesson?.time}
              </div>
            </div>
            <div className='col-sm-3'>
              <div className='form_group'>
                <label className='card_details_label'>Sessão da aula/treino</label>
                {lessonSessionLabel}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-9'>
              <div className='form_group'>
                <label htmlFor='material_id' className='card_details_label'>
                  Material
                </label>
                {materialsSelectedList}
              </div>
            </div>
            <div className='col-sm-3'>
              <div className='form_group'>
                <label className='card_details_label'>N. de jogadores</label>
                {lesson?.numberOfPlayers}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='form_group'>
                <label className='card_details_label'>Descrição</label>
                {lesson?.description}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='form_group'>
                <label className='card_details_label'>Regras</label>
                {lesson?.rules}
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='form_group'>
                <label className='card_details_label'>Pontuação</label>
                {lesson?.score}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='form_group'>
                <label className='card_details_label'>Variação</label>
                {lesson?.variation}
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='form_group'>
                <label className='card_details_label'>Feedback</label>
                {lesson?.feedback}
              </div>
            </div>
          </div>
          {lesson?.artUrl && <div className='row'>
            <div className='col-sm-12'>
              <div className='form_group'>
                <span className='card_details_label mb-2'>Exemplo</span>
                <div className='border border-warning p-3 mt-1 rounded-lg w-100 d-flex justify-content-center'>
                  <img src={lesson?.artUrl} width='100%' alt='' />
                </div>
              </div>
            </div>
          </div>}
          {lesson?.videoURL && <div className='row mt-3'>
            <div className='col-sm-12'>
              <span className='card_details_label mb-2'>Vídeo</span>
              <iframe width='100%' height='300px' src={lesson?.videoURL} />
            </div>
          </div>}
        </div>
        <div className='d-flex flex-1 mt-3'>
          <button type='button' className='btn-main-color mr-3' onClick={() => ref.current.closeModal()}>
            Voltar
          </button>
        </div>
      </div>
    </ModalWithMethods>
  );
};

export default forwardRef(ViewLesson);
