import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalWithMethods } from '../../components/modal/Modal';

const ModalViewLesson = ({ lesson, sessions, ...props }, ref) => {
  const { t } = useTranslation();

  const lessonCard = (lesson) => (
    <div>
      <div className='modal_title' style={{ textAlign: 'center' }}>
        {lesson.concept}
      </div>
      <div className='w-100'>
        <div className='row'>
          <div className='col-sm-9'>
            <label htmlFor='status' className='card_details_label'>
              Categorias
            </label>
            <div className='d-flex align-items-center'>
              {lesson?.lesson_categories?.sort((a, b) => a.name - b.name)?.map((category) => {
                return (
                  <div key={category?.id} className='d-flex justify-content-center align-items-center mr-3'>
                    <span>{category?.name}</span>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='col-sm-3'>
            <div className='form_group'>
              <label htmlFor='status' className='card_details_label'>
                Status
              </label>
              {lesson.status === 'A' ? 'Ativo' : 'Inativo'}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-9'>
            <div className='form_group'>
              <label htmlFor='content_sport_id' className='card_details_label'>
                Conteúdo
              </label>
              <div className='d-flex align-items-center'>
                {lesson?.lesson_content_sports?.sort((a, b) => a - b)?.map((contentSport) => {
                  return (
                    <div key={contentSport?.id} className='d-flex justify-content-center align-items-center mr-3'>
                      <span>{contentSport?.name}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className='col-sm-3'>
            <div className='form_group'>
              <span className='card_details_label'>Espaço</span>
              {lesson.space}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='form_group'>
              <label htmlFor='didactic_strategy_id' className='card_details_label'>
                Estratégia didática
              </label>
              <div className='d-flex align-items-center'>
                {lesson?.lesson_didactic_strategies?.sort((a, b) => a - b)?.map((didactic) => {
                  return (
                    <div key={didactic?.id} className='d-flex justify-content-center align-items-center mr-3'>
                      <span>{didactic?.name}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className='col-sm-3'>
            <div className='form_group'>
              <label className='card_details_label'>Tempo</label>
              {lesson.time}
            </div>
          </div>
          <div className='col-sm-3'>
            <div className='form_group'>
              <label className='card_details_label'>Sessão da aula/treino</label>
              {sessions?.find((session) => session.id === lesson.lesson_session_id)?.name}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-9'>
            <div className='form_group'>
              <label htmlFor='material_id' className='card_details_label'>
                Material
              </label>
              <div className='d-flex align-items-center'>
                {lesson?.lesson_materials?.sort((a, b) => a - b)?.map((material) => {
                  return (
                    <div key={material?.id} className='d-flex justify-content-center align-items-center mr-3'>
                      <span>{material?.name}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className='col-sm-3'>
            <div className='form_group'>
              <label className='card_details_label'>N. de jogadores</label>
              {lesson.numberOfPlayers}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='form_group'>
              <label className='card_details_label'>Descrição</label>
              {lesson.description}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='form_group'>
              <label className='card_details_label'>Regras</label>
              {lesson.rules}
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='form_group'>
              <label className='card_details_label'>Pontuação</label>
              {lesson.score}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='form_group'>
              <label className='card_details_label'>Variação</label>
              {lesson.variation}
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='form_group'>
              <label className='card_details_label'>Feedback</label>
              {lesson.feedback}
            </div>
          </div>
        </div>
        {lesson.artUrl && <div className='row'>
          <div className='col-sm-12'>
            <div className='form_group'>
              <span className='card_details_label mb-2'>Exemplo</span>
              <div className='border border-warning p-3 mt-1 rounded-lg w-100 d-flex justify-content-center'>
                <img src={lesson.artUrl} width='100%' alt='' />
              </div>
            </div>
          </div>
        </div>}
        {lesson.videoURL && <div className='row mt-3'>
          <div className='col-sm-12'>
            <span className='card_details_label mb-2'>Vídeo</span>
            <iframe width='100%' height='300px' src={lesson.videoURL} />
          </div>
        </div>}
      </div>
    </div>
  );

  return (
    <ModalWithMethods
      {...props}
      ref={ref}
      title={t('Lesson')}
      size={null}
      modalContentClassName='pb-2'
      modalContentStyle={{ maxWidth: '1200px' }}
    >
      {lesson && (
        <div className='d-flex flex-column mt-3' style={{ rowGap: '24px', paddingBottom: '70px' }}>
          {lesson?.lessons.map((relatedLesson) => lessonCard(relatedLesson))}
        </div>
      )}
    </ModalWithMethods>
  );
};

export default forwardRef(ModalViewLesson);
