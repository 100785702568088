import React, { useState, useEffect } from 'react'
import Loading from '../../../../../components/Loading'
import Api from '../../../../../utils/api'
import axios from 'axios'
import { Redirect } from 'react-router-dom'

import './Resgatados.css'

import Nav from '../../components/nav/Nav'
import CTA from '../../components/Buttons/btn-cta/BtnCTA'
import Footer from '../../components/footer/Footer'

const Resgatados = () => {

    const [athleteID, setAthleteID] = useState("")
    const [process, setProcess] = useState(false)
    const [points, setPoints] = useState(0)
    const [dateStart, setDateStart] = useState(new Date().toISOString().substr(0,7)+'-'+'01')
    const [dateEnd, setDateEnd] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0,10))
    const [login, setLogin] = useState(false)
    const baseURL = Api()

    useEffect(() => {

        async function getSession (){
            const athlete = JSON.parse(await localStorage.getItem('athlete'))
   
            if ( athlete !== null ) {
                setAthleteID(athlete.id)
                get(athlete.id)
            } else {
                setLogin(true)
            }
        }
   
        getSession()
    }, [])

    const get = async (athlete_id) => {
        
        if ( dateEnd >=  dateStart) {
            setProcess(true)
            const res = await axios.post(baseURL + 'get-experiences-ransomed', {
                dateStart,
                dateEnd,
                athlete_id
            })
            setProcess(false)

            setPoints(res.data.points)
        } else {
            alert('Data final não pode ser menor que data inicial')
        }
    }

    if ( login ) {
        return <Redirect to="/" />
    }

    return (
        <React.Fragment>
            <Nav />
            <div className="mainContainer">
                <form action="" className="form_periodo">
                    <label>período
                        <input type="date" value={dateStart} onChange={e => setDateStart(e.target.value)} />
                    </label>
                    <label>a
                        <input type="date" value={dateEnd} onChange={e => setDateEnd(e.target.value)} />
                    </label>
                    <button type="button" onClick={e => get(athleteID)}>
                    {
                        process &&
                        <Loading type="1" />
                    }
                    {
                        !process &&
                        <React.Fragment>Buscar</React.Fragment>
                    }
                    </button>
                </form>
                <section className="card_resgatados">
                    <h3 className="card_header">
                        você resgatou
                    </h3>
                    <article className="item">
                        <span>{points} pts</span>
                        <p>No período de {dateStart.split('-').reverse().join('/')} a {dateEnd.split('-').reverse().join('/')}</p>
                    </article>
                    
                </section>
                <p className="descript text-bold">
                    Garanta mais vantagens entrando no nosso Clube de Parceiros.
                </p>
                <CTA />
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Resgatados