export default function cart(state = [], action) {
    switch(action.type) {

        case 'ADD': {
            return [...state, action.payload];
        };

        case 'DEL': {
            return state.filter(item => item.id !== action.payload.id);
        };

        case 'CLEAR': {
            return action.payload;
        };

        case 'UPDATE_QTY': {
            
            let newState = state;

            state.map((item, i) => {
                if (item.id === action.payload.id ) {
                    let result = parseInt(state[i].qtd) + parseInt(action.payload.qtd) 

                    if (result >= 1)
                        newState[i].qtd = result 
                }
            })

            return [...newState]
        }

        default:
            return state;

    }
}