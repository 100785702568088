import axios from 'axios';
import api from './api';

const autentique = axios.create({
  baseURL: process.env.AUTENTIQUE_URL,
});
export async function getAutentiqueCompanies() {
  const response = await api.get('get-autentique-companies');
  return response.data.data;
}
