import React, { useState, useEffect, useRef } from 'react'
import Footer from '../../components/club/Footer'
import Utils from '../../utils/utils'
import Message from '../../components/Message'
import Loading from '../../components/Loading'
import Api from '../../utils/api'
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';

const Benefit = () => {
   const { t, i18n } = useTranslation();

   //Ref to modal component
   const modalScroll = useRef(null)

   //Libraries
   const baseURL = Api()
   const { scrollTop, compareStrings } = Utils()
   const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message()

   //Session
   const [userID, setUserID] = useState()
   const [companyID, setCompanyID] = useState()

   //Loading
   const [process, setProcess] = useState(true)
   const [sendSave, setSendSave] = useState(false)

   //Display style
   const [display, setDisplay] = useState({})

   //Search
   const [filterName, setFilterName] = useState('')
   const [filterStatus, setFilterStatus] = useState('A')

   //Maps
   const [benefits, setBenefits] = useState([])
   const [benefitsFilter, setBenefitsFilter] = useState([])

   //Mensage
   const [showMessage, setShowMessage] = useState(false)
   const [messageType, setMessageType] = useState('');
   const [message, setMessage] = useState('');

   //Modal
   const [idModal, setIdModal] = useState(null)
   const [nameModal, setNameModal] = useState('')
   const [statusModal, setStatusModal] = useState('')

   useEffect(() => {
      async function getSession() {
         const usuSession = JSON.parse(await localStorage.getItem("user"));
         const companySession = JSON.parse(await localStorage.getItem("company"));

         setUserID(usuSession.id);
         setCompanyID(companySession.id);

         getBenefits(companySession.id);
      }

      getSession();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const getBenefits = async (companyId) => {
      setProcess(true)
      const { data } = await axios.get(baseURL + 'club/' + companyId + '/benefits')
      const { benefits } = data

      setBenefits(benefits.sort((a, b) => compareStrings(a.name, b.name)))
      setBenefitsFilter(benefits.filter(q => q.status === filterStatus).sort((a, b) => compareStrings(a.name, b.name)))

      setProcess(false)
   }

   const openAdd = () => {
      setIdModal(null)
      setNameModal('')
      setStatusModal('A')

      setDisplay({
         display: "flex",
         opacity: 1
      })
   }

   const edit = (e, item) => {
      setIdModal(item.id)
      setNameModal(item.name)
      setStatusModal(item.status)

      setDisplay({
         display: "flex",
         opacity: 1
      })
   }

   const closeAdd = () => setDisplay({})

   const search = (e) => {
      e.preventDefault()
      let auxBenefits = benefits

      if (filterName !== '')
      auxBenefits = auxBenefits.filter(q => q.name.toLowerCase().includes(filterName.toLowerCase().trim()))

      if (filterStatus !== '')
         auxBenefits = auxBenefits.filter(q => q.status === filterStatus)

      setBenefitsFilter(auxBenefits)
   }

   const handleSubmit = async () => {
      const request = idModal === null ? {
         endpoint: 'club/benefits/store',
         data: {
            name: nameModal,
            status: statusModal,
            companie_id: companyID
         }
      } : {
         endpoint: 'club/benefits/update/'+idModal,
         data: {
            name: nameModal,
            status: statusModal,
         }
      }

      setSendSave(true)
      const res = await axios.post(baseURL + request.endpoint, request.data)
      const { data } = res
      setSendSave(false)

      if (data.success) {
         setMessageType('success')
         setShowMessage(true);
         setMessage(idModal === null ? 'Incluido com sucesso' : 'Alterado com sucesso')
         setIdModal(data.res.id)
         getBenefits(companyID)
         setDisplay({})
      } else {
         setMessageType('error')
         setShowMessage(true);
         setMessage('Houve um erro. Tente novamente por favor.')
      }
   }

   return (
      <main>
         <div className='container-fluid container_painel'>
            <div className='container'>
               <div className='row content_painel'>
                  <main className='content_panel'>
                     <div className='sidebar'>
                        <div className='card'>
                           <div className='card_header'>
                              <h2 className='card_title'>Adicionar um benefício</h2>
                              <p className='card_subtitle'>
                                 Clique abaixo para adicionar um benefício
                              </p>
                           </div>
                           <button
                              onClick={openAdd}
                              className='btn_card mx-auto mt-4 btn_open_modal'
                              data-modal='#add_questionnaire_item'
                           >
                              <svg
                                 viewBox='0 0 25 25'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'
                              >
                                 <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              Adicionar item
                           </button>
                        </div>
                        <div className='card'>
                           <div className='card_header'>
                              <h2 className='card_title'>Procurar</h2>
                              <p className='card_subtitle'>Filtrar os benefício</p>
                           </div>
                           <form action='' method='post' className='form_card'>
                              <div className='form_group'>
                                 <label
                                    htmlFor='name'
                                    className='card_details_label'
                                 >
                                    Nome
                                 </label>
                                 <input
                                    type='text'
                                    className='form_control'
                                    value={filterName}
                                    onChange={(e) =>
                                       setFilterName(e.target.value)
                                    }
                                 />
                              </div>
                              <div className='form_group'>
                                 <label
                                    htmlFor='title'
                                    className='card_details_label'
                                 >
                                    Status
                                 </label>
                                 <select
                                    required
                                    value={filterStatus}
                                    className='form_control select'
                                    onChange={(e) =>
                                       setFilterStatus(e.target.value)
                                    }
                                 >
                                    <option value=''>Todos</option>
                                    <option value='A'>Ativo</option>
                                    <option value='I'>Inativo</option>
                                 </select>
                              </div>
                              <button
                                 className='btn_card mx-auto'
                                 onClick={search}
                              >
                                 <svg
                                    viewBox='0 0 25 25'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                 >
                                    <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                                 </svg>
                                 Buscar
                              </button>
                           </form>
                        </div>
                     </div>
                     <div className='content'>
                        <div className='card'>
                           <div className='card_header'>
                              <h2 className='card_title'>
                                 resultado da pesquisa
                              </h2>
                              <p className='card_subtitle'>
                                 Veja abaixo os benefícios cadastrados.{' '}
                              </p>
                           </div>
                           <div className='list_card'>
                              {process && (
                                 <p className='loading-card'>
                                    <Loading type='1' />
                                 </p>
                              )}
                              {!process &&
                                 benefitsFilter.map((item, i) => {
                                    return (
                                       <div
                                          className='card_list_turma'
                                          key={`${i}`}
                                       >
                                          <div className='content_card'>
                                             <div className='card_details'>
                                                <p className='card_details_label text_uppercase' style={{ flexDirection: 'row', }}>
                                                   <span style={{ color: item.status === 'A' ? '#3b3b3b' : 'red', }}>
                                                      {item.name}
                                                   </span>
                                                </p>
                                                <div
                                                   style={{ display: 'flex' }}
                                                   id='botoesEditarItem'
                                                >
                                                   <span
                                                      onClick={(e) =>
                                                         edit(e, item)
                                                      }
                                                      className='btn_card'
                                                      style={{
                                                         marginRight: '1.5%',
                                                         background: 'orange',
                                                         fontWeight: 500,
                                                         border:
                                                            '1px solid orange',
                                                      }}
                                                   >
                                                      <svg
                                                         viewBox='0 0 25 25'
                                                         fill='none'
                                                         xmlns='http://www.w3.org/2000/svg'
                                                      >
                                                         <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                                                      </svg>
                                                      Alterar
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    )
                                 })}
                           </div>
                        </div>
                     </div>
                     <div className='modal' id='add_questionnaire_item' style={display}>
                        <div className='modal_content' ref={modalScroll}>
                           <div className='modal_close' onClick={closeAdd}>
                              X
                           </div>
                           <h3 className='modal_title'>Adicionar um item de benefício</h3>
                           <form
                              // action=''
                              // onSubmit={handleSubmit}
                              // method='post'
                              className='form_modal'
                           >
                              <div className='group_50' style={{ width: '622.5px' }}>
                                 <div className='form_group'>
                                    <label
                                       htmlFor='name'
                                       className='card_details_label'
                                    >
                                       Nome do item
                                    </label>
                                    <input
                                       type='text'
                                       required
                                       name='name'
                                       value={nameModal}
                                       onChange={(e) =>
                                          setNameModal(e.target.value)
                                       }
                                       className='form_control'
                                    />
                                 </div>
                              </div>
                              <div className='group_50' style={{ width: '207.5px' }}>
                                 <div className='form_group'>
                                    <label
                                       htmlFor='title'
                                       className='card_details_label'
                                    >
                                       Status
                                    </label>
                                    <select
                                       required
                                       value={statusModal}
                                       className='form_control select'
                                       onChange={(e) =>
                                          setStatusModal(e.target.value)
                                       }
                                    >
                                       <option value='' disabled hidden></option>
                                       <option value='A'>Ativo</option>
                                       <option value='I'>Inativo</option>
                                    </select>
                                 </div>
                              </div>

                              {!sendSave && (
                                 <button className='btn_card mx-auto' onClick={handleSubmit}>
                                    <svg
                                       viewBox='0 0 25 25'
                                       fill='none'
                                       xmlns='http://www.w3.org/2000/svg'
                                    >
                                       <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                                    </svg>
                                    {idModal === null && 'Incluir'}
                                    {idModal !== null && 'Alterar'} item
                                 </button>
                              )}
                              {sendSave && (
                                 <button
                                    className='btn_card mx-auto'
                                    style={{
                                       background: '#ccc',
                                       border: 'none',
                                    }}
                                    disabled={sendSave}
                                 >
                                    <svg
                                       viewBox='0 0 25 25'
                                       fill='none'
                                       xmlns='http://www.w3.org/2000/svg'
                                    >
                                       <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                                    </svg>
                                    processando...
                                    <Loading
                                       type='1'
                                       style={{ marginLeft: '2%' }}
                                    />
                                 </button>
                              )}
                           </form>
                        </div>
                     </div>
                  </main>

                  <Footer />
               </div>
            </div>
            <SweetAlert
               type={messageType}
               title=''
               show={showMessage}
               onConfirm={() => setShowMessage(false)}
            >
               {message.extra ? t(message.text, message.extra) : t(message)}
            </SweetAlert>
         </div>
      </main>
   )
}

export default Benefit
