import axios from 'axios';

//export  const urlWhatsApp = "http://localhost:3333" ;
export const urlWhatsApp = 'https://api.multdesk.com.br/api';

export const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MjM4ZDI1NDc4ZGJhYTgwMDc4MmU5ZDEiLCJhcGkiOnRydWUsImlhdCI6MTY0Nzg5MTMxMX0.EIQy2M6B-O6bl8aiHBFrB-NrLeIy-ipZP-7OXQVnZXw"

export default axios.create({
  baseURL: urlWhatsApp,
  headers: { Authorization : `Bearer ${token}`}
});
