import React from 'react';

import { Chip, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../../../Context/UserContext';
import { getEmployeeStudentsSchool, saveClassSchool, updateClassSchool } from '../../../services/ClassService';

import Message from '../../../components/Message';
import ButtonRounded from '../../../components/buttons/ButtonRounded';
import Footer from '../../../components/school/Footer';
import useForm from '../../../hooks/useForm';

import '../../../utils/extendsType';

const yearsRange = Array.from({ length: Number(moment().format('Y')) - 1995 + 1 }, (v, i) => 1995 + i);
//component
const CreateClass = () => {
  //hooks states --------------------------------------------------------------------------------------

  const history = useHistory();

  const location = useLocation();

  const { t } = useTranslation();

  const classes = useStyles();

  const formRefer = React.useRef();

  const alertRef = React.useRef();

  //!gambiarra pra resetar o Autocomplete
  const [autokey, setAutoKey] = React.useState(moment().toISOString());

  const { school } = React.useContext(UserContext);

  const [errors, setErrors] = React.useState([]);
  const [errorsServer, setErrorsServe] = React.useState([]);

  const { state, handleOnChange, handleOnSubmit, disable, setState, setDisable } = useForm(
    stateSchema,
    validationStateSchema,
    onSubmitForm,
    formRefer,
    setErrors
  );

  const { MessageDangerMarginTop } = Message();

  const [sendSave, setSendSave] = React.useState(false);

  const [isDone, setIsDone] = React.useState(false);

  const [students, setStudents] = React.useState([]);

  const [employeers, setEmployeers] = React.useState([]);

  const isMaxStudentsExceeded = state.maxStudents.value < state.students.value.length;

  //handler funtions --------------------------------------------------------------------------------------

  async function onSubmitForm(payload) {
    setSendSave(true);
    const elmain = document.querySelector('main');
    try {
      const response =
        payload.id > 0
          ? await updateClassSchool(nomalPayloadAPI(payload))
          : await saveClassSchool(nomalPayloadAPI(payload));

      if (response.id) {
        setIsDone(true);
      }
      if (response.type === 'VALIDATOR') {
        setErrorsServe(response.message);
        setDisable(false);
        if (elmain) {
          elmain.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSendSave(false);
    }
  }

  /* Normaliza os dados para envio API */
  const nomalPayloadAPI = (payload) => {
    const idadesPermitidas = payload.acceptYears.join(';');
    const school_id = school.id;
    const companie_id = school.companie_id;
    const name = payload.name;
    const days = payload.selectedDateTime.map((str) => {
      const [day, time] = str.split(' ');
      return { id: week_days.find((v) => v.name.toLowerCase() === day.toLowerCase()).id, name: day, hour: time };
    });

    const status = payload.status;
    const qtdAulas = payload.lessonsPerWeek;
    const taxaCarteirinha = payload.fee;
    const taxaFalta = payload.forgetFee;
    const qtdeMaximaAluno = payload.maxStudents;
    const students = payload.students.map(({ id }) => ({ id }));
    const responsaveis = payload.employeers.map(({ id, name }) => ({ id, name }));

    return {
      id: payload.id,
      idadesPermitidas,
      school_id,
      companie_id,
      name,
      days,
      status,
      qtdAulas,
      taxaCarteirinha,
      taxaFalta,
      qtdeMaximaAluno,
      students,
      responsaveis,
    };
  };

  /* set state para Edicao */
  const tranformPaylodToEdit = (payload) => {
    const { status, name, qtdAulas, taxaCarteirinha, id, taxaFalta, days, qtdeMaximaAluno, idadesPermitidas } = payload;

    setState({
      ...state,
      id: { value: id, error: '' },
      lessonsPerWeek: { value: qtdAulas, error: '' },
      status: { value: status, error: '' },
      fee: { value: taxaCarteirinha, error: '' },
      forgetFee: { value: taxaFalta, error: '' },
      maxStudents: { value: qtdeMaximaAluno, error: '' },
      name: { value: name, error: '' },
      days: { value: week_days[0].name, error: '' },
      selectedDateTime: { value: days.map((v) => `${v.name} ${v.hour}`), error: '' },
      acceptYears: { value: idadesPermitidas ? idadesPermitidas.split(';') : [], error: '' },
      students: { value: payload.students.sort(sortByName), error: '' },
      employeers: { value: payload.responsaveis.sort(sortByName).map((v) => ({ ...v, role: v.rolRole })), error: '' },
    });

    setDisable(false);
  };

  const handlerFinally = () => {
    history.push('/class-school');
    setIsDone(false);
  };

  async function getRequestClass() {
    const response = await getEmployeeStudentsSchool(school.id);
    setStudents(response.data[0].user.sort(sortByName));
    setEmployeers(response.data[0].employeers.sort(sortByName));
  }

  const resetErrorAcceptYears = React.useCallback((callFilter) => {
    setErrorsServe(errorsServer.filter(callFilter));
  });
  const removeStudentFromList = React.useCallback((id) => {
    setState((current) => ({
      ...current,
      students: { value: current.students.value.filter((student) => student.id !== id) },
    }));
    resetErrorAcceptYears((err) => err.id !== id); //reset error message
  });
  const removeEmployeerFromList = React.useCallback((id) =>
    setState((current) => ({
      ...current,
      employeers: { value: current.employeers.value.filter((funci) => funci.id !== id) },
    }))
  );
  const removeDateFromList = React.useCallback((dt) =>
    setState((current) => ({
      ...current,
      selectedDateTime: { value: current.selectedDateTime.value.filter((d) => d !== dt) },
    }))
  );
  const removeYearFromList = React.useCallback((year) => {
    setState((current) => ({
      ...current,
      acceptYears: { value: current.acceptYears.value.filter((y) => y !== year) },
    }));
    resetErrorAcceptYears((err) => err.ano_nascimento === year); //reset error message
  });

  const addDateTimeToList = React.useCallback(() => {
    const { time, days } = state;

    handleOnChange({
      target: {
        value: [...state.selectedDateTime.value, `${days.value} ${time.value}`].unique(),
        error: '',
        name: 'selectedDateTime',
      },
    });
  });

  const addAcceptYearsToList = React.useCallback((e) => {
    //only react16 Prevents React from resetting its properties
    e.persist();

    setState((current) => ({
      ...current,
      acceptYears: { value: [...current.acceptYears.value, e.target.value].unique() },
    }));
    resetErrorAcceptYears((err) => err.ano_nascimento !== e.target.value); //reset error message
  });

  const sortByName = function (a, b) {
    var nameA = a.name.toLowerCase(),
      nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  const createFilterOptions = (options, { inputValue }) => {
    const filteredOptions = options.filter((option) => {
      const { usuCPF, name, usuBirth, email } = option;
      const lowerSearchText = inputValue.toLowerCase();
      const _name = name.toString().toLowerCase();
      return (
        (_name && _name.includes(lowerSearchText)) ||
        (usuCPF && usuCPF.includes(lowerSearchText)) ||
        (usuBirth && usuBirth.includes(lowerSearchText)) ||
        (email && email.includes(lowerSearchText))
      );
    });
    return filteredOptions;
  };

  const validateAcceptYears = (student) => {
    return state.acceptYears.value.length > 0 ? state.acceptYears.value.some((v) => v == student.ano_nascimento) : true;
  };

  //constructor --------------------------------------------------------------------------------------
  React.useEffect(() => {
    //inicia o select no domingo
    setState((current) => ({
      ...current,
      days: { value: week_days[0].name, error: '' },
    }));

    try {
      getRequestClass();
    } catch (error) {
      console.log(error);
    }
  }, []);

  //add border no sweet alerta
  React.useEffect(() => {
    //garanto que o elemento existe
    if (alertRef.current !== undefined && isDone) {
      // alertRef.current.style.cssText += 'border-top: solid 10px var(--main-color)';
      document.querySelector('div.sweet-alert ').style.cssText += 'border-top: 10px solid var(--main-color)';
    }
  }, [alertRef, isDone]);

  React.useEffect(() => {
    const elmain = document.querySelector('main');

    if (alertRef.current !== undefined && elmain) {
      elmain.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }
  }, [alertRef]);

  React.useEffect(() => {
    if (location.state !== undefined) {
      tranformPaylodToEdit(location.state.payload);
    } else {
      console.log('Reset State');
      setState((current) => ({
        ...current,
        students: { value: [], error: '' },
        employeers: { value: [], error: '' },
      }));
    }
  }, [location]);

  //const properties --------------------------------------------------------------------------------------
  const week_days = [
    { id: 1, name: t('WeekdayName.Sunday') },
    { id: 2, name: t('WeekdayName.Monday') },
    { id: 3, name: t('WeekdayName.Tuesday') },
    { id: 4, name: t('WeekdayName.Wednesday') },
    { id: 5, name: t('WeekdayName.Thursday') },
    { id: 6, name: t('WeekdayName.Friday') },
    { id: 7, name: t('WeekdayName.Saturday') },
  ];

  const columnStudent = [
    {
      id: 'name',
      label: 'Alunos Incluídos',
      minWidth: 220,
      align: 'left',
    },
    {
      id: 'usuBirth',
      label: 'Data Nascimento',
      minWidth: 170,
      align: 'left',
      format: (value) => moment(value).format('DD/MM/YYYY'),
    },
    {
      id: 'id',
      align: 'center',
      label: 'Ação',
      minWidth: 100,
      format: (id) => (
        <BtnDelete
          onClick={(e) => {
            e.preventDefault();
            removeStudentFromList(id);
          }}
        />
      ),
    },
  ];

  const columnEmployee = [
    {
      id: 'name',
      label: 'Funcionários Incluídos',
      minWidth: 220,
      align: 'left',
    },
    {
      id: 'rolRole',
      label: 'Função',
      minWidth: 170,
      align: 'left',
    },
    {
      id: 'id',
      align: 'center',
      label: 'Ação',
      minWidth: 100,
      format: (id) => (
        <BtnDelete
          onClick={(e) => {
            e.preventDefault();
            removeEmployeerFromList(id);
          }}
        />
      ),
    },
  ];

  return (
    <main>
      <SweetAlert
        ref={alertRef}
        show={isDone}
        success
        title='Pronto!'
        onConfirm={handlerFinally}
        customButtons={
          <ButtonRounded isBig={true} onClick={handlerFinally} className='w-100 m-4'>
            Pronto
          </ButtonRounded>
        }
      >
        <p> Turma {state.id.value > 0 ? 'alterada' : 'criada'} com sucesso.</p>
      </SweetAlert>

      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row d-flex align-items-center justify-content-center pt-1 pb-5'>
            <img src='/img/clubes/fensor.svg' className='logo' alt='' />
          </div>
          <div className='row content_painel'>
            <main className='content_panel'>
              {isDone === false && (
                <div className='card p-0 flex-column'>
                  <div className='card_header w-100 flex-column align-items-center pt-3'>
                    <h2 className='card_title'>
                      {state.id.value === 0 ? t('ClassPage.Modal.TitleAdd') : t('ClassPage.Modal.TitleEdit')}
                    </h2>
                    <BtnBack
                      onClick={handlerFinally}
                      style={{
                        float: 'right',
                        position: 'absolute',
                        left: 35,
                        marginTop: 5,
                      }}
                    />
                  </div>
                  <hr />
                  {errors.map((err, k) => (
                    <MessageDangerMarginTop key={`err-${k}`} title='Atenção! ' description={err.error} />
                  ))}
                  {errorsServer.map((item, k) => (
                    <MessageDangerMarginTop
                      title='Atenção! '
                      description={`Aluno(a) ${item.name} nascido em ${item.ano_nascimento}, não está entre as idades permitidas!`}
                      key={`error_${k}`}
                    />
                  ))}
                  <div className='row mx-3'>
                    <form ref={formRefer} action='' className='form_card' noValidate>
                      <div className='col'>
                        <div className='row'>
                          <div className='col-md-4 col-sm-12 form_group'>
                            <label className='card_details_label'>{t('ClassName')} *</label>
                            <input
                              type='text'
                              name='name'
                              required
                              placeholder='Informe o Nome da turma'
                              className='form-control form_control'
                              value={state.name.value}
                              onChange={handleOnChange}
                            />
                          </div>
                          <div className='col-md-4 col-sm-12 form_group'>
                            <label className='card_details_label'>{t('Status')} *</label>
                            <select
                              value={state.status.value}
                              required
                              onChange={handleOnChange}
                              name='status'
                              className='form-control form_control select'
                            >
                              <option value='A'>{t('StatusType.Active')}</option>
                              <option value='I'>{t('StatusType.Inactive')}</option>
                            </select>
                          </div>
                          <div className='col-md-4 col-sm-12 form_group'>
                            <label className='card_details_label'>{t('MaxStudentsInClass')} *</label>
                            <input
                              value={state.maxStudents.value || ''}
                              onChange={handleOnChange}
                              placeholder='Informe a quantidade de alunos'
                              type='number'
                              name='maxStudents'
                              className='form-control form_control'
                              max={999}
                              min={0}
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-6 col-sm-12 form_group'>
                            <label className='card_details_label'> {t('Weekday')}</label>
                            <select
                              name='days'
                              value={state.days.value}
                              onChange={handleOnChange}
                              className='form-control form_control select'
                            >
                              {week_days.map((day) => (
                                <option key={day.name.slice(0, 3)} value={day.name}>
                                  {day.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className='col form_group'>
                            <label className='card_details_label'> {t('Schedule')} *</label>
                            <input
                              type='time'
                              name='time'
                              value={state.time.value}
                              onChange={handleOnChange}
                              className='form-control form_control'
                            />
                          </div>
                          <div className='col form_group pt-4'>
                            <button
                              type='button'
                              disabled={!(state.time.value !== '')}
                              className='btn btn-light btn-lg'
                              style={{ backgroundColor: 'var(--main-color)', color: 'white' }}
                              onClick={addDateTimeToList}
                            >
                              {t('Add')}
                            </button>
                          </div>
                        </div>

                        <div className='row my-3 mx-2'>
                          {state.selectedDateTime.value.length > 0 ? (
                            state.selectedDateTime.value.map((value, k) => (
                              <div className='col-md-3 text-left' key={`d-${k}`}>
                                <BtnDelete onClick={() => removeDateFromList(value)} h={14} w={14} />{' '}
                                <small>{value}</small>
                              </div>
                            ))
                          ) : (
                            <small className='text-danger'>Adicione um Horário!</small>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* Estudantes */}
                  <div className='row mb-2 mx-3'>
                    <div className='col'>
                      <label className='card_details_label'>{t('IncludeStudent')}</label>

                      <Autocomplete
                        key={autokey}
                        classes={classes}
                        options={students.filter(
                          (u) => !state.students.value.find((t) => t.id === u.id) && validateAcceptYears(u)
                        )}
                        getOptionLabel={(option) => option.name}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disabled={state.maxStudents.value <= state.students.value.length}
                        filterOptions={createFilterOptions}
                        onChange={(event, user, detail) => {
                          if (detail === 'select-option') {
                            const { idUser, id, name, usuBirth } = user;
                            state.students.value.push({ idUser, id, name, usuBirth });
                            setState((prevState) => ({
                              ...prevState,
                              students: { value: state.students.value, error: '' },
                            }));
                            setAutoKey(moment().toISOString());
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder='Pesquisar aluno por nome, cpf ou email ou data de nascimento...'
                            variant='outlined'
                            helperText={
                              state.acceptYears.value.length > 0 ? 'O filtro de idades está sendo aplicado' : ''
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                  {isMaxStudentsExceeded && (
                    <MessageDangerMarginTop
                      title='Atenção!'
                      description={'O numero de alunos selecionado é maior que o numero de alunos permitido'}
                      key={`error_maxStudents`}
                    />
                  )}
                  <TableList rows={state.students.value} columns={columnStudent} />

                  {/* Funcionarios  */}
                  <hr />
                  <div className='row mt-3 mb-2 mx-3'>
                    <div className='col'>
                      <label className='card_details_label'> {t('IncludeEmployee')}</label>

                      <Autocomplete
                        key={autokey}
                        classes={classes}
                        options={employeers.filter((u) => !state.employeers.value.find((t) => t.id === u.id))}
                        getOptionLabel={(option) => `${option.name} (${option.role.rolRole})`}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        filterOptions={createFilterOptions}
                        onChange={(event, func, detail) => {
                          if (detail === 'select-option') {
                            const {
                              idUser,
                              id,
                              name,
                              role: { rolRole },
                            } = func;

                            state.employeers.value.push({ idUser, id, name, rolRole });
                            setState((prevState) => ({
                              ...prevState,
                              employeers: { value: state.employeers.value, error: '' },
                            }));
                            setAutoKey(moment().toISOString());
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder='Pesquisar funcionário por nome, cpf ou email ou data de nascimento...'
                            variant='outlined'
                          />
                        )}
                      />
                    </div>
                  </div>

                  <TableList rows={state.employeers.value} columns={columnEmployee} />

                  <hr />
                  <div className='row mx-3'>
                    <form action='' className='form_card' noValidate>
                      <div className='col'>
                        <div className='row'>
                          <div className='col-md-3 col-sm-12 form_group'>
                            <label className='card_details_label'>{t('ClassPage.Modal.AllowedAgesSimple')}</label>
                            <select
                              name='acceptYears'
                              className='form-control form_control select'
                              value={state.acceptYears.value.last()}
                              onChange={addAcceptYearsToList}
                            >
                              <option value=''>Selecione os Anos</option>
                              {yearsRange.map((y) => (
                                <option key={y} value={y}>
                                  {y}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className='col-md-3 col-sm-12 form_group'>
                            <label className='card_details_label'> {t('NumClassPerWeek')}</label>
                            <input
                              type='text'
                              name='lessonsPerWeek'
                              className='form-control form_control'
                              value={state.lessonsPerWeek.value}
                              onChange={handleOnChange}
                            />
                          </div>
                          <div className='col-md-3 col-sm-12 form_group'>
                            <label className='card_details_label'> {t('CardFee')}</label>
                            <NumberFormat
                              name='fee'
                              isNumericString
                              format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })}
                              className='form-control form_control'
                              value={state.fee.value * 100}
                              onValueChange={(e) => {
                                handleOnChange({
                                  target: { value: e.floatValue / 100, name: 'fee', setCustomValidity: () => {} },
                                });
                              }}
                            />
                          </div>
                          <div className='col-md-3 col-sm-12 form_group'>
                            <label className='card_details_label'> {t('CardForgettingFee')}</label>
                            <NumberFormat
                              name='forgetFee'
                              className='form-control form_control'
                              isNumericString
                              format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })}
                              value={state.forgetFee.value * 100}
                              onValueChange={(e) => {
                                handleOnChange({
                                  target: { value: e.floatValue / 100, name: 'forgetFee', setCustomValidity: () => {} },
                                });
                              }}
                            />
                          </div>
                        </div>
                        {/* Selected years  */}
                        <div className='row my-3 mx-2 align-items-center'>
                          <div className='col-md-1 text-right'>{t('ClassPage.Year')}:</div>
                          {state.acceptYears.value
                            .filter((v) => v && v.length > 0)
                            .sort((a, b) => a - b)
                            .map((ay) => (
                              <div className='col-md-1 mb-2' key={ay}>
                                <StyledChip onDelete={() => removeYearFromList(ay)} label={ay} />
                              </div>
                            ))}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='row align-items-center justify-content-center my-4'>
                    <div className='col-md-5 col-sm-10'>
                      <ButtonRounded
                        className='w-100'
                        variant='outline'
                        color='var(--main-color)'
                        isBig={true}
                        onClick={handlerFinally}
                      >
                        {t('Back')}
                      </ButtonRounded>
                    </div>
                    <div className='col-md-5 col-sm-10'>
                      <ButtonRounded
                        isDisabled={disable || isMaxStudentsExceeded}
                        isLoading={sendSave}
                        onClick={handleOnSubmit}
                        className='w-100'
                        isBig={true}
                      >
                        {state.id.value > 0 ? t('ClassPage.Modal.ButtonEdit') : t('ClassPage.Modal.ButtonAdd')}
                      </ButtonRounded>
                    </div>
                  </div>
                </div>
              )}
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};
//form states ----------------------------------------------------------------------------------------------
const stateSchema = {
  id: { value: 0, error: '' },
  name: { value: '', error: '' },
  status: { value: 'A', error: '' },
  maxStudents: { value: '', error: '' },
  days: { value: '', error: '' },
  time: { value: '', error: '' },
  acceptYears: { value: [], error: '' },
  selectedDateTime: { value: [], error: '' },
  lessonsPerWeek: { value: 0, error: '' },
  fee: { value: 0, error: '' },
  forgetFee: { value: 0, error: '' },
  students: { value: [], error: '' },
  employeers: { value: [], error: '' },
};

const validationStateSchema = {
  name: {
    required: true,
  },
  status: {
    required: true,
  },
  maxStudents: {
    validator: {
      max: 999,
      error: 'Valor {{value}} excede o maximo permitido de 999!',
    },
  },
  selectedDateTime: {
    required: true,
    validator: {
      min: 1,
      error: 'Adicione ao menos um dia/horario!',
    },
  },
};

//material ui custom styles --------------------------------------------------------------------------------
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'var(--main-color)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledChip = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: `var(--main-color) !important`,
    '&:hover': {
      backgroundColor: 'var(--main-color)',
      filter: 'brightness(120%)',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'var(--main-color)',
      borderColor: 'var(--main-color)',
    },
  },

  deleteIcon: {
    color: theme.palette.common.white,
  },
}))(Chip);

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: '#f4f0f0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
  },
});

//private components  --------------------------------------------------------------------------------------
const BtnDelete = ({ fcolor = '#FF2929', w = 16, h = 16, onClick }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer' }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z'
        fill={fcolor}
      />
    </svg>
  </span>
);

const BtnBack = ({ fcolor = '#1C1B1F', w = 16, h = 16, onClick, style }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer', ...style }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z' fill={fcolor} />
    </svg>
  </span>
);

const TableList = ({ columns, rows, mxh = 380 }) => {
  return (
    <TableContainer style={{ maxHeight: mxh, minHeight: mxh * 0.8 }}>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow hover tabIndex={-1} key={row.id}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {typeof column.format === 'function' ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default CreateClass;
