import React, { useEffect, useState } from 'react';
import HeaderSchool from '../../components/school/Header';
import HeaderClub from '../../components/club/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Utils, { getUserPhoto } from '../../../src/utils/utils';
import { Link, useHistory, useParams } from 'react-router-dom';
import Api from '../../utils/api';
import axios from 'axios';
import Badge from 'react-bootstrap/Badge';
import { useTranslation } from 'react-i18next';
import { getStudentClasses } from '../../services/StudentService';
import FinancialTable from '../../components/tables/FinancialTable';

/* sublevel alunos */
const SUB_LEVEL_DISPLAY = {
  "BEGINNER": "Beginner",
  "INTERMEDIARY": "Intermediary",
  "ADVANCED": "Advanced",
};

const DetailStudent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { idStudent } = useParams();
  const [isSchool , setIsSchool ] = useState(false);

  const [student, setStudent] = useState([]);
  // const [countParticipacaoSeletivas, setCountParticipacaoSeletivas] = useState(0);
  // const [countAprovacaoSeletivas, setCountAprovacaoSeletivas] = useState(0);
  const [countFaltas, setCountFaltas] = useState(0);
  const [countAulas, setCountAulas] = useState(0);
  // const [seletivas, setSeletivas] = useState([]);
  const [dirUsuFotoPrincipal, setDirUsuFotoPrincipal] = useState('');
  const [timeSchool, setTimeSchool] = useState('');
  const [filteredFinancials, setFilteredFinancials] = useState([]);
  const [financialFilter, setFinancialFilter] = useState('TUDO');
  const [studentClassesIsLoading, setStudentClassesIsLoading] = useState(true);
  const [studentClasses, setStudentClasses] = useState([]);

  const [month, setMonth] = useState();
  const [frequency, setFrequency] = useState([]);
  const [daysMonth, setDaysMonth] = useState([]);

  const [process, setProcess] = useState(false);
  const [processFrequency, setProcessFrequency] = useState(false);

  const { formatCNPJCPF, formatCEP, diffDaysNow, formatTelefone, dateFormat } = Utils();
  const baseURL = Api();

  useEffect(() => {
    checkIfIsSchool();
    async function getStudent() {
      setProcess(true);
      const res = await axios.post(baseURL + 'get-student-seletivas-frequency', {
        user_id: idStudent,
      });
      setDirUsuFotoPrincipal(res.data.dir_usuFotoPrincipal);
      setStudent(res.data.student);
      // setCountParticipacaoSeletivas(res.data.countParticipacaoSeletivas);
      // setCountAprovacaoSeletivas(res.data.countAprovacaoSeletivas);
      setCountFaltas(res.data.countFaltas);
      setCountAulas(res.data.countAulas);
      // setSeletivas(res.data.seletivas);
      calcTimeSchool(res.data.student.created_at);
      setFilteredFinancials(res.data.financials.filter(({ type }) => type === 'Receita'));

      setProcess(false);
      getFrequency('', idStudent);
      getClasses(idStudent);
    }

    getStudent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfIsSchool = () => {
    const sessionSchool = JSON.parse(localStorage.getItem('user'));
    setIsSchool(sessionSchool.role.type === "SCHOOL");
  };

  const calcTimeSchool = (date) => {
    const dataCreated = date.split(' ')[0];
    const dias = diffDaysNow(dataCreated);
    setTimeSchool(dias);
  };

  const getFrequency = async (monthYear = '', idStudent) => {
    setDaysMonth([]);
    setFrequency([]);

    if (monthYear == '') {
      monthYear = new Date().toISOString().substr(0, 7);
      setMonth(new Date().toISOString().substr(5, 2));
    } else {
      monthYear = monthYear.substr(0, 7);
      setMonth(monthYear.substr(5, 2));
    }

    setProcessFrequency(true);
    const res = await axios.post(baseURL + 'get-frequency-student-per-month', {
      idStudent,
      monthYear,
    });

    setDaysMonth(res.data.monthYear);
    setFrequency(res.data.student);
    setProcessFrequency(false);
  };

  const getClasses = async (studentId) => {
    setStudentClassesIsLoading(true);
    const { classes } = await getStudentClasses(studentId);
    setStudentClasses(classes);
    setStudentClassesIsLoading(false);
  };

  const filterFinancial = (item) => {
    if (financialFilter === 'TUDO') {
      return true;
    } else if (financialFilter === 'VENCIDA') {
      const today = new Date();
      const auxEndDate = item.dueDate.split('-');
      const endDate = new Date(auxEndDate[0], auxEndDate[1] - 1, auxEndDate[2]);
      return endDate < today && item.received === 'N';
    } else if (financialFilter === 'A VENCER') {
      const today = new Date();
      const auxEndDate = item.dueDate.split('-');
      const endDate = new Date(auxEndDate[0], auxEndDate[1] - 1, auxEndDate[2]);
      return endDate >= today && item.received === 'N';
    } else if (financialFilter === 'PAGA') {
      return item.received === 'S';
    }
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            {isSchool ? <HeaderSchool /> : <HeaderClub />}
            <main className='content_panel'>
              <div className='sidebar'>
                {process && (
                  <div className='card'>
                    <p className='loading-card'>
                      <Loading type='1' />
                    </p>
                  </div>
                )}
                {!process && (
                  <React.Fragment>
                    <div className='card'>
                      <div className='img_school'>
                        <img
                          src={getUserPhoto(dirUsuFotoPrincipal + '/', student.usuFotoPrincipal)}
                          alt=''
                          style={{ borderRadius: '50%' }}
                        />
                      </div>
                      <h2 className='card_title mx-auto'>{student.name}</h2>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label'>{t('Position')}: </p>
                          <p className='card_details_text'>{t('Sports.Position.' + student.posPosition)}</p>
                        </div>
                        <div className='card_details'>
                          <p className='card_details_label'>{t('Birth')}: </p>
                          <p className='card_details_text'>{dateFormat(student.usuBirth)}</p>
                        </div>
                        <div className='card_details'>
                          <p className='card_details_label'>{t('CellPhone')}: </p>
                          <p className='card_details_text'>{formatTelefone(String(student.usuPhone))}</p>
                        </div>
                        <div className='card_details'>
                          <p className='card_details_label'>{t('Email')}: </p>
                          <p className='card_details_text'>{student.email}</p>
                        </div>
                        <div className='card_details'>
                          <p className='card_details_label'>{t('Sublevel')}: </p>
                          <p className='card_details_text'>{t(SUB_LEVEL_DISPLAY[student.sublevel]) || "--"}</p>
                        </div>
                        <div className='card_details'>
                          <p className='card_details_label'>{t('SkillLevel')}: </p>
                          <p className='card_details_text'>{student.skillLevel == 7 ? '6' : student.skillLevel || "--"} {parseInt(student.skillLevel) === 7 ? <i className='pl-1'>{t('Complete')}</i> : ''}</p>
                        </div>
                      </div>
                    </div>
                    <div className='card'>
                      <div className='card_header mx-auto'>
                        <h2 className='card_title'>{t('TimeAtSchool')}</h2>
                        <div className='sidebar_data'>{t('Day', { count: timeSchool })}</div>
                      </div>
                      <hr />
                      {/* <div className='card_header mx-auto'>
                        <h2 className='card_title'>{t('ParticipationInSelectives')}</h2>
                        <div className='sidebar_data'>{countParticipacaoSeletivas}</div>
                      </div>
                      <hr />
                      <div className='card_header mx-auto'>
                        <h2 className='card_title'>{t('ApprovalInSelective')}</h2>
                        <div className='sidebar_data'>{countAprovacaoSeletivas}</div>
                      </div> */}
                    </div>
                    <div className='card'>
                      <div className='card_header mx-auto'>
                        <h2 className='card_title'>{t('NumberOfClasses')}</h2>
                        <div className='sidebar_data'>{countAulas}</div>
                      </div>
                      <hr />
                      <div className='card_header mx-auto'>
                        <h2 className='card_title'>{t('NumberOfAbsences')}</h2>
                        <div className='sidebar_data'>{countFaltas}</div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className='content'>
                {process && (
                  <div className='card'>
                    <p className='loading-card'>
                      <Loading type='1' />
                    </p>
                  </div>
                )}
                {!process && (
                  <React.Fragment>
                    <div className='card'>
                      <div className='card_header'>
                        <h2 className='card_title'>{t('StudentInformation')}</h2>
                      </div>
                      <div className='content_card aluno_content'>
                        <div className='card_details'>
                          <p className='card_details_label'>{t('StartDate')}: </p>
                          <p className='card_details_text'>{dateFormat(student.created_at)}</p>
                        </div>
                        <div className='card_details'>
                          <p className='card_details_label'>{t('BestFoot')}: </p>
                          <p className='card_details_text'>
                            {student.athLateralidade == 'D'
                              ? t('Right')
                              : student.athLateralidade == 'C'
                              ? t('Left')
                              : t('Ambidextrous')}
                          </p>
                        </div>
                        <div className='card_details'>
                          <p className='card_details_label'>{t('Address')}: </p>
                          <p className='card_details_text'>
                            {student.athLogradouro}, {student.athNumero}, {student.athBairro}, {student.citCity}-
                            {student.staStateSigla}
                          </p>
                        </div>
                        <div className='card_details'>
                          <p className='card_details_label'>{t('CEP')}: </p>
                          <p className='card_details_text'>{formatCEP(String(student.athCep))}</p>
                        </div>
                      </div>
                      <div className='content_card aluno_content'>
                        <div className='group_50'>
                          <div className='card_details'>
                            <p className='card_details_label'>{t('Mother')}: </p>
                            <p className='card_details_text'>{student.athMaeNome}</p>
                          </div>
                          <div className='card_details'>
                            <p className='card_details_label'>{t('CellPhone')}: </p>
                            <p className='card_details_text'>{formatTelefone(String(student.athMaeCelular))}</p>
                          </div>
                          <div className='card_details'>
                            <p className='card_details_label'>{t('CPF')}: </p>
                            <p className='card_details_text'>{formatCNPJCPF(String(student.athMaeCPF))}</p>
                          </div>
                        </div>
                        <div className='group_50'>
                          <div className='card_details'>
                            <p className='card_details_label'>{t('Father')}: </p>
                            <p className='card_details_text'>{student.athPaiNome}</p>
                          </div>
                          <div className='card_details'>
                            <p className='card_details_label'>{t('CellPhone')}: </p>
                            <p className='card_details_text'>{formatTelefone(String(student.athPaiCelular))}</p>
                          </div>
                          <div className='card_details'>
                            <p className='card_details_label'>{t('CPF')}: </p>
                            <p className='card_details_text'>{formatCNPJCPF(String(student.athPaiCPF))} </p>
                          </div>
                        </div>
                      </div>
                      <h2 className='card_title'>{t('FinancialResponsible')}</h2>
                      <div className='content_card aluno_content'>
                        <div className='group_50'>
                          <div className='card_details'>
                            <p className='card_details_label'>{t('FinancialResponsibleName')}: </p>
                            <p className='card_details_text'>{student.athNomeRespFin}</p>
                          </div>
                          <div className='card_details'>
                            <p className='card_details_label'>{t('FinancialResponsibleEmail')}: </p>
                            <p className='card_details_text'>{formatCNPJCPF(String(student.athEmailRespFin))}</p>
                          </div>
                          <div className='card_details'>
                            <p className='card_details_label'>{t('CellPhone')}: </p>
                            <p className='card_details_text'>{formatTelefone(String(student.athPhoneRespFin))}</p>
                          </div>
                        </div>
                      </div>
                      <div className='content_card aluno_content'>
                        <div className='card_header'>
                          <h2 className='card_title'>{t('Classes_other')}</h2>
                        </div>
                        <div className='content_card aluno_content'>
                          <div className='card_details'>
                            {studentClassesIsLoading && <Loading type='1' />}
                            {!studentClassesIsLoading && studentClasses.length === 0 && (
                              <p className='card_details_text'>{t('StudentDontHaveClassesRegistered')}</p>
                            )}
                            <ul style={{ listStyle: 'initial', padding: '0px 0px 0px 30px' }}>
                              {!studentClassesIsLoading &&
                                studentClasses.length > 0 &&
                                studentClasses
                                  .sort((a, b) => a.id - b.id)
                                  .map((studentClass) => (
                                    <li key={`DetailStudent_StudentClass_${studentClass.id}`}>
                                      {isSchool ? (
                                        <Link
                                          className='table_mes'
                                          to={{
                                            pathname: 'detail-class',
                                            state: {
                                              id: studentClass.id,
                                              school_id: JSON.parse(localStorage.getItem('school')).id,
                                              name: studentClass.name,
                                            },
                                          }}
                                        >
                                          {studentClass.name}
                                        </Link>
                                      ) : (
                                        studentClass.name
                                      )}
                                    </li>
                                  ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='content_card aluno_content'>
                        <div className='card_header'>
                          <h2 className='card_title'>{t('About')}</h2>
                        </div>
                        <div className='content_card aluno_content'>
                          <div className='card_details'>
                            <p className='card_details_text'>{student.usuSobre}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>
                      {t('Financial')}
                      <Badge
                        pill
                        variant='success'
                        style={{
                          marginLeft: 3,
                          background: 'orange',
                          fontWeight: '500',
                        }}
                        className='badge-font-size-50'
                      >
                        {filteredFinancials.length}
                      </Badge>
                    </h2>
                    <p className='card_subtitle'>{t('SeeStudentInstallmentsBelow')}</p>
                    <div>
                      <input
                        type='radio'
                        value='TUDO'
                        name='financialFilter'
                        checked={financialFilter === 'TUDO'}
                        onChange={(e) => setFinancialFilter(e.target.value)}
                      />
                      <span style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>{t('Everything')}</span>

                      <input
                        type='radio'
                        value='VENCIDA'
                        name='financialFilter'
                        checked={financialFilter === 'VENCIDA'}
                        onChange={(e) => setFinancialFilter(e.target.value)}
                      />
                      <span style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>
                        {t('Overdue', { context: 'female', count: 2 })}
                      </span>

                      <input
                        type='radio'
                        value='A VENCER'
                        name='financialFilter'
                        checked={financialFilter === 'A VENCER'}
                        onChange={(e) => setFinancialFilter(e.target.value)}
                      />
                      <span style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>{t('ToExpire')}</span>

                      <input
                        type='radio'
                        value='PAGA'
                        name='financialFilter'
                        checked={financialFilter === 'PAGA'}
                        onChange={(e) => setFinancialFilter(e.target.value)}
                      />
                      <span style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>
                        {t('Paid', { context: 'female', count: 2 })}
                      </span>
                    </div>
                  </div>
                  <div style={{ overflow: 'scroll', width: '100%' }}>
                    <FinancialTable
                      type='INCOME'
                      isLoading={process}
                      onEdit={
                        isSchool
                          ? (finance) =>
                            history.push('/financial-school', {
                              student: student,
                              financials: finance,
                            })
                          : undefined
                      }
                      finances={filteredFinancials.filter(filterFinancial)}
                    />
                  </div>
                </div>
                {/* <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('Selective', { context: 2 })}</h2>
                    <p className='card_subtitle'>{t('StudentSelectiveSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    {seletivas.map((item, i) => {
                      return (
                        <div
                          className={`card_list_seletiva ${item.iteItem == 'Aprovado' ? 'aprovado' : 'reprovado'}`}
                          key={`seletivas_${i}`}
                        >
                          <div className='img_list_seletiva'>
                            <img src='../../../img/clubes/seletiva.jpg' alt='' />
                          </div>
                          <div className='content_card'>
                            <div className='card_details'>
                              <p className='card_details_label'>{t('Club')}: </p>
                              <p className='card_details_text'>{item.comCompanie}</p>
                            </div>
                            <div className='card_details'>
                              <p className='card_details_label'>{t('Place')}: </p>
                              <p className='card_details_text'>{item.locLocal}</p>
                            </div>
                            <div className='card_details'>
                              <p className='card_details_label'>{t('Date')}: </p>
                              <p className='card_details_text'>{dateFormat(String(item.eveData))}</p>
                            </div>
                          </div>
                          <div className='card_details'>
                            <div className='seletiva_result_icon' style={{ height: '0px !important' }}>
                              <svg viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M12.0098 2.06048C10.6811 0.731619 8.91422 0 7.03516 0C5.15609 0 3.38942 0.731619 2.06053 2.06048C0.731857 3.38912 0 5.15598 0 7.035C0 8.91402 0.731857 10.6807 2.06053 12.0095C3.3892 13.3382 5.15609 14.07 7.03516 14.07C8.91422 14.07 10.6811 13.3382 12.0098 12.0095C13.3385 10.6809 14.0703 8.91424 14.0703 7.035C14.0703 5.15576 13.3385 3.38912 12.0098 2.06048ZM7.20907 2.38876L9.26449 1.78862L11.3241 3.29051L11.3883 5.42384L9.354 6.31893L7.20907 4.76041V2.38876ZM4.55617 6.63343L5.36421 9.12058L4.03043 10.5327L1.96413 9.55302L1.40996 7.41836L2.5474 5.74967L4.55617 6.63343ZM2.67778 5.45116L2.74419 3.24475L4.79827 1.7864L6.88323 2.3952V4.74442L4.69122 6.33692L2.67778 5.45116ZM5.50237 9.44863H8.59949L8.61903 9.38889L9.89573 10.7408L8.83959 12.4257H5.25271L4.23056 10.7953L5.50237 9.44863ZM10.121 10.5047L8.73364 9.03596L9.52547 6.59945L11.5054 5.72835L12.681 7.45323L12.0915 9.57057L10.121 10.5047ZM12.5055 3.38401L11.4516 2.98045L9.37954 1.46968L8.74186 0.680978C10.2993 1.09965 11.6302 2.07692 12.5055 3.38401ZM5.32845 0.680978L4.70232 1.45546L2.48276 3.03131L1.56589 3.38224C2.44123 2.07603 3.77167 1.09943 5.32845 0.680978ZM0.468655 6.63365L1.1361 7.65979L1.68272 9.76536L1.63474 10.7879C0.892222 9.72293 0.455995 8.42894 0.455995 7.035C0.455995 6.90018 0.460659 6.76647 0.468655 6.63365ZM4.23811 12.989L5.45683 12.7516H8.63525L9.83842 12.9861C8.98752 13.3886 8.0371 13.6142 7.03493 13.6142C6.03543 13.614 5.08746 13.3895 4.23811 12.989ZM12.4562 10.7584L12.4047 9.65985L12.9842 7.57827L13.6017 6.62921C13.6099 6.76359 13.6145 6.89885 13.6145 7.035C13.6143 8.41606 13.1863 9.69895 12.4562 10.7584Z' />
                              </svg>
                            </div>
                            <p className='seletiva_result'>{item.iteItem}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div> */}
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('StudentAttendance')}</h2>
                    <p className='card_subtitle'>
                      {t('StudentAttendanceSubtitle')}
                      {processFrequency && <Loading type='1' style={{ marginLeft: 5 }} />}
                    </p>
                  </div>
                  {!processFrequency && frequency.length > 0 && (
                    <React.Fragment>
                      <div className='table_header'>
                        {[
                          { monthNumber: '01', monthName: 'January' },
                          { monthNumber: '02', monthName: 'February' },
                          { monthNumber: '03', monthName: 'March' },
                          { monthNumber: '04', monthName: 'April' },
                          { monthNumber: '05', monthName: 'May' },
                          { monthNumber: '06', monthName: 'June' },
                          { monthNumber: '07', monthName: 'July' },
                          { monthNumber: '08', monthName: 'August' },
                          { monthNumber: '09', monthName: 'September' },
                          { monthNumber: '10', monthName: 'October' },
                          { monthNumber: '11', monthName: 'November' },
                          { monthNumber: '12', monthName: 'December' },
                        ].map(({ monthNumber, monthName }) => (
                          <p
                            key={`DetailStudent_Months_${monthNumber}`}
                            onClick={() =>
                              getFrequency(new Date().toISOString().substr(0, 4) + `-${monthNumber}`, idStudent)
                            }
                            className={`table_mes ${month == '01' ? 'active' : ''}`}
                          >
                            {t(`MonthName.${monthName}`, { context: 'abbreviated' })}
                          </p>
                        ))}
                      </div>

                      <table className='table'>
                        <thead>
                          <tr>
                            <th>{t('Student')}</th>
                            {daysMonth.map((item, i) => (
                              <th key={i}>{item.data}</th>
                            ))}
                            <th>{t('Class', { count: 2 })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {frequency.map((student, i) => {
                            return (
                              <tr key={i}>
                                <td>{student.name}</td>
                                {daysMonth.map((day, y) => (
                                  <td
                                    key={y}
                                    className={`${String(student.dates).indexOf(day.data) !== -1 ? 'check' : ''}`}
                                  ></td>
                                ))}
                                <td>
                                  {student.frequente}
                                  <b className='table_day'>/{daysMonth.length}</b>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default DetailStudent;
