import React, { useEffect, useState, useContext, useRef } from 'react';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import {
  getDailyStudentFrequencyPerClass,
  getDays,
  getDetailCardPerAthelte,
  getDetailCardPerDayPerSchool,
  getMonthlyStudentFrequencyPerClass,
  registerStudentFrequencies,
  updateAbsenceJustification,
  updateAbsenceStudentJustification,
} from '../../services/ClassService';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../Context/UserContext';
import { SchoolRoleUtils } from '../../utils/role-utils';
import { getMonthsGrouped, handleOnChange, range } from '../../utils/utils';
import moment from 'moment';
import InputRounded from '../../components/inputs/InputRounded';
import SelectRounded from '../../components/inputs/SelectRounded';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import { FaRegCalendarAlt, FaSearch, FaPlus, FaMinus, FaEdit, FaEye } from 'react-icons/fa';
import { GiWhistle } from 'react-icons/gi';
import Loading from '../../components/Loading';
import { Collapse } from '@material-ui/core';
import Message from '../../components/Message';
import RegisterStudentAttendance from './RegisterStudentAttendance';
import ViewPendingAttendances from './ViewPendingAttendances';
import ViewAttendances from './ViewAttendances';
import { BsBell } from 'react-icons/bs';

const Class = () => {
  const { user, school } = useContext(UserContext);
  const { t } = useTranslation();
  const { MessageDangerMarginTop } = Message();
  const registerStudentAttendanceRef = useRef(null);
  const viewPendingAttendancesRef = useRef(null);
  const viewAttendancesRef = useRef(null);

  const monthsGrouped = getMonthsGrouped();
  const now = moment();

  const [studentClassSelected, setStudentClassSelected] = useState(null);
  const [studentClasses, setStudentClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [studentsFiltered, setStudentsFiltered] = useState([]);
  const [student, setStudent] = useState(null);
  const [studentClass, setStudentClass] = useState(null);
  const [weekDaySelected, setWeekDaySelected] = useState(null);
  const [frequencies, setFrequencies] = useState([]);
  const [countFaltas, setCountFaltas] = useState(0);
  const [countAulas, setCountAulas] = useState(0);

  // carregamento
  const [days, setDays] = useState([]);

  // filtros
  const [filters, setFilters] = useState({
    dayId: now.weekday() + 1,
    name: '',
    year: now.year(),
    month: now.month() + 1,
  });

  // process
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStudentWithFrequencies, setIsLoadingStudentWithFrequencies] = useState(false);
  const [process, setProcess] = useState(false);
  const [isLoadingFrequencies, setIsLoadingFrequencies] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [user_id, setUserID] = useState('');

  const onChangeFilters = handleOnChange(setFilters);

  useEffect(() => {
    (async () => {
      const usuSession = JSON.parse(await localStorage.getItem('user'));

      setUserID(usuSession.id);
      fetchDays();
      getClass(usuSession.id);
    })();


    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  useEffect(() => {
    setStudentsFiltered(
      students.filter((student) =>
        filters.name === '' ? true : student.name.toLowerCase().includes(filters.name.toLowerCase())
      )
    );
  }, [students, filters.name]);

  useEffect(() => {
    if (studentClassSelected !== null) getStudentsWithFrequencies(studentClassSelected);
  }, [studentClassSelected, filters.year, filters.month]);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') registerStudentAttendanceRef.current.closeModal();
  };

  const fetchDays = async () => {
    setIsLoading(true);
    const days = await getDays();
    setDays(days);
    setIsLoading(false);
  };

  const getClass = async (userId) => {
    try {
      setIsLoading(true);
      setProcess(true);
      setErrorMessage(null);
      setStudentClasses([]);
      setStudentClassSelected(null);
      setStudent(null);

      const { classs, countFaltas, countAulas } = await getDetailCardPerAthelte({ userId: userId, schoolId: school.id });

      setCountFaltas(countFaltas);
      setCountAulas(countAulas);
      setStudentClasses(classs);
      setIsLoading(false);
      setProcess(false);
    } catch (error) {
      setErrorMessage('SystemMessage.Fail.InternalError');
    }
  };

  const getStudentsWithFrequencies = async ({ classId, hour }) => {
    setIsLoadingStudentWithFrequencies(true);
    setErrorMessage(null);
    setStudents([]);
    setStudent(null);

    const { status, studentClass, students } = await getMonthlyStudentFrequencyPerClass({
      classId: classId,
      hour: hour,
      year: filters.year,
      month: filters.month,
      withClass: true,
      userId: user_id
    });

    if (status === 'SUCCESS') {
      setWeekDaySelected(filters.dayId - 1);
      setStudentClass(studentClass);
      setStudents(
        students
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
          .map((student) => ({
            ...student,
            frequency: `${student.frequencies.filter((s) => s.confirmed === 'S' || s.justification_title !== null).length
              }/${student.frequencies.length}`,
            pendingAttendances: student.frequencies.filter(
              (f) => f.confirmed === 'N' && f.justification_title === null
            ),
            attendances: student.frequencies.filter((f) => f.confirmed === 'N' && f.justification_title !== null),
          }))
      );
      setIsLoadingStudentWithFrequencies(false);
    } else {
      setErrorMessage('SystemMessage.Fail.InternalErrorTryAgain');
    }
  };

  const onSelectClass = (studentClass) => {
    setStudentClassSelected((current) =>
      current?.classId === studentClass.classId && current?.classDayId === studentClass.classDayId ? null : studentClass
    );

    setStudentClass(null);
    setFrequencies([]);
  };

  const onSubmitAbsenceJustification = async (formData) => {
    setIsSubmitting(true);
    setTimeout(() => setIsSubmitting(false), 5000);

    const { status, messageKey, message, fullSuccess } = await updateAbsenceStudentJustification(formData, user_id);
    setIsSubmitting(false);

    if (status === 'SUCCESS') {
      const studentSelected = student;
      getStudentsWithFrequencies(studentClassSelected).then(() =>
        setStudents((students) => {
          setStudent(students.find(({ id }) => id === studentSelected.id));
          return students;
        })
      );

      return {
        status: fullSuccess ? 'SUCCESS' : 'WARNING',
        message: messageKey ?? message,
      };
    } else {
      return {
        status,
        message: messageKey ?? message,
      };
    }
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card flex-column align-items-center'>

                  {!process && (
                    <>
                      <div className='card_header mx-auto'>
                        <h2 className='card_title'>{t('NumberOfClasses')}</h2>
                        <div className='sidebar_data'>{countAulas}</div>
                      </div>
                      <hr />
                      <div className='card_header mx-auto'>
                        <h2 className='card_title'>{t('NumberOfAbsences')}</h2>
                        <div className='sidebar_data'>{countFaltas}</div>
                      </div>
                    </>
                  )}

                </div>

                <div className='card flex-column align-items-center'>
                  <div className='card_header mt-4 mx-auto'>
                    <h2 className='card_title'>{t('My_classes')}</h2>
                  </div>
                  <p>
                    {studentClasses.length === 0 && t('ClassStudentAttendancePage.ActionCard.NoStudentClassesFound')}
                  </p>
                  {studentClasses.map((studentClassAux) => (
                    <div
                      key={`studentClass_list_${studentClassAux.classId}_${studentClassAux.hour}`}
                      className={
                        `position-relative w-100 mt-3 d-flex flex-column align-items-center py-2 border-color-default clickable ` +
                        (studentClassSelected?.classId === studentClassAux.classId &&
                          studentClassSelected?.classDayId === studentClassAux.classDayId
                          ? 'background-color-default text-white'
                          : '')
                      }
                      style={{ border: '1px solid', borderRadius: '8px' }}
                      onClick={() => onSelectClass(studentClassAux)}
                    >
                      <span className='text-bold mb-2' style={{ fontSize: '120%' }}>
                        {t('Schedule')}: {studentClassAux.hour}
                      </span>
                      {studentClassAux.frequency.length > 0 &&
                        <span style={{ top: 4, left: 4 }} className="badge badge-danger position-absolute ">
                          <BsBell size={20} />
                        </span>
                      }
                      <div>
                        <div className='d-flex' style={{ columnGap: '10px' }}>
                          <span className='text-right text-bold' style={{ width: '60px' }}>
                            {t('Classes')}:
                          </span>
                          <span className='text-left'>{studentClassAux.name}</span>
                        </div>
                        <div className='d-flex' style={{ columnGap: '10px' }}>
                          <span className='text-right text-bold' style={{ width: '60px' }}>
                            {t('Days')}:
                          </span>
                          <span className='text-left'>{studentClassAux.dias}</span>
                        </div>
                        <div className='d-flex' style={{ columnGap: '10px' }}>
                          <span className='text-right text-bold' style={{ width: '60px' }}>
                            {t('Responsible_abbreviated')}:
                          </span>
                          <span className='text-left'>{studentClassAux.responsaveis}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className='content'>
                {!studentClassSelected ? (
                  <div className='card' style={{ height: '130px' }}>
                    <div className='card_header mx-auto text-center align-items-center justify-content-center'>
                      <h4>{t('ClassStudentAttendancePage.ContentCard.NoStudentClassSelected')}</h4>
                    </div>
                  </div>
                ) : (
                  <div className='card' style={{ rowGap: 5 }}>
                    {errorMessage && (
                      <div className='w-100 mb-2'>
                        <MessageDangerMarginTop
                          title={t('Attention_exclamation') + ' '}
                          description={t(errorMessage)}
                        />
                      </div>
                    )}
                    <div className='card_header w-100 position-relative'>
                      <h2 className='card_title' style={{ fontSize: '1.2rem' }}>
                        {t('ClassStudentAttendancePage.ContentCard.Title')}
                      </h2>
                      <p
                        key={studentClassSelected.classId}
                        className='card_subtitle d-flex d-md-block flex-column mt-2'
                      >
                        {[
                          { key: t('Classes'), value: studentClassSelected.name },
                          { key: t('Schedule'), value: studentClassSelected.hour },
                          { key: t('Days'), value: studentClassSelected.dias },
                          { key: t('Responsible'), value: studentClassSelected.responsaveis },
                        ].map(({ key, value }, i) => (
                          <span
                            key={`studentClass_list_infos_${studentClassSelected.classId}_${studentClassSelected.classDayId}_${key}`}
                          >
                            {i !== 0 && <span className='mx-1 d-none d-md-inline'>|</span>}
                            <b>{key}:</b> {value}
                          </span>
                        ))}
                      </p>

                      <SelectRounded
                        classNameContainer='position-absolute w-auto mt-md-0 mt-4 pt-md-0 pt-1'
                        classNameWrapper='border-color-default w-auto bg-white'
                        className='text-left select-height'
                        styleContainer={{ top: 0, right: 0 }}
                        styleWrapper={{ borderRadius: '8px', padding: '0 10px' }}
                        style={{ width: '55px', paddingLeft: '5px', '--select-height': '37px' }}
                        name='year'
                        value={filters}
                        onChange={onChangeFilters}
                      >
                        {range(2020, now.year() + 1).map((year) => (
                          <option value={year} key={`year_${year}`}>
                            {year}
                          </option>
                        ))}
                      </SelectRounded>
                    </div>

                    <div
                      className='row w-100 m-0 border-color-default'
                      style={{ border: '1px solid', borderRadius: '8px' }}
                    >
                      {monthsGrouped.map((months, i) => (
                        <div key={`Months_list_${i}`} className='p-0 col-12 col-md-4 d-flex justify-content-around'>
                          {months.map((month) => (
                            <span
                              key={`Months_list_${i}_${month.number}`}
                              className={`table_mes ${filters.month === month.number ? 'active' : ''}`}
                              onClick={() => onChangeFilters('month', month.number)}
                            >
                              {t(month.nameKeyAbbreviated)}
                            </span>
                          ))}
                        </div>
                      ))}
                    </div>
                    <div className='row w-100 m-0'>
                      <div
                        className='w-100 d-flex border-color-default align-items-center text-center text-bold'
                        style={{ borderBottom: '1px solid' }}
                      >
                        <div className='w-50 text-left overflow-hidden'>Aluno</div>
                        <div
                          className='w-25 h-100 border-color-default overflow-hidden'
                          style={{ borderLeft: '1px solid', fontSize: '75%' }}
                        >
                          {t('FrequencyInClasses_abbreviated')}
                        </div>
                        <div
                          className='w-25 h-100 border-color-default overflow-hidden'
                          style={{ borderLeft: '1px solid', fontSize: '75%' }}
                        >
                          {t('UnjustifiedAbsences_abbreviated')}
                        </div>
                        <div
                          className='w-25 h-100 border-color-default overflow-hidden'
                          style={{ borderLeft: '1px solid', fontSize: '75%' }}
                        >
                          {t('JustifiedAbsences_abbreviated')}
                        </div>
                      </div>
                    </div>

                    {isLoadingStudentWithFrequencies && (
                      <p className='loading-card sidebar_data' style={{ height: '45px' }}>
                        <Loading type='1' />
                      </p>
                    )}

                    {!isLoadingStudentWithFrequencies &&
                      studentsFiltered.map((studentAux) => (
                        <div
                          key={`Frequency_Student_${studentAux.id}`}
                          className='row w-100 m-0 border-color-default overflow-hidden'
                          style={{ border: '1px solid', borderRadius: '8px' }}
                        >
                          <div className='w-100 d-flex align-items-center text-center'>
                            <div className='w-50 text-left overflow-hidden'>
                              <span className='pl-3 py-1 d-flex align-items-center'>
                                {(!student || student?.id !== studentAux.id) && (
                                  <FaPlus
                                    className='mr-1 clickable link-normal'
                                    onClick={() => setStudent(studentAux)}
                                  />
                                )}
                                {student?.id === studentAux.id && (
                                  <FaMinus className='mr-1 clickable link-normal' onClick={() => setStudent(null)} />
                                )}
                                {studentAux.name}
                              </span>
                            </div>
                            <div
                              className='w-25 h-100 border-color-default d-flex align-items-center justify-content-center overflow-hidden'
                              style={{ borderLeft: '1px solid' }}
                            >
                              {studentAux.frequency}
                            </div>
                            <div
                              className='w-25 h-100 text-bold border-color-default d-flex align-items-center justify-content-center flex-wrap overflow-hidden'
                              style={{ borderLeft: '1px solid' }}
                            >
                              {studentAux.pendingAttendances.length}
                              <ButtonRounded
                                type='button'
                                className='px-3 ml-1'
                                isDisabled={studentAux.pendingAttendances.length === 0}
                                isLoading={isLoading}
                                onClick={() => {
                                  setStudent(studentAux);
                                  viewPendingAttendancesRef.current.showModal();
                                }}
                              >
                                <FaEdit className='mr-0' style={{ width: '18px', height: '18px' }} />
                              </ButtonRounded>
                            </div>
                            <div
                              className='w-25 h-100 text-bold border-color-default d-flex align-items-center justify-content-center flex-wrap overflow-hidden'
                              style={{ borderLeft: '1px solid' }}
                            >
                              {studentAux.attendances.length}
                              <ButtonRounded
                                type='button'
                                className='px-3 ml-1'
                                isDisabled={studentAux.attendances.length === 0}
                                isLoading={isLoading}
                                onClick={() => {
                                  setStudent(studentAux);
                                  viewAttendancesRef.current.showModal();
                                }}
                              >
                                <FaEye className='mr-1' style={{ width: '18px', height: '18px' }} />
                              </ButtonRounded>
                            </div>
                          </div>

                          <Collapse in={student?.id === studentAux.id} className='w-100'>
                            <div
                              className='w-100 d-flex flex-row justify-content-around border-color-default custom-scrollbar custom-scrollbar-small-height'
                              style={{ borderTop: '1px solid', overflowX: 'auto' }}
                            >
                              {studentAux.frequencies.map((frequency) => (
                                <div
                                  key={`Student_Date_${studentAux.id}_${frequency.id}`}
                                  className='d-flex flex-column text-center'
                                  style={{ width: '60px', flex: 1 }}
                                >
                                  <div
                                    className='w-100 py-1 px-1 border-color-default '
                                    style={{ borderWidth: '0 1px 1px 0', borderStyle: 'solid' }}
                                  >
                                    {moment(frequency.date).format('DD/MM')}
                                  </div>
                                  <div className='w-100 py-1 border-color-default' style={{ borderRight: '1px solid' }}>
                                    {frequency.confirmed === 'S' ? (
                                      <span className='badge badge-success'>P</span>
                                    ) : frequency.justification_title !== null ? (
                                      <span className='badge badge-warning'>J</span>
                                    ) : (
                                      <span className='badge badge-danger'>F</span>
                                    )}
                                  </div>
                                </div>
                              ))}
                              <div className='d-flex flex-column text-center' style={{ width: '60px', flex: 1 }}>
                                <div
                                  className='w-100 py-1 px-1 border-color-default'
                                  style={{ borderBottom: '1px solid' }}
                                >
                                  {t('Lessons')}
                                </div>
                                <div className='w-100 py-1 px-1 border-color-default'>{studentAux.frequency}</div>
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </main>

            <ViewPendingAttendances
              ref={viewPendingAttendancesRef}
              isLoading={isLoadingFrequencies}
              isSending={isSubmitting}
              studentClass={{
                ...studentClass,
                hour: studentClassSelected?.hour,
                dias: studentClassSelected?.dias,
                responsaveis: studentClassSelected?.responsaveis,
                classDayId: studentClassSelected?.classDayId,
                dayId: weekDaySelected,
              }}
              student={student}
              weekDaySelected={weekDaySelected}
              onSubmit={onSubmitAbsenceJustification}
            />

            <ViewAttendances
              ref={viewAttendancesRef}
              isLoading={isLoadingFrequencies}
              isSending={isSubmitting}
              studentClass={{
                ...studentClass,
                hour: studentClassSelected?.hour,
                dias: studentClassSelected?.dias,
                responsaveis: studentClassSelected?.responsaveis,
                classDayId: studentClassSelected?.classDayId,
                dayId: weekDaySelected,
              }}
              student={student}
              weekDaySelected={weekDaySelected}
              onSubmit={onSubmitAbsenceJustification}
            />

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Class;
