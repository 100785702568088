import React from 'react';
import { StudentFormContext } from '../../../Context/StudentFormContext';
import { ContractFaturarContext } from '../../../Context/ContractFaturarContext';
import { CobrancaFaturarContext } from '../../../Context/CobrancaFaturarContext';
import ButtonRounded from '../../../components/buttons/ButtonRounded';
import moment from 'moment';
import TableResumeList from '../TableResumeList';
import { useTranslation } from 'react-i18next';
import Message from '../../../components/Message';

import '../../../utils/extendsType';
import api, { base_url } from '../../../services/api';
import { getURLContractPDF } from '../../../utils/utils';
import FullScreen from '../../../components/modal/FullScreen';
import Mensal from './Mensal';
import useForm from '../../../hooks/useForm';
import Matricula from './Matricula';
import SweetAlert from 'react-bootstrap-sweetalert';

const { MessageDangerMarginTop, MessageSuccessMarginTop } = Message();

const requestContracts = async (id_user) => {
  return await api.get(`student/contracts/${id_user}`);
};
const rowColor = (row) => {
  if (row.received === 'S') return 'green';
  if (moment(row.dueDate) < moment()) return 'tomato';
  if (row.faturado === 'S') return 'blue';
  return 'var(--main-color)';
};

export default function () {
  // Contexts states -------------------------------------------------------------------------------------------------------------
  const context = React.useContext(StudentFormContext);

  const contextFaturar = React.useContext(ContractFaturarContext);

  const contextFaturarCobranca = React.useContext(CobrancaFaturarContext);

  const id_school = JSON.parse(localStorage.getItem('school')).id;

  const authUser = JSON.parse(localStorage.getItem('user'));

  const { t, i18n } = useTranslation();

  const [sendSave, setSendSave] = React.useState(false);

  const [showModal, setShowModal] = React.useState(false);

  const [errorsServer, setErrorsServe] = React.useState([]);

  const [student, setStudent] = React.useState({ contracts: [], finacials: [] });

  const [open, setOpen] = React.useState(false);

  const [openm, setOpenm] = React.useState(false);

  const [loadTable, setLoadTable] = React.useState(false);

  const [success, setSuccess] = React.useState('');

  const formRefer = React.useRef(null);

  const [errors, setErrors] = React.useState([]);

  const onSubmitEditContract = (payload, event) => {
    const btn = event.target.loader();

    const formData = new FormData();
    console.log(payload);

    formData.append('id', payload.id);
    formData.append('status', payload.statusContrato);
    formData.append('dateStart', payload.dataInicio);
    formData.append('dateEnd', payload.dataFim);
    formData.append('observation', payload.observacao);
    formData.append('cargaHoraria', payload.cargaHora);
    formData.append('cyclo', payload.paymentCycle);
    formData.append('payment', payload.paymentMethod);
    formData.append('discount_type', payload.tipoDesconto === 'NONE' ? '' : payload.tipoDesconto);
    formData.append('discount', payload.desconto);
    formData.append('late_fee_type', payload.tipoMulta === 'NONE' ? '' : payload.tipoMulta);
    formData.append('late_fee', payload.valorMulta);
    formData.append('late_interest_type', payload.tipoJuros === 'NONE' ? '' : payload.tipoJuros);
    formData.append('late_interest', payload.valorJuros);
    formData.append('value', payload.valor);
    formData.append('originalValue', payload.valorOriginal);
    formData.append('insurance', payload.seguro);
    formData.append('service', payload.service);
    formData.append('class_changed', false);

    btn.start();

    context
      .requestValidate('update-contract-school', formData)
      .then((response) => {
        if (response.success === true) {
          console.log('response', response);
          handlerSuccessMessage(`StudentContractsPage.SystemMessage.${response.messageKey}`);
          setOpen(false);
          getStudent();
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorsServe([err.message.toString()]);
        setOpen(false);
      })
      .finally(() => {
        btn.reset();
      });
  };

  const onSubmitFormEditMatricula = (payload, event) => {
    const btn = event.target.loader();

    //faz a baixa no asass caso integrado
    if (
      payload.payment_status !== 'Aprovado' &&
      payload.receber === 'S' &&
      payload.faturado === 'S' &&
      payload.payment_id_asaas !== null &&
      payload.datePayment !== null &&
      payload.valorReceber !== null
    ) {
      handlerConfirmReceivedFatura(event, payload);
      return null;
    }

    const formData = new FormData();

    formData.append('id', payload.id);

    formData.append('school_id', id_school);

    formData.append('user_id_student', student.user_id);

    formData.append('type', 'Receita');

    formData.append('countDescription', payload.contaContabil);

    formData.append('mode', payload.paymentModo);

    formData.append('dueDate', payload.expirationDate || '');

    formData.append('value', payload.valorOriginal);

    formData.append('payment', payload.paymentMethod || '');

    formData.append('amount', payload.parcelas);

    formData.append('received', payload.receber);

    formData.append('datePayment', payload.paymentDate || '');

    formData.append('discount_type', payload.discount_type || null);

    formData.append('discount', payload.discount);

    formData.append('late_interest_type', payload.late_interest_type || null);

    formData.append('late_interest', payload.late_interest);

    formData.append('late_fee_type', payload.late_fee_type || null);

    formData.append('late_fee', payload.late_fee);

    formData.append('valueReceived', payload.valorReceber);

    formData.append('payment_status', payload.payment_status);

    btn.start();

    context
      .requestValidate('update-financial-school', formData)
      .then((response) => {
        if (response.messageKey === 'SystemMessage.Success.UpdateSuccess') {
          console.log('response', response);
          handlerSuccessMessage(response.messageKey);
          setOpenm(false);
          getStudent();
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorsServe([err.message.toString()]);
        setOpenm(false);
      })
      .finally(() => {
        btn.reset();
      });
  };

  const mensalForm = useForm(context.mensal, {}, onSubmitEditContract, formRefer, setErrors);

  const matriculaForm = useForm(context.matricula, {}, onSubmitFormEditMatricula, formRefer, setErrors);

  const handlerSuccessMessage = (message) => {
    setSuccess('');
    setSuccess(message);
    setTimeout(() => {
      setSuccess('');
    }, 6000);
  };
  // Private function -------------------------------------------------------------------------------------------------------------
  const handlerConfirmFaturar = async (e, row) => {
    //* passa as informações do contrato para o context de faturamento
    contextFaturar.setState({
      contract: row,
      student: student,
    });

    contextFaturar.setState({
      successCallback: () => {
        getStudent();
        handlerSuccessMessage('StudentContractsPage.SystemMessage.SubscribeApproved');
      },
    });
    //* abre o modal de confirmacao de Faturamento
    contextFaturar.setState({ done: true });
    contextFaturar.handlerConfirmFaturar(e);
  };

  const handlerConfirmFaturaAvulsa = async (e, row) => {
    const BILLING_TYPE = {
      'Boleto.Parcelado': 'BOLETO_INTEGRATED_AMOUNT',
      'Boleto.A vista': 'BOLETO_INTEGRATED',
      'Cartão.Parcelado': 'CREDIT_CARD_INTEGRATED_AMOUNT',
      'Cartão.A vista': 'CREDIT_CARD_INTEGRATED',
      'Dinheiro.A vista': 'MONEY',
    };

    row.billingType = BILLING_TYPE[`${row.payment}.${row.mode}`];

    //* passa as informações do contrato para o context de faturamento
    contextFaturarCobranca.setState({
      cobranca: row,
      student: student,
    });

    contextFaturarCobranca.setState({
      successCallback: () => {
        getStudent().then(() => {
          handlerSuccessMessage('StudentContractsPage.SystemMessage.SubscribeApproved');
        });
      },
    });
    //* abre o modal de confirmacao de Faturamento
    contextFaturarCobranca.setState({ done: true });
    contextFaturarCobranca.handlerConfirmFaturar(e);
  };

  const handlerConfirmRemoverFatura = async (e, row) => {
    //* passa as informações do contrato para o context de faturamento
    contextFaturar.setState({
      contract: row,
      student: student,
    });

    contextFaturar.setState({
      successCallback: () => {
        getStudent();
        handlerSuccessMessage('StudentContractsPage.SystemMessage.InvoiceCanceled');
      },
    });
    //* abre o modal de confirmacao de Cancelamento de Fatura
    contextFaturar.setState({ done: true });
    contextFaturar.handlerConfirmRemoverFatura(e);
  };

  const handlerConfirmReceivedFatura = async (e, row) => {
    //* passa as informações do contrato para o context de faturamento
    contextFaturarCobranca.setState({
      cobranca: row,
      student: student,
    });

    contextFaturarCobranca.setState({
      successCallback: () => {
        getStudent();
        handlerSuccessMessage('choolFinancePage.Modal.ReceiveInCash');
      },
    });
    //* abre o modal de confirmacao de Recebimento de Fatura
    contextFaturarCobranca.setState({ done: true });
    contextFaturarCobranca.handlerConfirmReceivedFatura(e);
  };

  const getStudent = async () => {
    setLoadTable(true);
    const res = await api.post('get-student-seletivas-frequency', {
      user_id: context.atleta.id.value,
    });

    const { data = { contracts: [] } } = await requestContracts(context.atleta.id.value);

    res.data.student.contracts = data.contracts;
    res.data.student.finacials = res.data.financials;

    setStudent(res.data.student);
    setLoadTable(false);
  };

  const openReceipt = (id) => {
    const queries = new URLSearchParams({ language: i18n.resolvedLanguage /* single: 1 */ });
    const url = `${base_url}financial/recibo/${id}/.pdf?${queries}`;
    window.open(url, '_blank');
  };

  React.useEffect(() => {
    if (context.atleta.id.value && context.atleta.id.value !== 0) getStudent();
  }, []);

  React.useEffect(() => {
    const elmain = document.querySelector('main');
    if (elmain && (errorsServer.length > 0 || success !== '')) {
      elmain.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }
  }, [errorsServer, success]);

  const setPayloadContract = (payload) => {
    mensalForm.setState({
      id: { value: payload.id, error: '' },
      dataVencimento: { value: payload.dateStart, error: '' },
      dataInicio: { value: payload.contractDateStart, error: '' },
      dataFim: { value: payload.dateEnd || '', error: '' },
      paymentMethod: { value: payload.payment, error: '' },
      paymentCycle: { value: payload.cyclo, error: '' },
      service: { value: payload.service.id, error: '' },
      seguro: { value: payload.insurance, error: '' },
      tipoDesconto: { value: payload.discount_type || 'NONE', error: '' },
      desconto: { value: payload.discount, error: '' },
      valorOriginal: { value: payload.originalValue, error: '' },
      valorComSeguro: { value: payload.value || '', error: '' },
      tipoJuros: { value: payload.late_interest_type || 'NONE', error: '' },
      valorJuros: { value: payload.late_interest, error: '' },
      tipoMulta: { value: payload.late_fee_type || 'NONE', error: '' },
      valorMulta: { value: payload.late_fee, error: '' },
      statusContrato: { value: payload.status, error: '' },
      cargaHora: { value: payload.cargaHoraria, error: '' },
      observacao: { value: payload.observation || '', error: '' },
      faturado: { value: payload.faturado, error: '' },
      novoContrato: { value: 'N', error: '' },
      anexo: { value: '', error: '' },
    });
  };

  const setPayloadInvoice = (payload) => {
    matriculaForm.setState({
      id: { value: payload.id, error: '' },
      paymentDate: { value: payload.datePayment, error: '' },
      expirationDate: { value: payload.dueDate, error: '' },
      paymentMethod: { value: payload.payment, error: '' },
      paymentModo: { value: payload.mode, error: '' },
      parcelas: { value: payload.amount, error: '' },
      valorOriginal: { value: payload.value, error: '' },
      contaContabil: { value: payload.countDescription, error: '' },
      observation: { value: payload.observation, error: '' },
      discount_type: { value: payload.discount_type, error: '' },
      discount: { value: payload.discount, error: '' },
      late_interest_type: { value: payload.late_interest_type, error: '' },
      late_interest: { value: payload.late_interest, error: '' },
      late_fee_type: { value: payload.late_fee_type, error: '' },
      late_fee: { value: payload.late_fee, error: '' },
      receber: { value: payload.received, error: '' },
      valorReceber: { value: payload.valueReceived, error: '' },
      payment_status: { value: payload.payment_status, error: '' },
      anexo: { value: '', error: '' },
      faturado: { value: payload.faturado, error: '' },
      contract: { value: payload.contract_id, error: '' },
      payment_id_asaas: { value: payload.payment_id_asaas, error: '' },
    });
  };

  //Action bar buttons ---------------------------------------------------------------
  const BarAction = ({ row }) => {
    return (
      <div className='d-flex justify-content-around align-itens-center'>
        <BtnMoney
          fcolor={row.faturado === 'S' ? 'green' : 'tomato'}
          title='Faturado'
          disabled={row.faturado === 'S'}
          onClick={(e) => handlerConfirmFaturar(e, row)}
        />
        <BtnEdit
          onClick={(e) => {
            e.preventDefault();
            setPayloadContract(row);
            setOpen(true);
            // edit(e, row);
          }}
        />
        {/*   <BtnZap
          disabled={row.status !== 'Ativo'}
          onClick={(e) => {
            e.preventDefault();
          }}
        /> */}
        <BtnPdf
          disabled={row.status !== 'Ativo'}
          onClick={(e) => {
            e.preventDefault();
            if (row.status === 'Ativo') window.open(getURLContractPDF(row, e));
          }}
        />
        <BtnCancelar
          disabled={row.faturado !== 'S'}
          onClick={(e) => {
            handlerConfirmRemoverFatura(e, row);
          }}
        />
      </div>
    );
  };

  const handlerFinalizeAdd = () => {
    const semfaturar = student.contracts.some((item) => item.faturado === 'N');

    if (semfaturar) {
      setShowModal(true);
      return null;
    }
    context.setState({ done: true });
  };
  // contants -------------------------------------------------------------------------------------------------------------
  const cycle_map = {
    MONTHLY: t('Monthly'),
    QUARTERLY: t('Quarterly'),
    SEMIANNUALLY: t('Semiannually'),
    YEARLY: t('Yearly'),
  };
  const payment_map = {
    CREDIT_CARD: t('Card'),
    BOLETO: t('Bill'),
    MONEY: t('Money'),
    /*     CASH: t('Cash'),
    DEBIT: t('Debit'), */
  };
  const columnsContract = [
    {
      id: 'service.nome',
      label: t('ContractedService'),
      minWidth: 120,
      align: 'left',
      format: (value, row) => row.service.nome,
    },
    {
      id: 'payment',
      label: t('Mode'),
      align: 'left',
      format: (value) => payment_map[value],
    },
    {
      id: 'cyclo',
      label: t('BillingCycle'),
      align: 'left',
      format: (value) => cycle_map[value],
    },
    {
      id: 'value',
      label: t('Value'),
      align: 'left',
      format: (value) => t('FormatFunction.intlCurrencyWithOptions', { value }),
    },
    {
      id: 'dateEnd',
      label: t('EndDate'),
      align: 'left',
      format: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
    },
    {
      id: 'status',
      label: t('Status'),
      align: 'center',
      format: (value) => <StatusRender value={value} />,
    },
    {
      id: 'id',
      label: t('Action'),
      align: 'center',
      minWidth: 110,
      format: (value, row) => authUser.usuStudent !== 'S' && <BarAction row={row} ativos={row.status === 'Ativo'} />,
    },
  ];
  const columns = [
    {
      id: 'type',
      label: t('Type'),
      minWidth: 120,
      align: 'left',
      format: (value, row) => <RecivedRender value={value} row={row} />,
    },
    {
      id: 'payment',
      label: t('Payment'),
      minWidth: 120,
      align: 'left',
      format: (value, row) => <RecivedRender value={value} row={row} />,
    },
    {
      id: 'mode',
      label: t('Mode'),
      align: 'left',
      format: (value, row) => <RecivedRender value={value} row={row} />,
    },
    {
      id: 'value',
      label: t('Value'),
      align: 'left',
      format: (value, row) => (
        <RecivedRender value={t('FormatFunction.intlCurrencyWithOptions', { value })} row={row} />
      ),
    },
    {
      id: 'valueReceived',
      label: t('Received_abbreviated'),
      align: 'left',
      format: (value, row) => (
        <RecivedRender value={t('FormatFunction.intlCurrencyWithOptions', { value })} row={row} />
      ),
    },
    {
      id: 'dueDate',
      label: t('DueDate_abbreviated'),
      align: 'left',
      format: (value, row) => <RecivedRender value={value ? moment(value).format('DD/MM/YYYY') : ''} row={row} />,
    },
    {
      id: 'id',
      label: t('Action'),
      align: 'center',
      format: (value, row) =>
        authUser.usuStudent !== 'S' && (
          <div className='d-flex justify-content-around align-itens-center'>
            {/* Exibi botao faturar caso integrado com asaas */}
            {contextFaturarCobranca.state.asassIntegration && (
              <BtnMoney
                fcolor={'tomato'}
                title='Faturado'
                disabled={row.mode === 'Assinatura' || row.faturado === 'S' || row.payment === 'Dinheiro'}
                onClick={(e) => handlerConfirmFaturaAvulsa(e, row)}
              />
            )}

            <BtnEdit
              disabled={row.faturado === 'S'}
              onClick={(e) => {
                e.preventDefault();
                setPayloadInvoice(row);
                setOpenm(true);
              }}
            />
            <BtnRecibo disabled={row.received !== 'S'} onClick={(f) => openReceipt(row.id)} />
            {/*  <BtnCancelar
              disabled={row.mode === 'Assinatura' || row.faturado === 'S' || row.payment !== 'Dinehiro'}
              onClick={(e) => {
                 handlerConfirmRemoverFatura(e, row);
              }}
            /> */}
          </div>
        ),
    },
  ];

  return (
    <main>
      <SweetAlert
        warning
        confirmBtnBsStyle='danger'
        onConfirm={() => {
          setShowModal(false);
        }}
        show={showModal}
        title={t('Attention_exclamation')}
        customButtons={
          <span className='w-100 d-flex justify-content-around align-items-center'>
            <ButtonRounded
              variant='outline'
              color='var(--main-color)'
              isBig={true}
              onClick={() => {
                setShowModal(false);
              }}
            >
              {t('Not')}
            </ButtonRounded>
            <ButtonRounded
              isBig={true}
              onClick={() => {
                context.setState({ done: true });
              }}
            >
              {t('Yes')}
            </ButtonRounded>
          </span>
        }
      >
        <h5>
          Existem contratos não faturados, deseja finalizar <b>sem faturar</b>?
        </h5>
        <p>
          Basta clicar em <BtnMoney fcolor={'tomato'} /> na 'Lista de Contratos' coluna 'Ação' para gerar a fatura!
        </p>
        <p>
          Ou se preferir, é possível faturar os contrato pendentes posteriormente em{' '}
          <a target='_blank' href='/contracts-student-school'>
            Contratos
          </a>{' '}
        </p>
      </SweetAlert>
      {errorsServer.map((item, k) => (
        <MessageDangerMarginTop title='Atenção!' description={item} key={`error_${k}`} />
      ))}
      {success !== '' && <MessageSuccessMarginTop title='Pronto!' description={t(success)} />}
      <h2 className='card_title p-2'>{t('SystemMenu.Contratos')}</h2>
      <div className='row'>
        <div className='col-md-12'>
          <TableResumeList loading={loadTable} mxh={350} rows={student.contracts} columns={columnsContract} />
        </div>
      </div>
      <h2 className='card_title p-2'>{t('SystemMenu.Financeiro')}</h2>
      <div className='row'>
        <div className='col-md-12'>
          <TableResumeList loading={loadTable} mxh={350} rows={student.finacials} columns={columns} />
        </div>
      </div>
      <div className='row align-items-center justify-content-center my-4'>
        <div className='col-md-5 col-sm-10'>
          <ButtonRounded isLoading={sendSave} onClick={handlerFinalizeAdd} className='w-100' isBig={true}>
            {t('Finalize')}
          </ButtonRounded>
        </div>
      </div>

      <FullScreen open={open} setOpen={setOpen} title='Editar Contrato' onSave={mensalForm.handleOnSubmit}>
        <form ref={formRefer} action='' className='form_card p-3' noValidate>
          <Mensal useForm={mensalForm} />
        </form>
      </FullScreen>
      <FullScreen open={openm} setOpen={setOpenm} title='Editar Receita' onSave={matriculaForm.handleOnSubmit}>
        <form ref={formRefer} action='' className='form_card p-3' noValidate>
          <Matricula useForm={matriculaForm} />
        </form>
      </FullScreen>
    </main>
  );
}
const RecivedRender = ({ value, row }) => <span style={{ color: rowColor(row) }}>{value}</span>;

const BtnEdit = ({ fcolor = 'var(--main-color)', w = 16, h = 16, onClick }) => {
  return (
    <span title='Editar' onClick={onClick} className='text-danger' style={{ cursor: 'pointer' }} role='button'>
      <svg width={w} height={h} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10L16 6V10H14V7H9V2H2V18H8V20H2ZM14.3 12.525L15.375 13.6L11.5 17.45V18.5H12.55L16.425 14.65L17.475 15.7L13.175 20H10V16.825L14.3 12.525ZM17.475 15.7L14.3 12.525L15.75 11.075C15.9333 10.8917 16.1667 10.8 16.45 10.8C16.7333 10.8 16.9667 10.8917 17.15 11.075L18.925 12.85C19.1083 13.0333 19.2 13.2667 19.2 13.55C19.2 13.8333 19.1083 14.0667 18.925 14.25L17.475 15.7Z'
          fill={fcolor}
        />
      </svg>
    </span>
  );
};
const BtnCancelar = ({ disabled = false, fcolor = 'tomato', w = 16, h = 16, onClick }) => {
  const color = disabled ? '#D9D9D9' : fcolor;
  return (
    <span
      title='Cancelar Fatura'
      onClick={onClick}
      className='text-danger'
      style={{ cursor: disabled ? 'auto' : 'pointer' }}
      role='button'
    >
      <svg width={w} height={h} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill={color}
          d='M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'
        ></path>
      </svg>
    </span>
  );
};
const BtnRecibo = ({ fcolor = 'var(--main-color)', w = 16, h = 16, onClick }) => {
  return (
    <span title='Gerar Recibo' onClick={onClick} className='text-danger' style={{ cursor: 'pointer' }} role='button'>
      <svg
        width={w}
        height={h}
        viewBox='0 0 18 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        data-testid='ReceiptLongIcon'
        aria-label='fontSize medium'
      >
        <path
          fill={fcolor}
          d='M19.5 3.5 18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2v14H3v3c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V2l-1.5 1.5zM19 19c0 .55-.45 1-1 1s-1-.45-1-1v-3H8V5h11v14z'
        ></path>
        <path fill={fcolor} d='M9 7h6v2H9zm7 0h2v2h-2zm-7 3h6v2H9zm7 0h2v2h-2z'></path>
      </svg>
    </span>
  );
};
const BtnPdf = ({ disabled = false, fcolor = '#B8872B', w = 16, h = 16, onClick }) => {
  const { t } = useTranslation();
  const color = disabled ? '#D9D9D9' : fcolor;
  return (
    <span
      onClick={onClick}
      className='text-danger'
      style={{ cursor: disabled ? 'auto' : 'pointer' }}
      role='button'
      title={t('StudentContractsPage.ActionsTableList.printerButton')}
    >
      <svg width={w} height={h} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7 10.5H8V8.5H9C9.28333 8.5 9.52083 8.40417 9.7125 8.2125C9.90417 8.02083 10 7.78333 10 7.5V6.5C10 6.21667 9.90417 5.97917 9.7125 5.7875C9.52083 5.59583 9.28333 5.5 9 5.5H7V10.5ZM8 7.5V6.5H9V7.5H8ZM11 10.5H13C13.2833 10.5 13.5208 10.4042 13.7125 10.2125C13.9042 10.0208 14 9.78333 14 9.5V6.5C14 6.21667 13.9042 5.97917 13.7125 5.7875C13.5208 5.59583 13.2833 5.5 13 5.5H11V10.5ZM12 9.5V6.5H13V9.5H12ZM15 10.5H16V8.5H17V7.5H16V6.5H17V5.5H15V10.5ZM6 16C5.45 16 4.97917 15.8042 4.5875 15.4125C4.19583 15.0208 4 14.55 4 14V2C4 1.45 4.19583 0.979167 4.5875 0.5875C4.97917 0.195833 5.45 0 6 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H6ZM6 14H18V2H6V14ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V4H2V18H16V20H2Z'
          fill={color}
        />
      </svg>
    </span>
  );
};
const BtnMoney = ({ disabled = false, fcolor = '#B8872B', w = 18, h = 18, onClick }) => {
  const color = disabled ? '#D9D9D9' : fcolor;
  return (
    <span
      onClick={(e) => {
        if (!disabled) onClick(e);
      }}
      className='text-danger'
      style={{ cursor: disabled ? 'auto' : 'pointer' }}
      role='button'
      title={disabled ? 'Já está Faturado!' : 'Faturar?'}
    >
      <svg
        width={w}
        height={h}
        viewBox='0 0 20 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        focusable='false'
        aria-hidden='true'
        data-testid='AttachMoneyIcon'
        aria-label='fontSize medium'
      >
        <path
          fill={color}
          d='M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z'
        ></path>
      </svg>
    </span>
  );
};
const StatusRender = ({ value }) => <span style={{ color: value === 'Ativo' ? 'green' : 'tomato' }}>{value}</span>;
