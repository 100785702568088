import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import '../../../utils/extendsType';

import useForm from '../../../hooks/useForm';
import Message from '../../../components/Message';
import ButtonRounded from '../../../components/buttons/ButtonRounded';
import Matricula from '../../Students/Forms/Matricula';

import StudentFormContext from '../../../Context/StudentFormContext';
import SweetAlert from 'react-bootstrap-sweetalert';
import { standardizedInvoicePlan } from '../../../services/ContractStudentService';
//component
const InvoiceRevenueForm = () => {
  //hooks states --------------------------------------------------------------------------------------
  const history = useHistory();

  const location = useLocation();

  const { t } = useTranslation();

  const formRefer = React.useRef();

  const alertRef = React.useRef();

  const [errors, setErrors] = React.useState([]);

  const [errorServer, setErrorsServe] = React.useState([]);

  const [school, setSchool] = React.useState({});

  const { MessageDangerMarginTop } = Message();

  const [isDone, setIsDone] = React.useState(false);

  const onSubmitFormMensal = (p, e, s, formData) => {
    if (e.target.dataset?.isdefault) {
      formData.set('default', 1);
    }

    formData.set('school_id', school.id);

    const btn = e.target.loader();

    btn.start();

    standardizedInvoicePlan(formData)
      .then((data) => {
        setIsDone(true);
      })
      .catch(handlerErrorServer)
      .finally(() => {
        btn.reset();
      });
  };

  const handlerErrorServer = (err) => {
    if (err.response?.status === 422) {
      const { message = [] } = err.response.data;
      Object.keys(message).map((k) => {
        const element = document.querySelector(`[name=${k}]`);
        if (element) {
          matriculaForm.dispatchHtmlValidity(element, `[${k}] ${message[k]}`);
        }
      });
    } else {
      setErrorsServe([err.toString()]);
    }
  };

  const matriculaForm = useForm(
    stateMatriculaSchema,
    validationStateSchemaMatricula,
    onSubmitFormMensal,
    formRefer,
    setErrors
  );

  //constructor --------------------------------------------------------------------------------------
  React.useEffect(() => {
    const schoolSession = JSON.parse(localStorage.getItem('school'));

    const contract = location.state?.contract;

    console.log('contract', contract);
    if (contract) {
      matriculaForm.setState({
        id: { value: contract.id, error: '' },
        name: { value: contract.name, error: '' },
        expirationDate: { value: contract.due_date, error: '' },
        paymentDate: { value: '', error: '' },
        paymentMethod: { value: contract.payment, error: '' },
        paymentModo: { value: contract.mode, error: '' },
        parcelas: { value: contract.amount, error: '' },
        valorOriginal: { value: contract.value, error: '' },
        discount_type: { value: contract.discount_type == null ? 'NONE' : contract.discount_type, error: '' },
        discount: { value: contract.discount, error: '' },
        late_interest_type: { value: contract.late_interest_type == null ? 'NONE' : contract.late_interest_type, error: '' },
        late_interest: { value: contract.late_interest, error: '' },
        late_fee_type: { value: contract.late_fee_type == null ? 'NONE' : contract.late_fee_type, error: '' },
        late_fee: { value: contract.late_fee, error: '' },
        observation: { value: contract.observation, error: '' },
        billingType: { value: contract.type, error: '' },
        contaContabil: { value: contract.account_description, error: '' },
        faturado: { value: 'N', error: '' },
        receber: { value: 'S', error: '' },
        valorReceber: { value: 0, error: '' },
        remove_recevid: { value: true, error: '' },
      });
    }

    setSchool(schoolSession);
  }, []);

  React.useEffect(() => {
    const elmain = document.querySelector('main');

    if ((errors.length || errorServer.length) && elmain) {
      elmain.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }
  }, [errors, errorServer]);

  return (
    <StudentFormContext>
      <SweetAlert
        ref={alertRef}
        show={isDone}
        success
        onConfirm={()=>{}}
        title='Pronto!'
        customButtons={
          <ButtonRounded isBig={true} onClick={() => history.push('/standardized-plans')} className='w-100 m-4'>
            Fechar
          </ButtonRounded>
        }
      >
        {matriculaForm.state.id.value ? (
          <p> Plano de cobrança avulsa atualizado com sucesso!</p>
        ) : (
          <p>Plano de cobrança avulsa criado com sucesso!</p>
        )}
      </SweetAlert>
      <div className='card p-0 flex-column'>
        <div className='card_header w-100 flex-column align-items-center pt-3'>
          <h2 className='card_title'>Padronizar cobrança avulsa</h2>
          <BtnBack
            onClick={(e) => {
              history.push('/standardized-plans');
            }}
            style={{
              float: 'right',
              position: 'absolute',
              left: 35,
              marginTop: 5,
            }}
          />
        </div>
        <hr />
        {errors.map((err, k) => (
          <MessageDangerMarginTop
            key={`err-${k}`}
            title='Atenção!'
            description={t(`${err.error.replace(/\[.*?\]/g, '').trim()}`)}
          />
        ))}
        {errorServer.map((item, k) => (
          <MessageDangerMarginTop title='Atenção!' description={item} key={`error_${k}`} />
        ))}

        <form ref={formRefer} action='' className='form_card mt-0' noValidate>
          <div className='row p-3'>
            <div className='col col-sm-12 form_group'>
              <label className='card_details_label'>Nome do Plano *</label>
              <input
                type='text'
                required
                name='name'
                className='form_control form-control'
                value={matriculaForm.state.name.value}
                onChange={matriculaForm.handleOnChange}
              />
            </div>
            <Matricula useForm={matriculaForm} />
            <div className='col col-sm-12 form_group'>
              <label className='card_details_label'>Observação *</label>
              <textarea
                name='observation'
                className='form_control form-control'
                value={matriculaForm.state.observation.value}
                onChange={matriculaForm.handleOnChange}
              />
            </div>
          </div>
          <div className='row align-items-center justify-content-center my-5'>
            <div className='col-md-5 col-sm-10'>
              <ButtonRounded
                className='w-100'
                variant='outline'
                color='var(--main-color)'
                isBig={true}
                data-isdefault={1}
                isDisabled={matriculaForm.disable && !matriculaForm.state.id.value}
                onClick={matriculaForm.handleOnSubmit}
              >
                Definir como padrão
              </ButtonRounded>
            </div>
            <div className='col-md-5 col-sm-10'>
              <ButtonRounded
                isDisabled={matriculaForm.disable}
                onClick={matriculaForm.handleOnSubmit}
                className='w-100'
                isBig={true}
              >
                Criar plano
              </ButtonRounded>
            </div>
          </div>
        </form>
      </div>
    </StudentFormContext>
  );
};

//form states ----------------------------------------------------------------------------------------------
const stateMatriculaSchema = {
  id: { value: 0, error: '' },
  name: { value: '', error: '' },
  receber: { value: 'N', error: '' },
  paymentDate: { value: null, error: '' },
  expirationDate: { value: null, error: '' },
  paymentMethod: { value: '', error: '' },
  paymentModo: { value: 'A vista', error: '' },
  parcelas: { value: 1, error: '' },
  valorOriginal: { value: 0, error: '' },
  contaContabil: { value: '', error: '' },
  observation: { value: '', error: '' },
  discount_type: { value: 'NONE', error: '' },
  discount: { value: '', error: '' },
  late_interest_type: { value: 'NONE', error: '' },
  late_interest: { value: '', error: '' },
  late_fee_type: { value: 'NONE', error: '' },
  late_fee: { value: '', error: '' },
  billingType: { value: 'MONEY', error: '' },
  faturado: { value: 'N', error: '' },
  valorReceber: { value: 0, error: '' },
  remove_recevid: { value: true, error: '' },
};
const validationStateSchemaMatricula = {};

const stateSchema = {
  students: { value: [], error: '' },
};

const validationStateSchema = {};

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: '#f4f0f0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
  },
});

const BtnBack = ({ fcolor = '#1C1B1F', w = 16, h = 16, onClick, style }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer', ...style }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z' fill={fcolor} />
    </svg>
  </span>
);

export default InvoiceRevenueForm;
