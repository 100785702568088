import React from 'react';
import './header.css';

export const Header = ({ setCurrentRoute }) => {
  return (
    <div className='chat-header'>
      <div className='chat-header__left'>
        <button className='chat-header__left__back' onClick={() => setCurrentRoute('/chat-list')}>
          <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <mask id='mask0_20_1179' maskUnits='userSpaceOnUse' x='0' y='0' width='32' height='32'>
              <rect width='32' height='32' fill='#D9D9D9' />
            </mask>
            <g mask='url(#mask0_20_1179)'>
              <path
                d='M18.6666 24L10.6666 16L18.6666 8L20.5333 9.86667L14.4 16L20.5333 22.1333L18.6666 24Z'
                fill='#1C1B1F'
              />
            </g>
          </svg>
        </button>
        <div className='chat-new'>
          <span className='new-conversation'>Nova conversa</span>
          <span>Escolha com quem deseja iniciar uma nova conversa.</span>
        </div>
      </div>
      <button className='chat-header__right' onClick={() => setCurrentRoute('/')}>
        <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <mask id='mask0_20_1179' maskUnits='userSpaceOnUse' x='0' y='0' width='32' height='32'>
            <rect width='32' height='32' fill='#D9D9D9' />
          </mask>
          <g mask='url(#mask0_20_1179)'>
            <path
              d='M18.6666 24L10.6666 16L18.6666 8L20.5333 9.86667L14.4 16L20.5333 22.1333L18.6666 24Z'
              fill='#1C1B1F'
            />
          </g>
        </svg>
      </button>
    </div>
  );
};
