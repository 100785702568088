export const firebaseConfig = {
  apiKey: 'AIzaSyAwm7q4uKAZvAGBfxxZ0HOJHNQFpjmmNkk',
  authDomain: 'stl-fensor-chat.firebaseapp.com',
  databaseURL: 'https://stl-fensor-chat-default-rtdb.firebaseio.com',
  projectId: 'stl-fensor-chat',
  storageBucket: 'stl-fensor-chat.appspot.com',
  messagingSenderId: '1033187313897',
  appId: '1:1033187313897:web:da9ac38a05920cf1080878',
  measurementId: 'G-JEJ0P104LZ',
};
