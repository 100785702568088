import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';

const TableList = ({ columns, rows, refer, mxh = 300 }) => {
  return (
    <TableContainer style={{ maxHeight: mxh, minHeight: mxh }}>
      <TableSpacing ref={refer} stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            {columns.map((column, k) => (
              <StyledTableCell key={`h${k}-${column.id}`} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, k) => {
            return (
              <StyledTableRow hover tabIndex={-1} key={`r${k}-${row.id}`}>
                {columns.map((column, kk) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={`cc${kk}`} align={column.align}>
                      {typeof column.format === 'function' ? column.format(value, row) : value}
                    </TableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </TableSpacing>
    </TableContainer>
  );
};
//material ui custom styles --------------------------------------------------------------------------------
const TableSpacing = withStyles((theme) => ({
  root: {
    borderSpacing: `0 ${theme.spacing(2)}px`,
    borderCollapse: 'separate',
  },
}))(Table);
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'white',
    color: theme.palette.common.black,
    border: 'none',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '& td:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      borderLeft: `1px solid var(--main-color)`,
    },
    '& td:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      borderRight: `1px solid var(--main-color)`,
    },
    '& td': {
      borderBottom: `1px solid var(--main-color)`,
      borderTop: '1px solid var(--main-color)',
      marginBottom: theme.spacing(2),
    },
  },
}))(TableRow);

export default TableList;
