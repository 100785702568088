import React, { useEffect, useState, useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import HeaderSchool from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Badge from 'react-bootstrap/Badge';
import { useTranslation } from 'react-i18next';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import { FaTimes } from 'react-icons/fa';
import { ModalWithMethods } from '../../components/modal/Modal';
import { UserContext } from '../../Context/UserContext';
import { getOrdersSellBySchool } from '../../services/OrderService';
import SweetAlert from 'react-bootstrap-sweetalert';
import { cancelOrder } from '../../services/PaymentService';

const MyOrderClub = () => {
  const { t } = useTranslation();
 // const { Cart } = useSelector((state) => state);
  const modalOrderRef = useRef(null);
  const { school } = useContext(UserContext);

  // filtros
  const [filterNumero, setFilterNumero] = useState('');
  const [filterTitle, setFilterTitle] = useState('');
  const [filterDate, setFilterDate] = useState('');

  // carregamento
  const [orders, setOrders] = useState([]);
  const [ordersFilter, setOrdersFilter] = useState([]);
  const [detailOrder, setDetailOrder] = useState({});
  const [URLImage, setURLImage] = useState('');

  // process
  const [process, setProcess] = useState(false);
  const [sweetAlertCancelOrder, setSweetAlertCancelOrder] = useState(undefined);

  useEffect(() => {
    getOrders(school.id);

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeDetail();
    }
  };

  const getOrders = async (id) => {
    setProcess(true);
    const { orders, urlImage } = await getOrdersSellBySchool({ id });

    setOrders(orders);
    setOrdersFilter(orders);

    setURLImage(urlImage);
    setProcess(false);
  };

  const search = (e) => {
    e.preventDefault();

    let registers = [];
    if (filterTitle !== '') {
      orders.map((item) => {
        item.orders_items.filter((ordItem) => {
          let title = ordItem.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          if (title.toLowerCase().indexOf(filterTitle.toLowerCase()) !== -1) registers.push(item);
        });
      });
    } else {
      registers = orders;
    }

    if (filterNumero !== '') {
      registers = registers.filter((item) => {
        return String(item.number).indexOf(filterNumero) !== -1;
      });
    }

    if (filterDate !== '') {
      registers = registers.filter((item) => {
        return item.created_at.split(' ')[0] === filterDate;
      });
    }

    setOrdersFilter(registers);
  };

  const closeDetail = () => {
    setDetailOrder({});
    modalOrderRef.current.closeModal();
  };

  const openDetail = (order) => {
    setDetailOrder(order);

    modalOrderRef.current.clearMessage();
    modalOrderRef.current.showModal();
  };

  const confirmCancelOrder = (detailOrder) => {
    setSweetAlertCancelOrder(
      <SweetAlert
        danger
        showCloseButton
        showCancel
        title={t('CancelOrder') + '?'}
        cancelBtnText={t('Back')}
        confirmBtnBsStyle='danger'
        confirmBtnText={t('CancelOrder')}
        onCancel={() => setSweetAlertCancelOrder(undefined)}
        onConfirm={() => {
          setSweetAlertCancelOrder(undefined);
          handleCancelOrder(detailOrder);
        }}
      >
        Tem certeza que deseja <b>cancelar</b> esse pedido?
        {detailOrder.payment_status === 'Aprovado' && (
          <small className='d-block text-muted'>
            Obs: O estorno deverá ser feito <b>manualmente</b>.
          </small>
        )}
      </SweetAlert>
    );
  };

  const handleCancelOrder = async (order) => {
    setProcess(true);
    const { status, messageKey } = await cancelOrder({ orderId: order.id });
    setProcess(false);

    if (status === 'SUCCESS') {
      modalOrderRef.current.showSuccessMessageScrolling(messageKey);

      setDetailOrder((current) => ({
        ...current,
        payment_status: 'Cancelado',
        financial: { ...current.financial, payment_status: 'Cancelado' },
      }));

      getOrders(school.id);
    } else {
      modalOrderRef.current.showErrorMessageScrolling(messageKey);
    }

    setTimeout(() => modalOrderRef.current.clearMessage(), 7000);
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('MyRequestsPage.FilterCard.Title')}</h2>
                    <p className='card_subtitle'>{t('MyRequestsPage.FilterCard.Subtitle')}</p>
                  </div>

                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('RequestNumber')}
                      </label>
                      <input
                        type='text'
                        placeholder={t('EnterRequestNumber')}
                        className='form_control'
                        onChange={(e) => setFilterNumero(e.target.value)}
                      />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('ProductName')}
                      </label>
                      <input
                        type='text'
                        placeholder={t('EnterProductName')}
                        className='form_control'
                        onChange={(e) => setFilterTitle(e.target.value)}
                      />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('PurchaseDate')}
                      </label>
                      <input type='date' className='form_control' onChange={(e) => setFilterDate(e.target.value)} />
                    </div>

                    <button className='btn_card mx-auto' onClick={search}>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('Search')}
                    </button>
                  </form>
                </div>
                <div className='card'>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>{t('TotalPurchases')}</h2>
                    <div className='sidebar_data'>{orders.length}</div>
                  </div>
                </div>
              {/*   <div className='card'>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>{t('ProductsInCart')}</h2>
                    <div className='sidebar_data'>{Cart.length}</div>
                  </div>
                </div> */}
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>
                      {t('MyRequestsPage.PageTitle')}
                      <Badge
                        pill
                        variant='success'
                        style={{ marginLeft: 3, background: 'orange', fontWeight: '500' }}
                        className='badge-font-size-50'
                      >
                        {ordersFilter.length}
                      </Badge>
                    </h2>
                    <p className='card_subtitle'>{t('MyRequestsPage.PageSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    {process && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}

                    {!process && ordersFilter.length === 0 && (
                      <p className='loading-card'>Não existem  pedidos de comrpas</p>
                    )}
                    {!process &&
                      ordersFilter.map((item, i) => {
                        return (
                          <div className='card_list' key={`prod_${i}`} style={{ padding: 0, margin: 5 }}>
                            <div className='content_card' style={{ margin: '10px 10px' }}>
                              <div
                                className='card_details'
                                style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}
                              >
                                <p
                                  className='card_details_title'
                                  style={{ color: '#000', fontWeight: 'bold', fontSize: 14 }}
                                >
                                  {t('RequestNumberValue', { number: item.number })}
                                </p>
                                <p className='card_details_title' style={{ color: '#aaa' }}>
                                  {`${item.created_at
                                    .split(' ')[0]
                                    .split('-')
                                    .reverse()
                                    .join('/')} ${item.created_at.substr(-8)}`}
                                </p>
                              </div>
                              <div
                                className='card_details'
                                style={{ justifyContent: 'left', alignItems: 'flex-start' }}
                              >
                                <p className='card_details_label'>{t('Value')}: </p>
                                <p className='card_details_text'>
                                  {t('FormatFunction.intlCurrencyWithOptions', { value: item.total })}
                                </p>
                              </div>
                              <div
                                className='card_details'
                                style={{ justifyContent: 'left', alignItems: 'flex-start' }}
                              >
                                <p className='card_details_label'>{t('Payment')}: </p>
                                <p
                                  className='card_details_text'
                                  style={{
                                    color:
                                      item.payment_status === 'Pendente'
                                        ? 'red'
                                        : item.payment_status === 'Cancelado'
                                        ? 'gray'
                                        : '#00a650',
                                  }}
                                >
                                  {t('RequestModel.PaymentStatus.' + item.payment_status)}
                                </p>
                              </div>
                              <div
                                className='card_details'
                                style={{ justifyContent: 'left', alignItems: 'flex-start' }}
                              >
                                <p className='card_details_label'>{t('Status')}: </p>
                                <p className='card_details_text'>{t('RequestModel.Status.' + item.delivery_status)}</p>
                              </div>
                            </div>
                            {['BOLETO', 'CREDIT_CARD'].includes(item?.financial?.payment_type) && (
                              <span
                                style={{
                                  fontWeight: 400,
                                  marginTop: 5,
                                  color: 'white',
                                }}
                                onClick={() =>
                                  !!item?.financial?.payment_bankSlipUrl &&
                                  window.open(item?.financial?.payment_bankSlipUrl, '_blank', 'noopener')
                                }
                                className='btn_card background-color-default border-color-default'
                              >
                                {t('PayNow')}
                              </span>
                            )}
                            <span
                              style={{
                                backgroundColor: '#f4f4f4',
                                fontWeight: 400,
                                border: '1px solid #ddd',
                                marginBottom: 20,
                                marginTop: 5,
                                color: 'grey',
                              }}
                              onClick={() => openDetail(item)}
                              className='btn_card'
                            >
                              {t('SeeDetails')}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <ModalWithMethods ref={modalOrderRef} modalContentStyle={{ maxWidth: '920px' }} size={null}>
                {detailOrder?.orders_items?.map((item) => {
                  return (
                    <div
                      key={`Order_Product_Item_${item.id}`}
                      className='row'
                      style={{
                        margin: '10px 0px',
                        backgroundColor: 'rgb(244, 244, 244)',
                        padding: '8px',
                        borderRadius: '8px',
                      }}
                    >
                      <div className='col-30'>
                        <img
                          src={URLImage + '/' + item.foto_capa}
                          style={{ width: 200, height: 200, objectFit: 'cover', borderRadius: '8px' }}
                        />
                      </div>
                      <div className='col'>
                        <p style={{ color: '#0c2120', fontSize: 17 }}>
                          <b>{item.title}</b>
                        </p>
                        <p style={{ marginBottom: 5, fontSize: 12 }}>
                          <b>{t('Size')}:</b> {item.lenght}
                        </p>
                        <p style={{ marginBottom: 5, fontSize: 12 }}>
                          <b>{t('Quantity')}:</b> {item.qtd}
                        </p>
                        <p style={{ marginBottom: 5, fontSize: 12 }}>
                          <b>{t('Value')}: </b>
                          {item.preco}
                        </p>
                        <p style={{ marginBottom: 5, fontSize: 12 }}>
                          <b>{t('Description')}:</b> {item.description}
                        </p>
                      </div>
                    </div>
                  );
                })}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    background: '#f4f4f4',
                    paddingTop: 10,
                    alignItems: 'center',
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                  }}
                >
                  <p style={{ color: '#0c2120', fontSize: 13, fontWeight: '600' }}>{t('PaymentMethod')}:</p>
                  <p style={{ color: '#0c2120', fontSize: 13, marginLeft: 10 }}>
                    {t('RequestModel.PaymentMethod.' + detailOrder.payment_type)}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    background: '#f4f4f4',
                    paddingTop: 10,
                    alignItems: 'center',
                  }}
                >
                  <p style={{ color: '#0c2120', fontSize: 13, fontWeight: '600' }}>{t('Payment')}:</p>
                  <p
                    style={{
                      color:
                        detailOrder.payment_status === 'Pendente'
                          ? 'red'
                          : detailOrder.payment_status === 'Cancelado'
                          ? 'gray'
                          : '#00a650',
                      fontSize: 13,
                      marginLeft: 10,
                    }}
                  >
                    {t('RequestModel.PaymentStatus.' + detailOrder.payment_status)}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    background: '#f4f4f4',
                    paddingTop: 10,
                    paddingBottom: 10,
                    alignItems: 'center',
                  }}
                >
                  <p style={{ color: '#0c2120', fontSize: 13, fontWeight: '600' }}>{t('Status')}:</p>
                  <p style={{ color: '#0c2120', fontSize: 13, marginLeft: 10 }}>
                    {t('RequestModel.Status.' + detailOrder.delivery_status)}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    background: '#ddd',
                    padding: '10px 0px',
                    alignItems: 'center',
                    borderBottomLeftRadius: 6,
                    borderBottomRightRadius: 6,
                  }}
                >
                  <p style={{ color: '#0c2120', fontSize: 17 }}>
                    <b>{t('TotalOrder')}:</b>
                  </p>
                  <p style={{ color: '#0c2120', fontSize: 17, marginLeft: 10 }}>
                    <b> {t('FormatFunction.intlCurrencyWithOptions', { value: detailOrder.total })}</b>
                  </p>
                </div>

                <div className='mt-4 d-flex justify-content-center' style={{ gap: '8px' }}>
                  {detailOrder.payment_status === 'Pendente' && (
                    <ButtonRounded
                      isBig
                      variant='red'
                      isLoading={process}
                      onClick={() => confirmCancelOrder(detailOrder)}
                    >
                      <FaTimes /> {t('CancelOrder')}
                    </ButtonRounded>
                  )}
                </div>
              </ModalWithMethods>

              {sweetAlertCancelOrder}
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default MyOrderClub;
