import React from 'react';
import Table from 'react-bootstrap/Table';
import { BsClipboardCheck, BsPencil, BsTrash } from 'react-icons/bs';
import { RiFileUploadLine } from 'react-icons/ri';
import Utils from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const PurchaseOrderTable = ({ purchaseOrder, onChangeStatus, onClickFile, onClickOpenDetails, status }) => {
    const { t } = useTranslation();
    const { dateFormat, currencyFormatterText } = Utils();

    const defaultColHeaderStyle = {
        border: 'none',
        fontSize: '12px',
        width: '200px',
        color: '#3b3b3b',
        fontWeight: 600,
        justifyContent: 'left'
    };

    const defaultColRowStyle = {
        alignItems: 'center',
        width: '200px',
        height: '100%',
        border: 'none',
        justifyContent: 'left'
    };

    return (
        <Table responsive style={{ boxShadow: 'none', marginTop: '20px', minWidth: '625px' }}>
            <thead>
                <tr style={{ marginBottom: '5px' }}>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Data
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Numero da ordem
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Fornecedor
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Status
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Valor do pedido
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Data de entrega
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Upload
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Detalhes
                    </th>
                </tr>
            </thead>
            <tbody>
                {purchaseOrder && purchaseOrder.map((item, i) => (
                    <tr key={'ProducsTable_tbody_row' + i} style={{
                        marginBottom: '12px',
                        borderRadius: '10px',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        height: '62px',
                        borderColor: 'var(--main-color)'
                    }}>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {dateFormat(item.created_at)}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.order_number}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.supplier.name.length > 15 ? item.supplier.name.substr(0, 20) + '...' : item.supplier.name}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            <select
                                onChange={(e) => onChangeStatus(e.target.value, item.id)} value={item.status}
                                className='form-control select'
                                style={{ width: '110px', height: '10px !important', fontSize: '12px' }}
                            >
                                <option value='Aberta'>{'Aberta'}</option>
                                <option value='Recebida'>{'Recebida'}</option>
                                <option value='Conferida'>{'Conferida'}</option>
                                <option value='Cancelada'>{'Cancelada '}</option>
                            </select>
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {currencyFormatterText(item.value_order)}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {dateFormat(item.delivery_date)}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            <div className='d-flex' style={{ gap: '10px', cursor: 'pointer' }}>
                                <RiFileUploadLine onClick={() => onClickFile(item)} color='#' size={15} />
                            </div>
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            <BsClipboardCheck onClick={() => onClickOpenDetails(item)} style={{ gap: '10px', cursor: 'pointer' }} color='#00A4EA' size={15} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default PurchaseOrderTable;