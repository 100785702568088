import { Collapse } from '@material-ui/core';
import moment from 'moment';
import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight, FaCheck, FaExclamationTriangle, FaLevelUpAlt, FaTimes } from 'react-icons/fa';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import { ModalWithMethods } from '../../components/modal/Modal';
import { cancelMoneySubscribe, cancelSubscribe, getAsaasConfigSessionSchool } from '../../services/PaymentService';
import {
  getStudentInactivate,
  removeStudentFromAllClasses,
  removeStudentFromClass,
} from '../../services/StudentService';
import { PaymentErrors } from '../../utils/PaymentErrors';
import Utils from '../../utils/utils';

const ModalInactivateStudent = ({ studentId, ...props }, ref) => {
  const { t } = useTranslation();

  const { dateFormat } = Utils();
  const { MessageDangerMarginTop, MessageWarningMarginTop } = Message();

  const cancelContractFailMessage = 'InactivateStudentModal.CancelContractFailMessage';

  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);

  const [classes, setClasses] = useState([]);
  const [contracts, setContracts] = useState([]);

  const systemProcessIndicators = {
    STAND_BY: { icon: <FaTimes />, text: 'text-danger' },
    LOADING: { icon: <Loading type={1} />, text: 'text-danger' },
    SUCCESS: { icon: <FaCheck />, text: 'text-success' },
    FAIL: { icon: <FaExclamationTriangle />, text: 'text-warning' },
  };

  useEffect(() => {
    if (studentId && ref.current?.getStates().show) {
      fetchData(studentId);
      clearMessage();
    }
  }, [studentId, ref.current?.getStates().show]);

  const fetchData = async (studentId) => {
    setClasses([]);
    setContracts([]);

    setIsLoading(true);
    const { status, messageKey, classes, contracts } = await getStudentInactivate(studentId);
    setIsLoading(false);

    if (status === 'SUCCESS') {
      setClasses(classes.map((c) => ({ ...c, systemProcess: 'STAND_BY', systemMessage: '' })));
      setContracts(
        contracts.map((c) => ({
          ...c,
          isShowFinancial: false,
          systemProcess: 'STAND_BY',
          systemMessage: '',
        }))
      );
    } else {
      showErrorMessage(messageKey || message);
    }
  };

  const clearMessage = () => {
    setMessage('');
    setError(false);
  };

  const showErrorMessage = (message) => {
    setMessage(message);
    setError(true);
    setWarning(false);
  };

  const showWarningMessage = (message) => {
    setMessage(message);
    setError(false);
    setWarning(true);
  };

  const toggleCollapse = (index) => {
    setContracts((current) => {
      const aux = [...current];
      aux[index].isShowFinancial = !aux[index].isShowFinancial;
      return aux;
    });
  };

  const toggleClassSystemProcess = (state, index = null, message = '') => {
    setClasses((current) => {
      if (index !== null) {
        const aux = [...current];
        aux[index].systemProcess = state;
        aux[index].systemMessage = message;
        return aux;
      } else {
        return current.map((c) => ({ ...c, systemProcess: state, systemMessage: message }));
      }
    });
  };

  const toggleContractSystemProcess = (state, index = null, message = '') => {
    setContracts((current) => {
      if (index !== null) {
        const aux = [...current];
        aux[index].systemProcess = state;
        aux[index].systemMessage = message;
        return aux;
      } else {
        return current.map((c) => ({ ...c, systemProcess: state, systemMessage: message }));
      }
    });
  };

  const removeFromAllClasses = async () => {
    toggleClassSystemProcess('LOADING');
    const { status, message } = await removeStudentFromAllClasses(studentId);

    if (status === 'SUCCESS') {
      toggleClassSystemProcess('SUCCESS');
    } else {
      toggleClassSystemProcess('FAIL');
      showErrorMessage(message);
    }
  };

  const removeFromClass = async (classId, index) => {
    toggleClassSystemProcess('LOADING', index);
    const { status, message } = await removeStudentFromClass({ studentId, classId });

    if (status === 'SUCCESS') {
      toggleClassSystemProcess('SUCCESS', index);
    } else {
      toggleClassSystemProcess('FAIL', index);
      showErrorMessage(message);
    }
  };

  const closeContractIntegrated = async (contract, index) => {
    const schoolSession = JSON.parse(await localStorage.getItem('school'));
    const companySession = JSON.parse(await localStorage.getItem('company'));
    const { token_assas } = getAsaasConfigSessionSchool(schoolSession, companySession);

    const response = await cancelSubscribe({
      idAsaas: contract.financial[contract.financial.length - 1].payment_subscription,
      token_assas,
      contract_id: contract.id,
      contract_type: 'SCHOOL',
      mustCancelContract: true,
    });

    if (response.status === 'SUCCESS' || response.status === 'WARNING') {
      if (response.status === 'SUCCESS') {
        toggleContractSystemProcess('SUCCESS', index);
      } else {
        toggleContractSystemProcess('WARNING', index, response.messageKey);
        showWarningMessage(cancelContractFailMessage);
      }
    } else {
      if (response.message === "TypeError: Cannot read property 'subscription' of null") {
        toggleContractSystemProcess('FAIL', index, 'StudentContractsPage.SystemMessage.InvoiceCancelFailTemp');
        showErrorMessage(cancelContractFailMessage);
      } else {
        toggleContractSystemProcess('FAIL', index, 'StudentContractsPage.SystemMessage.InvoiceCancelFail');
        showErrorMessage(cancelContractFailMessage);
      }
    }
  };

  const closeContractIntegratedNotIntegrated = async (contract, index) => {
    try {
      await cancelMoneySubscribe({ contract_id: contract.id, contract_type: 'SCHOOL', mustCancelContract: true });
      toggleContractSystemProcess('SUCCESS', index);
    } catch (error) {
      if (error.message === PaymentErrors.PROCESSING_ERROR) {
        toggleContractSystemProcess('FAIL', index, 'StudentContractsPage.SystemMessage.InvoiceCancelFail');
        showErrorMessage(cancelContractFailMessage);
      } else {
        toggleContractSystemProcess('FAIL', index, 'StudentContractsPage.SystemMessage.CommunicationProblemServer');
        showErrorMessage(cancelContractFailMessage);
      }
    }
  };

  const closeContractAndCancelBills = async (contract, index) => {
    toggleContractSystemProcess('LOADING', index);

    if (contract.payment !== 'MONEY' && !!contract.financial[contract.financial.length - 1]?.payment_subscription) {
      await closeContractIntegrated(contract, index);
    } else {
      await closeContractIntegratedNotIntegrated(contract, index);
    }
  };

  const closeAllContractsAndCancelBills = async () => {
    contracts.map((contract, i) => closeContractAndCancelBills(contract, i));
  };

  return (
    <ModalWithMethods {...props} size={'lg'} ref={ref}>
      {isLoading && <Loading type='6' />}
      {error && <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />}
      {warning && <MessageWarningMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />}

      {/* Turmas */}
      <TableHeader
        title={t('Classes_other')}
        quantity={classes.length}
        subtitle={t('InactivateStudentModal.StudentClassesSubtitle', { count: classes.length })}
        description={t('InactivateStudentModal.StudentClassesInstruction')}
        action={removeFromAllClasses}
        actionDisabled={!classes.length || !classes.some((c) => ['STAND_BY', 'FAIL'].includes(c.systemProcess))}
        actionText={t('RemoveAll')}
      />
      <table className='table_reports'>
        <thead>
          <tr>
            <th>{t('Classes')}</th>
            <th></th>
            <th className='col-min' style={{ width: 40 }}></th>
          </tr>
        </thead>
        <tbody>
          {classes.length === 0 && (
            <tr>
              <td colSpan={999}>{t('InactivateStudentModal.StudentNoClasses')}</td>
            </tr>
          )}
          {classes.map((_class, i) => (
            <tr key={i}>
              <td>{_class.name}</td>
              <td style={{ minWidth: 130 }}>{_class.name}</td>
              <td className='col-min' style={{ width: 40 }}>
                <h5
                  title={t(_class.systemMessage || 'RemoveFromClass')}
                  className={`${
                    systemProcessIndicators[_class.systemProcess].text
                  } clickable d-flex align-items-center`}
                  onClick={() => removeFromClass(_class.id, i)}
                  style={{ pointerEvents: ['SUCCESS', 'LOADING'].includes(_class.systemProcess) ? 'none' : undefined }}
                >
                  {systemProcessIndicators[_class.systemProcess].icon}
                </h5>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Contratos e financeiro */}
      <TableHeader
        marginTopExtra
        title={t('Contracts')}
        quantity={contracts.length}
        subtitle={t('InactivateStudentModal.StudentContractsSubtitle', { count: contracts.length })}
        description={t('InactivateStudentModal.StudentContractsInstruction')}
        action={closeAllContractsAndCancelBills}
        actionDisabled={!contracts.length || !contracts.some((c) => ['STAND_BY', 'FAIL'].includes(c.systemProcess))}
        actionText={t('CloseAll')}
      />
      <table className='table_reports'>
        <thead>
          <tr>
            <th className='col-min'>#</th>
            <th>{t('ContractStart')}</th>
            <th>{t('ContractEnd')}</th>
            <th>{t('Value')}</th>
            <th>{t('Billing')}</th>
            <th className='col-min' style={{ width: 40 }}></th>
          </tr>
        </thead>
        <tbody>
          {contracts.length === 0 && (
            <tr>
              <td colSpan={999}>{t('InactivateStudentModal.StudentNoContracts')}</td>
            </tr>
          )}
          {contracts.map((contract, i) => (
            <Fragment key={'student_contract' + i}>
              <tr>
                <td className='clickable' onClick={() => toggleCollapse(i)}>
                  <FaCaretRight
                    style={{
                      transform: contract.isShowFinancial ? 'rotate(90deg)' : undefined,
                    }}
                  />
                </td>
                <td>{dateFormat(contract.contractDateStart)}</td>
                <td>{dateFormat(contract.dateEnd)}</td>
                <td>
                  {t('FormatFunction.intlCurrencyWithOptions', {
                    value: contract.value,
                  })}
                </td>
                <td>
                  <span className={`font-weight-bold text-${contract.faturado === 'S' ? 'danger' : 'success'}`}>
                    {contract.faturado === 'S' ? t('Yes') : t('Not')}
                  </span>
                </td>
                <td className='col-min' style={{ width: 40 }}>
                  <h5
                    title={t(contract.systemMessage || 'TerminateContractCancelBillings')}
                    className={`${
                      systemProcessIndicators[contract.systemProcess]?.text || 'text-success'
                    } clickable d-flex align-items-center`}
                    onClick={() => closeContractAndCancelBills(contract, i)}
                    style={{
                      pointerEvents: ['SUCCESS', 'LOADING'].includes(contract.systemProcess) ? 'none' : undefined,
                    }}
                  >
                    {systemProcessIndicators[contract.systemProcess]?.icon || <FaCheck />}
                  </h5>
                </td>
              </tr>
              <td colSpan={999}>
                <Collapse in={contract.isShowFinancial} style={{ width: '100%' }}>
                  <table
                    style={{
                      width: '100%',
                      padding: '0 15px',
                      marginBottom: '15px',
                    }}
                  >
                    <thead style={{ background: '#dddddda1', height: '30px' }}>
                      <tr>
                        <th style={{ width: '140px', textAlign: 'center' }}>{t('DueDate')}</th>
                        <th>{t('Status')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contract.financial.length === 0 && (
                        <tr>
                          <td colSpan={999}>{t('InactivateStudentModal.NoBilling')}</td>
                        </tr>
                      )}
                      {contract.financial.map((finance, j) => (
                        <tr key={'student_contract_financial' + i + j}>
                          <td
                            style={{
                              textAlign: 'center',
                              position: 'relative',
                            }}
                          >
                            <FaLevelUpAlt
                              style={{
                                transform: 'rotate(90deg)',
                                position: 'absolute',
                                left: '15px',
                              }}
                            />
                            {dateFormat(finance.dueDate)}
                          </td>
                          <td>
                            {t(finance.dueDate > moment().format('YYYY-MM-DD') ? 'AwaitingPayment' : 'OverdueBilling')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Collapse>
              </td>
            </Fragment>
          ))}
        </tbody>
      </table>

      <div className='form_modal' style={{ justifyContent: 'center' }}>
        <ButtonRounded isBig onClick={() => ref.current.closeModal()} variant='default'>
          {t('Finalize')}
        </ButtonRounded>
      </div>
    </ModalWithMethods>
  );
};

const TableHeader = ({
  title,
  quantity,
  subtitle,
  description,
  action,
  actionDisabled,
  actionText,
  marginTopExtra,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: marginTopExtra ? '50px' : undefined,
      }}
    >
      <div style={{ paddingRight: '50px' }}>
        <h3 className='modal_title'>
          {title} - <span className='badge badge-info'>{quantity}</span>
        </h3>
        <small style={{ display: 'block' }}>{subtitle}</small>
        <small style={{ display: 'block', marginTop: '5px' }}>{description}</small>
      </div>

      <ButtonRounded onClick={action} variant={!actionDisabled ? 'red' : 'grey'} isDisabled={actionDisabled}>
        {actionText}
      </ButtonRounded>
    </div>
  );
};

export default forwardRef(ModalInactivateStudent);
