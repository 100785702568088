import React, { useEffect, useState } from 'react';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Utils, { getURLContractPDF, getUserPhoto } from '../../../src/utils/utils';
import Api from '../../utils/api';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ModalAthleteModelCard from '../../views/ModalAthleteModelCard';
import SweetAlert from 'react-bootstrap-sweetalert';
import { signContractStudent } from '../../services/ContractStudentService';

const ContractsStudent = () => {
  const { t } = useTranslation();
  const userId = JSON.parse(localStorage.getItem('athlete')).user_id;

  // indexs
  const [school, setSchool] = useState({});

  // inputs modal
  const [modalServices, setModalServices] = useState([]);

  // carregamentos
  const [contracts, setContracts] = useState([]);

  // filtros
  const [filterService, setFilterService] = useState('');

  // process
  const [process, setProcess] = useState(true);
  const [numberDataPerPaginate] = useState(24);

  const [isShowModalCarteirinha, setIsShowModalCarteirinha] = useState(false);
  const [studentCarteirinha, setStudentCarteirinha] = useState(undefined);

  // outros
  const [dirImage, setDirImage] = useState(false);
  const [sweetAlert, setSweetAlert] = useState({ type: null, message: '' });

  // libraries
  const baseURL = Api();
  const { dateFormat } = Utils();

  useEffect(() => {
    const schoolSession = JSON.parse(localStorage.getItem('school'));

    setSchool(schoolSession);
    getContracts(schoolSession.id);

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeCarteirinha();
    }
  };

  async function getContracts(school) {
    setProcess(true);
    const res = await axios.post(baseURL + 'get-contracts', {
      school_id: school,
      pagination: { offset: 0, limit: numberDataPerPaginate },
      page: 1,
      filters: getFilters(),
    });

    setDirImage(res.data.dirImage);
    setContracts(res.data.contracts);
    setModalServices(res.data.services);

    setProcess(false);
  }

  const getFilters = () => {
    return {
      filterStudentId: userId,
      filterStatus: 'Ativo',
      filterService,
    };
  };

  const search = (e) => {
    e.preventDefault();
    getContracts(school.id);
  };

  const openCarteirinha = (item) => {
    setStudentCarteirinha({
      name: item.nameStudent,
      usuBirth: item.usuBirth,
      nameClass: item.nameClass,
      foto: dirImage + '/' + item.usuFotoPrincipal,
      idStudent: item.idStudent,
      idContract: item.id,
      schName: school.schName,
      schPhone: school.schPhone,
      schEmail: school.schEmail,
      schCnpj: school.schCnpj,
    });

    setIsShowModalCarteirinha(true);
  };

  const closeCarteirinha = () => {
    setStudentCarteirinha(undefined);
    setIsShowModalCarteirinha(false);
  };

  const acceptContract = async (contractId) => {
    const { success, message } = await signContractStudent(contractId);

    if (success) {
      getContracts(school.id);
      setSweetAlert({ type: 'SUCCESS', message });
    } else setSweetAlert({ type: 'DANGER', message });
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('StudentContractsPage.FilterCard.Title')}</h2>
                    <p className='card_subtitle'>{t('StudentContractsPage.FilterCard.Subtitle')}</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='schedule' className='card_details_label'>
                        {t('ContractedService')}
                      </label>
                      <select
                        className='form_control select'
                        value={filterService}
                        onChange={(e) => setFilterService(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        {modalServices.map((item, i) => (
                          <option key={i} value={item.nome}>
                            {item.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                    <button className='btn_card mx-auto' onClick={search}>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('Search')}
                    </button>
                  </form>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('StudentContractsPage.PageTitle')}</h2>
                    <p className='card_subtitle'>{t('StudentContractsPage.PageSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    {process && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!process &&
                      contracts.map((item, i) => {
                        return (
                          <div className='card_list' key={`employees_${i}`}>
                            <div className='img_list_user'>
                              <img src={getUserPhoto(dirImage + '/', item.usuFotoPrincipal)} alt='' />
                            </div>
                            <div className='content_card'>
                              <div className='card_details' style={{ alignItems: 'flex-start', justifyContent: '' }}>
                                <p className='card_details_title'>
                                  {t('ContractNumberValue', { number: String(item.number).padStart(6, '0') })}
                                </p>
                              </div>
                              <div
                                className='card_details'
                                style={{ alignItems: 'flex-start', justifyContent: 'start' }}
                              >
                                <p className='card_details_label'>{t('Student')}: </p>
                                <p className='card_details_text'>{item.nameStudent}</p>
                              </div>
                              <div
                                className='card_details'
                                style={{ alignItems: 'flex-start', justifyContent: 'start' }}
                              >
                                <p className='card_details_label'>{t('StartDate')}: </p>
                                <p className='card_details_text'>{dateFormat(item.dateStart)}</p>
                              </div>
                              <div
                                className='card_details'
                                style={{ alignItems: 'flex-start', justifyContent: 'start' }}
                              >
                                <p className='card_details_label'>{t('EndDate')}: </p>
                                <p className='card_details_text'>{dateFormat(item.dateEnd)}</p>
                              </div>
                              <div
                                className='card_details'
                                style={{ alignItems: 'flex-start', justifyContent: 'start' }}
                              >
                                <p className='card_details_label'>{t('Value')}: </p>
                                <p className='card_details_text'>
                                  {t('FormatFunction.intlCurrencyWithOptions', { value: item.value })}
                                </p>
                              </div>
                              <div
                                className='card_details'
                                style={{ alignItems: 'flex-start', justifyContent: 'start' }}
                              >
                                <p className='card_details_label'>{t('Signed')}: </p>
                                <p
                                  className='card_details_text'
                                  style={{
                                    color: item.responsibleSignatureDate === null ? 'red' : 'green',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {item.responsibleSignatureDate === null ? t('Not') : t('Yes')}
                                </p>
                              </div>
                            </div>
                            <a
                              onClick={(event) => window.open(getURLContractPDF(item, event))}
                              className='btn_card'
                              style={{
                                backgroundColor: 'green',
                                fontWeight: 500,
                                border: '1px solid green',
                                marginBottom: 8,
                              }}
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('PDFContract')}
                            </a>
                            <span
                              style={{
                                backgroundColor: '#aaa',
                                fontWeight: 500,
                                border: '1px solid #aaa',
                              }}
                              onClick={() => openCarteirinha(item)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('ModelCard')}
                            </span>
                            {item.responsibleSignatureDate === null && item.status === 'Ativo' && (
                              <button
                                style={{
                                  backgroundColor: 'orange',
                                  fontWeight: 500,
                                  border: '1px solid orange',
                                  marginTop: 8,
                                }}
                                onClick={() => acceptContract(item.id)}
                                className='btn_card'
                                disabled={item.status !== 'Ativo'}
                              >
                                <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                  <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                                </svg>
                                {t('Aceitar contrato')}
                              </button>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <ModalAthleteModelCard
                show={isShowModalCarteirinha}
                handleClose={closeCarteirinha}
                data={studentCarteirinha}
              />
              <SweetAlert
                show={sweetAlert.type !== null}
                danger={sweetAlert.type === 'DANGER'}
                success={sweetAlert.type === 'SUCCESS'}
                title={sweetAlert.type === 'SUCCESS' ? 'Sucesso!' : sweetAlert.type === 'DANGER' ? 'Atenção!' : ''}
                onConfirm={() => setSweetAlert({ type: null, message: '' })}
                onCancel={() => setSweetAlert({ type: null, message: '' })}
              >
                {sweetAlert.message}
              </SweetAlert>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default ContractsStudent;
