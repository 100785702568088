import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './BtnCTA.css'

const BtnCTA = () => {

    return (
        <Link to={window.location.pathname.indexOf('advantage-club') !== -1 ? '/sign' : '/advantage-club' } className="btn_cta">
            { window.location.pathname.indexOf('advantage-club') !== -1 ? 'Participar agora!' : 'Quero mais vantagens agora!' }
        </Link>
    )
}

export default BtnCTA
