import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Footer from '../components/login/Footer'
import Loading from '../components/Loading'
import Message from '../components/Message'
import Api from '../utils/api'
import axios from 'axios'

const Error404 = () => {
   
   return (
         <React.Fragment>
            <main>
               <section className="pg-acesso" style={{marginTop: '12%'}}>
                  <div className="conteudo-pg-acesso">
                     <div className="card-pg-acesso">
                        <div className="titulo-pg-acesso">
                           <img src="/img/logo.png" alt="Marca" style={{width: '200px'}} />
                        </div>

                        <div className="link-externo">
                           <div className="texto-link-externo">
                              <h3 style={{fontSize: '1.3em', fontFamily: 'Poppins,sans-serif', textAlign: "center"}}>página não encontrada<br /><br />:(</h3>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </main>

         </React.Fragment>
   )
}

export default Error404