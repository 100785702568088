import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import Fade from '@material-ui/core/Fade'
import Footer from '../../components/login/Footer'
import Header from '../../components/login/Header'
import Loading from '../../components/Loading'
import Message from '../../components/Message'
import InputMask from 'react-input-mask';
import Api from '../../utils/api'
import axios from 'axios'

const RegisterCompleteUser = (props) => {
   const baseURL = Api()
   const { MessageDangerMarginTop, MessageSuccess } = Message()
   const [user_id, setUserID] = useState("")
   const [name, setName] = useState("")
   const [email, setEmail] = useState("")
   const [cpf, setCPF] = useState("")
   const [password, setPassword] = useState("")
   const [usuBirth, setUsuBirth] = useState("")
   const [position, setPosition] = useState("")
   const [gender, setGender] = useState("")
   const [termo, setTermo] = useState(false)
   const [send, setSend] = useState(false)
   const [success, setSuccess] = useState(false)
   const [error, setError] = useState(false)
   const [message, setMessage] = useState("")
   const [positions, setPositions] = useState([])
   const [login, setLogin] = useState(false)

   useEffect(() => {
      if ( props.history.location.state === undefined ) {
         setLogin(true)
      } else {
         setEmail(props.history.location.state.email)
         setCPF(props.history.location.state.cpf)
         getPositions()
      }

   },[])

   const getPositions = async () => {
      const res = await axios.get(baseURL + 'get-positions')

      if ( !res.data.error )
         setPositions(res.data.positions)
   } 

   const handleSubmit = async (evt) => {
      evt.preventDefault()
      setSend(true)
      setSuccess(false)
      setMessage("")
      setError(false)

      const res = await axios.post(baseURL + 'register',
                                                         {
                                                            name: name,
                                                            password: password,
                                                            usuBirth: usuBirth,
                                                            position_id: position,
                                                            email: email,
                                                            usuCPF: cpf,
                                                            role_id: 2,
                                                            usuGender: gender,
                                                            termo: termo
                                                         })

      setSend(false)
      if ( !res.data.error ){
         setMessage('Seu usuário foi criado, estamos redirecionando...')
         setUserID(res.data.id)
         localStorage.setItem('user', JSON.stringify(res.data.user))
         localStorage.setItem('athlete', JSON.stringify(res.data.athlete))
         localStorage.setItem('docs', JSON.stringify(res.data.docs))
         localStorage.setItem('menu', JSON.stringify(res.data.menu))
         localStorage.setItem('sign', false)
         setError(false)
         setSuccess(true)
      } else {
         setSuccess(false)
         setError(true)

         let prop = ''
         let msgs = ''
         for(prop in res.data.errors) {
            if (res.data.errors.hasOwnProperty(prop)) {
               msgs += res.data.errors[prop][0]
            }
         }

         setMessage(msgs)
      }
   }

   if ( login ) {
      return (
         <Redirect to="/" />
      )
   }

   if ( success ) {
      return (
         <Redirect to={{
               pathname: '/home-athlete',
               state: {
                  user_id: user_id
               }
            }} />
      )
   }

   return (
      <article className="container-fluid_new bg_new bg_login_new" style={{flexDirection: "column"}}>
         <div className="container_login_new register">
            <div className="col-12 col-md-6">
               <div className="content_login_new">
                  <div className="swiper-container_new">
                     <div className="swiper-wrapper">
                        <Fade in={true} timeout={2000}>
                           <div className="swiper-slide_new" style={{alignText: 'center !important'}}>
                              <h1 className="title_login_new" style={{fontFamily: 'TitlingGothicFB'}}>Bem vido a</h1>
                              <h1 className="title_login_verde_new" style={{fontFamily: 'TitlingGothicFB'}}>Fensor</h1>
                              <p style={{fontFamily: 'TitlingGothicFB Comp', color: 'white', fontSize: '16px',lineHeight: '19px'}}>Preencha os primeiros detalhes sobre você</p>
                           </div>
                        </Fade>
                     </div>
                  </div>
                  <span className="linha_vertical_login_new"></span>
               </div>
            </div>
            <div className="col-12 col-md-6">
               <div className="content_login_new">
                  <form onSubmit={handleSubmit} className="call_to_action_new" autoComplete="new-password">
                     {
                        success && <MessageSuccess title="Sucesso! " description={message} />
                     }

                     {
                        error && <MessageDangerMarginTop title="Ops! " description={message} />
                     }
                     <div className="content_form_new" style={{lineHeight: '0.8em'}} >
                        <label className="lbl_login_new" style={{marginBottom: '0px'}} style={{fontFamily: 'TitlingGothicFB Comp'}}>Nome Completo</label>
                        <input autoComplete="new-password" className="input_login_new" style={{marginBottom: '15px'}} required type="name" name="name" onChange={e => setName(e.target.value)}/>
                     </div>
                     <div className="content_form_new" style={{lineHeight: '0.8em'}}>
                        <label className="lbl_login_new" style={{marginBottom: '0px'}} style={{fontFamily: 'TitlingGothicFB Comp'}}>Defina sua Senha</label>
                        <input autoComplete="new-password" className="input_login_new" style={{marginBottom: '15px'}}  required type="password" name="password" onChange={e => setPassword(e.target.value)}/>
                     </div>
                     <div className="content_form_new" style={{lineHeight: '0.8em'}}>
                        <label className="lbl_login_new" style={{marginBottom: '0px'}} style={{fontFamily: 'TitlingGothicFB Comp'}}>Data de Nascimento</label>
                        <input autoComplete="new-password" className="input_login_new" style={{marginBottom: '15px'}}  required type="date" name="date" onChange={e => setUsuBirth(e.target.value)}/>
                     </div>
                     <div className="content_form_new" style={{lineHeight: '0.8em'}}>
                        <label className="lbl_login_new" style={{marginBottom: '0px'}} style={{fontFamily: 'TitlingGothicFB Comp'}}>Qual é a sua posição?</label>
                        <select className="input_login_new" required name="position" style={{marginBottom: '15px', color: 'black'}}  onChange={e => setPosition(e.target.value)}>
                           <option value=""></option>
                           { positions.map((item) => <option value={item.id} key={item.id}>{item.posPosition}</option>) }
                        </select>	
                     </div>
                     <div className="content_form_new" style={{lineHeight: '0.8em'}}>
                        <label className="lbl_login_new" style={{marginBottom: '0px'}} style={{fontFamily: 'TitlingGothicFB Comp'}}>Qual é o seu sexo?</label>
                        <select className="input_login_new" required style={{marginBottom: '15px', color: 'black'}}  name="position" onChange={e => setGender(e.target.value)}>
                           <option value=""></option>
                           <option value="M">Masculino</option>
                           <option value="F">Feminino</option>
                        </select>	
                     </div>
                     <div className="content_form_new" style={{lineHeight: '0.8em', flexDirection: 'row', color: 'white', alignItems: 'center', justifyContent: 'center'}}>
                        <input style={{width: '20px', margin: '0px'}} required className="input_login_new" type="checkbox" name="termo" value="S" id="termos" onChange={e => setTermo(e.target.checked)} />
                        <label htmlFor="termos" style={{margin: 0, marginLeft: 10, fontSize: '13px'}}>Concordo com os termos de uso</label>
                     </div>
                     <div className="content_form_new" style={{ lineHeight: '0.8em', width: '100%', marginTop: 5}}>
                        <button className="btn_login_entrar_new" style={{width: '100%'}}>
                        {
                           send && <Loading type="1" />
                        }
                        {
                           !send && <React.Fragment>CRIAR CONTA</React.Fragment>
                        }
                        </button>
                     </div>
                  </form>
               </div>
            </div>
         </div>

         <Footer />
      </article>
   )
}

export default RegisterCompleteUser