import React, { useEffect, useState } from 'react'
import Header from '../../components/athlete/Header'
import Footer from '../../components/athlete/Footer'
import Events from '../../components/athlete/Events'
import Loading from '../../components/Loading'
import utils from '../../utils/utils'
import Api from '../../utils/api'
import axios from 'axios'

const EventsHome = (props) => {
   const { accordion } = utils()
   const baseURL = Api()
   
   const [sendState, setSendingState] = useState(false)
   const [sendCity, setSendingCity] = useState(false)
   const [sendCompanie, setSendingCompanie] = useState(false)
   const [sendSearch, setSendingSearch] = useState(false)
   
   const [companies, setCompanies] = useState([])
   const [states, setStates] = useState([])
   const [citys, setCitys] = useState([])

   const [search, setSearch] = useState('')
   const [companie_id, setCompanieID] = useState('')
   const [state_id, setStateID] = useState('')
   const [city_id, setCity] = useState('')
   const [data, setData] = useState([])
   
   useEffect(()=>{
      getCompanies()
      getStates()
   },[])

   const getCompanies = async () => {
      setSendingCompanie(true)
      const res = await axios.get(baseURL + 'get-companies')
      setSendingCompanie(false)
      setCompanies(res.data.companies)
   }

   const getStates = async () => {
      setCitys([])
      setSendingState(true)
      const res = await axios.get(baseURL + 'get-states')
      setSendingState(false)
      setStates(res.data.states)
   }

   const getCitys = async (state_id) => {
      setStateID(state_id)
      setSendingCity(true)
      const res = await axios.post(baseURL + 'get-citys',{
         state_id: state_id
      })
      setSendingCity(false)
      setCitys(res.data.cidades)
   }

   const handleSubmit = async (evt) => {
      evt.preventDefault()
     
      setSendingSearch(true)
      setTimeout(function(){ 
         setData({
            search,
            companie_id,
            state_id,
            city_id
         })

         setSendingSearch(false)
      }, 2000)
      
   }

   return (
         <React.Fragment>
            
            <Header type="1" menu={true}/>

            <main>
               <section className="bloco-principal">
                  <div className="conteudo-bloco-principal bloco-pg-seletivas">
                     <div className="bloco-2 excluir">
                        <div className="conteudo-generico">
                           <div className="coluna-pg-seletivas">
                              <h3>Procurar</h3>
                              <p>Escolha que tipo de seletivas quer ver.</p>
                           </div>
                           <form className="pesquisa-pg-seletivas" onSubmit={handleSubmit} action="" method="">
                              <div className="coluna-curriculo">
                                 <label>
                                    Clube &nbsp;
                                    {
                                       sendCompanie && 
                                       <Loading type="1" />
                                    }
                                 </label>
                                 {
                                    !sendCompanie && 
                                    <select name="companie_id" onChange={e => setCompanieID(e.target.value)}>
                                       <option value="">-- Todos os Clubes --</option>
                                       {
                                          companies.map(item => <option value={item.id} key={item.id}>{item.comCompanie}</option>)
                                       }
                                    </select>
                                 }
                              </div>
                              
                              <div className="coluna-curriculo">
                                 <label>
                                    Estados &nbsp;
                                    {
                                       sendState && 
                                       <Loading type="1" />
                                    }
                                 </label>
                                 {
                                    !sendState && 
                                    <select name="state_id" onChange={e => getCitys(e.target.value)}>
                                       <option value="">-- Todos os Estados --</option>
                                       {
                                          states.map(item => <option value={item.id} key={item.id}>{item.staState}</option>)
                                       }
                                    </select>
                                 }
                              </div>

                              <div className="coluna-curriculo">
                                 <label>
                                    Cidades &nbsp;
                                    {
                                       sendCity && 
                                       <Loading type="1" />
                                    }
                                 </label>
                                 {
                                    !sendCity && 
                                    <select name="city_id" onChange={e => setCity(e.target.value)}>
                                       <option value="">-- Todos as Cidades --</option>
                                       {
                                          citys.map(item => <option value={item.id} key={item.id}>{item.citCity}</option>)
                                       }
                                    </select>
                                 }
                              </div>

                              <div className="botao-pesquisa-seletivas">
                              {
                                 !sendSearch &&
                                 <button type="submit">Buscar</button>
                              }
                              {
                                 sendSearch && <span>Estamos procurando... <Loading type="1"/></span>
                              } 
                              </div>
                           </form>
                        </div>
                     </div>

                     <div className="pesquisa-responsivo">
                        <button className="accordion" onClick={accordion}>Procurar <img src="../../img/icones/seta-2.png" /></button>

                        <div className="panel">
                           <form className="pesquisa-pg-seletivas" onSubmit={handleSubmit} action="" method="">
                              <div className="coluna-curriculo">
                                 <label>Pesquisar</label>
                                 <input type="search" name="search" />
                              </div>
                              
                              <div className="coluna-curriculo">
                                 <label>
                                    Clube &nbsp;
                                    {
                                       sendCompanie && 
                                       <Loading type="1" />
                                    }
                                 </label>
                                 {
                                    !sendCompanie && 
                                    <select name="companie_id" onChange={e => setCompanieID(e.target.value)}>
                                       <option value="">-- Todos os Clubes --</option>
                                       {
                                          companies.map(item => <option value={item.id} key={item.id}>{item.comCompanie}</option>)
                                       }
                                    </select>
                                 }
                              </div>
                              
                              <div className="coluna-curriculo">
                                 <label>
                                    Estados &nbsp;
                                    {
                                       sendState && 
                                       <Loading type="1" />
                                    }
                                 </label>
                                 {
                                    !sendState && 
                                    <select name="state_id" onChange={e => getCitys(e.target.value)}>
                                       <option value="">-- Todos os Estados --</option>
                                       {
                                          states.map(item => <option value={item.id} key={item.id}>{item.staState}</option>)
                                       }
                                    </select>
                                 }
                              </div>

                              <div className="coluna-curriculo">
                                 <label>
                                    Cidades &nbsp;
                                    {
                                       sendCity && 
                                       <Loading type="1" />
                                    }
                                 </label>
                                 {
                                    !sendCity && 
                                    <select name="city_id" onChange={e => setCity(e.target.value)}>
                                       <option value="">-- Todos as Cidades --</option>
                                       {
                                          citys.map(item => <option value={item.id} key={item.id}>{item.citCity}</option>)
                                       }
                                    </select>
                                    }
                              </div>

                              <div className="botao-pesquisa-seletivas">
                              {
                                 !sendSearch &&
                                 <button type="submit">Buscar</button>
                              }
                              {
                                 sendSearch && <span>Estamos procurando... <Loading type="1"/></span>
                              }  
                              </div>
                           </form>
                        </div>
                     </div>
                     <div className="bloco-1-secundario">
                        <Events data={data} carregarMais={false} verTodos={false} verDetalhes={false} limit="" title="PRÓXIMOS EVENTOS" description="Veja abaixo os próximos eventos na sua região." />
                     </div>
                  </div>
               </section>
            </main>

            <Footer />
          
         </React.Fragment>
   )
}

export default EventsHome