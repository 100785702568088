import { defaultApiObject, handleFail } from '../utils/utils-api';
import api from './api';

export const getMethodologiesByCompanyId = async (id) => {
  try {
    const { data } = await api.get('methodology-by-company-id');
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getMethodologiesBySchoolId = async () => {
  try {
    const { data } = await api.get('methodology-by-school-id');
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const createMethodology = async (values) => {
  try {
    const { data } = await api.post('methodology', values);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateMethodology = async (values) => {
  try {
    const { data } = await api.put('methodology', values);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};
