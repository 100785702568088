import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { collection, addDoc, getFirestore, query, onSnapshot, where, getDocs } from 'firebase/firestore';
import './chat.css';
import NewChat from './components/NewChat/NewChat';
import PrivateChat from './components/PrivateChat/PrivateChat';
import ChatList from './components/ChatList/ChatList';
import { firebaseConfig } from '../../config/firebase.config';
import { v4 as uuidv4 } from 'uuid';
import {
  countUnreadMessages,
  createUserInFirestore,
  fetchChats,
  findUserById,
  handleConnectivityStatus,
  updateUserStatus,
} from '../../services/ChatService';
import { getAuth, onAuthStateChanged } from '@firebase/auth';

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

const Chat = () => {
  const [chats, setChats] = useState([]);
  const [showChatBox, setShowChatBox] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [currentRoute, setCurrentRoute] = useState('/home');
  const [chatId, setChatId] = useState(null);
  const [docId, setDocId] = useState(null);
  const [widgetMessageCount, setWidgetMessageCount] = useState(0);

  //Get userID from localstorage
  const user = localStorage.getItem('user');
  const userObj = JSON.parse(user || '{}');
  const { id, school_id, role_id } = userObj;

  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setTimeout(() => {
          handleConnectivityStatus(currentUser);
        }, 5000);
        fetchChatsEffect();
      }
    });
  }, [auth]);

  useEffect(() => {
    if (currentRoute === '/') {
      handleChatBox();
    }
  }, [currentRoute]);

  const fetchChatsEffect = async () => {
    const chatsRef = collection(firestore, 'chats');
    const q = query(chatsRef, where('members', 'array-contains', id), where('messages', '!=', []));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      if (querySnapshot.empty) {
        setCurrentRoute('/new-chat');
      }

      const chatsData = [];
      for (const doc of querySnapshot.docs) {
        const data = doc.data();

        
        const userId = data.members.find((member) => member !== id);
        let user = await findUserById(firestore, userId);
        if (!user.empty && user.role_data && user.role_data.id === 2 && role_id === 2) {
          continue;
        }
        
        chatsData.push({ ...data, id: doc.id, user });
      }

      const { chatsWithUnreadMessages } = countUnreadMessages(chatsData, id);
      setWidgetMessageCount(chatsWithUnreadMessages);
      setChats(chatsData);
    });
    return unsubscribe;
  };

  const createNewChat = async (sender_id, contact) => {
    try {
      const myChats = await fetchChats(firestore, sender_id, contact.user_id);
      if (myChats.length > 0) {
        const chat = myChats.find((chat) => chat.members.includes(contact.user_id));
        if (chat) {
          goToPrivateChat(chat.chat_id, chat.id);
          return true;
        }
      }

      const chat_id = uuidv4();
      const payload = {
        created_by: sender_id,
        created_at: new Date().getTime(),
        chat_id: chat_id,
        members: [sender_id, contact.user_id],
        messages: [],
        sender_id: sender_id,
        receiver_id: contact.user_id,
      };

      const searchResult = await findUserById(firestore, contact.user_id);

      if (searchResult.empty) {
        await createUserInFirestore({
          avatar: contact.avatar,
          name: contact.name,
          email: contact.email,
          status: 'offline',
          role: contact.role,
          user_id: contact.user_id,
          school_id: school_id,
          last_seen: 0,
          uid: uuidv4(),
        });
      }

      const { id } = await addDoc(collection(firestore, 'chats'), payload);
      goToPrivateChat(chat_id, id);
    } catch (error) {
      console.error('Erro ao criar a conversa:', error);
      return false;
    }
  };

  const goToNewChat = () => {
    setCurrentRoute('/new-chat');
  };

  const goToPrivateChat = (chat_id, doc_id) => {
    setChatId(chat_id);
    setDocId(doc_id);
    setCurrentRoute('/private-chat');
  };

  const handleChatBox = () => {
    setShowChatBox(!showChatBox);
    setCurrentRoute('/chat-list');
  };

  return (
    <div>
      {showChatBox && (
        <div className='chat-container'>
          {currentRoute === '/private-chat' && (
            <PrivateChat chatId={chatId} setCurrentRoute={setCurrentRoute} docId={docId} />
          )}
          {currentRoute === '/new-chat' && (
            <NewChat
              firestore={firestore}
              userData={userObj}
              createNewChat={createNewChat}
              setCurrentRoute={setCurrentRoute}
            />
          )}
          {currentRoute === '/chat-list' && (
            <ChatList
              chats={chats}
              myId={id}
              goToNewChat={goToNewChat}
              setCurrentRoute={setCurrentRoute}
              goToPrivateChat={goToPrivateChat}
              setWidgetMessageCount={setWidgetMessageCount}
            />
          )}
        </div>
      )}
      <button className='chat-widget' onClick={() => handleChatBox()}>
        {widgetMessageCount > 0 && <div className='chat-widget__message-count'>{widgetMessageCount}</div>}
        <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M0.666626 27.3333V3.33333C0.666626 2.6 0.927737 1.97222 1.44996 1.45C1.97218 0.927779 2.59996 0.666668 3.33329 0.666668H24.6666C25.4 0.666668 26.0277 0.927779 26.55 1.45C27.0722 1.97222 27.3333 2.6 27.3333 3.33333V19.3333C27.3333 20.0667 27.0722 20.6944 26.55 21.2167C26.0277 21.7389 25.4 22 24.6666 22H5.99996L0.666626 27.3333ZM3.33329 20.9L4.89996 19.3333H24.6666V3.33333H3.33329V20.9Z'
            fill='var(--main-color)'
          />
        </svg>
      </button>
    </div>
  );
};
export default Chat;
