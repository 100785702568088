import React from 'react';
import BaseInput from './BaseInput';
import PhoneMask from './PhoneMask';

const InputPhoneMask = ({ ...props }) => {
  return (
    <BaseInput
      {...props}
      inputComponent={({ onChange, ...props }) => (
        <PhoneMask {...props} onChange={(value) => onChange(props.name, value)} />
      )}
    />
  );
};

export default InputPhoneMask;
