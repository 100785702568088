import React, { Component } from 'react'

import './Expirar.css'

import Nav from '../../components/nav/Nav'
import CTA from '../../components/Buttons/btn-cta/BtnCTA'
import Footer from '../../components/footer/Footer'

const Expirar = () => {

    return (
        <React.Fragment>
            <Nav />
            <div className="mainContainer">
                <table className="table_expirar">
                    <thead>
                        <tr>
                            <th>pontos a expirar</th>
                            <th>data de expiração</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>200 pts</td>
                            <td>01/05/2020</td>
                        </tr>
                        <tr>
                            <td>200 pts</td>
                            <td>01/05/2020</td>
                        </tr>
                        <tr>
                            <td>200 pts</td>
                            <td>01/05/2020</td>
                        </tr>
                    </tbody>
                </table>

                <p className="descript text-bold">
                    Garanta mais vantagens entrando no nosso Clube de Parceiros.
                </p>
                <CTA />
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Expirar