import React, { forwardRef } from 'react';
import { ModalWithMethods } from '../../components/modal/Modal';
import FinancialTable from '../../components/tables/FinancialTable';

const ModalCashFlow = ({ income, expense, isLoading, ...props }, ref) => {
  return (
    <ModalWithMethods {...props} ref={ref} title={''} size={'lg'}>
      <h3 className='modal_title'>Receitas</h3>
      <FinancialTable
        type='INCOME'
        isLoading={isLoading}
        finances={income.filter((finance) => finance.type === 'Receita')}
      />
      <h3 className='modal_title'>Despesas</h3>
      <FinancialTable
        type='EXPENSE'
        isLoading={isLoading}
        finances={expense.filter((finance) => finance.type === 'Despesa')}
      />
    </ModalWithMethods>
  );
};

export default forwardRef(ModalCashFlow);
