import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import { AllMonths, getDaysInMonth, handleOnChange, range } from '../../../src/utils/utils';
import { useTranslation } from 'react-i18next';
import SelectRounded from '../../components/inputs/SelectRounded';
import moment from 'moment';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import { getClassFrequencyReports } from '../../services/ReportService';
import { useReactToPrint } from 'react-to-print';
import { CardIndicatorSimple } from '../../components/cards/CardIndicatorSimple';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const ClassFrequencyReports = () => {
  const { t } = useTranslation();
  const { MessageDangerMarginTop } = Message();
  const schoolId = JSON.parse(localStorage.getItem('school')).id;
  const tableRef = useRef(null);

  const [studentClasses, setStudentClasses] = useState([]);
  const [classFrequency, setClassFrequency] = useState([]);
  const [daysMonth, setDaysMonth] = useState(getDaysInMonth());

  const [selectedData, setSelectedData] = useState({
    class: '',
    hour: '',
    month: '',
    year: '',
  });

  const [filters, setFilters] = useState({
    classId: '',
    hour: '',
    year: moment().year(),
    month: moment().format('MM'),
  });
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchClassFrequencyReports();
  }, []);

  const onChangeFilters = handleOnChange(setFilters);

  const fetchClassFrequencyReports = async (event = null) => {
    event && event.preventDefault();

    clearMessage();
    setIsLoading(true);
    const { success, messageKey, classFrequency, classDates, studentClasses } = await getClassFrequencyReports(
      schoolId,
      filters
    );
    setIsLoading(false);
    if (success) {
      setClassFrequency(
        classFrequency.map((student) => ({ ...student, presenceDays: student.dates?.split(',') || [] }))
      );
      setStudentClasses(studentClasses);
      setDaysMonth(classDates?.map(({ data }) => data) || []);
      setSelectedData({
        class: studentClasses.find((studentClass) => studentClass.id == filters.classId)?.name,
        hour: filters.hour,
        month: filters.month,
        year: filters.year,
      });
    } else {
      showErrorMessage(messageKey);
    }
  };

  const clearMessage = () => {
    setMessage(null);
  };

  const showErrorMessage = (message) => {
    setMessage(message);
  };

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    pageStyle: `
      .btn_card {
        display: none !important;
      }
      * {
        color: black !important;
      }
      .card {
        border: none !important;
      }
      .pdf-title {
        display: block !important;
      }
    `,
  });

  const exportToXLS = async () => {
    const studentClass = studentClasses.find((studentClass) => studentClass.id == filters.classId);

    if (studentClass) {
      await fetchClassFrequencyReports();

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const filename = `${studentClass.name} - ${filters.year}-${filters.month}.xlsx`;

      const data = classFrequency.map((student) => ({
        [t('Student')]: student.name,
        ...daysMonth.reduce(
          (acc, current) => ({
            ...acc,
            [`${filters.year}-${current.split('/').reverse().join('-')}`]: student.presenceDays.some(
              (dayStudent) => dayStudent.indexOf(current) === 0
            )
              ? t('Present')
              : t('Absent'),
          }),
          {}
        ),
      }));

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, filename);
    }
  };

  const calcStudentAttendanceRate = () => {
    const individualAttendanceRate = classFrequency.map((student) =>
      daysMonth.length === 0 ? 0 : (student.frequente / daysMonth.length) * 100
    );

    const averageAttendanceRate =
      individualAttendanceRate.reduce((acc, cur) => acc + cur, 0) / individualAttendanceRate.length;

    return averageAttendanceRate.toFixed(2) + '%';
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel' style={{ minHeight: 'auto', alignItems: 'center' }}>
              <div className='card' style={{ justifyContent: 'center' }}>
                <div className='card_header' style={{ alignItems: 'center', width: '100%' }}>
                  <h2 className='card_title'>{t('ClassFrequencyReportsPage.Filter.Title')}</h2>
                  <p className='card_subtitle'>{t('ClassFrequencyReportsPage.Filter.Subtitle')}</p>
                </div>

                <form onSubmit={fetchClassFrequencyReports} style={{ alignItems: 'center' }}>
                  <div
                    className='form_card row'
                    style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}
                  >
                    {[
                      {
                        emptyOption: false,
                        label: t('ClassFrequencyReportsPage.Filter.ChooseTheClass'),
                        name: 'classId',
                        value: filters.classId,
                        options: studentClasses.map((studentClass) => ({
                          value: studentClass.id,
                          label: studentClass.name,
                        })),
                      },
                      {
                        emptyOption: true,
                        label: t('ClassFrequencyReportsPage.Filter.ChooseTime'),
                        name: 'hour',
                        value: filters.hour,
                        options:
                          studentClasses
                            .find((studentClass) => studentClass.id == filters.classId)
                            ?.class_days.filter(
                              ({ hour }, index, self) => self.findIndex(({ hour: hourF }) => hourF === hour) === index
                            )
                            .map(({ hour }) => ({
                              value: hour,
                              label: hour,
                            })) || [],
                      },
                      {
                        emptyOption: false,
                        label: t('ClassFrequencyReportsPage.Filter.ChooseTheYear'),
                        name: 'year',
                        value: filters.year,
                        options: range(2015, moment().year() + 1).map((year) => ({ value: year, label: year })),
                      },
                      {
                        emptyOption: false,
                        label: t('ClassFrequencyReportsPage.Filter.ChooseTheMonth'),
                        name: 'month',
                        value: filters.month,
                        options: AllMonths.map((month) => ({ value: month.numberString, label: t(month.name) })),
                      },
                    ].map((select, i) => (
                      <div key={'ClassFrequencyReportsFilter' + i} className='mx-2' style={{ width: '180px' }}>
                        <SelectRounded
                          required
                          label={select.label}
                          name={select.name}
                          value={select.value}
                          onChange={onChangeFilters}
                        >
                          <option value={''} disabled={!select.emptyOption}></option>
                          {select.options.map((option, j) => (
                            <option key={'ClassFrequencyReportsFilterOptions' + i + j} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </SelectRounded>
                      </div>
                    ))}
                  </div>
                  <div style={{ width: '100px' }}>
                    <ButtonRounded type='submit'>{t('Research')}</ButtonRounded>
                  </div>
                </form>
              </div>
            </main>

            <main className='content_panel' style={{ minHeight: 'auto' }}>
              <div className='card' ref={tableRef} style={{ maxWidth: '100vw' }}>
                <div className='card_header' style={{ alignItems: 'center', width: '100%' }}>
                  <h2 className='card_title'>{t('ClassFrequencyReportsPage.Report.Title')}</h2>
                  <p className='card_subtitle'>{t('ClassFrequencyReportsPage.Report.Subtitle')}</p>
                  {!!message && (
                    <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                  )}
                  <h4 className='mt-3 pdf-title' style={{ display: 'none' }}>
                    {selectedData.class} - {selectedData.hour}
                  </h4>
                  <div className='pdf-title' style={{ display: 'none' }}>
                    {selectedData.month}/{selectedData.year}
                  </div>
                </div>
                <div className='list_card'>
                  {isLoading ? (
                    <p className='loading-card'>
                      <Loading type='1' />
                    </p>
                  ) : (
                    <table
                      className='table'
                      style={{
                        width: 'fit-content',
                        minWidth: '100%',
                        boxShadow: 'none',
                        borderRadius: 'initial',
                        overflowX: 'auto',
                      }}
                    >
                      <thead style={{ position: 'sticky', top: '0px', backgroundColor: 'white', zIndex: 1 }}>
                        <tr>
                          <th
                            style={{
                              padding: '0px',
                              minWidth: '150px',
                              position: 'sticky',
                              left: '0px',
                              top: '0px',
                              zIndex: 2,
                              backgroundColor: 'white',
                              fontWeight: 'bold',
                            }}
                          >
                            {t('Student')}
                          </th>
                          {daysMonth.map((day) => (
                            <th
                              key={day}
                              style={{ minWidth: '25px', width: '25px', padding: '0px', fontWeight: 'bold' }}
                            >
                              {day.split('/').shift()}
                            </th>
                          ))}
                          <th style={{ minWidth: '50px', width: '50px', padding: '0px', fontWeight: 'bold' }}>
                            {t('Class_other')}
                          </th>
                          <th style={{ minWidth: '50px', width: '50px', padding: '0px', fontWeight: 'bold' }}>%</th>
                        </tr>
                      </thead>

                      <tbody>
                        {classFrequency.map((student, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  color: student.overdue ? 'red' : '',
                                  padding: '0px 0px 0px 10px',
                                  minWidth: '150px',
                                  height: 'auto',
                                  position: 'sticky',
                                  left: '0px',
                                  zIndex: 1,
                                  backgroundColor: 'white',
                                }}
                              >
                                {student.name}
                                <span style={{ color: 'red' }} className='ml-2'>
                                  {student.deleted_at !== null ? '(saiu da turma)' : ''}
                                </span>
                              </td>
                              {daysMonth.map((day) => (
                                <td
                                  key={student.id + day}
                                  style={{ minWidth: '25px', width: '25px', padding: '0px', height: 'auto' }}
                                  className={`${
                                    student.presenceDays.some((dayStudent) => dayStudent.indexOf(day) === 0)
                                      ? 'check'
                                      : ''
                                  }`}
                                />
                              ))}
                              <td style={{ height: 'auto', minWidth: '50px', width: '50px', padding: '0px' }}>
                                {student.frequente}
                                <b className='table_day'>/{daysMonth.length}</b>
                              </td>
                              <td style={{ height: 'auto', minWidth: '50px', width: '50px', padding: '0px' }}>
                                <b className='table_day'>
                                  {daysMonth.length == 0
                                    ? 0
                                    : ((student.frequente / daysMonth.length) * 100).toFixed(0)}
                                  %
                                </b>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className='w-100 d-flex mt-5 flex-column flex-sm-row'>
                  <CardIndicatorSimple
                    doubleLine
                    isLoading={isLoading}
                    title={t('ClassFrequencyReportsPage.Indicators.TotalStudents')}
                    value={classFrequency.length === 0 ? '--' : classFrequency.length}
                  />
                  <CardIndicatorSimple
                    doubleLine
                    className='mx-sm-2'
                    isLoading={isLoading}
                    title={t('ClassFrequencyReportsPage.Indicators.TotalClasses')}
                    value={studentClasses.length}
                  />
                  <CardIndicatorSimple
                    doubleLine
                    isLoading={isLoading}
                    title={t('ClassFrequencyReportsPage.Indicators.StudentAttendanceRate')}
                    value={classFrequency.length === 0 ? '--' : calcStudentAttendanceRate()}
                  />
                </div>
                <div className='w-100 d-flex justify-content-center'>
                  <ButtonRounded className='mr-1' onClick={handlePrint}>
                    Imprimir
                  </ButtonRounded>
                  <ButtonRounded className='ml-1' onClick={exportToXLS}>
                    Exportar
                  </ButtonRounded>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default ClassFrequencyReports;
