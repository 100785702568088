import React, { useState, useEffect } from 'react'
import Loading from '../Loading'
import { Redirect } from 'react-router-dom'
import Api from '../../utils/api'
import axios from 'axios'

const Resultados = (props) => {
   let athlete_id = ''
   const [logo, setLogo] = useState([])
   const [resultados, setResultados] = useState([])
   const [sendingResultados, setResultadosSending] = useState(false)
   const [login, setLogin] = useState(false)
   const baseURL = Api()

   useEffect(() => {
      const athlete = JSON.parse(localStorage.getItem('athlete'))

      if ( athlete !== null )
         athlete_id = athlete.id
      else
         setLogin(true)

      getResultados()
   },[])

   const getResultados = async () => {
      setResultados([])
      setResultadosSending(true)

      const res = await axios.post(baseURL + 'get-results',{
         "athlete_id": athlete_id
      })
      
      if ( res.data.error === false ){
         setLogo(res.data.urlLogo)
         setResultados(res.data.data)
      }  

      setResultadosSending(false)
   }

   if ( login )
      return <Redirect to="/"></Redirect>

   return (
      <div className="seletivas bloco-vestiario" style={{marginBottom: '30px'}}>
         <div className="titulo-seletivas titulo-vestiario-pg">
            <h2>Últimas novidades</h2>
            <h3>Resultado de peneiras e eventos</h3>
            <p>Veja aqui o histórico dos clubes que querem saber mais sobre você.</p>
         </div>
         <div className="eventos-seletivas pg-vestiario">
            <ul className="quadro-vestiario">
               {
                  sendingResultados &&
                  <Loading type="1" />
               }
               {
                  resultados.map((item, i) => {
                     return (
                        <li className="dado-quadro-vestiario" key={"novidades" + i}>
                           <div className="col-1-vestiario">
                              {
                                 item.comlogo !== null &&
                                 <img src={logo + '/' +item.comlogo} />
                              }
                              <h3>{item.comcompanie}</h3>
                           </div>

                           <div className="col-2-vestiario">
                              <h4>{item.citcity}, {item.stastatesigla}</h4>
                              <p>{item.evedata}</p>
                           </div>

                           <div className="col-3-vestiario">
                              <a href="#" onClick={e => e.preventDefault(e)} style={{color: '#303030'}}>Status: {item.iteitem}</a>
                           </div>
                        </li>
                     )
                  })
               }
               
            </ul>
            {
               props.carregarMais && 
               <div className="btn-pg-vestiario">
                  <a href="#">Carregar mais</a>
               </div>
            }
           
         </div>
      </div>
   )
}

export default Resultados