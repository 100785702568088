import React from 'react';
import './chatListHeader.css';

export const ChatListHeader = ({ newMessagesCount = 0, myStatus = 'online agora', setCurrentRoute }) => {
  return (
    <div className='chat-list-header'>
      <div className='chat-header__left'>
        <button className='chat-header__left__back'>
          <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0.666626 27.3333V3.33329C0.666626 2.59996 0.927737 1.97218 1.44996 1.44996C1.97218 0.927737 2.59996 0.666626 3.33329 0.666626H24.6666C25.4 0.666626 26.0277 0.927737 26.55 1.44996C27.0722 1.97218 27.3333 2.59996 27.3333 3.33329V19.3333C27.3333 20.0666 27.0722 20.6944 26.55 21.2166C26.0277 21.7388 25.4 22 24.6666 22H5.99996L0.666626 27.3333ZM3.33329 20.9L4.89996 19.3333H24.6666V3.33329H3.33329V20.9Z'
              fill='var(--main-color)'
            />
          </svg>
        </button>
        <div className='chat-new__message'>
          <div className='message_box'>
            <span className='new-conversation'>Mensagens</span>
            {newMessagesCount > 0 && <span className='new-messages__number'>{newMessagesCount}</span>}
          </div>
          <span className='chat-my__status'>
            <span className={`chat-my__status__dot ${myStatus}`}></span>
            <span className='chat-my__status__text'>{myStatus === 'online' ? 'Online agora' : myStatus}</span>
          </span>
        </div>
      </div>
      <button className='chat-header__right' onClick={() => setCurrentRoute('/')}>
        <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <mask id='mask0_20_1179' maskUnits='userSpaceOnUse' x='0' y='0' width='32' height='32'>
            <rect width='32' height='32' fill='#D9D9D9' />
          </mask>
          <g mask='url(#mask0_20_1179)'>
            <path
              d='M18.6666 24L10.6666 16L18.6666 8L20.5333 9.86667L14.4 16L20.5333 22.1333L18.6666 24Z'
              fill='#1C1B1F'
            />
          </g>
        </svg>
      </button>
    </div>
  );
};
