import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-calendar/dist/Calendar.css';
import 'react-tooltip/dist/index.js';

import './style.css';

import moment from 'moment';
import 'moment/locale/pt-br';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import './i18nextConf';

import App from './App';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <ThemeProvider theme={createTheme({}, ptBR)}> */}
      <App />
      {/* </ThemeProvider> */}
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
