import React, { useState } from 'react';
import { auth, client, getAsaasConfigSessionSchool } from '../services/PaymentService';

function useAsaasClient(callback) {
  const schoolSession = JSON.parse(localStorage.getItem('school'));

  const companySession = JSON.parse(localStorage.getItem('company'));

  const usuSession = JSON.parse(localStorage.getItem('user'));

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState('');

  const [authAsaas, setAuthAsaas] = useState(null);

  const [clienteResponse, setClientResponse] = useState(null);

  const [parametersAsaas, setParametersAsaas] = useState(null);

  const handlerAuthAssa = async (configAsaas) =>
    await auth(configAsaas.cnpj, configAsaas.password, configAsaas.loginEmail);

  const handlerClientAssas = async (student, payload) => {
    const configAsaas = getAsaasConfigSessionSchool(schoolSession, companySession);
    try {
      setLoading(true);
      const client_asaas = await client(
        student.athCPFRespFin,
        student.athNomeRespFin + ` (${student.name})`,
        student.athEmailRespFin,
        student.athPhoneRespFin,
        configAsaas.system_id,
        authAsaas.user_id,
        configAsaas.token_assas,
        '',
        {
          postalCode: student.postalCode,
          address: student.address,
          addressNumber: student.addressNumber,
          province: student.province,
        }
      );

      if (client_asaas.status === 200 && typeof client_asaas.data.message === 'string') {
        const customer = client_asaas.data.data;
        return { payload, customer, configAsaas, usuSession, authAsaas };
      }

      if (client_asaas.status === 200 && Array.isArray(client_asaas.data.message)) {
        let message = [
          'Erro ao validar as informações do cliente.',
          ...client_asaas.data.message
            .map(({ field, message }) => {
              switch (field) {
                case 'email':
                  return `- Endereço de e-mail inválido: ${student.athEmailRespFin}`;

                case 'phone':
                  return `- Telefone inválido: ${student.athPhoneRespFin}`;

                case 'address':
                  return `- Endereço maior do que 55 caracteres: ${[
                    student.postalCode,
                    student.address,
                    student.addressNumber,
                    student.province,
                  ]
                    .filter((f) => f)
                    .join(', ')}`;

                default:
                  return `\n- ${message}`;
              }
            })
            .filter((f) => f),
        ].join('\n');

        throw new Error(message);
      }
      //nao temos um retorno 200
      throw new Error(client_asaas.data.message);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    try {
      const configAsaas = getAsaasConfigSessionSchool(schoolSession, companySession);
      (async () => {
        //Faz a autenticação na API do Asaas
        const auth_response = await handlerAuthAssa(configAsaas);

        if (auth_response.status !== 200) {
          throw new Error('StudentContractsPage.SystemMessage.CommunicationProblemServer');
        }

        if (auth_response.connection !== undefined) {
          throw new Error('Erro ao comunicar com o servidor.');
        }

        if (auth_response.data?.token === undefined) {
          throw new Error('Autenticação com a API inválida.');
        }
        auth_response.data.asass_conf_token = configAsaas.token_assas;
        setAuthAsaas(auth_response.data);
        setParametersAsaas(configAsaas);
      })();
    } catch (err) {
      setError('StudentContractsPage.SystemMessage.CommunicationProblemServer');
      console.log(err.message);
    }
  }, []);

  const handleOnSubmitClientPayment = React.useCallback(
    async (event, student, contract) => {
      event.preventDefault();

      try {
        const payload = await handlerClientAssas(student, contract);
        setClientResponse(payload);

        return payload;
      } catch (error) {
        setError(error.message);
      }
    },
    [authAsaas]
  );

  return { handleOnSubmitClientPayment, errorClient: error, authAsaas, clienteResponse, configAsaas: parametersAsaas };
}

export default useAsaasClient;
