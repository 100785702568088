import * as React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Trans, useTranslation } from 'react-i18next';
import ButtonRounded from '../components/buttons/ButtonRounded';
import useAsaasClient from '../hooks/useAsaasClient';
import useAsaasPayment from '../hooks/useAsaasPayment';

import '../utils/extendsType';

/**
 * ContractFaturarContextState usa os hooks useAsaasClient e useAsaasPayment para faturar um contrato
 * @typedef {Object} ContractFaturarContextState
 */
export const ContractFaturarContext = React.createContext();

const DATA_ASSAS = {
  billingType: '',
  customer: {},
  token_assas: '',
  split: [],
};

const descriptionCycle = {
  MONTHLY: 'mensal',
  QUARTERLY: 'trimestral',
  SEMIANNUALLY: 'semestral',
  YEARLY: 'anual',
};

const descriptionTypePayment = {
  CREDIT_CARD: 'cartão de crédito',
  BOLETO: 'boleto',
  MONEY: 'dinheiro',
};

const parseClientDataResponseContract = (contract, customer, usuSession, configAsaas) => {
  DATA_ASSAS.billingType = contract.payment;
  DATA_ASSAS.cycle = contract.cyclo;
  DATA_ASSAS.nextDueDate = contract.dateStart;
  DATA_ASSAS.value = contract.value;
  DATA_ASSAS.contract = contract.id;
  DATA_ASSAS.user_id_student = contract.user_id_student;

  DATA_ASSAS.customer = customer.customer;
  DATA_ASSAS.client_id = customer.id;

  DATA_ASSAS.token_assas = configAsaas.token_assas;
  DATA_ASSAS.user_id = usuSession.id;

  DATA_ASSAS.endDate = '';
  DATA_ASSAS.description = `Contrato com cobrança ${descriptionCycle[contract.cyclo]} por ${
    descriptionTypePayment[contract.payment]
  }`;
  if (configAsaas.percentual) {
    DATA_ASSAS.split = [
      {
        walletId: configAsaas.wallet,
        percentualValue: configAsaas.percentual,
      },
    ];
  }
  if (configAsaas.valor) {
    DATA_ASSAS.split = [
      {
        walletId: configAsaas.wallet,
        fixedValue: configAsaas.valor,
      },
    ];
  }
  // --------------------------------------------------------------------------------------------------------------------------------
  if (contract.discount) {
    DATA_ASSAS.discount = {
      value: contract.discount,
      dueDateLimitDays: 0,
      type: !contract.discount_type ? 'NONE' : contract.discount_type,
    };
  }

  if (contract.late_interest) {
    DATA_ASSAS.interest = {
      value: contract.late_interest,
      type: !contract.late_interest_type ? 'NONE' : contract.late_interest_type,
    };
  }

  if (contract.late_fee) {
    DATA_ASSAS.fine = {
      value: contract.late_fee,
      type: !contract.late_fee_type ? 'NONE' : contract.late_fee_type,
    };
  }

  return DATA_ASSAS;
};

export default function ({ children }) {
  const { t } = useTranslation();

  const alertRef = React.useRef(null);

  const btnLoadRef = React.useRef(null);

  const { handlerPayment, handlerRemovePayment, handlerRemovePaymentMoney, errorPayment, successPayment } =
    useAsaasPayment();

  const { handleOnSubmitClientPayment, errorClient, authAsaas, clienteResponse, configAsaas } = useAsaasClient();

  const [state, _setState] = React.useState({
    contract: { payment: 'MONEY' },
    student: {},
    faturar: false,
    cancelar_fatura: false,
    error: {
      show: false,
      message: '',
    },
    done: false,
    authAsaas: authAsaas || null,
    asassIntegration: false,
    successCallback: () => {},
  });
  // constructors -------------------------------------------------------------------------------------------------------------
  React.useEffect(() => {
    if (successPayment) {
      console.log('successPayment', successPayment);
      if (btnLoadRef.current) btnLoadRef.current.reset();
      //setDone(true);
      handlerCancelFaturar();
      //* dispara o callback do filho no sucesso do faturamento
      state.successCallback();
    }
  }, [successPayment]);

  React.useEffect(() => {
    if (errorPayment) {
      if (btnLoadRef.current) btnLoadRef.current.reset();
      faturarContext.setState({ error: { message: errorPayment, show: true } });
    }

    if (errorClient) {
      if (btnLoadRef.current) btnLoadRef.current.reset();
      faturarContext.setState({ error: { message: errorClient, show: true } });
    }
  }, [errorPayment, errorClient]);

  React.useEffect(() => {
    if (configAsaas) {
      faturarContext.setState({ asassIntegration: configAsaas.token_assas.length > 10 });
    }
  }, [configAsaas]);
  // private functions -------------------------------------------------------------------------------------------------------------
  const handlerConfirmFaturar = () => {
    faturarContext.setState({ faturar: true });
  };
  const handlerConfirmRemoverFatura = () => {
    faturarContext.setState({ cancelar_fatura: true });
  };

  const handlerCancelFaturar = () => {
    faturarContext.reset();
  };

  const handlerFaturar = async (e) => {
    e.preventDefault();
    e.persist();
    btnLoadRef.current = e.target.loader();

    btnLoadRef.current.start();

    const response = await handleOnSubmitClientPayment(
      e,
      {
        ...state.student,
        postalCode: state.student.athCep,
        address: state.student.athLogradouro,
        addressNumber: state.student.athNumero,
        province: state.student.athBairro,
        name: state.student.name,
      },
      state.contract
    );

    await handlerPayment(
      e,
      parseClientDataResponseContract(response.payload, response.customer, response.usuSession, response.configAsaas)
    );
  };

  const handlerRemoverFatura = async (e) => {
    e.preventDefault();
    e.persist();
    btnLoadRef.current = e.target.loader();

    btnLoadRef.current.start();

    state.contract.token_assas = authAsaas.asass_conf_token;

    console.log('state.contract.payment', state.contract);

    if (state.contract.payment === 'MONEY') {
      handlerRemovePaymentMoney(e, state.contract);
      return;
    }
    handlerRemovePayment(e, state.contract);
  };
  //public functions -------------------------------------------------------------------------------------------------------------
  const faturarContext = React.useMemo(
    () => ({
      handlerConfirmFaturar,
      handlerConfirmRemoverFatura,
      state,
      setState(state) {
        _setState((prevState) => ({ ...prevState, ...state }));
      },
      reset: () => {
        _setState({
          contract: { payment: 'MONEY' },
          student: {},
          faturar: false,
          cancelar_fatura: false,
          error: {
            show: false,
            message: '',
          },
          showLogo: false,
          done: false,
          authAsaas: authAsaas || null,
          asassIntegration: false,
          successCallback: () => {},
        });
      },
    }),
    [state]
  );
  return (
    <>
      <SweetAlert
        warning
        showCancel
        confirmBtnBsStyle='danger'
        onConfirm={handlerFaturar}
        onCancel={handlerCancelFaturar}
        focusCancelBtn
        ref={alertRef}
        show={state.faturar}
        title={t('Attention_exclamation')}
        customButtons={
          <span className='w-100 d-flex justify-content-around align-items-center'>
            <ButtonRounded variant='outline' color='var(--main-color)' isBig={true} onClick={handlerCancelFaturar}>
              {t('Cancel')}
            </ButtonRounded>
            <ButtonRounded isBig={true} onClick={handlerFaturar}>
              {t('InvoiceContract')}
            </ButtonRounded>
          </span>
        }
      >
        <Trans
          i18nKey={
            state.contract.payment === 'MONEY'
              ? 'StudentContractsPage.SystemMessage.WarningInvoiceContractMoney'
              : 'StudentContractsPage.SystemMessage.WarningInvoiceContract'
          }
        >
          <strong>a</strong>b
        </Trans>
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        confirmBtnBsStyle='danger'
        onConfirm={handlerRemoverFatura}
        onCancel={handlerCancelFaturar}
        focusCancelBtn
        ref={alertRef}
        show={state.cancelar_fatura}
        title={t('Attention_exclamation')}
        customButtons={
          <span className='w-100 d-flex justify-content-around align-items-center'>
            <ButtonRounded variant='outline' color='var(--main-color)' isBig={true} onClick={handlerCancelFaturar}>
              {t('Cancel')}
            </ButtonRounded>
            <ButtonRounded isBig={true} onClick={handlerRemoverFatura}>
              {t('Continue')}
            </ButtonRounded>
          </span>
        }
      >
        <Trans
          i18nKey={
            state.contract.payment === 'MONEY'
              ? 'StudentContractsPage.SystemMessage.WarningCancelInvoiceContractMoney'
              : 'StudentContractsPage.SystemMessage.WarningCancelInvoiceContract'
          }
        >
          <strong>a</strong>b
        </Trans>
      </SweetAlert>
      <SweetAlert
        show={state.error.show}
        danger
        title='Falha!'
        onConfirm={handlerCancelFaturar}
        customButtons={
          <ButtonRounded isBig={true} onClick={handlerCancelFaturar} className='w-100 m-4'>
            {t('Close')}
          </ButtonRounded>
        }
      >
        <p> {t(state.error.message)}</p>
      </SweetAlert>
      <ContractFaturarContext.Provider value={faturarContext}>
        {children}
        {/* {state.done === false && (
          <div className='container-fluid container_painel'>
            <div className='container'>
              {state.showLogo && (
                <div className='row d-flex align-items-center justify-content-center pt-1 pb-5'>
                  <img src='/img/clubes/fensor.svg' className='logo' alt='' />
                </div>
              )}
              {children}
            </div>
          </div>
        )} */}
      </ContractFaturarContext.Provider>
    </>
  );
}
