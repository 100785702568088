import React, { useEffect, useState } from 'react';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Logo from '../../components/school/Logo';
import Utils from '../../utils/utils';
import { Link } from 'react-router-dom';
import Api from '../../utils/api';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const EvaluationList = () => {
  const { t } = useTranslation();
  const [school, setSchool] = useState({});
  const [countEvaluation, setCountEvaluation] = useState(0);
  const [countStudents, setCountStudents] = useState(0);
  const [evaluations, setEvaluations] = useState([]);
  const [process, setProcess] = useState(false);
  const [dirFotoPrincipalStudent, setDirFotoPrincipalStudent] = useState('');

  const { formatCNPJCPF, formatTelefone, dateFormatText } = Utils();
  const baseURL = Api();

  useEffect(() => {
    async function getSession() {
      const schoolSession = JSON.parse(await localStorage.getItem('school'));
      setSchool(schoolSession);
      getStudentsEvaluations(schoolSession.id, schoolSession.companie_id);
    }

    getSession();
  }, []);

  const getStudentsEvaluations = async (school_id, company_id) => {
    setProcess(true);
    const resp = await axios.post(baseURL + 'get-evaluations-school', { school_id, company_id });
    setProcess(false);

    setCountStudents(resp.data.countStudents);
    setCountEvaluation(resp.data.countEvaluation);
    setDirFotoPrincipalStudent(resp.data.dirFotoPrincipalStudent);
    setEvaluations(resp.data.students_evaluations);
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='img_school'>
                    <Logo />
                  </div>
                  <h2 className='card_title mx-auto'>{school.schName}</h2>
                  <div className='content_card'>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('Localization')}: </p>
                      <p className='card_details_text'>{school.schLocalizacao}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('Email')}: </p>
                      <p className='card_details_text'>{school.schEmail}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('CellPhone')}: </p>
                      <p className='card_details_text'>{formatTelefone(String(school.schPhone))}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('CNPJ')}: </p>
                      <p className='card_details_text'>{formatCNPJCPF(String(school.schCnpj))} </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>{t('RegisteredStudents')}</h2>
                    <div className='sidebar_data'>{countStudents}</div>
                    <Link to='/student-school' className='btn_card'>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('SeeAll')}
                    </Link>
                  </div>
                  <hr />
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>{t('StudentsEvaluated')}</h2>
                    <div className='sidebar_data'>{countEvaluation}</div>
                    <Link to='/evaluation-list-school' className='btn_card'>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('SeeAll')}
                    </Link>
                  </div>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('AssessmentListPage.PageTitle')}</h2>
                    <p className='card_subtitle'>
                      {t('AssessmentListPage.PageSubtitle')}
                      {process && (
                        <React.Fragment>
                          <Loading type='2' style={{ color: 'green' }} />
                          <Loading type='2' style={{ color: 'green' }} />
                          <Loading type='2' style={{ color: 'green' }} />
                        </React.Fragment>
                      )}
                    </p>
                  </div>
                  <div className='card_avaliacao'>
                    <div className='avaliacao_list'>
                      <div className='avaliacao_table_header'>
                        <span>{t('School')}</span>
                        <span>{t('StudentName')}</span>
                        <span>{t('Average')}</span>
                      </div>
                      <div class='avaliacao_list'>
                        {evaluations.map((student) => {
                          return (
                            <Link
                              to={{
                                pathname: 'evaluation-detail',
                                state: {
                                  id: student.id,
                                  countStudents,
                                  countEvaluation,
                                  dirFotoPrincipalStudent,
                                  teacher: student.professor,
                                  school: student.school,
                                  student: student.student,
                                  position: student.athlete.posPosition,
                                  date: student.created_at.split(' ')[0],
                                  evaluation: student,
                                },
                              }}
                              className='avaliacao_table_row'
                              style={{ color: '#000' }}
                              key={student.id}
                            >
                              <span>{student.school.schName}</span>
                              <span>{student.student.name}</span>
                              <span style={{ color: '#C8102E' }}>{student.average}</span>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default EvaluationList;
