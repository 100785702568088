import React from 'react'
import Logoff from '../Logoff'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'react-bootstrap';

const footer = () => {

   let current    = ''
   let userLogado   = localStorage.getItem('user')
   
   if ( userLogado !== null ) {
      userLogado = JSON.parse(userLogado)
      current = window.location.origin + '/vestiario/' + userLogado.id
   }

   return (
      <footer className="rodape">
         <div className="conteudo-rodape">
            <ul className="menu-rodape">
               <div className="coluna-menu-rodape">
                  <li><a href="#">Central de ajuda</a></li>
                  <li><a href="/docs/TERMOS DE USO ATLETA - FENSOR FLASH.pdf" download="/docs/TERMOS DE USO ATLETA - FENSOR FLASH.pdf">Termos de uso</a></li>
                  <li><a href="/docs/POLÍTICA DE PRIVACIDADE - FENSOR FLASH.pdf" download="/docs/POLÍTICA DE PRIVACIDADE - FENSOR FLASH.pdf">Política de privacidade</a></li>
                  <li><a href="#curriculo">Compartilhe seu curriculo</a></li>
               </div>

               <div className="coluna-menu-rodape">
                  <li><Logoff /></li>
               </div>
            </ul>

            <div className="direitos-reservados">
               <p>Grupo Fensor. Todos os direitos reservados.</p>
            </div>
         </div>
         <div id="curriculo" className="overlay">
            <div className="popup"> 
               <h3>Compartilhe seu currículo</h3>
               <a className="close" href="#"><img src="../../img/icones/exit.png" /></a>
               <div  className="conteudo-popup popup-indique">
                  <div className="popup-form-indique">
                     <label>Link</label>
                     <input type="text" value={current} readOnly />
                     <br />
                     <CopyToClipboard text={current}>
                        <Button color="secundary">Clique aqui para copiar o link</Button>
                     </CopyToClipboard>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   )
}

export default footer