import Utils from './utils';

export const validateCPF = (cpf, language = 'pt-BR') => {
  const { validaCPF } = Utils();

  switch (language) {
    case 'pt-BR':
      return validaCPF(cpf);

    case 'en-US':
      return true;

    case 'en-US-Arabic':
      return true;

    default:
      return validaCPF(cpf);
    // return console.log('language :>> ', language);
  }
};

export const validateCNPJ = (cnpj, language = 'pt-BR') => {
  const { validaCNPJ } = Utils();

  switch (language) {
    case 'pt-BR':
      return validaCNPJ(cnpj);

    case 'en-US':
      return true;

    case 'en-US-Arabic':
      return true;

    default:
      return validaCNPJ(cnpj);
    // return console.log('language :>> ', language);
  }
};

export const validateRG = (rg, language = 'pt-BR') => {
  switch (language) {
    case 'pt-BR':
      return rg.length <= 32;

    case 'en-US':
      return rg.length <= 32;

    case 'en-US-Arabic':
      return rg.length <= 32;

    default:
      return rg.length <= 32;
    // return console.log('language :>> ', language);
  }
};

export const validatePhone = (number, language = 'pt-BR') => {
  console.log(number, language);
  switch (language) {
    case 'pt-BR':
      return number.length === 11 ? true : false;

    case 'en-US':
      return number.length < 10 ? false : true;

    case 'en-US-Arabic':
      return number.length < 10 ? false : true;

    default:
      return number.length === 11 ? true : false;
  }
};
