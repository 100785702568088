import React from 'react';

import { useHistory } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import ButtonRounded from '../../../components/buttons/ButtonRounded';
import { Skeleton } from '@material-ui/lab';
import { defaultCssStyleToPrint } from '../../../utils/utils';
import { useReactToPrint } from 'react-to-print';
import { base_url } from '../../../services/api';

const mapResults = (results) => {
  return Object.keys(results).map((key) => results[key]);
};

//component
const InvoiceRevenueNotification = ({ results, total }) => {
  const history = useHistory();

  const contentPrint = React.useRef();

  const handlePrint = useReactToPrint({
    documentTitle: `Resumo Cobranças - ${new Date().toLocaleDateString()}`,
    content: () => contentPrint.current,
    pageStyle: defaultCssStyleToPrint(),
  });

  const _progress = React.useCallback(() => (results.length / total) * 100, [results]);

  //const properties --------------------------------------------------------------------------------------
  const columnStudent = [
    {
      id: 'name',
      label: 'Atletas',
      align: 'left',
      format: (name, r) => (
        <div>
          {name}
          {r.status !== 'success' && (
            <p className='text-danger'>{r.info?.error || r.info || 'Houve um problema, a cobrança não foi gerada!'} </p>
          )}
        </div>
      ),
    },
    {
      id: 'status',
      align: 'right',
      label: () => <BtnPrint onClick={handlePrint} />,
      minWidth: 220,
      format: (status) => (
        <div className='d-flex justify-content-end'>
          {status === 'success' ? (
            <span className='text-success border border-success p-2 rounded text-center' style={{ minWidth: 250 }}>
              <BtnOk fcolor='#28a745' /> Sucesso!
            </span>
          ) : (
            <span className='text-danger border border-danger p-2 rounded text-center' style={{ minWidth: 250 }}>
              <BtnNegative fcolor='#dc3545' /> Não Faturado
            </span>
          )}
        </div>
      ),
    },
  ];

  const handleDownload = async (e) => {

    const btn = e.target.loader();
    try {
  
      btn.start();

      const response = await fetch(base_url + 'finacial/save-multi-invoice-revenue/+pdf', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          results.map((r) => ({
            error: r.info?.error || r.info,
            status: r.status,
            name: r.name,
            cpf: r.cpf,
            email: r.email,
          }))
        ),
      });

      if (!response.ok) {
        throw new Error('Erro ao obter o PDF');
      }

      const blob = await response.blob();

      // Cria um link temporário
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.target = '_blank';
      link.download = 'resumo-faturas.pdf';
      document.body.appendChild(link);

      // Clica no link para iniciar o download
      link.click();

      // Remove o link temporário
      document.body.removeChild(link);

      // Redireciona para a página anterior
      history.goBack();
    } catch (error) {
      console.error('Erro:', error);
      // Lide com o erro aqui
    } finally {
      btn.reset();
    }
  };

  return (
    <div className='card p-0 flex-column'>
      <div className='card_header w-100 flex-column align-items-center pt-3'>
        <h2 className='card_title'>Cobrança Concluída</h2>
        <BtnBack
          onClick={(e) => {
            history.push('/financial-school');
          }}
          style={{
            float: 'right',
            position: 'absolute',
            left: 35,
            marginTop: 5,
          }}
        />
      </div>
      <hr />
      <div className='card_body'>
        {results.length === 0 ? (
          <TableContainer style={{ maxHeight: 600, minHeight: 600 * 0.8 }}>
            <Table stickyHeader size='small' aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Atletas</StyledTableCell>
                  <StyledTableCell align='right'>
                    <BtnPrint onClick={handlePrint} />
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRowsLoader rowsNum={columnStudent.length} />
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <>
            <div className='row m-0'>
              <div className='col-md-11 p-0 mt-2'>
                <LinearProgress variant='determinate' value={_progress()} />
              </div>
              <div className='col-md-1 text-center small'>{Math.floor(_progress())}%</div>
            </div>
            <TableContainer style={{ maxHeight: 600, minHeight: 600 * 0.8 }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Atletas</StyledTableCell>
                    <StyledTableCell align='right'>
                      <BtnPrint onClick={handlePrint} />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mapResults(results).map((row, index) => {
                    const labelId = `status-${index}`;
                    return (
                      <TableRow hover role='checkbox' key={labelId} tabIndex={-1}>
                        {columnStudent.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {typeof column.format === 'function' ? column.format(value, row) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        <div className='row align-items-center justify-content-center my-5'>
          <div className='col-md-12 my-2 text-center'>
            <h6 className='text-danger'>
              {mapResults(results).some((r) => r.status === 'fail') &&
                '*As cobranças não faturadas, deverão ser corrigidos e faturadas manualmente.'}
            </h6>
          </div>
          <div className='col-md-5 col-sm-10'>
            <ButtonRounded
              isLoading={Math.floor(_progress()) < 100}
              onClick={handleDownload}
              className='w-100'
              isBig={true}
            >
              Finalizar
            </ButtonRounded>
          </div>
        </div>
      </div>
      {/* to print */}
      <div ref={contentPrint} className='d-none d-print-block'>
        {/*  <img src='/img/clubes/fensor.svg' className='logo' alt='' /> */}

        <div className='d-none d-print-block'>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th colSpan='2' className='text-center p-2'>
                  <h4>RESUMO FATURAS</h4>
                </th>
              </tr>
              <tr>
                <th className='p-3'>ATLETA</th>
                <th className='p-3'>SITUAÇÃO</th>
              </tr>
            </thead>
            <tbody>
              {mapResults(results).map((row, index) => {
                const labelId = `status-${index}`;
                return (
                  <tr key={labelId}>
                    <td className='p-3'>
                      {row.name}
                      <p className='small'>{row.status !== 'success' && 'Cobrança não gerada e não faturada!'}</p>
                    </td>
                    <td style={{ with: 320 }}>
                      {row.status === 'success' ? (
                        <span
                          className='text-success border border-success p-2 rounded text-center'
                          style={{ minWidth: 250 }}
                        >
                          <BtnOk fcolor='#28a745' /> Sucesso!
                        </span>
                      ) : (
                        <span
                          className='text-danger border border-danger p-2 rounded text-center'
                          style={{ minWidth: 250 }}
                        >
                          <BtnNegative fcolor='#dc3545' /> Não Faturado
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
//form states ----------------------------------------------------------------------------------------------

//material ui custom styles --------------------------------------------------------------------------------
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'var(--main-color)',
    color: theme.palette.common.white,
    border: 'none',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

//private components  --------------------------------------------------------------------------------------

const TableRowsLoader = ({ rowsNum }) => {
  return (
    <TableRow>
      {[...Array(rowsNum)].map((row, index) => (
        <TableCell component='th' scope='row' key={index}>
          <Skeleton animation='wave' variant='text' />
        </TableCell>
      ))}
    </TableRow>
  );
};

const BtnOk = ({ fcolor = '#1C1B1F', w = 24, h = 24 }) => (
  <svg width={w} height={h} fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
    <path
      fill={fcolor}
      d='M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z'
    ></path>
  </svg>
);
const BtnNegative = ({ fcolor = '#1C1B1F', w = 24, h = 24 }) => (
  <svg width={w} height={h} fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
    <path
      fill={fcolor}
      d='M22 4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h2V4zM2.17 11.12c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22L10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4h-8.1c-.71 0-1.36.37-1.72.97l-2.67 6.15z'
    ></path>
  </svg>
);
const BtnBack = ({ fcolor = '#1C1B1F', w = 16, h = 16, onClick, style }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer', ...style }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z' fill={fcolor} />
    </svg>
  </span>
);
const BtnPrint = ({ fcolor = '#ffff', w = 24, h = 24, onClick, style }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer', ...style }} role='button'>
    <svg width={w} height={h} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={fcolor}
        d='M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z'
      ></path>
    </svg>
  </span>
);

export default InvoiceRevenueNotification;
