import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import '../../../utils/extendsType';

import useForm from '../../../hooks/useForm';
import Message from '../../../components/Message';
import ButtonRounded from '../../../components/buttons/ButtonRounded';

import Mensal from '../../Students/Forms/Mensal';

import { standardizedContractPlan } from '../../../services/ContractStudentService';
import SweetAlert from 'react-bootstrap-sweetalert';
import StudentFormContext  from '../../../Context/StudentFormContext';

//component
const ContractForm = () => {
  //hooks states --------------------------------------------------------------------------------------
  const history = useHistory();

  const location = useLocation();

  const { t } = useTranslation();

  const formRefer = React.useRef();

  const alertRef = React.useRef();

  const [errors, setErrors] = React.useState([]);

  const [errorServer, setErrorsServe] = React.useState([]);

  const [school, setSchool] = React.useState({});

  const { MessageDangerMarginTop } = Message();

  const [isDone, setIsDone] = React.useState(false);

  const onSubmitFormMensal = (p, e, s, formData) => {
    if (e.target.dataset?.isdefault) {
      formData.set('default', 1);
    }
    if (formData.get('valorReceber') === '') {
      formData.set('valorReceber', p.valorOriginal);
    }

    formData.set('school_id', school.id);

    const btn = e.target.loader();

    btn.start();

    standardizedContractPlan(formData)
      .then((data) => {
        setIsDone(true);
      })
      .catch(handlerErrorServer)
      .finally(() => {
        btn.reset();
      });
  };

  const mensalForm = useForm(stateMensalSchema, validationStateSchema, onSubmitFormMensal, formRefer, setErrors);

  const handlerErrorServer = (err) => {
    if (err.response?.status === 422) {
      const { message = [] } = err.response.data;
      Object.keys(message).map((k) => {
        const element = document.querySelector(`[name=${k}]`);
        if (element) {
          mensalForm.dispatchHtmlValidity(element, `[${k}] ${message[k]}`);
        }
      });
    } else {
      setErrorsServe([err.toString()]);
    }
  };
  //constructor --------------------------------------------------------------------------------------

  React.useEffect(() => {
    const schoolSession = JSON.parse(localStorage.getItem('school'));

    const contract = location.state?.contract;

    if (contract) {
      mensalForm.setState({
        id: { value: contract.id, error: '' },
        name: { value: contract.name, error: '' },
        dataVencimento: { value: contract.date_start, error: '' },
        dataInicio: { value: contract.contract_date_start, error: '' },
        dataFim: { value: contract.date_end, error: '' },
        paymentMethod: { value: contract.payment, error: '' },
        paymentCycle: { value: contract.cycle, error: '' },
        service: { value: contract.id_service, error: '' },
        seguro: { value: '', error: '' },
        tipoDesconto: { value: contract.discount_type == null ? 'NONE' : contract.discount_type, error: '' },
        desconto: { value: contract.discount, error: '' },
        valor: { value: contract.value, error: '' },
        valorOriginal: { value: contract.original_value, error: '' },
        valorComSeguro: { value: contract.value, error: '' },
        tipoJuros: { value: contract.late_interest_type == null ? 'NONE' : contract.late_interest_type, error: '' },
        valorJuros: { value: contract.late_interest, error: '' },
        tipoMulta: { value: contract.late_fee_type == null ? 'NONE' : contract.late_fee_type, error: '' },
        valorMulta: { value: contract.late_fee, error: '' },
        statusContrato: { value: contract.status, error: '' },
        cargaHora: { value: contract.hours, error: '' },
        faturado: { value: 'N', error: '' },
        observacao: { value: contract.observation, error: '' },
        novoContrato: { value: 0, error: '' },
      });
    }

    setSchool(schoolSession);
  }, []);

  React.useEffect(() => {
    const elmain = document.querySelector('main');

    if ((errors.length || errorServer.length) && elmain) {
      elmain.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }
  }, [errors, errorServer]);

  return (
    <StudentFormContext>
      <SweetAlert
        ref={alertRef}
        show={isDone}
        success
        onConfirm={()=>{}}
        title='Pronto!'
        customButtons={
          <ButtonRounded isBig={true} onClick={() => history.push('/standardized-plans')} className='w-100 m-4'>
            Fechar
          </ButtonRounded>
        }
      >
        {mensalForm.state.id.value ? (
          <p> Plano de contrato atualizado com sucesso!</p>
        ) : (
          <p>Plano de contrato criado com sucesso!</p>
        )}
      </SweetAlert>
      <div className='card p-0 flex-column'>
        <div className='card_header w-100 flex-column align-items-center pt-3'>
          <h2 className='card_title'>Criar plano de contrato</h2>
          <BtnBack
            onClick={(e) => {
              history.push('/standardized-plans');
            }}
            style={{
              float: 'right',
              position: 'absolute',
              left: 35,
              marginTop: 5,
            }}
          />
        </div>
        <hr />
        {errors.map((err, k) => (
          <MessageDangerMarginTop
            key={`err-${k}`}
            title='Atenção!'
            description={t(`${err.error.replace(/\[.*?\]/g, '').trim()}`)}
          />
        ))}
        {errorServer.map((item, k) => (
          <MessageDangerMarginTop title='Atenção!' description={item} key={`error_${k}`} />
        ))}
        <form ref={formRefer} action='' className='form_card' noValidate>
          <div className='row p-3'>
            <div className='col col-sm-12 form_group'>
              <label className='card_details_label'>Nome do Plano *</label>
              <input
                type='text'
                required
                name='name'
                className='form_control form-control'
                value={mensalForm.state.name.value}
                onChange={mensalForm.handleOnChange}
              />
            </div>
            <Mensal useForm={mensalForm} />
          </div>
          <div className='row align-items-center justify-content-center my-5'>
            <div className='col-md-5 col-sm-10'>
              <ButtonRounded
                className='w-100'
                variant='outline'
                color='var(--main-color)'
                isBig={true}
                data-isdefault={1}
                isDisabled={mensalForm.disable && !mensalForm.state.id.value}
                onClick={mensalForm.handleOnSubmit}
              >
                Definir como padrão
              </ButtonRounded>
            </div>
            <div className='col-md-5 col-sm-10'>
              <ButtonRounded
                isDisabled={mensalForm.disable}
                onClick={mensalForm.handleOnSubmit}
                className='w-100'
                isBig={true}
              >
                Criar plano
              </ButtonRounded>
            </div>
          </div>
        </form>
      </div>
    </StudentFormContext>
  );
};

//form states ----------------------------------------------------------------------------------------------
const stateMensalSchema = {
  id: { value: 0, error: '' },
  name: { value: '', error: '' },
  dataVencimento: { value: '', error: '' },
  dataInicio: { value: moment().format('YYYY-MM-DD'), error: '' },
  dataFim: { value: '', error: '' },
  paymentMethod: { value: '', error: '' },
  paymentCycle: { value: 'MONTHLY', error: '' },
  valor: { value: '', error: '' },
  seguro: { value: '', error: '' },
  tipoDesconto: { value: 'NONE', error: '' },
  desconto: { value: '', error: '' },
  service: { value: '', error: '' },
  valorOriginal: { value: '', error: '' },
  valorComSeguro: { value: '', error: '' },
  tipoJuros: { value: 'NONE', error: '' },
  valorJuros: { value: '', error: '' },
  tipoMulta: { value: 'NONE', error: '' },
  valorMulta: { value: '', error: '' },
  statusContrato: { value: 'Ativo', error: '' },
  cargaHora: { value: '', error: '' },
  observacao: { value: '', error: '' },
  novoContrato: { value: 0, error: '' },
  faturado: { value: 'N', error: '' },
  anexo: { value: '', error: '' },
};

const validationStateSchema = {
  name: {
    required: true,
  },
};

//private components  --------------------------------------------------------------------------------------
const BtnBack = ({ fcolor = '#1C1B1F', w = 16, h = 16, onClick, style }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer', ...style }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z' fill={fcolor} />
    </svg>
  </span>
);

export default ContractForm;
