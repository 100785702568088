import React, { useEffect, useState, useRef } from 'react';
import InputMask from 'react-input-mask';
import { CirclePicker, SketchPicker } from 'react-color';

import Header from '../../components/club/Header';
import Footer from '../../components/club/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Utils from '../../../src/utils/utils';
import Logo from '../../components/school/Logo';
import { Link } from 'react-router-dom';
import Api, { uploadFolder } from '../../utils/api';
import axios from 'axios';
import { saveSchool, updateSchool } from '../../services/SchoolService';
import { getStates, verifyEmail } from '../../services/SharedService';
import { getAutentiqueCompanies } from '../../services/AutentiqueService';

const School = () => {
  const [id, setID] = useState(null);
  const [idUser, setIDUser] = useState(null);
  const [company, setCompany] = useState('');

  // campos modal
  const [schName, setSchName] = useState('');
  const [schFantasia, setSchFantasia] = useState('');
  const [schCnpj, setSchCnpj] = useState('');
  const [schCep, setSchCep] = useState('');
  const [schLogradouro, setSchLogradouro] = useState('');
  const [schNumero, setSchNumero] = useState('');
  const [schBairro, setSchBairro] = useState('');
  const [staStateSigla, setStaStateSigla] = useState('');
  const [city, setCity] = useState('');
  const [schPhone, setSchPhone] = useState('');
  const [schEmail, setSchEmail] = useState('');
  const [schResponsavel, setSchResponsavel] = useState('');
  const [schStatus, setSchStatus] = useState('I');
  const [schSobre, setSchSobre] = useState('');
  const [schUtilizaFaturamento, setSchUtilizaFaturamento] = useState('S');
  const [usuName, setUsuName] = useState('');
  const [usuEmail, setUsuEmail] = useState('');
  const [usuPhone, setUsuPhone] = useState('');
  const [defaultColor, setDefaultColor] = useState();
  const [logo, setLogo] = useState();
  const [mainBackground, setMainBackground] = useState();
  const [autentiqueCompany, setAutentiqueCompany] = useState(undefined);
  const [autentiqueCompanies, setAutentiqueCompanies] = useState([]);
  // filtros
  const [filterName, setFilterName] = useState('');
  //const [filterCNPJ, setFilterCNPJ] = useState("")
  const [filterGestor, setFilterGestor] = useState('');
  const [filterLocal, setFilterLocal] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterLocals, setFilterLocals] = useState([]);

  // carregamento
  const [schools, setSchools] = useState([]);
  const [schoolsFilter, setSchoolsFilter] = useState([]);
  const [states, setStates] = useState([]);
  const [citys, setCitys] = useState([]);

  // process
  const [process, setProcess] = useState(false);
  const [sendSave, setSendSave] = useState(false);
  const [sendCep, setSendingCep] = useState(false);
  const [sendState, setSendingState] = useState(false);
  const [sendCity, setSendingCity] = useState(false);
  const [isVisibleSketchPicker, setIsVisibleSketchPicker] = useState(false);
  const [schoolCustomColor, setSchoolCustomColor] = useState(false);
  const [hasNewLogo, setHasNewLogo] = useState(false);
  const [hasNewBackground, setHasNewBackground] = useState(false);
  const [isAwaitEmailCheck, setIsAwaitEmailCheck] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [display, setDisplay] = useState({});

  const [errors, setErrors] = useState([]);

  // libraries
  const baseURL = Api();
  const uploadURL = uploadFolder();
  const defaultCircleColors = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b',
  ];

  const { scrollTop, formatTelefone, formatCNPJ, replaceAll, formatCPF } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  let modalScroll = useRef();
  const formRefr = useRef();

  useEffect(() => {
    getAutentiqueCompanies().then(setAutentiqueCompanies);
  }, []);

  useEffect(() => {
    (async () => {
      const companySession = JSON.parse(await localStorage.getItem('company'));
      setCompany(companySession);
      getSchools(companySession.id);
      fetchStates();
    })();

    async function getSchools(companie_id) {
      setProcess(true);
      const res = await axios.post(baseURL + 'get-schools-club', {
        companie_id,
      });

      setSchools(res.data.schools);
      setSchoolsFilter(res.data.schools);
      setFilterLocals(res.data.locals);
      setProcess(false);
    }

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseURL]);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') closeAdd();
  };

  const fetchStates = async () => {
    setCitys([]);
    setSendingState(true);
    const res = await getStates();
    setSendingState(false);
    setStates(res.states);
  };

  const getCitys = async (staStateSigla, localidade = '') => {
    setCitys([]);
    setStaStateSigla(staStateSigla || '');

    if (staStateSigla) {
      setSendingCity(true);
      const res = await axios.post(baseURL + 'get-citys-by-state-sigla', { staStateSigla: staStateSigla });
      setSendingCity(false);

      for (let i = 0; i < res.data.cidades.length; i++) {
        let citCity = res.data.cidades[i].citCity;
        if (citCity == localidade) {
          setCity(res.data.cidades[i].citCity);
        }
      }

      setCitys(res.data.cidades);
    }
  };

  const search = (e) => {
    e.preventDefault();

    let registers = schools;

    if (filterName !== '') {
      registers = registers.filter((item) => {
        return item.schFantasia.toLowerCase().includes(filterName.toLowerCase());
      });
    }

    if (filterGestor !== '') {
      registers = registers.filter((item) => {
        if (item.schResponsavel) return item.schResponsavel.toLowerCase().indexOf(filterGestor) !== -1;
      });
    }

    if (filterLocal !== '') {
      registers = registers.filter((item) => {
        return item.schLocalizacao == filterLocal;
      });
    }

    if (filterStatus !== '') {
      registers = registers.filter((item) => {
        return item.schStatus == filterStatus;
      });
    }

    setSchoolsFilter(registers);
  };

  const openAdd = () => {
    clearMessage();
    setFalse();
    setAutentiqueCompany('');
    setSchName('');
    setSchFantasia('');
    setSchCnpj('');
    setSchCep('');
    setSchLogradouro('');
    setSchNumero('');
    setSchBairro('');
    setStaStateSigla('');
    setCity('');
    setSchPhone('');
    setSchEmail('');
    setSchResponsavel('');
    setSchStatus('I');
    setUsuName('');
    setUsuEmail('');
    setUsuPhone('');
    setSchSobre('');
    setSchUtilizaFaturamento('S');
    setIDUser(null);
    setID(null);
    setDefaultColor();
    setLogo();
    setMainBackground();
    setIsVisibleSketchPicker(false);
    setSchoolCustomColor(false);

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const closeAdd = () => {
    setDisplay({});
  };

  /* form validator */
  const handlerFormValidator = (event, form) /* boolean */ => {
    let valid = false;
    clearMessage();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      valid = true;
    }
    form.classList.add('was-validated');
    return valid && (!event.detail || event.detail === 1);
  };

  /* handler change para remover mensages de erro serverside */
  const handlerChange = (e, callback) => {
    if (errors[e.target.name]) {
      const novoObjeto = { ...errors };
      delete novoObjeto[e.target.name];
      setErrors(novoObjeto);
    }
    e.target.setCustomValidity('');
    callback(e.target.value);
  };

  /* proccess serverside errors */
  const processErrorServer = (errors) => {
    for (let i in errors) {
      const element = document.querySelectorAll(`[name="${i}"]`); //encotra elemento pela tag name

      if (element.length === 0) {
        setErrorMessage(errors[i][0]);
        continue;
      }
      for (let t in errors[i]) {
        element[0].setCustomValidity(errors[i][t]);
        //element.after(`<div class="invalid-tooltip">${errors[i][t]}</div>`)
      }
      // remove message if event key press
      element[0].addEventListener('keypress', function (e) {
        e.target.setCustomValidity('');
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (handlerFormValidator(e, e.target)) {
      if (defaultColor === undefined) {
        setErrorMessage('O campo "Cor padrão" é obrigatório');
        return null;
      }

      const school = {
        companie_id: company.id,
        schName,
        schFantasia,
        schUtilizaFaturamento,
        schCnpj: schCnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '').replaceAll('_', ''),
        schCep: schCep.replaceAll('-', ''),
        schLogradouro,
        schNumero,
        schBairro,
        staStateSigla,
        schLocalizacao: city,
        schPhone: schPhone
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll(' ', '')
          .replaceAll('-', '')
          .replaceAll('_', ''),
        schEmail,
        schResponsavel,
        schStatus,
        usuName,
        usuEmail,
        usuPhone: usuPhone
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll(' ', '')
          .replaceAll('-', '')
          .replaceAll('_', ''),
        schSobre,

        defaultColor: defaultColor,
        schLogomarca: logo,
        mainBackground: mainBackground,
        hasNewLogo: hasNewLogo instanceof File || hasNewLogo instanceof Blob ? hasNewLogo : null,
        hasNewBackground:
          hasNewBackground instanceof File || hasNewBackground instanceof Blob ? hasNewBackground : null,
        autentiqueCompanyId: autentiqueCompany,
        autentiqueCompanyToken:
          autentiqueCompany &&
          (autentiqueCompanies.find((company) => company.id === autentiqueCompany) || {}).tokens[0].token,
      };
      //sanatize for submit
      for (let item in school) {
        const _iobj = school[item];

        if (_iobj === undefined || _iobj === null) delete school[item];
      }

      setSendSave(true);
      const res = id === null ? await saveSchool({ ...school }) : await updateSchool({ id, idUser, ...school });
      setSendSave(false);

      if (!res.success) {
        if (res.type === 'error' && typeof res.msg === 'object') {
          setErrors(res.msg);
          processErrorServer(res.msg);
          scrollTop(modalScroll);
        } else {
          setErrorMessage(res.msg);
        }

        /* if (res.error?.type === 'crud') {
            setErrorMessage(res.error.msg);
          } else {
            setSchools(res.schools);
            setSchoolsFilter(res.schools);
            setFilterLocals(res.locals);
            setID(res.school.id);
            setIDUser(res.user.id);
    
            if (res.schLogomarca !== null && hasNewLogo !== false) {
              setLogo(res.schLogomarca.arquivo);
              setHasNewLogo(false);
            }
    
            if (res.mainBackground !== null && hasNewBackground !== false) {
              setMainBackground(res.mainBackground.arquivo);
              setHasNewBackground(false);
            }
    
            if (res.error?.type === 'validation') {
              setErrorMessage(res.error.msg);
            } else if (res.error.type === 'file') {
              if (res.error.data.erro) {
                const msgs = getErrorMessageFromFileResponse(res.error);
                setErrorMessage(msgs);
              } else {
                setErrorMessage(res.error.msg);
              }
            }
          } */
      } else {
        setSuccessMessage(id === null ? 'Escola criada com sucesso' : 'Escola atualizada com sucesso');

        setSchools(res.schools);
        setSchoolsFilter(res.schools);
        setFilterLocals(res.locals);
        setID(res.school.id);
        setIDUser(res.user.id);

        if (hasNewLogo) {
          setLogo(res.schLogomarca.arquivo);
          setHasNewLogo(false);
        }

        if (hasNewBackground) {
          setMainBackground(res.mainBackground.arquivo);
          setHasNewBackground(false);
        }
      }
    } else {
      //reset
      setSendSave(false);
      scrollTop(modalScroll);
    }
  };

  const edit = (e, item) => {
    e.preventDefault();
    clearMessage();
    setFalse();

    setSchName(item.name);
    setSchFantasia(item.schFantasia);
    setSchCnpj(item.cpf_cnpj);
    setSchCep(item.schCep);
    setSchLogradouro(item.schLogradouro);
    setSchNumero(item.schNumero);
    setSchBairro(item.schBairro);
    setSchPhone(item.schPhone);
    setSchEmail(item.email);
    setSchResponsavel(item.schResponsavel);
    setSchStatus(item.schStatus);
    setSchSobre(item.schSobre == null ? '' : item.schSobre);
    setSchUtilizaFaturamento(item.schUtilizaFaturamento);
    setUsuName(item.user.name);
    setUsuEmail(item.user.email);
    setUsuPhone(item.user.usuPhone);
    setIDUser(item.user.id);
    setID(item.id);
    setDefaultColor(item.defaultColor || undefined);
    setLogo(item.schLogomarca);
    setMainBackground(item.mainBackground);
    setAutentiqueCompany(item.autentiqueCompanyId || '')
    if (!defaultCircleColors.includes(item.defaultColor)) setSchoolCustomColor(true);

    console.dir(item);
    getCitys(item.schEstado, item.schLocalizacao);

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const setFalse = () => {
    setHasNewLogo(false);
    setHasNewBackground(false);
  };

  const buscaCEP = async () => {
    const cep = replaceAll(replaceAll(schCep, '-', ''), '_', '');

    if (cep.length == 8) {
      setSchLogradouro('');
      setSchBairro('');
      setSchNumero('');
      setStaStateSigla('');
      setCity('');

      setSendingCep(true);
      const res = await axios.get('https://viacep.com.br/ws/' + cep + '/json/');
      setSendingCep(false);

      if (res.data.erro) {
        setSchLogradouro('');
        setSchBairro('');
        setSchNumero('');
        setStaStateSigla('');
        setCity('');
      } else {
        setSchLogradouro(res.data.logradouro);
        setSchBairro(res.data.bairro);
        setSchNumero(res.data.complemento);
        setStaStateSigla(res.data.uf);
        getCitys(
          res.data.uf,
          res.data.localidade
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        );
      }
    }
  };

  /*   const handleVerifyEmail = async (email) => {
      if (email !== '' && email.includes('@')) {
        setIsAwaitEmailCheck(true);
        const { error, message } = await verifyEmail({ email, id: idUser });
        setIsAwaitEmailCheck(false);
  
        if (error) {
          setErrorMessage(message);
          setUsuEmail('');
        } else {
          clearMessage();
        }
      }
    }; */

  const toggleSketchPicker = () => {
    setIsVisibleSketchPicker((prev) => !prev);
  };

  const setErrorMessage = (message) => {
    setMessage(message);
    setError(true);
    scrollTop(modalScroll);
  };

  const setSuccessMessage = (message) => {
    setMessage(message);
    setSuccess(true);
    scrollTop(modalScroll);
  };

  const clearMessage = () => {
    setSuccess(false);
    setError(false);
    setMessage('');
    setErrors([]);
    formRefr.current.classList.remove('was-validated');
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Adicionar Escola</h2>
                    <p className='card_subtitle'>Clique abaixo para adicionar uma escola.</p>
                  </div>
                  <button onClick={openAdd} className='btn_card mx-auto mt-4 btn_open_modal' data-modal='#add_escola'>
                    <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                    </svg>
                    Adicionar escola
                  </button>
                </div>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Procurar</h2>
                    <p className='card_subtitle'>Filtrar as escolas cadastradas.</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        Nome da escola
                      </label>
                      <input type='text' className='form_control' onChange={(e) => setFilterName(e.target.value)} />
                    </div>
                    {/* <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        Gestor/Responsável
                      </label>
                      <input type='text' className='form_control' onChange={(e) => setFilterGestor(e.target.value)} />
                    </div> */}
                    {
                      // <div className="form_group">
                      //    <label htmlFor="name" className="card_details_label">CNPJ</label>
                      //    <input type="text" className="form_control" onChange={ e => setFilterCNPJ(e.target.value) } />
                      // </div>
                    }
                    <div className='form_group'>
                      <label htmlFor='position' className='card_details_label'>
                        Local
                      </label>
                      <select
                        className='form_control select'
                        value={filterLocal}
                        onChange={(e) => setFilterLocal(e.target.value)}
                      >
                        <option value=''>Todos</option>
                        {filterLocals.map((item, i) => (
                          <option value={item.schLocalizacao} key={i}>
                            {item.schLocalizacao}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='position' className='card_details_label'>
                        Status
                      </label>
                      <select
                        className='form_control select'
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value=''>todos</option>
                        <option value='A'>Ativa</option>
                        <option value='I'>Inativa</option>
                      </select>
                    </div>
                    <button className='btn_card mx-auto' onClick={search}>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      Buscar
                    </button>
                  </form>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Escolas</h2>
                    <p className='card_subtitle'>Veja abaixo as escolas cadastradas. </p>
                  </div>
                  <div className='list_card'>
                    {process && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!process &&
                      schoolsFilter.map((item) => {
                        return (
                          <div className='card_list' key={item.id}>
                            <div className='img_list_user'>
                              <Logo schLogomarca={item.schLogomarca} />
                            </div>
                            <div className='content_card'>
                              <div className='card_details'>
                                <p className='card_details_title' title={item.name}>
                                  {item.name.length > 20 ? item.schFantasia.substr(0, 20) + '...' : item.schFantasia}
                                </p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>{item.cpf_cnpj.length === 14 ? 'CNPJ: ' : 'CPF: '}</p>
                                <p className='card_details_text'>
                                  {item.cpf_cnpj.length === 14 ? formatCNPJ(item.cpf_cnpj) : formatCPF(item.cpf_cnpj)}
                                </p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Telefone: </p>
                                <p className='card_details_text'>{formatTelefone(item.schPhone)}</p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Local: </p>
                                <p className='card_details_text'>{item.schLocalizacao}</p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Alunos da escola: </p>
                                <p className='card_details_text'>{item.students}</p>
                              </div>
                            </div>
                            <Link
                              style={{ marginBottom: 10, fontWeight: 500 }}
                              to={{
                                pathname: '/detail-school',
                                state: {
                                  school: item,
                                },
                              }}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              Ver escola
                            </Link>
                            <span
                              style={{
                                backgroundColor: 'orange',
                                fontWeight: 500,
                                border: '1px solid orange',
                              }}
                              onClick={(e) => edit(e, item)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              Edit escola
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className='modal' id='add_escola' style={display}>
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeAdd}>
                    X
                  </div>
                  <h3 className='modal_title'>{id !== null ? 'editar escola' : 'adicionar escola'}</h3>
                  {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
                  {success && <MessageSuccessMarginTop title='Sucesso! ' description={message} />}

                  {Object.values(errors)
                    .flat()
                    .map((_msg, k) => (
                      <MessageDangerMarginTop title='Atenção! ' description={_msg} key={`error_${k}`} />
                    ))}
                  <form
                    ref={formRefr}
                    action=''
                    onSubmit={handleSubmit}
                    method='post'
                    className='form_modal'
                    noValidate
                  >
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Razão Social</label>
                        <input
                          required
                          type='text'
                          name='name'
                          value={schName}
                          onChange={(e) => handlerChange(e, setSchName)}
                          className='form-control form_control'
                        />
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Nome Fantasia</label>
                        <input
                          type='text'
                          name='name'
                          value={schFantasia}
                          onChange={(e) => handlerChange(e, setSchFantasia)}
                          className='form-control form_control'
                        />
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>CNPJ / CPF</label>
                        <InputMask
                          className='form-control form_control'
                          value={schCnpj}
                          required
                          mask={schCnpj.length > 11 ? '99.999.999/9999-99' : '999.999.999-99'}
                          name='schCnpj'
                          type='tel'
                          disableunderline='true'
                          onChange={(e) => handlerChange(e, setSchCnpj)}
                        ></InputMask>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label' style={{ flexDirection: 'row' }}>
                          CEP&nbsp;&nbsp;
                          <a
                            href='http://www.buscacep.correios.com.br/sistemas/buscacep/'
                            style={{ color: '#aaa' }}
                            target='blank'
                          >
                            (Consultar CEP)
                          </a>
                        </label>
                        {sendCep && <Loading type='1' style={{ marginTop: 10 }} />}
                        {!sendCep && (
                          <InputMask
                            className='form-control form_control'
                            required
                            value={schCep}
                            mask='99999-999'
                            name='schCep'
                            type='tel'
                            disableunderline='true'
                            onBlur={buscaCEP}
                            onChange={(e) => setSchCep(e.target.value)}
                          ></InputMask>
                        )}
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Endereço</label>
                        <input
                          type='text'
                          required
                          name='name'
                          value={schLogradouro}
                          onChange={(e) => setSchLogradouro(e.target.value)}
                          className='form-control form_control'
                        />
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Número ou complemento</label>
                        <input
                          type='text'
                          required
                          name='name'
                          value={schNumero}
                          onChange={(e) => setSchNumero(e.target.value)}
                          className='form-control form_control'
                        />
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Bairro</label>
                        <input
                          type='text'
                          required
                          name='name'
                          value={schBairro}
                          onChange={(e) => setSchBairro(e.target.value)}
                          className='form-control form_control'
                        />
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>
                          Estado
                          {sendState && <Loading type='1' style={{ marginLeft: '2%' }} />}
                        </label>
                        <select
                          required
                          name='state_id'
                          value={staStateSigla}
                          onChange={(e) => getCitys(e.target.value, '')}
                          className='form-control form_control select'
                        >
                          <option value=''>-- Todos os Estados --</option>
                          {states.map((item) => (
                            <option value={item.staStateSigla} key={item.staState}>
                              {item.staState}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>
                          Cidade
                          {sendCity && <Loading type='1' style={{ marginLeft: '2%' }} />}
                        </label>
                        <select
                          required
                          value={city}
                          name='city'
                          onChange={(e) => setCity(e.target.value)}
                          className='form-control form_control select'
                        >
                          <option value=''>-- Todos as Cidades --</option>
                          {citys.map((item) => (
                            <option value={item.citCity} key={item.citCity}>
                              {item.citCity}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Telefone</label>
                        <InputMask
                          className='form-control form_control'
                          required
                          value={schPhone}
                          type='tel'
                          mask='(99)99999-9999'
                          name='schPhone'
                          disableunderline='true'
                          onChange={(e) => setSchPhone(e.target.value)}
                        ></InputMask>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Email</label>
                        <input
                          type='email'
                          required
                          name='schEmail'
                          value={schEmail}
                          onChange={(e) => handlerChange(e, setSchEmail)}
                          className='form-control form_control'
                        />
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Responsável</label>
                        <input
                          type='text'
                          required
                          name='schResponsavel'
                          value={schResponsavel}
                          onChange={(e) => setSchResponsavel(e.target.value)}
                          className='form-control form_control'
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Status</label>
                        <select
                          value={schStatus}
                          required
                          name='schStatus'
                          className='form-control form_control select'
                          onChange={(e) => setSchStatus(e.target.value)}
                        >
                          <option value='A'>Ativa</option>
                          <option value='I'>Inativa</option>
                        </select>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Utiliza faturamento</label>
                        <select
                          value={schUtilizaFaturamento}
                          required
                          name='schUtilizaFaturamento'
                          className='form-control form_control select'
                          onChange={(e) => setSchUtilizaFaturamento(e.target.value)}
                        >
                          <option value='S'>Sim</option>
                          <option value='N'>Não</option>
                        </select>
                      </div>
                    </div>

                    <div className='form_group'>
                      <label className='card_details_label'>Sobre</label>
                      <textarea
                        type='text'
                        name='schSobre'
                        value={schSobre}
                        onChange={(e) => setSchSobre(e.target.value)}
                        className='form-control form_control'
                      />
                    </div>

                    <h3 className='modal_title' style={{ marginBottom: 10 }}>
                      Integrações
                    </h3>

                    <div className='form_group'>
                      <label htmlFor='tel' className='card_details_label'>
                        Selecione a conta Autentique
                      </label>
                      <select
                        required
                        value={autentiqueCompany || ''}
                        className='form_control select'
                        onChange={(e) => setAutentiqueCompany(e.target.value)}
                      >
                        <option>Nenhuma opção</option>
                        {autentiqueCompanies.map((company) => (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <h3 className='modal_title' style={{ marginBottom: 10 }}>
                      cadastro de usuário
                    </h3>

                    <div className='form_group'>
                      <label className='card_details_label'>Nome</label>
                      <input
                        type='text'
                        required
                        name='usuName'
                        value={usuName}
                        onChange={(e) => setUsuName(e.target.value)}
                        className='form-control form_control'
                      />
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>
                          Email
                          {/*  {isAwaitEmailCheck && <Loading type='1' style={{ marginLeft: '2%' }} />} */}
                        </label>
                        <input
                          type='email'
                          required
                          name='usuEmail'
                          value={usuEmail}
                          onChange={(e) => handlerChange(e, setUsuEmail)}
                          className='form-control form_control'
                          /* onBlur={(e) => handleVerifyEmail(e.target.value)} */
                        />
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Telefone</label>
                        <InputMask
                          className='form-control form_control'
                          required
                          value={usuPhone}
                          type='tel'
                          mask='(99)99999-9999'
                          name='usuPhone'
                          disableunderline='true'
                          onChange={(e) => setUsuPhone(e.target.value)}
                        ></InputMask>
                      </div>
                    </div>

                    <h3 className='modal_title' style={{ marginBottom: 10 }}>
                      Personalização
                    </h3>

                    <div className='form_group' />

                    <div className='group_50'>
                      <div className='form_group'>
                        <label style={{ marginBottom: '15px' }} className='card_details_label'>
                          Cor padrão
                        </label>
                        <CirclePicker
                          color={defaultColor}
                          onChange={(color) => {
                            setDefaultColor(color.hex);
                            setSchoolCustomColor(false);
                          }}
                        />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px' }}>
                          <span
                            style={{
                              height: '25px',
                              width: '25px',
                              borderRadius: '50%',
                              boxShadow: schoolCustomColor
                                ? `${defaultColor} 0px 0px 0px 3px inset`
                                : 'transparent 0px 0px 0px 3px inset',
                            }}
                          />
                          <small
                            className='on-hover on-hover-green'
                            onClick={toggleSketchPicker}
                            style={{ marginTop: '10px', marginLeft: '10px', fontWeight: 'bold', width: 'max-content' }}
                          >
                            Mais cores...
                          </small>
                        </div>
                        <div style={{ position: 'relative' }}>
                          <div
                            style={{
                              ...(isVisibleSketchPicker
                                ? { opacity: 1, pointerEvents: 'auto' }
                                : { opacity: 0, pointerEvents: 'none' }),
                              position: 'absolute',
                              bottom: 0,
                              left: '25%',
                              transition: 'all 0.2s ease-in-out 0s',
                            }}
                          >
                            <SketchPicker
                              color={defaultColor}
                              onChange={(color) => {
                                setDefaultColor(color.hex);
                                setSchoolCustomColor(true);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <div>
                            <div style={{ height: '126px', display: 'flex', justifyContent: 'center' }}>
                              {logo && !hasNewLogo && (
                                <a
                                  style={{ display: 'flex', justifyContent: 'center' }}
                                  href={`${uploadURL}upload/schools/logo/${logo}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <img src={`${uploadURL}upload/schools/logo/${logo}`} />
                                </a>
                              )}
                              {hasNewLogo && <img src={URL.createObjectURL(hasNewLogo)} />}
                              {!logo && !hasNewLogo && (
                                <div
                                  className='upload_file background-color-default'
                                  style={{ width: '100%', minHeight: '126px' }}
                                />
                              )}
                            </div>
                            <span
                              style={{
                                backgroundColor: '#AAAAAA',
                                fontWeight: 500,
                                border: '1px solid #AAAAAA',
                                height: '28px',
                                width: '190px',
                                minWidth: '150px',
                                marginTop: '10px',
                                fontSize: '0.8rem',
                              }}
                              className='btn_card'
                            >
                              Escolher logomarca
                              <input
                                type='file'
                                accept='.png, .jpg, .jpeg'
                                onChange={(e) => {
                                  setHasNewLogo(e.target.files[0]);
                                }}
                                style={{
                                  display: 'initial',
                                  opacity: 0,
                                  border: '1px solid #AAAAAA',
                                  height: '28px',
                                  width: '190px',
                                  minWidth: '150px',
                                  position: 'absolute',
                                  borderRadius: '50px',
                                  cursor: 'pointer',
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className='group_50'>
                        <div className='form_group'>
                          <div>
                            <div style={{ height: '126px', display: 'flex', justifyContent: 'center' }}>
                              {mainBackground && !hasNewBackground && (
                                <a
                                  style={{ display: 'flex', justifyContent: 'center' }}
                                  href={`${uploadURL}upload/schools/background/${mainBackground}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <img
                                    style={{ height: '100%' }}
                                    src={`${uploadURL}upload/schools/background/${mainBackground}`}
                                  />
                                </a>
                              )}
                              {hasNewBackground && <img src={URL.createObjectURL(hasNewBackground)} />}
                              {!mainBackground && !hasNewBackground && (
                                <div
                                  className='upload_file background-color-default'
                                  style={{ width: '100%', minHeight: '126px' }}
                                />
                              )}
                            </div>
                            <span
                              style={{
                                backgroundColor: '#AAAAAA',
                                fontWeight: 500,
                                border: '1px solid #AAAAAA',
                                height: '28px',
                                width: '190px',
                                minWidth: '150px',
                                marginTop: '10px',
                                fontSize: '0.8rem',
                              }}
                              className='btn_card'
                            >
                              Escolher plano de fundo
                              <input
                                type='file'
                                accept='.png, .jpg, .jpeg'
                                onChange={(e) => {
                                  setHasNewBackground(e.target.files[0]);
                                }}
                                style={{
                                  display: 'initial',
                                  opacity: 0,
                                  border: '1px solid #AAAAAA',
                                  height: '28px',
                                  width: '190px',
                                  minWidth: '150px',
                                  position: 'absolute',
                                  borderRadius: '50px',
                                  cursor: 'pointer',
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {!sendSave && (
                      <button className='btn_card mx-auto'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {id === null && 'Incluir'}
                        {id !== null && 'Alterar'} escola
                      </button>
                    )}
                    {sendSave && (
                      <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none' }} disabled>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        processando...
                        <Loading type='1' style={{ marginLeft: '2%' }} />
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default School;
