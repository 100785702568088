import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Message from '../../components/Message';
import Utils from '../../../src/utils/utils';
import { useTranslation } from 'react-i18next';

const Modal = ({
  show,
  handleClose,
  modalRef,
  message,
  errorMessage,
  successMessage,
  warningMessage,
  size = 'lg',
  modalStyle,
  modalContentStyle,
  modalContentClassName,
  title,
  titleClassName,
  children,
}) => {
  const { MessageSuccessMarginTop, MessageDangerMarginTop, MessageWarningMarginTop } = Message();
  const { t } = useTranslation();

  const sizes = {
    sm: '500px',
    lg: '800px',
  };

  return (
    <div className='modal' id='add_aluno' style={show ? { ...modalStyle, display: 'flex', opacity: 1 } : undefined}>
      <div
        className={`modal_content ${modalContentClassName ?? ''}`}
        ref={modalRef}
        style={{ ...modalContentStyle, ...(size ? { maxWidth: sizes[size] } : undefined) }}
      >
        <div className='modal_close' onClick={handleClose}>
          X
        </div>
        {title === undefined ? (
          ''
        ) : typeof title === 'string' ? (
          <h3 className={`modal_title ${titleClassName || ''}`}>{title}</h3>
        ) : (
          title
        )}
        {errorMessage && <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />}
        {successMessage && <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message)} />}
        {warningMessage && (
          <MessageWarningMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
        )}
        {children}
      </div>
    </div>
  );
};

const ModalWithMethodsAux = (props, ref) => {
  const modalRef = useRef(null);
  const { scrollTop } = Utils();
  const handleClose = () => setShow(false);

  const [show, setShow] = useState(false);

  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);

  const showErrorMessage = (message) => {
    setMessage(message);
    setErrorMessage(true);
    setSuccessMessage(false);
    setWarningMessage(false);
  };

  const showSuccessMessage = (message) => {
    setMessage(message);
    setErrorMessage(false);
    setSuccessMessage(true);
    setWarningMessage(false);
  };

  const showWarningMessage = (message) => {
    setMessage(message);
    setErrorMessage(false);
    setSuccessMessage(false);
    setWarningMessage(true);
  };

  useEffect(() => {
    if (show === true) {
      props.onShow && props.onShow();
      // ref.dispatchEvent(new Event('ModalWithMethodsAux-show'))
    } else if (show === false) {
      props.onClose && props.onClose();
      // ref.dispatchEvent(new Event('ModalWithMethodsAux-show'))
    }
  }, [show]);

  useImperativeHandle(ref, () => ({
    scrollTop: () => scrollTop(modalRef),
    showModal: () => setShow(true),
    closeModal: () => handleClose(),
    getStates: () => ({ show }),
    showErrorMessage,
    showSuccessMessage,
    showWarningMessage,

    showErrorMessageScrolling: (message) => {
      showErrorMessage(message);
      scrollTop(modalRef);
    },
    showSuccessMessageScrolling: (message) => {
      showSuccessMessage(message);
      scrollTop(modalRef);
    },
    showWarningMessageScrolling: (message) => {
      showWarningMessage(message);
      scrollTop(modalRef);
    },
    clearMessage: () => {
      setMessage('');
      setErrorMessage(false);
      setSuccessMessage(false);
      setWarningMessage(false);
    },
  }));

  return Modal({ show, handleClose, modalRef, message, errorMessage, successMessage, warningMessage, ...props });
};

export const ModalWithMethods = forwardRef(ModalWithMethodsAux);
export default Modal;
