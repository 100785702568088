import React from 'react';
import '../../../utils/extendsType';

import CobrancaFaturarContext from '../../../Context/CobrancaFaturarContext';
import InvoiceRevenueForm from './InvoiceRevenueForm';
import StudentFormContext from '../../../Context/StudentFormContext';

//component
const InvoiceRevenue = () => {
  //hooks states --------------------------------------------------------------------------------------

  return (
    <CobrancaFaturarContext>
      <StudentFormContext>
        <InvoiceRevenueForm />
      </StudentFormContext>
    </CobrancaFaturarContext>
  );
};

export default InvoiceRevenue;
