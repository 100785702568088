import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../Context/UserContext';
import HeaderMenu from '../menu/HeaderMenu/HeaderMenu';

const Header = () => {
  const { clearContextData, logout } = useContext(UserContext);
  const history = useHistory();

  const [displaySideBar, setDisplaySideBar] = useState('');
  const [opacitySideBar, setOpacitySideBar] = useState('');

  const clear = async (e) => {
    e.preventDefault();
    clearContextData();
    logout();
    history.push('/');
  };

  const openSideBar = (open) => {
    if (open) {
      setDisplaySideBar('flex');
      setOpacitySideBar(1);
    } else {
      setDisplaySideBar('');
      setOpacitySideBar(0);
    }
  };

  return (
    <header className='col-12' style={{ paddingLeft: 0 }}>
      <div className='row header' style={{ margin: 0, padding: 0 }}>
        <Link to={'home-admin'} className='logo_header'>
          <img src='../../../img/clubes/fensor.svg' className='logo' alt='' />
        </Link>
        <div className='options_header'>
          <HeaderMenu
            title='Configurações'
            icon={
              <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M15.8844 6.6182H15.0324C14.9074 6.11049 14.7247 5.62585 14.4913 5.17071L15.0523 4.60979C15.4882 4.17388 15.4882 3.4668 15.0523 3.03088L13.9701 1.94879C13.5341 1.51288 12.8269 1.51288 12.391 1.94879L11.9069 2.43278C11.4331 2.15393 10.9222 1.93148 10.383 1.77507V1.11671C10.383 0.500019 9.8829 0 9.26614 0H7.73514C7.11838 0 6.61831 0.500019 6.61831 1.11671V1.77507C6.07912 1.93212 5.56815 2.15393 5.09436 2.43278L4.61031 1.94879C4.17435 1.51288 3.46719 1.51288 3.03123 1.94879L1.94901 3.03088C1.51305 3.4668 1.51305 4.17388 1.94901 4.60979L2.50999 5.17071C2.27663 5.62521 2.09455 6.11049 1.96889 6.6182H1.11684C0.500076 6.6182 0 7.11822 0 7.73491V9.26573C0 9.88242 0.500076 10.3824 1.11684 10.3824H2.12211C2.27085 10.8248 2.46447 11.2459 2.6972 11.6415L1.94837 12.3902C1.51241 12.8261 1.51241 13.5332 1.94837 13.9691L3.03058 15.0512C3.46655 15.4871 4.17371 15.4871 4.60967 15.0512L5.43479 14.2262C5.80985 14.4185 6.20542 14.5762 6.61766 14.6961V15.8833C6.61766 16.5 7.11774 17 7.7345 17H9.2655C9.88226 17 10.3823 16.5 10.3823 15.8833V14.6961C10.7939 14.5762 11.1901 14.4185 11.5652 14.2262L12.3903 15.0512C12.8263 15.4871 13.5334 15.4871 13.9694 15.0512L15.0516 13.9691C15.4876 13.5332 15.4876 12.8261 15.0516 12.3902L14.3028 11.6415C14.5355 11.2459 14.7291 10.8241 14.8779 10.3824H15.8832C16.4999 10.3824 17 9.88242 17 9.26573V7.73491C17.0006 7.11758 16.5012 6.6182 15.8844 6.6182ZM8.50064 11.9299C6.45995 11.9299 4.80585 10.276 4.80585 8.23557C4.80585 6.19511 6.45995 4.5412 8.50064 4.5412C10.5413 4.5412 12.1954 6.19511 12.1954 8.23557C12.1954 10.2754 10.5413 11.9299 8.50064 11.9299Z' />
              </svg>
            }
            options={[
              {
                type: 'SPAN',
                icon: (
                  <svg width='12' height='12' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M20.625 0H3.375C1.51125 0 0 1.51125 0 3.375V10.875H13.41L11.955 9.42C11.5312 8.96625 11.5575 8.25375 12.0112 7.83C12.4425 7.42875 13.1137 7.42875 13.545 7.83L16.92 11.205C17.3587 11.6437 17.3587 12.3562 16.92 12.795L13.545 16.17C13.1062 16.6087 12.3937 16.6087 11.955 16.17C11.5162 15.7312 11.5162 15.0188 11.955 14.58L13.41 13.125H0V20.625C0 22.4888 1.51125 24 3.375 24H20.625C22.4888 24 24 22.4888 24 20.625V3.375C24 1.51125 22.4888 0 20.625 0Z' />
                  </svg>
                ),
                title: 'Desconectar',
                onClick: clear,
              },
            ]}
          />
        </div>
      </div>
      <button className='btn_menu_mobile' onClick={() => openSideBar(true)}>
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M20.75 18.25H3.25C1.87125 18.25 0.75 19.3713 0.75 20.75C0.75 22.1287 1.87125 23.25 3.25 23.25H20.75C22.1287 23.25 23.25 22.1287 23.25 20.75C23.25 19.3713 22.1287 18.25 20.75 18.25ZM20.75 9.5H3.25C1.87125 9.5 0.75 10.6213 0.75 12C0.75 13.3787 1.87125 14.5 3.25 14.5H20.75C22.1287 14.5 23.25 13.3787 23.25 12C23.25 10.6213 22.1287 9.5 20.75 9.5ZM20.75 0.75H3.25C1.87125 0.75 0.75 1.87125 0.75 3.25C0.75 4.62875 1.87125 5.75 3.25 5.75H20.75C22.1287 5.75 23.25 4.62875 23.25 3.25C23.25 1.87125 22.1287 0.75 20.75 0.75Z'
            fill='#FBFBFB'
          />
        </svg>
      </button>
      <nav
        className='nav_atleta'
        style={{
          display: displaySideBar,
          opacity: opacitySideBar == '' ? 1 : opacitySideBar,
        }}
      >
        <button className='btn_menu_mobile_close' onClick={() => openSideBar(false)}>
          <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0.731193 4.26672L13.1056 16.6411C14.0805 17.616 15.6662 17.616 16.6411 16.6411C17.616 15.6662 17.616 14.0805 16.6411 13.1056L4.26673 0.731192C3.2918 -0.243731 1.70612 -0.243731 0.731192 0.731193C-0.243731 1.70612 -0.243731 3.2918 0.731193 4.26672Z'
              fill='#FBFBFB'
            />
            <path
              d='M13.2928 0.918373L0.918388 13.2927C-0.0565354 14.2677 -0.0565354 15.8534 0.918388 16.8283C1.89331 17.8032 3.479 17.8032 4.45392 16.8283L16.8283 4.45391C17.8032 3.47898 17.8032 1.8933 16.8283 0.918373C15.8534 -0.0565502 14.2677 -0.0565502 13.2928 0.918373Z'
              fill='#FBFBFB'
            />
          </svg>
        </button>
        <Link
          key={`menu_home-admin`}
          className={'btn_panel ' + (window.location.pathname.substr(1) == 'home-admin' ? 'active' : '')}
          to={'/home-admin'}
        >
          Home
        </Link>
        <Link
          key={`menu_account-asaas`}
          className={'btn_panel ' + (window.location.pathname.substr(1) == 'account-asaas' ? 'active' : '')}
          to={'/account-asaas'}
        >
          Asaas
        </Link>
        <Link
          key={`menu_list-club`}
          className={'btn_panel ' + (window.location.pathname.substr(1) == 'list-club' ? 'active' : '')}
          to={'/list-club'}
        >
          Clube
        </Link>
        <Link
          key={`menu_list-courses`}
          className={'btn_panel ' + (window.location.pathname.substr(1) == 'list-courses' ? 'active' : '')}
          to={'/list-courses'}
        >
          Cursos
        </Link>
        <Link
          key={`menu_list-users`}
          className={'btn_panel ' + (window.location.pathname.substr(1) == 'list-users' ? 'active' : '')}
          to={'/list-users'}
        >
          Usuários
        </Link>
        <Link
          key={`menu_list-exercises`}
          className={'btn_panel ' + (window.location.pathname.substr(1) == 'list-exercises' ? 'active' : '')}
          to={'/list-exercises'}
        >
          Exercícios
        </Link>
        <Link
          key={`menu_list-sportscategories`}
          className={'btn_panel ' + (window.location.pathname.substr(1) == 'sports-categories' ? 'active' : '')}
          to={'/sports-categories'}
        >
          Modalidades
        </Link>
        <Link
          key={`menu_show_log`}
          className={'btn_panel ' + (window.location.pathname.substr(1) == 'show-logs' ? 'active' : '')}
          to={'/show-logs'}
        >
          Ver Logs
        </Link>
        <Link
          key={`menu_credits`}
          className={'btn_panel ' + (window.location.pathname.substr(1) == 'credits' ? 'active' : '')}
          to={'/credits'}
        >
          Créditos
        </Link>
        <Link
          key={`menu_metodology`}
          className={'btn_panel ' + (['admin-lesson', 'admin-planning', 'admin-microcycle'].includes(window.location.pathname.substr(1)) ? 'active' : '')}
          to={'/admin-lesson'}
        >
          Metodologia
        </Link>
      </nav>
      {/* </div> */}
    </header>
  );
};

export default Header;
