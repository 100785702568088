import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import 'react-tabs/style/react-tabs.css';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Utils from '../../../src/utils/utils';
import Api from '../../utils/api';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaFileInvoiceDollar } from 'react-icons/fa';

const FinancialStudent = () => {
  const { t, i18n } = useTranslation();
  // indexs
  const [school_id, setSchoolID] = useState('');
  const [user_id, setUserID] = useState('');

  // carregamentos
  const [financials, setFinancials] = useState([]);

  // filtros
  const [filterPayment, setFilterPayment] = useState('');
  const [filterDueDateIni, setFilterDueDateIni] = useState(new Date().toISOString().substr(0, 8) + '01');
  const [filterDueDateEnd, setFilterDueDateEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10)
  );

  // process
  const [process, setProcess] = useState(false);

  // outros
  const [valueD, setValueD] = useState(0);

  // libraries
  const baseURL = Api();
  const { dateFormatText } = Utils();

  useEffect(() => {
    async function getSession() {
      const usuSession = JSON.parse(await localStorage.getItem('user'));
      const schoolSession = JSON.parse(await localStorage.getItem('school'));

      setUserID(usuSession.id);
      setSchoolID(schoolSession.id);
      getFinancials(schoolSession.id, usuSession.id);
    }

    getSession();
  }, []);

  const getFinancials = async (school_id, user_id_student) => {
    setProcess(true);
    const res = await axios.post(baseURL + 'get-financials-student', {
      school_id,
      user_id_student,
      filterStudentClientFornecedor: '',
      filterType: 'Receita',
      filterPayment,
      filterStatus: '',
      filterDueDateIni,
      filterDueDateEnd,
    });

    setFinancials(res.data.financials);
    setValueD(res.data.valueD);
    setProcess(false);
  };

  const search = (e) => {
    e.preventDefault();
    getFinancials(school_id, user_id);
  };

  const openReceipt = (financial) => {
    const queries = new URLSearchParams({ language: i18n.resolvedLanguage, single: 1 });
    const url = `${Api()}financial/recibo/${financial.id}/.pdf?${queries}`;
    window.open(url, '_blank');
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('StudentFinancialPage.FilterCard.Title')}</h2>
                    <p className='card_subtitle'>{t('StudentFinancialPage.FilterCard.Subtitle')}</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='schedule' className='card_details_label'>
                        {t('PaymentMethods')}
                      </label>
                      <select className='form_control select' value={filterPayment} onChange={(e) => setFilterPayment(e.target.value)}>
                        <option value=''>{t('All')}</option>
                        <option value='Cartão'>{t('Card')}</option>
                        <option value='Boleto'>{t('Bill')}</option>
                        <option value='Dinheiro'>{t('Money')}</option>
                      </select>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                      <div className='group_50' style={{ width: '-webkit-calc(52% - 15px)' }}>
                        <div className='form_group'>
                          <label htmlFor='schedule' className='card_details_label'>
                            {t('InitialOverdue')}
                          </label>
                          <input
                            type='date'
                            className='form_control'
                            value={filterDueDateIni}
                            onChange={(e) => setFilterDueDateIni(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='group_50' style={{ width: '-webkit-calc(52% - 15px)' }}>
                        <div className='form_group'>
                          <label htmlFor='schedule' className='card_details_label'>
                            {t('EndOverdue')}
                          </label>
                          <input
                            type='date'
                            className='form_control'
                            value={filterDueDateEnd}
                            onChange={(e) => setFilterDueDateEnd(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {process && (
                      <button className='btn_card mx-auto' style={{ background: '#fdcc06', color: '#1c842c' }}>
                        <Loading type='1' />
                      </button>
                    )}
                    {!process && (
                      <button className='btn_card mx-auto' onClick={search}>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('Search')}
                      </button>
                    )}
                  </form>
                </div>
                <div className='card'>
                  <div className='card_header mx-auto' style={{ alignItems: 'center' }}>
                    <h2 className='card_title'>{t('TotalPayment')}</h2>
                    <div className='sidebar_data'>{t('FormatFunction.intlCurrencyWithOptions', { value: valueD })}</div>
                  </div>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('StudentFinancialPage.PageTitle')}</h2>
                    <p className='card_subtitle'>{t('StudentFinancialPage.PageSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    {process && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {
                      <Table style={{ boxShadow: 'none', marginTop: '20px' }}>
                        <thead>
                          <tr style={{ marginBottom: '5px' }}>
                            <th style={{ border: 'none', fontSize: '13px', color: '#3b3b3b' }}>{t('Description')}</th>
                            <th style={{ border: 'none', fontSize: '13px', color: '#3b3b3b' }}>{t('DueDate_abbreviated')}</th>
                            <th style={{ border: 'none', fontSize: '13px', color: '#3b3b3b', width: '70px' }}>{t('Installment')}</th>
                            <th style={{ border: 'none', fontSize: '13px', color: '#3b3b3b' }}>{t('Value')}</th>
                            <th style={{ border: 'none', fontSize: '13px', color: '#3b3b3b' }}>{t('Paid')}</th>
                            <th style={{ border: 'none', fontSize: '13px', color: '#3b3b3b', padding: '0px 5px' }}>2° Via</th>
                            <th style={{ border: 'none', fontSize: '13px', color: '#3b3b3b', padding: '0px 5px' }}>Recibo</th>
                          </tr>
                        </thead>
                        <tbody>
                          {financials.length == 0 && (
                            <tr
                              style={{
                                marginBottom: '12px',
                                boxShadow: 'rgba(0, 0, 0, 0.05) 1px -2px 13px 1px',
                                borderRadius: '10px',
                                height: '55px',
                              }}
                            >
                              <td colSpan='5' style={{ paddingTop: 20 }}>
                                {t('StudentFinancialPage.EmptyTable')}
                              </td>
                            </tr>
                          )}
                          {financials.map((item, i) => {
                            return (
                              item.type == 'Receita' && (
                                <tr
                                  key={i}
                                  style={{
                                    marginBottom: '12px',
                                    boxShadow: 'rgba(0, 0, 0, 0.05) 1px -2px 13px 1px',
                                    borderRadius: '10px',
                                    height: '55px',
                                  }}
                                >
                                  <td style={{ alignItems: 'center', height: '100%', border: 'none' }}>{item.countDescription}</td>
                                  <td style={{ alignItems: 'center', height: '100%', border: 'none' }}>{dateFormatText(item.dueDate)}</td>
                                  <td style={{ alignItems: 'center', height: '100%', border: 'none', width: '70px' }}>{item.quota}</td>
                                  <td style={{ alignItems: 'center', height: '100%', border: 'none' }}>
                                    {t('FormatFunction.intlCurrencyWithOptions', { value: item.value })}
                                  </td>
                                  <td
                                    style={{
                                      alignItems: 'center',
                                      height: '100%',
                                      border: 'none',
                                      color: item.datePayment === null ? '#dc3545' : '',
                                    }}
                                  >
                                    {item.datePayment !== null ? t('Yes') : t('Not')}
                                  </td>
                                  <td
                                    style={{
                                      alignItems: 'center',
                                      height: '100%',
                                      border: 'none',
                                      padding: '0px 5px',
                                      fontSize: '12pt',
                                    }}
                                  >
                                    {item.payment_type !== 'MONEY' && !!item.payment_bankSlipUrl && (
                                      <a
                                        className='link-color-custom'
                                        style={{ '--link-color': 'grey' }}
                                        href={item.payment_bankSlipUrl}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                      >
                                        <FaFileInvoiceDollar title='Abrir link de pagamento' style={{ cursor: 'pointer' }} />
                                      </a>
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      alignItems: 'center',
                                      height: '100%',
                                      border: 'none',
                                      padding: '0px 5px',
                                      fontSize: '12pt',
                                    }}
                                  >
                                    {item.received === 'S' && item.datePayment !== null && (
                                      <a
                                        className='link-color-custom'
                                        style={{ '--link-color': 'grey' }}
                                        href={item.payment_bankSlipUrl}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                      >
                                        <span
                                          className='lowercase'
                                          onClick={() => openReceipt(item)}
                                          style={{
                                            cursor: 'pointer',
                                            background: 'green',
                                            fontWeight: 400,
                                            padding: '3px 10px 3px 10px',
                                            color: 'white',
                                            borderRadius: '20px',
                                            width: '68px',
                                            fontSize: '12px',
                                          }}
                                        >
                                          {t('Receipt')}
                                        </span>
                                      </a>
                                    )}
                                  </td>
                                </tr>
                              )
                            );
                          })}
                        </tbody>
                      </Table>
                    }
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default FinancialStudent;
