import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import Header from '../../../components/school/Header';
import Footer from '../../../components/school/Footer';
import { useTranslation } from 'react-i18next';
import EditLesson from './EditLesson';
import {
  createLessonSchool,
  getAllLessonsSchool,
  updateLessonSchool,
  updateSchoolStatus
} from '../../../services/LessonPlanningService';
import Message from '../../../components/Message';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import Loading from '../../../components/Loading';
import { useReactToPrint } from 'react-to-print';
import ViewLesson from './ViewLesson';
import { fetchLessonSession } from '../../../services/LessonSession';
import { Link, useHistory } from 'react-router-dom';
import { handleOnChange } from '../../../utils/utils';
import { BsChevronDown, BsDownload, BsEye, BsFillPencilFill } from "react-icons/bs";
import { UserContext } from '../../../Context/UserContext';

const Lessons = () => {
  const { t } = useTranslation();
  const { action, setContextData } = useContext(UserContext);
  const history = useHistory();

  const [filters, setFilters] = useState({
    concept: '',
    source: 'T',
    status: 'A',
  });
  // carregamento
  const [lesson, setLesson] = useState({
    id: undefined,
    status: '',
    description: '',
    videoURL: '',
    concept: '',
    space: '',
    time: '',
    lesson_session_id: 0,
    numberOfPlayers: null,
    rules: '',
    score: '',
    variation: '',
    feedback: '',
    artUrl: '',
    category_id: 0,
    content_sport_id: 0,
    didactic_strategy_id: 0,
    material_id: 0,
  });
  const [lessons, setLessons] = useState([]);
  const [lessonsFiltered, setLessonsFiltered] = useState([]);
  const [dropDownStatus, setDropDownStatus] = useState([]);
  const [sessions, setSessions] = useState([]);

  // process
  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  // libraries
  const editLessonRef = useRef();
  const viewLessonRef = useRef();
  const alertRef = useRef();
  const lessonPdfRef = useRef();

  const ref = useOutsideClick(() => {
    setDropDownStatus(dropDownStatus?.map((value) => ({ ...value, status: false })));
  });

  const { MessageDangerMarginTop } = Message();

  useEffect(() => {
    if (action?.action === 'search') {
      setFilters({
        concept: action.term,
        status: action.status
      });
      fetchLessons().then((response) => {
        setLessonsFiltered(
          response?.filter((lesson) => action?.status === 'T' || lesson.status === action?.status)
            .filter((lesson) => lesson.concept?.toUpperCase()?.includes(action?.term?.toUpperCase())));
      }).catch((e) => console.log(e));
    } else {
      fetchLessons();
    }
    getLessonSession();
    action?.action === 'openAdd' && openAddLesson();
    setContextData({ action: {} });

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const search = (e) => {
    e && e.preventDefault();

    if (filters.source === 'A' || filters.source === 'M') {
      setContextData({
        action: {
          action: 'search',
          term: filters.name,
          status: filters.status
        }
      })
      filters.source === 'A' ? history.push('/school-lessons-planning', { replace: true }) : history.push('/school-microcycle', { replace: true });
      return;
    }
    setLessonsFiltered(
      lessons.filter((lesson) => filters.status === 'T' || lesson.status === filters.status)
        .filter((lesson) => lesson.concept?.toUpperCase()?.includes(filters.concept.toUpperCase())));
  };

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      editLessonRef.current.closeModal();
    }
  };

  const fetchLessons = async () => {
    clearMessage();
    setIsLoading(true);
    const { status, lessons, messageKey } = await getAllLessonsSchool();
    setIsLoading(false);

    if (status === 'SUCCESS') {
      setLessons(lessons);
      setLessonsFiltered(lessons);
      setDropDownStatus(lessons?.map((lesson) => ({ id: lesson.id, status: false })));
      return lessons;
    } else {
      setErrorMessage(messageKey);
    }
  };

  const getLessonSession = async () => {
    const response = await fetchLessonSession();
    setSessions(response.sessions)
  }

  const handleSubmitLesson = async (id, formData) => {
    clearMessage();
    setIsLoading(true);

    const {
      messageKey,
      status,
      lessons,
    } = id ? await updateLessonSchool(formData) : await createLessonSchool(formData);
    setIsLoading(false);

    if (status === 'SUCCESS') {
      setLessons(lessons);
      setLessonsFiltered(lessons);
      return {
        messageKey,
        status,
      };
    } else {
      return {
        messageKey,
        status,
      };
    }
  };

  const openAddLesson = () => {
    setLesson({
      id: undefined,
      status: '',
      description: '',
      videoURL: '',
      concept: '',
      space: '',
      time: '',
      lesson_session_id: 0,
      numberOfPlayers: null,
      rules: '',
      score: '',
      variation: '',
      feedback: '',
      artUrl: '',
      category_id: 0,
      content_sport_id: 0,
      didactic_strategy_id: 0,
      material_id: 0,
    });
    editLessonRef.current.showModal();
  };

  const viewLesson = (lesson) => {
    configLesson(lesson);
    viewLessonRef.current.showModal();
  };

  const openEditLesson = (lesson) => {
    configLesson(lesson);
    editLessonRef.current.showModal();
  };

  const setErrorMessage = (message) => {
    setMessage(message);
    setError(true);
  };

  const clearMessage = () => {
    setError(false);
    setMessage('');
  };

  const configLesson = (lesson) => {
    setLesson({
      id: lesson.id,
      status: lesson.status,
      description: lesson.description,
      videoURL: lesson.videoURL,
      concept: lesson.concept,
      space: lesson.space,
      time: lesson.time,
      lesson_session_id: lesson.lesson_session_id,
      numberOfPlayers: lesson.numberOfPlayers,
      rules: lesson.rules,
      score: lesson.score,
      variation: lesson.variation,
      feedback: lesson.feedback,
      artUrl: lesson.artUrl,
      category_id: lesson?.lesson_categories[0]?.id,
      content_sport_id: lesson?.lesson_content_sports[0]?.id,
      didactic_strategy_id: lesson?.lesson_didactic_strategies[0]?.id,
      material_id: lesson?.lesson_materials[0]?.id,
      categories: lesson?.lesson_categories,
      contents: lesson?.lesson_content_sports,
      strategies: lesson?.lesson_didactic_strategies,
      materials: lesson?.lesson_materials,
    });
  }

  const toPdf = (lesson) => {
    Promise.resolve(configLesson(lesson)).then(() => {
      handlePrint();
    })
  }

  const handlePrint = useReactToPrint({
    content: () => lessonPdfRef.current
  })

  const updateByStatus = async (values) => {
    const data = {
      id: values.id,
      status: values.status,
    }

    const {
      status,
      lessons,
      message,
    } = await updateSchoolStatus(data);

    if (status === 'SUCCESS') {
      setLessons(lessons);
      setLessonsFiltered(lessons);
      setDropDownStatus(lessons?.map((lesson) => ({ id: lesson.id, status: false })));
    } else {
      setErrorMessage(message);
    }
  }

  const onChangeFilters = handleOnChange(setFilters);

  const categoriesSelectedList = useMemo(() => {
    return (
      <div className='d-flex align-items-center'>
        {lesson?.categories?.sort((a, b) => a.name - b.name)?.map((category) => {
          return (
            <div key={category?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span>{category?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [lesson?.categories])
  const contentSportsSelectedList = useMemo(() => {
    return (
      <div className='d-flex align-items-center'>
        {lesson?.contents?.sort((a, b) => a - b)?.map((contentSport) => {
          return (
            <div key={contentSport?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span>{contentSport?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [lesson?.contents])
  const didacticStrategySelectList = useMemo(() => {
    return (
      <div className='d-flex align-items-center'>
        {lesson?.strategies?.sort((a, b) => a - b)?.map((didactic) => {
          return (
            <div key={didactic?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span>{didactic?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [lesson?.strategies])
  const materialsSelectedList = useMemo(() => {
    return (
      <div className='d-flex align-items-center'>
        {lesson?.materials?.sort((a, b) => a - b)?.map((material) => {
          return (
            <div key={material?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span>{material?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [lesson?.materials])
  const lessonSessionLabel = useMemo(() => {
    return sessions?.find((session) => session.id === lesson?.lesson_session_id)?.name
  }, [lesson, sessions])

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <Header />
            <main className='content_panel'>
              <div className='d-flex flex-row flex-1'>
                <div className='d-flex flex-column card mr-3'>
                  <div className='card_header'>
                    <h2 className='card_title'>Tarefas</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar tarefa.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'school-lessons'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={openAddLesson} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-column card  mr-3'>
                  <div className='card_header'>
                    <h2 className='card_title'>Aulas</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar uma aula.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'school-lessons-planning'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={() => {
                      setContextData({
                        action: {
                          action: 'openAdd'
                        }
                      })
                      history.push('/school-lessons-planning', { replace: true });
                    }} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-column card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Microciclo</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar um microciclo.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'school-microcycle'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={() => {
                      setContextData({
                        action: {
                          action: 'openAdd'
                        }
                      })
                      history.push('/school-microcycle', { replace: true });
                    }} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card_header'>
                  <h2 className='card_title'>Pesquisar</h2>
                  <p className='card_subtitle'>Busque abaixo por tarefas, aulas ou microciclos.</p>
                </div>
                <form action='' method='post' className='form_card'>
                  <div className='d-flex flex-row align-items-center row'>
                    <div className='col-sm-3'>
                      <input
                        required
                        type='text'
                        className='form_control mr-3'
                        name='concept'
                        placeholder='Conceito'
                        value={filters.concept}
                        onChange={onChangeFilters}
                      />
                    </div>
                    <div className='col-sm-3'>
                      <select
                        required
                        className='form_control select mr-3'
                        name='source'
                        value={filters.source}
                        onChange={onChangeFilters}
                      >
                        <option disabled={true}>Selecionar</option>
                        <option value='T'>Tarefas</option>
                        <option value='A'>Aulas</option>
                        <option value='M'>Microciclo</option>
                      </select>
                    </div>
                    <div className='col-sm-3'>
                      <select
                        required
                        className='form_control select mr-3'
                        name='status'
                        value={filters.status}
                        onChange={onChangeFilters}
                      >
                        <option disabled={true}>Status</option>
                        <option value='T'>Tudo</option>
                        <option value='A'>Ativo</option>
                        <option value='I'>Inativo</option>
                      </select>
                    </div>
                    <div className='col-sm-3 my-auto'>
                      <button className='btn-secondary-color rounded-pill' onClick={search}>
                        Buscar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Tarefas</h2>
                    <p className='card_subtitle'>Veja abaixo as tarefas cadastradas</p>
                    {error && <MessageDangerMarginTop ref={alertRef} title='Atenção! ' description={t(message)} />}
                  </div>
                  <div className='list_card'>
                    {isLoading && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!isLoading &&
                      lessonsFiltered.map((lesson) => {
                        const status = dropDownStatus?.find((value) => value.id === lesson.id && value.status);
                        return (
                          <div className='card_list_turma' key={`${lesson.id}`}>
                            <div className='content_card'>
                              <div className='card_details_alternative'>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <p className='card_details_label text_uppercase my-auto' style={{ width: '240px' }}>
                                    <span style={{ color: '#3b3b3b' }}>{lesson.concept}</span>
                                  </p>
                                  <div ref={ref} style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '120px' }}>
                                    <div
                                      onClick={() => setDropDownStatus(dropDownStatus?.map((value) => (value.id === lesson.id ? { ...value, status: !value.status } : { ...value })))}
                                      className={`${lesson.status === 'A' ? 'badge badge-success' : 'badge badge-danger'} badge-button`}
                                      style={status ? { borderRadius: 8, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { borderRadius: 8, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
                                    >
                                      {lesson.status === 'A' ? 'Ativo' : 'Inativo'}
                                      <div style={{ marginLeft: 10 }}><BsChevronDown color='#FFFFFF' /></div>
                                    </div>
                                    <div style={{ opacity: status ? 1 : 0 }} className='dropdown-shadow'>
                                      <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...lesson, status: 'A' })}>Ativo</div>
                                      <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...lesson, status: 'I' })}>Inativo</div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 12, width: '120px', justifyContent: 'center' }}>
                                  <span onClick={() => openEditLesson(lesson)}><BsFillPencilFill /></span>
                                  <span onClick={() => viewLesson(lesson)}><BsEye /></span>
                                  <span onClick={() => toPdf(lesson)}><BsDownload /></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      )}
                  </div>
                </div>
              </div>
              <EditLesson ref={editLessonRef} lesson={lesson} setLesson={setLesson} onSubmit={handleSubmitLesson} />
              <ViewLesson ref={viewLessonRef} lesson={lesson} />
              <div style={{ display: 'none' }}>
                <div ref={lessonPdfRef}>
                  <div className='m-5' style={{ height: '100vh' }}>
                    <div className='d-flex flex-1 flex-column justify-content-center align-items-center'>
                      <img src="../../img/logo.png" alt="Marca" />
                      <h1 className='mt-3'>
                        {lesson?.concept}
                      </h1>
                    </div>
                    <div className='row'>
                      <div className='col-sm-9'>
                        <span style={{ fontWeight: 'bold' }}>Categorias</span>
                        {categoriesSelectedList}
                      </div>
                      <div className='col-sm-3'>
                        <span style={{ fontWeight: 'bold' }}>Status: </span>
                        {lesson?.status === 'A' ? 'Ativo' : 'Inativo'}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-9'>
                        <span style={{ fontWeight: 'bold' }}>
                          Conteúdo
                        </span>
                        {contentSportsSelectedList}
                      </div>
                      <div className='col-sm-3'>
                        <span style={{ fontWeight: 'bold' }}>Espaço: </span>
                        {lesson?.space}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-6'>
                        <span style={{ fontWeight: 'bold' }}>
                          Estratégia didática
                        </span>
                        {didacticStrategySelectList}
                      </div>
                      <div className='col-sm-3'>
                        <span style={{ fontWeight: 'bold' }}>Tempo: </span>
                        {lesson?.time}
                      </div>
                      <div className='col-sm-3'>
                        <span style={{ fontWeight: 'bold' }}>Sessão da aula/treino: </span>
                        {lessonSessionLabel}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-9'>
                        <span style={{ fontWeight: 'bold' }}>
                          Material
                        </span>
                        {materialsSelectedList}
                      </div>
                      <div className='col-sm-3'>
                        <span style={{ fontWeight: 'bold' }}>N. de jogadores: </span>
                        {lesson?.numberOfPlayers}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-12'>
                        <div style={{ fontWeight: 'bold' }}>Descrição</div>
                        {lesson?.description}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-6'>
                        <div style={{ fontWeight: 'bold' }}>Regras</div>
                        {lesson?.rules}
                      </div>
                      <div className='col-sm-6'>
                        <div style={{ fontWeight: 'bold' }}>Pontuação</div>
                        {lesson?.score}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-6'>
                        <div style={{ fontWeight: 'bold' }}>Variação</div>
                        {lesson?.variation}
                      </div>
                      <div className='col-sm-6'>
                        <div style={{ fontWeight: 'bold' }}>Feedback</div>
                        {lesson?.feedback}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-12'>
                        <div style={{ fontWeight: 'bold' }}>Exemplo</div>
                        <div className='border p-3 rounded-lg mt-3 d-flex justify-content-center'>
                          <img src={lesson?.artUrl} width='100%' alt='' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Lessons;
