import { collection, onSnapshot, query, where } from '@firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { getContactList } from '../../../../services/ChatService';
import { Header } from '../Header/Header';
import './newChat.css';

const NewChat = ({ firestore, userData, createNewChat, setCurrentRoute }) => {
  const [searchText, setSearchText] = useState('');
  const [contacts, setContacts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [currentLimit, setCurrentLimit] = useState(10);
  const [observerCreated, setObserverCreated] = useState(false);
  const [observer, setObserver] = useState(null);
  const lastContactRef = useRef(null);

  const { school_id, id } = userData;

  const handleInput = (e) => {
    if (e.target.value === '') {
      fetchContactList('', school_id);
    }
    setSearchText(e.target.value);
  };

  const handleSearch = () => {
    fetchContactList(searchText, school_id);
  };

  useEffect(() => {
    fetchContactList(searchText, school_id);
  }, []);

  const fetchContactList = async (name = '', school_id = '', offset = 0, limit = 10, append = false) => {
    let { contactsUsers, total } = await getContactList(name, school_id, offset, limit);
    if (total === 0) {
      setContacts([]);
      return;
    }
    setTotalPages(total);

    const index = contactsUsers.findIndex((contact) => contact.user_id === id);
    if (index !== -1) {
      contactsUsers.splice(index, 1);
    }

    if (append) {
      contactsUsers = [...contacts, ...contactsUsers];
    }

    updateContactListEffect(contactsUsers);
  };
  const updateContactListEffect = async (contactData) => {
    const usersRef = collection(firestore, 'users');
    const q = query(usersRef, where('school_id', '==', school_id));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      if (querySnapshot.empty) {
        setContacts([]);
        setContacts(contactData);
        return;
      }
      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        const contactListIndex = contactData.findIndex((contact) => contact.user_id === data.user_id);
        if (contactListIndex !== -1) {
          contactData[contactListIndex].status = data.status;
        }
      }
      setContacts([]);
      setContacts(contactData);
    });

    return unsubscribe;
  };

  const handleCreateNewChat = (contact) => {
    createNewChat(id, contact);
  };

  useEffect(() => {
    if (contacts.length > 0 && !observerCreated && totalPages > currentLimit) {
      createObserver();
      setObserverCreated(true);
    }
  }, [contacts]);

  useEffect(() => {
    if (currentOffset !== 0 && observer !== null) {
      fetchContactList(searchText, school_id, currentOffset, currentLimit, true);

      if (currentOffset >= totalPages) {
        observer.disconnect();
      }
    }
  }, [currentOffset, currentLimit, observer]);

  const createObserver = () => {
    const observerData = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCurrentOffset((prevOffset) => prevOffset + currentLimit);
          }
        });
      },
      { threshold: 1 }
    );
    const target = lastContactRef.current;
    if (target) {
      observerData.observe(target);
      setObserver(observerData);
    }
  };

  return (
    <div className='new-chat__container'>
      <Header setCurrentRoute={setCurrentRoute} />
      <div className='chat-search__container'>
        <input
          type='text'
          placeholder='Buscar contato...'
          className='chat-search__input'
          value={searchText}
          onChange={handleInput}
        />
        <button className='chat-search__btn' onClick={() => handleSearch()}>
          Buscar
        </button>
      </div>
      <div className='contact-list chat-container-scroll'>
        {contacts.map((contact, index) => (
          <div key={index} className='contact-list__item'>
            <div className='contact-list__item__left'>
              <div className='contact-list__item__avatar'>
                <img
                  src={contact.avatar}
                  alt='avatar'
                  onError={(e) => (e.target.src = 'https://i.imgur.com/HeIi0wU.png')}
                />
              </div>
              <div className='contact-list__item__info'>
                <div className='contact-list__item__info__name'>{contact.name}</div>
                <div className='contact-list__item__statuses'>
                  <div className='contact-list__item__info__status'>
                    <span
                      className={`contact-list__item__info__status__indicator contact-list__item__info__status__indicator--${contact.status}`}
                    ></span>
                    {contact.status}
                  </div>
                  <div className='contact-list__item__info__role'>
                    <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <mask id='mask0_86_435' maskUnits='userSpaceOnUse' x='0' y='0' width='10' height='10'>
                        <rect width='10' height='10' fill='#D9D9D9' />
                      </mask>
                      <g mask='url(#mask0_86_435)'>
                        <path
                          d='M4.99996 5.00008C4.54163 5.00008 4.14926 4.83689 3.82288 4.5105C3.49649 4.18411 3.33329 3.79175 3.33329 3.33341C3.33329 2.87508 3.49649 2.48272 3.82288 2.15633C4.14926 1.82994 4.54163 1.66675 4.99996 1.66675C5.45829 1.66675 5.85065 1.82994 6.17704 2.15633C6.50343 2.48272 6.66663 2.87508 6.66663 3.33341C6.66663 3.79175 6.50343 4.18411 6.17704 4.5105C5.85065 4.83689 5.45829 5.00008 4.99996 5.00008ZM1.66663 8.33341V7.16675C1.66663 6.93064 1.72739 6.71362 1.84892 6.51571C1.97045 6.31779 2.1319 6.16675 2.33329 6.06258C2.76385 5.8473 3.20135 5.68584 3.64579 5.57821C4.09024 5.47057 4.54163 5.41675 4.99996 5.41675C5.45829 5.41675 5.90968 5.47057 6.35413 5.57821C6.79857 5.68584 7.23607 5.8473 7.66663 6.06258C7.86801 6.16675 8.02947 6.31779 8.151 6.51571C8.27253 6.71362 8.33329 6.93064 8.33329 7.16675V8.33341H1.66663Z'
                          fill='var(--main-color)'
                        />
                      </g>
                    </svg>
                    <span>{contact.role}</span>
                  </div>
                </div>
              </div>
            </div>
            <button className='contact-list__item__info__message-btn' onClick={() => handleCreateNewChat(contact)}>
              Iniciar conversa
            </button>
          </div>
        ))}
        <div id='last-contact-ref' ref={lastContactRef}></div>
      </div>
    </div>
  );
};

export default NewChat;
