import React, { useEffect, useState, useContext, forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import Loading from '../../../components/Loading';
import Message from '../../../components/Message';
import { range } from '../../../../src/utils/utils';
import { deleteDayClassSchool } from '../../../services/ClassService';
import { Trans, useTranslation } from 'react-i18next';
import MultiSelect from '../../../components/inputs/MultiSelect';
import moment from 'moment';
import { UserContext } from '../../../Context/UserContext';
import { ModalWithMethods } from '../../../components/modal/Modal';
import { cpfCnpjFormatI18n } from '../../../utils/formatters';

const EditStudentClass = (
  { studentClass, onSubmit, getClass, dias, responsaveis, students, horarios, ...props },
  ref
) => {
  const { school, company } = useContext(UserContext);

  const { t } = useTranslation();

  const [id, setID] = useState(null);
  const [nameModal, setNameModal] = useState('');
  const [qtdAulasModal, setQtdAulasModal] = useState('');
  const [taxaCarteirinhaModal, setTaxaCarteirinhaModal] = useState('');
  const [taxaFaltaModal, setTaxaFaltaModal] = useState('');
  const [hourModal, setHourModal] = useState('');
  const [dayModal, setDayModal] = useState('');
  const [responsavelModal, setResponsavelModal] = useState('');
  const [daysModal, setDaysModal] = useState([]);
  const [studentsModal, setStudentsModal] = useState([]);
  const [responsaveisModal, setResponsaveisModal] = useState([]);
  const [statusModal, setStatusModal] = useState('');
  const [qtdeMaximaAlunoModal, setQtdeMaximaAlunoModal] = useState(999);
  const [agesSelected, setAgesSelected] = useState([]);
  const [studentThisAge, setStudentThisAge] = useState([]);

  // process
  const [sendSave, setSendSave] = useState(false);

  // libraries
  const { MessageWarning } = Message();

  const onShow = () => {
    if (studentClass) {
      clearMessage();

      setID(studentClass.id);
      setNameModal(studentClass.name);
      setStatusModal(studentClass.status);
      setDaysModal(studentClass.days);
      setStudentsModal(studentClass.students);
      setResponsaveisModal(studentClass.responsaveis);
      setTaxaFaltaModal(studentClass.taxaFalta);
      setTaxaCarteirinhaModal(studentClass.taxaCarteirinha);
      setQtdAulasModal(studentClass.qtdAulas);
      setQtdeMaximaAlunoModal(studentClass.qtdeMaximaAluno === undefined ? 999 : studentClass.qtdeMaximaAluno);
      setAgesSelected(
        studentClass.idadesPermitidas
          ? studentClass.idadesPermitidas
              .split(';')
              .filter((i) => i)
              .map((i) => +i)
          : []
      );
    } else {
      clearMessage();
      setNameModal('');
      setHourModal('');
      setQtdAulasModal('');
      setTaxaFaltaModal('');
      setTaxaCarteirinhaModal('');
      setDaysModal([]);
      setStudentsModal([]);
      setResponsaveisModal([]);
      setStatusModal('');
      setQtdeMaximaAlunoModal(999);
      setID(null);
      setAgesSelected([]);
    }
  };

  useEffect(() => {
    checkStudentAge(agesSelected);
  }, [agesSelected]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();

    if (daysModal.length == 0) {
      setErrorMessage('ClassPage.SystemMessage.RequiredFields.Schedule');
    } else if (responsaveisModal.length == 0) {
      setErrorMessage('ClassPage.SystemMessage.RequiredFields.Employee');
    } else {
      const classData = {
        school_id: school.id,
        name: nameModal,
        days: daysModal,
        students: studentsModal,
        responsaveis: responsaveisModal,
        status: statusModal,
        qtdAulas: qtdAulasModal,
        taxaCarteirinha: taxaCarteirinhaModal !== '' && taxaCarteirinhaModal !== null ? taxaCarteirinhaModal : 0,
        taxaFalta: taxaFaltaModal !== '' && taxaFaltaModal !== null ? taxaFaltaModal : 0,
        qtdeMaximaAluno: qtdeMaximaAlunoModal,
        idadesPermitidas: agesSelected.reduce((acc, age) => acc + age + ';', ''),
      };

      setSendSave(true);
      const res = id ? await onSubmit({ id, ...classData }) : await onSubmit({ companie_id: company.id, ...classData });
      setSendSave(false);

      id
        ? setSuccessMessage('SystemMessage.Success.ClassUpdated')
        : setSuccessMessage('SystemMessage.Success.ClassCreated');

      setID(res.id);
    }
  };

  const addHour = (hour) => {
    clearMessage();

    if (document.getElementById('dias_semana').value !== '') {
      const index = document.getElementById('dias_semana').selectedIndex;
      const dia = document.getElementById('dias_semana').options[index].text;

      let newDaysModal = daysModal.filter((e) => e.name === dia && e.hour === hour);

      if (newDaysModal.length > 0) {
        setErrorMessage('ClassPage.SystemMessage.ScheduleAlreadyIncluded');
        return;
      }

      setDaysModal(newDaysModal);
      setHourModal(hour);

      setDaysModal([
        ...daysModal,
        {
          id: index,
          name: dia,
          hour: hour,
        },
      ]);

      setDayModal('');
      setHourModal('');
    } else {
      setErrorMessage('ClassPage.SystemMessage.SelectSchedule');
    }
  };

  const delDay = async (item) => {
    clearMessage();

    if (id !== null) {
      await deleteDayClassSchool({
        dayId: item.id,
        classId: id,
        hour: item.hour,
        schoolId: school.id,
      });
      getClass(school.id);
    }

    setDaysModal(daysModal.filter((e) => e.id !== item.id));
  };

  const addStudent = (studentSelected) => {
    clearMessage();

    const newStudentsModal = studentsModal.filter((s) => s.id === studentSelected.value);

    if (newStudentsModal.length > 0) {
      setErrorMessage('ClassPage.SystemMessage.StudentAlreadyIncluded');
    } else if (studentsModal.length >= qtdeMaximaAlunoModal) {
      setErrorMessage('ClassPage.SystemMessage.MaxStudentsExceed');
    } else {
      setStudentsModal([
        ...studentsModal,
        {
          id: studentSelected.value,
          name: studentSelected.label,
          ano_nascimento: +studentSelected.idade,
        },
      ]);
    }
  };

  const delStudent = (item) => {
    clearMessage();
    setStudentsModal(studentsModal.filter((e) => e.id !== item.value));
  };

  const addResponsavel = (event) => {
    clearMessage();
    var index = event.nativeEvent.target.selectedIndex;

    let newResponsaveisModal = responsaveisModal.filter((e) => e.id === event.target.value);

    if (newResponsaveisModal.length > 0) {
      setErrorMessage('ClassPage.SystemMessage.EmployeeAlreadyIncluded');
      return;
    }

    setResponsaveisModal([
      ...responsaveisModal,
      {
        id: event.target.value,
        name: event.nativeEvent.target[index].text,
      },
    ]);

    setResponsavelModal('');
  };

  const delResponsavel = (item) => {
    clearMessage();
    setResponsaveisModal(responsaveisModal.filter((e) => e.id !== item.id));
  };

  const setErrorMessage = (message) => {
    ref.current.showErrorMessage(message);
  };

  const setSuccessMessage = (message) => {
    ref.current.showSuccessMessage(message);
  };

  const clearMessage = () => {
    ref.current.clearMessage();
  };

  const addYear = (year) => {
    console.log('year :>> ', year);
    const agesSelectedAux = new Set([...agesSelected]);
    agesSelectedAux.add(year.value);
    setAgesSelected([...agesSelectedAux]);
  };

  const removeYear = (year) => {
    const agesSelectedAux = new Set([...agesSelected]);
    agesSelectedAux.delete(year.value);
    setAgesSelected([...agesSelectedAux]);
  };

  const checkStudentAge = (agesSelected) => {
    if (agesSelected.length === 0) {
      setStudentThisAge(students);
    } else {
      const studentsThisAge = students.filter((student) => agesSelected.includes(+student.ano_nascimento));
      setStudentThisAge(studentsThisAge);
    }
  };

  return (
    <ModalWithMethods
      {...props}
      ref={ref}
      title={id === null ? t('ClassPage.Modal.TitleAdd') : t('ClassPage.Modal.TitleEdit')}
      size={'lg'}
      onShow={onShow}
    >
      <form action='' onSubmit={handleSubmit} method='post' className='form_modal'>
        <div className='group_50'>
          <div className='form_group'>
            <label htmlFor='name' className='card_details_label'>
              {t('ClassName')}
            </label>
            <input
              type='text'
              required
              name='name'
              value={nameModal}
              onChange={(e) => setNameModal(e.target.value)}
              className='form_control'
            />
          </div>
        </div>
        <div className='group_50'>
          <div className='group_50'>
            <div className='form_group'>
              <label htmlFor='title' className='card_details_label'>
                {t('Status')}
              </label>
              <select
                required
                value={statusModal}
                className='form_control select'
                onChange={(e) => setStatusModal(e.target.value)}
              >
                <option value='' disabled></option>
                <option value='A'>{t('StatusType.Active')}</option>
                <option value='I'>{t('StatusType.Inactive')}</option>
              </select>
            </div>
          </div>
          <div className='group_50'>
            <div className='form_group'>
              <label htmlFor='name' className='card_details_label'>
                {t('MaxStudentsInClass')}
              </label>
              <input
                type='number'
                required
                name='name'
                value={qtdeMaximaAlunoModal}
                onChange={(e) => setQtdeMaximaAlunoModal(e.target.value)}
                className='form_control'
                onBlur={() => {
                  if (studentsModal.length > qtdeMaximaAlunoModal) {
                    setErrorMessage('ClassPage.SystemMessage.MaxStudentsExceed');
                    setQtdeMaximaAlunoModal(studentsModal.length);
                  } else {
                    clearMessage();
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className='group_50'>
          <div className='form_group'>
            <label htmlFor='day' className='card_details_label'>
              {t('Weekday')}
            </label>
            <select
              id='dias_semana'
              name='day'
              value={dayModal}
              className='form_control select'
              onChange={(e) => setDayModal(e.target.value)}
            >
              <option value='' disabled></option>
              {dias.map((dia) => (
                <option value={dia.id} key={dia.id}>
                  {t('WeekdayName.' + dia.name)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='group_50'>
          <div className='form_group'>
            <label htmlFor='day' className='card_details_label'>
              {t('Schedule')}
            </label>
            <select className='form_control select' value={hourModal} onChange={(e) => addHour(e.target.value)}>
              <option value='' disabled></option>
              {horarios.map((item, i) => (
                <option value={item.title} key={`horario_${i}`}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='list_select'>
          {daysModal.map((item, i) => (
            <div className='list_item' key={`day_${i}`}>
              <p style={{ fontSize: 13, marginRight: 5 }}>
                {item.name} - {item.hour} -{' '}
              </p>
              <span type='button' className='remove' onClick={() => delDay(item)}>
                <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M8.53555 1.46445C7.59121 0.520117 6.33555 0 5 0C3.66445 0 2.40879 0.520117 1.46445 1.46445C0.520117 2.40879 0 3.66445 0 5C0 6.33555 0.520117 7.59121 1.46445 8.53555C2.40879 9.47988 3.66445 10 5 10C6.33555 10 7.59121 9.47988 8.53555 8.53555C9.47988 7.59121 10 6.33555 10 5C10 3.66445 9.47988 2.40879 8.53555 1.46445ZM7.1627 6.47207C7.35332 6.66289 7.35332 6.97188 7.1627 7.1627C7.06738 7.25801 6.94238 7.30566 6.81738 7.30566C6.69238 7.30566 6.56738 7.25801 6.47207 7.1627L5 5.69062L3.52793 7.1627C3.43262 7.25801 3.30762 7.30566 3.18262 7.30566C3.05762 7.30566 2.93262 7.25801 2.8373 7.1627C2.64668 6.97188 2.64668 6.66289 2.8373 6.47207L4.30937 5L2.8373 3.52793C2.64668 3.33711 2.64668 3.02813 2.8373 2.8373C3.02813 2.64648 3.33711 2.64648 3.52793 2.8373L5 4.30937L6.47207 2.8373C6.66289 2.64668 6.97188 2.64648 7.1627 2.8373C7.35352 3.02793 7.35352 3.33711 7.1627 3.52793L5.69062 5L7.1627 6.47207Z'
                    fill='#B2B1B1'
                  />
                </svg>
              </span>
            </div>
          ))}
        </div>
        <div className='form_group'>
          <label htmlFor='day' className='card_details_label'>
            {t('IncludeEmployee')}
          </label>
          <select
            name='day'
            className='form_control select'
            value={responsavelModal}
            onChange={(e) => addResponsavel(e)}
          >
            <option value='' disabled></option>
            {responsaveis
              .filter((r) => r.usuStatus === 'A')
              .filter((item) => !responsaveisModal.some((r) => r.id == item.id))
              .map((item, i) => (
                <option value={item.id} key={`resp_${i}`}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div className='list_select'>
          {responsaveisModal.map((item, i) => (
            <div className='list_item' key={`resp_${i}`}>
              <p style={{ fontSize: 13, marginRight: 5 }}>{item.name}</p>
              <button type='button' className='remove' onClick={() => delResponsavel(item)}>
                <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M8.53555 1.46445C7.59121 0.520117 6.33555 0 5 0C3.66445 0 2.40879 0.520117 1.46445 1.46445C0.520117 2.40879 0 3.66445 0 5C0 6.33555 0.520117 7.59121 1.46445 8.53555C2.40879 9.47988 3.66445 10 5 10C6.33555 10 7.59121 9.47988 8.53555 8.53555C9.47988 7.59121 10 6.33555 10 5C10 3.66445 9.47988 2.40879 8.53555 1.46445ZM7.1627 6.47207C7.35332 6.66289 7.35332 6.97188 7.1627 7.1627C7.06738 7.25801 6.94238 7.30566 6.81738 7.30566C6.69238 7.30566 6.56738 7.25801 6.47207 7.1627L5 5.69062L3.52793 7.1627C3.43262 7.25801 3.30762 7.30566 3.18262 7.30566C3.05762 7.30566 2.93262 7.25801 2.8373 7.1627C2.64668 6.97188 2.64668 6.66289 2.8373 6.47207L4.30937 5L2.8373 3.52793C2.64668 3.33711 2.64668 3.02813 2.8373 2.8373C3.02813 2.64648 3.33711 2.64648 3.52793 2.8373L5 4.30937L6.47207 2.8373C6.66289 2.64668 6.97188 2.64648 7.1627 2.8373C7.35352 3.02793 7.35352 3.33711 7.1627 3.52793L5.69062 5L7.1627 6.47207Z'
                    fill='#B2B1B1'
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>

        {agesSelected.length > 0 && studentThisAge.length === 0 && (
          <MessageWarning
            style={{ width: '100%' }}
            title={t('Attention_exclamation') + ' '}
            description={t('ClassPage.SystemMessage.StudentAgeNotFound')}
          />
        )}

        <MultiSelect
          label={
            <span>
              <Trans i18nKey='ClassPage.Modal.AllowedAges'>
                <small>b</small>
              </Trans>
            </span>
          }
          selectOption={addYear}
          deselectOption={removeYear}
          optionsSelected={agesSelected.map((year) => ({ value: year, label: year }))}
          options={range(1995, moment().year() + 1)
            .filter((year) => !agesSelected.includes(year))
            .map((year) => ({ value: year, label: year }))}
        />

        <MultiSelect
          label={t('IncludeStudent')}
          selectOption={addStudent}
          deselectOption={delStudent}
          optionsSelected={studentsModal.map((item) => ({ value: item.id, label: item.name }))}
          customOptionLabel={({ label, cpf }) => label + (cpf ? ` - ${cpf}` : '')}
          options={studentThisAge
            .filter((s) => s.usuStatus === 'A')
            .filter((item) => !studentsModal.some((s) => s.id == item.idUser))
            .map((item) => ({
              value: item.idUser,
              label: item.name,
              idade: item.idade,
              cpf: cpfCnpjFormatI18n(item.usuCPF, 'pt-BR'),
            }))}
        />

        <div className='group_50'>
          <div className='form_group'>
            <label htmlFor='name' className='card_details_label'>
              {t('NumClassPerWeek')}
            </label>
            <input
              type='number'
              required
              name='name'
              value={qtdAulasModal}
              onChange={(e) => setQtdAulasModal(e.target.value)}
              className='form_control'
            />
          </div>
        </div>
        <div className='group_50'>
          <div className='form_group'>
            <label htmlFor='name' className='card_details_label'>
              {t('CardForgettingFee')}
            </label>
            <NumberFormat
              className='form_control'
              displayType={'input'}
              prefix={'R$ '}
              format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: e / 100 })}
              value={taxaFaltaModal * 100}
              onValueChange={(e) => setTaxaFaltaModal(e.floatValue / 100 || 0)}
            />
          </div>
        </div>
        <div className='form_group'>
          <label htmlFor='name' className='card_details_label'>
            {t('CardFee')}
          </label>
          <NumberFormat
            className='form_control'
            displayType={'input'}
            prefix={'R$ '}
            format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: e / 100 })}
            value={taxaCarteirinhaModal * 100}
            onValueChange={(e) => setTaxaCarteirinhaModal(e.floatValue / 100 || 0)}
          />
        </div>
        {sendSave ? (
          <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none' }} disabled>
            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
            </svg>
            {t('SystemMessage.Loading.Processing')}
            <Loading type='1' style={{ marginLeft: '2%' }} />
          </button>
        ) : (
          <button className='btn_card mx-auto'>
            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
            </svg>
            {id === null && t('ClassPage.Modal.ButtonAdd')}
            {id !== null && t('ClassPage.Modal.ButtonEdit')}
          </button>
        )}
      </form>
    </ModalWithMethods>
  );
};

export default forwardRef(EditStudentClass);
