import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';

const PrivateRouterAdmin = (props) => {
  const token = localStorage.getItem('admin');
  const isLogged = !!token;
  const today = new Date();
  const adminToken = `F51z8$%61&@l&E2E_${today.getUTCFullYear()}${today.getUTCMonth()}${today.getUTCDay()}`;

  if (!isLogged) return <Redirect to={'/'} />;
  else return token === adminToken ? <Route {...props} /> : <Redirect to='/nao-autorizado' />;
};

export default PrivateRouterAdmin;
