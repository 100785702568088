import { defaultApiObject, handleFail } from '../utils/utils-api';
import api from './api';

export const getStudentReports = async (companyId, filters) => {
  try {
    const { data } = await api.post('get-reports-club-students', { companyId, filters });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const getContractReports = async (companyId, filters) => {
  try {
    const { data } = await api.post('get-reports-club-contracts', { companyId, filters });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const getFinancialReports = async (companyId, filters) => {
  try {
    const { data } = await api.post('get-reports-club-financials', { companyId, filters });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};
