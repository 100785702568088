import React, { useEffect, useState } from 'react';
import { countUnreadMessages } from '../../../../services/ChatService';
import './chatList.css';
import { ChatListHeader } from './ChatListHeader';

const ChatList = ({ chats, myId, goToNewChat, setCurrentRoute, goToPrivateChat, setWidgetMessageCount }) => {
  const [chatList, setChatList] = useState([]);
  const [newMessagesCount, setNewMessagesCount] = useState(0);

  useEffect(() => {
    if (chats.length === 0) return;
    const { chatsWithUnreadMessages, chatUpdated } = countUnreadMessages(chats, myId);
    setChatList(chatUpdated);
    setNewMessagesCount(chatsWithUnreadMessages);
    setWidgetMessageCount(chatsWithUnreadMessages);
  }, [chats]);

  const isTyping = (chat) => {
    const chatUserId = chat.sender_id === myId ? chat.receiver_id : chat.sender_id;
    if (!chat.typing) return false;
    return chat.typing.indexOf(chatUserId) !== -1;
  };

  return (
    <div className='chat-list-container'>
      <ChatListHeader newMessagesCount={newMessagesCount} myStatus='online' setCurrentRoute={setCurrentRoute} />
      <div className='chat-list chat-container-scroll'>
        {chatList.map((chat, index) => (
          <div className='chat-user-message-container' key={index}>
            <div className='chat-user-message'>
              <div className='chat-message-avatar'>
                <img
                  src={chat.user?.avatar}
                  alt='avatar'
                  onError={(e) => (e.target.src = 'https://i.imgur.com/HeIi0wU.png')}
                />
              </div>
              <div className='chat-user-content'>
                <div className='chat-user-name'>{chat.user?.name ?? 'Sem nome'}</div>
                {isTyping(chat) ? (
                  <div className='chat-user-typing'>Está digitando</div>
                ) : (
                  <div
                    className={`chat-user-message-content ${
                      chat.unreadMessages > 0 ? 'unread_message' : 'read_message'
                    }`}
                  >
                    {chat.lastMessage?.content ?? 'Sem mensagens'}
                  </div>
                )}
              </div>
            </div>
            <button onClick={() => goToPrivateChat(chat?.chat_id, chat?.id)} className='chat-user-message-button'>
              Abrir conversa
            </button>
          </div>
        ))}
      </div>
      <div className='chat-list-footer'>
        <button onClick={() => goToNewChat()} className='chat-list-footer-button'>
          <svg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M3.5 9H9.5V7.5H3.5V9ZM3.5 6.75H12.5V5.25H3.5V6.75ZM3.5 4.5H12.5V3H3.5V4.5ZM0.5 15V1.5C0.5 1.0875 0.646875 0.734375 0.940625 0.440625C1.23438 0.146875 1.5875 0 2 0H14C14.4125 0 14.7656 0.146875 15.0594 0.440625C15.3531 0.734375 15.5 1.0875 15.5 1.5V10.5C15.5 10.9125 15.3531 11.2656 15.0594 11.5594C14.7656 11.8531 14.4125 12 14 12H3.5L0.5 15ZM2 11.3813L2.88125 10.5H14V1.5H2V11.3813Z'
              fill='#1C1B1F'
            />
          </svg>
          Nova conversa
        </button>
      </div>
    </div>
  );
};

export default ChatList;
