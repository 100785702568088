import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { formatByPattern } from '../../utils/utils';

const CEPMask = ({ value, onChange, className = 'form_control', maskLocation = "", ...rest }) => {
  const { t } = useTranslation();

  return (
    <NumberFormat
      {...rest}
      type='tel'
      className={className}
      format={(value) => formatByPattern(value, maskLocation !== "" ? maskLocation : t('Mask.ZipCode') || null)}
      value={value}
      onValueChange={({ value }) => onChange(value)}
    />
  );
};

export default CEPMask;
