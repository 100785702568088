import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Message from '../Message';
import {
  destroyDocumentStudent,
  getDocumentStudent,
  getDocumentTypes,
  uploadDocumentStudent,
} from '../../services/StudentService';
import { getAllAvailableStudentDocuments } from '../../services/DocumentService';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';

const AddDocumentsModal = ({ closeAddDocumento, athleteID, show, defaultAthleteDocuments }) => {
  const modalScroll = useRef(null);
  const [errorUpload, setErrorUpload] = useState(false);
  const [messageUpload, setMessageUpload] = useState('');
  const [successUpload, setSuccessUpload] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [sendDoc, setSendingDoc] = useState(false);
  const [documentID, setDocumentID] = useState(null);
  const [athleteDocuments, setAthleteDocuments] = useState(defaultAthleteDocuments ?? []);
  const [uploadFileConfirm, setUploadFileConfirm] = useState({
    show: false,
    file: null,
  });
  const [mandatoryDocuments, setMandatoryDocuments] = useState([]);
  const [dirDocument, setDirDocument] = useState('');
  const [sendSave, setSendSave] = useState(false);
  const [loadingAthleteDocs, setLoadingAthleteDocs] = useState(false);
  const docFormRef = useRef(null);
  const { t } = useTranslation();
  const getDocumentosAthleta = useCallback(async (athlete_id) => {
    setLoadingAthleteDocs(true);
    try {
      const data = await getDocumentStudent(athlete_id);
      setAthleteDocuments(data);
    } finally {
      setLoadingAthleteDocs(false);
    }
  }, []);

  const missingMandatoryDocuments = useMemo(
    () =>
      mandatoryDocuments.filter(
        ({ document_id }) => !athleteDocuments.some(({ idDocument }) => idDocument == document_id)
      ),
    [mandatoryDocuments, athleteDocuments]
  );

  const uploadFile = async (file, athleteID, documentID) => {
    if (!athleteID) {
      setAthleteDocuments((prev) =>
        prev.map((document) => (document.idDocument === documentID ? { ...document, file } : document))
      );
      return;
    }
    setSendingDoc(true);
    try {
      const res = await uploadDocumentStudent({
        athleteID: athleteID,
        documentID: documentID,
        athletedocument_id: null,
        file: file,
      });

      if (res.success === false) {
        setErrorUpload(true);
        setSuccessUpload(false);

        setMessageUpload('SystemMessage.File.Fail.' + res.messageKey);

        // if (!displayDocumento.display) {
        //     onUploadFail()
        // //   setDisplayDocumento({
        // //     display: 'flex',
        // //     opacity: 1,
        // //   });
        // }
      } else if ((res.original || {}).errors) {
        setErrorUpload(true);
        setSuccessUpload(false);

        setMessageUpload((Object.values(res.original.errors)[0] || [])[0] || 'Houve um erro inesperado');

        // if (!displayDocumento.display) {
        //   setDisplayDocumento({
        //     display: 'flex',
        //     opacity: 1,
        //   });
        // }
        return;
      }

      setDocumentID(null);
      setSuccessUpload(true);
      setMessageUpload('SystemMessage.File.Success.' + res.messageKey);
      await getDocumentosAthleta(athleteID);
      docFormRef.current.reset();
    } finally {
      setSendingDoc(false);
    }
  };
  const schoolId = useMemo(() => JSON.parse(localStorage.getItem('school'))?.id, []);

  const fetchAllAvailableStudentDocuments = async () => {
    const { status, documents, mandatoryStudentsDocuments } = await getAllAvailableStudentDocuments(schoolId);

    if (status === 'SUCCESS') {
      setDocuments(
        documents
          .sort(({ docDocument: docDocumentA }, { docDocument: docDocumentB }) =>
            docDocumentA.toLowerCase().localeCompare(docDocumentB.toLowerCase())
          )
          .map((document) => ({ label: document.docDocument, ...document }))
      );
      setMandatoryDocuments(mandatoryStudentsDocuments);
    }
  };

  const getDirDocuments = async () => {
    const res = await getDocumentTypes();
    setDirDocument(res.dir_documents);
  };

  useEffect(() => {
    if (!show) return;
    fetchAllAvailableStudentDocuments();
    getDirDocuments();
  }, [show]);

  useEffect(() => {
    if (!show || !defaultAthleteDocuments) return;
    setAthleteDocuments(defaultAthleteDocuments);
  }, [defaultAthleteDocuments, show]);

  useEffect(() => {
    if (!show || !athleteID) return;

    getDocumentosAthleta(athleteID);
  }, [getDocumentosAthleta, show, athleteID]);
  const handleUpload = async (e) => {
    e.persist();
    setErrorUpload(false);
    setSuccessUpload(false);

    if (!documentID) {
      setDocumentID(null);
      setErrorUpload(true);
      setMessageUpload(t('RequiredField', { fieldName: t('Type') }));
      return;
    }

    if (athleteDocuments.some((document) => document.idDocument == documentID) && e.target.files) {
      setUploadFileConfirm({ show: true, file: e.target.files[0] });
      return;
    }

    if (!athleteID) {
      setAthleteDocuments((prev) => [
        ...prev,
        {
          docDocument: (documents.find((d) => d.id == documentID) || {}).docDocument ?? '',
          idDocument: documentID,
          // esse atributo será necessário para que seja possível fazer o upload após o cadastro do aluno
          file: e.target.files[0],
        },
      ]);
      return;
    }
    uploadFile(e.target.files[0], athleteID, documentID);
  };
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();

  const clearMessage = () => {
    setErrorUpload(false);
    setSuccessUpload(false);
  };

  const handleDeleteDocument = async (index) => {
    const id = athleteDocuments[index].idDocumentAthlete;
    if (!id) {
      setAthleteDocuments((prev) => prev.filter((_, i) => i !== index));
      return;
    }
    const res = await destroyDocumentStudent(id);

    if (res.success === false) {
      setErrorUpload(true);
      setSuccessUpload(false);

      setMessageUpload('SystemMessage.File.Fail.' + res.messageKey);
    } else {
      setSuccessUpload(true);
      setMessageUpload('SystemMessage.Success.' + res.messageKey);
      await getDocumentosAthleta(athleteID);
    }
  };
  return (
    <div
      className='modal'
      id='add_documento'
      style={
        show
          ? {
              display: 'flex',
              opacity: 1,
            }
          : undefined
      }
    >
      <div className='modal_content' ref={modalScroll} style={{ width: 500 }}>
        <div
          className='modal_close'
          onClick={() => {
            docFormRef.current.reset();
            setDocumentID(null)
            closeAddDocumento(athleteDocuments);
          }}
        >
          X
        </div>
        <h3 className='modal_title'>{t('Documents')}</h3>
        {errorUpload && (
          <MessageDangerMarginTop
            title={t('Attention_exclamation') + ' '}
            description={messageUpload.extra ? t(messageUpload.text, messageUpload.extra) : t(messageUpload)}
          />
        )}
        {successUpload && (
          <MessageSuccessMarginTop
            title={t('Success_exclamation') + ' '}
            description={messageUpload.extra ? t(messageUpload.text, messageUpload.extra) : t(messageUpload)}
          />
        )}
        <form action='' method='post' className='form_modal' ref={docFormRef}>
          <div className='form_group'>
            <label htmlFor='name' className='card_details_label'>
              {t('Type')}
            </label>
            <select
              className='form_control'
              defaultValue={documentID}
              onChange={(e) => {
                clearMessage();
                setDocumentID(e.target.value);
              }}
            >
              <option value=''>-- {t('Select')} --</option>
              {documents.map((item) => (
                <option key={item.id} value={item.id}>
                  {t('DocumentTypeModel.' + item.docDocument)}
                </option>
              ))}
            </select>
          </div>
          <div className='form_group' style={{ border: '2px dashed #ddd' }}>
            <label htmlFor='documentFile' className='carregar-arquivo' style={{ margin: 0 }}>
              {!sendDoc && !loadingAthleteDocs ? (
                <React.Fragment>
                  {t('LoadFile')}...
                  <input
                    type='file'
                    name='documentFile'
                    id='file'
                    disabled={!documentID}
                    onChange={handleUpload}
                    accept='image/png, image/jpg, image/jpeg, application/pdf'
                  />
                </React.Fragment>
              ) : (
                <Loading type='1' />
              )}
            </label>
          </div>
          <label style={{ marginBottom: 40 }} htmlFor='file'>
            {t('StudentPage.DocumentFileHelp')}
          </label>
          {athleteDocuments.length === 0 ? (
            <p style={{ textAlign: 'center', width: '100%' }}>
              <b>{t('NoDocumentsAttached')}</b>
            </p>
          ) : (
            <p style={{ textAlign: 'center', width: '100%', marginBottom: 20 }}>
              <b>{t('AttachedDocuments')}</b>
            </p>
          )}

          {athleteDocuments.map((item, index) => (
            <div
              key={item.idDocumentAthlete}
              className='form_group'
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p style={{ fontSize: 14 }}>&gt; {t('DocumentTypeModel.' + item.docDocument, item.docDocument)}</p>
              <div
                style={{
                  width: '31%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <button
                  target='_blank'
                  rel='noopener noreferrer'
                  type='button'
                  onClick={() => {
                    window.open(
                      item.atdAthleteDocument
                        ? `${dirDocument}/${item.atdAthleteDocument}`
                        : URL.createObjectURL(item.file),
                      '_blank'
                    );
                  }}
                  style={{
                    fontSize: 12,
                    background: '#4682B4',
                    color: 'white',
                    padding: '2px 8px',
                    borderRadius: 4,
                  }}
                >
                  {t('View')}
                </button>
                <p
                  onClick={() => handleDeleteDocument(index)}
                  style={{
                    cursor: 'pointer',
                    fontSize: 12,
                    background: '#B22222',
                    color: 'white',
                    padding: '2px 8px',
                    borderRadius: 4,
                  }}
                >
                  {t('Delete')}
                </p>
              </div>
            </div>
          ))}

          {sendDoc && (
            <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none', pointerEvents: 'none' }}>
              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
              </svg>
              {t('SystemMessage.Loading.Processing')}
              <Loading type='1' style={{ marginLeft: '2%' }} />
            </button>
          )}
        </form>
        {loadingAthleteDocs && <Loading type='1' />}
        {mandatoryDocuments.length > 0 && missingMandatoryDocuments.length > 0 && (
          <MessageDangerMarginTop
            title={t('Attention_exclamation') + ' '}
            description={
              <div>
                {t('StudentPage.SystemMessage.MissingMandatoryDocuments')}
                <ul style={{ listStyleType: 'disc', paddingLeft: '18px' }}>
                  {missingMandatoryDocuments.map(({ id, document: { docDocument } }) => (
                    <li key={`${missingMandatoryDocuments}_${id}`}>{docDocument}</li>
                  ))}
                </ul>
              </div>
            }
          />
        )}
      </div>
      <SweetAlert
        warning
        showCancel
        confirmBtnText={t('Confirm')}
        cancelBtnText={t('Cancel')}
        confirmBtnBsStyle='warning'
        onConfirm={() => {
          uploadFile(uploadFileConfirm.file, athleteID, documentID);
          setUploadFileConfirm({
            show: false,
            file: null,
          });
        }}
        onCancel={() => setUploadFileConfirm({ show: false, file: null })}
        focusCancelBtn
        show={uploadFileConfirm.show}
        title={t('Attention_exclamation')}
      >
        {t('StudentPage.DocumentFileUploadConfirm')}
      </SweetAlert>
    </div>
  );
};

export default AddDocumentsModal;
