import api from './api';

export const fetchLessonDidacticStrategy = async () => {
  try {
    const { data } = await api.get('lesson-didactic-strategies');
    return data;
  } catch (error) {
    return { success: false, error: error }
  }
};
