import { useState, useEffect, useCallback } from 'react';

function useForm(stateSchema, validationSchema = {}, callback, formrefer = null, dispatchCustomError) {
  const [state, setState] = useState(stateSchema);
  const [disable, setDisable] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  let formRefer = formrefer;
  // Disable button in initial render.
  useEffect(() => {
    setDisable(true);
  }, []);

  // For every changed in our state this will be fired
  // To be able to disable the button
  useEffect(() => {
    if (isDirty) {
      setDisable(validateState());
    }
  }, [state, isDirty]);

  const setFormRefer = useCallback((ref) => {
    formRefer = ref;
  }, []);
  // Used to disable submit button if there's an error in state
  // or the required field in state has no value.
  // Wrapped in useCallback to cached the function to avoid intensive memory leaked
  // in every re-render in component
  const validateState = useCallback(() => {
    const hasErrorInState = Object.keys(validationSchema).some((key) => {
      const isInputFieldRequired = validationSchema[key].required;
      const stateValue = state[key].value; // state value
      const stateError = state[key].error; // state error

      return (isInputFieldRequired && (!stateValue || stateValue.length === 0)) || stateError;
    });

    return hasErrorInState;
  }, [state, validationSchema]);

  // Used to handle every changes in every input
  const handleOnChange = useCallback(
    (event) => {
      setIsDirty(true);

      const name = event.target.name;
      let value = event.target.value;
      const label = event.target?.label;
      const element = event.target;

      let error = '';
      if (validationSchema[name] !== undefined) {
        if (validationSchema[name].required) {
          if (!value || value.length === 0) {
            error = `SystemMessage.Fail.RequeridFields`;
          }
        }

        if (validationSchema[name].validator !== null && typeof validationSchema[name].validator === 'object') {
          const { regEx = false, max = false, min = false } = validationSchema[name].validator;

          if (value && regEx && !validationSchema[name].validator.regEx.test(value)) {
            error = validationSchema[name].validator.error;
          }

          if (value && max && (element.type === 'number' ? Number(value) > max : value.length > max)) {
            error = validationSchema[name].validator.error.replace('{{value}}', value.toString());
          }

          if (value && min && (element.type === 'number' ? Number(value) < min : value.length < min)) {
            error = validationSchema[name].validator.error.replace('{{value}}', value.toString());
          }
        }

        if (validationSchema[name].mask !== undefined && typeof validationSchema[name].mask === 'function') {
          value = validationSchema[name].mask(value);
        }
      }

      dispatchHtmlValidity(element, error);

      setState((prevState) => ({
        ...prevState,
        [name]: { value, error, label },
      }));
    },
    [validationSchema]
  );

  const dispatchHtmlValidity = useCallback((element, error) => {
    if (error.length > 0) {
      dispatchCustomError((current) => current.filter((errors) => errors.key !== element.name));

      if (typeof element.setCustomValidity === 'function') {
        element.setCustomValidity(error);
      }

      if (typeof formRefer.current !== undefined) {
        formRefer.current.classList.add('was-validated');
        formRefer.current.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
      }

      dispatchCustomError((current) => [...current, { key: element.name, error }]);
    } else {
      dispatchCustomError((current) => current.filter((errors) => errors.key !== element.name));
      if (typeof element.setCustomValidity === 'function') {
        element.setCustomValidity('');
      }
    }
  }, []);

  const handleOnSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setDisable(true);
      // Make sure that validateState returns false
      // Before calling the submit callback function
      let payload = {};
      if (!validateState()) {
        normalizeToPost(payload);
        callback(payload, event, state, toFormData(payload));
      }
    },
    [state]
  );

  const normalizeToPost = (payload = {}) => {
    Object.keys(state).map((k) => Object.assign(payload, { [k]: state[k].value }));
  };

  const toFormData = (payload) => {
    const formData = new FormData();

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const value = payload[key];

        if (value instanceof File) {
          // Se o valor for um objeto File, adicione-o diretamente ao FormData
          formData.append(key, value, value.name);
        } else {
          formData.append(key, value);
        }
      }
    }

    return formData;
  };

  return {
    state,
    disable,
    handleOnChange,
    handleOnSubmit,
    setState,
    setDisable,
    dispatchHtmlValidity,
    setIsDirty,
    setFormRefer,
  };
}

export default useForm;
