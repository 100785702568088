import axios from 'axios';
import Api from '../utils/api';

const baseURL = Api();

const getEmployeesSchool = async (schoolId) =>
  new Promise((response, reject) => {
    const requestBody = {
      school_id: schoolId,
    };
    axios
      .post(baseURL + 'get-employees-school', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

const saveEmployeeSchool = async ({
  companieId,
  schoolId,
  name,
  cpf,
  birthdate,
  phone,
  email,
  cep,
  endereco,
  cityId,
  bairro,
  dataInicio,
  roleId,
  sobre,
  password,
  status,
  sports,
  remuneration
}) => {
  const employee = {
    companie_id: companieId,
    school_id: schoolId,
    name: name,
    usuCPF: cpf,
    usuBirth: birthdate,
    usuPhone: phone,
    email: email,
    usuCEP: cep,
    usuEndereco: endereco,
    city_id: cityId,
    usuBairro: bairro,
    usuDataInicioSchool: dataInicio,
    role_id: roleId,
    usuSobre: sobre,
    password: password,
    usuStatus: status,
    sports: sports,
    remuneration: remuneration
  };

  try {
    const { data } = await axios.post(baseURL + 'save-employee-school', employee);
    return data;
  } catch (error) {
    return { error };
  }
};

const updateEmployeeSchool = async ({
  userId,
  schoolId,
  name,
  cpf,
  birthdate,
  phone,
  email,
  cep,
  endereco,
  cityId,
  bairro,
  dataInicio,
  roleId,
  sobre,
  status,
  sports,
  remuneration
}) => {
  const employee = {
    id: userId,
    school_id: schoolId,
    name: name,
    usuCPF: cpf,
    usuBirth: birthdate,
    usuPhone: phone,
    email: email,
    usuCEP: cep,
    usuEndereco: endereco,
    city_id: cityId,
    usuBairro: bairro,
    usuDataInicioSchool: dataInicio,
    role_id: roleId,
    usuSobre: sobre,
    usuStatus: status,
    sports: sports,
    remuneration
  };

  try {
    const { data } = await axios.post(baseURL + 'update-employee-school', employee);
    return data;
  } catch (error) {
    return { error };
  }
};

const uploadEmployeePhotoSchool = ({ schoolId, userId, photo }) =>
  new Promise((response, reject) => {
    const formData = new FormData();
    formData.append('school_id', schoolId);
    formData.append('id', userId);
    formData.append('foto', photo);

    axios
      .post(baseURL + 'upload-employee-school', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export { getEmployeesSchool, saveEmployeeSchool, updateEmployeeSchool, uploadEmployeePhotoSchool };
