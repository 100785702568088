import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import InputMask from "react-input-mask";
import { Link, Redirect } from "react-router-dom";
import Loading from "../../components/Loading";
import Message from "../../components/Message";
import Api, { getAccountFensorAsaas, getEnvironment } from "../../utils/api";
import utils from "../../utils/utils";

const PagamentoAssinatura = (props) => {
  // estados do formulario
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [usuPhone, setUsuPhone] = useState("");
  const [usuCPF, setUsuCPF] = useState("");
  const [numeroCartao, setNumeroCartao] = useState("");
  const [titularCartao, setTitularCartao] = useState("");
  const [mesValidade, setMesValidade] = useState("");
  const [anoValidade, setAnoValidade] = useState("");
  const [codSeguranca, setCodSeguranca] = useState("");
  const [opcao, setOpcao] = useState("");
  const [parcelasPagamento, setParcelasPagamento] = useState("");
  const [postalCode, setPostalCode] = useState(false);
  const [addressNumber, setAddressNumber] = useState(false);
  const [anos, setAnos] = useState([]);
  const [urlBoleto, setUrlBoleto] = useState("");
  const [tipoPagamento, setTipoPagamento] = useState("");

  // estados de processamento
  const [sendingPG, setSendingPG] = useState(false);

  // estados de mensagens
  const [errorFormClient, setErrorFormClient] = useState(false);
  const [errorFormCartao, setErrorFormCartao] = useState(false);
  const [errorFormBoleto, setErrorFormBoleto] = useState(false);
  const [message, setMessage] = useState("");

  // estados de redirecionamento
  const [goBack, setGoBack] = useState(false);
  const [login, setLogin] = useState(false);
  const [auth, setAuth] = useState(false);

  // outras variaveis
  const { MessageDanger, MessageDangerMarginTop } = Message();
  const baseURL = Api();
  const { replaceAll, validaCPF } = utils();
  const user = JSON.parse(localStorage.getItem("user"));
  const athlete = JSON.parse(localStorage.getItem("athlete"));
  let currentTab = 0;

  // parametros API asaas
  // DESENVOLVIMENATO
  // const cnpj_asaas  = '05380325610'
  // const pass_asaas  = 'assas2020@v1#'
  // const token_asaas = '0f590ebd6c8800460762c31ea6f6a8c53ee040fb2266a8fb5b1ed647601fdc93'
  // const user_asaas  = 4

  // PRODUCAO
  const cnpj_asaas = getAccountFensorAsaas().cnpj;
  const pass_asaas = getAccountFensorAsaas().password;
  const token_asaas = getAccountFensorAsaas().token_assas;
  const user_asaas = getAccountFensorAsaas().user_id;
  const path_asaas = (getEnvironment() !== 'PROD') ? 'hmg-' : '';

  useEffect(() => {
    ReactGA.initialize("GTM-5V5LZV5");
    ReactGA.pageview(window.location.pathname);

    getAnos();
    showTab(currentTab);

    if (user !== null) {
      setName(user.name);
      setEmail(user.email);
      setUsuPhone(user.usuPhone);
      setUsuCPF(user.usuCPF);
      setPostalCode(athlete.athCep);
      setAddressNumber(athlete.athNumero);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAnos = () => {
    if (anos.length == 0) {
      const anos = [];
      const anoAtual = new Date().getFullYear();
      for (let i = anoAtual; i <= parseInt(anoAtual) + 8; i = i + 1)
        anos.push(i);

      setAnos(anos);
    }
  };

  const setCartao = (e) => {
    e.preventDefault(e);
    document.getElementById("item1").style.display = "inline";
    document.getElementById("item2").style.display = "none";
  };

  const setBoleto = (e) => {
    e.preventDefault(e);
    document.getElementById("item1").style.display = "none";
    document.getElementById("item2").style.display = "inline";
  };

  const showTab = (n) => {
    var x = document.getElementsByClassName("tab");

    x[n].style.display = "block";
    if (n == 0) {
      document.getElementById("prevBtn").style.display = "none";
    } else {
      document.getElementById("prevBtn").style.display = "inline";
    }

    fixStepIndicator(n);
  };

  const nextPrev = (n) => {
    var x = document.getElementsByClassName("tab");
    x[currentTab].style.display = "none";
    currentTab = currentTab + n;
    showTab(currentTab);
  };

  const fixStepIndicator = (n) => {
    var i,
      x = document.getElementsByClassName("step");
    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(" active", "");
    }
    x[n].className += " active";
  };

  const handleValidaForm = (e) => {
    e.preventDefault();

    const cpf = replaceAll(
      replaceAll(replaceAll(usuCPF, ".", ""), "-", ""),
      "_",
      ""
    );
    const phone = replaceAll(
      replaceAll(
        replaceAll(replaceAll(replaceAll(usuPhone, "(", ""), ")", ""), " ", ""),
        "-",
        ""
      ),
      "_",
      ""
    );

    if (phone.length < 11) {
      setErrorFormClient(true);
      setMessage("O Telefone deve ter 11 dígitos");
    } else if (cpf.length < 11) {
      setErrorFormClient(true);
      setMessage("O CPF deve ter 11 dígitos");
    } else if (!validaCPF(cpf)) {
      setErrorFormClient(true);
      setMessage("CPF inválido");
    } else {
      ReactGA.event({
        category: "Botão - Pagamento da Assinatura",
        action:
          "Click no botão próximo passo da aba dados pessoais no preenchimento da assinatura",
      });

      nextPrev(1);
    }
  };

  const handleValidaFormCartao = async (e) => {
    e.preventDefault();
    setErrorFormClient(true);
    setErrorFormCartao(false);
    setErrorFormBoleto(false);
    setUrlBoleto("");
    setTipoPagamento(1);

    ReactGA.event({
      category: "Botão - Pagamento da Assinatura Cartão",
      action:
        "Click no botão próximo passo da aba pagamento no preenchimento da assinatura",
    });

    const codSeg = replaceAll(codSeguranca, "_", "").trim();
    const numCar = replaceAll(
      replaceAll(numeroCartao, "_", ""),
      " ",
      ""
    ).trim();

    if (numCar.length < 16) {
      setErrorFormCartao(true);
      setMessage("Verifique o número do cartão");
    } else if (codSeg.length < 3) {
      setErrorFormCartao(true);
      setMessage("Verifique o cód. de segurança");
    } else {
      setSendingPG(true);

      // autentica na api de pgto
      const resAuth = await axios.post(baseURL + "payment-auth", {
        cnpj: cnpj_asaas,
        password: pass_asaas,
      });

      if (resAuth.status == 200) {
        if (resAuth.connection == undefined) {
          if (resAuth.data.token != undefined) {
            const dataCli = {
              cpf_cnpj: replaceAll(replaceAll(usuCPF, ".", ""), "-", ""),
              name: name,
              email: email,
              customer: "",
              system_id: 1,
              user_id: user_asaas,
              token_assas: token_asaas,
            };

            // envia o cliente para obter o customer_id
            const resCli = await axios.post(baseURL + `${path_asaas}cliente-save`, dataCli);

            if (resCli.status == 200) {
              const date = new Date();
              date.setDate(date.getDate());
              let day = date.getDate();
              day = day < 10 ? "0" + day : day;
              let mon = date.getMonth();
              mon = mon + 1 < 10 ? "0" + (mon + 1) : mon + 1;
              let yea = date.getFullYear();
              let dueDate = yea + "-" + mon + "-" + day;

              // CARTAO
              // MENSAL assinatura
              // ANUAL a vista ou parcelado

              let data = "";
              if (opcao === "MONTHLY") {
                // cartao assinatura mes
                data = {
                  billingType: "CREDIT_CARD",
                  customer: resCli.data.data.customer,
                  value: parcelasPagamento,
                  nextDueDate: dueDate,
                  cycle: "MONTHLY",
                  endDate: "",
                  maxPayments: "",
                  description: "Assinatura mensal com cartao de credito",
                  postalService: false,
                  client_id: resCli.data.data.id,
                  token_assas: token_asaas,
                  holderName: titularCartao,
                  number: numCar,
                  expiryMonth: mesValidade,
                  expiryYear: anoValidade,
                  ccv: codSeg,
                  name: name,
                  email: email,
                  cpfCnpj: replaceAll(replaceAll(usuCPF, ".", ""), "-", ""),
                  postalCode: postalCode,
                  addressNumber: addressNumber,
                  phone: replaceAll(
                    replaceAll(
                      replaceAll(
                        replaceAll(replaceAll(usuPhone, "(", ""), ")", ""),
                        " ",
                        ""
                      ),
                      "-",
                      ""
                    ),
                    "_",
                    ""
                  ),
                  sign: true,
                  user: user,
                };
              } else {
                // cartao a vista ou parcelado
                data = {
                  billingType: "CREDIT_CARD",
                  customer: resCli.data.data.customer,
                  value: parcelasPagamento == 118.8 ? parcelasPagamento : "",
                  dueDate: dueDate,
                  description:
                    "Assinatura " +
                    (parcelasPagamento < 118.8 ? "parcelado" : "a vista") +
                    " através de cartão de crédito",
                  installmentCount: parcelasPagamento < 118.8 ? 12 : "",
                  installmentValue:
                    parcelasPagamento < 118.8 ? parcelasPagamento : "",
                  postalService: false,
                  client_id: resCli.data.data.id,
                  token_assas: token_asaas,
                  holderName: titularCartao,
                  number: numCar,
                  expiryMonth: mesValidade,
                  expiryYear: anoValidade,
                  ccv: codSeg,
                  name: name,
                  email: email,
                  cpfCnpj: replaceAll(replaceAll(usuCPF, ".", ""), "-", ""),
                  postalCode: postalCode,
                  addressNumber: addressNumber,
                  phone: replaceAll(
                    replaceAll(
                      replaceAll(
                        replaceAll(replaceAll(usuPhone, "(", ""), ")", ""),
                        " ",
                        ""
                      ),
                      "-",
                      ""
                    ),
                    "_",
                    ""
                  ),
                  sign: true,
                  user: user,
                };
              }

              const url =
                baseURL +
                (opcao === "MONTHLY"
                  ? `${path_asaas}payment-cart-cred-assinatura`
                  : parcelasPagamento == 118.8
                  ? `${path_asaas}payment-cart-cred-vista`
                  : `${path_asaas}payment-cart-cred-parcelado`);

              // envia pagamento
              const resPayment = await axios.post(url, data);

              if (resPayment.data.data.paymentResponse != undefined) {
                if (
                  resPayment.data.data.paymentResponse.status == "CONFIRMED" ||
                  resPayment.data.data.paymentResponse.status == "ACTIVE"
                ) {
                  localStorage.setItem(
                    "user",
                    JSON.stringify(resPayment.data.data.user)
                  );
                  localStorage.setItem(
                    "athlete",
                    JSON.stringify(resPayment.data.data.athlete)
                  );
                  localStorage.setItem(
                    "menu",
                    JSON.stringify(resPayment.data.data.menu)
                  );
                  localStorage.setItem(
                    "docs",
                    JSON.stringify(resPayment.data.data.docs)
                  );
                  localStorage.setItem("sign", true);

                  window.scrollTo(0, 950);
                  document.getElementsByClassName("tab")[1].style.display =
                    "none";
                  showTab(2);
                } else {
                  setErrorFormCartao(true);
                  setMessage(
                    "Pagamento não aprovado, tente novamente ou com outro cartão..."
                  );
                }
              } else if (resPayment.data.data.message != undefined) {
                setErrorFormCartao(true);

                let msgs = "";
                let prop = "";
                for (prop in resPayment.data.data.message) {
                  if (resPayment.data.data.message.hasOwnProperty(prop)) {
                    if (resPayment.data.data.message[prop].message != undefined)
                      msgs += resPayment.data.data.message[prop].message + ", ";
                    else
                      msgs +=
                        resPayment.data.data.message[prop].description + " ";
                  }
                }

                setMessage(msgs);
              } else if (resPayment.data.data.erro != undefined) {
                setErrorFormCartao(true);
                setMessage(
                  "Estamos com problema de comunicação com o servidor, tente novamente mais tarde..."
                );
                console.log("Nao foi possivel validar o pagamento.");
              }
            } else {
              setErrorFormCartao(true);
              setMessage(
                "Estamos com problema de comunicação com o servidor, tente novamente mais tarde..."
              );
              console.log("Nao foi possivel enviar o cliente.");
            }
          } else {
            setErrorFormCartao(true);
            setMessage(
              "Estamos com problema de comunicação com o servidor, tente novamente mais tarde..."
            );
            console.log("Autenticação com a API inválida.");
          }
        } else {
          setErrorFormCartao(true);
          setMessage(
            "Estamos com problema de comunicação com o servidor, tente novamente mais tarde..."
          );
          console.log("Erro ao comunicar com o servidor.");
        }
      } else {
        setErrorFormCartao(true);
        setMessage(
          "Estamos com problema de comunicação com o servidor, tente novamente mais tarde..."
        );
        console.log("Erro ao comunicar com o servidor.");
      }

      setSendingPG(false);
    }

    window.scrollTo(0, 950);
  };

  const handleValidaFormBoleto = async (e) => {
    e.preventDefault();
    setErrorFormClient(true);
    setErrorFormCartao(false);
    setErrorFormBoleto(false);
    setSendingPG(true);
    setUrlBoleto("");
    setTipoPagamento(2);

    ReactGA.event({
      category: "Botão - Pagamento da Assinatura Boleto",
      action:
        "Click no botão próximo passo da aba pagamento no preenchimento da assinatura",
    });

    // autentica na api de pgto
    const resAuth = await axios.post(baseURL + "payment-auth", {
      cnpj: cnpj_asaas,
      password: pass_asaas,
    });

    if (resAuth.status == 200) {
      if (resAuth.connection == undefined) {
        if (resAuth.data.token != undefined) {
          const dataCli = {
            cpf_cnpj: replaceAll(replaceAll(usuCPF, ".", ""), "-", ""),
            name: name,
            email: email,
            customer: "",
            system_id: 1,
            user_id: user_asaas,
            token_assas: token_asaas,
          };

          // envia o cliente para obter o customer_id
          const resCli = await axios.post(baseURL + `${path_asaas}cliente-save`, dataCli);

          if (resCli.status == 200) {
            const date = new Date();
            date.setDate(date.getDate());
            let day = date.getDate();
            day = day < 10 ? "0" + day : day;
            let mon = date.getMonth();
            mon = mon + 1 < 10 ? "0" + (mon + 1) : mon + 1;
            let yea = date.getFullYear();
            let dueDate = yea + "-" + mon + "-" + day;

            // BOLETO
            // MENSAL assinatura
            // ANUAL a vista

            let data = "";
            if (opcao === "MONTHLY") {
              // boleto assinatura mes
              data = {
                billingType: "BOLETO",
                customer: resCli.data.data.customer,
                value: parcelasPagamento,
                nextDueDate: dueDate,
                cycle: "MONTHLY",
                endDate: "",
                maxPayments: "",
                description: "Assinatura Fensor mensal com Boleto",
                postalService: false,
                client_id: resCli.data.data.id,
                token_assas: token_asaas,
                holderName: "",
                number: "",
                expiryMonth: "",
                expiryYear: "",
                ccv: "",
                name: name,
                email: email,
                cpfCnpj: replaceAll(replaceAll(usuCPF, ".", ""), "-", ""),
                postalCode: postalCode,
                addressNumber: "",
                phone: replaceAll(
                  replaceAll(
                    replaceAll(
                      replaceAll(replaceAll(usuPhone, "(", ""), ")", ""),
                      " ",
                      ""
                    ),
                    "-",
                    ""
                  ),
                  "_",
                  ""
                ),
                sign: true,
                user: user,
              };
            } else {
              // boleto a vista
              data = {
                billingType: "BOLETO",
                customer: resCli.data.data.customer,
                value: parcelasPagamento,
                dueDate: dueDate,
                description: "Assinatura Fensor a vista através de boleto",
                installmentCount: "",
                installmentValue: "",
                postalService: false,
                client_id: resCli.data.data.id,
                token_assas: token_asaas,
                holderName: "",
                number: "",
                expiryMonth: "",
                expiryYear: "",
                ccv: "",
                name: name,
                email: email,
                cpfCnpj: replaceAll(replaceAll(usuCPF, ".", ""), "-", ""),
                postalCode: postalCode,
                addressNumber: addressNumber,
                phone: replaceAll(
                  replaceAll(
                    replaceAll(
                      replaceAll(replaceAll(usuPhone, "(", ""), ")", ""),
                      " ",
                      ""
                    ),
                    "-",
                    ""
                  ),
                  "_",
                  ""
                ),
                sign: true,
                user: user,
              };
            }

            const url =
              baseURL +
              (opcao === "MONTHLY"
                ? `${path_asaas}payment-boleto-assinatura`
                : `${path_asaas}payment-boleto-vista`);

            // envia pagamento
            const resPayment = await axios.post(url, data);

            if (resPayment.data.data.paymentResponse != undefined) {
              if (
                resPayment.data.data.paymentResponse.status == "PENDING" ||
                resPayment.data.data.paymentResponse.status == "ACTIVE"
              ) {
                window.scrollTo(0, 950);
                document.getElementsByClassName("tab")[1].style.display =
                  "none";
                showTab(2);

                if (resPayment.data.data.paymentResponse.bankSlipUrl)
                  setUrlBoleto(
                    resPayment.data.data.paymentResponse.bankSlipUrl
                  );

                localStorage.setItem(
                  "user",
                  JSON.stringify(resPayment.data.data.user)
                );
                localStorage.setItem(
                  "athlete",
                  JSON.stringify(resPayment.data.data.athlete)
                );
                localStorage.setItem(
                  "menu",
                  JSON.stringify(resPayment.data.data.menu)
                );
                localStorage.setItem(
                  "docs",
                  JSON.stringify(resPayment.data.data.docs)
                );
                localStorage.setItem("sign", true);
              } else {
                setErrorFormBoleto(true);
                setMessage(
                  "Não foi possível gerar o boleto, tente novamente mais tarde..."
                );
              }
            } else if (resPayment.data.data.message != undefined) {
              setErrorFormBoleto(true);

              let msgs = "";
              let prop = "";
              for (prop in resPayment.data.data.message) {
                if (resPayment.data.data.message.hasOwnProperty(prop)) {
                  if (resPayment.data.data.message[prop].message != undefined)
                    msgs += resPayment.data.data.message[prop].message + ", ";
                  else
                    msgs +=
                      resPayment.data.data.message[prop].description + " ";
                }
              }

              setMessage(msgs);
            } else if (resPayment.data.data.erro != undefined) {
              setErrorFormBoleto(true);
              setMessage(
                "Estamos com problema de comunicação com o servidor, tente novamente mais tarde..."
              );
              console.log("Nao foi possivel validar o pagamento.");
            }
          } else {
            setErrorFormBoleto(true);
            setMessage(
              "Estamos com problema de comunicação com o servidor, tente novamente mais tarde..."
            );
            console.log("Nao foi possivel enviar o cliente.");
          }
        } else {
          setErrorFormBoleto(true);
          setMessage(
            "Estamos com problema de comunicação com o servidor, tente novamente mais tarde..."
          );
          console.log("Autenticação com a API inválida.");
        }
      } else {
        setErrorFormBoleto(true);
        setMessage(
          "Estamos com problema de comunicação com o servidor, tente novamente mais tarde..."
        );
        console.log("Erro ao comunicar com o servidor.");
      }
    } else {
      setErrorFormBoleto(true);
      setMessage(
        "Estamos com problema de comunicação com o servidor, tente novamente mais tarde..."
      );
      console.log("Erro ao comunicar com o servidor.");
    }

    setSendingPG(false);
    window.scrollTo(0, 950);
  };

  const setReactGA = (acao, message) => {
    ReactGA.event({
      category: "Botão - Tipo " + acao,
      action:
        "Click no botão " +
        message +
        " da aba obrigado no preenchimento da assinatura",
    });
  };

  if (goBack) {
    return <Redirect to="/home-athlete" />;
  }

  if (login) {
    return <Redirect to="/login" />;
  }

  return (
    <React.Fragment>
      <header className="header-login"></header>

      <main>
        <section className="bloco-principal pg-pagamento-principal">
          <div className="conteudo-bloco-principal">
            <div className="bloco-2">
              <div className="conteudo-generico bloco-pagamento-responsivo">
                <div className="img-pg-pagamento">
                  <img src="/favicon.png" className="img-radius" />
                  <div className="titulo-pg-pagamento">
                    <h3>FAÇA O UPGRADE</h3>
                    <p>Seja visto nas principais seletivas.</p>
                  </div>
                </div>
                <div className="texto-pg-pagamento">
                  <ul>
                    <li style={{ marginBottom: 20, color: "#8e8e8e" }}>
                      SORTEIOS: Concorra a brindes, ingressos, produtos e uma
                      série de benefícios exclusivos.
                    </li>
                    <li style={{ marginBottom: 20, color: "#8e8e8e" }}>
                      EVENTOS: Participe dos melhores eventos e campeonatos de
                      futebol.
                    </li>
                    <li style={{ marginBottom: 20, color: "#8e8e8e" }}>
                      DESCONTOS: Ganhe descontos incríveis em diversos produtos
                      e serviços.
                    </li>
                    <li style={{ marginBottom: 20, color: "#8e8e8e" }}>
                      MAIOR visibilidade.
                    </li>
                    <li style={{ color: "#8e8e8e" }}>
                      Concorra a CAMISAS autografadas.
                    </li>
                  </ul>
                </div>
                <div className="botao-formulario">
                  <div
                    className="botao-avancar-formulario"
                    style={{ justifyContent: "center" }}
                  >
                    {user !== null && (
                      <button
                        type="button"
                        style={{ backgroundColor: "gainsboro" }}
                        onClick={(e) => setGoBack(true)}
                      >
                        voltar para a home
                      </button>
                    )}
                    {user == null && (
                      <button
                        type="button"
                        style={{ backgroundColor: "gainsboro" }}
                        onClick={(e) => setLogin(true)}
                      >
                        Acessar agora
                      </button>
                    )}
                  </div>
                </div>
                <div className="logo-pg-pagamento">
                  <p>Desenvolvido por</p>
                  <img src="../../img/logo-2.png" />
                </div>
              </div>
            </div>
            <div className="bloco-1-secundario">
              <div className="dados-pagamento">
                <div className="coluna-dados-pagamento">
                  <div className="tabs-titulos">
                    <div className="guia-tab-pagamento">
                      <p className="step">1</p>
                      <span>Dados pessoais</span>
                    </div>
                    <div className="guia-tab-pagamento">
                      <p className="step">2</p>
                      <span>Pagamento</span>
                    </div>
                    <div className="guia-tab-pagamento">
                      <p className="step">3</p>
                      <span>Obrigado!</span>
                    </div>
                  </div>
                  <div className="informacoes-pagamento">
                    <form className="tab" onSubmit={handleValidaForm}>
                      {errorFormClient && (
                        <MessageDanger
                          title="Atenção! "
                          description={message}
                        />
                      )}
                      <input
                        value={name}
                        type="text"
                        name="name"
                        required
                        placeholder="Seu nome completo"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <input
                        value={email}
                        type="email"
                        name="email"
                        required
                        placeholder="Seu melhor email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <InputMask
                        value={usuPhone}
                        required
                        type="tel"
                        name="usuPhone"
                        mask="(99)9 9999-9999"
                        disableunderline="true"
                        onChange={(e) => setUsuPhone(e.target.value)}
                        placeholder="Seu celular"
                      ></InputMask>
                      <InputMask
                        value={usuCPF}
                        required
                        type="tel"
                        name="usuCPF"
                        mask="999.999.999-99"
                        disableunderline="true"
                        onChange={(e) => setUsuCPF(e.target.value)}
                        placeholder="Seu CPF"
                      ></InputMask>
                      <div className="botao-formulario">
                        <div className="botao-avancar-formulario">
                          <button type="submit" id="nextBtn">
                            Próximo passo
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="informacoes-pagamento">
                    <div className="tab">
                      <div className="forma-pagamento">
                        <div className="tipo-pagamento">
                          <div className="aba-cartao">
                            <a href="#" onClick={(e) => setCartao(e)}>
                              Cartão
                            </a>
                          </div>
                          <div className="aba-cartao">
                            <a href="#" onClick={(e) => setBoleto(e)}>
                              Boleto
                            </a>
                          </div>
                        </div>
                        <div className="item-tipo-pagamento">
                          <form
                            className="ind-tipo-pagamento"
                            id="item1"
                            onSubmit={handleValidaFormCartao}
                          >
                            <div
                              className="estilo-cartao"
                              style={{ padding: "0px" }}
                            >
                              <div className="col-cartao">
                                <div className="input-forma-pagamento">
                                  {errorFormCartao && (
                                    <MessageDanger
                                      title="Atenção! "
                                      description={message}
                                    />
                                  )}
                                  <input
                                    value={titularCartao}
                                    required
                                    type="text"
                                    name="titularCartao"
                                    placeholder="Títular do cartão"
                                    onChange={(e) =>
                                      setTitularCartao(e.target.value)
                                    }
                                  />
                                  <InputMask
                                    value={numeroCartao}
                                    required
                                    type="tel"
                                    name="numeroCartao"
                                    mask="9999 9999 9999 9999"
                                    disableunderline="true"
                                    onChange={(e) =>
                                      setNumeroCartao(e.target.value)
                                    }
                                    placeholder="Número do cartão"
                                  ></InputMask>
                                </div>
                                <div className="bloco-cartao">
                                  <div className="sobre-cartao">
                                    <div className="col-1-val-cartao">
                                      <label>Data validade</label>
                                    </div>
                                    <div className="col-2-val-cartao">
                                      <div className="input-val-cartao">
                                        <select
                                          name="mesValidade"
                                          required
                                          onChange={(e) =>
                                            setMesValidade(e.target.value)
                                          }
                                        >
                                          <option value="">
                                            -- Selecione --
                                          </option>
                                          <option value="01">01</option>
                                          <option value="02">02</option>
                                          <option value="03">03</option>
                                          <option value="04">04</option>
                                          <option value="05">05</option>
                                          <option value="06">06</option>
                                          <option value="07">07</option>
                                          <option value="08">08</option>
                                          <option value="09">09</option>
                                          <option value="10">10</option>
                                          <option value="11">11</option>
                                          <option value="12">12</option>
                                        </select>
                                      </div>
                                      <div className="input-val-cartao">
                                        <select
                                          name="anoValidade"
                                          required
                                          onChange={(e) =>
                                            setAnoValidade(e.target.value)
                                          }
                                        >
                                          <option value="">
                                            -- Selecione --
                                          </option>
                                          {anos.map((item, i) => (
                                            <option
                                              value={item}
                                              key={"anos" + i}
                                            >
                                              {item}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="sobre-cartao">
                                    <label>Cód. de segurança</label>
                                    <InputMask
                                      value={codSeguranca}
                                      required
                                      type="tel"
                                      name="anoValidade"
                                      mask="9999"
                                      disableunderline="true"
                                      onChange={(e) =>
                                        setCodSeguranca(e.target.value)
                                      }
                                    ></InputMask>
                                  </div>
                                </div>
                              </div>
                              <div className="col-cartao">
                                <div className="img-cartao">
                                  <img src="../../img/pagamento/cartao.png" />
                                </div>
                              </div>
                            </div>
                            <div
                              className="bloco-2-estilo-boleto"
                              style={{ paddingTop: "10px" }}
                            >
                              <div
                                className="col-estilo-boleto"
                                style={{ marginRight: "1%" }}
                              >
                                <label>Opções</label>
                                <select
                                  name="opcao"
                                  required
                                  onChange={(e) => setOpcao(e.target.value)}
                                >
                                  <option value="">Selecione</option>
                                  <option value="YEARLY">Anual</option>
                                  <option value="MONTHLY">Mensal</option>
                                </select>
                              </div>
                              <div
                                className="col-estilo-boleto"
                                style={{ marginRight: "1%" }}
                              >
                                <label>Pagamento</label>
                                {opcao == "" && (
                                  <select
                                    name="parcelasPagamento"
                                    required
                                    onChange={(e) =>
                                      setParcelasPagamento(e.target.value)
                                    }
                                  >
                                    <option value="">Selecione</option>
                                  </select>
                                )}
                                {opcao == "MONTHLY" && (
                                  <select
                                    name="parcelasPagamento"
                                    required
                                    onChange={(e) =>
                                      setParcelasPagamento(e.target.value)
                                    }
                                  >
                                    <option value="">Selecione</option>
                                    <option value="12.90">R$ 12,90</option>
                                  </select>
                                )}
                                {opcao == "YEARLY" && (
                                  <select
                                    name="parcelasPagamento"
                                    required
                                    onChange={(e) =>
                                      setParcelasPagamento(e.target.value)
                                    }
                                  >
                                    <option value="">Selecione</option>
                                    <option value="118.80">
                                      À vista R$ 118,80
                                    </option>
                                    <option value="12.90">
                                      parcelado 12x de R$ 12,90
                                    </option>
                                  </select>
                                )}
                              </div>
                              <div
                                className="col-estilo-boleto"
                                style={{ marginRight: "1%" }}
                              >
                                <label>CEP</label>
                                <input
                                  type="text"
                                  maxLength="8"
                                  name="postalCode"
                                  value={postalCode}
                                  placeholder="Seu cep"
                                  onChange={(e) =>
                                    setPostalCode(e.target.value)
                                  }
                                />
                              </div>

                              <div
                                className="col-estilo-boleto"
                                style={{ marginRight: "1%" }}
                              >
                                <label>Número</label>
                                <input
                                  type="number"
                                  name="addressNumber"
                                  value={addressNumber}
                                  placeholder="Número da sua residência"
                                  onChange={(e) =>
                                    setAddressNumber(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="botao-formulario">
                              <div className="botao-voltar-formulario">
                                <button
                                  type="button"
                                  id="prevBtn"
                                  onClick={(e) => nextPrev(-1)}
                                >
                                  Voltar
                                </button>
                              </div>
                              <div className="botao-avancar-formulario">
                                {sendingPG && (
                                  <span>
                                    Processando o pagamento...{" "}
                                    <Loading type="3" />
                                  </span>
                                )}
                                {!sendingPG && (
                                  <button type="submit" id="nextBtn">
                                    Pagar agora
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                          <form
                            className="ind-tipo-pagamento"
                            id="item2"
                            onSubmit={handleValidaFormBoleto}
                          >
                            <div className="estilo-boleto">
                              <div className="bloco-1-estilo-boleto">
                                <h3>Não se esqueça!</h3>
                                <p>- Boleto (somente à vista)</p>
                                <p>
                                  - Pagamentos com boleto bancário levam até 3
                                  dias úteis para serem compensados.
                                </p>
                                <p>
                                  - Fique atento com o vencimento dos boletos.
                                  Você pode pagá-lo em qualquer banco ou casa
                                  lotérica.
                                </p>
                                <p>
                                  - Depois do pagamento, verifique seu email
                                  para receber os dados de confirmação.
                                </p>
                                {errorFormBoleto && (
                                  <MessageDangerMarginTop
                                    title="Atenção! "
                                    description={message}
                                  />
                                )}
                                <div
                                  className="bloco-2-estilo-boleto"
                                  style={{ paddingTop: "10px" }}
                                >
                                  <div
                                    className="col-estilo-boleto"
                                    style={{ marginRight: "1%" }}
                                  >
                                    <label>Opções</label>
                                    <select
                                      name="opcao"
                                      required
                                      onChange={(e) => setOpcao(e.target.value)}
                                    >
                                      <option value="">Selecione</option>
                                      <option value="YEARLY">Anual</option>
                                      <option value="MONTHLY">Mensal</option>
                                    </select>
                                  </div>
                                  <div
                                    className="col-estilo-boleto"
                                    style={{ marginRight: "1%" }}
                                  >
                                    <label>Pagamento</label>
                                    {opcao == "" && (
                                      <select
                                        name="parcelasPagamento"
                                        required
                                        onChange={(e) =>
                                          setParcelasPagamento(e.target.value)
                                        }
                                      >
                                        <option value="">Selecione</option>
                                      </select>
                                    )}
                                    {opcao == "MONTHLY" && (
                                      <select
                                        name="parcelasPagamento"
                                        required
                                        onChange={(e) =>
                                          setParcelasPagamento(e.target.value)
                                        }
                                      >
                                        <option value="">Selecione</option>
                                        <option value="15.90">R$ 15,90</option>
                                      </select>
                                    )}
                                    {opcao == "YEARLY" && (
                                      <select
                                        name="parcelasPagamento"
                                        required
                                        onChange={(e) =>
                                          setParcelasPagamento(e.target.value)
                                        }
                                      >
                                        <option value="">Selecione</option>
                                        <option value="118.80">
                                          À vista R$ 118,80
                                        </option>
                                      </select>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="botao-formulario">
                              <div className="botao-voltar-formulario">
                                <button
                                  type="button"
                                  id="prevBtn"
                                  onClick={(e) => nextPrev(-1)}
                                >
                                  Voltar
                                </button>
                              </div>
                              <div className="botao-avancar-formulario">
                                {sendingPG && (
                                  <span>
                                    Processando o pagamento...{" "}
                                    <Loading type="3" />
                                  </span>
                                )}
                                {!sendingPG && (
                                  <button type="submit" id="nextBtn">
                                    Pagar agora
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="informacoes-pagamento">
                      <div className="tab">
                        <div className="final-formulario">
                          {tipoPagamento == 1 && (
                            <span>
                              <h3>Parabéns :) </h3>
                              <p style={{ fontSize: "0.95em" }}>
                                Sua assinatura foi concluída com sucesso.
                                <br />
                                <br />
                                Vá em &#128073; <b>ACESSAR AGORA</b> para
                                aproveitar seus benefícios.
                              </p>
                            </span>
                          )}
                          {tipoPagamento == 2 && urlBoleto !== "" && (
                            <span>
                              <h3>
                                Atenção! Falta pouco para confirmar sua
                                assinatura :)
                              </h3>
                              <p style={{ fontSize: "0.95em" }}>
                                Imprima o boleto e realize o pagamento. <br />
                                <br />
                                Vá em &#128073; <b>IMPRIMIR BOLETO</b>
                              </p>
                            </span>
                          )}
                          {tipoPagamento == 2 && urlBoleto === "" && (
                            <span>
                              <h3>
                                Atenção! Falta pouco para confirmar sua
                                assinatura :)
                              </h3>
                              <p style={{ fontSize: "0.95em" }}>
                                Agora enviaremos um SMS e um boleto no seu email
                                para realizar o pagamento, clique abaixo e
                                acesse o sistema.
                              </p>
                            </span>
                          )}
                        </div>
                        <div className="botao-formulario">
                          <div className="botao-avancar-formulario">
                            {tipoPagamento == 1 && (
                              <a
                                href="/advantage-club"
                                onClick={(e) =>
                                  setReactGA("Cartão", "acessar agora")
                                }
                              >
                                ACESSAR AGORA
                              </a>
                            )}
                            {tipoPagamento == 2 && urlBoleto !== "" && (
                              <a
                                href={urlBoleto}
                                download={urlBoleto}
                                onClick={(e) =>
                                  setReactGA("Bolero", "imprimir boleto")
                                }
                                target="_blank"
                              >
                                IMPRIMIR O BOLETO
                              </a>
                            )}
                            {tipoPagamento == 2 && urlBoleto === "" && (
                              <Link
                                to="login"
                                onClick={(e) =>
                                  setReactGA("Bolero", "acessar agora")
                                }
                              >
                                ACESSAR AGORA
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default PagamentoAssinatura;
