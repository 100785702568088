import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Utils from '../../../utils/utils';
import { Skeleton } from '@material-ui/lab';
import { standardizedContractPlanDelete } from '../../../services/ContractStudentService';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import ButtonRounded from '../../../components/buttons/ButtonRounded';

const { currencyFormatter } = Utils();

const descriptionCycle = {
  MONTHLY: 'mensal',
  QUARTERLY: 'trimestral',
  SEMIANNUALLY: 'semestral',
  YEARLY: 'anual',
};
const descriptionTypePayment = {
  CREDIT_CARD: 'cartão de crédito',
  BOLETO: 'boleto',
  MONEY: 'dinheiro',
};

const StyleDefaultPlan = ({ value }) =>
  value === 'S' ? <span className='text-primary font-weight-bold'>Sim</span> : '';

const ContractsRecurring = ({ data = [], loading = true, alertRef }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [isDone, setIsDone] = React.useState(false);
  const [id, setId] = React.useState(null);

  const [dados, setData] = React.useState(data);

  React.useEffect(() => {
    setData(data);

    return () => {
      setData([]);
    };
  }, [data]);

  const handlerDelete = (e) => {
    const btn = e.target.loader();

    const form = new FormData();

    form.append('id', id);

    btn.start();

    standardizedContractPlanDelete(form)
      .then((data) => {
        setIsDone(false);
        setData((prev) => prev.filter((c) => c.id !== id));
      })
      .finally(() => {
        btn.reset();
      });
  };

  const columns = [
    { id: 'name', label: 'Nome', minWidth: 170 },
    { id: 'cycle', label: 'Ciclo', minWidth: 100, align: 'right', format: (value) => descriptionCycle[value] },
    {
      id: 'description',
      label: 'Observação',
      align: 'center',
      format: (value) => (value && value != 'null' ? value : ''),
    },
    { id: 'value', label: 'Valor', minWidth: 100, align: 'right', format: (value) => currencyFormatter(value * 100) },
    { id: 'payment', label: 'Forma de Pgto.', align: 'right', format: (value) => descriptionTypePayment[value] },
    { id: 'status', label: 'Situação', align: 'right', format: (value) => (value ? 'Ativo' : 'Inativo') },
    {
      id: 'default_plan',
      label: 'Padrão',
      align: 'center',
      minWidth: 80,
      format: (value) => <StyleDefaultPlan value={value} />,
    },
    {
      id: 'id',
      align: 'center',
      label: 'Ação',
      minWidth: 120,
      format: (id, row) => (
        <>
          <BtnEdit
            onClick={(e) => {
              history.push('/standardized-plans/create-contract', {
                contract: data.find((c) => c.id === id),
              });
            }}
          />
          {row.default_plan === 'S' ? (
            <BtnDeleteDisabled />
          ) : (
            <BtnDelete
              onClick={(e) => {
                setId(id);
                setIsDone(true);
              }}
            />
          )}
        </>
      ),
    },
  ];

  if (loading) {
    return (
      <TableContainer className='max-to-print' style={{ maxHeight: 500, minHeight: 500 * 0.8 }}>
        <Table stickyHeader size='small' aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRowsLoader rowsNum={columns.length} />
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <SweetAlert
        warning
        showCancel
        confirmBtnBsStyle='danger'
        onConfirm={() => {}}
        focusCancelBtn
        ref={alertRef}
        show={isDone}
        title={t('Attention_exclamation')}
        customButtons={
          <span className='w-100 d-flex justify-content-around align-items-center'>
            <ButtonRounded
              variant='outline'
              color='var(--main-color)'
              isBig={true}
              onClick={() => {
                setIsDone(false);
              }}
            >
              {t('Cancel')}
            </ButtonRounded>
            <ButtonRounded isBig={true} onClick={handlerDelete}>
              Apagar
            </ButtonRounded>
          </span>
        }
      >
        Apagar Plano de Contrato?
      </SweetAlert>
      <TableContainer style={{ maxHeight: 500, minHeight: 500 * 0.8 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dados.map((row, index) => {
              return (
                <TableRow hover key={row.id} tabIndex={-1}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {typeof column.format === 'function' ? column.format(value, row) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

//material ui custom styles --------------------------------------------------------------------------------
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'white',
    color: theme.palette.common.black,
    border: 'none',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const TableRowsLoader = ({ rowsNum }) => {
  return (
    <TableRow>
      {[...Array(rowsNum)].map((row, index) => (
        <TableCell component='th' scope='row' key={index}>
          <Skeleton animation='wave' variant='text' />
        </TableCell>
      ))}
    </TableRow>
  );
};
//private components  --------------------------------------------------------------------------------------
const BtnDelete = ({ fcolor = '#FF2929', w = 16, h = 16, onClick }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer' }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z'
        fill={fcolor}
      />
    </svg>
  </span>
);
const BtnDeleteDisabled = ({ fcolor = '#CCCC', w = 16, h = 16 }) => (
  <span>
    <svg width={w} height={h} viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z'
        fill={fcolor}
      />
    </svg>
  </span>
);

const BtnEdit = ({ fcolor = '#B8872B', w = 16, h = 16, onClick }) => {
  return (
    <span onClick={onClick} className='text-danger mr-2' style={{ cursor: 'pointer' }} role='button'>
      <svg width={w} height={h} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10L16 6V10H14V7H9V2H2V18H8V20H2ZM14.3 12.525L15.375 13.6L11.5 17.45V18.5H12.55L16.425 14.65L17.475 15.7L13.175 20H10V16.825L14.3 12.525ZM17.475 15.7L14.3 12.525L15.75 11.075C15.9333 10.8917 16.1667 10.8 16.45 10.8C16.7333 10.8 16.9667 10.8917 17.15 11.075L18.925 12.85C19.1083 13.0333 19.2 13.2667 19.2 13.55C19.2 13.8333 19.1083 14.0667 18.925 14.25L17.475 15.7Z'
          fill={fcolor}
        />
      </svg>
    </span>
  );
};

export default ContractsRecurring;
