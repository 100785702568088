import React, { useState, useEffect } from 'react'
import Loading from '../../../../../components/Loading'
import Api from '../../../../../utils/api'
import axios from 'axios'
import { Redirect } from 'react-router-dom'

import './Extrato.css'

import Nav from '../../components/nav/Nav'
import CTA from '../../components/Buttons/btn-cta/BtnCTA'
import Footer from '../../components/footer/Footer'

const Extrato = () => {
    const [athleteID, setAthleteID] = useState("")
    const [process, setProcess] = useState(false)
    const [extract, setExtract] = useState([])
    const [login, setLogin] = useState(false)
    const baseURL = Api()

    useEffect(() => {

        async function getSession (){
            const athlete = JSON.parse(await localStorage.getItem('athlete'))
   
            if ( athlete !== null ) {
                setAthleteID(athlete.id)
                get(athlete.id)
            } else {
                setLogin(true)
            }
        }
   
        getSession()
    }, [])

    const get = async (athlete_id) => {
        
        setProcess(true)
        const res = await axios.post(baseURL + 'get-experiences-extract', {
            athlete_id
        })
        setProcess(false)

        setExtract(res.data)
    }

    if ( login ) {
        return <Redirect to="/" />
    }

    return (
        <React.Fragment>
            <Nav />
            <div className="mainContainer">
                <table className="table_extrato">
                    <thead>
                        <tr>
                            <th>prêmio</th>
                            <th>número</th>
                            <th>resgate</th>
                            <th>pontos</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            process &&
                            <tr><td colspan="4" style={{width: '100%', padding: '3%'}}><Loading type="3" /></td></tr>
                        }
                        {
                            extract.map(item => {
                                return <tr key={item.id}>
                                <td>{item.title !== null ? item.title : item.type}</td>
                                <td>{item.title !== null ? item.id : '###'}</td>
                                <td>{item.title !== null ? item.date_valid : '###'}</td>
                                <td style={{color: item.points < 0 ? 'red' : ''}}>{item.points.replace('.00', '')} pts</td>
                            </tr>
                            })
                        }
                    </tbody>
                </table>

                <p className="descript text-bold">
                    Garanta mais vantagens entrando no nosso Clube de Parceiros.
                </p>
                <CTA />
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Extrato 