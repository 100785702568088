import { defaultApiObject, handleFail } from '../utils/utils-api';
import api from './api';

export const getMicrocycleBySchoolId = async (id, onlyActive = false) => {
  try {
    const { data } = await api.get(`get-microcycle-by-school/${id}/${onlyActive}`);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const createMicrocycle = async (values) => {
  try {
    const { data } = await api.post('microcycle', values);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateMicrocycle = async (values) => {
  try {
    const { data } = await api.put('microcycle', values);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateStatusMicrocycle = async (values) => {
  try {
    const { data } = await api.patch('/update-microcycle-status', values);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getMicrocycleByAdminId = async (id, onlyActive = false) => {
  try {
    const { data } = await api.get(`admin-get-microcycle-by-admin/${id}/${onlyActive}`);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const createAdminMicrocycle = async (values) => {
  try {
    const { data } = await api.post('admin-microcycle', values);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateAdminMicrocycle = async (values) => {
  try {
    const { data } = await api.put('admin-microcycle', values);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateStatusAdminMicrocycle = async (values) => {
  try {
    const { data } = await api.patch('/admin-update-microcycle-status', values);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};
