/* eslint-disable react/display-name */
import moment from 'moment';
import React from 'react';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import Utils from '../../utils/utils';
import { SortDownIcon } from '../icons/SortDownIcon';
import { SortNoneIcon } from '../icons/SortNoneIcon';
import { SortUpIcon } from '../icons/SortUpIcon';
import Loading from '../Loading';

/**
 *
 * @param {{type: 'INCOME'| 'EXPENSE'}} param0
 * @returns
 */
const FinancialTable = ({
  mixed,
  type,
  isLoading,
  onEdit,
  onOpenReceipt,
  onOpenSendWhatsApp,
  sortIconState,
  finances,
}) => {
  const { t } = useTranslation();
  const { dateFormatText } = Utils();

  const defaultColHeaderStyle = {
    border: 'none',
    fontSize: '13px',
    color: '#3b3b3b',
  };

  const defaultColRowStyle = {
    alignItems: 'center',
    height: '100%',
    border: 'none',
  };

  const columns = [
    {
      label: `${t('Customer')}/${t('Provider')}`,
      value: ({ client, student, school }) => (client ? client : student ? student : school),

      style: { justifyContent: 'left' },

      colSort: sortIconState ? 'client' : null,
      show: mixed ? true : false,
    },
    {
      label: t(type === 'INCOME' ? 'Customer' : 'Provider'),
      value: ({ client, student, school }) => (client ? client : student ? student : school),

      style: { justifyContent: 'left' },

      colSort: sortIconState ? 'client' : null,
      show: mixed ? false : true,
    },
    {
      label: t('DueDate_abbreviated'),
      value: ({ dueDate }) => dateFormatText(dueDate),

      style: { width: '65px', marginRight: '5px' },

      colSort: sortIconState ? 'dueDate' : null,
      show: true,
    },
    {
      label: t('Installment_abbreviated'),
      value: ({ quota }) => quota,
      style: { width: '55px' },

      colSort: null,
      show: true,
    },
    {
      label: t('PayDay_abbreviated'),
      value: ({ datePayment }) => (datePayment ? dateFormatText(datePayment) : '--'),
      style: { width: '70px', marginRight: '5px', textAlign: 'center', lineHeight: 1 },

      colSort: null,
      show: true,
    },
    {
      label: t('Type'),
      value: ({ order_id, contract_id, contract_id_club }) => (
        <span style={{ fontWeight: 900 }}>{order_id ? 'P' : contract_id || contract_id_club ? 'C' : 'A'}</span>
      ),
      style: { width: '35px' },

      colSort: null,
      show: true,
    },
    {
      label: t('Value'),
      value: ({ value }) => t('FormatFunction.intlCurrencyWithOptions', { value }),
      style: { width: '70px' },

      colSort: null,
      show: mixed ? false : true,
    },
    {
      label: t('Expense'),
      value: ({ value, type }) => (type === 'Despesa' ? t('FormatFunction.intlCurrencyWithOptions', { value }) : 0),
      style: { width: '70px' },

      colSort: null,
      show: mixed ? true : false,
    },
    {
      label: t('Revenue'),
      value: ({ value, type }) => (type === 'Receita' ? t('FormatFunction.intlCurrencyWithOptions', { value }) : 0),
      style: { width: '70px' },

      colSort: null,
      show: mixed ? true : false,
    },
    {
      label: t(type === 'INCOME' ? 'Received_abbreviated' : 'Paid'),
      value: ({ received, value, valueReceived }) =>
        received === 'S' ? t('FormatFunction.intlCurrencyWithOptions', { value: valueReceived || value }) : t('Not'),
      style: { width: '70px', marginRight: '5px' },

      colSort: null,
      show: mixed ? false : true,
    },
    {
      label: t('AmountPaid'),
      value: ({ received, value, valueReceived }) =>
        received === 'S' ? t('FormatFunction.intlCurrencyWithOptions', { value: valueReceived || value }) : '--',
      style: { width: '70px', marginRight: '5px', textAlign: 'center' },

      colSort: null,
      show: mixed ? true : false,
    },
    {
      label: t('Action'),
      value: (finance) => <ChangeButton onClick={onEdit} finance={finance} />,
      style: { width: '60px' },

      colSort: null,
      show: !!onEdit,
    },
    {
      label: t('Receipt'),
      value: (finance) => <ReceiptButton onClick={onOpenReceipt} finance={finance} />,
      style: { width: '60px' },

      colSort: null,
      show: type === 'INCOME' && !!onOpenReceipt,
    },
    {
      label: '',
      value: (finance) => <WhatsAppButton onClick={onOpenSendWhatsApp} finance={finance} />,
      style: { color: 'white', width: '25px', padding: '0px' },

      colSort: null,
      show: type === 'INCOME' && !!onOpenSendWhatsApp,
    },
  ];

  const renderSortIcon = (column) => {
    const { get: sortIcon, set: setSortIcon } = sortIconState || { get: null, set: null };

    if (sortIcon.column === column) {
      if (sortIcon.state === 'up') return <SortUpIcon column={column} onClick={setSortIcon} />;
      if (sortIcon.state === 'down') return <SortDownIcon column={column} onClick={setSortIcon} />;
    } else {
      return <SortNoneIcon column={column} onClick={setSortIcon} />;
    }
  };

  return (
    <Table style={{ boxShadow: 'none', marginTop: '20px', minWidth: '625px' }}>
      <thead>
        <tr style={{ marginBottom: '5px' }}>
          {columns
            .filter(({ show }) => show)
            .map(({ label, colSort, style }, i) => (
              <th key={'FinancialTable_thead_col' + i} style={{ ...defaultColHeaderStyle, ...style }}>
                <span style={{ marginRight: colSort ? '5px' : undefined }}>{label}</span>
                {colSort && renderSortIcon(colSort)}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {isLoading === undefined ? (
          <></>
        ) : isLoading ? (
          <tr
            style={{
              marginBottom: '12px',
              boxShadow: 'rgba(0, 0, 0, 0.05) 1px -2px 13px 1px',
              borderRadius: '10px',
              height: '55px',
            }}
          >
            <td colSpan='6' style={{ paddingTop: 20, justifyContent: 'center' }}>
              <Loading type='1' />
            </td>
          </tr>
        ) : (
          <></>
        )}
        {finances.length == 0 && !isLoading && (
          <tr
            style={{
              marginBottom: '12px',
              boxShadow: 'rgba(0, 0, 0, 0.05) 1px -2px 13px 1px',
              borderRadius: '10px',
              height: '55px',
            }}
          >
            <td colSpan='6' style={{ paddingTop: 20 }}>
              {t('NoRevenueInPeriod')}
            </td>
          </tr>
        )}
        {finances.map((finance, i) => (
          <tr
            key={'FinancialTable_tbody_row' + i}
            style={{
              marginBottom: '12px',
              boxShadow: 'rgba(0, 0, 0, 0.05) 1px -2px 13px 1px',
              borderRadius: '10px',
              height: '55px',
            }}
          >
            {columns
              .filter(({ show }) => show)
              .map(({ value, style }, j) => (
                <td
                  key={`FinancialTable_tbody_col_${i}_${j}`}
                  style={{
                    color: (finance.received === 'S' ? (finance.datePayment > moment().format('YYYY-MM-DD') ? 'blue' : 'green') : finance.received !== 'S' && (finance.dueDate >= moment().format('YYYY-MM-DD') ? 'black' : (finance.dueDate < moment().format('YYYY-MM-DD') && 'red'))),
                    ...defaultColRowStyle,
                    ...style,
                  }}
                >
                  {value(finance)}
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const ChangeButton = ({ onClick, finance }) => {
  const { t } = useTranslation();

  return (
    <span
      className='lowercase'
      onClick={() => onClick(finance)}
      style={{
        cursor: 'pointer',
        marginRight: '1.5%',
        background: (finance.received === 'S' ? (finance.datePayment > moment().format('YYYY-MM-DD') ? 'blue' : 'green') : finance.received !== 'S' && (finance.dueDate >= moment().format('YYYY-MM-DD') ? 'black' : (finance.dueDate < moment().format('YYYY-MM-DD') && 'red'))),
        fontWeight: 400,
        padding: '3px 10px 3px 10px',
        color: 'white',
        borderRadius: '20px',
        width: '68px',
      }}
    >
      {t('Change')}
    </span>
  );
};

const ReceiptButton = ({ onClick, finance }) => {
  const { t } = useTranslation();

  return (
    <span
      className='lowercase'
      onClick={() => onClick(finance)}
      style={{
        cursor: 'pointer',
        background: (finance.received === 'S' ? (finance.datePayment > moment().format('YYYY-MM-DD') ? 'blue' : 'green') : finance.received !== 'S' && (finance.dueDate >= moment().format('YYYY-MM-DD') ? 'black' : (finance.dueDate < moment().format('YYYY-MM-DD') && 'red'))),
        fontWeight: 400,
        padding: '3px 10px 3px 10px',
        color: 'white',
        borderRadius: '20px',
        width: '68px',
      }}
    >
      {t('Receipt')}
    </span>
  );
};

const WhatsAppButton = ({ onClick, finance }) => {
  return (
    <span
      onClick={() => onClick(finance)}
      style={{
        cursor: 'pointer',
        fontWeight: 400,
        color: 'white',
        padding: '2px 2px 2px 2px',
        margin: 'auto',
        marginRight: '0px',
        width: '25px',
      }}
    >
      <img
        style={{
          filter: finance.received === 'S' ? 'grayscale(0%)' : 'grayscale(100%)',
        }}
        src='/img/whatsapp/whatsapp.png'
      />
    </span>
  );
};

export default FinancialTable;
