import React, { useEffect, useState, useRef, useContext } from 'react';
import Message from '../../components/Message';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Table from 'react-bootstrap/Table';
import Utils, { getErrorMessageFromFileResponse } from '../../../src/utils/utils';
import SweetAlert from 'react-bootstrap-sweetalert';
import Collapse from '@material-ui/core/Collapse';
import {
  getDocumentTypes,
  getDocumentStudent,
  uploadDocumentStudent,
  destroyDocumentStudent,
  saveStudentAnamnesisForm,
} from '../../services/StudentService';
import { useTranslation } from 'react-i18next';
import ModalHealthForm from '../../views/ModalHealthForm';
import { baseURL } from '../../utils/api';
import { tokenIdentifier, UserContext } from '../../Context/UserContext';

const TableHeadCell = {
  border: 'none',
  fontSize: '13px',
  color: '#3b3b3b',
  flexGrow: 0,
};
const TableBodyCell = {
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  border: 'none',
  flexGrow: 0,
};

const actionButton = {
  cursor: 'pointer',
  marginRight: '1.5%',
  fontWeight: 400,
  padding: '3px 10px 3px 10px',
  color: 'white',
  borderRadius: '20px',
};

const Documents = () => {
  const { t } = useTranslation();
  const { athlete } = useContext(UserContext)

  const userSession = JSON.parse(localStorage.getItem('athlete'));
  const athleteSession = JSON.parse(localStorage.getItem('athlete'));
  const modalHealthFormRef = useRef(null);

  const [documents, setDocuments] = useState([]);
  const [dirDocument, setDirDocument] = useState('');
  const [documentTypes, setDocumentTypes] = useState([]);
  const [process, setProcess] = useState(false);
  const [athleteId, setAthleteId] = useState();
  const [sweetAlertDisplay, setSweetAlertDisplay] = useState(false);
  const [docId, setDocId] = useState();
  const [docTypeId, setDocTypeId] = useState();
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [displayModalAdd, setDisplayModalAdd] = useState({});
  const [sendDoc, setSendDoc] = useState(false);
  const [errorUploadMessage, setErrorUploadMessage] = useState(false);
  const [isSendingAnamnesisForm, setIsSendingAnamnesisForm] = useState(false);

  const windowScroll = useRef(window);
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  const { dateFormatText, scrollTop } = Utils();

  useEffect(() => {
    const athleteSession = JSON.parse(localStorage.getItem('athlete'));

    setAthleteId(athleteSession.id);
    getDocuments(athleteSession.id);

    const foo = async () => {
      const res = await getDocumentTypes();
      setDocumentTypes(res.documents);
      setDirDocument(res.dir_documents);
    };
    foo();

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeAdd();
    }
  };

  const getDocuments = async (athleteId) => {
    setProcess(true);
    const res = await getDocumentStudent(athleteId);
    setProcess(false);
    setDocuments(
      res.map((doc) => {
        const [date, time] = doc.created_at.split(' ');
        console.log(time);
        return {
          ...doc,
          date: dateFormatText(date),
          time: time.slice(0, 5),
        };
      })
    );
  };

  const openAdd = () => {
    setDisplayModalAdd({
      display: 'flex',
      opacity: 1,
    });
  };

  const closeAdd = () => {
    setDisplayModalAdd({});
  };

  const openSweetAlert = (id) => {
    setSweetAlertDisplay(true);
    setDocId(id);
  };

  const confirmRemove = async () => {
    setSweetAlertDisplay(false);
    setProcess(true);
    const res = await destroyDocumentStudent(docId);

    if (res.success === false) {
      if (res.erro) {
        const msgs = getErrorMessageFromFileResponse(res.erro);
        showErrorMessage(msgs);
      } else {
        showErrorMessage('SystemMessage.File.Fail.' + res.messageKey);
      }
    } else {
      showSuccessMessage('SystemMessage.Success.' + res.messageKey);
      await getDocuments(athleteId);
    }
    setProcess(false);
  };

  const handleUpload = async (e) => {
    setProcess(true);
    setSendDoc(true);
    closeAdd();
    const res = await uploadDocumentStudent({
      athleteID: athleteId,
      documentID: docTypeId,
      athletedocument_id: null,
      file: e.target.files[0],
      from_school: 0,
    });

    if (res.success === false) {
      if (res.erro) {
        const msgs = getErrorMessageFromFileResponse(res.erro);
        showErrorMessage(msgs);
      } else {
        showErrorMessage('SystemMessage.File.Fail.' + res.messageKey);
      }
    } else {
      setDocTypeId();
      showSuccessMessage('SystemMessage.Success.' + res.messageKey);
      await getDocuments(athleteId);
    }
    setProcess(false);
    setSendDoc(false);
  };

  const showSuccessMessage = (message) => {
    setMessage(message);
    setSuccessMessage(true);
    scrollTop(windowScroll);
    setTimeout(() => setSuccessMessage(false), 3000);
  };

  const showErrorMessage = (message) => {
    setMessage(message);
    setErrorMessage(true);
    scrollTop(windowScroll);
    setTimeout(() => setErrorMessage(false), 3000);
  };

  const showErrorUploadMessage = (message) => {
    setMessage(message);
    setErrorUploadMessage(true);
    setTimeout(() => setErrorUploadMessage(false), 3000);
  };

  const showErrorModalUpload = (e) => {
    if (!docTypeId) {
      e.preventDefault();
      showErrorUploadMessage('StudentDocumentsPage.SystemMessage.DocumentTypeIsRequired');
    }
  };

  const onSubmitAnamnesisForm = async (anamnesisForm) => {
    setIsSendingAnamnesisForm(true);
    const { status, messageKey, message } = await saveStudentAnamnesisForm(athleteSession.id, anamnesisForm);
    setIsSendingAnamnesisForm(false);

    modalHealthFormRef.current.scrollTop();
    if (status === 'SUCCESS') {
      modalHealthFormRef.current.showSuccessMessage(messageKey ? t(messageKey) : message);
    } else {
      modalHealthFormRef.current.showErrorMessage(messageKey ? t(messageKey) : message);
    }
  };

  const openPreview = () => {
    const token = localStorage.getItem(tokenIdentifier);

    window.open(
      baseURL + `get-student-certificate-default?token=${token}`,
      '_blank',
      'noopener noreferrer'
    );
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card' style={{ flexDirection: 'column' }}>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('StudentDocumentsPage.ActionCard.Title')}</h2>
                    <p className='card_subtitle'>{t('StudentDocumentsPage.ActionCard.Subtitle')}</p>
                  </div>
                  <div className='card-actions'>
                    <div className='card-action-row'>
                      <button
                        onClick={openAdd}
                        className='btn_card mx-auto mt-4 btn_open_modal'
                        data-modal='#add_aluno'
                      >
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('StudentDocumentsPage.ActionCard.Button')}
                      </button>
                      <button
                        onClick={() => {
                          modalHealthFormRef.current.showModal();
                          modalHealthFormRef.current.clearMessage();
                        }}
                        className='btn_card mx-auto mt-4 btn_open_modal'
                        data-modal='#add_aluno'
                      >
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('AnamnesisForm_abbreviated')}
                      </button>
                    </div>
                  </div>
                </div>
                {athlete.allow_certificate_access && athlete.skillLevel && athlete.skillLevel > 1 && <div className='card' style={{ flexDirection: 'column' }}>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('Certificate')}</h2>
                    <p className='card_subtitle'>{t('StudentDocumentsPage.ActionCard.Subtitle')}</p>
                  </div>
                  <div className='card-actions'>
                    <div className='card-action-row'>
                      <button
                        onClick={openPreview}
                        className='btn_card mx-auto mt-4 btn_open_modal'
                      >
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('StudentDocumentsPage.ButtonCertificate')}
                      </button>
                    </div>
                  </div>
                </div>}
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('StudentDocumentsPage.PageTitle')}</h2>
                    <p className='card_subtitle'>
                      {t('StudentDocumentsPage.PageSubtitle')}
                      <br />
                      <span style={{ color: 'red' }}>{t('StudentDocumentsPage.PageSubtitleWarning')}</span>
                    </p>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Collapse in={successMessage}>
                      <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message)} />
                    </Collapse>

                    <Collapse in={errorMessage}>
                      <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                    </Collapse>
                  </div>
                  <div className='list_card'>
                    <Table
                      style={{
                        boxShadow: 'none',
                        marginTop: '20px',
                      }}
                    >
                      <thead>
                        <tr style={{ marginBottom: '5px' }}>
                          <th
                            style={{
                              ...TableHeadCell,
                              width: '60px',
                            }}
                          >
                            <span style={{ marginRight: '5px' }}>ID</span>
                          </th>
                          <th
                            style={{
                              ...TableHeadCell,
                              flexGrow: 1,
                            }}
                          >
                            <span style={{ marginRight: '5px' }}>{t('DocumentType')}</span>
                          </th>
                          <th
                            style={{
                              ...TableHeadCell,
                              width: '100px',
                              padding: '0px 10px',
                            }}
                          >
                            {t('CreatedAt')}
                          </th>
                          <th
                            style={{
                              ...TableHeadCell,
                              width: '175px',
                            }}
                          >
                            {t('Actions')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {process && (
                          <p className='loading-card'>
                            <Loading type='1' />
                          </p>
                        )}

                        {!process &&
                          documents.map((item, i) => (
                            <tr
                              key={i}
                              style={{
                                marginBottom: '12px',
                                boxShadow: 'rgba(0, 0, 0, 0.05) 1px -2px 13px 1px',
                                borderRadius: '10px',
                                height: '55px',
                              }}
                            >
                              <td
                                style={{
                                  ...TableBodyCell,
                                  width: '60px',
                                }}
                              >
                                {item.idDocument}
                              </td>
                              <td
                                style={{
                                  ...TableBodyCell,
                                  flexGrow: 1,
                                }}
                              >
                                {t('DocumentTypeModel.' + item.docDocument)}
                              </td>
                              <td
                                style={{
                                  ...TableBodyCell,
                                  width: '100px',
                                  flexDirection: 'column',
                                }}
                              >
                                <span>{item.date}</span>
                                <span>{item.time}</span>
                              </td>
                              <td
                                style={{
                                  ...TableBodyCell,
                                  width: '175px',
                                }}
                              >
                                <span
                                  style={{
                                    ...actionButton,
                                    background: 'orange',
                                  }}
                                >
                                  <a
                                    style={{ color: 'white' }}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={`${dirDocument}/${item.atdAthleteDocument}`}
                                  >
                                    {t('View')}
                                  </a>
                                </span>
                                {!item.from_school && (
                                  <span
                                    onClick={() => openSweetAlert(item.idDocumentAthlete)}
                                    style={{
                                      ...actionButton,
                                      background: 'red',
                                      marginRight: '5px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    {t('Delete')}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>

      <div className='modal' id='add_documento' style={displayModalAdd}>
        <div className='modal_content' style={{ width: 500 }}>
          <div className='modal_close' onClick={closeAdd}>
            X
          </div>
          <h3 className='modal_title'>{t('Documents')}</h3>
          {errorUploadMessage && (
            <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
          )}
          <form action='' method='post' className='form_modal'>
            <div className='form_group'>
              <label htmlFor='name' className='card_details_label'>
                {t('Type')}
              </label>
              <select className='form_control' defaultValue={docTypeId} onChange={(e) => setDocTypeId(e.target.value)}>
                <option value=''>-- {t('Select')} --</option>
                {documentTypes.map((item) => (
                  <option key={item.id} value={item.id}>
                    {t('DocumentTypeModel.' + item.docDocument)}
                  </option>
                ))}
              </select>
            </div>
            <div className='form_group' style={{ border: '2px dashed #ddd', marginBottom: 40 }}>
              <label className='carregar-arquivo' style={{ margin: 0 }}>
                {!sendDoc && (
                  <>
                    {t('LoadFile')}
                    <input
                      type='file'
                      name='file'
                      onClick={showErrorModalUpload}
                      onChange={(e) => docTypeId && handleUpload(e)}
                    />
                  </>
                )}
                {sendDoc && <Loading type='1' />}
              </label>
            </div>
          </form>
        </div>
      </div>

      <SweetAlert
        show={sweetAlertDisplay}
        warning
        showCancel
        confirmBtnText={t('Yes')}
        confirmBtnBsStyle='danger'
        cancelBtnText={t('Cancel')}
        title={t('IsSure')}
        onConfirm={confirmRemove}
        onCancel={() => setSweetAlertDisplay(false)}
        focusCancelBtn
      >
        {t('StudentDocumentsPage.SystemMessage.DeleteConfirmation')}
      </SweetAlert>

      <ModalHealthForm
        readOnly={false}
        ref={modalHealthFormRef}
        isLoading={isSendingAnamnesisForm}
        student={{ athleteId: athleteSession.id, name: userSession.name }}
        onSubmit={onSubmitAnamnesisForm}
      />
    </main>
  );
};

export default Documents;
