import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table,
    Tooltip,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
/* import { exportExcelClassReport, getClassReports, getClassSchool } from '../../services/ClassService';
import { defaultCssStyleToPrint, handleOnChange } from '../../utils/utils';
import { useReactToPrint } from 'react-to-print'; */

const TableResumeList = ({ columns, rows, mxh = 500, rowColor, loading = false }) => {
    
    if (loading) {
        return (
            <TableContainer className='max-to-print' style={{ maxHeight: mxh, minHeight: mxh * 0.8 }}>
                <Table stickyHeader size='small' aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRowsLoader rowsNum={columns.length} />
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    return (
        <TableContainer style={{ maxHeight: mxh, minHeight: mxh * 0.8 }}>
            <Table stickyHeader size='small' aria-label='sticky table'>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                {column.label}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate)).map((row, k) => {
                        return (
                            <TableRow hover tabIndex={-1} key={`${k}-${row.key}`}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell style={{ color: column.color === 'function' ? column.color(row) : 'var(--main-color)' }} key={column.id} align={column.align}>
                                            {typeof column.format === 'function' ? column.format(value, row) : value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const TableRowsLoader = ({ rowsNum }) => {
    return (
        <TableRow>
            {[...Array(rowsNum)].map((row, index) => (
                <TableCell component='th' scope='row' key={index}>
                    <Skeleton animation='wave' variant='text' />
                </TableCell>
            ))}
        </TableRow>
    );
};
/*   style={{
    color:
  }} */
//material ui custom styles --------------------------------------------------------------------------------
const StyledTableCell = withStyles((theme) => ({
    head: {
        color: 'var(--main-color)',
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

export default TableResumeList;