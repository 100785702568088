import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/admin/Header';
import Footer from '../../components/admin/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Utils from '../../../src/utils/utils';
import { uploadFolder } from '../../utils/api';
import { createCourse, getAllCourses, updateCourse } from '../../services/CourseService';
import { updateSchoolScreenConfig } from '../../services/PermissionService';

const listCSS = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  background: '#ffffff',
  boxShadow: '0px 5px 15px rgb(0 0 0 / 15%)',
  borderRadius: '0px 0px 10px 10px',
};
const headerCSS = {
  display: 'flex',
  width: '100%',
  boxShadow: '0px 0px 10px rgb(0 0 0 / 15%)',
  borderRadius: '10px 10px 0px 0px',
  borderBottom: 0,
  color: '#000',
};
const headerLabelCSS = {
  margin: 0,
  boxSizing: 'border-box',
  width: '50%',
  padding: '15px',
  fontSize: '16px',
  borderRight: '1px solid #e5e5e5',
};
const headerActionCSS = {
  margin: 0,
  boxSizing: 'border-box',
  width: '205px',
  padding: '15px',
  fontSize: '16px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  display: 'flex',
};
const rowCSS = {
  display: 'flex',
  width: '100%',
  borderBottom: '1px solid #e5e5e5',
  color: '#747272',
  justifyContent: 'start',
  alignItems: 'center',
};
const rowLabelCSS = {
  color: '#747272',
  boxSizing: 'border-box',
  background: 'none',
  width: '50%',
  padding: '15px',
  borderRight: '1px solid #e5e5e5',
  display: 'flex',
  alignItems: 'center',
};
const rowActionCSS = {
  color: '#747272',
  boxSizing: 'border-box',
  background: 'none',
  width: '205px',
  padding: '15px',
  borderRight: '1px solid #e5e5e5',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'flex-start',
};

function Course() {
  //form
  const [courseId, setCourseId] = useState(undefined);
  const [name, setName] = useState('');
  const [type, setType] = useState('VIDEO');
  const [status, setStatus] = useState('A');
  const [currentFileName, setCurrentFileName] = useState('');
  const [newFile, setNewFile] = useState(undefined);
  const [newFileURL, setNewFileURL] = useState('');
  const [videoURL, setVideoURL] = useState('');
  const [description, setDescription] = useState('');

  //filtros
  const [filterStatus, setFilterStatus] = useState('A');
  const [filterType, setFilterType] = useState('ALL');

  // dados
  const [courses, setCourses] = useState([]);
  const [coursesFiltered, setCoursesFiltered] = useState([]);
  const [schools, setSchools] = useState([]);
  const [schoolsConfig, setSchoolsConfig] = useState([]);
  const [permissionId, setPermissionId] = useState(undefined);

  //interface
  const [display, setDisplay] = useState(undefined);
  const [displayConfigSchool, setDisplayConfigSchool] = useState(undefined);

  // process
  const [isLoading, setIsLoading] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  // libraries
  const { scrollTop, setThemeSchool } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  let modalScroll = useRef();
  const uploadURL = uploadFolder();

  useEffect(() => {
    setThemeSchool({
      defaultColor: '#ba8820',
      mainBackground: 'bg_login_qa.jpg',
    });

    fetchAllCourses();

    window.addEventListener('keydown', closeModalOnEscape);

    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') closeAdd();
  };

  const fetchAllCourses = async () => {
    setIsLoading(true);
    const { success, courses, schools, schoolsConfig, permissionId, error } = await getAllCourses();
    setIsLoading(false);

    if (success) {
      setCourses(courses);
      setCoursesFiltered(courses);
      setSchools(schools);
      setSchoolsConfig(schoolsConfig.map((schoolConfig) => schoolConfig.school_id));
      setPermissionId(permissionId);
    } else {
      console.log(error.message);
    }
  };

  const search = (e) => {
    e && e.preventDefault();

    setCoursesFiltered(
      courses
        .filter((course) => course.status === filterStatus)
        .filter((course) => (filterType === 'ALL' ? true : course.type === filterType))
    );
  };

  const openAdd = () => {
    clearMessage();

    setCourseId(undefined);
    setName('');
    setType('VIDEO');
    setStatus('A');
    setCurrentFileName('');
    setNewFile(undefined);
    setNewFileURL('');
    setVideoURL('');
    setDescription('');

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const edit = (club) => {
    clearMessage();

    setCourseId(club.id);
    setName(club.name);
    setType(club.type);
    setStatus(club.status);
    setCurrentFileName(club.audioFilename);
    setNewFile(undefined);
    setNewFileURL('');
    setVideoURL(club.videoURL);
    setDescription(club.description);

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const closeAdd = () => {
    setDisplay(undefined);
  };

  const openModalConfigSchool = () => {
    clearMessage();

    setDisplayConfigSchool({
      display: 'flex',
      opacity: 1,
    });
  };

  const closeModalConfigSchool = () => {
    clearMessage();

    setDisplayConfigSchool(undefined);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();

    if (type === 'AUDIO' && currentFileName === '' && newFile === undefined)
      return setErrorMessage('Escolha o arquivo de audio');

    const formCourse = {
      id: courseId,
      name: name,
      type: type,
      videoURL: videoURL,
      audioFilename: currentFileName,
      description: description,
      status: status,
      newFile: newFile,
    };

    setIsLoading(true);
    const { success, course, courses, message, error } =
      courseId === undefined ? await createCourse(formCourse) : await updateCourse(formCourse);
    setIsLoading(false);

    scrollTop(modalScroll);

    if (success) {
      setCourseId(course.id);
      setCourses(courses);
      setCoursesFiltered(courses);
      setSuccessMessage(message);
    } else if (error.type === 'crud') {
      setErrorMessage(error.message);
    } else if (error.type === 'validation' || error.type === 'file') {
      setErrorMessage(error.message);

      setCourseId(course.id);
      setCourses(courses);
      setCoursesFiltered(courses);
    } else {
      setErrorMessage(error.message);
    }
  };

  const fillPermissionIds = (checked) => {
    if (checked) setSchoolsConfig(schools.map((school) => school.id));
    else setSchoolsConfig([]);
  };

  const changeSchoolConfigId = (checked, value) => {
    console.log(checked);
    console.log(value);

    if (checked) setSchoolsConfig([...schoolsConfig, +value]);
    else setSchoolsConfig([...schoolsConfig.filter((id) => id !== +value)]);
  };

  const handleSubmitConfigSchool = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const { success } = await updateSchoolScreenConfig(schoolsConfig, permissionId);
    setIsLoading(false);

    if (success) setSuccessMessage('Configuração salva com sucesso!');
    else setErrorMessage('Erro ao salvar a configuração.');
  };

  const setErrorMessage = (message) => {
    setMessage(message);
    setError(true);
    scrollTop(modalScroll);
  };

  const setSuccessMessage = (message) => {
    setMessage(message);
    setSuccess(true);
    scrollTop(modalScroll);
  };

  const clearMessage = () => {
    setSuccess(false);
    setError(false);
    setMessage('');
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <Header />
            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Adicionar cursos</h2>
                    <p className='card_subtitle'>Clique abaixo para adicionar um novo curso.</p>
                  </div>
                  <button onClick={openAdd} className='btn_card mx-auto mt-4 btn_open_modal'>
                    <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                    </svg>
                    Adicionar curso
                  </button>
                </div>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Configurar escolas</h2>
                    <p className='card_subtitle'>
                      Clique abaixo para configurar as escolas que tem acesso a essa funcionalidade.
                    </p>
                  </div>
                  <button onClick={openModalConfigSchool} className='btn_card mx-auto mt-4 btn_open_modal'>
                    <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                    </svg>
                    Configurar escolas
                  </button>
                </div>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Procurar</h2>
                    <p className='card_subtitle'>Filtrar os cursos cadastrados.</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='tel' className='card_details_label'>
                        Status
                      </label>
                      <select
                        required
                        value={filterStatus}
                        className='form_control select'
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value='A'>Ativo</option>
                        <option value='I'>Inativo</option>
                      </select>
                      <div />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='tel' className='card_details_label'>
                        Tipo
                      </label>
                      <select
                        required
                        value={filterType}
                        className='form_control select'
                        onChange={(e) => setFilterType(e.target.value)}
                      >
                        <option value='ALL'>Todos</option>
                        <option value='AUDIO'>Áudio</option>
                        <option value='VIDEO'>Vídeo</option>
                      </select>
                      <div />
                    </div>
                    <button className='btn_card mx-auto' onClick={search}>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      Buscar
                    </button>
                  </form>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Cursos</h2>
                    <p className='card_subtitle'>Veja abaixo a sua lista de cursos cadastrados.</p>
                  </div>

                  <div className='list_card'>
                    {isLoading && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!isLoading &&
                      coursesFiltered.map((course) => (
                        <div className='card_list_turma' key={`${course.id}`}>
                          <div className='content_card'>
                            <div className='card_details'>
                              <p className='card_details_label text_uppercase' style={{ flexDirection: 'row' }}>
                                <span style={{ color: '#3b3b3b' }}>{course.name}</span>
                              </p>
                              <div style={{ display: 'flex' }} id='botoesEditarTurma'>
                                <p
                                  className='card_details_label text_uppercase'
                                  style={{ width: '50px', margin: 'auto', marginRight: '10px', color: '#3B3B3B' }}
                                >
                                  {course.type === 'AUDIO' ? 'Áudio' : 'Vídeo'}
                                </p>
                                <p
                                  className='card_details_label text_uppercase'
                                  style={{ width: '50px', margin: 'auto', marginRight: '10px', color: '#3B3B3B' }}
                                >
                                  {course.status === 'A' ? (
                                    <span className='badge badge-success'>Ativo</span>
                                  ) : (
                                    <span className='badge badge-danger'>Inativo</span>
                                  )}
                                </p>
                                <span
                                  onClick={() => edit(course)}
                                  className='btn_card'
                                  style={{
                                    marginRight: '1.5%',
                                    background: 'orange',
                                    fontWeight: 500,
                                    border: '1px solid orange',
                                  }}
                                >
                                  <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                                  </svg>
                                  alterar
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className='modal' style={display}>
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeAdd}>
                    X
                  </div>
                  <h3 className='modal_title'>{courseId === undefined ? 'Adicionar curso' : 'Atualizar curso'}</h3>
                  {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
                  {success && <MessageSuccessMarginTop title='Sucesso! ' description={message} />}
                  <form onSubmit={handleSubmit} method='post' className='form_modal'>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          Nome do curso
                        </label>
                        <input
                          type='text'
                          required
                          className='form_control'
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='email' className='card_details_label'>
                            Tipo de mídia
                          </label>
                          <select
                            required
                            value={type}
                            className='form_control select'
                            onChange={(e) => setType(e.target.value)}
                          >
                            <option value='AUDIO'>Áudio</option>
                            <option value='VIDEO'>Vídeo</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            Status
                          </label>
                          <select
                            required
                            value={status}
                            className='form_control select'
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value='A'>Ativo</option>
                            <option value='I'>Inativo</option>
                          </select>
                          <div />
                        </div>
                      </div>
                    </div>

                    <div className='form_group'>
                      <label className='card_details_label'>
                        {type === 'AUDIO' ? 'Arquivo de áudio' : 'URL do vídeo'}
                      </label>
                      {type === 'AUDIO' ? (
                        <div style={{ display: 'flex' }}>
                          <label
                            htmlFor='newFile'
                            className='btn_card'
                            style={{
                              backgroundColor: '#AAAAAA',
                              fontWeight: 500,
                              border: '1px solid #AAAAAA',
                              height: '28px',
                              width: '150px',
                              minWidth: '150px',
                              marginTop: '10px',
                              fontSize: '0.8rem',
                            }}
                          >
                            Escolher arquivo
                          </label>
                          <input
                            id='newFile'
                            name='newFile'
                            type='file'
                            accept='audio/*,image/*'
                            className='form_control'
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                URL.revokeObjectURL(newFileURL);
                                setNewFileURL(URL.createObjectURL(e.target.files[0]));
                                setNewFile(e.target.files[0]);
                              }
                            }}
                            style={{ border: 'none', padding: '8px 15px 0px 0px' }}
                          />
                          <a
                            className='link-normal'
                            target='_blank'
                            rel='noopener noreferrer'
                            href={newFileURL ? newFileURL : `${uploadURL}upload/schools/course/${currentFileName}`}
                            style={{ margin: '13px 0px 0px 10px', cursor: 'pointer' }}
                          >
                            {newFile ? (
                              <>
                                <span className='marker' />
                                {newFile.name}
                              </>
                            ) : (
                              currentFileName && (
                                <>
                                  <span className='marker' />
                                  Arquivo de audio
                                </>
                              )
                            )}
                          </a>
                        </div>
                      ) : (
                        <input
                          type='text'
                          required
                          className='form_control'
                          value={videoURL}
                          onChange={(e) => setVideoURL(e.target.value)}
                        />
                      )}
                    </div>

                    <div className='form_group'>
                      <label className='card_details_label'>Descrição</label>
                      <textarea
                        required
                        className='form_control'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>

                    {!isLoading && (
                      <button className='btn_card mx-auto'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {courseId === undefined ? 'Adicionar curso' : 'Atualizar curso'}
                      </button>
                    )}
                    {isLoading && (
                      <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none' }} disabled>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        processando...
                        <Loading type='1' style={{ marginLeft: '2%' }} />
                      </button>
                    )}
                  </form>
                </div>
              </div>

              <div className='modal' style={displayConfigSchool}>
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeModalConfigSchool}>
                    X
                  </div>
                  <h3 className='modal_title'>Configurar escolas</h3>
                  {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
                  {success && <MessageSuccessMarginTop title='Sucesso! ' description={message} />}
                  <form onSubmit={handleSubmitConfigSchool} method='post' className='form_modal'>
                    <div style={listCSS}>
                      <div style={headerCSS}>
                        <span style={headerLabelCSS}>Razão Social</span>
                        <span style={headerLabelCSS}>Nome Fantasia</span>
                        <div style={headerActionCSS}>
                          <input
                            type='checkbox'
                            checked={schools.length === schoolsConfig.length}
                            onChange={(event) => fillPermissionIds(event.target.checked)}
                            disabled={false}
                          />
                          <span style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>Marcar todos?</span>
                        </div>
                      </div>
                      {isLoading && (
                        <p className='loading-card' style={{ margin: '5px' }}>
                          <Loading type='1' />
                        </p>
                      )}
                      {!isLoading &&
                        schools.map((school) => (
                          <div key={school.id} style={rowCSS}>
                            <span style={rowLabelCSS}>{school.schName}</span>
                            <span style={rowLabelCSS}>{school.schFantasia}</span>
                            <div style={rowActionCSS}>
                              <input
                                type='checkbox'
                                checked={schoolsConfig.includes(school.id)}
                                onChange={(event) => changeSchoolConfigId(event.target.checked, event.target.value)}
                                defaultValue={school.id}
                                disabled={false}
                              />
                              <span style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>
                                permitir acesso
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>

                    {!isLoading && (
                      <button className='btn_card mx-auto'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        Salvar configuração
                      </button>
                    )}
                    {isLoading && (
                      <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none' }} disabled>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        processando...
                        <Loading type='1' style={{ marginLeft: '2%' }} />
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Course;
