import apiWhatsapp from '../config/api.whatsapp.default';
import { defaultApiObject, handleFail } from '../utils/utils-api';
import api from './api';

export const getIdSchoolWhatsApp = async () => {
  try {
    const schoolSession = JSON.parse(await localStorage.getItem('school'));

    // Pega o id da escola no sistema, caso a escola não exista é criado um novo registro
    var data_school_whatsapp = {
      departamento: {
        nome: `${schoolSession.id} - ${schoolSession.schName}`,
        segmento: 'API - INTEGRAÇÃO',
        responsavel: 'Fensor',
      },
    };

    var result_create_school = await apiWhatsapp.post('/departamento', data_school_whatsapp);

    return result_create_school.data;
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const initWhatsAppSession = async () => {
  try {
    const { departamento } = await getIdSchoolWhatsApp();

    var { data } = await apiWhatsapp.post('/whatsapp', {
      sessionId: departamento._id,
      multidevice: true,
      nome_whatsapp: 'Fensor',
    });

    return data;
  } catch (e) {
    console.log(e);
    return { error: true };
  }
};

export const createWhatsAppSession = async (_, setter) => {
  try {
    const { departamento } = await getIdSchoolWhatsApp();
    const { data } = await apiWhatsapp.get(`/whatsapp/qrcode/${departamento._id}`);
    setter({ conected: data.isConected, qrCode: data.qrCode, session: data.session });
    return data;
  } catch (error) {
    return error;
  }
};

export const closeWhatsAppSession = async () => {
  try {
    const { departamento } = await getIdSchoolWhatsApp();
    const { data } = await apiWhatsapp.get(`/whatsapp/pausar/${departamento._id}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const removeWhatsAppSession = async () => {
  try {
    const { departamento } = await getIdSchoolWhatsApp();
    const { data } = await apiWhatsapp.delete(`/whatsapp/${departamento._id}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const sendWhatsAppMessage = async ({ _, numbersList, message }) => {
  try {
    const { departamento } = await getIdSchoolWhatsApp();

    var data_mensagem = {
      listNumbers: numbersList,
      mensagens: [
        {
          mensagem: {
            type: 'text',
            mensagem: message,
          },
        },
      ],
    };

    const { data } = await apiWhatsapp.post(`/whatsapp/sendMessageArray/${departamento._id}`, data_mensagem);

    if (data.length) return { success: false, ...data };
    else return { success: true, ...data };
  } catch (error) {
    return { success: false, erro: error };
  }
};

export const sendWhatsAppMessageWithFile = async ({ _, numbersList, messageOptional, fileLink, mimeType }) => {
  try {
    const { departamento } = await getIdSchoolWhatsApp();

    var data_mensagem = {
      listNumbers: numbersList,
      ...(mimeType ? { mimeType } : undefined),
      mensagens: [
        {
          mensagem: {
            type: 'file',
            mensagem: fileLink,
            text_option: messageOptional,
          },
        },
      ],
    };

    const { data } = await apiWhatsapp.post(`/whatsapp/sendMessageArray/${departamento._id}`, data_mensagem);

    if (data.length) return { success: false, ...data };
    else return { success: true, ...data };
  } catch (error) {
    return { success: false, erro: error };
  }
};

export const sendWhatsAppMessageWithFileAlt = async ({ _, numbersList, message, fileLink, mimeType }) => {
  try {
    const { departamento } = await getIdSchoolWhatsApp();

    var data_mensagem = {
      listNumbers: numbersList,
      ...(mimeType ? { mimeType } : undefined),
      mensagens: [
        {
          mensagem: {
            type: 'text',
            mensagem: message,
          },
        },
        ...(Array.isArray(fileLink)
          ? fileLink.map((f) => ({
              mensagem: {
                type: 'file',
                mensagem: f,
              },
            }))
          : [
              {
                mensagem: {
                  type: 'file',
                  mensagem: fileLink,
                },
              },
            ]),
      ],
    };

    const { data } = await apiWhatsapp.post(`/whatsapp/sendMessageArray/${departamento._id}`, data_mensagem);

    if (data.length) return { success: false, ...data };
    else return { success: true, ...data };
  } catch (error) {
    return { success: false, erro: error };
  }
};

export const getUserPhoneNumbers = async ({ ids, type }) => {
  try {
    const { data } = await api.post('/school/user/whatsapp-phone', { ids, type });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};
