import React, { useEffect, useState } from 'react';
import Header from '../../components/club/Header';
import Footer from '../../components/club/Footer';
import Utils from '../../../src/utils/utils';
import Logo from '../../components/school/Logo';
import Api from '../../utils/api';
import axios from 'axios';
import SchoolCard from '../../components/cards/SchoolCard';

const DetailSchool = (props) => {
  const [countClass, setCountClass] = useState('');
  const [classs, setClasss] = useState([]);
  const [schoolCardData, setSchoolCardData] = useState([]);
  const { formatCNPJ, formatTelefone, diffDaysNow, formatCPF } = Utils();
  const baseURL = Api();

  useEffect(() => {
    (async () => {
      axios.post(baseURL + 'get-count-class-by-school', { id: props.location.state.school.id }).then((resp) => {
        setCountClass(resp.data.countClass);
        setClasss(resp.data.classs);
        setSchoolCardData(resp.data.schoolCardData);
      });
    })();
  }, []);

  const calcTimeSchool = (date) => {
    const dataCreated = date.split(' ')[0];
    const dias = diffDaysNow(dataCreated);
    return dias;
  };

  function formatTimeInMonthsAndDays(days) {
    const months = Math.floor(days / 30);
    const remainingDays = days % 30;
    const monthLabel = months === 1 ? 'mês' : 'meses';

    if (remainingDays === 0) {
      return `${months} ${monthLabel}`;
    } else {
      return `${months} ${monthLabel} e ${remainingDays} dias`;
    }
  }

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='img_school'>
                    <Logo schLogomarca={props.location.state.school.schLogomarca} />
                  </div>
                  <h2 className='card_title mx-auto'>{props.location.state.school.name}</h2>
                  <div className='content_card'>
                    <div className='card_details'>
                      <p className='card_details_label'>
                        {props.location.state.school.cpf_cnpj.length === 14 ? 'CNPJ: ' : 'CPF: '}
                      </p>
                      <p className='card_details_text'>
                        {props.location.state.school.cpf_cnpj.length === 14
                          ? formatCNPJ(props.location.state.school.cpf_cnpj)
                          : formatCPF(props.location.state.school.cpf_cnpj)}
                      </p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>Responsável: </p>
                      <p className='card_details_text'>{props.location.state.school.schResponsavel}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>Telefone: </p>
                      <p className='card_details_text'>
                        {formatTelefone(String(props.location.state.school.schPhone))}
                      </p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>E-mail: </p>
                      <p className='card_details_text'>{props.location.state.school.email}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>Localizacao: </p>
                      <p className='card_details_text'>{props.location.state.school.schLocalizacao}</p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>Tempo de escola</h2>
                    <div className='sidebar_data'>
                      {formatTimeInMonthsAndDays(calcTimeSchool(props.location.state.school.created_at))}
                    </div>
                  </div>
                  <hr />
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>Turmas cadastradas</h2>
                    <div className='sidebar_data'>{countClass}</div>
                  </div>
                </div>
              </div>

              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Dados </h2>
                  </div>
                  <div className='content_card aluno_content'>
                    <div className='card_details'>
                      <p className='card_details_label'>Data de criação: </p>
                      <p className='card_details_text'>
                        {props.location.state.school.created_at.substr(0, 10).split('-').reverse().join('/')}
                      </p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>Endereço: </p>
                      <p className='card_details_text'>
                        {props.location.state.school.schLogradouro},{' ' + props.location.state.school.schNumero},
                        {' ' + props.location.state.school.schBairro},
                        {' ' + props.location.state.school.schLocalizacao + ' '}-
                        {' ' + props.location.state.school.schEstado}
                      </p>
                    </div>
                  </div>
                  <div className='content_card aluno_content'>
                    <div className='card_header'>
                      <h2 className='card_title'>Sobre </h2>
                    </div>
                    <div className='content_card aluno_content'>
                      <div className='card_details'>
                        <p className='card_details_text'>{props.location.state.school.schSobre}</p>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  schoolCardData[0] ?
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 20, marginTop: 20 }}>
                      {schoolCardData[0] && (
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', justifyContent: 'center' }}>
                          <SchoolCard title={"TOTAL DE ALUNOS"} value={schoolCardData[0].studentsMale + schoolCardData[0].studentsFemale} subtitle={"Total de alunos de todas as escolas da turma"} />
                          <SchoolCard title={"ALUNOS ATIVOS"} value={schoolCardData[0].studentsMale} subtitle={"Total de alunos ativos na escola do clube"} />
                          <SchoolCard title={"FREQUENCIA MEDIA MENSAL"} value={schoolCardData[0].averageFrequency} subtitle={"Média da frequencia mensal da escola"} />
                          <SchoolCard title={"ALUNAS ATIVAS"} value={schoolCardData[0].studentsFemale} subtitle={"Total de alunas ativos na escola do clube"} />
                        </div>
                      )}
                    </div> :
                    <div style={{ marginBottom: 20 }}><h6>Carregando dados</h6> </div>
                }

                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Turmas</h2>
                    <p className='card_subtitle'>Veja abaixo as turmas cadastrados desta escola. </p>
                  </div>
                  <div className='list_card'>
                    {classs && classs.length > 0 && classs.map((item, i) => (
                      <div className='card_list_turma' key={`${i}`}>
                        <div className='content_card'>
                          <div className='card_details'>
                            <p className='card_details_label text_uppercase' style={{ flexDirection: 'row' }}>
                              {item.className} -
                              <span style={{ color: '#3b3b3b' }}>&nbsp;{item.dayName}&nbsp;-&nbsp;</span>
                              {item.hour}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default DetailSchool;
