import React from 'react';
import Loading from '../Loading';
import { RiArrowDownSLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

const CardIndicator = ({ isLoading, title, subtitle, valueIndicator, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className='card_header mx-auto' style={{ alignItems: 'center', height: '187px' }}>
      <h2 className='card_title' style={{ fontSize: '1.175rem' }}>
        {title}
      </h2>
      {isLoading ? (
        <p className='loading-card sidebar_data' style={{ height: '95px' }}>
          <Loading type='1' />
        </p>
      ) : (
        <div className='sidebar_data' style={{ fontSize: 40 }}>
          {valueIndicator ?? '--'}
        </div>
      )}

      <div
        onClick={onClick}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: onClick ? 'pointer' : 'auto' }}
      >
        <p style={{ color: '#808080', textAlign: 'center', marginBottom: 10, opacity: 0.5, height: '48px' }}>
          {subtitle}
        </p>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '49px' }}>
          {!!onClick && (
            <>
              <p style={{ color: '#808080', textAlign: 'center', textTransform: 'lowercase' }}>{t('ShowMore')}</p>
              <RiArrowDownSLine size={30} color='#808080' style={{ marginTop: -5 }} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardIndicator;
