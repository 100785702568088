import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import utils from '../../utils/utils'
import Logoff from '../Logoff'
import EditPassword from '../EditPassword'

const Header = (props) => {
   const { openNav, closeNav } = utils()
   const [menus, setMenus] = useState([])
   const user  = JSON.parse(localStorage.getItem('user'))
   const menu = JSON.parse(localStorage.getItem('menu'))
   const shoppingCart = JSON.parse(localStorage.getItem('shoppingCart'))
   let counts = shoppingCart !== null ? shoppingCart.length : 0

   useEffect(()=>{
      if ( menu != null )
         setMenus(menu)
   },[])

   return (
         <header className="navbar">
            <div className="conteudo-navbar">
               <div className="coluna-1-navbar">
                  <Link to="/home-athlete" className="logo-navbar">
                     <img src="../../img/logo.png" alt="Marca" />
                  </Link>

                  <div className="aviso-navbar">
                     <div className="texto-aviso-navbar">
                        <p>Preencha seu currículo para desbloquear as seletivas.</p>
                     </div>

                     <div className="exit-aviso-navbar">
                        <img src="../../img/icones/exit.png" />
                     </div>
                  </div>
               </div>

               <div className="coluna-2-navbar">
                  <div className="menu-mobile">
                     <ul id="mySidenav" className="sidenav">
                        <a href="" className="closebtn" onClick={closeNav}><img src="../../img/icones/exit.png" /></a>
                        {
                           props.type == 1 && user !== null &&
                           <li className="vestiario-mobile"><Link to="/vestiario-athlete">Seu vestiário</Link></li>  
                        }
                        {
                           props.type == 2 && props.menu == false &&
                           <li className="vestiario-mobile"><Link to="/login">Efetue o login</Link></li>  
                        }
                        {
                           props.type == 2 && props.menu == true &&
                           <li className="vestiario-mobile"><Link to="/home-athlete">Painel central</Link></li>  
                        }
                        {
                           props.type == 3 && props.menu == false &&
                           <li className="vestiario-mobile"><Link to="/seu-carrinho">Seu carrinho</Link></li>  
                        }
                        {
                           props.type == 4 && props.menu == true &&
                           <li className="vestiario-mobile"><Link to="/photosalbum">Voltar para galeria</Link></li>  
                        }
                        {
                           menus.map(item => {
                                 return item.rolModule == 'AA' && <li key={item.rolPermissions}><Link id={ (window.location.pathname.substr(1) == item.rolClass ? 'menu-ativado' : '') }  to={"/" + item.rolClass}>{item.rolItem}</Link></li>
                              }
                           )
                        }
                        <li><Link to="/advantage-club">Clube de Vantagens</Link></li>
                        <li><Link to="/photosalbum">Fensor flash</Link></li>
                     </ul>

                     <span className="abrir-menu-mobile" onClick={openNav}><img src="../../img/icones/menu-mobile.png" /></span>
                  </div>

                  <div className="vestiario-navbar">
                     {
                        props.type == 1 &&
                           <a href="#" className="informacao-vestiario-navbar">
                              <div className="img-vestiario-navbar">
                                 <img src="../../img/perfil/foto-perfil.png" />
                              </div>
                              <div className="titulo-vestiario-navbar">
                                 {
                                    user !== null &&
                                    <Link to={`/vestiario/` + user.id}><p>Seu vestiário</p></Link>
                                 }
                              </div>
                           </a>
                     }
                     {
                        props.type == 2 && props.menu == false &&
                           <a href="#" className="informacao-vestiario-navbar">
                              <div className="img-vestiario-navbar">
                                 <img src="../../img/perfil/foto-perfil.png" />
                              </div>
                              <div className="titulo-vestiario-navbar">
                                 <Link to={`/login`}><p>Efetue o login</p></Link>
                              </div>
                           </a>
                     }
                     {
                        props.type == 2 && props.menu == true &&
                           <a href="#" className="informacao-vestiario-navbar info-painel">
                              <div className="img-vestiario-navbar">
                                 <img src="../../img/icones/painel.png" />
                              </div>
                              <div className="titulo-vestiario-navbar">
                                 <Link to="/home-athlete"><p>Painel central</p></Link>
                              </div>
                           </a>
                     }
                     {
                        props.type == 3 && props.menu == false &&
                           <a href="#" className="informacao-vestiario-navbar">
                              <div className="img-vestiario-navbar">
                                 <img src="../../img/perfil/foto-perfil.png" />
                                 <div className="seu-carrinho">{counts}</div>
                              </div>
                              <div className="titulo-vestiario-navbar">
                                 <Link to={`/login`}><p>Seu carrinho</p></Link>
                              </div>
                           </a>
                     }
                     {
                        props.type == 3 && props.menu == true &&
                           <a href="#" className="informacao-vestiario-navbar info-painel">
                              <div className="img-vestiario-navbar">
                                 <img src="../../img/icones/carrinho.png" />
                                 <div className="seu-carrinho">{counts}</div>
                              </div>
                              <div className="titulo-vestiario-navbar">
                                 {
                                    counts > 0 &&
                                    <Link to={`/seu-carrinho`}><p>Seu carrinho </p></Link>
                                 }
                                 {
                                    counts == 0 &&
                                    <p>Seu carrinho</p>
                                 }
                              </div>
                           </a>
                     }
                     {
                        props.type == 4 && props.menu == true &&
                           <a href="#" className="informacao-vestiario-navbar voltar-galeria">
                              <div className="titulo-vestiario-navbar">
                                 <Link to={`/photosalbum`}><p>Voltar para galeria </p></Link>
                              </div>
                           </a>
                     }
                  </div>

                  <div className="configuracao-navbar">
                     <button className="icon-configuracao-navbar"><img src="../../img/icones/icone-configuracao.png" /></button>

                     {
                        user !== null && 
                        <ul className="links-dropdown">
                           <li><EditPassword user_id={user.id} /></li>
                           <li><Logoff /></li>
                        </ul> 
                     }
                     
                  </div>
               </div>
            </div>

            <div className="menu-navbar">
               {
                  props.menu == true && 
                  <ul className="links-menu-navbar">
                     <div className="coluna-menu-navbar">
                     {
                        menus.map(item => {
                              return item.rolModule == 'AA' && <li key={item.rolPermissions}><Link id={ (window.location.pathname.substr(1) == item.rolClass ? 'menu-ativado' : '') } to={"/" + item.rolClass}>{item.rolItem}</Link></li>
                           }
                        )
                     }
                     </div>

                     <div className="coluna-menu-navbar">
                        <li><Link to="/advantage-club">Clube de Vantagens</Link></li>
                        <li><Link to="/photosalbum">Fensor flash</Link></li>
                     </div>
                  </ul>
               }
               
            </div>
         </header>
   )
}

export default Header