import React, { Component } from 'react'
import api from '../../../../../utils/api'
import Card from './card/Card'

import './List.css'

const List = ({data, athlete_id}) => {
    return (
        <section className="list">
            {
                data.map(item => {
                    return <Card key={item.id}
                        color={ item.type === 'Produto' ? 'var(--green)' : 'var(--yellow)' }
                        title={item.type} 
                        tumblr={api().replace('/api', '/') + item.img} 
                        subTitle={item.title} 
                        descript={item.description} 
                        points={item.points.replace('.00', '')} 
                        id={item.id} 
                        dateExpire={item.date_expire} 
                        athlete_id={athlete_id}
                    />
                })
                
            }
        </section>
    )
}

export default List 