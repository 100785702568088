import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { FormSportCategories } from '../admin/Modals/SportCategories/FormSelect';
import Header from '../../components/school/Header';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import Footer from '../../components/school/Footer';
import { getPositionsSchool } from '../../services/SharedService';
import { useTranslation } from 'react-i18next';

const SchoolSportsCategories = ({ children }) => {
  //hooks states --------------------------------------------------------------------------------------
  const history = useHistory();

  const alertRef = React.useRef();

  const { t } = useTranslation();
  const [isDone, setIsDone] = React.useState(false);
  const [locat, setLocat] = React.useState({ state: {} });

  React.useEffect(() => {
    (async () => {
      const schoolSession = JSON.parse(localStorage.getItem('school'));

      const { sports } = await getPositionsSchool({ school_id: schoolSession.id });

      setLocat({ state: { school_ids: [schoolSession.id], isEdit: true, sports } });
    })();
  }, []);
  //add border no sweet alerta
  React.useEffect(() => {
    //garanto que o elemento existe
    if (alertRef.current !== undefined && isDone) {
      // alertRef.current.style.cssText += 'border-top: solid 10px var(--main-color)';
      document.querySelector('div.sweet-alert ').style.cssText += 'border-top: 10px solid var(--main-color)';
    }
  }, [alertRef, isDone]);

  const handlerFinally = () => {
    history.push('/home-school');
    setIsDone(false);
  };

  return (
    <main>
      <SweetAlert
        ref={alertRef}
        show={isDone}
        success
        title='Pronto!'
        onConfirm={handlerFinally}
        customButtons={
          <ButtonRounded isBig={true} onClick={handlerFinally} className='w-100 m-4'>
            Pronto
          </ButtonRounded>
        }
      >
        <p>Sucesso! Esportes foram vinculados as Escolas selecionadas. </p>
      </SweetAlert>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <main className='content_panel'>
            <div className='content'>
              <FormSportCategories
                {...{
                  handlerFinally,
                  isDone,
                  alertRef,
                  setIsDone,
                  location: locat,
                  title: t('SportsTypesPage.title'),
                }}
              />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </main>
  );
};

export default SchoolSportsCategories;
