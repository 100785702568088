import { useCallback, useState } from 'react';
import { payment, receiveInCash } from '../services/PaymentService';
import { getErrorMessageFromResponse } from '../utils/utils';

const PAYMENS_ENDPOINS = {
  BOLETO: 'payment-boleto-assinatura',
  MONEY: 'payment-dinheiro-assinatura',
  CREDIT_CARD: 'payment-cart-cred-assinatura-invoice-url',
  BOLETO_INTEGRATED: 'payment-boleto-vista',
  CREDIT_CARD_INTEGRATED: 'payment-card-cred-vista-invoice-url',
  BOLETO_INTEGRATED_AMOUNT: 'payment-boleto-parcelado',
  CREDIT_CARD_INTEGRATED_AMOUNT: 'payment-card-cred-parcelado-invoice-url',
};
function useAsaasPayment() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setPaymentSuccess] = useState('');

  const processPayment = async (client) => {
    try {
      setLoading(true);

      const endpoint = PAYMENS_ENDPOINS[client._billingType || client.billingType] || PAYMENS_ENDPOINS['CREDIT_CARD'];

      delete client._billingType;
      const response_payment = await payment(endpoint, client);

      if (response_payment === 'Request failed with status code 500') {
        console.log('paymentResponse', response_payment);

        throw Error('StudentContractsPage.SystemMessage.CommunicationProblemServer');
      }

      const {
        paymentResponse,
        request,
        error,
        erro = undefined,
        message,
      } = response_payment.data?.data || response_payment.data;

      if (response_payment.data.message !== undefined && Array.isArray(response_payment.data.message)) {
        let msg = '';
        response_payment.data.message.forEach((m) => {
          msg += `[${m.code || m.field}] ${m.description || m.message} \n`;
        });
        throw Error(msg);
      }

      const rawResponse = paymentResponse !== undefined ? paymentResponse : request;

      const response = Array.isArray(rawResponse) ? rawResponse[0] : rawResponse;

      /* success */
      if (response.status === 'ACTIVE' || response.status == 'CONFIRMED' || response.status == 'PENDING') {
        return response;
      }

      if (response.message !== undefined) {
        throw Error(getErrorMessageFromResponse(response.message));
      }

      if (error !== undefined || erro !== undefined) {
        throw Error('StudentContractsPage.SystemMessage.CommunicationProblemServer');
      }

      if (message !== undefined && Array.isArray(message)) {
        let msg = '';
        message.forEach((m) => {
          msg += `[${m.code}] ${m.description} \n`;
        });
        throw Error(msg);
      }

      if (response.status !== 'ACTIVE') {
        console.log('paymentResponse', response);
        throw Error('StudentContractsPage.SystemMessage.SubscribeUnapproved');
      }
    } finally {
      setLoading(false);
    }
  };

  const handlerPayment = useCallback(async (event, payload) => {
    event.preventDefault();

    if (payload === null) {
      setError('Asaas client not provider!');
      return null;
    }
    if (payload.billingType === null || payload.billingType === undefined) {
      setError('Asaas client type Payment (client.payment) not provider!');
      return null;
    }

    processPayment(payload)
      .then((response) => {
        setPaymentSuccess(response);
      })
      .catch((error) => {
        setError(error.message);
      });
  });

  const handlerRemovePayment = async (event, payload) => {
    event.preventDefault();

    if (payload === null) {
      setError('Asaas client not provider!');
      return null;
    }
    if (
      payload.payment_subscription === null ||
      payload.payment_subscription === undefined ||
      payload.payment_subscription === ''
    ) {
      setError('Asaas client payment_subscription not provider!');
      return null;
    }

    const data = {
      idAsaas: payload.payment_subscription,
      subscription: payload.payment_subscription,
      token_assas: payload.token_assas,
      contract_id: payload.id,
      contract_type: 'SCHOOL',
    };
    const resp = await payment('payment-cancelar-assinatura', data);

    if (resp.data.data.deleted || resp.data.data.status === 'WARNING') {
      if (resp.data.data.deleted) {
        setPaymentSuccess(resp.data.data);
      } else {
        setError(resp.data.data.messageKey);
      }
    } else {
      console.log(resp);
      if (resp.data.data.message === "TypeError: Cannot read property 'subscription' of null")
        setError('StudentContractsPage.SystemMessage.InvoiceCancelFailTemp');
      else setError('StudentContractsPage.SystemMessage.InvoiceCancelFail');
    }
  };
  const handlerRemovePaymentMoney = async (event, payload) => {
    event.preventDefault();
    if (payload === null) {
      setError('Asaas client not provider!');
      return null;
    }

    const data = {
      contract_id: payload.id,
    };
    const resp = await payment('payment-dinheiro-cancelar-assinatura', data);

    if (resp.data.success) {
      setPaymentSuccess(resp.data);
    } else {
      setError('StudentContractsPage.SystemMessage.InvoiceCancelFail');
    }
  };

  const handlerReceivedPayment = async (event, payload) => {
    if (payload === null) {
      setError('Asaas client config not provider!');
      return null;
    }

    if (payload.cnpj && payload.senha && payload.token && payload.wallet && payload.utilizaFaturamento === 'S') {
      const data = {
        id: payload.id,
        idAsaas: payload.payment_id_asaas,
        paymentDate: payload.paymentDate,
        value: payload.valueReceived,
        token_assas: payload.token,
        observation: payload.observation,
      };

      const { status, messageKey } = await receiveInCash(data);
      if (status === 'SUCCESS') {
        setPaymentSuccess(messageKey);
        return true;
      }
      setError(messageKey);
      return false;
    }
    setError('SystemMessage.Fail.SchoolMustBeParameterized');
  };

  return {
    handlerPayment,
    handlerRemovePayment,
    handlerRemovePaymentMoney,
    handlerReceivedPayment,
    loading,
    errorPayment: error,
    successPayment: success,
  };
}

export default useAsaasPayment;
