import React from 'react';
import * as Icons from 'react-icons/fa'; // Importe todos os ícones FontAwesome

export const dinamicImport = (iconName) => {
  if (!iconName) return null;
  const iconToImport = Icons[iconName];

  if (iconToImport) {
    const Icon = iconToImport;
    return <Icon />;
  } else {
    console.warn(`Ícone '${iconName}' não encontrado`);
    return null;
  }
};
