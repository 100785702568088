export const handleFail = (response /* , request = null */) => {
  const error = new Error(response.message);
  error.messageKey = response.messageKey;
  error.payload = response.payload;
  error.isFail = true;
  throw error;
};

export const defaultApiObject = (isFail, error = null, payload = null) => {
  // console.dir(error);
  // console.log(error.isAxiosError);

  // console.log(error.config);
  // console.log(error.config?.data);
  // console.log(error.config?.url);

  // console.log(error.response);
  // console.log(error.response?.data);
  // console.log(error.response?.status);

  // console.log(error.toJSON());

  return {
    status: isFail ? 'FAIL' : 'ERROR',
    statusCode: error?.response ? error?.response?.status : null,
    message: isFail
      ? error.message
      : error.response?.data?.message ?? 'Ocorreu um erro interno. Por favor, recarregue a página e tente novamente.',
    messageKey: isFail ? error.messageKey : error.response?.data?.messageKey ?? 'SystemMessage.Fail.InternalError',
    payload,
    error,
  };
};
