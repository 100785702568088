import React, { useEffect, useState } from 'react';
import Header from '../../components/club/Header';
import Footer from '../../components/club/Footer';
import Loading from '../../components/Loading';
import Utils from '../../../src/utils/utils';
import { Link, Redirect } from 'react-router-dom';
import Api from '../../utils/api';
import axios from 'axios';

const DetailEmployee = (props) => {
  const [employee, setEmployee] = useState([]);
  const [countClass, setCountClass] = useState(0);
  const [turmas, setTurmas] = useState([]);
  const [dirUsuFotoPrincipal, setDirUsuFotoPrincipal] = useState('');
  const [timeSchool, setTimeSchool] = useState('');

  const [process, setProcess] = useState(false);
  const [classs, setClasss] = useState(false);

  const { formatCNPJCPF, formatCEP, diffDaysNow, formatTelefone, dateFormat } = Utils();
  const baseURL = Api();

  useEffect(() => {
    const hasEmployeePhoto = props.location.state.photo.split('/').pop() !== 'null';

    setDirUsuFotoPrincipal(hasEmployeePhoto ? props.location.state.photo : '../../img/perfil/foto-perfil.png');
    setEmployee(props.location.state.employee);
    calcTimeSchool(props.location.state.employee.usuDataInicioSchool);

    // async function getEmployee (user_id) {
    //    // setProcess(true)

    //    // const res = await axios.post(baseURL + 'get-employee-class',{
    //    //    user_id: props.location.state.user_id
    //    // })
    //    setDirUsuFotoPrincipal(props.location.employee.photo)
    //    setEmployee(props.location.employee)
    //    calcTimeSchool(props.location.employee.usuDataInicioSchool)
    //    setProcess(false)
    // }

    // getEmployee()
  }, []);

  const calcTimeSchool = (date) => {
    if (!date) setTimeSchool(null);
    else {
      const dataCreated = date.split(' ')[0];
      const dias = diffDaysNow(dataCreated);
      setTimeSchool(dias);
    }
  };

  return (
    <body>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='sidebar'>
                {process && (
                  <div className='card'>
                    <p className='loading-card'>
                      <Loading type='1' />
                    </p>
                  </div>
                )}
                {!process && (
                  <React.Fragment>
                    <div className='card'>
                      <div className='img_school'>
                        <img src={dirUsuFotoPrincipal} alt='' style={{ borderRadius: '50%' }} />
                      </div>
                      <h2 className='card_title mx-auto'>{employee.name}</h2>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label'>Cargo: </p>
                          <p className='card_details_text'>{employee.rolRole}</p>
                        </div>
                        <div className='card_details'>
                          <p className='card_details_label'>Nascimento: </p>
                          <p className='card_details_text'>{dateFormat(employee.usuBirth)}</p>
                        </div>
                        <div className='card_details'>
                          <p className='card_details_label'>Telefone: </p>
                          <p className='card_details_text'>{formatTelefone(String(employee.usuPhone))}</p>
                        </div>
                        <div className='card_details'>
                          <p className='card_details_label'>E-mail: </p>
                          <p className='card_details_text'>{employee.email}</p>
                        </div>
                      </div>
                    </div>
                    <div className='card'>
                      <div className='card_header mx-auto'>
                        <h2 className='card_title'>Tempo de escola</h2>
                        <div className='sidebar_data'>
                          {timeSchool ? (
                            `${timeSchool} dias`
                          ) : (
                            <span style={{ fontSize: '10pt', color: 'black', fontWeight: 'normal' }}>
                              (Data de início não informada)
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className='content'>
                {process && (
                  <div className='card'>
                    <p className='loading-card'>
                      <Loading type='1' />
                    </p>
                  </div>
                )}
                {!process && (
                  <React.Fragment>
                    <div className='card'>
                      <div className='card_header'>
                        <h2 className='card_title'>Dados </h2>
                      </div>
                      <div className='content_card aluno_content'>
                        <div className='card_details'>
                          <p className='card_details_label'>Data: </p>
                          <p className='card_details_text'>
                            {employee.usuDataInicioSchool
                              ? dateFormat(String(employee.usuDataInicioSchool))
                              : '(não informado)'}
                          </p>
                        </div>
                        <div className='card_details'>
                          <p className='card_details_label'>Endereço: </p>
                          {employee.usuEndereco && (
                            <p className='card_details_text'>
                              {employee.usuEndereco}, {employee.usuNumero}, {employee.usuBairro}, {employee.citCity}-
                              {employee.staStateSigla}
                            </p>
                          )}
                          {!employee.usuEndereco && <p className='card_details_text'>(não informado)</p>}
                        </div>
                        <div className='card_details'>
                          <p className='card_details_label'>CEP: </p>
                          {employee.usuCEP && <p className='card_details_text'>{formatCEP(String(employee.usuCEP))}</p>}
                          {!employee.usuCEP && <p className='card_details_text'>(não informado)</p>}
                        </div>
                      </div>
                      <div className='content_card aluno_content'>
                        <div className='card_header'>
                          <h2 className='card_title'>Sobre </h2>
                        </div>
                        <div className='content_card aluno_content'>
                          <div className='card_details'>
                            <p className='card_details_text'>
                              {employee.usuSobre ? employee.usuSobre : '(não informado)'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </body>
  );
};

export default DetailEmployee;
