import React from 'react';

function SchoolCard({ title, value, subtitle }) {
    const styles = {
        card: {
            width: '250px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Arial, sans-serif',
            overflow: 'hidden',
            borderColor: '#ffd700',
            borderWidth: '1px',
            borderStyle: 'solid'
        },
        cardHeader: {
            color: '#ffd700',
            fontSize: '16px',
            padding: '15px 20px',
            backgroundColor: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold'
        },
        cardBody: {
            padding: '20px',
            textAlign: 'center',
        },
        studentCount: {
            fontSize: '48px',
            fontWeight: 'bold',
            color: '#333333',
        },
        studentInfo: {
            color: '#666666',
            fontSize: '14px',
            marginTop: '10px'
        },
        moreInfoBtn: {
            padding: '8px 16px',
            fontSize: '14px',
            color: '#ffffff',
            backgroundColor: '#ffd700',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            marginTop: '20px'
        }
    };

    return (
        <div style={styles.card}>
            <div style={styles.cardHeader}>{title}</div>
            <div style={styles.cardBody}>
                <div style={styles.studentCount}>{value}</div>
                <div style={styles.studentInfo}>{subtitle}</div>
            </div>
        </div>
    );
}

export default SchoolCard;
