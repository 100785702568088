import React from 'react';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import { CardIndicatorSimple } from '../../components/cards/CardIndicatorSimple';
import Footer from '../../components/school/Footer';
import { useTranslation } from 'react-i18next';
import Header from '../../components/school/Header';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  TextField,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Tooltip,
} from '@material-ui/core';
import { exportExcelClassReport, getClassReports, getClassSchool } from '../../services/ClassService';
import { defaultCssStyleToPrint, handleOnChange } from '../../utils/utils';
import { useReactToPrint } from 'react-to-print';

import moment from 'moment';

//local const --------------------------------------------------------------------------
const PAYMENT_MAP = {
  CREDIT_CARD: 'Cartão Credito',
  MONEY: 'Dinheiro',
  BOLETO: 'Boleto',
};

const payloadData = {
  totalFaturas: 0,
  adimplentes: 0,
  valorAdimplentes: 0,
  inadimplentes: 0,
  valorInadimplentes: 0,
  alunosTotal: 0,
  alunosAtivos: 0,
  alunosInativos: 0,
  alunosSocial: 0,
  alunosSocial: 0,
  faturamentoTotal: 0,
  data: [],
};

const mapPayload = (d) => ({
  totalFaturas: d.total_faturas,
  adimplentes: d.adimplentes,
  valorAdimplentes: d.valor_adimplentes,
  inadimplentes: d.inadimplentes,
  valorInadimplentes: d.valor_inadimplentes,
  alunosTotal: d.alunos_total,
  alunosAtivos: d.alunos_ativos,
  alunosInativos: d.alunos_inativos,
  alunosSocial: d.alunos_total_social,
  faturamentoTotal: d.faturamento_total,
  data: d.data,
});
//handler functions --------------------------------------------------------------------------
const createFilterOptions = (options, { inputValue }) => {
  const filteredOptions = options.filter((option) => {
    const { id, name, responsaveis } = option;
    const lowerSearchText = inputValue.toLowerCase();

    return (
      name.toLowerCase().includes(lowerSearchText) ||
      id.toString().includes(lowerSearchText) ||
      responsaveis.some((item) => item.name.toLowerCase().includes(lowerSearchText))
    );
  });
  return filteredOptions;
};

const ClasseReport = () => {
  //hooks states --------------------------------------------------------------------------------------
  const { t } = useTranslation();

  const classes = useStyles();

  const contentPrint = React.useRef();

  const [isLoading, setloading] = React.useState(false);

  const [exportLoad, setExportLoad] = React.useState(false);

  const [reposts, setReports] = React.useState(payloadData);

  const [classs, setClasses] = React.useState([]);

  const [filters, setFilters] = React.useState({
    classIds: '',
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  });

  //handler functions --------------------------------------------------------------------------------------
  const onChangeFilters = handleOnChange(setFilters);

  const handleExportToXLS = async () => {
    setExportLoad(true);

    await exportExcelClassReport(filters).finally(() => {
      setExportLoad(false);
    });
  };

  const handlerFilterReport = async (e) => {
    try {
      setloading(true);

      const payload =
        filters.classIds === '' ? { ...filters, classIds: classs.map((v) => v.id).filter((v) => +v > 0) } : filters;

      const data = await getClassReports(payload);

      setFilters(payload);

      setReports(mapPayload(data));
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  const handlePrint = useReactToPrint({
    documentTitle: `Relatório Turmas - Periodo: ${filters.startDate} - ${filters.endDate} `,
    content: () => contentPrint.current,
    pageStyle: defaultCssStyleToPrint(),
  });

  //constructor --------------------------------------------------------------------------------------
  React.useEffect(() => {
    const { id } = JSON.parse(localStorage.getItem('school'));
    setloading(true);
    getClassSchool(id, undefined, true)
      .then(async (resp) => {
        resp.classs.unshift({ name: 'TODAS', id: '', responsaveis: [] });
        setClasses(resp.classs);
      })
      .finally(() => {
        setloading(false);
      });
  }, []);

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel' style={{ minHeight: 'auto', alignItems: 'center' }}>
              <div className='card' style={{ justifyContent: 'center' }}>
                <form style={{ alignItems: 'center' }}>
                  <div
                    className='form_card row'
                    style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}
                  >
                    <div className='col-md-8 form_group'>
                      <label className='card_details_label'>{t('ClassName')}</label>

                      <Autocomplete
                        classes={classes}
                        options={classs}
                        getOptionLabel={(option) => option.name}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        filterOptions={createFilterOptions}
                        onChange={(event, classs, detail) => {
                          if (detail === 'select-option') {
                            const { id } = classs;
                            setFilters((prev) => ({
                              ...prev,
                              classIds: id,
                            }));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder='Pesquisar por nome da turma, ID turma ou nome profressores.'
                            variant='outlined'
                          />
                        )}
                      />
                    </div>

                    <div className='col-md-2 form_group'>
                      <label className='card_details_label'>Data Inicial:*</label>
                      <input
                        type='date'
                        name='startDate'
                        className='form-control form_control'
                        value={filters.startDate}
                        onChange={onChangeFilters}
                      />
                    </div>
                    <div className='col-md-2 form_group'>
                      <label className='card_details_label'>Data Final:*</label>
                      <input
                        type='date'
                        name='endDate'
                        className='form-control form_control'
                        value={filters.endDate}
                        onChange={onChangeFilters}
                      />
                    </div>
                  </div>
                  <div style={{ width: '100px' }}>
                    <ButtonRounded isLoading={isLoading} onClick={handlerFilterReport}>
                      {t('Research')}
                    </ButtonRounded>
                  </div>
                </form>
              </div>
            </main>

            <main ref={contentPrint} className='content_panel' style={{ minHeight: 'auto' }}>
              <div className='card no-border-pint'>
                <div className='card_header mb-3' style={{ alignItems: 'center', width: '100%' }}>
                  <h2 className='card_title'>Relatório de Turmas</h2>
                  <p className='card_subtitle'>Relatório gerado automaticamente com base nos filtros aplicados.</p>
                </div>
                <div className='row w-100'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='card p-0'>
                          <ul className='list-group text-center w-100'>
                            <li className='list-group-item'>
                              <h2 style={{ color: 'var(--main-color)' }}>{reposts.adimplentes}</h2>
                              <h6>Total adimplentes</h6>
                            </li>
                            <li className='list-group-item'>
                              <h2 style={{ color: 'var(--main-color)' }}>{reposts.inadimplentes}</h2>
                              <h6>Total inadimplentes</h6>
                            </li>
                            <li className='list-group-item'>
                              <h2 style={{ color: 'var(--main-color)' }}>{reposts.alunosSocial}</h2>
                              <h6>Total social</h6>
                            </li>
                            <li className='list-group-item'>
                              <h2 style={{ color: 'var(--main-color)' }}>{reposts.faturamentoTotal}</h2>
                              <h6>Total com faturamento</h6>
                            </li>
                            <li className='list-group-item'>
                              <h2 style={{ color: 'var(--main-color)' }}>{reposts.alunosTotal}</h2>
                              <h6>Total geral alunos na turma</h6>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-8'>
                        <div className='w-100 d-flex flex-column flex-lg-row'>
                          <CardIndicatorSimple
                            doubleLine
                            className='mx-lg-2'
                            isLoading={isLoading}
                            title={
                              <CardIndicatorHeader
                                title='Entradas por Turma'
                                tooltip='Valor referente a receita total da turma'
                              />
                            }
                            value={
                              <CardIndicatorBody
                                color='green'
                                value={t('FormatFunction.intlCurrencyWithOptions', { value: reposts.valorAdimplentes })}
                              />
                            }
                          />
                          <CardIndicatorSimple
                            doubleLine
                            isLoading={isLoading}
                            title={
                              <CardIndicatorHeader
                                title='Inadimplência Turma'
                                tooltip='Valor referente a inadimplência total da turma.'
                              />
                            }
                            value={
                              <CardIndicatorBody
                                color='tomato'
                                value={t('FormatFunction.intlCurrencyWithOptions', {
                                  value: reposts.valorInadimplentes,
                                })}
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card p-0 max-to-print'>
                  <TableList rows={reposts.data} columns={columns} />

                  <div className='card-footer w-100 d-flex justify-content-center d-print-none'>
                    <ButtonRounded className='mr-1' onClick={handlePrint}>
                      Imprimir
                    </ButtonRounded>

                    <ButtonRounded isLoading={exportLoad} className='ml-1' onClick={handleExportToXLS}>
                      Exportar
                    </ButtonRounded>
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

//Columns --------------------------------------------------------------------------------------------------
const columns = [
  {
    id: 'student.athlete.athCodigoAluno',
    label: 'Matricula',
    minWidth: 120,
    align: 'left',
    format: (value, row) => row.student.athlete.athCodigoAluno,
  },
  {
    id: 'student.name',
    label: 'Nome',
    align: 'left',
    format: (value, row) => row.student.name,
  },
  {
    id: 'student.usuBirth',
    label: 'Data nascimento',
    align: 'left',
    format: (value, row) => moment(row.student.usuBirth).format('DD/MM/YYYY'),
  },
  {
    id: 'class.class_days_count',
    label: 'Quant. Treino semanal',
    align: 'left',
    format: (value, row) => row.class.class_days_count,
  },
  {
    id: 'value',
    label: 'Mensalidade',
    align: 'left',
  },
  {
    id: 'payment',
    label: 'Forma de pagamento',
    align: 'left',
    format: (value) => PAYMENT_MAP[value],
  },
  {
    id: 'dateStart',
    label: 'Inicio contrato',
    align: 'left',
    format: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
  },
  {
    id: 'status',
    label: 'Situação contrato',
    align: 'left',
    format: (value) => <StatusRender value={value} />,
  },
];

//material ui custom styles --------------------------------------------------------------------------------
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: '#f4f0f0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'var(--main-color)',
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

//local Components --------------------------------------------------------------------------------------------------
const StatusRender = ({ value }) => <span style={{ color: value === 'Ativo' ? 'green' : 'tomato' }}>{value}</span>;

const TableList = ({ columns, rows, mxh = 500 }) => {
  return (
    <TableContainer className='max-to-print' style={{ maxHeight: mxh, minHeight: mxh * 0.8 }}>
      <Table stickyHeader size='small' aria-label='sticky table'>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow hover tabIndex={-1} key={row.id}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {typeof column.format === 'function' ? column.format(value, row) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const CardIndicatorHeader = ({ title, tooltip }) => {
  return (
    <div>
      <span className='mx-2'>{title}</span>
      <BtnInfo title={tooltip} />
    </div>
  );
};
const CardIndicatorBody = ({ color, value }) => {
  return <span style={{ color: color }}>{value}</span>;
};
const BtnInfo = ({ title }) => (
  <Tooltip title={title} arrow tplacement='top'>
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.33337 10.3333H7.66671V6.33333H6.33337V10.3333ZM7.00004 4.99999C7.18893 4.99999 7.34726 4.93611 7.47504 4.80833C7.60282 4.68055 7.66671 4.52222 7.66671 4.33333C7.66671 4.14444 7.60282 3.98611 7.47504 3.85833C7.34726 3.73055 7.18893 3.66666 7.00004 3.66666C6.81115 3.66666 6.65282 3.73055 6.52504 3.85833C6.39726 3.98611 6.33337 4.14444 6.33337 4.33333C6.33337 4.52222 6.39726 4.68055 6.52504 4.80833C6.65282 4.93611 6.81115 4.99999 7.00004 4.99999ZM7.00004 13.6667C6.07782 13.6667 5.21115 13.4917 4.40004 13.1417C3.58893 12.7917 2.88337 12.3167 2.28337 11.7167C1.68337 11.1167 1.20837 10.4111 0.858374 9.59999C0.508374 8.78888 0.333374 7.92222 0.333374 6.99999C0.333374 6.07777 0.508374 5.21111 0.858374 4.39999C1.20837 3.58888 1.68337 2.88333 2.28337 2.28333C2.88337 1.68333 3.58893 1.20833 4.40004 0.858328C5.21115 0.508328 6.07782 0.333328 7.00004 0.333328C7.92226 0.333328 8.78893 0.508328 9.60004 0.858328C10.4112 1.20833 11.1167 1.68333 11.7167 2.28333C12.3167 2.88333 12.7917 3.58888 13.1417 4.39999C13.4917 5.21111 13.6667 6.07777 13.6667 6.99999C13.6667 7.92222 13.4917 8.78888 13.1417 9.59999C12.7917 10.4111 12.3167 11.1167 11.7167 11.7167C11.1167 12.3167 10.4112 12.7917 9.60004 13.1417C8.78893 13.4917 7.92226 13.6667 7.00004 13.6667Z'
        fill='#B8872B'
      />
    </svg>
  </Tooltip>
);

export default ClasseReport;
