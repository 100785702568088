import React from 'react';
import Footer from '../../components/admin/Footer';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import useForm from '../../hooks/useForm';
import { createSport, getPositions } from '../../services/SharedService';
import { useTranslation } from 'react-i18next';

import '../../utils/extendsType';
import Message from '../../components/Message';
import SweetAlert from 'react-bootstrap-sweetalert';

const filter = createFilterOptions();

const CreateSports = () => {
  //hooks states --------------------------------------------------------------------------------------
  const classes = useStyles();

  const history = useHistory();

  const { t } = useTranslation();

  const { MessageDangerMarginTop } = Message();

  const [sendSave, setSendSave] = React.useState(false);

  const [errors, setErrors] = React.useState([]);

  const [positions, setPositions] = React.useState([]);

  const [value, setValue] = React.useState(null);

  const [filteredRows, setFilterRows] = React.useState(null);

  const formRefer = React.useRef(null);
  const tableRef = React.useRef(null);
  const alertRef = React.useRef();

  const [isDone, setIsDone] = React.useState(false);
  const { state, handleOnChange, handleOnSubmit, disable, setState, setDisable } = useForm(
    stateSchema,
    validationStateSchema,
    onSubmitForm,
    formRefer,
    setErrors
  );

  //constructor -------------------------------------------------------------------------------------------
  React.useEffect(() => {
    let isMounted = true;
    (async () => {
      const { positions } = await getPositions();
      if (isMounted) {
        setPositions(positions.unique('posPosition'));
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);
  React.useEffect(() => {
    //garanto que o elemento existe
    if (alertRef.current !== undefined && isDone) {
      // alertRef.current.style.cssText += 'border-top: solid 10px var(--main-color)';
      document.querySelector('div.sweet-alert ').style.cssText += 'border-top: 10px solid var(--main-color)';
    }
  }, [alertRef, isDone]);
  //handler funtions --------------------------------------------------------------------------------------
  async function onSubmitForm(payload) {
    setSendSave(true);

    const form = new FormData();
    form.append('positions', payload.positions.join(','));
    form.append('name', payload.name);
    form.append('id', payload.id);

    try {
      const result = await createSport(form);
      if (result.status === 'SUCCESS') {
        setIsDone(true);
      }
    } catch (error) {
      setErrors([{ error: error.message }]);
    } finally {
      setSendSave(false);
    }
  }

  const handlerFinally = () => {
    history.push('/sports-categories');
    setIsDone(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = positions.map((n) => n.posPosition);
      setState((prevState) => ({ ...prevState, positions: { value: newSelecteds, error: '' } }));
      return;
    }
    setState((prevState) => ({ ...prevState, positions: { value: [], error: '' } }));
  };

  const handleClick = (event, name) => {
    const selected = state.positions.value;

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setState((prevState) => ({ ...prevState, positions: { value: newSelected, error: '' } }));
  };

  const handleChangeFitler = (name) => {
    const selecteds = state.positions.value;
    selecteds.push(name);
    setFilterRows(selecteds);
  };

  const isSelected = (name) => state.positions.value.indexOf(name) !== -1;
  //private const --------------------------------------------------------------------------------------
  const column = [
    {
      id: 'posPosition',
      label: 'Posições / Provas / Competências',
      minWidth: 320,
      align: 'left',
    },
  ];
  return (
    <main>
      <SweetAlert
        ref={alertRef}
        show={isDone}
        success
        title='Pronto!'
        onConfirm={handlerFinally}
        customButtons={
          <ButtonRounded isBig={true} onClick={handlerFinally} className='w-100 m-4'>
            Pronto
          </ButtonRounded>
        }
      >
        <p> Esporte/Modalidade {state.id.value > 0 ? 'alterado' : 'criado'} com sucesso.</p>
      </SweetAlert>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row d-flex align-items-center justify-content-center pt-1 pb-5'>
            <img src='/img/clubes/fensor.svg' className='logo' alt='' />
          </div>
          <div className='row content_painel'>
            <main className='content_panel'>
              {isDone === false && (
                <div className='card p-0 flex-column'>
                  <div className='card_header w-100 flex-column align-items-center pt-3'>
                    <h2 className='card_title'>{state.id.value === 0 ? 'Adicionar Esporte' : 'Editar Esporte'}</h2>
                    <BtnBack
                      onClick={handlerFinally}
                      style={{
                        float: 'right',
                        position: 'absolute',
                        left: 35,
                        marginTop: 5,
                      }}
                    />
                  </div>
                  <hr />
                  {errors.map((err, k) => (
                    <MessageDangerMarginTop key={`err-${k}`} title='Atenção! ' description={err.error} />
                  ))}

                  <div className='row mx-3'>
                    <form ref={formRefer} action='' className='form_card' noValidate>
                      <div className='col'>
                        <div className='row'>
                          <div className='col-md-6 col-sm-12 form_group'>
                            <label className='card_details_label'>Nome Esporte *</label>
                            <input
                              type='text'
                              name='name'
                              required
                              placeholder='Nome do Esporte'
                              className='form-control form_control'
                              value={state.name.value}
                              onChange={handleOnChange}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='row mb-2 mx-3'>
                    <div className='col'>
                      <label className='card_details_label'>Posições / Provas / Competências</label>

                      <Autocomplete
                        value={value}
                        classes={classes}
                        onChange={(event, newValue, detail) => {
                          if (typeof newValue === 'string') {
                            setValue({
                              posPosition: newValue,
                            });
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                              posPosition: newValue.inputValue,
                            });
                            setPositions([...positions, { id: 0, posPosition: newValue.inputValue }]);
                          } else {
                            setValue(newValue);
                          }
                          if (detail === 'select-option') {
                            handleChangeFitler(newValue.inputValue || newValue.posPosition);
                          }
                          if (detail === 'clear') {
                            setFilterRows(null);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (params.inputValue !== '') {
                            filtered.push({
                              inputValue: params.inputValue,
                              posPosition: `➕ Adicionar "${params.inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={positions}
                        getOptionLabel={(option) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.posPosition;
                        }}
                        renderOption={(option) => option.posPosition}
                        freeSolo
                        renderInput={(params) => (
                          <TextField {...params} placeholder='Pesquisar ou adicionar um novo' variant='outlined' />
                        )}
                      />
                    </div>
                  </div>
                  <hr />
                  <TableList
                    rows={positions.filter(
                      (p) => filteredRows === null || filteredRows.some((v) => v.includes(p.posPosition))
                    )}
                    columns={column}
                    {...{ selected: state.positions.value, handleClick, handleSelectAllClick, isSelected, tableRef }}
                  />

                  <div className='row align-items-center justify-content-center my-4'>
                    <div className='col-md-5 col-sm-10'>
                      <ButtonRounded
                        className='w-100'
                        variant='outline'
                        color='var(--main-color)'
                        isBig={true}
                        onClick={handlerFinally}
                      >
                        {t('Back')}
                      </ButtonRounded>
                    </div>
                    <div className='col-md-5 col-sm-10'>
                      <ButtonRounded isDisabled={disable} isLoading={sendSave} onClick={handleOnSubmit} className='w-100' isBig={true}>
                        {state.id.value > 0 ? t('ClassPage.Modal.ButtonEdit') : t('ClassPage.Modal.ButtonAdd')}
                      </ButtonRounded>
                    </div>
                  </div>
                </div>
              )}
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

//form states ----------------------------------------------------------------------------------------------
const stateSchema = {
  id: { value: 0, error: '' },
  name: { value: '', error: '' },
  positions: { value: [], error: '' },
};

const validationStateSchema = {
  name: {
    required: true,
  },
  positions: {
    required: true,
  },
};

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: '#f4f0f0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
  },
});

//private components  --------------------------------------------------------------------------------------
const BtnBack = ({ fcolor = '#1C1B1F', w = 16, h = 16, onClick, style }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer', ...style }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z' fill={fcolor} />
    </svg>
  </span>
);

//material ui custom styles --------------------------------------------------------------------------------
const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'var(--main-color)',
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const CheckboxCustom = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: 'var(--main-color)',
    },
  },
  checked: {},
}))(Checkbox);

const TableList = ({ selected, handleSelectAllClick, handleClick, isSelected, tableRef, columns, rows, mxh = 380 }) => {
  return (
    <TableContainer className='max-to-print' style={{ maxHeight: mxh, minHeight: mxh * 0.8 }}>
      <Table ref={tableRef} stickyHeader size='small' aria-label='sticky table'>
        <TableHead>
          <TableRow>
            <StyledTableCell padding='checkbox'>
              <CheckboxCustom
                indeterminate={selected.length > 0 && selected.length < rows.length}
                checked={rows.length > 0 && selected.length === rows.length}
                onChange={handleSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </StyledTableCell>
            {columns.map((column) => (
              <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            const isItemSelected = isSelected(row.posPosition);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, row.posPosition)}
                role='checkbox'
                aria-checked={isItemSelected}
                key={index}
                selected={isItemSelected}
                tabIndex={-1}
              >
                <TableCell padding='checkbox'>
                  <CheckboxCustom checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                </TableCell>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {typeof column.format === 'function' ? column.format(value, row) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CreateSports;
