import React from 'react';
import BaseInput from './BaseInput';

const Input = ({ type = 'text', ...props }) => {
  return (
    <BaseInput {...props} inputComponent={(props) => <input {...props} type={type} value={props.value ?? ''} />} />
  );
};

export default Input;
