import React from 'react';
import Table from 'react-bootstrap/Table';
import { BsClipboardCheck, BsPencil, BsTrash } from 'react-icons/bs';
import { RiFileUploadLine } from 'react-icons/ri';
import Utils from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const StockAdjustmentTable = ({ stockAdjustment }) => {
    const { t } = useTranslation();
    const { dateFormat, currencyFormatterText } = Utils();

    const defaultColHeaderStyle = {
        border: 'none',
        fontSize: '12px',
        width: '200px',
        color: '#3b3b3b',
        fontWeight: 600,
        justifyContent: 'left'
    };

    const defaultColRowStyle = {
        alignItems: 'center',
        width: '200px',
        height: '100%',
        border: 'none',
        justifyContent: 'left'
    };

    return (
        <Table responsive style={{ boxShadow: 'none', marginTop: '20px', minWidth: '625px' }}>
            <thead>
                <tr style={{ marginBottom: '5px' }}>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Produto
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Motivo
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Descrição
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Status
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Modifica por
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Data e Hora
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Qtde ajusdtada
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Saldo final
                    </th>
                </tr>
            </thead>
            <tbody>
                {stockAdjustment && stockAdjustment.map((item, i) => (
                    <tr key={'ProducsTable_tbody_row' + i} style={{
                        marginBottom: '12px',
                        borderRadius: '10px',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        height: '62px',
                        borderColor: 'var(--main-color)'
                    }}>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.product_stock.product.titulo.length > 20 ? item.product_stock.product.titulo.substr(0, 20) + '...' : item.product_stock.product.titulo.length}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.stock_adjustment_reason && item.stock_adjustment_reason.title}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.description}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.product_stock.product.ativo}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.user.name}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {dateFormat(item.created_at)}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.adjusted_amount}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {currencyFormatterText(item.final_balance)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default StockAdjustmentTable;