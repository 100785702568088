import React from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';

const TableBordered = ({
  isLoading,
  emptyTableMessage,
  columns,
  data,
  actions,
  keyPrefix,
  maxHeight,
  style,
  lineColor,
  highlightOnHover,
  highlightRow,
  staticHighlightRow,
  onClickRow,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className='table-container'
      style={{
        width: '100%',
        overflow: 'auto',
        maxHeight: maxHeight,
        boxShadow: '0 5px 15px rgb(0 0 0 / 15%)',
        borderRadius: '10px 10px 10px 10px',
        ...style,
      }}
    >
      <table
        className={`table d-table static-highlight-tr ${highlightOnHover ? 'table-highlight-hover' : ''}`}
        style={{
          width: 'fit-content',
          minWidth: '100%',
          boxShadow: 'none',
          borderRadius: 'initial',
          marginBottom: '0px',
        }}
      >
        <thead style={{ position: 'sticky', top: '0px', zIndex: 2, backgroundColor: 'white' }}>
          <tr className='d-table-row'>
            {columns.map(({ label, width, minWidth, textAlign }, i) => (
              <th
                key={`${keyPrefix || 'keyPrefix'}_TableBordered_Header_Row_${i}`}
                className='d-table-cell'
                style={{
                  minWidth: minWidth || 'auto',
                  width: width || 'auto',
                  position: 'sticky',
                  left: '0px',
                  top: '0px',
                  zIndex: 2,
                  borderTop: 'none',
                  textAlign: textAlign,
                }}
              >
                {label}
              </th>
            ))}

            {actions &&
              actions.map(({ header, width, minWidth, textAlign }, i) => (
                <th
                  key={`${keyPrefix || 'keyPrefix'}_TableBordered_Header_Row_${i}`}
                  className='d-table-cell'
                  style={{
                    minWidth: minWidth || 'auto',
                    width: width || 'auto',
                    position: 'sticky',
                    left: '0px',
                    top: '0px',
                    zIndex: 2,
                    borderTop: 'none',
                    textAlign: textAlign,
                  }}
                >
                  {header}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr className='d-table-row'>
              <td
                colSpan={999}
                className='d-table-cell'
                style={{
                  height: 'auto',
                  position: 'sticky',
                  left: '0px',
                  zIndex: 1,
                  textAlign: 'center',
                  paddingTop: '5px',
                }}
              >
                <Loading type='1' />
              </td>
            </tr>
          )}
          {!isLoading && data.length === 0 && (
            <tr className='d-table-row'>
              <td
                colSpan={999}
                className='d-table-cell'
                style={{
                  height: 'auto',
                  position: 'sticky',
                  left: '0px',
                  zIndex: 1,
                }}
              >
                {emptyTableMessage ?? t('TableBorderedComponent.emptyTableMessage')}
              </td>
            </tr>
          )}
          {!isLoading &&
            data.map((row, i) => {
              return (
                <tr
                  key={`${keyPrefix || 'keyPrefix'}_TableBordered_Body_Row_${i}`}
                  className={'d-table-row static-highlight-tr'}
                  style={{
                    cursor: onClickRow ? 'pointer' : undefined,
                    '--table-highlight-hover-background-color': highlightRow && highlightRow(row) ? '#ffa534' : '',
                    '--static-highlight-tr-background-color':
                      staticHighlightRow && staticHighlightRow(row) ? 'var(--main-color-alfa)' : '',
                  }}
                  onClick={() => onClickRow && onClickRow(row)}
                >
                  {columns.map(({ field, width, minWidth, textAlign, className, style }, j) => {
                    return (
                      <td
                        key={`${keyPrefix || 'keyPrefix'}_TableBordered_Body_Cell_${i}_${j}`}
                        className={`d-table-cell ${className || ''}`}
                        style={{
                          minWidth: minWidth || 'auto',
                          width: width || 'auto',
                          height: 'auto',
                          position: 'sticky',
                          left: '0px',
                          zIndex: 1,
                          color: lineColor && lineColor(row),
                          textAlign: textAlign,
                          ...style,
                        }}
                      >
                        {typeof field === 'function' ? field(row) : row[field]}
                      </td>
                    );
                  })}

                  {actions &&
                    actions.map(({ label, title, action, width, minWidth, textAlign }, i) => (
                      <td
                        key={`${keyPrefix || 'keyPrefix'}_TableBordered_Body_ActionCell_${i}`}
                        className='d-table-cell'
                        style={{
                          minWidth: minWidth || 'auto',
                          width: width || 'auto',
                          height: 'auto',
                          position: 'sticky',
                          left: '0px',
                          zIndex: 1,
                          color: lineColor && lineColor(row),
                          textAlign: textAlign,
                        }}
                      >
                        <span title={title} onClick={(event) => action(row, event, i)}>
                          {label}
                        </span>
                      </td>
                    ))}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TableBordered;
