import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import Api from '../../../../../utils/api'
import axios from 'axios'

import './Nav.css'

import SubNav from '../sub-nav/SubNav'

import ImgUser from '../../assets/images/foto-perfil.png'

const Nav = () => {

    const [userName, setUserName] = useState("")
    const [login, setLogin] = useState(false)
    const [process, setProcess] = useState(false)
    const [saldo, setSaldo] = useState(localStorage.getItem('saldo'))
    const baseURL = Api()

    useEffect(() => {
        getUser()
    }, [])

    const getUser = async () => {
        const user = JSON.parse(await localStorage.getItem('user'))
        
        if ( user !== null ) {
            setUserName(user.name)

            const athlete = JSON.parse(await localStorage.getItem('athlete'))

            setProcess(true)
            axios.post(baseURL + 'get-saldo', {athlete_id: athlete.id}).then(resp => {
                setSaldo(resp.data.saldo)
                localStorage.setItem('saldo', resp.data.saldo)
            }).catch(err =>  {
                console.log('Erro: ' + err.message)
            })

            setProcess(false)
        }
        else 
           setLogin(true)
     }

    if ( login ) {
        return <Redirect to="/" />
    }

    return (
        <React.Fragment>
            <SubNav
                tumblr={ImgUser}
                fullName={userName}
                points={saldo}
            />
        </React.Fragment>
    );
}

export default Nav