import React, { useState, forwardRef, useContext, useRef, useCallback, useEffect, useMemo } from 'react';
import { handleOnChange } from '../../../../src/utils/utils';
import { useTranslation } from 'react-i18next';
import { ModalWithMethods } from '../../../components/modal/Modal';
import { UserContext } from '../../../Context/UserContext';
import { getAllLessonsClub, getAllLessonsPlanning } from '../../../services/LessonPlanningService';
import { getAllLessonsPlanning as getAllLessonPlanningAdmin } from '../../../services/AdminService';

import ReactTooltip from 'react-tooltip'
import { BsBoxArrowUpRight, BsCalendar3 } from 'react-icons/bs';
import { Success } from '../../../components/svgs/Success';
import Calendar from 'react-calendar';
import moment from 'moment';
import { getClassSchool } from '../../../services/ClassService';

const EditMicrocycle = (
  { data, onSubmit, onlyView, isSubscriptiontActive, ...props },
  ref
) => {
  const { school } = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const inputRefInformation = useRef(null);
  const inputRefPeriod = useRef(null);
  const inputRefMicrocycle = useRef(null);
  const inputRefClass = useRef(null);

  const [filters, setFilters] = useState({
    name: '',
    classClub: '',
    classSchool: '',
    classAdmin: '',
    class: '',
    source: 'P',
    status: 'A',
  });
  // dados
  const [microcycle, setMicrocycle] = useState({
    id: undefined,
    name: '',
    status: 'A',
    classNumber: '',
    objective: '',
  });
  const [selectedDate, setSelectedDate] = useState();
  const [rangeDate, setRangeDate] = useState([]);
  const [date, setDate] = useState(null);
  const [planningsSchool, setPlanningsSchool] = useState([])
  const [planningsSchoolFiltered, setPlanningsSchoolFiltered] = useState([]);
  const [planningsSchoolSelected, setPlanningsSchoolSelected] = useState([]);
  const [planningsClub, setPlanningsClub] = useState([])
  const [planningsClubFiltered, setPlanningsClubFiltered] = useState([]);
  const [planningsClubSelected, setPlanningsClubSelected] = useState([]);
  const [planningsAdmin, setPlanningsAdmin] = useState([])
  const [planningsAdminFiltered, setPlanningsAdminFiltered] = useState([]);
  const [planningsAdminSelected, setPlanningsAdminSelected] = useState([]);
  const [classes, setClasses] = useState([])
  const [classesFiltered, setClassesFiltered] = useState([]);
  const [classesSelected, setClassesSelected] = useState([]);

  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(null);
  const [session, setSession] = useState('information');

  const onChange = handleOnChange(setMicrocycle);

  const onChangeFilters = handleOnChange(setFilters);

  const fetchAllLessonsPlanningClub = async () => {
    const { status, plannings, message } = await getAllLessonsClub(1, 0);
    if (status === 'SUCCESS') {
      setPlanningsClub(plannings);
      setPlanningsClubFiltered(plannings);
    } else {
      console.log(message);
    }
  };

  const fetchAllLessonsPlanningSchool = async () => {
    const { status, lessonsPlanning, message } = await getAllLessonsPlanning({ date: null, onlyActive: 1, nested: 0 });
    if (status === 'SUCCESS') {
      setPlanningsSchool(lessonsPlanning);
      setPlanningsSchoolFiltered(lessonsPlanning);
    } else {
      console.log(message);
    }
  };

  const fetchAllLessonsPlanningAdmin = async () => {
    const { status, plannings, message } = await getAllLessonPlanningAdmin(1, 0);
    if (status === 'SUCCESS') {
      setPlanningsAdmin(plannings);
      setPlanningsAdminFiltered(plannings);
    } else {
      console.log(message);
    }
  };

  const fetchClass = async () => {
    try {
      const { classs } = await getClassSchool(school.id, null, true);
      const data = []
      const map = classs?.filter((c) => c.status === 'A')?.map((c) => {
        return c?.days?.map((d) => {
          data.push({
            id: d.class_days_id,
            class: c.name,
            weekdate: d.name,
            hour: d.hour
          })
          return
        })
      })
      setClasses(data);
      setClassesFiltered(data);
    } catch (error) {
      console.log(error);
    }
  };

  const validateInformation = (e) => {
    e.preventDefault()
    const validate = {}
    if (!microcycle?.name) validate['name'] = 'Nome da aula é obrigatório';
    if (!microcycle?.objective) validate['objective'] = 'Objetivo é obrigatório';
    if (!microcycle?.classNumber) validate['classNumber'] = 'Número da aula é obrigatório';
    if (!microcycle?.status) validate['status'] = 'Status é obrigatório';
    if (Object.keys(validate)?.length === 0) { setSession('period') } else { setErrors(validate); ReactTooltip.show(inputRefInformation.current); }
  }

  const validatePeriod = (e) => {
    e.preventDefault()
    const validate = {}
    if ((!selectedDate || Object?.values(selectedDate)?.length !== 2)) validate['period'] = 'Período selecionado incorretamente';
    if (Object.keys(validate)?.length === 0) {
      setSession('microcycle');
    } else {
      ReactTooltip.show(inputRefPeriod.current);
    }
  }

  const validateMicrocycle = (e) => {
    e.preventDefault();
    const validate = rangeDate?.map((date) => {
      const findPlanningSchool = planningsSchoolSelected?.find((planning) => planning.date === date);
      const findPlanningClub = planningsClubSelected?.find((planning) => planning.date === date);
      if (!findPlanningSchool && !findPlanningClub) { return date }
    })?.filter((date) => date !== undefined);
    if (Object.values(validate)?.length > 0) {
      setDate(validate[0]);
      ReactTooltip.show(inputRefMicrocycle.current);
    } else {
      setDate(rangeDate[0]);
      setSession('class');
    }
  }

  const validateClass = (e) => {
    e.preventDefault()
    const validate = {}
    if ((!classesSelected || classesSelected?.length === 0)) validate['class'] = 'Turma é obrigatório';
    if (Object.keys(validate)?.length === 0) {
      setSession('resume');
    } else {
      ReactTooltip.show(inputRefClass.current);
    }
  }

  const onShow = () => {
    ref.current.clearMessage();
    setSuccess(false);
    setErrors(null);
    setDate(data?.dates?.length ? moment(data?.dates[0]).format("YYYY-MM-DD") : null)
    setSelectedDate(
      data?.dates?.length ?
        [new Date(`${data?.dates[0]['date']}T00:00:00`), new Date(`${data?.dates[data?.dates?.length - 1]['date']}T23:59:59`)]
        : null
    );
    setRangeDate(data?.dates ?? [])
    setPlanningsSchoolSelected(data?.classesSchool ?? []);
    setPlanningsClubSelected(data?.classesClub ?? []);
    setPlanningsAdminSelected(data?.classesAdmin ?? []);
    setClassesSelected(data?.classes ?? []);
    !onlyView && fetchAllLessonsPlanningSchool();
    !onlyView && fetchAllLessonsPlanningClub();
    !onlyView && fetchAllLessonsPlanningAdmin();
    !onlyView && fetchClass();
    setMicrocycle(data);
    setSession(onlyView ? 'resume' : 'information');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    ref.current.clearMessage();

    const request = {
      ...microcycle,
      dates: rangeDate?.map((date) => ({ date })),
      classSchool: planningsSchoolSelected?.map((planning) => ({ lesson_planning_id: planning.id, date: planning.date })),
      classClub: planningsClubSelected?.map((planning) => ({ lesson_planning_id: planning.id, date: planning.date })),
      classAdmin: planningsAdminSelected?.map((planning) => ({ lesson_planning_id: planning.id, date: planning.date })),
      classes: classesSelected?.map((c) => c.id)
    };

    const { status, messageKey } = microcycle?.id
      ? await onSubmit({ id: microcycle.id, ...request })
      : await onSubmit({ school_id: school.id, ...request });

    if (status === 'SUCCESS') {
      setSuccess(true)
    } else {
      showErrorMessage(messageKey);
    }
  }

  const showErrorMessage = (message) => {
    ref.current.showErrorMessage(message);
    ref.current.scrollTop();
  };

  const calculateRangeDate = () => {
    if (selectedDate && Object?.values(selectedDate)?.length === 2) {
      const start = new Date(selectedDate[0]);
      const final = new Date(selectedDate[1]);
      const diffTime = Math.abs(final - start);
      const diffDates = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      const range = [moment(start).format('YYYY-MM-DD')];
      for (let i = 1; i < diffDates; i++) {
        const date = moment(start.setDate(start.getDate() + 1)).format('YYYY-MM-DD');
        range.push(date)
      }
      setRangeDate(range)
      setDate(range[0])
    }
  }

  const addPlanningClub = useCallback((planning) => {
    const find = planningsClubSelected?.find((value) => value.key === `${date}-${planning.id}`);
    !find && setPlanningsClubSelected(old => [...old, { ...planning, date, key: `${date}-${planning.id}` }])
    setFilters({ classClub: '' })
  }, [planningsClubSelected, date])

  const removePlanningClub = useCallback((value) => {
    const plan = planningsClubSelected.filter((planning) => planning.key !== `${date}-${value.id}`)
    setPlanningsClubSelected(plan)
  }, [planningsClubSelected, date])

  const addPlanningSchool = useCallback((planning) => {
    const find = planningsSchoolSelected?.find((value) => value.key === `${date}-${planning.id}`);
    !find && setPlanningsSchoolSelected(old => [...old, { ...planning, date, key: `${date}-${planning.id}` }])
    setFilters({ classSchool: '' })
  }, [planningsSchoolSelected, date])

  const removePlanningSchool = useCallback((value) => {
    const plan = planningsSchoolSelected.filter((planning) => planning.key !== `${date}-${value.id}`)
    setPlanningsSchoolSelected(plan)
  }, [planningsSchoolSelected, date])

  const addPlanningAdmin = useCallback((planning) => {
    const find = planningsAdminSelected?.find((value) => value.key === `${date}-${planning.id}`);
    !find && setPlanningsAdminSelected(old => [...old, { ...planning, date, key: `${date}-${planning.id}` }])
    setFilters({ classAdmin: '' })
  }, [planningsAdminSelected, date])

  const removePlanningAdmin = useCallback((value) => {
    const plan = planningsAdminSelected.filter((planning) => planning.key !== `${date}-${value.id}`)
    setPlanningsAdminSelected(plan)
  }, [planningsAdminSelected, date])

  const addClasses = useCallback((data) => {
    const find = classesSelected?.find((value) => value.id === data.id);
    !find && setClassesSelected(old => [...old, data])
    setFilters({ class: '' })
  }, [classesSelected])

  const removeClasses = useCallback((value) => {
    const find = classesSelected.filter((c) => c.id !== value.id)
    setClassesSelected(find)
  }, [classesSelected])

  useEffect(() => {
    calculateRangeDate();
  }, [selectedDate])

  const classClubList = useMemo(() => {
    return planningsClubFiltered?.map((planning) => {
      return (
        <div className='autocomplete-input-list' onClick={() => addPlanningClub(planning)} key={planning.id}>
          {planning?.name}
        </div>
      )
    })
  }, [planningsClubFiltered])

  const planningClubSelectedList = useMemo(() => {
    return (
      <div className='d-flex align-items-center'>
        {planningsClubSelected.filter((planning) => planning.date === date)?.sort((a, b) => a.name - b.name)?.map((planning) => {
          return (
            <div key={planning?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <button type='button' onClick={() => removePlanningClub(planning)} className='badge-items-button'>X</button>
              <span className='badge-items'>{planning?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [planningsClubSelected, date])

  const classSchoolList = useMemo(() => {
    return planningsSchoolFiltered?.map((planning) => {
      return (
        <div className='autocomplete-input-list' onClick={() => addPlanningSchool(planning)} key={planning.id}>
          {planning?.name}
        </div>
      )
    })
  }, [planningsSchoolFiltered])

  const planningSchoolSelectedList = useMemo(() => {
    return (
      <div className='d-flex align-items-center'>
        {planningsSchoolSelected?.filter((planning) => planning.date === date)?.sort((a, b) => a.name - b.name)?.map((planning) => {
          return (
            <div key={planning?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <button type='button' onClick={() => removePlanningSchool(planning)} className='badge-items-button'>X</button>
              <span className='badge-items'>{planning?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [planningsSchoolSelected, date])

  const classAdminList = useMemo(() => {
    return planningsAdminFiltered?.map((planning) => {
      return (
        <div className='autocomplete-input-list' onClick={() => addPlanningAdmin(planning)} key={planning.id}>
          {planning?.name}
        </div>
      )
    })
  }, [planningsAdminFiltered])

  const planningAdminSelectedList = useMemo(() => {
    return (
      <div className='d-flex align-items-center'>
        {planningsAdminSelected?.filter((planning) => planning.date === date)?.sort((a, b) => a.name - b.name)?.map((planning) => {
          return (
            <div key={planning?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <button type='button' onClick={() => removePlanningAdmin(planning)} className='badge-items-button'>X</button>
              <span className='badge-items'>{planning?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [planningsAdminSelected, date])

  const classesList = useMemo(() => {
    return classesFiltered?.map((c) => {
      return (
        <div className='autocomplete-input-list' onClick={() => addClasses(c)} key={c.id}>
          {c?.class}
        </div>
      )
    })
  }, [classesFiltered])

  const classesListSelected = useMemo(() => {
    return classesSelected && classesSelected?.map((c) => {
      return (
        <div className='d-flex justify-content-between border rounded shadow p-3 mb-2' key={c.id}>
          <div style={{ width: '240px' }}>{c.class}</div>
          <div style={{ width: '120px' }}>{c.hour}</div>
          <div className='pl-4 pr-4 pt-1 pb-1 rounded-pill bg-danger text-white' style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => removeClasses(c)}>Remover</div>
        </div>
      )
    })
  }, [classesSelected])

  return (
    <ModalWithMethods
      {...props}
      ref={ref}
      title={<div className='modal_title' style={{ padding: '0 30px' }}>{onlyView ? 'Visualizar Microciclo' : microcycle?.id ? 'Editar Microciclo' : 'Criar Microciclo'}</div>}
      size={'lg'}
      onShow={onShow}
      modalContentStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 20 }}
    >
      {session === 'information' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-secondary-color mr-3'>Informação</button>
              <button className='btn-disabled mr-3' disabled>Data</button>
              <button className='btn-disabled mr-3' disabled>Microciclo</button>
              <button className='btn-disabled mr-3' disabled>Turmas</button>
              <button className='btn-disabled' disabled>Resumo</button>
            </div>
          </div>
          <div className='form_modal'>
            <div
              data-for='informationTooltip'
              data-tip
              ref={inputRefInformation}
              className='w-100'
              style={{ padding: '0 30px' }}
            >
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label className='card_details_label'>Nome da Aula</label>
                    <input
                      required
                      type='text'
                      className='form_control'
                      name='name'
                      value={microcycle.name}
                      onChange={onChange}
                      placeholder='Nome da Aula'
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label className='card_details_label'>Objetivo</label>
                    <input
                      required
                      type='text'
                      className='form_control'
                      name='objective'
                      value={microcycle.objective}
                      onChange={onChange}
                      placeholder='Objetivo'
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label className='card_details_label'>Número da Aula</label>
                    <input
                      required
                      type='number'
                      className='form_control'
                      name='classNumber'
                      value={microcycle.classNumber}
                      onChange={onChange}
                      placeholder='Número da Aula'
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label htmlFor='status' className='card_details_label'>
                      Status
                    </label>
                    <select
                      required
                      name='status'
                      className='form_control select'
                      value={microcycle.status}
                      onChange={onChange}
                    >
                      <option value='' disabled hidden></option>
                      <option value='A'>Ativo</option>
                      <option value='I'>Inativo</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='main-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill'></div>
              </div>
              <div className='w-100 d-flex justify-content-center'>
                <button type='button' className='btn-main-color mr-3' onClick={() => ref.current.closeModal()}>
                  Voltar
                </button>
                <button className='btn-secondary-color' onClick={validateInformation}>
                  Avançar
                </button>
              </div>
            </div>
          </div>
          <ReactTooltip
            id='informationTooltip'
            type='warning'
            className={(errors) ? 'visible' : 'invisible'}>
            Preencha todos os dados corretamente
          </ReactTooltip>
        </>
      )}
      {session === 'period' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-disabled mr-3' disabled>Informação</button>
              <button className='btn-secondary-color mr-3'>Data</button>
              <button className='btn-disabled mr-3' disabled>Microciclo</button>
              <button className='btn-disabled mr-3' disabled>Turmas</button>
              <button className='btn-disabled' disabled>Resumo</button>
            </div>
          </div>
          <div className='form_modal'>
            <div className='w-100' style={{ padding: '0 30px' }}>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'
                    data-for='periodTooltip'
                    data-tip
                    ref={inputRefPeriod}>
                    <Calendar
                      locale={i18n.language}
                      value={selectedDate}
                      onChange={setSelectedDate}
                      style={{ width: '100%', border: 'none' }}
                      selectRange
                    />
                    <ReactTooltip
                      id='periodTooltip'
                      type='warning'
                      className={(!selectedDate || Object?.values(selectedDate)?.length !== 2) ? 'visible' : 'invisible'}>
                      Escolha o período corretamente
                    </ReactTooltip>
                  </div>
                </div>
              </div>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='disable-pill mr-2'></div>
                <div className='main-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill'></div>
              </div>
              <div className='w-100 d-flex justify-content-center'>
                <button type='button' className='btn-main-color mr-3' onClick={() => setSession('information')}>
                  Voltar
                </button>
                <button className='btn-secondary-color' onClick={(e) => validatePeriod(e)}>
                  Avançar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {session === 'microcycle' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-disabled mr-3' disabled>Informação</button>
              <button className='btn-disabled mr-3' disabled>Data</button>
              <button className='btn-secondary-color mr-3'>Microciclo</button>
              <button className='btn-disabled mr-3' disabled>Turmas</button>
              <button className='btn-disabled' disabled>Resumo</button>
            </div>
          </div>
          <div className='form_modal'>
            <div className='w-100' style={{ padding: '0 30px' }}>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='d-flex justify-content-between overflow-auto pb-2'>
                    {rangeDate && rangeDate?.map((d) => {
                      return (
                        <button className={`${d === date ? 'btn-secondary-color' : 'btn-disabled'} p-3 mx-2`} onClick={() => setDate(d)}>
                          <div className='d-flex justify-content-around align-items-center'>
                            <BsCalendar3 className='mr-2' />
                            {moment(d).format('DD/MM')}
                          </div>
                        </button>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div
                className='mt-3 grid-microcycle'
                data-for='microcycleTooltip'
                data-tip
                ref={inputRefMicrocycle}
              >
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label className='card_details_label'>Aula Escola</label>
                    <input
                      type='text'
                      className='form_control error'
                      name='classSchool'
                      value={filters.classSchool}
                      onChange={(e) => {
                        onChangeFilters(e);
                        setPlanningsSchoolFiltered(planningsSchool?.filter((planning) => planning?.name?.toUpperCase()?.includes(e?.target?.value?.toUpperCase() || '')))
                      }}
                      placeholder='Selecione uma ou mais aulas'
                      style={{ position: 'relative' }}
                    />
                    <div className='autocomplete-input' style={filters?.classSchool ? { display: 'block' } : { display: 'none' }}>
                      {classSchoolList}
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 mb-2'>
                  <div className="d-flex align-items-center"><span className='bagde-title'>Aula(s) selecionada(s)</span> {planningSchoolSelectedList}</div>
                </div>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label className='card_details_label'>Aula Clube</label>
                    <input
                      type='text'
                      className='form_control error'
                      name='classClub'
                      value={filters.classClub}
                      onChange={(e) => {
                        onChangeFilters(e);
                        setPlanningsClubFiltered(planningsClub?.filter((planning) => planning?.name?.toUpperCase()?.includes(e?.target?.value?.toUpperCase() || '')))
                      }}
                      placeholder='Selecione uma ou mais aulas'
                      style={{ position: 'relative' }}
                    />
                    <div className='autocomplete-input' style={filters?.classClub ? { display: 'block' } : { display: 'none' }}>
                      {classClubList}
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 mb-2'>
                  <div className="d-flex align-items-center"><span className='bagde-title'>Aula(s) selecionada(s)</span> {planningClubSelectedList}</div>
                </div>
                <ReactTooltip
                  id='microcycleTooltip'
                  type='warning'
                  className={(!planningsSchoolSelected?.filter((planning) => planning.date === date)?.length > 0 && !planningsClubSelected?.filter((planning) => planning.date === date)?.length > 0) ? 'visible' : 'invisible'}
                >
                  Escolha pelo menos uma aula
                </ReactTooltip>
                {isSubscriptiontActive && (
                  <>
                    <div className='col-sm-12'>
                      <div className='form_group'>
                        <label className='card_details_label'>Aula Administrador</label>
                        <input
                          type='text'
                          className='form_control error'
                          name='classAdmin'
                          value={filters.classAdmin}
                          onChange={(e) => {
                            onChangeFilters(e);
                            setPlanningsAdminFiltered(planningsAdmin?.filter((planning) => planning?.name?.toUpperCase()?.includes(e?.target?.value?.toUpperCase() || '')))
                          }}
                          placeholder='Selecione uma ou mais aulas'
                          style={{ position: 'relative' }}
                        />
                        <div className='autocomplete-input' style={filters?.classAdmin ? { display: 'block' } : { display: 'none' }}>
                          {classAdminList}
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 mb-2'>
                      <div className="d-flex align-items-center"><span className='bagde-title'>Aula(s) selecionada(s)</span> {planningAdminSelectedList}</div>
                    </div>
                  </>
                )}
              </div>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='main-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill'></div>
              </div>
              <div className='w-100 d-flex justify-content-center'>
                <button type='button' className='btn-main-color mr-3' onClick={() => setSession('period')}>
                  Voltar
                </button>
                <button className='btn-secondary-color' onClick={(e) => validateMicrocycle(e)}>
                  Avançar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {session === 'class' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-disabled mr-3' disabled>Informação</button>
              <button className='btn-disabled mr-3' disabled>Data</button>
              <button className='btn-disabled mr-3' disabled>Microciclo</button>
              <button className='btn-secondary-color mr-3'>Turmas</button>
              <button className='btn-disabled' disabled>Resumo</button>
            </div>
          </div>
          <div className='form_modal'>
            <div className='w-100' style={{ padding: '0 30px' }}>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <input
                      type='text'
                      className='form_control error'
                      name='class'
                      value={filters.class}
                      onChange={(e) => {
                        onChangeFilters(e);
                        setClassesFiltered(classes?.filter((c) => c?.class?.toUpperCase()?.includes(e?.target?.value?.toUpperCase() || '')))
                      }}
                      placeholder='Procurar turma'
                      style={{ position: 'relative' }}
                      data-for='classTooltip'
                      data-tip
                      ref={inputRefClass}
                    />
                    <div className='autocomplete-input' style={filters?.class ? { display: 'block' } : { display: 'none' }}>
                      {classesList}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 d-flex justify-content-between px-3'>
                  <div className='font-weight-bold pl-3' style={{ width: '240px' }}>Nome da Turma</div>
                  <div className='font-weight-bold' style={{ width: '120px' }}>Horário</div>
                  <div style={{ width: '100px' }}>&nbsp;</div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 d-flex flex-column mt-3 mb-3' style={{ height: '250px', overflowY: 'auto' }}>
                  {classesListSelected}
                </div>
              </div>
              <ReactTooltip
                id='classTooltip'
                type='warning'
              >
                Escolha pelo menos uma turma
              </ReactTooltip>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='main-pill mr-2'></div>
                <div className='disable-pill'></div>
              </div>
              <div className='w-100 d-flex justify-content-center'>
                <button type='button' className='btn-main-color mr-3' onClick={() => {
                  setSession('microcycle');
                  setDate(rangeDate[0]);
                }}>
                  Voltar
                </button>
                <button className='btn-secondary-color' onClick={(e) => validateClass(e)}>
                  Avançar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {session === 'resume' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-disabled mr-3' disabled>Informação</button>
              <button className='btn-disabled mr-3' disabled>Data</button>
              <button className='btn-disabled mr-3' disabled>Microciclo</button>
              <button className='btn-disabled mr-3' disabled>Turmas</button>
              <button className='btn-secondary-color' >Resumo</button>
            </div>
          </div>
          <form className='form_modal'>
            <div className='w-100' style={{ padding: '0 30px' }}>
              <div className='row'>
                <div className='col-sm-12 text-center'>
                  <h4>Microciclo para a data:</h4>
                </div>
                <div className='col-sm-12 text-center my-3'>
                  <BsCalendar3 className='mr-3' />
                  {rangeDate && moment(rangeDate[0]).format('DD/MM')} a {rangeDate && moment(rangeDate[rangeDate?.length - 1]).format('DD/MM')}
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 mb-3'>
                  <div className='d-flex justify-content-between overflow-auto pb-2'>
                    {rangeDate && rangeDate?.map((d) => {
                      return (
                        <button type='button' className={`${d === date ? 'btn-secondary-color' : 'btn-disabled'} p-3 mx-2`} onClick={() => setDate(d)}>
                          <div className='d-flex justify-content-around align-items-center'>
                            <BsCalendar3 className='mr-2' />
                            {moment(d).format('DD/MM')}
                          </div>
                        </button>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className='grid-microcycle p-3'>
                <div className='row border-bottom border-warning pb-3 mx-1'>
                  <div className='col-sm-12'>
                    <div className='font-weight-bold'>Objetivo</div>
                    <div className=''>{microcycle?.objective}</div>
                  </div>
                </div>
                <div className='row border-bottom border-warning py-3 mx-1'>
                  <div className='col-sm-6'>
                    <div className='font-weight-bold'>Nome da aula</div>
                    <div className=''>{microcycle?.name}</div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='font-weight-bold'>Número da aula</div>
                    <div className=''>{microcycle?.classNumber}</div>
                  </div>
                  <div className='col-sm-12 pt-3'>
                    <div className='font-weight-bold'>Turma(s) selecionada(as)</div>
                    <div className='resume'>
                      <ul>
                        {classesSelected?.map((c) => <li key={c.id}>{c.class}</li>)}
                      </ul>
                    </div>
                  </div>
                </div>
                {planningsSchoolSelected && planningsSchoolSelected?.filter((c) => c.date === date)?.length > 0 && (
                  <div className='row pt-3 mx-1'>
                    <div className='col-sm-12'>
                      <div className='font-weight-bold'>Aula da Escola</div>
                      <div className='resume'>
                        <ul>
                          {planningsSchoolSelected?.filter((c) => c.date === date)?.map((c) =>
                            <>
                              <li key={c.id}>
                                {c.name}
                                <div data-for={`lesson-description-${microcycle.id}-${c.id}`} data-tip><BsBoxArrowUpRight className='ml-2 secondary-color' /></div>
                                <ReactTooltip id={`lesson-description-${microcycle.id}-${c.id}`} place='right' className='rounded-lg'>
                                  <div className='mb-2'><span className='font-weight-bold'>Nome: </span><span>{c.name}</span></div>
                                  <div className='mb-2'><span className='font-weight-bold'>Objetivo: </span><span>{c.objective}</span></div>
                                  <div className='mb-2'><span className='font-weight-bold'>Número da Aula: </span><span>{c.classNumber}</span></div>
                                  <div className='mb-2'>
                                    <div className='font-weight-bold'>Tarefas</div>
                                    <ul style={{ listStyle: 'disc' }}>
                                      {c?.tasks?.map((task) => (<li className='ml-3' key={`task-${microcycle.id}-${c.id}-${task.id}`}>{task?.concept}</li>))}
                                    </ul>
                                  </div>
                                </ReactTooltip>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {planningsClubSelected && planningsClubSelected?.filter((c) => c.date === date)?.length > 0 && (
                  <div className='row pt-3 mx-1'>
                    <div className='col-sm-12'>
                      <div className='font-weight-bold'>Aula do Clube</div>
                      <div className='resume'>
                        <ul>
                          {planningsClubSelected?.filter((c) => c.date === date)?.map((c) =>
                            <>
                              <li key={c.id}>
                                {c.name}
                                <div data-for={`lesson-description-${microcycle.id}-${c.id}`} data-tip><BsBoxArrowUpRight className='ml-2 secondary-color' /></div>
                                <ReactTooltip id={`lesson-description-${microcycle.id}-${c.id}`} place='right' className='rounded-lg'>
                                  <div className='mb-2'><span className='font-weight-bold'>Nome: </span><span>{c.name}</span></div>
                                  <div className='mb-2'><span className='font-weight-bold'>Objetivo: </span><span>{c.objective}</span></div>
                                  <div className='mb-2'><span className='font-weight-bold'>Número da Aula: </span><span>{c.classNumber}</span></div>
                                  <div className='mb-2'>
                                    <div className='font-weight-bold'>Tarefas</div>
                                    <ul style={{ listStyle: 'disc' }}>
                                      {c?.tasks?.map((task) => (<li className='ml-3' key={`task-${microcycle.id}-${c.id}-${task.id}`}>{task?.concept}</li>))}
                                    </ul>
                                  </div>
                                </ReactTooltip>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {planningsAdminSelected && planningsAdminSelected?.filter((c) => c.date === date)?.length > 0 && (
                  <div className='row pt-3 mx-1'>
                    <div className='col-sm-12'>
                      <div className='font-weight-bold'>Aula do Administrador</div>
                      <div className='resume'>
                        <ul>
                          {planningsAdminSelected?.filter((c) => c.date === date)?.map((c) =>
                            <>
                              <li key={c.id}>
                                {c.name}
                                <div data-for={`lesson-description-${microcycle.id}-${c.id}`} data-tip><BsBoxArrowUpRight className='ml-2 secondary-color' /></div>
                                <ReactTooltip id={`lesson-description-${microcycle.id}-${c.id}`} place='right' className='rounded-lg'>
                                  <div className='mb-2'><span className='font-weight-bold'>Nome: </span><span>{c.name}</span></div>
                                  <div className='mb-2'><span className='font-weight-bold'>Objetivo: </span><span>{c.objective}</span></div>
                                  <div className='mb-2'><span className='font-weight-bold'>Número da Aula: </span><span>{c.classNumber}</span></div>
                                  <div className='mb-2'>
                                    <div className='font-weight-bold'>Tarefas</div>
                                    <ul style={{ listStyle: 'disc' }}>
                                      {c?.tasks?.map((task) => (<li className='ml-3' key={`task-${microcycle.id}-${c.id}-${task.id}`}>{task?.concept}</li>))}
                                    </ul>
                                  </div>
                                </ReactTooltip>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='main-pill'></div>
              </div>
              {!onlyView && (
                <div className='w-100 d-flex justify-content-center mb-3'>
                  <button type='button' className='btn-main-color mr-3' onClick={() => setSession('class')}>
                    Voltar
                  </button>
                  <button className='btn-secondary-color' onClick={handleSubmit}>
                    Salvar Alterações
                  </button>
                </div>
              )}
            </div>
          </form>
          <div className="modal_success" style={success ? { display: 'flex', opacity: 1, alignSelf: 'center' } : undefined}>
            <div className='modal_content_success'>
              <div className="modal_header_success">
                <div className='modal_title_success'>{microcycle?.id === undefined ? 'Microciclo criado' : 'Alterações salvas'}</div>
                <div className='modal_close_success' onClick={() => ref.current.closeModal()}>
                  X
                </div>
              </div>
              <div className='modal_body_success'>
                <div className='modal_icon_success'>
                  <Success style={{ color: '#24c782' }} />
                </div>
                {microcycle?.id === undefined ? 'Microciclo criado com sucesso!' : 'Microciclo editado com sucesso!'}
              </div>
              <div className='modal_footer_success'>
                <button type='button' onClick={() => ref.current.closeModal()} className='btn-secondary-color w-100'>
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </ModalWithMethods>
  );
};

export default forwardRef(EditMicrocycle);
