import moment from 'moment';

export const dateFormatI18n = (date, language) => {
  switch (language) {
    case 'pt-BR':
      return moment(date).format('DD/MM/YYYY');

    case 'en-US':
      return moment(date).format('MM/DD/YYYY');

    case 'en-US-Arabic':
      return moment(date).format('MM/DD/YYYY');

    default:
      break;
  }
};

export const cellPhoneFormatI18n = (cellPhone, language) => {
  if(cellPhone)
    switch (language) {
      case 'pt-BR':
        if (cellPhone.length == 11) {
          return cellPhone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, '($1)$2 $3-$4');
        } else {
          return cellPhone.replace(/(\d{2})(\d{4})(\d{4})/g, '($1)$2-$3');
        }

      case 'en-US':
        return cellPhone.replace(/(\d{3})(\d{3})(\d{4})/g, '($1) $2-$3');

      case 'en-US-Arabic':
        return cellPhone.replace(/(\+\d{3})(\d{3})(\d{7})/g, '$1 ($2) $3');

      default:
        break;
    }
};

export const cpfCnpjFormatI18n = (value, language) => {
  switch (language) {
    case 'pt-BR':
      if (value.length == 11) {
        return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
      } else {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
      }

    case 'en-US':
      return value;

    case 'en-US-Arabic':
      return value;

    default:
      break;
  }
};

export const creditCardNumberFormat = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{4})(\d)/g, '$1 $2')
    .replace(/^(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3')
    .replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3 $4');
};
