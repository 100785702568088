import React, { useEffect, useState, useRef } from 'react'
import Header from '../../components/athlete/Header'
import Footer from '../../components/athlete/Footer'
import Loading from '../../components/Loading'
import utils from '../../utils/utils'
import Api from '../../utils/api'
import axios from 'axios'

const Content = (props) => {

   const scrollObserve = useRef();
   const { accordion } = utils()
   const baseURL = Api()
   
   const [sendContent, setSendContent] = useState(false)
   const [sendingSearch, setSendingSearch] = useState(false)

   const [search, setSearch] = useState('')
   const [content, setContent] = useState([])
   const [page, setPage] = useState(0)
   const [scrollRadio, setScrollRadio] = useState(null)

   const intersectionObserver = new IntersectionObserver( entries => {
      const radio = entries[0].intersectionRatio
      setScrollRadio(radio)
   })

   useEffect(() => {

      getContent(page, false)

      intersectionObserver.observe(scrollObserve.current)

      return () => {
         intersectionObserver.disconnect()
      }

   },[])

   useEffect(() => {

      if ( scrollRadio > 0 && content.length > 0) {
         const novaPage = page + 10
         setPage(novaPage)

         getContent(novaPage, false)
      }

   },[scrollRadio])

   const getContent = async (novaPage, clear) => {

      if ( clear )
         setSendingSearch(true)

      setSendContent(true)
      const res = await axios.post(baseURL + 'get-content',{
         search,
         start: novaPage,
         length: 20}
         )

      if ( res.data.length > 0 ) {
         for ( let i = 0; i < res.data.length; i = i + 1 ) {
            let urlVideo = ''
            if ( res.data[i].link_video !== '' ) {
               if ( res.data[i].link_video.indexOf('youtube.com') != -1 ) {
                  urlVideo = res.data[i].link_video.replace('watch?v=','embed/')
               } else if ( res.data[i].link_video.indexOf('vimeo.com') != -1 ) {
                  let stringVideo = res.data[i].link_video.split('/')
                  urlVideo = 'https://player.vimeo.com/video/' + stringVideo[parseInt(stringVideo.length) - 1]
               }
            }

            res.data[i].link_video = urlVideo
         }
      }

      if( clear ) {
         setContent(res.data)
      } else {
         const news = [...content]
         news.push(...res.data)
         setContent(news)
      }

      setSendContent(false)
       if ( clear )
         setSendingSearch(false)
   }

   const handleSubmit = async (evt) => {
      evt.preventDefault()
      
      setPage(0)
      getContent(0, true)
   }

   return (
         <React.Fragment>
            
            <Header type="1" menu={true}/>

            <main>
               <section className="bloco-principal">
                  <div className="conteudo-bloco-principal bloco-pg-seletivas">
                     <div className="bloco-2 excluir">
                        <div className="conteudo-generico">
                           <div className="coluna-pg-seletivas">
                              <h3>Procurar</h3>
                              <p>Informe a palavra chave: exemplo: evento, seletiva...</p>
                           </div>
                           <form className="pesquisa-pg-seletivas" onSubmit={handleSubmit} action="" method="">
                              <div className="coluna-curriculo">
                                 <label>Pesquisar</label>
                                 <input type="search" name="search" placeholder="Digite aqui..." onChange={(e) => setSearch(e.target.value)}/>
                              </div>
                              
                              <div className="botao-pesquisa-seletivas">
                              {
                                 !sendingSearch &&
                                 <button type="submit">Buscar</button>
                              }
                              {
                                 sendingSearch && <span>Estamos procurando... <Loading type="1"/></span>
                              } 
                              </div>
                           </form>
                        </div>
                     </div>

                     <div className="pesquisa-responsivo">
                        <button className="accordion" onClick={accordion}>Procurar <img src="../../img/icones/seta-2.png" /></button>

                        <div className="panel">
                           <form className="pesquisa-pg-seletivas" onSubmit={handleSubmit} action="" method="">
                              <div className="coluna-curriculo">
                                 <label>Pesquisar</label>
                                 <input type="search" name="search" placeholder="Digite aqui..." onChange={(e) => setSearch(e.target.value)}/>
                              </div>
                              
                              <div className="botao-pesquisa-seletivas">
                              {
                                 !sendingSearch &&
                                 <button type="submit">Buscar</button>
                              }
                              {
                                 sendingSearch && <span>Estamos procurando... <Loading type="3"/></span>
                              } 
                              </div>
                           </form>
                        </div>
                     </div>
                     <div className="bloco-1-secundario">
                        <div className="eventos">
                           <div className="titulo-seletivas">
                              <h2>Conteúdo</h2>
                              <p>Fique por dentro das notícias publicadas pela Fensor aqui.</p>
                           </div>
                  
                           <ul className="datas-eventos">
                              {
                                 sendContent && <p style={{marginBottom: 10}}><Loading type="3" /></p>
                              }
                              {
                                 content.length == 0 && 
                                 sendContent === false &&
                                 sendingSearch === false &&
                                 <p>Nenhum conteúdo disponível no momento.</p> 
                              }
                              {
                                 content.map((item, e) => {
                                    return (
                                       <li className="informacoes-eventos" key={`conteudo` + item.id}>
                                          <div className="col-1-eventos">
                                             <iframe type="text/html" className="videoContent" src={item.link_video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                          </div>
                           
                                          <div className="data-btn-eventos">
                                             <div className="col-1-eventos" style={{paddingLeft: 15, paddingTop: 0}}>
                                                <div className="info-eventos">
                                                   <div className="info-col-3-sel">
                                                      <div className="nome-col-3">
                                                         <h3 style={{marginBottom: 4}}>{item.title}</h3>
                                                         <p className="data-content" >De {item.date_formatado}</p>
                                                      </div>
                                                   </div>
                        
                                                   <div className="info-col-3-sel">
                                                      <div className="idade-col-3">
                                                         <p>{item.description}</p>
                                                      </div>
                                                   </div>

                                                </div>
                                             </div>
                                             
                                          </div>
                                       </li>
                                    )
                                 })
                              }
                           </ul>
                           <div ref={scrollObserve}></div>
                        </div>
                     </div>
                  </div>
               </section>
            </main>

            <Footer />
          
         </React.Fragment>
   )
}

export default Content