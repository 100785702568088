import React, { useState, forwardRef, useRef, useCallback, useEffect, useMemo } from 'react';
import { handleOnChange } from '../../utils/utils';
import { ModalWithMethods } from '../../components/modal/Modal';
import { getAllLessonsPlanning } from '../../services/AdminService';
import ReactTooltip from 'react-tooltip'
import { BsBoxArrowUpRight, BsCalendar3 } from 'react-icons/bs';
import { Success } from '../../components/svgs/Success';

const EditMicrocycle = (
  { data, onSubmit, onlyView, ...props },
  ref
) => {
  const inputRefInformation = useRef(null);
  const inputRefMicrocycle = useRef(null);

  const [filters, setFilters] = useState({
    name: '',
    class: '',
    source: 'P',
    status: 'A',
  });
  // dados
  const [microcycle, setMicrocycle] = useState({
    id: undefined,
    name: '',
    status: 'A',
    classNumber: '',
    classQuantity: 0,
    objective: '',
  });

  const [rangeDay, setRangeDay] = useState([]);
  const [day, setDay] = useState(null);
  const [plannings, setPlannings] = useState([])
  const [planningsFiltered, setPlanningsFiltered] = useState([]);
  const [planningsSelected, setPlanningsSelected] = useState([]);

  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(null);
  const [session, setSession] = useState('information');

  const onChange = handleOnChange(setMicrocycle);

  const onChangeFilters = handleOnChange(setFilters);

  const fetchAllLessonsPlanning = async () => {
    const { status, plannings, message } = await getAllLessonsPlanning(1, 0);
    if (status === 'SUCCESS') {
      setPlannings(plannings);
      setPlanningsFiltered(plannings);
    } else {
      console.log(message);
    }
  };

  const validateInformation = (e) => {
    e.preventDefault()
    const validate = {}
    if (!microcycle?.name) validate['name'] = 'Nome da aula é obrigatório';
    if (!microcycle?.objective) validate['objective'] = 'Objetivo é obrigatório';
    if (!microcycle?.classNumber) validate['classNumber'] = 'Número da aula é obrigatório';
    if (!microcycle?.classQuantity) validate['classQuantity'] = 'Quantidade de aulas é obrigatório';
    if (!microcycle?.status) validate['status'] = 'Status é obrigatório';
    if (Object.keys(validate)?.length === 0) { setSession('microcycle') } else { setErrors(validate); ReactTooltip.show(inputRefInformation.current); }
  }

  const validateMicrocycle = (e) => {
    e.preventDefault();
    const validate = rangeDay?.map((day) => {
      const findPlanning = planningsSelected?.find((planning) => planning.day === day);
      if (!findPlanning) { return day }
    })?.filter((day) => day !== undefined);
    if (Object.values(validate)?.length > 0) {
      setDay(validate[0]);
      ReactTooltip.show(inputRefMicrocycle.current);
    } else {
      setDay(rangeDay[0]);
      setSession('resume');
    }
  }

  const onShow = () => {
    ref.current.clearMessage();
    setSuccess(false);
    setErrors(null);
    setDay(data?.days?.length ? data?.days[0]?.day : null)
    setPlanningsSelected(data?.classes ?? []);
    !onlyView && fetchAllLessonsPlanning();
    setMicrocycle(data);
    setSession(onlyView ? 'resume' : 'information');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    ref.current.clearMessage();

    const request = {
      ...microcycle,
      days: rangeDay?.map((day) => ({ day })),
      classes: planningsSelected?.map((planning) => ({ lesson_planning_id: planning.id, day: planning.day })),
    };

    const { status, messageKey } = microcycle?.id
      ? await onSubmit({ id: microcycle.id, ...request })
      : await onSubmit({ admin_id: 1, ...request });

    if (status === 'SUCCESS') {
      setSuccess(true)
    } else {
      showErrorMessage(messageKey);
    }
  }

  const showErrorMessage = (message) => {
    ref.current.showErrorMessage(message);
    ref.current.scrollTop();
  };

  const calculateRangeDay = () => {
    if (microcycle.classQuantity > 0) {
      const range = Array(Number(microcycle.classQuantity)).fill().map((_, i) => i + 1)
      setRangeDay(range)
      setDay(range[0])
    }
  }

  const addPlanning = useCallback((planning) => {
    const find = planningsSelected?.find((value) => value.key === `${day}-${planning.id}`);
    !find && setPlanningsSelected(old => [...old, { ...planning, tasks: planning.lessons, day, key: `${day}-${planning.id}` }])
    setFilters({ class: '' })
  }, [planningsSelected, day])

  const removePlanning = useCallback((value) => {
    const plan = planningsSelected.filter((planning) => planning.key !== `${day}-${value.id}`)
    setPlanningsSelected(plan)
  }, [planningsSelected, day])

  useEffect(() => {
    calculateRangeDay();
  }, [microcycle?.classQuantity])

  const classList = useMemo(() => {
    return planningsFiltered?.map((planning) => {
      return (
        <div className='autocomplete-input-list' onClick={() => addPlanning(planning)} key={planning.id}>
          {planning?.name}
        </div>
      )
    })
  }, [planningsFiltered])

  const planningSelectedList = useMemo(() => {
    return (
      <div className='d-flex align-items-center'>
        {planningsSelected.filter((planning) => planning.day === day)?.sort((a, b) => a.name - b.name)?.map((planning) => {
          return (
            <div key={planning?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <button type='button' onClick={() => removePlanning(planning)} className='badge-items-button'>X</button>
              <span className='badge-items'>{planning?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [planningsSelected, day])

  return (
    <ModalWithMethods
      {...props}
      ref={ref}
      title={<div className='modal_title' style={{ padding: '0 30px' }}>{onlyView ? 'Visualizar Microciclo' : microcycle?.id ? 'Editar Microciclo' : 'Criar Microciclo'}</div>}
      size={'lg'}
      onShow={onShow}
      modalContentStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 20 }}
    >
      {session === 'information' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-secondary-color mr-3'>Informação</button>
              <button className='btn-disabled mr-3' disabled>Microciclo</button>
              <button className='btn-disabled' disabled>Resumo</button>
            </div>
          </div>
          <div className='form_modal'>
            <div
              data-for='informationTooltip'
              data-tip
              ref={inputRefInformation}
              className='w-100'
              style={{ padding: '0 30px' }}
            >
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label className='card_details_label'>Nome da Aula</label>
                    <input
                      required
                      type='text'
                      className='form_control'
                      name='name'
                      value={microcycle.name}
                      onChange={onChange}
                      placeholder='Nome da Aula'
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label className='card_details_label'>Objetivo</label>
                    <input
                      required
                      type='text'
                      className='form_control'
                      name='objective'
                      value={microcycle.objective}
                      onChange={onChange}
                      placeholder='Objetivo'
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label className='card_details_label'>Número da Aula</label>
                    <input
                      required
                      type='number'
                      className='form_control'
                      name='classNumber'
                      value={microcycle.classNumber}
                      onChange={onChange}
                      placeholder='Número da Aula'
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label className='card_details_label'>Quantidade de Aulas</label>
                    <input
                      required
                      type='number'
                      className='form_control'
                      name='classQuantity'
                      value={microcycle.classQuantity}
                      onChange={onChange}
                      placeholder='Quantidade de Aulas'
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label htmlFor='status' className='card_details_label'>
                      Status
                    </label>
                    <select
                      required
                      name='status'
                      className='form_control select'
                      value={microcycle.status}
                      onChange={onChange}
                    >
                      <option value='' disabled hidden></option>
                      <option value='A'>Ativo</option>
                      <option value='I'>Inativo</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='main-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill'></div>
              </div>
              <div className='w-100 d-flex justify-content-center'>
                <button type='button' className='btn-main-color mr-3' onClick={() => ref.current.closeModal()}>
                  Voltar
                </button>
                <button className='btn-secondary-color' onClick={validateInformation}>
                  Avançar
                </button>
              </div>
            </div>
          </div>
          <ReactTooltip
            id='informationTooltip'
            type='warning'
            className={(errors) ? 'visible' : 'invisible'}>
            Preencha todos os dados corretamente
          </ReactTooltip>
        </>
      )}
      {session === 'microcycle' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-disabled mr-3' disabled>Informação</button>
              <button className='btn-secondary-color mr-3'>Microciclo</button>
              <button className='btn-disabled' disabled>Resumo</button>
            </div>
          </div>
          <div className='form_modal'>
            <div className='w-100' style={{ padding: '0 30px' }}>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='d-flex justify-content-between overflow-auto pb-2'>
                    {rangeDay && rangeDay?.map((d) => {
                      return (
                        <button className={`${d === day ? 'btn-secondary-color' : 'btn-disabled'} p-3 mx-2`} onClick={() => setDay(d)}>
                          <div className='d-flex justify-content-around align-items-center'>
                            <BsCalendar3 className='mr-2' />
                            {d}
                          </div>
                        </button>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div
                className='mt-3 grid-microcycle'
                data-for='microcycleTooltip'
                data-tip
                ref={inputRefMicrocycle}
              >
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label className='card_details_label'>Aula</label>
                    <input
                      type='text'
                      className='form_control error'
                      name='class'
                      value={filters.class}
                      onChange={(e) => {
                        onChangeFilters(e);
                        setPlanningsFiltered(plannings?.filter((planning) => planning?.name?.toUpperCase()?.includes(e?.target?.value?.toUpperCase() || '')))
                      }}
                      placeholder='Selecione uma ou mais aulas'
                      style={{ position: 'relative' }}
                    />
                    <div className='autocomplete-input' style={filters?.class ? { display: 'block' } : { display: 'none' }}>
                      {classList}
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 mb-2'>
                  <div className="d-flex align-items-center"><span className='bagde-title'>Aula(s) selecionada(s)</span> {planningSelectedList}</div>
                </div>
                <ReactTooltip
                  id='microcycleTooltip'
                  type='warning'
                  className={(!planningsSelected?.filter((planning) => planning.day === day)?.length > 0) ? 'visible' : 'invisible'}
                >
                  Escolha pelo menos uma aula
                </ReactTooltip>
              </div>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='disable-pill mr-2'></div>
                <div className='main-pill mr-2'></div>
                <div className='disable-pill'></div>
              </div>
              <div className='w-100 d-flex justify-content-center'>
                <button type='button' className='btn-main-color mr-3' onClick={() => setSession('information')}>
                  Voltar
                </button>
                <button className='btn-secondary-color' onClick={(e) => validateMicrocycle(e)}>
                  Avançar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {session === 'resume' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-disabled mr-3' disabled>Informação</button>
              <button className='btn-disabled mr-3' disabled>Microciclo</button>
              <button className='btn-secondary-color' >Resumo</button>
            </div>
          </div>
          <form className='form_modal'>
            <div className='w-100' style={{ padding: '0 30px' }}>
              <div className='row'>
                <div className='col-sm-12 text-center mb-3'>
                  <h4>Microciclo para {rangeDay?.length} dia(s):</h4>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 mb-3'>
                  <div className='d-flex justify-content-between overflow-auto pb-2'>
                    {rangeDay && rangeDay?.map((d) => {
                      return (
                        <button type='button' className={`${d === day ? 'btn-secondary-color' : 'btn-disabled'} p-3 mx-2`} onClick={() => setDay(d)}>
                          <div className='d-flex justify-content-around align-items-center'>
                            <BsCalendar3 className='mr-2' />
                            {d}
                          </div>
                        </button>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className='grid-microcycle p-3'>
                <div className='row border-bottom border-warning pb-3 mx-1'>
                  <div className='col-sm-12'>
                    <div className='font-weight-bold'>Objetivo</div>
                    <div className=''>{microcycle?.objective}</div>
                  </div>
                </div>
                <div className='row border-bottom border-warning py-3 mx-1'>
                  <div className='col-sm-6'>
                    <div className='font-weight-bold'>Nome da aula</div>
                    <div className=''>{microcycle?.name}</div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='font-weight-bold'>Número da aula</div>
                    <div className=''>{microcycle?.classNumber}</div>
                  </div>
                </div>
                {planningsSelected && planningsSelected?.filter((c) => c.day === day)?.length > 0 && (
                  <div className='row pt-3 mx-1'>
                    <div className='col-sm-12'>
                      <div className='font-weight-bold'>Aula da Escola</div>
                      <div className='resume'>
                        <ul>
                          {planningsSelected?.filter((c) => c.day === day)?.map((c) =>
                            <>
                              <li key={c.id}>
                                {c.name}
                                <div data-for={`lesson-description-${microcycle.id}-${c.id}`} data-tip><BsBoxArrowUpRight className='ml-2 secondary-color' /></div>
                                <ReactTooltip id={`lesson-description-${microcycle.id}-${c.id}`} place='right' className='rounded-lg'>
                                  <div className='mb-2'><span className='font-weight-bold'>Nome: </span><span>{c.name}</span></div>
                                  <div className='mb-2'><span className='font-weight-bold'>Objetivo: </span><span>{c.objective}</span></div>
                                  <div className='mb-2'><span className='font-weight-bold'>Número da Aula: </span><span>{c.classNumber}</span></div>
                                  <div className='mb-2'>
                                    <div className='font-weight-bold'>Tarefas</div>
                                    <ul style={{ listStyle: 'disc' }}>
                                      {c?.tasks?.map((task) => (<li className='ml-3' key={`task-${microcycle.id}-${c.id}-${task.id}`}>{task?.concept}</li>))}
                                    </ul>
                                  </div>
                                </ReactTooltip>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='main-pill'></div>
              </div>
              {!onlyView && (
                <div className='w-100 d-flex justify-content-center mb-3'>
                  <button type='button' className='btn-main-color mr-3' onClick={() => setSession('microcycle')}>
                    Voltar
                  </button>
                  <button className='btn-secondary-color' onClick={handleSubmit}>
                    Salvar Alterações
                  </button>
                </div>
              )}
            </div>
          </form>
          <div className="modal_success" style={success ? { display: 'flex', opacity: 1, alignSelf: 'center' } : undefined}>
            <div className='modal_content_success'>
              <div className="modal_header_success">
                <div className='modal_title_success'>{microcycle?.id === undefined ? 'Microciclo criado' : 'Alterações salvas'}</div>
                <div className='modal_close_success' onClick={() => ref.current.closeModal()}>
                  X
                </div>
              </div>
              <div className='modal_body_success'>
                <div className='modal_icon_success'>
                  <Success style={{ color: '#24c782' }} />
                </div>
                {microcycle?.id === undefined ? 'Microciclo criado com sucesso!' : 'Microciclo editado com sucesso!'}
              </div>
              <div className='modal_footer_success'>
                <button type='button' onClick={() => ref.current.closeModal()} className='btn-secondary-color w-100'>
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </ModalWithMethods>
  );
};

export default forwardRef(EditMicrocycle);
