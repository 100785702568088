import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import Select, { createFilter } from 'react-select';
import Utils from '../../../src/utils/utils';
import Loading from '../../components/Loading';
import Footer from '../../components/admin/Footer';
import Header from '../../components/admin/Header';
import SimpleModal from '../../components/modal/SimpleModal';
import TableBordered from '../../components/tables/TableBordered';
import { getCountClubSchoolAsaas, getListAccounts, saveTokenClub, saveTokenSchool } from '../../services/AsaasService';
import { getAccountFensorAsaas } from '../../utils/api';

let tokenAsaas = getAccountFensorAsaas().token_assas;
let walletID = '0f29a0b1-34fe-4e65-9e90-f15eeb79e280';

const Home = () => {
  const [accounts, setAccounts] = useState(null);
  const [unusedAccounts, setUnusedAccounts] = useState('-');
  const [accountSelected, setAccountSelected] = useState(null);
  const [selected, setSelected] = useState(null);

  const [countClub, setCountClub] = useState('-');
  const [countClubAsaas, setCountClubAsaas] = useState(null);
  const [countClubFaturando, setCountClubFaturando] = useState('-');
  const [countClubSemAsaas, setCountClubSemAsaas] = useState(null);

  const [countSchool, setCountSchool] = useState('-');
  const [countSchoolAsaas, setCountSchoolAsaas] = useState(null);
  const [countSchoolFaturando, setCountSchoolFaturando] = useState('-');
  const [countSchoolSemAsaas, setCountSchoolSemAsaas] = useState(null);

  const [process, setProcess] = useState(false);
  const [processPage, setProcessPage] = useState(false);

  const [isShowModalManager, setIsShowModalManager] = useState(false);

  const [filterModalManager, setFilterModalManager] = useState('');
  const [dataModalManager, setDataModalManager] = useState([]);
  const [dataModalManagerFiltered, setDataModalManagerFiltered] = useState([]);

  const [titleModalManager, setTitleModalManager] = useState('');
  const [typeModalManager, setTypeModalManager] = useState(null);

  const { setThemeSchoolDefault, formatCNPJCPF } = Utils();

  useEffect(() => {
    setThemeSchoolDefault();

    getData();
  }, []);

  useEffect(() => {
    setDataModalManagerFiltered(
      dataModalManager.filter((item) =>
        [
          item.auxMap.name,
          item.auxMap.cpfCnpj,
          item.auxMap.asaasCpfCnpj || '',
          item.auxMap.asaasCpfCnpj ? formatCNPJCPF(item.auxMap.asaasCpfCnpj) : '',
          item.auxMap.asaasLoginEmail || '',
        ].some((i) => i.toLowerCase().includes(filterModalManager.toLowerCase()))
      )
    );
  }, [filterModalManager, dataModalManager]);

  const getData = async () => {
    setProcessPage(true);
    const [accounts, tokens] = await Promise.all([getCountAccountsAsaas(), getCountClubSchool()]);
    setProcessPage(false);

    const count = accounts?.filter((account) => !tokens.includes(account.token)) || [];
    setUnusedAccounts(count.length);
  };

  const getCountAccountsAsaas = async () => {
    const response = await getListAccounts(tokenAsaas);
    const accounts = response.data;
    setAccounts(accounts);

    return accounts;
  };

  const getCountClubSchool = async () => {
    const response = await getCountClubSchoolAsaas();

    setCountClub(response.clubTotal);
    setCountClubFaturando(response.clubsFaturandoTotal);

    setCountSchool(response.schoolTotal);
    setCountSchoolFaturando(response.schoolsFaturandoTotal);

    setCountClubAsaas(
      response.clubsAsaas?.map((club) => ({
        ...club,
        defaultValue: club.comCnpjAsaas,
        auxMap: {
          name: club.comCompanie,
          cpfCnpj: club.comCNPJ,

          asaasToken: club.comToken,
          asaasCpfCnpj: club.comCnpjAsaas,
          asaasLoginEmail: club.comLoginEmailAsaas,
        },
      }))
    );

    setCountClubSemAsaas(
      response.clubsSemAsaas?.map((club) => ({
        ...club,
        defaultValue: club.comCnpjAsaas,
        auxMap: {
          name: club.comCompanie,
          cpfCnpj: club.comCNPJ,

          asaasToken: club.comToken,
          asaasCpfCnpj: club.comCnpjAsaas,
          asaasLoginEmail: club.comLoginEmailAsaas,
        },
      }))
    );

    setCountSchoolAsaas(
      response.schoolAsaas?.map((school) => ({
        ...school,
        defaultValue: school.schCnpjAsaas,
        auxMap: {
          name: school.schName,
          cpfCnpj: school.schCnpj,

          asaasToken: school.schToken,
          asaasCpfCnpj: school.schCnpjAsaas,
          asaasLoginEmail: school.schLoginEmailAsaas,
        },
      }))
    );

    setCountSchoolSemAsaas(
      response.schoolsSemAsaas?.map((school) => ({
        ...school,
        defaultValue: school.schCnpjAsaas,
        auxMap: {
          name: school.schName,
          cpfCnpj: school.schCnpj,

          asaasToken: school.schToken,
          asaasCpfCnpj: school.schCnpjAsaas,
          asaasLoginEmail: school.schLoginEmailAsaas,
        },
      }))
    );

    return response.tokens;
  };

  const saveAccount = async (type) => {
    setProcess(true);

    const body = {
      token: type === 'LOCK' && accountSelected ? accountSelected.account.token : null,
      cpfCnpjAsaas: type === 'LOCK' && accountSelected ? accountSelected.account.cpfCnpj : null,
      loginEmail: type === 'LOCK' && accountSelected ? accountSelected.account.loginEmail : null,
      walletID,
      id: selected.id,
    };

    if (typeModalManager === 'CLUB') await saveTokenClub(body);
    else if (typeModalManager === 'SCHOOL') await saveTokenSchool(body);

    setProcess(false);

    setSelected(null);
    setAccountSelected(null);

    alert('Dados atualizados com sucesso!');
    closeModalManager();
    getCountClubSchool();
  };

  const getASAASAccount = (token, { cpfCnpj, loginEmail }) => {
    if (!token) {
      return [];
    } else {
      const accountsFiltered = (accounts || []).filter((account) => {
        const cpfCnpjAux = !!account.cpfCnpj && account.cpfCnpj === cpfCnpj;
        const loginEmailAux = loginEmail ? account.loginEmail === loginEmail : true;

        return cpfCnpjAux && loginEmailAux;
      });

      return accountsFiltered;
    }
  };

  const showModalManager = (data, title, type) => {
    setTitleModalManager(title);
    setTypeModalManager(type);
    setFilterModalManager('');

    setAccountSelected(null);
    setSelected(null);
    setDataModalManager(data);

    setIsShowModalManager(true);
  };

  const closeModalManager = () => {
    setIsShowModalManager(false);

    setTitleModalManager('');
    setTypeModalManager(null);
    setFilterModalManager('');

    setAccountSelected(null);
    setSelected(null);
    setDataModalManager([]);
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel' style={{ position: 'relative' }}>
              {processPage && <Loading type='6' />}
              <Header />
              <div className='sidebar'>
                <div className='card' style={{ paddingBottom: 0, paddingTop: 10 }}>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>Contas no Asaas</h2>
                    <div className='sidebar_data'>{accounts ? accounts.length : '-'}</div>
                  </div>
                </div>

                <div className='card' style={{ paddingBottom: 0, paddingTop: 10 }}>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>Clubes ativos</h2>
                    <div className='sidebar_data'>{countClub}</div>
                  </div>
                </div>
                <div className='card' style={{ paddingBottom: 0, paddingTop: 10 }}>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>Clubes faturando em {moment().format('MMM/YYYY')}</h2>
                    <div className='sidebar_data'>{countClubFaturando}</div>
                  </div>
                </div>

                <div className='card' style={{ paddingBottom: 0, paddingTop: 10 }}>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>Escolas Ativas</h2>
                    <div className='sidebar_data'>{countSchool}</div>
                  </div>
                </div>
                <div className='card' style={{ paddingBottom: 0, paddingTop: 10 }}>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>
                      Escolas faturando em {new Date().toUTCString().substr(8, 3) + '/' + new Date().getFullYear()}{' '}
                    </h2>
                    <div className='sidebar_data'>{countSchoolFaturando}</div>
                  </div>
                </div>
              </div>

              <div className='content'>
                <div className='card' style={{ paddingBottom: 0, paddingTop: 10 }}>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>Contas no ASAAS não utilizadas</h2>
                    <div className='sidebar_data'>{unusedAccounts}</div>
                  </div>
                </div>
                <div
                  className='card'
                  style={{ paddingBottom: 0, paddingTop: 10, cursor: countClubAsaas ? 'pointer' : 'wait' }}
                  onClick={
                    countClubAsaas
                      ? () => showModalManager(countClubAsaas, 'Clubes ativos com ASAAS', 'CLUB')
                      : undefined
                  }
                >
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>Clubes ativos com ASAAS</h2>
                    <div className='sidebar_data'>{countClubAsaas ? countClubAsaas.length : '-'}</div>
                  </div>
                </div>
                <div
                  className='card'
                  style={{ paddingBottom: 0, paddingTop: 10, cursor: countClubSemAsaas ? 'pointer' : 'wait' }}
                  onClick={
                    countClubSemAsaas
                      ? () => showModalManager(countClubSemAsaas, 'Clubes ativos sem ASAAS', 'CLUB')
                      : undefined
                  }
                >
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>Clubes ativos sem ASAAS</h2>
                    <div className='sidebar_data'>{countClubSemAsaas ? countClubSemAsaas.length : '-'}</div>
                  </div>
                </div>

                <div
                  className='card'
                  style={{ paddingBottom: 0, paddingTop: 10, cursor: countSchoolAsaas ? 'pointer' : 'wait' }}
                  onClick={
                    countSchoolAsaas
                      ? () => showModalManager(countSchoolAsaas, 'Escolas Ativas com Asaas', 'SCHOOL')
                      : undefined
                  }
                >
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>Escolas Ativas com Asaas</h2>
                    <div className='sidebar_data'>{countSchoolAsaas ? countSchoolAsaas.length : '-'}</div>
                  </div>
                </div>
                <div
                  className='card'
                  style={{ paddingBottom: 0, paddingTop: 10, cursor: countSchoolSemAsaas ? 'pointer' : 'wait' }}
                  onClick={
                    countSchoolSemAsaas
                      ? () => showModalManager(countSchoolSemAsaas, 'Escolas Ativas sem Asaas', 'SCHOOL')
                      : undefined
                  }
                >
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>Escolas Ativas sem Asaas</h2>
                    <div className='sidebar_data'>{countSchoolSemAsaas ? countSchoolSemAsaas.length : '-'}</div>
                  </div>
                </div>
              </div>
            </main>

            <SimpleModal size='xl' isShow={isShowModalManager} handleCloseModal={() => closeModalManager()}>
              <h3 className='modal_title d-flex align-items-center'>
                <span className='badge-simple-red mr-1'>{dataModalManager ? dataModalManager.length : '-'}</span>
                {titleModalManager}
              </h3>
              <h4 className='text-bold mt-3'>
                {selected ? `${selected.auxMap.name} - ${formatCNPJCPF(selected.auxMap.cpfCnpj)}` : 'Nada selecionado'}
              </h4>
              <div className='mb-3' style={{ zIndex: 10 }}>
                <Select
                  placeholder='Escolha'
                  options={accounts?.map((account) => ({
                    value: account.id,
                    account,
                  }))}
                  filterOption={createFilter({
                    stringify: ({ data }) =>
                      data.account
                        ? [
                          data.account.name,
                          data.account.cpfCnpj,
                          data.account.cpfCnpj ? formatCNPJCPF(data.account.cpfCnpj) : '',
                          data.account.loginEmail,
                        ].join(' ')
                        : '',
                  })}
                  formatOptionLabel={({ account }) => (
                    <span className='d-flex flex-column'>
                      <small className='text-bold'>Nome</small>
                      <span>{account.name}</span>

                      <hr className='text-bold m-0' />

                      <small className='text-bold'>CNPJ/CPF</small>
                      <span>{account.cpfCnpj ? formatCNPJCPF(account.cpfCnpj) : '--'}</span>

                      <hr className='text-bold m-0' />

                      <small className='text-bold'>Login Email</small>
                      <span>{account.loginEmail ? account.loginEmail : '--'}</span>
                    </span>
                  )}
                  value={accountSelected}
                  onChange={(value) => (selected ? setAccountSelected(value) : setAccountSelected(null))}
                />
                <div>
                  <form action='' method='post' className='form_modal mt-0 justify-content-center'>
                    {accountSelected && (
                      <>
                        <button disabled={process} className='btn_card' onClick={() => saveAccount('LOCK')}>
                          Vincular
                        </button>
                        <button disabled={process} className='btn_card mx-3' onClick={() => saveAccount('UNLOCK')}>
                          Desvincular
                        </button>
                        <button
                          disabled={process}
                          className='btn_card btn_card_grey'
                          onClick={() => {
                            setSelected(null);
                            setAccountSelected(null);
                          }}
                        >
                          Limpar
                        </button>
                      </>
                    )}
                  </form>
                </div>
              </div>
              <div className='mx-1'>
                <span style={{ color: 'blue' }}>
                  {
                    dataModalManager.filter(
                      (item) =>
                        getASAASAccount(item.auxMap.asaasToken, {
                          cpfCnpj: item.auxMap.asaasCpfCnpj,
                          loginEmail: item.auxMap.asaasLoginEmail,
                        }).length < 1
                    ).length
                  }{' '}
                  conta(s) não identificada(s)
                </span>
                <br />
                <span style={{ color: 'red' }}>
                  {
                    dataModalManager.filter(
                      (item) =>
                        getASAASAccount(item.auxMap.asaasToken, {
                          cpfCnpj: item.auxMap.asaasCpfCnpj,
                          loginEmail: item.auxMap.asaasLoginEmail,
                        }).length > 1
                    ).length
                  }{' '}
                  conta(s) com associação ambígua
                </span>
                <input
                  placeholder='Pesquisar'
                  style={{
                    width: '100%',
                    padding: '15px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    color: '#aaa',
                    height: '35px',
                  }}
                  value={filterModalManager}
                  onChange={(e) => setFilterModalManager(e.target.value)}
                />
              </div>
              <TableBordered
                style={{ minHeight: '350px' }}
                staticHighlightRow={({ id }) => id === selected?.id}
                columns={[
                  {
                    label: 'ID',
                    width: '50px',
                    textAlign: 'center',
                    field: ({ id, accounts }) => (
                      <span>
                        {id}
                        <br />
                        {accounts?.length !== 1 && (
                          <GoAlert color={accounts?.length < 1 ? 'blue' : accounts?.length > 1 ? 'red' : undefined} />
                        )}
                      </span>
                    ),
                  },
                  {
                    label: 'Razão Social',
                    field: ({ auxMap }) => (
                      <span>
                        {auxMap.name}
                        <br />
                        {formatCNPJCPF(auxMap.cpfCnpj)}
                      </span>
                    ),
                  },
                  {
                    label: 'CNPJ/CPF',
                    width: '160px',
                    className: 'py-1',
                    field: ({ auxMap }) => (
                      <span>
                        <span
                          className='badge badge-info'
                          style={{ fontSize: '90%' }}
                          title='CNPJ/CPF associado ao ASAAS'
                        >
                          {auxMap.asaasCpfCnpj ? formatCNPJCPF(auxMap.asaasCpfCnpj) : '--'}
                        </span>
                        <br />
                        <span
                          className='badge badge-secondary'
                          style={{ fontSize: '90%' }}
                          title='Login Email associado ao ASAAS'
                        >
                          {auxMap.asaasLoginEmail ? formatCNPJCPF(auxMap.asaasLoginEmail) : '--'}
                        </span>
                      </span>
                    ),
                  },
                  {
                    label: 'ASAAS',
                    field: ({ accounts }) =>
                      accounts?.map((account, i) => (
                        <>
                          {i !== 0 && <hr className='text-bold m-0' />}
                          <span key={moment().valueOf()}>
                            name: {account.name}
                            <br />
                            cpfCnpj: {account.cpfCnpj ? formatCNPJCPF(account.cpfCnpj) : ''}
                            <br />
                            loginEmail: {account.loginEmail}
                          </span>
                        </>
                      )),
                  },
                ]}
                actions={[
                  {
                    label: <FaEdit size={20} className='clickable' />,
                    action: (value) => {
                      setSelected(value);

                      if (value.accounts.length > 0)
                        setAccountSelected({ account: value.accounts[0], value: value.accounts[0].id });
                      else setAccountSelected(null);
                    },
                    textAlign: 'right',
                    width: '55px',
                  },
                ]}
                data={
                  dataModalManagerFiltered.map((item) => ({
                    ...item,
                    accounts: getASAASAccount(item.auxMap.asaasToken, {
                      cpfCnpj: item.auxMap.asaasCpfCnpj,
                      loginEmail: item.auxMap.asaasLoginEmail,
                    }),
                  })) || []
                }
              />
            </SimpleModal>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;
