import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Fade from '@material-ui/core/Fade'
import Footer from '../../components/login/Footer'
import Header from '../../components/login/Header'
import Loading from '../../components/Loading'
import Message from '../../components/Message'
import Utils from '../../../src/utils/utils'
import InputMask from 'react-input-mask';
import Api from '../../utils/api'
import axios from 'axios'

const RegisterUser = () => {
   const baseURL = Api()
   const [email, setEmail] = useState("")
   const [cpf, setCPF] = useState("")
   const [send, setSend] = useState(false)
   const [auth, setAuth] = useState(false)
   const [invalid, setInvalid] = useState(false)
   const [message, setMessage] = useState("")
   const [back, setBack] = useState(false)
   const { MessageDanger, MessageSuccess } = Message()
   const { validaCPF, replaceAll } = Utils()

   const handleSubmit = async (evt) => {
      evt.preventDefault()

      if ( !validaCPF(cpf) ) {
         setInvalid(true)
         setMessage("CPF inválido")
      } else {
         setSend(true)
         setAuth(false)
         setInvalid(false)
   
         const res = await axios.get(baseURL + 'verify-email-cpf/' + email + '/' + cpf)
         
         setSend(false)
         if ( !res.data.error ){
            setAuth(true)
         } else {
            setAuth(false)
            setInvalid(true)
         }
   
         setMessage(res.data.message)
      }
      
   }

   if ( back ) {
      return (
         <Redirect to="/" />
      )
   }

   if ( auth ) {
      return (
         <Redirect to={{
            pathname: '/register-complete-user',
            state: {
               email,
               cpf: cpf.replace('.','').replace('.','').replace('-','')
            }
         }} />
      )
   }

   return (
      <article className="container-fluid_new bg_new bg_login_new" style={{flexDirection: "column"}}>
         <div className="container_login_new">
            <div className="col-12 col-md-6">
               <div className="content_login_new">
                  <div className="swiper-container_new">
                     <div className="swiper-wrapper">
                        <Fade in={true} timeout={2000}>
                           <div className="swiper-slide_new" style={{alignText: 'center !important'}}>
                              <h1 className="title_login_new" style={{fontFamily: 'TitlingGothicFB'}}>Criar uma </h1>
                              <h1 className="title_login_verde_new" style={{fontFamily: 'TitlingGothicFB'}}>nova conta</h1>
                              <p style={{fontFamily: 'TitlingGothicFB Comp', color: 'white', fontSize: '16px',lineHeight: '19px'}}>Cadastre seu endereço de <br />email profissional para <br />ter acesso</p>
                           </div>
                        </Fade>
                     </div>
                  </div>
                  <span className="linha_vertical_login_new"></span>
               </div>
            </div>
            <div className="col-12 col-md-6">
               <div className="content_login_new">
                  <form onSubmit={handleSubmit} className="call_to_action_new" autoComplete="new-password">
                     {
                        invalid && <MessageDanger title="Atenção! " description={message} />
                     }

                     {
                        auth && <MessageSuccess title="Autenticado! " description={message} />
                     }
                     <div className="content_form_new">
                        <label className="lbl_login_new" style={{fontFamily: 'TitlingGothicFB Comp'}}>Infome seu email</label>
                        <input autoComplete="new-password" className="input_login_new" required type="email" name="email" onChange={e => setEmail(e.target.value)}/>
                     </div>
                     <div className="content_form_new">
                        <label className="lbl_login_new" style={{fontFamily: 'TitlingGothicFB Comp'}}>Infome seu CPF</label>
                        <InputMask className="input_login_new" value={cpf} required type="tel" name="cpf" mask="999.999.999-99" disableunderline="true" onChange={e => setCPF(replaceAll(replaceAll(e.target.value,'.',''),'-',''))}></InputMask>
                     </div>
                     <div className="content_form_new" style={{marginTop: '20px', width: '100%', flexDirection: "row", justifyContent: "space-between"}}>
                        <button className="btn_login_entrar_new" style={{width: '40%'}} onClick={e => setBack(true)}>
                           <React.Fragment>VOLTAR</React.Fragment>
                        </button>
                        <button className="btn_login_entrar_new" style={{width: '40%'}}>
                        {
                           send && <Loading type="1" />
                        }
                        {
                           !send && <React.Fragment>CONTINUAR</React.Fragment>
                        }
                        </button>
                     </div>
                  </form>
               </div>
            </div>
         </div>

         <Footer />
      </article>
   )
}

export default RegisterUser