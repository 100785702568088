import React, { useEffect, useRef, useState } from 'react';
import Utils, { handleOnChange } from '../../utils/utils';
import Message from '../../components/Message';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import Input from '../../components/inputs/Input';
import InputCpfCnpjMask from '../../components/inputs/InputCpfCnpjMask';
import InputPhoneMask from '../../components/inputs/InputPhoneMask';
import Select from '../../components/inputs/Select';
import InputCEPMask from '../../components/inputs/InputCEPMask';
import { getCitysByStateSigla, getStates, searchByZipCodeCountry } from '../../services/SharedService';

const { scrollTop } = Utils();
const { MessageSuccessMarginTop, MessageDangerMarginTop, MessagePrimaryMarginTop } = Message();

const ModalFinanceStakeholder = ({ isShow, handleCloseButton, listClienteFornecedor, onSubmit }) => {
  const { t, i18n } = useTranslation();
  const modalRef = useRef(null);

  const [stakeholderSelected, setStakeholderSelected] = useState(null);
  const [sendingCep, setSendingCep] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    cpfCnpj: '',
    phone: '',
    email: '',
    typeClienteFornecedor: '',

    zipCode: '',
    streetName: '',
    streetNumber: '',
    complement: '',
    neighborhood: '',
    cityId: '',
    state: '',
  });

  const [messageType, setMessageType] = useState('NONE');
  const [message, setMessage] = useState('');

  const onChangeForm = handleOnChange(setFormData);

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    setStakeholderSelected(null);
    clearForm();
  }, [isShow]);

  const fetchStates = async () => {
    const res = await getStates(i18n.language);
    setStates(res.states);
    setCities([]);
  };

  const getCities = async (state, cityId = '') => {
    setCities([]);
    onChangeForm('state', state || '');

    setLoadingCities(true);

    const { cidades: cities } = await getCitysByStateSigla(state, i18n.language);
    setLoadingCities(false);

    if (Array.isArray(cities)) {
      const cityFound = cities.find(({ id }) => id === cityId);
      onChangeForm('cityId', cityFound?.id || '');
      setCities(cities);
    }
  };

  const handleCpfCnpjChange = (name, value) => {
    const cleanedValue = value.replace(/\D/g, '');

    if (cleanedValue.length > 14) {
      return;
    }

    let formattedValue;
    if (cleanedValue.length <= 11) {
      formattedValue = cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
      formattedValue = cleanedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }

    onChangeForm(name, formattedValue);
  };


  const clearMessage = () => {
    setMessageType('NONE');
  };

  const showErrorMessage = (message) => {
    setMessage(message);
    setMessageType('ERROR');
    scrollTop(modalRef);
  };

  const showSuccessMessage = (message) => {
    setMessage(message);
    setMessageType('SUCCESS');
    scrollTop(modalRef);
  };

  const showAlreadyExistsMessage = (type) => {
    setMessageType('ALREADY_EXISTS');
    setMessage(
      `Este ${type === 'C' ? 'cliente' : 'fornecedor'} já existe. Faça a pesquisa novamente para conferir os dados...`
    );
    scrollTop(modalRef);
  };

  const showAwaitingMessage = () => {
    setMessageType('AWAITING');
    scrollTop(modalRef);
  };

  const existClientFornecedor = (type) => {
    const clienteFornecedor = listClienteFornecedor
      .filter((stakeholder) => (stakeholderSelected ? stakeholder.id !== stakeholderSelected.id : true))
      .find((stakeholder) => stakeholder.cpf_cnpj === formData.cpfCnpj || stakeholder.email === formData.email);

    if (clienteFornecedor) {
      showAlreadyExistsMessage(clienteFornecedor.type);

      if (type === 'email') onChangeForm('email', '');
      else if (type === 'cpf_cnpj') onChangeForm('cpfCnpj', '');
      else clearForm();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    showAwaitingMessage();

    const { success, message, data } = await onSubmit({
      id: stakeholderSelected ? stakeholderSelected.id : null,
      name: formData.name,
      cpf_cnpj: formData.cpfCnpj.match(/\d/g).join(""),
      phone: formData.phone,
      email: formData.email,
      type: formData.typeClienteFornecedor,

      zipCode: formData.zipCode,
      streetName: formData.streetName,
      streetNumber: formData.streetNumber,
      complement: formData.complement,
      neighborhood: formData.neighborhood,
      cityId: formData.cityId,
    });

    if (success) {
      showSuccessMessage(message);
      setStakeholderSelected(data);
    } else showErrorMessage(message);
  };

  const clearForm = () => {
    setFormData({
      name: '',
      cpfCnpj: '',
      phone: '',
      email: '',
      typeClienteFornecedor: '',

      zipCode: '',
      streetName: '',
      streetNumber: '',
      complement: '',
      neighborhood: '',
      cityId: '',
      state: '',
    });
  };

  const fillForm = (stakeholder) => {
    setFormData({
      name: stakeholder.name,
      cpfCnpj: stakeholder.cpf_cnpj,
      phone: stakeholder.phone,
      email: stakeholder.email,
      typeClienteFornecedor: stakeholder.type,

      zipCode: stakeholder.zipCode,
      streetName: stakeholder.streetName,
      streetNumber: stakeholder.streetNumber,
      complement: stakeholder.complement,
      neighborhood: stakeholder.neighborhood,
      cityId: stakeholder.city?.id,
      state: stakeholder.city?.state?.staStateSigla,
    });

    if (stakeholder.city?.state?.staStateSigla) getCities(stakeholder.city?.state?.staStateSigla, stakeholder.city?.id);
  };

  const searchZipCode = async () => {
    if (formData.zipCode.length) {
      setSendingCep(true);
      const { success, streetName, neighborhood, states, state, cities, city } = await searchByZipCodeCountry(
        formData.zipCode,
        i18n.language
      );
      setSendingCep(false);

      if (!success) {
        onChangeForm('streetName', '');
        onChangeForm('neighborhood', '');
        onChangeForm('state', '');
        onChangeForm('cityId', '');
      } else {
        onChangeForm('streetName', streetName);
        onChangeForm('neighborhood', neighborhood);
        onChangeForm('state', state);
        onChangeForm('cityId', city.id);

        setStates(states);
        setCities(cities);
      }
    }
  };

  return (
    <div className='modal' id='add_client_fornecedor' style={isShow ? { display: 'flex', opacity: 1 } : undefined}>
      <div className='modal_content' ref={modalRef}>
        <div className='modal_close' onClick={handleCloseButton}>
          X
        </div>
        <h3 className='modal_title'>{t('CustomerOrSupplier')}</h3>
        {messageType === 'ERROR' && (
          <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
        )}
        {messageType === 'SUCCESS' && (
          <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message)} />
        )}
        {messageType === 'ALREADY_EXISTS' && (
          <MessagePrimaryMarginTop title={t('Information_abbreviation_exclamation') + ' '} description={t(message)} />
        )}
        {messageType === 'AWAITING' && (
          <p style={{ textAlign: 'center' }}>
            {t('SystemMessage.Loading.Wait')} <Loading type='4' style={{ color: 'green' }} />
          </p>
        )}
        <form action='' autoComplete='new-password' onSubmit={handleSubmit} method='post' className='form_modal'>
          <div className='group_50' style={{ width: '100%' }}>
            <div className='form_group'>
              <label className='card_details_label'>{t('Research')}</label>
              <Autocomplete
                id='modal_client_fornecedor_02'
                noOptionsText={t('NoCustomerOrSupplier')}
                loadingText={t('SystemMessage.Loading.Loading')}
                options={listClienteFornecedor}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id && option.type === value.type}
                style={{ width: '100%' }}
                value={stakeholderSelected}
                onChange={(event, newValue) => {
                  clearMessage();
                  setStakeholderSelected(newValue);
                  if (newValue) {
                    fillForm(newValue);
                  } else clearForm();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off',
                    }}
                    label={t('EnterCustomerSupplierName')}
                    variant='outlined'
                  />
                )}
              />
            </div>
          </div>
          <div className='group_50'>
            <Input required label={t('Name')} name='name' value={formData} onChange={onChangeForm} />
          </div>
          <div className='group_50'>
            <Input
              required
              label={t('CPFOrCNPJ')}
              name='cpfCnpj'
              value={formData.cpfCnpj}
              onChange={(e) => handleCpfCnpjChange('cpfCnpj', e.target.value)}
            />
          </div>
          <div className='group_50'>
            <InputPhoneMask required label={t('CellPhone')} name='phone' value={formData} onChange={onChangeForm} />
          </div>
          <div className='group_50'>
            <Input
              required
              label={t('Email')}
              name='email'
              value={formData}
              onChange={onChangeForm}
              onBlur={() => existClientFornecedor()}
            />
          </div>
          <div className='group_50'>
            <Select required label={t('Type')} name='typeClienteFornecedor' value={formData} onChange={onChangeForm}>
              <option value='' disabled></option>
              <option value='F'>Fornecedor</option>
              <option value='C'>Cliente</option>
            </Select>
          </div>

          <div className='group_50'>
            <InputCEPMask
              isLoading={sendingCep}
              label={t('CEP')}
              name='zipCode'
              value={formData}
              onChange={onChangeForm}
              onBlur={searchZipCode}
            />
          </div>

          <div className='group_50'>
            <div className='group_50'>
              <Select
                label={t('State')}
                name='state'
                value={formData}
                onChange={onChangeForm}
                onBlur={() => getCities(formData.state)}
              >
                <option value=''>-- {t('Select')} --</option>
                {states.map((item) => (
                  <option value={item.staStateSigla} key={item.staState}>
                    {item.staState}
                  </option>
                ))}
              </Select>
            </div>

            <div className='group_50'>
              <Select
                isLoading={loadingCities}
                label={t('City')}
                name='cityId'
                value={formData}
                onChange={onChangeForm}
              >
                <option value=''>-- {t('Select')} --</option>
                {cities.map((item) => (
                  <option value={item.id} key={item.citCity}>
                    {item.citCity}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <div className='group_50'>
            <Input label={t('Address')} name='streetName' value={formData} onChange={onChangeForm} />
          </div>

          <div className='group_50'>
            <div className='group_50'>
              <Input label={t('Number')} name='streetNumber' value={formData} onChange={onChangeForm} />
            </div>

            <div className='group_50'>
              <Input label={t('Complement')} name='complement' value={formData} onChange={onChangeForm} />
            </div>
          </div>

          <div className='group_50'>
            <Input label={t('Neighborhood')} name='neighborhood' value={formData} onChange={onChangeForm} />
          </div>

          <div
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <button className='btn_card' style={{ marginRight: 5 }}>
              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
              </svg>
              {!stakeholderSelected ? 'Incluir' : 'Alterar'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalFinanceStakeholder;
