import React, { useEffect, useState, useRef } from 'react'
import Header from '../../components/club/Header'
import Footer from '../../components/club/Footer'
import Loading from '../../components/Loading'
import Message from '../../components/Message'
import Utils, { correctImageOrientation } from '../../../src/utils/utils'
import Api from '../../utils/api'
import axios from 'axios'

const Communication = (props) => {

   const [id, setID] = useState(null)
   const [role_id, setRoleID] = useState("")
   const [user_id, setUserID] = useState("")
   const [title, setTitle] = useState("")
   const [description, setDescription] = useState("")
   const [type, setType] = useState("")
   const [from_json, setFromJson] = useState("")
   const [companie_id, setCompanieID] = useState("")
   const [status, setStatus] = useState("")
   const [date_show, setDateShow] = useState("")
   const [dirImage, setDirImage] = useState("")
   const [foto, setFoto] = useState("")
   const [label, setLabel] = useState("")
   const [detail, setDetail] = useState({})
   const [documents, setDocuments] = useState([])

   // carregamentos
   const [communications, setCommunications] = useState([])
   const [schools, setSchools] = useState([])
   const [schoolsModal, setSchoolsModal] = useState("")
   const [schoolssModal, setSchoolssModal] = useState([])

   // filtros
   const [filterTitle, setFilterTitle] = useState("")
   const [filterDescription, setFilterDescription] = useState("")
   const [filterData, setFilterData] = useState("")
   const [communicationsFilter, setCommunicationsFilter] = useState([])
   const [display, setDisplay] = useState({})
   const [displayDetail, setDisplayDetail] = useState(false)

   // process
   const [process, setProcess] = useState(false)
   const [sendUpload, setSendingUpload] = useState(false)
   const [sendSave, setSendSave] = useState(false)

   // messages
   const [error, setError] = useState(false)
   const [success, setSuccess] = useState(false)
   const [message, setMessage] = useState("")

   // libraries
   const baseURL = Api()
   const { scrollTop } = Utils()
   const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message()
   let modalScroll = useRef()

   useEffect(() => {

      async function getCommunications(companie_id, role_id) {

         setProcess(true)
         const res = await axios.post(baseURL + 'get-communication-companie', {
            companie_id,
            role_id
         })

         setDirImage(res.data.dirImage)
         setCommunications(res.data.communications)
         setCommunicationsFilter(res.data.communications)
         setSchools(res.data.schools)
         setProcess(false)
      }


      async function getSession() {
         const usuSession = JSON.parse(await localStorage.getItem('user'))
         const companieSession = JSON.parse(await localStorage.getItem('company'))

         setRoleID(usuSession.role_id)
         setUserID(usuSession.id)
         setCompanieID(companieSession.id)
         getCommunications(companieSession.id, usuSession.role_id)
      }

      getSession()

   }, [])

   const search = (e) => {
      e.preventDefault()

      let registers = []
      if (filterTitle !== '') {
         registers = communications.filter((item) => {
            return (item.title.toLowerCase().indexOf(filterTitle) !== -1 || item.description.toLowerCase().indexOf(filterTitle) !== -1) && item.type == 'Notícia'
         })
      } else {
         registers = communications
      }

      if (filterDescription !== '') {
         registers = registers.filter((item) => {
            return (item.title.toLowerCase().indexOf(filterTitle) !== -1 || item.description.toLowerCase().indexOf(filterTitle) !== -1) && item.type == 'Comunicado'
         })
      }

      if (filterData !== '') {
         registers = registers.filter((item) => {
            return item.date_show.substr(0, 10) == filterData
         })
      }

      setCommunicationsFilter(registers)
   }

   const addSchool = (event) => {
      setSuccess(false)
      setError(false)

      if (event.target.value == "") {
         setSchoolssModal(schools)
      } else {
         var index = event.nativeEvent.target.selectedIndex;

         let newData = schoolssModal.filter(e => e.id === event.target.value)

         if (newData.length > 0) {
            setError(true)
            setMessage("Esta Escola ja foi incluída")
            scrollTop(modalScroll)
            return
         }

         setSchoolssModal([...schoolssModal, {
            id: event.target.value,
            name: event.nativeEvent.target[index].text
         }])
      }

      setSchoolsModal("")
   }

   const delSchool = (item) => {
      setSuccess(false)
      setError(false)
      let newData = schoolssModal.filter(e => e.id !== item.id)
      setSchoolssModal(newData)
   }

   const showNoticia = () => {
      setError(false)
      setSuccess(false)

      setTitle("")
      setDescription("")
      setType("Notícia")
      setStatus("")
      setFromJson([])
      setSchoolssModal([])
      setID(null)
      setLabel("Notícia")
      setFoto("")
      setDateShow(new Date().toISOString().substr(0, 10))
      setDocuments([])

      setDisplay({
         display: "flex",
         opacity: 1
      })
   }

   const showComunicado = () => {
      setError(false)
      setSuccess(false)

      setTitle("")
      setDescription("")
      setStatus("")
      setType("Comunicado")
      setFromJson([])
      setSchoolssModal([])
      setID(null)
      setLabel("Comunicado")
      setFoto("")
      setDateShow(new Date().toISOString().substr(0, 10))
      setDocuments([])

      setDisplay({
         display: "flex",
         opacity: 1
      })
   }

   const close = () => {
      setDisplay({})
   }

   const handleSubmit = async (e) => {
      e.preventDefault()
      setError(false)
      setSuccess(false)

      if (!id) {
         const date_now = new Date().toISOString().substr(0, 10)

         if (date_show < date_now) {
            setError(true)
            setMessage("A data tem que ser igual ou maior que hoje.")
            return
         }
      }

      let data = {}
      let endpoint = ''
      if (id === null) {
         endpoint = 'save-communication-companie'
         data = {
            companie_id,
            user_id,
            title,
            description,
            type,
            status,
            from_json,
            school_json: JSON.stringify(schoolssModal),
            date_show
         }
      } else {
         endpoint = 'update-communication-school'
         data = {
            id,
            companie_id,
            title,
            description,
            type,
            status,
            from_json,
            school_json: JSON.stringify(schoolssModal),
            date_show
         }
      }


      setSendSave(true)
      const res = await axios.post(baseURL + endpoint, data)
      setSendSave(false)
      setSuccess(true)
      setID(res.data.create.id)
      setMessage(res.data.message)
      setCommunications(res.data.communications)
      setCommunicationsFilter(res.data.communications)
      scrollTop(modalScroll)
   }

   const handleFoto = async (e) => {
      const foto = await correctImageOrientation(e.target.files[0])
      const formData = new FormData()
      formData.append('companie_id', companie_id)
      formData.append('foto', foto)
      formData.append('id', id)

      setError(false)
      setSuccess(false)
      setSendingUpload(true)
      console.log('test');
      const res = await axios.post(baseURL + 'upload-communication-companie', formData, { headers: { 'Content-Type': 'multipart/form-data' } })

      if (res.data.success === false) {
         setError(true)
         setSuccess(false)

         if (res.data.erro) {
            let prop = ''
            let msgs = ''
            for (prop in res.data.erro) {
               if (res.data.erro.hasOwnProperty(prop)) {
                  msgs += res.data.erro[prop][0]
               }
            }
            setMessage(msgs)
         } else {
            setMessage(res.data.msg)
         }
      } else {
         setSuccess(true)
         setMessage("Imagem atualizada com sucesso")
         setFoto(res.data.arq)
         setCommunications(res.data.communications)
         setCommunicationsFilter(res.data.communications)
         scrollTop(modalScroll)
      }

      setSendingUpload(false)

   }

   const handleAnexo = async (e) => {
      if (id === null) {
         return setErrorFoto()

      }
      const anexo = e.target.files[0]

      const formData = new FormData()
      formData.append('companie_id', companie_id)
      formData.append('anexo', anexo)
      formData.append('id', id)

      setError(false)
      setSuccess(false)
      setSendingUpload(true)

      const res = await axios.post(baseURL + 'attach-communication-companie', formData, { headers: { 'Content-Type': 'multipart/form-data' } })

      if (res.data.success === false) {
         setError(true)
         setSuccess(false)

         if (res.data.erro) {
            let prop = ''
            let msgs = ''
            for (prop in res.data.erro) {
               if (res.data.erro.hasOwnProperty(prop)) {
                  msgs += res.data.erro[prop][0]
               }
            }
            setMessage(msgs)
         } else {
            setMessage(res.data.msg)
         }
      } else {
         setSuccess(true)
         setMessage("Documento anexado com sucesso")
         setDocuments([...documents, res.data.document])
         setCommunications(res.data.communications)
         setCommunicationsFilter(res.data.communications)
         scrollTop(modalScroll)
      }

      setSendingUpload(false)
   }

   const handleDeleteAnexo = async ({ id, filename }) => {
      setError(false)
      setSuccess(false)
      setSendingUpload(true)

      try {
         const { data: { success, msg } } = await axios.post(baseURL + 'detach-communication-companie', { id, filename })
         if (success) {
            setDocuments([...documents].filter(document => document.id !== id))
            setSuccess(true)
         }
         else {
            setError(true)
         }
         setMessage(msg)
      } catch (error) {
         setError(true)
         setMessage("Erro interno. Tente recarregar a página")
      }
      setSendingUpload(false)
      scrollTop(modalScroll)
   }

   const edit = (e, comm) => {
      setError(false)
      setSuccess(false)

      setID(comm.id)
      setTitle(comm.title)
      setType(comm.type)
      setDescription(comm.description)
      setStatus(comm.status)
      setFromJson(comm.from_json)
      setSchoolssModal(comm.school_json !== '' ? JSON.parse(comm.school_json) : [])
      setDateShow(comm.date_show)
      setID(comm.id)
      setLabel(comm.type)
      setFoto("")
      if (comm.img !== null)
         setFoto(dirImage + '/' + comm.img)

      setDocuments([])
      if (comm.communication_documents && comm.communication_documents.length > 0)
         setDocuments(comm.communication_documents)


      setDisplay({
         display: "flex",
         opacity: 1
      })
   }

   const setErrorFoto = () => {
      scrollTop(modalScroll)
      setError(true)
      setMessage(label === 'Notícia' ? "A notícia precisa ser salva antes do enviar do arquivo!" : "O comunicado precisa ser salvo antes do enviar do arquivo!")
   }

   const openDetail = (item) => {
      setDisplayDetail(true)
      setDetail(item)
      window.scrollTo(0, 0)
   }

   const closeDetail = () => {
      setDisplayDetail(false)
      setDetail({})
   }

   return (
      <main>
         <div className="container-fluid container_painel" >
            <div className="container">
               <div className="row content_painel">



                  <main className="content_panel">
                     <div className="sidebar">
                        <div className="card">
                           <div className="card_header">
                              <h2 className="card_title">Adicionar notícia</h2>
                              <p className="card_subtitle">Clique abaixo para adicionar notícias para os alunos.</p>
                           </div>
                           <button onClick={showNoticia} className="btn_card mx-auto mt-4 btn_open_modal" data-modal="#noticia">
                              <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path
                                    d="M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z" />
                              </svg>
                              Adicionar notícia
                           </button>
                           <hr />
                           <div className="card_header">
                              <h2 className="card_title">Adicionar comunicado</h2>
                              <p className="card_subtitle">Clique abaixo para se comunicar com os alunos.</p>
                           </div>
                           <button onClick={showComunicado} className="btn_card mx-auto mt-4 btn_open_modal" data-modal="#comunicado">
                              <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path
                                    d="M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z" />
                              </svg>
                              Adicionar comunicado
                           </button>
                        </div>
                        <div className="card">
                           <div className="card_header">
                              <h2 className="card_title">Procurar</h2>
                              <p className="card_subtitle">Filtrar os tipos de comunicação.</p>
                           </div>
                           <form action="" method="post" className="form_card">
                              <div className="form_group">
                                 <label htmlFor="news" className="card_details_label">Notícias</label>
                                 <input type="text" name="news" className="form_control" onChange={(e) => setFilterTitle(e.target.value)} />
                              </div>
                              <div className="form_group">
                                 <label htmlFor="schedule" className="card_details_label">Comunicados</label>
                                 <input type="text" name="schedule" className="form_control" onChange={(e) => setFilterDescription(e.target.value)} />
                              </div>
                              <div className="form_group">
                                 <label htmlFor="date" className="card_details_label">Data</label>
                                 <input type="date" name="date" className="form_control" onChange={(e) => setFilterData(e.target.value)} />
                              </div>
                              <button className="btn_card mx-auto" onClick={search}>
                                 <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                       d="M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z" />
                                 </svg>
                                 Buscar
                              </button>
                           </form>
                        </div>
                     </div>
                     <div className="content">
                        <div className="card_noticia" style={{ display: displayDetail ? "inline" : "none" }}>
                           <div className="img_noticia">
                              <img src={dirImage + '/' + detail.img} alt="" />
                           </div>
                           <div className={`comunicado_type ${String(detail.type).toLowerCase().replace('í', 'i')}`}>{detail.type}</div>
                           <div className="noticia_content">
                              <h2 className="comunicado_title">{detail.title}</h2>
                              <div className="comunicado_text"><p>{detail.description}</p></div>
                              <div>
                                 {detail.communication_documents && detail.communication_documents.length > 0 && detail.communication_documents.map(document => (
                                    <li key={document.id + '_document_list'}>
                                       <a className='table_mes' target='_black' href={dirImage + '/' + document.filename}>{document.name}</a>
                                    </li>
                                 ))}
                              </div>
                              <button onClick={closeDetail} className="btn_card mx-auto mt-4 btn_open_modal" data-modal="#comunicado" style={{ background: "grey", border: "grey" }}>
                                 <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                       d="M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z" />
                                 </svg>
                                 fechar
                              </button>
                           </div>
                        </div>
                        <div className="card">
                           <div className="card_header">
                              <h2 className="card_title">Resultado da pesquisa</h2>
                              <p className="card_subtitle">Veja abaixo as últimas notícias cadastradas.</p>
                           </div>
                           <div className="list_card">
                              {
                                 process &&
                                 <p className="loading-card"><Loading type="1" /></p>
                              }
                              {
                                 !process &&
                                 communicationsFilter.map((item, i) => {
                                    return <div className="card_list_comunicado" key={i}>
                                       <div className="img_list_comunicado">
                                          <img src={dirImage + '/' + item.img} alt="" style={{ width: '100%' }} />
                                       </div>
                                       <div className="content_card">
                                          <div className={`comunicado_type ${item.type.toLowerCase().replace('í', 'i')}`}>{item.type}</div>
                                          <h2 className="comunicado_title">{item.title}</h2>
                                          <p className="comunicado_text">{item.description}</p>
                                          <div style={{ flexDirection: 'row', display: 'flex' }}>
                                             <span onClick={e => edit(e, item)} className="btn_card" style={{ backgroundColor: 'orange', border: '1px solid orange', fontWeight: '500', marginRight: 7 }}>
                                                <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                   <path
                                                      d="M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z" />
                                                </svg>
                                                Alterar {item.type}
                                             </span>

                                             <span onClick={e => openDetail(item)} className="btn_card">
                                                <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                   <path
                                                      d="M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z" />
                                                </svg>
                                                Ver detalhes
                                             </span>
                                          </div>
                                       </div>
                                    </div>
                                 })
                              }
                           </div>
                        </div>
                     </div>
                     <div className="modal" id="add_noticia_comunicado" style={display}>
                        <div className="modal_content" ref={modalScroll}>
                           <div className="modal_close" onClick={close}>X</div>
                           <h3 className="modal_title">Incluir {label}</h3>
                           {
                              error &&
                              <MessageDangerMarginTop title="Atenção! " description={message} />
                           }
                           {
                              success &&
                              <MessageSuccessMarginTop title="Sucesso! " description={message} />
                           }
                           <form action="" onSubmit={handleSubmit} method="post" className="form_modal">
                              <div className="group_50">
                                 <div className="form_group">
                                    <label htmlFor="title" className="card_details_label">Titulo</label>
                                    <input type="text" name="title" value={title} required className="form_control" onChange={e => setTitle(e.target.value)} />
                                 </div>
                                 <div className="group_50">
                                    <div className="form_group">
                                       <label htmlFor="title" className="card_details_label">A partir de</label>
                                       <input type="date" name="date_show" value={date_show} required className="form_control" onChange={e => setDateShow(e.target.value)} />
                                    </div>
                                 </div>
                                 <div className="group_50">
                                    <div className="form_group">
                                       <label htmlFor="title" className="card_details_label">Status</label>
                                       <select required value={status} className="form_control select" onChange={e => setStatus(e.target.value)}>
                                          <option value=""></option>
                                          <option value="A">Ativa</option>
                                          <option value="I">Inativa</option>
                                       </select>
                                    </div>
                                 </div>
                                 <div className="form_group">
                                    <label htmlFor="descript" required className="card_details_label">Descrição</label>
                                    <textarea name="descript" value={description} className="form_control" id="" cols="30" rows="10" onChange={e => setDescription(e.target.value)} ></textarea>
                                 </div>
                              </div>
                              {
                                 sendUpload &&
                                 <div className="group_50" style={{ justifyContent: 'center', alignItems: 'center', background: '#218135', borderRadius: '10px', color: 'white', flexDirection: "column" }}>
                                    <label htmlFor="file">Aguarde, estamos enviando...</label>
                                    <Loading type="3" style={{ color: 'white', marginTop: 5 }} />
                                 </div>
                              }
                              {
                                 foto !== '' && !sendUpload &&
                                 <div className="group_50" style={{ justifyContent: 'space-around' }}>
                                    <img style={{ borderRadius: 10, marginBottom: 10, width: '100%', height: '230px' }} src={foto} />
                                    <label style={{ cursor: 'pointer', padding: '12px 22px', borderRadius: '50px', border: '1px solid rgb(2, 132, 44)', color: 'rgb(2, 132, 44)', fontSize: '13px', justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                       <input type="file" name="file[]" onChange={e => handleFoto(e)} />
                                       Alterar foto
                                    </label>
                                 </div>
                              }
                              {
                                 id !== null && foto == '' && !sendUpload &&
                                 <div className="group_50 upload_file">
                                    <label htmlFor="file"></label>
                                    <input type="file" name="file[]" id="file" onChange={e => handleFoto(e)} style={{ display: 'initial', opacity: 0, cursor: 'pointer' }} />
                                 </div>
                              }
                              {
                                 id === null && foto == '' && !sendUpload &&
                                 <div className="group_50 upload_file" onClick={(e) => setErrorFoto()}>
                                    <label htmlFor="file"></label>
                                 </div>
                              }
                              <div className="group_50">
                                 <div className="form_group">
                                    <label htmlFor="position" className="card_details_label">Enviar para</label>
                                    <select required name="from_json" value={from_json} className="form_control select" onChange={e => setFromJson(e.target.value)}>
                                       <option value=""></option>
                                       <option value="T">Todos</option>
                                       <option value="F">Funcionários do Clube</option>
                                       <option value="E">Escolas</option>
                                    </select>
                                 </div>
                              </div>
                              <div className="group_50">
                                 <div className="form_group">
                                    <label htmlFor="position" className="card_details_label">Escola</label>
                                    <select name="position" value={schoolsModal} className="form_control select" onChange={e => addSchool(e)}>
                                       <option value="" disabled></option>
                                       <option value="">Todas</option>
                                       {
                                          schools.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)
                                       }
                                    </select>
                                 </div>
                                 <div className="list_select">
                                    {
                                       schoolssModal.map((item, i) =>
                                          <div className="list_item" key={`resp_${i}`}>
                                             <p>{item.name}</p>
                                             <button className="remove" onClick={e => delSchool(item)}>
                                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                                   xmlns="http://www.w3.org/2000/svg">
                                                   <path
                                                      d="M8.53555 1.46445C7.59121 0.520117 6.33555 0 5 0C3.66445 0 2.40879 0.520117 1.46445 1.46445C0.520117 2.40879 0 3.66445 0 5C0 6.33555 0.520117 7.59121 1.46445 8.53555C2.40879 9.47988 3.66445 10 5 10C6.33555 10 7.59121 9.47988 8.53555 8.53555C9.47988 7.59121 10 6.33555 10 5C10 3.66445 9.47988 2.40879 8.53555 1.46445ZM7.1627 6.47207C7.35332 6.66289 7.35332 6.97188 7.1627 7.1627C7.06738 7.25801 6.94238 7.30566 6.81738 7.30566C6.69238 7.30566 6.56738 7.25801 6.47207 7.1627L5 5.69062L3.52793 7.1627C3.43262 7.25801 3.30762 7.30566 3.18262 7.30566C3.05762 7.30566 2.93262 7.25801 2.8373 7.1627C2.64668 6.97188 2.64668 6.66289 2.8373 6.47207L4.30937 5L2.8373 3.52793C2.64668 3.33711 2.64668 3.02813 2.8373 2.8373C3.02813 2.64648 3.33711 2.64648 3.52793 2.8373L5 4.30937L6.47207 2.8373C6.66289 2.64668 6.97188 2.64648 7.1627 2.8373C7.35352 3.02793 7.35352 3.33711 7.1627 3.52793L5.69062 5L7.1627 6.47207Z"
                                                      fill="#B2B1B1" />
                                                </svg>
                                             </button>
                                          </div>
                                       )
                                    }
                                 </div>
                              </div>

                              <h3 className="modal_title" style={{ marginBottom: 10 }}>
                                 Anexos
                                 <label className='btn_card' htmlFor="file-attach" style={{
                                    marginTop: 0,
                                    height: '28px',
                                    width: '100px',
                                    display: 'inline-block',
                                    marginLeft: '10px',
                                    minWidth: '160px',
                                    fontSize: 'small'
                                 }}
                                    onClick={() => id === null && setErrorFoto()}
                                 >
                                    {sendUpload ? <>Processando...</> : 'Anexar arquivo'}
                                 </label>
                              </h3>

                              <div className="form_group">
                                 {id !== null && !sendUpload && <input id='file-attach' type="file" onChange={handleAnexo} />}
                                 <div style={{ border: '2px dashed var(--main-color)', minHeight: '50px', borderRadius: '8px' }}>
                                    <ul style={{ marginLeft: '30px', listStyle: 'disc' }}>
                                       {documents.map(document => (
                                          <li key={document.id + '_document_list'}>
                                             <a className='table_mes' target='_black' href={dirImage + '/' + document.filename}>{document.name}</a>
                                             <svg onClick={() => handleDeleteAnexo(document)} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" className="bi bi-x" viewBox="0 0 16 16" style={{ cursor: 'pointer' }}>
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                             </svg>
                                          </li>
                                       ))}
                                    </ul>
                                 </div>
                              </div>

                              <button className="btn_card mx-auto">
                                 <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                       d="M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z" />
                                 </svg>
                                 {id === null ? 'Incluir' : 'Alterar'} {label}
                              </button>
                           </form>
                        </div>
                     </div>
                  </main>

                  <Footer />
               </div>

            </div>
         </div>
      </main>
   )
}

export default Communication