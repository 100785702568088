import React from 'react'

const footer = () => {
   return ( 
      <div className="botao-rodape-acesso">
         <a href="/docs/POLÍTICA DE PRIVACIDADE - FENSOR FLASH.pdf" download="/docs/POLÍTICA DE PRIVACIDADE - FENSOR FLASH.pdf">Política de privacidade</a>
         <a href="/docs/TERMOS DE USO ATLETA - FENSOR FLASH.pdf" download="/docs/TERMOS DE USO ATLETA - FENSOR FLASH.pdf">Termos de uso</a>
      </div>
   )
}

export default footer