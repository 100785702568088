import React, { useState, useEffect, useRef } from 'react';
import HeaderClub from '../../components/club/Header';
import FooterClub from '../../components/club/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import utils from '../../utils/utils';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import { ButtonRoundedDefault } from '../../components/buttons/ButtonRoundedDefault';
import ModalAddClubSchoolDocument from '../../views/ModalAddClubSchoolDocument';
import { FaTimes } from 'react-icons/fa';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  createClubSchoolDocument,
  deleteClubSchoolDocument,
  getClubSchoolDocuments,
  updateClubSchoolDocument,
} from '../../services/DocumentService';

const SchoolDocuments = () => {
  const { t } = useTranslation();
  const { scrollTop } = utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();

  const messageScroll = useRef(window);
  const modalAddClubSchoolDocumentRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState({ text: '', type: null });

  const [schools, setSchools] = useState([]);

  const [document, setDocument] = useState(null);
  const [documents, setDocuments] = useState([]);

  const [sweetAlertConfirmDelete, setSweetAlertConfirmDelete] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const mapDocuments = (documents) =>
    documents.map((document) => ({
      ...document,
      isSending: false,
      schoolsAllowed: document.schools_allowed.map(({ id }) => id),
    }));

  const fetchData = async () => {
    setIsLoading(true);
    const { status, messageKey, schools, documents } = await getClubSchoolDocuments();
    setIsLoading(false);

    if (status === 'SUCCESS') {
      setSchools(schools);
      setDocuments(mapDocuments(documents));
    } else {
      showErrorMessage(messageKey);
    }
  };

  const onPressKey = (e) => {
    e.preventDefault();
    if (e.key === 'Enter') handleUpdateDocument(e);
  };

  const showSuccessMessage = (message) => {
    setMessage({ text: message, type: 'SUCCESS' });
    scrollTop(messageScroll);
    setTimeout(() => setMessage({ text: '', type: null }), 5000);
  };

  const showErrorMessage = (message) => {
    setMessage({ text: message, type: 'ERROR' });
    scrollTop(messageScroll);
    setTimeout(() => setMessage({ text: '', type: null }), 5000);
  };

  const openAdd = (document = null) => {
    setDocument(document);
    modalAddClubSchoolDocumentRef.current.showModal();
  };

  const togglePermission = (isChecked, document, schoolId) => {
    document.schoolsAllowed = [
      ...document.schoolsAllowed.filter((id) => id !== schoolId),
      ...(isChecked ? [schoolId] : []),
    ];

    setDocuments((documents) => [...documents]);
  };

  const fillPermissions = (isChecked, schoolId) => {
    setDocuments((documents) =>
      documents.map(({ schoolsAllowed, ...document }) => ({
        ...document,
        schoolsAllowed: [...schoolsAllowed.filter((id) => id !== schoolId), ...(isChecked ? [schoolId] : [])],
      }))
    );
  };

  const handleCreateDocument = async ({ file }) => {
    setIsLoading(true);
    const { status, messageKey, documents } = await createClubSchoolDocument({ file });
    setIsLoading(false);

    if (status === 'SUCCESS') {
      showSuccessMessage(messageKey);
      setDocuments(mapDocuments(documents));
      return { success: true };
    } else {
      return { success: false, message: messageKey };
    }
  };

  const handleUpdateDocument = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const { status, messageKey, documents: documentsSaved } = await updateClubSchoolDocument({ documents });
    setIsLoading(false);

    if (status === 'SUCCESS') {
      showSuccessMessage(messageKey);
      setDocuments(mapDocuments(documentsSaved));
    } else {
      showErrorMessage(messageKey);
    }
  };

  const confirmDeleteDocument = (document) => {
    setSweetAlertConfirmDelete(
      <SweetAlert
        danger
        showCloseButton
        showConfirm
        confirmBtnText={t('Delete')}
        onConfirm={() => {
          setSweetAlertConfirmDelete(null);
          handleDeleteDocument(document);
        }}
        showCancel
        cancelBtnText={t('Cancel')}
        onCancel={() => setSweetAlertConfirmDelete(null)}
      >
        <span>{t('StudentDocumentsPage.SystemMessage.DeleteConfirmation')}</span>
        <small className='text-bold'>{t('PermanentAction')}</small>
      </SweetAlert>
    );
  };

  const handleDeleteDocument = async (document) => {
    setDocuments((current) => {
      document.isSending = true;
      return [...current];
    });

    const { status, messageKey } = await deleteClubSchoolDocument(document.id);

    if (status === 'SUCCESS') {
      showSuccessMessage(messageKey);
      setDocuments((current) => mapDocuments(current.filter(({ id }) => id !== document.id)));
    } else {
      setDocuments((current) => {
        document.isSending = false;
        return [...current];
      });
      showErrorMessage(messageKey);
    }
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            {/* <HeaderClub /> */}

            <main className='content_panel'>
              <div className='content'>
                <div className='card'>
                  <form
                    action=''
                    onSubmit={handleUpdateDocument}
                    method='post'
                    className='form_modal flex-column'
                    onKeyPress={onPressKey}
                  >
                    <div className='d-flex'>
                      <div className='card_header'>
                        <h2 className='card_title'>{t('ClubSchoolDocumentsPage.PageTitle')}</h2>
                        <p className='card_subtitle'>{t('ClubSchoolDocumentsPage.PageSubtitle')}</p>
                      </div>

                      <ButtonRoundedDefault
                        type='button'
                        className='ml-auto mr-0 mt-auto mb-3'
                        style={{ height: '40px' }}
                        onClick={() => openAdd()}
                      >
                        {t('ClubSchoolDocumentsPage.ButtonAddClubSchoolDocuments')}
                      </ButtonRoundedDefault>
                    </div>

                    <div className='mb-3' ref={messageScroll} style={{ width: '100%' }}>
                      <Collapse in={message.type === 'SUCCESS'}>
                        <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message.text)} />
                      </Collapse>

                      <Collapse in={message.type === 'ERROR'}>
                        <MessageDangerMarginTop
                          title={t('Attention_exclamation') + ' '}
                          description={t(message.text)}
                        />
                      </Collapse>
                    </div>

                    <div className='pseudo-table'>
                      <div className='pseudo-table-wrapper'>
                        <div
                          className='pseudo-table-scroll scrollbar-rounded'
                          style={{ minHeight: isLoading ? '180px' : undefined }}
                        >
                          {isLoading && <Loading type='6' />}
                          <div className='pseudo-table-list'>
                            <div className='pseudo-table-header'>
                              <div className='pseudo-table-header-label'>{t('Document')}</div>
                              {schools.map((school) => (
                                <div
                                  key={`SchoolDocuments_Table_Header_Schools_${school.id}`}
                                  className='pseudo-table-header-action'
                                >
                                  <label className='label-checkbox'>
                                    <input
                                      type='checkbox'
                                      checked={
                                        documents.length &&
                                        documents.filter(({ schoolsAllowed }) => schoolsAllowed.includes(school.id))
                                          .length === documents.length
                                      }
                                      onChange={(e) => fillPermissions(e.target.checked, school.id)}
                                    />
                                    {t('MarkAll_question')}
                                  </label>
                                </div>
                              ))}
                            </div>

                            {!isLoading &&
                              documents.map((document) => (
                                <div
                                  key={`SchoolDocuments_Table_Row_Documents_${document.id}`}
                                  className='pseudo-table-row'
                                >
                                  <div className='pseudo-table-row-label'>
                                    <a
                                      className='link-normal'
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      href={document.urlPath}
                                    >
                                      {document.label}
                                    </a>
                                    {document.isSending ? (
                                      <Loading type='1' />
                                    ) : (
                                      <button
                                        type='button'
                                        style={{ background: 'none', border: 0 }}
                                        title={t('DeleteDocument')}
                                        onClick={() => confirmDeleteDocument(document)}
                                      >
                                        <span className='badge badge-danger ml-1'>
                                          <FaTimes />
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                  {schools.map((school) => (
                                    <div
                                      key={`SchoolDocuments_Table_Row_Schools_${document.id}_${school.id}`}
                                      className='pseudo-table-row-action'
                                    >
                                      <label className='label-checkbox'>
                                        <input
                                          type='checkbox'
                                          checked={document.schoolsAllowed.includes(school.id)}
                                          onChange={(e) => togglePermission(e.target.checked, document, school.id)}
                                        />
                                        {school.schName}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                      {isLoading ? (
                        <button className='btn_card mx-auto disabled' disabled>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t('SystemMessage.Loading.Processing')}
                          <Loading type='1' style={{ marginLeft: '2%' }} />
                        </button>
                      ) : (
                        <button
                          className={`btn_card mx-auto ${!documents.length ? 'disabled' : ''}`}
                          disabled={!documents.length}
                        >
                          {t('Save')}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </main>

            <FooterClub />
          </div>
        </div>
      </div>

      <ModalAddClubSchoolDocument
        ref={modalAddClubSchoolDocumentRef}
        title={document ? t('ClubSchoolDocumentsPage.Modal.TitleEdit') : t('ClubSchoolDocumentsPage.Modal.TitleAdd')}
        document={document}
        onSubmit={handleCreateDocument}
      />

      {sweetAlertConfirmDelete}
    </main>
  );
};

export default SchoolDocuments;
