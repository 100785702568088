import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import { css, handleOnChange } from '../../../src/utils/utils';
import { useTranslation } from 'react-i18next';
import SelectRounded from '../../components/inputs/SelectRounded';
import moment from 'moment';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import { generateFinancialReportXLS, getFinancialReports } from '../../services/ReportService';
import { useReactToPrint } from 'react-to-print';
import { CardIndicatorSimple } from '../../components/cards/CardIndicatorSimple';
import InputRounded from '../../components/inputs/InputRounded';
import FinancialTable from '../../components/tables/FinancialTable';
import { calcValueToReceive } from '../../utils/utils-financial';

const FinancialReports = () => {
  const { t } = useTranslation();
  const { MessageDangerMarginTop } = Message();
  const schoolId = JSON.parse(localStorage.getItem('school')).id;
  const tableRef = useRef(null);

  const [finances, setFinances] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);

  const [filters, setFilters] = useState({
    filterDueDateIni: moment().startOf('month').format('YYYY-MM-DD'),
    filterDueDateEnd: moment().endOf('month').format('YYYY-MM-DD'),
    filterStatus: '',
    filterDateType: 'OVERDUE',
  });

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchFinancialReports();
  }, []);

  const onChangeFilters = handleOnChange(setFilters);

  const fetchFinancialReports = async (event = null) => {
    event && event.preventDefault();

    clearMessage();
    setIsLoading(true);
    const { success, messageKey, finances } = await getFinancialReports(schoolId, { ...filters, withClass: true });
    setIsLoading(false);

    if (success) {
      setFinances(finances);

      setTotalExpense(
        finances
          .filter((fin) => fin.type === 'Despesa')
          .map(calcValueToReceive)
          .reduce((acc, current) => acc + current.valueCalculated, 0)
      );
      setTotalIncome(
        finances
          .filter((fin) => fin.type === 'Receita')
          .map(calcValueToReceive)
          .reduce((acc, current) => acc + current.valueCalculated, 0)
      );
    } else {
      showErrorMessage(messageKey);
    }
  };

  const clearMessage = () => {
    setMessage(null);
  };

  const showErrorMessage = (message) => {
    setMessage(message);
  };

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    pageStyle: css`
      .btn_card {
        display: none !important;
      }
      * {
        color: black !important;
      }
      .card {
        border: none !important;
      }
    `,
  });

  const handleExportToXLS = async () => {
    generateFinancialReportXLS(finances);
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel' style={{ minHeight: 'auto', alignItems: 'center' }}>
              <div className='card' style={{ justifyContent: 'center' }}>
                <div className='card_header' style={{ alignItems: 'center', width: '100%' }}>
                  <h2 className='card_title'>{t('FinancialReportsPage.Filter.Title')}</h2>
                </div>

                <form onSubmit={fetchFinancialReports} style={{ alignItems: 'center' }}>
                  <div
                    className='form_card row'
                    style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}
                  >
                    <div className='mx-2' style={{ width: '200px' }}>
                      <label className='card_details_label'>{t('FilterByDate')}</label>
                      <select
                        className='form_control select'
                        value={filters.filterDateType}
                        name='filterDateType'
                        onChange={onChangeFilters}
                      >
                        <option value='OVERDUE'>{t('DueDate')}</option>
                        <option value='PAYDAY'>{t('PayDay')}</option>
                      </select>
                    </div>
                    {filters.filterDateType === 'OVERDUE' && (
                      <div
                        style={{
                          width: '350px',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className=''>
                          <div className='form_group'>
                            <label className='card_details_label'>{t('InitialOverdue')}</label>
                            <input
                              type='date'
                              name='filterDueDateIni'
                              className='form_control'
                              value={filters.filterDueDateIni}
                              onChange={onChangeFilters}
                            />
                          </div>
                        </div>
                        <div className=''>
                          <div className='form_group'>
                            <label className='card_details_label'>{t('EndOverdue')}</label>
                            <input
                              type='date'
                              className='form_control'
                              name='filterDueDateEnd'
                              value={filters.filterDueDateEnd}
                              onChange={onChangeFilters}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {filters.filterDateType === 'PAYDAY' && (
                      <div
                        style={{
                          width: '350px',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <div className='form_group'>
                            <label className='card_details_label'>{t('InitialPaymentDate')}</label>
                            <input
                              type='date'
                              className='form_control'
                              name='filterDueDateIni'
                              value={filters.filterDueDateIni}
                              onChange={onChangeFilters}
                            />
                          </div>
                        </div>
                        <div>
                          <div className='form_group'>
                            <label className='card_details_label'>{t('EndPaymentDate')}</label>
                            <input
                              type='date'
                              className='form_control'
                              name='filterDueDateEnd'
                              value={filters.filterDueDateEnd}
                              onChange={onChangeFilters}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='mx-2' style={{ width: '180px' }}>
                      <SelectRounded
                        label={t('Received')}
                        name='filterStatus'
                        value={filters.filterStatus}
                        onChange={onChangeFilters}
                      >
                        <option value=''>{t('All')}</option>
                        <option value='N'>{t('Not')}</option>
                        <option value='S'>{t('Yes')}</option>
                      </SelectRounded>
                    </div>
                  </div>
                  <div style={{ width: '100px' }}>
                    <ButtonRounded type='submit'>{t('Research')}</ButtonRounded>
                  </div>
                </form>
              </div>
            </main>

            <main className='content_panel' style={{ minHeight: 'auto' }}>
              <div className='card' ref={tableRef}>
                <div className='card_header' style={{ alignItems: 'center', width: '100%' }}>
                  {!!message && (
                    <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                  )}
                </div>

                <div className='w-100 d-flex mt-5 flex-column flex-lg-row'>
                  {isLoading && <Loading type='6' />}
                  <CardIndicatorSimple
                    doubleLine
                    isLoading={isLoading}
                    title={t('FinancialReportsPage.Indicators.TotalExpense')}
                    value={t('FormatFunction.intlCurrencyWithOptions', { value: totalExpense })}
                  />
                  <CardIndicatorSimple
                    doubleLine
                    className='mx-lg-2'
                    isLoading={isLoading}
                    title={t('FinancialReportsPage.Indicators.TotalIncome')}
                    value={t('FormatFunction.intlCurrencyWithOptions', { value: totalIncome })}
                  />
                  <CardIndicatorSimple
                    doubleLine
                    isLoading={isLoading}
                    title={t('FinancialReportsPage.Indicators.Balance')}
                    value={t('FormatFunction.intlCurrencyWithOptions', { value: totalIncome - totalExpense })}
                  />
                </div>
                <div className='w-100 d-flex justify-content-center'>
                  <ButtonRounded className='mr-1' onClick={handlePrint}>
                    Imprimir
                  </ButtonRounded>
                  <ButtonRounded className='ml-1' onClick={handleExportToXLS}>
                    Exportar
                  </ButtonRounded>
                </div>

                <div className='list_card w-100' style={{ overflow: 'scroll' }}>
                  <FinancialTable mixed finances={finances}></FinancialTable>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default FinancialReports;
