import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css, handleOnChange } from '../../utils/utils';
import { ModalWithMethods } from '../../components/modal/Modal';
import Input from '../../components/inputs/Input';
import Select from '../../components/inputs/Select';
import Textarea from '../../components/inputs/Textarea';
import TableBordered from '../../components/tables/TableBordered';
import { IoIosAddCircle, IoIosRemoveCircle } from 'react-icons/io';
import { getStudentAnamnesisForm } from '../../services/StudentService';
import Loading from '../../components/Loading';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

const ModalHealthForm = ({ isLoading, student, onSubmit, readOnly, ...props }, ref) => {
  const { t } = useTranslation();
  const anamnesisFormRef = useRef(null);

  useEffect(() => {
    setInternReadOnly(readOnly);
  }, [readOnly]);

  const nestedQuestion = (value, onChange) => (
    <input
      required
      disabled={internReadOnly}
      className='w-100'
      style={{ border: 'none', borderBottom: '1px solid grey' }}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );

  const baseQuestions = [
    'Doenças infecto-contagiosa (AIDS, Hepatite, Chagas, Tuberculose, meningite, outros)',
    'Doença respiratória (Efisema, Asthma, Bronquite, Pneumonia) e outros',
    'Problemas ortopédicos (Escoliose, Cifose, Artrose, Fraturas, Hérnia de Disco ou outro distúrbio ósseo ou de Membros)',
    'Doença neurológica (Derrame cerebral ou outros / Desmaios)',
    'Diabetes (Açúcar no sangue)',
    'Câncer (Tumoração ou Leucemia)',
    'Hérnia de qualquer natureza',
    'Doença cardíaca (Insuficiência Cardíaca, Insuficiência coronariana, Angina, Cardiopatia congênita, arritmia) e outros',
    'Doença vascular (Varizes, Insuf. Arterial, Aneurisma, Tromboangelite) e outros',
    'Hipertensão arterial',
    'Doença Urinária (Rins, Bexiga, Próstata, Uretra, Cálc. Renal, Obstr. Urinária, Prostatismo, Incont. Urinária, Insuf. Renal, Perda de Urina, Pedra nos Rins) outros',
    'Distúrbio psquiátrico ou psicológico',
    'Doença ginecológica (Cisto de ovário, endometriose, mioma, incontinência urinária, Tumor de mama) e outros',
    'Doença reumatológica (Reumatismo, artrite) e outros',
    'Sequela de acidente, Moléstia adquirida ou congênita (nasceu com a doença)',
    'Úlcera ou doença do Ap. Digestivo (Probl. De Intestino, Hemorróida, Ictericia)',
    'Tem indicação para submeter-se a alguma cirurgia',
    'Doença de Otorrinolaringologia (Adenóide, Amigdalite, Sinusite, Problemas de ouvido, Audição - Apneia Noturna ) e outros',
    'Doença Oftalmológica ( Miopia de Alto Grau, problemas de retina - córnea) e outros / dificuldade para enxergar de perto ou de longe',
    'Tem qualquer deficiência visual, catarata, glaucoma (pressão alta no olho) e outros',
    'Tratamento de Quimioterapia ou Radioterapia',
  ];

  const [isFetchingData, setIsFetchingData] = useState(false);
  const [internReadOnly, setInternReadOnly] = useState(true);
  // const [questions, setQuestions] = useState([]);

  const [formData, setFormData] = useState({
    weight: { id: 1, value: '' },
    height: { id: 2, value: '' },
    age: { id: 3, value: '' },
    imc: { id: 4, value: '' },
    areSmoker: { id: 5, value: '' },
    howLong: { id: 6, value: '' },
  });
  const [formQuestions, setFormQuestions] = useState({});
  const [nestedQuestions, setNestedQuestions] = useState([]);
  const [formNestedQuestions, setFormNestedQuestions] = useState({});

  const [formObservations, setFormObservations] = useState({
    observations: { id: 34, value: '' },
  });

  const options = (id) => (
    <div className='py-1'>
      <label className='m-0 d-flex align-items-center'>
        <input
          disabled={internReadOnly}
          className='mr-1'
          name={id}
          type='radio'
          required
          value={'S'}
          checked={formQuestions[id] === 'S'}
          onChange={onChangeQuestion}
        />{' '}
        {t('Yes')}
      </label>
      <label className='m-0 d-flex align-items-center'>
        <input
          disabled={internReadOnly}
          className='mr-1'
          name={id}
          type='radio'
          required
          value={'N'}
          checked={formQuestions[id] === 'N'}
          onChange={onChangeQuestion}
        />{' '}
        {t('Not')}
      </label>
    </div>
  );

  useEffect(() => {
    // setQuestions(
    //   baseQuestions.map((question, i) => ({
    //     labelId: i + 1,
    //     id: i + 7,
    //     question,
    //     response: options(i + 7),
    //   }))
    // );

    setNestedQuestions([
      {
        id: 28,
        question: 'Cirurgias Realizadas',
        children: [
          { id: 30, question: 'Especificação' },
          { id: 31, question: 'Há quanto tempo?' },
        ],
      },
      {
        id: 29,
        question: 'Já foi indicado para fazer algum tratamento clínico ou cirúrgico que não tenha realizado?',
        children: [
          { id: 32, question: 'Tratamento' },
          { id: 33, question: 'Nome do médico que indicou' },
        ],
      },
    ]);
  }, []);

  useEffect(() => {
    if (student.athleteId) fetchAnamnesisForm(student.athleteId);
  }, [student.athleteId]);

  const onChangeForm = handleOnChange(setFormData);
  const onChangeQuestion = handleOnChange(setFormQuestions);
  const onChangeObservations = handleOnChange(setFormObservations);

  const handleOnSubmit = (event) => {
    event.preventDefault();
    onSubmit({
      section1: Object.values(formData),
      section2: Object.keys(formQuestions).map((key) => ({ id: key, value: formQuestions[key] })),
      section3: Object.keys(formNestedQuestions).map((key) => ({
        id: key,
        nest: formNestedQuestions[key].map((nestValue) => {
          const [questionKey, responseKey] = Object.keys(nestValue);
          return {
            question: { id: questionKey, value: nestValue[questionKey] },
            response: { id: responseKey, value: nestValue[responseKey] },
          };
        }),
      })),
      section4: Object.values(formObservations),
    });
  };

  const responseColor = (response) => {
    switch (response) {
      case 'S':
        return undefined;

      case 'N':
        return '#0000003b';

      default:
        return 'grey';
    }
  };

  const addNestedQuestion = (id, questions) => {
    setFormNestedQuestions((current) => {
      const aux = current[id] ? [...current[id]] : [];
      aux.push(questions.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {}));

      return { ...current, [id]: aux };
    });
  };

  const removeNestedQuestion = (id, index) => {
    setFormNestedQuestions((current) => {
      const aux = [...current[id]];
      aux.splice(index, 1);

      return { ...current, [id]: aux };
    });
  };

  //TODO: Temporário. Usar state quando o formulário for dinamizado.
  const questions = baseQuestions.map((question, i) => ({
    labelId: i + 1,
    id: i + 7,
    question,
    response: options(i + 7),
  }));

  const fetchAnamnesisForm = async (athleteId) => {
    clearAnamnesisForm();
    setIsFetchingData(true);
    const { status, messageKey, message, anamnesisForm } = await getStudentAnamnesisForm(athleteId);
    setIsFetchingData(false);

    if (status === 'SUCCESS') {
      if (anamnesisForm.length) fillAnamnesisForm(anamnesisForm);
    } else {
      ref.current.showErrorMessage(messageKey ? t(messageKey) : message);
    }
  };

  const fillAnamnesisForm = (data) => {
    const section1 = data.filter(({ section }) => section === 1).sort((a, b) => a.id - b.id);
    const section2 = data.filter(({ section }) => section === 2);
    const section3 = data.filter(({ section }) => section === 3);
    const section4 = data.filter(({ section }) => section === 4);

    //TODO: Temporário. Alterar quando o formulário for dinamizado.
    setFormData({
      weight: { id: 1, value: section1[0]?.response?.response || '' },
      height: { id: 2, value: section1[1]?.response?.response || '' },
      age: { id: 3, value: section1[2]?.response?.response || '' },
      imc: { id: 4, value: section1[3]?.response?.response || '' },
      areSmoker: { id: 5, value: section1[4]?.response?.response || '' },
      howLong: { id: 6, value: section1[5]?.response?.response || '' },
    });

    setFormQuestions(section2.reduce((acc, curr) => ({ ...acc, [curr.id]: curr?.response?.response || '' }), {}));

    setFormNestedQuestions(
      section3.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: curr.nested_questions[0].responses.map((response, i) => ({
            [curr.nested_questions[0].id]: curr.nested_questions[0].responses[i].response,
            [curr.nested_questions[1].id]: curr.nested_questions[1].responses[i].response,
          })),
        }),
        {}
      )
    );

    setFormObservations({
      observations: { id: 34, value: section4[0]?.response?.response ?? '' },
    });
  };

  const clearAnamnesisForm = () => {
    setFormData({
      weight: { id: 1, value: '' },
      height: { id: 2, value: '' },
      age: { id: 3, value: '' },
      imc: { id: 4, value: '' },
      areSmoker: { id: 5, value: '' },
      howLong: { id: 6, value: '' },
    });
    setFormQuestions({});
    setFormNestedQuestions([]);
    setFormObservations({
      observations: { id: 34, value: '' },
    });
  };

  const handlePrint = useReactToPrint({
    documentTitle: `${t('AnamnesisForm')} - ${student.name}`,
    content: () => anamnesisFormRef.current,
    pageStyle: css`
      @page {
        margin: 5mm 5mm 0mm 5mm !important;
      }

      .btn_card {
        display: none !important;
      }

      #title-to-print {
        display: block !important;
        width: 100% !important;
      }

      form {
        margin: 0px !important;
      }

      * {
        color: #464646 !important;
        border-color: #464646 !important;
      }

      table,
      table * {
        box-shadow: unset !important;
      }

      table,
      th,
      td {
        border: 2px solid #464646 !important;
        border-collapse: collapse !important;
      }

      table td input {
        border: none !important;
      }

      .table-container {
        border-radius: 0px !important;
      }

      table th {
        font-size: 10pt !important;
        font-weight: bold !important;
      }

      thead,
      th {
        position: unset !important;
        background-color: unset !important;
      }

      .group_50 {
        width: 49% !important;
      }

      .group_50,
      .form_group,
      .form_group * {
        margin: 0px !important;
        margin-bottom: 1mm !important;
      }

      .form_control {
        height: 35px !important;
      }

      textarea.form_control {
        height: 50px !important;
        color: #464646 !important;
      }
    `,
    onBeforeGetContent: async () => {
      setInternReadOnly(true);
    },
    onAfterPrint: async () => {
      setInternReadOnly(readOnly);
    },
  });

  return (
    <ModalWithMethods {...props} size={'lg'} ref={ref} title={t('AnamnesisForm')}>
      <form ref={anamnesisFormRef} onSubmit={handleOnSubmit} method='post' className='form_modal position-relative'>
        {isFetchingData && <Loading type={6} style={{ alignItems: 'flex-start', marginTop: '115px' }} />}
        <h3 id='title-to-print' className='modal_title' style={{ display: 'none' }}>
          {t('AnamnesisForm')}
        </h3>
        <div className='group_50'>
          <Input required disabled label={t('Name')} value={student.name} />
        </div>

        <div className='group_50'>
          <div className='group_50'>
            <Input
              required
              disabled={internReadOnly}
              label={t('Weight')}
              type='number'
              name='weight.value'
              value={formData.weight.value}
              onChange={onChangeForm}
            />
          </div>
          <div className='group_50'>
            <Input
              required
              disabled={internReadOnly}
              label={t('Height')}
              type='number'
              name='height.value'
              value={formData.height.value}
              onChange={onChangeForm}
            />
          </div>
        </div>

        <div className='group_50'>
          <div className='group_50'>
            <Input
              required
              disabled
              label={t('Age')}
              type='number'
              name='age.value'
              value={moment().diff(student.birth, 'years', false)}
            />
          </div>
          <div className='group_50'>
            <Input
              required
              disabled={internReadOnly}
              label={'IMC'}
              type='number'
              name='imc.value'
              value={formData.imc.value}
              onChange={onChangeForm}
            />
          </div>
        </div>

        <div className='group_50'>
          <div className='group_50'>
            <Select
              required
              disabled={internReadOnly}
              label={t('ModalHealthForm.AreSmoker')}
              name='areSmoker.value'
              value={formData.areSmoker.value}
              onChange={(e) => {
                onChangeForm(e);
                if (e.target.value === 'S') onChangeForm('howLong.value', '');
                else onChangeForm('howLong.value', '-');
              }}
            >
              <option value='' disabled />
              <option value='S'>{t('Yes')}</option>
              <option value='N'>{t('Not')}</option>
            </Select>
          </div>
          <div className='group_50'>
            <Input
              required={formData.areSmoker.value === 'S'}
              disabled={formData.areSmoker.value === 'N' || readOnly}
              label={t('ModalHealthForm.HowLong')}
              type='text'
              name='howLong.value'
              value={formData.howLong.value}
              onChange={onChangeForm}
            />
          </div>
        </div>

        {/* Seção 2 */}
        <TableBordered
          keyPrefix='section_2'
          lineColor={({ id }) => responseColor(formQuestions[id])}
          columns={[
            { field: 'labelId', label: '#' },
            { field: 'question', label: t('ModalHealthForm.Questions.Tem ou já teve as doenças abaixo?') },
            { field: 'response', label: `${t('Yes')}/${t('Not')}` },
          ]}
          data={questions.map(({ question, ...rest }) => ({
            ...rest,
            question: t('ModalHealthForm.Questions.' + question),
          }))}
        />

        {/* Seção 3 */}
        {nestedQuestions.map(({ id, question, children }) => (
          <div key={`section_2_${id}`} className='w-100 mt-3'>
            <label className='card_details_label flex-row align-items-center justify-content-center'>
              {t('ModalHealthForm.Questions.' + question)}
              {!internReadOnly && (
                <IoIosAddCircle
                  title={t('AddLine')}
                  size={20}
                  style={{ cursor: 'pointer' }}
                  onClick={() => addNestedQuestion(id, children)}
                  onMouseEnter={(event) => (event.currentTarget.style.color = 'grey')}
                  onMouseLeave={(event) => (event.currentTarget.style.color = null)}
                />
              )}
            </label>
            <TableBordered
              emptyTableMessage={''}
              columns={children.map(({ id, question }) => ({
                field: id,
                label: t('ModalHealthForm.Questions.' + question),
                width: '50%',
              }))}
              actions={
                internReadOnly
                  ? undefined
                  : [
                      {
                        header: t('Remove'),
                        action: (v, e, j) => removeNestedQuestion(id, j),
                        title: t('Remove'),
                        width: '50.5px',
                        textAlign: 'center',
                        label: <IoIosRemoveCircle size={20} style={{ cursor: 'pointer' }} />,
                      },
                    ]
              }
              data={
                formNestedQuestions[id]?.map((nest, i) =>
                  Object.keys(nest).reduce(
                    (acc, questionId) => ({
                      ...acc,
                      [questionId]: nestedQuestion(nest[questionId], (value) => {
                        setFormNestedQuestions((current) => {
                          const aux = { ...current };
                          aux[id][i][questionId] = value;

                          return aux;
                        });
                      }),
                    }),
                    {}
                  )
                ) || []
              }
            />
          </div>
        ))}

        <Textarea
          disabled={internReadOnly}
          classNameContainer='mt-3'
          label='Observations'
          name='observations.value'
          rows='1'
          maxLength={50}
          value={formObservations.observations.value}
          onChange={onChangeObservations}
        />

        <div className='w-100 flex-justify-align'>
          {!readOnly && isLoading && (
            <button className='btn_card' style={{ background: '#ccc', border: 'none' }} disabled>
              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
              </svg>
              {t('SystemMessage.Loading.Processing')}
            </button>
          )}
          {!readOnly && !isLoading && (
            <button className='btn_card '>
              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
              </svg>
              {t('Save')}
            </button>
          )}
          <button className='btn_card ml-1' type='button' onClick={handlePrint}>
            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
            </svg>
            {t('Print')}
          </button>
        </div>
      </form>
    </ModalWithMethods>
  );
};

export default forwardRef(ModalHealthForm);
