import React from 'react';
import Message from '../../../components/Message';
import useForm from '../../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import DragDropUpload from '../../../components/DragDropUpload';
import ButtonRounded from '../../../components/buttons/ButtonRounded';
import { StudentFormContext } from '../../../Context/StudentFormContext';
import api, { base_url } from '../../../services/api';
import NumberFormat from 'react-number-format';
import { BtnInfo } from '../NewStudent';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getUserPhoto } from '../../../utils/utils';

const { MessageDangerMarginTop } = Message();

// TODO: usar o intl para traduzir os textos
const Atleta = ({ title = 'Informações Aluno', nextTab, close }) => {
  const context = React.useContext(StudentFormContext);

  const { selected } = useSelector(({ User }) => User);
  const dispatch = useDispatch();

  const formRefer = React.useRef(null);

  const parentRef = React.useRef(null);

  const { id } = useParams();

  const [sendSave, setSendSave] = React.useState(false);

  const [errors, setErrors] = React.useState([]);

  const [errorsServer, setErrorsServe] = React.useState([]);

  const { t, i18n } = useTranslation();

  const handlerErrorServer = (err) => {
  
    if (err.response.status === 422) {
      const { message = [] } = err.response.data;

      Object.keys(message).map((k) => {
        const element = document.querySelector(`[name=${k}]`);
        if (element) {
          dispatchHtmlValidity(element, `[${k}] ${message[k]}`);

          setState({
            ...state,
            [k]: { value: state[k]?.value, error: message[k] },
          });
        } else {
          setState({
            ...state,
            [k]: { value: state[k]?.value, error: message[k] },
          });
          setErrorsServe(message[k]);
        }
      });
    } else setErrorsServe([err.response.data.message.toString()]);
  };

  const onSubmitForm = (p, e, s, formData) => {
    const { tipo = undefined } = e.target.dataset;

    formData.append('companie_id', context.school.companie_id);
    formData.append('role_id', 2);
    formData.append('usuStudent', 'S');
    formData.append('school_id', context.school.id);
    formData.append('session_user_id', context.user.id);
    formData.append('location', i18n.language);
    setSendSave(true);
    //valida os dados no servidor
    context
      .requestValidate('/validate/student', formData)
      .then((data) => {
        //persiste os dados sem tratamento
        context.setState({
          atleta: {
            ...state,
            ...context.normalResponse(data.message),
            companie_id: { value: context.school.companie_id, error: '' },
            role_id: { value: 2, error: '' },
            usuStudent: { value: 'S', error: '' },
            school_id: { value: context.school.id, error: '' },
            location: { value: i18n.language, error: '' },
            session_user_id: { value: context.user.id, error: '' },
          },
        });
        if (tipo !== undefined && tipo === 'finalizar') {
          context.handlerFinally();
          return null;
        }
        //avanca a tab
        nextTab(1);
      })
      .catch(handlerErrorServer)
      .finally(() => setSendSave(false));
  };

  const handlerSetUserEdit = (student) => {
    return {
      atleta: {
        id: { value: student.user_id, error: '' },
        athId: { value: student.athId, error: '' },
        name: { value: student.name, error: '' },
        usuStatus: { value: student.status === 'Ativo' ? 'A' : 'I', error: '' },
        login: { value: student.usuLogin, error: '' },
        email: { value: student.email, error: '' },
        athRG: { value: student.athRG, error: '' },
        usuCPF: { value: student.usuCPF, error: '' },
        usuBirth: { value: student.usuBirth, error: '' },
        usuPhone: { value: student.usuPhone, error: '' },
        usuGender: { value: student.usuGender, error: '' },
        athCodigoAluno: { value: student.athCodigoAluno, error: '' },
        athTipoStudent: { value: student.athTipoStudent, error: '' },
        expirationDateMedicalExamination: { value: student.expirationDateMedicalExamination, error: '' },
        foto: { value: student.foto, error: '' },
        companie_id: { value: student.companie_id, error: '' },
        school_id: { value: student.school_id, error: '' },
        role_id: { value: student.role_id, error: '' },
        usuStudent: { value: student.usuStudent, error: '' },
        location: { value: student.location_zip, error: '' },
        athIndication: { value: student.athIndication, error: '' },
        session_user_id: { value: context.user.id, error: '' },
      },
      fiador: {
        id: { value: 0, error: '' },
        athNomeRespFin: { value: student.athNomeRespFin, error: '' },
        athRGRespFin: { value: student.athRGRespFin, error: '' },
        athCPFRespFin: { value: student.athCPFRespFin, error: '' },
        athEmailRespFin: { value: student.athEmailRespFin, error: '' },
        athParentescoRespFin: { value: student.athParentescoRespFin, error: '' },
        athPhoneRespFin: { value: student.athPhoneRespFin, error: '' },
      },
      responsaveis: {
        athCep: { value: student.athCep, error: '' },
        athLogradouro: { value: student.athLogradouro, error: '' },
        athBairro: { value: student.athBairro, error: '' },
        athMaeNome: { value: student.athMaeNome, error: '' },
        athPaiNome: { value: student.athPaiNome, error: '' },
        athMaeCPF: { value: student.athMaeCPF, error: '' },
        athPaiCPF: { value: student.athPaiCPF, error: '' },
        athMaeCelular: { value: student.athMaeCelular, error: '' },
        athPaiCelular: { value: student.athPaiCelular, error: '' },
        uf: { value: student.staStateSigla, error: '' },
        numero: { value: student.athNumero, error: '' },
        cidade: { value: student.city_id, error: '' },
        cidadeID: { value: student.city_id, error: '' },
        complemento: { value: student.athComplemento, error: '' },
        descricao: { value: student.usuSobre, error: '' },
        questionario: { value: student.questionnaire_id, error: '' },
      },
      esportes: {
        schoolYear: { value: student.schoolYear, error: '' },
        skillLevel: { value: student.skillLevel, error: '' },
        sublevel: { value: student.sublevel, error: '' },
        position: { value: student.position_id, error: '' },
        sport: { value: student.sport_id, error: '' },
        athLateralidade: { value: student.athLateralidade, error: '' },
        certificado: { value: student.allow_certificate_access, error: '' },
      },
    };
  };

  const { state, handleOnChange, handleOnSubmit, disable, setState, dispatchHtmlValidity, setIsDirty } = useForm(
    selected ? handlerSetUserEdit(selected).atleta : context.atleta,
    validationStateSchema,
    onSubmitForm,
    formRefer,
    setErrors
  );

  const setImages = (image) => {
    setState((prevState) => ({
      ...prevState,
      foto: {
        value: image,
        error: '',
      },
    }));
    setErrorsServe([]);
  };
  const getStudent = async (id) => {
    return await api.post('get-student-seletivas-frequency', {
      user_id: id,
    });
  };
  React.useEffect(() => {
    if (selected) {
      context.setState(handlerSetUserEdit(selected));
      dispatch({ type: 'RESET_USER', payload: null });
    }
  }, [selected]);

  React.useEffect(() => {
    setIsDirty(true);

    if (id && selected === null) {
      getStudent(id).then((res) => {
        res.data.student.foto = getUserPhoto(res.data.dir_usuFotoPrincipal + '/', res.data.student.usuFotoPrincipal);
        res.data.student.athId = res.data.student.id;

        dispatch({ type: 'SET_USER', payload: res.data.student });
        setState(handlerSetUserEdit(res.data.student).atleta);
      });
    }
  }, []);

  React.useEffect(() => {
    const elmain = document.querySelector('main');
    if (elmain && (errorsServer.length > 0 )) {
      elmain.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }
  }, [errorsServer]);

  return (
    <>
      {errors.map((err, k) => {
        return (
          <MessageDangerMarginTop
            key={`err-${k}`}
            title='Atenção!'
            description={t(`${err.error.replace(/\[.*?\]/g, '').trim()}`)}
          />
        );
      })}
      {errorsServer.map((err, k) => (
        <MessageDangerMarginTop
          title='Atenção!'
          description={t(`${err.replace(/\[.*?\]/g, '').trim()}`)}
          key={`error_${k}`}
        />
      ))}
      <div className='mx-3'>
        <form ref={formRefer} action='' className='form_card' noValidate>
          <div className='col p-0 w-100'>
            <div className='row'>
              <div className='col-md-9 col-sm-12 form_group'>
                <label className='card_details_label'>{t('Name')} *</label>
                <input
                  type='text'
                  name='name'
                  required
                  placeholder='Nome Aluno'
                  className='form-control form_control'
                  value={state.name.value}
                  onChange={handleOnChange}
                />
              </div>
              <div className='col-md-3 col-sm-12 form_group'>
                <label className='card_details_label'>{t('Status')} *</label>
                <select
                  value={state.usuStatus.value}
                  required
                  onChange={handleOnChange}
                  name='usuStatus'
                  className='form-control form_control select'
                >
                  <option value='A'>{t('StatusType.Active')}</option>
                  <option value='I'>{t('StatusType.Inactive')}</option>
                  <option value='P'>{t('StatusType.PreRegistered')}</option>
                </select>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-3 col-sm-12 form_group'>
                <label htmlFor='how' className='card_details_label'>
                  {t('BiologicalSex')}
                </label>
                <select
                  /* required */
                  name='usuGender'
                  className='form_control form-control select'
                  value={state.usuGender.value}
                  onChange={handleOnChange}
                >
                  <option value=''></option>
                  <option value='F'>{t('Feminine')}</option>
                  <option value='M'>{t('Masculine')}</option>
                </select>
              </div>
              <div className='col-md-3 col-sm-12 form_group'>
                <label className='card_details_label'>{t('StudentType')}*</label>
                <select
                  required
                  value={state.athTipoStudent.value}
                  className='form_control form-control  select'
                  name='athTipoStudent'
                  onChange={handleOnChange}
                >
                  <option value='Social'>{t('StudentModel.Type.Social')}</option>
                  <option value='Regular'>{t('StudentModel.Type.Regular')}</option>
                  <option value='Seletiva'>{t('StudentModel.Type.Selective')}</option>
                  <option value='Alojado'>{t('StudentModel.Type.Housed')}</option>
                </select>
              </div>
              <div className='col-md-3 col-sm-12 form_group'>
                <label htmlFor='birth' className='card_details_label'>
                  {t('BirthDate')} *
                </label>
                <input
                  type='date'
                  required
                  name='usuBirth'
                  className='form_control form-control '
                  value={state.usuBirth.value || ''}
                  onChange={handleOnChange}
                />
              </div>
              <div className='col-md-3 col-sm-12 form_group'>
                <label className='card_details_label'>{t('StudentCode')}</label>
                <input
                  type='text'
                  name='athCodigoAluno'
                  placeholder={`${t('StudentCode')}`}
                  className='form-control form_control'
                  value={state.athCodigoAluno.value || ''}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-3 col-sm-12 form_group'>
                <label className='card_details_label'>{t('Email')}</label>
                <input
                  type='email'
                  name='email'
                  placeholder='Email Aluno'
                  className='form-control form_control'
                  value={state.email.value || ''}
                  onChange={handleOnChange}
                />
              </div>
              <div className='col-md-3 col-sm-12 form_group'>
                <label className='card_details_label'>{t('Login')} *</label>
                <input
                  type='text'
                  name='login'
                  required
                  className='form-control form_control'
                  value={state.login.value || ''}
                  onChange={handleOnChange}
                />
                <div style={{ position: 'absolute', padding: 5, right: 20, top: 30 }}>
                  <BtnInfo title='Nome de usuário (Login) é usado para acessar o sistema.' />
                </div>
              </div>
              <div className='col-md-3 col-sm-12 form_group'>
                <label className='card_details_label'>{t('ExpirationDateMedicalExamination')}</label>
                <input
                  type='date'
                  name='expirationDateMedicalExamination'
                  className='form-control form_control'
                  value={state.expirationDateMedicalExamination.value || ''}
                  onChange={handleOnChange}
                />
              </div>
              <div className='col-md-3 col-sm-12 form_group'>
                <label className='card_details_label'> {t('Indication')}</label>
                <input
                  type='text'
                  name='athIndication'
                  className='form-control form_control'
                  value={state.athIndication.value || ''}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-12 form_group'>
                <label className='card_details_label'>{t('CPF')} </label>
                <NumberFormat
                  name='usuCPF'
                  format={t('Mask.CPF')}
                  className='form-control form_control'
                  mask='_'
                  value={state.usuCPF.value || ''}
                  onChange={handleOnChange}
                />
              </div>
              <div className='col-md-4 col-sm-12 form_group'>
                <label className='card_details_label'>{t('CellPhone')} </label>
                <NumberFormat
                  type='text'
                  name='usuPhone'
                  className='form-control form_control'
                  value={state.usuPhone.value || ''}
                  onChange={handleOnChange}
                  format={t('Mask.CellPhone') || undefined}
                />
              </div>
              <div className='col-md-4 col-sm-12 form_group'>
                <label className='card_details_label'>{t('RG')} </label>
                <input
                  type='text'
                  name='athRG'
                  className='form-control form_control'
                  value={state.athRG.value || ''}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12 p-4'>
                <DragDropUpload
                  parentRef={parentRef}
                  setState={setImages}
                  persistFile={state.foto.value || context.atleta.foto.value}
                />
                <p className='text-gray'>
                  *Apenas permitido arquivos no formato JPG, JPEG ou PNG de até no máximo 1mbs
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='row align-items-center justify-content-center my-4'>
        {/* <div className='col-md-2 col-sm-12'>
          {range(0, 6).map((i) => (
            <span key={`dot_${i}`} className='mx-1 clickable' onClick={() => nextTab(1)}>
              <FaCircle color='var(--main-color)' style={{ opacity: 0 === i ? 1 : 0.3 }} />
            </span>
          ))}
        </div> */}
      </div>
      <div className='row align-items-center justify-content-center my-4'>
        <div className='col-md-5 col-sm-10'>
          <ButtonRounded
            isDisabled={disable}
            isLoading={sendSave}
            data-tipo='finalizar'
            onClick={handleOnSubmit}
            className='w-100'
            isBig={true}
          >
            {t('Save')} {t('And')} {t('Finalize')}
          </ButtonRounded>
        </div>
        <div className='col-md-5 col-sm-10'>
          <ButtonRounded
            isDisabled={disable}
            isLoading={sendSave}
            onClick={handleOnSubmit}
            className='w-100'
            isBig={true}
          >
            {t('Save')} {t('And')} {t('Continue')}
          </ButtonRounded>
        </div>
      </div>
    </>
  );
};

const validationStateSchema = {
  name: {
    required: true,
  },
  /*   email: {
    required: true,
  },
  login: {
    required: true,
  },
  usuBirth: {
    required: true,
  }, */
};

export default Atleta;
