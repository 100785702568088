import React, { useEffect, useState, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import LogoSchool from '../../components/school/Logo';
import LogoClub from '../../components/club/Logo';
import Api from '../../utils/api';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../Context/UserContext';

const EditPassword = () => {
  const { t } = useTranslation();
  const baseURL = Api();
  const { user } = useContext(UserContext);
  const user_id = user.id;

  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [send, setSend] = useState(false);
  const [auth, setAuth] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [message, setMessage] = useState('');
  const [isSchool, setIsSchool] = useState(undefined);
  const { MessageDanger, MessageSuccess } = Message();

  useEffect(() => {
    setIsSchool(localStorage.getItem('school') !== 'null' ? true : false);
  }, []);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setSend(true);
    setAuth(false);
    setInvalid(false);

    axios
      .post(baseURL + 'edit-password', {
        user_id: user_id,
        currentPassword: currentPassword,
        password: password,
        confirmPassword: confirmPassword,
      })
      .then((res) => {
        setSend(false);

        if (!res.data.error) {
          setAuth(true);
          setPassword('');
          setConfirmPassword('');
        } else {
          setAuth(false);
          setInvalid(true);
        }

        setMessage(t('RecoveryPasswordPage.RecoveryResultMessage.') + res.data.messageKey);
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  if (auth) {
    return (
      <Redirect
        to={{
          pathname: '/home-school',
          state: {
            user_id: user_id,
          },
        }}
      />
    );
  }

  return (
    <div>
      <div className='container-fluid container_login'>
        <div className='content_login'>
          <div className='login_header'></div>
          {isSchool === undefined ? '' : isSchool ? <LogoSchool /> : <LogoClub />}
          {invalid && (
            <MessageDanger
              style={{ marginLeft: '10%', marginRight: '10%' }}
              title={t('Attention_exclamation') + ' '}
              description={t(message)}
            />
          )}
          {auth && <MessageSuccess title={t('Authenticated_exclamation') + ' '} description={t(message)} />}
          <form onSubmit={handleSubmit} className='form_login'>
            <div className='form_group'>
              <label htmlFor='email'>{t('CurrentPassword')}</label>
              <input
                // required
                type='password'
                name='currentPassword'
                value={currentPassword}
                className='form_control'
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div className='form_group'>
              <label htmlFor='email'>{t('NewPassword')}</label>
              <input
                required
                type='password'
                name='password'
                value={password}
                className='form_control'
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className='form_group'>
              <label htmlFor='email'>{t('ConfirmNewPassword')}</label>
              <input
                required
                type='password'
                name='confirmPassword'
                value={confirmPassword}
                className='form_control'
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className='form_footer'>
              <div>
                <Link to='/login-school' className='login_link'>
                  {t('RecoveryPasswordPage.NewPasswordBackText')}
                </Link>
              </div>
              {send && (
                <span style={{ textAlign: 'end', width: '150px' }}>
                  {t('SystemMessage.Loading.WaitValidating')}&nbsp;
                  <Loading type='1' />
                </span>
              )}
              {!send && (
                <button type='submit' className='btn_login'>
                  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                  </svg>{' '}
                  {t('Change')}
                </button>
              )}
            </div>
          </form>
        </div>
        <svg width='84' height='12' viewBox='0 0 84 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M64.1537 0L71.734 5.99941L64.1537 11.9988H77.8664C81.2531 11.9988 83.9988 9.31271 83.9988 5.99941C84 2.68611 81.2543 0 77.8664 0H64.1537Z'
            fill='white'
          />
          <path
            d='M1.87286 3.74668V5.26768H7.12286V6.8474H1.87286V9.82244H0V2.21512H7.37017V3.74668H1.87286Z'
            fill='white'
          />
          <path
            d='M8.78682 9.82244V2.21512H16.3839V3.74668H10.6297V5.24889H16.2158V6.76988H10.6297V8.29205H16.4535V9.82244H8.78682Z'
            fill='white'
          />
          <path
            d='M24.4276 9.82244L19.8619 4.59938V9.82244H18.0479V2.21512H20.1092L24.586 7.3219V2.21512H26.3797V9.82244H24.4276Z'
            fill='white'
          />
          <path
            d='M32.4712 9.93872C28.9452 9.93872 27.8659 8.65028 27.8659 7.37124H29.8661C29.8661 8.00077 30.7485 8.47528 32.5601 8.47528C34.3633 8.47528 34.8387 8.16521 34.8387 7.66134C34.8387 7.09875 34.4822 6.92492 32.8086 6.74052C32.4808 6.71116 31.5804 6.60428 31.2431 6.55612C28.866 6.29421 27.9548 5.60594 27.9548 4.37623C27.9548 3.07722 29.291 2.09885 32.1939 2.09885C35.3442 2.09885 36.5519 3.31094 36.5519 4.57002H34.5698C34.5698 3.92991 33.8267 3.56229 32.0739 3.56229C30.2718 3.56229 29.8853 3.80424 29.8853 4.27874C29.8853 4.77321 30.2814 4.92825 32.1939 5.15023C32.6297 5.20896 33.5998 5.31584 33.8675 5.34403C35.888 5.54722 36.7992 6.26484 36.7992 7.53449C36.7992 9.04727 35.4126 9.93872 32.4712 9.93872Z'
            fill='white'
          />
          <path
            d='M46.6461 3.37791C47.28 4.03681 47.5273 4.87071 47.5273 5.97476C47.5273 7.11756 47.2104 7.98082 46.6353 8.61976C45.873 9.49125 44.5055 9.93756 42.6531 9.93756C40.8415 9.93756 39.5533 9.47246 38.7405 8.63855C38.1162 7.99844 37.7801 7.12695 37.7801 5.97358C37.7801 4.93649 38.0478 4.10258 38.6613 3.45425C39.5629 2.50407 40.7814 2.05893 42.6531 2.05893C44.526 2.0601 45.7841 2.49702 46.6461 3.37791ZM45.0806 7.68132C45.4768 7.30313 45.6245 6.6642 45.6245 6.00529C45.6245 5.337 45.4768 4.67809 45.0902 4.32809C44.556 3.85359 43.8921 3.5717 42.6435 3.5717C41.455 3.5717 40.6818 3.88177 40.2268 4.34688C39.8798 4.71568 39.6913 5.32525 39.6913 6.00412C39.6913 6.74054 39.8702 7.37008 40.3456 7.76706C40.8811 8.2122 41.5546 8.44476 42.6531 8.44476C43.8524 8.44593 44.556 8.18519 45.0806 7.68132Z'
            fill='white'
          />
          <path
            d='M56.9576 5.75275C56.7103 6.15091 56.2541 6.48917 55.7007 6.57726L58.0285 9.82362H55.9084L53.8386 6.85797H50.8168V9.82362H48.9644V2.21512H54.5013C55.6803 2.21512 56.4534 2.54398 56.8592 3.05724C57.1365 3.39668 57.2758 3.83242 57.2758 4.43377C57.2746 4.98697 57.1653 5.44268 56.9576 5.75275ZM55.3141 4.06615C55.146 3.8242 54.8579 3.70792 54.3032 3.70792H50.8264V5.39453H54.4125C54.9179 5.39453 55.1952 5.28765 55.3141 5.09385C55.4125 4.93881 55.4425 4.73562 55.4425 4.58059C55.4425 4.39619 55.4125 4.19182 55.3141 4.06615Z'
            fill='white'
          />
        </svg>
      </div>
    </div>
  );
};

export default EditPassword;
