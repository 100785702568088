import React from 'react';
import BaseInput from './BaseInput';
import CEPMask from './CEPMask';

const InputCEPMask = ({ ...props }) => {
  return (
    <BaseInput
      {...props}
      inputComponent={({ onChange, ...props }) => (
        <CEPMask {...props} onChange={(value) => onChange(props.name, value)} />
      )}
    />
  );
};

export default InputCEPMask;
