import axios from 'axios';
import Api from '../utils/api';

const baseURL = Api();

const getStudentsEvaluations = ({ schoolId, companyId, classId, name = '' }) =>
  new Promise((response, reject) => {
    const requestBody = {
      school_id: schoolId,
      company_id: companyId,
      class_id: classId,
      name: name,
    };
    axios
      .post(baseURL + 'get-students-evaluations', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

const deleteStudentsEvaluation = ({ evaluationId, schoolId, companyId }) =>
  new Promise((response, reject) => {
    const requestBody = {
      id: evaluationId,
      school_id: schoolId,
      company_id: companyId,
    };
    axios
      .post(baseURL + 'delete-school-evaluation', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export { getStudentsEvaluations, deleteStudentsEvaluation };
