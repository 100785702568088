import React, { useEffect, useState, useRef } from 'react'
import Header from '../../components/athlete/Header'
import Footer from '../../components/athlete/Footer'
import Loading from '../../components/Loading'
import { Modal, Button } from 'react-bootstrap';
import Api from '../../utils/api'
import axios from 'axios'

const PhotosAthlete = () => {
   // ref
   const scrollObserve = useRef();

   // set loading 
   const [sendingPhotos, setSendingPhotos] = useState(false)

   // set data
   const [user_id, setUserID] = useState("")
   const [showDetalhes, setShowDetalhes] = useState(false)
   const [modal, setModal] = useState(false)
   const [photos, setPhotos] = useState([])
   const [photo, setPhoto] = useState([])
   const [dir, setDir] = useState("")
   const [page, setPage] = useState(0)
   const [scrollRadio, setScrollRadio] = useState(null)

   // outras variaveis
   const baseURL    = Api()
   let userLogado   = ''

   const getPhotos = async (novaPage, user_id_params) => {
      setSendingPhotos(true)
      //const res = await axios.post(baseURL + 'get-photoalbum-photos-pagination',{
      const res = await axios.post(baseURL + 'get-photos-athlete-pagination',{
         user_id: user_id_params == '' ? user_id : user_id_params,
         start: novaPage,
         length: 20
      })
      setSendingPhotos(false)
      setDir(res.data.urlPhotos)

      // monta as photos
      const novasPhotos = [...photos]
      novasPhotos.push(...res.data.photos)
      setPhotos(novasPhotos)
   }

   const showPhoto = (detalhes) => {
      window.scrollTo(0, 250)
      setShowDetalhes(true)
      setPhoto(detalhes)
   }

   const intersectionObserver = new IntersectionObserver( entries => {
      const radio = entries[0].intersectionRatio
      setScrollRadio(radio)
   })

   const handleCloseModal = () => setModal(false)

   const handleShowModal = (e) => {
      e.preventDefault()
      setModal(true)
   }

   useEffect(()=>{

      (async () => {
         const user = JSON.parse(await localStorage.getItem('user'))
         setUserID(user.id)
   
         getPhotos(page, user.id)
      })()
     
   },[])

   useEffect(() => {

      intersectionObserver.observe(scrollObserve.current)

      return () => {
         intersectionObserver.disconnect()
      }

   },[])

   useEffect(() => {

      if ( scrollRadio > 0 && photos.length > 0) {
         const novaPage = page + 10
         setPage(novaPage)
         getPhotos(novaPage, '')
      }

   },[scrollRadio])

   return (
         <React.Fragment>
            
         <Header type="2" menu={true} />

         <main>
            <section className="titulo-pg-foto">
            {
               photos.length > 0 &&
               <div className="cont-titulo-pg-foto">
                  <h3>Fotos</h3>
                  <div className="desc-titulo-pg-foto" id="deletar-antes-filtro">
                     <p>Estas fotos foram adquiridas por você na plataforma fensor.</p>
                  </div>
               </div>
            }
            {
               !sendingPhotos && photos.length == 0 &&
               <div className="cont-titulo-pg-foto">
                  <h3>Fotos</h3>
                  <div className="desc-titulo-pg-foto" id="deletar-antes-filtro">
                     <p>Você ainda não comprou suas fotos, para comprar clique aqui &#128073; <a href="/photosalbum" style={{ fontWeight: '500', fontSize: '12px', color: 'gray', textTransform: 'uppercase', fontSize: '0.9em', fonFamily: 'titling-gothic-fb-condensed, sans-serif', fontWeight: '500', width: '100%'}}>FENSOR FLASH</a></p>
                  </div>
               </div>
            }
            </section>
            {
               showDetalhes &&
               <React.Fragment>
                  <section className="bloco-principal">
                     <div className="conteudo-bloco-principal bloco-principal-pg-foto"> <div className="bloco-1">
                           <div className="bloco-1-pg-foto">
                              <div className="img-ind-pg-foto">
                                 {
                                    photo.status == 'Aprovado' &&
                                    <img src={dir + photo.name} />
                                 }
                                 {
                                    photo.status !== 'Aprovado' &&
                                    <img src={dir + photo.name.replace('.','_marcadagua.')} />
                                 }
                              </div>
                           </div>
                        </div>
                        <div className="bloco-2">
                           <div className="conteudo-generico">
                              <div className="info-card-pg-foto">
                                 <div className="cont-card-pg-foto">
                                    <div className="titulo-album">
                                       <h4>{photo.description}</h4>
                                    </div>
                                    <div className="tx-card-pg-foto">
                                       <h3>Data:</h3>
                                       <div className="data-album">
                                          <p>{photo.date_formatado}</p>
                                       </div>
                                    </div>
                                    <div className="tx-card-pg-foto">
                                       <h3>Local:</h3>
                                       <div className="local-album">
                                          <p>{photo.local}, {photo.citCity + ' - ' + photo.staStateSigla}</p>
                                       </div>
                                    </div>
                                    <div className="tx-card-pg-foto">
                                       <h3>Por:</h3>
                                       <div className="local-album">
                                          <p>{ (photo.user == null ? 'Fensor' : photo.user) }</p>
                                       </div>
                                    </div>
                                    <div className="tx-card-pg-foto">
                                       <h3><b>Status</b>:</h3>
                                       <div className="local-album">
                                          <p style={{color: (photo.status == 'Aprovado' ? 'green' : 'red')}}><b>{ photo.status }</b></p>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="ad-carrinho-pg-foto">
                                    {
                                       photo.status == 'Aprovado' &&
                                       <a href={dir + photo.name} target="_blank">Download</a>
                                    }
                                    {
                                       photo.status !== 'Aprovado' &&
                                       <a onClick={e => handleShowModal(e)} style={{backgroundColor: 'gainsboro', color: 'gray'}} >Download</a>
                                    }
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="titulo-pg-foto">
                     <div className="cont-titulo-pg-foto" id="cont-titulo-pg-foto-outro">
                        <h3>Outras fotos que você comprou.</h3>
                     </div>
                  </section>
               </React.Fragment>
            }
            
            <section className="albuns-recentes">
               <div className="cont-albuns-recentes">
                  <ul className="album-ind-recentes">
                  {
                     photos.map((item, i) => {
                        return <li className="card-album" key={`item_`+i} onClick={e => showPhoto(item)}>
                                 <div className="img-pg-foto">
                                    {
                                       item.status == 'Aprovado' &&
                                       <img src={dir + item.name} />
                                    }
                                    {
                                       item.status !== 'Aprovado' &&
                                       <img src={dir + item.name.replace('.','_marcadagua.')} />
                                    }
                                 </div>
                              </li>
                     })
                  }
                  </ul>
                  <br />

                  {
                     sendingPhotos && <Loading type="3" />
                  }

                  <div ref={scrollObserve}></div>
                  
                  <br />
               </div>
            </section>

            <Modal show={modal} onHide={handleCloseModal}>
               <Modal.Header>
                  <Modal.Title style={{fontSize: '1.2rem'}}>Atenção!</Modal.Title>
               </Modal.Header>
               <Modal.Body style={{fontWeight: '200'}}>Aguarde a confirmação de pagamento para efetuar o download da foto &#9203;</Modal.Body>
               <Modal.Footer>
                  <Button variant="danger" onClick={handleCloseModal}>Ok, entendi!</Button>
               </Modal.Footer>
            </Modal>
         </main>

         <Footer />
          
         </React.Fragment>
   )
}

export default PhotosAthlete