export function gerarDiasPaginados(ano, mes, diasPorPagina) {
    // Função para adicionar dias a uma data
    const adicionarDias = (data, dias) => {
        const novaData = new Date(data);
        novaData.setDate(novaData.getDate() + dias);
        return novaData;
    };

    const dataInicial = new Date(ano, mes - 1, 1); // Meses começam do 0
    const ultimoDia = new Date(ano, mes, 0).getDate();

    const arrayPaginado = [];
    let paginaAtual = [];

    for (let dia = 1; dia <= ultimoDia; dia++) {
        paginaAtual.push(adicionarDias(dataInicial, dia - 1));

        if (dia % diasPorPagina === 0 || dia === ultimoDia) {
            arrayPaginado.push(paginaAtual);
            paginaAtual = [];
        }
    }

    return arrayPaginado;
}

export const formatarData = (data) =>
    `${data.getDate().toString().padStart(2, '0')}/${(data.getMonth() + 1).toString().padStart(2, '0')}`;
