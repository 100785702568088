import React, { useEffect, useState, useRef } from 'react';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Logo from '../../components/school/Logo';
import Message from '../../components/Message';
import Utils from '../../utils/utils';
import { Link } from 'react-router-dom';
import Api from '../../utils/api';
import { getStudentsEvaluations, deleteStudentsEvaluation } from '../../services/EvaluationService';
import { Trans, useTranslation } from 'react-i18next';
import { sendWhatsAppMessageWithFileAlt } from '../../services/WhatsAppService';
import WhatsAppComponent from '../../components/whatsapp/WhatsAppComponent';
import { getClassSchool } from '../../services/ClassService';

const Evaluation = () => {
  const { t, i18n } = useTranslation();
  const [id, setId] = useState(null);
  const [school, setSchool] = useState({});
  const [countEvaluation, setCountEvaluation] = useState(0);
  const [countStudents, setCountStudents] = useState(0);
  const [countContracts, setCountContracts] = useState(0);
  const [evaluations, setEvaluations] = useState([]);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [process, setProcess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [displayMobile, setDisplayMobile] = useState('none');
  const [displayItemMobile, setDisplayItemMobile] = useState('none');
  const [dirFotoPrincipalStudent, setDirFotoPrincipalStudent] = useState('');
  const [teachers, setTeachers] = useState([]);
  const [filteredEvaluations, setFilteredEvaluations] = useState([]);
  const [modalAcoesData, setModalAcoesData] = useState({});
  const [bodyFatPercentageReferenceTable, setBodyFatPercentageReferenceTable] = useState(null);
  const [imcReferenceTable, setImcReferenceTable] = useState(null);
  const [classes, setClasses] = useState([]);
  const [classId, setClassId] = useState('');
  const [studentName, setStudentName] = useState('');

  //WhatsApp
  const [detail, setDetail] = useState();
  const [user, setUser] = useState();

  const { formatCNPJCPF, formatTelefone, dateFormatText, scrollTop, compareStrings } = Utils();
  const { MessageSuccessMarginTop } = Message();
  const baseURL = Api();
  const refScroll = useRef(null);
  const whatsAppComponentRef = useRef();

  const imgExpand = 'mais';

  useEffect(() => {
    fetchClassBySchoolId();
  }, []);

  const fetchStudentsEvaluations = async (school_id, company_id, classId, name) => {
    setProcess(true);
    const resp = await getStudentsEvaluations({
      schoolId: school_id,
      companyId: company_id,
      classId: classId,
      name: name,
    });
    setProcess(false);

    const sortedEvaluations = resp.students_evaluations.sort((a, b) => compareStrings(a.name, b.name));

    setCountStudents(resp.countStudents);
    setCountEvaluation(resp.countEvaluation);
    setCountContracts(resp.countContracts);
    setDirFotoPrincipalStudent(resp.dirFotoPrincipalStudent);
    setEvaluations(sortedEvaluations);
    setFilteredEvaluations(sortedEvaluations);
    setTeachers(resp.teachers);
    setBodyFatPercentageReferenceTable(resp.bodyFatPercentageReferenceTable);
    setImcReferenceTable(resp.imcReferenceTable);
  };

  const fetchClassBySchoolId = async () => {
    const schoolSession = JSON.parse(localStorage.getItem('school'));
    setSchool(schoolSession);
    const resp = await getClassSchool(schoolSession.id, undefined, true);
    if (resp && resp.classs && resp.classs.length > 0) {
      setClasses(resp.classs);
    }
  };

  const dialogConfirmDelete = (id) => {
    setSuccess(false);
    setDialogDelete(true);
    setId(id);
    scrollTop(refScroll);
  };

  const handleDelete = async () => {
    setDialogDelete(false);
    setProcess(true);

    const resp = await deleteStudentsEvaluation({
      evaluationId: id,
      schoolId: school.id,
      companyId: school.companie_id,
    });

    const sortedEvaluations = resp.data.students_evaluations.sort((a, b) => compareStrings(a.name, b.name));

    setProcess(false);

    setSuccess(true);
    setEvaluations(sortedEvaluations);
    setFilteredEvaluations(sortedEvaluations);
    setCountEvaluation(resp.data.countEvaluation);
  };

  const filterEvaluations = (student) => {
    setStudentName(student);
    return student === ''
      ? setFilteredEvaluations(evaluations)
      : setFilteredEvaluations(
        evaluations
          .filter((s) => s.name.toLowerCase().includes(student.trim().toLowerCase()))
          .sort((a, b) => compareStrings(a.name, b.name))
      );
  };

  const switchModalAcoes = (display, data) => {
    setDisplayMobile(display);
    setModalAcoesData(data);
  };

  const switchModalAcoesInterna = (display, data) => {
    setDisplayItemMobile(display);
    setModalAcoesData(data);
  };

  const checkUserTeacher = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.role_id === 7) return user;
    else return {};
  };

  //WhatsApp
  const openSendWhatsApp = async (item, student) => {
    setDetail(student);
    setUser(item);
    whatsAppComponentRef.current.showModal();
  };

  const sendMessageWhatsApp = async (item, student) => {
    const linkPdf = baseURL + `relatorio-default/pdf/${item.id}/.pdf?language=${i18n.resolvedLanguage}`;
    const message = `*AVALIAÇÃO*\nOlá, ${student.name}. Segue sua avaliação.`;

    const response = await sendWhatsAppMessageWithFileAlt({
      numbersList: [
        i18n.resolvedLanguage === 'pt-BR' ? `55${student.athlete.athPhoneRespFin}` : student.athlete.athPhoneRespFin,
      ],
      mimeType: 'pdf',
      message: message,
      fileLink: linkPdf,
    });

    if (response.success) {
      return { success: true, message: t('WhatsAppComponent.SendSuccessMessage.EvaluationSendSuccessfully') };
    } else {
      return {
        success: false,
        message: (
          <div>
            <b>{t('WhatsAppComponent.SendFailMessage.Fail')}</b>
            <br />
            <b>{student.name}:</b> {t('WhatsAppComponent.SendFailMessage.IncorrectNumber')}
            <br />
          </div>
        ),
      };
    }
  };
  // fim whatsApp

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!classId) return;
    fetchStudentsEvaluations(school.id, school.companie_id, classId, studentName);
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('LessonPages.FilterByClass.Title')}</h2>
                    <p className='card_subtitle'>{t('LessonPages.FilterByClass.Subtitle')}</p>
                  </div>
                  <form className='form_card' onSubmit={handleSubmit}>
                    <div className='form_group'>
                      <label htmlFor='classId' className='card_details_label'>
                        {t('LessonPages.FilterByClass.Label')}
                      </label>
                      <select
                        className='form_control select'
                        name='classId'
                        onChange={(e) => setClassId(e.target.value)}
                        style={{
                          width: '100%',
                          padding: '15px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          color: '#aaa',
                        }}
                        value={classId}
                      >
                        {classes && classes.length > 0 ? (
                          <>
                            <option value=''>Selecionar</option>
                            {classes.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value=''>Buscando classes...</option>
                        )}
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('LessonPages.FilterByClass.Name')}
                      </label>
                      <input
                        placeholder={t('AssessmentPage.InputSearchPlaceholder')}
                        type='text'
                        name='name'
                        value={studentName}
                        style={{
                          width: '100%',
                          padding: '15px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          color: '#aaa',
                          marginTop: '5px',
                        }}
                        onChange={(e) => filterEvaluations(e.target.value)}
                      />
                    </div>
                    <button className='btn_card mx-auto' type='submit'>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z'>
                          {process && (
                            <animateTransform
                              attributeName='transform'
                              attributeType='XML'
                              type='rotate'
                              from='0 12.5 12.5'
                              to='360 12.5 12.5'
                              dur='2s'
                              repeatCount='indefinite'
                            />
                          )}
                        </path>
                      </svg>
                      {t('Search')}
                    </button>
                  </form>
                </div>
                <div className='card'>
                  <div className='img_school'>
                    <Logo />
                  </div>
                  <h2 className='card_title mx-auto'>{school.schName}</h2>
                  <div className='content_card'>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('Localization')}: </p>
                      <p className='card_details_text'>{school.schLocalizacao}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('Email')}: </p>
                      <p className='card_details_text'>{school.schEmail}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('CellPhone')}: </p>
                      <p className='card_details_text'>{formatTelefone(String(school.schPhone))}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('CNPJ')}: </p>
                      <p className='card_details_text'>{formatCNPJCPF(String(school.schCnpj))} </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>{t('RegisteredStudents')}</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>{countStudents}</div>
                    )}
                    <Link to='/student-school' className='btn_card'>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('SeeAll')}
                    </Link>
                  </div>
                  <hr />
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>{t('StudentsEvaluated')}</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>{countEvaluation}</div>
                    )}
                    <Link to='/evaluation-list-school' className='btn_card'>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('SeeAll')}
                    </Link>
                  </div>
                  <hr />
                  <div className='card_header mx-auto' style={{ alignItems: 'center' }}>
                    <h2 className='card_title'>{t('Contracts')}</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>{countContracts}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className='content' ref={refScroll}>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('AssessmentPage.PageTitle')}</h2>
                    <p className='card_subtitle'>
                      {t('AssessmentPage.PageSubtitle')}
                      {process && (
                        <React.Fragment>
                          <Loading type='2' style={{ color: 'green' }} />
                          <Loading type='2' style={{ color: 'green' }} />
                          <Loading type='2' style={{ color: 'green' }} />
                        </React.Fragment>
                      )}
                    </p>
                    {success && (
                      <MessageSuccessMarginTop
                        title={t('Success', { context: 'exclamation' }) + ' '}
                        description={t('SystemMessage.Success.EvaluationDeleted')}
                      />
                    )}
                    {dialogDelete && (
                      <div className='alert alert-margin-top alert-warning alert-dismissible fade show' role='alert'>
                        <Trans i18nKey='AssessmentPage.SystemMessage.DeleteConfirmation'>
                          <strong>a</strong>b
                          <strong style={{ cursor: 'pointer' }} onClick={handleDelete}>
                            c
                          </strong>
                        </Trans>
                      </div>
                    )}
                  </div>
                  <div className='card_avaliacao'>
                    <div className='avaliacao_list'>
                      <div className='avaliacao_escola_table_header'>
                        <span>{t('StudentName')}</span>
                        <span style={{ padding: '15px 5px' }}>{t('Average')}</span>
                        <span>{t('Actions')}</span>
                      </div>
                      {filteredEvaluations.map((student, key) => {
                        return (
                          <React.Fragment key={Math.random()}>
                            <div className='avaliacao_escola_table_row'>
                              <span
                                data-toggle='collapse'
                                data-target={`#avaliacao${key}`}
                                style={{ color: '#747272', boxSizing: 'border-box', background: 'none' }}
                              >
                                <div>
                                  {student.name}
                                  {student.evaluations.length > 0 && (
                                    <img
                                      src={`../../../img/icones/evaluation/${imgExpand}.svg`}
                                      style={{ width: 20 }}
                                      id={key}
                                    />
                                  )}
                                </div>
                              </span>
                              <span>{student.evaluations.length > 0 ? student.avaregeGlobal : 0}</span>
                              <span>
                                <div className='none_mobile'>
                                  <Link
                                    title={t('AssessmentPage.ButtonAddEvaluation')}
                                    to={{
                                      pathname: 'evaluation-form-school',
                                      state: {
                                        id: null,
                                        evaluationDefault: student.club_evaluations,
                                        countStudents,
                                        countEvaluation,
                                        dirFotoPrincipalStudent,
                                        teachers,
                                        teacher: checkUserTeacher(),
                                        position: student.athlete.posPosition,
                                        school,
                                        student,
                                        date: new Date().toISOString().substr(0, 10),
                                        evaluation: {},
                                        bodyFatPercentageReferenceTable,
                                        imcReferenceTable,
                                        action: 'ADD_EVALUATION',
                                      },
                                    }}
                                  >
                                    <img
                                      src='../../../img/icones/evaluation/add.svg'
                                      alt={t('AssessmentPage.ButtonAddEvaluation')}
                                    />
                                  </Link>
                                </div>
                                <div
                                  className='none_desktop'
                                  onClick={() => switchModalAcoes('flex', { student: student })}
                                >
                                  ...
                                </div>
                              </span>
                            </div>
                            {student.evaluations.map((item) => {
                              return (
                                <div className='collapse collapse_avaliacao' id={`avaliacao${key}`} key={Math.random()}>
                                  <div>
                                    <span>
                                      <img src='../../../img/icones/evaluation/ir.svg' />
                                      {dateFormatText(item.created_at.split(' ')[0])}
                                    </span>
                                    <span>{Number(item.average).toFixed(0)}</span>
                                    <span>
                                      <div className='none_mobile'>
                                        <Link
                                          title={t('AssessmentPage.ButtonEditEvaluation')}
                                          to={{
                                            pathname: 'evaluation-form-school',
                                            state: {
                                              id: item.id,
                                              evaluationDefault: {},
                                              countStudents,
                                              countEvaluation,
                                              dirFotoPrincipalStudent,
                                              teachers,
                                              teacher: item.teacher,
                                              position: student.athlete.posPosition,
                                              school,
                                              student,
                                              date: item.created_at.split(' ')[0],
                                              evaluation: item,
                                              bodyFatPercentageReferenceTable,
                                              imcReferenceTable,
                                              action: 'EDIT_EVALUATION',
                                            },
                                          }}
                                        >
                                          <img
                                            src='../../../img/icones/evaluation/edit.svg'
                                            alt={t('AssessmentPage.ButtonEditEvaluation')}
                                          />
                                        </Link>
                                        <img
                                          src='../../../img/icones/evaluation/delete.svg'
                                          alt={t('AssessmentPage.ButtonDeleteEvaluation')}
                                          onClick={() => dialogConfirmDelete(item.id)}
                                          title={t('AssessmentPage.ButtonDeleteEvaluation')}
                                        />
                                        <a
                                          href={
                                            baseURL +
                                            `relatorio-default/pdf/${item.id}/.pdf?language=${i18n.resolvedLanguage}`
                                          }
                                          target='_blank'
                                          rel='noopener noreferrer'
                                          title={t('AssessmentPage.ButtonExportEvaluationPDF')}
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18'
                                            height='18'
                                            fill='white'
                                            viewBox='0 0 16 16'
                                            enableBackground='true'
                                            style={{
                                              backgroundColor: '#2d2926',
                                              width: '24px',
                                              height: '24px',
                                              padding: '4px',
                                              borderRadius: '8px 8px 8px 0px',
                                            }}
                                          >
                                            <path d='M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z' />
                                            <path d='M4 6.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7zm0-3a.5.5 0 0 1 .5-.5H7a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1-.5-.5z' />
                                          </svg>
                                        </a>
                                        <img
                                          title={t('AssessmentPage.ButtonSendEvaluation')}
                                          style={{ width: '18%' }}
                                          src='/img/whatsapp/whatsapp.png'
                                          onClick={() => openSendWhatsApp(item, student)}
                                          alt={t('AssessmentPage.ButtonSendEvaluation')}
                                        />
                                      </div>
                                      <div
                                        className='none_desktop'
                                        onClick={() =>
                                          switchModalAcoesInterna('flex', { student: student, item: item })
                                        }
                                      >
                                        ...
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div id='acoes' style={{ display: displayMobile }} className='modal_acoes'>
                <span onClick={() => switchModalAcoes('none', {})} className='close modal_close'>
                  X
                </span>
                <div className='modal_container_acoes'>
                  {modalAcoesData.student && (
                    <Link
                      to={{
                        pathname: 'evaluation-form-school',
                        state: {
                          id: null,
                          evaluationDefault: modalAcoesData.student.club_evaluations,
                          countStudents,
                          countEvaluation,
                          dirFotoPrincipalStudent,
                          teachers,
                          teacher: {},
                          position: modalAcoesData.student.athlete.posPosition,
                          school,
                          student: modalAcoesData.student,
                          date: new Date().toISOString().substr(0, 10),
                          evaluation: {},
                          bodyFatPercentageReferenceTable,
                          imcReferenceTable,
                          action: 'ADD_EVALUATION',
                        },
                      }}
                    >
                      <img src='../../../img/icones/evaluation/add.svg' alt={t('AssessmentPage.ButtonAddEvaluation')} />
                    </Link>
                  )}
                </div>
              </div>

              <div key='key' id='acoes_interna' style={{ display: displayItemMobile }} className='modal_acoes_interna'>
                <span onClick={() => switchModalAcoesInterna('none', {})} className='close modal_close'>
                  X
                </span>
                <div className='modal_container_acoes' key='acoes'>
                  {modalAcoesData.student && modalAcoesData.item && (
                    <Link
                      to={{
                        pathname: 'evaluation-form-school',
                        state: {
                          id: modalAcoesData.item.id,
                          evaluationDefault: {},
                          countStudents,
                          countEvaluation,
                          dirFotoPrincipalStudent,
                          teachers,
                          teacher: modalAcoesData.item.teacher,
                          position: modalAcoesData.student.athlete.posPosition,
                          school,
                          student: modalAcoesData.student,
                          date: modalAcoesData.item.created_at.split(' ')[0],
                          evaluation: modalAcoesData.item,
                          bodyFatPercentageReferenceTable,
                          imcReferenceTable,
                          action: 'EDIT_EVALUATION',
                        },
                      }}
                    >
                      <img
                        src='../../../img/icones/evaluation/edit.svg'
                        alt={t('AssessmentPage.ButtonEditEvaluation')}
                      />
                    </Link>
                  )}
                  {modalAcoesData.item && (
                    <img
                      src='../../../img/icones/evaluation/delete.svg'
                      alt={t('AssessmentPage.ButtonDeleteEvaluation')}
                      onClick={() => dialogConfirmDelete(modalAcoesData.item.id)}
                    />
                  )}
                  {modalAcoesData.item && (
                    <a
                      href={
                        baseURL +
                        `relatorio-default/pdf/${modalAcoesData.item.id}/.pdf?language=${i18n.resolvedLanguage}`
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        fill='white'
                        viewBox='0 0 16 16'
                        enableBackground='true'
                        style={{
                          backgroundColor: '#2d2926',
                          width: '24px',
                          height: '24px',
                          padding: '4px',
                          margin: '0px 7px',
                          borderRadius: '8px 8px 8px 0px',
                        }}
                      >
                        <path d='M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z' />
                        <path d='M4 6.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7zm0-3a.5.5 0 0 1 .5-.5H7a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1-.5-.5z' />
                      </svg>
                    </a>
                  )}

                  {modalAcoesData.item && (
                    <img
                      style={{ width: '10%' }}
                      src='/img/whatsapp/whatsapp.png'
                      onClick={() => openSendWhatsApp(modalAcoesData.item, modalAcoesData.student)}
                      alt={t('AssessmentPage.ButtonSendEvaluation')}
                    />
                  )}
                </div>
              </div>

              <WhatsAppComponent
                ref={whatsAppComponentRef}
                sendMessageWhatsApp={() => sendMessageWhatsApp(user, detail)}
                onClose={() => {
                  setDetail({});
                  setUser({});
                }}
                sendButtonText={t('WhatsAppComponent.SendAssessmentButton')}
              >
                {detail && (
                  <>
                    <div className='img_list_user'>
                      <img src={dirFotoPrincipalStudent + '/' + detail.usuFotoPrincipal} alt='' />
                    </div>
                    <h3 className='whatsapp-title-contrato'>
                      {t('Assessment')}: <br /> <b>{detail.name}</b>
                    </h3>
                  </>
                )}
              </WhatsAppComponent>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Evaluation;
