import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, Modifier, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const CustomSelect = ({ editorState, onChange, visible = true }) => {
    const [open, setOpen] = useState(false);


    const openPlaceholderDropdown = () => setOpen(!open)

    const addPlaceholder = (placeholder) => {
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            placeholder,
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    }

    const placeholderOptions = [
        { key: "nomeAluno", value: "{{nomeAluno}}", text: "Nome do aluno" },
        { key: "idadeAluno", value: "{{idadeAluno}}", text: "Idade do aluno" },
        { key: "dataNascimentoAluno", value: "{{dataNascimentoAluno}}", text: "Data de nascimento do aluno" },
        { key: "categoriaAluno", value: "{{categoriaAluno}}", text: "Turma do aluno" },
        { key: "cpfAluno", value: "{{cpfAluno}}", text: "CPF do aluno" },
        { key: "rgAluno", value: "{{rgAluno}}", text: "RG do aluno" },
        { key: "telefoneAluno", value: "{{telefoneAluno}}", text: "Telefone do aluno" },
        { key: "emailAluno", value: "{{emailAluno}}", text: "Email do aluno" },
        { key: "turmaDiasSemana", value: "{{turmaDiasSemana}}", text: "Dias de treino" },
        { key: "turmaHorarios", value: "{{turmaHorarios}}", text: "Horarios de treino" },
        { key: "enderecoAluno", value: "{{enderecoAluno}}", text: "Endereço do aluno" },
        { key: "cepAluno", value: "{{cepAluno}}", text: "Cep do Aluno" },
        { key: "ruaAluno", value: "{{ruaAluno}}", text: "Rua do Aluno" },
        { key: "numeroAluno", value: "{{numeroAluno}}", text: "Numero endereço do aluno" },
        { key: "bairroAluno", value: "{{bairroAluno}}", text: "Bairro do aluno" },
        { key: "cidadeAluno", value: "{{cidadeAluno}}", text: "Cidade do aluno" },
        { key: "ufAluno", value: "{{ufAluno}}", text: "UF do aluno" },
        { key: "nomeContratante", value: "{{nomeContratante}}", text: "Nome do contrate/responsavel" },
        { key: "emailContratante", value: "{{emailContratante}}", text: "Email do contratante/responsavel" },
        { key: "telefoneContratante", value: "{{telefoneContratante}}", text: "Telefone do contratante/responsavel" },
        { key: "cpfCnpjContratante", value: "{{cpfCnpjContratante}}", text: "CPF/CNPJ do contratante/responsavel" },
        { key: "rgContratante", value: "{{rgContratante}}", text: "RG do contratante/responsavel" },
        { key: "dataAssinaturaContrato", value: "{{dataAssinaturaContrato}}", text: "Data de assinatura" },
        { key: "mensalidade", value: "{{mensalidade}}", text: "Mensalidade" },
        { key: "valorDesconto", value: "{{valorDesconto}}", text: "Desconto" },
        { key: "multaValor", value: "{{multaValor}}", text: "Multa" },
        { key: "jurosValor", value: "{{jurosValor}}", text: "Juros" },
        { key: "DataInicioContrato", value: "{{dataInicioContratoAlt}}", text: "Data de inicio do contrato" },
        { key: "DataFimContrato", value: "{{dataFimContratoAlt}}", text: "Data final do contrato" },
        { key: "dataPrimeiroFaturamento", value: "{{dataPrimeiroVencimento}}", text: "Data primeiro faturamento" },
        { key: "jurosValor", value: "{{jurosValor}}", text: "Juros" },
        { key: "cargaHorariaContrato", value: "{{cargaHorariaContrato}}", text: "Carga horaria" },
        { key: "cicloPagamentoFormatado", value: "{{cicloPagamentoFormatado}}", text: "Ciclo de pagamento" },
        { key: "indicacaoAluno", value: "{{indicacaoAluno}}", text: "Indicação do Aluno" },
        { key: "observacaoContrato", value: "{{observacaoContrato}}", text: "Observação do Contrato" },
    ]

    const listItem = placeholderOptions.map(item => (
        <li
            onClick={addPlaceholder.bind(item, item.value)}
            key={item.key}
            className="rdw-dropdownoption-default placeholder-li"
        >
            {item.text}
        </li>
    ))

    return (
        <>
            { visible &&
            <div onClick={openPlaceholderDropdown} className="rdw-block-wrapper" aria-label="rdw-block-control">
                <div className="rdw-dropdown-wrapper rdw-block-dropdown" aria-label="rdw-dropdown" style={{ width: 300 }}>
                    <div className="rdw-dropdown-selectedtext" title="Placeholders" style={{ width: 300 }}>
                        <span>Adicionar Opções</span>
                        <div className={`rdw-dropdown-caretto${open ? "close" : "open"}`}></div>
                    </div>
                    <ul className={`rdw-dropdown-optionwrapper ${open ? "" : "placeholder-ul"}`} style={{ width: 300 }}>
                        {listItem}
                    </ul>
                </div>
            </div>
            }
        </>
    );
};

const CKEditorDocument = ({ initialValue, handleEditorData, visible }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        setEditorState(startWithContent());
    }, []);

    useEffect(()=>{
        handleEditorData(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    },[editorState])


    const startWithContent = () => {
        const contentBlock = htmlToDraft(initialValue);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock);
            return EditorState.createWithContent(contentState);
        }
    }

    const toolbarConfig = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded'],
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript']
        },
        blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote']
        },
        fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48, 60, 72]
        },
        fontFamily: {
            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']
        },
        list: {
            options: ['unordered', 'ordered']
        },
        textAlign: {
            options: ['left', 'center', 'right', 'justify']
        },
        colorPicker: {
            colors: ['black', 'red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet', 'white']
        },
        link: {
            defaultTargetOption: '_blank',
            showOpenOptionOnHover: true
        }
    };

    return (
        <div>
            <Editor
                wrapperClassName="my-editor-wrapper"
                editorClassName="my-editor"
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbarCustomButtons={[<CustomSelect
                    editorState={editorState}
                    onChange={setEditorState}
                    visible={visible}
                />]}
                toolbar={toolbarConfig}
            />
        </div>
    );
};

export default CKEditorDocument;