import React, { useEffect, useState, useRef } from 'react'
import Header from '../../components/athlete/Header'
import Footer from '../../components/athlete/Footer'
import Loading from '../../components/Loading'
import Message from '../../components/Message'
import Api from '../../utils/api'
import axios from 'axios'

const Photos = (props) => {
   // ref
   const scrollObserve = useRef();

   // set loading
   const [sendingPhotos, setSendingPhotos] = useState(false)

   // set messages
   const [message, setMessage] = useState(false)

   // set data
   const [showDetalhes, setShowDetalhes] = useState(false)
   const [photoalbum, setPhotoalbum] = useState([])
   const [photos, setPhotos] = useState([])
   const [photo, setPhoto] = useState([])
   const [priceType, setPriceType] = useState({})
   const [pricePhotographer, setPricePhotographer] = useState({})
   const [shoppingCart, setShoppingCart] = useState([])
   const [dir, setDir] = useState("")
   const [page, setPage] = useState(0)
   const [scrollRadio, setScrollRadio] = useState(null)

   // outras variaveis
   const { MessageSuccess } = Message()
   const baseURL    = Api()
   let userLogado   = localStorage.getItem('user')
   let shopping = localStorage.getItem('shoppingCart')

   if ( userLogado !== null ) {
      userLogado = JSON.parse(userLogado)
   }

   if ( shopping !== null ) {
      shopping = JSON.parse(shopping)
   }

   const getPhotos = async (novaPage) => {
      setSendingPhotos(true)
      const res = await axios.post(baseURL + 'get-photoalbum-photos-pagination',{
         id: props.match.params.id,
         start: novaPage,
         length: 20
      })
      setSendingPhotos(false)
      setDir(res.data.urlPhotos)

      // monta a album
      res.data.photoalbum.preco = getPrice(res.data.price_type, res.data.price_photographer)
      res.data.photoalbum.url   = res.data.urlPhotos
      setPhotoalbum(res.data.photoalbum)

      // monta as photos
      const novasPhotos = [...photos]
      novasPhotos.push(...res.data.photos)
      setPhotos(novasPhotos)

      // guarda a tabela de preco no momento da compra
      setPriceType(res.data.price_type)
      setPricePhotographer(res.data.price_photographer)
   }

   const getPrice = (price_type, price_photographer) => {
      if ( price_photographer.length > 0 ) {
         return price_photographer[0].ate1
      } else {
         return price_type.ate1
      }
   }

   const showPhoto = (detalhes) => {
      window.scrollTo(0, 250)
      setShowDetalhes(true)

      detalhes.photoalbum        = photoalbum
      detalhes.priceType         = priceType
      detalhes.pricePhotographer = pricePhotographer
      setPhoto(detalhes)
   }

   const addShoppingCart = () => {
      const novoItem = shoppingCart
      novoItem.push(photo)
      setShoppingCart(novoItem)
      localStorage.setItem('shoppingCart', JSON.stringify(novoItem))

      setShowDetalhes(false)
      setMessage(true)
      setTimeout(() => {
         setMessage(false)
      },3000)
   }

   const intersectionObserver = new IntersectionObserver( entries => {
      const radio = entries[0].intersectionRatio
      setScrollRadio(radio)
   })

   useEffect(() => {
      getPhotos(page)
      
      if ( shopping !== null )
         setShoppingCart(shopping)

      intersectionObserver.observe(scrollObserve.current)

      return () => {
         intersectionObserver.disconnect()
      }

   },[])

   useEffect(() => {

      if ( scrollRadio > 0 && photos.length > 0) {
         const novaPage = page + 10
         setPage(novaPage)
         getPhotos(novaPage)
      }

   },[scrollRadio])

   return (
         <React.Fragment>
            
         {
            userLogado !== null && 
            <Header type="3" menu={true} />
         }
         {
            userLogado === null &&
            <Header type="3" menu={false} />
         }
         <main>
            <section className="titulo-pg-foto">
               <div className="cont-titulo-pg-foto">
                  <h3>Àlbum</h3>
                  {
                     !sendingPhotos &&
                     <div className="desc-titulo-pg-foto" id="deletar-antes-filtro">
                        <p>{photoalbum.description}, {photoalbum.date_formatado}, {photoalbum.citCity + ', ' + photoalbum.staStateSigla}</p>
                     </div>
                  }
                  {
                     sendingPhotos &&
                     <div className="desc-titulo-pg-foto" id="deletar-antes-filtro">
                        <p>Carregando...</p>
                     </div>
                  }
                  
               </div>
               
            </section>
            {
               showDetalhes &&
               <React.Fragment>
                  <section className="bloco-principal">
                     <div className="conteudo-bloco-principal bloco-principal-pg-foto">
                        <div className="bloco-1">
                           <div className="bloco-1-pg-foto">
                              <div className="img-ind-pg-foto">
                                 <img src={dir + photo.name.replace('.','_marcadagua.')} />
                              </div>
                           </div>
                        </div>
                        <div className="bloco-2">
                           <div className="conteudo-generico">
                              <div className="info-card-pg-foto">
                                 <div className="cont-card-pg-foto">
                                    <div className="titulo-album">
                                       <h4>{photoalbum.description}</h4>
                                    </div>
                                    <div className="tx-card-pg-foto">
                                       <h3>Data:</h3>
                                       <div className="data-album">
                                          <p>{photoalbum.date_formatado}</p>
                                       </div>
                                    </div>
                                    <div className="tx-card-pg-foto">
                                       <h3>Local:</h3>
                                       <div className="local-album">
                                          <p>{photoalbum.local}, {photoalbum.citCity + ' - ' + photoalbum.staStateSigla}</p>
                                       </div>
                                    </div>
                                    <div className="tx-card-pg-foto">
                                       <h3>Por:</h3>
                                       <div className="local-album">
                                          <p>{ (photoalbum.user == null ? 'Fensor' : photoalbum.user) }</p>
                                       </div>
                                    </div>
                                    <div className="preco-card-pg-foto">
                                       <p>{Intl.NumberFormat('pt-BR',{ style: 'currency', currency: 'BRL' }).format(photoalbum.preco)}</p>
                                    </div>
                                 </div>
                                 <div className="ad-carrinho-pg-foto">
                                    <a href="#" onClick={addShoppingCart}>Adicionar ao carrinho</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="titulo-pg-foto">
                     <div className="cont-titulo-pg-foto" id="cont-titulo-pg-foto-outro">
                        <h3>Outras fotos do álbum</h3>
                        <div className="desc-titulo-pg-foto" id="deletar-antes-filtro">
                           <p>{photoalbum.description}, {photoalbum.date_formatado}, {photoalbum.citCity + ', ' + photoalbum.staStateSigla}</p>
                        </div>
                     </div>
                  </section>
               </React.Fragment>
            }
            
            <section className="albuns-recentes">
               <div className="cont-albuns-recentes">
                  {
                     message !== false && <span><MessageSuccess title="Parabéns! " description="Foto adicionada ao seu carrinho!" /></span>
                  }
                  <ul className="album-ind-recentes">
                 
                  {
                     photos.map((item, i) => {
                        return <li className="card-album" key={`item_`+i} onClick={e => showPhoto(item)}>
                                 <div className="img-pg-foto">
                                    <img src={dir + '/' + item.name.replace('.','_marcadagua.')} />
                                 </div>
                              </li>
                     })
                  }
                  </ul>
                  <br />

                  {
                     sendingPhotos && <Loading type="3" />
                  }

                  <div ref={scrollObserve}></div>
                  
                  <br />
               </div>
            </section>
         </main>

         <Footer />
          
         </React.Fragment>
   )
}

export default Photos