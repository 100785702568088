import React, { useState, useEffect } from 'react'
import Loading from '../../components/Loading'
import { Link , Redirect} from 'react-router-dom'
import Api from '../../utils/api'
import utils from '../../utils/utils'
import axios from 'axios'

const Seletivas = (props) => {
   const [logo, setLogo] = useState("")
   const [seletivas, setSeletivas] = useState([])
   const [goDetails, setGoDetails] = useState(false)
   const [event, setEvent] = useState({})
   const [inscrito, setInscrito] = useState(false)
   const [sendingSeletivas, setSeletivasSending] = useState(false)
   const baseURL = Api()
   const { replaceAll } = utils()

   let user_id = ''
   let athlete_id = ''

   useEffect(() => {

      const user = JSON.parse(localStorage.getItem('user'))

      if ( user !== null ) {
         user_id = user.id
         
         const athlete = JSON.parse(localStorage.getItem('athlete'))
         athlete_id = athlete.id
      }
      
      getSeletivas()
   },[props.data])
   
   const getSeletivas = async () => {
      setSeletivas([])
      setSeletivasSending(true)
        
      const res = await axios.post(baseURL + 'get-events',{
         "user_id": user_id,
         "eveCobrarInscricao": "N",
         "limit": props.limit,
         "search": props.data ? props.data.search : '',
         "companie_id": props.data ? props.data.companie_id : '',
         "state_id": props.data ? props.data.state_id : '',
         "city_id": props.data ? props.data.city_id : '',
         "myFuture": props.myFuture
      })
      
      if ( res.data.error === false ){
         setLogo(res.data.urlLogo)
         setSeletivas(res.data.data)
      }  

      setSeletivasSending(false)
   }

   const details = (e, event, inscrito) => {
      e.preventDefault(e)
      setEvent(event)
      setGoDetails(true)
      setInscrito(inscrito)
   }

   if(goDetails)
      return <Redirect to={{
         pathname: '/detalhes-seletiva-evento',
         state: {
            event: event, 
            url: logo,
            inscrito: inscrito
         }
      }} />
      
   return (
      <React.Fragment>
         <div className="seletivas seletivas-pg" style={{background: 'white'}}>
            <div className="titulo-seletivas titulo-seletivas-pg">
               <h2>{props.title}</h2>
               <p>{props.description}</p>
            </div>

            <ul className="datas-seletivas">
               {
                  sendingSeletivas && <Loading type="1" />
               }
               {
               
                  seletivas.length == 0 && 
                  sendingSeletivas === false &&
                  <p>Nenhuma seletiva encontrada.</p> 
               }
               {
                  seletivas.map((item, e) => {
                     return (
                        <li className="informacoes-seletivas" key={item.data_hora}>
                           <div className="coluna-1-seletivas">
                              <img src={`../../img/index/imagem-6.png`} />
                           </div>

                           <div className="coluna-2-seletivas">
                              <img className="img-radius-club" src="../../../favicon.png"/>
                           </div>

                           <div className="coluna-3-seletivas">
                              <div className="info-col-3-sel">
                                    <p><b>Clube:</b></p>

                                    <div className="clube-col-3">
                                       <p>{item.comCompanie}</p>
                                    </div>
                                 </div>

                                 <div className="info-col-3-sel">
                                    <p><b>Local:</b></p>

                                    <div className="local-col-3">
                                       <p>{item.locLocal}</p>
                                    </div>
                                 </div>

                                 <div className="info-col-3-sel">
                                    <p><b>Categorias:</b> {replaceAll(item.categorias, ' - ', ', ')}</p>
                                 </div>
                           </div>

                           {
                              props.verDetalhes &&
                              <div className="coluna-4-seletivas ver-detalhes">
                                 <a href="#" onClick={(e) => details(e, item, true)}>Ver detalhes</a>
                              </div>
                           }
                           {
                              !props.verDetalhes &&
                              <div className="coluna-4-seletivas">
                                 <a href="#" onClick={(e) => details(e, item, false)}>Se inscrever</a>
                              </div>
                           }
                        </li>
                     )
                  })
               }
            </ul>
            
            {
               props.verTodos &&
               <div className="botao-seletivas">
                  <Link to="/seletivas-athlete">Ver todas</Link>
               </div>
            }
            
         </div>
         {
            props.carregarMais &&
            <div className="carregar-mais-seletivas">
               <a href="#">Carregar mais</a>
            </div>
         }
      </React.Fragment>
   )
}

export default Seletivas