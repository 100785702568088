import React, { useEffect, useState } from 'react'
import Header from '../../components/athlete/Header'
import Footer from '../../components/athlete/Footer'
import { Modal, Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom'
import utils from '../../utils/utils'

const SeuCarrinho = (props) => {
   // estados
   const [photos, setPhotos] = useState([])
   const [item, setItem] = useState({})
   const [show, setShow] = useState(false)
   const [priceFinal, setPriceFinal] = useState('0.00')
   const [priceCheio, setPriceCheio] = useState('0.00')
   const [data, setData] = useState({})
   
   // outras variaveis
   const { dateFormat }     = utils()
   const shoppingCart       = localStorage.getItem('shoppingCart') 
   
   const calcPrice = (result) => {
      let qtdPhotos = result.length
      let arrFiltrado = []

      for(let i=0; i < qtdPhotos; i++) {
         if ( result[i].photoalbum.user_id !== null )
            arrFiltrado[result[i].photoalbum.user_id] = result.filter( e => e.photoalbum.user_id === result[i].photoalbum.user_id )
         else
            arrFiltrado['fensor'] = result.filter( e => e.photoalbum.user_id === result[i].photoalbum.user_id )
      }

      let priceCheio = 0
      let value = 0
      let arrKeys = Object.values(arrFiltrado)
      for(let i=0; i < arrKeys.length; i++) {
         let arrNewKeys = arrKeys[i]
         for(let y=0; y < arrNewKeys.length; y++) {

            let priceType = arrNewKeys[y].priceType
            let pricePhotographer = arrNewKeys[y].pricePhotographer

            if ( pricePhotographer.length > 0 ) {
               priceCheio += parseFloat(pricePhotographer[0].ate1)
               if ( qtdPhotos < 2 )
                  value += parseFloat(pricePhotographer[0].ate1)
               else if ( qtdPhotos < 4 )
                  value += parseFloat(pricePhotographer[0].de2a3)
               else if ( qtdPhotos < 6 )
                  value += parseFloat(pricePhotographer[0].de4a5)
               else if ( qtdPhotos < 11 )
                  value += parseFloat(pricePhotographer[0].de6a10)
               else 
                  value += parseFloat(pricePhotographer[0].acima10)
            } else {
               priceCheio += parseFloat(priceType.ate1)
               if ( qtdPhotos < 2 )
                  value += parseFloat(priceType.ate1)
               else if ( qtdPhotos < 4 )
                  value += parseFloat(priceType.de2a3)
               else if ( qtdPhotos < 6 )
                  value += parseFloat(priceType.de4a5)
               else if ( qtdPhotos < 11 )
                  value += parseFloat(priceType.de6a10)
               else 
                  value += parseFloat(priceType.acima10)
            }
         }
      }
      
      setPriceCheio(priceCheio.toFixed(2))
      setPriceFinal(value.toFixed(2))
   }

   const handleClose = () => setShow(false)
   const handleShow = () => setShow(true)

   const handleItem = (item) => {
      setItem(item)
      handleShow()
   }

   const handleDelete = () => {
      const novasPhotos = photos.filter( e => e.id !== item.id )
      setPhotos(novasPhotos)
      localStorage.setItem('shoppingCart', JSON.stringify(novasPhotos))
      calcPrice(novasPhotos)
      handleClose()
   }

   const handleEfetuarPagamento = () => {
      let arr = {
         priceFinal,
         photos
      }

      setData(arr)
   }

   useEffect(() => {
      let resp = JSON.parse(shoppingCart)
      if ( resp !== null ) {
         setPhotos(resp)
         calcPrice(resp)
      }
   },[])  

   if ( Object.keys(data).length > 0 )
      return <Redirect to={{
         pathname: '/pagamento-fotos',
         state: data
      }} />

   return (
         <React.Fragment>
            
         <Header type="4" menu={true} />
         
         <main>
            <section className="bloco-principal">
               <div className="conteudo-bloco-principal bloco-principal-carrinho">
                  <div className="bloco-1">
                     <div className="bloco-pg-carrinho">
                        <div className="titulo-seletivas titulo-carrinho">
                           <h2>Seu carrinho</h2>
                           <p>Veja abaixo as próximas seletivas encontradas.</p>
                        </div>
                        <ul className="cont-carrinho">
                           {
                              photos.map((item, i) => {
                                 return (
                                    <li className="produto-carrinho" key={`shop` + i}>
                                       <div className="desc-produto-carrinho">
                                          <div className="cont-desc-produto-carrinho">
                                             <div className="evento-produto-carrinho">
                                                <p>{item.photoalbum.description}</p>
                                             </div>
                                             <div className="info-produto-carrinho">
                                                <h3>Data:</h3>
                                                <div className="data-produto-carrinho">
                                                   <p>{dateFormat(item.created_at)}</p>
                                                </div>
                                             </div>
                                             <div className="preco-carrinho">
                                                <p>{Intl.NumberFormat('pt-BR',{ style: 'currency', currency: 'BRL' }).format(item.photoalbum.preco)}</p>
                                             </div>
                                          </div>
                                          <div className="exit-produto-carrinho cursor" title="Excluir" alt="Excluir" data-toggle="modal" data-target="#exampleModal" onClick={e => handleItem(item)}>
                                             <img src="../../img/icones/exit.png"/>
                                          </div>
                                       </div>
                                       <div className="foto-produto-carrinho">
                                          <img src={ item.photoalbum.url + item.name.replace('.','_marcadagua.') } />
                                       </div>
                                    </li>
                                 )
                              })
                           }
                        </ul>
                     </div>
                  </div>
                  <div className="bloco-2">
                     <div className="conteudo-generico">
                        <div className="info-card-pg-foto">
                           <div className="cont-card-pg-foto">
                              <div className="titulo-preco-carrinho">
                                 <p>Valor total do carrinho</p>
                              </div>
                              <div className="preco-card-pg-foto">
                                 {
                                    priceCheio == priceFinal &&
                                    <p>
                                       {Intl.NumberFormat('pt-BR',{ style: 'currency', currency: 'BRL' }).format(priceCheio)}
                                    </p>
                                 }
                                 {
                                    priceCheio !== priceFinal && 
                                    <React.Fragment>
                                       <p style={{textDecoration: 'line-through', color: 'red'}}>
                                          {Intl.NumberFormat('pt-BR',{ style: 'currency', currency: 'BRL' }).format(priceCheio)}
                                       </p>
                                       <p>
                                          {Intl.NumberFormat('pt-BR',{ style: 'currency', currency: 'BRL' }).format(priceFinal)} 
                                          <span style={{fontSize: 14, fontWeight: 500}}> (com desconto)</span>
                                       </p>
                                    </React.Fragment>
                                 }
                                 
                              </div>
                           </div>
                           {
                              priceFinal > 0 && 
                              <div className="ad-carrinho-pg-foto pagar-pg-carrinho">
                                 <a href="" onClick={handleEfetuarPagamento}>Efetuar o pagamento</a>
                              </div>
                           }
                           
                           <div className="ad-carrinho-pg-foto pagar-pg-carrinho">
                              <a href="/photosalbum" style={{backgroundColor: 'gainsboro'}}>voltar para galeria</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>

            <Modal show={show} onHide={handleClose}>
               <Modal.Header closeButton>
                  <Modal.Title>Exclusão</Modal.Title>
               </Modal.Header>
               <Modal.Body>Deseja realmente excluir este item?</Modal.Body>
               <Modal.Footer>
                  <Button variant="danger" onClick={handleClose}>Não</Button>
                  <Button variant="light" onClick={handleDelete}>Confirmar exclusão</Button>
               </Modal.Footer>
            </Modal>

         </main>

         <Footer />
          
         </React.Fragment>
   )
}

export default SeuCarrinho