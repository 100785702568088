import React, { useState, useRef, useEffect, useContext } from 'react';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import { useTranslation } from 'react-i18next';
import Message from '../../components/Message';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import Loading from '../../components/Loading';
import { useReactToPrint } from 'react-to-print';
import { Link, useHistory } from 'react-router-dom';
import { handleOnChange } from '../../utils/utils';
import { BsChevronDown, BsDownload, BsEye, BsFillPencilFill } from "react-icons/bs";
import EditMicrocycle from './EditMicrocycle';
import { UserContext } from '../../Context/UserContext';
import { createAdminMicrocycle, getMicrocycleByAdminId, updateAdminMicrocycle, updateStatusAdminMicrocycle } from '../../services/MicrocycleService';
import { BiSolidCopy } from 'react-icons/bi';

const AdminMicrocycle = () => {
  const { t } = useTranslation();
  const { action, setContextData } = useContext(UserContext);
  const history = useHistory();

  const [filters, setFilters] = useState({
    name: '',
    source: 'M',
    status: 'A',
  });
  const [microcycles, setMicrocycles] = useState([])
  const [microcyclesFiltered, setMicrocyclesFiltered] = useState([])
  const [microcycle, setMicrocycle] = useState({
    id: undefined,
    name: '',
    status: 'A',
    classNumber: '',
    classQuantity: 0,
    objective: '',
  });
  const [dropDownStatus, setDropDownStatus] = useState([]);
  const [onlyView, setOnlyView] = useState(false)

  // process
  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  // libraries
  const editMicrocycleRef = useRef();
  const microcyclePdfRef = useRef();
  const alertRef = useRef();

  const ref = useOutsideClick(() => {
    setDropDownStatus(dropDownStatus?.map((value) => ({ ...value, status: false })));
  });

  const { MessageDangerMarginTop } = Message();

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      editMicrocycleRef.current.closeModal();
    }
  };

  const fetchMicrocycles = async () => {
    clearMessage();
    setIsLoading(true);
    const { status, microcycles, messageKey } = await getMicrocycleByAdminId(1);
    setIsLoading(false);
    if (status === 'SUCCESS') {
      setMicrocycles(microcycles);
      setMicrocyclesFiltered(microcycles);
      setDropDownStatus(microcycles?.map((microcycle) => ({ id: microcycle.id, status: false })));
      return microcycles;
    } else {
      setErrorMessage(messageKey);
    }
  };

  const handleSubmit = async (data) => {
    clearMessage();
    setIsLoading(true);

    const {
      messageKey,
      status,
      microcycles,
    } = data.id ? await updateAdminMicrocycle(data) : await createAdminMicrocycle(data);
    setIsLoading(false);

    if (status === 'SUCCESS') {
      setMicrocycles(microcycles);
      setMicrocyclesFiltered(microcycles);
      return {
        messageKey,
        status,
      };
    } else {
      return {
        messageKey,
        status,
      };
    }
  };

  const config = (value, copy) => {
    const classes = []
    const aux = value?.days?.length ? value?.days?.forEach(day => {
      return day?.lessons?.filter((lesson) => lesson.admin_id)?.forEach(lesson => {
        return classes.push({
          ...lesson,
          day: day?.day,
          key: `${day?.day}-${lesson.id}`

        })
      })
    }) : [];
    setMicrocycle({
      id: copy ? undefined : value.id,
      name: value.name,
      status: value.status,
      objective: value.objective,
      classNumber: value.classNumber,
      classQuantity: value.classQuantity,
      days: value?.days?.map((day) => ({ day: day?.day })),
      classes
    });
  }

  const openEdit = (value, view = false, copy = false) => {
    config(value, copy);
    setOnlyView(view);
    editMicrocycleRef.current.showModal();
  };

  const openAdd = () => {
    setMicrocycle({
      id: undefined,
      name: '',
      status: 'A',
      classNumber: '',
      classQuantity: 0,
      objective: '',
    });
    editMicrocycleRef.current.showModal();
  };

  const setErrorMessage = (message) => {
    setMessage(message);
    setError(true);
  };

  const clearMessage = () => {
    setError(false);
    setMessage('');
  };

  const toPdf = (value) => {
    Promise.resolve(
      config(value, false)
    ).then(() => {
      handlePrint();
    })
  }

  const handlePrint = useReactToPrint({
    content: () => microcyclePdfRef.current
  })

  const updateByStatus = async (values) => {
    const data = {
      id: values.id,
      status: values.status,
    }

    const {
      status,
      microcycles,
      message,
    } = await updateStatusAdminMicrocycle(data);

    if (status === 'SUCCESS') {
      setMicrocycles(microcycles);
      setMicrocyclesFiltered(microcycles);
      setDropDownStatus(microcycles?.map((microcycle) => ({ id: microcycle.id, status: false })));
    } else {
      setErrorMessage(message);
    }
  }

  const search = (e) => {
    e && e.preventDefault();

    if (filters.source === 'T' || filters.source === 'A') {
      setContextData({
        action: {
          action: 'search',
          term: filters.name,
          status: filters.status
        }
      })
      filters.source === 'T' ? history.push('/admin-lesson', { replace: true }) : history.push('/admin-planning', { replace: true });
      return;
    }

    setMicrocyclesFiltered(
      microcycles.filter((microcycle) => filters.status === 'T' || microcycle.status === filters.status)
        .filter((microcycle) => microcycle.name?.toUpperCase()?.includes(filters.name.toUpperCase())));
  };

  const onChangeFilters = handleOnChange(setFilters);

  useEffect(() => {
    if (action?.action === 'search') {
      setFilters({
        name: action.term,
        status: action.status
      });
      fetchMicrocycles().then((response) => {
        setMicrocyclesFiltered(
          response?.filter((microcycle) => action?.status === 'T' || microcycle.status === action?.status)
            .filter((microcycle) => microcycle.name?.toUpperCase()?.includes(action?.term?.toUpperCase())));
      }).catch((e) => console.log(e));
    } else {
      fetchMicrocycles();
    }
    action?.action === 'openAdd' && openAdd();
    setContextData({ action: {} });

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);


  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <Header />
            <main className='content_panel'>
              <div className='d-flex flex-row flex-1'>
                <div className='d-flex flex-column card mr-3'>
                  <div className='card_header'>
                    <h2 className='card_title'>Tarefas</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar tarefa.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'admin-lesson'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={() => {
                      setContextData({
                        action: {
                          action: 'openAdd'
                        }
                      })
                      history.push('/admin-lesson', { replace: true });
                    }} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-column card mr-3'>
                  <div className='card_header'>
                    <h2 className='card_title'>Aulas</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar uma aula.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'admin-planning'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={() => {
                      setContextData({
                        action: {
                          action: 'openAdd'
                        }
                      })
                      history.push('/admin-planning', { replace: true });
                    }} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-column card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Microciclo</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar um microciclo.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'admin-microcycle'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={openAdd} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card_header'>
                  <h2 className='card_title'>Pesquisar</h2>
                  <p className='card_subtitle'>Busque abaixo por tarefas, aulas ou microciclos.</p>
                </div>
                <form action='' method='post' className='form_card'>
                  <div className='d-flex flex-row align-items-center row'>
                    <div className='col-sm-3'>
                      <input
                        required
                        type='text'
                        className='form_control mr-3'
                        name='name'
                        placeholder='Nome'
                        value={filters.name}
                        onChange={onChangeFilters}
                      />
                    </div>
                    <div className='col-sm-3'>
                      <select
                        required
                        className='form_control select mr-3'
                        name='source'
                        value={filters.source}
                        onChange={onChangeFilters}
                      >
                        <option disabled={true}>Selecionar</option>
                        <option value='T'>Tarefas</option>
                        <option value='A'>Aulas</option>
                        <option value='M'>Microciclo</option>
                      </select>
                    </div>
                    <div className='col-sm-3'>
                      <select
                        required
                        className='form_control select mr-3'
                        name='status'
                        value={filters.status}
                        onChange={onChangeFilters}
                      >
                        <option disabled={true}>Status</option>
                        <option value='T'>Tudo</option>
                        <option value='A'>Ativo</option>
                        <option value='I'>Inativo</option>
                      </select>
                    </div>
                    <div className='col-sm-3 my-auto'>
                      <button className='btn-secondary-color rounded-pill' onClick={search}>
                        Buscar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Microciclos</h2>
                    <p className='card_subtitle'>Veja abaixo os microciclos cadastradas</p>
                    {error && <MessageDangerMarginTop ref={alertRef} title='Atenção! ' description={t(message)} />}
                  </div>
                  <div className='list_card'>
                    <div className='d-flex justify-content-between flex-1 font-weight-bold' style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                      <div className='d-flex'>
                        <div style={{ width: '360px', marginRight: '5px' }}>&nbsp;</div>
                        <div style={{ width: '240px' }}>&nbsp;</div>
                        <div style={{ width: '120px', textAlign: 'center' }}>Número</div>
                      </div>
                      <div style={{ width: '120px', textAlign: 'center' }}>Ações</div>
                    </div>
                    {isLoading && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!isLoading &&
                      microcyclesFiltered.map((microcycle) => {
                        const status = dropDownStatus?.find((value) => value.id === microcycle.id && value.status);
                        return (
                          <div className='card_list_turma' key={`${microcycle.id}`}>
                            <div className='content_card'>
                              <div className='card_details_alternative'>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <p className='card_details_label text_uppercase my-auto' style={{ width: '360px' }}>
                                    <span style={{ color: '#3b3b3b' }}>{microcycle.name}</span>
                                  </p>
                                  <div ref={ref} style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '120px' }}>
                                    <div
                                      onClick={() => setDropDownStatus(dropDownStatus?.map((value) => (value.id === microcycle.id ? { ...value, status: !value.status } : { ...value })))}
                                      className={`${microcycle.status === 'A' ? 'badge badge-success' : 'badge badge-danger'} badge-button`}
                                      style={status ? { borderRadius: 8, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { borderRadius: 8, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
                                    >
                                      {microcycle.status === 'A' ? 'Ativo' : 'Inativo'}
                                      <div style={{ marginLeft: 10 }}><BsChevronDown color='#FFFFFF' /></div>
                                    </div>
                                    <div style={{ opacity: status ? 1 : 0 }} className='dropdown-shadow'>
                                      <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...microcycle, status: 'A' })}>Ativo</div>
                                      <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...microcycle, status: 'I' })}>Inativo</div>
                                    </div>
                                  </div>
                                  <div style={{ textAlign: 'center', width: '240px' }}>{microcycle?.classNumber}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 12, width: '120px', justifyContent: 'center' }}>
                                  <span onClick={() => openEdit(microcycle)}><BsFillPencilFill /></span>
                                  <span onClick={() => openEdit(microcycle, false, true)}><BiSolidCopy /></span>
                                  <span onClick={() => openEdit(microcycle, true)}><BsEye /></span>
                                  <span onClick={() => toPdf(microcycle)}><BsDownload /></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      )}
                  </div>
                </div>
              </div>
              <EditMicrocycle ref={editMicrocycleRef} data={microcycle} onSubmit={handleSubmit} onlyView={onlyView} />
              <div style={{ display: 'none' }}>
                <div ref={microcyclePdfRef}>
                  {microcycle?.days?.map((value) => {
                    return (
                      <div key={value?.id} className='m-5' style={{ height: '100vh' }}>
                        <div className='d-flex flex-1 flex-column'>
                          <img src="../../img/logo.png" width={252} height={36} alt="Marca" className='mx-auto' />
                          <h4 className='mt-3 mx-auto'>{microcycle?.name}</h4>
                          <h5 className='mt-1 mx-auto'>
                            {value?.day}
                          </h5>
                          <div className='row py-3'>
                            <div className='col-sm-12'>
                              <div className='font-weight-bold'>Objetivo</div>
                              <div className=''>{microcycle?.objective}</div>
                            </div>
                          </div>
                          <div className='row pb-3'>
                            <div className='col-sm-6 text-left'>
                              <div className='font-weight-bold'>Nome da aula</div>
                              <div className=''>{microcycle?.name}</div>
                            </div>
                            <div className='col-sm-6'>
                              <div className='font-weight-bold'>Número da aula</div>
                              <div className=''>{microcycle?.classNumber}</div>
                            </div>
                            <div className='col-sm-6'>
                              <div className='font-weight-bold'>Quantidade de aulas</div>
                              <div className=''>{microcycle?.classQuantity}</div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12'>
                              {microcycle?.classes?.filter((c) => c.day === value.day)?.length > 0 && (
                                <div className='row pb-3'>
                                  <div className='col-sm-12'>
                                    <div className='font-weight-bold'>Aula</div>
                                    <div className='resume'>
                                      <ul>
                                        {microcycle?.classes?.filter((c) => c.day === value.day)?.map((c) =>
                                          <li key={c.id}>
                                            <span class='font-weight-bold mr-1'>Nome da Aula: </span><span class='mr-2'>{c.name}</span>
                                            <span class='font-weight-bold mr-1'>Objetivo: </span><span class='mr-2'>{c.objective}</span>
                                            <span class='font-weight-bold mr-1'>Número da Aula: </span><span class='mr-2'>{c.classNumber}</span>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div >
    </main >
  );
};

export default AdminMicrocycle;
