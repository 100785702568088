import React from 'react';
import Message from '../../../components/Message';
import useForm from '../../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import ButtonRounded from '../../../components/buttons/ButtonRounded';
import { getPositionsSchool } from '../../../services/SharedService';
import { StudentFormContext } from '../../../Context/StudentFormContext';

const { MessageDangerMarginTop } = Message();

const Esportes = ({ title = 'Informações Aluno', nextTab }) => {
  const context = React.useContext(StudentFormContext);

  const formRefer = React.useRef(null);

  const [errors, setErrors] = React.useState([]);

  const [sendSave, setSendSave] = React.useState(false);

  const [errorsServer, setErrorsServe] = React.useState([]);

  const [sports, setSports] = React.useState([]);

  const [positions, setPositions] = React.useState([]);

  const { t } = useTranslation();

  const handlerErrorServer = (err) => {
    if (err.response.status === 422) {
      const { message = [] } = err.response.data;

      Object.keys(message).map((k) => {
        const element = document.querySelector(`[name=${k}]`);
        if (element) {
          dispatchHtmlValidity(element, `[${k}] ${message[k]}`);

          setState({
            ...state,
            [k]: { value: state[k]?.value, error: message[k] },
          });
        } else {
          setState({
            ...state,
            [k]: { value: state[k]?.value, error: message[k] },
          });
        }
      });
    } else setErrorsServe([err.response.data.message.toString()]);
  };
  const onSubmitForm = (payload, e, s, formData) => {
    const { tipo = undefined } = e.target.dataset;

    if (payload.sport && !payload.position) {
      const element = document.querySelector(`[name=position]`);
      dispatchHtmlValidity(element, `Informe a posição / atividade`);
      setState({
        ...state,
        position: { value: '', error: `Informe a posição / atividade` },
      });
      return null;
    }

    setSendSave(true);
    formData.append('session_user_id', context.user.id);
    formData.append('athId', context.atleta.athId.value);
    formData.append('id', context.atleta.id.value);
    //valida os dados no servidor
    context
      .requestValidate('/validate/esporte', formData)
      .then((data) => {
        //persiste os dados sem tratamento
        context.setState({
          esportes: {
            ...state,
            ...context.normalResponse(data.message),
          },
        });
        if (tipo !== undefined && tipo === 'finalizar') {
          context.handlerFinally();
          return null;
        }
        //avanca a tab
        nextTab(3);
      })
      .catch(handlerErrorServer)
      .finally(() => setSendSave(false));
  };

  const { state, handleOnChange, handleOnSubmit, disable, setState, setIsDirty, dispatchHtmlValidity } = useForm(
    context.esportes,
    validationStateSchema,
    onSubmitForm,
    formRefer,
    setErrors
  );

  React.useEffect(() => {
    let isMounted = true;
    const getSports = async () => {
      if (isMounted) {
        const res = await getPositionsSchool({ school_id: context.school.id });
        setPositions(res.positions);
        setSports(res.sports);
      }
    };
    setIsDirty(true);
    getSports();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {errors.map((err, k) => (
        <MessageDangerMarginTop key={`err-${k}`} title='Atenção!' description={err.error} />
      ))}
      {errorsServer.map((item, k) => (
        <MessageDangerMarginTop title='Atenção!' description={item} key={`error_${k}`} />
      ))}
      <div className='mx-3'>
        <form ref={formRefer} action='' className='form_card' noValidate>
          <div className='col p-0 w-100'>
            <div className='row'>
              <div className='col-md-6 col-sm-12 form_group'>
                <label className='card_details_label flex-row'>{t('SportType')}</label>
                <select
                  className='form_control form-control select'
                  value={state.sport.value}
                  onChange={handleOnChange}
                  name='sport'
                >
                  <option value=''>-- {t('Select')} --</option>
                  {sports.map((sport) => (
                    <option key={`tipo_${sport.id}`} value={sport.id}>
                      {t(`Sports.Name.${sport.label}`)}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form_group col-md-6 col-sm-12 '>
                <label className='card_details_label'>{t('Position')}</label>
                <select
                  className='form_control form-control select'
                  value={state.position.value}
                  onChange={handleOnChange}
                  name='position'
                >
                  <option value=''>-- {t('Select')} --</option>
                  {positions
                    .filter((position) => position.sport_id == state.sport.value)
                    .sort((a, b) => a.posOrdem - b.posOrdem)
                    .map((position) => (
                      <option key={`po_${position.id}`} value={position.id}>
                        {t(`Sports.Position.${position.posPosition}`)}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 col-sm-12 form_group'>
                <label className='card_details_label flex-row'>{t('SkillLevel')}</label>
                <select
                  className='form_control form-control select'
                  value={state.skillLevel.value}
                  onChange={handleOnChange}
                  name='skillLevel'
                >
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                  <option value='7'>6 {t('Complete')}</option>
                </select>
              </div>

              <div className='form_group col-md-6 col-sm-12'>
                <label className='card_details_label flex-row'>{t('Sublevel')}</label>
                <select
                  className='form_control form-control select'
                  value={state.sublevel.value}
                  onChange={handleOnChange}
                  name='sublevel'
                >
                  <option value='BEGINNER'>{t('Beginner')}</option>
                  <option value='INTERMEDIARY'>{t('Intermediary')}</option>
                  <option value='ADVANCED'>{t('Advanced')}</option>
                </select>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 col-sm-12 form_group'>
                <label className='card_details_label'>{t('SchoolYear')}</label>
                <input
                  type='text'
                  name='schoolYear'
                  placeholder={t('SchoolYear')}
                  className='form-control form_control'
                  value={state.schoolYear.value}
                  onChange={handleOnChange}
                />
              </div>
              <div className='col-md-6 col-sm-12 form_group'>
                <label className='card_details_label'>{t('Laterality')}</label>
                <select
                  onChange={handleOnChange}
                  value={state.athLateralidade.value}
                  name='athLateralidade'
                  className='form_control form-control select'
                >
                  <option value=''>-- {t('Select')} --</option>
                  <option value='D'>{t('RightHanded')}</option>
                  <option value='C'>{t('LeftHanded')}</option>
                  <option value='A'>{t('Ambidextrous')}</option>
                </select>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12 col-sm-12 form_group'>
                <label className='card_details_label'>{t('ReleaseCertificate')}</label>
                <select
                  required
                  name='certificado'
                  className='form_control form-control select'
                  value={state.certificado.value}
                  onChange={handleOnChange}
                >
                  <option value='0'>{t('Not')}</option>
                  <option value='1'>{t('Yes')}</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className='row align-items-center justify-content-center my-4'>
        <div className='col-md-5 col-sm-10'>
          <ButtonRounded
            isDisabled={disable}
            isLoading={sendSave}
            data-tipo='finalizar'
            onClick={handleOnSubmit}
            className='w-100'
            isBig={true}
          >
            {t('Save')} {t('And')} {t('Finalize')}
          </ButtonRounded>
        </div>
        <div className='col-md-5 col-sm-10'>
          <ButtonRounded
            isDisabled={disable}
            isLoading={sendSave}
            onClick={handleOnSubmit}
            className='w-100'
            isBig={true}
          >
            {t('Save')} {t('And')} {t('Continue')}
          </ButtonRounded>
        </div>
      </div>
    </>
  );
};

const validationStateSchema = {
  /* sport: {
    required: true,
    error: 'Selecione uma modalidade',
  }, */
};

export default Esportes;
