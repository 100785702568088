import React, { useEffect, useRef, useState } from 'react';
import HeaderSchool from '../../components/school/Header';
import FooterSchool from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import { useTranslation } from 'react-i18next';
import TableWithSelection from '../../components/tables/TableWithSelection';
import { getAllAvailableStudentDocuments, setMandatoryStudentsDocuments } from '../../services/DocumentService';
import utils from '../../utils/utils';

const MandatoryDocuments = () => {
  const { scrollTop } = utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  const messageScroll = useRef(window);

  const { t } = useTranslation();

  const schoolId = JSON.parse(localStorage.getItem('school'))?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [documents, setDocuments] = useState([]);
  const [documentsSelected, setDocumentsSelected] = useState([]);

  useEffect(() => {
    fetchAllAvailableStudentDocuments();
  }, []);

  const clearMessage = () => {
    setSuccessMessage(false);
    setErrorMessage(false);
    setMessage('');
  };

  const showSuccessMessage = (message) => {
    setMessage(message);
    setSuccessMessage(true);
    setErrorMessage(false);
    scrollTop(messageScroll);
  };

  const showErrorMessage = (message) => {
    setMessage(message);
    setSuccessMessage(false);
    setErrorMessage(true);
    scrollTop(messageScroll);
  };

  const fetchAllAvailableStudentDocuments = async () => {
    clearMessage();
    setDocuments([]);

    setIsLoading(true);
    const { status, messageKey, message, documents, mandatoryStudentsDocuments } =
      await getAllAvailableStudentDocuments(schoolId);
    setIsLoading(false);

    if (status === 'SUCCESS') {
      setDocuments(
        documents
          .sort(({ docDocument: docDocumentA }, { docDocument: docDocumentB }) =>
            docDocumentA.toLowerCase().localeCompare(docDocumentB.toLowerCase())
          )
          .map((document) => ({ label: document.docDocument, ...document }))
      );

      setDocumentsSelected(mandatoryStudentsDocuments.map(({ document_id }) => document_id));
    } else {
      showErrorMessage(t(messageKey) || message);
    }
  };

  const onPressKey = (e) => {
    e.preventDefault();
    if (e.key === 'Enter') handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();

    setIsSending(true);
    const { status, messageKey, message } = await setMandatoryStudentsDocuments({
      schoolId,
      documentIds: documentsSelected,
    });
    setIsSending(false);

    if (status === 'SUCCESS') {
      showSuccessMessage(t(messageKey) || message);
    } else {
      showErrorMessage(t(messageKey) || message);
    }
  };

  const toggleSelection = (checked, { id }) => {
    setDocumentsSelected((current) => {
      const filtered = current.filter((c) => c !== id);

      return checked ? [...filtered, id] : [...filtered];
    });
  };

  const toggleFill = (checked) => {
    if (checked) setDocumentsSelected(documents.map(({ id }) => id));
    else setDocumentsSelected([]);
  };

  return (
    <main style={{ overflow: 'auto' }}>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            {/* {<HeaderSchool />} */}

            <main className='content_panel'>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('MandatoryDocsPage.PageTitle')}</h2>
                    <p className='card_subtitle'>{t('MandatoryDocsPage.PageSubtitle')}</p>
                  </div>
                  <div ref={messageScroll} style={{ width: '100%' }}>
                    {successMessage && (
                      <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={message} />
                    )}
                    {errorMessage && (
                      <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={message} />
                    )}
                  </div>
                  <div className='list_card'>
                    <form
                      action=''
                      onSubmit={handleSubmit}
                      method='post'
                      className='form_modal'
                      onKeyPress={onPressKey}
                    >
                      <TableWithSelection
                        title={t('Documents')}
                        checkboxLabel={t('Mandatory_question')}
                        isLoading={isLoading}
                        options={documents}
                        selectedOptions={documentsSelected}
                        disableSelectAll={isLoading}
                        checker={({ id }) => documentsSelected.includes(id)}
                        fillSelectedOptions={toggleFill}
                        onChangeSelection={toggleSelection}
                      />
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        {!isSending && (
                          <button className={`btn_card mx-auto ${isSending ? 'disabled' : ''}`} disabled={isSending}>
                            {t('Save')}
                          </button>
                        )}
                        {isSending && (
                          <button className='btn_card mx-auto disabled' disabled>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            {t('SystemMessage.Loading.Processing')}
                            <Loading type='1' style={{ marginLeft: '2%' }} />
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>

            {<FooterSchool />}
          </div>
        </div>
      </div>
    </main>
  );
};

export default MandatoryDocuments;
