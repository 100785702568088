import React, { createContext, useCallback, useState } from 'react';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

const userIdentifier = 'user';
const athleteIdentifier = 'athlete';
const docsIdentifier = 'docs';
const menuIdentifier = 'menu';
const signIdentifier = 'sign';
const schoolIdentifier = 'school';
const saldoIdentifier = 'saldo';
const companyIdentifier = 'company';
// const persist_rootIdentifier = 'persist:root';
const adminIdentifier = 'admin';
const tokenIdentifier = '@token';
const actionIdentifier = 'action'

/**
 * @typedef ObjectContextStates
 * @property {object} user
 * @property {object} athlete
 * @property {object} docs
 * @property {object} menu
 * @property {object} sign
 * @property {object} school
 * @property {object} saldo
 * @property {object} company
 * @property {object} persist_root
 * @property {object} admin
 * @property {object} action
 */

/**
 * @typedef UserContextState
 * @property {(data: ObjectContextStates) => void} setContextData
 * @property {() => void} clearContextData
 * @property {object} tokenPayload
 * @property {(token: string) => void} setToken
 * @property {() => boolean} isAuthenticated
 * @property {() => void} logout
 */

/** @type {import('react').Context<ObjectContextStates & UserContextState>} */
const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const clearContextData = () => {
    localStorage.removeItem(userIdentifier);
    localStorage.removeItem(athleteIdentifier);
    localStorage.removeItem(docsIdentifier);
    localStorage.removeItem(menuIdentifier);
    localStorage.removeItem(signIdentifier);
    localStorage.removeItem(schoolIdentifier);
    localStorage.removeItem(saldoIdentifier);
    localStorage.removeItem(companyIdentifier);
    // localStorage.removeItem(persist_rootIdentifier);
    localStorage.removeItem(adminIdentifier);
    localStorage.removeItem(actionIdentifier);
  };

  const [dataToken, setDataToken] = useState(() => {
    const token = localStorage.getItem(tokenIdentifier);

    if (token) {
      try {
        return {
          tokenPayload: jwtDecode(token),
        };
      } catch (error) {
        return {
          tokenPayload: null,
        };
      }
    } else {
      localStorage.removeItem(tokenIdentifier);

      return {
        tokenPayload: null,
      };
    }
  });

  const [data, setData] = useState(() => {
    const user = localStorage.getItem(userIdentifier);
    const athlete = localStorage.getItem(athleteIdentifier);
    const docs = localStorage.getItem(docsIdentifier);
    const menu = localStorage.getItem(menuIdentifier);
    const sign = localStorage.getItem(signIdentifier);
    const school = localStorage.getItem(schoolIdentifier);
    const saldo = localStorage.getItem(saldoIdentifier);
    const company = localStorage.getItem(companyIdentifier);
    // const persist_root = localStorage.getItem(persist_rootIdentifier);
    const admin = localStorage.getItem(adminIdentifier);
    const action = localStorage.getItem(actionIdentifier);

    try {
      return {
        user: user ? JSON.parse(user) : {},
        athlete: athlete ? JSON.parse(athlete) : {},
        docs: docs ? JSON.parse(docs) : [],
        menu: menu ? JSON.parse(menu) : [],
        sign: sign ? JSON.parse(sign) : null,
        school: school ? JSON.parse(school) : {},
        saldo: saldo ? JSON.parse(saldo) : null,
        company: company ? JSON.parse(company) : {},
        // persist_root: persist_root ? JSON.parse(persist_root) : null,
        admin: admin,
        action: action ? JSON.parse(action) : {},
      };
    } catch (error) {
      clearContextData();

      return {
        user: {},
        athlete: {},
        docs: [],
        menu: [],
        sign: null,
        school: {},
        saldo: null,
        company: {},
        // persist_root: null,
        admin: admin,
        action: {},
      };
    }
  });

  const setContextData = (data) => {
    const objectKeys = Object.keys(data);
    const keys = [
      userIdentifier,
      athleteIdentifier,
      docsIdentifier,
      menuIdentifier,
      signIdentifier,
      schoolIdentifier,
      saldoIdentifier,
      companyIdentifier,
      // persist_rootIdentifier,
      adminIdentifier,
      actionIdentifier,
    ];

    objectKeys.forEach((key) => {
      if (keys.includes(key)) {
        localStorage.setItem(key, JSON.stringify(data[key]));
        setData((current) => ({ ...current, [key]: data[key] }));
      }
    });
  };

  const setToken = (token) => {
    try {
      const user = jwtDecode(token);
      localStorage.setItem(tokenIdentifier, token);
      setDataToken((current) => ({ ...current, tokenPayload: user }));
    } catch (error) {
      setDataToken((current) => ({ ...current, tokenPayload: null }));
    }
  };

  const isAuthenticated = useCallback(() => {
    try {
      const token = localStorage.getItem(tokenIdentifier);
      if (!token) {
        return false;
      } else {
        const tokenDecoded = jwtDecode(token);
        const timestamp = moment().valueOf();

        return !!tokenDecoded && tokenDecoded.exp * 1000 >= timestamp ? true : false;
      }
    } catch (error) {
      return false;
    }
  }, []);

  const logout = () => {
    localStorage.removeItem(tokenIdentifier);
  };

  return (
    <UserContext.Provider
      value={{
        user: data.user,
        athlete: data.athlete,
        docs: data.docs,
        menu: data.menu,
        sign: data.sign,
        school: data.school,
        saldo: data.saldo,
        company: data.company,
        action: data.action,
        // persist_root: data.persist_root,
        tokenPayload: dataToken.tokenPayload,
        setContextData,
        clearContextData,
        setToken,
        isAuthenticated,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export {
  UserContext,
  UserProvider,
  userIdentifier,
  athleteIdentifier,
  docsIdentifier,
  menuIdentifier,
  signIdentifier,
  schoolIdentifier,
  saldoIdentifier,
  companyIdentifier,
  // persist_rootIdentifier,
  tokenIdentifier,
  actionIdentifier,
};
