import React, { useContext, useEffect, useState } from 'react';
import { handleOnChange } from '../../../src/utils/utils';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Footer from '../../components/club/Footer';
import { Success } from '../../components/svgs/Success';
import { Link, useHistory } from 'react-router-dom';
import { createMethodology, getMethodologiesByCompanyId, updateMethodology } from '../../services/MethodologyService';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { UserContext } from '../../Context/UserContext';
import { BsChevronDown, BsFillPencilFill } from 'react-icons/bs';

function Methodology() {
  const history = useHistory();
  const { action, setContextData } = useContext(UserContext);
  //filtros
  const [filters, setFilters] = useState({
    name: '',
    source: 'M',
    status: 'A',
  });

  // dados
  const [methodology, setMethodology] = useState({
    id: undefined,
    name: '',
    status: 'A',
    description: '',
  });
  const [methodologies, setMethodologies] = useState([]);
  const [methodologiesFiltered, setMethodologiesFiltered] = useState([]);
  const [dropDownStatus, setDropDownStatus] = useState([]);

  //interface
  const [display, setDisplay] = useState(false);

  // process
  const [isLoading, setIsLoading] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  const ref = useOutsideClick(() => {
    setDropDownStatus(dropDownStatus?.map((value) => ({ ...value, status: false })));
  });

  // libraries
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();

  useEffect(() => {
    if (action?.action === 'search') {
      setFilters({
        name: action.term,
        status: action.status
      });
      fetchAllMethodologies().then((response) => {
        setMethodologiesFiltered(
          response?.filter((methodology) => action?.status === 'T' || methodology.status === action?.status)
            .filter((methodology) => methodology.name?.toUpperCase()?.includes(action?.term?.toUpperCase())));
      }).catch((e) => console.log(e));
    } else {
      fetchAllMethodologies();
    }
    action?.action === 'openAdd' && openAdd();
    setContextData({ action: {} });

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeAdd();
    }
  };

  const fetchAllMethodologies = async () => {
    setIsLoading(true);
    const { status, methodologies, message } = await getMethodologiesByCompanyId();
    setIsLoading(false);
    if (status === 'SUCCESS') {
      setMethodologies(methodologies);
      setMethodologiesFiltered(methodologies);
      setDropDownStatus(methodologies?.map((methodology) => ({ id: methodology.id, status: false })))
      return methodologies;
    } else {
      console.log(message);
      setErrorMessage(message);
    }
  };

  const onChangeMethodology = handleOnChange(setMethodology);

  const onChangeFilters = handleOnChange(setFilters);

  const search = (e) => {
    e && e.preventDefault();

    if (filters.source === 'T' || filters.source === 'A') {
      setContextData({
        action: {
          action: 'search',
          term: filters.name,
          status: filters.status
        }
      })
      filters.source === 'T' ? history.push('/club-lesson', { replace: true }) : history.push('/club-planning', { replace: true });
      return;
    }

    setMethodologiesFiltered(
      methodologies.filter((methodology) => filters.status === 'T' || methodology.status === filters.status)
        .filter((methodology) => methodology.name?.includes(filters.name))
    );

  };

  const openAdd = () => {
    setMethodology({
      id: undefined,
      name: '',
      status: 'A',
      description: '',
    });
    clearMessage();
    setDisplay(true);
  };

  const configValues = (value) => {
    setMethodology({
      id: value.id,
      name: value.name,
      status: value.status,
      description: value.description,
    });
  }

  const edit = (value) => {
    configValues(value);
    clearMessage();
    setDisplay(true);
  };

  const closeAdd = () => {
    setDisplay(false);
    clearMessage();
  };

  const updateByStatus = async (values) => {
    const {
      status,
      methodologies,
      message,
    } = await updateMethodology(values);

    if (status === 'SUCCESS') {
      setMethodologies(methodologies);
      setMethodologiesFiltered(methodologies);
      setDropDownStatus(methodologies?.map((methodology) => ({ id: methodology.id, status: false })))
    } else {
      setErrorMessage(message);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();

    const formData = {
      id: methodology.id,
      name: methodology.name,
      status: methodology.status,
      description: methodology.description,
    };

    setIsLoading(true);
    const {
      status,
      methodologies,
      message,
    } = methodology.id === undefined ? await createMethodology(formData) : await updateMethodology(formData);
    setIsLoading(false);

    if (status === 'SUCCESS') {
      setMethodologies(methodologies);
      setMethodologiesFiltered(methodologies);
      setDropDownStatus(methodologies?.map((methodology) => ({ id: methodology.id, status: false })))
      setSuccess(true);
    } else {
      setErrorMessage(message);
    }
  };

  const setErrorMessage = (message) => {
    setMessage(message);
    setError(true);
  };

  const clearMessage = () => {
    setSuccess(false);
    setError(false);
    setMessage('');
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='d-flex flex-row flex-1'>
            <div className='d-flex flex-column card mr-3'>
              <div className='card_header'>
                <h2 className='card_title'>Adicionar tarefa</h2>
                <p className='card_subtitle'>Clique no botão abaixo para adicionar tarefa.</p>
              </div>
              <div className='d-flex flex-1 mt-4 align-items-end'>
                <Link to={'club-lesson'} className='btn-secondary-color rounded-pill mr-3'>
                  Ver
                </Link>
                <button onClick={() => {
                  setContextData({
                    action: {
                      action: 'openAdd'
                    }
                  })
                  history.push('/club-lesson', { replace: true });
                }} className='btn-main-color rounded-pill'>
                  Criar
                </button>
              </div>
            </div>
            <div className='d-flex flex-column card mr-3'>
              <div className='card_header'>
                <h2 className='card_title'>Adicionar Aulas</h2>
                <p className='card_subtitle'>Clique no botão abaixo para adicionar uma aula.</p>
              </div>
              <div className='d-flex flex-1 mt-4 align-items-end'>
                <Link to={'club-planning'} className='btn-secondary-color rounded-pill mr-3'>
                  Ver
                </Link>
                <button onClick={() => {
                  setContextData({
                    action: {
                      action: 'openAdd'
                    }
                  })
                  history.push('/club-planning', { replace: true });
                }} className='btn-main-color rounded-pill'>
                  Criar
                </button>
              </div>
            </div>
            <div className='d-flex flex-column card'>
              <div className='card_header'>
                <h2 className='card_title'>Metodologia Pronta</h2>
                <p className='card_subtitle'>Clique no botão abaixo para visualizar as metodologias prontas.</p>
              </div>
              <div className='d-flex flex-1 mt-4 align-items-end'>
                <Link to={'club-methodology'} className='btn-secondary-color rounded-pill mr-3'>
                  Ver
                </Link>
                <button onClick={openAdd} className='btn-main-color rounded-pill'>
                  Criar
                </button>
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='card_header'>
              <h2 className='card_title'>Pesquisar</h2>
              <p className='card_subtitle'>Busque abaixo por tarefas, aulas ou metodologias.</p>
            </div>
            <form action='' method='post' className='form_card'>
              <div className='d-flex flex-row align-items-center row'>
                <div className='col-sm-3'>
                  <input
                    required
                    type='text'
                    className='form_control mr-3'
                    name='name'
                    placeholder='Nome'
                    value={filters.name}
                    onChange={onChangeFilters}
                  />
                </div>
                <div className='col-sm-3'>
                  <select
                    required
                    className='form_control select mr-3'
                    name='source'
                    value={filters.source}
                    onChange={onChangeFilters}
                  >
                    <option disabled={true}>Selecionar</option>
                    <option value='T'>Tarefas</option>
                    <option value='A'>Aulas</option>
                    <option value='M'>Metodologias</option>
                  </select>
                </div>
                <div className='col-sm-3'>
                  <select
                    required
                    className='form_control select mr-3'
                    name='status'
                    value={filters.status}
                    onChange={onChangeFilters}
                  >
                    <option disabled={true}>Status</option>
                    <option value='T'>Tudo</option>
                    <option value='A'>Ativo</option>
                    <option value='I'>Inativo</option>
                  </select>
                </div>
                <div className='col-sm-3 my-auto'>
                  <button className='btn-secondary-color rounded-pill' onClick={search}>
                    Buscar
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className='content_painel'>
            <main className='content_panel'>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Metodologias</h2>
                    <p className='card_subtitle'>Veja abaixo as metodologias criadas.</p>
                    {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
                  </div>

                  <div className='list_card'>
                    {isLoading && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!isLoading &&
                      methodologiesFiltered.map((methodology) => {
                        const status = dropDownStatus?.find((value) => value.id === methodology.id && value.status);
                        return (
                          <div className='card_list_turma' key={`${methodology.id}`}>
                            <div className='content_card'>
                              <div className='card_details_alternative'>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  <p className='card_details_label text_uppercase' style={{ minWidth: '150px' }}>
                                    <span style={{ color: '#3b3b3b' }}>{methodology.name}</span>
                                  </p>
                                  <div ref={ref} style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '95px' }}>
                                    <div
                                      onClick={() => setDropDownStatus(dropDownStatus?.map((value) => (value.id === methodology.id ? { ...value, status: !value.status } : { ...value })))}
                                      className={`${methodology.status === 'A' ? 'badge badge-success' : 'badge badge-danger'} badge-button`}
                                      style={status ? { borderRadius: 8, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { borderRadius: 8, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
                                    >
                                      {methodology.status === 'A' ? 'Ativo' : 'Inativo'}
                                      <div style={{ marginLeft: 10 }}><BsChevronDown color='#FFFFFF' /></div>
                                    </div>
                                    <div style={{ opacity: status ? 1 : 0 }} className='dropdown-shadow'>
                                      <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...methodology, status: 'A' })}>Ativo</div>
                                      <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...methodology, status: 'I' })}>Inativo</div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  onClick={() => edit(methodology)}
                                >
                                  <BsFillPencilFill />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      )}
                  </div>
                </div>
              </div>
              <div className='modal' style={display ? { display: 'flex', opacity: 1 } : undefined}>
                <div className='modal_content'>
                  <div className='modal_close' onClick={closeAdd}>
                    X
                  </div>
                  <h3 className='modal_title'>
                    {methodology.id === undefined ? 'Criar metodologia' : 'Editar metodologia'}
                  </h3>
                  {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
                  <form onSubmit={handleSubmit} method='post' className='form_modal'>
                    <div className='w-100'>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form_group'>
                            <label className='card_details_label'>Nome da Metodologia</label>
                            <input
                              type='text'
                              className='form_control'
                              name='name'
                              value={methodology.name}
                              onChange={onChangeMethodology}
                              placeholder='Inserir'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form_group'>
                            <label className='card_details_label'>Descrição</label>
                            <textarea
                              required
                              className='form_control'
                              name='description'
                              value={methodology.description}
                              onChange={onChangeMethodology}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form_group'>
                            <label htmlFor='status' className='card_details_label'>
                              Status
                            </label>
                            <select
                              required
                              name='status'
                              className='form_control select'
                              value={methodology.status}
                              onChange={onChangeMethodology}
                            >
                              <option value='' disabled hidden></option>
                              <option value='A'>Ativo</option>
                              <option value='I'>Inativo</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w-100 d-flex justify-content-center'>
                      <button type='button' className='btn-main-color mr-3' onClick={closeAdd}>
                        Voltar
                      </button>
                      <button className='btn-secondary-color'>
                        {methodology.id === undefined ? 'Criar Metodologia' : 'Editar Metodologia'}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="modal_success" style={success ? { display: 'flex', opacity: 1 } : undefined}>
                  <div className='modal_content_success'>
                    <div className="modal_header_success">
                      <div className='modal_title_success'>{methodology.id === undefined ? 'Metodologia criada' : 'Alterações salvas'}</div>
                      <div className='modal_close_success' onClick={closeAdd}>
                        X
                      </div>
                    </div>
                    <div className='modal_body_success'>
                      <div className='modal_icon_success'>
                        <Success style={{ color: '#24c782' }} />
                      </div>
                      <div className="">{methodology.id === undefined ? 'Metodologia criada com sucesso!' : 'Metodologia editada com sucesso!'}</div>
                    </div>
                    <div className='modal_footer_success'>
                      <button type='button' onClick={closeAdd} className='btn btn-secondary-color w-100'>
                        Fechar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Methodology;
