import React from 'react';

const SimpleModal = ({ isShow, handleCloseModal, children, modalRef, size }) => {
  const sizes = {
    sm: '500px',
    lg: '800px',
    xl: '1100px',
  };

  return (
    <div className='modal' style={isShow ? { display: 'flex', opacity: 1 } : undefined}>
      <div
        className='modal_content'
        ref={modalRef}
        style={{ ...(size !== undefined ? { maxWidth: sizes[size] } : undefined) }}
      >
        <div className='modal_close' onClick={() => handleCloseModal()}>
          X
        </div>
        {children}
      </div>
    </div>
  );
};

export default SimpleModal;
