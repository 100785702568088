import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import './SubNav.css'

const SubNav = (props) => {

    return (
        <article className="sub_header">
            <div className="user">
                <img src={props.tumblr} alt={props.fullName} title={props.fullName} />
                <span>{props.fullName}</span>
            </div>
            <nav>
                <span>{Number(props.points)} pts</span>
                <NavLink to='/home-athlete' activeClassName="active">home</NavLink>
                <NavLink to='/ransomed' activeClassName="active">Pontos resgatados</NavLink>
                {
                    // <NavLink to='/expire' activeClassName="active">Pontos a expirar</NavLink>
                }
                <NavLink to='/extract' activeClassName="active">extrato</NavLink>
            </nav>
        </article>
    )
}

export default SubNav