import React, { useEffect, useState } from 'react';
import './privateChat.css';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, where, query, onSnapshot, updateDoc } from 'firebase/firestore';
import { firebaseConfig } from '../../../../config/firebase.config';
import { createNewMessage, changeTypingStatus, findChatDocId } from '../../../../services/ChatService';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';

const PrivateChat = ({ chatId, setCurrentRoute, docId }) => {
  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [myUser, setMyUser] = useState(null);
  const [typing, setTyping] = useState(false);
  const [receiverTyping, setReceiverTyping] = useState(false);
  const [defaultAvatar, setDefaultAvatar] = useState('https://i.imgur.com/HeIi0wU.png');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  //Get userID from localstorage
  const userData = localStorage.getItem('user');
  const userObj = JSON.parse(userData || '{}');
  const { id } = userObj;

  const fetchChatById = async () => {
    const chatRef = collection(firestore, 'chats');
    const q = query(chatRef, where('chat_id', '==', chatId));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.empty) {
        console.error('No matching documents, fetchChatById.');
        return;
      }

      const fetchedMessages = [];
      let fetchedUserId;

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const { messages } = docData;
        fetchedMessages.push(...messages);

        if (docData.typing && docData.typing.length > 0) {
          setReceiverTyping(docData.typing.find((user) => user !== id));
        }

        if (!fetchedUserId) {
          fetchedUserId = docData.receiver_id === id ? docData.sender_id : docData.receiver_id;
        }
      });

      setMessages(fetchedMessages);
      setUserId(fetchedUserId);
    });

    return unsubscribe;
  };

  const fetchMyUser = async () => {
    const userRef = collection(firestore, 'users');
    const q = query(userRef, where('user_id', '==', id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.empty) {
        console.error('No matching documents, fetchMyUser.');
        return;
      }
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        setMyUser(docData);
      });
    });
    return unsubscribe;
  };

  useEffect(() => {
    fetchMyUser();
  }, []);

  useEffect(() => {
    const unsubscribe = fetchChatById();
    return () => {
      unsubscribe
        .then((unsubscribe) => {
          unsubscribe();
        })
        .catch((error) => {
          console.error('Error unsubscribing from chat', error);
        });
    };
  }, []);

  useEffect(() => {
    changeTypingStatus(docId, id, typing);
  }, [typing]);

  useEffect(() => {
    if (!userId) return;
    const userRef = collection(firestore, 'users');
    const q = query(userRef, where('user_id', '==', userId));
    onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.empty) {
        console.error('No matching documents.');
        return;
      }
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        setUser(docData);
      });
    });
  }, [userId]);

  const handleSendMessage = async (message) => {
    setShowEmojiPicker(false);
    if (hasOnlySpaces(message)) {
      setMessage('');
      return;
    }

    if (await createNewMessage(firestore, id, docId, message)) {
      setMessage('');
      scrollToBottom();
    }
  };

  const hasOnlySpaces = (message) => {
    return message.trim().length === 0;
  };

  const readUnreadMessages = async () => {
    try {
      const chat = await findChatDocId(firestore, docId);

      if (chat.exists()) {
        const docData = chat.data();
        const { messages } = docData;
        messages.forEach((message) => {
          if (message.sender_id !== id && message.seen_by.indexOf(id) === -1) {
            message.seen_by.push(id);
          }
        });
        await updateDoc(chat.ref, { messages: messages });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const scrollToBottom = () => {
    const chat = document.getElementsByClassName('private-chat__messages')[0];
    chat.scrollTop = chat.scrollHeight;
  };

  useEffect(() => {
    if (messages.length > 0) {
      readUnreadMessages();
      scrollToBottom();
    }
  }, [messages]);

  const formatUnixToDateTime = (unix) => {
    const date = new Date(unix);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (day < 10 && month < 10) {
      return `0${day}/0${month}/${year} ${hours}:${minutes}`;
    } else if (day < 10) {
      return `0${day}/${month}/${year} ${hours}:${minutes}`;
    } else if (month < 10) {
      return `${day}/0${month}/${year} ${hours}:${minutes}`;
    }
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const onEmojiClick = (emojiData, event) => {
    setMessage(message + emojiData.emoji);
  };

  const onFocusInput = () => {
    setTyping(true);
    setShowEmojiPicker(false);
  };

  return (
    <React.Fragment>
      <div className='private-chat__container'>
        <div className='private-chat__header'>
          <div className='private-chat__header__info'>
            <button className='private-chat__header__info__btn___back' onClick={() => setCurrentRoute('/chat-list')}>
              <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <mask id='mask0_20_1179' maskUnits='userSpaceOnUse' x='0' y='0' width='32' height='32'>
                  <rect width='32' height='32' fill='#D9D9D9' />
                </mask>
                <g mask='url(#mask0_20_1179)'>
                  <path
                    d='M18.6666 24L10.6666 16L18.6666 8L20.5333 9.86667L14.4 16L20.5333 22.1333L18.6666 24Z'
                    fill='#1C1B1F'
                  />
                </g>
              </svg>
            </button>
            <div className='private-chat__header__info__user'>
              <div className='private-chat__header__info__name'>{user?.name}</div>
              {receiverTyping ? (
                <div className='private-chat__header__info__typing'>Está digitando</div>
              ) : (
                <div className='private-chat__header__info__status'>{user?.status}</div>
              )}
            </div>
          </div>
          <div className='private-chat__header__actions'>
            <button className='private-chat__header__actions__btn___close' onClick={() => setCurrentRoute('/')}>
              <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <mask id='mask0_20_1179' maskUnits='userSpaceOnUse' x='0' y='0' width='32' height='32'>
                  <rect width='32' height='32' fill='#D9D9D9' />
                </mask>
                <g mask='url(#mask0_20_1179)'>
                  <path
                    d='M18.6666 24L10.6666 16L18.6666 8L20.5333 9.86667L14.4 16L20.5333 22.1333L18.6666 24Z'
                    fill='#1C1B1F'
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>
        <div className='private-chat__messages chat-container-scroll'>
          {messages.map((message) => {
            return (
              <div
                key={message.created_at}
                className={`private-chat__messages__container ${
                  message.sender_id === id ? 'private-chat__msg___sent' : 'private-chat__msg___received'
                }`}
              >
                <div className='private-chat__messages__message' key={message.created_at}>
                  {message.sender_id !== id && (
                    <div className='private-chat__messages__message__avatar'>
                      <img src={user?.avatar} alt='avatar' onError={(e) => (e.target.src = defaultAvatar)} />
                    </div>
                  )}
                  <div className='private-chat__messages__message__content'>
                    {message.sender_id !== id ? (
                      <div className='private-chat__messages__message__content__name'>{user?.name}</div>
                    ) : (
                      <div className='private-chat__messages__message__content__name'>Você</div>
                    )}
                    <div className='private-chat__messages__message__content__text'>{message.content}</div>
                  </div>
                  {message.sender_id === id && (
                    <div className='private-chat__messages__message__avatar my-avatar'>
                      <img src={myUser?.avatar} alt='avatar' onError={(e) => (e.target.src = defaultAvatar)} />
                    </div>
                  )}
                </div>
                <div className='private-chat__messages__message__date'>{formatUnixToDateTime(message.created_at)}</div>
              </div>
            );
          })}
        </div>
        <div className='private-chat__send__message'>
          <input
            value={message}
            type='text'
            placeholder='Digite sua mensagem...'
            className='private-chat__send__message__input'
            onChange={(e) => setMessage(e.target.value)}
            onFocus={() => onFocusInput()}
            onBlur={() => setTyping(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage(message);
              }
            }}
          />
          <button
            className='private-chat__send__message__input__emoji'
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          >
            <svg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M10 21C8.61667 21 7.31667 20.7375 6.1 20.2125C4.88333 19.6875 3.825 18.975 2.925 18.075C2.025 17.175 1.3125 16.1167 0.7875 14.9C0.2625 13.6833 0 12.3833 0 11C0 9.61667 0.2625 8.31667 0.7875 7.1C1.3125 5.88333 2.025 4.825 2.925 3.925C3.825 3.025 4.88333 2.3125 6.1 1.7875C7.31667 1.2625 8.61667 1 10 1C10.7167 1 11.4083 1.07083 12.075 1.2125C12.7417 1.35417 13.3833 1.55833 14 1.825V4.075C13.4167 3.74167 12.7875 3.47917 12.1125 3.2875C11.4375 3.09583 10.7333 3 10 3C7.78333 3 5.89583 3.77917 4.3375 5.3375C2.77917 6.89583 2 8.78333 2 11C2 13.2167 2.77917 15.1042 4.3375 16.6625C5.89583 18.2208 7.78333 19 10 19C12.2167 19 14.1042 18.2208 15.6625 16.6625C17.2208 15.1042 18 13.2167 18 11C18 10.4667 17.9458 9.95 17.8375 9.45C17.7292 8.95 17.5833 8.46667 17.4 8H19.55C19.7 8.48333 19.8125 8.97083 19.8875 9.4625C19.9625 9.95417 20 10.4667 20 11C20 12.3833 19.7375 13.6833 19.2125 14.9C18.6875 16.1167 17.975 17.175 17.075 18.075C16.175 18.975 15.1167 19.6875 13.9 20.2125C12.6833 20.7375 11.3833 21 10 21ZM18 6V4H16V2H18V0H20V2H22V4H20V6H18ZM13.5 10C13.9167 10 14.2708 9.85417 14.5625 9.5625C14.8542 9.27083 15 8.91667 15 8.5C15 8.08333 14.8542 7.72917 14.5625 7.4375C14.2708 7.14583 13.9167 7 13.5 7C13.0833 7 12.7292 7.14583 12.4375 7.4375C12.1458 7.72917 12 8.08333 12 8.5C12 8.91667 12.1458 9.27083 12.4375 9.5625C12.7292 9.85417 13.0833 10 13.5 10ZM6.5 10C6.91667 10 7.27083 9.85417 7.5625 9.5625C7.85417 9.27083 8 8.91667 8 8.5C8 8.08333 7.85417 7.72917 7.5625 7.4375C7.27083 7.14583 6.91667 7 6.5 7C6.08333 7 5.72917 7.14583 5.4375 7.4375C5.14583 7.72917 5 8.08333 5 8.5C5 8.91667 5.14583 9.27083 5.4375 9.5625C5.72917 9.85417 6.08333 10 6.5 10ZM10 16.5C11.1333 16.5 12.1625 16.1792 13.0875 15.5375C14.0125 14.8958 14.6833 14.05 15.1 13H4.9C5.31667 14.05 5.9875 14.8958 6.9125 15.5375C7.8375 16.1792 8.86667 16.5 10 16.5Z'
                fill='var(--main-color)'
              />
            </svg>
          </button>

          {showEmojiPicker && (
            <EmojiPicker
              width='91.5%'
              height='70%'
              onEmojiClick={onEmojiClick}
              autoFocusSearch={false}
              lazyLoadEmojis={true}
              emojiStyle={EmojiStyle.NATIVE}
            />
          )}
          <button className='private-chat__send__message__btn' onClick={() => handleSendMessage(message)}>
            Enviar
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrivateChat;
