import React from 'react'
import { Link } from 'react-router-dom'

const getLoginURLAndLogout = () => {
   const user = JSON.parse(localStorage.getItem('user'))

   localStorage.removeItem('user')
   localStorage.removeItem('athlete')
   localStorage.removeItem('docs')
   localStorage.removeItem('menu')
   localStorage.removeItem('sign')
   localStorage.removeItem('school')
   localStorage.removeItem('saldo')
   localStorage.removeItem('company')
   localStorage.removeItem('persist:root')

   if (
      user !== null && (
         user.role_id == 6 ||
         user.role_id == 7 ||
         user.role_id == 3 ||
         user.role_id == 7 ||
         user.role_id == 8 ||
         user.role_id == 9 ||
         user.role_id == 10 ||
         user.role_id == 11 ||
         user.role_id == 12 ||
         user.role_id == 13 ||
         user.role_id == 14 ||
         user.role_id == 15 ||
         user.role_id == 16 ||
         user.role_id == 17
      )) {
      return "/login-school"
   } else
      return "/"
}

const desconectar = () => {
   
   const clear = () => {
      localStorage.removeItem('user')
      localStorage.removeItem('athlete')
      localStorage.removeItem('docs')
      localStorage.removeItem('menu')
      localStorage.removeItem('sign')
      localStorage.removeItem('school')
      localStorage.removeItem('saldo')
      localStorage.removeItem('company')
      localStorage.removeItem('persist:root')
   } 

   const user = JSON.parse(localStorage.getItem('user'))

   if ( user !== null && user.role_id == 2 ) {
      return (
         <Link to="/" onClick={clear}>Desconectar</Link>
      )
   } else if( 
      user !== null && (
         user.role_id == 6 || 
         user.role_id == 7 || 
         user.role_id == 3 || 
         user.role_id == 7 || 
         user.role_id == 8 || 
         user.role_id == 9 || 
         user.role_id == 10 || 
         user.role_id == 11 || 
         user.role_id == 12 ||
         user.role_id == 13 || 
         user.role_id == 14 || 
         user.role_id == 15 || 
         user.role_id == 16 || 
         user.role_id == 17
      ) ){
      return (
         <Link to="/login-school" onClick={clear}>Desconectar</Link>
      )
   } else 
      return <></>
   
}

export default desconectar
export { getLoginURLAndLogout }