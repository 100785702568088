import React from 'react';
import Message from '../../../components/Message';
import ButtonRounded from '../../../components/buttons/ButtonRounded';
import Mensal from './Mensal';
import Matricula from './Matricula';
import useForm from '../../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import { StudentFormContext } from '../../../Context/StudentFormContext';
import TableResumeList from '../TableResumeList';
import moment from 'moment';
import { BtnDelete } from '../NewStudent';

import '../../../utils/extendsType';

const { MessageDangerMarginTop } = Message();

const normal = (state) => Object.keys(state).map((k) => ({ [k]: state[k].value }));

//TODO: usar o intl para traducao
const Cobranca = ({ nextTab }) => {
  const context = React.useContext(StudentFormContext);

  const { t } = useTranslation();

  const formRefer = React.useRef(null);

  const [errors, setErrors] = React.useState([]);

  const [sendSave, setSendSave] = React.useState(false);

  const [tipo, setTipo] = React.useState('1');

  const [errorsServer, setErrorsServe] = React.useState([]);

  const cycle_map = {
    MONTHLY: t('Monthly'),
    QUARTERLY: t('Quarterly'),
    SEMIANNUALLY: t('Semiannually'),
    YEARLY: t('Yearly'),
  };

  const payment_map = {
    CREDIT_CARD: t('Card'),
    BOLETO: t('Bill'),
    MONEY: t('Money'),
    CASH: t('Cash'),
    DEBIT: t('Debit'),
  };

  const handlerErrorServer = (err) => {
    if (err.response?.status === 422) {
      const { message = [] } = err.response.data;
      Object.keys(message).map((k) => {
        const element = document.querySelector(`[name=${k}]`);
        if (element) {
          dispatchHtmlValidity(element, `[${k}] ${message[k]}`);
        }
      });
    } else {
      setErrorsServe([err.toString()]);
    }
  };

  const onSubmitFormMatricula = (p, e, s, formData) => {
    const btn = e.target.loader();
    btn.start();

    if (formData.get('valorReceber') === '') {
      formData.set('valorReceber', p.valorOriginal);
    }

    const BILLING_TYPE = {
      'BOLETO.Parcelado': 'BOLETO_INTEGRATED_AMOUNT',
      'BOLETO.A vista': 'BOLETO_INTEGRATED',
      'CREDIT_CARD.Parcelado': 'CREDIT_CARD_INTEGRATED_AMOUNT',
      'CREDIT_CARD.A vista': 'CREDIT_CARD_INTEGRATED',
      'MONEY.Parcelado': 'MONEY',
      'MONEY.A vista': 'MONEY',
    };

    const billingType = BILLING_TYPE[`${p.paymentMethod}.${p.paymentModo}`];

    context
      .requestValidate('/validate/matricula', formData)
      .then((data) => {
        data.data.billingType = billingType;
        context.setState({ cobranca: { financas: [...context.cobranca.financas, ...data.data] } });
      })
      .catch(handlerErrorServer)
      .finally(() => {
        btn.reset();
      });
  };

  const onSubmitFormMensal = (p, e, s, formData) => {
    if (formData.get('valorReceber') === '') {
      formData.set('valorReceber', p.valorOriginal);
    }
    const btn = e.target.loader();
    btn.start();
    context
      .requestValidate('/validate/mensal', formData)
      .then((data) => {
        context.setState({ cobranca: { financas: [...context.cobranca.financas, { ...p, ...data.data }] } });
      })
      .catch(handlerErrorServer)
      .finally(() => {
        btn.reset();
      });
  };
  //cobranca
  const matriculaForm = useForm(
    context.matricula,
    validationStateSchemaMat,
    onSubmitFormMatricula,
    formRefer,
    setErrors
  );
  //contrato
  const mensalForm = useForm(context.mensal, validationStateSchema, onSubmitFormMensal, formRefer, setErrors);

  const dispatchHtmlValidity = (element, message) => {
    matriculaForm.dispatchHtmlValidity(element, message);
    mensalForm.dispatchHtmlValidity(element, message);
  };

  const handlerAdd = (e) => {
    if (tipo === '1') {
      matriculaForm.handleOnSubmit(e);
    } else if (tipo === '2') {
      mensalForm.handleOnSubmit(e);
    }
  };

  const handlerSubmit = (e) => {
    e.preventDefault();
    setSendSave(true);
    const formData = new FormData();

    formData.append('atleta', JSON.stringify(normal(context.atleta)));
    formData.append('matricula', JSON.stringify(context.cobranca.financas.filter((f) => f.type === 'Receita')));
    formData.append('mensal', JSON.stringify(context.cobranca.financas.filter((f) => f.type === 'Recorrente')));
    formData.append('turmas', JSON.stringify(normal(context.turmas)));

    context
      .requestValidate('/student/finacial/create', formData)
      .then((response) => {
        if (response.type === 'success') {
          nextTab(6);
        }
      })
      .catch(handlerErrorServer)
      .finally(() => setSendSave(false));
  };

  const removeFromList = (key) => {
    context.setState({
      cobranca: {
        financas: context.cobranca.financas.filter((item) => item.key !== key),
      },
    });
  };

  React.useEffect(() => {
    const elmain = document.querySelector('main');
    if (elmain && (errorsServer.length > 0 || errors.length > 0)) {
      elmain.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }
  }, [errorsServer, errors]);

  const columns = [
    {
      id: 'type',
      label: t('Type'),
      minWidth: 120,
      align: 'left',
      format: (value) => value,
    },
    {
      id: 'payment',
      label: t('Mode'),
      align: 'left',
      format: (value, r) => payment_map[value] || value,
    },
    {
      id: 'mode',
      label: `${t('BillingCycle')} / ${t('Installment_other')}`,
      align: 'left',
      format: (value, r) => (r.type === 'Recorrente' ? cycle_map[value] : r.quota),
    },
    {
      id: 'value',
      label: t('Value'),
      align: 'left',
      format: (value, r) =>
        r.type === 'Recorrente'
          ? t('FormatFunction.intlCurrencyWithOptions', { value })
          : t('FormatFunction.intlCurrencyWithOptions', { value: value * r.amount }),
    },
    {
      id: 'dueDate',
      label: t('DueDate'),
      align: 'left',
      format: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
    },
    {
      id: 'key',
      align: 'center',
      label: t('Action'),
      minWidth: 100,
      format: (value) => (
        <BtnDelete
          onClick={(e) => {
            e.preventDefault();
            removeFromList(value);
          }}
        />
      ),
    },
  ];
  return (
    <>
      {errors.map((err, k) => (
        <MessageDangerMarginTop
          key={`err-${k}`}
          title='Atenção!'
          description={t(`${err.error.replace(/\[.*?\]/g, '').trim()}`)}
        />
      ))}
      {errorsServer.map((item, k) => (
        <MessageDangerMarginTop title='Atenção!' description={item} key={`error_${k}`} />
      ))}
      <div className='mx-3'>
        <form ref={formRefer} action='' className='form_card' noValidate>
          <div className='col p-0 w-100'>
            <div className='row'>
              <div className='col-md-12 py-4'>
                <select
                  value={tipo}
                  onChange={(e) => {
                    setTipo(e.target.value);
                    setErrors([]);
                    formRefer.current.classList.remove('was-validated');
                  }}
                  className='form-control form_control select'
                  name='tipo'
                  id='forma_pagamento'
                  style={{ maxWidth: 320 }}
                >
                  <option value='1'>Matricula</option>
                  {context.turmas.classs.value.length > 0 && <option value='2'>Contrato</option>}
                </select>
                {context.turmas.classs.value.length === 0 && (<p class="font-weight-normal text-danger"> Não sera possivel criar um contrato. Selecione ao menos uma TURMA na aba anterior!</p>)}
              </div>
            </div>
            <div className='row'>
              {tipo === '1' && <Matricula useForm={matriculaForm} />}
              {tipo === '2' && <Mensal useForm={mensalForm} />}
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='py-2 w-100 d-flex justify-content-center d-print-none'>
                  <ButtonRounded className='mr-1' onClick={handlerAdd}>
                    {t('Add')}
                  </ButtonRounded>
                </div>
              </div>
            </div>

            <div className='card p-0 max-to-print'>
              <TableResumeList mxh={320} rows={context.cobranca.financas.unique('key')} columns={columns} />
            </div>
          </div>
        </form>
      </div>

      <div className='row align-items-center justify-content-center my-4'>
        <div className='col-md-5 col-sm-10'>
          <ButtonRounded isLoading={sendSave} onClick={handlerSubmit} className='w-100' isBig={true}>
            {t('Continue')}
          </ButtonRounded>
        </div>
      </div>
    </>
  );
};

const validationStateSchemaMat = {
  /* valorOriginal: {
    mask: (value) => {
      if (typeof value === 'undefined' || value == null) return '';
      value.replace(/\D/g, '');
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
  }, */
};

const validationStateSchema = {};
export default Cobranca;
