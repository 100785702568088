import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Badge from 'react-bootstrap/Badge';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Utils from '../../../src/utils/utils';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Footer from '../../components/club/Footer';
import MoneyMask from '../../components/inputs/MoneyMask';
import FinancialTable from '../../components/tables/FinancialTable';
import {
  authClient,
  cancelBillingAndDelete,
  getAsaasConfigSessionClub,
  receiveInCash,
  saveClient,
  sendBilling,
} from '../../services/PaymentService';
import { PaymentErrors } from '../../utils/PaymentErrors';
import Api from '../../utils/api';
import { calcFinanceValue, discoverBillCase, onBillCase } from '../../utils/utils-financial';
import ModalFinanceStakeholder from '../../views/ModalFinanceStakeholder';

const Financial = () => {
  const { t } = useTranslation();

  const alertRef = React.useRef(null);

  // indexs
  const [id, setID] = useState(null);
  const [company_id, setCompanyID] = useState('');
  const [user_id, setUserID] = useState('');
  const [configAsaas, setConfigAsaas] = useState({});

  /** @type {['UNSAVED' | 'UNBILLED' | 'CONTRACT_ATTACHED' | 'CONTRACT_DETACHED' | 'FINANCE_ATTACHED' | 'FINANCE_DETACHED', : React.Dispatch<React.SetStateAction<'UNSAVED' | 'UNBILLED' | 'CONTRACT_ATTACHED' | 'CONTRACT_DETACHED' | 'FINANCE_ATTACHED' | 'FINANCE_DETACHED'>>]} */
  const [financeType, setFinanceType] = useState(undefined);

  // inputs modal finaceiro
  const [modalSchoolClientFornecedor, setModalSchoolClientFornecedor] = useState(null);
  const [modalType, setModalType] = useState('');
  const [modalCountDescription, setModalCountDescription] = useState('');
  const [modalPayment, setModalPayment] = useState('');
  const [modalModo, setModalModo] = useState('');
  const [modalDueDate, setModalDueDate] = useState('');
  const [modalValue, setModalValue] = useState(null);
  const [modalAmount, setModalAmount] = useState('');
  const [modalReceived, setModalReceived] = useState(false);
  const [modalDatePayment, setModalDatePayment] = useState('');
  const [modalDocument, setModalDocument] = useState('');
  const [modalObservation, setModalObservation] = useState('');
  const [modalQuota, setModalQuota] = useState('');
  const [modalIDAsaas, setModalIDAsaas] = useState('');

  const [modalDiscountType, setModalDiscountType] = useState('NONE');
  const [modalDiscount, setModalDiscount] = useState(null);
  const [modalLateInterestType, setModalLateInterestType] = useState('NONE');
  const [modalLateInterest, setModalLateInterest] = useState(null);
  const [modalLateFeeType, setModalLateFeeType] = useState('NONE');
  const [modalLateFee, setModalLateFee] = useState(null);
  const [modalValueReceived, setModalValueReceived] = useState('');

  // carregamentos
  const [financials, setFinancials] = useState([]);
  const [listClienteFornecedor, setListClienteFornecedor] = useState([{ name: '' }]);

  // filtros
  const [filterSchoolClientFornecedor, setFilterSchoolClientFornecedor] = useState(null);
  const [filterType, setFilterType] = useState('');
  const [filterPayment, setFilterPayment] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterDueDateIni, setFilterDueDateIni] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [filterDueDateEnd, setFilterDueDateEnd] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [filterDateType, setFilterDateType] = useState('OVERDUE');
  const [display, setDisplay] = useState(false);
  const [displayClientFornecedor, setDisplayClientFornecedor] = useState(false);
  const [filteredFinancials, setFilteredFinancials] = useState([]);
  const [sortIcon, setSortIcon] = useState({ column: '', state: 'up' });

  // process
  const [process, setProcess] = useState(false);
  const [sendSave, setSendSave] = useState(false);
  const [isAllowedReceiveInCash, setIsAllowedReceiveInCash] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [message, setMessage] = useState('');
  const [warningFaturamento, setWarningFaturamento] = useState(false);
  const [inforFaturamento, setInfoFaturamento] = useState(false);

  // outros
  const [dirDoc, setDirDoc] = useState(false);
  const [valueR, setValueR] = useState(0);
  const [valueD, setValueD] = useState(0);
  const [countR, setCountR] = useState(0);
  const [countD, setCountD] = useState(0);
  const [showModalCharge, setShowModalCharge] = useState(false);

  // libraries
  const baseURL = Api();
  const { scrollTop, currencyFormatterText, validaCPF, validaCNPJ, compareStrings, compareDate } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  let modalScroll = useRef();

  useEffect(() => {
    const usuSession = JSON.parse(localStorage.getItem('user'));
    const companySession = JSON.parse(localStorage.getItem('company'));

    setUserID(usuSession.id);
    setCompanyID(companySession.id);
    getFinancials(companySession.id);
    setConfigAsaas(getAsaasConfigSessionClub(companySession));

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeModalClientFornecedor();
      closeModal();
    }
  };

  const getFinancials = async (company_id) => {
    setProcess(true);
    const res = await axios.post(baseURL + 'get-financials-club', {
      company_id,
      filterSchoolClientFornecedor,
      filterType,
      filterPayment,
      filterStatus,
      filterDueDateIni,
      filterDueDateEnd,
      filterDateType,
    });

    setDirDoc(res.data.dirDoc);
    setFinancials(res.data.financials);
    calcTotals(res.data.financials);

    setListClienteFornecedor(res.data.schoolsClientsFornecedors);

    setCountR(res.data.financials.filter((fin) => fin.type === 'Receita').length);
    setCountD(res.data.financials.filter((fin) => fin.type === 'Despesa').length);

    setProcess(false);
  };

  useEffect(() => {
    const { column, state } = sortIcon;
    const compareFunction = column === 'client' ? compareStrings : compareDate;

    if (state === 'up') {
      setFilteredFinancials(
        financials.sort((a, b) => compareFunction(a[column] ? a[column] : a.school, b[column] ? b[column] : b.school))
      );
    } else {
      setFilteredFinancials(
        financials.sort((b, a) => compareFunction(a[column] ? a[column] : a.school, b[column] ? b[column] : b.school))
      );
    }
  }, [financials, sortIcon]);

  const calcTotals = async (financials) => {
    const formatFat = (fat) => ({
      ...fat,
      valueCalculated: Number(fat.valueReceived)
        ? +fat.valueReceived
        : calcFinanceValue(
            fat.value,
            fat.received === 'S',
            fat.datePayment,
            fat.dueDate,
            { type: fat.discount_type, value: fat.discount },
            { type: fat.late_interest_type, value: fat.late_interest },
            { type: fat.late_fee_type, value: fat.late_fee }
          ),
    });

    setValueR(
      financials
        .filter((fin) => fin.type === 'Receita')
        .map(formatFat)
        .reduce((acc, current) => acc + current.valueCalculated, 0)
    );

    setValueD(
      financials
        .filter((fin) => fin.type === 'Despesa')
        .map(formatFat)
        .reduce((acc, current) => acc + current.valueCalculated, 0)
    );
  };

  const search = (e) => {
    e.preventDefault();
    setFilteredFinancials([]);
    getFinancials(company_id);
  };

  const closeModalClientFornecedor = () => {
    setDisplayClientFornecedor(false);
  };

  const newCount = () => {
    discoverCase(null);
    clearMessage();

    setDeleted(false);
    setSendSave(false);

    setID(null);
    setModalSchoolClientFornecedor(null);
    setModalType('');
    setModalCountDescription('');
    setModalPayment('');
    setModalModo('');
    setModalDueDate('');
    setModalValue('');
    setModalAmount('');
    setModalReceived('N');
    setModalDatePayment('');
    setModalObservation('');
    setModalQuota('');
    setModalDocument('');
    setModalIDAsaas('');
    scrollTop(modalScroll);

    setModalDiscountType('NONE');
    setModalDiscount('');
    setModalLateInterestType('NONE');
    setModalLateInterest('');
    setModalLateFeeType('NONE');
    setModalLateFee('');
    setModalValueReceived('');

    setIsAllowedReceiveInCash(false);

    setDisplay(true);
  };

  const closeModal = () => {
    setDisplay(false);
  };

  const edit = (financial) => {
    const payment =
      financial.payment_id_asaas && !financial.payment.includes('_INTEGRATED')
        ? financial.payment + '_INTEGRATED'
        : financial.payment;

    discoverCase(financial);
    clearMessage();
    setDeleted(false);
    setSendSave(false);
    setID(financial.id);
    setModalSchoolClientFornecedor(
      financial.client_fornecedor_id === null
        ? {
            id: financial.school_id,
            name: financial.school,
            cpf_cnpj: financial.schCnpj,
          }
        : {
            id: financial.client_fornecedor_id,
            name: financial.client,
            cpf_cnpj: financial.cpf_cnpj,
          }
    );
    setModalType(financial.type);
    setModalCountDescription(financial.countDescription);
    setModalPayment(payment);
    setModalModo(financial.mode);
    setModalDueDate(financial.dueDate);
    setModalValue(financial.value);
    setModalAmount(financial.amount);
    setModalReceived(financial.received);
    setModalDatePayment(financial.datePayment == null ? '' : financial.datePayment);
    setModalObservation(financial.observation || '');
    setModalQuota(financial.quota);
    setModalIDAsaas(financial.payment_id_asaas);
    setModalDocument(financial.document !== null ? dirDoc + '/' + financial.document : '');

    setModalDiscountType(financial.discount_type === null ? 'NONE' : financial.discount_type);
    setModalDiscount(financial.discount);
    setModalLateInterestType(financial.late_interest_type === null ? 'NONE' : financial.late_interest_type);
    setModalLateInterest(financial.late_interest);
    setModalLateFeeType(financial.late_fee_type === null ? 'NONE' : financial.late_fee_type);
    setModalLateFee(financial.late_fee);
    setModalValueReceived(financial.valueReceived);

    setIsAllowedReceiveInCash(false);

    scrollTop(modalScroll);
    setDisplay(true);
  };

  const handleValid = async (e) => {
    e.preventDefault();

    if (modalType === 'Receita' && modalPayment.includes('_INTEGRATED')) {
      const cpf_cnpj = modalSchoolClientFornecedor.cpf_cnpj;

      if (cpf_cnpj.length === 14 && !validaCNPJ(cpf_cnpj)) {
        showErrorMessage('O CNPJ informado é inválido');
      } else if (cpf_cnpj.length === 11 && !validaCPF(cpf_cnpj)) {
        showErrorMessage('O CPF informado é inválido');
      } else if (cpf_cnpj === null) {
        showErrorMessage('É obrigatório que o cliente ou fornecedor tenham um CPF ou CNPJ cadastrados.');
      } else if (id == null && new Date().toISOString().substr(0, 10) > modalDueDate) {
        showErrorMessage('O vencimento para receitas não pode ser menor que hoje.');
      } else if (modalSchoolClientFornecedor.email === null || modalSchoolClientFornecedor.email === '') {
        showErrorMessage('O email é obrigatório.');
      } else if (id !== null && isAllowedReceiveInCash) {
        handleReceiveInCash();
      } else if (id !== null) {
        handleSubmitSaveUpdate();
      } else {
        showWarningFaturamentoMessage();
      }
    } else if (isAllowedReceiveInCash) {
      handleReceiveInCash();
    } else {
      handleSubmitSaveUpdate();
    }

    scrollTop(modalScroll);
  };

  const handleSubmitSaveUpdate = async () => {
    clearMessage();

    let data = {
      client_fornecedor_id: modalSchoolClientFornecedor.school_id !== undefined ? modalSchoolClientFornecedor.id : null,
      school_id: modalSchoolClientFornecedor.school_id !== undefined ? null : modalSchoolClientFornecedor.id,
      type: modalType,
      countDescription: modalCountDescription,
      mode: modalModo,
      payment: modalPayment,
      dueDate: modalDueDate,
      value: modalValue,
      amount: id === null ? modalAmount : modalAmount || undefined,
      received: modalReceived,
      datePayment: modalDatePayment,
      observation: modalObservation,
      discount_type: modalDiscountType === 'NONE' ? null : modalDiscountType,
      discount: modalDiscount || null,
      late_interest_type: modalLateInterestType === 'NONE' ? null : modalLateInterestType,
      late_interest: modalLateInterest || null,
      late_fee_type: modalLateFeeType === 'NONE' ? null : modalLateFeeType,
      late_fee: modalLateFee || null,
      valueReceived: modalValueReceived || null,
    };
    let endpoint = '';

    if (id === null) {
      endpoint = 'save-financial-school';
      data = {
        ...data,
        user_id,
      };
    } else {
      endpoint = 'update-financial-school';
      data = {
        ...data,
        id,
      };
    }

    setSendSave(true);
    const res = await axios.post(baseURL + endpoint, data);
    setSendSave(false);

    setID(res.data.id);
    getFinancials(company_id);

    showSuccessMessage(res.data.message);

    closeModal();
  };

  const handleDeleteCount = async () => {
    clearMessage();

    setDeleted(true);
    scrollTop(modalScroll);
    const { data } = await axios.post(baseURL + 'delete-financial-school', { id });

    setFinancials([]);
    getFinancials(company_id);

    closeModal();
    setDeleted(false);
    setSuccess(true);
    setMessage(data.message);
  };

  const handleFaturar = async () => {
    try {
      clearMessage();
      if (!(configAsaas.cnpj && configAsaas.password && configAsaas.token_assas && configAsaas.wallet)) {
        throw new Error(PaymentErrors.CLUB_ASAAS_CONFIG_INVALID);
      }

      setSendSave(true);

      const resAuth = await authClient({
        cnpj: configAsaas.cnpj,
        password: configAsaas.password,
        loginEmail: configAsaas.loginEmail,
      });

      const customer = !['C', 'F'].includes(modalSchoolClientFornecedor.type)
        ? {
            cpf_cnpj: modalSchoolClientFornecedor.cpf_cnpj,
            name: modalSchoolClientFornecedor.name,
            email: modalSchoolClientFornecedor.email,
            phone: modalSchoolClientFornecedor.schPhone,

            postalCode: modalSchoolClientFornecedor.schCep,
            address: modalSchoolClientFornecedor.schLogradouro,
            addressNumber: modalSchoolClientFornecedor.schNumero,
            province: modalSchoolClientFornecedor.schBairro,
          }
        : {
            cpf_cnpj: modalSchoolClientFornecedor.cpf_cnpj,
            name: modalSchoolClientFornecedor.name,
            email: modalSchoolClientFornecedor.email,
            phone: modalSchoolClientFornecedor.phone,

            postalCode: modalSchoolClientFornecedor.zipCode,
            address: modalSchoolClientFornecedor.streetName,
            addressNumber: modalSchoolClientFornecedor.streetNumber,
            complement: modalSchoolClientFornecedor.complement,
            province: modalSchoolClientFornecedor.neighborhood,
          };

      // envia o cliente para obter o customer_id
      const resCli = await saveClient({
        cpf_cnpj: customer.cpf_cnpj,
        name: customer.name,
        email: customer.email,
        phone: customer.phone,
        customer: '',
        system_id: configAsaas.system_id,
        user_id: resAuth.user_id,
        token_assas: configAsaas.token_assas,

        postalCode: customer.postalCode,
        address: customer.address,
        addressNumber: customer.addressNumber,
        complement: customer.complement,
        province: customer.province,
      });

      let data = {
        billingType: modalPayment === 'Boleto_INTEGRATED' ? 'BOLETO' : 'CREDIT_CARD',
        customer: resCli.data.customer,
        dueDate: modalDueDate,
        description: modalCountDescription,
        postalService: false,
        client_id: resCli.data.id,
        token_assas: configAsaas.token_assas,
        financial: {
          company_id,
          user_id,
          client_fornecedor_id:
            modalSchoolClientFornecedor.school_id !== undefined ? modalSchoolClientFornecedor.id : null,
          school_id: modalSchoolClientFornecedor.school_id !== undefined ? null : modalSchoolClientFornecedor.id,
          type: modalType,
          countDescription: modalCountDescription,
          mode: modalModo,
          payment: modalPayment.replace('_INTEGRATED', ''),
          dueDate: modalDueDate,
          amount: modalAmount,
          received: modalReceived,
          datePayment: modalDatePayment,
          observation: modalObservation,
        },
      };

      if (configAsaas.percentual) {
        data.split = [
          {
            walletId: configAsaas.wallet,
            percentualValue: configAsaas.percentual,
          },
        ];
      } else if (configAsaas.valor) {
        data.split = [
          {
            walletId: configAsaas.wallet,
            fixedValue: configAsaas.valor,
          },
        ];
      }

      if (modalDiscount) {
        data.discount = { value: modalDiscount, dueDateLimitDays: 0, type: modalDiscountType };
      }

      if (modalLateInterest) {
        data.interest = { value: modalLateInterest, type: modalLateInterestType };
      }

      if (modalLateFee) {
        data.fine = { value: modalLateFee, type: modalLateFeeType };
      }

      if (modalAmount === 1) {
        data.value = modalValue;
        data.installmentCount = '';
        data.installmentValue = '';
      } else {
        data.installmentCount = modalAmount;
        data.installmentValue = modalValue;
      }

      // envia pagamento
      await sendBilling({
        paymentMethod: modalPayment === 'Boleto_INTEGRATED' ? 'BOLETO' : 'CREDIT_CARD',
        paymentType: modalAmount === 1 ? 'A_VISTA' : 'PARCELADO',
        billingData: data,
      });

      setSendSave(false);
      showSuccessMessage('Cobrança agendada com sucesso. O cliente será cobrado em todo vencimento...');

      getFinancials(company_id);

      setTimeout(() => {
        closeModal();
      }, 3000);
    } catch (error) {
      setSendSave(false);

      handlePaymentErrors(error);
    }
  };

  const handlePaymentErrors = (error) => {
    switch (error.message) {
      case PaymentErrors.CLUB_ASAAS_CONFIG_INVALID:
        showErrorMessage('Este clube deve ser parametrizado pela equipe Fensor. Entre em contato.');
        console.log('Configuração do ASAAS inválida.');
        break;

      case PaymentErrors.SERVER_COMMUNICATION_FAILED:
        showErrorMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...');
        break;

      case PaymentErrors.INVALID_API_AUTH:
        showErrorMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...');
        break;

      case PaymentErrors.UNABLE_SEND_CUSTOMER:
        showErrorMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...');
        break;

      case PaymentErrors.CUSTOMER_VALIDATION_ERROR:
        showErrorMessage(error.auxData);
        break;

      case PaymentErrors.UNABLE_VALIDATE_PAYMENT:
        showErrorMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...');
        console.log('Nao foi possível validar o pagamento.');
        break;

      case PaymentErrors.BILL_STATUS_ERROR:
        showErrorMessage('Pagamento não aprovado, tente novamente...');
        break;

      case PaymentErrors.BILL_ERROR:
        showErrorMessage(error.auxData);
        break;

      default:
        showErrorMessage('Erro interno. Por favor, recarregue a página e tente novamente.');
    }
  };

  // const handleFaturarDinheiro = async () => {};

  const handleDeleteCountFaturamento = async () => {
    try {
      // requisita o cancelamento
      showLoading();
      await cancelBillingAndDelete({
        idAsaas: modalIDAsaas,
        token_assas: configAsaas.token_assas,
        financial_id: id,
      });

      showSuccessMessage('Conta e cobrança excluída.');
      setFinancials([]);
      getFinancials(company_id);

      closeModal();
    } catch (error) {
      showErrorMessage('Conta não excluída, tente novamente mais tarde.');
    }
  };

  const allowReceiveInCash = () => {
    if (modalReceived == 'N') {
      setIsAllowedReceiveInCash(true);
      setModalReceived('S');
    }
  };

  const handleReceiveInCash = async () => {
    try {
      clearMessage();

      if (!(configAsaas.cnpj && configAsaas.password && configAsaas.token_assas && configAsaas.wallet)) {
        throw new Error(PaymentErrors.CLUB_ASAAS_CONFIG_INVALID);
      }

      setSendSave(true);
      const { status, message, statusCode } = await receiveInCash({
        id: id,
        idAsaas: modalIDAsaas,
        paymentDate: modalDatePayment,
        value: modalValue,
        token_assas: configAsaas.token_assas,
      });
      setSendSave(false);

      if (status === 'SUCCESS') {
        showSuccessMessage(message);
        getFinancials(company_id);

        setTimeout(() => {
          closeModal();
        }, 3000);
      } else {
        if (statusCode === 400) {
          setShowModalCharge(true);
        } else {
          showErrorMessage(message);
        }
      }
    } catch (error) {
      setSendSave(false);
      handlePaymentErrors(error);
    }
  };

  const handleSubmitClientFornecedor = async (formValues) => {
    try {
      if (formValues.cpf_cnpj.length === 14 && !validaCNPJ(formValues.cpf_cnpj)) {
        throw new Error('O CNPJ informado é inválido');
      } else if (formValues.cpf_cnpj.length === 11 && !validaCPF(formValues.cpf_cnpj)) {
        throw new Error('O CPF informado é inválido');
      }
      if (formValues.streetName.length > 50) {
        throw new Error('SystemMessage.Fail.LongAdress');
      } else {
        let endpoint = '';
        let body = {
          company_id,

          name: formValues.name,
          cpf_cnpj: formValues.cpf_cnpj,
          phone: formValues.phone,
          email: formValues.email,
          type: formValues.type,

          zipCode: formValues.zipCode,
          streetName: formValues.streetName,
          streetNumber: formValues.streetNumber,
          complement: formValues.complement,
          neighborhood: formValues.neighborhood,
          city_id: formValues.cityId,
        };
        if (formValues.id === null) {
          endpoint = 'save-client-fornecedor-club';
          body = {
            ...body,
            user_id,
          };
        } else {
          endpoint = 'update-client-fornecedor-club';
          body = {
            ...body,
            id: formValues.id,
          };
        }

        const { data } = await axios.post(baseURL + endpoint, body);

        setListClienteFornecedor(data.schoolsClientsFornecedors);

        return {
          success: true,
          message: data.message,
          data: data.create,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  };

  useEffect(() => {
    if (modalPayment === 'Dinheiro') {
      setModalModo('A vista');
    }
  }, [modalPayment]);

  useEffect(() => {
    setModalAmount(modalModo === 'A vista' ? 1 : '');
  }, [modalModo]);

  const discoverCase = (financial) => discoverBillCase(financial, setFinanceType, 'CLUB');

  /**
   *
   * @param {{ unsaved: boolean, unbilled: boolean, contractAttached: boolean, contractDetached: boolean, financeAttached: boolean, financeDetached: boolean }?} cases
   * @returns {boolean}
   */
  const onCase = (cases) => onBillCase(cases, financeType);

  const clearMessage = () => {
    setMessage('');
    setError(false);
    setSuccess(false);
    setWarningFaturamento(false);
    setInfoFaturamento(false);
    setSendSave(false);
  };

  const showErrorMessage = (message) => {
    clearMessage();
    setError(true);
    setMessage(message);
    scrollTop(modalScroll);
  };
  const showSuccessMessage = (message) => {
    clearMessage();
    setSuccess(true);
    setMessage(message);
    scrollTop(modalScroll);
  };

  const showWarningFaturamentoMessage = () => {
    clearMessage();
    setWarningFaturamento(true);
    scrollTop(modalScroll);
  };

  const showInfoFaturamentoMessage = () => {
    clearMessage();
    setInfoFaturamento(true);
    scrollTop(modalScroll);
  };

  const showLoading = () => {
    clearMessage();
    setSendSave(true);
  };

  const handlePaidCharge = async () => {
    try {
      const response = await axios.post(baseURL + 'update-financial-club', {
        id,
        datePayment: modalDatePayment,
        valueReceived: modalValue,
        payment_clientPayment: modalDatePayment,
        payment_status: 'Aprovado',
        received: 'S',
      });

      setShowModalCharge(false);

      if (response.status === 200) {
        showSuccessMessage('Baixa Realizada com sucesso!');
        getFinancials(company_id);

        setTimeout(() => {
          closeModal();
        }, 3000);
      } else {
        showErrorMessage(response?.data?.message);
      }
    } catch (error) {
      handlePaymentErrors(error);
    }
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            {/*   */}

            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Adicionar conta</h2>
                    <p className='card_subtitle'>Clique abaixo para adicionar uma conta.</p>
                  </div>
                  <button onClick={newCount} className='btn_card mx-auto mt-4 btn_open_modal' data-modal='#noticia'>
                    <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                    </svg>
                    Adicionar conta
                  </button>
                </div>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Procurar</h2>
                    <p className='card_subtitle'>Filtrar as contas a receber e a pagar.</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label className='card_details_label'>Cliente ou Fornecedor</label>
                      <Autocomplete
                        noOptionsText='Nenhum cliente ou fornecedor com este nome...'
                        loadingText='Carregando...'
                        options={listClienteFornecedor.map(({ name }) => name)}
                        style={{ width: '100%', zIndex: 0 }}
                        value={filterSchoolClientFornecedor}
                        onChange={(event, newValue) => {
                          setFilterSchoolClientFornecedor(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label='Digite o nome...' variant='outlined' />}
                      />
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label'>Tipos</label>
                      <select
                        className='form_control select'
                        value={filterType}
                        onChange={(e) => setFilterType(e.target.value)}
                      >
                        <option value=''>Todos</option>
                        <option value='Receita'>Receita</option>
                        <option value='Despesa'>Despesa</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label'>Formas de Pagamento</label>
                      <select
                        className='form_control select'
                        value={filterPayment}
                        onChange={(e) => setFilterPayment(e.target.value)}
                      >
                        <option value=''>Todos</option>
                        <option value='Dinheiro'>Dinheiro</option>
                        <option value='Cartão'>Cartão</option>
                        <option value='Boleto'>Boleto</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label'>Recebido</label>
                      <select
                        className='form_control select'
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value=''>Todos</option>
                        <option value='N'>Não</option>
                        <option value='S'>Sim</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label'>{t('FilterByDate')}</label>
                      <select
                        className='form_control select'
                        value={filterDateType}
                        onChange={(e) => setFilterDateType(e.target.value)}
                      >
                        <option value='OVERDUE'>{t('DueDate')}</option>
                        <option value='PAYDAY'>{t('PayDay')}</option>
                      </select>
                    </div>
                    {filterDateType === 'OVERDUE' && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className='group_50' style={{ width: '-webkit-calc(52% - 15px)' }}>
                          <div className='form_group'>
                            <label className='card_details_label'>{t('InitialOverdue')}</label>
                            <input
                              type='date'
                              className='form_control'
                              value={filterDueDateIni}
                              onChange={(e) => setFilterDueDateIni(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className='group_50' style={{ width: '-webkit-calc(52% - 15px)' }}>
                          <div className='form_group'>
                            <label className='card_details_label'>{t('EndOverdue')}</label>
                            <input
                              type='date'
                              className='form_control'
                              value={filterDueDateEnd}
                              onChange={(e) => setFilterDueDateEnd(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {filterDateType === 'PAYDAY' && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className='group_50' style={{ width: '-webkit-calc(52% - 15px)' }}>
                          <div className='form_group'>
                            <label className='card_details_label'>{t('InitialPaymentDate')}</label>
                            <input
                              type='date'
                              className='form_control'
                              value={filterDueDateIni}
                              onChange={(e) => setFilterDueDateIni(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className='group_50' style={{ width: '-webkit-calc(52% - 15px)' }}>
                          <div className='form_group'>
                            <label className='card_details_label'>{t('EndPaymentDate')}</label>
                            <input
                              type='date'
                              className='form_control'
                              value={filterDueDateEnd}
                              onChange={(e) => setFilterDueDateEnd(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {process && (
                      <button className='btn_card mx-auto' style={{ background: '#fdcc06', color: '#1c842c' }}>
                        <Loading type='1' />
                      </button>
                    )}
                    {!process && (
                      <button className='btn_card mx-auto' onClick={search}>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        Buscar
                      </button>
                    )}
                  </form>
                </div>
                <div className='card'>
                  <div className='card_header mx-auto' style={{ alignItems: 'center' }}>
                    <h2 className='card_title'>contas a receber</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>{valueR == 0 ? 'R$ 0,00' : currencyFormatterText(valueR)}</div>
                    )}
                  </div>
                  <hr />
                  <div className='card_header mx-auto' style={{ alignItems: 'center' }}>
                    <h2 className='card_title'>contas a pagar</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>{valueD == 0 ? 'R$ 0,00' : currencyFormatterText(valueD)}</div>
                    )}
                  </div>
                  <hr />
                  <div className='card_header mx-auto' style={{ alignItems: 'center' }}>
                    <h2 className='card_title'>saldo no período</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>
                        {valueR - valueD == 0 ? 'R$ 0,00' : currencyFormatterText(valueR - valueD)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Resultado da pesquisa</h2>
                    <p className='card_subtitle'>Veja abaixo os contratos cadastrados.</p>
                  </div>
                  <div className='list_card'>
                    {process && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {
                      <Tabs>
                        <TabList>
                          <Tab style={{ fontSize: 12.5 }}>
                            Receitas{' '}
                            <Badge pill variant='success' className='badge-font-size-90'>
                              {countR}
                            </Badge>
                          </Tab>
                          <Tab style={{ fontSize: 12.5 }}>
                            Despesas{' '}
                            <Badge pill variant='danger' className='badge-font-size-90'>
                              {countD}
                            </Badge>
                          </Tab>
                        </TabList>

                        <TabPanel>
                          <div style={{ overflowX: 'auto' }}>
                            <FinancialTable
                              type='INCOME'
                              isLoading={process}
                              onEdit={edit}
                              sortIconState={{ get: sortIcon, set: setSortIcon }}
                              finances={filteredFinancials.filter((finance) => finance.type === 'Receita')}
                            />
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div style={{ overflowX: 'auto' }}>
                            <FinancialTable
                              type='EXPENSE'
                              isLoading={process}
                              onEdit={edit}
                              sortIconState={{ get: sortIcon, set: setSortIcon }}
                              finances={filteredFinancials.filter((finance) => finance.type === 'Despesa')}
                            />
                          </div>
                        </TabPanel>
                      </Tabs>
                    }
                  </div>
                </div>
              </div>
              <div
                className='modal'
                id='add_noticia_comunicado'
                style={display ? { display: 'flex', opacity: 1 } : undefined}
              >
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeModal}>
                    X
                  </div>
                  <h3 style={{ alignItems: 'flex-end', display: 'flex' }} className='modal_title'>
                    {id === null ? 'Criar nova' : 'Alterar'} conta
                    {modalQuota !== '' && (
                      <Badge
                        pill
                        variant='secondary'
                        style={{
                          fontWeight: 500,
                          textTransform: 'capitalize',
                          paddingTop: '10px',
                          paddingBottom: '10px',
                          fontSize: '0% !important',
                          fontFamily: 'sans-serif',
                          marginLeft: '10px',
                        }}
                      >
                        {modalQuota}
                      </Badge>
                    )}
                  </h3>
                  {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
                  {success && <MessageSuccessMarginTop title='Sucesso! ' description={message} />}
                  {deleted && (
                    <div
                      style={{
                        alignItems: 'center',
                        justifyContent: 'initial',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          marginTop: 5,
                        }}
                      >
                        aguarde, estamos excluindo...
                        <Loading
                          type='1'
                          style={{
                            marginLeft: 5,
                            color: 'green',
                            borderWidth: '.1em',
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {warningFaturamento && (
                    <div className='alert alert-margin-top alert-warning alert-dismissible fade show' role='alert'>
                      <strong>Atenção! </strong>
                      Após faturar, será agendado as cobranças deste Cliente e não será permitido alterações nesta
                      conta. <br />
                      <br />
                      Deseja continuar ?{' '}
                      <strong style={{ cursor: 'pointer' }} onClick={handleFaturar}>
                        se sim, clique aqui!
                      </strong>
                    </div>
                  )}
                  {inforFaturamento && (
                    <div className='alert alert-margin-top alert-info alert-dismissible fade show' role='alert'>
                      <strong>Info! </strong>
                      Esta conta foi faturada, o cliente recebe a cobrança em todo vencimento. <br />
                      <br />
                      Deseja realmente excluir esta cobrança ?{' '}
                      <strong style={{ cursor: 'pointer' }} onClick={handleDeleteCountFaturamento}>
                        se sim, clique aqui!
                      </strong>
                    </div>
                  )}
                  {sendSave && (
                    <p style={{ textAlign: 'center' }}>
                      Aguarde, processando... <Loading type='4' style={{ color: 'green' }} />
                    </p>
                  )}
                  <form
                    action=''
                    autoComplete='new-password'
                    onSubmit={handleValid}
                    method='post'
                    className='form_modal'
                  >
                    <div className='group_50'>
                      <div className='form_group'>
                        <label
                          className='card_details_label'
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          Cliente
                          <span
                            style={{
                              marginLeft: 10,
                              color: '#841202',
                              cursor: 'pointer',
                            }}
                            onClick={() => setDisplayClientFornecedor(true)}
                          >
                            criar ou atualizar cliente
                          </span>
                        </label>
                        <Autocomplete
                          required
                          autoComplete={false}
                          disabled={onCase()}
                          noOptionsText='Nenhum cliente ou fornecedor com este nome...'
                          loadingText='Carregando...'
                          options={listClienteFornecedor}
                          getOptionLabel={({ name }) => name}
                          getOptionSelected={(option, value) => option.id === value.id && option.type === value.type}
                          style={{ width: '100%' }}
                          value={modalSchoolClientFornecedor}
                          onChange={(event, newValue) => {
                            setModalSchoolClientFornecedor(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off',
                                required: true,
                              }}
                              label='Digite o nome...'
                              variant='outlined'
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Tipo</label>
                        <select
                          required
                          value={modalType}
                          disabled={onCase()}
                          className='form_control select'
                          onChange={(e) => setModalType(e.target.value)}
                        >
                          <option value='' disabled></option>
                          <option value='Receita'>Receita</option>
                          <option value='Despesa'>Despesa</option>
                        </select>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label' style={{ flexDirection: 'row' }}>
                          Conta do lançamento
                        </label>
                        <input
                          type='text'
                          required
                          className='form_control'
                          value={modalCountDescription}
                          onChange={(e) => setModalCountDescription(e.target.value)}
                        />
                        {/* <select
                          required
                          disabled={onCase()}
                          className={`form_control select`}
                          value={modalCountDescription}
                          onChange={(e) => setModalCountDescription(e.target.value)}
                        >
                          <option value='' disabled></option>
                          {financialAccountOptions.map((option) => (
                            <option key={`School_Financial_CountDescription_${option}`} value={option}>
                              {option}
                            </option>
                          ))}
                          {modalInitialCountDescription !== null && (
                            <option value={modalInitialCountDescription} disabled>
                              {modalInitialCountDescription}
                            </option>
                          )}
                        </select> */}
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Forma de pagamento</label>
                        <select
                          required
                          disabled={onCase()}
                          className={`form_control select`}
                          value={modalPayment}
                          onChange={(e) => setModalPayment(e.target.value)}
                        >
                          <option value='' disabled></option>
                          <option value='Dinheiro'>Dinheiro</option>
                          <option value='Cartão'>Cartão</option>
                          <option value='Boleto'>Boleto</option>

                          <option
                            value='Cartão_INTEGRATED'
                            disabled={!(!!configAsaas.token_assas && modalType === 'Receita')}
                          >
                            {t('Card')} ({t('Integrated')})
                          </option>

                          <option
                            value='Boleto_INTEGRATED'
                            disabled={!(!!configAsaas.token_assas && modalType === 'Receita')}
                          >
                            {t('Bill')} ({t('Integrated')})
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Modo</label>
                        <select
                          required
                          disabled={onCase()}
                          className={`form_control select`}
                          value={modalModo}
                          onChange={(e) => setModalModo(e.target.value)}
                        >
                          <option value='' disabled></option>
                          <option value='A vista'>A vista</option>
                          {(configAsaas.token_assas || modalType == 'Despesa') && modalPayment != 'Dinheiro' && (
                            <option value='Parcelado'>Parcelado</option>
                          )}

                          {!configAsaas.token_assas && modalType == 'Receita' && modalPayment != 'Dinheiro' && (
                            <option value='Parcelado' disabled>
                              Parcelado (Somente integrado)
                            </option>
                          )}

                          {modalModo == 'Assinatura' && (
                            <option value='Assinatura' disabled>
                              Assinatura do Contrato (Somente integrado)
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Data do Vencimento</label>
                        <input
                          type='date'
                          required
                          className='form_control'
                          disabled={onCase({
                            unsaved: false,
                            unbilled: false,
                            contractAttached: true,
                            contractDetached: true,
                            financeAttached: true,
                            financeDetached: false,
                          })}
                          value={modalDueDate}
                          onChange={(e) => setModalDueDate(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('DiscountType')}</label>
                          <select
                            className={`form_control select`}
                            value={modalDiscountType}
                            onChange={(e) => {
                              setModalDiscountType(e.target.value);
                              setModalDiscount(null);
                            }}
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                          >
                            <option value='NONE'>{t('NoDiscount')}</option>
                            <option value='PERCENTAGE'>{t('Percentage')}</option>
                            <option value='FIXED'>{t('Fixed')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('DiscountAmount')}</label>
                          <NumberFormat
                            required
                            className='form_control'
                            displayType={'input'}
                            placeholder={
                              modalDiscountType === 'FIXED'
                                ? t('PlaceholderMoneyZero')
                                : modalDiscountType === 'PERCENTAGE'
                                ? t('PlaceholderPercentageZero')
                                : ''
                            }
                            format={(e) =>
                              modalDiscountType === 'FIXED'
                                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                                : modalDiscountType === 'PERCENTAGE'
                                ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                                : ''
                            }
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                            readOnly={modalDiscountType === 'NONE'}
                            isAllowed={({ floatValue }) =>
                              modalDiscountType === 'PERCENTAGE' ? floatValue < 10000 : true
                            }
                            value={modalDiscount * 100}
                            onValueChange={(e) => setModalDiscount(e.floatValue / 100 || undefined)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>Valor parcela sem desconto</label>
                          <MoneyMask
                            required
                            value={modalValue}
                            onChange={setModalValue}
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>Número de Parcelas</label>
                          <select
                            required
                            disabled={onCase()}
                            className={`form_control select`}
                            value={modalAmount}
                            onChange={(e) => setModalAmount(e.target.value)}
                          >
                            <option value='' disabled></option>
                            {modalModo === 'A vista' && <option value='1'>01 (a vista)</option>}
                            {modalModo === 'Parcelado' && (
                              <>
                                <option value='2'>02</option>
                                <option value='3'>03</option>
                                <option value='4'>04</option>
                                <option value='5'>05</option>
                                <option value='6'>06</option>
                                <option value='7'>07</option>
                                <option value='8'>08</option>
                                <option value='9'>09</option>
                                <option value='0'>10</option>
                                <option value='11'>11</option>
                                <option value='12'>12</option>
                              </>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('LateInterestType')}</label>
                          <select
                            className={`form_control select`}
                            value={modalLateInterestType}
                            onChange={(e) => {
                              setModalLateInterestType(e.target.value);
                              setModalLateInterest(null);
                            }}
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                          >
                            <option value='NONE'>{t('NoLateInterest')}</option>
                            <option value='PERCENTAGE'>{t('Percentage')}</option>
                            <option value='FIXED'>{t('Fixed')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('LateInterestAmount')}</label>
                          <NumberFormat
                            className='form_control'
                            displayType={'input'}
                            placeholder={
                              modalLateInterestType === 'FIXED'
                                ? t('PlaceholderMoneyZero')
                                : modalLateInterestType === 'PERCENTAGE'
                                ? t('PlaceholderPercentageZero')
                                : ''
                            }
                            format={(e) =>
                              modalLateInterestType === 'FIXED'
                                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                                : modalLateInterestType === 'PERCENTAGE'
                                ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                                : ''
                            }
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                            readOnly={modalLateInterestType === 'NONE'}
                            isAllowed={({ floatValue }) =>
                              modalLateInterestType === 'PERCENTAGE' ? floatValue < 10000 : true
                            }
                            value={modalLateInterest * 100 || null}
                            onValueChange={(e) => setModalLateInterest(e.floatValue / 100 || undefined)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('LateFeeType')}</label>
                          <select
                            className={`form_control select`}
                            value={modalLateFeeType}
                            onChange={(e) => {
                              setModalLateFeeType(e.target.value);
                              setModalLateFee(null);
                            }}
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                          >
                            <option value='NONE'>{t('NoLateFee')}</option>
                            <option value='PERCENTAGE'>{t('Percentage')}</option>
                            <option value='FIXED'>{t('Fixed')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('LateFeeAmount')}</label>
                          <NumberFormat
                            className='form_control'
                            displayType={'input'}
                            placeholder={
                              modalLateFeeType === 'FIXED'
                                ? t('PlaceholderMoneyZero')
                                : modalLateFeeType === 'PERCENTAGE'
                                ? t('PlaceholderPercentageZero')
                                : ''
                            }
                            format={(e) =>
                              modalLateFeeType === 'FIXED'
                                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                                : modalLateFeeType === 'PERCENTAGE'
                                ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                                : ''
                            }
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                            readOnly={modalLateFeeType === 'NONE'}
                            isAllowed={({ floatValue }) =>
                              modalLateFeeType === 'PERCENTAGE' ? floatValue < 10000 : true
                            }
                            value={modalLateFee * 100}
                            onValueChange={(e) => setModalLateFee(e.floatValue / 100 || undefined)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('Received')}</label>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              height: '100%',
                            }}
                          >
                            <div
                              className='group_50'
                              style={{
                                alignItems: 'center',
                                justifyContent: 'start',
                              }}
                            >
                              <input
                                type='radio'
                                checked={modalReceived === 'S' ? 'checked' : ''}
                                disabled={onCase({
                                  unsaved: false,
                                  unbilled: false,
                                  contractAttached: true,
                                  contractDetached: false,
                                  financeAttached: true,
                                  financeDetached: false,
                                })}
                                name='received'
                                className='form_control'
                                style={{ width: '15px', cursor: 'pointer', height: '15px' }}
                                value='S'
                                onChange={(e) => {
                                  const today = moment().format('YYYY-MM-DD');
                                  setModalReceived(e.target.value);
                                  setModalValueReceived(
                                    calcFinanceValue(
                                      modalValue,
                                      true,
                                      today,
                                      modalDueDate,
                                      { type: modalDiscountType, value: modalDiscount },
                                      { type: modalLateInterestType, value: modalLateInterest },
                                      { type: modalLateFeeType, value: modalLateFee }
                                    )
                                  );
                                  setModalDatePayment(today);
                                }}
                              />{' '}
                              <span className='lowercase' style={{ margin: '0 10px' }}>
                                {t('Yes')}
                              </span>
                            </div>
                            <div
                              className='group_50'
                              style={{
                                alignItems: 'center',
                                justifyContent: 'start',
                              }}
                            >
                              <input
                                type='radio'
                                checked={modalReceived === 'N' ? 'checked' : ''}
                                disabled={onCase({
                                  unsaved: false,
                                  unbilled: false,
                                  contractAttached: true,
                                  contractDetached: false,
                                  financeAttached: true,
                                  financeDetached: false,
                                })}
                                name='received'
                                className='form_control'
                                style={{ width: '15px', cursor: 'pointer', height: '15px' }}
                                value='N'
                                onChange={(e) => {
                                  setModalReceived(e.target.value);
                                  setModalValueReceived(null);
                                  setModalDatePayment(null);
                                }}
                              />{' '}
                              <span className='lowercase' style={{ margin: '0 10px' }}>
                                {t('Not')}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('AmountReceived')}</label>
                          <NumberFormat
                            className='form_control'
                            displayType={'input'}
                            format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })}
                            disabled={
                              (onCase({
                                unsaved: false,
                                unbilled: false,
                                contractAttached: true,
                                contractDetached: false,
                                financeAttached: true,
                                financeDetached: false,
                              }) ||
                                modalReceived === 'N') &&
                              !isAllowedReceiveInCash
                            }
                            readOnly={modalReceived == 'N'}
                            value={modalValueReceived * 100 || ''}
                            onValueChange={(e) => setModalValueReceived(e.floatValue / 100 || '')}
                            required={
                              (modalPayment === 'Dinheiro' && modalReceived === 'S') || isAllowedReceiveInCash
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Data do Pagamento</label>
                        <input
                          type='date'
                          className='form_control'
                          disabled={
                            (onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: false,
                              financeAttached: true,
                              financeDetached: false,
                            }) ||
                              modalReceived === 'N') &&
                            !isAllowedReceiveInCash
                          }
                          value={modalDatePayment}
                          onChange={(e) => setModalDatePayment(e.target.value)}
                          required={
                            (modalPayment === 'Dinheiro' && modalReceived === 'S') || isAllowedReceiveInCash
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                    {id !== null && (
                      <React.Fragment>
                        <div className='group_50' style={{ width: '65%' }}>
                          <div className='form_group'>
                            <label htmlFor='anexo' className='card_details_label'>
                              Anexo
                            </label>
                            <input
                              type='file'
                              name='files[]'
                              id='anexo'
                              style={{
                                display: 'block',
                                padding: '13px',
                                color: modalDocument !== '' ? 'white' : '#757575',
                              }}
                              className='form_control'
                              onChange={(e) => setModalDocument(e.target.value)}
                            />
                          </div>
                        </div>
                        {modalDocument === '' && (
                          <div className='group_50' style={{ width: '35%' }}>
                            <div className='form_group'>
                              <label className='card_details_label'>&nbsp;</label>
                              <input
                                style={{
                                  background: '#fff',
                                  textAlign: 'center',
                                  color: '#757575',
                                  borderRadius: '0 4px 4px',
                                  fontWeight: 'bold',
                                }}
                                disabled
                                className='form_control'
                                value='sem documento'
                              />
                            </div>
                          </div>
                        )}
                        {modalDocument !== '' && (
                          <div className='group_50' style={{ width: '35%' }}>
                            <div className='form_group'>
                              <label className='card_details_label'>&nbsp;</label>
                              <a
                                href={modalDocument}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  background: '#FFCB08',
                                  textAlign: 'center',
                                  color: '#1b7c2c',
                                  borderRadius: '0 4px 4px',
                                  fontWeight: 'bold',
                                  cursor: 'pointer',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                                className='form_control'
                              >
                                documento anexado, imprimir
                              </a>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}

                    <div className='form_group'>
                      <label className='card_details_label'>Observações</label>
                      <textarea
                        name='descript'
                        value={modalObservation}
                        className='form_control'
                        id=''
                        cols='30'
                        rows='5'
                        onChange={(e) => setModalObservation(e.target.value)}
                      ></textarea>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {onCase({
                        unsaved: true,
                        unbilled: false,
                        contractAttached: false,
                        contractDetached: false,
                        financeAttached: false,
                        financeDetached: false,
                      }) &&
                        !sendSave && (
                          <button className='btn_card' style={{ marginRight: 5 }}>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            Incluir conta
                          </button>
                        )}

                      {onCase({
                        unsaved: false,
                        unbilled: true,
                        contractAttached: true,
                        contractDetached: true,
                        financeAttached: true,
                        financeDetached: true,
                      }) &&
                        !isAllowedReceiveInCash && (
                          <button className='btn_card' style={{ marginRight: 5 }}>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            Alterar conta
                          </button>
                        )}

                      {onCase({
                        unsaved: false,
                        unbilled: false,
                        contractAttached: true,
                        contractDetached: false,
                        financeAttached: true,
                        financeDetached: false,
                      }) &&
                        !isAllowedReceiveInCash &&
                        modalReceived == 'N' &&
                        !sendSave && (
                          <div
                            className='btn_card btn_card_grey'
                            style={{ marginRight: 5 }}
                            onClick={allowReceiveInCash}
                          >
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            {t('SchoolFinancePage.Modal.ReceiveInCash')}
                          </div>
                        )}

                      {onCase({
                        unsaved: false,
                        unbilled: false,
                        contractAttached: true,
                        contractDetached: false,
                        financeAttached: true,
                        financeDetached: false,
                      }) &&
                        isAllowedReceiveInCash &&
                        !sendSave && (
                          <button className='btn_card' style={{ marginRight: 5 }}>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            {t('Confirm')}
                          </button>
                        )}

                      {onCase({
                        unsaved: false,
                        unbilled: true,
                        contractAttached: false,
                        contractDetached: false,
                        financeAttached: false,
                        financeDetached: true,
                      }) && (
                        <div className='btn_card_red' onClick={() => handleDeleteCount()}>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          Excluir Conta
                        </div>
                      )}

                      {onCase({
                        unsaved: false,
                        unbilled: false,
                        contractAttached: false,
                        contractDetached: false,
                        financeAttached: true,
                        financeDetached: false,
                      }) &&
                        !sendSave && (
                          <div className='btn_card_red' onClick={() => showInfoFaturamentoMessage()}>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            Excluir Conta e cobrança
                          </div>
                        )}
                    </div>
                  </form>
                </div>
              </div>
              <ModalFinanceStakeholder
                isShow={displayClientFornecedor}
                handleCloseButton={() => closeModalClientFornecedor()}
                listClienteFornecedor={listClienteFornecedor.filter((item) => ['C', 'F'].includes(item.type))}
                onSubmit={handleSubmitClientFornecedor}
              />
              <SweetAlert
                ref={alertRef}
                show={showModalCharge}
                warning
                title='Atenção!'
                confirmBtnText='Baixar'
                onConfirm={handlePaidCharge}
                showCancel={true}
                cancelBtnBsStyle='danger'
                cancelBtnText='Cancelar'
                onCancel={() => setShowModalCharge(false)}
              >
                <p>Está cobrança não se encontra pedente no banco ASAAS.</p>
                <br />
                <p>Vamos baixar somente no Fensor!</p>
              </SweetAlert>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Financial;
