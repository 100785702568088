import React, { useEffect, useState, useRef } from 'react';
import Header from '../../components/club/Header';
import Footer from '../../components/club/Footer';
import Loading from '../../components/Loading';
import Logo from '../../components/club/Logo';
import Utils from '../../utils/utils';
import { Link } from 'react-router-dom';
import Api from '../../utils/api';
import axios from 'axios';
import { toast } from 'react-toastify';

const Evaluation = () => {
  const endRef = useRef(null);
  const [company, setCompany] = useState({});
  const [id, setId] = useState('');
  //   const [countEvaluatuion, setCountEvaluatuion] = useState(0);
  const [countStudents, setCountStudents] = useState(0);
  const [ages, setAges] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [process, setProcess] = useState(false);

  const { formatCNPJCPF, formatTelefone } = Utils();
  const baseURL = Api();

  useEffect(() => {
    async function getSession() {
      const companySession = JSON.parse(await localStorage.getItem('company'));
      setCompany(companySession);
      getEvaluations(companySession.id);
    }

    getSession();
  }, []);

  const getEvaluations = async (id) => {
    setProcess(true);
    const resp = await axios.post(baseURL + 'get-evaluation', { id });
    setProcess(false);

    setAges(resp.data.ages);
    setEvaluations(resp.data.evaluation_atribute);
    setId(resp.data.id);
    setCountStudents(resp.data.countStudents);
  };

  const setCheckedColumn = async (evaluation_atribute_id, age_id, checked) => {
    setProcess(true);

    const resp = await axios.post(baseURL + 'update-item-age-multiple-column', {
      evaluation_atribute_id,
      age_id,
      checked,
      companie_id: company.id,
    });

    setAges(resp.data.ages);
    setEvaluations(resp.data.evaluation_atribute);
    setProcess(false);
  };

  const setCheckedRow = async (evaluation_id, item_id, checked) => {
    setProcess(true);
    let ages = [];
    evaluations.map((parent) => {
      if (parent.id === evaluation_id && parent.name !== "") {
        parent.items.map((item) => {
          if (item.id === item_id) {
            item.ages.map((age) => {
              ages.push({
                evaluation_atribute_items_id: item_id,
                age_id: age.age_id,
              });
            });
          }
        });
      }
    });

    const resp = await axios.post(baseURL + 'update-item-age-multiple-row', {
      items: ages,
      checked,
      companie_id: company.id,
    });

    setAges(resp.data.ages);
    setEvaluations(resp.data.evaluation_atribute);
    setProcess(false);
  };

  const updateItemAge = async (evaluation_atribute_items_id, age_id, checked) => {
    setProcess(true);
    const resp = await axios.post(baseURL + 'update-item-age', {
      evaluation_atribute_items_id,
      age_id,
      checked,
      companie_id: company.id,
    });

    setAges(resp.data.ages);
    setEvaluations(resp.data.evaluation_atribute);
    setProcess(false);
  };

  const updateNameItem = async (id, name) => {
    if (name === "") {
      return toast.error('Preencha o nome da item.')
    }
    setProcess(true);
    await axios.post(baseURL + 'update-name-item', {
      id,
      name,
    });
    toast.success('Item atualizado.')
    setProcess(false);
  };

  const updateNameAtribute = async (id, name) => {
    console.log(name);
    if (name === "") {
      return toast.error('Preencha o nome da características.')
    }
    setProcess(true);
    await axios.post(baseURL + 'update-name-atribute', {
      id,
      name,
    });
    toast.success('Características atualizada.')
    setProcess(false);
  };

  const addItem = async (evaluation_atribute_id, ages) => {
    setProcess(true);
    console.log(ages);
    const resp = await axios.post(baseURL + 'add-item-atribute', {
      evaluation_atribute_id,
      ages: ages,
      companie_id: company.id,
    });

    setAges(resp.data.ages);
    setEvaluations(resp.data.evaluation_atribute);
    setProcess(false);
  };

  const addAtribute = async () => {
    setProcess(true);

    const resp = await axios.post(baseURL + 'add-atribute', {
      id,
      ages: ages,
      companie_id: company.id,
    });

    setAges(resp.data.ages);
    setEvaluations(resp.data.evaluation_atribute);
    setProcess(false);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    endRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='img_school'>
                    <Logo />
                  </div>
                  <h2 className='card_title mx-auto'>{company.comName}</h2>
                  <div className='content_card'>
                    <div className='card_details'>
                      <p className='card_details_label'>Localização: </p>
                      <p className='card_details_text'>{company.comLocalizacao}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>E-mail: </p>
                      <p className='card_details_text'>{company.comEmail}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>Telefone: </p>
                      <p className='card_details_text'>{formatTelefone(String(company.comTelefone))}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>CNPJ: </p>
                      <p className='card_details_text'>{formatCNPJCPF(String(company.comCNPJ))} </p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>Alunos Cadastrados</h2>
                    <div className='sidebar_data'>{countStudents}</div>
                    <Link to='/student-school' className='btn_card'>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      Ver todos
                    </Link>
                  </div>
                  <hr />
                  {/* <div className='card_header mx-auto'>
                    <h2 className='card_title'>Alunos Avaliados</h2>
                    <div className='sidebar_data'>{countEvaluatuion}</div>
                    <Link to='/class-school' className='btn_card'>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      Ver todos
                    </Link>
                  </div> */}
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Avaliação</h2>
                    <p className='card_subtitle'>
                      Adicione os itens para serem avaliados.
                      {process && (
                        <React.Fragment>
                          <Loading type='2' style={{ color: 'green' }} />
                          <Loading type='2' style={{ color: 'green' }} />
                          <Loading type='2' style={{ color: 'green' }} />
                        </React.Fragment>
                      )}
                    </p>
                  </div>
                  <div className='card_avaliacao'>
                    <div className='avaliacao_header'>
                      <div className='avaliacao_categoria'>
                        Características
                        <button className='add_categoria' onClick={() => addAtribute()}>
                          +
                        </button>
                      </div>
                      <div className='avalicao_periodo'>Idade</div>
                    </div>

                    <div className='avaliacao_table'>
                      {evaluations.map((parent) => (
                        <React.Fragment key={parent.id}>
                          <div className='avaliacao_row'>
                            <div className='avaliacao_categoria'>
                              <input
                                className='avaliacao_input ml-0 mr-1'
                                type='text'
                                defaultValue={parent.name}
                                onBlur={(e) => updateNameAtribute(parent.id, e.target.value)}
                                style={{ width: '-webkit-fill-available' }}
                              />
                              <button className='button_set_all ml-0 mr-1'>
                                Marcar
                                <br />
                                todos
                              </button>
                            </div>
                            <p className='title_avaliacao_checkbox_mobile'>Idade</p>
                            <div className='avalicao_periodo'>
                              {ages.map((item, i) => (
                                <label className='avaliacao_checkbox' key={'_' + item.id}>
                                  <span className='label_avaliacao_checkbox'>{item.name}</span>
                                  <input
                                    type='checkbox'
                                    checked={!parent.items.some((children) => !children.ages[i].checked)}
                                    onClick={(e) => setCheckedColumn(parent.id, item.id, e.target.checked)}
                                  />
                                  <span className='checkmark'></span>
                                </label>
                              ))}
                            </div>
                          </div>
                          {parent.items.map((children, i) => (
                            <React.Fragment key={'__' + children.id}>
                              <div className='avaliacao_row'>
                                <div className='avaliacao_categoria' style={{}}>
                                  <label className='switch' style={{ width: '25px', marginRight: '18px' }}>
                                    <input
                                      type='checkbox'
                                      checked={!children.ages.some((age) => !age.checked)}
                                      onClick={(e) => setCheckedRow(parent.id, children.id, e.target.checked)}
                                    />
                                    <span className='slider round'></span>
                                  </label>
                                  <input
                                    className='avaliacao_input ml-0 mr-1'
                                    style={{ flexGrow: 'inherit' }}
                                    type='text'
                                    defaultValue={children.name}
                                    onBlur={(e) => updateNameItem(children.id, e.target.value)}
                                  />
                                </div>
                                <p className='title_avaliacao_checkbox_mobile'>Idade</p>
                                <div className='avalicao_periodo'>
                                  {children.ages.map((age) => (
                                    <label className='avaliacao_checkbox' key={'___' + age.age_id}>
                                      <span className='label_avaliacao_checkbox_mobile'>{age.name}</span>
                                      <input
                                        type='checkbox'
                                        checked={Boolean(age.checked)}
                                        onClick={() => updateItemAge(age.evaluation_atribute_items_id, age.age_id, !age.checked)}
                                      />
                                      <span className='checkmark'></span>
                                    </label>
                                  ))}
                                </div>
                              </div>
                              {parent.items.length === i + 1 && (
                                <div className='avaliacao_row'>
                                  <div className='avaliacao_categoria'>
                                    <button className='button_add_item' onClick={() => addItem(children.evaluation_atribute_id, children.ages)}>
                                      Adicionar mais um item
                                      {process && (
                                        <React.Fragment>
                                          <Loading type='2' style={{ color: 'green' }} />
                                          <Loading type='2' style={{ color: 'green' }} />
                                          <Loading type='2' style={{ color: 'green' }} />
                                        </React.Fragment>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          ))}

                          <div className='avaliacao_row'>
                            <div className='avaliacao_categoria' onClick={() => addAtribute()}>
                              Adicionar mais características
                              <button className='add_categoria'>+</button>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}

                      <div ref={endRef} />
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Evaluation;
