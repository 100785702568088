import React, { useEffect, useState } from 'react'
import Header from '../../components/athlete/Header'
import Footer from '../../components/athlete/Footer'
import Seletivas from '../../components/athlete/Seletivas'
import Events from '../../components/athlete/Events'
import { Link } from 'react-router-dom'

const HomeAthlete = () => {

   const [user_id, setUserID] = useState("")
   let sign = JSON.parse(localStorage.getItem('sign'))
   sign = sign === null ? false : sign
   
   useEffect(() => {

      async function getUser (){
         const user = JSON.parse(await localStorage.getItem('user'))
         setUserID(user.id)
      }

      getUser()

   },[])

   return (
         <React.Fragment>
            
            <Header type="1" menu={true} />

            <main>

               <section className="banner">
                  <Link to={{
                           pathname: (!sign ? "/sign" : ""),
                           query: {
                              user_id: user_id
                           }
                        }}

                        className="conteudo-banner">
                     {
                        // <h1>Mostre seu futebol</h1>
                        // <p>Bem-vindo à plataforma Fensor! Aqui você será<br /> conectado ao mundo do futebol profissional.</p>
                        // <Link to="/curriculo-athlete">Complete seu currículo</Link>
                     }
                  </Link>
               </section>

               <section className="bloco-informativo">
                  <div className="conteudo-informativo" style={{paddingTop: '30px'}}>
                     <div className="coluna-informativo-01">
                        <div className="texto-informativo">
                           <h3>Mostre seu futebol</h3>
                           <h4>Seja visto nas principais seletivas.</h4>
                           <Link to={{
                              pathname: (!sign ? "/sign" : ""),
                              query: {
                                 user_id: user_id
                              }
                           }}>{ (!sign ? "Faça o upgrade" : "você é um assinante") }</Link>
                           <p>Assinatura anual Fensor.</p>
                        </div>
                     </div>

                     <div className="coluna-informativo-02">
                        <div className="texto-informativo">
                           <h3>Encontre suas fotos</h3>
                           <h4>Banco de imagens exclusivas dos jogos.</h4>
                           <Link to="/photosalbum" >Acessar agora</Link>
                           <p>Banco de imagens Fensor.</p>
                        </div>
                     </div>

                     <div className="coluna-informativo-03">
                        <div className="texto-informativo">
                           <h3>Seja visto pelos clubes</h3>
                           <h4>Complete seu curriculo e compartilhe seu vestiário.</h4>
                           <Link to="/curriculo-athlete">Acessar agora</Link>
                           <p>Seu vestiário.</p>
                        </div>
                     </div>
                  </div>
               </section>
               
               <section className="bloco-principal">
                  <div className="conteudo-bloco-principal">

                     <div className="bloco-1">
                        <Seletivas carregarMais={false} verTodos={true}  verDetalhes={false} title="PRÓXIMAS SELETIVAS" limit="3" description="Essas são algumas das próximas seletivas na sua região." />
                        <br />
                        <Events carregarMais={false} verTodos={true}  verDetalhes={false}  title="PRÓXIMOS EVENTOS" limit="1" description="Veja abaixo os próximos eventos na sua região."/>
                     </div>

                     <div className="bloco-2">
                        <div className="conteudo-generico">
                           <div className="img-tarefas-iniciais">
                              <img src="../../img/index/complete-perfil.png"/>
                           </div>

                           <ul className="tarefas-iniciais">
                              <li className="tarefa-feita">
                                 <div className="icone-check"></div>

                                 <div className="texto-tarefa">
                                    <p>Coloque uma foto no seu vestiário.</p>
                                 </div>
                              </li>


                              <li className="tarefa-feita">
                                 <div className="icone-no-check"></div>

                                 <div className="texto-tarefa">
                                    <p>Preencha todos os dados gerais.</p>
                                 </div>
                              </li>


                              <li className="tarefa-feita">
                                 <div className="icone-no-check"></div>

                                 <div className="texto-tarefa">
                                    <p>Configure o seu currículo.</p>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>

                  </div>
               </section>
               {
                  // <section className="bloco-informativo">
                  //    <div className="conteudo-informativo">
                  //       <div className="coluna-informativo-1">
                  //          <div className="texto-informativo">
                  //             <h3>Mostre seu futebol</h3>
                  //             <h4>Seja visto nas principais seletivas.</h4>
                  //             <Link to={{
                  //                pathname: (!sign ? "/sign" : ""),
                  //                query: {
                  //                   user_id: user_id
                  //                }
                  //             }}>{ (!sign ? "Faça o upgrade" : "você é um assinante") }</Link>
                  //             <p>Assinatura anual Fensor.</p>
                  //          </div>
                  //       </div>

                  //       <div className="coluna-informativo-2">
                  //          <div className="texto-informativo">
                  //             <h3>Encontre suas fotos</h3>
                  //             <h4>Banco de imagens exclusivas dos jogos.</h4>
                  //             <Link to="/photosalbum" >Acessar agora</Link>
                  //             <p>Banco de imagens Fensor.</p>
                  //          </div>
                  //       </div>

                  //       <div className="coluna-informativo-3">
                  //          <div className="texto-informativo">
                  //             <h3>Seja visto pelos clubes</h3>
                  //             <h4>Complete seu curriculo e compartilhe seu vestiário.</h4>
                  //             <Link to="/curriculo-athlete">Acessar agora</Link>
                  //             <p>Seu vestiário.</p>
                  //          </div>
                  //       </div>
                  //    </div>
                  // </section>
               }
               
            </main>

            <Footer />
          
         </React.Fragment>
   )
}

export default HomeAthlete