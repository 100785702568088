import api from './api';

export const signContractStudent = async (contractId) => {
  try {
    const { data } = await api.post('/sign-contract-student', { contractId });

    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Erro interno. Por favor, tente novamente.',
    };
  }
};

export const standardizedContractPlan = async (payload) => {
  try {
    const { data } = await api.post('/standardized-plans/create-contract', payload);

    return data;
  } catch (error) {
    // Add logic to handle the error or rethrow the exception automatically.
    throw error;
  }
};
export const standardizedContractPlanDelete = async (payload) => {
  try {
    const { data } = await api.post('/standardized-plans/delete-contract', payload);

    return data;
  } catch (error) {
    // Add logic to handle the error or rethrow the exception automatically.
    throw error;
  }
};
export const getStandardizedContractPlan = async (school_id) => {
  try {
    const { data } = await api.get(`/standardized-plans/contracts/${school_id}`);

    return data;
  } catch (error) {
    throw error;
  }
};
export const standardizedInvoicePlan = async (payload) => {
  try {
    const { data } = await api.post('/standardized-plans/create-invoice', payload);

    return data;
  } catch (error) {
    // Add logic to handle the error or rethrow the exception automatically.
    throw error;
  }
};

export const standardizedInvoicePlanDelete = async (payload) => {
  try {
    const { data } = await api.post('/standardized-plans/delete-invoice', payload);

    return data;
  } catch (error) {
    // Add logic to handle the error or rethrow the exception automatically.
    throw error;
  }
};

export const getStandardizedInvoicePlan = async (school_id) => {
  try {
    const { data } = await api.get(`/standardized-plans/invoice/${school_id}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export async function storeContract(payload) {
  const { data } = await api.post(`/contract/save-multi`, payload);

  return data;
}
