import React from 'react';
import Atleta from './Forms/Atleta';
import Responsaveis from './Forms/Responsaveis';
import Esportes from './Forms/Esportes';
import Fiador from './Forms/Fiador';
import { Tooltip } from '@material-ui/core';
import Cobranca from './Forms/Cobranca';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Turmas from './Forms/Turmas';
import Resumo from './Forms/Resumo';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StudentFormContext from '../../Context/StudentFormContext';
import ContractFaturarContext from '../../Context/ContractFaturarContext';
import { useSelector } from 'react-redux';
import CobrancaFaturarContext from '../../Context/CobrancaFaturarContext';

const NewStudent = ({ props }) => {
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);

  const [title, setTitle] = React.useState('StudentPage.AddStudentCard.Title');

  const parentRef = React.useRef(null);

  const history = useHistory();
  const { id } = useParams();

  const { selected } = useSelector(({ User }) => User);

  const handlerFinally = () => {
    history.push('/student-school');
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const elmain = document.querySelector('main');
    if (elmain) {
      elmain.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }
  }, [value]);

  React.useEffect(() => {
    const elmain = document.querySelector('main');
    if (elmain) {
      elmain.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }
    setTitle(selected !== null || id ? 'StudentPage.Modal.TitleEdit' : 'StudentPage.AddStudentCard.Title');
  }, []);

  return (
    <StudentFormContext>
      <ContractFaturarContext>
        <CobrancaFaturarContext>
          <div ref={parentRef} className='card p-0 flex-column'>
            <div className='card_header w-100 flex-column align-items-center pt-3'>
              <h2 className='card_title'>{t(title)}</h2>
              <BtnBack
                onClick={handlerFinally}
                style={{
                  float: 'right',
                  position: 'absolute',
                  right: 35,
                  marginTop: 5,
                }}
              />
            </div>

            <CustomAppBar position='static' color='default'>
              <CustomTabs
                variant='scrollable'
                scrollButtons='auto'
                aria-label='scrollable auto tabs'
                value={value}
                onChange={handleChange}
              >
                <CustomTab className='nav-link' label={`${t('Student')}`} {...a11yProps(0)} />
                <CustomTab
                  className='nav-link'
                  disabled={value < 1}
                  label={t('LessonPages.Responsible')}
                  {...a11yProps(1)}
                />
                <CustomTab
                  className='nav-link'
                  disabled={value < 2}
                  label={t('SportsTypesPage.title')}
                  {...a11yProps(2)}
                />
                <CustomTab
                  className='nav-link'
                  disabled={value < 3}
                  label={`${t('LessonPages.Responsible')}  ${t('SystemMenu.Financeiro')}`}
                  {...a11yProps(3)}
                />
                <CustomTab className='nav-link' disabled={value < 4} label={t('SystemMenu.Turmas')} {...a11yProps(4)} />
                <CustomTab
                  className='nav-link'
                  disabled={value < 5}
                  label={t('SystemMenu.Financeiro')}
                  {...a11yProps(5)}
                />
                <CustomTab className='nav-link' disabled={value < 6} label={t('Summary')} {...a11yProps(6)} />
              </CustomTabs>
            </CustomAppBar>
            <TabPanel value={value || 0} index={0} className='p-0'>
              <Atleta nextTab={(i) => setValue(i)} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Responsaveis nextTab={(i) => setValue(i)} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Esportes nextTab={(i) => setValue(i)} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Fiador nextTab={(i) => setValue(i)} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Turmas nextTab={(i) => setValue(i)} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Cobranca nextTab={(i) => setValue(i)} />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Resumo />
            </TabPanel>
          </div>
        </CobrancaFaturarContext>
      </ContractFaturarContext>
    </StudentFormContext>
  );
};
export default NewStudent;

export const BtnBack = ({ fcolor = '#1C1B1F', w = 20, h = 20, onClick, style }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer', ...style }} role='button'>
    <svg width={w} height={h} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {/* <path d='M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z' /> */}
      <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill={fcolor} />
    </svg>
  </span>
);
export const BtnInfo = ({ fcolor = 'var(--main-color)', w = 16, h = 16, title, style }) => (
  <Tooltip title={title} arrow tplacement='top'>
    <span style={{ ...style }} role='button'>
      <svg width={w} height={h} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill={fcolor}
          d='M7.25 11.75H8.75V7.25H7.25V11.75ZM8 5.75C8.2125 5.75 8.39063 5.67812 8.53438 5.53438C8.67813 5.39062 8.75 5.2125 8.75 5C8.75 4.7875 8.67813 4.60938 8.53438 4.46562C8.39063 4.32187 8.2125 4.25 8 4.25C7.7875 4.25 7.60938 4.32187 7.46563 4.46562C7.32188 4.60938 7.25 4.7875 7.25 5C7.25 5.2125 7.32188 5.39062 7.46563 5.53438C7.60938 5.67812 7.7875 5.75 8 5.75ZM8 15.5C6.9625 15.5 5.9875 15.3031 5.075 14.9094C4.1625 14.5156 3.36875 13.9813 2.69375 13.3063C2.01875 12.6313 1.48438 11.8375 1.09063 10.925C0.696875 10.0125 0.5 9.0375 0.5 8C0.5 6.9625 0.696875 5.9875 1.09063 5.075C1.48438 4.1625 2.01875 3.36875 2.69375 2.69375C3.36875 2.01875 4.1625 1.48438 5.075 1.09063C5.9875 0.696875 6.9625 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09063C11.8375 1.48438 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.0375 15.3031 10.0125 14.9094 10.925C14.5156 11.8375 13.9813 12.6313 13.3063 13.3063C12.6313 13.9813 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14C9.675 14 11.0938 13.4187 12.2563 12.2563C13.4187 11.0938 14 9.675 14 8C14 6.325 13.4187 4.90625 12.2563 3.74375C11.0938 2.58125 9.675 2 8 2C6.325 2 4.90625 2.58125 3.74375 3.74375C2.58125 4.90625 2 6.325 2 8C2 9.675 2.58125 11.0938 3.74375 12.2563C4.90625 13.4187 6.325 14 8 14Z'
        />
      </svg>
    </span>
  </Tooltip>
);
export const BtnDelete = ({ fcolor = '#FF2929', w = 16, h = 16, onClick }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer' }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z'
        fill={fcolor}
      />
    </svg>
  </span>
);

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
//local components  --------------------------------------------------------------------------------------

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
const CustomAppBar = withStyles((theme) => ({
  root: {
    marginTop: 10,
    padding: 10,
    boxShadow: 'none', // Remove a sombra
    borderBottom: `1px solid ${theme.palette.divider}`, // Adiciona uma borda na parte inferior
    backgroundColor: 'white',
  },
}))(AppBar);

const CustomTabs = withStyles((theme) => ({
  root: {
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
  },
}))(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2), // Defina o espaçamento desejado entre as Tabs
    },
    backgroundColor: 'var(--main-color)', // Define a cor de fundo da aba para "cyan"
    color: 'white', // Define a cor do texto da aba para "cyan"

    '&.Mui-selected:after': {
      opacity: 1, // Quando selecionado, torna o overlay totalmente opaco
      borderBottom: 'none',
    },
  },
}))(Tab);
