import React from "react"
//import { Table } from "react-bootstrap"
import { AllMonths } from "../../utils/utils"
import { useTranslation } from "react-i18next";
import { TableBody, TableCell, TableContainer, TableHead, Table, TableRow, Paper, makeStyles } from "@material-ui/core";

  const useStyles = makeStyles({
    stickyCell: {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        backgroundColor: '#fff',
      },
  });

export const TableDashBoard = ({ matriculas, desistentes, inadimplentes, pagos, countStudents }) => {
    const { t } = useTranslation();

    const classes = useStyles();
    // calcula media de cada relatorio
    const averageMatricula = matriculas.reduce((sum, value) => {
        if (value.Total !== undefined && value.Total !== '') {
            return sum + value.Total
        }
        return sum;
    }, 0) / matriculas.filter(element => element.Total !== undefined && element.Total !== '').length;

    const averageDesistentes = desistentes.reduce((sum, value) => {
        if (value.Total !== undefined && value.Total !== '') {
            return sum + value.Total
        }
        return sum;
    }, 0) / desistentes.filter(element => element.Total !== undefined && element.Total !== '').length;

    const averageInadimplentes = inadimplentes.reduce((sum, value) => {
        if (value.Total !== undefined && value.Total !== '') {
            return sum + value.Total
        }
        return sum;
    }, 0) / inadimplentes.filter(element => element.Total !== undefined && element.Total !== '').length;

    const averagePagos = pagos.reduce((sum, value) => {
        if (value.Total !== undefined && value.Total !== '') {
            return sum + value.Total
        }
        return sum;
    }, 0) / pagos.filter(element => element.Total !== undefined && element.Total !== '').length;

    const averageStudents = countStudents.reduce((sum, value) => {
        if (value.Total !== undefined && value.Total !== '') {
            return sum + value.Total;
        }
        return sum;
    }, 0) / countStudents.filter(element => element.Total !== undefined && element.Total !== '').length;

    return (
        <TableContainer component={Paper} style={{ fontSize: '10px', boxShadow: 'none', marginTop: '20px' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.stickyCell} style={{ border:'var(--main-color) 1px solid' }}></TableCell>
              {AllMonths.map((item, index) => (
                <TableCell key={item.name + index} style={{ fontSize: '17px', width: '70px', height: '40px', border:'var(--main-color) 1px solid' }}>
                  {t(item.nameKeyAbbreviated)}
                </TableCell>
              ))}
              <TableCell  style={{ fontSize: '17px', width: '70px', height: '40px',border:'var(--main-color) 1px solid' }}>
                { t('Average') }
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.stickyCell} style={{ fontSize: '17px', height: '40px', border:'var(--main-color) 1px solid' }}>{ t('Registration') }</TableCell>
              {matriculas.map((item, index) => (
                <TableCell key={item + index} style={{ width: '70px', height: '40px',border:'var(--main-color) 1px solid' }}>
                  {item.Total}
                </TableCell>
              ))}
              <TableCell style={{ width: '70px', height: '40px',border:'var(--main-color) 1px solid' }}>
                {averageMatricula > 0 ? averageMatricula.toFixed(1) : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.stickyCell} style={{ fontSize: '17px', height: '40px',border:'var(--main-color) 1px solid' }}>{ t('Dropouts') }</TableCell>
              {desistentes.map((item, index) => (
                <TableCell key={item + index} style={{ width: '70px', height: '40px',border:'var(--main-color) 1px solid' }}>
                  {item.Total}
                </TableCell>
              ))}
              <TableCell style={{ width: '70px', height: '40px',border:'var(--main-color) 1px solid' }}>
                {averageDesistentes > 0 ? averageDesistentes.toFixed(1) : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.stickyCell} style={{ fontSize: '17px', height: '40px',border:'var(--main-color) 1px solid' }}>{ t('Defaulters') }</TableCell>
              {inadimplentes.map((item, index) => (
                <TableCell key={item + index} style={{ width: '70px', height: '40px',border:'var(--main-color) 1px solid' }}>
                  {item.Total}
                </TableCell>
              ))}
              <TableCell style={{ width: '70px', height: '40px',border:'var(--main-color) 1px solid' }}>
                {averageInadimplentes > 0 ? averageInadimplentes.toFixed(1) : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.stickyCell} style={{ fontSize: '17px', height: '40px',border:'var(--main-color) 1px solid' }}>{ t('PaidOut') }</TableCell>
              {pagos.map((item, index) => (
                <TableCell key={item + index} style={{ width: '70px', height: '40px',border:'var(--main-color) 1px solid' }}>
                  {item.Total}
                </TableCell>
              ))}
              <TableCell style={{ width: '70px', height: '40px',border:'var(--main-color) 1px solid' }}>
                {averagePagos > 0 ? averagePagos.toFixed(2) : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.stickyCell} style={{ fontSize: '17px', height: '40px',border:'var(--main-color) 1px solid' }}>{ t('TotalOfStudents') }</TableCell>
              {countStudents.map((item, index) => (
                <TableCell key={item + index} style={{ width: '70px', height: '40px',border:'var(--main-color) 1px solid' }}>
                  {item.Total}
                </TableCell>
              ))}
              <TableCell style={{ width: '70px', height: '40px',border:'var(--main-color) 1px solid' }}>
                {averageStudents > 0 ? averageStudents.toFixed(1) : ''}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
}
