import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import Utils from '../../../src/utils/utils';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Footer from '../../components/admin/Footer';
import Header from '../../components/admin/Header';
import { getListAccounts, saveAccount } from '../../services/AsaasService';
import { searchByZipCode } from '../../services/SharedService';
import { getAccountFensorAsaas } from '../../utils/api';

function AccountAsaas() {
  // const tokenAsaas = '293580135efdbbbf15ace5638f19aa99588f7f68dd93a10ab91baaef0a9207ad';
  const tokenAsaas = getAccountFensorAsaas().token_assas;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loginEmail, setLoginEmail] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [province, setProvince] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [incomeValue, setIncomeValue] = useState('');
  
  // carregamento
  const [filterName, setFilterName] = useState('');
  const [filterCNPJ, setFilterCNPJ] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [accountsFilter, setAccountsFilter] = useState([]);
  const [display, setDisplay] = useState({});

  // process
  const [process, setProcess] = useState(false);
  const [sendCep, setSendCep] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  // libraries
  const { replaceAll, scrollTop } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  let modalScroll = useRef();

  const { setThemeSchool } = Utils();

  useEffect(() => {
    setThemeSchool({
      defaultColor: '#ba8820',
      mainBackground: 'bg_login_qa.jpg',
    });

    getList();

    window.addEventListener('keydown', closeModalOnEscape);

    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') closeAdd();
  };

  const search = (e) => {
    e.preventDefault();

    let registers = [];
    if (filterName !== '') {
      registers = accounts.filter((item) => {
        return item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1;
      });
    } else {
      registers = accounts;
    }

    if (filterCNPJ !== '') {
      registers = registers.filter((item) => {
        return item.cpfCnpj === filterCNPJ;
      });
    }

    setAccountsFilter(registers);
  };

  const openAdd = () => {
    clearMessage();

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const closeAdd = () => {
    setDisplay({});
  };

  const getList = async () => {
    setProcess(true);
    const response = await getListAccounts(tokenAsaas);
    setProcess(false);

    setAccounts(response.data);
    setAccountsFilter(response.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();

    setProcess(true);
    const response = await saveAccount({
      name,
      email,
      loginEmail,
      cpfCnpj,
      companyType,
      address,
      addressNumber,
      province,
      postalCode,
      tokenAsaas,
      birthDate,
      incomeValue
    });
    setProcess(false);

    if (response.data.message === 'Conta cadastrada com sucesso') {
      setSuccessMessage(response.data.message);
      getList();
    } else {
      if (typeof response.data.message === 'string') setErrorMessage(response.data.message);
      else setErrorMessage(response.data.message.map(({ message }) => message).join(' '));
    }

    scrollTop(modalScroll);
  };

  const setErrorMessage = (message) => {
    setMessage(message);
    setError(true);
    scrollTop(modalScroll);
  };

  const setSuccessMessage = (message) => {
    setMessage(message);
    setSuccess(true);
    scrollTop(modalScroll);
  };

  const clearMessage = () => {
    setSuccess(false);
    setError(false);
    setMessage('');
  };

  const verifyEmail = (email) => {
    const existe = accountsFilter.filter((item) => item.email === email);
    if (existe.length > 0) {
      setErrorMessage('Este email já encontra-se cadastrado');
      setError(true);
      return;
    }

    setEmail(email);
  };

  const verifyLoginEmail = (loginEmail) => {
    const existe = accountsFilter.filter((item) => item.loginEmail === loginEmail);
    if (existe.length > 0) {
      setErrorMessage('Este login email já encontra-se cadastrado');
      setError(true);
      return;
    }

    setLoginEmail(loginEmail);
  };

  const buscaCEP = async () => {
    const cepAux = postalCode.replaceAll('-', '').replaceAll('_', '');

    if (cepAux.length === 8) {
      setAddress('');
      setAddressNumber('');
      setProvince('');

      setSendCep(true);
      const res = await searchByZipCode(cepAux);
      setSendCep(false);

      if (res.erro) {
        setAddress('');
        setAddressNumber('');
        setProvince('');
      } else {
        setAddress(res.logradouro);
        setProvince(res.bairro);
      }
    }
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <Header />

            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Ativar Contas</h2>
                    <p className='card_subtitle'>Clique abaixo para ativar o WebHook de todas as contas criadas.</p>
                  </div>
                  <a
                    href={`http://assas.laks.net.br:3000/api/job-active-accounts`}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='btn_card mx-auto mt-4 btn_open_modal'
                    data-modal='#add_funcionario'
                  >
                    <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                    </svg>
                    Ativar Contas
                  </a>
                </div>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Procurar</h2>
                    <p className='card_subtitle'>Filtrar as contras cadastradas no Asaas.</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        Nome da Empresa
                      </label>
                      <input type='text' className='form_control' onChange={(e) => setFilterName(e.target.value)} />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        CNPJ
                      </label>
                      <input type='text' className='form_control' onChange={(e) => setFilterCNPJ(e.target.value)} />
                    </div>
                    <button className='btn_card mx-auto' onClick={search}>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      Buscar
                    </button>
                  </form>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'> Adicionar Conta</h2>
                    <p className='card_subtitle'>Clique ao lado para adicionar uma conta na plataforma Asaas.</p>
                  </div>
                  <button onClick={openAdd} className='btn_card ml-auto mt-4 mt-md-0'>
                    <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                    </svg>
                    Adicionar Conta
                  </button>
                </div>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Contas</h2>
                    <p className='card_subtitle'>Veja abaixo a sua lista de contas cadastradas. </p>
                  </div>

                  <div className='list_card'>
                    {process && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!process &&
                      accountsFilter.map((item, i) => {
                        return (
                          <div className='card_list' key={`accounts_${i}`}>
                            <div className='content_card'>
                              <div className='card_details'>
                                <p className='card_details_title' title={item.name}>
                                  {item.name.length > 20 ? item.name.substr(0, 20) + '...' : item.name}
                                </p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>CPF/CNPJ: </p>
                                <p className='card_details_text'>{item.cpfCnpj}</p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Login: </p>
                                <p className='card_details_text'>
                                  {(item.loginEmail || '').length > 20
                                    ? item.loginEmail.substr(0, 20) + '...'
                                    : item.loginEmail}
                                </p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Telefone: </p>
                                <p className='card_details_text'>{item.phone || '(n/i)'}</p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Celular: </p>
                                <p className='card_details_text'>{item.mobilePhone || '(n/i)'}</p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Estado: </p>
                                <p className='card_details_text'>{item.state}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className='modal' id='add_funcionario' style={display}>
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeAdd}>
                    X
                  </div>
                  <h3 className='modal_title'>Incluir conta</h3>
                  {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
                  {success && <MessageSuccessMarginTop title='Sucesso! ' description={message} />}
                  <form action='' onSubmit={handleSubmit} method='post' className='form_modal'>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          Razão Social ou Nome
                        </label>
                        <input
                          type='text'
                          required
                          className='form_control'
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='email' className='card_details_label' style={{ flexDirection: 'row' }}>
                          E-mail
                        </label>
                        <input
                          type='email'
                          required
                          className='form_control'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={(e) => verifyEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='email' className='card_details_label' style={{ flexDirection: 'row' }}>
                          Login E-mail
                        </label>
                        <input
                          type='email'
                          required
                          className='form_control'
                          value={loginEmail}
                          onChange={(e) => setLoginEmail(e.target.value)}
                          onBlur={(e) => verifyLoginEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          CNPJ ou CPF
                        </label>
                        <InputMask
                          className='form_control'
                          required
                          value={cpfCnpj}
                          type='tel'
                          mask='99999999999999'
                          disableunderline='true'
                          onChange={(e) => setCpfCnpj(e.target.value)}
                        ></InputMask>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label' style={{ flexDirection: 'row' }}>
                          CEP
                          {sendCep && <Loading type='1' style={{ marginLeft: '2%' }} />}
                        </label>
                        <InputMask
                          value={postalCode}
                          className='form_control'
                          mask='99999-999'
                          type='tel'
                          disableunderline='true'
                          onBlur={buscaCEP}
                          onChange={(e) => setPostalCode(replaceAll(replaceAll(e.target.value, '-', ''), '_', ''))}
                        ></InputMask>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          Endereço
                        </label>
                        <input
                          type='text'
                          value={address}
                          className='form_control'
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label'>
                            Número
                          </label>
                          <input
                            type='text'
                            value={addressNumber}
                            className='form_control'
                            onChange={(e) => setAddressNumber(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label'>
                            Data Nasc. Sócio
                          </label>
                          <input
                            type='date'
                            value={birthDate}
                            className='form_control'
                            onChange={(e) => setBirthDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            Bairro
                          </label>
                          <input
                            type='text'
                            value={province}
                            className='form_control'
                            onChange={(e) => setProvince(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='title' className='card_details_label'>
                            Tipo de Empresa
                          </label>
                          <select
                            required
                            value={companyType}
                            className='form_control select'
                            onChange={(e) => setCompanyType(e.target.value)}
                          >
                            <option value=''></option>
                            <option value='MEI'>MEI</option>
                            <option value='LIMITED'>LIMITED</option>
                            <option value='INDIVIDUAL'>INDIVIDUAL</option>
                            <option value='ASSOCIATION'>ASSOCIATION</option>
                          </select>
                        </div>
                      </div>
                    </div>


                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label'>
                            Valor de Faturamento
                          </label>
                          <input
                            type='text'
                            required
                            className='form_control'
                            value={incomeValue}
                            onChange={(e) => setIncomeValue(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='form_group'>
                      {!process && (
                        <button className='btn_card mx-auto'>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          Incluir conta
                        </button>
                      )}
                      {process && (
                        <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none' }} disabled>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          processando...
                          <Loading type='1' style={{ marginLeft: '2%' }} />
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
}

export default AccountAsaas;
