import React from 'react';
import { RiFileExcel2Line } from 'react-icons/ri';
import SimpleModal from './SimpleModal';

const ModalSimpleTable = ({ isShow, handleCloseModal, modalRef, title, onExport, columns, data, subtitle = null }) => {
  return (
    <SimpleModal isShow={isShow} handleCloseModal={handleCloseModal} modalRef={modalRef} size={'xl'}>
      <h3 className='modal_title'>
        {title} - <RiFileExcel2Line style={{ cursor: 'pointer' }} size={25} color='green' onClick={() => onExport()} />
        <span className='text-danger small pl-5'>{subtitle}</span>
      </h3>
      <table className='table_reports' border='1' borderColor='#ddd'>
        <thead>
          <tr>
            {columns.map(({ label }) => (
              <th key={`ModalSimpleTable_${title}_Header_${label}`}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <tr key={i}>
              {columns.map(({ label, minWidth, width, getter }) => (
                <td
                  key={`ModalSimpleTable_${title}_Row_${label}`}
                  style={{ minWidth: minWidth ?? undefined, width: width ?? undefined }}
                >
                  {getter(item)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </SimpleModal>
  );
};

export default ModalSimpleTable;
