const resources = [
  { group: 'COMPANIE', view: 'Escolas', path: '/school-club' },
  { group: 'COMPANIE', view: 'Alunos', path: '/student-school' },
  { group: 'COMPANIE', view: 'Avaliação', path: '/evaluation-club' },
  { group: 'COMPANIE', view: 'Relatórios', path: '/report-club' },
  { group: 'COMPANIE', view: 'Contratos', path: '/contract-school' },
  { group: 'COMPANIE', view: 'Financeiro', path: '/financial-club' },
  { group: 'COMPANIE', view: 'Funcionários', path: '/employee-club' },
  { group: 'COMPANIE', view: 'Comunicação', path: '/communication-club' },
  { group: 'COMPANIE', view: 'Questionário', path: '/questionnaire-club' },
  { group: 'COMPANIE', view: 'Controle de acesso', path: '/view-control' },
  { group: 'COMPANIE', view: 'Home', path: '/home-club' },

  { group: 'SCHOOL', view: 'Funcionários', path: '/employee-school' },
  // { group: 'SCHOOL', view: 'Frequência', path: '/general-frequency-school' },
  { group: 'SCHOOL', view: 'Alunos', path: '/student-school' },
  { group: 'SCHOOL', view: 'Turmas', path: '/class-school' },
  { group: 'SCHOOL', view: 'Financeiro', path: '/financial-school' },
  { group: 'SCHOOL', view: 'Comunicação', path: '/communication-school' },
  { group: 'SCHOOL', view: 'Relatórios', path: '/reports-school' },
  { group: 'SCHOOL', view: 'Contratos', path: '/contracts-student-school' },
  { group: 'SCHOOL', view: 'Avaliação', path: '/evaluation-school' },
  { group: 'SCHOOL', view: 'Produtos', path: '/products' },
  { group: 'SCHOOL', view: 'Pedidos', path: '/my-request' },
  { group: 'SCHOOL', view: 'Controle de acesso', path: '/view-control' },
  { group: 'SCHOOL', view: 'Home', path: '/home-school' },
  { group: 'SCHOOL', view: 'Fluxo de caixa', path: '/cash-flow-reports' },
  { group: 'SCHOOL', view: 'Financeiro com Turma', path: '/class-frequency-reports' },

  { group: 'STUDENT', view: 'Home', path: '/home-student-school' },
  { group: 'STUDENT', view: 'Frequência', path: '/frequency-student-school' },
  { group: 'STUDENT', view: 'Financeiro', path: '/financial-student-school' },
  { group: 'STUDENT', view: 'Produtos', path: '/list-products' },
  { group: 'STUDENT', view: 'Meus Pedidos', path: '/my-order' },
  { group: 'STUDENT', view: 'Comunicação', path: '/communication-athlete-school' },
  { group: 'STUDENT', view: 'Documentos', path: '/documents-athlete' },
];

const canView = (path) => {
  console.log(path);
  const menus = JSON.parse(localStorage.getItem('menu'));
  if (menus === null) return false;
  if (!resources.some((resource) => resource.path === path)) return true;

  const checkMenu = (menu) => {
    if ('/' + menu.route === path) return true;
    if (menu.children) {
      return menu.children.some(checkMenu);
    }
    return false;
  };

  return menus.some(checkMenu);
};

const homeCaseRedirect = () => {
  const menus = JSON.parse(localStorage.getItem('menu'));

  if (menus === null || menus.length === 0) return '/nao-autorizado';

  return '/' + menus[0].route;
};

const getHomeURL = () => {
  const userGroup = localStorage.getItem('school') !== 'null' ? 'SCHOOL' : 'COMPANIE';

  return userGroup === 'SCHOOL' ? '/home-school' : userGroup === 'COMPANIE' ? '/home-club' : '/home-student-school';
};

const emptyPermissions = () => {
  const menus = JSON.parse(localStorage.getItem('menu'));
  return menus === null || menus.length === 0;
};

export default canView;
export { homeCaseRedirect, getHomeURL, emptyPermissions };
