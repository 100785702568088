import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Badge from 'react-bootstrap/Badge';
import Utils, { sortProductSizes } from '../../../src/utils/utils';
import Api from '../../utils/api';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import { adicionarProdutoCarrinho } from '../../redux/actions/Cart';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/school/Footer';

const Products = () => {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const { Cart } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [detalhesProduto, setDetalhesProduto] = useState({});
  const [qtd, setQtd] = useState(1);
  const [tamanho, setTamanho] = useState(null);

  // filtros
  const [filterTitulo, setFilterTitulo] = useState('');
  const [filterCodProduto, setFilterCodProduto] = useState('');
  const [filterPrecoInicial, setFilterPrecoInicial] = useState('');
  const [filterPrecoFinal, setFilterPrecoFinal] = useState('');

  // carregamento
  const [products, setProducts] = useState([]);
  const [productsFilter, setProductsFilter] = useState([]);
  const [dirUsuFotoPrincipal, setDirUsuFotoPrincipal] = useState('');
  const [display, setDisplay] = useState({});

  // process
  const [process, setProcess] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  // libraries
  const baseURL = Api();
  const { replaceAll, currencyFormatter } = Utils();
  const { MessageDangerMarginTop } = Message();
  let modalScroll = useRef(null);

  useEffect(() => {
    async function getSession() {
      let schoolSession = await localStorage.getItem('school');
      schoolSession = JSON.parse(schoolSession);
      getProducts(schoolSession.id);
    }

    getSession();

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeAdd();
    }
  };

  useEffect(() => {
    setError(false);
  }, [tamanho]);

  async function getProducts(school) {
    setProcess(true);
    const { data } = await axios.get(baseURL + `products/${school}?withStock=true&filterStatus=Sim`);

    setDirUsuFotoPrincipal(data.dir_usuFotoPrincipal);

    let newData = data.products.data;
    newData.map((prod, i) => {
      newData[i].fotoCapa = 'no-image.png';
      newData[i].url = data.dir_usuFotoPrincipal;

      if (prod.products_fotos.length > 0) {
        prod.products_fotos.map((foto) => {
          if (foto.capa === 'S') {
            newData[i].fotoCapa = foto.nome;
          }
        });
      }

      newData[i].adicionado = false;
      Cart.map((item) => {
        if (item.id === prod.id) newData[i].adicionado = true;
      });
    });

    setProducts(newData.filter((item) => item.ativo === 'Sim'));
    setProductsFilter(newData.filter((item) => item.ativo === 'Sim'));
    setProcess(false);
  }

  const search = (e) => {
    e.preventDefault();

    let registers = [];
    if (filterTitulo !== '') {
      registers = products.filter((item) => {
        let nome = item.titulo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return (
          nome.toLowerCase().indexOf(filterTitulo.toLowerCase()) !== -1 ||
          item.titulo.toLowerCase().indexOf(filterTitulo.toLowerCase()) !== -1
        );
      });
    } else {
      registers = products;
    }

    if (filterCodProduto !== '') {
      registers = registers.filter((item) => {
        return item.cod_produto.indexOf(filterCodProduto) !== -1;
      });
    }

    if (filterPrecoInicial !== '' && filterPrecoFinal === '') {
      registers = registers.filter((item) => {
        const precoIni = replaceAll(replaceAll(replaceAll(filterPrecoInicial, '.', ''), ',', '.'), 'R$', '').trim();

        return item.preco >= parseFloat(precoIni);
      });
    } else if (filterPrecoInicial === '' && filterPrecoFinal !== '') {
      registers = registers.filter((item) => {
        const precoFim = replaceAll(replaceAll(replaceAll(filterPrecoFinal, '.', ''), ',', '.'), 'R$', '').trim();
        return item.preco <= parseFloat(precoFim);
      });
    } else if (filterPrecoInicial !== '' && filterPrecoFinal !== '') {
      registers = registers.filter((item) => {
        const precoIni = replaceAll(replaceAll(replaceAll(filterPrecoInicial, '.', ''), ',', '.'), 'R$', '').trim();
        const precoFim = replaceAll(replaceAll(replaceAll(filterPrecoFinal, '.', ''), ',', '.'), 'R$', '').trim();

        return parseFloat(precoIni) <= item.preco && parseFloat(precoFim) >= item.preco;
      });
    }

    setProductsFilter(registers);
  };

  const closeAdd = () => {
    setError(false);
    setDisplay({});
  };

  const addCart = async () => {
    debugger;
    if (!tamanho && detalhesProduto.tamanho !== "[]") {
      setError(true);
      setMessage('EnterTheSize');
      return;
    }

    setError(false);
    dispatch(adicionarProdutoCarrinho({ ...detalhesProduto, tamanho, qtd }));

    let newArray = productsFilter;
    productsFilter.map((item, i) => {
      if (item.id === detalhesProduto.id) newArray[i].adicionado = true;
    });

    setProductsFilter(newArray);
    closeAdd();
  };

  const edit = (e, produto) => {
    setQtd(1);
    setTamanho(null);
    setDetalhesProduto(produto);

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('StudentProductsPage.FilterCard.Title')}</h2>
                    <p className='card_subtitle'>{t('StudentProductsPage.FilterCard.Subtitle')}</p>
                  </div>

                  <form action='' method='post' ref={formRef} className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('Product')}
                      </label>
                      <input
                        type='text'
                        placeholder={t('EnterProductName')}
                        className='form_control'
                        onChange={(e) => setFilterTitulo(e.target.value)}
                      />
                    </div>
                    <div className='form_group' style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div style={{ width: '47%' }}>
                        <label htmlFor='title' className='card_details_label'>
                          {t('InitialPrice')}
                        </label>
                        <NumberFormat
                          className='form_control'
                          style={{ width: '100%' }}
                          required
                          placeholder={t('PlaceholderMoneyZero')}
                          displayType={'input'}
                          prefix={'R$ '}
                          format={currencyFormatter}
                          value={filterPrecoInicial}
                          onChange={(e) => setFilterPrecoInicial(e.target.value)}
                        />
                      </div>

                      <div style={{ width: '47%' }}>
                        <label htmlFor='title' className='card_details_label'>
                          {t('FinalPrice')}
                        </label>
                        <NumberFormat
                          className='form_control'
                          style={{ width: '100%' }}
                          required
                          placeholder={t('PlaceholderMoneyZero')}
                          displayType={'input'}
                          prefix={'R$ '}
                          format={currencyFormatter}
                          value={filterPrecoFinal}
                          onChange={(e) => setFilterPrecoFinal(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('ProductCode_abbreviated')}
                      </label>
                      <input
                        type='text'
                        placeholder={t('Example123456')}
                        className='form_control'
                        onChange={(e) => setFilterCodProduto(e.target.value)}
                      />
                    </div>

                    <button className='btn_card mx-auto' onClick={search}>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('Search')}
                    </button>
                  </form>
                </div>
                <div className='card'>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>{t('AvailableProducts')}</h2>
                    <div className='sidebar_data'>{products.length}</div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>{t('ProductsInCart')}</h2>
                    <div className='sidebar_data'>{Cart.length}</div>
                  </div>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>
                      {t('StudentProductsPage.PageTitle')}
                      <Badge
                        pill
                        variant='success'
                        style={{
                          marginLeft: 3,
                          background: 'orange',
                          fontWeight: '500',
                        }}
                        className='badge-font-size-50'
                      >
                        {productsFilter.length}
                      </Badge>
                    </h2>
                    <p className='card_subtitle'>{t('StudentProductsPage.PageSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    {process && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!process &&
                      productsFilter.map((item, i) => {
                        return (
                          <div className='card_list' key={`prod_${i}`} style={{ padding: 0, margin: 5 }}>
                            <div className='img_list_user' style={{ width: '100%', height: 200, borderRadius: 0 }}>
                              <img
                                style={{
                                  width: '100%',
                                  height: 200,
                                  objectFit: 'cover',
                                  borderRadius: 0,
                                  borderTopLeftRadius: 6,
                                  borderTopRightRadius: 6,
                                }}
                                src={dirUsuFotoPrincipal + '/' + item.fotoCapa}
                                alt=''
                              />
                            </div>
                            <div className='content_card' style={{ margin: '10px 10px' }}>
                              <div className='card_details'>
                                <p className='card_details_title' title={item.titulo}>
                                  {item.titulo.length > 20 ? item.titulo.substr(0, 20) + '...' : item.titulo}
                                </p>
                              </div>
                              <div
                                className='card_details'
                                style={{ justifyContent: 'left', alignItems: 'flex-start' }}
                              >
                                <p className='card_details_label'>{t('Price')}: </p>
                                <p className='card_details_text'>
                                  {item.cortesia
                                    ? t('ComplimentaryProduct')
                                    : t('FormatFunction.intlCurrencyWithOptions', { value: item.preco })}
                                </p>
                              </div>
                              <div
                                className='card_details'
                                style={{ justifyContent: 'left', alignItems: 'flex-start' }}
                              >
                                <p className='card_details_label'>{t('Sizes')}: </p>
                                {
                                  item.tamanho !== "[]" ?
                                <p className='card_details_text'>
                                  {sortProductSizes(JSON.parse(item.tamanho)).join(', ')}
                                </p>
                                :
                                <p className='card_details_text'>
                                  {t('Unique')}
                                </p>
                                }
                              </div>
                              <div
                                className='card_details'
                                style={{ justifyContent: 'left', alignItems: 'flex-start' }}
                              >
                                <p className='card_details_label'>{t('ProductCode', { context: 'abbreviated' })}: </p>
                                <p className='card_details_text'>{item.cod_produto}</p>
                              </div>
                              <div
                                className='card_details'
                                style={{ justifyContent: 'left', alignItems: 'flex-start', marginBottom: 3 }}
                              >
                                <p className='card_details_label'>{t('Photos')}: </p>
                                <p className='card_details_text'>{item.products_fotos.length}</p>
                              </div>
                            </div>
                            <span
                              style={{
                                backgroundColor: 'orange',
                                fontWeight: 500,
                                border: '1px solid orange',
                                marginBottom: 10,
                              }}
                              onClick={(e) => edit(e, item)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('SeeProduct')}
                            </span>
                            <span
                              style={{
                                backgroundColor: 'green',
                                fontWeight: 500,
                                border: '1px solid green',
                                marginBottom: 10,
                              }}
                              onClick={(e) => edit(e, item)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('Add')}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              {display.display === 'flex' && (
                <div className='modal' id='add_aluno' style={display}>
                  <div className='modal_content' style={{ width: 1000 }} ref={modalScroll}>
                    <div className='modal_close' onClick={closeAdd}>
                      X
                    </div>
                    <div className='row flex-column flex-md-row' style={{ margin: '10px 0px' }}>
                      <div className='col-12 col-md-6 px-0'>
                        <Carousel
                          showArrows={true}
                          showIndicators={true}
                          showThumbs={false}
                          showStatus={true}
                          statusFormatter={(i, a) => `${i} de ${a}`}
                        >
                          {Object.keys(detalhesProduto).length > 0 &&
                            detalhesProduto.products_fotos.map((item) => (
                              <div key={item.id}>
                                <img
                                  src={dirUsuFotoPrincipal + '/' + item.nome}
                                  style={{ width: '100%', height: 500, objectFit: 'cover' }}
                                />
                              </div>
                            ))}
                        </Carousel>
                      </div>
                      <div style={{ paddingLeft: 10 }} className='col-12 col-md-6 mt-3 mt-md-0 px-0 pl-md-4'>
                        <h3 style={{ color: '#0c2120' }}>
                          <b>{detalhesProduto.titulo}</b>
                        </h3>
                        <p style={{ fontSize: 25 }}>
                          {detalhesProduto.cortesia
                            ? t('ComplimentaryProduct')
                            : t('FormatFunction.intlCurrencyWithOptions', { value: detalhesProduto.preco })}
                        </p>
                        <p style={{ marginBottom: 20, fontSize: 14 }}>
                          <b>
                            {t('Size', {
                              count: JSON.parse(detalhesProduto.tamanho ? detalhesProduto.tamanho : '[]').length,
                            })}
                            :
                          </b>{' '}
                          {Object.keys(detalhesProduto).length > 0
                            ? sortProductSizes(JSON.parse(detalhesProduto.tamanho)).join(', ')
                            : ''}
                        </p>
                        <p style={{ marginBottom: 20, fontSize: 14 }}>
                          <b>{t('Description')}:</b> {detalhesProduto.descricao}
                        </p>

                        <div
                          className='form_group flex-wrap'
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 15,
                            marginBottom: 10,
                            columnGap: '10px',
                          }}
                        >
                          {
                            detalhesProduto.tamanho !== "[]" &&
                            <span
                              style={{
                                fontSize: '0.95rem',
                                fontWeight: 600,
                                fontStyle: 'normal',
                                marginRight: 10,
                                color: '#ba8820',
                              }}
                            >
                              {t('Choose')}:{' '}
                            </span>
                          }
                          {Object.keys(detalhesProduto).length > 0 &&
                            sortProductSizes(JSON.parse(detalhesProduto.tamanho)).map((tamanho) => (
                              <div
                                key={`Produto_detalhesProduto_${tamanho}`}
                                style={{ display: 'flex', columnGap: '3px' }}
                              >
                                <input
                                  type='radio'
                                  name='tamanhoEscolher'
                                  onChange={(e) => setTamanho(e.target.value)}
                                  defaultValue={tamanho}
                                />
                                <span style={{ fontSize: '0.80rem' }}>{tamanho}</span>
                              </div>
                            ))}
                        </div>

                        <div
                          className='form_group'
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 0,
                            marginBottom: 20,
                          }}
                        >
                          <span
                            style={{
                              fontSize: '0.95rem',
                              fontWeight: 600,
                              fontStyle: 'normal',
                              marginRight: 10,
                              color: '#ba8820',
                            }}
                          >
                            {t('Quantity')}:{' '}
                          </span>

                          <select
                            required
                            value={qtd}
                            className='form_control select'
                            onChange={(e) => setQtd(e.target.value)}
                          >
                            {[...Array(10).keys()].map((_, index) => (
                              <option key={'product_quantity' + index} value={(index + 1).toString()}>
                                {t('UnitCount', { count: index + 1, value: index + 1 })}
                              </option>
                            ))}
                          </select>
                        </div>

                        {error && (
                          <MessageDangerMarginTop
                            title={t('Attention', { context: 'exclamation' }) + ' '}
                            description={t(message)}
                          />
                        )}
                        <span
                          style={{
                            backgroundColor: 'green',
                            fontWeight: 500,
                            border: '1px solid green',
                            marginTop: 20,
                          }}
                          onClick={() => addCart()}
                          className='btn_card'
                        >
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t('AddToCart')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Products;
