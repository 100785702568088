import {createStore} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducers from './reducers';

const persistConfig = {
    key: 'root',
    storage,
    //whitelist: [""]
    //blacklist: [""]
}

const persisteReducer = persistReducer(persistConfig, rootReducers);

const store = createStore(persisteReducer);
const persistor = persistStore(store);

export {store, persistor};