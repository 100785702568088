import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Footer from '../../components/club/Footer';
import Logo from '../../components/school/Logo';
import {
  authClient,
  cancelMoneySubscribe,
  cancelSubscribe,
  getAsaasConfigSessionClub,
  saveClient,
  sendMoneySubscribe,
  sendSubscribe,
} from '../../services/PaymentService';
import { PaymentErrors } from '../../utils/PaymentErrors';
import Api from '../../utils/api';
import Utils from '../../utils/utils';

const ContractsSchool = () => {
  // indexs
  const [id, setID] = useState(null);
  const [company_id, setCompanyID] = useState(undefined);
  const [userID, setUserID] = useState(undefined);
  const [configAsaas, setConfigAsaas] = useState(undefined);

  // inputs modal
  const [modalSchool, setModalSchool] = useState(null);
  const [modalService, setModalService] = useState('');
  const [modalCycle, setModalCycle] = useState('');
  const [modalTypePayment, setModalTypePayment] = useState('');
  const [modalValue, setModalValue] = useState(null);
  const [modalDateStart, setModalDateStart] = useState('');
  const [modalContractDateStart, setModalContractDateStart] = useState('');
  const [modalDateEnd, setModalDateEnd] = useState('');
  const [modalObservation, setModalObservation] = useState('');
  const [modalFaturado, setModalFaturado] = useState(false);
  const [modalPaymentID, setModalPaymentID] = useState('');
  const [modalStatus, setModalStatus] = useState('Ativo');
  const [modalDiscountType, setModalDiscountType] = useState('NONE');
  const [modalDiscount, setModalDiscount] = useState(null);
  const [modalLateInterestType, setModalLateInterestType] = useState('NONE');
  const [modalLateInterest, setModalLateInterest] = useState(null);
  const [modalLateFeeType, setModalLateFeeType] = useState('NONE');
  const [modalLateFee, setModalLateFee] = useState(null);

  // inputs modal disabled
  const [disabled, setDisabled] = useState(false);

  // carregamentos
  const [contracts, setContracts] = useState([]);
  const [modalSchools, setModalSchools] = useState([]);

  // filtros
  const [filterSchool, setFilterSchool] = useState('');
  const [filterService, setFilterService] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [contractsFilter, setContractsFilter] = useState([]);

  // process
  const [process, setProcess] = useState(false);
  const [sendSave, setSendSave] = useState(false);
  const [display, setDisplay] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [message, setMessage] = useState('');
  const [warningFaturamento, setWarningFaturamento] = useState(false);
  const [infoFaturamento, setInfoFaturamento] = useState(false);

  // libraries
  const { t } = useTranslation();
  const baseURL = Api();
  const { scrollTop, currencyFormatter, currencyFormatterText, dateFormat, validaCNPJ } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop, MessageWarningMarginTop } = Message();
  let modalScroll = useRef();

  useEffect(() => {
    const usuSession = JSON.parse(localStorage.getItem('user'));
    const companySession = JSON.parse(localStorage.getItem('company'));

    setUserID(usuSession.id);
    setCompanyID(companySession.id);
    getContracts(companySession.id);
    setConfigAsaas(getAsaasConfigSessionClub(companySession));

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  async function getContracts(company) {
    setProcess(true);
    const res = await axios.post(baseURL + 'get-contracts-club', {
      companie_id: company,
    });

    setContracts(res.data.contracts);
    setContractsFilter(res.data.contracts);
    setModalSchools(res.data.schools);

    setProcess(false);
  }

  const search = (e) => {
    e.preventDefault();

    let registers = [];
    if (filterSchool !== '') {
      registers = registers.filter((item) => {
        return item.school_id === Number(filterSchool);
      });
    } else {
      registers = contracts;
    }

    if (filterService !== '') {
      registers = registers.filter((item) => {
        return item.service === filterService;
      });
    }

    if (filterStatus === 'Ativo') {
      registers = registers.filter((item) => {
        let dateEnd = item.dateEnd;
        let dateNow = new Date().toISOString().substr(0, 10);
        return item.dateEnd === null || dateNow < dateEnd;
      });
    }

    if (filterStatus === 'Encerrado') {
      registers = registers.filter((item) => {
        let dateEnd = item.dateEnd;
        let dateNow = new Date().toISOString().substr(0, 10);
        return item.dateEnd !== null && dateNow >= dateEnd;
      });
    }

    if (filterStatus === 'Inativo') {
      registers = registers.filter((item) => {
        let dateEnd = item.dateEnd;
        let dateNow = new Date().toISOString().substr(0, 10);
        return item.dateEnd !== null && dateNow >= dateEnd;
      });
    }

    setContractsFilter(registers);
  };

  const newContract = () => {
    setError(false);
    setSuccess(false);
    setWarningFaturamento(false);
    setInfoFaturamento(false);

    setID(null);
    setModalSchool(null);
    setModalService('');
    setModalCycle('');
    setModalTypePayment('');
    setModalValue('');
    setModalDateStart('');
    setModalContractDateStart('');
    setModalDateEnd('');
    setModalStatus('Ativo');
    setModalObservation('');
    setModalFaturado('N');
    setModalDiscountType('NONE');
    setModalDiscount(null);
    setModalLateInterestType('NONE');
    setModalLateInterest(null);
    setModalLateFeeType('NONE');
    setModalLateFee(null);

    setModalPaymentID('');
    setDisabled(false);

    setDisplay(true);
  };

  const closeModal = () => {
    setDisplay(false);
  };

  const handleSubmit = async (e = null) => {
    e && e.preventDefault();

    showLoading(true);
    const { message, success } = await submit();

    if (success) {
      showSuccessMessage(message);
      getContracts(company_id);
    } else showErrorMessage(message);
  };

  const submit = async () => {
    let body = {
      school_id: modalSchool.id,
      observation: modalObservation,
      dateStart: modalDateStart,
      contractDateStart: modalContractDateStart,
      dateEnd: modalDateEnd,
      status: modalStatus,
      value: modalValue,
      service: modalService,
      cyclo: modalCycle,
      payment: modalTypePayment,
      discount_type: modalDiscountType === 'NONE' ? null : modalDiscountType,
      discount: modalDiscount || null,
      late_interest_type: modalLateInterestType === 'NONE' ? null : modalLateInterestType,
      late_interest: modalLateInterest || null,
      late_fee_type: modalLateFeeType === 'NONE' ? null : modalLateFeeType,
      late_fee: modalLateFee || null,
    };
    let endpoint = '';

    if (id === null) {
      endpoint = 'save-contract-club';
      body = {
        ...body,
        companie_id: company_id,
        user_id: userID,
        faturado: 'N',
        sign: 'S',
      };

      setModalFaturado('N');
    } else {
      endpoint = 'update-contract-club';
      body = {
        ...body,
        id,
      };
    }

    try {
      const { data } = await axios.post(baseURL + endpoint, body);

      setID(data.create.id);
      setContracts(data.contracts);
      setContractsFilter(data.contracts);

      return { success: true, message: data.message, faturado: data.create.faturado, contract: data.create };
    } catch (error) {
      return { success: false, message: 'Erro interno. Por favor, tente novamente.' };
    }
  };

  const edit = (_, contract) => {
    clearMessage();

    setID(contract.id);
    setModalSchool({
      id: contract.school_id,
      name: contract.schName,
      cpf_cnpj: contract.schCnpj,
      email: contract.schEmail,
    });

    setModalService(contract.service);
    setModalCycle(contract.cyclo);
    setModalTypePayment(contract.payment);
    setModalValue(contract.value);
    setModalDateStart(contract.dateStart);
    setModalContractDateStart(contract.contractDateStart);
    setModalDateEnd(contract.dateEnd);
    setModalStatus(contract.status);
    setModalObservation(contract.observation);
    setModalFaturado(contract.faturado);
    setModalDiscountType(contract.discount_type);
    setModalDiscount(contract.discount);
    setModalLateInterestType(contract.late_interest_type);
    setModalLateInterest(contract.late_interest);
    setModalLateFeeType(contract.late_fee_type);
    setModalLateFee(contract.late_fee);
    setModalPaymentID(contract.payment_subscription);

    if (contract.faturado === 'S') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    setDisplay(true);
  };

  const handleFaturar = async () => {
    const descriptionCycle = {
      MONTHLY: 'mensal',
      QUARTERLY: 'trimestral',
      SEMIANNUALLY: 'semestral',
      YEARLY: 'anual',
    };
    const descriptionTypePayment = {
      CREDIT_CARD: 'cartão de crédito',
      BOLETO: 'boleto',
      MONEY: 'dinheiro',
    };

    try {
      clearMessage();

      if (!validaCNPJ(modalSchool.cpf_cnpj)) {
        throw new Error(PaymentErrors.INVALID_CNPJ);
      }

      showLoading(true);

      if (!(configAsaas.cnpj && configAsaas.password && configAsaas.token_assas && configAsaas.wallet)) {
        throw new Error(PaymentErrors.SCHOOL_ASAAS_CONFIG_INVALID);
      }

      const saveResult = await submit();
      if (!saveResult.success) {
        const error = new Error('SAVE_ERROR');
        error.auxData = saveResult.message;
        throw error;
      }

      if (saveResult.faturado === 'S') {
        edit(null, saveResult.contract);
        getContracts(company_id);
        throw new Error('ALREADY_INVOICED');
      }

      const resAuth = await authClient({
        cnpj: configAsaas.cnpj,
        password: configAsaas.password,
        loginEmail: configAsaas.loginEmail,
      });

      // envia o cliente para obter o customer_id
      const resCli = await saveClient({
        cpf_cnpj: modalSchool.cpf_cnpj,
        name: modalSchool.name,
        email: modalSchool.email,
        customer: '',
        system_id: configAsaas.system_id,
        user_id: resAuth.user_id,
        token_assas: configAsaas.token_assas,
      });

      const request = {
        billingType: modalTypePayment,
        customer: resCli.data.customer,
        nextDueDate: modalDateStart,
        cycle: modalCycle,
        endDate: '',
        value: modalValue,
        client_id: resCli.data.id,
        description: `Contrato com cobrança ${descriptionCycle[modalCycle]} por ${descriptionTypePayment[modalTypePayment]}`,
        contract: id,

        token_assas: configAsaas.token_assas,
        user_id: userID,
        school_id: modalSchool.id,
        companie_id: company_id,
      };

      if (configAsaas.percentual) {
        request.split = [
          {
            walletId: configAsaas.wallet,
            percentualValue: configAsaas.percentual,
          },
        ];
      } else if (configAsaas.valor) {
        request.split = [
          {
            walletId: configAsaas.wallet,
            fixedValue: configAsaas.valor,
          },
        ];
      }

      if (modalDiscount) {
        request.discount = { value: modalDiscount, dueDateLimitDays: 0, type: modalDiscountType };
      }

      if (modalLateInterest) {
        request.interest = { value: modalLateInterest, type: modalLateInterestType };
      }

      if (modalLateFee) {
        request.fine = { value: modalLateFee, type: modalLateFeeType };
      }

      // cria a assinatura
      const response = await sendSubscribe({ paymentMethod: modalTypePayment, billingData: request });

      setDisabled(true);
      setModalFaturado('S');
      showSuccessMessage('Assinatura ativada. A Escola será comunicada em todo vencimento...');
      getContracts(company_id);

      setModalPaymentID(response.paymentResponse.idAssas);
      setTimeout(() => {
        closeModal();
      }, 3000);
    } catch (error) {
      switch (error.message) {
        case PaymentErrors.INVALID_CNPJ:
          showErrorMessage('O CNPJ da escola é inválido, altere o cadastro e tente novamente...');
          break;

        case PaymentErrors.SCHOOL_ASAAS_CONFIG_INVALID:
          showErrorMessage('Este clube deve ser parametrizado pela equipe Fensor. Entre em contato.');
          console.log('Configuração do ASAAS inválida.');
          break;

        case PaymentErrors.SERVER_COMMUNICATION_FAILED:
          showErrorMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...');
          break;

        case PaymentErrors.INVALID_API_AUTH:
          showErrorMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...');
          break;

        case PaymentErrors.UNABLE_SEND_CUSTOMER:
          showErrorMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...');
          break;

        case PaymentErrors.CUSTOMER_VALIDATION_ERROR:
          showErrorMessage(error.auxData);
          break;

        case PaymentErrors.UNABLE_VALIDATE_PAYMENT:
          showErrorMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...');
          break;

        case PaymentErrors.SUBSCRIBE_STATUS_ERROR:
          showErrorMessage('Assinatura não ativada, tente novamente...');
          setDisabled(false);
          setModalFaturado('N');
          break;

        case PaymentErrors.SUBSCRIBE_ERROR:
          showErrorMessage(error.auxData);
          break;

        case 'SAVE_ERROR':
          showErrorMessage(error.auxData);
          // showErrorMessage('Erro ao salvar o contrato. Por favor, tente novamente.');
          break;

        case 'ALREADY_INVOICED':
          showErrorMessage(t('ContractAlreadyInvoiced'));
          break;

        default:
          throw error;
      }
    }
  };

  const handleFaturarDinheiro = async () => {
    const descriptionCycle = {
      MONTHLY: 'mensal',
      QUARTERLY: 'trimestral',
      SEMIANNUALLY: 'semestral',
      YEARLY: 'anual',
    };
    const descriptionTypePayment = {
      CREDIT_CARD: 'cartão de crédito',
      BOLETO: 'boleto',
      MONEY: 'dinheiro',
    };

    try {
      clearMessage();

      if (modalDateStart < modalContractDateStart) {
        throw new Error('FirstDueDateInvalid');
      }
      if (modalTypePayment === 'MONEY' && modalDateEnd < modalContractDateStart) {
        throw new Error('EndDateInvalid');
      }

      if (!validaCNPJ(modalSchool.cpf_cnpj)) {
        throw new Error(PaymentErrors.INVALID_CNPJ);
      }

      showLoading(true);

      const saveResult = await submit();
      if (!saveResult.success) {
        const error = new Error('SAVE_ERROR');
        error.auxData = saveResult.message;
        throw error;
      }

      const data = {
        billingType: modalTypePayment,
        nextDueDate: modalDateStart,
        cycle: modalCycle,
        endDate: modalDateEnd,
        startDate: modalContractDateStart,
        value: modalValue,
        description: `Contrato com cobrança ${descriptionCycle[modalCycle]} por ${descriptionTypePayment[modalTypePayment]}`,
        contract: id,
        user_id: userID,
        school_id: modalSchool.id,
        companie_id: company_id,
      };

      if (modalDiscount) {
        data.discount = { value: modalDiscount, dueDateLimitDays: 0, type: modalDiscountType };
      }

      if (modalLateInterest) {
        data.interest = { value: modalLateInterest, type: modalLateInterestType };
      }

      if (modalLateFee) {
        data.fine = { value: modalLateFee, type: modalLateFeeType };
      }

      await sendMoneySubscribe(data);

      setDisabled(true);
      setModalFaturado('S');
      showSuccessMessage(t('StudentContractsPage.SystemMessage.SubscribeApproved'));
      getContracts(company_id);

      setTimeout(() => {
        closeModal();
      }, 3000);
    } catch (error) {
      switch (error.message) {
        case 'FirstDueDateInvalid':
          showErrorMessage(t('StudentContractsPage.SystemMessage.FirstDueDateInvalid'));
          break;

        case 'EndDateInvalid':
          showErrorMessage(t('StudentContractsPage.SystemMessage.EndDateInvalid'));
          break;

        case PaymentErrors.INVALID_CNPJ:
          showErrorMessage('O CNPJ da escola é inválido, altere o cadastro e tente novamente...');
          break;

        case 'SAVE_ERROR':
          showErrorMessage(error.auxData);
          // showErrorMessage('Erro ao salvar o contrato. Por favor, tente novamente.');
          break;

        case PaymentErrors.MONEY_SUBSCRIBE_ERROR:
          showErrorMessage(t('StudentContractsPage.SystemMessage.ValidationError'));
          break;

        default:
          throw error;
      }
    }
  };

  const handleCancelarFaturamento = async () => {
    try {
      // cancela a assinatura
      showLoading(true);
      const response = await cancelSubscribe({
        idAsaas: modalPaymentID,
        token_assas: configAsaas.token_assas,
        contract_id: id,
        contract_type: 'CLUB',
      });
      setSendSave(false);

      if (response.status === 'SUCCESS' || response.status === 'WARNING') {
        if (response.status === 'SUCCESS') {
          showSuccessMessage('Faturamento cancelado.');
        } else {
          showWarningMessage(response.messageKey);
        }

        setDisabled(false);
        setModalFaturado('N');
        getContracts(company_id);

        setTimeout(() => {
          closeModal();
        }, 3000);
      } else {
        if (response.message === "TypeError: Cannot read property 'subscription' of null")
          showErrorMessage('Faturamento não cancelado. Por favor, espere um pouco e tente novamente.');
        else showErrorMessage('Faturamento não cancelado. Por favor, tente novamente.');

        setTimeout(() => {
          closeModal();
        }, 3000);
      }
    } catch (error) {
      showErrorMessage('Estamos com problema de comunicação com o servidor. Por favor, tente novamente.');
    }
  };

  const handleCancelarFaturamentoDinheiro = async () => {
    try {
      showLoading();

      await cancelMoneySubscribe({ contract_id: id, contract_type: 'CLUB' });

      showSuccessMessage('Faturamento cancelado.');
      setDisabled(false);
      setModalFaturado('N');
      getContracts(company_id);

      setTimeout(() => {
        closeModal();
      }, 3000);
    } catch (error) {
      if (error.message === PaymentErrors.PROCESSING_ERROR)
        showErrorMessage('O faturamento não foi cancelado. Por favor, tente novamente.');
      else showErrorMessage('Estamos com problema de comunicação com o servidor. Por favor, tente novamente.');

      setTimeout(() => {
        closeModal();
      }, 3000);
    }
  };

  const clearMessage = () => {
    setError(false);
    setSuccess(false);
    setWarningFaturamento(false);
    setInfoFaturamento(false);
    setSendSave(false);
    setMessage('');
  };

  const showErrorMessage = (message) => {
    clearMessage();
    setError(true);
    setMessage(message);
    scrollTop(modalScroll);
  };
  const showSuccessMessage = (message) => {
    clearMessage();
    setSuccess(true);
    setMessage(message);
    scrollTop(modalScroll);
  };

  const showWarningMessage = (message) => {
    clearMessage();
    setWarning(true);
    setMessage(message);
    scrollTop(modalScroll);
  };

  const showWarningFaturamentoMessage = () => {
    clearMessage();
    setWarningFaturamento(true);
    scrollTop(modalScroll);
  };
  console.log(modalStatus);
  const showInfoFaturamentoMessage = () => {
    clearMessage();
    setInfoFaturamento(true);
    scrollTop(modalScroll);
  };

  const showLoading = () => {
    clearMessage();
    setSendSave(true);
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Adicionar contrato</h2>
                    <p className='card_subtitle'>Clique abaixo para adicionar contratos para os alunos.</p>
                  </div>
                  <button onClick={newContract} className='btn_card mx-auto mt-4 btn_open_modal' data-modal='#noticia'>
                    <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                    </svg>
                    Adicionar contrato
                  </button>
                </div>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Procurar</h2>
                    <p className='card_subtitle'>Filtrar os contrato.</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='news' className='card_details_label'>
                        Nome da Escola
                      </label>
                      <Autocomplete
                        id='option-student-search'
                        noOptionsText='Nenhuma escola com este nome...'
                        loadingText='Carregando...'
                        options={modalSchools}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        style={{ width: '100%' }}
                        onChange={(event, newValue) => {
                          setFilterSchool(newValue === null ? '' : newValue.id);
                        }}
                        renderInput={(params) => <TextField {...params} label='Digite o nome...' variant='outlined' />}
                      />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='schedule' className='card_details_label'>
                        Serviço Contratado
                      </label>
                      <select
                        className='form_control select'
                        value={filterService}
                        onChange={(e) => setFilterService(e.target.value)}
                      >
                        <option value='Royalts'>Royalts</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='schedule' className='card_details_label'>
                        Status
                      </label>
                      <select
                        className='form_control select'
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value=''>Todos</option>
                        <option value='Ativo'>Ativo</option>
                        <option value='Encerrado'>Encerrado</option>
                        <option value='Inativo'>Inativo</option>
                      </select>
                    </div>
                    <button className='btn_card mx-auto' onClick={search}>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      Buscar
                    </button>
                  </form>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Resultado da pesquisa</h2>
                    <p className='card_subtitle'>Veja abaixo os contratos cadastrados.</p>
                  </div>
                  <div className='list_card'>
                    {process && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!process &&
                      contractsFilter.map((item, i) => {
                        return (
                          <div className='card_list' key={`contracts_${i}`}>
                            <div className='img_list_user'>
                              <Logo schLogomarca={item.schLogomarca} />
                            </div>
                            <div className='content_card'>
                              <div className='card_details'>
                                <p className='card_details_title'>Contrato {String(item.number).padStart(6, '0')}</p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Escola: </p>
                                <p className='card_details_text'>{item.schName}</p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Data início: </p>
                                <p className='card_details_text'>{dateFormat(item.contractDateStart)}</p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Data fim: </p>
                                <p className='card_details_text'>{dateFormat(item.dateEnd)}</p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Valor: </p>
                                <p className='card_details_text'>{currencyFormatterText(item.value)}</p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Faturando: </p>
                                <p className='card_details_text'>
                                  <strong style={{ color: 'red' }}>{item.faturado == 'S' ? 'Sim' : 'Não'}</strong>
                                </p>
                              </div>
                            </div>
                            <button
                              style={{
                                backgroundColor: 'orange',
                                fontWeight: 500,
                                border: '1px solid orange',
                                marginBottom: 10,
                              }}
                              onClick={(e) => edit(e, item)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              Edit contrato
                            </button>
                            <Link
                              style={{ fontWeight: 500 }}
                              to={{
                                pathname: '/detail-school',
                                state: {
                                  school: modalSchools.find((school) => school.id == item.idSchool),
                                },
                              }}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              Ver escola
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className='modal' id='add_contracts' style={display ? { display: 'flex', opacity: 1 } : undefined}>
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeModal}>
                    X
                  </div>
                  <h3 className='modal_title'>{id === null ? 'Incluir' : 'Alterar'} Contrato</h3>
                  {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
                  {success && <MessageSuccessMarginTop title='Sucesso! ' description={message} />}
                  {warning && <MessageWarningMarginTop title='Atenção! ' description={message} />}
                  {warningFaturamento && (
                    <div className='alert alert-margin-top alert-warning alert-dismissible fade show' role='alert'>
                      {modalTypePayment === 'MONEY' ? (
                        <>
                          <strong>Atenção! </strong>
                          Após faturar, serão agendadas as cobranças em dinheiro desta escola e não serão permitidas
                          alterações referentes ao financeiro deste contrato. <br />
                          <br />
                          Deseja continuar ?{' '}
                          <strong style={{ cursor: 'pointer' }} onClick={handleFaturarDinheiro}>
                            se sim, clique aqui!
                          </strong>
                        </>
                      ) : (
                        <>
                          <strong>Atenção! </strong>
                          Após faturar, serão agendadas as cobranças desta escola e não serão permitidas alterações
                          referentes ao financeiro deste contrato. <br />
                          <br />
                          Deseja continuar ?{' '}
                          <strong style={{ cursor: 'pointer' }} onClick={handleFaturar}>
                            se sim, clique aqui!
                          </strong>
                        </>
                      )}
                    </div>
                  )}
                  {infoFaturamento && (
                    <div className='alert alert-margin-top alert-info alert-dismissible fade show' role='alert'>
                      {modalTypePayment === 'MONEY' ? (
                        <>
                          <strong>Info! </strong>
                          Este contrato esta faturado. Ao cancelar, todas as parcelas em aberto serão excluídas. <br />
                          <br />
                          Deseja encerrar ou cancelar esta cobrança ?{' '}
                          <strong style={{ cursor: 'pointer' }} onClick={handleCancelarFaturamentoDinheiro}>
                            se sim, clique aqui!
                          </strong>
                        </>
                      ) : (
                        <>
                          <strong>Info! </strong>
                          Este contrato esta faturado, a escola recebe a cobrança em todo vencimento. <br />
                          <br />
                          Deseja encerrar ou cancelar esta cobrança ?{' '}
                          <strong style={{ cursor: 'pointer' }} onClick={handleCancelarFaturamento}>
                            se sim, clique aqui!
                          </strong>
                        </>
                      )}
                    </div>
                  )}
                  {sendSave && (
                    <p style={{ textAlign: 'center' }}>
                      Aguarde, processando... <Loading type='4' style={{ color: 'green' }} />
                    </p>
                  )}
                  <form action='' onSubmit={handleSubmit} method='post' className='form_modal'>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='title' className='card_details_label'>
                          Escola
                        </label>
                        <Autocomplete
                          required
                          disabled={disabled}
                          id='option-student-modal'
                          noOptionsText='Nenhuma escola com este nome...'
                          loadingText='Carregando...'
                          options={modalSchools}
                          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                          getOptionSelected={(option, value) => option.id === value.id}
                          style={{ width: '100%' }}
                          value={modalSchool}
                          onChange={(event, newValue) => {
                            setModalSchool(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label='Digite o nome...' variant='outlined' />
                          )}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='position' className='card_details_label'>
                          Serviços Contratado
                        </label>
                        <select
                          required
                          disabled={disabled}
                          value={modalService}
                          className='form_control select'
                          onChange={(e) => setModalService(e.target.value)}
                        >
                          <option value='' disabled></option>
                          <option value='Royalts'>Royalts</option>
                        </select>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='schedule' className='card_details_label'>
                          Ciclo de Cobrança
                        </label>
                        <select
                          required
                          className={`form_control select ${disabled ? 'disabled' : ''}`}
                          disabled={disabled}
                          value={modalCycle}
                          onChange={(e) => setModalCycle(e.target.value)}
                        >
                          <option value='' disabled></option>
                          <option value='MONTHLY'>Mensal</option>
                          <option value='QUARTERLY'>Trimestral</option>
                          <option value='SEMIANNUALLY'>Semestral</option>
                          <option value='YEARLY'>Anual</option>
                        </select>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='schedule' className='card_details_label'>
                          Forma de pagamento
                        </label>
                        <select
                          required
                          className={`form_control select ${disabled ? 'disabled' : ''}`}
                          disabled={disabled}
                          value={modalTypePayment}
                          onChange={(e) => setModalTypePayment(e.target.value)}
                        >
                          <option value='' disabled></option>
                          <option value='CREDIT_CARD'>Cartão</option>
                          <option value='BOLETO'>Boleto</option>
                          <option value='MONEY'>Dinheiro</option>
                        </select>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='descript' required className='card_details_label'>
                          Valor mensal sem desconto
                        </label>
                        <NumberFormat
                          required
                          className='form_control'
                          placeholder='R$ 0,00'
                          displayType={'input'}
                          prefix={'R$ '}
                          format={currencyFormatter}
                          value={modalValue * 100}
                          onValueChange={(e) => setModalValue(e.floatValue / 100)}
                          disabled={disabled}
                        />
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>Tipo de desconto</label>
                          <select
                            className={`form_control select ${disabled ? 'disabled' : ''}`}
                            value={modalDiscountType}
                            onChange={(e) => {
                              setModalDiscountType(e.target.value);
                              setModalDiscount(null);
                            }}
                            disabled={disabled}
                          >
                            <option value='NONE'>Sem desconto</option>
                            <option value='PERCENTAGE'>Percentual</option>
                            <option value='FIXED'>Fixo</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>Valor de desconto</label>
                          <NumberFormat
                            required
                            className='form_control'
                            displayType={'input'}
                            placeholder={
                              modalDiscountType === 'FIXED'
                                ? 'R$ 0,00'
                                : modalDiscountType === 'PERCENTAGE'
                                  ? '0,00%'
                                  : ''
                            }
                            format={(e) =>
                              modalDiscountType === 'FIXED'
                                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                                : modalDiscountType === 'PERCENTAGE'
                                  ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                                  : ''
                            }
                            disabled={disabled}
                            readOnly={modalDiscountType === 'NONE'}
                            isAllowed={({ floatValue }) =>
                              modalDiscountType === 'PERCENTAGE' ? floatValue < 10000 : true
                            }
                            value={modalDiscount * 100}
                            onValueChange={(e) => setModalDiscount(e.floatValue / 100 || undefined)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            {t('LateInterestType')}
                          </label>
                          <select
                            className={`form_control select ${disabled ? 'disabled' : ''}`}
                            value={modalLateInterestType}
                            onChange={(e) => {
                              setModalLateInterestType(e.target.value);
                              setModalLateInterest(null);
                            }}
                            disabled={disabled}
                          >
                            <option value='NONE'>{t('NoLateInterest')}</option>
                            <option value='PERCENTAGE'>Percentual</option>
                            <option value='FIXED'>Fixo</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            {t('LateInterestAmount')}
                          </label>
                          <NumberFormat
                            className='form_control'
                            displayType={'input'}
                            placeholder={
                              modalLateInterestType === 'FIXED'
                                ? 'R$ 0,00'
                                : modalLateInterestType === 'PERCENTAGE'
                                  ? '0,00%'
                                  : ''
                            }
                            format={(e) =>
                              modalLateInterestType === 'FIXED'
                                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                                : modalLateInterestType === 'PERCENTAGE'
                                  ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                                  : ''
                            }
                            disabled={disabled}
                            readOnly={modalLateInterestType === 'NONE'}
                            isAllowed={({ floatValue }) =>
                              modalLateInterestType === 'PERCENTAGE' ? floatValue < 10000 : true
                            }
                            value={modalLateInterest * 100}
                            onValueChange={(e) => setModalLateInterest(e.floatValue / 100 || undefined)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            {t('LateFeeType')}
                          </label>
                          <select
                            className={`form_control select ${disabled ? 'disabled' : ''}`}
                            value={modalLateFeeType}
                            onChange={(e) => {
                              setModalLateFeeType(e.target.value);
                              setModalLateFee(null);
                            }}
                            disabled={disabled}
                          >
                            <option value='NONE'>{t('NoLateFee')}</option>
                            <option value='PERCENTAGE'>Percentual</option>
                            <option value='FIXED'>Fixo</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            {t('LateFeeAmount')}
                          </label>
                          <NumberFormat
                            className='form_control'
                            displayType={'input'}
                            placeholder={
                              modalLateFeeType === 'FIXED'
                                ? 'R$ 0,00'
                                : modalLateFeeType === 'PERCENTAGE'
                                  ? '0,00%'
                                  : ''
                            }
                            format={(e) =>
                              modalLateFeeType === 'FIXED'
                                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                                : modalLateFeeType === 'PERCENTAGE'
                                  ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                                  : ''
                            }
                            disabled={disabled}
                            readOnly={modalLateFeeType === 'NONE'}
                            isAllowed={({ floatValue }) =>
                              modalLateFeeType === 'PERCENTAGE' ? floatValue < 10000 : true
                            }
                            value={modalLateFee * 100}
                            onValueChange={(e) => setModalLateFee(e.floatValue / 100 || undefined)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='schedule' className='card_details_label'>
                            Status do contrato
                          </label>
                          <select
                            required
                            value={modalStatus}
                            className='form_control select'
                            onChange={(e) => setModalStatus(e.target.value)}
                            disabled={disabled}
                          >
                            <option value='' disabled></option>
                            <option value='Ativo'>Ativo</option>
                            <option value='Inativo'>Inativo</option>
                            <option value='Encerrado'>Encerrado</option>
                          </select>
                        </div>
                      </div>

                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='schedule' className='card_details_label'>
                            Data do primeiro vencimento
                          </label>
                          <input
                            type='date'
                            required
                            className='form_control'
                            disabled={disabled}
                            value={modalDateStart}
                            onChange={(e) => setModalDateStart(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='schedule' className='card_details_label'>
                            Data de início do contrato
                          </label>
                          <input
                            type='date'
                            required
                            className='form_control'
                            value={modalContractDateStart}
                            onChange={(e) => setModalContractDateStart(e.target.value)}
                            disabled={disabled}
                            style={{
                              color:
                                modalFaturado === 'S' && modalTypePayment === 'MONEY' && disabled
                                  ? 'rgba(0, 0, 0, 0.38)'
                                  : '#3b3b3b',
                            }}
                          />
                        </div>
                      </div>

                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            Data de fim do contrato
                          </label>
                          <input
                            type='date'
                            className='form_control'
                            disabled={disabled}
                            value={modalDateEnd}
                            onChange={(e) => setModalDateEnd(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='form_group'>
                      <label htmlFor='descript' className='card_details_label'>
                        Observações
                      </label>
                      <textarea
                        name='descript'
                        value={modalObservation}
                        className='form_control'
                        id=''
                        cols='30'
                        rows='5'
                        onChange={(e) => setModalObservation(e.target.value)}
                      ></textarea>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <button className='btn_card' style={{ marginRight: 5 }}>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {id === null ? 'Incluir' : 'Alterar'} Contrato
                      </button>
                      {id !== null && modalFaturado == 'N' && (
                        <div className='btn_card_red' onClick={() => showWarningFaturamentoMessage()}>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          Faturar Contrato
                        </div>
                      )}
                      {modalFaturado == 'S' && (
                        <div className='btn_card_red' onClick={() => showInfoFaturamentoMessage()}>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          Cancelar Faturamento
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default ContractsSchool;
