import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import Utils, { getURLContractPDF, getUserPhoto } from '../../utils/utils';
import { getStudentClasses } from '../../services/StudentService';
import TableResumeList from './TableResumeList';
import moment from 'moment';
import avatarDefault from '../../assets/img/avatar-default.png';

import '../../utils/extendsType';

import ContractFaturarContext, {
  ContractFaturarContext as ProviderContractContext,
} from '../../Context/ContractFaturarContext';

import Footer from '../../components/school/Footer';
import { Skeleton } from '@material-ui/lab';

const { formatCNPJCPF, formatCEP, diffDaysNow, formatTelefone, dateFormat } = Utils();

const requestContracts = async (id_user) => {
  return await api.get(`student/contracts/${id_user}`);
};

const rowColor = (row) =>
  row.received === 'S' ? 'green' : moment(row.dueDate) < moment() ? 'red' : 'var(--main-color)';

/* sublevel alunos */
const SUB_LEVEL_DISPLAY = {
  BEGINNER: 'Beginner',
  INTERMEDIARY: 'Intermediary',
  ADVANCED: 'Advanced',
};

const Details = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const contextFaturar = React.useContext(ProviderContractContext);

  const [student, setStudent] = React.useState({});

  const [loading, setLoading] = React.useState(true);

  const [financialFilter, setFinancialFilter] = React.useState('TUDO');

  const authUser = JSON.parse(localStorage.getItem('user'));

  const cycle_map = {
    MONTHLY: t('Monthly'),
    QUARTERLY: t('Quarterly'),
    SEMIANNUALLY: t('Semiannually'),
    YEARLY: t('Yearly'),
  };

  const payment_map = {
    CREDIT_CARD: t('Card'),
    BOLETO: t('Bill'),
    MONEY: t('Money'),
    CASH: t('Cash'),
    DEBIT: t('Debit'),
  };
  const columnsContract = [
    {
      id: 'service.nome',
      label: t('ContractedService'),
      minWidth: 120,
      align: 'left',
      format: (value, row) => (row?.service?.nome !== undefined ? row.service.nome : ' - '),
    },
    {
      id: 'payment',
      label: t('Mode'),
      align: 'left',
      format: (value) => payment_map[value],
    },
    {
      id: 'cyclo',
      label: t('Cycle'),
      align: 'left',
      format: (value) => cycle_map[value],
    },
    {
      id: 'value',
      label: t('Value'),
      align: 'left',
      format: (value) => t('FormatFunction.intlCurrencyWithOptions', { value }),
    },
    {
      id: 'dateEnd',
      label: t('EndDate'),
      align: 'left',
      format: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
    },
    {
      id: 'status',
      label: t('Status'),
      align: 'center',
      format: (value) => <StatusRender value={value} />,
    },
    {
      id: 'id',
      label: t('Action'),
      align: 'center',
      minWidth: 110,
      format: (value, row) => authUser.usuStudent !== 'S' && <BarAction row={row} ativos={row.status === 'Ativo'} />,
    },
  ];
  const columns = [
    {
      id: 'type',
      label: t('Type'),
      minWidth: 120,
      align: 'left',
      format: (value, row) => <RecivedRender value={value} row={row} />,
    },
    {
      id: 'mode',
      label: t('Mode'),
      align: 'left',
      format: (value, row) => <RecivedRender value={value} row={row} />,
    },
    {
      id: 'value',
      label: t('Value'),
      align: 'left',
      format: (value, row) => (
        <RecivedRender value={t('FormatFunction.intlCurrencyWithOptions', { value })} row={row} />
      ),
    },
    {
      id: 'valueReceived',
      label: t('Received_abbreviated'),
      align: 'left',
      format: (value, row) => (
        <RecivedRender value={t('FormatFunction.intlCurrencyWithOptions', { value })} row={row} />
      ),
    },
    {
      id: 'dueDate',
      label: t('DueDate_abbreviated'),
      align: 'left',
      format: (value, row) => <RecivedRender value={value ? moment(value).format('DD/MM/YYYY') : ''} row={row} />,
    },
    {
      id: 'id',
      label: t('Action'),
      align: 'center',
      format: (value, row) =>
        authUser.usuStudent !== 'S' && (
          <BtnEdit
            onClick={(f) =>
              history.push('/financial-school', {
                student: student,
                financials: row,
              })
            }
          />
        ),
    },
  ];

  const handlerConfirmFaturar = async (e, row) => {
    //* passa as informações do contrato para o context de faturamento
    contextFaturar.setState({
      contract: row,
      student: student,
    });

    contextFaturar.setState({
      successCallback: () => {
        getStudent();
      },
    });
    //* abre o modal de confirmacao de Faturamento
    contextFaturar.setState({ done: false });
    contextFaturar.handlerConfirmFaturar(e);
  };

  //Action bar buttons ---------------------------------------------------------------
  const BarAction = ({ ativos, row }) => {
    const recived = row.received === 'S';

    return (
      <div className='d-flex justify-content-around align-items-center'>
        {/*   <InvoiceContractButton finance={row} onClick={handlerFaturar} /> */}

        {recived ? (
          <BtnMoney
            title='Faturado'
            disabled={row.faturado === 'S'}
            onClick={(e) => {
              handlerConfirmFaturar(e, row);
            }}
            color='green'
          />
        ) : (
          <BtnMoney
            disabled={row.faturado === 'S'}
            onClick={(e) => {
              handlerConfirmFaturar(e, row);
            }}
            color='var(--main-color)'
          />
        )}
        {/*   <BtnEdit
          onClick={(e) => {
            alert('Ainda sendo implementado');
          }}
        /> */}
        <BtnPdf
          disabled={row.status !== 'Ativo'}
          onClick={(e) => {
            e.preventDefault();
            if (row.status === 'Ativo') window.open(getURLContractPDF(row, e));
          }}
        />
      </div>
    );
  };

  const getStudent = async () => {
    const res = await api.post('get-student-seletivas-frequency', {
      user_id: id,
    });
    if (res.data.student === null) {
      return;
    }
    res.data.student.usuAvatar = await getUserPhoto(
      `${res.data.dir_usuFotoPrincipal}/`,
      res.data.student?.usuFotoPrincipal
    );
    //setDirUsuFotoPrincipal(res.data.dir_usuFotoPrincipal);

    const { classes } = await getStudentClasses(id);
    const { data } = await requestContracts(id);

    res.data.student.turmas = classes;
    res.data.student.contracts = data.contracts;
    res.data.student.finacials = res.data.financials;
    res.data.student.totalAulas = +res.data.countAulas;
    res.data.student.totalFaltas = +res.data.countFaltas;

    setStudent(res.data.student);
    setLoading(false);

    // setCountFaltas(res.data.countFaltas);
    // setCountAulas(res.data.countAulas);
    // calcTimeSchool(res.data.student.created_at);
    // setFilteredFinancials(res.data.financials.filter(({ type }) => type === 'Receita'));

    // getFrequency('', idStudent);
    // getClasses(idStudent);
  };

  React.useEffect(() => {
    contextFaturar.setState({ showLogo: false });
  }, []);

  React.useEffect(() => {
    if (id) {
      getStudent();
    }
  }, [id]);

  const filteredFinaces = (item) => {
    if (financialFilter === 'TUDO') {
      return true;
    } else if (financialFilter === 'VENCIDA') {
      const today = new Date();
      const auxEndDate = item.dueDate.split('-');
      const endDate = new Date(auxEndDate[0], auxEndDate[1] - 1, auxEndDate[2]);
      return endDate < today && item.received === 'N';
    } else if (financialFilter === 'A VENCER') {
      const today = new Date();
      const auxEndDate = item.dueDate.split('-');
      const endDate = new Date(auxEndDate[0], auxEndDate[1] - 1, auxEndDate[2]);
      return endDate >= today && item.received === 'N';
    } else if (financialFilter === 'PAGA') {
      return item.received === 'S';
    }
  };

  if (loading) {
    return (
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel justify-content-center'>
              <div className='row w-100'>
                <div className='col-md-3 col-sm-12'>
                  <div className='card flex-column p-2'>
                    <div className='img_avatar'>
                      <img src={avatarDefault} alt='' style={{ borderRadius: '50%' }} />
                    </div>
                    <div>
                      <Skeleton variant='text' animation='wave' />
                      <Skeleton variant='text' animation='wave' />
                      <Skeleton variant='text' animation='wave' />
                    </div>
                  </div>
                  {/*  */}
                  <div className='card p-0'></div>
                </div>
                <div className='col-md-9 col-sm-12'>
                  <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                      {' '}
                      <div className='card p-2 flex-column' style={{ minHeight: 220 }}>
                        <h6>{t('StudentInformation')}</h6>
                        <div>
                          <Skeleton variant='text' animation='wave' />
                          <Skeleton variant='text' animation='wave' />
                          <Skeleton variant='text' animation='wave' />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                      {' '}
                      <div className='card p-2 flex-column' style={{ minHeight: 220 }}>
                        <h6>{t('FinancialResponsible')}</h6>
                        <div>
                          <Skeleton variant='text' animation='wave' />
                          <Skeleton variant='text' animation='wave' />
                          <Skeleton variant='text' animation='wave' />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                      {' '}
                      <div className='card p-2 flex-column' style={{ minHeight: 220 }}>
                        <div className='card_header'>
                          <h6>{t('Classes_other')}</h6>
                        </div>
                        <div>
                          <Skeleton variant='text' animation='wave' />
                          <Skeleton variant='text' animation='wave' />
                          <Skeleton variant='text' animation='wave' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <div className='card p-2'>
                        <div className='card_header p-4'>
                          <h6>{t('Contracts')}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-sm-12'>
                      <div className='card'>
                        <div className='card_header pt-4 pl-4'>
                          <h6>{t('Financial')}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='container-fluid container_painel'>
      <div className='container'>
        <div className='row content_painel'>
          <main className='content_panel justify-content-center'>
            <div className='row w-100'>
              <div className='col-md-3 col-sm-12'>
                <div className='card p-2'>
                  <div className='img_avatar'>
                    <img src={student.usuAvatar} alt='' style={{ borderRadius: '50%' }} />
                  </div>
                  <div className='w-100 text-center'>
                    <h6 className='text-center'>{student.name}</h6>

                    <p className='small'>
                      <b style={{ color: 'var(--main-color)' }}>{t('Position')}: </b>
                      {student.posPosition && t(`Sports.Position.${student.posPosition}`)}
                    </p>
                    <p className='small'>
                      <b style={{ color: 'var(--main-color)' }}>{t('Birth')}: </b>
                      {dateFormat(student.usuBirth)}
                    </p>
                    <p className='small'>
                      <b style={{ color: 'var(--main-color)' }}>{t('CellPhone')}: </b>
                      {student.usuPhone}
                    </p>
                    <p className='small'>
                      <b style={{ color: 'var(--main-color)' }}>{t('Email')}: </b>
                      {student.email}
                    </p>
                    <p className='small'>
                      <b style={{ color: 'var(--main-color)' }}>Login: </b>
                      {student.usuLogin}
                    </p>
                    <p className='small'>
                      <b style={{ color: 'var(--main-color)' }}>{t('Sublevel')}: </b>
                      {t(SUB_LEVEL_DISPLAY[student.sublevel]) || '--'}
                    </p>
                    <p className='small'>
                      <b style={{ color: 'var(--main-color)' }}>{t('SkillLevel')}: </b>
                      {student.skillLevel == 7 ? '6' : student.skillLevel || '--'}{' '}
                      {parseInt(student.skillLevel) === 7 ? <i className='pl-1'>{t('Complete')}</i> : ''}
                    </p>
                  </div>
                </div>

                {/*  */}
                <div className='card p-0'>
                  <ul className='list-group text-center w-100'>
                    <li className='list-group-item'>
                      <h2 style={{ color: 'var(--main-color)' }}>{student.totalAulas}</h2>
                      <h6>{t('NumberOfClasses')}</h6>
                    </li>
                    <li className='list-group-item'>
                      <h2 style={{ color: 'var(--main-color)' }}>{student.totalFaltas}</h2>
                      <h6>{t('NumberOfAbsences')}</h6>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-9 col-sm-12'>
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                    {' '}
                    <div className='card p-2 flex-column' style={{ minHeight: 220 }}>
                      <h6>{t('StudentInformation')}</h6>
                      <div className='content_card aluno_content'>
                        <div className='w-100'>
                          <div className=''>
                            <p className='card_details_label'>{t('StartDate')}: </p>
                            <p className='card_details_text'>{dateFormat(student.created_at)}</p>
                          </div>
                          <div className=''>
                            <p className='card_details_label'>{t('BestFoot')}: </p>
                            <p className='card_details_text'>{student.athLateralidade}</p>
                          </div>
                          <div className=''>
                            <p className='small'>
                              <b style={{ color: 'var(--main-color)' }}>{t('Address')}: </b>
                              {student.athLogradouro} {student.athNumero} {student.athBairro} {student.citCity} -
                              {student.staStateSigla}
                            </p>
                          </div>
                          <div className=''>
                            <p className='card_details_label'>{t('CEP')}: </p>
                            <p className='card_details_text'>{formatCEP(String(student.athCep))}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-12'>
                    {' '}
                    <div className='card p-2 flex-column' style={{ minHeight: 220 }}>
                      <h6>{t('FinancialResponsible')}</h6>
                      <div className='content_card aluno_content'>
                        <div className='w-100'>
                          <div className=''>
                            <p className='card_details_label'>{t('FinancialResponsibleName')}: </p>
                            <p className='card_details_text'>{student.athNomeRespFin}</p>
                          </div>
                          <div className=''>
                            <p className='card_details_label'>{t('FinancialResponsibleEmail')}: </p>
                            <p className='card_details_text'>{formatCNPJCPF(String(student.athEmailRespFin))}</p>
                          </div>
                          <div className=''>
                            <p className='card_details_label'>{t('CellPhone')}: </p>
                            <p className='card_details_text'>{formatTelefone(String(student.athPhoneRespFin))}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-12'>
                    {' '}
                    <div className='card p-2 flex-column' style={{ minHeight: 220 }}>
                      <div className='card_header'>
                        <h6>{t('Classes_other')}</h6>
                      </div>
                      <div className='content_card aluno_content'>
                        <div className='card_details'>
                          <ul>
                            {student.turmas !== undefined && student.turmas.length > 0 ? (
                              student.turmas.map((turma) => (
                                <li key={`tm_${turma.id}`}>
                                  <Link
                                    className='c-list-item'
                                    to={{
                                      pathname: 'detail-class',
                                      state: {
                                        id: turma.id,
                                        school_id: student.school_id,
                                        name: turma.name,
                                      },
                                    }}
                                  >
                                    {turma.name}
                                  </Link>
                                </li>
                              ))
                            ) : (
                              <p className='card_details_text'>{t('StudentDontHaveClassesRegistered')}</p>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    {' '}
                    <div className='card p-2'>
                      <div className='card_header p-4'>
                        <h6>{t('Contracts')}</h6>
                      </div>
                      <TableResumeList mxh={350} rows={student.contracts} columns={columnsContract} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 col-sm-12'>
                    <div className='card'>
                      <div className='card_header pt-4 pl-4'>
                        <h6>{t('Financial')}</h6>
                        <div className='mt-2'>
                          <input
                            type='radio'
                            value='TUDO'
                            id='tudo'
                            name='financialFilter'
                            checked={financialFilter === 'TUDO'}
                            onChange={(e) => setFinancialFilter(e.target.value)}
                          />
                          <label htmlFor='tudo' style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>
                            {t('Everything')}
                          </label>

                          <input
                            type='radio'
                            value='VENCIDA'
                            id='vencida'
                            name='financialFilter'
                            checked={financialFilter === 'VENCIDA'}
                            onChange={(e) => setFinancialFilter(e.target.value)}
                          />
                          <label htmlFor='vencida' style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>
                            {t('Overdue', { context: 'female', count: 2 })}
                          </label>

                          <input
                            type='radio'
                            value='A VENCER'
                            id='a_vencer'
                            name='financialFilter'
                            checked={financialFilter === 'A VENCER'}
                            onChange={(e) => setFinancialFilter(e.target.value)}
                          />
                          <label htmlFor='a_vencer' style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>
                            {t('ToExpire')}
                          </label>

                          <input
                            type='radio'
                            value='PAGA'
                            id='paga'
                            name='financialFilter'
                            checked={financialFilter === 'PAGA'}
                            onChange={(e) => setFinancialFilter(e.target.value)}
                          />
                          <label htmlFor='paga' style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>
                            {t('Paid', { context: 'female', count: 2 })}
                          </label>
                        </div>
                      </div>

                      <TableResumeList mxh={350} rows={student.finacials.filter(filteredFinaces)} columns={columns} />
                      {/*    <div style={{ overflow: 'scroll', width: '100%' }}>
                            <FinancialTable
                              type='INCOME'
                              isLoading={false}
                              onEdit={(finance) =>
                                history.push('/financial-school', {
                                  student: student,
                                  financials: finance,
                                })
                              }
                              finances={student.finacials.filter(filteredFinaces)}
                            />
                          </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

const DetailsWithContract = () => {
  return (
    <ContractFaturarContext>
      <Details />
    </ContractFaturarContext>
  );
};

const RecivedRender = ({ value, row }) => <span style={{ color: rowColor(row) }}>{value}</span>;

const BtnEdit = ({ fcolor = 'var(--main-color)', w = 16, h = 16, onClick }) => {
  return (
    <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer' }} role='button'>
      <svg width={w} height={h} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10L16 6V10H14V7H9V2H2V18H8V20H2ZM14.3 12.525L15.375 13.6L11.5 17.45V18.5H12.55L16.425 14.65L17.475 15.7L13.175 20H10V16.825L14.3 12.525ZM17.475 15.7L14.3 12.525L15.75 11.075C15.9333 10.8917 16.1667 10.8 16.45 10.8C16.7333 10.8 16.9667 10.8917 17.15 11.075L18.925 12.85C19.1083 13.0333 19.2 13.2667 19.2 13.55C19.2 13.8333 19.1083 14.0667 18.925 14.25L17.475 15.7Z'
          fill={fcolor}
        />
      </svg>
    </span>
  );
};

const BtnPdf = ({ disabled = false, fcolor = '#B8872B', w = 16, h = 16, onClick }) => {
  const { t } = useTranslation();
  const color = disabled ? '#D9D9D9' : fcolor;
  return (
    <span
      onClick={onClick}
      className='text-danger'
      style={{ cursor: disabled ? 'auto' : 'pointer' }}
      role='button'
      title={t('StudentContractsPage.ActionsTableList.printerButton')}
    >
      <svg width={w} height={h} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7 10.5H8V8.5H9C9.28333 8.5 9.52083 8.40417 9.7125 8.2125C9.90417 8.02083 10 7.78333 10 7.5V6.5C10 6.21667 9.90417 5.97917 9.7125 5.7875C9.52083 5.59583 9.28333 5.5 9 5.5H7V10.5ZM8 7.5V6.5H9V7.5H8ZM11 10.5H13C13.2833 10.5 13.5208 10.4042 13.7125 10.2125C13.9042 10.0208 14 9.78333 14 9.5V6.5C14 6.21667 13.9042 5.97917 13.7125 5.7875C13.5208 5.59583 13.2833 5.5 13 5.5H11V10.5ZM12 9.5V6.5H13V9.5H12ZM15 10.5H16V8.5H17V7.5H16V6.5H17V5.5H15V10.5ZM6 16C5.45 16 4.97917 15.8042 4.5875 15.4125C4.19583 15.0208 4 14.55 4 14V2C4 1.45 4.19583 0.979167 4.5875 0.5875C4.97917 0.195833 5.45 0 6 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H6ZM6 14H18V2H6V14ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V4H2V18H16V20H2Z'
          fill={color}
        />
      </svg>
    </span>
  );
};
const BtnMoney = ({ disabled = false, fcolor = '#B8872B', w = 18, h = 18, onClick }) => {
  const color = disabled ? '#D9D9D9' : fcolor;
  return (
    <span
      onClick={(e) => {
        if (!disabled) onClick(e);
      }}
      className='text-danger'
      style={{ cursor: disabled ? 'auto' : 'pointer' }}
      role='button'
      title={disabled ? 'Contrato já Faturado' : 'Faturar Contrato'}
    >
      <svg
        width={w}
        height={h}
        viewBox='0 0 20 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        focusable='false'
        aria-hidden='true'
        data-testid='AttachMoneyIcon'
        aria-label='fontSize medium'
      >
        <path
          fill={color}
          d='M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z'
        ></path>
      </svg>
    </span>
  );
};

const StatusRender = ({ value }) => <span style={{ color: value === 'Ativo' ? 'green' : 'tomato' }}>{value}</span>;

export default DetailsWithContract;
