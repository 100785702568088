import React, { useEffect, useState, useRef } from 'react';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Utils, { handleOnChange } from '../../utils/utils';
import Api from '../../utils/api';
import { useTranslation } from 'react-i18next';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import api from '../../services/api';
import { Pagination } from '@material-ui/lab';
import Header from '../../components/school/Header';
import StockAdjustmentTable from '../../components/tables/StockAdjustmentTable';

const StockAdjustment = () => {
  const { t, i18n } = useTranslation();
  const baseURL = Api();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // libraries
  const { scrollTop, currencyFormatter, currencyFormatterText } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  const modalScroll = useRef(null);



  const [school_id, setSchoolID] = useState('');
  const [user_id, setUserID] = useState('');

  const [loading, setLoading] = useState(false);
  const [stockAdjustment, setStockAdjustment] = useState([]);
  const [stockAdjustmentReason, setStockAdjustmentReason] = useState([]);


  // filtros
  const [filterReason, setFilterReason] = useState('');
  const [filterProduct, setFilterProduct] = useState('');
  const [filterDueDateIni, setFilterDueDateIni] = useState(new Date().toISOString().substr(0, 8) + '01');
  const [filterDueDateEnd, setFilterDueDateEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10)
  );


  useEffect(() => {
    async function getSession() {
      const usuSession = JSON.parse(await localStorage.getItem('user'));
      const schoolSession = JSON.parse(await localStorage.getItem('school'));
      setSchoolID(schoolSession.id);
      setUserID(usuSession.id);
      getStockAdjustment({ schoolID: schoolSession.id })
    }

    getSession();
  }, []);

  const getStockAdjustment = async ({ schoolID, page }) => {
    setLoading(true);
    const response = await api.get(baseURL + 'get-stock-adjustment', {
      params: {
        page: page,
        school_id: schoolID,
        filterProduct,
        filterReason,
        filterDueDateIni,
        filterDueDateEnd
      }
    });

    setCurrentPage(response.data.current_page);
    setTotalPages(response.data.last_page);
    setStockAdjustment(response.data.stockAdjustment.data);
    setStockAdjustmentReason(response.data.stockAdjustmentReason);

    setLoading(false);
  }

  const search = (e) => {
    e.preventDefault();
    getStockAdjustment({ schoolID: school_id });
  };

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
    getStockAdjustment({ schoolID: school_id, page: page })
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='card'>
                <div className='card_header mx-auto text-center'>
                  <h2 className='card_title'>{t('ProductsManagerPage.FilterProductsCard.Title')}</h2>
                  <p clas='card_subtitle'>{ } {t('ProductsManagerPage.FilterProductsCard.Subtitle')}</p>
                </div>

                <div className='d-flex flex-column w-100' style={{ gap: '5px' }}>
                  <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center" style={{ gap: '10px' }}>
                    <input
                      placeholder="Buscar por produto"
                      type="text"
                      value={filterProduct}
                      className="form-control form-control-lg"
                      onChange={(e) => setFilterProduct(e.target.value)}
                    />
                    <ButtonRounded isBig onClick={search}>
                      {t('Search')}
                    </ButtonRounded>
                  </div>
                  <div className='d-flex flex-column flex-sm-row form_card' style={{ gap: '10px' }}>
                    <div className='d-flex w-100' style={{ gap: '5px' }}>
                      <div className='form_group'>
                        <label className='card_details_label'>
                          {t('StartDate')}
                        </label>
                        <input
                          type='date'
                          className='form_control'
                          value={filterDueDateIni}
                          onChange={(e) => setFilterDueDateIni(e.target.value)}
                        />
                      </div>
                      <div className='form_group'>
                        <label className='card_details_label'>{t('EndDate')}</label>
                        <input
                          type='date'
                          className='form_control'
                          value={filterDueDateEnd}
                          onChange={(e) => setFilterDueDateEnd(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label'>
                        Motivo
                      </label>
                      <select
                        required
                        value={filterReason}
                        className='form_control select'
                        onChange={(e) => setFilterReason(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        {stockAdjustmentReason.map((item) => (
                          <option value={item.id}>{item.title}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='list_card'>
                  {loading && (
                    <p className='loading-card'>
                      <Loading type='1' />
                    </p>
                  )}
                  {!loading &&
                    <>
                      <StockAdjustmentTable
                        stockAdjustment={stockAdjustment}
                      />
                      <div className='w-100 p-2 d-flex justify-content-center'>
                        {stockAdjustment.length > 0 && (
                          <Pagination
                            page={currentPage}
                            onChange={handleChangePage}
                            count={totalPages}
                            variant='outlined'
                            shape='rounded'
                          />
                        )}
                      </div>
                    </>
                  }
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default StockAdjustment;
