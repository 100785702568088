import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Footer from '../../components/login/Footer'
import Header from '../../components/login/Header'
import Loading from '../../components/Loading'
import Fade from '@material-ui/core/Fade'
import Message from '../../components/Message'
import Api from '../../utils/api'
import { url } from '../../config/local.default'
import axios from 'axios'

const RecoveryPassword = () => {
   const baseURL = Api()
   const [email, setEmail] = useState("")
   const [send, setSend] = useState(false)
   const [auth, setAuth] = useState(false)
   const [invalid, setInvalid] = useState(false)
   const [message, setMessage] = useState("")
   const { MessageDanger, MessageSuccess } = Message()

   const handleSubmit = async (evt) => {
      evt.preventDefault()
      setSend(true)
      setAuth(false)
      setInvalid(false)

      const res = await axios.post(baseURL + 'recovery-password', {email: email, urlEditar: url + '/edit-password/', urlOriginal: url})

      setSend(false)
      if ( !res.data.error ){
         setAuth(true)
      } else {
         setAuth(false)
         setInvalid(true)
      }

      setMessage(res.data.message)
   }

   return (
      <article className="container-fluid_new bg_new bg_login_new" style={{flexDirection: "column"}}>
         <div className="container_login_new">
            <div className="col-12 col-md-6">
               <div className="content_login_new">
                  <div className="swiper-container_new">
                     <div className="swiper-wrapper">
                        <Fade in={true} timeout={2000}>
                           <div className="swiper-slide_new" style={{alignText: 'center !important'}}>
                              <h1 className="title_login_verde_new" style={{fontFamily: 'TitlingGothicFB'}}>Recupere</h1>
                              <h1 className="title_login_new" style={{fontFamily: 'TitlingGothicFB'}}>sua senha</h1>
                           </div>
                        </Fade>
                     </div>
                  </div>
                  <span className="linha_vertical_login_new"></span>
               </div>
            </div>
            <div className="col-12 col-md-6">
               <div className="content_login_new">
                  <form onSubmit={handleSubmit} className="call_to_action_new" autoComplete="new-password">
                     {
                        invalid && <MessageDanger title="Atenção! " description={message} />
                     }

                     {
                        auth && <MessageSuccess title="Autenticado! " description={message} />
                     }
                     <div className="content_form_new">
                        <label className="lbl_login_new" style={{fontFamily: 'TitlingGothicFB Comp'}}>Infome seu email</label>
                        <input autoComplete="new-password" className="input_login_new" required type="email" name="email" onChange={e => setEmail(e.target.value)}/>

                     </div>
                     <div className="content_form_new">
                        <span className="text_login_new">Após redefinir a senha, clique aqui e faça seu login.</span>
                     </div>
                     <div className="content_form_new" style={{marginTop: '20px', width: '100%'}}>
                        <button className="btn_login_entrar_new" style={{width: '100%'}}>
                        {
                           send && <Loading type="1" />
                        }
                        {
                           !send && <React.Fragment>RECUPERAR</React.Fragment>
                        }
                        </button>
                     </div>
                  </form>
               </div>
            </div>
         </div>

         <Footer />
      </article>
   )
}

export default RecoveryPassword
