import React, { useEffect, useState } from 'react';
import Header from '../../components/admin/Header';
import Footer from '../../components/admin/Footer';
import Loading from '../../components/Loading';
import Utils, { getUserPhoto, handleOnChange } from '../../utils/utils';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getAllUsersOnSystem, adminPasswordResetter, updateUserStatus } from '../../services/AdminService';
import { useTranslation } from 'react-i18next';
import { getAllClubs } from '../../services/CompanieService';
import { getAllSchools } from '../../services/SchoolService';

const Users = () => {
  const { t } = useTranslation();
  const { setThemeSchoolDefault, formatCNPJCPF, clearCNPJCPF, dateFormatText } = Utils();

  const [isLoading, setIsLoading] = useState(false);
  const [sweetAlert, setSweetAlert] = useState(null);

  const [formFilters, setFormFilters] = useState({
    club: '',
    school: '',
    user: '',
  });

  const [users, setUsers] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [schools, setSchools] = useState([]);
  const [athletePhotoDir, setAthletePhotoDir] = useState('');
  const [employeePhotoDir, setEmployeePhotoDir] = useState('');

  useEffect(() => {
    setThemeSchoolDefault();
    getAllClubsList();
  }, []);

  const fetchUsers = async (filters = null) => {
    setIsLoading(true);
    const { status, payload, message } = await getAllUsersOnSystem(filters);

    if (status === 'SUCCESS') {
      setUsers(payload.users);
      setAthletePhotoDir(payload.athletePhotoDir);
      setEmployeePhotoDir(payload.employeePhotoDir);
    } else {
      console.error(message);
    }

    setIsLoading(false);
  };

  const getAllClubsList = async () => {
    setIsLoading(true);
    const { clubs } = await getAllClubs();

    setClubs(clubs);
    search({ preventDefault: () => {} });
    setIsLoading(false);
  };

  const getAllSchoolsList = async (params) => {
    setUsers([]);
    const { schools } = await getAllSchools(params);
    if (schools.length > 0) {
      setSchools(schools);
    } else {
      setSchools([]);
    }
  };

  const onChangeFormFilters = handleOnChange(setFormFilters);

  const search = async (event) => {
    event.preventDefault();

    const filters = {
      club: clearCNPJCPF(formFilters.club || ''),
      school: clearCNPJCPF(formFilters.school || ''),
      user: clearCNPJCPF(formFilters.user || ''),
    };

    await fetchUsers(filters);
  };

  const resetPassword = async (user) => {
    setSweetAlert(null);
    setIsLoading(true);
    const { status } = await adminPasswordResetter(user.idUser);
    setIsLoading(false);

    if (status === 'SUCCESS') {
      showSuccess();
    } else {
      showError();
    }
  };

  const changeUserStatus = async (user) => {
    setSweetAlert(null);
    setIsLoading(true);
    const { status } = await updateUserStatus(user.idUser);
    setIsLoading(false);
    search({ preventDefault: () => {} });
    if (status === 'SUCCESS') {
      showStatusSuccess();
    } else {
      showStatusError();
    }
  };

  const showResetConfirmation = (user) => {
    setSweetAlert(
      <SweetAlert
        warning
        title='Tem certeza?'
        onConfirm={() => resetPassword(user)}
        onCancel={() => setSweetAlert(null)}
        showCloseButton
        showCancel
        cancelBtnBsStyle='primary'
        cancelBtnText='Não'
        showConfirm
        confirm
        confirmBtnBsStyle='danger'
        confirmBtnText='Sim'
      >
        Tem certeza que deseja redefinir a senha do usuário{' '}
        <b>
          {user.name} - CPF {formatCNPJCPF(user.usuCPF)}
        </b>{' '}
        para <b className='text-danger'>123456</b>?
      </SweetAlert>
    );
  };

  const showChangeStatusConfirmation = (user) => {
    setSweetAlert(
      <SweetAlert
        warning
        title='Tem certeza?'
        onConfirm={() => changeUserStatus(user)}
        onCancel={() => setSweetAlert(null)}
        showCloseButton
        showCancel
        cancelBtnBsStyle='primary'
        cancelBtnText='Não'
        showConfirm
        confirm
        confirmBtnBsStyle='danger'
        confirmBtnText='Sim'
      >
        Tem certeza que deseja alterar o status do usuário <b>{user.name}</b>{' '}
      </SweetAlert>
    );
  };

  const showSuccess = () => {
    setSweetAlert(
      <SweetAlert
        success
        title='A senha foi redefinida.'
        onConfirm={() => setSweetAlert(null)}
        showCloseButton
        confirmBtnText='Ok'
      />
    );
  };
  const showStatusSuccess = () => {
    setSweetAlert(
      <SweetAlert
        success
        title='O status foi alterado com sucesso.'
        onConfirm={() => setSweetAlert(null)}
        showCloseButton
        confirmBtnText='Ok'
      />
    );
  };

  const showError = () => {
    setSweetAlert(
      <SweetAlert
        error
        title='Erro ao redefinir a senha. Por favor, tente novamente.'
        onConfirm={() => setSweetAlert(null)}
        showCloseButton
        confirmBtnText='Ok'
      />
    );
  };

  const showStatusError = () => {
    setSweetAlert(
      <SweetAlert
        error
        title='Erro ao alterar o status. Por favor, tente novamente.'
        onConfirm={() => setSweetAlert(null)}
        showCloseButton
        confirmBtnText='Ok'
      />
    );
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <Header />
            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Procurar</h2>
                    <p className='card_subtitle'>Filtrar os usuários cadastrados.</p>
                  </div>
                  <form className='form_card' onSubmit={search}>
                    <div className='form_group'>
                      <label htmlFor='tel' className='card_details_label'>
                        Clube
                      </label>
                      <select
                        className='form_control'
                        name='club'
                        value={formFilters.club}
                        onChange={(e) => {
                          setFormFilters({
                            club: '',
                            school: '',
                            user: '',
                          });
                          onChangeFormFilters(e);
                          const club = clubs.find((club) => club.comCNPJ === e.target.value);
                          if (club) {
                            getAllSchoolsList({ companie_id: club.id });
                          }
                        }}
                      >
                        <option value=''>Selecione</option>
                        {clubs.map((club, i) => {
                          return (
                            <option key={'club' + i} value={club.comCNPJ}>
                              {club.comCompanie}
                            </option>
                          );
                        })}
                      </select>
                      <div />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='tel' className='card_details_label'>
                        Escola
                      </label>
                      <select
                        className='form_control'
                        name='school'
                        value={formFilters.school}
                        onChange={(e) => {
                          setFormFilters({
                            ...formFilters,
                            user: '',
                          });
                          onChangeFormFilters(e);
                        }}
                      >
                        <option value=''>Selecione</option>
                        {schools.map((school, i) => {
                          return (
                            <option key={'school' + i} value={school.schCNPJ}>
                              {school.schName}
                            </option>
                          );
                        })}
                      </select>
                      <div />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='tel' className='card_details_label'>
                        Usuário
                      </label>
                      <input
                        className='form_control'
                        name='user'
                        value={formFilters.user}
                        onChange={onChangeFormFilters}
                        placeholder='Nome, Email ou CPF'
                      />
                      <div />
                    </div>
                    <button className='btn_card mx-auto'>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      Buscar
                    </button>
                  </form>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Usuários</h2>
                    <p className='card_subtitle'>
                      Veja abaixo a lista de usuários cadastrados no sistema.
                      <br />
                      <span className='text-danger'>(Apenas os primeiros 50 resultados são exibidos)</span>
                    </p>
                  </div>

                  <div className='list_card'>
                    {isLoading && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!isLoading &&
                      users.map((user, i) => {
                        return user.usuStudent == 'S' ? (
                          <div key={'user_cards' + i} className='card_list'>
                            <div className='img_list_user'>
                              <img src={getUserPhoto(athletePhotoDir + '/', user.usuFotoPrincipal)} alt='' />
                            </div>
                            <div className='content_card'>
                              <div className='card_details'>
                                <p className='card_details_title' title={user.name}>
                                  {user.name.length > 20 ? user.name.substr(0, 20) + '...' : user.name}
                                </p>
                              </div>
                              <div
                                className='card_details'
                                style={{ justifyContent: 'left', alignItems: 'flex-start', marginBottom: 3 }}
                              >
                                <p className='card_details_label'>{t('StudentCod')}: </p>
                                <p className='card_details_text' title={user.athCodigoAluno}>
                                  {user.athCodigoAluno !== '' &&
                                  user.athCodigoAluno !== null &&
                                  user.athCodigoAluno.length > 14
                                    ? user.athCodigoAluno.substr(0, 14) + '...'
                                    : user.athCodigoAluno}
                                </p>
                              </div>
                              <div
                                className='card_details'
                                style={{ justifyContent: 'left', alignItems: 'flex-start', marginBottom: 3 }}
                              >
                                <p className='card_details_label'>{t('Status')}: </p>
                                <p className='card_details_text'>{t('StatusType.' + user.usuStatus)}</p>
                              </div>
                              <div
                                className='card_details'
                                style={{ justifyContent: 'left', alignItems: 'flex-start', marginBottom: 3 }}
                              >
                                <p className='card_details_label'>{t('Birth')}: </p>
                                <p className='card_details_text'>{dateFormatText(user.usuBirth)}</p>
                              </div>
                              <div
                                className='card_details'
                                style={{
                                  justifyContent: 'left',
                                  alignItems: 'flex-start',
                                }}
                              >
                                <p className='card_details_label'>{t('Position')}: </p>
                                <p className='card_details_text'>
                                  {user.posPosition ? t('Sports.Position.' + user.posPosition) : `(${t('Uninformed')})`}
                                </p>
                              </div>
                              <div 
                                className='card_details'
                                style={{
                                  justifyContent: 'left',
                                  alignItems: 'flex-start',
                                }}
                              >
                                <p className='card_details_label'>{t('Club')}: </p>
                                <p className='card_details_text' style={{ fontSize: '0.70rem'}}>
                                  {user.comCompanie}
                                </p>
                              </div>
                              <div 
                                className='card_details'
                                style={{
                                  justifyContent: 'left',
                                  alignItems: 'flex-start',
                                }}
                              >
                                <p className='card_details_label'>{t('School')}: </p>
                                <p className='card_details_text' style={{ fontSize: '0.70rem'}}>
                                  {user.schName}
                                </p>
                              </div>
                              <div 
                                className='card_details'
                                style={{
                                  justifyContent: 'left',
                                  alignItems: 'flex-start',
                                }}
                              >
                                <p className='card_details_label'>{t('Profile')}: </p>
                                <p className='card_details_text'>
                                  {t([`EmployeeModel.Occupations.${user.rolRole}`, ''])}
                                </p>
                              </div>
                            </div>
                            <span
                              style={{
                                backgroundColor: 'orange',
                                fontWeight: 500,
                                border: '1px solid orange',
                                marginBottom: 10,
                              }}
                              onClick={() => showResetConfirmation(user)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('ResetPassword')}
                            </span>
                            <span
                              style={{
                                backgroundColor: user.usuStatus === 'I' ? 'green' : 'red',
                                fontWeight: 500,
                                border: '1px solid ' + (user.usuStatus === 'I' ? 'green' : 'red'),
                                marginBottom: 10,
                              }}
                              onClick={() => showChangeStatusConfirmation(user)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {user.usuStatus === 'I' ? t('Active') : t('Inactive')}
                            </span>
                          </div>
                        ) : (
                          <div key={'user_cards' + i} className='card_list'>
                            <div className='img_list_user'>
                              <img src={getUserPhoto(employeePhotoDir + '/', user.usuFotoPrincipal)} alt='' />
                            </div>
                            <div className='content_card'>
                              <div className='card_details'>
                                <p className='card_details_title' title={user.name}>
                                  {user.name.length > 20 ? user.name.substr(0, 20) + '...' : user.name}
                                </p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>{t('Occupation')}: </p>
                                <p className='card_details_text'>
                                  {t([`EmployeeModel.Occupations.${user.rolRole}`, ''])}
                                </p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>{t('Status')}: </p>
                                <p className='card_details_text'>{t(`StatusType.${user.usuStatus}`)}</p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>{t('Club')}: </p>
                                <p className='card_details_text' style={{ fontSize: '0.70rem'}}>
                                  {user.comCompanie}
                                </p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>{t('School')}: </p>
                                <p className='card_details_text' style={{ fontSize: '0.70rem'}}>
                                  {user.schName}
                                </p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>{t('Profile')}: </p>
                                <p className='card_details_text'>
                                  {t([`EmployeeModel.Occupations.${user.rolRole}`, ''])}
                                </p>
                              </div>
                            </div>
                            <span
                              style={{
                                backgroundColor: 'orange',
                                fontWeight: 500,
                                border: '1px solid orange',
                                marginBottom: 10,
                              }}
                              onClick={() => showResetConfirmation(user)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('ResetPassword')}
                            </span>
                            <span
                              style={{
                                backgroundColor: user.usuStatus === 'I' ? 'green' : 'red',
                                fontWeight: 500,
                                border: '1px solid ' + (user.usuStatus === 'I' ? 'green' : 'red'),
                                marginBottom: 10,
                                color: '#fff',
                                ':hover': {
                                  color: '#fff',
                                },
                              }}
                              onClick={() => showChangeStatusConfirmation(user)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {user.usuStatus === 'I' ? t('Active') : t('Inactive')}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
      {sweetAlert}
    </main>
  );
};

export default Users;
