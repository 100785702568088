import { defaultApiObject } from '../utils/utils-api';
import api from './api';

export const getOrdersBySchool = async ({ schoolId }) => {
  try {
    const { data } = await api.get(`get-orders-by-school/${schoolId}`);

    return data;
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};
export const getOrdersByClub = async ({ id }) => {
  try {
    const { data } = await api.get(`club-ecommerce/get-orders-sale/${id}`);

    return data;
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};
/* REcupera as ordens de comrpa da escola com club */
export const getOrdersSellBySchool = async ({ id }) => {
  try {
    const { data } = await api.get(`get-school-order-club/${id}`);

    return data;
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const updateStatusOrder = async ({ orderId }) => {
  try {
    const { data } = await api.put(`update-status-order/${orderId}`);

    return data;
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const getOrdersByStudent = async ({ userId }) => {
  try {
    const { data } = await api.get(`get-orders-by-student/${userId}`);

    return data;
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};
