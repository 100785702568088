import React from 'react';
import { Table } from 'react-bootstrap';
import { BsClipboardCheck, BsPencil, BsTrash } from 'react-icons/bs';
import Utils from '../../utils/utils';
import moment from 'moment';
import TableList from './TableList';
import { useTranslation } from 'react-i18next';

const defaultColRowStyle = {
  alignItems: 'center',
  width: '200px',
  height: '100%',
  border: 'none',
  justifyContent: 'left',
};

const defaultColHeaderStyle = {
  border: 'none',
  fontSize: '14px',
  color: '#3b3b3b',
  fontWeight: 600,
  justifyContent: 'left',
};

const StatusNames = {
  pending_payment: 'Aguardando pagamento',
  spent: 'Gasto',
};
const { currencyFormatter } = Utils();
const CreditTransactionsTable = ({ transactions }) => {
  const { t } = useTranslation();
  const columns = [
    {
      id: 'id',
      label: 'ID',
      align: 'center',
    },
    {
      id: 'status',
      label: t('SignatureCreditPage.Status'),
      align: 'center',
      format: (status) => StatusNames[status] ?? '-',
    },

    {
      id: 'payment_link',
      label: t('SignatureCreditPage.Link'),
      align: 'center',
      format: (payment_link) => (
        <a href={payment_link} rel='noopener noreferrer' target='_blank'>
          Link
        </a>
      ),
    },
    {
      id: 'created_at',
      label: t('SignatureCreditPage.Date'),
      align: 'center',
      format: (date) => moment(date).format('DD/MM/YYYY HH:mm'),
    },
    {
      id: 'totalCredits',
      label: t('SignatureCreditPage.Quantity'),
      align: 'center',
    },
    {
      id: 'value',
      label: t('SignatureCreditPage.Value'),
      align: 'center',
      format: (value) => currencyFormatter(value * 100),
    },
  ];
  return <TableList columns={columns} rows={transactions} mxh={500}/>;
};

export default CreditTransactionsTable;
