import React, { forwardRef, useEffect, useState } from 'react';
import { ModalWithMethods } from '../../components/modal/Modal';
import { useTranslation } from 'react-i18next';
import PasswordInput from '../../components/inputs/PasswordInput';
import { getAPIToken, regenerateAPIToken } from '../../services/CompanieService';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import { copyToClipboard } from '../../utils/utils';
import { baseURL } from '../../utils/api';

const ModalApiSettings = (props, ref) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible && token === null) fetchToken();
  }, [visible]);

  const fetchToken = async () => {
    ref.current.clearMessage();
    setIsLoading(true);
    const { status, message, token } = await getAPIToken();
    setIsLoading(false);

    if (status === 'SUCCESS') {
      setToken(token);
    } else {
      ref.current.showErrorMessage(message);
    }
  };

  const handleCopyToken = async () => {
    ref.current.clearMessage();
    const result = copyToClipboard(token);
    if (result) ref.current.showSuccessMessage('Token copiado.');
    else ref.current.showErrorMessage('Erro ao copiar o token.');
  };

  const handleRegenerateToken = async () => {
    ref.current.clearMessage();
    setIsLoading(true);
    const { status, message, token } = await regenerateAPIToken();
    setIsLoading(false);

    if (status === 'SUCCESS') {
      setToken(token);
      ref.current.showSuccessMessage(message);
    } else {
      ref.current.showErrorMessage(message);
    }
  };

  return (
    <ModalWithMethods {...props} ref={ref} title={t('ModalApiSettings.Title')} size={'sm'}>
      <div className='form_modal'>
        <PasswordInput
          placeholder='********************************************************'
          isLoading={isLoading}
          value={token}
          visibleState={{ visible: visible && token !== null, setVisible }}
        />
        <div className='d-flex w-100 mb-3' style={{ gap: '10px' }}>
          <ButtonRounded className='flex-grow-1' variant='default' onClick={handleCopyToken} isDisabled={!visible}>
            Copiar
          </ButtonRounded>
          <ButtonRounded className='flex-grow-1' variant='yellow' onClick={handleRegenerateToken}>
            Regenerar
          </ButtonRounded>
        </div>
      </div>
      <span className='alert alert-warning'>
        O token acima fornece acesso aos recursos da API da Fensor. Somente usuários com cargo &quot;Gestor&quot; podem
        visualizar esse token ou gerar um novo.
        <br />
        <a href={baseURL + 'documentation'} target='_blank' rel='noopener noreferrer'>
          Link para a documentação da API
        </a>
      </span>
    </ModalWithMethods>
  );
};

export default forwardRef(ModalApiSettings);
