import { defaultApiObject, handleFail } from '../utils/utils-api';
import api from './api';

export const getAllLessons = async () => {
  try {
    const { data } = await api.get('get-all-lessons');
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const createLesson = async (lesson) => {
  try {
    const { data } = await api.post('create-lesson', lesson);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateLesson = async (lesson) => {
  try {
    const { data } = await api.post('update-lesson', lesson);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateStatus = async (lesson) => {
  try {
    const { data } = await api.patch('update-lesson-status', lesson);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getAllActiveLessons = async () => {
  try {
    const { data } = await api.get('get-all-active-lessons-school');
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getAllLessonsSchool = async () => {
  try {
    const { data } = await api.get('get-all-lessons-school');
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const createLessonSchool = async (lesson) => {
  try {
    const { data } = await api.post('create-lesson-school', lesson, { headers: { 'Content-Type': 'multipart/form-data' } });
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateLessonSchool = async (lesson) => {
  try {
    const { data } = await api.post('update-lesson-school', lesson, { headers: { 'Content-Type': 'multipart/form-data' } });
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateSchoolStatus = async (lesson) => {
  try {
    const { data } = await api.patch('update-school-lesson-status', lesson);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getAllLessonsPlanning = async ({ date, onlyActive = 1, nested = 1 } = {}) => {
  try {
    const { data } = await api.get(`get-all-lesson-planning?onlyActive=${onlyActive}&nested=${nested}`, { params: { date } });
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const createLessonPlanning = async (lessonsPlanning) => {
  try {
    const { data } = await api.post('create-lesson-planning', lessonsPlanning);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateLessonPlanning = async (lessonsPlanning) => {
  try {
    const { data } = await api.post('update-lesson-planning', lessonsPlanning);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateSchoolLessonPlanningStatus = async (lesson) => {
  try {
    const { data } = await api.patch('update-school-planning-status', lesson);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getAllStudentLessons = async ({ date }) => {
  try {
    const { data } = await api.get('get-all-student-lessons', { params: { date } });
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const markLessonAsViewed = async ({ lesson, lessonsPlanning }) => {
  try {
    const { data } = await api.post('mark-lesson-as-viewed', { lesson, lessonsPlanning });
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getAllLessonsClub = async (onlyActive = 1, nested = 1) => {
  try {
    const { data } = await api.get(`get-all-lessons-club?onlyActive=${onlyActive}&nested=${nested}`);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const createLessonClub = async (lesson) => {
  try {
    const { data } = await api.post('create-lesson-club', lesson);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateLessonClub = async (lesson) => {
  try {
    const { data } = await api.post('update-lesson-club', lesson);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updatePlanningStatusClub = async (planning) => {
  try {
    const { data } = await api.patch('update-planning-status', planning);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getAllActiveLessonsClub = async () => {
  try {
    const { data } = await api.get('get-all-active-lessons-club');
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};
