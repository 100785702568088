import React, { useEffect, useState } from 'react';
import { uploadFolder } from '../../utils/api';

const uploadURL = uploadFolder();

const Logo = (props) => {
  const [comLogo, setComLogo] = useState(props.comLogo);

  useEffect(() => {
    if (props.comLogo === undefined) {
      const { comLogo } = JSON.parse(localStorage.getItem('company'));
      setComLogo(comLogo);
    }
  }, []);

  return <img className={props.isLoginPage ? 'logo-login' : undefined} src={`${uploadURL}upload/companie/logo/${props.comLogo || comLogo}`} alt='' />;
};

export default Logo;
