import React, { useEffect, useState, useRef } from 'react';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';

import Utils from '../../utils/utils';
import Api from '../../utils/api';
import { useTranslation } from 'react-i18next';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import PurchaseOrderTable from '../../components/tables/PurchaseOrderTable';
import Modal from '../../components/modal/Modal';
import { validateCNPJ, validateCPF } from '../../utils/validators';
import ModalFinanceStakeholder from '../../views/ModalFinanceStakeholder';
import api from '../../services/api';
import { Autocomplete, Pagination } from '@material-ui/lab';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import ProductsOrderPurchase from '../../components/tables/ProductsOrderPurchase';
import { APIError } from '../../services/ExceptionClass/APIError';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';

const status = 'Aberta';

const CustomTextField = (props) => {
  return <TextField {...props} size={'small'} variant='outlined' className='mt-1' />;
};

const PurchaseOrder = () => {
  const { t, i18n } = useTranslation();

  const baseURL = Api();

  const [display, setDisplay] = useState({});

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(0);
  // libraries
  const { scrollTop, currencyFormatter, currencyFormatterText } = Utils();

  const modalScroll = useRef(null);
  const [showAlert, setShowAlert] = useState(false);

  const [school_id, setSchoolID] = useState('');

  const [user_id, setUserID] = useState('');

  const [loading, setLoading] = useState(false);
  const [sendSave, setSendSave] = useState(false);
  const [message, setMessage] = useState();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState();
  const [purchaseOrder, setPurchaseOrder] = useState([]);
  const [modalCreateOrder, setModalCreateOrder] = useState();
  const [displayClientFornecedor, setDisplayClientFornecedor] = useState(false);

  //form ordem de compra
  const [modalStudentsClientsFornecedors, setModalStudentsClientsFornecedors] = useState([]);
  const [modalStudentClientFornecedor, setModalStudentClientFornecedor] = useState('');
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState([]);
  const [productsIncludes, setProductsIncludes] = useState([]);
  const [quanty, setQuanty] = useState('');
  const [sizes, setSizes] = useState([]);
  const [size, setSize] = useState('');
  const [valorUnit, setValorUnit] = useState(0);
  const [valueTotalOrder, setValueTotalOrder] = useState(0);

  // filtros
  const [filterNumberOrder, setFilterNumberOrder] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterSupplier, setFilterSupplier] = useState('all');
  const [filterDueDateIni, setFilterDueDateIni] = useState(new Date().toISOString().substr(0, 8) + '01');
  const [filterDueDateEnd, setFilterDueDateEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10)
  );

  const [updateStatus, setUpdateStatus] = useState();
  const [idOrderPurchase, setIdOrderPurchase] = useState();

  //file
  const [modalFile, setModalFile] = useState();
  const [errorUpload, setErrorUpload] = useState();
  const [successUpload, setSuccessUpload] = useState(false);
  const [file, setFile] = useState('');
  const [dirDocument, setDirDocument] = useState('');
  const [sendDoc, setSendingDoc] = useState(false);

  const [purchaseOrderDetail, setPurchaseOrderDetail] = useState([]);

  useEffect(() => {
    async function getSession() {
      const usuSession = JSON.parse(localStorage.getItem('user'));

      const schoolSession = JSON.parse(localStorage.getItem('school'));

      setSchoolID(schoolSession.id);
      setUserID(usuSession.id);
      getPurchaseOrders({ schoolID: schoolSession.id });
    }

    getSession();
    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const getPurchaseOrders = async ({ schoolID, page }) => {
    setLoading(true);
    const response = await api.get(baseURL + 'get-purchase-orders', {
      params: {
        page: page,
        school_id: schoolID,
        filterNumberOrder,
        filterStatus,
        filterSupplier,
        filterDueDateIni,
        filterDueDateEnd,
      },
    });

    setPurchaseOrder(response.data.purchaseOrders.data);

    setModalStudentsClientsFornecedors(response.data.fornecedores || []);

    setProducts(response.data.products);

    setTotalPages(response.data.purchaseOrders.last_page);

    setDirDocument(response.data.dirDocument);

    setLoading(false);
  };

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
    getPurchaseOrders({ schoolID: school_id, page: page });
  };

  const search = (e) => {
    e.preventDefault();
    getPurchaseOrders({ schoolID: school_id });
  };

  const showAlertUpdateStatus = (value, orderPurchaseId) => {
    setUpdateStatus(value);
    setIdOrderPurchase(orderPurchaseId);
    setShowAlert(true);
  };

  const handleStatusOrdem = async () => {
    const data = {
      status: updateStatus,
      school_id: school_id,
      purchaseOrderId: idOrderPurchase,
    };
    const response = await api.post(baseURL + 'update-status-purchase-order', data);
    if (response.data.error) {
      toast.info(response.data.error);
      setShowAlert(false);
      return;
    }
    setShowAlert(false);
    toast.success('Status alterado com sucesso.');

    getPurchaseOrders({ schoolID: school_id });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setSuccess(false);

    if (productsIncludes.length === 0) {
      setErrorMessage('É necessario incluir pelo menos um produto para essa ordem de compra.');
    } else if (purchaseOrderDetail && purchaseOrderDetail.status !== 'Aberta') {
      setErrorMessage('Você não pode editar essa ordem de compra.');
    } else {
      const data = {
        id: idOrderPurchase,
        school_id,
        client_fornecedor_id: modalStudentClientFornecedor.id,
        status: status,
        value_order: valueTotalOrder,
        value_unitary: valorUnit,
        productsIncludes,
      };

      try {
        setSendSave(true);
        await api.post(baseURL + 'save-purchase-orders', data);
        setSuccess(true);
        setProduct('');
        setSize('');
        setQuanty('');
        if (idOrderPurchase) {
          setMessage('SystemMessage.Success.PurchaseOrderUpdatedSuccess');
        } else {
          setModalStudentClientFornecedor('');
          setProductsIncludes([]);
          setMessage('SystemMessage.Success.PurchaseOrderCreatedSuccess');
        }
        // setSendSave(false);
      } catch (error) {
        if (error instanceof APIError) {
          setError(true);
          setMessage(error.messageKey || error.message);
        } else {
          setError(true);
          setMessage('SystemMessage.Fail.ErrorRetry');
        }
      } finally {
        setSendSave(false);
        scrollTop(modalScroll);
        closeModal();
      }
    }

    getPurchaseOrders({ schoolID: school_id });
  };

  const handleCancel = () => {
    // Lógica a ser executada quando o usuário clica em Cancelar
    setShowAlert(false); // Fecha o alerta após a ação de Cancelar
  };

  const closeModalClientFornecedor = () => {
    setDisplayClientFornecedor(false);
  };

  const closeModal = () => {
    setDisplay({});
  };

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeModal();
      closeModalClientFornecedor();
    }
  };

  const newClientFornecedor = () => {
    setDisplayClientFornecedor(true);
  };

  const addProduct = () => {
    clearMessage();
    if (product.length === 0) {
      setErrorMessage('Escolha um produto.');
    } else if (size === '') {
      setErrorMessage('Para incluir um produto é necessário colocar o tamanho.');
    } else if (quanty === '') {
      setErrorMessage('Para incluir um produto é necessário colocar a quantidade');
    } else {
      // Verifica se o produto já foi adicionado com o mesmo tamanho e quantidade
      const isProductIncluded = productsIncludes.some((p) => p.product_id === product.id && p.size_product === size);

      if (isProductIncluded) {
        setErrorMessage('Este produto já foi adicionado com o mesmo tamanho.');
      } else {
        const newProduct = {
          product_id: product.id,
          titileProduct: product.titulo,
          amount: quanty,
          unitary_value: valorUnit,
          value_total: valorUnit * quanty,
          size_product: size,
        };

        setProductsIncludes([...productsIncludes, newProduct]);
        // Realize a soma
        setValueTotalOrder((value) => Number(value) + Number(newProduct.value_total));
        resetStates();
      }
    }
  };

  const resetStates = () => {
    setProduct('');
    setSize('');
    setQuanty('');
    setValorUnit('');
  };

  const setErrorMessage = (message, extra) => {
    if (extra) setMessage({ text: message, extra });
    else setMessage(message);
    setError(true);
    scrollTop(modalScroll);
  };

  const clearMessage = () => {
    setError(false);
    setMessage('');
  };

  const handleSubmitClientFornecedor = async (formValues) => {
    try {
      if (formValues.cpf_cnpj.length === 14 && !validateCNPJ(formValues.cpf_cnpj, i18n.language)) {
        throw new Error('SystemMessage.Fail.InvalidCNPJ');
      } else if (formValues.cpf_cnpj.length === 11 && !validateCPF(formValues.cpf_cnpj, i18n.language)) {
        throw new Error('SystemMessage.Fail.InvalidCPF');
      } else if (formValues.streetName.length > 50) {
        throw new Error('SystemMessage.Fail.LongAdress');
      } else {
        let endpoint = '';
        let body = {
          school_id,
          name: formValues.name,
          cpf_cnpj: formValues.cpf_cnpj,
          phone: formValues.phone,
          email: formValues.email,
          type: formValues.type,

          zipCode: formValues.zipCode,
          streetName: formValues.streetName,
          streetNumber: formValues.streetNumber,
          complement: formValues.complement,
          neighborhood: formValues.neighborhood,
          city_id: formValues.cityId,
        };

        if (formValues.id === null) {
          endpoint = 'save-client-fornecedor-club';
          body = {
            ...body,
            user_id,
          };
        } else {
          endpoint = 'update-client-fornecedor-club';
          body = {
            ...body,
            id: formValues.id,
          };
        }

        const { data } = await api.post(endpoint, body);
        setModalStudentsClientsFornecedors(data.schoolsClientsFornecedors);

        return {
          success: true,
          message: data.messageKey,
          data: data.create,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  };

  const removeProductInclude = (id, sizeProduct) => {
    const newProductsIncludes = productsIncludes.filter((product) => {
      return !(product.product_id === id && product.size_product === sizeProduct);
    });
    setProductsIncludes(newProductsIncludes);
  };

  const openAddOrder = () => {
    setError(false);
    setSuccess(false);
    setMessage('');
    setModalCreateOrder(true);
    setPurchaseOrderDetail(null);
    setIdOrderPurchase('');
    setProductsIncludes([]);
    setModalStudentClientFornecedor('');
    setProduct('');
    setSize('');
    setValorUnit('');
    setQuanty('');
    setValueTotalOrder('');
  };

  const addUploadFile = async (purchaseOrder) => {
    setMessage('');
    setSuccessUpload(false);
    setErrorUpload(false);
    setFile('');
    setIdOrderPurchase(null);
    setIdOrderPurchase(purchaseOrder.id);
    setModalFile(true);
    if (purchaseOrder.file) {
      setFile(purchaseOrder.file);
    }
  };

  const handleDeleteDocument = async (id) => {
    const data = {
      id: id,
      filePurchase: file,
    };

    const res = await api.post(baseURL + 'delete-file-purchase-order', data);

    if (res.success === false) {
      setErrorUpload(true);
      setSuccessUpload(false);
    } else {
      setSuccessUpload(true);
      setFile('');
      setMessage('SystemMessage.Success.DocumentDeleted');
    }
  };

  const handleUpload = async (e) => {
    setErrorUpload(false);
    setSuccessUpload(false);

    if (!idOrderPurchase) {
      setIdOrderPurchase(null);
      setErrorUpload(true);
      setMessage(t('RequiredField', { fieldName: t('Type') }));
      return;
    }

    const filePurchase = e.target.files[0];
    setSendingDoc(true);
    const data = new FormData();
    const requestOptions = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    data.append('id', idOrderPurchase);
    data.append('filePurchase', filePurchase);

    const res = await api.post(baseURL + 'upload-file-purchase-order', data, requestOptions);

    setSendingDoc(false);

    if (res.success === false) {
      setErrorUpload(true);
      setSuccessUpload(false);
      setFile('');
      setMessage('SystemMessage.File.Fail.FileNotUploaded');
    } else {
      setFile(res.data.nomeArq);
      setIdOrderPurchase(res.data.retorno.id);
      setSuccessUpload(true);
      setMessage('SystemMessage.File.Success.UploadSuccess');
    }
  };

  const onClickOpenDetails = (order) => {
    setError(false);
    setSuccess(false);
    setMessage('');
    setModalCreateOrder(true);
    setPurchaseOrderDetail(order);
    setModalStudentClientFornecedor(order.supplier);
    setValueTotalOrder(order.value_order);
    setIdOrderPurchase(order.id);

    const newProductsIncludes = order.products_order.map((product) => ({
      product_id: product.id,
      titileProduct: product.titulo,
      amount: product.pivot.amount,
      unitary_value: product.pivot.unitary_value,
      value_total: product.pivot.value_total,
      size_product: product.pivot.size_product,
    }));

    setProductsIncludes((prevProductsIncludes) => [...prevProductsIncludes, ...newProductsIncludes]);
  };

  return (
    <main>
      <SweetAlert
        show={showAlert}
        warning
        showCancel
        cancelBtnText={t('Cancel')}
        confirmBtnText={t('Yes')}
        confirmBtnBsStyle='danger'
        title='Alterar status da ordem de compra'
        onConfirm={handleStatusOrdem}
        onCancel={handleCancel}
        focusCancelBtn
      >
        <span>
          Você realmente deseja mudar o status para: <span style={{ color: '#F8BB86' }}>{updateStatus}</span>
        </span>
      </SweetAlert>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='card'>
                <div className='d-flex flex-column mt-4 w-100' style={{ gap: '5px' }}>
                  <div
                    className='d-flex flex-column flex-sm-row justify-content-center align-items-center'
                    style={{ gap: '10px' }}
                  >
                    <input
                      placeholder='Buscar por numero da ordem'
                      type='text'
                      value={filterNumberOrder}
                      className='form-control form-control-lg'
                      onChange={(e) => setFilterNumberOrder(e.target.value)}
                    />
                    <ButtonRounded isBig onClick={search}>
                      {t('Search')}
                    </ButtonRounded>

                    <ButtonRounded color='black' variant='outline' isBig onClick={openAddOrder}>
                      {t('Create_Purchase_Order')}
                    </ButtonRounded>
                  </div>
                  <div className='d-flex flex-column flex-sm-row form_card' style={{ gap: '10px' }}>
                    <div className='d-flex w-100' style={{ gap: '5px' }}>
                      <div className='form_group'>
                        <label className='card_details_label'>{t('StartDate')}</label>
                        <input
                          type='date'
                          className='form_control'
                          value={filterDueDateIni}
                          onChange={(e) => setFilterDueDateIni(e.target.value)}
                        />
                      </div>
                      <div className='form_group'>
                        <label className='card_details_label'>{t('EndDate')}</label>
                        <input
                          type='date'
                          className='form_control'
                          value={filterDueDateEnd}
                          onChange={(e) => setFilterDueDateEnd(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label'>Status</label>
                      <select
                        required
                        value={filterStatus}
                        className='form_control select'
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        <option value='Aberta'>{'Aberta'}</option>
                        <option value='Recebida'>{'Recebida'}</option>
                        <option value='Conferida'>{'Conferida'}</option>
                        <option value='Cancelada'>{'Cancelada '}</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label'>Fornecedor</label>
                      <select
                        required
                        value={filterSupplier}
                        className='form_control select'
                        onChange={(e) => setFilterSupplier(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        {Array.isArray(modalStudentsClientsFornecedors) && modalStudentsClientsFornecedors.map((item) => (
                          <option key={`i${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className='list_card'>
                    {loading && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!loading && (
                      <>
                        <PurchaseOrderTable
                          onClickOpenDetails={onClickOpenDetails}
                          purchaseOrder={purchaseOrder}
                          onChangeStatus={showAlertUpdateStatus}
                          onClickFile={addUploadFile}
                        />
                        <div className='w-100 p-2 d-flex justify-content-center'>
                          {purchaseOrder.length > 0 && (
                            <Pagination
                              page={currentPage}
                              onChange={handleChangePage}
                              count={totalPages}
                              variant='outlined'
                              shape='rounded'
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </main>

            <Modal
              modalRef={modalScroll}
              message={message}
              successMessage={success}
              errorMessage={error}
              show={modalCreateOrder}
              handleClose={() => {
                setModalCreateOrder(!modalCreateOrder);
                setProductsIncludes([]);
              }}
            >
              <form action='' onSubmit={handleSubmit} method='post' className='form_modal_add_category_product h-100'>
                <div className='d-flex flex-column'>
                  <h3 className='modal_title text-center'>
                    {!idOrderPurchase && 'Ordem de compra - inclusão de produto'}
                    {idOrderPurchase &&
                      purchaseOrderDetail &&
                      'Editar Ordem de compra - ' + purchaseOrderDetail.order_number}
                  </h3>
                  {(!purchaseOrderDetail || purchaseOrderDetail.status === 'Aberta') && (
                    <>
                      <label
                        className='card_details_label'
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        {t('Provider')}
                        <span
                          className='lowercase'
                          style={{
                            marginLeft: 10,
                            color: '#841202',
                            cursor: 'pointer',
                          }}
                          onClick={() => newClientFornecedor()}
                        >
                          {t('CreateOrUpdateCustomer')}
                        </span>
                      </label>
                      <Autocomplete
                        size='small'
                        clearOnEscape={true}
                        id={'_' + Math.random() * 1000 + '_'}
                        noOptionsText={t('NoCustomerOrSupplier')}
                        loadingText={t('SystemMessage.Loading.Loading')}
                        options={modalStudentsClientsFornecedors}
                        getOptionLabel={(option) => (typeof option === 'string' ? '' : option.name)}
                        getOptionSelected={(option, value) => option.idUser === value.idUser}
                        style={{ width: '100%', marginTop: '5px' }}
                        value={modalStudentClientFornecedor === '' ? null : modalStudentClientFornecedor}
                        onChange={(event, newValue) => {
                          setModalStudentClientFornecedor(newValue === null ? '' : newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'off',
                              required: true,
                            }}
                            label={t('EnterTheName')}
                            variant='outlined'
                          />
                        )}
                      />

                      <div className='row pt-3'>
                        <div className='col-md-4 col-sm-12'>
                          <label
                            className='card_details_label'
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            {t('Product')}
                          </label>
                          <Autocomplete
                            size='small'
                            className='mt-1'
                            value={product === '' ? null : product}
                            options={products}
                            getOptionLabel={(option) => (typeof option === 'string' ? '' : option.titulo)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            loadingText={t('SystemMessage.Loading.Loading')}
                            style={{ width: '100%' }}
                            noOptionsText={t('NoCustomerOrSupplier')}
                            onChange={(event, newValue) => {
                              setProduct(newValue === null ? '' : newValue);
                              if (newValue !== null) {
                                const tamanhos = JSON.parse(newValue.tamanho);
                                if (tamanhos.length === 0) {
                                  setSizes(['Único']);
                                } else setSizes(tamanhos);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'off',
                                  required: false,
                                }}
                                label={'Digite um produto'}
                                variant='outlined'
                              />
                            )}
                          />
                        </div>
                        <div className='col-md-3 col-sm-12'>
                          <label
                            className='card_details_label'
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            {t('Price')}
                          </label>
                          <NumberFormat
                            placeholder={t('PlaceholderMoneyZero')}
                            format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: e / 100 })}
                            value={valorUnit * 100 || ''}
                            onValueChange={(e) => setValorUnit(e.floatValue / 100 || null)}
                            name='quanty'
                            label='Preço de Custo'
                            customInput={CustomTextField}
                          />
                        </div>
                        <div className='col-md-3 col-sm-12'>
                          <label
                            className='card_details_label'
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            {t('Size')}
                          </label>
                          <FormControl size='small' className='mt-1' variant='outlined' fullWidth>
                            <InputLabel id='tamanho'>Tamanho</InputLabel>
                            <Select
                              label='Tamanho'
                              labelId='tamanho'
                              id='tamanhos'
                              value={size === '' ? null : size}
                              onChange={(e) => setSize(e.target.value)}
                              name='size'
                              placeholder='Tamanho'
                            >
                              {sizes.map((item, k) => (
                                <MenuItem key={`m${k}`} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className='col-md-2 col-sm-12 pl-0'>
                          <label
                            className='card_details_label'
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            {t('Quanty')}
                          </label>
                          <TextField
                            size='small'
                            className='mt-1'
                            variant='outlined'
                            value={quanty}
                            onChange={(e) => setQuanty(e.target.value)}
                            name='quanty'
                            label='Quantidade'
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {(!purchaseOrderDetail || purchaseOrderDetail.status === 'Aberta') && (
                    <ButtonRounded className='mt-4' type='button' onClick={() => addProduct()}>
                      Incluir produto
                    </ButtonRounded>
                  )}
                  <div className='d-flex flex-column mt-4'>
                    <h4 className='text-center font-bold'>Produtos incluídos</h4>
                    {productsIncludes.length > 0 && (
                      <ProductsOrderPurchase
                        status={purchaseOrderDetail ? purchaseOrderDetail.status : ''}
                        productsIncludes={productsIncludes}
                        onClickRemoveProduct={removeProductInclude}
                      />
                    )}
                    {productsIncludes.length <= 0 && <p>Nenhum produto incluído.</p>}
                  </div>
                  <hr />
                  <div className='d-flex flex-column justify-content-end'>
                    <div className='d-flex justify-content-between'>
                      <span>Valor Total</span>
                      <span className='font-weight-bold'>{currencyFormatterText(valueTotalOrder)}</span>
                    </div>
                  </div>
                  <div className='d-flex align-items-center justify-content-between mt-5 w-100'>
                    <ButtonRounded
                      className='w-100'
                      variant='outline'
                      color='black'
                      type='button'
                      isBig={true}
                      onClick={() => setModalCreateOrder(false)}
                    >
                      Cancelar
                    </ButtonRounded>
                    {(!purchaseOrderDetail || purchaseOrderDetail.status === 'Aberta') && (
                      <ButtonRounded className='w-100 ml-2' isBig={true}>
                        {sendSave && (
                          <>
                            {t('SystemMessage.Loading.Processing')}
                            <Loading type='1' style={{ marginLeft: '2%' }} />
                          </>
                        )}
                        {!sendSave && t('Save')}
                      </ButtonRounded>
                    )}
                  </div>
                </div>
              </form>
            </Modal>

            <ModalFinanceStakeholder
              isShow={displayClientFornecedor}
              handleCloseButton={() => closeModalClientFornecedor()}
              listClienteFornecedor={modalStudentsClientsFornecedors.filter((item) => ['C', 'F'].includes(item.type))}
              onSubmit={handleSubmitClientFornecedor}
            />

            <Modal
              modalContentStyle={{ maxHeight: '200px' }}
              message={message}
              successMessage={successUpload}
              errorMessage={errorUpload}
              size={'sm'}
              show={modalFile}
              handleClose={() => setModalFile(!modalFile)}
            >
              <div className='d-flex flex-column'>
                <h3 className='modal_title'>{t('Document')}</h3>
                <form action='' onSubmit={handleSubmit} method='post' className='form_modal'>
                  {file === '' && (
                    <div className='form_group' style={{ border: '2px dashed #ddd', marginBottom: 40 }}>
                      <label htmlFor='file' className='carregar-arquivo' style={{ margin: 0 }}>
                        {!sendDoc && (
                          <React.Fragment>
                            {t('LoadFile')}...
                            <input type='file' name='file' id='file' onChange={(e) => handleUpload(e)} />
                          </React.Fragment>
                        )}
                        {sendDoc && <Loading type='1' />}
                      </label>
                    </div>
                  )}
                  {file === '' ? (
                    <p style={{ textAlign: 'center', width: '100%' }}>
                      <b>{t('NoDocumentsAttached')}</b>
                    </p>
                  ) : (
                    <p style={{ textAlign: 'center', width: '100%', marginBottom: 20 }}>
                      <b>{t('AttachedDocuments')}</b>
                    </p>
                  )}

                  {file != -'' && (
                    <div
                      className='form_group'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p style={{ fontSize: 14 }}>{t('Document')}</p>
                      <div
                        style={{
                          width: '31%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href={`${dirDocument}/${file}`}
                          style={{
                            fontSize: 12,
                            background: '#4682B4',
                            color: 'white',
                            padding: '2px 8px',
                            borderRadius: 4,
                          }}
                        >
                          {t('View')}
                        </a>
                        <p
                          onClick={() => handleDeleteDocument(idOrderPurchase)}
                          style={{
                            cursor: 'pointer',
                            fontSize: 12,
                            background: '#B22222',
                            color: 'white',
                            padding: '2px 8px',
                            borderRadius: 4,
                          }}
                        >
                          {t('Delete')}
                        </p>
                      </div>
                    </div>
                  )}

                  {sendSave && (
                    <button
                      className='btn_card mx-auto'
                      style={{ background: '#ccc', border: 'none', pointerEvents: 'none' }}
                    >
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('SystemMessage.Loading.Processing')}
                      <Loading type='1' style={{ marginLeft: '2%' }} />
                    </button>
                  )}
                </form>
              </div>
            </Modal>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default PurchaseOrder;
