import Axios from 'axios';
import Api, { getEnvironment } from '../utils/api';
import { defaultApiObject, handleFail } from '../utils/utils-api';
import api from './api';

const enableDebug = (getEnvironment() !== 'PROD');
const baseURL = Api()

export const getMicrocyclePaymentsBySchoolId = async (id) => {
  try {
    const { data } = await api.get(`get-microcycle-payments-by-school/${id}`)
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getMicrocyclePaymentsBySchoolIdAndMicrocycleId = async (id) => {
  try {
    const { data } = await api.get(`get-microcycle-payments-by-school-and-microcycle/${id}`);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getMicrocyclePayment = async (id) => {
  try {
    const { data } = await api.get(`get-microcycle-payments-by-id/${id}`);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const createMicrocyclePayment = async (values) => {
  try {
    const { data } = await api.post('create-microcycle-payment', values);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateMicrocyclePayment = async (values) => {
  try {
    const { data } = await api.put('update-microcycle-payment', values);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const retrieveToken = async (values) => {
  try {
    const { data } = await Axios.post(baseURL + 'payment-auth', values);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
}

export const clientSave = async (values, token) => {
  try {
    const { data } = await Axios.post(`${baseURL}${enableDebug ? "hmg-" : ""}cliente-save`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
}

export const paymentSubscription = async (values) => {
  try {
    const { data } = await api.post((enableDebug ? 'hmg-' : '') + 'payment-microcycle-subscription', values);
    return data;
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
}

export const checkSubscription = async (id) => {
  try {
    const today = new Date().toLocaleDateString('en-CA')
    const response = await getMicrocyclePaymentsBySchoolId(id)
    const status = response?.microcyclePayments?.find((value) => {
      const subscriptionActiveLastDate = new Date(value?.created_at)
      subscriptionActiveLastDate.setDate(subscriptionActiveLastDate.getDate() + 30)
      const dateLimit = subscriptionActiveLastDate.toLocaleDateString('en-CA')
      return today <= dateLimit && value?.status === 'A' && value?.invoiced === 'S' && value?.financial?.received == 'S'
    })
    return status
  } catch (error) {
    console.error(error)
  }
}