import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

const Footer = () => {

   const [logoff, setLogoff] = useState(false)
  
   const clear = (e) => {
      e.preventDefault()

      localStorage.removeItem('user')
      localStorage.removeItem('athlete')
      localStorage.removeItem('docs')
      localStorage.removeItem('menu')
      localStorage.removeItem('sign')
      localStorage.removeItem('school')
      localStorage.removeItem('saldo')
      localStorage.removeItem('company')
      localStorage.removeItem('persist:root')
      setLogoff(true)
   } 

   if ( logoff )
      return (
         <Redirect to="/login-school" />
      )

   return (
      <footer className="footer">
         <div className="nav_footer">
            <div className="links_footer">
               <a className="footer_link" href="#">Central de ajuda</a>
               <a className="footer_link" href="/docs/FENSOR ESCOLA - TERMOS DE USO E POLÍTICA DE PRIVACIDADE.pdf" download="/docs/FENSOR ESCOLA - TERMOS DE USO E POLÍTICA DE PRIVACIDADE.pdf">Termos de uso</a>
               <a className="footer_link" href="/docs/FENSOR ESCOLA - TERMOS DE USO E POLÍTICA DE PRIVACIDADE.pdf" download="/docs/FENSOR ESCOLA - TERMOS DE USO E POLÍTICA DE PRIVACIDADE.pdf">Política de privacidade</a>
            </div>

            <a href="#" onClick={(e) => clear(e)} className="logout">
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M20.625 0H3.375C1.51125 0 0 1.51125 0 3.375V10.875H13.41L11.955 9.42C11.5312 8.96625 11.5575 8.25375 12.0112 7.83C12.4425 7.42875 13.1137 7.42875 13.545 7.83L16.92 11.205C17.3587 11.6437 17.3587 12.3562 16.92 12.795L13.545 16.17C13.1062 16.6087 12.3937 16.6087 11.955 16.17C11.5162 15.7312 11.5162 15.0188 11.955 14.58L13.41 13.125H0V20.625C0 22.4888 1.51125 24 3.375 24H20.625C22.4888 24 24 22.4888 24 20.625V3.375C24 1.51125 22.4888 0 20.625 0Z" />
               </svg>
               Desconectar
            </a>
         </div>
         <div className="comunicacao" style={{backgroundColor: 'rgb(20, 47, 118, 0.5)'}}>
            Grupo Fensor. Todos os direitos reservados.
         </div>
      </footer>
   )
}

export default Footer