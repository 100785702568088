import React, { useEffect, useRef, useState } from 'react';
import Footer from '../../components/school/Footer';
import Logo from '../../components/school/Logo';
import { Box } from '@material-ui/core';

const HomeBasic = () => {
  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='dashboard-top-card' style={{ gap: '10px' }}>
                <Box className='card w-100'>
                  <div className='img_school'>
                  </div>
                </Box>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default HomeBasic;
