export function adicionarProdutoCarrinho(item) {
    return {
        type: 'ADD',
        payload: item
    }
}

export function excluirProdutoCarrinho(id) {
    return {
        type: 'DEL',
        payload: {
            id
        }
    }
}

export function clearCarrinho(id) {
    return {
        type: 'CLEAR',
        payload: []
    }
}

export function atualizarQtdProdutoCarrinho(id, qtd) {
    return {
        type: 'UPDATE_QTY',
        payload: {
            id,
            qtd
        }
    }
}