import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSave, FaUpload } from 'react-icons/fa';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import InputRounded from '../../components/inputs/InputRounded';
import TextareaRounded from '../../components/inputs/TextareaRounded';
import { handleOnChange } from '../../utils/utils';

const AttendanceForm = ({ initialData, active, isSending, onSubmit }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({ classDaysFrequencyId: null, title: '', description: '' });
  const onChangeFormData = handleOnChange(setFormData);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.url));
    };
  }, []);

  useEffect(() => {
    if (active && initialData) {
      setFormData({
        classDaysFrequencyId: initialData.id,
        title: initialData.justification_title,
        description: initialData.justification_description,
      });

      setFiles(
        initialData.class_absence_justifications.map((attachment) => ({
          ...attachment,
          idAux: attachment.id,
          url: attachment.url,
        }))
      );
    } else {
      setFiles([]);
    }
  }, [active]);

  const addFiles = (files) => {
    setFiles((current) => [
      ...current,
      ...files.map((file, i) => ({ idAux: +(Date.now().toString() + i), blob: file, url: URL.createObjectURL(file) })),
    ]);
  };

  const removeFiles = (files) => {
    files.forEach((file) => URL.revokeObjectURL(file.url));

    setFiles((current) => current.filter((f) => !files.some((ff) => ff.idAux === f.idAux)));
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    onSubmit({ ...formData, files });
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <InputRounded
        required
        label={t('AbsenceReason')}
        classNameWrapper='border-color-default m-0'
        className='text-left py-1 w-100'
        styleContainer={{ alignItems: 'flex-start' }}
        styleWrapper={{ borderRadius: '8px' }}
        name='title'
        type='text'
        maxlength={50}
        placeholder={t('MaxCharacters', 50)}
        value={formData}
        onChange={onChangeFormData}
      />

      <TextareaRounded
        required
        label={t('Description')}
        classNameWrapper='border-color-default m-0'
        className='text-left py-1 w-100'
        styleContainer={{ alignItems: 'flex-start' }}
        styleWrapper={{ borderRadius: '8px' }}
        name='description'
        maxlength={500}
        placeholder={t('MaxCharacters', 500)}
        value={formData}
        onChange={onChangeFormData}
      />

      <ul style={{ marginLeft: '30px', listStyle: 'disc' }}>
        {files.map((file) => (
          <li key={`AttendanceForm_File_${file.idAux}`}>
            <a className='table_mes' target='_black' href={file.url}>
              {file.name ?? file.blob.name}
            </a>
            <svg
              onClick={() => removeFiles([file])}
              xmlns='http://www.w3.org/2000/svg'
              width='25'
              height='25'
              fill='red'
              className='bi bi-x'
              viewBox='0 0 16 16'
              style={{ cursor: 'pointer' }}
            >
              <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z' />
            </svg>
          </li>
        ))}
      </ul>

      <small className='d-block text-right color-default mr-1'>{['jpg', 'png', 'pdf', 'doc', 'docx'].join(', ')}</small>
      <ButtonRounded
        variant='grey'
        color='#ffffff'
        type='button'
        className='w-100 mb-2 p-0'
        style={{ borderRadius: '8px' }}
        isDisabled={isSending}
      >
        <label className='m-0 w-100 h-100 clickable d-flex justify-content-center align-items-center'>
          <FaUpload style={{ width: '18px', height: '18px', fill: '#ffffff' }} />
          {t('AttachFile')}
          <input multiple className='d-none' type='file' onChange={(e) => addFiles([...e.target.files], e)} />
        </label>
      </ButtonRounded>

      <ButtonRounded type='submit' className='w-100 py-3 mb-2' style={{ borderRadius: '8px' }} isLoading={isSending}>
        <FaSave style={{ width: '18px', height: '18px' }} /> {t('Save')}
      </ButtonRounded>
    </form>
  );
};

export default AttendanceForm;
