import React, { useContext } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Trans, useTranslation } from 'react-i18next';
import ButtonRounded from '../components/buttons/ButtonRounded';
import useAsaasClient from '../hooks/useAsaasClient';
import useAsaasPayment from '../hooks/useAsaasPayment';

import '../utils/extendsType';
import { UserContext } from './UserContext';

/**
 * ContractFaturarContextState usa os hooks useAsaasClient e useAsaasPayment para faturar um contrato
 * @typedef {Object} ContractFaturarContextState
 */
export const CobrancaFaturarContext = React.createContext();

const PAYMENT_TYPE = {
  Boleto: 'BOLETO',
  Cartão: 'CREDIT_CARD',
};
const DATA_ASSAS = {
  billingType: '',
  _billingType: '',
  customer: {},
  token_assas: '',
  split: [],
};

const payloadAvista = (cobranca, customer, usuSession, configAsaas) => {
  DATA_ASSAS.billingType = PAYMENT_TYPE[cobranca.payment] || cobranca.payment;
  DATA_ASSAS._billingType = cobranca.billingType;
  DATA_ASSAS.value = cobranca.value;
  DATA_ASSAS.installmentCount = '';
  DATA_ASSAS.installmentValue = '';
  DATA_ASSAS.dueDate = cobranca.dueDate;
  DATA_ASSAS.postalService = false;
  DATA_ASSAS.financial = {
    school_id: usuSession.school_id,
    user_id: usuSession.id,
    client_fornecedor_id: null,
    user_id_student: cobranca.user_id_student,
    type: cobranca.type,
    countDescription: cobranca.countDescription,
    mode: cobranca.mode,
    payment: cobranca.payment,
    dueDate: cobranca.dueDate,
    amount: cobranca.amount,
    received: cobranca.received,
    datePayment: cobranca.datePayment,
    observation: cobranca.observation,
  };

  DATA_ASSAS.customer = customer.customer;
  DATA_ASSAS.client_id = customer.id;

  DATA_ASSAS.token_assas = configAsaas.token_assas;
  //DATA_ASSAS.user_id = usuSession.id;

  if (configAsaas.percentual) {
    DATA_ASSAS.split = [
      {
        walletId: configAsaas.wallet,
        percentualValue: configAsaas.percentual,
      },
    ];
  }
  if (configAsaas.valor) {
    DATA_ASSAS.split = [
      {
        walletId: configAsaas.wallet,
        fixedValue: configAsaas.valor,
      },
    ];
  }
  // --------------------------------------------------------------------------------------------------------------------------------
  if (cobranca.discount) {
    DATA_ASSAS.discount = {
      value: cobranca.discount,
      dueDateLimitDays: 0,
      type: !cobranca.discount_type ? 'NONE' : cobranca.discount_type,
    };
  }

  if (cobranca.late_interest) {
    DATA_ASSAS.interest = {
      value: cobranca.late_interest,
      type: !cobranca.late_interest_type ? 'NONE' : cobranca.late_interest_type,
    };
  }

  if (cobranca.late_fee) {
    DATA_ASSAS.fine = {
      value: cobranca.late_fee,
      type: !cobranca.late_fee_type ? 'NONE' : cobranca.late_fee_type,
    };
  }

  return DATA_ASSAS;
};
const payloadParcelado = (cobranca, customer, usuSession, configAsaas) => {
  DATA_ASSAS.billingType = PAYMENT_TYPE[cobranca.payment] || cobranca.payment;
  DATA_ASSAS._billingType = cobranca.billingType;
  DATA_ASSAS.dueDate = cobranca.dueDate;
  DATA_ASSAS.description = cobranca.countDescription;
  DATA_ASSAS.installmentCount = +cobranca.amount;
  DATA_ASSAS.installmentValue = cobranca.value;
  DATA_ASSAS.postalService = false;

  DATA_ASSAS.customer = customer.customer;
  DATA_ASSAS.client_id = customer.id;
  DATA_ASSAS.token_assas = configAsaas.token_assas;

  DATA_ASSAS.financial = {
    school_id: 33,
    user_id: usuSession.id,
    client_fornecedor_id: cobranca.client_fornecedor_id || null,
    user_id_student: cobranca.user_id_student,
    type: cobranca.type,
    countDescription: cobranca.countDescription,
    mode: cobranca.mode,
    payment: cobranca.payment,
    dueDate: cobranca.dueDate,
    amount: +cobranca.amount,
    received: 'N',
    datePayment: '',
    observation: cobranca.observation || '',
  };

  if (configAsaas.percentual) {
    DATA_ASSAS.split = [
      {
        walletId: configAsaas.wallet,
        percentualValue: configAsaas.percentual,
      },
    ];
  }
  if (configAsaas.valor) {
    DATA_ASSAS.split = [
      {
        walletId: configAsaas.wallet,
        fixedValue: configAsaas.valor,
      },
    ];
  }
  // --------------------------------------------------------------------------------------------------------------------------------
  if (cobranca.discount) {
    DATA_ASSAS.discount = {
      value: cobranca.discount,
      dueDateLimitDays: 0,
      type: !cobranca.discount_type ? 'NONE' : cobranca.discount_type,
    };
  }

  if (cobranca.late_interest) {
    DATA_ASSAS.interest = {
      value: cobranca.late_interest,
      type: !cobranca.late_interest_type ? 'NONE' : cobranca.late_interest_type,
    };
  }

  if (cobranca.late_fee) {
    DATA_ASSAS.fine = {
      value: cobranca.late_fee,
      type: !cobranca.late_fee_type ? 'NONE' : cobranca.late_fee_type,
    };
  }

  return DATA_ASSAS;
};

export default function ({ children }) {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const alertRef = React.useRef(null);

  const btnLoadRef = React.useRef(null);

  const {
    handlerPayment,
    handlerRemovePayment,
    handlerReceivedPayment,
    handlerRemovePaymentMoney,
    errorPayment,
    successPayment,
  } = useAsaasPayment();

  const { handleOnSubmitClientPayment, errorClient, authAsaas, configAsaas, clienteResponse } = useAsaasClient();

  const [state, _setState] = React.useState({
    cobranca: { payment: 'Cartão' },
    student: {},
    faturar: false,
    cancelar_fatura: false,
    received_fatura: false,
    error: {
      show: false,
      message: '',
    },
    authAsaas: authAsaas || null,
    configAsaas: configAsaas || null,
    asassIntegration: false,
    done: false,
    successCallback: () => {},
  });
  // constructors -------------------------------------------------------------------------------------------------------------
  React.useEffect(() => {
    if (successPayment) {
      console.log('successPayment', successPayment);
      btnLoadRef.current.reset();
      //setDone(true);
      handlerCancelFaturar();
      //* dispara o callback do filho no sucesso do faturamento
      state.successCallback();
    }
  }, [successPayment]);

  React.useEffect(() => {
    if (errorPayment) {
      btnLoadRef.current.reset();
      faturarContext.setState({ error: { message: errorPayment, show: true } });
    }

    if (errorClient) {
      btnLoadRef.current.reset();
      faturarContext.setState({ error: { message: errorClient, show: true } });
    }
  }, [errorPayment, errorClient]);

  React.useEffect(() => {

    if(configAsaas){
      faturarContext.setState({ asassIntegration: configAsaas.token_assas.length > 10 });
      faturarContext.setState({ authAsaas: authAsaas, configAsaas: configAsaas });
    }
  }, [configAsaas]);

  // private functions -------------------------------------------------------------------------------------------------------------
  const handlerConfirmFaturar = () => {
    faturarContext.setState({ faturar: true });
  };
  const handlerConfirmRemoverFatura = () => {
    faturarContext.setState({ cancelar_fatura: true });
  };
  const handlerConfirmReceivedFatura = () => {
    faturarContext.setState({ received_fatura: true });
  };

  const handlerCancelFaturar = () => {
    faturarContext.reset();
  };

  const handlerFaturar = async (e) => {
    e.preventDefault();
    e.persist();
    btnLoadRef.current = e.target.loader();

    btnLoadRef.current.start();

    const response = await handleOnSubmitClientPayment(
      e,
      {
        ...state.student,
        postalCode: state.student.athCep,
        address: state.student.athLogradouro,
        addressNumber: state.student.athNumero,
        province: state.student.athBairro,
        name: state.student.name,
      },
      state.cobranca
    );

    if (state.cobranca.billingType === 'BOLETO_INTEGRATED' || state.cobranca.billingType === 'CREDIT_CARD_INTEGRATED') {
      await handlerPayment(
        e,
        payloadAvista(response.payload, response.customer, {...response.usuSession, school_id: user.school_id}, response.configAsaas)
      );
    } else {
      await handlerPayment(
        e,
        payloadParcelado(response.payload, response.customer, {...response.usuSession, school_id: user.school_id}, response.configAsaas)
      );
    }
  };

  const handlerRemoverFatura = async (e) => {
    e.preventDefault();
    e.persist();
    btnLoadRef.current = e.target.loader();

    btnLoadRef.current.start();

    state.cobranca.token_assas = authAsaas.asass_conf_token;

    console.log('state.cobranca.payment', state.cobranca);

    if (state.cobranca.payment === 'MONEY') {
      handlerRemovePaymentMoney(e, state.cobranca);
      return;
    }
    handlerRemovePayment(e, state.cobranca);
  };

  const handlerRecivedFatura = async (e) => {
    e.preventDefault();
    e.persist();
    btnLoadRef.current = e.target.loader();

    btnLoadRef.current.start();

    //state.cobranca.token = authAsaas.asass_conf_token;

    state.cobranca.cnpj = configAsaas.cnpj;
    state.cobranca.password = configAsaas.password;
    state.cobranca.token_assas = configAsaas.token_assas;
    state.cobranca.wallet = configAsaas.wallet;
    state.cobranca.utilizaFaturamento = configAsaas.utilizaFaturamento;

    state.cobranca.valueReceived = state.cobranca.valorReceber;

    console.log('state.cobranca.payment', state.cobranca);

    await handlerReceivedPayment(e, state.cobranca);
  };
  //public functions -------------------------------------------------------------------------------------------------------------
  const faturarContext = React.useMemo(
    () => ({
      handlerConfirmFaturar,
      handlerConfirmRemoverFatura,
      handlerConfirmReceivedFatura,
      state,
      setState(state) {
        _setState((prevState) => ({ ...prevState, ...state }));
      },
      reset: () => {
        _setState({
          contract: { payment: 'MONEY' },
          student: {},
          faturar: false,
          cancelar_fatura: false,
          received_fatura: false,
          error: {
            show: false,
            message: '',
          },
          done: false,
          authAsaas: authAsaas || null,
          asassIntegration: false,
          successCallback: () => {},
        });
      },
    }),
    [state]
  );
  return (
    <>
      <SweetAlert
        warning
        showCancel
        confirmBtnBsStyle='danger'
        onConfirm={handlerFaturar}
        onCancel={handlerCancelFaturar}
        focusCancelBtn
        ref={alertRef}
        show={state.faturar}
        title={t('Attention_exclamation')}
        customButtons={
          <span className='w-100 d-flex justify-content-around align-items-center'>
            <ButtonRounded variant='outline' color='var(--main-color)' isBig={true} onClick={handlerCancelFaturar}>
              {t('Cancel')}
            </ButtonRounded>
            <ButtonRounded isBig={true} onClick={handlerFaturar}>
              {t('InvoiceContract')}
            </ButtonRounded>
          </span>
        }
      >
        <Trans i18nKey='SchoolFinancePage.SystemMessage.WarningInvoice'>
          <strong>a</strong>b
        </Trans>
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        confirmBtnBsStyle='danger'
        onConfirm={handlerRemoverFatura}
        onCancel={handlerCancelFaturar}
        focusCancelBtn
        ref={alertRef}
        show={state.cancelar_fatura}
        title={t('Attention_exclamation')}
        customButtons={
          <span className='w-100 d-flex justify-content-around align-items-center'>
            <ButtonRounded variant='outline' color='var(--main-color)' isBig={true} onClick={handlerCancelFaturar}>
              {t('Cancel')}
            </ButtonRounded>
            <ButtonRounded isBig={true} onClick={handlerRemoverFatura}>
              {t('Continue')}
            </ButtonRounded>
          </span>
        }
      >
        <Trans i18nKey={'SchoolFinancePage.SystemMessage.WarningCancelInvoice'}>
          <strong>a</strong>b
        </Trans>
      </SweetAlert>
      <SweetAlert
        show={state.error.show}
        danger
        title='Falha!'
        onConfirm={handlerCancelFaturar}
        customButtons={
          <ButtonRounded isBig={true} onClick={handlerCancelFaturar} className='w-100 m-4'>
            {t('Close')}
          </ButtonRounded>
        }
      >
        <p> {t(state.error.message)}</p>
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        confirmBtnBsStyle='danger'
        onConfirm={handlerRecivedFatura}
        onCancel={handlerCancelFaturar}
        focusCancelBtn
        ref={alertRef}
        show={state.received_fatura}
        title={t('Attention_exclamation')}
        customButtons={
          <span className='w-100 d-flex justify-content-around align-items-center'>
            <ButtonRounded variant='outline' color='var(--main-color)' isBig={true} onClick={handlerCancelFaturar}>
              {t('Cancel')}
            </ButtonRounded>
            <ButtonRounded isBig={true} onClick={handlerRecivedFatura}>
              {t('Confirm')}
            </ButtonRounded>
          </span>
        }
      >
        <Trans i18nKey='SchoolFinancePage.Modal.ReceiveInCash'>
          <strong>a</strong>b
        </Trans>
      </SweetAlert>
      <CobrancaFaturarContext.Provider value={faturarContext}>
        {state.done === false && children}
      </CobrancaFaturarContext.Provider>
    </>
  );
}
