import React, { useContext, useState } from 'react';
import { UserContext } from '../../../Context/UserContext';
import { clientSave, createMicrocyclePayment, paymentSubscription, retrieveToken } from '../../../services/MicrocyclePaymentsService';
import { getAccountFensorAsaas } from '../../../utils/api';

const PaySubscription = ({ onClose, microcycle, updateMicrocycle }) => {
  const { user, school } = useContext(UserContext);
  const [paymentMethod, setPaymentMethod] = useState(1)

  const createSubscription = async () => {
    try {
      const nextDueDate = new Date()
      nextDueDate.setDate(nextDueDate.getDate() + 30)
      const nextDueDateFormatted = nextDueDate.toLocaleDateString('en-CA')
      const credentials = getAccountFensorAsaas()

      const { data: responseCreateSubscription } = await createMicrocyclePayment({
        "school_id": school.id,
        "microcycle_id": microcycle.id,
        "value": 100,
        "status": "A",
        "invoiced": "N"
      });

      const { data: responseRetrieveToken } = await retrieveToken({
        "cnpj": credentials.cnpj,
        "password": credentials.password,
        "loginEmail": credentials.loginEmail
      });

      const { data: responseClientSave } = await clientSave({
        "cpf_cnpj": "08573116625",
        "name": "dani sla (Daniel 2023)",
        "email": "danielfavarini@gmail.com",
        "phone": "31987836583",
        "customer": "",
        "system_id": 1,
        "user_id": credentials.user_id,
        "token_assas": credentials.token_assas
      }, responseRetrieveToken?.token);

      const responsePayment = await paymentSubscription({
        "billingType": "CREDIT_CARD",
        "customer": responseClientSave?.customer,
        "nextDueDate": nextDueDateFormatted,
        "cycle": "MONTHLY",
        "endDate": "",
        "value": 100,
        "client_id": responseClientSave?.id,
        "description": "Contrato com cobrança mensal por cartão de crédito",
        "methodology_id": responseCreateSubscription?.id,
        "token_assas": credentials.token_assas,
        "user_id": user?.id
      })

      if (responsePayment?.paymentCreate?.id !== undefined) {
        updateMicrocycle(true)
        onClose(false)
      } else {
        console.warn('Subscription Payment Error!')
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className="modal-general" onClick={() => onClose(false)}>
      <div className="modal-general-content" style={{ padding: '30px' }} onClick={e => e.stopPropagation()}>
        <div className='d-flex justify-content-center'>
          <button className="modal-general-close" onClick={() => onClose(false)}>
            &times;
          </button>
          <div className=''><strong>Contratar Metodologia</strong></div>
        </div>
        <div className='d-flex flex-column align-items-center mt-3'>
          <div className=''><strong>Assinatura Mensal</strong></div>
          <div className=''>R$ 100,00</div>
        </div>
        <div className='d-flex flex-column mt-3'>
          <div className='' style={{ color: 'green' }}><strong>Forma de Pagamento</strong></div>
          <select
            required
            name='paymentMethod'
            className='form_control select'
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value='0' disabled>Selecionar</option>
            <option value='1'>CARTÃO DE CRÉDITO</option>
          </select>
        </div>
        <div className='mt-4'>
          <strong>O acesso as metodologias padrões</strong> gera uma assinatura recorrente mensal e só poderá ser pago com cartão de crédito.
        </div>
        <div className='mt-3'>
          Obs: Não existe fidelidade, podendo ser cancelado a qualquer momento.
        </div>
        <div className='d-flex mt-3'>
          <button className='w-100 p-2 rounded-lg border-1' style={{ backgroundColor: 'white', borderColor: 'purple' }} onClick={() => onClose(false)}>Sair</button>
          <button className='w-100 p-2 ml-3 rounded-lg border-0' style={{ color: 'white', backgroundColor: 'purple' }} onClick={() => createSubscription()}>Confirmar Compra</button>
        </div>
      </div>
    </div>
  )
}

export default PaySubscription;