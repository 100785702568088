import React, { useEffect, useState, useRef } from 'react'
import Header from '../../components/athlete/Header'
import Footer from '../../components/athlete/Footer'
import Loading from '../../components/Loading'
import { Redirect } from 'react-router-dom'
import Api from '../../utils/api'
import axios from 'axios'

const Photosalbum = (props) => {
   // ref
   const scrollObserve = useRef();

   // set loading
   const [sendingFilter, setSendingFilter] = useState(false)
   const [sendingSearch, setSendingSearch] = useState(false)

   // set data
   const [photographers, setPhotographers] = useState([])
   const [teams, setTeams] = useState([])
   const [states, setStates] = useState([])
   const [photoalbuns, setPhotoalbuns] = useState([])
   const [dir, setDir] = useState("")
   const [page, setPage] = useState(0)
   const [scrollRadio, setScrollRadio] = useState(null)

   // set ID
   const [id, setID] = useState('')
   const [team_id, setTeamID] = useState('')
   const [photographer_id, setPhotographerID] = useState('')
   const [state_id, setStateID] = useState('')
   const [date, setDate] = useState('')

   // outras variaveis
   const baseURL = Api()
   let userLogado = localStorage.getItem('user')

   if ( userLogado !== null ) {
      userLogado = JSON.parse(userLogado)
   }

   useEffect(()=>{
      getFilters()
      handleSearch(page)
   },[])

   const getFilters = async () => {
      setSendingFilter(true)
      const res = await axios.get(baseURL + 'get-filters-photoalbum')
      setSendingFilter(false)
      setPhotographers(res.data.photographers)
      setTeams(res.data.teams)
      setStates(res.data.states)
   }

   const handleSearch = async (novaPage, search = false) => {

      setSendingSearch(true)
      const res = await axios.post(baseURL + 'get-photoalbum',{
         user_id: photographer_id,
         team_id: team_id,
         state_id: state_id,
         date: date,
         start: novaPage,
         length: 20
      })
      setSendingSearch(false)

      // monta as photos
      if ( ! search ) {
         const novosAlbuns = [...photoalbuns]
         novosAlbuns.push(...res.data.data)
         setPhotoalbuns(novosAlbuns)
      } else {
         setPhotoalbuns(res.data.data)
      }

      setDir(res.data.url)
   }

   const intersectionObserver = new IntersectionObserver( entries => {
      const radio = entries[0].intersectionRatio
      setScrollRadio(radio)
   })

   useEffect(() => {

      intersectionObserver.observe(scrollObserve.current)

      return () => {
         intersectionObserver.disconnect()
      }

   },[])

   useEffect(() => {

      if ( scrollRadio > 0 && photoalbuns.length > 0) {
         const novaPage = page + 10
         setPage(novaPage)
         handleSearch(novaPage)
      }

   },[scrollRadio])

   if (id !== '')
      return <Redirect to={'/photos/' + id} />

   return (
         <React.Fragment>
            
         {
            userLogado !== null && 
            <Header type="3" menu={true} />
         }
         {
            userLogado === null &&
            <Header type="3" menu={false} />
         }

         <main>
            <section className="banner">
               <div className="conteudo-banner banner-fensor-flash">
                  <span>Bem-vindo(a) ao Fensor Flash</span>
                  <h1>Seus melhores momentos estão registrados aqui.</h1>
                  <p>Encontre as melhores fotos dos seus jogos no banco de imagens exclusivo da Fensor.</p>
               </div>
            </section>
            <section className="bloco-principal">
               <div className="conteudo-bloco-principal">
                  <div className="filtro-flash">
                     <div className="titulo-filtro-flash">
                        <img src="../../img/icones/filtro.png" />
                        <h3>Filtros</h3>
                        <div className="titulo-texto-filtro">
                           <p>Faça uma busca avançada pelas suas fotos.</p>
                        </div>
                     </div>
                     <form className="busca-filtro-flash" action="" method="">
                        
                        <div className="box-filtro-flash">
                           <label>
                              Fotógrafo &nbsp;
                              {
                                 sendingFilter && 
                                 <Loading type="1" />
                              }
                           </label>
                           {
                              !sendingFilter && 
                              <select name="photographer_id" onChange={e => setPhotographerID(e.target.value)}>
                                 <option value="">-- Todos os Fotógrafos --</option>
                                 {
                                    photographers.map(item => <option value={item.id} key={item.id}>{item.name}</option>)
                                 }
                              </select>
                           }
                        </div>
                        <div className="box-filtro-flash">
                           <label>
                              Time &nbsp;
                              {
                                 sendingFilter && 
                                 <Loading type="1" />
                              }
                           </label>
                           {
                              !sendingFilter && 
                              <select name="companie_id" onChange={e => setTeamID(e.target.value)}>
                                 <option value="">-- Todos os Times --</option>
                                 {
                                    teams.map(item => <option value={item.id} key={item.id}>{item.teaTeam}</option>)
                                 }
                              </select>
                           }
                        </div>
                        <div className="box-filtro-flash">
                           <label>
                              Estados &nbsp;
                              {
                                 sendingFilter && 
                                 <Loading type="1" />
                              }
                           </label>
                           {
                              !sendingFilter && 
                              <select name="state_id" onChange={e => setStateID(e.target.value)}>
                                 <option value="">-- Todos os Estados --</option>
                                 {
                                    states.map(item => <option value={item.id} key={item.id}>{item.staState}</option>)
                                 }
                              </select>
                           }
                        </div>
                        <div className="box-filtro-flash-1">
                           <label>Data</label>
                           <input type="date" name="date" onChange={e => setDate(e.target.value)} />
                        </div>
                        <div className="botao-filtro-flash">
                           {
                              sendingSearch && <Loading type="1" />
                           }
                           {
                              !sendingSearch && <button type="button" onClick={() => handleSearch(0, true)}>Pesquisar</button>
                           }
                        </div>
                     </form>
                  </div>
               </div>
            </section>
            <section className="albuns-recentes">
               <div className="cont-albuns-recentes">
                  {
                     photoalbuns.length == 0 && <h3>Nenhum álbum encontrado para o filtro selecionado.</h3>
                  }
                  {
                     photoalbuns.length > 0 && <h3>Álbuns recentes</h3>
                  }
                  <ul className="album-ind-recentes">
                     {
                        photoalbuns.map((item, i) => {
                           return (
                              item.photoalbumphoto.length > 0 &&
                              <li className="card-album" key={"photos_" + i} onClick={e => setID(item.id)}>
                                 <div className="img-album">
                                    {
                                       item.capa === null &&
                                       <img src="../../img/fensor-flash/albuns/austin-bold/foto-1.png" />
                                    }
                                    {
                                       item.capa !== null &&
                                       <img src={dir + item.capa.replace('.','_marcadagua.')} />
                                    }
                                 </div>
                                 <a href="foto-individual.html" className="corpo-card-album">
                                    <div className="titulo-album">
                                       <h4>{ (  item.tournament === null ? item.description : item.tournament ) }</h4>
                                    </div>
                                    <div className="local-album">
                                       <p>{item.citCity} - {item.staStateSigla}</p>
                                    </div>
                                    <div className="data-album">
                                       <p>{item.date_formatado}</p>
                                    </div>  
                                    <div className="data-album">
                                       <p>Por { (item.user != null ? item.user : 'FENSOR') }</p>
                                    </div>   
                                 </a>
                              </li>
                           )
                        })
                     }
                  </ul>
                  {
                     sendingSearch && <Loading type="3" />
                  }
                  <div ref={scrollObserve}></div>
               </div>
            </section>
         </main>

         <Footer />
          
         </React.Fragment>
   )
}

export default Photosalbum