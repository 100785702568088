import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Message from '../../components/Message';
import { defaultCssStyleToPrint, exportToXLS, handleOnChange, range } from '../../../src/utils/utils';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import { getCashFlowReport, getSchoolFinancesOnDay } from '../../services/ReportService';
import { useReactToPrint } from 'react-to-print';
import InputRounded from '../../components/inputs/InputRounded';
import TableBordered from '../../components/tables/TableBordered';
import MoneyMask from '../../components/inputs/MoneyMask';
import ModalCashFlow from '../../views/ModalCashFlow';
import Loading from '../../components/Loading';

const CashFlowReports = () => {
  const { t } = useTranslation();
  const { MessageDangerMarginTop } = Message();
  const schoolId = JSON.parse(localStorage.getItem('school')).id;
  const tableRef = useRef(null);
  const modalRef = useRef(null);
  const today = moment();
  const todayFormatted = today.format('YYYY-MM-DD');

  const [filters, setFilters] = useState({
    date: today.format('YYYY-MM'),
  });

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFinancesOnDay, setIsLoadingFinancesOnDay] = useState(false);

  const [initialBalance, setInitialBalance] = useState(null);
  const [cashFlow, setCashFlow] = useState([]);
  const [totalIncome, setTotalIncome] = useState(null);
  const [totalExpense, setTotalExpense] = useState(null);
  const [financesOnDay, setFinancesOnDay] = useState({ income: [], expense: [] });

  useEffect(() => {
    fetchCashFlowReport();
  }, []);

  useEffect(() => {
    if (typeof initialBalance === 'number') calcBalances(initialBalance);
  }, [initialBalance]);

  const onChangeFilters = handleOnChange(setFilters);

  const fetchCashFlowReport = async (event = null) => {
    event && event.preventDefault();
    clearMessage();
    setIsLoading(true);
    const { status, messageKey, balance, finances } = await getCashFlowReport({ schoolId, ...filters });

    if (status === 'SUCCESS') {
      mountCashFlow(balance, finances);
    } else {
      showErrorMessage(messageKey);
    }

    setIsLoading(false);
  };

  const mountCashFlow = (initialBalance, data) => {
    const momentDate = moment(filters.date);
    const days = range(1, momentDate.daysInMonth() + 1).map((day) =>
      momentDate.format(`YYYY-MM-${day.toString().padStart(2, '0')}`)
    );

    let balance = initialBalance;
    let income = 0;
    let expense = 0;

    const cashFlowCalculated = days.map((date) => {
      income += data[date].income;
      expense += data[date].expense;
      balance += income + expense;
      return {
        date: date,
        day: date.split('-').pop(),
        income: data[date].income,
        expense: data[date].expense,
        balanceValue: balance,
      };
    });

    setCashFlow(cashFlowCalculated);
    setTotalIncome(income);
    setTotalExpense(expense);

    setInitialBalance(initialBalance);
  };

  const calcBalances = (initialBalance) => {
    let balance = initialBalance;

    setCashFlow((current) =>
      current.map((dayTrades) => {
        balance += dayTrades.income + dayTrades.expense;
        return { ...dayTrades, balanceValue: balance };
      })
    );
  };

  const formatCashFlow = (cashFlow) => {
    return [
      ...cashFlow.map(({ income, expense, balanceValue, ...rest }) => ({
        ...rest,
        incomeFormatted: t('FormatFunction.intlCurrencyWithOptions', { value: income }).replace('R$', ''),
        expenseFormatted: t('FormatFunction.intlCurrencyWithOptions', { value: expense }).replace('R$', ''),
        balanceFormatted: t('FormatFunction.intlCurrencyWithOptions', {
          value: balanceValue,
        }).replace('R$', ''),
      })),
      {
        date: t('Total'),
        day: t('Total'),
        incomeFormatted: t('FormatFunction.intlCurrencyWithOptions', { value: totalIncome }).replace('R$', ''),
        expenseFormatted: t('FormatFunction.intlCurrencyWithOptions', {
          value: totalExpense,
        }).replace('R$', ''),
        balanceFormatted: '--',
      },
    ];
  };

  const clearMessage = () => {
    setMessage(null);
  };

  const showErrorMessage = (message) => {
    setMessage(message);
  };

  const fetchFinancesOnDay = async (date) => {
    modalRef.current.showModal();

    setIsLoadingFinancesOnDay(true);
    const { status, messageKey, finances } = await getSchoolFinancesOnDay({ schoolId, date });

    if (status === 'SUCCESS') {
      const financesMapped = finances.map((finance) => ({
        ...finance,
        student: finance.user_student?.name,
        client: finance.client_fornecedor?.name,
      }));
      setFinancesOnDay({
        income: financesMapped.filter(({ type }) => type === 'Receita'),
        expense: financesMapped.filter(({ type }) => type === 'Despesa'),
      });
    } else {
      showErrorMessage(messageKey);
    }

    setIsLoadingFinancesOnDay(false);
  };

  const handlePrint = useReactToPrint({
    documentTitle: `${filters.date} - ${t('CashFlowReportsPage.PageTitle')}`,
    content: () => tableRef.current,
    // pageStyle: css`
    //   .btn_card {
    //     display: none !important;
    //   }
    //   * {
    //     color: black !important;
    //   }
    //   .card {
    //     border: none !important;
    //   }
    // `,
    pageStyle: defaultCssStyleToPrint(),
  });

  const handleExportToXLS = async () => {
    const reportData = [
      {
        [t('Day')]: '--',
        [t('Revenue')]: '--',
        [t('Expense')]: '--',
        [t('Balance')]: initialBalance,
      },
      ...formatCashFlow(cashFlow).map((data) => ({
        [t('Day')]: data.date,
        [t('Revenue')]: data.incomeFormatted,
        [t('Expense')]: data.expenseFormatted,
        [t('Balance')]: data.balanceFormatted,
      })),
    ];

    return exportToXLS(t('CashFlowReportsPage.xlsFile'), reportData);
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel' style={{ minHeight: 'auto' }}>
              <div className='card' ref={tableRef}>
                <div className='card_header mb-2' style={{ alignItems: 'center', width: '100%' }}>
                  {!!message && (
                    <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                  )}
                </div>

                <div className='w-100 d-flex justify-content-end'>
                  <form onSubmit={fetchCashFlowReport} style={{ alignItems: 'center' }}>
                    <div
                      className='form_card row m-0'
                      style={{ flexDirection: 'row', width: '100%', justifyContent: 'end', rowGap: '0.5rem' }}
                    >
                      <div style={{ width: '210px' }}>
                        <InputRounded
                          type='month'
                          name='date'
                          value={filters}
                          onChange={onChangeFilters}
                          styleContainer={{ margin: 0 }}
                          style={{ height: '30px' }}
                          styleWrapper={{ borderColor: '#919191' }}
                        />
                      </div>

                      <div className='d-flex'>
                        <div className='spacer mr-1' />
                        <ButtonRounded type='submit' style={{ height: '30px', marginTop: 0 }}>
                          {t('Search')}
                        </ButtonRounded>

                        <div className='spacer mx-1' />

                        <ButtonRounded
                          type='button'
                          style={{ height: '30px', marginTop: 0 }}
                          onClick={handleExportToXLS}
                        >
                          {t('Export')}
                        </ButtonRounded>
                        <div className='spacer mr-1' />
                        <ButtonRounded type='button' style={{ height: '30px', marginTop: 0 }} onClick={handlePrint}>
                          {t('Print')}
                        </ButtonRounded>
                      </div>
                    </div>
                  </form>
                </div>

                <div className='list_card form_card'>
                  {isLoading ? (
                    <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                      <Loading type='1' />
                    </p>
                  ) : (
                    <TableBordered
                      onClickRow={(row) => row.day !== 'Total' && fetchFinancesOnDay(row.date)}
                      emptyTableMessage=''
                      staticHighlightRow={(row) => row.date === todayFormatted}
                      highlightOnHover
                      highlightRow={(row) => row.date === todayFormatted}
                      tooltipInRow={(row) => {
                        const selectedDate = moment(row.date);
                        const today = moment();

                        if (
                          today.format('YYYY-MM') === selectedDate.format('YYYY-MM') &&
                          selectedDate.format('YYYY-MM-DD') >= today.format('YYYY-MM-DD')
                        )
                          return 'REGRA: Com ou Sem baixa.';
                        else if (
                          today.format('YYYY-MM') === selectedDate.format('YYYY-MM') &&
                          selectedDate.format('YYYY-MM-DD') < today.format('YYYY-MM-DD')
                        )
                          return 'REGRA: Baixado(s).';
                        else return undefined;
                      }}
                      columns={[
                        { field: 'day', label: t('Day') },
                        { field: 'incomeFormatted', label: t('Revenue'), textAlign: 'end' },
                        { field: 'expenseFormatted', label: t('Expense'), textAlign: 'end' },
                        {
                          field: 'balanceFormatted',
                          label: (
                            <span className='d-flex justify-content-between align-items-end flex-column flex-sm-row'>
                              {t('BalanceMonthStart')}
                              <MoneyMask
                                style={{ marginBottom: '5px', height: '30px', textAlign: 'right' }}
                                value={initialBalance}
                                onChange={setInitialBalance}
                              />
                            </span>
                          ),
                          textAlign: 'end',
                        },
                      ]}
                      data={formatCashFlow(cashFlow)}
                    />
                  )}
                </div>
              </div>
            </main>

            <ModalCashFlow
              ref={modalRef}
              isLoading={isLoadingFinancesOnDay}
              income={financesOnDay.income}
              expense={financesOnDay.expense}
            />
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default CashFlowReports;
