import axios from 'axios';
import Api from '../utils/api';
import api from './api';

const baseURL = Api();

export const saveSchool = (school) =>
  new Promise((response, reject) => {
    const formData = new FormData();

    for (const key in school) {
      formData.append(key, school[key]);
    }

    const requestOptions = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post(baseURL + 'save-school', formData, requestOptions)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const updateSchool = (school) =>
  new Promise((response, reject) => {
    const formData = new FormData();

    for (const key in school) {
      console.log(`${key}: ${school[key]}`);
      formData.append(key, school[key]);
    }

    const requestOptions = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post(baseURL + 'update-school', formData, requestOptions)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const getSchoolBirthdays = async (schoolId) => {
  try {
    const { data } = await axios.post(baseURL + 'get-school-birthdays', { schoolId });
    return data;
  } catch (error) {
    return { success: false, error };
  }
};

export const sendManagerSignature = async ({ schoolId, image }) => {
  const formData = new FormData();
  formData.append('signature', image);
  formData.append('schoolId', schoolId);

  const requestOptions = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const { data } = await api.post('/save-school-signature', formData, requestOptions);
  return data;
};

export const deleteManagerSignature = async (schoolId) => {
  const { data } = await api.post(`/delete-school-signature`, { id: schoolId });
  return data;
};

export const getAllSchools = async (params) => {
  const { data } = await api.get('/admin/get-all-schools', { params });
  return data;
};
