import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import Header from '../../../components/school/Header';
import Footer from '../../../components/school/Footer';
import { useTranslation } from 'react-i18next';
import EditLessonPlanning from './EditLessonPlanning';
import {
  createLessonPlanning,
  getAllLessonsPlanning,
  updateLessonPlanning,
  updateSchoolLessonPlanningStatus,
} from '../../../services/LessonPlanningService';
import Message from '../../../components/Message';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import Loading from '../../../components/Loading';
import { useReactToPrint } from 'react-to-print';
import { fetchLessonSession } from '../../../services/LessonSession';
import { Link, useHistory } from 'react-router-dom';
import { handleOnChange } from '../../../utils/utils';
import { BsChevronDown, BsDownload, BsEye, BsFillPencilFill } from "react-icons/bs";
import ReactTooltip from 'react-tooltip';
import { UserContext } from '../../../Context/UserContext';

const Class = () => {
  const { t } = useTranslation();
  const { action, setContextData } = useContext(UserContext);
  const history = useHistory();
  const [filters, setFilters] = useState({
    name: '',
    source: 'A',
    status: 'A',
  });
  const [lesson, setLesson] = useState([])
  const [lessonsLibrary, setLessonsLibrary] = useState([]);
  const [lessonsPlanning, setLessonsPlanning] = useState([]);
  const [lessonsPlanningFiltered, setLessonsPlanningFiltered] = useState([]);
  const [lessonPlanning, setLessonPlanning] = useState({
    id: undefined,
    name: '',
    status: 'A',
    classNumber: '',
    objective: '',
    category_id: 0,
  });
  const [dropDownStatus, setDropDownStatus] = useState([]);
  const [onlyView, setOnlyView] = useState(false)
  const [sessions, setSessions] = useState([]);

  // process
  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  // libraries
  const editLessonPlanningRef = useRef();
  const planningPdfRef = useRef();
  const alertRef = useRef();

  const ref = useOutsideClick(() => {
    setDropDownStatus(dropDownStatus?.map((value) => ({ ...value, status: false })));
  });

  const { MessageDangerMarginTop } = Message();

  useEffect(() => {
    if (action?.action === 'search') {
      setFilters({
        concept: action.term,
        status: action.status
      });
      fetchLessonsPlanning().then((response) => {
        setLessonsPlanningFiltered(
          response?.filter((planning) => action?.status === 'T' || planning.status === action?.status)
            .filter((planning) => planning.name?.toUpperCase()?.includes(action?.term?.toUpperCase())));
      }).catch((e) => console.log(e));
    } else {
      fetchLessonsPlanning();
    }
    getLessonSession();
    action?.action === 'openAdd' && openAddLessonPlanning();
    setContextData({ action: {} });

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      editLessonPlanningRef.current.closeModal();
    }
  };

  const fetchLessonsPlanning = async () => {
    clearMessage();
    setIsLoading(true);
    const { status, lessonsPlanning, lessons, messageKey } = await getAllLessonsPlanning({ date: undefined });
    setIsLoading(false);

    if (status === 'SUCCESS') {
      setLessonsPlanning(lessonsPlanning);
      setLessonsPlanningFiltered(lessonsPlanning);
      setLessonsLibrary(lessons);
      setDropDownStatus(lessonsPlanning?.map((planning) => ({ id: planning.id, status: false })));
      return lessonsPlanning;
    } else {
      setErrorMessage(messageKey);
    }
  };

  const getLessonSession = async () => {
    const response = await fetchLessonSession();
    setSessions(response.sessions)
  }

  const handleSubmitLessonPlanning = async (formData) => {
    clearMessage();
    setIsLoading(true);

    const {
      messageKey,
      status,
      lessonsPlanning: lessonsPlanningUpdated,
    } = formData.id ? await updateLessonPlanning(formData) : await createLessonPlanning(formData);
    setIsLoading(false);

    if (status === 'SUCCESS') {
      setLessonsPlanning(lessonsPlanningUpdated);
      setLessonsPlanningFiltered(lessonsPlanningUpdated);
      return {
        messageKey,
        status,
      };
    } else {
      return {
        messageKey,
        status,
      };
    }
  };

  const configLesson = (value) => {
    setLessonPlanning({
      id: value.id,
      name: value.name,
      status: value.status,
      objective: value.objective,
      classNumber: value.classNumber,
      category_id: value?.categories[0]?.id,
      lessons: value?.lessons?.filter((lesson) => lesson?.school_id),
      lessonsClub: value?.lessons?.filter((lesson) => lesson?.company_id),
      lessonsAdmin: value?.lessons?.filter((lesson) => lesson?.admin_id),
      categories: value?.categories,
      clubLesson: value?.lesson_planning,
    });
  }

  const openEditLesson = (value, view = false) => {
    configLesson(value);
    setOnlyView(view);
    editLessonPlanningRef.current.showModal();
  };

  const openAddLessonPlanning = () => {
    setLessonPlanning({
      id: undefined,
      name: '',
      status: 'A',
      classNumber: '',
      objective: '',
      category_id: 0,
    });
    setOnlyView(false);
    editLessonPlanningRef.current.showModal();
  };

  const setErrorMessage = (message) => {
    setMessage(message);
    setError(true);
  };

  const clearMessage = () => {
    setError(false);
    setMessage('');
  };

  const toPdf = (value) => {
    Promise.all([
      setLessonPlanning({
        id: value.id,
        name: value.name,
        status: value.status,
        objective: value.objective,
        classNumber: value.classNumber,
      }),
      setLesson(value?.lessons?.filter((value) => value?.id)?.map((lesson) => {
        const find = lessonsLibrary?.find((l) => l.id === lesson.id);
        const lessonSessionLabel = sessions?.find((session) => session.id === lesson.lesson_session_id)?.name;
        if (find) {
          return {
            ...find,
            lessonSessionLabel,
          }
        }
      })),
    ]).then(() => {
      handlePrint();
    })
  }

  const handlePrint = useReactToPrint({
    content: () => planningPdfRef.current
  })

  const updateByStatus = async (values) => {
    const data = {
      id: values.id,
      status: values.status,
    }

    const {
      status,
      lessonsPlanning,
      lessons,
      message,
    } = await updateSchoolLessonPlanningStatus(data);

    if (status === 'SUCCESS') {
      setLessonsPlanning(lessonsPlanning);
      setLessonsPlanningFiltered(lessonsPlanning);
      setLessonsLibrary(lessons);
      setDropDownStatus(lessonsPlanning?.map((planning) => ({ id: planning.id, status: false })));
    } else {
      setErrorMessage(message);
    }
  }

  const search = (e) => {
    e && e.preventDefault();

    if (filters.source === 'T' || filters.source === 'M') {
      setContextData({
        action: {
          action: 'search',
          term: filters.name,
          status: filters.status
        }
      })
      filters.source === 'T' ? history.push('/school-lessons', { replace: true }) : history.push('/school-microcycle', { replace: true });
      return;
    }

    setLessonsPlanningFiltered(
      lessonsPlanning.filter((planning) => filters.status === 'T' || planning.status === filters.status)
        .filter((planning) => planning.name?.toUpperCase()?.includes(filters.name.toUpperCase())));
  };

  const onChangeFilters = handleOnChange(setFilters);

  const categoriesSelectedList = useCallback((value) => {
    return (
      <div className='d-flex align-items-center'>
        {value?.sort((a, b) => a.name - b.name)?.map((category) => {
          return (
            <div key={category?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span>{category?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [])
  const contentSportsSelectedList = useCallback((value) => {
    return (
      <div className='d-flex align-items-center'>
        {value?.sort((a, b) => a - b)?.map((contentSport) => {
          return (
            <div key={contentSport?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span>{contentSport?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [])
  const didacticStrategySelectList = useCallback((value) => {
    return (
      <div className='d-flex align-items-center'>
        {value?.sort((a, b) => a - b)?.map((didactic) => {
          return (
            <div key={didactic?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span>{didactic?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [])
  const materialsSelectedList = useCallback((value) => {
    return (
      <div className='d-flex align-items-center'>
        {value?.sort((a, b) => a - b)?.map((material) => {
          return (
            <div key={material?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <span>{material?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [])

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <Header />
            <main className='content_panel'>
              <div className='d-flex flex-row flex-1'>
                <div className='d-flex flex-column card mr-3'>
                  <div className='card_header'>
                    <h2 className='card_title'>Tarefas</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar tarefa.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'school-lessons'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={() => {
                      setContextData({
                        action: {
                          action: 'openAdd'
                        }
                      })
                      history.push('/school-lessons', { replace: true });
                    }} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-column card mr-3'>
                  <div className='card_header'>
                    <h2 className='card_title'>Aulas</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar uma aula.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'school-lessons-planning'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={openAddLessonPlanning} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-column card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Microciclo</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar um microciclo.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'school-microcycle'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={() => {
                      setContextData({
                        action: {
                          action: 'openAdd'
                        }
                      })
                      history.push('/school-microcycle', { replace: true });
                    }} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card_header'>
                  <h2 className='card_title'>Pesquisar</h2>
                  <p className='card_subtitle'>Busque abaixo por tarefas, aulas ou metodologias.</p>
                </div>
                <form action='' method='post' className='form_card'>
                  <div className='d-flex flex-row align-items-center row'>
                    <div className='col-sm-3'>
                      <input
                        required
                        type='text'
                        className='form_control mr-3'
                        name='name'
                        placeholder='Nome'
                        value={filters.name}
                        onChange={onChangeFilters}
                      />
                    </div>
                    <div className='col-sm-3'>
                      <select
                        required
                        className='form_control select mr-3'
                        name='source'
                        value={filters.source}
                        onChange={onChangeFilters}
                      >
                        <option disabled={true}>Selecionar</option>
                        <option value='T'>Tarefas</option>
                        <option value='A'>Aulas</option>
                        <option value='M'>Microciclo</option>
                      </select>
                    </div>
                    <div className='col-sm-3'>
                      <select
                        required
                        className='form_control select mr-3'
                        name='status'
                        value={filters.status}
                        onChange={onChangeFilters}
                      >
                        <option disabled={true}>Status</option>
                        <option value='T'>Tudo</option>
                        <option value='A'>Ativo</option>
                        <option value='I'>Inativo</option>
                      </select>
                    </div>
                    <div className='col-sm-3 my-auto'>
                      <button className='btn-secondary-color rounded-pill' onClick={search}>
                        Buscar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Aulas</h2>
                    <p className='card_subtitle'>Veja abaixo as aulas cadastradas</p>
                    {error && <MessageDangerMarginTop ref={alertRef} title='Atenção! ' description={t(message)} />}
                  </div>
                  <div className='list_card'>
                    <div className='d-flex justify-content-between flex-1 font-weight-bold' style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                      <div className='d-flex'>
                        <div style={{ width: '360px', marginRight: '5px' }}>&nbsp;</div>
                        <div style={{ width: '120px' }}>&nbsp;</div>
                        <div style={{ width: '120px', textAlign: 'center' }}>Ordem</div>
                        <div style={{ width: '120px', textAlign: 'center' }}>Categoria</div>
                      </div>
                      <div style={{ width: '120px', textAlign: 'center' }}>Ações</div>
                    </div>
                    {isLoading && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!isLoading &&
                      lessonsPlanningFiltered.map((planning) => {
                        const status = dropDownStatus?.find((value) => value.id === planning.id && value.status);
                        return (
                          <div className='card_list_turma' key={`${planning.id}`}>
                            <div className='content_card'>
                              <div className='card_details_alternative'>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <p className='card_details_label text_uppercase my-auto' style={{ width: '360px' }}>
                                    <span style={{ color: '#3b3b3b' }}>{planning.name}</span>
                                  </p>
                                  <div ref={ref} style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '120px' }}>
                                    <div
                                      onClick={() => setDropDownStatus(dropDownStatus?.map((value) => (value.id === planning.id ? { ...value, status: !value.status } : { ...value })))}
                                      className={`${planning.status === 'A' ? 'badge badge-success' : 'badge badge-danger'} badge-button`}
                                      style={status ? { borderRadius: 8, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { borderRadius: 8, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
                                    >
                                      {planning.status === 'A' ? 'Ativo' : 'Inativo'}
                                      <div style={{ marginLeft: 10 }}><BsChevronDown color='#FFFFFF' /></div>
                                    </div>
                                    <div style={{ opacity: status ? 1 : 0 }} className='dropdown-shadow'>
                                      <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...planning, status: 'A' })}>Ativo</div>
                                      <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...planning, status: 'I' })}>Inativo</div>
                                    </div>
                                  </div>
                                  <div style={{ textAlign: 'center', width: '120px' }}>{planning?.classNumber}</div>
                                  <div style={{ textAlign: 'center', width: '120px' }} data-for={`lessonCategories-${planning?.id}`} data-tip>
                                    {planning?.categories[0]?.name}
                                  </div>
                                  <ReactTooltip id={`lessonCategories-${planning?.id}`}>
                                    {planning?.categories?.map((category, key, array) => `${category.name} ${key !== (array?.length - 1) ? ' | ' : ''}`)}
                                  </ReactTooltip>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 12, width: '120px', justifyContent: 'center' }}>
                                  <span onClick={() => openEditLesson(planning)}><BsFillPencilFill /></span>
                                  <span onClick={() => openEditLesson(planning, true)}><BsEye /></span>
                                  <span onClick={() => toPdf(planning)}><BsDownload /></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      )}
                  </div>
                </div>
              </div>
              <EditLessonPlanning ref={editLessonPlanningRef} lessonPlanning={lessonPlanning} onSubmit={handleSubmitLessonPlanning} onlyView={onlyView} />
              <div style={{ display: 'none' }}>
                <div ref={planningPdfRef}>
                  {lesson?.map((value) => {
                    return (
                      <div key={value?.id} className='m-5' style={{ height: '100vh' }}>
                        <div className='d-flex flex-1 flex-column justify-content-center align-items-center'>
                          <img src="../../img/logo.png" alt="Marca" />
                          <h4 className='mt-3'>{lessonPlanning?.name}</h4>
                          <h5 className='mt-1'>
                            {value?.concept}
                          </h5>
                        </div>
                        <div className='row'>
                          <div className='col-sm-9'>
                            <span style={{ fontWeight: 'bold' }}>Categorias</span>
                            {categoriesSelectedList(value?.lesson_categories)}
                          </div>
                          <div className='col-sm-3'>
                            <span style={{ fontWeight: 'bold' }}>Status: </span>
                            {value?.status === 'A' ? 'Ativo' : 'Inativo'}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-9'>
                            <span style={{ fontWeight: 'bold' }}>
                              Conteúdo
                            </span>
                            {contentSportsSelectedList(value?.lesson_content_sports)}
                          </div>
                          <div className='col-sm-3'>
                            <span style={{ fontWeight: 'bold' }}>Espaço: </span>
                            {value?.space}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-6'>
                            <span style={{ fontWeight: 'bold' }}>
                              Estratégia didática
                            </span>
                            {didacticStrategySelectList(value?.lesson_didactic_strategies)}
                          </div>
                          <div className='col-sm-3'>
                            <span style={{ fontWeight: 'bold' }}>Tempo: </span>
                            {value?.time}
                          </div>
                          <div className='col-sm-3'>
                            <span style={{ fontWeight: 'bold' }}>Sessão da aula/treino: </span>
                            {value?.lessonSessionLabel}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-9'>
                            <span style={{ fontWeight: 'bold' }}>
                              Material
                            </span>
                            {materialsSelectedList(value?.lesson_materials)}
                          </div>
                          <div className='col-sm-3'>
                            <span style={{ fontWeight: 'bold' }}>N. de jogadores: </span>
                            {value?.numberOfPlayers}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-12'>
                            <div style={{ fontWeight: 'bold' }}>Descrição</div>
                            {value?.description}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-6'>
                            <div style={{ fontWeight: 'bold' }}>Regras</div>
                            {value?.rules}
                          </div>
                          <div className='col-sm-6'>
                            <div style={{ fontWeight: 'bold' }}>Pontuação</div>
                            {value?.score}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-6'>
                            <div style={{ fontWeight: 'bold' }}>Variação</div>
                            {value?.variation}
                          </div>
                          <div className='col-sm-6'>
                            <div style={{ fontWeight: 'bold' }}>Feedback</div>
                            {value?.feedback}
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-sm-12'>
                            <div style={{ fontWeight: 'bold' }}>Exemplo</div>
                            <div className='border p-3 rounded-lg mt-3 d-flex justify-content-center'>
                              <img src={value?.artUrl} width='100%' alt='' />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Class;
