import React, { useEffect, useState, useRef } from 'react';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Utils, { correctImageOrientation } from '../../../src/utils/utils';
import LoadingTheme from '../../components/LoadingTheme';
import { useTranslation } from 'react-i18next';
import {
  getStates,
  getPositionsSchool,
  getQuestionnaires,
  getCitysByStateSigla,
  verifyLogin,
  verifyCpfSchool,
  verifyEmailSchool,
  searchByZipCodeCountry,
} from '../../services/SharedService';
import {
  saveStudentLinkSchool,
  updateStudentLinkSchool,
  getThemeBySchool,
  uploadDocumentStudent,
} from '../../services/StudentService';
import { languages } from '../../i18nextConf';
import { useQuery } from '../../utils/custom-react-hook';
import { APIError } from '../../services/ExceptionClass/APIError';
import CpfCnpjMask from '../../components/inputs/CpfCnpjMask';
import PhoneMask from '../../components/inputs/PhoneMask';
import CEPMask from '../../components/inputs/CEPMask';
import { validateCPF, validateRG } from '../../utils/validators';
import AddDocumentsModal from '../../components/athlete/AddDocumentsModal';
import SweetAlert from 'react-bootstrap-sweetalert';

const StudentLinkCreate = (props) => {
  const { t, i18n } = useTranslation();
  const [showUploadDocument, setShowUploadDocument] = useState(false);
  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [rg, setRG] = useState('');
  const [cpf, setCPF] = useState('');
  const [birth, setBirth] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCEP] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [estado, setEstado] = useState('');
  const [nomeMae, setNomeMae] = useState('');
  const [nomePai, setNomePai] = useState('');
  const [cpfMae, setCPFMae] = useState('');
  const [cpfPai, setCPFPai] = useState('');
  const [phoneMae, setPhoneMae] = useState('');
  const [phonePai, setPhonePai] = useState('');
  const [posicao, setPosicao] = useState('');
  const [lateralidade, setLateralidade] = useState('');
  const [sobre, setSobre] = useState('');
  const [city_id, setCityID] = useState('');
  const [id, setID] = useState(null);
  const [athleteId, setAthleteId] = useState(null);
  const [school_id] = useState(props.match.params.school_id);
  const [companie_id] = useState(props.match.params.companie_id);
  const [foto, setFoto] = useState('');
  const [newPhoto, setNewPhoto] = useState(null);
  const [nomeRespFin, setNomeRespFin] = useState('');
  const [rgRespFin, setRGRespFin] = useState('');
  const [cpfRespFin, setCPFRespFin] = useState('');
  const [emailRespFin, setEmailRespFin] = useState('');
  const [parentesco, setParentesco] = useState('');
  const [phoneRespFin, setPhoneRespFin] = useState('');
  const [questionnaire, setQuestionnaire] = useState('');
  const [sexo, setSexo] = useState('');
  const [trainingTime, setTrainingTime] = useState(new Set([]));
  const [location, setLocation] = useState(i18n.language);

  // carregamento
  const [states, setStates] = useState([]);
  const [citys, setCitys] = useState([]);
  const [positions, setPositions] = useState([]);
  const [sports, setSports] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);

  // process
  const [sendCity, setSendingCity] = useState(false);
  const [sendCep, setSendingCep] = useState(false);
  const [sendSave, setSendSave] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendCPF, setSendCPF] = useState(false);
  const [schoolTheme, setSchoolTheme] = useState(null);
  const [tipoEsporte, setTipoEsporte] = useState('');

  // messages
  const [showMessage, setShowMessage] = useState(false)
  const [messageType, setMessageType] = useState('');
  const [message, setMessage] = useState('');

  const [athleteDocuments, setAthleteDocuments] = useState([]);
  // libraries
  const { scrollTop } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  let modalScroll = useRef(window);
  const query = useQuery();
  const classId = query.get('c');
  const agesAllowed =
    query
      .get('a')
      ?.split(';')
      .filter((a) => a) || [];

  useEffect(() => {
    async function fetchPositions(id) {
      const { positions, sports } = await getPositionsSchool({ school_id: id });
      setPositions(positions);
      setSports(sports);
    }

    async function fetchQuestionnaires() {
      const { questionnaire } = await getQuestionnaires(companie_id);
      setQuestionnaires(questionnaire);
    }

    fetchStates();
    fetchPositions(school_id);
    fetchQuestionnaires();

    getThemeBySchool(school_id).then((res) => {
      setSchoolTheme(res);
    });

    URL.revokeObjectURL(foto);

    i18n.changeLanguage(query.get('l') || 'pt-BR');
  }, []);

  useEffect(() => {
    fetchStates();
  }, [location]);

  const fetchStates = async () => {
    const res = await getStates(location);
    setStates(res.states);
  };

  const getCitys = async (estado, localidade = '') => {
    setCitys([]);
    setEstado(estado);
    setSendingCity(true);
    const res = await getCitysByStateSigla(estado, location);
    setSendingCity(false);

    for (let i = 0; i < res.cidades.length; i++) {
      let citCity = res.cidades[i].citCity;
      if (citCity == localidade) {
        setCityID(res.cidades[i].id);
      }
    }

    setCitys(res.cidades);
  };

  const buscaCEP = async () => {
    if (cep.length) {
      setSendingCep(true);
      const { success, streetName, neighborhood, states, state, cities, city } = await searchByZipCodeCountry(
        cep,
        location
      );
      setSendingCep(false);

      if (!success) {
        setEndereco('');
        setBairro('');
        setEstado('');
        setCityID('');
      } else {
        setEndereco(streetName);
        setBairro(neighborhood);
        setStates(states);
        setEstado(state);

        setCitys(cities);
        setCityID(city.id);
      }
    }
  };

  const verifyCPF = async (email) => {
    if (id == null) {
      setSendCPF(true);
      const res = await verifyCpfSchool({ cpf: cpf, schoolId: school_id });
      setSendCPF(false);

      if (res.error !== false) {
        setCPF('');
        setErrorMessage('SystemMessage.Fail.' + res.messageKey);
      } else {
        clearMessage();
        setCPF(email);
      }
    }
  };

  const verifyEmail = async (email) => {
    // Comentando validação de e-mail cadastrado na mesma escola.
    // Agora e-mail pode ser cadastrado para mais de um aluno na mesma escola
    // if (id == null) {
    //   setSendEmail(true);
    //   const res = await verifyEmailSchool({ email: email, schoolId: school_id });
    //   setSendEmail(false);
    //   if (res.error !== false) {
    //     setEmail('');
    //     setErrorMessage('SystemMessage.Fail.' + res.messageKey);
    //   } else {
    //     clearMessage();
    //     setEmail(email);
    //   }
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();

    if (!validateCPF(cpf, i18n.language)) {
      setErrorMessage('StudentPage.SystemMessage.InvalidStudentCPF');
    } else if (!validateRG(rg, i18n.language)) {
      setErrorMessage('StudentPage.SystemMessage.StudentRGExceedMaxSize');
    } else if (!validateCPF(cpfRespFin, i18n.language)) {
      setErrorMessage('StudentPage.SystemMessage.InvalidResponsibleCPF');
    } else if (!validateRG(rgRespFin, i18n.language)) {
      setErrorMessage('StudentPage.SystemMessage.ResponsibleRGExceedMaxSize');
    } else if (classId && agesAllowed.length > 0 && !agesAllowed.includes(birth.split('-').shift())) {
      setErrorMessage('StudentPage.SystemMessage.StudentAgeNotAllowed', { value: agesAllowed });
    } else {
      const body = {
        school_id,
        name,
        login,
        athRG: rg,
        usuCPF: cpf,
        usuBirth: birth,
        usuPhone: phone,
        email: email,
        athCep: cep,
        athLogradouro: endereco,
        athNumero: numero,
        athComplemento: complemento,
        athBairro: bairro,
        athMaeNome: nomeMae,
        athPaiNome: nomePai,
        athMaeCPF: cpfMae,
        athPaiCPF: cpfPai,
        athMaeCelular: phoneMae,
        athPaiCelular: phonePai,
        position_id: posicao,
        athLateralidade: lateralidade,
        usuSobre: sobre,
        city_id,
        usuStatus: 'P',
        athNomeRespFin: nomeRespFin,
        athRGRespFin: rgRespFin,
        athCPFRespFin: cpfRespFin,
        athPhoneRespFin: phoneRespFin,
        athEmailRespFin: emailRespFin,
        athParentescoRespFin: parentesco,
        athTipoStudent: 'Regular',
        questionnaire_id: questionnaire,
        usuGender: sexo,
        sport_id: tipoEsporte,
        novaFoto: newPhoto,
      };

      try {
        setSendSave(true);

        const { student, messageKey, dir_usuFotoPrincipal } =
          id === null
            ? await saveStudentLinkSchool(
                {
                  companie_id,
                  role_id: 2,
                  usuStudent: 'S',
                  password: body.athEmail,
                  classId: classId,
                  ...body,
                },
                'full'
              )
            : await updateStudentLinkSchool(
                {
                  id,
                  ...body,
                },
                'full'
              );

        setSuccessMessage(messageKey);
        setID(student.user.id);
        setAthleteId(student.athlete.id);

        if (newPhoto) {
          URL.revokeObjectURL(foto);
          setNewPhoto(null);
          setFoto(dir_usuFotoPrincipal + '/' + student.user.usuFotoPrincipal);
        }
        if (athleteDocuments.length) {
          await Promise.all(
            athleteDocuments.map((document) => {
              uploadDocumentStudent({
                athleteID: student.athlete.id,
                documentID: document.idDocument,
                file: document.file,
                athletedocument_id: null,
              });
            })
          );
          setAthleteDocuments([]);
        }
      } catch (error) {
        if (error instanceof APIError) setErrorMessage(error.messageKey || error.message, error.extra);
        else setErrorMessage('SystemMessage.Fail.ErrorRetry');
      } finally {
        setSendSave(false);
      }
    }
  };

  const handleFoto = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const newPhoto = await correctImageOrientation(file);

      URL.revokeObjectURL(foto);
      setFoto(URL.createObjectURL(newPhoto));
      setNewPhoto(newPhoto);
    }
  };
  const handlerValidateLogin = async (e) => {
    e.preventDefault();

    const res = await verifyLogin({ login: e.target.value, id: id });
    if (res.error === true) {
      //e.target.setCustomValidity(res.messageKey)
      setErrorMessage('SystemMessage.Fail.' + res.messageKey);
    } else {
      //e.target.setCustomValidity('')
      clearMessage();
    }
  };

  const setErrorMessage = (message, extra = null) => {
    if (extra) setMessage({ text: message, extra });
    else setMessage(message);
    
    setMessageType('error')
    setShowMessage(true);
  };

  const setSuccessMessage = (message, extra = null) => {
    if (extra) setMessage({ text: message, extra });
    else setMessage(message);

    setMessageType('success')
    setShowMessage(true)
  };

  const clearMessage = () => {
    setShowMessage(false)
    setMessage('');
    setMessageType('')
  };

  const toggleTrainingTime = (event) => {
    const aux = new Set([...trainingTime]);
    event.target.checked ? aux.add(event.target.value) : aux.delete(event.target.value);
    setTrainingTime(aux);
  };

  return (
    <main>
      <LoadingTheme schoolTheme={schoolTheme} />
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <header className='col-12' style={{ paddingLeft: 0, marginBottom: 40 }}>
              <div className='row header' style={{ margin: 0, padding: 0 }}>
                <img
                  src='../../../img/clubes/fensor.svg'
                  className={`logo ${companie_id == 175 ? 'ml-auto mr-0' : ''}`}
                  alt=''
                />
              </div>
            </header>

            <main className='content_panel'>
              <div className='sidebar' style={{ flex: 1, maxWidth: 'none' }}>
                <div className='card'>
                  <div className='card_header' style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div>
                        <h2 className='card_title'>{t('StudentPage.ExternalLink.Title')}</h2>
                        <p className='card_subtitle'>{t('StudentPage.ExternalLink.Subtitle')}</p>
                      </div>

                      <div>
                        <select
                          className='login_link select-link text-transform-none'
                          value={i18n.resolvedLanguage}
                          onChange={(e) => i18n.changeLanguage(e.target.value)}
                          style={{ marginTop: '5px', width: 'auto' }}
                        >
                          {Object.keys(languages).map((language) => (
                            <option key={language} value={language}>
                              {languages[language].nativeName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <form action='' onSubmit={handleSubmit} method='post' className='form_modal'>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label flex-row required'>
                          {t('Name')}
                        </label>
                        <input
                          type='text'
                          required
                          className='form_control'
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='login' className='card_details_label flex-row required'>
                          {t('Login')}
                        </label>
                        <input
                          type='text'
                          required
                          className='form_control'
                          value={login}
                          onChange={(e) => setLogin(e.target.value)}
                          onBlur={handlerValidateLogin}
                        />
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='rg' className='card_details_label'>
                            {t('RG')}
                          </label>
                          <input
                            type='text'
                            className='form_control'
                            value={rg}
                            onChange={(e) => setRG(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='cpf' className='card_details_label flex-row'>
                            {t('CPF')}
                            {sendCPF && <Loading type='1' style={{ marginLeft: '2%' }} />}
                          </label>
                          <CpfCnpjMask value={cpf} onChange={setCPF} onBlur={() => verifyCPF(cpf)} mode='CPF' />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='birth' className='card_details_label flex-row required'>
                            {t('BirthDate')}
                          </label>
                          <input
                            type='date'
                            required
                            className='form_control'
                            value={birth}
                            onChange={(e) => setBirth(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='phone' className='card_details_label'>
                            {t('CellPhone')}
                          </label>
                          <PhoneMask value={phone} onChange={setPhone} />
                        </div>
                      </div>
                      <div className='form_group'>
                        <label htmlFor='email' className='card_details_label flex-row'>
                          {t('Email')}
                          {sendEmail && <Loading type='1' style={{ marginLeft: '2%' }} />}
                        </label>
                        <input
                          type='email'
                          className='form_control'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={(e) => verifyEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    {foto !== '' ? (
                      <div className='group_50' style={{ justifyContent: 'space-around' }}>
                        <img
                          style={{
                            borderRadius: 10,
                            marginBottom: 10,
                            width: '90%',
                          }}
                          src={foto}
                        />
                        <label
                          style={{
                            cursor: 'pointer',
                            padding: '12px 22px',
                            borderRadius: '50px',
                            border: '1px solid grey',
                            color: 'grey',
                            fontSize: '13px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                          }}
                        >
                          <input type='file' name='file[]' onChange={(e) => handleFoto(e)} />
                          Alterar foto
                        </label>
                      </div>
                    ) : (
                      <div className='group_50 upload_file'>
                        <label htmlFor='file'></label>
                        <input
                          type='file'
                          name='file[]'
                          id='file'
                          onChange={(e) => handleFoto(e)}
                          style={{
                            display: 'initial',
                            opacity: 0,
                            cursor: 'pointer',

                            position: 'relative',
                            top: '-52px',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </div>
                    )}
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='parentesco' className='card_details_label'>
                            {t('SportType')}
                          </label>
                          <select
                            className='form_control select'
                            value={tipoEsporte}
                            onChange={(e) => setTipoEsporte(e.target.value)}
                          >
                            <option value='' disabled hidden></option>
                            {sports.map((sport) => (
                              <option key={'tipo-esporte' + sport.id} value={sport.id}>
                                {t('Sports.Name.' + sport.label)}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='parentesco' className='card_details_label'>
                            {t('Position')}
                          </label>
                          <select
                            className='form_control select'
                            value={posicao}
                            onChange={(e) => setPosicao(e.target.value)}
                          >
                            <option value='' disabled hidden></option>
                            {positions
                              .filter((position) => position.sport_id == tipoEsporte)
                              .sort((a, b) => a.posOrdem - b.posOrdem)
                              .map((position) => (
                                <option key={'posicao-' + position.id} value={position.id}>
                                  {t('Sports.Position.' + position.posPosition)}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='how' className='card_details_label'>
                            {t('BiologicalSex')}
                          </label>
                          <select
                            className='form_control select'
                            value={sexo}
                            onChange={(e) => setSexo(e.target.value)}
                          >
                            <option value='' disabled hidden></option>
                            <option value='F'>{t('Feminine')}</option>
                            <option value='M'>{t('Masculine')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='how' className='card_details_label'>
                            {t('HowKnowSchool')}
                          </label>
                          <select
                            className='form_control select'
                            value={questionnaire}
                            onChange={(e) => setQuestionnaire(e.target.value)}
                          >
                            <option value=''></option>
                            {questionnaires.map(
                              (item) =>
                                item.status === 'A' && (
                                  <option value={item.id} key={item.id}>
                                    {t(item.name)}
                                  </option>
                                )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='position' className='card_details_label'>
                            {t('Laterality')}
                          </label>
                          <select
                            onChange={(e) => setLateralidade(e.target.value)}
                            value={lateralidade}
                            className='form_control select'
                          >
                            <option value='' disabled></option>
                            <option value='D'>{t('RightHanded')}</option>
                            <option value='C'>{t('LeftHanded')}</option>
                            <option value='A'>{t('Ambidextrous')}</option>
                          </select>
                        </div>
                      </div>

                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('TrainingTime')}</label>
                          <div style={{ height: '50px', marginTop: '5px' }}>
                            <label className='form_control checkbox'>
                              <input
                                type='checkbox'
                                value='MANHA'
                                checked={trainingTime.has('MANHA')}
                                onChange={toggleTrainingTime}
                              />
                              {t('Morning')}
                            </label>
                            <label className='form_control checkbox'>
                              <input
                                type='checkbox'
                                value='TARDE'
                                checked={trainingTime.has('TARDE')}
                                onChange={toggleTrainingTime}
                              />
                              {t('Evening')}
                            </label>
                            <label className='form_control checkbox'>
                              <input
                                type='checkbox'
                                value='NOITE'
                                checked={trainingTime.has('NOITE')}
                                onChange={toggleTrainingTime}
                              />
                              {t('Night')}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='tel' className='card_details_label'>
                          {t('Location')}
                        </label>
                        <select
                          className='form_control select'
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        >
                          <option value={'pt-BR'}>BRA</option>
                          <option value={'en-US'}>USA</option>
                          <option value={'en-US-Arabic'}>USA-ARABIC</option>
                        </select>
                      </div>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label flex-row'>
                          {t('CEP')}
                          {sendCep && <Loading type='1' style={{ marginLeft: '2%' }} />}
                        </label>
                        <CEPMask
                          maskLocation={location === 'pt-BR' ? '#####-###' : '#####'}
                          value={cep}
                          onChange={setCEP}
                          onBlur={buscaCEP}
                        />
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            {t('State')}
                          </label>
                          <select
                            className='form_control select'
                            value={estado}
                            onChange={(e) => getCitys(e.target.value, '')}
                          >
                            <option value=''>-- {t('Select')} --</option>
                            {states.map((item) => (
                              <option value={item.staStateSigla} key={item.staState}>
                                {item.staState}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label flex-row'>
                            {t('City')}
                            {sendCity && <Loading type='1' style={{ marginLeft: '2%' }} />}
                          </label>
                          <select
                            className='form_control select'
                            value={city_id}
                            onChange={(e) => setCityID(e.target.value)}
                          >
                            <option value=''>-- {t('Select')} --</option>
                            {citys.map((item) => (
                              <option value={item.id} key={item.citCity}>
                                {item.citCity}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label'>
                            {t('Address')}
                          </label>
                          <input
                            type='text'
                            value={endereco}
                            className='form_control'
                            onChange={(e) => setEndereco(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label'>
                            {t('Number')}
                          </label>
                          <input
                            type='text'
                            value={numero}
                            className='form_control'
                            onChange={(e) => setNumero(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label'>
                            {t('Complement')}
                          </label>
                          <input
                            type='text'
                            value={complemento}
                            className='form_control'
                            onChange={(e) => setComplemento(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            {t('Neighborhood')}
                          </label>
                          <input
                            type='text'
                            value={bairro}
                            className='form_control'
                            onChange={(e) => setBairro(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          {t('MotherName')}
                        </label>
                        <input
                          type='text'
                          className='form_control'
                          value={nomeMae}
                          onChange={(e) => setNomeMae(e.target.value)}
                        />
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='document' className='card_details_label'>
                            {t('MotherCPF')}
                          </label>
                          <CpfCnpjMask value={cpfMae} onChange={setCPFMae} mode='CPF' />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            {t('MotherCellPhone')}
                          </label>
                          <PhoneMask value={phoneMae} onChange={setPhoneMae} />
                        </div>
                      </div>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          {t('FatherName')}
                        </label>
                        <input
                          type='text'
                          className='form_control'
                          value={nomePai}
                          onChange={(e) => setNomePai(e.target.value)}
                        />
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='document' className='card_details_label'>
                            {t('FatherCPF')}
                          </label>
                          <CpfCnpjMask value={cpfPai} onChange={setCPFPai} mode='CPF' />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            {t('FatherCellPhone')}
                          </label>

                          <PhoneMask value={phonePai} onChange={setPhonePai} />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label flex-row required'>
                          {t('FinancialResponsibleName')}
                        </label>
                        <input
                          type='text'
                          className='form_control'
                          value={nomeRespFin}
                          required
                          onChange={(e) => setNomeRespFin(e.target.value)}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='descript' className='card_details_label flex-row required'>
                          {t('FinancialResponsibleEmail')}
                        </label>
                        <input
                          required
                          type='email'
                          className='form_control'
                          value={emailRespFin}
                          onChange={(e) => setEmailRespFin(e.target.value)}
                        ></input>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='document' className='card_details_label'>
                            {t('FinancialResponsibleRG')}
                          </label>
                          <input
                            type='text'
                            className='form_control'
                            value={rgRespFin}
                            onChange={(e) => setRGRespFin(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='document' className='card_details_label flex-row required'>
                            {t('FinancialResponsibleCPF')}
                          </label>
                          <CpfCnpjMask required value={cpfRespFin} onChange={setCPFRespFin} mode='CPF' />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='parentesco' className='card_details_label'>
                            {t('Kinship')}
                          </label>
                          <select
                            className='form_control select'
                            value={parentesco}
                            onChange={(e) => setParentesco(e.target.value)}
                          >
                            <option value='' disabled hidden></option>
                            <option value='Avô(ó)'>{t('Grandparent')}</option>
                            <option value='Pai'>{t('Father')}</option>
                            <option value='Mãe'>{t('Mother')}</option>
                            <option value='Tio(a)'>{t('UncleAunt')}</option>
                            <option value='Primo(a)'>{t('Cousin')}</option>
                            <option value='Irmão(ã)'>{t('Sibling')}</option>
                            <option value='Nenhum'>{t('None')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            {t('FinancialResponsibleCellPhone')}
                          </label>
                          <PhoneMask value={phoneRespFin} onChange={setPhoneRespFin} />
                        </div>
                      </div>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='descript' className='card_details_label'>
                        {t('Description')}
                      </label>
                      <textarea
                        className='form_control'
                        value={sobre}
                        id=''
                        cols='30'
                        rows='10'
                        onChange={(e) => setSobre(e.target.value)}
                      ></textarea>
                    </div>
                    <div className='form_group'>
                      <button
                        disabled={sendSave}
                        type='button'
                        className='btn_card'
                        onClick={async () => {
                          setShowUploadDocument(true);
                        }}
                        style={{
                          display: 'block',
                          backgroundColor: 'rgb(170, 170, 170)',
                          width: 'min-content',
                          height: 28,
                          fontSize: '0.75rem',
                        }}
                      >
                        {sendSave ? (
                          <Loading type='1' style={{ marginLeft: '2%' }} />
                        ) : (
                          <svg
                            viewBox='0 0 25 25'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            style={{ transform: 'scale(0.8)' }}
                          >
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                        )}
                        Anexar docs
                      </button>
                    </div>
                    {!sendSave && (
                      <button className='btn_card mx-auto'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {id === null && t('StudentPage.ExternalLink.ButtonAdd')}
                        {id !== null && t('StudentPage.ExternalLink.ButtonEdit')}
                      </button>
                    )}
                    {sendSave && (
                      <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none' }} disabled>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('SystemMessage.Loading.Processing')}
                        <Loading type='1' style={{ marginLeft: '2%' }} />
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </main>

            <footer className='footer'>
              <div className='comunicacao' style={{ backgroundColor: 'rgb(20, 47, 118, 0.5)' }}>
                {t('SystemFooter.Copyright')}
              </div>
            </footer>
          </div>
        </div>
      </div>
      <AddDocumentsModal
        athleteID={athleteId}
        show={showUploadDocument}
        defaultAthleteDocuments={athleteDocuments}
        closeAddDocumento={(documents) => {
          setShowUploadDocument(false);
          if (!athleteId) {
            setAthleteDocuments(documents);
          }
        }}
      />
      <SweetAlert
        type={messageType}
        title=''
        show={showMessage}
        onConfirm={() => setShowMessage(false)}
      >
      {message.extra ? t(message.text, message.extra) : t(message)}
    </SweetAlert>
    </main>
  );
};

export default StudentLinkCreate;
