import React from 'react';
import Header from '../../components/admin/Header';
import Footer from '../../components/admin/Footer';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import { useHistory, Link } from 'react-router-dom';
import { Autocomplete, Pagination } from '@material-ui/lab';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  makeStyles,
  withStyles,
} from '@material-ui/core';

import { getAllCourses } from '../../services/CourseService';
import { getPositions } from '../../services/SharedService';
import Loading from '../../components/Loading';

//handler functions --------------------------------------------------------------------------
const createFilterOptions = (options, { inputValue }) => {
  const filteredOptions = options.filter((option) => {
    const { id, schName } = option;
    const lowerSearchText = inputValue.toLowerCase();

    return schName.toLowerCase().includes(lowerSearchText) || id.toString().includes(lowerSearchText);
  });
  return filteredOptions;
};

const SportsCategories = () => {
  //hooks states --------------------------------------------------------------------------------------
  const history = useHistory();

  const classes = useStyles();

  const [isLoading, setIsLoading] = React.useState(false);

  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 20;
  const [rows, setRows] = React.useState([]);
  const [sports, setSports] = React.useState([]);
  const [filteredRows, setFilterRows] = React.useState(null);
  const tableRef = React.useRef();

  //constructor -------------------------------------------------------------------------------------------
  React.useEffect(() => {
    let isMounted = true;
    (async () => {
      setIsLoading(true);
      const { schools } = await getAllCourses();
      if (sports.length === 0) {
        const { sports } = await getPositions();
        if (isMounted) {
          setSports(sports);
        }
      }

      if (isMounted) {
        setRows(schools);
        setIsLoading(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  //handler funtions --------------------------------------------------------------------------------------
  const handleAddCategories = (event) => {
    history.push('/sports-categories/append-sports', { school_ids: selected, isEdit: false });
  };
  const handleEditCategories = (id) => {
    history.push('/sports-categories/append-sports', {
      school_ids: [id],
      isEdit: true,
      sports: rows.find((v) => v.id === id).sports,
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    toggleDisable(event.target.closest('tr').children[3].children[0]);
    setSelected(newSelected);
  };

  const toggleDisable = (elemento) => {
    elemento.disabled = !elemento.disabled;
    elemento.style.cursor = elemento.disabled ? 'not-allowed' : 'pointer';
    elemento.style.opacity = elemento.disabled ? '0.5' : '1';
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tableRef.current.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
  };

  const handleChangeFitler = (id) => {
    const filtered = rows.filter((v) => v.id === id).map((n) => n.id);
    setFilterRows([...filtered, ...selected]);
    setPage(1);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  //Columns --------------------------------------------------------------------------------------------------
  const columns = [
    {
      id: 'schName',
      label: 'Escolas',
      minWidth: 320,
      align: 'left',
    },
    {
      id: 'sports',
      label: 'Total Catg. Esportes',
      align: 'center',
      format: (s) => s.length,
    },
    {
      id: 'id',
      label: 'Editar',
      minWidth: 65,
      align: 'center',
      format: (id) => (
        <BtnEdit
          onClick={(e) => {
            e.preventDefault();
            if (e.target.closest('span').style.cursor === 'pointer') {
              handleEditCategories(id);
            } else {
              e.stopPropagation();
            }
          }}
        />
      ),
    },
  ];
  //local consts --------------------------------------------------------------------------------------------
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <Header />
            <main className='content_panel'>
              {/* sidebar */}
              <div className='sidebar' style={{ flex: 1 }}>
                <div className='card p-0'>
                  <ul className='list-group text-center w-100'>
                    <li className='list-group-item'>
                      <h2 style={{ color: 'var(--main-color)' }}>{rows.length || 0}</h2>
                      <h6>Escolas Cadastradas</h6>
                    </li>
                    <li className='list-group-item'>
                      <h2 style={{ color: 'var(--main-color)' }}>{sports.length || 0}</h2>
                      <h6>Espotes Cadastrados</h6>
                    </li>
                  </ul>
                </div>
                <div className='card flex-column align-items-center'>
                  <div className='card_header align-items-center'>
                    <h2 className='card_title'>Novo Esporte</h2>
                  </div>
                  <div className='card-actions mt-4'>
                    <div className='card-action-row' style={{ justifyContent: 'center' }}>
                      <Link to='/sports-categories/create-sports' className='btn_card ml-auto mt-4 mt-md-0'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        Adicionar
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* conteudo */}
              <div className='content'>
                <div className='card' style={{ justifyContent: 'center' }}>
                  <form style={{ alignItems: 'center' }}>
                    <div
                      className='form_card row'
                      style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}
                    >
                      <div className='col form_group'>
                        <label className='card_details_label'>Buscar Escolas</label>

                        <Autocomplete
                          classes={classes}
                          options={rows}
                          getOptionLabel={(option) => option.schName}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          filterOptions={createFilterOptions}
                          onChange={(event, school, detail) => {
                            if (detail === 'select-option') {
                              handleChangeFitler(school.id);
                            }
                            if (detail === 'clear') {
                              setFilterRows(null);
                              setPage(1);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Pesquisar por nome da Escola ou ID'
                              variant='outlined'
                            />
                          )}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className='card p-2 max-to-print'>
                  <EnhancedTableToolbar numSelected={selected.length} handlerAdd={handleAddCategories} />
                  <TableContainer className='max-to-print' style={{ maxHeight: 500, minHeight: 500 * 0.8 }}>
                    <TableSpacing ref={tableRef} stickyHeader size='small' aria-label='sticky table'>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell padding='checkbox'>
                            <CheckboxCustom
                              indeterminate={selected.length > 0 && selected.length < rows.length}
                              checked={rows.length > 0 && selected.length === rows.length}
                              onChange={handleSelectAllClick}
                              inputProps={{ 'aria-label': 'select all desserts' }}
                            />
                          </StyledTableCell>
                          {columns.map((column) => (
                            <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                              {column.label}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows
                          .filter((p) => filteredRows === null || filteredRows.some((v) => v === p.id))
                          .slice((page - 1) * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <StyledTableRow
                                hover
                                onClick={(event) => handleClick(event, row.id)}
                                role='checkbox'
                                aria-checked={isItemSelected}
                                key={row.id}
                                selected={isItemSelected}
                                tabIndex={-1}
                              >
                                <TableCell padding='checkbox'>
                                  <CheckboxCustom
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </TableCell>
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      {typeof column.format === 'function' ? column.format(value, row) : value}
                                    </TableCell>
                                  );
                                })}
                              </StyledTableRow>
                            );
                          })}
                      </TableBody>
                    </TableSpacing>
                    {isLoading && (
                      <div className='d-flex justify-content-center'>
                        {' '}
                        <Loading type='1' />{' '}
                      </div>
                    )}
                  </TableContainer>

                  <div className='w-100 p-2 d-flex justify-content-center'>
                    <Pagination
                      count={
                        Math.ceil(
                          rows.filter((p) => filteredRows === null || filteredRows.some((v) => v === p.id)).length /
                            rowsPerPage
                        ) - 1
                      }
                      page={page}
                      onChange={handleChangePage}
                      variant='outlined'
                      shape='rounded'
                    />
                  </div>
                  <div className='card-footer w-100 d-flex justify-content-center d-print-none'></div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

//material ui custom styles --------------------------------------------------------------------------------
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    flex: '1 1 100%',
  },
  highlight: {
    color: 'white',
    backgroundColor: 'var(--main-color)',
  },

  title: {
    flex: '1 1 100%',
  },
}));

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: '#f4f0f0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
  },
});

//local Components --------------------------------------------------------------------------------------------------
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'white',
    color: theme.palette.common.black,
    border: 'none',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '& td:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      borderLeft: `1px solid var(--main-color)`,
    },
    '& td:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      borderRight: `1px solid var(--main-color)`,
    },
    '& td': {
      borderBottom: `1px solid var(--main-color)`,
      borderTop: '1px solid var(--main-color)',
      marginBottom: theme.spacing(2),
    },
  },
}))(TableRow);

const TableSpacing = withStyles((theme) => ({
  root: {
    borderSpacing: `0 ${theme.spacing(2)}px`,
  },
}))(Table);

const CheckboxCustom = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: 'var(--main-color)',
    },
  },
  checked: {},
}))(Checkbox);

const BtnEdit = ({ fcolor = '#B8872B', w = 16, h = 16, onClick }) => (
  <span onClick={onClick} className='text-info' style={{ cursor: 'pointer' }} role='button'>
    <svg width={w} height={h} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10L16 6V10H14V7H9V2H2V18H8V20H2ZM14.3 12.525L15.375 13.6L11.5 17.45V18.5H12.55L16.425 14.65L17.475 15.7L13.175 20H10V16.825L14.3 12.525ZM17.475 15.7L14.3 12.525L15.75 11.075C15.9333 10.8917 16.1667 10.8 16.45 10.8C16.7333 10.8 16.9667 10.8917 17.15 11.075L18.925 12.85C19.1083 13.0333 19.2 13.2667 19.2 13.55C19.2 13.8333 19.1083 14.0667 18.925 14.25L17.475 15.7Z'
        fill={fcolor}
      />
    </svg>
  </span>
);
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, handlerAdd } = props;

  return (
    <Toolbar className={`${classes.root} ${numSelected > 0 ? classes.highlight : ''}`}>
      {numSelected > 0 ? (
        <div className={classes.title}>
          <p>{numSelected}, escolas selecionadas</p>
        </div>
      ) : (
        <div className={classes.title}>
          <h6>Lista de Escolas</h6>
          <p className='small'>Selecione as escolas para víncular os esportes</p>
        </div>
      )}

      {numSelected > 0 ? (
        <ButtonRounded variant='outline' color='var(--main-color)' onClick={handlerAdd}>
          Víncular
        </ButtonRounded>
      ) : (
        ''
      )}
    </Toolbar>
  );
};
export default SportsCategories;
