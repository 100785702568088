import React, { useEffect, useRef, useState } from 'react';
import { BsCreditCard } from 'react-icons/bs';
import { RiBarcodeLine } from 'react-icons/ri';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import { clearCarrinho } from '../../redux/actions/Cart';
import {
  auth,
  client,
  getAsaasConfigSessionClub,
  payment
} from '../../services/PaymentService';
import utils, { getErrorMessageFromResponse, handleOnChange } from '../../utils/utils';

import api from '../../services/api';
import Api from '../../utils/api';

import { UserContext } from '../../Context/UserContext';

const MyCartPayment = (props) => {
  const { user } = React.useContext(UserContext);
  const baseURL = Api();
  const paymentRef = useRef(null);
  const [send, setSend] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [boleto, setBoleto] = useState('');
  const [name, setName] = useState(props.location.state.identidade.user.name);
  const [email, setEmail] = useState(props.location.state.identidade.user.email);
  const [phone, setPhone] = useState(props.location.state.identidade.user.usuPhone);
  const [cpf, setCPF] = useState(props.location.state.identidade.user.usuCPF);
  const address = {
    postalCode: props.location.state.identidade.athlete.athCep,
    address: props.location.state.identidade.athlete.athLogradouro,
    addressNumber: props.location.state.identidade.athlete.athNumero,
    province: props.location.state.identidade.athlete.athBairro,
  };
  const courtesy = props.location.state.produtos.length >= 1 && !props.location.state.produtos.some((p) => !p.cortesia);
  const [typePayment, setTypePayment] = useState(courtesy ? 'COURTESY' : '');
  const [aba, setAba] = useState({ one: true, two: false });
  const [validado, setValidado] = useState({ phone: true, cpf: true });
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  const { replaceAll, currencyFormatterText, validaCPF, sumDaysDate } = utils();
  const dispatch = useDispatch();
  const [creditCardInfo, setCreditCardInfo] = useState({
    holderName: '',
    number: '',
    expiry: '',
    ccv: '',
  });
  const onChangeCreditCardInfo = handleOnChange(setCreditCardInfo);

  function validateDadosPessoais(e) {
    e.preventDefault();

    if (phone.length < 10) {
      setValidado({ ...validado, phone: false });
      setAba({ ...aba, two: false });
      return;
    }

    if (!validaCPF(cpf)) {
      setValidado({ phone: true, cpf: false });
      setAba({ ...aba, two: false });
      return;
    }

    setValidado({ phone: true, cpf: true });
    setAba({ ...aba, two: true });
  }

  async function handleFaturar() {
    const configAsaas = getAsaasConfigSessionClub(props.location.state.identidade.company);

    if (props.location.state.identidade.company.comPercentual) {
      configAsaas.percentual = parseFloat(props.location.state.identidade.company.comPercentual);
    } else if (props.location.state.identidade.company.comValor) {
      configAsaas.valor = parseFloat(props.location.state.identidade.company.comValor);
    } else {
      configAsaas.percentual = null;
    }

    setSend(true);
    setError(false);
    setSuccess(false);
    setMessage('');

    if (configAsaas.cnpj && configAsaas.password && configAsaas.token_assas && configAsaas.wallet) {
      const authResp = await auth(configAsaas.cnpj, configAsaas.password, configAsaas.loginEmail);

      if (authResp.status === 200 || authResp.connection == undefined || authResp.data.token !== undefined) {
        const clientResp = await client(
          cpf,
          name + ' (' + props.location.state.identidade.user.name + ')',
          email,
          phone,
          configAsaas.system_id,
          authResp.data.user_id,
          configAsaas.token_assas,
          '',
          address
        );

        console.log(clientResp);
        if (clientResp.data?.erro === 401) {
          setMessage(
            clientResp.data?.message ||
              'Estamos com problema de comunicação com o servidor, tente novamente mais tarde...'
          );
          setError(true);
          setSuccess(false);
          setSend(false);
          return;
        }
        if (clientResp.status == 200) {
          let dueDate = typePayment === 'BOLETO' ? sumDaysDate(2) : new Date().toISOString().substr(0, 10);

          let data = {
            billingType: typePayment,
            customer: clientResp.data.data.customer,
            value: props.location.state.valorTotal,
            dueDate,
            description: 'Venda de produto(s)',
            installmentCount: '',
            installmentValue: '',
            postalService: false,
            client_id: clientResp.data.data.id,
            token_assas: configAsaas.token_assas,
            /*       financial: {
              company_id: props.location.state.identidade.company.id,
              school_id: null,
              user_id: props.location.state.identidade.user.id,
              client_fornecedor_id: null,
              user_id_student: props.location.state.identidade.user.id,
              type: 'Receita',
              countDescription: 'Venda de produto(s)',
              mode: 'A vista',
              payment: typePayment === 'BOLETO' ? 'Boleto' : 'Cartão',
              dueDate,
              amount: 1,
              received: 'N',
              datePayment: null,
              observation: 'Venda de produto(s)',
              products: props.location.state.produtos,
              qtdTotal: props.location.state.qtdTotal,
            }, */
          };
          // if (typePayment === 'CREDIT_CARD') {
          //   const [expiryMonth, expiryYear] = creditCardInfo.expiry.split('/');

          //   data['creditCard'] = {
          //     holderName: creditCardInfo.holderName,
          //     number: creditCardInfo.number,
          //     expiryMonth: expiryMonth,
          //     expiryYear: expiryYear,
          //     ccv: creditCardInfo.ccv,
          //   };
          // }

          if (configAsaas.percentual) {
            data.split = [
              {
                walletId: configAsaas.wallet,
                percentualValue: configAsaas.percentual,
              },
            ];
          } else if (configAsaas.valor) {
            data.split = [
              {
                walletId: configAsaas.wallet,
                fixedValue: configAsaas.valor,
              },
            ];
          }

          let endpoint = typePayment === 'BOLETO' ? 'payment-boleto-vista' : 'payment-card-cred-vista-invoice-url';
          let paymentResp = await payment(endpoint, data);
          paymentResp = paymentResp.data.data ? paymentResp.data.data : paymentResp.data;

          if (paymentResp.paymentResponse) {
            if (paymentResp.request.status == 'CONFIRMED' || paymentResp.request.status == 'PENDING') {

              await createProductOrder(); //gera pedido de compra

              const resp_financial = await createClubFinancial(typePayment, dueDate);//gera finceiro club

              const data_order = {
                products: props.location.state.produtos,
                qtdTotal: props.location.state.qtdTotal,
                value: props.location.state.valorTotal,
                payment: typePayment === 'BOLETO' ? 'Boleto' : 'Cartão',
                status: 'Pendente',
                club_id: props.location.state.identidade.company.id,
                user_id: resp_financial.data.data[0].user_id,
                finacial_id: resp_financial.data.id,
              };

              await api.post(baseURL + 'club-ecommerce/sales-order', data_order);// gera uma ordem de compra clube

              setSend(false);
              setError(false);
              setSuccess(true);

              let notification =
                'Enviamos um email com as informações sobre o pagamento. Estamos aguardando para confirmar a sua compra.';

              if (typePayment === 'BOLETO') {
                notification = 'Boleto gerado. Estamos aguardando o pagamento para confirmar a sua compra.';
                setBoleto(paymentResp.request.bankSlipUrl);
                setMessage(notification);
                window.open(paymentResp.request.bankSlipUrl, '_blank');
              } else {
                setMessage(notification);
              }

              dispatch(clearCarrinho());
            } else {
              setMessage('Oops! Houve um problema inesperado. Por favor, tente novamente...');
              setSend(false);
              setError(true);
              setSuccess(true);
            }
          } else if (paymentResp.message != undefined) {
            const msgs = getErrorMessageFromResponse(paymentResp.message);

            setMessage(msgs);
            setError(true);
            setSuccess(false);
            setSend(false);
          } else if (paymentResp.erro != undefined) {
            setMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...');
            setError(true);
            setSuccess(false);
            setSend(false);
          }
        } else {
          setMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...');
          setError(true);
          setSuccess(false);
          setSend(false);
        }
      } else {
        setMessage('Estamos com problema de comunicação com o servidor, tente novamente mais tarde...');
        setError(true);
        setSuccess(false);
        setSend(false);
      }
    } else {
      setMessage('Entre em contato com o responsável pela sua escola.');
      setError(true);
      setSuccess(false);
      setSend(false);
    }
  }

  async function createProductOrder() {
    const product = props.location.state.produtos.map((p) => ({
      amount: p.qtd,
      size_product: p.tamanho,
      titileProduct: p.titulo,
      unitary_value: p.preco,
      product_id: p.id,
      value_total: p.preco * p.qtd,
    }));

    const data = {
      school_id: props.location.state.identidade.school.id,
      client_fornecedor_id: null,
      value_order: product.reduce((acc, curr) => acc + curr.value_total, 0),
      productsIncludes: product,
      status: 'Aberto',
      company_id: props.location.state.identidade.company.id,
      user_id: props.location.state.identidade.user.id,
    };
    await api.post(baseURL + 'save-purchase-orders', data);
  }

  async function createClubFinancial(typePayment, dueDate) {

    const data_financial = {
      client_fornecedor_id: null,
      school_id: props.location.state.identidade.school.id,
      type: 'Receita',
      countDescription: 'Venda de produto(s)',
      mode: 'A vista',
      payment: typePayment === 'BOLETO' ? 'Boleto' : 'Cartão',
      dueDate,
      value: props.location.state.valorTotal,
      amount: 1,
      received: 'N',
      datePayment: null,
      observation: 'Venda de produto(s)',
      discount_type: null,
      discount: null,
      late_interest_type: null,
      late_interest: null,
      late_fee_type: null,
      late_fee: null,
      valueReceived: null,
      payment_for_club: true,
    };

    return await api.post(baseURL + 'save-financial-school', data_financial);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(props.location.state);
  }, []);

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Seu Pedido</h2>
                    <p className='card_subtitle'>Veja abaixo os produtos confirmados nesta compra.</p>
                  </div>
                  <div className='list_card'>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                      <div
                        style={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: 10 }}
                        className='flex-column flex-lg-row'
                      >
                        <div className='card' style={{ display: 'block', boxShadow: 'none' }}>
                          <p style={{ fontWeight: '500', marginBottom: 20, textAlign: 'center', width: '100%' }}>
                            1. Dados do Pagador
                          </p>
                          <form action='' onSubmit={validateDadosPessoais} method='post' className='form_card'>
                            <div className='form_group'>
                              <label htmlFor='name' className='card_details_label'>
                                Nome completo*
                              </label>
                              <input
                                type='text'
                                required
                                className='form_control'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                            <div className='form_group'>
                              <label htmlFor='name' className='card_details_label'>
                                E-mail*
                              </label>
                              <input
                                type='email'
                                pattern='[^ @]*@[^ @]*'
                                required
                                className='form_control'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                            <div className='form_group'>
                              <label htmlFor='name' className='card_details_label'>
                                Celular*
                              </label>
                              <InputMask
                                required
                                className='form_control'
                                value={phone}
                                type='tel'
                                mask='(99)9 9999-9999'
                                disableunderline='true'
                                onChange={(e) =>
                                  setPhone(
                                    replaceAll(
                                      replaceAll(
                                        replaceAll(replaceAll(replaceAll(e.target.value, '(', ''), ')', ''), '-', ''),
                                        ' ',
                                        ''
                                      ),
                                      '_',
                                      ''
                                    )
                                  )
                                }
                              />
                              {!validado.phone && (
                                <p style={{ color: 'red', fontSize: 12 }}>O telefone deve ser de 10 ou 11 dígitos!</p>
                              )}
                            </div>
                            <div className='form_group'>
                              <label htmlFor='name' className='card_details_label'>
                                CPF*
                              </label>
                              <InputMask
                                required
                                value={cpf}
                                className='form_control'
                                mask='999.999.999-99'
                                type='tel'
                                disableunderline='true'
                                onChange={(e) =>
                                  setCPF(replaceAll(replaceAll(replaceAll(e.target.value, '.', ''), '-', ''), '-', ''))
                                }
                              />
                              {!validado.cpf && <p style={{ color: 'red', fontSize: 12 }}>Este CPF é inválido!</p>}
                            </div>
                            <button
                              style={{
                                borderRadius: 4,
                                fontSize: 18,
                                fontFamily: 'Montserrat, sans-serif!important',
                                border: 0,
                                marginTop: 20,
                                height: 50,
                                width: '93%',
                                marginLeft: 10,
                                background: '#000',
                                color: 'white',
                              }}
                              onClick={() => {
                                if (paymentRef) {
                                  paymentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
                                }
                              }}
                            >
                              ir para pagamento
                            </button>

                            <Link
                              to={{ pathname: 'my-cart' }}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 4,
                                fontSize: 18,
                                fontFamily: 'Montserrat, sans-serif!important',
                                border: '2px solid #B0B0B0',
                                marginTop: 20,
                                height: 50,
                                width: '93%',
                                marginLeft: 10,
                                background: '#fff',
                                color: '#B0B0B0',
                                marginBottom: 30,
                              }}
                              onClick={() => {}}
                            >
                              voltar
                            </Link>
                          </form>
                        </div>

                        <div
                          ref={paymentRef}
                          className='card'
                          style={{ display: 'block', opacity: aba.two ? 1 : 0.2, boxShadow: 'none' }}
                        >
                          <p style={{ fontWeight: '500', marginBottom: 20, textAlign: 'center', width: '100%' }}>
                            2. Pagamento
                          </p>
                          <p style={{ fontWeight: '700', marginBottom: 20, textAlign: 'center', width: '100%' }}>
                            Escolha a forma de pagamento
                          </p>

                          <p
                            onClick={() => setTypePayment('')}
                            style={{
                              display: courtesy || typePayment === '' ? 'none' : 'block',
                              fontSize: 12,
                              marginBottom: 20,
                              color: '#0077DF',
                              cursor: 'pointer',
                            }}
                          >
                            Alterar forma de pagamento
                          </p>

                          <div
                            onClick={() => setTypePayment('COURTESY')}
                            style={{
                              display: courtesy ? 'flex' : 'none',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              height: 50,
                              marginBottom: 10,
                              background: '#f4f4f4',
                              cursor: 'pointer',
                            }}
                          >
                            <RiBarcodeLine style={{ width: 40 }} />
                            <p style={{ fontSize: 13.5 }}>
                              Produto cortesia. <b>Gratuito.</b>
                            </p>
                          </div>

                          <div
                            onClick={() => setTypePayment('CREDIT_CARD')}
                            style={{
                              display: typePayment === '' || typePayment === 'CREDIT_CARD' ? 'flex' : 'none',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              height: 50,
                              marginBottom: 10,
                              background: '#f4f4f4',
                              cursor: 'pointer',
                            }}
                          >
                            <BsCreditCard style={{ width: 40 }} />
                            <p style={{ fontSize: 13.5 }}>
                              Pagamento com <b>cartão de crédito</b>
                            </p>
                          </div>

                          <div style={{ display: typePayment === 'COURTESY' ? 'inline' : 'none' }}>
                            <p style={{ margin: '20px 10px' }}>
                              <b>Valor do pedido: {currencyFormatterText(props.location.state.valorTotal)}</b>
                            </p>

                            {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}

                            {success && <MessageSuccessMarginTop title='Sucesso! ' description={message} />}

                            {success && (
                              <Link
                                to='/home-student-school'
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  orderRadius: 4,
                                  fontSize: 18,
                                  fontFamily: 'Montserrat, sans-serif!important',
                                  border: 0,
                                  marginTop: 20,
                                  height: 50,
                                  width: '93%',
                                  marginLeft: 10,
                                  background: '#000',
                                  color: 'white',
                                }}
                              >
                                <p>voltar para o sistema</p>
                              </Link>
                            )}              

                            {send && (
                              <button
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  orderRadius: 4,
                                  fontSize: 18,
                                  fontFamily: 'Montserrat, sans-serif!important',
                                  border: 0,
                                  marginTop: 20,
                                  height: 50,
                                  width: '93%',
                                  marginLeft: 10,
                                  background: '#ddd',
                                  color: 'white',
                                }}
                              >
                                <p>processando pedido...</p>{' '}
                                <Loading style={{ position: 'absolute', right: 40 }} type='1' />
                              </button>
                            )}
                          </div>
                          {/* <div
                            style={{
                              display: typePayment === 'CREDIT_CARD' ? 'flex' : 'none',
                              flexDirection: 'column',
                              fontSize: '13px',
                            }}
                          >
                            <span className='text-center mb-2'>Dados do cartão de crédito</span>

                            <InputRounded
                              classNameWrapper='border-color-default'
                              className='text-left'
                              style={{ height: '35px' }}
                              styleWrapper={{ borderRadius: '8px' }}
                              name='holderName'
                              type='text'
                              placeholder={'Nome no cartão de crédito'}
                              value={creditCardInfo}
                              onChange={onChangeCreditCardInfo}
                            />

                            <InputRounded
                              classNameWrapper='border-color-default'
                              className='text-left'
                              style={{ height: '35px' }}
                              styleWrapper={{ borderRadius: '8px' }}
                              name='number'
                              type='text'
                              placeholder={'Número do cartão de crédito'}
                              value={creditCardInfo}
                              onChange={(e) => onChangeCreditCardInfo('number', creditCardNumberFormat(e.target.value))}
                            />

                            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}>
                              <InputFormatRounded
                                classNameContainer='w-50'
                                classNameWrapper='border-color-default'
                                className='text-left'
                                style={{ height: '35px' }}
                                styleWrapper={{ borderRadius: '8px' }}
                                useFormattedValue
                                maskCharacter={'_'}
                                name='expiry'
                                type='text'
                                placeholder={'MM/AAAA'}
                                pattern={'##/####'}
                                value={creditCardInfo.expiry}
                                onChange={(value) => onChangeCreditCardInfo('expiry', value)}
                              />

                              <InputRounded
                                classNameContainer='w-50'
                                classNameWrapper='border-color-default'
                                className='text-left'
                                style={{ height: '35px' }}
                                styleWrapper={{ borderRadius: '8px' }}
                                name='ccv'
                                type='text'
                                placeholder={'CVV'}
                                value={creditCardInfo}
                                onChange={(e) => onChangeCreditCardInfo('ccv', e.target.value.replace(/\D/, ''))}
                              />
                            </div> */}
                          {/* </div> */}

                          <div
                            style={{
                              display: typePayment === 'CREDIT_CARD' ? 'flex' : 'none',
                              flexDirection: 'row',
                              alignItems: 'center',
                              height: 45,
                              background: '#f4f4f4',
                            }}
                          >
                            <img
                              src='img/icones/icon-locked.png'
                              style={{ width: 22, position: 'absolute', marginLeft: 5 }}
                            />
                            <p style={{ fontSize: 12, marginLeft: 35 }}>
                              {' '}
                              Este é um pagamento seguro, realizado em ambiente criptografado.
                            </p>
                          </div>

                          <div style={{ display: typePayment === 'CREDIT_CARD' ? 'inline' : 'none' }}>
                            <ul style={{ marginLeft: 20, marginRight: 20 }}>
                              <li style={{ fontSize: 12, listStyleType: 'disc' }}>
                                Pagamento com cartão é confirmado no mesmo dia;
                              </li>
                              <li style={{ fontSize: 12, listStyleType: 'disc' }}>
                                Enviaremos um email com as informações sobre o pagamento;
                              </li>
                              <li style={{ fontSize: 12, listStyleType: 'disc' }}>
                                Abra o email, informe os dados do cartão para sua compra.
                              </li>
                            </ul>

                            <div
                              className={`${typePayment === 'CREDIT_CARD' ? 'd-flex' : 'd-none'} mt-2`}
                              style={{ fontSize: 12 }}
                            >
                              <span className='alert alert-warning'>
                                Ao clicar em "Finalizar pedido", você recebera um email para finalizar o pagamento.
                              </span>
                            </div>

                            {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}

                            {success && <MessageSuccessMarginTop title='Sucesso! ' description={message} />}

                            {success && (
                              <Link
                                to='/home-student-school'
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  orderRadius: 4,
                                  fontSize: 18,
                                  fontFamily: 'Montserrat, sans-serif!important',
                                  border: 0,
                                  marginTop: 20,
                                  height: 50,
                                  width: '93%',
                                  marginLeft: 10,
                                  background: '#000',
                                  color: 'white',
                                }}
                              >
                                <p>voltar para o sistema</p>
                              </Link>
                            )}

                            {!send && !success && (
                              <button
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  orderRadius: 4,
                                  fontSize: 18,
                                  fontFamily: 'Montserrat, sans-serif!important',
                                  border: 0,
                                  marginTop: 20,
                                  height: 50,
                                  width: '93%',
                                  marginLeft: 10,
                                  background: '#000',
                                  color: 'white',
                                }}
                                onClick={() => handleFaturar()}
                              >
                                <p>Finalizar pedido</p>
                              </button>
                            )}

                            {send && (
                              <button
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  orderRadius: 4,
                                  fontSize: 18,
                                  fontFamily: 'Montserrat, sans-serif!important',
                                  border: 0,
                                  marginTop: 20,
                                  height: 50,
                                  width: '93%',
                                  marginLeft: 10,
                                  background: '#ddd',
                                  color: 'white',
                                }}
                              >
                                <p>processando pedido...</p>{' '}
                                <Loading style={{ position: 'absolute', right: 40 }} type='1' />
                              </button>
                            )}
                          </div>

                          <div
                            onClick={() => setTypePayment('BOLETO')}
                            style={{
                              display: typePayment === '' || typePayment === 'BOLETO' ? 'flex' : 'none',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              height: 50,
                              background: '#f4f4f4',
                              cursor: 'pointer',
                            }}
                          >
                            <RiBarcodeLine style={{ width: 40, marginLeft: 0 }} />
                            <p style={{ fontSize: 13.5 }}>
                              Pagamento com <b>boleto bancário à vista</b>
                            </p>
                          </div>

                          <div style={{ display: typePayment === 'BOLETO' ? 'inline' : 'none' }}>
                            <p style={{ margin: '20px 10px' }}>
                              <b>Valor do pedido: {currencyFormatterText(props.location.state.valorTotal)}</b>
                            </p>

                            <ul style={{ marginLeft: 20, marginRight: 20 }}>
                              <li style={{ fontSize: 12, listStyleType: 'disc' }}>
                                O boleto pode ser pago em qualquer agência bancária;
                              </li>
                              <li style={{ fontSize: 12, listStyleType: 'disc' }}>
                                O banco nos enviará automaticamente a confirmação do pagamento em até 2 dias úteis;
                              </li>
                              <li style={{ fontSize: 12, listStyleType: 'disc' }}>
                                Se o boleto não for pago até a data de vencimento (Nos casos de vencimento em final de
                                semana ou feriado, no próximo dia útil), seu pedido será cancelado automaticamente;
                              </li>
                              <li style={{ fontSize: 12, listStyleType: 'disc' }}>
                                Não faça depósito ou transferência entre contas.
                              </li>
                            </ul>

                            {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}

                            {success && <MessageSuccessMarginTop title='Sucesso! ' description={message} />}

                            {boleto !== '' && (
                              <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={boleto}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  orderRadius: 4,
                                  fontSize: 18,
                                  fontFamily: 'Montserrat, sans-serif!important',
                                  marginTop: 20,
                                  height: 50,
                                  width: '93%',
                                  marginLeft: 10,
                                  background: '#fff',
                                  color: '#000000',
                                  border: '2px solid #B0B0B0',
                                }}
                              >
                                <p>imprimir boleto</p>
                              </a>
                            )}

                            {success && (
                              <Link
                                to='/home-student-school'
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  orderRadius: 4,
                                  fontSize: 18,
                                  fontFamily: 'Montserrat, sans-serif!important',
                                  border: 0,
                                  marginTop: 20,
                                  height: 50,
                                  width: '93%',
                                  marginLeft: 10,
                                  background: '#000',
                                  color: 'white',
                                }}
                              >
                                <p>voltar para o sistema</p>
                              </Link>
                            )}

                            {!send && !success && (
                              <button
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  orderRadius: 4,
                                  fontSize: 18,
                                  fontFamily: 'Montserrat, sans-serif!important',
                                  border: 0,
                                  marginTop: 20,
                                  height: 50,
                                  width: '93%',
                                  marginLeft: 10,
                                  background: '#000',
                                  color: 'white',
                                }}
                                onClick={() => handleFaturar()}
                              >
                                <p>Finalizar pedido</p>
                              </button>
                            )}

                            {send && (
                              <button
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  orderRadius: 4,
                                  fontSize: 18,
                                  fontFamily: 'Montserrat, sans-serif!important',
                                  border: 0,
                                  marginTop: 20,
                                  height: 50,
                                  width: '93%',
                                  marginLeft: 10,
                                  background: '#ddd',
                                  color: 'white',
                                }}
                              >
                                <p>processando pedido...</p>{' '}
                                <Loading style={{ position: 'absolute', right: 40 }} type='1' />
                              </button>
                            )}
                          </div>
                        </div>

                        <div
                          className='card'
                          style={{
                            display: 'block',
                            background: '#ddd',
                            textAlign: 'center',
                            opacity: aba.two ? 1 : 0.2,
                            boxShadow: 'none',
                          }}
                        >
                          <p style={{ fontWeight: '500', marginBottom: 10, textAlign: 'center', width: '100%' }}>
                            3. Resumo do Pedido
                          </p>

                          <div style={{ width: '100%', marginBottom: 20 }}>
                            {props.location.state.produtos.map((item, i) => (
                              <div
                                key={i}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  background: '#fff',
                                  width: '100%',
                                  padding: 10,
                                }}
                              >
                                <div
                                  style={{ display: 'flex', flexDirection: 'row', background: '#fff', gap: '10px' }}
                                  className='flex-column flex-sm-row'
                                >
                                  <img
                                    style={{
                                      maxWidth: 120,
                                      minWidth: 120,
                                      height: 120,
                                      objectFit: 'cover',
                                      borderRadius: 4,
                                    }}
                                    src={item.url + '/' + item.fotoCapa}
                                  />
                                  <div>
                                    <p
                                      style={{
                                        fontFamily: 'Montserrat, sans-serif!important',
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        textAlign: 'left',
                                      }}
                                    >
                                      {item.titulo}
                                    </p>
                                    <p
                                      style={{
                                        fontFamily: 'Montserrat, sans-serif!important',
                                        fontSize: 11,
                                        textAlign: 'left',
                                      }}
                                    >
                                      Tamanho: {item.tamanho}
                                    </p>
                                    <p
                                      style={{
                                        fontFamily: 'Montserrat, sans-serif!important',
                                        fontSize: 11,
                                        marginBottom: 13,
                                        textAlign: 'left',
                                      }}
                                    >
                                      Quantidade: {item.qtd}
                                    </p>
                                    <p
                                      style={{
                                        fontFamily: 'Montserrat, sans-serif!important',
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        textAlign: 'left',
                                      }}
                                    >
                                      {currencyFormatterText(item.preco)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              margin: '10px 10px',
                            }}
                          >
                            <p style={{ fontFamily: 'Montserrat, sans-serif!important' }}>Produto(s)</p>
                            <p style={{ fontFamily: 'Montserrat, sans-serif!important' }}>
                              {props.location.state.produtos.length}
                            </p>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              margin: '10px 10px',
                            }}
                          >
                            <p style={{ fontFamily: 'Montserrat, sans-serif!important' }}>Quantidade(s)</p>
                            <p style={{ fontFamily: 'Montserrat, sans-serif!important' }}>
                              {props.location.state.qtdTotal}
                            </p>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              margin: '10px 10px',
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: 18,
                                fontFamily: 'Montserrat, sans-serif!important',
                              }}
                            >
                              Total
                            </p>
                            <p
                              style={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                fontFamily: 'Montserrat, sans-serif!important',
                              }}
                            >
                              {currencyFormatterText(props.location.state.valorTotal)}
                            </p>
                          </div>

                          <br />

                          <Link
                            to='/my-cart'
                            style={{ textAlign: 'center', fontSize: 14, color: '#0077DF', cursor: 'pointer' }}
                          >
                            voltar para o carrinho
                          </Link>
                        </div>
                      </div>
                    </div>

                    <React.Fragment>
                      <div
                        style={{ width: '100%', height: 1, background: '#ddd', marginTop: 20, marginBottom: 10 }}
                      ></div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 20,
                          marginBottom: 10,
                        }}
                      >
                        {typePayment !== 'COURTESY' && (
                          <>
                            <span
                              style={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold', marginRight: 20 }}
                            >
                              FORMAS DE PAGAMENTOS:{' '}
                            </span>

                            <RiBarcodeLine style={{ marginLeft: 20 }} />
                            <span style={{ fontSize: 12, marginLeft: 10, fontWeight: '500' }}>Boleto</span>
                            <BsCreditCard style={{ marginLeft: 30 }} />
                            <span style={{ fontSize: 12, marginLeft: 10, fontWeight: '500' }}>Cartão</span>
                          </>
                        )}
                      </div>
                    </React.Fragment>
                  </div>
                </div>
              </div>
            </main>

            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </main>
  );
};

export default MyCartPayment;
