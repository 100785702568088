import React, { useState, forwardRef, useEffect } from 'react';
import { ModalWithMethods } from '../../../components/modal/Modal';
import ReactTooltip from 'react-tooltip'
import { BsBoxArrowUpRight, BsCalendar3 } from 'react-icons/bs';

const ViewAdminMicrocycle = (
  { data, onSubmit, onlyView, ...props },
  ref
) => {
  const [microcycle, setMicrocycle] = useState({
    id: undefined,
    name: '',
    status: 'A',
    classNumber: '',
    classQuantity: 0,
    objective: '',
  });

  const [rangeDay, setRangeDay] = useState([]);
  const [day, setDay] = useState(null);
  const [planningsSelected, setPlanningsSelected] = useState([]);

  const onShow = () => {
    ref.current.clearMessage();
    setDay(data?.days?.length ? data?.days[0]?.day : null)
    setPlanningsSelected(data?.classes ?? []);
    setMicrocycle(data);
  };

  const calculateRangeDay = () => {
    if (microcycle.classQuantity > 0) {
      const range = Array(Number(microcycle.classQuantity)).fill().map((_, i) => i + 1)
      setRangeDay(range)
      setDay(range[0])
    }
  }

  useEffect(() => {
    calculateRangeDay();
  }, [microcycle?.classQuantity])

  return (
    <ModalWithMethods
      {...props}
      ref={ref}
      title={<div className='modal_title' style={{ padding: '0 30px' }}>Visualizar Microciclo</div>}
      size={'lg'}
      onShow={onShow}
      modalContentStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 20 }}
    >
      <div className='w-100'>
        <div className='planning-header-buttons'>
          <button className='btn-secondary-color' >Resumo</button>
        </div>
      </div>
      <form className='form_modal'>
        <div className='w-100' style={{ padding: '0 30px' }}>
          <div className='row'>
            <div className='col-sm-12 text-center mb-3'>
              <h4>Microciclo para {rangeDay?.length} dia(s):</h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 mb-3'>
              <div className='d-flex justify-content-between overflow-auto pb-2'>
                {rangeDay && rangeDay?.map((d) => {
                  return (
                    <button type='button' className={`${d === day ? 'btn-secondary-color' : 'btn-disabled'} p-3 mx-2`} onClick={() => setDay(d)}>
                      <div className='d-flex justify-content-around align-items-center'>
                        <BsCalendar3 className='mr-2' />
                        {d}
                      </div>
                    </button>
                  )
                })}
              </div>
            </div>
          </div>
          <div className='grid-microcycle p-3'>
            <div className='row border-bottom border-warning pb-3 mx-1'>
              <div className='col-sm-12'>
                <div className='font-weight-bold'>Objetivo</div>
                <div className=''>{microcycle?.objective}</div>
              </div>
            </div>
            <div className='row border-bottom border-warning py-3 mx-1'>
              <div className='col-sm-6'>
                <div className='font-weight-bold'>Nome da aula</div>
                <div className=''>{microcycle?.name}</div>
              </div>
              <div className='col-sm-6'>
                <div className='font-weight-bold'>Número da aula</div>
                <div className=''>{microcycle?.classNumber}</div>
              </div>
            </div>
            {planningsSelected && planningsSelected?.filter((c) => c.day === day)?.length > 0 && (
              <div className='row pt-3 mx-1'>
                <div className='col-sm-12'>
                  <div className='font-weight-bold'>Aula da Escola</div>
                  <div className='resume'>
                    <ul>
                      {planningsSelected?.filter((c) => c.day === day)?.map((c) =>
                        <>
                          <li key={c.id}>
                            {c.name}
                            <div data-for={`lesson-description-${microcycle.id}-${c.id}`} data-tip><BsBoxArrowUpRight className='ml-2 secondary-color' /></div>
                            <ReactTooltip id={`lesson-description-${microcycle.id}-${c.id}`} place='right' className='rounded-lg'>
                              <div className='mb-2'><span className='font-weight-bold'>Nome: </span><span>{c.name}</span></div>
                              <div className='mb-2'><span className='font-weight-bold'>Objetivo: </span><span>{c.objective}</span></div>
                              <div className='mb-2'><span className='font-weight-bold'>Número da Aula: </span><span>{c.classNumber}</span></div>
                              <div className='mb-2'>
                                <div className='font-weight-bold'>Tarefas</div>
                                <ul style={{ listStyle: 'disc' }}>
                                  {c?.tasks?.map((task) => (<li className='ml-3' key={`task-${microcycle.id}-${c.id}-${task.id}`}>{task?.concept}</li>))}
                                </ul>
                              </div>
                            </ReactTooltip>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
            <div className='main-pill'></div>
          </div>
        </div>
      </form>
    </ModalWithMethods>
  );
};

export default forwardRef(ViewAdminMicrocycle);
