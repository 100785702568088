import React from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Checkbox, TextField } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import '../../../utils/extendsType';

import useForm from '../../../hooks/useForm';
import Message from '../../../components/Message';
import ButtonRounded from '../../../components/buttons/ButtonRounded';

import Mensal from '../../Students/Forms/Mensal';
import ContractNotification from './ContractNotification';
import { getStandardizedContractPlan, storeContract } from '../../../services/ContractStudentService';
import api from '../../../services/api';

import { StudentFormContext } from '../../../Context/StudentFormContext';
import { CobrancaFaturarContext } from '../../../Context/CobrancaFaturarContext';
import { set } from 'react-ga';
import { getAsaasConfigSessionSchool } from '../../../services/PaymentService';

const sortByName = function (a, b) {
  var nameA = a.name.toLowerCase(),
    nameB = b.name.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

const createFilterOptions = (options, { inputValue }) => {
  const filteredOptions = options.filter((option) => {
    const { usuCPF, name, usuBirth, email } = option;
    const lowerSearchText = inputValue.toLowerCase();
    const _name = name.toString().toLowerCase();
    return (
      (_name && _name.includes(lowerSearchText)) ||
      (usuCPF && usuCPF.includes(lowerSearchText)) ||
      (usuBirth && usuBirth.includes(lowerSearchText)) ||
      (email && email.includes(lowerSearchText))
    );
  });
  return filteredOptions;
};
const createFilterOptionsTurma = (options, { inputValue }) => {
  const filteredOptions = options.filter((option) => {
    const { name } = option;
    const lowerSearchText = inputValue.toLowerCase();
    const _name = name.toString().toLowerCase();
    return _name && _name.includes(lowerSearchText);
  });
  return filteredOptions;
};

//component
const ContractForm = () => {
  //hooks states --------------------------------------------------------------------------------------
  const history = useHistory();

  const context = React.useContext(StudentFormContext);
/*   const clientContext = React.useContext(CobrancaFaturarContext); */

  const { t } = useTranslation();

  const classes = useStyles();

  const formRefer = React.useRef();

  const alertRef = React.useRef();

  //!gambiarra pra resetar o Autocomplete
  const [autokey, setAutoKey] = React.useState(moment().toISOString());

  const [errors, setErrors] = React.useState([]);
  const [errorServer, setErrorsServe] = React.useState([]);

  const { MessageDangerMarginTop } = Message();

  const [sendSave, setSendSave] = React.useState(false);

  const [progress, setProgress] = React.useState([]);

  const [isDone, setIsDone] = React.useState(false);

  const [_students, setStudents] = React.useState([]);

  const [studentsList, setStudentsList] = React.useState(stateSchema);

  const [turmas, setClasses] = React.useState([]);

  const [selected, setSelected] = React.useState([]);

  const [filterClass, setFilterClass] = React.useState('');

  const [tipo, setTipo] = React.useState(1);

  const [standContracts, setStandContracts] = React.useState([]);
  
  const [standDefault, setStandDefalt] = React.useState('');

  const handlerErrorServer = (err) => {
    setSendSave(false);
    if (err.response?.status === 422) {
      const { message = [] } = err.response.data;
      Object.keys(message).map((k) => {
        const element = document.querySelector(`[name=${k}]`);
        if (element) {
          mensalForm.dispatchHtmlValidity(element, `[${k}] ${message[k]}`);
        }
      });
    } else {
      setErrorsServe([err.toString()]);
    }
  };
  
  const onSubmitFormMensal = (p, e, s, formData) => {
    if (formData.get('valorReceber') === '') {
      formData.set('valorReceber', p.valorOriginal);
    }
    const btn = e.target.loader();

    btn.start();

    setSendSave(true);

    const clientContext = getAsaasConfigSessionSchool(context.school, context.company);

    let counter = 0;
    const payload = {
      user_id_assas: clientContext.user_id || '',
      company_id: context.company.id,
      school_id: context.school.id,
      system_id: clientContext.system_id || '',
      token_assas: clientContext.token_assas || '',
      percentual: clientContext?.percentual || '',
      valor_config: clientContext?.valor || '',
      wallet: clientContext.wallet || '',
      students: null,
      ...p,
    };

    const promise = async (id) => {
      payload.students = studentsList.students.value.find((u) => u.id == id);

      return storeContract(payload)
        .then((data) => {
          setProgress((prev) => [...prev, data.data]);
        })
        .catch((error) => {
          counter = selected.length;
          handlerErrorServer(error);
        });
    };

    (async () => {
      while (counter < selected.length) {
        await promise(selected[counter]);
        counter++;
      }
    })();
  };

  const mensalForm = useForm(stateMensalSchema, validationStateSchema, onSubmitFormMensal, formRefer, setErrors);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  //handler funtions --------------------------------------------------------------------------------------

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = studentsList.students.value.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  async function _onSubmitForm(payload) {
    setIsDone(true);
  }

  async function getRequestClass() {
    const response = await api.post('get-students-and-class-and-services', { school_id: context.school.id });

    const dados = response.data.classs.sort(sortByName);

    dados
      .filter((t) => t.students.length > 0)
      .map((v) => {
        v.students.forEach((s) => {
          s.turma = v.name;
          s.turma_id = v.id;
        });
        v.students.unshift({ name: 'TODOS', id: '' });
        return v;
      });

    setClasses(dados);
  }

  const removeStudentFromList = React.useCallback((id) => {
    setStudentsList((current) => ({
      ...current,
      students: { value: current.students.value.filter((student) => student.id !== id) },
    }));

    setSelected((current) => current.filter((student) => student !== id));
  });

  //constructor --------------------------------------------------------------------------------------
  React.useEffect(() => {
    setSelected([]);
    setStudentsList({ students: { value: [] } });

    (async () => {
      try {
        await getRequestClass();
        const data = await getStandardizedContractPlan(context.school.id);
        setStandContracts(data);
      } catch (error) {
        console.log(error);
      }
    })();

    return () => {
      setStudents([]);
      setClasses([]);
      setSelected([]);
      setStudentsList({ students: { value: [] } });
    };
  }, []);

  React.useEffect(() => {
    if (standContracts.length > 0) {
      const defaultPlan = standContracts.find((plan) => plan.default_plan == 'S');
      if (defaultPlan) {
        const { id = '' } = defaultPlan;
        handlerSetDefaultPlan(id);
      } else {
        handlerSetDefaultPlan(''); 
      }
    }
  }, [isDone]);
  
  React.useEffect(() => {
    if (filterClass !== '') {
      const dt = turmas.find((t) => +t.id === +filterClass);

      if (dt) setStudents(dt.students);
    }
  }, [filterClass]);

  //const properties --------------------------------------------------------------------------------------
  const columnStudent = [
    {
      id: 'name',
      label: 'Alunos Incluídos',
      minWidth: 220,
      align: 'left',
      format: (value, row) => (
        <>
          <p>{value}</p>
          <small>{row.turma}</small>
        </>
      ),
    },
    {
      id: 'usuBirth',
      label: 'Data Aniversário',
      minWidth: 220,
      align: 'left',
      format: (value) => moment(value).format('DD/MM/YYYY'),
    },
    {
      id: 'athEmail',
      label: 'Email',
      align: 'left',
      format: (value, row) => row?.athEmail || row?.usuEmail || '-',
    },
    {
      id: 'id',
      align: 'center',
      label: 'Ação',
      minWidth: 100,
      format: (id) => (
        <BtnDelete
          onClick={(e) => {
            e.preventDefault();
            removeStudentFromList(id);
          }}
        />
      ),
    },
  ];

  const handlerFilter = (options, austate) => {
    return tipo === 1 ? createFilterOptions(options, austate) : createFilterOptionsTurma(options, austate);
  };

  const handlerSetDefaultPlan = (id) => {
    const contract = standContracts.find((plan) => plan.id == id);

    setStandDefalt(id);

    if (contract) {
      const obs = contract.observation ? contract.observation : (contract.description ? contract.description : '');
      mensalForm.setState({
        id: { value: contract.id, error: '' },
        name: { value: contract.name, error: '' },
        dataVencimento: { value: contract.date_start, error: '' },
        dataInicio: { value: contract.contract_date_start, error: '' },
        dataFim: { value: contract.date_end, error: '' },
        paymentMethod: { value: contract.payment, error: '' },
        paymentCycle: { value: contract.cycle, error: '' },
        service: { value: contract.id_service, error: '' },
        seguro: { value: '', error: '' },
        tipoDesconto: { value: !contract.discount_type ? 'NONE' : contract.discount_type, error: '' },
        desconto: { value: contract.discount, error: '' },
        valor: { value: contract.value, error: '' },
        valorOriginal: { value: contract.original_value, error: '' },
        valorComSeguro: { value: contract.value, error: '' },
        tipoJuros: { value: !contract.late_interest_type ? 'NONE' : contract.late_interest_type, error: '' },
        valorJuros: { value: contract.late_interest, error: '' },
        tipoMulta: { value: !contract.late_fee_type ? 'NONE' : contract.late_fee_type, error: '' },
        valorMulta: { value: contract.late_fee, error: '' },
        statusContrato: { value: contract.status, error: '' },
        cargaHora: { value: contract.hours, error: '' },
        faturado: { value: 'N', error: '' },
        observacao: { value: obs, error: '' },
        novoContrato: { value: 0, error: '' },
      });
    } else {
      mensalForm.setState(stateMensalSchema); //reset form
    }
  };

  return (
    <>
      {sendSave ? (
        <>
          <ContractNotification results={progress} total={selected.length} />
        </>
      ) : (
        <div className='card p-0 flex-column'>
          <div className='card_header w-100 flex-column align-items-center pt-3'>
            <h2 className='card_title'>Novo Contrato</h2>
            <BtnBack
              onClick={(e) => {
                history.push('/contracts-student-school');
              }}
              style={{
                float: 'right',
                position: 'absolute',
                left: 35,
                marginTop: 5,
              }}
            />
          </div>
          <hr />
          {errors.map((err, k) => (
            <MessageDangerMarginTop
              key={`err-${k}`}
              title='Atenção!'
              description={t(`${err.error.replace(/\[.*?\]/g, '').trim()}`)}
            />
          ))}
          {errorServer.map((item, k) => (
            <MessageDangerMarginTop title='Atenção!' description={item} key={`error_${k}`} />
          ))}
          {isDone === false ? (
            <>
              <div className='row mb-2 mx-3'>
                <div className='col-md-5 col-sm-12'>
                  <div className='form_group'>
                    <label htmlFor='news' className='card_details_label'>
                      {t('Classes')}
                    </label>
                    <select
                      required
                      value={filterClass}
                      className='form_control select'
                      onChange={(e) => setFilterClass(e.target.value)}
                    >
                      <option value=''>Selecione uma turma</option>
                      {turmas.map((item, i) => {
                        return (
                          <option key={`mc${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className='col'>
                  <label className='card_details_label'>Selecionar Alunos: *</label>

                  <Autocomplete
                    key={autokey}
                    classes={classes}
                    options={
                      tipo == 1
                        ? _students.filter((u) => !studentsList.students.value.find((t) => t.id === u.id))
                        : turmas
                    }
                    getOptionLabel={(option) => option.name}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    filterOptions={handlerFilter}
                    onChange={(event, user, detail) => {
                      if (detail === 'select-option') {
                        const {
                          idUser = undefined,
                          name,
                          usuBirth = undefined,
                          athEmail = undefined,
                          usuEmail = undefined,
                          students = undefined,
                          turma_id = undefined,
                          turma = undefined,
                        } = user;
                        if (name === 'TODOS') {
                          studentsList.students.value.push(..._students.filter((u) => u.name !== 'TODOS'));
                        }

                        if (Array.isArray(students)) {
                          studentsList.students.value.push(...students);
                        }

                        if (idUser) {
                          studentsList.students.value.push({
                            idUser,
                            id: idUser,
                            name,
                            usuBirth,
                            athEmail,
                            usuEmail,
                            turma_id,
                            turma,
                          });
                        }

                        setSelected(
                          studentsList.students.value
                            .filter((u) => u.name !== 'TODOS')
                            .unique('id')
                            .map((n) => n.id)
                        );
                        setAutoKey(moment().toISOString());
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          tipo == 1
                            ? 'Pesquisar aluno por nome, cpf ou email ou data de nascimento...'
                            : 'Pesquisar turma por nome...'
                        }
                        variant='outlined'
                        helperText={
                          studentsList.students.value.length > 0 && selected.length === 0
                            ? 'Selecione os alunos para prosseguir há criação da cobrança!'
                            : ''
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <TableContainer style={{ maxHeight: 500, minHeight: 500 * 0.8 }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell padding='checkbox'>
                        <CheckboxCustom
                          indeterminate={selected.length > 0 && selected.length < studentsList.students.value.length}
                          checked={
                            studentsList.students.value.length > 0 &&
                            selected.length === studentsList.students.value.length
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                      </StyledTableCell>
                      {columnStudent.map((column) => (
                        <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentsList.students.value.unique('id').map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role='checkbox'
                          aria-checked={isItemSelected}
                          key={row.id}
                          selected={isItemSelected}
                          tabIndex={-1}
                        >
                          <TableCell padding='checkbox'>
                            <CheckboxCustom checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                          </TableCell>
                          {columnStudent.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {typeof column.format === 'function' ? column.format(value, row) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className='row align-items-center justify-content-center my-4'>
                {/*    <div className='col-md-5 col-sm-10'>
                  <ButtonRounded
                    className='w-100'
                    variant='outline'
                    color='var(--main-color)'
                    isBig={true}
                    onClick={handlerFinally}
                  >
                    {t('Back')}
                  </ButtonRounded>
                </div> */}
                <div className='col-md-5 col-sm-10'>
                  <ButtonRounded
                    isDisabled={selected.length === 0}
                    isLoading={sendSave}
                    onClick={_onSubmitForm}
                    className='w-100'
                    isBig={true}
                  >
                    {t('Continue')}
                  </ButtonRounded>
                </div>
              </div>
            </>
          ) : (
            <form ref={formRefer} action='' className='form_card' noValidate>
              <div className='row px-4'>
                <p className='lead'>Total de alunos selecionados: {selected.length}</p>
              </div>
              <div className='row p-3'>
                <div className='col-12'>
                  <label htmlFor='schedule' className='card_details_label'>
                    Usar um Plano Padrão
                  </label>
                  <select
                    className={`form_control select`}
                    value={standDefault}
                    onChange={(e) => handlerSetDefaultPlan(e.target.value)}
                  >
                    <option value=''>Selecionar Plano Padrão</option>
                    {standContracts.map((item, i) => (
                      <option key={`stdplan${i}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='row p-3'>
                <Mensal useForm={mensalForm} />
              </div>
              <div className='row align-items-center justify-content-center my-5'>
                <div className='col-md-5 col-sm-10'>
                  <ButtonRounded
                    className='w-100'
                    variant='outline'
                    color='var(--main-color)'
                    isBig={true}
                    onClick={() => setIsDone(false)}
                  >
                    {t('Back')}
                  </ButtonRounded>
                </div>
                <div className='col-md-5 col-sm-10'>
                  <ButtonRounded
                    isDisabled={selected.length === 0}
                    onClick={mensalForm.handleOnSubmit}
                    className='w-100'
                    isBig={true}
                  >
                    {t('Continue')}
                  </ButtonRounded>
                </div>
              </div>
            </form>
          )}
        </div>
      )}
    </>
  );
};

//form states ----------------------------------------------------------------------------------------------
const stateMensalSchema = {
  id: { value: 0, error: '' },
  dataVencimento: { value: '', error: '' },
  dataInicio: { value: moment().format('YYYY-MM-DD'), error: '' },
  dataFim: { value: '', error: '' },
  paymentMethod: { value: '', error: '' },
  paymentCycle: { value: 'MONTHLY', error: '' },
  service: { value: '', error: '' },
  seguro: { value: '', error: '' },
  tipoDesconto: { value: 'NONE', error: '' },
  desconto: { value: '', error: '' },
  valor: { value: '', error: '' },
  valorOriginal: { value: '', error: '' },
  valorComSeguro: { value: '', error: '' },
  tipoJuros: { value: 'NONE', error: '' },
  valorJuros: { value: '', error: '' },
  tipoMulta: { value: 'NONE', error: '' },
  valorMulta: { value: '', error: '' },
  statusContrato: { value: 'Ativo', error: '' },
  cargaHora: { value: '', error: '' },
  observacao: { value: '', error: '' },
  novoContrato: { value: 0, error: '' },
  faturado: { value: 'N', error: '' },
  anexo: { value: '', error: '' },
};

const stateSchema = {
  students: { value: [], error: '' },
};

const validationStateSchema = {};

//material ui custom styles --------------------------------------------------------------------------------
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'white',
    color: theme.palette.common.black,
    border: 'none',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: '#f4f0f0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'var(--main-color)',
    },
  },
});

const CheckboxCustom = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: 'var(--main-color)',
    },
  },
  checked: {},
}))(Checkbox);

//private components  --------------------------------------------------------------------------------------
const BtnDelete = ({ fcolor = '#FF2929', w = 16, h = 16, onClick }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer' }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z'
        fill={fcolor}
      />
    </svg>
  </span>
);

const BtnBack = ({ fcolor = '#1C1B1F', w = 16, h = 16, onClick, style }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer', ...style }} role='button'>
    <svg width={w} height={h} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z' fill={fcolor} />
    </svg>
  </span>
);

export default ContractForm;
