import React, { forwardRef, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import Input from '../../components/inputs/Input';
import Loading from '../../components/Loading';
import { ModalWithMethods } from '../../components/modal/Modal';
import { handleOnChange } from '../../utils/utils';

const ModalCTsManager = ({ trainingCenters, isLoading, onSubmit, onDelete, ...props }, ref) => {
  const { t } = useTranslation();

  const [isSending, setIsSending] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    name: '',
  });
  const [sweetAlert, setSweetAlert] = useState(null);

  const onChangeForm = handleOnChange(setFormData);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    await onSubmit(formData);
    setIsSending(false);
    setFormData({});
  };

  const handleOnDelete = (id) => {
    setSweetAlert(
      <SweetAlert
        danger
        title={t('Attention_exclamation')}
        onConfirm={() => {
          setSweetAlert(null);
          deleteTrainingCenter(id);
        }}
        confirmBtnText={t('Yes')}
        onCancel={() => setSweetAlert(null)}
        showCancel
        cancelBtnText={t('Cancel')}
      >
        {t('StudentPage.SystemMessage.ConfirmDeleteTrainingCenter')}
      </SweetAlert>
    );
  };

  const deleteTrainingCenter = async (id) => {
    setIsSending(true);
    await onDelete({ id });
    setIsSending(false);
  };

  const setToEdit = ({ id, name }) => {
    setFormData({ id, name });
  };

  return (
    <ModalWithMethods {...props} ref={ref} title={''} size={'lg'}>
      <form action='' autoComplete='new-password' method='post' className='form_modal' onSubmit={handleOnSubmit}>
        <div className='form_group'>
          <Input required label={t('TrainingCenter')} name='name' value={formData} onChange={onChangeForm} />

          {formData.id == null ? (
            <button className='btn_card' style={{ marginRight: 5 }} disabled={isSending}>
              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
              </svg>
              {t('Include')}
            </button>
          ) : (
            <>
              <button className='btn_card' style={{ marginRight: 5 }} disabled={isSending}>
                <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                </svg>
                {t('EditTrainingCenter')}
              </button>
              <button
                className='btn_card'
                style={{ marginRight: 5, backgroundColor: 'gray' }}
                type='button'
                onClick={() => setFormData({})}
              >
                <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                </svg>
                {t('Clear')}
              </button>
            </>
          )}
        </div>
      </form>
      {isLoading ? (
        <div className='d-flex mt-3 justify-content-center'>
          <Loading type='1' />
        </div>
      ) : (
        <div style={{ margin: '10px 10px' }}>
          <p style={{ textAlign: 'center', width: '100%', marginBottom: 20 }}>
            <b>{trainingCenters.length === 0 ? t('NoRegisteredTrainingCenter') : t('ListRegisteredTrainingCenters')}</b>
          </p>

          {trainingCenters.map((trainingCenter, i) => (
            <>
              <div
                className='row'
                key={i}
                style={formData.id === trainingCenter.id ? { border: '1px solid orange', borderRadius: '50px' } : {}}
              >
                <div className='col'>{trainingCenter.name}</div>
                <div className='col' style={{ textAlign: 'right', color: 'brown', fontWeight: 600 }}>
                  <span
                    className='mr-3'
                    onClick={() => setToEdit(trainingCenter)}
                    style={{ cursor: 'pointer', color: 'orange' }}
                  >
                    {t('Change')}
                  </span>
                  <span onClick={() => handleOnDelete(trainingCenter.id)} style={{ cursor: 'pointer' }}>
                    {t('Delete')}
                  </span>
                </div>
              </div>
              <hr style={{ borderBottom: '1px solid #f4f4f4' }} />
            </>
          ))}
        </div>
      )}
      {isSending && (
        <p style={{ textAlign: 'center' }}>
          {t('SystemMessage.Loading.Processing')} <Loading type='4' style={{ color: 'green' }} />
        </p>
      )}

      {sweetAlert}
    </ModalWithMethods>
  );
};

export default forwardRef(ModalCTsManager);
