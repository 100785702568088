import React from 'react';
import '../../../utils/extendsType';

import StudentFormContext from '../../../Context/StudentFormContext';
import ContractForm from './ContractForm';
import CobrancaFaturarContext from '../../../Context/CobrancaFaturarContext';

//component
const ContractMulti = () => {
  //hooks states --------------------------------------------------------------------------------------

  return (
    <CobrancaFaturarContext>
      <StudentFormContext>
        <ContractForm />
      </StudentFormContext>
    </CobrancaFaturarContext>
  );
};

export default ContractMulti;
