import api from './api';

export const fetchLessonContentSportsBySport = async () => {
  try {
    const { data } = await api.get('lesson-content-sports/1');
    return data;
  } catch (error) {
    return { success: false, error: error }
  }
};
