/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { ModalWithMethods } from '../modal/Modal';
import { BsTrash } from 'react-icons/bs';
import Loading from '../Loading';
import { createWhatsAppSession, initWhatsAppSession, removeWhatsAppSession } from '../../services/WhatsAppService';
import { SendIcon } from '../icons/SendIcon';

const WhatsAppComponent = (
  { children, sendMessageWhatsApp, onClose, sendButtonText, extraSend = [], ...props },
  ref
) => {
  const school = JSON.parse(localStorage.getItem('school'));

  const { t } = useTranslation();

  const [data, setData] = useState({ conected: false, qrCode: undefined, session: null });
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [intervalState, setIntervalState] = useState(null);

  const sweetAlertRemoveSessionRef = useRef(null);
  const sweetAlertSuccessRef = useRef(null);
  const sweetAlertFailRef = useRef(null);

  useEffect(() => {
    return () => {
      closeWhatsApp();
    };
  }, []);

  const newQrCodeWhatsApp = async () => {
    setIsLoading(true);
    setIsSending(true);
    initWhatsAppSession(school.id);
    openWhatsApp();
    setIsSending(false);
  };

  const openWhatsApp = async () => {
    setIsLoading(true);

    window.scrollTo(0, 0);

    setIntervalState(
      setInterval(async () => {
        const getStatusWhatsApp = await createWhatsAppSession(school.id, setData);
        
        if(getStatusWhatsApp.response?.status === 404){
          clearInterval(intervalState);
          setIntervalState(null);
          setData({ conected: false, qrCode: undefined, session: null });
          setIsLoading(false);
        }
        
        if (getStatusWhatsApp.isConected) {
          setIsLoading(false);
        }
      }, 2000)
    );
  
    setIsSending(false);
  };

  const closeWhatsApp = async () => {
    clearInterval(intervalState);
    setIntervalState(null);
    setData({ conected: false, qrCode: undefined, session: null });
    onClose && onClose();
  };

  const removeSessionWhatsApp = async () => {
    setIsLoading(true);
    await removeWhatsAppSession(school.id);

    clearInterval(intervalState);
    setIntervalState(null);
    setData({ conected: false, qrCode: undefined, session: null });

    setIsLoading(false);
  };

  const handleSendMessageWhatsApp = async (submitter) => {
    setIsSending(true);

    const { success, message } = await submitter();

    if (success) {
      sweetAlertSuccessRef.current.showSweetAlert(message);
    } else {
      sweetAlertFailRef.current.showSweetAlert(message);
    }
    setIsSending(false);
  };

  return (
    <ModalWithMethods
      {...props}
      ref={ref}
      modalStyle={{ alignItems: 'center' }}
      modalContentStyle={{ paddingBottom: '20px', minHeight: '560px' }}
      onShow={openWhatsApp}
      onClose={closeWhatsApp}
    >
      <h2 className='whatsapp-title' style={{ paddingRight: '80px' }}>
        <img className='whatsapp-title-img' src='/img/whatsapp/qr-code--v1.png' /> {t('WhatsAppComponent.Title')}
      </h2>

      <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        {data.qrCode && <img key='qrcode' height='250px' alt='QR CODE' src={data.qrCode} />}
      </div>

      {isLoading ? (
        <React.Fragment key='5'>
          <p className='loading-card'>
            <Loading type='4' />
          </p>
          <button
            className='btn_card mx-auto button_whatsapp'
            onClick={() => sweetAlertRemoveSessionRef.current.showSweetAlert()}
          >
            <BsTrash />
            {t('WhatsAppComponent.RemoveSession')}
          </button>
        </React.Fragment>
      ) : data.conected ? (
        <React.Fragment>
          {children}
          {isSending ? (
            <p className='loading-card'>
              <Loading type='4' />
            </p>
          ) : (
            <>
              <button
                className='btn_card mx-auto'
                style={{ margin: 8 }}
                onClick={() => handleSendMessageWhatsApp(sendMessageWhatsApp)}
              >
                <SendIcon />
                {sendButtonText ? sendButtonText : t('SendLink')}
              </button>
              {extraSend &&
                extraSend.map(({ title, onSend }, i) => (
                  <button
                    key={`WhatsAppComponent_extraSend_${i}`}
                    className='btn_card mx-auto'
                    style={{ margin: 8 }}
                    onClick={() => handleSendMessageWhatsApp(onSend)}
                  >
                    <SendIcon />
                    {title}
                  </button>
                ))}
            </>
          )}
          <button
            className='btn_card mx-auto'
            style={{ margin: 8 }}
            onClick={() => sweetAlertRemoveSessionRef.current.showSweetAlert()}
          >
            <BsTrash />
            {t('WhatsAppComponent.RemoveSession')}
          </button>
        </React.Fragment>
      ) : data.qrCode ? (
        <React.Fragment>
          <p className='loading-card'>
            <Loading type='4' />
          </p>
          <button
            className='btn_card mx-auto'
            style={{ margin: 8 }}
            onClick={() => sweetAlertRemoveSessionRef.current.showSweetAlert()}
          >
            <BsTrash />
            {t('WhatsAppComponent.RemoveSession')}
          </button>
        </React.Fragment>
      ) : (
        <button className='btn_card mx-auto' style={{ margin: 8 }} onClick={() => newQrCodeWhatsApp()}>
          <svg viewBox='0 0 512.043 512.043' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g>
              <path d='m206.669 60.026h-146.65v146.651h146.651v-146.651zm-30 116.651h-86.65v-86.651h86.651v86.651z' />
              <path d='m118.344 118.351h30v30.002h-30z' />
              <path d='m60.019 452.017h146.651v-146.651h-146.651zm30-116.651h86.651v86.651h-86.651z' />
              <path d='m452.039 320.366h-30v101.651h-29.374v-22.317h-30v22.317h-36.621v30h125.995z' />
              <path d='m118.344 363.69h30v30.002h-30z' />
              <path d='m452.039 60.026h-146.651v146.651h146.651zm-30 116.651h-86.651v-86.651h86.651z' />
              <path d='m363.714 118.351h30v30.002h-30z' />
              <path d='m120.021 241.021h86.649v30h-86.649z' />
              <path d='m60.018 241.021h30.002v30h-30.002z' />
              <path d='m236.672 241.021h111.65v30h-111.65z' />
              <path d='m452.039 290.364v-49.343h-73.714v30h43.714v19.343z' />
              <path d='m241.04 120.029h30v86.649h-30z' />
              <path d='m241.04 60.025h30v30.002h-30z' />
              <path d='m271.04 392.013h22.317v-30h-22.317v-56.648h-30v146.652h55.002v-30h-25.002z' />
              <path d='m332.663 357.683h30v-22.317h24.343v-30h-78.686v30h24.343z' />
              <path d='m407.007.022v30h75.036v75.006h30v-105.006z' />
              <path d='m30 30.022h75.036v-30h-105.036v105.006h30z' />
              <path d='m482.043 482.021h-75.036v30h105.036v-105.006h-30z' />
              <path d='m30 407.015h-30v105.006h105.036v-30h-75.036z' />
            </g>
          </svg>
          {t('WhatsAppComponent.NewQrCode')}
        </button>
      )}

      <h3 className='modal_title text-center whatsapp-rodape'>
        <svg
          style={{ width: '40px', paddingRight: '10px' }}
          viewBox='0 0 418.135 418.135'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            style={{ fill: '#7AD06D' }}
            d='M198.929,0.242C88.5,5.5,1.356,97.466,1.691,208.02c0.102,33.672,8.231,65.454,22.571,93.536
            L2.245,408.429c-1.191,5.781,4.023,10.843,9.766,9.483l104.723-24.811c26.905,13.402,57.125,21.143,89.108,21.631
            c112.869,1.724,206.982-87.897,210.5-200.724C420.113,93.065,320.295-5.538,198.929,0.242z M323.886,322.197
            c-30.669,30.669-71.446,47.559-114.818,47.559c-25.396,0-49.71-5.698-72.269-16.935l-14.584-7.265l-64.206,15.212l13.515-65.607
            l-7.185-14.07c-11.711-22.935-17.649-47.736-17.649-73.713c0-43.373,16.89-84.149,47.559-114.819
            c30.395-30.395,71.837-47.56,114.822-47.56C252.443,45,293.218,61.89,323.887,92.558c30.669,30.669,47.559,71.445,47.56,114.817
            C371.446,250.361,354.281,291.803,323.886,322.197z'
          />
          <path
            style={{ fill: '#7AD06D' }}
            d='M309.712,252.351l-40.169-11.534c-5.281-1.516-10.968-0.018-14.816,3.903l-9.823,10.008
            c-4.142,4.22-10.427,5.576-15.909,3.358c-19.002-7.69-58.974-43.23-69.182-61.007c-2.945-5.128-2.458-11.539,1.158-16.218
            l8.576-11.095c3.36-4.347,4.069-10.185,1.847-15.21l-16.9-38.223c-4.048-9.155-15.747-11.82-23.39-5.356
            c-11.211,9.482-24.513,23.891-26.13,39.854c-2.851,28.144,9.219,63.622,54.862,106.222c52.73,49.215,94.956,55.717,122.449,49.057
            c15.594-3.777,28.056-18.919,35.921-31.317C323.568,266.34,319.334,255.114,309.712,252.351z'
          />
        </svg>
        {data.conected ? (
          <b style={{ color: '#7AD06D' }}>{t('Connected')}</b>
        ) : (
          <b style={{ color: 'red' }}>{t('Disconnected')}</b>
        )}
      </h3>

      <SweetAlertRemoveSession ref={sweetAlertRemoveSessionRef} removeSession={removeSessionWhatsApp} />
      <SweetAlertSuccess ref={sweetAlertSuccessRef} />
      <SweetAlertFail ref={sweetAlertFailRef} />
    </ModalWithMethods>
  );
};

const SweetAlertRemoveSession = forwardRef(({ removeSession }, ref) => {
  const { t } = useTranslation();

  const [isShow, setIsShow] = useState(false);

  useImperativeHandle(ref, () => ({
    showSweetAlert: () => setIsShow(true),
    hideSweetAlert: () => setIsShow(false),
  }));

  return (
    <SweetAlert
      key='2'
      show={isShow}
      warning
      title={t('WhatsAppComponent.RemoveSessionConfirmation')}
      onConfirm={() => {
        removeSession();
        setIsShow(false);
      }}
      customButtons={
        <React.Fragment>
          <button
            className='btn_card mx-auto'
            style={{ margin: 8, color: '#ba8820', backgroundColor: 'white' }}
            onClick={() => {
              removeSession();
              setIsShow(false);
            }}
          >
            {t('WhatsAppComponent.RemoveSession')}
          </button>
          <button
            className='btn_card mx-auto'
            style={{ margin: 8, backgroundColor: 'black', borderColor: 'black' }}
            onClick={() => {
              setIsShow(false);
            }}
          >
            {t('Back')}
          </button>
        </React.Fragment>
      }
      focusCancelBtn
    >
      {t('WhatsAppComponent.RemoveSessionInstruction')}
    </SweetAlert>
  );
});

const SweetAlertSuccess = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [isShow, setIsShow] = useState(false);
  const [message, setMessage] = useState('');

  const hideSweetAlert = () => {
    setMessage('');
    setIsShow(false);
  };

  useImperativeHandle(ref, () => ({
    showSweetAlert: (message) => {
      setMessage(message);
      setIsShow(true);
    },
    hideSweetAlert: hideSweetAlert,
  }));

  return (
    <SweetAlert
      key='3'
      show={isShow}
      success
      title={message}
      onConfirm={() => {}}
      onCancel={() => hideSweetAlert()}
      customButtons={
        <React.Fragment>
          <button
            className='btn_card mx-auto'
            style={{ margin: 8, backgroundColor: 'black', borderColor: 'black' }}
            onClick={() => {
              setIsShow(false);
            }}
          >
            {t('Back')}
          </button>
        </React.Fragment>
      }
    ></SweetAlert>
  );
});

const SweetAlertFail = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [isShow, setIsShow] = useState(false);
  const [message, setMessage] = useState('');

  const hideSweetAlert = () => {
    setMessage('');
    setIsShow(false);
  };

  useImperativeHandle(ref, () => ({
    showSweetAlert: (message) => {
      setMessage(message);
      setIsShow(true);
    },
    hideSweetAlert: hideSweetAlert,
  }));

  return (
    <SweetAlert
      key='4'
      title=''
      show={isShow}
      onConfirm={() => {}}
      onCancel={hideSweetAlert}
      customButtons={
        <React.Fragment>
          <button
            className='btn_card mx-auto'
            style={{ margin: 8, backgroundColor: 'black', borderColor: 'black' }}
            onClick={hideSweetAlert}
          >
            {t('Back')}
          </button>
        </React.Fragment>
      }
    >
      {message}
    </SweetAlert>
  );
});

export default forwardRef(WhatsAppComponent);
