import React from 'react';
import { getNestedPropByIndex } from '../../utils/utils';

const DateIntervalRounded = ({
  className,
  label,
  startDateName,
  startDateValue,
  startDateOnChange,
  endDateName,
  endDateValue,
  endDateOnChange,
  type,
  style,
  styleContainer,
  styleWrapper,
  ...props
}) => {
  return (
    <div className={'form_group ' + (className || '')} style={{ alignItems: 'center', ...styleContainer }}>
      {label && (
        <label className='card_details_label' style={{ fontSize: '1rem' }}>
          {label}
        </label>
      )}
      <label
        className='w-100 input-rounded d-flex flex-row align-items-center'
        style={{ overflow: 'hidden', backgroundColor: '#fff', ...styleWrapper }}
      >
        <input
          {...props}
          type={type}
          className='form_control pl-0'
          name={startDateName}
          value={
            typeof startDateValue === 'object' && startDateName
              ? getNestedPropByIndex(startDateValue, startDateName)
              : startDateValue
          }
          onChange={startDateOnChange}
          style={{ border: 'none', marginTop: 0 }}
        />
        <span className='text-bold'>à</span>
        <input
          {...props}
          type={type}
          className='form_control px-0'
          name={endDateName}
          value={
            typeof endDateValue === 'object' && endDateName
              ? getNestedPropByIndex(endDateValue, endDateName)
              : endDateValue
          }
          onChange={endDateOnChange}
          style={{ border: 'none', marginTop: 0, ...style }}
        />
      </label>
    </div>
  );
};

export default DateIntervalRounded;
