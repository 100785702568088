import axios from 'axios';
import { baseURL } from '../utils/api';

const api = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('@token')}`,
  },
});

api.interceptors.request.use((config) => {
  if (config?.headers) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('@token')}`;
  }

  return config;
});

export const base_url = baseURL;

export const request = axios.create({ baseURL: baseURL });

export default api;
