import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

const MoneyMask = ({ value, onChange, className = 'form_control', ...rest }) => {
  const { t } = useTranslation();

  return (
    <NumberFormat
      {...rest}
      type='tel'
      className={className}
      isNumericString
      format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })}
      value={!Number.isNaN(value) ? value * 100 : ''}
      onValueChange={(e) => onChange(!Number.isNaN(e.floatValue) ? e.floatValue / 100 : null)}
    />
  );
};

export default MoneyMask;
