import React, { useEffect, useState }  from 'react'
import { Redirect } from 'react-router-dom'
import Loading from '../../../components/Loading'
import Api from '../../../utils/api'
import axios from 'axios'
import ReactGA from 'react-ga'

import './css/style.css'
import './css/swiper.css'

const Sign = (props) => {

   const [user_id, setUserID] = useState("")
   const [email, setEmail] = useState("")
   const [loading, setLoading] = useState(false)
   const [validEmailMessage, setValidEmailMessage] = useState("")
   const [goPayment, setGoPayment] = useState(false)
   const baseURL = Api()
   
   useEffect(() => {
      
      ReactGA.initialize('GTM-5V5LZV5')
      ReactGA.pageview(window.location.pathname)

      if ( props.location.query ) {
         setUserID(props.location.query.user_id)
         
         ReactGA.event({
            category: 'Botão ou Banner - Upgrade de Conta',
            action: 'Click no banner ou no botão de upgrade na home do atleta'
         })

      } else {
         
         const session = localStorage.getItem('user')

         if ( session !== null ) {
            const { id } = JSON.parse(session)
            setUserID(id)
         }

      }
      
      window.scrollTo(0,0)

   },[])
   
   const openValidEmail = async() => {
      document.getElementById('validEmail').style.display='flex'
   }

   const closeValidEmail = () => {
      document.getElementById('validEmail').style.display='none'
   }

   const openVip = async () => {

      if ( email == "" ) {
         openValidEmail(true)
         setValidEmailMessage("Informe o seu email melhor e-mail")
      } else if ( email.indexOf('@') !== -1 && email.indexOf('.') !== -1) {

         await store()
         
      } else {
         openValidEmail(true)
         setValidEmailMessage("E-mail inválido")
      }
   }

   const closeVip = () => {
      document.getElementById('email').style.display='none'
   }

   const store = async () => {
      setLoading(true)
      const resp = await axios.post(baseURL + 'vip', { user_id: user_id, email, created_at: new Date().toISOString().substr(0,10) })
      setLoading(false)

      if ( !resp.data.success ){
         openValidEmail(true)
         setValidEmailMessage(resp.data.message)
      } else {
         setEmail("")
         document.getElementById('email').style.display='flex'
      }
   }

   if ( goPayment ) {
      ReactGA.event({
         category: 'Botão - Quero assinar agora',
         action: 'Click no botão quero assinar agora na página principal de assinatura'
      })

      return <Redirect to={{
         pathname: '/pagamento-assinatura',
         state: {
            user_id: user_id
         }
      }} />
   }

   return (
      <React.Fragment>
         <div className="container-fluid bg bg_header">
            <div className="row">
               <div className="container">
                  <div className="row">
                     <div className="header">
                        <img className="logo_header" src="../../img/sign/logo.svg" />
                     </div>
                  </div>
                  <div className="container_sociais">
                     <div className="content_sociais">
                        <a href="https://www.facebook.com/Fensor-106752027673649/?modal=admin_todo_tour" target="_blank">
                           <img className="img_sociais" src="../../img/sign/facebook.svg" />
                        </a>
                     </div>
                     <div className="content_sociais_2">
                        <a href="https://twitter.com/Fensor3" target="_blank">
                           <img className="img_sociais" src="../../img/sign/twitter.svg" />
                        </a>
                     </div>
                     <div className="content_sociais_2">
                        <a href="https://www.instagram.com/fensor.br/" target="_blank">
                           <img className="img_sociais" src="../../img/sign/instagram.svg" />
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="container-fluid onda">
            <div className="row jogador">
               <div className="container">
                  <div className="row">
                     <div className="container_jogador">
                        <div className="content_jogador">
                           <img className="img_jogador" src="../../img/sign/jogador.png" />
                        </div>
                        <h1 className="title_jogador">MAIOR VISIBILIDADE</h1>
                        <p className="text_jogador">Somos a ponte que conecta você aos maiores clubes de futebol do mundo.
                        Nascemos com o objetivo de realizar o sonho de milhares de jovens espalhados pelo Brasil.
                        Nossa plataforma possibilita que o atleta apareça e seja encontrado através de ações como
                        peneiras, seletivas oficiais dos clubes brasileiros, internacionais e eventos exclusivos.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="container-fluid bg bg_seletivas">
            <div className="row">
               <div className="container">
                  <div className="row">
                     <div className="container_seletivas">
                        <div className="content_seletivas">
                           <h1 className="title_seletivas">ACESSO <br /> ÀS SELETIVAS</h1>
                           <p className="text_seletivas">Facilitamos o acesso dos atletas às seletivas, preparando-os com
                              processos devidamente elaborados que envolvem, além de desempenho e atributos físicos,
                              habilidade com bola nos pés e capacidade de entendimento de jogo, fazendo assim com que
                              os clubes tenham um novo olhar para a captação de novos talentos e detectem potenciais
                              jogadores.
                           </p>
                        </div>
                     </div>
                     <div className="content_seletivas_meio">
                        <p className="text_call_to_action">ENTRE PARA NOSSA LISTA VIP E RECEBA CONTEÚDOS EXCLUSIVOS</p>
                        <form className="call_to_action">
                           <input className="input_footer footer_placeholder" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Escreva aqui o seu melhor e-mail" />
                           {
                              !loading &&
                              <a className="btn_footer" onClick={openVip}>
                                 <img className="img_footer_enviar" style={{marginTop: '20%'}} src="../../img/sign/enviar.svg" />
                              </a>
                           }
                           {
                              loading &&
                              <div style={{padding: 20}}><Loading type="3" /></div>
                           }
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="container-fluid bg bg_branco_2">
            <div className="row">
               <div className="container">
                  <div className="col-12">
                     <div className="row" id="accordion">
                        <div className="container_vantagens">
                           <div className="content_vantagens">
                              <img className="img_vantagens" src="../../img/sign/sorteio.svg" />
                              <h1 className="title_vantagens">SORTEIOS</h1>
                              <p className="text_vantagens">Concorra a brindes, ingressos, produtos e uma série de benefícios exclusivos.</p>
                           </div>
                           <div className="content_vantagens">
                              <img className="img_vantagens" src="../../img/sign/evento.svg" />
                              <h1 className="title_vantagens">EVENTOS</h1>
                              <p className="text_vantagens">Participe dos melhores eventos e campeonatos de futebol.</p>
                           </div>
                           <div className="content_vantagens">
                              <img className="img_vantagens" src="../../img/sign/desconto.svg" />
                              <h1 className="title_vantagens">DESCONTOS</h1>
                              <p className="text_vantagens">Ganhe descontos incríveis em diversos produtos e serviços.</p>
                           </div>
                        </div>
                        <div className="container_camisa">
                           <div className="content_camisa">
                              <h1 className="title_camisa">BATA UM BOLÃO. ADQUIRA <br /> ITENS ÚNICOS!</h1>
                           </div>
                           <div className="content_camisa">
                              <img className="img_camisa" src="../../img/sign/camisa.svg" />
                              <p className="text_camisa">CAMISAS AUTOGRAFADAS</p>
                           </div>
                        </div>
                        <div className="swiper-container">,
                           <h1 className="title_faq">TIRE SUAS DÚVIDAS</h1>
                           <div className="swiper-wrapper">
                              <div className="swiper-slide">
                                 <div className="content_faq_slid">
                                    <div className="list_quest">
                                       <button type="button" className="box_faq collapsed" data-toggle="collapse" data-target="#box1">
                                          <p className="text_faq"> Por quanto tempo tenho acesso ao clube de benefício?</p>
                                       </button>
                                       <p className="collapse content_faq_box_mobile" id="box1">
                                          No plano mensal seria durante o mês e no plano anual seria no ano,
                                          podendo ser renovado no final do período.
                                       </p>
                                       <button type="button" className="box_faq collapsed" data-toggle="collapse" data-target="#box2">
                                          <p className="text_faq">Como tenho acesso à plataforma clube de benefício?</p>
                                       </button>
                                       <p className="collapse content_faq_box_mobile" id="box2">
                                          Assim que confirmar o pagamento da assinatura Fensor PRO, basta clicar
                                          no botão de assinatura PRO que fica no menu.
                                       </p>
                                       <button type="button" className="box_faq collapsed" data-toggle="collapse" data-target="#box3">
                                          <p className="text_faq">Posso compartilhar meu acesso com outra pessoa?</p>
                                       </button>
                                       <p className="collapse content_faq_box_mobile" id="box3">
                                          Não. Esse acesso é uso exclusivo da pessoa que tem seu CPF cadastrado na
                                          plataforma.
                                       </p>
                                       <button type="button" className="box_faq collapsed" data-toggle="collapse" data-target="#box4">
                                          <p className="text_faq">Existe algum custo para utilizar o Fensor?</p>
                                       </button>
                                       <p className="collapse content_faq_box_mobile" id="box4">
                                          Não existe custo para utilizar a plataforma, porém, se você deseja obter
                                          serviços diferenciados pode optar pela modalidade PRO.
                                       </p>
                                    </div>
                                    <div className="list_quest">
                                       <button type="button" className="box_faq collapsed" data-toggle="collapse" data-target="#box5">
                                          <p className="text_faq">Quais são as formas de pagamento? </p>
                                       </button>
                                       <p className="collapse content_faq_box_mobile" id="box5">
                                          Boleto e cartão de crédito
                                       </p>
                                       <button type="button" className="box_faq collapsed" data-toggle="collapse" data-target="#box6">
                                          <p className="text_faq">Tenho que ser assinante Fensor PRO para poder participar de experiências, produtos e sorteios Premium?</p>
                                       </button>
                                       <p className="collapse content_faq_box_mobile" id="box6">Sim, você precisa ter uma assinatura Fensor PRO válida.</p>
                                       <button type="button" className="box_faq collapsed" data-toggle="collapse" data-target="#box7">
                                          <p className="text_faq"> Quais são as vantagens de ser assinante Fensor PRO? </p>
                                       </button>
                                       <p className="collapse content_faq_box_mobile" id="box7">
                                          <p className="collapse content_faq_box_mobile" id="box7">Ter acesso em experiências exclusivas no futebol</p>
                                          <p className="collapse content_faq_box_mobile" id="box7">Ter desconto em lojas parceiras sobre futebol e farmácias</p> 
                                          <p className="collapse content_faq_box_mobile" id="box7">Ter descontos em convênio de saúde</p> 
                                          <p className="collapse content_faq_box_mobile" id="box7">Participar de sorteios ligados ao mundo do futebol</p>
                                          <p className="collapse content_faq_box_mobile" id="box7">Colocar vídeos ilimitados</p>
                                          <p className="collapse content_faq_box_mobile" id="box7">Colocar fotos ilimitadas</p>
                                       </p>
                                       <button type="button" className="box_faq collapsed" data-toggle="collapse" data-target="#box8">
                                          <p className="text_faq">Ainda tenho dúvidas, quais são os canais de atendimento?</p>
                                       </button>
                                       <p className="collapse content_faq_box_mobile" id="box8">
                                          Em caso de dúvida poderá entrar em contato pelo email
                                          <a>contato@fensor.com.br</a> que em breve responderemos.
                                       </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="container_assinar">
                     <h1 className="text_assinar">SEJA O MAIOR JOGADOR DO MUNDO</h1>
                     <p className="text_assinar_2">Assine agora o Fensor e você terá acesso a uma série de seletivas, eventos exclusivos, benefícios e recompensas incríveis que só um jogador de elite tem.</p>
                     <button className="btn_assinar" onClick={() => setGoPayment(true)}>
                        QUERO ASSINAR AGORA!
                     </button>
                  </div>
               </div>
            </div>
         </div>
         <footer>
            <div className="container-fluid footer bg_footer">
               <div className="row">
                  <div className="container">
                     <div className="col-12">
                        <div className="row">
                           <div className="container_footer">
                              <img className="img_footer" src="../../img/sign/logo.svg" />
                              <h1 className="title_footer">O PONTAPÉ INICIAL PARA A SUA CARREIRA PROFISSIONAL</h1>
                              <div className="content_seletivas_footer">
                                 <p className="text_call_to_action">ENTRE PARA NOSSA LISTA VIP E RECEBA CONTEÚDOS EXCLUSIVOS</p>
                                 <form className="call_to_action">
                                    <input className="input_footer footer_placeholder" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Escreva aqui o seu melhor e-mail" />
                                    {
                                       !loading &&
                                       <a className="btn_footer" onClick={openVip}>
                                          <img className="img_footer_enviar"  style={{marginTop: '20%'}}  src="../../img/sign/enviar.svg" />
                                       </a>
                                    }
                                    {
                                       loading &&
                                       <div style={{padding: 20}}><Loading type="3" /></div>
                                    }
                                 </form>
                              </div>
                              <p>
                                 <a className="text_footer_2" href="/docs/TERMOS DE USO ATLETA - FENSOR FLASH.pdf" download="/docs/TERMOS DE USO ATLETA - FENSOR FLASH.pdf">Termos de uso | </a>
                                 <a className="text_footer_2" href="/docs/POLÍTICA DE PRIVACIDADE - FENSOR FLASH.pdf" download="/docs/POLÍTICA DE PRIVACIDADE - FENSOR FLASH.pdf">Política de Privacidade</a>
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="container_footer_2">
                  <p>COPYRIGHT © 2020. Desenvolvido por Maui Comunicação.</p>
               </div>
            </div>
         </footer>
         <div id="email" className="modal">
            <div className="container_modal_3">
               <div className="col-6 bg_modal"></div>
               <div className="col-6">
                  <div className="content_modal">
                     <h1 className="title_modal">Obrigado!</h1>
                     <p className="text_modal">
                        Em breve <br />
                        entraremos em <br />
                        contato com você <br />
                        para passar maiores<br />
                        informações.
                     </p>
                     <p className="text_modal_2">Agora acesse<br /> nossa platatorma</p>
                     <a href="http://www.fensor.com.br" className="link_btn_modal">
                        <button className="btn_modal">ENTRAR</button>
                     </a>
                  </div>
               </div>
               <div className="close_modal">
                  <span onClick={closeVip} className="close" title="Close Modal">&times;</span>
               </div>
            </div>
         </div>
         <div id="validEmail" className="modal">
            <div className="container_modal_2">
               <div className="col-6 bg_modal"></div>
               <div className="col-6">
                  <div className="content_modal">
                     <h1 className="title_modal">Atenção!</h1>
                     <p className="text_modal">
                        {validEmailMessage}
                     </p>
                     <br />
                     <button className="btn_modal" onClick={closeValidEmail}>Ok, entendi!</button>
                  </div>
               </div>
               <div className="close_modal">
                  <span onClick={closeValidEmail} className="close" title="Close Modal">&times;</span>
               </div>
            </div>
         </div>
      </React.Fragment>
   )
}

export default Sign