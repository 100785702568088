import axios from 'axios';
import Api from '../utils/api';

const baseURL = Api();
const api = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('@token')}`,
  },
});

const getSuppliersSchool = async (schoolId) =>
  new Promise((response, reject) => {
    axios
      .get(baseURL + 'get-suppliers-school/' + schoolId)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

const saveSuppliersSchool = async ({
  school_id,
  name,
  cpf_cnpj,
  phone,
  email,
  type,
  zipCode,
  streetName,
  streetNumber,
  complement,
  neighborhood,
  city_id,
  user_id
}) => {
  const payload = {
    school_id,
    name,
    cpf_cnpj,
    phone,
    email,
    type,
    zipCode,
    streetName,
    streetNumber,
    complement,
    neighborhood,
    city_id,
    user_id
  };

  try {
    const { data } = await api.post('save-client-fornecedor-school', payload);
    return data;
  } catch (error) {
    return { error };
  }
};

const updateSuppliersSchool = async ({
  school_id,
  name,
  cpf_cnpj,
  phone,
  email,
  type,
  zipCode,
  streetName,
  streetNumber,
  complement,
  neighborhood,
  city_id,
  id
}) => {
  const payload = {
    school_id,
    name,
    cpf_cnpj,
    phone,
    email,
    type,
    zipCode,
    streetName,
    streetNumber,
    complement,
    neighborhood,
    city_id,
    id
  };

  try {
    const { data } = await api.post( 'update-client-fornecedor-school', payload);
    return data;
  } catch (error) {
    return { error };
  }
};

export { getSuppliersSchool, saveSuppliersSchool, updateSuppliersSchool };
