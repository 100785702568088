import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Footer from '../../components/athlete/Footer'
import Message from '../../components/Message'
import Loading from '../../components/Loading'
import Api from '../../utils/api'
import utils from '../../utils/utils'
import axios from 'axios'

const DetalhesSeletivaEvento = (props) => {
   const { MessageDanger, MessageSuccess } = Message()
   const baseURL = Api()
   const [item, setItem] = useState({})
   const [inscrito, setInscrito] = useState(props.location.state.inscrito)
   const [goDetails, setGoDetails] = useState(false)
   const [send, setSend] = useState(false)
   const [success, setSuccess] = useState(false)
   const [error, setError] = useState(false)
   const { replaceAll } = utils()
   
   if ( props.location.state == undefined )
      return <Redirect to={{pathname: '/'}} />
   
   const athlete = JSON.parse(localStorage.getItem('athlete'))
   const event = props.location.state.event
   
   let logo = ''
   if ( props.location.state.url.indexOf(".png") !== -1 ||
        props.location.state.url.indexOf(".jpg") !== -1 ||
        props.location.state.url.indexOf(".jpeg") !== -1) {
      logo = props.location.state.url
   } else {
      logo = props.location.state.url + '/' + event.comLogo
   }

   const pagamento = (e, item) => {
      e.preventDefault(e)

      if (  item.eveCobrarInscricao == 'N' ) {
         inscrever(item)
      } else {
         setItem(item)
         setGoDetails(true)
      }
   }

   const inscrever = async (event) => {

      setSend(true)
      setError(false)
      setSuccess(false)

      const data = {
         "event_id": event.id,
         "athlete_id": athlete.id
      }

      const response = await axios.post(baseURL + 'athlete-event-free', data)
      setSend(false)

      if ( response.data !== false ) {
         setSuccess(true)
         setInscrito(true)
      } else {
         setError(true)
      }

      window.scrollTo(0, 0);
   }

   if(goDetails)
      return <Redirect to={{
         pathname: '/pagamento-inscricao',
         state: {
            event: item, 
            url: logo
         }
      }} />

   return (
         <React.Fragment>
         <header className="navbar navbar-outro">
            <a href="/home-athlete" className="logo-navbar">
               <img src="../../img/logo.png" alt="Marca" />
            </a>
         </header>
      
         <main>
      
            <section className="banner">
               <div className="desc-sel">
                  {
                     error &&   
                     <MessageDanger type="1" title="Atenção" description="Não foi possível realizar sua pré-inscrição na seletiva. Tente novamente mais tarde ou entre em contado com a Fensor!"></MessageDanger>
                  }
                  {
                     success && 
                     <MessageSuccess type="1" title="Parabéns ;)" description="Sua pré-inscrição foi concluída com sucesso! Veja abaixo mais informações sobre esta seletiva."></MessageSuccess>
                  }
                  <div className="bloco-desc-sel">
                     <div className="col-1-desc-sel">
                        <div className="titulo-desc-sel">
                           <h2><b className="tip-extra">Seletiva:</b> {event.comCompanie}</h2>
                           <p>{event.eveDescricao}</p>
                        </div>
      
                        <div className="img-col-desc-sel">
                           <img src="../../img/icones/avatar.png" />
                           {
                              event.eveSexo == 'M'&&
                              <p>Futebol masculino</p>
                           }

                           {
                              event.eveSexo == 'F' &&
                              <p>Futebol feminino</p>
                           }

                           {
                              event.eveSexo != 'F' && event.eveSexo != 'M' &&
                              <p>Futebol masculino e feminino</p>
                           }
                        </div>
      
                        <div className="img-col-desc-sel">
                           <img src="../../img/icones/seta-3.png" />
                           <p>Categorias {replaceAll(event.categorias, ' - ', ', ')}</p>
                        </div>

                        <div className="img-col-desc-sel">
                           <img src="../../img/icones/ingresso.png" />
                           <p>{event.endereco_evento}</p>
                        </div>
      
                        <div className="img-col-desc-sel">
                           <img src="../../img/icones/no-check.png" />
                           <p>{event.eveData + " às " + event.eveHora}</p>
                        </div>
                     </div>
      
                     {
                        inscrito &&   
                        <div className="col-2-desc-sel">
                           <h3>Você ja está pré-inscrito</h3>
                           <div className="insc-col-2-desc-sel">
                              <img src="../../img/icones/ingresso.png" />
                              <p>Verifique as instruções abaico</p>
                           </div>
                           <Link to="/agenda-athlete">Voltar para sua Agenda</Link>
                        </div>
                     }
                     {
                        !inscrito &&   
                        <div className="col-2-desc-sel">
                           <h3>Garanta sua vaga</h3>
                           <div className="insc-col-2-desc-sel">
                              <img src="../../img/icones/ingresso.png" />
                              <p>Pré-inscrição necessária</p>
                           </div>
                           <br />
                           {
                              !send &&
                              <a href="#" onClick={(e) => pagamento(e, event)}>Fazer pré-inscrição</a>
                           }
                           {
                              send &&
                              <p>Aguarde...<Loading type="1"></Loading></p>
                           }
                        </div>
                     }
   
                  </div>
      
                  <div className="time-desc-sel">
                     <img src="../../../favicon.png" className="img-radius" />
                  </div>
      
                  <div className="bloco-banner-desc-sel">
                     <div className="banner-desc-sel">
                        
                     </div>
                  </div>
      
               </div>
            </section>
      
            <section className="bloco-principal">
               <div className="conteudo-bloco-principal">
      
                  <div className="bloco-1">
                     <div className="instrucoes">
                        <div className="titulo-seletivas">
                           <h2>Instruções</h2>
                        </div>
      
                        <div className="texto-instrucoes">
                           <p>{event.eveObservacao}</p>
      
                           <p>Os atletas que tiverem o melhor desempenho serão selecionados.</p>
      
                           <p>O primeiro passo para participar é fazer sua inscrição na plataforma do Labfut. As inscrições vão de {event.data_inicio_completa.replace(' ', ' a partir das ')} até {event.data_termino_completa.replace(' ', ' finalizando as ')} {event.eveCobrarInscricao == "S" ? " e possuem o valor único de R$ " + event.eveValor : ""}.</p>
      
                           <p>Após fazer sua inscrição você receberá um e-mail falando o dia, horário, local e com antecedência de pelo menos 5 dias. No dia da avaliação você vai precisar de alguns documentos importantes. Já deixe separado os seguintes documentos: (RG, CPF, atestado médico válido por 90 dias e autorização dos pais (para atletas menores de 18 anos).</p>
      
                           <p>Além dos documentos é importante que você leve um short preto, um meião preto, uma camisa, caneleira e chuteira. Não serão permitidos materiais esportivos de outros clubes.</p>
      
                           <hr />
                        </div>
      
                        <div className="nome-time-instrucoes">
                           <div className="col-time-instrucoes">
                              <div className="img-texto-intrucoes">
                                 <img src="../../../favicon.png" className="img-radius" />
                              </div>
                        
                              <div className="img-texto-intrucoes">
                                 {
                                    event.eveCobrarInscricao == "N" &&
                                    <h3>Seletiva {event.comCompanie}</h3>
                                 }
                                 {
                                    event.eveCobrarInscricao == "S" &&
                                    <h3>Evento {event.comCompanie}</h3>
                                 }
                                 
                                 <p>{event.comTelefone}</p>
                                 <p>{event.comEmail}</p>
                                 <p>fensor.com.br/sac</p>
                              </div>
                           </div>
      
                           <div className="col-1-time-instrucoes">
                              <a href="#" onClick={e => e.preventDefault(e)}>Entrar em contato</a>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="bloco-2">
                     <div className="conteudo-generico card-instrucoes">
                        {
                           inscrito &&
                           <div className="info-card-instrucoes">
                              {
                                 inscrito &&
                                 <h3>{event.status}</h3>
                              }
                              {
                                 !inscrito &&
                                 <h3>Pré-inscrição</h3>
                              }
                              {
                                 event.eveCobrarInscricao == "N" && 
                                 <p>Seletiva Gratuita - vagas limitadas</p>
                              }
                              {
                                 event.eveCobrarInscricao == "S" && 
                                 <p>Evento Cobrado - vagas limitadas</p>
                              }
         
                              <div className="btn-fixed-instrucoes">
                                 <Link to="/agenda-athlete">Voltar para sua Agenda</Link>
                              </div>
                           </div>
                        }
                        {
                           !inscrito &&
                           <div className="info-card-instrucoes">
                              <h3>Pré-inscrição</h3>
                              <p>Gratuito - vagas limitadas</p>
         
                              <div className="btn-fixed-instrucoes">
                                 {
                                    !send &&
                                    <a href="#" onClick={(e) => pagamento(e, event)}>Fazer pré-inscrição</a>
                                 }
                                 {
                                    send &&
                                    <p>Aguarde...<Loading type="1"></Loading></p>
                                 }
                              </div>
                              
                           </div>
                        }
                     </div>
      
                     <div className="conteudo-generico redes-instrucoes">
                        <div className="info-card-instrucoes">
                           <p>Compartilhe com os amigos</p>
      
                           <div className="redes-sociais">
                              <a href="#"><img src="../../img/icones/facebook.png" /></a>
                              <a href="#"><img src="../../img/icones/whatsapp.png" /></a>
                              <a href="#"><img src="../../img/icones/instagram.png" /></a>
                              <a href="#"><img src="../../img/icones/twitter.png" /></a>
                           </div>
                        </div>
                     </div>
                  </div>
      
               </div>
            </section>
      
         </main>

            <Footer />
          
         </React.Fragment>
   )
}

export default DetalhesSeletivaEvento