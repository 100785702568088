import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import InvoiceCreditService from '../../services/InvoiceCreditService';

const InvoiceCredit = () => {
  const [totalCreditAmount, setTotalCreditAmount] = useState(null);

  useEffect(() => {
    InvoiceCreditService.getCreditAmount().then(setTotalCreditAmount);
  }, []);
  return (
    <div>
      <div style={{ maxWidth: 984, margin: '0 auto' }}>
        <div>
          <img style={{ borderRadius: 14 }} src='img/banners/banner-fensor-pay.png' />
        </div>
        <div
          style={{
            borderRadius: 14,
            padding: '40px 20px',
            backgroundColor: 'white',
            marginTop: 10,
            border: '1px solid #DEE4E9',
          }}
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                <div
                  style={{
                    width: 60,
                    height: 60,
                    backgroundColor: '#C4DBE47D',
                    borderRadius: 60,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src='img/icones/request_quote.svg' />
                </div>
                <div>
                  <p>
                    Saldo{' '}
                    <button
                      style={{ border: 'none', backgroundColor: 'transparent' }}
                      data-tip='A quantidade de documentos que você ainda possuí disponível para assinar digitalmente.'
                    >
                      <img src='/img/icones/info.svg' />
                    </button>
                    <ReactTooltip backgroundColor='#D9D9D9' place='top' type='dark' effect='float' />
                  </p>
                  <p>
                    {totalCreditAmount !== null && (
                      <span style={{ color: '#004F2E', fontSize: '1.125rem', fontWeight: 700, lineHeight: 1 }}>
                        {totalCreditAmount}
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <Link
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: 4,
                  backgroundColor: ' #27CD87',
                  padding: 16,
                  border: 'none',
                  color: 'black',
                }}
                to='/buy-invoice-credit'
              >
                Comprar créditos nota fiscal <img src='img/icones/arrow_forward_ios.svg' />
              </Link>
            </div>
          </div>
        </div>

        <div
          style={{
            borderRadius: 14,
            padding: '40px 20px',
            backgroundColor: 'white',
            marginTop: 10,
            border: '1px solid #DEE4E9',
          }}
        >
          <p>Extrato de documentos comprados/assinados</p>
          <div>
            <button
              style={{ backgroundColor: 'transparent', border: 'none', display: 'flex', alignItems: 'center', gap: 15 }}
            >
              Filtros avançados <img style={{ borderRadius: 14 }} src='img/icones/expand_more.svg' />
            </button>
          </div>
          <div style={{ display: 'flex', gap: 22, alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 16, flex: 1 }}>
              <label for=''>Período inicial:</label>
              <input style={{ borderRadius: 4, border: '1px solid #27CD87', height: 40, padding: 16 }} type='date' />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 16, flex: 1 }}>
              <label for=''>Período final:</label>
              <input style={{ borderRadius: 4, border: '1px solid #27CD87', height: 40, padding: 16 }} type='date' />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 16, flex: 1 }}>
              <label for=''>Status:</label>
              <select
                className='buy-credit-select'
                style={{
                  borderRadius: 4,
                  height: 40,
                  border: '1px solid #27CD87',
                  padding: 16,
                  backgroundColor: 'white',
                }}
              />
            </div>
          </div>
          <div
            style={{
              borderRadius: 8,
              background: '#F2F2F2',
              display: 'flex',
              alignItems: 'center',
              padding: '16px 24px',
              marginTop: 16,
            }}
          >
            <input
              placeholder='Buscar por código, nome, CPF/CNPJ ou Telefone...'
              style={{ backgroundColor: 'transparent', border: 'none', flex: 1 }}
            />
            <FaSearch />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceCredit;
