import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

const Card = (props) => {

    const [ expire, setExpire ] = useState(props.dateExpire <= new Date().toISOString().substr(0,10))

    useEffect(() => {
        setExpire(props.dateExpire <= new Date().toISOString().substr(0,10))
    }, [])

    return (
        <article className="card" style={{ borderColor: props.color }}>
            <h2 style={{ color: props.color }}>{props.title}</h2>
            <img style={{flex: 1, height: '300px', objectFit: 'scale-down'}} src={props.tumblr} alt="Concorra a essa experiência" />
            <h3>{props.subTitle}</h3>
            <p>{props.descript}</p>
            {
                expire &&
                <Link to="/advantage-club" className="card_btn" style={{ backgroundColor: '#fff', color: 'grey' }}>expirou</Link>
            }
            {
                !expire &&
                <Link to={{
                    pathname: "/Rescue",
                    state: {
                        color: props.color,
                        title: props.title,
                        tumblr: props.tumblr,
                        subTitle: props.subTitle,
                        descript: props.descript,
                        points: props.points,
                        id: props.id,
                        dateExpire: props.dateExpire, 
                        athlete_id: props.athlete_id
                    }
                }} className="card_btn" style={{ backgroundColor: props.color }}>resgatar</Link>
            }
           
        </article>
    );
}

export default Card
