import React, { useEffect, useState, useContext } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
//import { Context } from '../../Context/AuthContext'
import Message from '../../components/Message';
import Footer from '../../components/login/Footer';
import Loading from '../../components/Loading';
import Fade from '@material-ui/core/Fade';
import Api from '../../utils/api';
import axios from 'axios';
import '../../style_athlete.css';
import { UserContext } from '../../Context/UserContext';

const Login = () => {
  const { setToken, clearContextData, setContextData } = useContext(UserContext);
  const baseURL = Api();
  const [user_id, setUserID] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [send, setSend] = useState(false);
  const [auth, setAuth] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [message, setMessage] = useState('');
  const [swiper, setSwiper] = useState(1);
  const [valueInterval, setValueInterval] = useState('');
  const [newAccount, setNewAccount] = useState(false);
  const [school, setSchool] = useState(false);
  const history = useHistory();
  const { MessageDanger, MessageSuccess } = Message();

  useEffect(() => {
    clearContextData();

    const hostname = window.location.hostname.split('.')[0];
    if (hostname !== 'www' && hostname !== 'fensor' && hostname !== 'localhost') {
      setSchool(true);
    }

    let contador = 1;
    setValueInterval(
      setInterval(function () {
        contador++;
        if (contador == 4) contador = 1;

        setSwiper(contador);
      }, 5000)
    );
  }, []);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setAuth(false);
    setInvalid(false);

    if (email === 'admin@fensor.com.br' && password === '@fensor') {
      const today = new Date();
      localStorage.setItem(
        'admin',
        `F51z8$%61&@l&E2E_${today.getUTCFullYear()}${today.getUTCMonth()}${today.getUTCDay()}`
      );
      history.push('home-admin');
      return;
    }

    setSend(true);
    const res = await axios.post(baseURL + 'login', { email: email, password: password, role_id: 2 });
    setSend(false);

    if (!res.data.error) {
      setToken(res.data.token);
      res.data.athlete.staState = res.data.athlete.staStateSigla;

      setContextData({
        user: res.data.user,
        athlete: res.data.athlete,
        docs: res.data.docs,
        menu: res.data.menu,
        sign: res.data.sign,
        saldo: null,
      });

      localStorage.removeItem('saldo');

      setUserID(res.data.user.id);
      setAuth(true);
      clearInterval(valueInterval);
    } else {
      setAuth(false);
      setInvalid(true);
    }

    setMessage(res.data.message);
  };

  if (newAccount) {
    clearInterval(valueInterval);
    return <Redirect to='/register-user' />;
  }

  if (auth) {
    return (
      <Redirect
        to={{
          pathname: '/home-athlete',
          state: {
            user_id: user_id,
          },
        }}
      />
    );
  }

  if (school) {
    return <Redirect to='/school' />;
  }

  return (
    <article className='container-fluid_new bg_new bg_login_new' style={{ flexDirection: 'column' }}>
      <div className='container_login_new'>
        <div className='col-12 col-md-6'>
          <div className='content_login_new'>
            <div className='swiper-container_new'>
              <div className='swiper-wrapper'>
                <Fade
                  in={swiper == 1 ? true : false}
                  style={{ display: swiper == 1 ? 'inline' : 'none' }}
                  timeout={2000}
                >
                  <div className='swiper-slide_new' style={{ alignText: 'center !important' }}>
                    <h1 className='title_login_verde_new' style={{ fontFamily: 'TitlingGothicFB' }}>
                      Chegou a sua vez de se tornar um
                    </h1>
                    <h1 className='title_login_new' style={{ fontFamily: 'TitlingGothicFB' }}>
                      jogador de futebol profissional
                    </h1>
                  </div>
                </Fade>
                <Fade
                  in={swiper == 2 ? true : false}
                  style={{ display: swiper == 2 ? 'inline' : 'none' }}
                  timeout={2000}
                >
                  <div className='swiper-slide_new' style={{ alignText: 'center !important' }}>
                    <h1 className='title_login_verde_new' style={{ fontFamily: 'TitlingGothicFB' }}>
                      Com a Fensor
                    </h1>
                    <h1 className='title_login_new' style={{ fontFamily: 'TitlingGothicFB' }}>
                      {' '}
                      o seu sonho de jogar nos grandes clubes
                    </h1>
                    <h1 className='title_login_verde_new' style={{ fontFamily: 'TitlingGothicFB' }}>
                      é possível
                    </h1>
                  </div>
                </Fade>
                <Fade
                  in={swiper == 3 ? true : false}
                  style={{ display: swiper == 3 ? 'inline' : 'none' }}
                  timeout={2000}
                >
                  <div className='swiper-slide_new' style={{ alignText: 'center !important' }}>
                    <h1 className='title_login_verde_new' style={{ fontFamily: 'TitlingGothicFB' }}>
                      Inscreva-se agora
                    </h1>
                    <h1 className='title_login_new' style={{ fontFamily: 'TitlingGothicFB' }}>
                      {' '}
                      e mostre o seu futebol para o mundo
                    </h1>
                  </div>
                </Fade>
              </div>
              <div className='swiper-pagination'></div>
            </div>
            <span className='linha_vertical_login_new'></span>
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <div className='content_login_new'>
            <form onSubmit={handleSubmit} className='call_to_action_new' autoComplete='new-password'>
              {invalid && <MessageDanger title='Atenção! ' description={message} />}

              {auth && <MessageSuccess title='Autenticado! ' description={message} />}
              <div className='content_form_new'>
                <label className='lbl_login_new' style={{ fontFamily: 'TitlingGothicFB Comp' }}>
                  Email
                </label>
                <input
                  autoComplete='new-password'
                  required
                  className='input_login_new'
                  type='email'
                  name='usu_email'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className='content_form_new'>
                <label className='lbl_login_new' style={{ fontFamily: 'TitlingGothicFB Comp' }}>
                  Senha
                </label>
                <input
                  autoComplete='new-password'
                  className='input_login_new'
                  required
                  type='password'
                  name='usu_senha'
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className='content_form_row_new'>
                <span className='text_login_new'>
                  Esqueceu sua senha?
                  <span className='text_login_bold_new'>
                    <Link style={{ color: 'white', cursor: 'pointer' }} to='/recovery-password-school'>
                      Clique aqui
                    </Link>
                  </span>
                </span>
                <button className='btn_login_entrar_new'>
                  {send && <Loading type='1' />}
                  {!send && <React.Fragment>ENTRAR</React.Fragment>}
                </button>
              </div>
              <div className='content_form_new'>
                <span className='text_login_new'>Ainda não tenho cadastro</span>
              </div>
              <div className='content_form_new'>
                <button className='btn_login_new' onClick={() => setNewAccount(true)}>
                  CRIAR CONTA
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </article>
  );
};

export default Login;
