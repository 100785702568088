import React from 'react';
import { useTranslation } from 'react-i18next';

import NumberFormat from 'react-number-format';
import { calcFinanceValue, discoverBillCase, onBillCase } from '../../../utils/utils-financial';
import moment from 'moment';
import { BtnInfo } from '../NewStudent';

// TODO: usar o intl para traduzir os textos
const Matricula = ({ title = 'Informações Aluno', useForm }) => {
  const valorRef = React.useRef(null);

  const { t } = useTranslation();

  const { state, handleOnChange, setState } = useForm;

  const [financeType, setFinanceType] = React.useState(undefined);

  const [static_recevid] = React.useState(state.receber.value === 'S');

  const setValueReceivedWithCalc = (e) => {
    const receivedValue = calcFinanceValue(
      state.valorOriginal.value,
      true,
      moment(),
      moment(state.expirationDate.value),
      { type: state.discount_type.value, value: state.discount.value },
      { type: state.late_interest_type.value, value: state.late_interest.value },
      { type: state.late_fee_type.value, value: state.late_fee.value }
    );
    setState((prevState) => ({
      ...prevState,
      valorReceber: { value: receivedValue, error: '', label: 'Valor a Receber' },
      receber: { value: e.target.value, error: '', label: 'Receber' },
    }));
  }; //valor receber

  const discoverCase = (financial) => discoverBillCase(financial, setFinanceType, 'SCHOOL');
  const onCase = (cases) => onBillCase(cases, financeType);

  //TODO: disabled receber combinado com hasFatura
  const hasFatura = () => state.faturado.value === 'S'; // disable inputs

  return (
    <div className='col p-0 mx-3'>
      <div className='row'>
        <div className='col-md-6 col-sm-12 form_group'>
          <label className='card_details_label'>{t('PaymentMethod')}*</label>
          <select
            required
            name='paymentMethod'
            className={`form_control select form-control`}
            value={state.paymentMethod.value || ''}
            onChange={handleOnChange}
            disabled={hasFatura()}
          >
            <option value='' disabled></option>
            <option value='Dinheiro'>{t('Money')}</option>
            <option value='Cartão'>{t('Card')}</option>
            <option value='Boleto'>{t('Bill')}</option>
          </select>
        </div>
        <div className='col-md-3 col-sm-12 form_group'>
          <label className='card_details_label'>{t('Mode')}*</label>
          <select
            required
            className={`form_control select form-control`}
            value={state.paymentModo.value || 'A vista'}
            onChange={handleOnChange}
            name='paymentModo'
            disabled={hasFatura()}
          >
            <option value='A vista'>{t('InCash')}</option>
            <option value='Parcelado'>{t('PaymentInstallments')}</option>
          </select>
        </div>
        <div className='col-md-3 col-sm-12 form_group'>
          <label className='card_details_label'>{t('NumberOfInstallments')}*</label>
          <select
            required
            name='parcelas'
            className={`form_control select`}
            value={state.parcelas.value || ''}
            onChange={handleOnChange}
            disabled={hasFatura()}
          >
            <option value='' disabled></option>
            {state.paymentModo.value !== 'Parcelado' && <option value='1'>01 ({t('InCash')})</option>}
            <option value='2'>02</option>
            <option value='3'>03</option>
            <option value='4'>04</option>
            <option value='5'>05</option>
            <option value='6'>06</option>
            <option value='7'>07</option>
            <option value='8'>08</option>
            <option value='9'>09</option>
            <option value='10'>10</option>
            <option value='11'>11</option>
            <option value='12'>12</option>
          </select>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-3 col-sm-12 form_group'>
          <label className='card_details_label'>{t('InstallmentValueWithoutDiscount')}*</label>
          <NumberFormat
            required
            disabled={hasFatura({
              unsaved: false,
              unbilled: false,
              contractAttached: true,
              contractDetached: true,
              financeAttached: true,
              financeDetached: false,
            })}
            ref={valorRef}
            className='form_control form-control'
            name='valorOriginal'
            placeholder={t('PlaceholderMoneyZero')}
            displayType={'input'}
            format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })}
            value={state.valorOriginal.value * 100}
            onChange={(e) => {
              e.target.value = +e.target.value.replace(/\D/g, '') / 100;
              handleOnChange(e);
            }}
            onBlur={(e) => {
              //if (e.target.value) setValueReceivedWithCalc(e);
            }}
          />
        </div>
        <div className='col-md-3 col-sm-12 form_group'>
          <label className='card_details_label'>{t('ExpirationDate')}*</label>
          <input
            disabled={hasFatura({
              unsaved: false,
              unbilled: false,
              contractAttached: true,
              contractDetached: true,
              financeAttached: true,
              financeDetached: false,
            })}
            type='date'
            required
            className='form_control form-control'
            value={state.expirationDate.value || ''}
            onChange={handleOnChange}
            name='expirationDate'
          />
        </div>
        <div className='col-md-6 col-sm-12 form_group'>
          <label className='card_details_label'>{t('PostingAccount')}*</label>
          <input
            type='text'
            required
            name='contaContabil'
            className='form_control form-control'
            value={state.contaContabil.value}
            onChange={handleOnChange}
          />
          <div style={{ position: 'absolute', padding: 5, right: 20, top: 30 }}>
            <BtnInfo title='inserir o que refere-se está cobrança avulsa. Exemplo: matricula, taxa de jogo.' />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 col-sm-12 form_group'>
          <label htmlFor='descript' className='card_details_label'>
            {t('DiscountType')}
          </label>
          <select
            disabled={hasFatura({
              unsaved: false,
              unbilled: false,
              contractAttached: true,
              contractDetached: true,
              financeAttached: true,
              financeDetached: false,
            })}
            className={`form_control select form-control`}
            value={state.discount_type.value || 'NONE'}
            onChange={handleOnChange}
            name='discount_type'
          >
            <option value='NONE'>{t('NoDiscount')}</option>
            <option value='PERCENTAGE'>{t('Percentage')}</option>
            <option value='FIXED'>{t('Fixed')}</option>
          </select>
        </div>
        <div className='col-md-6 col-sm-12 form_group'>
          <label className='card_details_label'>{t('DiscountAmount')}</label>
          <NumberFormat
            disabled={hasFatura({
              unsaved: false,
              unbilled: false,
              contractAttached: true,
              contractDetached: true,
              financeAttached: true,
              financeDetached: false,
            })}
            className='form_control'
            displayType={'input'}
            name='discount'
            placeholder={
              state.discount_type.value === 'FIXED'
                ? t('PlaceholderMoneyZero')
                : state.discount_type.value === 'PERCENTAGE'
                ? t('PlaceholderPercentageZero')
                : ''
            }
            format={(e) =>
              state.discount_type.value === 'FIXED'
                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                : state.discount_type.value === 'PERCENTAGE'
                ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                : ''
            }
            readOnly={state.discount_type.value === 'NONE'}
            isAllowed={({ floatValue }) => (state.discount_type.value === 'PERCENTAGE' ? floatValue < 10000 : true)}
            value={state.discount.value * 100}
            onChange={(e) => {
              e.target.value = +e.target.value.replace(/\D/g, '') / 100;
              handleOnChange(e);
            }}
          />
        </div>
      </div>

      <div className='row'>
        <div className='form_group col-md-3  col-sm-12 '>
          <label htmlFor='descript' className='card_details_label'>
            {t('LateInterestType')}
          </label>
          <select
            disabled={hasFatura()}
            className={`form_control select form-control`}
            value={state.late_interest_type.value || 'NONE'}
            onChange={handleOnChange}
            name='late_interest_type'
          >
            <option value='NONE'>{t('NoLateInterest')}</option>
            <option value='PERCENTAGE'>{t('Percentage')}</option>
            <option value='FIXED'>{t('Fixed')}</option>
          </select>
        </div>
        <div className='col-sm-12 col-md-3 form_group'>
          <label htmlFor='descript' className='card_details_label'>
            {t('LateInterestAmount')}
          </label>
          <NumberFormat
            disabled={hasFatura()}
            className='form_control'
            displayType={'input'}
            name='late_interest'
            placeholder={
              state.late_interest_type.value === 'FIXED'
                ? t('PlaceholderMoneyZero')
                : state.late_interest_type.value === 'PERCENTAGE'
                ? t('PlaceholderPercentageZero')
                : ''
            }
            format={(e) =>
              state.late_interest_type.value === 'FIXED'
                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                : state.late_interest_type.value === 'PERCENTAGE'
                ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                : ''
            }
            readOnly={state.late_interest_type.value === 'NONE'}
            isAllowed={({ floatValue }) =>
              state.late_interest_type.value === 'PERCENTAGE' ? floatValue < 10000 : true
            }
            value={state.late_interest.value * 100}
            onChange={(e) => {
              e.target.value = +e.target.value.replace(/\D/g, '') / 100;
              handleOnChange(e);
            }}
          />
        </div>
        <div className='col-sm-12 col-md-3 form_group'>
          <label htmlFor='descript' className='card_details_label'>
            {t('LateFeeType')}
          </label>
          <select
            disabled={hasFatura()}
            className={`form_control select form-control`}
            value={state.late_fee_type.value || 'NONE'}
            onChange={handleOnChange}
            name='late_fee_type'
          >
            <option value='NONE'>{t('NoLateFee')}</option>
            <option value='PERCENTAGE'>{t('Percentage')}</option>
            <option value='FIXED'>{t('Fixed')}</option>
          </select>
        </div>
        <div className='col-sm-12 col-md-3 form_group'>
          <label className='card_details_label'>{t('LateFeeAmount')}</label>
          <NumberFormat
            disabled={hasFatura()}
            className='form_control form-control'
            displayType={'input'}
            name='late_fee'
            placeholder={
              state.late_fee_type.value === 'FIXED'
                ? t('PlaceholderMoneyZero')
                : state.late_fee_type.value === 'PERCENTAGE'
                ? t('PlaceholderPercentageZero')
                : ''
            }
            format={(e) =>
              state.late_fee_type.value === 'FIXED'
                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                : state.late_fee_type.value === 'PERCENTAGE'
                ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                : ''
            }
            readOnly={state.late_fee_type.value === 'NONE'}
            isAllowed={({ floatValue }) => (state.late_fee_type.value === 'PERCENTAGE' ? floatValue < 10000 : true)}
            value={state.late_fee.value * 100}
            onChange={(e) => {
              e.target.value = +e.target.value.replace(/\D/g, '') / 100;
              handleOnChange(e);
            }}
          />
        </div>
      </div>

      {(state.id.value !== 0 && state.remove_recevid === undefined) && !static_recevid && (
        <>
          <hr />
          <div className='row'>
            <div className='col-md-4 col-sm-12 row justify-content-center align-items-center'>
              <div className='form-check form-check-inline'>
                <BtnInfo title='Valor está sendo pago hoje.' />
                <span className='ml-2'>{t('Received')}?</span>{' '}
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='receber'
                  id='receb1'
                  value='S'
                  checked={state.receber.value === 'S'}
                  onChange={setValueReceivedWithCalc}
                  disabled={static_recevid}
                />
                <label className='form-check-label' htmlFor='receb1'>
                  {t('Yes')}
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='receber'
                  id='receb2'
                  value='N'
                  checked={state.receber.value === 'N'}
                  onChange={handleOnChange}
                  disabled={static_recevid}
                />
                <label className='form-check-label' htmlFor='receb2'>
                  {t('Not')}
                </label>
              </div>
            </div>
            <div className='col-md-4 col-sm-12 form_group'>
              <label className='card_details_label'>{t('AmountReceived')}</label>
              <NumberFormat
                className='form_control form-control'
                displayType={'input'}
                name='valorReceber'
                format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })}
                readOnly={state.receber.value === 'N'}
                value={state.valorReceber.value * 100}
                onChange={(e) => {
                  e.target.value = +e.target.value.replace(/\D/g, '') / 100;
                  handleOnChange(e);
                }}
                required={state.paymentMethod.value === 'Dinheiro' && state.receber.value === 'S'}
              />
            </div>
            <div className='col-md-4 col-sm-12 form_group'>
              <label className='card_details_label'>{t('PayDay')}</label>
              <input
                type='date'
                className='form_control'
                name='paymentDate'
                value={state.paymentDate.value || ''}
                onChange={handleOnChange}
                required={state.paymentMethod.value === 'Dinheiro' && state.receber.value === 'S'}
                readOnly={state.receber.value === 'N'}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 form_group'>
              <label className='card_details_label'>{t('Observations')}</label>
              <textarea
                name='observation'
                value={state.observation.value || ''}
                className='form_control form-control'
                cols={20}
                rows={5}
                disabled={static_recevid}
                onChange={handleOnChange}
              ></textarea>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Matricula;
