Array.prototype.unique = function (column = null) {
  return this.filter((item, index, array) => {
    if (column) {
      return array.findIndex((t) => t[column] === item[column]) === index;
    }
    return array.indexOf(item) === index;
  });
};
Array.prototype.last = function () {
  return this[this.length - 1];
};

Array.prototype.sum = function (column = null) {
  if (column) {
    return this.reduce((acc, item) => acc + item[column], 0);
  }
  return this.reduce((acc, item) => acc + item, 0);
};

Array.prototype.groupBy = function (key = 'key') {
  return this.reduce((result, item) => {
    if (!result[item[key]]) {
      result[item[key]] = [];
    }
    result[item[key]].push(item);
    return result;
  }, {});
};

EventTarget.prototype.loader = function () {
  const loadingText = this.dataset.loadText;
  const initialState = this.innerHTML;
  const self = this;
  return {
    start: () => {
      self.disabled = true;
      self.innerHTML =
        loadingText ||
        '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Aguarde';
    },
    reset: () => {
      self.innerHTML = initialState;
      self.disabled = false;
    },
  };
};
