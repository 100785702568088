import React, { useEffect, useState } from "react";
import { uploadFolder } from '../../utils/api'

const uploadURL = uploadFolder()

const Logo = (props) => {
  const [schLogomarca, setSchLogomarca] = useState(props.schLogomarca)

  useEffect(() => {
    if (props.schLogomarca === undefined) {
      const { schLogomarca } = JSON.parse(localStorage.getItem('school'))
      setSchLogomarca(schLogomarca)
    }
  }, [])

  return (
    schLogomarca ?
      <img
        src={`${uploadURL}upload/schools/logo/${props.schLogomarca || schLogomarca}`}
        style={{
          padding: 15,
          width: props.home ? '' : 210,
          //maxWidth: '100%'
        }}
        alt=""
      />
      : <></>
  );
};

export default Logo;
