import axios from 'axios';
import Api from '../utils/api';
import api from './api';

const baseURL = Api();

const attachDocument = (financialId, document) =>
  new Promise((response, reject) => {
    const formData = new FormData();
    formData.append('id', financialId);
    formData.append('documento', document);

    const requestOptions = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post(baseURL + 'attach-document-financial', formData, requestOptions)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export async function storeFinancial(payload) {
  const { data } = await api.post(`/finacial/save-multi-invoice-revenue`, payload);

  return data;
}

export { attachDocument };
