import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { formatByPattern } from '../../utils/utils';

const CpfCnpjMask = ({ value, onChange, className = 'form_control', mode = 'BOTH', ...rest }) => {
  const { t } = useTranslation();

  const modes = {
    CPF: (value) => formatByPattern(value, t('Mask.CPF') || null, '_'),
    CNPJ: (value) => formatByPattern(value, t('Mask.CNPJ') || null, '_'),
    BOTH: (value) =>
      value.substring(8, 11) === '000'
        ? formatByPattern(value, t('Mask.CNPJ'), '_')
        : formatByPattern(value, t('Mask.CPF', '_')),
  };

  return (
    <NumberFormat
      {...rest}
      type='tel'
      className={className}
      format={modes[mode]}
      value={value}
      onValueChange={({ value }) => onChange(value)}
    />
  );
};

export default CpfCnpjMask;
