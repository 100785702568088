import React from 'react';
import Loading from '../Loading';
import { Link } from 'react-router-dom';

const List = ({ isLoading, items, actions, shadowInner, overflow, paddingInner }) => {
  return (
    <div className={`w-100 ${shadowInner ? 'shadow-y-inner' : ''}`} style={overflow ? { height: overflow } : undefined}>
      <div style={overflow ? { height: '100%', overflowY: 'auto' } : undefined}>
        <div className='list_card' style={paddingInner ? { padding: '0 10px' } : undefined}>
          {isLoading && (
            <p className='loading-card'>
              <Loading type='1' />
            </p>
          )}
          {!isLoading &&
            items.map((item, i) => {
              return (
                <div className={`card_list_turma ${item?.disabled || ''}`} key={`List_Item_${i}`} style={{ minWidth: '450px' }}>
                  <div className='content_card'>
                    <div className='card_details'>
                      <p className='card_details_label text_uppercase flex-row' style={{ flex: 1 }}>
                        <span className='w-100 text-secondary'>
                          {item.label}
                        </span>
                      </p>
                      <div className='d-flex align-items-center' style={{ columnGap: '5px' }}>
                        {/* <p
                    className='card_details_label text_uppercase'
                    style={{ width: '90px', margin: 'auto', marginRight: '10px', color: '#3B3B3B' }}
                  >
                    {t('StudentCount', { count: item.students.length })}
                  </p> */}
                        {actions.map((props, j) => {
                          if (React.isValidElement(props)) {
                            return props;
                          } else if (typeof props === 'function') {
                            return props(item, { index: i });
                          } else {
                            const { isLink, className, variant, label, onClick, ...propsAux } = props;

                            const Component = isLink ? Link : (props) => <span {...props} />;
                            return (
                              <Component
                                {...propsAux}
                                key={`List_Action_${i}_${j}`}
                                onClick={(e) => !isLink && onClick && onClick(item, e)}
                                to={isLink && onClick && onClick(item)}
                                className={`btn_card lowercase mt-0 ${className || ''}`}
                                style={
                                  variant
                                    ? {
                                        marginRight: '1.5%',
                                        background: 'orange',
                                        fontWeight: 500,
                                        border: '1px solid orange',
                                      }
                                    : undefined
                                }
                              >
                                {label}
                              </Component>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default List;
