import React, { useState, useEffect } from 'react';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Collapse from '@material-ui/core/Collapse';
import ReactPlayer from 'react-player';
import { getAllActiveCourses } from '../../services/CourseService';
import { uploadFolder } from '../../utils/api';
import { useTranslation } from 'react-i18next';

const Courses = () => {
  const { t } = useTranslation();

  const uploadURL = uploadFolder();

  const [courses, setCourses] = useState([]);
  const [collapseStatus, setCollapseStatus] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchAllCourses();
  }, []);

  useEffect(() => {
    setCollapseStatus(courses.map(() => false));
  }, [courses]);

  const fetchAllCourses = async () => {
    setIsLoading(true);
    const { success, courses, error } = await getAllActiveCourses();
    setIsLoading(false);

    if (success) {
      setCourses(courses);
    } else {
      console.log(error.message);
    }
  };

  const toggleCollapse = (index) => {
    const collapseStatusAux = [...collapseStatus];
    collapseStatusAux[index] = !collapseStatusAux[index];
    setCollapseStatus(collapseStatusAux);
  };

  const isVimeoURL = (url) => {
    const urlParsed = new URL(url);

    return urlParsed.hostname.includes('vimeo');
  };
  const parseVimeoURL = (url) => {
    const urlParsed = new URL(url);

    if (!urlParsed.hostname.startsWith('payer.')) {
      urlParsed.hostname = 'player.' + urlParsed.hostname;
    }

    if (!urlParsed.pathname.startsWith('/video')) {
      urlParsed.pathname = '/video' + urlParsed.pathname;
    }

    urlParsed.search = '';

    return urlParsed.href;
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('CoursesPage.PageTitle')}</h2>
                    <p className='card_subtitle'>{t('CoursesPage.PageSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    {isLoading && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!isLoading && courses.length === 0 && (
                      <div className='card_details'>
                        <p style={{ textAlign: 'center', margin: 100 }}>{t('CoursesPage.NoCoursesAvailable')}</p>
                      </div>
                    )}
                    {!isLoading &&
                      courses.map((course, index) => (
                        <div className='card_list_turma' key={'course_' + course.name + course.id}>
                          <div className='content_card' style={{ display: 'flex', flexDirection: 'column' }}>
                            <div
                              style={{ width: '100%', display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
                              onClick={() => toggleCollapse(index)}
                            >
                              <span className='card_details_label text_uppercase' style={{ fontSize: '14px', color: '#3b3b3b' }}>
                                {course.name}
                              </span>
                              {collapseStatus[index] ? (
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='16'
                                  height='16'
                                  fill='currentColor'
                                  className='bi bi-caret-up-fill'
                                  viewBox='0 0 16 16'
                                >
                                  <path d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z' />
                                </svg>
                              ) : (
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='16'
                                  height='16'
                                  fill='currentColor'
                                  className='bi bi-caret-down-fill'
                                  viewBox='0 0 16 16'
                                >
                                  <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
                                </svg>
                              )}
                            </div>
                            <Collapse in={collapseStatus[index]} style={{ width: '100%' }}>
                              <div style={{ width: '100%', marginTop: '20px' }}>
                                <div
                                  style={{
                                    width: course.type === 'VIDEO' ? '70%' : '100%',
                                    padding: '5px 25px 25px 25px',
                                    height: course.type === 'VIDEO' ? '500px' : '100px',
                                    display: 'inline-block',
                                    float: 'left',
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      color: 'white',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      position: 'relative',
                                    }}
                                  >
                                    {course.type === 'VIDEO' && isVimeoURL(course.videoURL) ? (
                                      <>
                                        <iframe
                                          src={parseVimeoURL(course.videoURL)}
                                          allow='autoplay; fullscreen; picture-in-picture'
                                          allowFullScreen
                                          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                        // title='Finaliza&amp;ccedil;&amp;atilde;o - Atividade 1'
                                        ></iframe>
                                        <script src='https://player.vimeo.com/api/player.js'></script>
                                      </>
                                    ) : (
                                      <ReactPlayer
                                        controls={true}
                                        width='100%'
                                        height='100%'
                                        url={course.type === 'VIDEO' ? course.videoURL : `${uploadURL}upload/schools/course/${course.audioFilename}`}
                                      />
                                    )}
                                    {/* <ReactPlayer
                                      controls={true}
                                      width='100%'
                                      height='100%'
                                      url={course.type === 'VIDEO' ? course.videoURL : `${uploadURL}upload/schools/course/${course.audioFilename}`}
                                    /> */}
                                  </div>
                                </div>
                                {course.description}
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Courses;
