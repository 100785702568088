import React from 'react';
import Message from '../../../components/Message';
import useForm from '../../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import ButtonRounded from '../../../components/buttons/ButtonRounded';
import { StudentFormContext } from '../../../Context/StudentFormContext';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

const { MessageDangerMarginTop } = Message();

// TODO: usar o intl para traduzir os textos
const Fiador = ({ title = 'Informações Aluno', nextTab, short = false }) => {
  const context = React.useContext(StudentFormContext);

  const history = useHistory();

  const formRefer = React.useRef(null);

  const [errors, setErrors] = React.useState([]);

  const [sendSave, setSendSave] = React.useState(false);

  const [showModal, setShowModal] = React.useState(false);

  const [errorsServer, setErrorsServe] = React.useState([]);

  const { t, i18n } = useTranslation();

  const [fiador, setFiador] = React.useState(-1);

  const handlerErrorServer = (err) => {
    if (err.response.status === 422) {
      const { message = [] } = err.response.data;

      Object.keys(message).map((k) => {
        const element = document.querySelector(`[name=${k}]`);
        if (element) {
          dispatchHtmlValidity(element, `[${k}] ${message[k]}`);

          setState({
            ...state,
            [k]: { value: state[k]?.value, error: message[k] },
          });
        } else {
          setState({
            ...state,
            [k]: { value: state[k]?.value, error: message[k] },
          });
          setErrorsServe(message[k]);
        }
      });
    } else setErrorsServe([err.response.data.message.toString()]);
  };

  const onSubmitForm = (payload, e, s, fdata) => {
    const { tipo = undefined } = e.target.dataset;

    setSendSave(true);

    fdata.append('session_user_id', context.user.id);
    fdata.append('location', i18n.language);
    fdata.append('athId', context.atleta.athId.value);
    fdata.append('id', context.atleta.id.value);

    context
      .requestValidate('/validate/fiador', fdata)
      .then((response) => {
        if (response.type === 'success') {
          context.setState({
            fiador: {
              ...state,
              ...context.normalResponse(response.message),
            },
          });

          if (tipo !== undefined && tipo === 'finalizar') {
            setShowModal(true);
            return null;
          }
          nextTab(4);
        }
      })
      .catch(handlerErrorServer)
      .finally(() => setSendSave(false));
  };

  const handleSubmitShort = (e) => {
    e.preventDefault();

    /*     const formData = new FormData();
    if (context.atleta.foto.value instanceof File) {
      formData.append('foto', context.atleta.foto.value);
    }
    formData.append('atleta', JSON.stringify(normal(context.atleta)));
    formData.append('fiador', JSON.stringify(normal(context.fiador)));

    setSendSave(true);
    context
      .requestValidate('student/create-short', formData)
      .then((response) => {
        if (response.type === 'success') {
          //context.reset();
          context.setState({ done: true });
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorsServe([err.message.toString()]);
      })
      .finally(() => setSendSave(false)); */
  };

  const { state, handleOnChange, handleOnSubmit, disable, setState, setIsDirty, dispatchHtmlValidity } = useForm(
    context.fiador,
    validationStateSchema,
    short === true ? handleSubmitShort : onSubmitForm,
    formRefer,
    setErrors
  );
  const mapResponsaveis = () => [
    {
      nome: context.responsaveis.athMaeNome.value,
      cpf: context.responsaveis.athMaeCPF.value,
      tel: context.responsaveis.athMaeCelular.value,
      tipo: 'Mãe',
    },
    {
      nome: context.responsaveis.athPaiNome.value,
      cpf: context.responsaveis.athPaiCPF.value,
      tel: context.responsaveis.athPaiCelular.value,
      tipo: 'Pai',
    },
  ];

  const handlerFinally = () => {
    history.push('/student-school');
    context.setState({ done: false });
  };
  const handlerFiancialInfos = (e) => {
    e.preventDefault();
    setFiador(+e.target.value);
    const { nome, cpf, tipo, tel } = mapResponsaveis()[e.target.value];
    setState((prevState) => ({
      ...prevState,
      athNomeRespFin: {
        value: nome,
        error: '',
      },
      athCPFRespFin: {
        value: cpf,
        error: '',
      },
      athPhoneRespFin: {
        value: tel,
        error: '',
      },
      athParentescoRespFin: {
        value: tipo,
        error: '',
      },
    }));
  };

  React.useEffect(() => {
    setIsDirty(true);
  }, []);

  return (
    <>
      <SweetAlert
        success
        showCancel
        confirmBtnBsStyle='danger'
        onConfirm={() => {
          nextTab(4);
        }}
        onCancel={handlerFinally}
        focusCancelBtn
        show={showModal}
        title={t('Attention_exclamation')}
        customButtons={
          <span className='w-100 d-flex justify-content-around align-items-center'>
            <ButtonRounded variant='outline' color='var(--main-color)' isBig={true} onClick={handlerFinally}>
              {t('Not')}
            </ButtonRounded>
            <ButtonRounded
              isBig={true}
              onClick={() => {
                nextTab(4);
              }}
            >
              {t('Yes')}
            </ButtonRounded>
          </span>
        }
      >
        ALUNO {context.atleta.id === 0 ? 'CADASTRADO' : 'ALTERADO'} COM SUCESSO! DESEJA CRIAR O CONTRATO?
      </SweetAlert>
      {errors.map((err, k) => (
        <MessageDangerMarginTop
          key={`err-${k}`}
          title='Atenção!'
          description={t(`${err.error.replace(/\[.*?\]/g, '').trim()}`)}
        />
      ))}
      {errorsServer.map((item, k) => (
        <MessageDangerMarginTop title='Atenção!' description={item} key={`error_${k}`} />
      ))}
      <div className='mx-3'>
        <form ref={formRefer} action='' className='form_card' noValidate>
          <div className='col p-0 w-100'>
            {Array.isArray(mapResponsaveis()) && mapResponsaveis().length > 0 && (
              <div className='row'>
                <div className='col-md-12 col-sm-12 form_group'>
                  {mapResponsaveis().filter((p) => p.cpf).length > 0 ? (
                    <label className='card_details_label'>Responsável finaceiro é um desses?</label>
                  ) : (
                    ''
                  )}
                  {mapResponsaveis()
                    .filter((p) => p.cpf)
                    .map((item, k) => (
                      <div className='form-check' key={`f${k}`}>
                        <input
                          value={k}
                          onChange={handlerFiancialInfos}
                          id={`resp_${k}`}
                          className='form-check-input'
                          type='radio'
                          checked={fiador === k}
                        />
                        <label className='form-check-label' htmlFor={`resp_${k}`}>
                          {item.nome}
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            )}

            <div className='row'>
              <div className='col-md-12 col-sm-12 form_group'>
                <label className='card_details_label'>{t('FinancialResponsibleName')} *</label>
                <input
                  type='text'
                  name='athNomeRespFin'
                  required
                  className='form-control form_control'
                  value={state.athNomeRespFin.value || ''}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 col-sm-12 form_group'>
                <label className='card_details_label'>{t('FinancialResponsibleCPF')} *</label>
                {i18n.language === 'pt-BR' ? (
                  <NumberFormat
                    name='athCPFRespFin'
                    required
                    format={t('Mask.CPF')}
                    className='form-control form_control'
                    mask='_'
                    value={state.athCPFRespFin.value || ''}
                    onChange={handleOnChange}
                  />
                ) : (
                  <input
                    type='text'
                    name='athCPFRespFin'
                    required
                    className='form-control form_control'
                    value={state.athCPFRespFin.value || ''}
                    onChange={handleOnChange}
                  />
                )}
              </div>
              <div className='col-md-6 col-sm-12 form_group'>
                <label className='card_details_label'>{t('FinancialResponsibleCellPhone')}</label>
                <NumberFormat
                  type='text'
                  name='athPhoneRespFin'
                  className='form-control form_control'
                  value={state.athPhoneRespFin.value || ''}
                  onChange={handleOnChange}
                  format={t('Mask.CellPhone') || undefined}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-sm-12 form_group'>
                <label className='card_details_label'>{t('FinancialResponsibleEmail')} *</label>
                <input
                  type='email'
                  name='athEmailRespFin'
                  required
                  className='form-control form_control'
                  value={state.athEmailRespFin.value || ''}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 col-sm-12 form_group'>
                <label htmlFor='how' className='card_details_label'>
                  {t('Kinship')}
                </label>
                <select
                  name='athParentescoRespFin'
                  className='form_control form-control select'
                  value={state.athParentescoRespFin.value || ''}
                  onChange={handleOnChange}
                >
                  <option value='' disabled></option>
                  <option value='Avô(ó)'>{t('Grandparent')}</option>
                  <option value='Pai'>{t('Father')}</option>
                  <option value='Mãe'>{t('Mother')}</option>
                  <option value='Tio(a)'>{t('UncleAunt')}</option>
                  <option value='Primo(a)'>{t('Cousin')}</option>
                  <option value='Irmão(ã)'>{t('Sibling')}</option>
                  <option value='Nenhum'>{t('None')}</option>
                </select>
              </div>
              <div className='col-md-6 col-sm-12 form_group'>
                <label className='card_details_label'>{t('FinancialResponsibleRG')}</label>
                <input
                  type='text'
                  name='athRGRespFin'
                  className='form-control form_control'
                  value={state.athRGRespFin.value || ''}
                  onChange={handleOnChange}
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className='row align-items-center justify-content-center my-4'>
        <div className='col-md-5 col-sm-10'>
          <ButtonRounded
            isDisabled={disable}
            isLoading={sendSave}
            data-tipo='finalizar'
            onClick={handleOnSubmit}
            className='w-100'
            isBig={true}
          >
            {t('Save')} {t('And')} {t('Finalize')}
          </ButtonRounded>
        </div>
        <div className='col-md-5 col-sm-10'>
          <ButtonRounded
            isDisabled={disable}
            isLoading={sendSave}
            onClick={handleOnSubmit}
            className='w-100'
            isBig={true}
          >
            {t('Save')} {t('And')} {t('Continue')}
          </ButtonRounded>
        </div>
      </div>
    </>
  );
};

const validationStateSchema = {
  athNomeRespFin: {
    required: true,
  },
  athCPFRespFin: {
    required: true,
  },
  athEmailRespFin: {
    required: true,
    validator: {
      regEx: /^[\w\.\-]+(?:\+[\w\.\-]+)?@[a-zA-Z\d\.\-]+\.[a-zA-Z]{2,}$/,
      error: 'SystemMessage.Fail.InvalidEmail',
    },
  },
};

export default Fiador;
