import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EditPassword = ({ user_id, school }) => {
  const [state, setState] = useState(undefined);
  const { t } = useTranslation();

  if (school) {
    return (
      <Link style={{ color: 'var(--a)', textDecoration: 'none' }} to={`/edit-password-school/${user_id}`}>
        {t('ChangePassword')}
      </Link>
    );
  } else {
    return <Link to={`/edit-password/${user_id}`}>{t('ChangePassword')}</Link>;
  }
};

export default EditPassword;
