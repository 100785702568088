import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import ButtonRounded from '../buttons/ButtonRounded';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: 'var(--main-color)',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const BtnBack = ({ fcolor = '#ffff', w = 16, h = 16, style }) => (
    <span className='text-danger' style={{ cursor: 'pointer', ...style }} role='button'>
        <svg width={w} height={h} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z' fill={fcolor} />
        </svg>
    </span>
);

export default function FullScreen({ children, title, setOpen, open, onSave }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog maxWidth="xl" open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <BtnBack />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>

                </Toolbar>
            </AppBar>
            {children}
            <div className='d-flex align-items-center justify-content-center my-4'>
                <div className='col-md-5 col-sm-10'>
                    <ButtonRounded
                        isLoading={false}
                        onClick={(e) => onSave(e, handleClose)}
                        className='w-100'
                        isBig={true}
                    >
                        {t('Finalize')}
                    </ButtonRounded>
                </div>
            </div>
        </Dialog>
    );
}