import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Header from '../../components/admin/Header';
import Footer from '../../components/admin/Footer';
import Loading from '../../components/Loading';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../Context/UserContext';
import Utils, { handleOnChange } from '../../utils/utils';
import { BsChevronDown, BsDownload, BsEye, BsFillPencilFill } from 'react-icons/bs';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useReactToPrint } from 'react-to-print';
import Message from '../../components/Message';
import { Success } from '../../components/svgs/Success';
import { Upload } from '../../components/svgs/Upload';
import * as yup from 'yup';
import { pt } from 'yup-locale-pt';
import { fetchLessonMaterials } from '../../services/LessonMaterials';
import { fetchLessonSession } from '../../services/LessonSession';
import { fetchLessonDidacticStrategy } from '../../services/LessonDidacticStrategy';
import { fetchLessonContentSportsBySport } from '../../services/LessonContentSports';
import { fetchAllCategories } from '../../services/CategoriesService';
import { createLesson, getAllLessons, updateLesson, updateStatus } from '../../services/AdminService';

const AdminLesson = () => {
  yup.setLocale(pt);
  const history = useHistory();
  const { action, setContextData } = useContext(UserContext);
  const [processPage, setProcessPage] = useState(false);
  const [filters, setFilters] = useState({
    concept: '',
    source: 'T',
    status: 'A',
  });
  const [lesson, setLesson] = useState({
    id: undefined,
    name: '',
    status: '',
    description: '',
    videoURL: '',
    concept: '',
    space: '',
    time: '',
    lesson_session_id: 0,
    numberOfPlayers: null,
    rules: '',
    score: '',
    variation: '',
    feedback: '',
    artUrl: '',
    category_id: 0,
    content_sport_id: 0,
    didactic_strategy_id: 0,
    material_id: 0
  });
  const [lessons, setLessons] = useState([]);
  const [lessonsFiltered, setLessonsFiltered] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([])
  const [contentSports, setContentSports] = useState([]);
  const [contentSportsSelected, setContentSportsSelected] = useState([]);
  const [didacticStrategy, setDidacticStrategy] = useState([]);
  const [didacticStrategySelected, setDidacticStrategySelected] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [materialsSelected, setMaterialsSelected] = useState([]);
  const [dropDownStatus, setDropDownStatus] = useState([]);
  const [file, setFile] = useState();
  const [previewFile, setPreviewFile] = useState();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [display, setDisplay] = useState(false);
  const [displayView, setDisplayView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onChangeFilters = handleOnChange(setFilters);
  const onChangeLesson = handleOnChange(setLesson);
  const ref = useOutsideClick(() => {
    setDropDownStatus(dropDownStatus?.map((value) => ({ ...value, status: false })));
  });
  const modalScroll = useRef(null);
  const lessonPdfRef = useRef(null);
  const { scrollTop } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  const openAdd = () => {
    setLesson({
      id: undefined,
      name: '',
      status: '',
      description: '',
      videoURL: '',
      concept: '',
      space: '',
      time: '',
      lesson_session_id: null,
      numberOfPlayers: 0,
      rules: '',
      score: '',
      variation: '',
      feedback: '',
      artUrl: '',
      category_id: 0,
      content_sport_id: 0,
      didactic_strategy_id: 0,
      material_id: 0
    });
    setCategoriesSelected([])
    setContentSportsSelected([])
    setDidacticStrategySelected([])
    setMaterialsSelected([])
    clearMessage();
    setDisplay(true);
  };
  const search = (e) => {
    e && e.preventDefault();

    if (filters.source === 'A' || filters.source === 'M') {
      setContextData({
        action: {
          action: 'search',
          term: filters.concept,
          status: filters.status
        }
      })
      filters.source === 'A' ? history.push('/admin-planning', { replace: true }) : history.push('/admin-methodology', { replace: true });
      return;
    }

    setLessonsFiltered(
      lessons.filter((lesson) => filters.status === 'T' || lesson.status === filters.status)
        .filter((lesson) => lesson.concept?.toUpperCase()?.includes(filters.concept.toUpperCase())));
  };
  const clearMessage = () => {
    setSuccess(false);
    setError(false);
    setMessage('');
  };
  const configLesson = (lesson) => {
    setLesson({
      id: lesson.id,
      name: lesson.name,
      status: lesson.status,
      description: lesson.description,
      videoURL: lesson.videoURL,
      concept: lesson.concept,
      space: lesson.space,
      time: lesson.time,
      lesson_session_id: lesson.lesson_session_id,
      numberOfPlayers: lesson.numberOfPlayers,
      rules: lesson.rules,
      score: lesson.score,
      variation: lesson.variation,
      feedback: lesson.feedback,
      artUrl: lesson.artUrl,
      category_id: lesson?.lesson_categories[0]?.id,
      content_sport_id: lesson?.lesson_content_sports[0]?.id,
      didactic_strategy_id: lesson?.lesson_didactic_strategies[0]?.id,
      material_id: lesson?.lesson_materials[0]?.id
    });
    setCategoriesSelected(lesson?.lesson_categories);
    setContentSportsSelected(lesson?.lesson_content_sports);
    setDidacticStrategySelected(lesson?.lesson_didactic_strategies);
    setMaterialsSelected(lesson?.lesson_materials);
  }
  const edit = (lesson) => {
    configLesson(lesson);
    scrollTop(modalScroll);
    clearMessage();
    setDisplay(true);
  };
  const view = (lesson) => {
    configLesson(lesson);
    scrollTop(modalScroll);
    setDisplayView(true);
  };
  const handlePrint = useReactToPrint({
    content: () => lessonPdfRef.current
  })
  const toPdf = (lesson) => {
    Promise.resolve(configLesson(lesson)).then(() => {
      handlePrint();
    })
  }
  const closeAdd = () => {
    setDisplay(false);
    clearMessage();
  };
  const closeView = () => {
    setDisplayView(false);
  };
  const setErrorMessage = (message) => {
    setMessage(message);
    setError(true);
    scrollTop(modalScroll)
  };
  const updateByStatus = async (values) => {
    const data = {
      id: values.id,
      status: values.status,
    }

    const {
      status,
      lessons,
      message,
    } = await updateStatus(data);

    if (status === 'SUCCESS') {
      setLessons(lessons);
      setLessonsFiltered(lessons);
      setDropDownStatus(lessons?.map((lesson) => ({ id: lesson.id, status: false })));
    } else {
      setErrorMessage(message);
    }
  }
  const yupObject = yup.object().shape({
    feedback: yup.string().required().label('Feedback'),
    variation: yup.string().required().label('Variação'),
    score: yup.string().required().label('Pontuação'),
    rules: yup.string().required().label('Regras'),
    description: yup.string().required().label('Descrição'),
    numberOfPlayers: yup.number().min(1).required().label('N. de jogadores'),
    materials: yup.array().min(1).required().label('Material'),
    lesson_session_id: yup.number().required().label('Sessão'),
    time: yup.string().required().label('Tempo'),
    strategies: yup.array().min(1).required().label('Estratégia'),
    space: yup.string().required().label('Espaço'),
    concept: yup.string().required().label('Conceito'),
    contents: yup.array().min(1).required().label('Conteúdo'),
    status: yup.string().required().label('Status'),
    categories: yup.array().min(1).required().label('Categoria'),
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();

    const formLesson = {
      id: lesson.id,
      name: lesson.name,
      status: lesson.status,
      description: lesson.description,
      videoURL: lesson.videoURL,
      concept: lesson.concept,
      space: lesson.space,
      time: lesson.time,
      lesson_session_id: lesson.lesson_session_id,
      numberOfPlayers: lesson.numberOfPlayers,
      rules: lesson.rules,
      score: lesson.score,
      variation: lesson.variation,
      feedback: lesson.feedback,
      categories: categoriesSelected ? categoriesSelected?.map((category) => category.id) : [],
      contents: contentSportsSelected ? contentSportsSelected?.map((content) => content.id) : [],
      strategies: didacticStrategySelected ? didacticStrategySelected?.map((strategy) => strategy.id) : [],
      materials: materialsSelected ? materialsSelected?.map((material) => material.id) : []
    };

    try {
      await yupObject.validate(formLesson);
      const dataFile = file?.name
        ? {
          artUrl: file,
        }
        : {};
      const request = { ...formLesson, ...dataFile };
      const formData = new FormData();
      Object.keys(request).forEach((key) => {
        if (request[key]) {
          formData.append(key, request[key]);
        }
      })

      setIsLoading(true);
      const {
        status,
        lessons,
        message,
      } = lesson.id === undefined ? await createLesson(formData) : await updateLesson(formData);
      setIsLoading(false);

      if (status === 'SUCCESS') {
        setLessons(lessons);
        setLessonsFiltered(lessons);
        setDropDownStatus(lessons?.map((lesson) => ({ id: lesson.id, status: false })));
        setSuccess(true);
      } else {
        setErrorMessage(message);
      }
    } catch (error) {
      setErrorMessage(error.errors);
    }
  };
  const handleFile = useCallback(async (e) => {
    const target = e?.target;
    const files = target?.files;
    files && setFile(files[0]);
    files && setPreviewFile(URL.createObjectURL(files[0]));
  }, []);
  const addCategories = useCallback((e) => {
    const id = Number(e.target.value)
    const name = e.target.selectedOptions[0].label
    if (id && !categoriesSelected?.find((value) => value.id === id)) setCategoriesSelected((old) => [...old, { id, name }])
  }, [categoriesSelected])
  const removeCategories = useCallback((value) => {
    const categories = categoriesSelected.filter((category) => category.id !== value.id)
    if (categories.length === 0) setLesson((old) => { return { ...old, category_id: 0 } });
    setCategoriesSelected(categories)
  }, [categoriesSelected])
  const addContentSports = useCallback((e) => {
    const id = Number(e.target.value)
    const name = e.target.selectedOptions[0].label
    if (id && !contentSportsSelected?.find((value) => value.id === id)) setContentSportsSelected((old) => [...old, { id, name }])
  }, [contentSportsSelected])
  const removeContentSports = useCallback((value) => {
    const contentSports = contentSportsSelected.filter((contentSport) => contentSport.id !== value.id)
    if (contentSports.length === 0) setLesson((old) => { return { ...old, content_sport_id: 0 } });
    setContentSportsSelected(contentSports)
  }, [contentSportsSelected])
  const addDidacticStrategy = useCallback((e) => {
    const id = Number(e.target.value)
    const name = e.target.selectedOptions[0].label
    if (id && !didacticStrategySelected?.find((value) => value.id === id)) setDidacticStrategySelected((old) => [...old, { id, name }])
  }, [didacticStrategySelected])
  const removeDidacticStrategy = useCallback((value) => {
    const didacticStrategies = didacticStrategySelected.filter((didacticStrategy) => didacticStrategy.id !== value.id)
    if (didacticStrategies.length === 0) setLesson((old) => { return { ...old, didactic_strategy_id: 0 } });
    setDidacticStrategySelected(didacticStrategies)
  }, [didacticStrategySelected])
  const addMaterial = useCallback((e) => {
    const id = Number(e.target.value)
    const name = e.target.selectedOptions[0].label
    if (id && !materialsSelected?.find((value) => value.id === id)) setMaterialsSelected((old) => [...old, { id, name }])
  }, [materialsSelected])
  const removeMaterial = useCallback((value) => {
    const materials = materialsSelected.filter((material) => material.id !== value.id)
    if (materials.length === 0) setLesson((old) => { return { ...old, material_id: 0 } });
    setMaterialsSelected(materials)
  }, [materialsSelected])
  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeAdd();
    }
  };
  const categoriesSelect = useMemo(() => categories?.map((category) => <option key={category.id} value={category.id}>{category.name}</option>), [categories])
  const categoriesSelectedList = useCallback((view = 'edit') => {
    return (
      <div className='d-flex align-items-center'>
        {categoriesSelected?.sort((a, b) => a.name - b.name)?.map((category) => {
          return (
            <div key={category?.id} className='d-flex justify-content-center align-items-center mr-3'>
              {view === 'edit' && <button type='button' onClick={() => removeCategories(category)} className='badge-items-button'>X</button>}
              <span className={view !== 'pdf' ? 'badge-items' : ''}>{category?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [categoriesSelected])
  const contentSportsSelect = useMemo(() => contentSports?.map((contentSport) => <option key={contentSport.id} value={contentSport.id}>{contentSport.name}</option>), [contentSports])
  const contentSportsSelectedList = useCallback((view = 'edit') => {
    return (
      <div className='d-flex align-items-center'>
        {contentSportsSelected?.sort((a, b) => a - b)?.map((contentSport) => {
          return (
            <div key={contentSport?.id} className='d-flex justify-content-center align-items-center mr-3'>
              {view === 'edit' && <button type='button' onClick={() => removeContentSports(contentSport)} className='badge-items-button'>X</button>}
              <span className={view !== 'pdf' ? 'badge-items' : ''}>{contentSport?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [contentSportsSelected])
  const didacticStrategySelect = useMemo(() => didacticStrategy?.map((didacticStrategy) => <option key={didacticStrategy.id} value={didacticStrategy.id}>{didacticStrategy.name}</option>), [didacticStrategy])
  const didacticStrategySelectList = useCallback((view = 'edit') => {
    return (
      <div className='d-flex align-items-center'>
        {didacticStrategySelected?.sort((a, b) => a - b)?.map((didactic) => {
          return (
            <div key={didactic?.id} className='d-flex justify-content-center align-items-center mr-3'>
              {view === 'edit' && <button type='button' onClick={() => removeDidacticStrategy(didactic)} className='badge-items-button'>X</button>}
              <span className={view !== 'pdf' ? 'badge-items' : ''}>{didactic?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [didacticStrategySelected])
  const sessionsSelect = useMemo(() => sessions?.map((session) => <option key={session.id} value={session.id}>{session.name}</option>), [sessions])
  const materialsSelect = useMemo(() => materials?.map((material) => <option key={material.id} value={material.id}>{material.name}</option>), [materials])
  const materialsSelectedList = useCallback((view = 'edit') => {
    return (
      <div className='d-flex align-items-center'>
        {materialsSelected?.sort((a, b) => a - b)?.map((material) => {
          return (
            <div key={material?.id} className='d-flex justify-content-center align-items-center mr-3'>
              {view === 'edit' && <button type='button' onClick={() => removeMaterial(material)} className='badge-items-button'>X</button>}
              <span className={view !== 'pdf' ? 'badge-items' : ''}>{material?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [materialsSelected])
  const lessonSessionLabel = useMemo(() => {
    return sessions?.find((session) => session.id === lesson.lesson_session_id)?.name
  }, [lesson, sessions])
  const getAllCategories = async () => {
    const response = await fetchAllCategories();
    setCategories(response?.categories);
  }
  const getLessonContentSportsBySport = async () => {
    const response = await fetchLessonContentSportsBySport();
    setContentSports(response?.contentSports);
  }
  const getLessonDidacticStrategy = async () => {
    const response = await fetchLessonDidacticStrategy();
    setDidacticStrategy(response.didacticStrategies)
  }
  const getLessonSession = async () => {
    const response = await fetchLessonSession();
    setSessions(response.sessions)
  }
  const getLessonMaterials = async () => {
    const response = await fetchLessonMaterials();
    setMaterials(response.materials)
  }
  const fetchAllLessons = async () => {
    setIsLoading(true);
    const { status, lessons, message } = await getAllLessons();
    setIsLoading(false);
    if (status === 'SUCCESS') {
      setLessons(lessons);
      setLessonsFiltered(lessons);
      setDropDownStatus(lessons?.map((lesson) => ({ id: lesson.id, status: false })));
      return lessons;
    } else {
      console.log(message);
      setErrorMessage(message);
    }
  };
  useEffect(() => {
    if (action?.action === 'search') {
      setFilters({
        concept: action.term,
        status: action.status
      });
      fetchAllLessons().then((response) => {
        setLessonsFiltered(
          response?.filter((lesson) => action?.status === 'T' || lesson.status === action?.status)
            .filter((lesson) => lesson.concept?.toUpperCase()?.includes(action?.term?.toUpperCase())));
      }).catch((e) => console.log(e));
    } else {
      fetchAllLessons();
    }
    action?.action === 'openAdd' && openAdd();
    setContextData({ action: {} });
    getAllCategories();
    getLessonContentSportsBySport();
    getLessonDidacticStrategy();
    getLessonSession();
    getLessonMaterials();

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel' style={{ position: 'relative' }}>
              {processPage && <Loading type='6' />}
              <Header />
              <div className='d-flex flex-row flex-1 mt-2'>
                <div className='d-flex flex-column card mr-3'>
                  <div className='card_header'>
                    <h2 className='card_title'>Adicionar tarefa</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar tarefa.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'admin-lesson'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={openAdd} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-column card mr-3'>
                  <div className='card_header'>
                    <h2 className='card_title'>Adicionar Aulas</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar uma aula.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'admin-planning'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={() => {
                      setContextData({
                        action: {
                          action: 'openAdd'
                        }
                      })
                      history.push('/admin-planning', { replace: true });
                    }} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-column card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Metodologia Pronta</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para visualizar as metodologias prontas.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'admin-microcycle'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={() => {
                      setContextData({
                        action: {
                          action: 'openAdd'
                        }
                      })
                      history.push('/admin-microcycle', { replace: true });
                    }} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card_header'>
                  <h2 className='card_title'>Pesquisar</h2>
                  <p className='card_subtitle'>Busque abaixo por tarefas, aulas ou metodologias.</p>
                </div>
                <form action='' method='post' className='form_card'>
                  <div className='d-flex flex-row align-items-center row'>
                    <div className='col-sm-3'>
                      <input
                        required
                        type='text'
                        className='form_control mr-3'
                        name='concept'
                        placeholder='Conceito'
                        value={filters.concept}
                        onChange={onChangeFilters}
                      />
                    </div>
                    <div className='col-sm-3'>
                      <select
                        required
                        className='form_control select mr-3'
                        name='source'
                        value={filters.source}
                        onChange={onChangeFilters}
                      >
                        <option disabled={true}>Selecionar</option>
                        <option value='T'>Tarefas</option>
                        <option value='A'>Aulas</option>
                        <option value='M'>Metodologias</option>
                      </select>
                    </div>
                    <div className='col-sm-3'>
                      <select
                        required
                        className='form_control select mr-3'
                        name='status'
                        value={filters.status}
                        onChange={onChangeFilters}
                      >
                        <option disabled={true}>Status</option>
                        <option value='T'>Tudo</option>
                        <option value='A'>Ativo</option>
                        <option value='I'>Inativo</option>
                      </select>
                    </div>
                    <div className='col-sm-3 my-auto'>
                      <button className='btn-secondary-color rounded-pill' onClick={search}>
                        Buscar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className='container-fluid'>
                <div className='row content_painel'>
                  <main className='content_panel'>
                    <div className='content'>
                      <div className='card'>
                        <div className='card_header'>
                          <h2 className='card_title'>Tarefas</h2>
                          <p className='card_subtitle'>Veja abaixo as tarefas cadastradas.</p>
                          {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
                        </div>
                        <div className='list_card'>
                          {/* {isLoading && (
                          <p className='loading-card'>
                            <Loading type='1' />
                          </p>
                        )} */}
                          {!processPage &&
                            lessonsFiltered?.map((lesson) => {
                              const status = dropDownStatus?.find((value) => value.id === lesson.id && value.status);
                              return (
                                <div className='card_list_turma' key={`${lesson.id}`}>
                                  <div className='content_card'>
                                    <div className='card_details_alternative'>
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p className='card_details_label text_uppercase my-auto' style={{ width: '360px' }}>
                                          <span style={{ color: '#3b3b3b' }}>{lesson.concept}</span>
                                        </p>
                                        <div ref={ref} style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '120px' }}>
                                          <div
                                            onClick={() => setDropDownStatus(dropDownStatus?.map((value) => (value.id === lesson.id ? { ...value, status: !value.status } : { ...value })))}
                                            className={`${lesson.status === 'A' ? 'badge badge-success' : 'badge badge-danger'} badge-button`}
                                            style={status ? { borderRadius: 8, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { borderRadius: 8, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
                                          >
                                            {lesson.status === 'A' ? 'Ativo' : 'Inativo'}
                                            <div style={{ marginLeft: 10 }}><BsChevronDown color='#FFFFFF' /></div>
                                          </div>
                                          <div style={{ opacity: status ? 1 : 0 }} className='dropdown-shadow'>
                                            <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...lesson, status: 'A' })}>Ativo</div>
                                            <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...lesson, status: 'I' })}>Inativo</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'row', gap: 12, width: '120px' }}>
                                        <span onClick={() => edit(lesson)}><BsFillPencilFill /></span>
                                        <span onClick={() => view(lesson)}><BsEye /></span>
                                        <span onClick={() => toPdf(lesson)}><BsDownload /></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                            )}
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
              <div className='modal' style={display ? { display: 'flex', opacity: 1 } : undefined}>
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeAdd}>
                    X
                  </div>
                  <h3 className='modal_title'>
                    {lesson.id === undefined ? 'Criar nova tarefa' : 'Editar tarefa'}
                  </h3>
                  {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
                  <form onSubmit={handleSubmit} method='post' className='form_modal'>
                    <div className='w-100'>
                      <div className='row'>
                        <div className='col-sm-9'>
                          <div className='form_group'>
                            <label htmlFor='category_id' className='card_details_label'>
                              Selecionar categoria
                            </label>
                            <select
                              name='category_id'
                              className='form_control select'
                              value={lesson.category_id}
                              onChange={(e) => {
                                onChangeLesson(e);
                                addCategories(e)
                              }}
                            >
                              <option value=''>Selecionar</option>
                              {categoriesSelect}
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                          <div className='form_group'>
                            <label htmlFor='status' className='card_details_label'>
                              Status
                            </label>
                            <select
                              name='status'
                              className='form_control select'
                              value={lesson.status}
                              onChange={onChangeLesson}
                            >
                              <option value=''>Selecionar</option>
                              <option value='A'>Ativo</option>
                              <option value='I'>Inativo</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-12 mb-2'>
                          <div className="d-flex align-items-center"><span className='bagde-title'>Categoria(s) selecionada(s)</span> {categoriesSelectedList()}</div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-4'>
                          <div className='form_group'>
                            <label htmlFor='content_sport_id' className='card_details_label'>
                              Conteúdo
                            </label>
                            <select
                              name='content_sport_id'
                              className='form_control select'
                              value={lesson.content_sport_id}
                              onChange={(e) => {
                                onChangeLesson(e);
                                addContentSports(e);
                              }}
                            >
                              <option value=''>Selecionar</option>
                              {contentSportsSelect}
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='form_group'>
                            <label className='card_details_label'>Conceito</label>
                            <input
                              type='text'
                              className='form_control'
                              name='concept'
                              value={lesson.concept}
                              onChange={onChangeLesson}
                              placeholder='Inserir'
                            />
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='form_group'>
                            <label className='card_details_label'>Espaço</label>
                            <input
                              type='text'
                              className='form_control'
                              name='space'
                              value={lesson.space}
                              onChange={onChangeLesson}
                              placeholder='Inserir'
                            />
                          </div>
                        </div>
                        <div className='col-sm-12 mb-2'>
                          <div className="d-flex align-items-center"><span className='bagde-title'>Conteúdo(s) selecionado(s)</span> {contentSportsSelectedList()}</div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-4'>
                          <div className='form_group'>
                            <label htmlFor='didactic_strategy_id' className='card_details_label'>
                              Estratégia didática
                            </label>
                            <select
                              name='didactic_strategy_id'
                              className='form_control select'
                              value={lesson.didactic_strategy_id}
                              onChange={(e) => {
                                onChangeLesson(e);
                                addDidacticStrategy(e);
                              }}
                            >
                              <option value=''>Selecionar</option>
                              {didacticStrategySelect}
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='form_group'>
                            <label className='card_details_label'>Tempo</label>
                            <input
                              type='text'
                              className='form_control'
                              name='time'
                              value={lesson.time}
                              onChange={onChangeLesson}
                              placeholder='Inserir'
                            />
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='form_group'>
                            <label className='card_details_label'>Sessão da aula/treino</label>
                            <select
                              name='lesson_session_id'
                              className='form_control select'
                              value={lesson.lesson_session_id}
                              onChange={onChangeLesson}
                            >
                              <option value=''>Selecionar</option>
                              {sessionsSelect}
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-12 mb-2'>
                          <div className="d-flex align-items-center"><span className='bagde-title'>Estratégia(s) selecionada(s)</span> {didacticStrategySelectList()}</div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <div className='form_group'>
                            <label htmlFor='material_id' className='card_details_label'>
                              Material
                            </label>
                            <select
                              name='material_id'
                              className='form_control select'
                              value={lesson.material_id}
                              onChange={(e) => {
                                onChangeLesson(e);
                                addMaterial(e);
                              }}
                            >
                              <option value=''>Selecionar</option>
                              {materialsSelect}
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-6'>
                          <div className='form_group'>
                            <label className='card_details_label'>N. de jogadores</label>
                            <input
                              type='number'
                              className='form_control'
                              name='numberOfPlayers'
                              value={lesson.numberOfPlayers}
                              onChange={onChangeLesson}
                              placeholder='Inserir'
                            />
                          </div>
                        </div>
                        <div className='col-sm-12 mb-2'>
                          <div className="d-flex align-items-center"><span className='bagde-title'>Material(ais) selecionado(s)</span> {materialsSelectedList()}</div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form_group'>
                            <label className='card_details_label'>Descrição</label>
                            <textarea
                              className='form_control'
                              name='description'
                              value={lesson.description}
                              onChange={onChangeLesson}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form_group'>
                            <label className='card_details_label'>Regras</label>
                            <textarea
                              className='form_control'
                              name='rules'
                              value={lesson.rules}
                              onChange={onChangeLesson}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form_group'>
                            <label className='card_details_label'>Pontuação</label>
                            <textarea
                              className='form_control'
                              name='score'
                              value={lesson.score}
                              onChange={onChangeLesson}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form_group'>
                            <label className='card_details_label'>Variação</label>
                            <textarea
                              className='form_control'
                              name='variation'
                              value={lesson.variation}
                              onChange={onChangeLesson}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form_group'>
                            <label className='card_details_label'>Feedback</label>
                            <textarea
                              className='form_control'
                              name='feedback'
                              value={lesson.feedback}
                              onChange={onChangeLesson}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form_group'>
                            <label className='card_details_label'>Link Youtube/Vimeo</label>
                            <input
                              type='url'
                              className='form_control'
                              name='videoURL'
                              value={lesson.videoURL}
                              onChange={onChangeLesson}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form_group'>
                            <span className='card_details_label mb-2'>Upload do desenho</span>
                            <div className='mb-3 mx-auto'>
                              <img src={previewFile || lesson?.artUrl} width='100%' alt='' />
                            </div>
                            <div className='relative d-flex flex-column border border-danger-subtle rounded justify-content-center align-items-center gap-2 p-3'>
                              <input type='file' name='artURL' className='absolute top-0 left-0 opacity-0' onChange={(e) => handleFile(e)} />
                              <Upload />
                              <div>Clique aqui para fazer o upload do arquivo</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-1 mt-3'>
                      <button type='button' className='btn-main-color mr-3' onClick={closeAdd}>
                        Voltar
                      </button>
                      <button className='btn-secondary-color'>
                        {lesson.id === undefined ? 'Criar Tarefa' : 'Editar Tarefa'}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="modal_success" style={success ? { display: 'flex', opacity: 1 } : undefined}>
                  <div className='modal_content_success'>
                    <div className="modal_header_success">
                      <div className='modal_title_success'>{lesson.id === undefined ? 'Tarefa criada' : 'Alterações salvas'}</div>
                      <div className='modal_close_success' onClick={closeAdd}>
                        X
                      </div>
                    </div>
                    <div className='modal_body_success'>
                      <div className='modal_icon_success'>
                        <Success style={{ color: '#24c782' }} />
                      </div>
                      <div className="">{lesson.id === undefined ? 'Tarefa criada com sucesso!' : 'Tarefa editada com sucesso!'}</div>
                    </div>
                    <div className='modal_footer_success'>
                      <button type='button' onClick={closeAdd} className='btn btn-secondary-color w-100'>
                        Fechar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal' style={displayView ? { display: 'flex', opacity: 1 } : undefined}>
                <div className='modal_content'>
                  <div className='modal_close' onClick={closeView}>
                    X
                  </div>
                  <div id='lesson'>
                    <div className='modal_title' style={{ textAlign: 'center' }}>
                      {lesson.concept}
                    </div>
                    <div className='w-100'>
                      <div className='row'>
                        <div className='col-sm-9'>
                          <label htmlFor='status' className='card_details_label'>
                            Categorias
                          </label>
                          {categoriesSelectedList('view')}
                        </div>
                        <div className='col-sm-3'>
                          <div className='form_group'>
                            <label htmlFor='status' className='card_details_label'>
                              Status
                            </label>
                            {lesson.status === 'A' ? 'Ativo' : 'Inativo'}
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-9'>
                          <div className='form_group'>
                            <label htmlFor='content_sport_id' className='card_details_label'>
                              Conteúdo
                            </label>
                            {contentSportsSelectedList('view')}
                          </div>
                        </div>
                        <div className='col-sm-3'>
                          <div className='form_group'>
                            <span className='card_details_label'>Espaço</span>
                            {lesson.space}
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <div className='form_group'>
                            <label htmlFor='didactic_strategy_id' className='card_details_label'>
                              Estratégia didática
                            </label>
                            {didacticStrategySelectList('view')}
                          </div>
                        </div>
                        <div className='col-sm-3'>
                          <div className='form_group'>
                            <label className='card_details_label'>Tempo</label>
                            {lesson.time}
                          </div>
                        </div>
                        <div className='col-sm-3'>
                          <div className='form_group'>
                            <label className='card_details_label'>Sessão da aula/treino</label>
                            {lessonSessionLabel}
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-9'>
                          <div className='form_group'>
                            <label htmlFor='material_id' className='card_details_label'>
                              Material
                            </label>
                            {materialsSelectedList('view')}
                          </div>
                        </div>
                        <div className='col-sm-3'>
                          <div className='form_group'>
                            <label className='card_details_label'>N. de jogadores</label>
                            {lesson.numberOfPlayers}
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form_group'>
                            <label className='card_details_label'>Descrição</label>
                            {lesson.description}
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <div className='form_group'>
                            <label className='card_details_label'>Regras</label>
                            {lesson.rules}
                          </div>
                        </div>
                        <div className='col-sm-6'>
                          <div className='form_group'>
                            <label className='card_details_label'>Pontuação</label>
                            {lesson.score}
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <div className='form_group'>
                            <label className='card_details_label'>Variação</label>
                            {lesson.variation}
                          </div>
                        </div>
                        <div className='col-sm-6'>
                          <div className='form_group'>
                            <label className='card_details_label'>Feedback</label>
                            {lesson.feedback}
                          </div>
                        </div>
                      </div>
                      {lesson.artUrl && <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form_group'>
                            <span className='card_details_label mb-2'>Exemplo</span>
                            <div className='border border-warning p-3 mt-1 rounded-lg w-100 d-flex justify-content-center'>
                              <img src={lesson.artUrl} width='100%' alt='' />
                            </div>
                          </div>
                        </div>
                      </div>}
                      {lesson.videoURL && <div className='row mt-3'>
                        <div className='col-sm-12'>
                          <span className='card_details_label mb-2'>Vídeo</span>
                          <iframe width='100%' height='300px' src={lesson.videoURL} />
                        </div>
                      </div>}
                    </div>
                    <div className='d-flex flex-1 mt-3'>
                      <button type='button' className='btn-main-color' onClick={closeView}>
                        Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'none' }}>
                <div ref={lessonPdfRef}>
                  <div className='m-5' style={{ height: '100vh' }}>
                    <div className='d-flex flex-1 flex-column justify-content-center align-items-center'>
                      <img src="../../img/logo.png" alt="Marca" />
                      <h1 className='mt-3'>
                        {lesson.concept}
                      </h1>
                    </div>
                    <div className='row'>
                      <div className='col-sm-9'>
                        <span style={{ fontWeight: 'bold' }}>Categorias</span>
                        {categoriesSelectedList('pdf')}
                      </div>
                      <div className='col-sm-3'>
                        <span style={{ fontWeight: 'bold' }}>Status: </span>
                        {lesson.status === 'A' ? 'Ativo' : 'Inativo'}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-9'>
                        <span style={{ fontWeight: 'bold' }}>
                          Conteúdo
                        </span>
                        {contentSportsSelectedList('pdf')}
                      </div>
                      <div className='col-sm-3'>
                        <span style={{ fontWeight: 'bold' }}>Espaço: </span>
                        {lesson.space}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-6'>
                        <span style={{ fontWeight: 'bold' }}>
                          Estratégia didática
                        </span>
                        {didacticStrategySelectList('pdf')}
                      </div>
                      <div className='col-sm-3'>
                        <span style={{ fontWeight: 'bold' }}>Tempo: </span>
                        {lesson.time}
                      </div>
                      <div className='col-sm-3'>
                        <span style={{ fontWeight: 'bold' }}>Sessão da aula/treino: </span>
                        {lessonSessionLabel}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-9'>
                        <span style={{ fontWeight: 'bold' }}>
                          Material
                        </span>
                        {materialsSelectedList('pdf')}
                      </div>
                      <div className='col-sm-3'>
                        <span style={{ fontWeight: 'bold' }}>N. de jogadores: </span>
                        {lesson.numberOfPlayers}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-12'>
                        <div style={{ fontWeight: 'bold' }}>Descrição</div>
                        {lesson.description}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-6'>
                        <div style={{ fontWeight: 'bold' }}>Regras</div>
                        {lesson.rules}
                      </div>
                      <div className='col-sm-6'>
                        <div style={{ fontWeight: 'bold' }}>Pontuação</div>
                        {lesson.score}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-6'>
                        <div style={{ fontWeight: 'bold' }}>Variação</div>
                        {lesson.variation}
                      </div>
                      <div className='col-sm-6'>
                        <div style={{ fontWeight: 'bold' }}>Feedback</div>
                        {lesson.feedback}
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-12'>
                        <div style={{ fontWeight: 'bold' }}>Exemplo</div>
                        <div className='border p-3 rounded-lg mt-3 d-flex justify-content-center'>
                          <img src={lesson?.artUrl} width='100%' alt='' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  )
}
export default AdminLesson