import React from 'react';
import InvoiceCreditService from '../services/InvoiceCreditService';
import Loading from './Loading';
import { useTranslation } from 'react-i18next';

const PlanCard = ({ plan, onBuyPlan, loading }) => {
  const { t } = useTranslation();
  const { name, value, description, features, isMain } = plan;

  return (
    <div
      style={{
        borderRadius: 9,
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        background: isMain ? 'linear-gradient(0.1turn,#525A61, #2F3132)' : 'white',

        width: 210,
        border: '1px solid rgba(196, 219, 228, 0.49)',
        color: isMain ? 'white' : '#3B3B3B',
      }}
    >
      <h2 style={{ fontSize: 13 }}>{t(`PlanCard.${name}`, { defaultValue: name })}</h2>
      <p>
        <span style={{ fontWeight: 400 }}>R$</span>
        <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>
          <strong>{value.toFixed(2).replace('.', ',')}</strong>
        </span>
      </p>
      <p style={{ fontWeight: 400, fontSize: '0.5625rem', color: isMain ? '#fff4' : '#3B3B3B73' }}>{description}</p>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16, flex: 1 }}>
        {features.split('\n').map((feature) => (
          <p style={{ display: 'flex', alignItems: 'center', gap: 6, fontSize: '0.625rem' }}>
            <img src='img/icones/check_circle.svg' />
            {t(`PlanCard.${feature}`, { defaultValue: feature })}
          </p>
        ))}
      </div>
      <button
        style={{
          alignSelf: 'end',
          border: '1px solid #525A61',
          color: 'inherit',
          padding: '13px 39px',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '0.6875rem',
          background: isMain ? 'linear-gradient(93.47deg, #27CD87 -3.55%, #30966B 115.64%)' : 'transparent',
          whiteSpace: 'nowrap',
          marginTop: 20,
        }}
        onClick={() => onBuyPlan(plan)}
        disabled={loading}
      >
        Escolher plano {loading && <Loading type='1' />}
      </button>
    </div>
  );
};

export default PlanCard;
