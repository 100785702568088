import React, { useState, forwardRef } from 'react';
import { DaysOfWeek } from '../../../../src/utils/utils';
import { useTranslation } from 'react-i18next';
import { ModalWithMethods } from '../../../components/modal/Modal';
import { FaEye, FaEdit } from 'react-icons/fa';
import ButtonRounded from '../../../components/buttons/ButtonRounded';
import moment from 'moment';
import { Collapse } from '@material-ui/core';
import AttendanceForm from './AttendanceForm';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loading from '../../../components/Loading';

const ViewAttendances = ({ studentClass, student, weekDaySelected, isLoading, isSending, onSubmit, ...props }, ref) => {
  const { t } = useTranslation();

  const [attendance, setAttendance] = useState(null);
  const [sweetAlertAttachments, setSweetAlertAttachments] = useState(null);

  const showErrorMessage = (message) => {
    ref.current.showErrorMessage(message);
    ref.current.scrollTop();
  };

  const showSuccessMessage = (message) => {
    ref.current.showSuccessMessage(message);
    ref.current.scrollTop();
  };

  const showWarningMessage = (message) => {
    ref.current.showWarningMessage(message);
    ref.current.scrollTop();
  };

  const resetForm = () => {
    setAttendance(null);
    ref.current.clearMessage();
  };

  const handleOnSubmit = async (formData) => {
    const { status, message } = await onSubmit(formData);

    if (status === 'SUCCESS') {
      showSuccessMessage(message);
      setAttendance(null);
    } else if (status === 'WARNING') {
      showWarningMessage(message);
      setAttendance(null);
    } else showErrorMessage(message);
  };

  const showAttachments = (attachments) => {
    setSweetAlertAttachments(
      <SweetAlert
        title={t('Attachments')}
        showCloseButton
        onConfirm={() => setSweetAlertAttachments(null)}
        confirmBtnText={t('Close')}
      >
        <ul>
          {attachments.map((file) => (
            <li key={`AttendanceForm_File_${file.id}`}>
              <a className='table_mes' target='_black' href={file.url}>
                {file.name}
              </a>
            </li>
          ))}
        </ul>
      </SweetAlert>
    );
  };

  return (
    <ModalWithMethods
      {...props}
      ref={ref}
      title={t('ViewJustifiedAbsences')}
      size={'md'}
      modalContentClassName='pb-2'
      titleClassName='text-center'
      onClose={resetForm}
    >
      <div className='row mx-0 w-100 mt-2'>
        {!isLoading && (
          <div className='col-12 text-center mb-1' style={{ lineHeight: '1.2' }}>
            <span className='d-block text-bold'>{student?.name}</span>
            <span className='badge badge-secondary'>
              {student?.attendances?.length || '0'} {t('Absences_alt')}
            </span>
          </div>
        )}
      </div>

      <div className='row mx-0 w-100 mt-2'>
        {isLoading && (
          <p className='loading-card sidebar_data' style={{ height: '45px' }}>
            <Loading type='1' />
          </p>
        )}
        {!isLoading &&
          student?.attendances?.map((attendanceAux) => (
            <div
              key={`View_Attendance_${student.id}_${attendanceAux.id}`}
              className='col-12 shadow mt-1 mb-2'
              style={{ borderRadius: '8px' }}
            >
              <div className='d-flex py-2 justify-content-between'>
                <span>{moment(attendanceAux.date).format('DD/MM/YYYY')}</span>
                <span className='px-2 text-bold text-center'>{attendanceAux.justification_title}</span>
                <div className='d-flex flex-row' style={{ columnGap: '5px' }}>
                  <ButtonRounded
                    className='py-0 px-1 h-auto'
                    style={{ borderRadius: '8px' }}
                    isDisabled={attendanceAux.class_absence_justifications.length === 0}
                    onClick={() => showAttachments(attendanceAux.class_absence_justifications)}
                  >
                    <FaEye style={{ width: '18px', height: '18px' }} /> {t('Attachments')}
                  </ButtonRounded>
                  <ButtonRounded
                    className='py-0 px-2 h-auto'
                    style={{ borderRadius: '8px' }}
                    onClick={() => setAttendance((c) => (c?.id === attendanceAux.id ? null : attendanceAux))}
                  >
                    <FaEdit className='m-0' style={{ width: '18px', height: '18px' }} />
                  </ButtonRounded>
                </div>
              </div>

              <hr className='border-color-default' style={{ margin: '0 -15px' }} />

              <Collapse in={attendance?.id === attendanceAux.id}>
                <div className='py-1 text-justify' style={{ fontSize: '13.6px' }}>
                  <AttendanceForm
                    active={attendance?.id === attendanceAux.id}
                    initialData={attendanceAux}
                    isSending={isSending}
                    onSubmit={handleOnSubmit}
                  />
                </div>
              </Collapse>

              <Collapse in={attendance?.id !== attendanceAux.id}>
                <div className='py-1 text-justify' style={{ fontSize: '13.6px' }}>
                  {attendanceAux.justification_description}
                </div>
              </Collapse>
            </div>
          ))}
      </div>

      <div className='row mx-0 w-100 mb-2 mt-auto'>
        {studentClass.id && (
          <div className='col-12 mb-2 text-center'>
            <p
              key={`RegisterStudentAttendance_info_${studentClass.classId}`}
              className='card_subtitle d-flex d-md-block flex-column mt-2'
            >
              {[
                { key: t('Classes'), value: studentClass.name },
                { key: t('Schedule'), value: studentClass.hour },
                { key: t('Day'), value: t(DaysOfWeek[weekDaySelected].nameKey) },
                { key: t('Responsible'), value: studentClass.responsaveis },
              ].map(({ key, value }, i) => (
                <span key={`studentClass_list_infos_${studentClass.classId}_${studentClass.classDayId}_${key}`}>
                  {i !== 0 && <span className='mx-1 d-none d-md-inline'>|</span>}
                  <b>{key}:</b> {value}
                </span>
              ))}
            </p>
          </div>
        )}

        <div className='col-12'>
          <ButtonRounded
            variant='grey'
            color='#ffffff'
            type='submit'
            className='w-100 py-3'
            style={{ borderRadius: '8px', height: '37px' }}
            onClick={() => ref.current.closeModal()}
          >
            {t('Close')}
          </ButtonRounded>
        </div>
      </div>

      {sweetAlertAttachments}
    </ModalWithMethods>
  );
};

export default forwardRef(ViewAttendances);
