import React, { Component } from 'react'

import './Footer.css'

export default class Footer extends Component {
    render() {
        return (
            <div className="footerClubeBeneficios">
                <a href="/docs/TERMOS DE USO ATLETA - FENSOR FLASH.pdf" download="/docs/TERMOS DE USO ATLETA - FENSOR FLASH.pdf">Termos de uso</a>
                <a href="/docs/POLÍTICA DE PRIVACIDADE - FENSOR FLASH.pdf" download="/docs/POLÍTICA DE PRIVACIDADE - FENSOR FLASH.pdf">Política de privacidade</a>
                <div className="copy">
                    COPYRIGHT © 2020. Desenvolvido por <a target='_blank' href="https://mauicomunicacao.com.br">Maui Comunicação.</a>
                </div>
            </div>
        );
    }
}