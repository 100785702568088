import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import canView, { homeCaseRedirect } from './Permissions';
import { UserContext } from '../../Context/UserContext';
import Sidebar from '../sidebar/Sidebar';
const PrivateRouter = (props) => {
  const { isAuthenticated } = useContext(UserContext);
  const domain = window.location.href.split('/')[2].split('.')[0];
  const to = domain === 'fensor' || domain === 'www' ? '/' : '/school';

  const userCanView = canView(props.path);

  if (!isAuthenticated()) return <Redirect to={to} />;
  else if (!userCanView && (props.path === '/home-school' || props.path === '/home-club'))
    return <Redirect to={homeCaseRedirect()} />;
  else
    return userCanView ? (
      <Sidebar>
        <Route {...props} />
      </Sidebar>
    ) : (
      <Redirect to='/nao-autorizado' />
    );
};

export default PrivateRouter;
