import React from 'react';
import Message from '../../../components/Message';
import useForm from '../../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import ButtonRounded from '../../../components/buttons/ButtonRounded';
import {
  getQuestionnaires,
  searchByZipCodeCountry,
  getStates,
  getCitysByStateSigla,
} from '../../../services/SharedService';
import { StudentFormContext } from '../../../Context/StudentFormContext';

import '../../../utils/extendsType';
import NumberFormat from 'react-number-format';

const { MessageDangerMarginTop } = Message();

const Responsaveis = ({ title = 'Informações Aluno', nextTab }) => {
  const context = React.useContext(StudentFormContext);

  const formRefer = React.useRef(null);
  const estadoRefer = React.useRef(null);
  const cidadeRefer = React.useRef(null);

  const [states, setStates] = React.useState([]);
  const [citys, setCitys] = React.useState([]);

  const [sendSave, setSendSave] = React.useState(false);

  const [errors, setErrors] = React.useState([]);

  const [errorsServer, setErrorsServe] = React.useState([]);

  const [questionnaires, setQuestionnaires] = React.useState([]);

  const { t, i18n } = useTranslation();

  const handlerErrorServer = (err) => {
    if (err.response.status === 422) {
      const { message = [] } = err.response.data;

      Object.keys(message).forEach((k) => {
        if (state[k] === undefined) {
          setErrorsServe([message.toString()]);
          return;
        }
        const element = document.querySelector(`[name=${k}]`);
        if (element) {
          dispatchHtmlValidity(element, `[${k}] ${message[k]}`);
        }
        setState({
          ...state,
          [k]: { value: state[k]?.value, error: message[k] },
        });
      });
    } else setErrorsServe([err.response.data.message.toString()]);
  };

  const onSubmitForm = (payload, e, s, formData) => {
    const { tipo = undefined } = e.target.dataset;

    formData.append('companie_id', context.school.companie_id);
    formData.append('role_id', 2);
    formData.append('usuStudent', 'S');
    formData.append('school_id', context.school.id);
    formData.append('session_user_id', context.user.id);
    formData.append('location', i18n.language);
    formData.append('athCodigoAluno', context.atleta.athCodigoAluno.value);
    formData.append('athTipoStudent', context.atleta.athTipoStudent.value);
    formData.append('athId', context.atleta.athId.value);
    formData.append('athIndication', context.atleta.athIndication.value);
    formData.append('id', context.atleta.id.value);

    setSendSave(true);
    context
      .requestValidate('/validate/parent', formData)
      .then((data) => {
        //persiste os dados sem tratamento
        context.setState({
          responsaveis: {
            ...state,
            ...context.normalResponse(data.message),
          },
          atleta: {
            ...context.atleta,
            ...context.normalResponse(data.message),
          },
        });
        if (tipo !== undefined && tipo === 'finalizar') {
          context.handlerFinally();
          return null;
        }
        //avanca a tab
        nextTab(2);
      })
      .catch(handlerErrorServer)
      .finally(() => setSendSave(false));
  };

  const { state, handleOnChange, handleOnSubmit, setState, disable, setIsDirty, dispatchHtmlValidity } = useForm(
    context.responsaveis,
    validationStateSchema,
    onSubmitForm,
    formRefer,
    setErrors
  );

  const handlerRequestCep = async (e) => {
    e.preventDefault();
    if (e.currentTarget.value) {
      const btn = e.currentTarget.loader();

      const v = state.athCep.value;

      if (v.length < 8) return;

      btn.start();

      try {
        const response = await searchByZipCodeCountry(v, i18n.language); //TODO: recuperar a location do usuario
        if (response.success) {
          cidadeRefer.current.disabled = false;
          setState((prevState) => ({
            ...prevState,
            athLogradouro: { value: response.streetName, error: '' },
            athBairro: { value: response.neighborhood, error: '' },
            uf: { value: response.state, error: '' },
            cidadeID: { value: response.city.id, error: '' },
          }));
          return null;
        }
        throw new Error('Não foi possível encontrar o CEP informado.');
      } catch (error) {
        setErrorsServe(['Não foi possível encontrar o CEP informado.']);
      } finally {
        btn.reset();
        const event = new Event('change', { bubbles: true });
        estadoRefer.current.dispatchEvent(event);
      }
    }
  };

  const fetchStates = async (location) => {
    const res = await getStates(location);
    setStates(res.states);
  };

  const getCitys = async (estado) => {
    setCitys([]);

    const res = await getCitysByStateSigla(estado, i18n.language);

    setCitys(res.cidades);
  };

  React.useEffect(() => {
    let isMounted = true;
    (async () => {
      if (isMounted) {
        const res = await getQuestionnaires(context.company.id);
        setQuestionnaires(res.questionnaire);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  React.useEffect(() => {
    setIsDirty(true);
    fetchStates(i18n.language);
    if (i18n.language === 'en-US' || i18n.language === 'en-US-Arabic') {
      cidadeRefer.current.disabled = false;
    }
  }, []);

  React.useEffect(() => {
    if (state.cidadeID.value) {
      setState((prevState) => ({
        ...prevState,
        cidade: { value: state.cidadeID.value, error: '' },
        cidadeID: { value: 0, error: '' },
      }));
    }
  }, [citys]);

  React.useEffect(() => {
    if (state.uf.value) {
      getCitys(state.uf.value);
    }
  }, [state.uf.value]);

  return (
    <>
      {errors.map((err, k) => (
        <MessageDangerMarginTop
          key={`err-${k}`}
          title='Atenção!'
          description={t(`${err.error.replace(/\[.*?\]/g, '').trim()}`)}
        />
      ))}
      {errorsServer.map((item, k) => (
        <MessageDangerMarginTop title='Atenção! ' description={item} key={`error_${k}`} />
      ))}
      <div className='mx-3'>
        <form ref={formRefer} action='' className='form_card' noValidate>
          <div className='col p-0 w-100'>
            <div className='row'>
              <div className='col-md-3 col-sm-12 form_group'>
                <label className='card_details_label mb-2'>{t('CEP')}</label>
                <input
                  type='text'
                  name='athCep'
                  style={{ marginTop: 0 }}
                  required
                  className='form-control form_control'
                  value={state.athCep.value || ''}
                  onChange={handleOnChange}
                  onBlur={handlerRequestCep}
                />
              </div>
              <div className='col-md-5 col-sm-12 form_group'>
                <label className='card_details_label'> {t('Address')}</label>
                <input
                  type='text'
                  value={state.athLogradouro.value || ''}
                  required
                  onChange={handleOnChange}
                  placeholder={t('Address')}
                  name='athLogradouro'
                  className='form-control form_control'
                />
              </div>
              <div className='col-md-4 col-sm-12 form_group'>
                <label className='card_details_label'> {t('Neighborhood')}</label>
                <input
                  type='text'
                  value={state.athBairro.value || ''}
                  onChange={handleOnChange}
                  name='athBairro'
                  className='form-control form_control'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-12 form_group'>
                <label className='card_details_label'>{t('Number')}</label>
                <input
                  type='text'
                  name='numero'
                  required
                  className='form-control form_control'
                  value={state.numero.value || ''}
                  onChange={handleOnChange}
                />
              </div>
              <div className='col-md-4 col-sm-12 form_group'>
                <label className='card_details_label'>{t('State')}</label>
                <select
                  ref={estadoRefer}
                  name='uf'
                  className='form_control select form-control'
                  value={state.uf.value || ''}
                  onChange={handleOnChange}
                >
                  <option value=''>-- {t('Select')} --</option>
                  {states.map((item) => (
                    <option value={item.staStateSigla} key={item.staState}>
                      {item.staState}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-md-4 col-sm-12 form_group'>
                <label className='card_details_label'>{t('City')}</label>
                <select
                  ref={cidadeRefer}
                  name='cidade'
                  required
                  className='form_control select'
                  placeholder={t('City')}
                  value={state.cidade.value || ''}
                  onChange={handleOnChange}
                >
                  <option value=''>-- {t('Select')} --</option>
                  {citys.map((item) => (
                    <option value={item.id} key={item.citCity}>
                      {item.citCity}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12 col-sm-12 form_group'>
                <label className='card_details_label'>Complemento</label>
                <input
                  type='text'
                  value={state.complemento.value || ''}
                  onChange={handleOnChange}
                  name='complemento'
                  className='form-control form_control'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 col-sm-12 form_group'>
                <label className='card_details_label'> {t('MotherName')}</label>
                <input
                  type='text'
                  name='athMaeNome'
                  placeholder={t('MotherName')}
                  className='form-control form_control'
                  value={state.athMaeNome.value || ''}
                  onChange={handleOnChange}
                />
              </div>
              <div className='col-md-3 col-sm-12 form_group'>
                <label className='card_details_label'> {t('MotherCellPhone')}</label>
                <NumberFormat
                  type='text'
                  name='athMaeCelular'
                  className='form-control form_control'
                  value={state.athMaeCelular.value || ''}
                  onChange={handleOnChange}
                  format={t('Mask.CellPhone') || undefined}
                />
              </div>
              <div className='col-md-3 col-sm-12 form_group'>
                <label className='card_details_label'> {t('MotherCPF')}</label>
                <NumberFormat
                  name='athMaeCPF'
                  format={t('Mask.CPF')}
                  className='form-control form_control'
                  mask='_'
                  value={state.athMaeCPF.value || ''}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 col-sm-12 form_group'>
                <label className='card_details_label'> {t('FatherName')}</label>
                <input
                  type='text'
                  name='athPaiNome'
                  className='form-control form_control'
                  value={state.athPaiNome.value || ''}
                  onChange={handleOnChange}
                />
              </div>
              <div className='col-md-3 col-sm-12 form_group'>
                <label className='card_details_label'> {t('FatherCellPhone')}</label>
                <NumberFormat
                  type='text'
                  name='athPaiCelular'
                  className='form-control form_control'
                  value={state.athPaiCelular.value || ''}
                  onChange={handleOnChange}
                  format={t('Mask.CellPhone') || undefined}
                />
              </div>
              <div className='col-md-3 col-sm-12 form_group'>
                <label className='card_details_label'> {t('FatherCPF')}</label>
                <NumberFormat
                  name='athPaiCPF'
                  format={t('Mask.CPF')}
                  className='form-control form_control'
                  mask='_'
                  value={state.athPaiCPF.value || ''}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-sm-12 form_group'>
                <div className='form_group'>
                  <label htmlFor='how' className='card_details_label'>
                    {t('HowKnowSchool')}
                  </label>
                  <select
                    name='questionario'
                    className='form_control select form-control'
                    value={state.questionario.value}
                    onChange={handleOnChange}
                  >
                    <option value=''>Selecionar</option>
                    {questionnaires &&
                      questionnaires.map((item) =>
                        item.status === 'A' ? (
                          <option value={item.id} key={item.id}>
                            {t(item.name)}
                          </option>
                        ) : (
                          <option value={item.id} key={item.id} disabled hidden>
                            {t(item.name)} ({t('DisabledOption')})
                          </option>
                        )
                      )}
                  </select>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12 col-sm-12 form_group'>
                <div className='form_group'>
                  <label htmlFor='descript' className='card_details_label'>
                    {t('Description')}
                  </label>
                  <textarea
                    className='form_control'
                    name='descricao'
                    value={state.descricao.value || ''}
                    cols='30'
                    rows='10'
                    onChange={handleOnChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className='row align-items-center justify-content-center my-4'>
        <div className='col-md-5 col-sm-10'>
          <ButtonRounded
            isDisabled={disable}
            isLoading={sendSave}
            data-tipo='finalizar'
            onClick={handleOnSubmit}
            className='w-100'
            isBig={true}
          >
            {t('Save')} {t('And')} {t('Finalize')}
          </ButtonRounded>
        </div>
        <div className='col-md-5 col-sm-10'>
          <ButtonRounded
            isDisabled={disable}
            isLoading={sendSave}
            onClick={handleOnSubmit}
            className='w-100'
            isBig={true}
          >
            {t('Save')} {t('And')} {t('Continue')}
          </ButtonRounded>
        </div>
      </div>
    </>
  );
};

const validationStateSchema = {
  athCep: {
    required: false,
    error: 'Preencha o campo CEP',
    mask: (value) => {
      if (typeof value === 'undefined' || value == null) return '';

      value = value.replace(/\D/g, '');

      if (value.length > 8) {
        value = value.substring(0, 8);
      }

      if (value.length > 5) {
        return value.replace(/^(\d{5})(\d{1,3})$/, '$1-$2');
      } else {
        return value;
      }
    },
  },
};

export default Responsaveis;
