import React from 'react'
import Header from '../../components/athlete/Header'
import Footer from '../../components/athlete/Footer'
import Seletivas from '../../components/athlete/Seletivas'
import Events from '../../components/athlete/Events'
import Resultados from '../../components/athlete/Resultados'

const AgendaAthlete = (props) => {

   return (
         <React.Fragment>
            
            <Header type="1" menu={true}/>

            <main>

               <section className="bloco-principal">
                  <div className="conteudo-bloco-principal">
                     <div className="bloco-1">
                        <Resultados carregarMais={false} />
                        <Seletivas carregarMais={false} verTodos={false} verDetalhes={true} limit="" myFuture={true} title="SUAS PRÓXIMAS SELETIVAS" description="Nessa página você vai encontrar as seletivas que você se inscreveu." />
                        <Events carregarMais={false} verTodos={false} verDetalhes={true} limit="" myFuture={true} title="SEUS PRÓXIMOS EVENTOS" description="Veja abaixo os próximos eventos que você se inscreveu."/>
                     </div>

                     <div className="bloco-2">
                        <div className="conteudo-generico">
                           <div className="img-tarefas-iniciais">
                              <img src="../../img/index/complete-perfil.png"/>
                           </div>

                           <ul className="tarefas-iniciais">
                              <li className="tarefa-feita">
                                 <div className="icone-check"></div>

                                 <div className="texto-tarefa">
                                    <p>Coloque uma foto no seu vestiário.</p>
                                 </div>
                              </li>


                              <li className="tarefa-feita">
                                 <div className="icone-no-check"></div>

                                 <div className="texto-tarefa">
                                    <p>Preencha todos os dados gerais.</p>
                                 </div>
                              </li>


                              <li className="tarefa-feita">
                                 <div className="icone-no-check"></div>

                                 <div className="texto-tarefa">
                                    <p>Configure o seu currículo.</p>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>

                  </div>
               </section>
            </main>

            <Footer />
          
         </React.Fragment>
   )
}

export default AgendaAthlete