import React from 'react'
import { uploadFolder } from '../utils/api'

const LoadingTheme = ({schoolTheme}) => {
    const uploadURL = uploadFolder()
    const company = JSON.parse(localStorage.getItem('company'))
    const school = JSON.parse(localStorage.getItem('school'))

    if (schoolTheme !== undefined && schoolTheme !== null) {
        document.documentElement.style.setProperty('--main-color', schoolTheme.defaultColor)
        document.documentElement.style.setProperty('--main-color-alfa', schoolTheme.defaultColor + '20')
        document.documentElement.style.setProperty('--background-image', `url(${uploadURL}upload/schools/background/${schoolTheme.mainBackground})`)
    }

    else if (company !== null && school === null) {
        const { defaultColor, mainBackground } = company
        document.documentElement.style.setProperty('--main-color', defaultColor)
        document.documentElement.style.setProperty('--main-color-alfa', defaultColor + '20')
        document.documentElement.style.setProperty('--background-image', `url(${uploadURL}upload/companie/background/${mainBackground})`)
    }

    else if (company !== null && school !== null) {
        const { defaultColor, mainBackground } = school
        document.documentElement.style.setProperty('--main-color', defaultColor)
        document.documentElement.style.setProperty('--main-color-alfa', defaultColor + '20')
        document.documentElement.style.setProperty('--background-image', `url(${uploadURL}upload/schools/background/${mainBackground})`)
    }

    return <></>
}

export default LoadingTheme