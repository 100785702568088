export const RoleUtils = {
  Admin: { id: 1, label: 'Adm', keyI18n: 'EmployeeModel.Occupations.Adm' },
  Athlete: { id: 2, label: 'Atleta', keyI18n: 'EmployeeModel.Occupations.Atleta' },
  Photographer: { id: 4, label: 'Fotógrafo(a)', keyI18n: 'EmployeeModel.Occupations.Fotógrafo(a)' },
  Guest: { id: 5, label: 'Visitante', keyI18n: 'EmployeeModel.Occupations.Visitante' },

  isAdmin(value) {
    return value === this.Admin.id || value === this.Admin.label;
  },
  isAthlete(value) {
    return value === this.Athlete.id || value === this.Athlete.label;
  },
  isPhotographer(value) {
    return value === this.Photographer.id || value === this.Photographer.label;
  },
  isGuest(value) {
    return value === this.Guest.id || value === this.Guest.label;
  },
};

export const ClubRoleUtils = {
  Director: { id: 3, label: 'Diretor(a)', keyI18n: 'EmployeeModel.Occupations.Director' },
  Manager: { id: 12, label: 'Gestor(a)', keyI18n: 'EmployeeModel.Occupations.Manager' },
  Teacher: { id: 13, label: 'Professor(a)', keyI18n: 'EmployeeModel.Occupations.Teacher' },
  Secretary: { id: 14, label: 'Secretário(a)', keyI18n: 'EmployeeModel.Occupations.Secretary' },
  Coordinator: { id: 15, label: 'Coordenador(a)', keyI18n: 'EmployeeModel.Occupations.Coordinator' },
  Intern: { id: 16, label: 'Estagiário(a)', keyI18n: 'EmployeeModel.Occupations.Intern' },
  Other: { id: 17, label: 'Outro(a)(s)', keyI18n: 'EmployeeModel.Occupations.Other' },
  Physiotherapist: { id: 18, label: 'Fisioterapeuta', keyI18n: 'EmployeeModel.Occupations.Physiotherapist' },
  MassageTherapist: { id: 19, label: 'Marketing', keyI18n: 'EmployeeModel.Occupations.MassageTherapist' },

  isDirector(value) {
    return value === this.Director.id || value === this.Director.label;
  },
  isManager(value) {
    return value === this.Manager.id || value === this.Manager.label;
  },
  isTeacher(value) {
    return value === this.Teacher.id || value === this.Teacher.label;
  },
  isSecretary(value) {
    return value === this.Secretary.id || value === this.Secretary.label;
  },
  isCoordinator(value) {
    return value === this.Coordinator.id || value === this.Coordinator.label;
  },
  isIntern(value) {
    return value === this.Intern.id || value === this.Intern.label;
  },
  isOther(value) {
    return value === this.Other.id || value === this.Other.label;
  },
  isPhysiotherapist(value) {
    return value === this.Physiotherapist.id || value === this.Physiotherapist.label;
  },
  isMassageTherapist(value) {
    return value === this.MassageTherapist.id || value === this.MassageTherapist.label;
  },
};

export const SchoolRoleUtils = {
  Manager: { id: 6, label: 'Gestor(a)', keyI18n: 'EmployeeModel.Occupations.Manager' },
  Teacher: { id: 7, label: 'Professor(a)', keyI18n: 'EmployeeModel.Occupations.Teacher' },
  Secretary: { id: 8, label: 'Secretário(a)', keyI18n: 'EmployeeModel.Occupations.Secretary' },
  Coordinator: { id: 9, label: 'Coordenador(a)', keyI18n: 'EmployeeModel.Occupations.Coordinator' },
  Intern: { id: 10, label: 'Estagiário(a)', keyI18n: 'EmployeeModel.Occupations.Intern' },
  Other: { id: 11, label: 'Outro(a)(s)', keyI18n: 'EmployeeModel.Occupations.Other' },
  Physiotherapist: { id: 20, label: 'Fisioterapeuta', keyI18n: 'EmployeeModel.Occupations.Physiotherapist' },
  MassageTherapist: { id: 21, label: 'Marketing', keyI18n: 'EmployeeModel.Occupations.MassageTherapist' },

  isSchool(value) {
    return [6, 7, 8, 9, 10, 11, 20, 21].includes(value);
  },

  isManager(value) {
    return value === this.Manager.id || value === this.Manager.label;
  },
  isTeacher(value) {
    return value === this.Teacher.id || value === this.Teacher.label;
  },
  isSecretary(value) {
    return value === this.Secretary.id || value === this.Secretary.label;
  },
  isCoordinator(value) {
    return value === this.Coordinator.id || value === this.Coordinator.label;
  },
  isIntern(value) {
    return value === this.Intern.id || value === this.Intern.label;
  },
  isOther(value) {
    return value === this.Other.id || value === this.Other.label;
  },
  isPhysiotherapist(value) {
    return value === this.Physiotherapist.id || value === this.Physiotherapist.label;
  },
  isMassageTherapist(value) {
    return value === this.MassageTherapist.id || value === this.MassageTherapist.label;
  },
};

export const getRoles = () => {
  return Object.keys(RoleUtils)
    .map((key) => RoleUtils[key])
    .filter((item) => typeof item === 'object');
};

export const getClubRoles = () => {
  return Object.keys(ClubRoleUtils)
    .map((key) => ClubRoleUtils[key])
    .filter((item) => typeof item === 'object');
};

export const getSchoolRoles = () => {
  return Object.keys(SchoolRoleUtils)
    .map((key) => SchoolRoleUtils[key])
    .filter((item) => typeof item === 'object');
};
