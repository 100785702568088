import React from 'react';

const Message = () => {
  const MessageWarning = ({ title, description, ...props }) => {
    return (
      <div {...props} className='alert alert-warning alert-dismissible fade show' role='alert'>
        <strong>{title}</strong> <span style={{ whiteSpace: 'pre-line' }}>{description}</span>
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          {/* <span aria-hidden="true">&times;</span>*/}
        </button>
      </div>
    );
  };

  const MessageWarningMarginTop = ({ title, description, ...props }) => {
    return (
      <div {...props} className='alert alert-margin-top alert-warning alert-dismissible fade show' role='alert'>
        <strong>{title}</strong> <span style={{ whiteSpace: 'pre-line' }}>{description}</span>
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          {/* <span aria-hidden="true">&times;</span>*/}
        </button>
      </div>
    );
  };

  const MessageDanger = ({ title, description, ...props }) => {
    return (
      <div {...props} className='alert alert-danger alert-dismissible fade show' role='alert'>
        <strong>{title}</strong> <span style={{ whiteSpace: 'pre-line' }}>{description}</span>
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          {/* <span aria-hidden="true">&times;</span>*/}
        </button>
      </div>
    );
  };

  const MessageDangerMarginTop = ({ title, description, ...props }) => {
    return (
      <div {...props} className='alert alert-margin-top alert-danger alert-dismissible fade show' role='alert'>
        <strong>{title}</strong> <span style={{ whiteSpace: 'pre-line' }}>{description}</span>
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          {/* <span aria-hidden="true">&times;</span>*/}
        </button>
      </div>
    );
  };

  const MessagePrimary = ({ title, description, ...props }) => {
    return (
      <div {...props} className='alert alert-primary alert-dismissible fade show' role='alert'>
        <strong>{title}</strong> <span style={{ whiteSpace: 'pre-line' }}>{description}</span>
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          {/* <span aria-hidden="true">&times;</span>*/}
        </button>
      </div>
    );
  };

  const MessagePrimaryMarginTop = ({ title, description, ...props }) => {
    return (
      <div {...props} className='alert alert-margin-top alert-primary alert-dismissible fade show' role='alert'>
        <strong>{title}</strong> <span style={{ whiteSpace: 'pre-line' }}>{description}</span>
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          {/* <span aria-hidden="true">&times;</span>*/}
        </button>
      </div>
    );
  };

  const MessageSuccessMarginTop = ({ title, description, ...props }) => {
    return (
      <div {...props} className='alert alert-margin-top alert-success alert-dismissible fade show' role='alert'>
        <strong>{title}</strong> <span style={{ whiteSpace: 'pre-line' }}>{description}</span>
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          {/* <span aria-hidden="true">&times;</span>*/}
        </button>
      </div>
    );
  };

  const MessageSuccess = ({ title, description, ...props }) => {
    return (
      <div {...props} className='alert alert-success alert-dismissible fade show' role='alert'>
        <strong>{title}</strong> <span style={{ whiteSpace: 'pre-line' }}>{description}</span>
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          {/* <span aria-hidden="true">&times;</span>*/}
        </button>
      </div>
    );
  };

  const MessageDark = ({ title, description, ...props }) => {
    return (
      <div {...props} className='alert alert-dark alert-dismissible fade show' role='alert'>
        <strong>{title}</strong> <span style={{ whiteSpace: 'pre-line' }}>{description}</span>
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          {/* <span aria-hidden="true">&times;</span>*/}
        </button>
      </div>
    );
  };

  return {
    MessageWarning,
    MessageDanger,
    MessagePrimary,
    MessageSuccess,
    MessageDark,
    MessageDangerMarginTop,
    MessageSuccessMarginTop,
    MessagePrimaryMarginTop,
    MessageWarningMarginTop,
  };
};

export default Message;
