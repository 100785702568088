import React from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';

/**
 *
 * @param {{variant: 'default' | 'yellow' | 'grey' | 'red'; style: import('@material-ui/core/styles/withStyles').CSSProperties}} param0
 * @returns {JSX.Element}
 */
const ButtonRounded = ({
  onClick,
  isDisabled,
  isBig,
  variant = 'default',
  color,
  backgroundColor,
  style,
  className,
  children,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation();
  const variants = {
    default: {
      backgroundColor: '--main-color',
      borderColor: '--main-color',
    },
    yellow: {
      backgroundColor: 'orange',
      color: 'orange',
    },
    grey: {
      backgroundColor: 'rgb(170, 170, 170)',
      borderColor: 'rgb(170, 170, 170)',
    },
    red: {
      backgroundColor: '#c7404d',
      borderColor: '#c7404d',
    },
    outline: {
      backgroundColor: 'white',
      borderColor: '--main-color',
    },
  };

  return (
    <button
      {...props}
      disabled={isDisabled || isLoading}
      style={{
        pointerEvents: (isDisabled || isLoading) ? 'none' : undefined,
        color: color,
        backgroundColor: backgroundColor || variants[variant]?.backgroundColor || '--main-color',
        border: `1px solid ${backgroundColor || variants[variant]?.borderColor || '--main-color'}`,
        minWidth: isBig ? '200px' : 'auto',
        height: isBig ? '50px' : '28px',
        fontWeight: isBig ? 700 : 500,
        fontSize: isBig ? '0.85rem' : '0.75rem',
        fontFamily: 'Poppins, sans-serif',
        ...style,
      }}
      onClick={onClick}
      className={`btn_card ${className || ''} ${isDisabled ? 'disabled' : ''}`}
    >
      {isLoading ? (
        <>
          {t('SystemMessage.Loading.Processing')} <Loading type='1' style={{ marginLeft: '2%' }} />
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default ButtonRounded;
