import React, { forwardRef, useEffect, useState } from 'react';
import { ModalWithMethods } from '../../components/modal/Modal';
import Loading from '../../components/Loading';
import { Trans, useTranslation } from 'react-i18next';
import { correctImageOrientation } from '../../utils/utils';
import { deleteManagerSignature, sendManagerSignature } from '../../services/SchoolService';

const UploadSignature = (props, ref) => {
  const { t } = useTranslation();

  const [isSendingImage, setIsSendingImage] = useState(false);
  const [urlPath, setUrlPath] = useState('');
  const [school, setSchool] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const school = JSON.parse(localStorage.getItem('school'));
    if (!school) ref.current.showErrorMessage(t('SystemMessage.Fail.InternalError'));
    if (school) {
      setSchool(school);
      setUrlPath(school.signaturePath);
      if (school.signaturePath !== null) setIsLoading(true);
    }
  }, []);

  const handleImage = async (imageInitial) => {
    const image = await correctImageOrientation(imageInitial);

    try {
      setIsSendingImage(true);
      const { success, messageKey, urlPath } = await sendManagerSignature({ image, schoolId: school.id });

      if (success) {
        ref.current.showSuccessMessage(t('SystemMessage.File.Success.' + messageKey));
        setUrlPath(urlPath);
        setIsLoading(true);

        const school = JSON.parse(localStorage.getItem('school'));
        school.signatureUrlPath = urlPath;
        setSchool(school);
        localStorage.setItem('school', JSON.stringify(school));
      } else {
        ref.current.showErrorMessage(t('SystemMessage.File.Fail.' + messageKey));
      }
    } catch (error) {
      ref.current.showErrorMessage(t('SystemMessage.Fail.InternalError'));
    } finally {
      setIsSendingImage(false);
    }
  };

  const handleDeleteImage = async () => {
    try {
      setIsSendingImage(true);
      const { success, messageKey } = await deleteManagerSignature(school.id);
      if (success) {
        ref.current.showSuccessMessage(t('SystemMessage.File.Success.' + messageKey));
        setUrlPath(null);

        const school = JSON.parse(localStorage.getItem('school'));
        school.signatureUrlPath = '';
        setSchool(school);
        localStorage.setItem('school', JSON.stringify(school));
      } else {
        ref.current.showErrorMessage(t('SystemMessage.File.Fail.' + messageKey));
      }
    } catch (error) {
      ref.current.showErrorMessage(t('SystemMessage.Fail.InternalError'));
    } finally {
      setIsSendingImage(false);
    }
  };

  return (
    <ModalWithMethods {...props} ref={ref} title={t('ModalSignatureUpload.Title')} size={'sm'}>
      <div className='form_modal'>
        {isSendingImage && (
          <div
            style={{
              width: '100%',
              height: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#218135',
              borderRadius: '10px',
              color: 'white',
              flexDirection: 'column',
              marginBottom: '15px',
            }}
          >
            <label>{t('SystemMessage.Loading.WaitSending')}</label>
            <Loading type='3' style={{ color: 'white', marginTop: 5 }} />
          </div>
        )}
        {isLoading && (
          <div
            style={{
              width: '100%',
              height: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#218135',
              borderRadius: '10px',
              color: 'white',
              flexDirection: 'column',
              marginBottom: '15px',
            }}
          >
            <label>{t('SystemMessage.Loading.Loading')}</label>
            <Loading type='3' style={{ color: 'white', marginTop: 5 }} />
          </div>
        )}
        {urlPath !== null && !isSendingImage && (
          <>
            <div
              style={{
                width: '100%',
                display: isLoading ? 'none' : 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img
                style={{
                  borderRadius: 10,
                  marginBottom: 10,
                  width: '100%',
                }}
                src={urlPath}
                onLoad={() => setIsLoading(false)}
                onError={() => setIsLoading(false)}
              />
              <div></div>
            </div>
            <div className='row w-100'>
              <div className='col text-center'>
                <label
                  style={{
                    cursor: 'pointer',
                    padding: '12px 22px',
                    borderRadius: '50px',
                    border: '1px solid grey',
                    color: 'grey',
                    fontSize: '13px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input type='file' name='file[]' onChange={(e) => handleImage(e.target.files[0])} />
                  {t('ChangePhoto')}
                </label>
              </div>
              <div className='col text-center'>
                <button
                  className='btn btn-outline-secondary ml-2'
                  onClick={handleDeleteImage}
                  style={{
                    padding: '12px 22px',
                    borderRadius: '50px',
                    border: '1px solid grey',
                    color: 'grey',
                    fontSize: '13px',
                    fontFamily: 'Poppins, sans-serif',
                  }}
                >
                  {t('DeletePhoto')}
                </button>
              </div>
            </div>
          </>
        )}
        {urlPath == null && !isSendingImage && (
          <div className='upload_file' style={{ width: '100%' }}>
            <label htmlFor='file'></label>
            <input
              type='file'
              name='file[]'
              id='file'
              onChange={(e) => handleImage(e.target.files[0])}
              style={{
                display: 'initial',
                opacity: 0,
                cursor: 'pointer',

                position: 'relative',
                top: '-52px',
                width: '100%',
                height: '100%',
              }}
            />
          </div>
        )}
        <div style={{ backgroundColor: '#8080802e', borderRadius: '10px', padding: '8px', textAlign: 'justify' }}>
          {/* Obs.: Essa é a assinatura do gestor da escola. Ela será apresentada nos contratos dos alunos e nos recibos de pagamento. */}
          {t('ModalSignatureUpload.Observation')}
          <br />
          <br />
          <Trans i18nKey='ModalSignatureUpload.Attention'>
            <b className='text-danger'>a</b>b
          </Trans>
        </div>
      </div>
    </ModalWithMethods>
  );
};

export default forwardRef(UploadSignature);
