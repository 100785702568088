import React from 'react';
import Header from '../../components/admin/Header';
import Footer from '../../components/admin/Footer';
import axios from 'axios';
import { baseURL } from '../../utils/api';

const getListLogs = async () => {
  const response = await axios.get(`${baseURL}admin-list-logs`);
  return response.data;
};

const LogsView = () => {
  const [logsFiles, setLogsFiles] = React.useState([]);

  const [logSelected, setSelected] = React.useState('');

  const [logContent, setLogContent] = React.useState('');

  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  const handleOnChange = (e) => {
    e.preventDefault();
    setSelected(e.target.value);
    setLogContent('');
  };

  React.useEffect(() => {
    getListLogs().then(async (files) => {
      setLogsFiles(files);
    });
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <Header />
            <main className='content_panel'>
              <div className='row w-100'>
                <div className='col-md-4 col-sm-12'>
                  <div className='card d-flex justify-content-center'>
                    <div className='card_header'>
                      <h2 className='card_title'>Ver Logs</h2>
                    </div>
                    <div className='row w-100'>
                      <div className='col'>
                        <select
                          name='logs'
                          value={logSelected}
                          onChange={handleOnChange}
                          className='form-control form_control select'
                        >
                          <option value=''>Selecione o Arquivo de Log</option>
                          {logsFiles.map((file) => (
                            <option key={file} value={file}>
                              {file}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-8 col-sm-12'>
                  <div className='content'>
                    <div className='card px-1 py-2 text-center'>
                      <div className='card_header p-3'>
                        {logSelected && (
                          <a href={`${baseURL}admin-show-log?file=${logSelected}`} download>
                            Download Arquivo
                          </a>
                        )}
                      </div>

                      <FileContent
                        setContent={setLogContent}
                        content={logContent}
                        sh={screenHeight}
                        filePath={logSelected}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};
const FileContent = ({ filePath, setContent, content, sh }) => {
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${baseURL}admin-show-log?file=${filePath}`);
        const reader = response.body.getReader();
        let _content = '';
        let receivedLength = 0;
        const totalLength = +response.headers.get('Content-Length');

        if (totalLength / (1024 * 1024) > 64) {
          setContent('Arquivo muito grande para ser exibido no navegador, faça o download para visualizar.');
          return;
        }

        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            break;
          }

          _content += new TextDecoder().decode(value);
          receivedLength += value.length;
          setContent(_content);
          setProgress((receivedLength / totalLength) * 100);
        }
      } catch (error) {
        console.error('Error fetching log:', error);
      } finally {
        setProgress(0);
      }
    };

    if (filePath) fetchData();
  }, [filePath]);

  const preStyle = {
    height: `${sh * 0.6}px`,
    overflow: 'auto',
    backgroundColor: 'black',
    color: 'white',
    fontSize: 12,
    padding: 8,
  };
  if (loading === false)
    return (
      <div className='d-flex w-100 pb-3 justify-content-center'>
        <h5>Selecione um arquivo de log</h5>
      </div>
    );
  return (
    <>
      <div className='d-flex w-100 pb-3 justify-content-center'>{progress > 0 && <p>{Math.round(progress)}%</p>}</div>
      <pre style={preStyle}>{content}</pre>
    </>
  );
};

export default LogsView;
