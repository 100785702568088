import React, { useEffect, useState, useRef } from 'react';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Utils from '../../utils/utils';
import {
  getSuppliersSchool,
  saveSuppliersSchool,
  updateSuppliersSchool,
} from '../../services/SupplierService';
import {
  getStates,
  getCitysByStateSigla,
  searchByZipCodeCountry,
  verifyCpfSchool,
} from '../../services/SharedService';
import { useTranslation } from 'react-i18next';
import CpfCnpjMask from '../../components/inputs/CpfCnpjMask';
import PhoneMask from '../../components/inputs/PhoneMask';
import CEPMask from '../../components/inputs/CEPMask';
import { validatePhone } from '../../utils/validators';

const Supplier = () => {
  const { t, i18n } = useTranslation();

  const type = 'F';
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [cpf_cnpj, setCpf_cnpj] = useState('');
  const [email, setEmail] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [streetName, setStreetName] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [stateUF, setStateUF] = useState('');
  const [states, setStates] = useState([]);
  const [citys, setCitys] = useState([]);
  const [city_id, setCityID] = useState('');
  const [id, setID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [school_id, setSchoolID] = useState('');
  const [companie_id, setCompanieID] = useState('');

  const [urlParams, setUrlParams] = useState(new URLSearchParams(window.location.search));
  const [formErrors, setFormErrors] = useState({
    cpf_cnpj: false,
    name: false,
    phone: false,
    email: false,
  });

  const [filterName, setFilterName] = useState('');
  const [suppliers, setSuppliers] = useState([]);
  const [suppliersFilter, setSuppliersFilter] = useState([]);

  const [display, setDisplay] = useState({});
  const [viewDisplay, setViewDisplay] = useState({});
  const [process, setProcess] = useState(false);

  const [selectedSupplier, setSelectedSupplier] = useState({});

  const [sendCity, setSendingCity] = useState(false);
  const [sendCep, setSendingCep] = useState(false);
  const [sendSave, setSendSave] = useState(false);
  const [sendCPF, setSendCPF] = useState(false);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  // libraries
  const { scrollTop, formatCNPJCPF } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  let modalScroll = useRef();

  useEffect(() => {
    async function getSession() {
      const schoolSession = JSON.parse(await localStorage.getItem('school'));
      const usuSession = JSON.parse(await localStorage.getItem('user'));

      setSchoolID(schoolSession.id);
      setCompanieID(schoolSession.companie_id);
      getSuppliers(schoolSession.id);
      setUserID(usuSession.id);
    }

    async function getSuppliers(school) {
      setProcess(true);

      const res = await getSuppliersSchool(school);

      setSuppliers(res.suppliers);
      setSuppliersFilter(res.suppliers);
      setProcess(false);
    }

    getSession();

    window.addEventListener('keydown', closeModalOnEscape);

    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  useEffect(() => {
    fetchStates();
  }, [i18n.language]);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') closeAdd();
  };

  const fetchStates = async () => {
    const res = await getStates(i18n.language);
    setStates(res.states);
  };

  const getCitys = async (stateUF, localidade = '') => {
    setCitys([]);
    setStateUF(stateUF !== null ? stateUF : '');
    setSendingCity(true);
    const res = await getCitysByStateSigla(stateUF, i18n.language);
    setSendingCity(false);

    for (let i = 0; i < res.cidades.length; i++) {
      let citCity = res.cidades[i].citCity;
      if (citCity === localidade) {
        setCityID(res.cidades[i].id);
      }
    }

    setCitys(res.cidades);
  };

  const buscaCEP = async () => {
    if (zipCode.length) {
      setSendingCep(true);
      const { success, streetName, neighborhood, states, state, cities, city } = await searchByZipCodeCountry(
        zipCode,
        i18n.language
      );
      setSendingCep(false);

      if (!success) {
        setStreetName('');
        setNeighborhood('');
        setStateUF('');
        setCityID('');
      } else {
        setStreetName(streetName);
        setNeighborhood(neighborhood);
        setStates(states);
        setStateUF(state);

        setCitys(cities);
        setCityID(city.id);
      }
    }
  };

  const search = (e) => {
    e.preventDefault();

    let registers = [];
    if (filterName !== '') {
      registers = suppliers.filter((item) => {
        return item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1;
      });
    } else {
      registers = suppliers;
    }

    setSuppliersFilter(registers);
  };

  useEffect(() => {
    const add = urlParams.get('add');
    if (add === 'true') {
      openAdd();
    }
  }, [urlParams]);

  const openAdd = () => {
    clearMessage();
    setName('');
    setCpf_cnpj('');
    setPhone('');
    setEmail('');
    setZipCode('');
    setStreetName('');
    setNeighborhood('');
    setStreetNumber('');
    setComplement('');
    setStateUF('');
    setCitys([]);
    setCityID('');
    setID(null);

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const closeAdd = () => {
    setDisplay({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();

    if (cpf_cnpj.length < 11 || cpf_cnpj.length > 14 || (cpf_cnpj.length > 11 && cpf_cnpj.length < 14)) {
      setErrorMessage('StudentPage.SystemMessage.InvalidFields');
      setFormErrors({ ...formErrors, cpf_cnpj: true });

      setSendSave(false);
      return;
    }

    setSendSave(true);

    const payload = {
      school_id,
      name,
      cpf_cnpj,
      phone,
      email,
      type,
      zipCode,
      streetName,
      streetNumber,
      complement,
      neighborhood,
      city_id,
    }
    const res =
      id === null
        ? await saveSuppliersSchool({...payload, user_id: userID})
        : await updateSuppliersSchool({...payload, id });
    setSendSave(false);

    if (res.error || formErrors.cpf_cnpj) {
      const errorTypes = {
        usuCPF: "CPFAlreadyExists", 
        email: "EmailAlreadyExists", 
        usuCPF_email: "CPFAndEmailAlreadyExists", 
        email_usuCPF: "CPFAndEmailAlreadyExists"
      }
      const errorsKeys = res.error?.response?.data?.errors ? Object.keys(res.error?.response?.data?.errors).join('_') : ''
      const errorTypeKeys = Object.keys(errorTypes)
      const translateError = errorTypeKeys.includes(errorsKeys) ? errorTypes[errorsKeys] : 'InternalError'
      setErrorMessage('SystemMessage.Fail.' + translateError);
    } else {
      setSuccessMessage(res.create.messageKey);
      setID(res.create.id);
      const suppliers = res.studentsClientsFornecedors.filter((supplier) => supplier.type === type)
      setSuppliers(suppliers);
      setSuppliersFilter(suppliers);
    }

    scrollTop(modalScroll);
  };

  const edit = (e, supplier) => {
    clearMessage();
    console.log('[teste]',supplier)
    setID(supplier.id);
    setName(supplier.name);
    setCpf_cnpj(supplier.cpf_cnpj);
    setPhone(supplier.phone);
    setEmail(supplier.email);
    setZipCode(supplier.zipCode ? supplier.zipCode : '');
    setStreetNumber(supplier.streetNumber ? supplier.streetNumber : null);
    setComplement(supplier.complement ? supplier.complement : null);
    setStreetName(supplier.streetName ? supplier.streetName : null);
    setNeighborhood(supplier.neighborhood ? supplier.neighborhood : null);
    setStateUF(supplier.city?.state?.staStateSigla ? supplier.city?.state?.staStateSigla : '');
    getCitys(supplier.city?.state?.staStateSigla, supplier.city?.citCity);
    setCityID(supplier.city_id !== null ? supplier.city_id : '');
    setSchoolID(supplier.school_id);
    setCompanieID(supplier.companie_id);

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const setErrorMessage = (message) => {
    setMessage(message);
    setError(true);
    scrollTop(modalScroll);
  };

  const setSuccessMessage = (message) => {
    setMessage(message);
    setSuccess(true);
    scrollTop(modalScroll);
  };

  const clearMessage = () => {
    setSuccess(false);
    setError(false);
    setMessage('');
  };

  const handleValidate = async (field) => {
    switch (field) {
      case 'name':
        if (name.length > 90) {
          setErrorMessage('StudentPage.SystemMessage.InvalidFields');
          setFormErrors({ ...formErrors, name: true });
        } else if (name.length <= 1) {
          setErrorMessage('StudentPage.SystemMessage.InvalidFields');
          setFormErrors({ ...formErrors, name: true });
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, name: false });
        }
        break;
      case 'email':
        if (!email) {
          setErrorMessage('Por favor, insira um endereço de e-mail.');
          setFormErrors({ ...formErrors, email: true });
        }
        break;
      case 'phone':
        if (!phone) {
          setErrorMessage('Por favor, insira um número de celular.');
          setFormErrors({ ...formErrors, phone: true });
        } else {
          const isValidPhone = validatePhone(phone, i18n.language);

          if (isValidPhone) {
            clearMessage();
            setFormErrors({ ...formErrors, phone: false });
          } else {
            setErrorMessage('StudentPage.SystemMessage.InvalidFields');
            setFormErrors({ ...formErrors, phone: true });
          }
        }
        break;
      default:
        break;
    }
  };

  const openView = (user) => {
    setSelectedSupplier(user)
    setViewDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const closeView = () => {
    setViewDisplay({});
    setSelectedSupplier({})
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card' style={{ flexDirection: 'column' }}>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('SupplierPage.AddSupplierCard.Title')}</h2>
                    <p className='card_subtitle'>{t('SupplierPage.AddSupplierCard.Subtitle')}</p>
                  </div>
                  <button
                    onClick={openAdd}
                    className='btn_card mx-auto mt-4 btn_open_modal'
                    data-modal='#add_funcionario'
                  >
                    <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                    </svg>
                    {t('SupplierPage.AddSupplierCard.Button')}
                  </button>
                </div>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('SupplierPage.FilterSupplierCard.Title')}</h2>
                    <p className='card_subtitle'>{t('SupplierPage.FilterSupplierCard.Subtitle')}</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('Name')}
                      </label>
                      <input type='text' className='form_control' onChange={(e) => setFilterName(e.target.value)} />
                    </div>
                    <button className='btn_card mx-auto' onClick={search}>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('Search')}
                    </button>
                  </form>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('SupplierPage.PageTitle')}</h2>
                    <p className='card_subtitle'>{t('SupplierPage.PageSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    {process && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!process &&
                      suppliersFilter.map((item, i) => {
                        return (
                          <div className='card_list' key={`suppliers_${i}`}>
                            <div className='content_card'>
                              <div className='card_details'>
                                <p className='card_details_title' title={item.name}>
                                  {item.name.length > 20 ? item.name.substr(0, 20) + '...' : item.name}
                                </p>
                              </div>
                            </div>
                            <span
                              style={{ marginBottom: 10, fontWeight: 500 }}
                              onClick={(_) => openView(item)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('SeeSupplier')}
                            </span>
                            <span
                              style={{ backgroundColor: 'orange', fontWeight: 500, border: '1px solid orange' }}
                              onClick={(e) => edit(e, item)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('EditSupplier')}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className='modal' id='add_supplier' style={display}>
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeAdd}>
                    X
                  </div>
                  <h3 className='modal_title'>
                    {id === null && t('SupplierPage.Modal.TitleAdd')}
                    {id !== null && t('SupplierPage.Modal.TitleEdit')}
                  </h3>
                  {error && (
                    <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                  )}
                  {success && (
                    <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message)} />
                  )}
                  <form action='' onSubmit={handleSubmit} method='post' className='form_modal'>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('Name')} *
                      </label>
                      <input
                        type='text'
                        required
                        className='form_control'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          {t('CPFOrCNPJ')}
                        </label>
                        <CpfCnpjMask
                          value={cpf_cnpj}
                          onChange={setCpf_cnpj}
                          name='cpfCnpj'
                          style={{ borderColor: formErrors.cpf_cnpj && '#c00' }}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='tel' className='card_details_label'>
                          {t('CellPhone')} *
                        </label>
                        <PhoneMask
                          required
                          value={phone}
                          onChange={setPhone}
                          style={{ borderColor: formErrors.phone && '#c00' }}
                          onBlur={() => handleValidate('phone')}
                        />
                      </div>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='email' className='card_details_label flex-row'>
                        {t('Email')}
                      </label>
                      <input
                        type='email'
                        required
                        className='form_control'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ borderColor: formErrors.email && '#c00' }}
                        onBlur={() => handleValidate('email')}
                      />
                    </div>
                    <div className='group_50'>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label flex-row'>
                        {t('CEP')}
                        {sendCep && <Loading type='1' style={{ marginLeft: '2%' }} />}
                      </label>
                      <CEPMask value={zipCode} onChange={setZipCode} onBlur={buscaCEP} />
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            {t('State')}
                          </label>
                          <select
                            className='form_control select'
                            value={stateUF}
                            onChange={(e) => getCitys(e.target.value, '')}
                          >
                            <option value=''>-- {t('Select')} --</option>
                            {states.map((item) => (
                              <option value={item.staStateSigla} key={item.staState}>
                                {item.staState}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label flex-row'>
                            {t('City')}
                            {sendCity && <Loading type='1' style={{ marginLeft: '2%' }} />}
                          </label>
                          <select
                            className='form_control select'
                            value={city_id}
                            onChange={(e) => setCityID(e.target.value)}
                          >
                            <option value=''>-- {t('Select')} --</option>
                            {citys.map((item) => (
                              <option value={item.id} key={item.citCity}>
                                {item.citCity}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          {t('Address')}
                        </label>
                        <input
                          type='text'
                          value={streetName}
                          className='form_control'
                          onChange={(e) => setStreetName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='streetNumber' className='card_details_label'>
                            {t('Number')}
                          </label>
                          <input
                            type='text'
                            value={streetNumber}
                            className='form_control'
                            name='streetNumber'
                            onChange={(e) => setStreetNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='complement' className='card_details_label'>
                            {t('Complement')}
                          </label>
                          <input
                            type='text'
                            value={complement}
                            className='form_control'
                            name='complement'
                            onChange={(e) => setComplement(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='tel' className='card_details_label'>
                          {t('Neighborhood')}
                        </label>
                        <input
                          type='text'
                          value={neighborhood}
                          className='form_control'
                          onChange={(e) => setNeighborhood(e.target.value)}
                        />
                      </div>
                    </div>
                    {!sendSave && (
                      <button className='btn_card mx-auto'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {id === null && t('SupplierPage.Modal.ButtonAdd')}
                        {id !== null && t('SupplierPage.Modal.ButtonEdit')}
                      </button>
                    )}
                    {sendSave && (
                      <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none' }} disabled>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('SystemMessage.Loading.Processing')}
                        <Loading type='1' style={{ marginLeft: '2%' }} />
                      </button>
                    )}
                  </form>
                </div>
              </div>
              <div className='modal' id='view_supplier' style={viewDisplay}>
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeView}>
                    X
                  </div>
                  <h3 className='modal_title'>
                    {t('SupplierPage.Modal.ViewSupplier')}
                  </h3>
                  <div className="content_card supplier_content">
                    <div className="card_details">
                      <p className="card_details_label">{t('Name')}: </p>
                      <p className="card_details_text">
                        {selectedSupplier.name}
                      </p>
                    </div>
                    <div className="card_details">
                      <p className="card_details_label">{t('CPFOrCNPJ')}: </p>
                      <p className="card_details_text">
                        {selectedSupplier.cpf_cnpj && formatCNPJCPF(selectedSupplier.cpf_cnpj)}
                      </p>
                    </div>
                    <div className="card_details">
                      <p className="card_details_label">{t('CellPhone')}: </p>
                      <p className="card_details_text">
                        {selectedSupplier.phone}
                      </p>
                    </div>
                    <div className="card_details">
                      <p className="card_details_label">{t('Email')}: </p>
                      <p className="card_details_text">
                        {selectedSupplier.email}
                      </p>
                    </div>
                    <div className="card_details">
                      <p className="card_details_label">{t('City')}: </p>
                      <p className="card_details_text">
                        {selectedSupplier.city?.citCity}
                      </p>
                    </div>
                    <div className="card_details">
                      <p className="card_details_label">{t('State')}: </p>
                      <p className="card_details_text">
                        {selectedSupplier.city?.state?.staState}
                      </p>
                    </div>
                    <div className="card_details">
                      <p className="card_details_label">CEP: </p>
                      <p className="card_details_text">
                        {selectedSupplier.zipCode}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Supplier;
