import React, { useState, forwardRef, useEffect } from 'react';
import { DaysOfWeek, handleOnChange } from '../../../../src/utils/utils';
import { useTranslation } from 'react-i18next';
import { ModalWithMethods } from '../../../components/modal/Modal';
import { FaRegCalendarAlt, FaSearch } from 'react-icons/fa';
import ButtonRounded from '../../../components/buttons/ButtonRounded';
import SelectRounded from '../../../components/inputs/SelectRounded';
import moment from 'moment';
import Loading from '../../../components/Loading';
import InputRounded from '../../../components/inputs/InputRounded';

Array.prototype.last = function () {
  return this[this.length - 1];
};
Array.prototype.unique = function () {
  return [...new Set(this)];
};

const RegisterStudentAttendance = (
  { studentClass, students, weekDaySelected, isLoading, isSending, onSearch, onSubmit, ...props },
  ref
) => {
  const { t } = useTranslation();

  const [studentClassId, setStudentClassId] = useState(null);
  const [studentsFiltered, setStudentsFiltered] = useState([]);
  const [dates, setDates] = useState([]);

  const [formData, setFormData] = useState({ date: new Date() });
  const onChangeForm = handleOnChange(setFormData);

  const [filters, setFilters] = useState({ name: '' });
  const onChangeFilters = handleOnChange(setFilters);

  const [registeredAttendances, setRegisteredAttendances] = useState([]);

  useEffect(() => {
    if (studentClass?.id && studentClass.hour && (weekDaySelected || weekDaySelected === 0)) {
      const dates = getDates(studentClass.id !== studentClassId);
      setDates(dates);

      setStudentClassId(studentClass.id);
    }
  }, [studentClass]);

  useEffect(() => {
    setRegisteredAttendances(
      students.map(({ id, frequency }) => ({ studentId: id, confirmed: frequency?.confirmed === 'S' }))
    );
  }, [students]);

  useEffect(() => {
    setStudentsFiltered(
      students.filter((student) =>
        filters.name === '' ? true : student.name.toLowerCase().includes(filters.name.toLowerCase())
      )
    );
  }, [students, filters.name]);

  const handleSubmit = async () => {
    if (!studentClass) {
      showErrorMessage('ClassStudentAttendancePage.RegisterStudentAttendance.NoClassSelected');
    } else if (registeredAttendances.length === 0 || dates.length === 0) {
      showErrorMessage('ClassStudentAttendancePage.RegisterStudentAttendance.NoDateSelected');
    } else {
      ref.current.clearMessage();

      const { status, messageKey } = await onSubmit({
        class_day_id: studentClass.classDayId,
        frequencies: registeredAttendances,
        date: formData.dateConfirmed,
      });

      if (status === 'SUCCESS') {
        showSuccessMessage(messageKey);
      } else {
        showErrorMessage(messageKey);
      }
    }
  };

  const showErrorMessage = (message) => {
    ref.current.showErrorMessage(message);
    ref.current.scrollTop();
  };

  const showSuccessMessage = (message) => {
    ref.current.showSuccessMessage(message);
    ref.current.scrollTop();
  };

  //Task https://www.meistertask.com/app/task/8hlNZ7Uy/erro-em-realizar-a-frequencia
  //adiciona a proxima data ao select de datas da frequencia.
  const nextDate = (createdAt) => {
    const dayOfWeek = t(DaysOfWeek[weekDaySelected].nameKey);

    return createdAt.day(dayOfWeek).format('YYYY-MM-DD');
  };

  const getDates = (mustSetDate) => {
    const createdAt = moment(studentClass.created_at);

    if (createdAt.isoWeekday() > weekDaySelected) createdAt.add(1, 'week');

    const now = moment().startOf('day');
    const date = createdAt.isoWeekday(weekDaySelected).startOf('day');
    const dates = [];

    while (date <= now) {
      dates.push(date.format('YYYY-MM-DD'));
      date.add(1, 'week');
    }
    const toNextDate = dates.length > 0 ? moment(dates.last()).add(7,'day') : createdAt;

    dates.push(nextDate(toNextDate));

    const dateAux = dates.unique().reverse();

    if (mustSetDate) {
      if(dateAux[1] === moment().format('YYYY-MM-DD')){
        onChangeForm('date', dateAux[1] || moment().format('YYYY-MM-DD'));
      }else{
        onChangeForm('date', dateAux[0] || moment().format('YYYY-MM-DD'));
      }
      
    }

    return dateAux;
  };

  const handleOnSearch = async (date) => {
    setRegisteredAttendances([]);
    ref.current.clearMessage();
    onChangeForm('dateConfirmed', date);

    onSearch({ date, hour: studentClass.hour, classId: studentClass.id });
  };

  const changeAttendance = (studentId, checked) => {
    setRegisteredAttendances((current) => {
      const found = current.find((c) => c.studentId === studentId);
      found.confirmed = checked;
      return [...current];
    });
  };

  const markAll = () => {
    setRegisteredAttendances((current) => current.map((c) => ({ ...c, confirmed: true })));
  };

  return (
    <ModalWithMethods
      {...props}
      ref={ref}
      title={t('CallTheRoll')}
      size={'md'}
      modalContentClassName='pb-2'
      onShow={() => ref.current.clearMessage()}
    >
      {studentClass.id && (
        <div>
          <p
            key={`RegisterStudentAttendance_info_${studentClass.classId}`}
            className='card_subtitle d-flex d-md-block flex-column mt-2'
          >
            {[
              { key: t('Classes'), value: studentClass.name },
              { key: t('Schedule'), value: studentClass.hour },
              { key: t('Day'), value: t(DaysOfWeek[weekDaySelected].nameKey) },
              { key: t('Responsible'), value: studentClass.responsaveis },
            ].map(({ key, value }, i) => (
              <span key={`studentClass_list_infos_${studentClass.classId}_${studentClass.classDayId}_${key}`}>
                {i !== 0 && <span className='mx-1 d-none d-md-inline'>|</span>}
                <b>{key}:</b> {value}
              </span>
            ))}
          </p>
        </div>
      )}

      <div className='row mx-0 w-100 mt-2'>
        <div className='col-6 pl-0 pr-1'>
          <SelectRounded
            iconPrefix={<FaRegCalendarAlt />}
            classNameWrapper='border-color-default'
            className='text-left select-height'
            styleWrapper={{ borderRadius: '8px' }}
            style={{ '--select-height': '37px' }}
            name='date'
            value={formData}
            onChange={onChangeForm}
          >
            <option disabled>{t('SelectDate')}</option>
            {dates.length === 0 && <option disabled>Volte aqui na proxima Semana! </option>}
            {dates.map((date) => (
              <option value={date} key={`date_${date}`}>
                {moment(date).format('DD/MM/YYYY')}
              </option>
            ))}
          </SelectRounded>
        </div>
        <div className='col-6 pr-0 pl-1'>
          <ButtonRounded
            className='w-100 py-3'
            style={{ borderRadius: '8px', height: '37px' }}
            isLoading={isLoading}
            onClick={() => handleOnSearch(formData.date)}
          >
            {t('FetchStudents')}
          </ButtonRounded>
        </div>
      </div>

      <div className='row w-100 m-0 mb-2'>
        <InputRounded
          iconPrefix={<FaSearch />}
          classNameContainer='mb-0'
          classNameWrapper='border-color-default'
          className='text-left py-1'
          styleWrapper={{ borderRadius: '8px' }}
          name='name'
          type='text'
          placeholder={t('SearchByStudentName')}
          value={filters}
          onChange={onChangeFilters}
        />
      </div>

      <div className='row mx-0 w-100 my-2'>
        <div className='row w-100 m-0'>
          <div
            className='w-100 d-flex border-color-default align-items-center text-center text-bold'
            style={{ borderLeft: '1px solid', borderBottom: '1px solid' }}
          >
            <div
              className='text-center border-color-default overflow-hidden'
              style={{ width: '90px', borderRight: '1px solid' }}
            >
              {t('Present')}?
            </div>
            <div
              className='d-flex justify-content-between h-100 pl-2 text-left border-color-default overflow-hidden'
              style={{ borderRight: '1px solid', flex: 1 }}
            >
              {t('Student')}
              <ButtonRounded
                className='d-inline-block px-1 mr-1 mb-1'
                style={{ borderRadius: '8px' }}
                isDisabled={students.length === 0 || isLoading || isSending}
                onClick={markAll}
              >
                {t('MarkAll')}
              </ButtonRounded>
            </div>
          </div>
        </div>

        {isLoading && (
          <p className='loading-card sidebar_data' style={{ height: '45px' }}>
            <Loading type='1' />
          </p>
        )}

        {!isLoading &&
          studentsFiltered.map((studentAux) => (
            <div key={`Frequency_Student_${studentAux.id}`} className='row w-100 m-0'>
              <div
                className='w-100 d-flex border-color-default align-items-center text-center'
                style={{ borderLeft: '1px solid', borderBottom: '1px solid' }}
              >
                <div
                  className='text-center border-color-default overflow-hidden'
                  style={{ width: '90px', borderRight: '1px solid' }}
                >
                  <label className='w-100 h-100 m-0 py-1'>
                    <input
                      type='checkbox'
                      checked={registeredAttendances.some(
                        ({ studentId, confirmed }) => studentId === studentAux.id && confirmed
                      )}
                      onChange={(e) => changeAttendance(studentAux.id, e.target.checked)}
                    />
                  </label>
                </div>
                <div
                  className='h-100 pl-2 text-left border-color-default overflow-hidden'
                  style={{ borderRight: '1px solid', flex: 1 }}
                >
                  {studentAux.name}
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className='row mx-0 w-100 mt-auto mb-2'>
        <div className='col-6 pl-0 pr-1'>
          <ButtonRounded
            variant='grey'
            color='#ffffff'
            type='submit'
            className='w-100 py-3'
            style={{ borderRadius: '8px', height: '37px' }}
            isDisabled={isSending}
            isLoading={isLoading}
            onClick={() => ref.current.closeModal()}
          >
            {t('Cancel')}
          </ButtonRounded>
        </div>

        <div className='col-6 pr-0 pl-1'>
          <ButtonRounded
            className='w-100 py-3'
            style={{ borderRadius: '8px', height: '37px' }}
            isDisabled={isLoading}
            isLoading={isSending}
            onClick={() => handleSubmit()}
          >
            {t('Confirm')}
          </ButtonRounded>
        </div>
      </div>
    </ModalWithMethods>
  );
};

export default forwardRef(RegisterStudentAttendance);
