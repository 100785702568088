import axios from 'axios';
import Api from '../utils/api';
import { defaultApiObject, handleFail } from '../utils/utils-api';
import api from './api';

const baseURL = Api();

export const getThemeBySubdomain = async (subdomain) =>
  new Promise((response, reject) => {
    const requestBody = {
      subdomain: subdomain,
    };
    axios
      .post(baseURL + 'get-subdomain-theme', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const getAllClubs = async () => {
  try {
    const { data } = await axios.get(baseURL + 'admin-all-clubs');
    return data;
  } catch (error) {
    return error;
  }
};

export const createClub = async (club) => {
  const formData = new FormData();

  for (const key in club) {
    formData.append(key, club[key] || '');
  }

  const requestOptions = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const { data } = await axios.post(baseURL + 'admin-create-club', formData, requestOptions);
    return data;
  } catch (error) {
    return { responseError: error };
  }
};

export const updateClub = async (club) => {
  const formData = new FormData();

  for (const key in club) {
    formData.append(key, club[key] || '');
  }

  const requestOptions = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const { data } = await axios.post(baseURL + 'admin-update-club', formData, requestOptions);
    return data;
  } catch (error) {
    return { responseError: error };
  }
};

export const verifyEmail = async (email) => {
  try {
    const { data } = await axios.post(baseURL + 'verify-email-club', { email });
    return data;
  } catch (error) {
    return error;
  }
};

export const getAPIToken = async () => {
  try {
    const { data } = await api.get('company/api');

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const regenerateAPIToken = async () => {
  try {
    const { data } = await api.put('company/api');

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};
