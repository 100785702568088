import React, { useEffect, useState, useRef, useContext } from 'react';
import Header from '../../../components/school/Header';
import Footer from '../../../components/school/Footer';
import { Link, useHistory } from 'react-router-dom';
import { getClassSchool, saveClassSchool, updateClassSchool } from '../../../services/ClassService';
import { useTranslation } from 'react-i18next';
import { FaExternalLinkAlt, FaRegCopy, FaWhatsapp } from 'react-icons/fa';
import SweetAlert from 'react-bootstrap-sweetalert';
import WhatsAppComponent from '../../../components/whatsapp/WhatsAppComponent';
import PhoneMask from '../../../components/inputs/PhoneMask';
import { sendWhatsAppMessage } from '../../../services/WhatsAppService';
import { UserContext } from '../../../Context/UserContext';
import { SchoolRoleUtils } from '../../../utils/role-utils';
import EditStudentClass from './EditStudentClass';
import { range } from '../../../utils/utils';
import List from '../../../components/tables/List';
import Calendar from 'react-calendar';
import {
  createLessonPlanning,
  getAllLessonsPlanning,
  updateLessonPlanning,
} from '../../../services/LessonPlanningService';
import EditLessonPlanning from './EditLessonPlanning';
import moment from 'moment';

const Class = () => {
  const { user, school, company } = useContext(UserContext);
  const { t, i18n } = useTranslation();

  const history = useHistory();

  const [studentClass, setStudentClass] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  // carregamento
  const [dias, setDias] = useState([]);
  const [responsaveis, setResponsaveis] = useState([]);
  const [students, setStudents] = useState([]);
  const [classs, setClasss] = useState([]);
  const [classFilter, setClassFilter] = useState([]);
  const [lessonsPlanning, setLessonsPlanning] = useState([]);
  const [lessonPlanning, setLessonPlanning] = useState(null);
  const [daysWithLessons, setDaysWithLessons] = useState([]);

  // WhatsApp
  const [classToLink, setClassToLink] = useState(null);
  const [contactWhatsApp, setContactWhatsApp] = useState({ name: '', phone: '' });

  // filtros
  const [filterName, setFilterName] = useState('');
  const [filterDia, setFilterDia] = useState('');
  const [filterHorario, setFilterHorario] = useState('');
  const [filterResponsavel, setFilterResponsavel] = useState(SchoolRoleUtils.isTeacher(user.role_id) ? user.name : '');
  const [filterStatus, setFilterStatus] = useState('A');

  // process
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLessonsPlanning, setIsLoadingLessonsPlanning] = useState(false);
  const [isShowSweetAlert, setIsShowSweetAlert] = useState(false);

  // libraries
  const whatsAppComponentRef = useRef();
  const editStudentClassRef = useRef();
  const editLessonPlanningRef = useRef();

  const horarios = range(7, 23)
    .map((n) => n.toString().padStart(2, '0'))
    .map((hora) => ['00', '15', '30', '45'].map((minuto) => `${hora}:${minuto}`))
    .reduce((acc, horario) => [...acc, ...horario], [])
    .map((horario) => ({ title: horario }));

  useEffect(() => {
    getClass(school.id);
    fetchLessonsPlanning(moment().format('YYYY-MM-DD'));

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') editStudentClassRef.current.closeModal();
  };

  async function getClass(schoolId) {
    setIsLoading(true);
    const res = await getClassSchool(schoolId, SchoolRoleUtils.isTeacher(user.role_id) ? user.id : undefined);

    setClasss(res.classs);

    const resp = res.classs.filter((item) => filterStatus === '' || item.status === filterStatus);
    setClassFilter(resp);
    setDias(res.days);
    setResponsaveis(res.users);
    setStudents(res.students);
    setIsLoading(false);
  }

  const fetchLessonsPlanning = async (date) => {
    setIsLoadingLessonsPlanning(true);
    const { status, lessonsPlanning, daysWithLessons } = await getAllLessonsPlanning(
      date ? { date: moment(date).format('YYYY-MM-DD') } : undefined
    );
    setIsLoadingLessonsPlanning(false);

    if (status === 'SUCCESS') {
      setLessonsPlanning(lessonsPlanning);
      setDaysWithLessons(daysWithLessons);
    }
  };

  const search = (e) => {
    e.preventDefault();

    let registers = [];
    if (filterName !== '') {
      registers = classs.filter((item) => {
        return item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1;
      });
    } else {
      registers = classs;
    }

    if (filterStatus !== '') {
      registers = registers.filter((item) => {
        return item.status == filterStatus;
      });
    }

    if (filterDia !== '') {
      let newFilterDays = [];
      registers.map((item) => {
        item.days.map((item2) => {
          if (item2.name.toLowerCase() == filterDia.toLowerCase()) {
            newFilterDays.push(item);
          }
        });
      });

      registers = newFilterDays;
    }

    if (filterHorario !== '') {
      let newFilterHours = [];
      registers.map((item) => {
        item.days.map((item2) => {
          if (item2.hour.toLowerCase() == filterHorario.toLowerCase()) {
            newFilterHours.push(item);
          };
        });
      });
      registers = newFilterHours;
    }

    if (filterResponsavel !== '') {
      registers = registers.filter((item) => {
        return item.responsaveis?.some((r) => r.name === filterResponsavel);
      });
    }

    setClassFilter(registers);
  };

  const openAdd = () => {
    setStudentClass(null);
    editStudentClassRef.current.showModal();
  };

  const handleSubmit = async (classData) => {
    const res = classData.id ? await updateClassSchool(classData) : await saveClassSchool(classData);

    setClasss(res.classs);
    setClassFilter(res.classs.filter((item) => filterStatus === '' || item.status === filterStatus));

    return res;
  };

  const handleSubmitLessonPlanning = async (formData) => {
    setIsLoadingLessonsPlanning(true);

    const {
      messageKey,
      status,
      lessonPlanning: lessonPlanningSaved,
      lessonsPlanning,
    } = formData.id
        ? await updateLessonPlanning({ ...formData, selectedDate: moment(selectedDate).format('YYYY-MM-DD') })
        : await createLessonPlanning({ ...formData, selectedDate: moment(selectedDate).format('YYYY-MM-DD') });
    setIsLoadingLessonsPlanning(false);

    if (status === 'SUCCESS') {
      setLessonPlanning(lessonPlanningSaved);
      setLessonsPlanning(lessonsPlanning);

      return {
        messageKey,
        status,
        lessonPlanning: lessonPlanningSaved,
      };
    } else {
      return {
        messageKey,
        status,
        lesson: null,
      };
    }
  };

  const edit = (e, item) => {
    //setStudentClass(item);

    delete item.label;
    history.push('/new-class-school', { payload: item })
    // editStudentClassRef.current.showModal();
  };

  const openEditLessonPlanning = (lessonPlanning) => {
    setLessonPlanning(lessonPlanning);
    editLessonPlanningRef.current.showModal();
  };

  const openShareOptions = (athletesClass) => {
    setClassToLink({ id: athletesClass.id, ages: athletesClass.idadesPermitidas });
    setIsShowSweetAlert(true);
  };

  const openWhatsAppComponent = () => {
    setIsShowSweetAlert(false);
    whatsAppComponentRef.current.showModal();
  };

  const getShareLink = () => {
    const queryParams = new URLSearchParams({ c: classToLink.id, a: classToLink.ages || '', l: i18n.resolvedLanguage });

    return `${window.location.origin}/student-create/${company.id}/${school.id}?${queryParams}`;
  };

  const copyLink = async () => {
    try {
      const copy = getShareLink();

      if (navigator.clipboard != undefined) {
        await navigator.clipboard.writeText(copy);
        alert(t('ClassPage.CopyLinkSuccessButton'));
      } else {
        window.open(copy);
      }
    } catch (e) {
      console.error(t('ClassPage.SystemMessage.ErrorCopyLink'));
      console.error(e.message);
      console.log(window.isSecureContext);
    } finally {
      setIsShowSweetAlert(false);
      setClassToLink(null);
    }
  };

  const sendMessageWhatsApp = async () => {
    const response = await sendWhatsAppMessage({
      schoolId: school.id,
      numbersList: [i18n.resolvedLanguage === 'pt-BR' ? `55${contactWhatsApp.phone}` : contactWhatsApp.phone],
      message: t('WhatsAppComponent.Messages.StudentClassRegistrationLink', {
        name: contactWhatsApp.name,
        link: getShareLink(),
      }),
    });

    if (response.success) {
      return { success: true, message: t('WhatsAppComponent.SendSuccessMessage.LinkSentSuccessfully') };
    } else {
      return {
        success: !response.erro,
        message: (
          <div>
            <b>{t('WhatsAppComponent.SendFailMessage.Fail')}</b>
            <br />
            <b>{contactWhatsApp.name}:</b> {t('WhatsAppComponent.SendFailMessage.IncorrectNumber')}
            <br />
          </div>
        ),
      };
    }
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card flex-column align-items-center'>
                  <div className='card_header align-items-center'>
                    <h2 className='card_title'>{t('ClassPage.AddClassCard.Title')}</h2>
                    <p className='card_subtitle'>{t('ClassPage.AddClassCard.Subtitle')}</p>
                  </div>
                  <div className='card-actions mt-4'>
                    <div className='card-action-row' style={{ justifyContent: 'center' }}>
                      {/* <button onClick={openAdd} className='btn_card btn_open_modal' data-modal='#add_turma'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('ClassPage.AddClassCard.Button')}
                      </button> */}
                      <Link to='/new-class-school' className='btn_card ml-auto mt-4 mt-md-0'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('ClassPage.AddClassCard.Button')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='card' style={{ height: '490px' }}>
                  <div className='card_header'></div>
                  <div className='w-100 d-flex justify-content-center align-items-center'>
                    <span className='card_title' style={{ fontSize: '80px' }}>
                      {selectedDate.getDate()}
                    </span>
                  </div>
                  <Calendar
                    locale={i18n.language}
                    value={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      fetchLessonsPlanning(date);
                    }}
                    style={{ width: '100%', border: 'none' }}
                    tileClassName={({ date, view }) => {
                      const dateFormatted = moment(date).format('YYYY-MM-DD');
                      const selectedDateFormatted = moment(selectedDate).format('YYYY-MM-DD');

                      if (
                        view === 'month' &&
                        dateFormatted !== selectedDateFormatted &&
                        daysWithLessons.includes(dateFormatted)
                      )
                        return 'bg-info-light';
                      else return '';
                    }}
                  />
                </div>
                <div className='card' style={{ height: '625px' }}>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('ClassPage.FilterClassCard.Title')}</h2>
                    <p className='card_subtitle'>{t('ClassPage.FilterClassCard.Subtitle')}</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('Name')}
                      </label>
                      <input type='text' className='form_control' onChange={(e) => setFilterName(e.target.value)} />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='title' className='card_details_label'>
                        {t('Status')}
                      </label>
                      <select
                        required
                        value={filterStatus}
                        className='form_control select'
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        <option value='A'>{t('StatusType.Active')}</option>
                        <option value='I'>{t('StatusType.Inactive')}</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='position' className='card_details_label'>
                        {t('Day')}
                      </label>
                      <select
                        className='form_control select'
                        value={filterDia}
                        onChange={(e) => setFilterDia(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        {dias.map((item) => (
                          <option value={item.name} key={item.id}>
                            {t('WeekdayName.' + item.name)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='position' className='card_details_label'>
                        {t('Schedule')}
                      </label>
                      <select
                        className='form_control select'
                        value={filterHorario}
                        onChange={(e) => setFilterHorario(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        {horarios.map((item, i) => (
                          <option value={item.title} key={`horario_${i}`}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='position' className='card_details_label'>
                        {t('Responsible')}
                      </label>
                      <select
                        className='form_control select'
                        value={filterResponsavel}
                        onChange={(e) => setFilterResponsavel(e.target.value)}
                      >
                        {SchoolRoleUtils.isTeacher(user.role_id) ? (
                          <option value={user.name}>{user.name}</option>
                        ) : (
                          <>
                            <option value=''>{t('All')}</option>
                            {responsaveis.map((item) => (
                              <option value={item.name} key={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    <button className='btn_card mx-auto' onClick={search}>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('Search')}
                    </button>
                  </form>
                </div>
              </div>
              <div className='content'>
                <div className='d-flex flex-column flex-md-row' style={{ gap: '15px' }}>
                  <div className='card flex-column align-items-center'>
                    <div className='card_header align-items-center'>
                      <h2 className='card_title'>{t('ClassPage.LessonsCard.Title')}</h2>
                      <p className='card_subtitle'>{t('ClassPage.LessonsCard.Subtitle')}</p>
                    </div>
                    <div className='card-actions mt-4'>
                      <div className='card-action-row' style={{ justifyContent: 'center' }}>
                        <Link to='/school-lessons' className='btn_card ml-auto mt-4 mt-md-0'>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t('ClassPage.LessonsCard.Button')}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='card flex-column align-items-center'>
                    <div className='card_header align-items-center'>
                      <h2 className='card_title'>{t('ClassPage.FrequencyCard.Title')}</h2>
                      <p className='card_subtitle'>{t('ClassPage.FrequencyCard.Subtitle')}</p>
                    </div>
                    <div className='card-actions mt-4'>
                      <div className='card-action-row' style={{ justifyContent: 'center' }}>
                        <Link to='/frequency-school' className='btn_card ml-auto mt-4 mt-md-0'>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t('ClassPage.FrequencyCard.Button')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card' style={{ height: '490px' }}>
                  <div className='card_header mx-auto text-center align-items-center'>
                    <h2 className='card_title'>{t('LessonPages.LessonPlanningTitle')}</h2>
                    <p className='card_subtitle'>{t('LessonPages.LessonPlanningSubtitle')}</p>
                  </div>

                  <div
                    className='d-flex flex-row text-uppercase w-100 mt-2 p-2 px-3 mb-1 shadow align-items-center'
                    style={{ fontSize: '0.75rem', fontWeight: 700, borderRadius: '10px', zIndex: 1 }}
                  >
                    <span style={{ flex: 1 }}>{t('Planning')}</span>
                    <span className='text-center' style={{ width: '80px' }}>
                      {t('LessonPages.LinkedClasses')}
                    </span>
                    <span className='text-center' style={{ width: '115px' }}>
                      {t('Actions')}
                    </span>
                  </div>

                  <List
                    shadowInner
                    paddingInner
                    overflow='80%'
                    isLoading={isLoadingLessonsPlanning}
                    items={lessonsPlanning.map((lessonPlanning) => ({
                      ...lessonPlanning,
                      label: (
                        <span className='d-flex align-items-center'>
                          <span>{lessonPlanning.name ?? 'COD' + lessonPlanning.id.toString().padStart('3', '0')}</span>
                          <span className={`ml-auto text-center`} style={{ width: '65px' }}>
                            {lessonPlanning.student_classes.length}
                          </span>
                        </span>
                      ),
                    }))}
                    actions={[
                      {
                        label: (
                          <>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            {t('Change')}
                          </>
                        ),
                        onClick: (item) => openEditLessonPlanning(item),
                        variant: true,
                      },
                    ]}
                  />
                </div>

                <div className='card' style={{ height: '625px' }}>
                  <div className='card_header mx-auto text-center'>
                    <h2 className='card_title'>{t('ClassPage.PageTitle')}</h2>
                    <p className='card_subtitle'>{t('ClassPage.PageSubtitle')}</p>
                  </div>

                  <List
                    shadowInner
                    paddingInner
                    overflow='80%'
                    isLoading={isLoading}
                    items={classFilter
                      .sort(function (a, b) {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                          return -1;
                        }
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((classItem) => ({
                        ...classItem,
                        label: (
                          <span className={`d-flex align-items-center`} style={{ opacity: classItem.status === 'I' ? 0.5 : 1 }}>
                            <span>{classItem.name}</span>
                            <span className={`ml-auto text-center`} style={{ width: '65px' }}>
                              {t('StudentCount', { count: classItem.students.length })}
                            </span>
                          </span>
                        ),
                        disabled: classItem.status === 'I' ? 'bg-light' : ''
                      }))}
                    actions={[
                      {
                        label: (
                          <>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            {t('Change')}
                          </>
                        ),
                        onClick: (item, e) => edit(e, item),
                        variant: true,
                      },
                      {
                        label: (
                          <>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            {t('See')}
                          </>
                        ),
                        isLink: true,
                        onClick: (item) => ({
                          pathname: 'detail-class',
                          state: {
                            id: item.id,
                            school_id: school.id,
                            name: item.name,
                          },
                        }),
                      },
                      {
                        label: (
                          <>
                            <FaExternalLinkAlt />
                            {t('Link')}
                          </>
                        ),
                        onClick: (item) => openShareOptions(item),
                      },
                    ]}
                  />
                </div>
              </div>

              {/*   <EditStudentClass
                ref={editStudentClassRef}
                studentClass={studentClass}
                onSubmit={handleSubmit}
                getClass={getClass}
                dias={dias}
                responsaveis={responsaveis}
                students={students}
                horarios={horarios}
              /> */}

              <EditLessonPlanning
                showResume
                ref={editLessonPlanningRef}
                lessonPlanning={lessonPlanning}
                onSubmit={handleSubmitLessonPlanning}
              />
            </main>

            <Footer />
          </div>
        </div>
      </div>

      <SweetAlert
        show={isShowSweetAlert}
        showCloseButton
        showConfirm={false}
        onConfirm={() => { }}
        onCancel={() => {
          setIsShowSweetAlert(false);
          setClassToLink(null);
        }}
        title={t('ClassPage.LinkRegisterCard.RegistrationLink')}
      >
        <p>{t('ClassPage.LinkRegisterCard.Description')}</p>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '15px' }}>
          <div style={{ height: '28px' }}>
            <span
              onClick={copyLink}
              className='btn_card lowercase'
              style={{ marginLeft: '1.5%', minWidth: 'auto', height: '28px', fontSize: '0.75rem' }}
            >
              <FaRegCopy style={{ width: '17px', height: '17px' }} />
              <span>{t('ClassPage.LinkRegisterCard.CopyLink')}</span>
            </span>
          </div>

          <div style={{ height: '28px' }}>
            <span
              onClick={openWhatsAppComponent}
              className='btn_card lowercase'
              style={{ marginLeft: '1.5%', minWidth: 'auto', height: '28px', fontSize: '0.75rem' }}
            >
              <FaWhatsapp style={{ width: '17px', height: '17px' }} />
              <span>{t('ClassPage.LinkRegisterCard.SendViaWhatsApp')}</span>
            </span>
          </div>
        </div>
      </SweetAlert>

      <WhatsAppComponent
        ref={whatsAppComponentRef}
        sendMessageWhatsApp={sendMessageWhatsApp}
        onClose={() => {
          setClassToLink(null);
          setContactWhatsApp({ name: '', phone: '' });
        }}
      >
        <div className='form_group'>
          <label htmlFor='name' className='card_details_label'>
            {t('Name')}
          </label>
          <input
            type='text'
            required
            className='form_control'
            value={contactWhatsApp.name}
            onChange={(e) => {
              const value = e.target.value;
              setContactWhatsApp((contactWhatsApp) => ({ ...contactWhatsApp, name: value }));
            }}
          />
        </div>
        <div className='form_group'>
          <label htmlFor='phone' className='card_details_label'>
            {t('CellPhone')}
          </label>
          <PhoneMask
            value={contactWhatsApp.phone}
            onChange={(value) => setContactWhatsApp((contactWhatsApp) => ({ ...contactWhatsApp, phone: value }))}
          />
        </div>
      </WhatsAppComponent>
    </main>
  );
};

export default Class;
