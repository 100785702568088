import React, { useEffect, useState, useRef } from 'react';
import NumberFormat from 'react-number-format';

import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Utils, {
  getErrorMessageFromFileResponse,
  correctImageOrientation,
  buildFormData,
} from '../../../src/utils/utils';
import Api from '../../utils/api';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { BiDollar } from 'react-icons/bi';
import { AiOutlineBarcode, AiOutlineUnorderedList } from 'react-icons/ai';
import { BiExport } from 'react-icons/bi';
import ProductsTable from '../../components/tables/ProductsTable';
import Select from '../../components/inputs/Select';
import Input from '../../components/inputs/Input';
import { ButtonRoundedDefault } from '../../components/buttons/ButtonRoundedDefault';
import Modal from '../../components/modal/Modal';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import { APIError } from '../../services/ExceptionClass/APIError';
import { Pagination } from '@material-ui/lab';
import ProductsDetailsTable from '../../components/tables/ProductsDetailsTable';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import Textarea from '../../components/inputs/Textarea';
import { UserContext } from '../../Context/UserContext';
import '../../utils/extendsType';

const Products = () => {
  const { t } = useTranslation();
  const moment = require('moment');
  const formRef = useRef(null);
  const [titulo, setTitulo] = useState('');
  const [cod_barras, setCodBarras] = useState('');
  const [cod_produto, setCodProduto] = useState('');
  const [descricao, setDescricao] = useState('');
  const [ativo, setAtivo] = useState('Sim');
  const [preco, setPreco] = useState('');
  const [costValue, setCostValue] = useState('');
  const [id, setID] = useState(null);
  const [school_id, setSchoolID] = useState('');
  const [tamanhos, setTamanhos] = useState([]);
  const [tamanhoUnico, setTamanhoUnico] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [cortesia, setCortesia] = useState(false);
  const [discontinued, setDiscontinued] = useState(false);
  const [typeProduct, setTypeProduct] = useState('PRODUCT');
  const [titleCategory, setTitleCategory] = useState('');
  const [titleTypeService, setTitleTypeService] = useState('');
  const [titleStockAdjustmentReason, setTitleStockAdjustmentReason] = useState('');
  const [totalStock, setTotalStock] = useState(0);
  const [category_id, setCategoryId] = useState('');
  const [type_service_id, setTypeServiceId] = useState('');
  const [newPhoto, setNewPhoto] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [valueTotalInStock, setValueTotalInStock] = useState(0);
  const [productDelete, setProductDelete] = useState([]);
  const [stockAdjustmentReason, setStockAdjustmentReason] = useState([]);

  // filtros
  const [filterTitulo, setFilterTitulo] = useState('');
  const [filterStatus, setFilterStatus] = useState('Sim');
  const [filterCategory, setFilterCategory] = useState('all');
  const [filterTypeService, setFilterTypeService] = useState('all');

  // carregamento
  const [products, setProducts] = useState([]);
  const [fotos, setFotos] = useState([]);
  const [foto, setFoto] = useState('');
  const [productsFilter, setProductsFilter] = useState([]);
  const [totalProductsActive, setTotalProductsActive] = useState([]);
  const [dirUsuFotoPrincipal, setDirUsuFotoPrincipal] = useState('');
  const [display, setDisplay] = useState({});
  const [displayFoto, setDisplayFoto] = useState({});
  const [categorysProduct, setCategorysProduct] = useState([]);
  const [typeServiceProduct, setTypeServiceProduct] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);

  // process
  const [process, setProcess] = useState(false);
  const [processExport, setProcessExport] = useState(false);
  const [sendSave, setSendSave] = useState(false);
  const [sendFoto, setSendingFoto] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [successUpload, setSuccessUpload] = useState(false);
  const [sendSaveCategory, setSendSaveCategory] = useState(false);
  const [sendSaveTypeService, setSendSaveTypeService] = useState(false);
  const [sendSaveStockAdjusment, setSendSaveStockAdjusment] = useState(false);
  const [sendSaveStockAdjusmentReason, setSendSaveStockAdjusmentReason] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [successCategoryProduct, setSuccessCategoryProduct] = useState(false);
  const [successTypeServiceProduct, setSuccessTypeServiceProduct] = useState(false);
  const [successStockAdjustment, setSuccessStockAdjustment] = useState(false);
  const [successStockAdjustmentReason, setSuccessStockAdjustmentReason] = useState(false);
  const [messageCategoryProduct, setMessageCategoryProduct] = useState('');
  const [messageTypeServiceProduct, setMessageTypeServiceProduct] = useState('');
  const [messageStockAdjustment, setMessageStockAdjustment] = useState('');
  const [messageStockAdjustmentReason, setMessageStockAdjustmentReason] = useState('');

  //modals
  const [modalCategoryProduct, setModalCategoryProduct] = useState(false);
  const [modalTypeService, setModalTypeService] = useState(false);
  const [modalDetailsProductStock, setModalDetailsProductStock] = useState();
  const [productDetail, setProductDetail] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [modalStockAdjusment, setModalStockAdjustment] = useState(false);
  const [modalStockAdjusmentReason, setModalStockAdjusmentReason] = useState(false);

  //modal stock adjstument
  const [stockAdjustmentReasoniD, setStockAdjustmentReasoniD] = useState('');
  const [productStockAdjustment, setProductStockAdjustment] = useState('');
  const [newQuantity, setNewQuantity] = useState('');
  const [adjustedAmount, setAdjustedAmount] = useState('');
  const [descriptionAdjstumentStock, setDescriptionAdjstumentStock] = useState('');
  const [user_id, setUserID] = useState('');

  // libraries
  const baseURL = Api();
  const { scrollTop, currencyFormatterText } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  let modalScroll = useRef(null);

  const { user, company } = React.useContext(UserContext);

  useEffect(() => {
    async function getSession() {
      setSchoolID(company.id);
      getProducts(company.id);
      setUserID(user.id);
    }

    getSession();

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const handlerErrorServer = (err) => {
    console.log(err.response.data);

    Object.keys(err.response.data.error).map((k) => {
      const element = document.querySelector(`[name=${k}]`);
      if (element) {
        //dispatchHtmlValidity(element, `[${k}] ${err.response.data.error[k]}`);
        setMessage(`${err.response.data.error[k][0]}`);
      }

      /*  element.addEventListener('input', () => {
        dispatchHtmlValidity(element, '');
      }); */
    });
  };

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeAdd();
      closeAddFoto();
    }
  };

  async function getProducts(school, numberPage) {
    setProcess(true);
    const { data } = await axios.get(baseURL + `club-ecommerce/products/${school}`, {
      params: {
        page: numberPage,
        filterCategory,
        filterTitulo,
        filterTypeService,
        filterStatus,
      },
    });

    setCategorysProduct(data.categorys);
    setTypeServiceProduct(data.typeServices);
    setDirUsuFotoPrincipal(data.dir_usuFotoPrincipal);
    setTotalPages(data.products.last_page);
    setValueTotalInStock(data.valueTotalInStock);
    setStockAdjustmentReason(data.stockAdjustmentReason);

    const mergedCategories = data.categorys.concat(data.typeServices);
    setCategoryFilter(mergedCategories);

    const newData = data.products.data.map((prod, i) => {
      prod.fotoCapa = prod.products_fotos.find((f) => f.capa === 'S')?.nome || 'no-image.png';
      return prod;
    });

    setProducts(newData);
    setProductsFilter(newData.filter((item) => item.ativo === 'Sim'));
    setTotalProductsActive(newData.filter((item) => item.ativo === 'Sim'));
    setProcess(false);
  }

  const search = (e) => {
    e.preventDefault();
    getProducts(school_id);
  };

  const openAdd = () => {
    setError(false);
    setSuccess(false);

    setID(null);
    setCodBarras('');
    setFoto('');
    setCodProduto('');
    setTitulo('');
    setDescricao('');
    setPreco('');
    setCostValue('');
    setAtivo('Sim');
    setTamanhoUnico('');
    setSelectedTypes([]);
    setTamanhos([]);
    setCategoryId('');
    setTypeServiceId('');
    setTotalStock(0);
    setTypeProduct('PRODUCT');
    setCortesia(false);
    setDiscontinued(false);

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const closeAdd = () => {
    setDisplay({});
  };

  const closeAddFoto = () => {
    setDisplayFoto({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setSuccess(false);

    let value = preco;

    let typesSizes = '';
    if (Array.isArray(selectedTypes) && selectedTypes.length > 0) {
      typesSizes = JSON.stringify(selectedTypes);
    }
    let data = {
      id,
      club_id: school_id,
      cod_produto,
      cod_barras,
      titulo,
      descricao,
      ativo,
      tipoTamanho: tamanhoUnico,
      tamanho: JSON.stringify(tamanhos),
      preco: value,
      cost_value: costValue,
      cortesia,
      discontinued,
      type_product: typeProduct,
      selectedTypes: typesSizes,
      category_id,
      type_service_id,
      foto: newPhoto,
    };

    try {
      const productFormData = buildFormData(data);
      setMessage(id ? 'SystemMessage.Success.ProductUpdatedSuccess' : 'SystemMessage.Success.ProductCreatedSuccess');
      setSendSave(true);

      const res = await axios.post(baseURL + 'club-ecommerce/products-save', productFormData);
      setSendSave(false);
      setSuccess(true);
      setID(res.data.create.id);

      await getProducts(school_id, currentPage);

      scrollTop(modalScroll);
    } catch (error) {
      setError(true);
      if (error.response.status === 422) {
        handlerErrorServer(error);
      } else {
        if (error instanceof APIError) {
          setMessage(error.messageKey || error.message);
        } else {
          setMessage('SystemMessage.Fail.ErrorRetry');
        }
      }
    } finally {
      setSendSave(false);
      scrollTop(modalScroll);
    }
  };

  const edit = (e, produto) => {
    setError(false);
    setSuccess(false);

    setID(produto.id);
    setCodBarras(produto.cod_barras);
    setCodProduto(produto.cod_produto);
    setTitulo(produto.titulo);
    setDescricao(produto.descricao);
    setPreco(produto.preco);
    setCostValue(produto.cost_value);
    setAtivo(produto.ativo);
    setTotalStock(produto.product_stock.sum('amount'));

    const jsonString = produto.tipoTamanho;

    try {
      const parsedJson = JSON.parse(jsonString);
      // Se chegou até aqui, o JSON é válido
      setSelectedTypes(parsedJson);
    } catch (error) {
      // Caso a string não seja um JSON válido, a execução entra aqui
      if (jsonString === 'UNICO') {
        setTamanhoUnico(jsonString);
      } else {
        setSelectedTypes(jsonString);
      }
    }
    setCortesia(produto.cortesia);
    setDiscontinued(produto.discontinued);
    setTypeProduct(produto.type_product);
    if (produto.category_id) {
      setCategoryId(produto.category_id);
    }
    if (produto.type_service_id) {
      setTypeServiceId(produto.type_service_id);
    }

    const fotoPrincipal = produto.products_fotos.find((item) => item.capa === 'S');
    if (fotoPrincipal) {
      setFoto(dirUsuFotoPrincipal + '/' + fotoPrincipal.nome);
    }

    const tam = JSON.parse(produto.tamanho);
    setTamanhos(tam);

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const editarFoto = async (e, product) => {
    setSuccessUpload(false);
    setErrorUpload(false);
    setID(product.id);
    setFotos(product.products_fotos);

    setDisplayFoto({
      display: 'flex',
      opacity: 1,
    });
  };

  const handleUpload = async (e) => {
    setErrorUpload(false);
    setSuccessUpload(false);

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('foto', file);
    formData.append('product_id', id);
    formData.append('club_id', school_id);

    setSendingFoto(true);
    const res = await axios.post(baseURL + 'club-ecommerce/products-uplodad-foto', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    setSendingFoto(false);

    if (!res.data.nomeArq) {
      setErrorUpload(true);
      setSuccessUpload(false);

      setMessage(getErrorMessageFromFileResponse(res.data.erro) || 'SystemMessage.File.Fail.' + res.data.messageKey);
    } else {
      setSuccessUpload(true);
      setMessage('SystemMessage.File.Success.PhotoSendSuccess');
      setFotos([...fotos, res.data.create]);
      await getProducts(school_id);
    }
  };

  const handleDeleteFoto = async (id, nome) => {
    await axios.post(baseURL + 'club-ecommerce/products-delete-foto', { id, nome });

    setSuccessUpload(true);
    setMessage('SystemMessage.File.Success.PhotoDeletedSuccess');

    const newFotos = fotos.filter((item) => {
      return item.id !== id;
    });

    setFotos(newFotos);

    await getProducts(school_id);
  };

  const addTamanho = (e) => {
    if (e.target.checked) {
      setTamanhos([...tamanhos, e.target.value]);
      return;
    }

    let newTamanhos = tamanhos.filter((item) => {
      return item !== e.target.value;
    });

    setTamanhos([...newTamanhos]);
  };

  const handleCapa = async (id, selected, product_id) => {
    await axios.post(baseURL + 'club-ecommerce/products-update-foto-capa', {
      id,
      capa: selected ? 'S' : 'N',
      product_id,
      club_id: school_id,
    });

    setSuccessUpload(true);
    setMessage('SystemMessage.Success.CoverUpdated');

    await getProducts(school_id);
  };

  const saveCategoryProduct = async (e) => {
    e.preventDefault();

    setError(false);

    setSuccessCategoryProduct(false);

    setMessageCategoryProduct('SystemMessage.Success.CategoryProductCreated');

    setSendSaveCategory(true);

    const data = {
      club_id: school_id,
      title: titleCategory,
    };

    try {
      const res = await axios.post(baseURL + 'club-ecommerce/category-product-save', data);

      setTitleCategory('');

      setSuccessCategoryProduct(true);

      setCategorysProduct(res.data.categorys);
      setCategoryId(res.data.create.id);
    } catch (error) {
      if (error instanceof APIError) {
        setError(true);
        setMessageCategoryProduct(error.messageKey || error.message);
      } else {
        setError(true);
        setMessageCategoryProduct('SystemMessage.Fail.ErrorRetry');
      }
    } finally {
      setSendSaveCategory(false);
      setModalCategoryProduct(false);
    }
  };

  const saveTypeServiceProduct = async (e) => {
    e.preventDefault();
    setError(false);
    setSuccessTypeServiceProduct(false);
    let data = {
      club_id: school_id,
      title: titleTypeService,
    };

    setMessageTypeServiceProduct('SystemMessage.Success.TypeServiceProductCreated');

    setSendSaveTypeService(true);

    try {
      const res = await axios.post(baseURL + 'club-ecommerce/type-service-product-save', data);
      setTitleTypeService('');
      setSendSaveTypeService(false);
      setSuccessTypeServiceProduct(true);
      setTypeServiceProduct(res.data.typesService);
    } catch (error) {
      setSuccessTypeServiceProduct(false);
    }
  };

  const saveStockAdjustmentReason = async (e) => {
    e.preventDefault();
    setError(false);
    setSuccessStockAdjustmentReason(false);
    let data = {
      club_id: school_id,
      title: titleStockAdjustmentReason,
    };

    setMessageStockAdjustmentReason('SystemMessage.Success.TypeServiceProductCreated');

    setSendSaveStockAdjusmentReason(true);
    const res = await axios.post(baseURL + 'club-ecommerce/save-stock-adjustment-reason', data);
    setTitleStockAdjustmentReason('');
    setSendSaveStockAdjusmentReason(false);
    setSuccessStockAdjustmentReason(true);
    setStockAdjustmentReason(res.data.stockAdjustmentReason);
  };

  const saveStockAdjustment = async (e) => {
    e.preventDefault();
    setError(false);
    setSuccessStockAdjustmentReason(false);

    let data = {
      club_id: school_id,
      product_stock_id: productStockAdjustment.id,
      user_id: user_id,
      stock_adjustment_reason_id: stockAdjustmentReasoniD,
      description: descriptionAdjstumentStock,
      available_quantity: productStockAdjustment.amount,
      new_quantity: newQuantity,
      adjusted_amount: adjustedAmount,
      final_balance: productDetail.cost_value * newQuantity,
    };

    setMessageStockAdjustment('SystemMessage.Success.StockAdjustmentUpdate');

    setSendSaveStockAdjusment(true);
    const res = await axios.post(baseURL + 'club-ecommerce/save-stock-adjustment', data);
    setSendSaveStockAdjusment(false);
    setSuccessStockAdjustment(true);
  };

  const tamanhosCheckBox = {
    LETRA: [
      { label: 'PP', value: 'PP' },
      { label: 'P', value: 'P' },
      { label: 'M', value: 'M' },
      { label: 'G', value: 'G' },
      { label: 'GG', value: 'GG' },
      { label: 'XGG', value: 'XGG' },
    ],
    NUMERO: [
      { label: '4', value: '4' },
      { label: '6', value: '6' },
      { label: '8', value: '8' },
      { label: '10', value: '10' },
      { label: '12', value: '12' },
      { label: '14', value: '14' },
      { label: '16', value: '16' },
      { label: '27', value: '27' },
      { label: '28', value: '28' },
      { label: '29', value: '29' },
      { label: '30', value: '30' },
      { label: '31', value: '31' },
      { label: '32', value: '32' },
      { label: '33', value: '33' },
      { label: '34', value: '34' },
      { label: '35', value: '35' },
      { label: '36', value: '36' },
      { label: '37', value: '37' },
      { label: '38', value: '38' },
      { label: '39', value: '39' },
      { label: '40', value: '40' },
    ],
  };

  const handleFoto = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const newPhoto = await correctImageOrientation(file);

      URL.revokeObjectURL(foto);
      setFoto(URL.createObjectURL(newPhoto));
      setNewPhoto(newPhoto);
    }
  };

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
    getProducts(school_id, page);
  };

  const showModalDetailsProductStock = (product) => {
    /*  if (product.product_stock.length === 0) {
      toast.error('Não existe estoque para esse produto.');
      return;
    } */
    setModalDetailsProductStock(true);
    setProductDetail(product);
  };

  const handleTypeChange = (e) => {
    setTamanhoUnico('');
    const selectedValue = e.target.value;
    if (e.target.checked) {
      setSelectedTypes((prevSelectedTypes) => [...prevSelectedTypes, selectedValue]);
    } else {
      setSelectedTypes((prevSelectedTypes) => prevSelectedTypes.filter((type) => type !== selectedValue));
    }
  };

  const showAlertDeleteProduct = (product) => {
    setShowAlert(true);
    setProductDelete(product);
  };

  const handleCancel = () => {
    setShowAlert(false);
  };

  const handleDeleteProduct = async (e) => {
    if (productDelete.product_stock.length > 0) {
      toast.error('Você nao pode excluir esse produto.');
      setShowAlert(false);
      return;
    }

    try {
      const response = await axios.post(baseURL + `products-delete`, { id: productDelete.id });

      if (response.data.delete) {
        toast.success('Produto excluido com sucesso.');
        setShowAlert(false);
        getProducts(school_id);
      } else {
        toast.error(error.response.data?.error || 'Houve um erro ao excluir esse produto.');
      }
    } catch (error) {
      toast.error(error.response.data?.error || 'Houve um erro ao excluir esse produto.');
    }
  };

  const showModalAdjustmentStock = (product) => {
    setModalStockAdjustment(true);
    // setModalDetailsProductStock(false);
    setProductStockAdjustment(product);
  };

  const exportCsvAndPdf = async () => {
    setProcessExport(true);
    const data = {
      club_id: school_id,
    };
    await axios
      .post(baseURL + 'club-ecommerce/export-csv-stock', data, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const filename = `relatorio_stock-${new Date().toISOString().replaceAll(':', '')}.xlsx`;
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error('Ocorreu um erro ao baixar o arquivo:', error);
      });

    await axios
      .post(baseURL + `club-ecommerce/export-pdf-stock`, data, { responseType: 'blob' })
      .then((reponse) => {
        const blob = new Blob([reponse.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `relatorio_stock-${new Date().toISOString().replaceAll(':', '')}.pdf`;
        link.click();
      })
      .catch((error) => {
        console.error('Ocorreu um erro ao baixar o arquivo:', error);
      });

    setProcessExport(false);
  };

  return (
    <main>
      <SweetAlert
        show={showAlert}
        warning
        showCancel
        cancelBtnText={t('Cancel')}
        confirmBtnText={t('Yes')}
        confirmBtnBsStyle='danger'
        title='Excluir produto'
        onConfirm={handleDeleteProduct}
        onCancel={handleCancel}
        focusCancelBtn
      >
        <div className='d-flex flex-column'>
          <span>Você deseja realmente excluir esse produto?</span>
          <span style={{ color: 'red', fontWeight: 700 }}>
            {productDelete.cod_produto} - {productDelete.titulo}
          </span>
        </div>
      </SweetAlert>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            {/* {school_id !== '' ? <HeaderSchool /> : <HeaderClub />} */}

            <main className='content_panel'>
              <div className='content flex-column flex-sm-row'>
                <div className='card mr-3 d-flex justify-content-center col-xs-12 col-sm-6'>
                  <div>
                    <h2 className='card_title'>{t('ProductsManagerPage.AddProductsCard.Title')}</h2>
                    <p className='card_subtitle'>{t('ProductsManagerPage.AddProductsCard.Subtitle')}</p>
                  </div>
                  <div className='card-actions'>
                    <div className='card-action-row'>
                      <button
                        onClick={openAdd}
                        className='btn_card mx-auto mt-4 btn_open_modal'
                        data-modal='#add_aluno'
                      >
                        {t('ProductsManagerPage.AddProductsCard.Button')}
                      </button>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('Summary')}</h2>
                    <div className='d-flex align-items-center' style={{ gap: '50px' }}>
                      <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                        <span className='p-2' style={{ background: 'var(--main-color)', borderRadius: '89px' }}>
                          <AiOutlineUnorderedList size={25} />
                        </span>
                        <div className='d-flex flex-column'>
                          <span className='font-weight-bold'>{totalProductsActive.length}</span>
                          <p>{t('RegisteredProducts')}</p>
                        </div>
                      </div>
                      <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                        <span className='p-2' style={{ background: 'var(--main-color)', borderRadius: '89px' }}>
                          <BiDollar size={25} />
                        </span>
                        <div className='d-flex flex-column'>
                          <span className='font-weight-bold'>{currencyFormatterText(valueTotalInStock)}</span>
                          <p>{t('TotalStockValue')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card_header mx-auto text-center'>
                  <h2 className='card_title'>{t('ProductsManagerPage.FilterProductsCard.Title')}</h2>
                  <p className='card_subtitle'>
                    {products.length} {t('ProductsManagerPage.FilterProductsCard.Subtitle')}
                  </p>
                </div>

                <form action='' method='post' ref={formRef} className='d-flex flex-column mt-4' style={{ gap: '5px' }}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <input
                      placeholder='Buscar por Cód, nome ou Serviço'
                      type='text'
                      className='form-control form-control-lg mr-2'
                      onChange={(e) => setFilterTitulo(e.target.value)}
                    />
                    <ButtonRounded isBig onClick={search}>
                      {t('Search')}
                    </ButtonRounded>
                  </div>
                  <div className='d-flex flex-column flex-sm-row' style={{ gap: '10px' }}>
                    <div className='form_group'>
                      <label htmlFor='title' className='card_details_label'>
                        {t('StatusType.Active', { context: 'question' })}
                      </label>
                      <select
                        required
                        value={filterStatus}
                        className='form-control select'
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        <option value='Sim'>{t('Yes')}</option>
                        <option value='Não'>{t('Not')}</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='title' className='card_details_label'>
                        {t('Choose_a_category')}
                      </label>
                      <select
                        required
                        value={filterCategory}
                        className='form-control select'
                        onChange={(e) => setFilterCategory(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        {categorysProduct.map((item) => (
                          <option key={`a${item.id}`} value={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='title' className='card_details_label'>
                        {t('Choose_a_type')}
                      </label>
                      <select
                        required
                        value={filterTypeService}
                        className='form-control select'
                        onChange={(e) => setFilterTypeService(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        {typeServiceProduct.map((item) => (
                          <option key={`b${item.id}`} value={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </form>
                <div className='list_card'>
                  {process && (
                    <p className='loading-card'>
                      <Loading type='1' />
                    </p>
                  )}
                  <div className='d-flex justify-content-start w-100'>
                    <ButtonRoundedDefault
                      isLoading={processExport}
                      onClick={() => exportCsvAndPdf()}
                      icon={<BiExport />}
                      isBig
                      className={'align-items-center'}
                    >
                      Exportar
                    </ButtonRoundedDefault>
                  </div>
                  {!process && (
                    <>
                      <ProductsTable
                        productsFilter={products}
                        dirUsuFotoPrincipal={dirUsuFotoPrincipal}
                        onEdit={edit}
                        onClickDetails={showModalDetailsProductStock}
                        onClickDelete={showAlertDeleteProduct}
                      />
                      <div className='w-100 p-2 d-flex justify-content-center'>
                        {products.length > 0 && (
                          <Pagination
                            page={currentPage}
                            onChange={handleChangePage}
                            count={totalPages}
                            variant='outlined'
                            shape='rounded'
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='modal' id='add_foto' style={displayFoto}>
                <div className='modal_content' ref={modalScroll} style={{ width: 570 }}>
                  <div className='modal_close' onClick={closeAddFoto}>
                    X
                  </div>
                  <h3 className='modal_title'>{t('Photos')}</h3>
                  {errorUpload && (
                    <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                  )}
                  {successUpload && (
                    <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message)} />
                  )}
                  <form action='' onSubmit={handleSubmit} method='post' className='form_modal'>
                    <div className='form_group' style={{ border: '2px dashed #ddd', marginBottom: 40 }}>
                      <label className='carregar-arquivo' style={{ margin: 0 }}>
                        {!sendFoto && (
                          <React.Fragment>
                            {t('LoadImage')}
                            <input type='file' name='file' onChange={(e) => handleUpload(e)} />
                          </React.Fragment>
                        )}
                        {sendFoto && <Loading type='1' />}
                      </label>
                    </div>

                    {fotos.length === 0 ? (
                      <p style={{ textAlign: 'center', width: '100%' }}>
                        <b>{t('NoPhotoAttached')}</b>
                      </p>
                    ) : (
                      <p style={{ textAlign: 'center', width: '100%', marginBottom: 20 }}>
                        <b>{t('AttachedPhotos')}</b>
                      </p>
                    )}

                    <div className='row' style={{ display: 'flex', paddingLeft: 15 }}>
                      {fotos.map((item) => (
                        <div style={{ marginBottom: 20, marginLeft: 7, marginRight: 10 }} key={item.id}>
                          <a target='_blank' rel='noopener noreferrer' href={`${dirUsuFotoPrincipal}/${item.nome}`}>
                            <img
                              src={`${dirUsuFotoPrincipal}/${item.nome}`}
                              style={{ borderRadius: 6, width: 150, height: 140, objectFit: 'cover' }}
                            />
                          </a>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: 5,
                              padding: 5,
                              justifyContent: 'space-between',
                              flexDirection: 'row',
                            }}
                          >
                            <div>
                              {item.capa === 'S' && (
                                <input
                                  type='radio'
                                  name='capa'
                                  defaultChecked={true}
                                  onClick={(e) => handleCapa(item.id, e.target.checked, item.product_id)}
                                />
                              )}
                              {item.capa === 'N' && (
                                <input
                                  type='radio'
                                  name='capa'
                                  defaultChecked={false}
                                  onClick={(e) => handleCapa(item.id, e.target.checked, item.product_id)}
                                />
                              )}
                              <span style={{ marginLeft: 5 }}>{t('Cover')}</span>
                            </div>
                            <div>
                              <p
                                onClick={() => handleDeleteFoto(item.id, item.nome, item.product_id)}
                                style={{
                                  cursor: 'pointer',
                                  fontSize: 12,
                                  background: '#B22222',
                                  color: 'white',
                                  padding: '2px 8px',
                                  borderRadius: 4,
                                }}
                              >
                                {t('Delete')}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    {sendSave && (
                      <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none' }} disabled>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('SystemMessage.Loading.Processing')}
                        <Loading type='1' style={{ marginLeft: '2%' }} />
                      </button>
                    )}
                  </form>
                </div>
              </div>

              <div className='modal' id='add_aluno' style={display}>
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeAdd}>
                    X
                  </div>
                  <h3 className='modal_title text-center'>
                    {id ? t('ProductsManagerPage.Modal.TitleEdit') : t('ProductsManagerPage.Modal.TitleAdd')}
                  </h3>
                  {error && (
                    <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                  )}
                  {success && (
                    <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message)} />
                  )}
                  <form action='' onSubmit={handleSubmit} method='post' className='form_modal'>
                    {foto !== '' ? (
                      <div className='group_50' style={{ justifyContent: 'space-around' }}>
                        <img
                          style={{
                            borderRadius: 10,
                            marginBottom: 10,
                            width: '90%',
                          }}
                          src={foto}
                        />
                        <label
                          style={{
                            cursor: 'pointer',
                            padding: '12px 22px',
                            borderRadius: '50px',
                            border: '1px solid grey',
                            color: 'grey',
                            fontSize: '13px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                          }}
                        >
                          <input type='file' name='file[]' onChange={(e) => handleFoto(e)} />
                          {t('ChangePhoto')}
                        </label>
                      </div>
                    ) : (
                      <div className='group_50 upload_file'>
                        <label htmlFor='file'></label>
                        <input
                          type='file'
                          name='file[]'
                          id='file'
                          onChange={(e) => handleFoto(e)}
                          style={{
                            display: 'initial',
                            opacity: 0,
                            cursor: 'pointer',

                            position: 'relative',
                            top: '-52px',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </div>
                    )}
                    <div className='group_50'>
                      <div className='d-flex align-items-center justify-content-center' style={{ gap: '2px' }}>
                        <span className='font-weight-bold' style={{ fontSize: '14px' }}>
                          O que você vai cadastrar?
                        </span>
                        <div className='form-check form-check-inline ml-2'>
                          <input
                            className='form-check-input'
                            onChange={() => setTypeProduct('PRODUCT')}
                            checked={typeProduct === 'PRODUCT'}
                            type='radio'
                            name='typeProduct'
                            id='Product'
                            value={typeProduct}
                          />
                          <label className='form-check-label' htmlFor='Product'>
                            Produto
                          </label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            onChange={() => {
                              setTypeProduct('SERVICE');
                              setPreco(0);
                            }}
                            checked={typeProduct === 'SERVICE'}
                            name='typeProduct'
                            id='Product'
                            value={typeProduct}
                          />
                          <label className='form-check-label' htmlFor='Service'>
                            Serviço
                          </label>
                        </div>
                      </div>

                      <div className='form_group mt-3'>
                        <label htmlFor='name' className='card_details_label'>
                          * {typeProduct === 'PRODUCT' && <>{t('ProductName')}</>}
                          {typeProduct !== 'PRODUCT' && <>{t('ServiceName')}</>}
                        </label>
                        <div style={{ position: 'relative' }}>
                          <Input
                            type='text'
                            required
                            className='form_control'
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                          />
                          {/* <AiOutlineBarcode style={{
                            color: 'var(--main-color)',
                            position: 'absolute',
                            left: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                          }}
                            size={20}
                          /> */}
                        </div>
                      </div>

                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          *{typeProduct === 'PRODUCT' && <>{t('ProductCode')}</>}
                          {typeProduct !== 'PRODUCT' && <>{t('ServiceCode')}</>}
                        </label>
                        <input
                          type='text'
                          required
                          className='form_control'
                          value={cod_produto}
                          onChange={(e) => setCodProduto(e.target.value)}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='descript' className='card_details_label'>
                          *Pequena descriçao
                        </label>
                        <input
                          className='form_control'
                          value={descricao}
                          id=''
                          onChange={(e) => setDescricao(e.target.value)}
                        />
                      </div>
                      {typeProduct === 'PRODUCT' && (
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            *Escolha uma categória
                          </label>
                          <Select
                            value={category_id}
                            className='form-control'
                            onChange={(e) => {
                              if (e.target.value === 'add_category_product') {
                                setModalCategoryProduct(true);
                              } else {
                                setCategoryId(e.target.value);
                              }
                            }}
                            name='category_id'
                            placeholder='Escolha uma categória'
                            required
                          >
                            <option value=''>Escolha uma categória</option>
                            {categorysProduct.map((category) => (
                              <option key={`c${category.id}`} value={category.id}>
                                {category.title}
                              </option>
                            ))}
                            <option
                              style={{ color: 'var(--main-color)', fontSize: '18px' }}
                              className='mt-2'
                              value='add_category_product'
                            >
                              + Adicionar nova categoria
                            </option>
                          </Select>
                        </div>
                      )}
                      {typeProduct !== 'PRODUCT' && (
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            *Escolha um tipo de serviço
                          </label>
                          <Select
                            className='form-control'
                            value={type_service_id}
                            onChange={(e) => {
                              if (e.target.value === 'add_type_service') {
                                setModalTypeService(true);
                              } else {
                                setTypeServiceId(e.target.value);
                              }
                            }}
                            placeholder='Escolha um tipo de serviço'
                            name='type_service_id'
                            required
                          >
                            <option value=''>Escolha um tipo de serviço</option>
                            {typeServiceProduct.map((typeService) => (
                              <option key={`d${typeService.id}`} value={typeService.id}>
                                {typeService.title}
                              </option>
                            ))}
                            <option style={{ color: 'var(--main-color)', fontSize: '18px' }} value='add_type_service'>
                              + Adicionar tipo de serviço
                            </option>
                          </Select>
                        </div>
                      )}
                    </div>
                    <div className='row w-100 py-4'>
                      <div className='col border border-top'></div>
                    </div>
                    <div className='row w-100'>
                      {typeProduct === 'PRODUCT' && (
                        <div className='col-md-4 col-sm-12'>
                          <div className='form_group'>
                            <label htmlFor='name' className='card_details_label'>
                              Quantidade em estoque
                            </label>
                            <input
                              type='text'
                              required
                              disabled
                              className='form_control'
                              value={totalStock}
                              onChange={(e) => setCodProduto(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                     
                        <div className='col-md-4 col-sm-12'>
                          <div className='form_group '>
                            <label htmlFor='descript' required className='card_details_label'>
                             { typeProduct === 'PRODUCT' ? 'Valor de venda do produto' : 'Valor seviços' }
                            </label>
                            <NumberFormat
                              className='form_control'
                              required
                              placeholder={t('PlaceholderMoneyZero')}
                              displayType={'input'}
                              format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: e / 100 })}
                              value={preco * 100 || ''}
                              onValueChange={(e) => setPreco(e.floatValue / 100 || null)}
                              readOnly={cortesia}
                            />
                          </div>
                        </div>
                   
                      <div className='col-md-4 col-sm-12'>
                        <div className='form_group mt-2'>
                          <label htmlFor='title' className='card_details_label'>
                            {t('StatusType.Active')}
                          </label>
                          <select
                            required
                            value={ativo}
                            className='form_control select'
                            onChange={(e) => setAtivo(e.target.value)}
                          >
                            <option value='Sim'>{t('Yes')}</option>
                            <option value='Não'>{t('Not')}</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div
                      className='form_group'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        marginTop: 10,
                        marginBottom: 20,
                      }}
                    >
                      {typeProduct === 'PRODUCT' && (
                        <>
                          <div
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '0.75rem',
                                fontWeight: 600,
                                fontStyle: 'normal',
                                marginRight: 15,
                                color: '#ba8820',
                              }}
                            >
                              {t('Size')}:{' '}
                            </span>

                            <input
                              type='checkbox'
                              defaultValue='LETRA'
                              name='tipoTamanho'
                              checked={selectedTypes?.includes('LETRA')}
                              onChange={handleTypeChange}
                            />
                            <span style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>{t('Letter')}</span>

                            <input
                              type='checkbox'
                              value='NUMERO'
                              name='tipoTamanho'
                              checked={selectedTypes?.includes('NUMERO')}
                              onChange={handleTypeChange}
                            />
                            <span style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>{t('Number')}</span>

                            <input
                              type='radio'
                              value='UNICO'
                              name='tipoTamanho'
                              checked={tamanhoUnico === 'UNICO'}
                              onChange={(e) => {
                                setSelectedTypes([]);
                                setTamanhos([]);
                                setTamanhoUnico(e.target.value);
                              }}
                            />
                            <span style={{ marginLeft: 5, marginRight: 15, fontSize: '0.80rem' }}>{t('Single')}</span>
                          </div>
                          {tamanhoUnico !== 'UNICO' && (
                            <div
                              style={{
                                marginTop: '5px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 5,
                              }}
                            >
                              {selectedTypes?.includes('LETRA') && (
                                <>
                                  {tamanhosCheckBox['LETRA'].map((option, i) => (
                                    <>
                                      <div
                                        key={i + '_checkbox_size'}
                                        style={{
                                          justifyContent: 'start',
                                          alignItems: 'center',
                                          display: 'flex',
                                          flexDirection: 'row',
                                          width: '100px',
                                        }}
                                      >
                                        <input
                                          id={i + '_checkbox_size'}
                                          type='checkbox'
                                          checked={tamanhos.some((t) => t === option.value)}
                                          onClick={addTamanho}
                                          defaultValue={option.value}
                                        />
                                        <label
                                          htmlFor={i + '_checkbox_size'}
                                          style={{
                                            marginLeft: 5,
                                            marginRight: 15,
                                            marginBottom: 0,
                                            fontSize: '0.80rem',
                                          }}
                                        >
                                          {' '}
                                          {option.label}{' '}
                                        </label>
                                      </div>
                                      {window.innerWidth > 425 &&
                                        (i + 1) % Math.ceil(tamanhosCheckBox['LETRA'].length / 2) === 0 && (
                                          <div style={{ flexBasis: '100%', width: 0 }} />
                                        )}
                                      {window.innerWidth <= 425 &&
                                        (i + 1) % Math.ceil(tamanhosCheckBox['LETRA'].length / 2) === 0 && (
                                          <div style={{ flexBasis: '100%', width: 0 }} />
                                        )}
                                    </>
                                  ))}
                                </>
                              )}
                              {selectedTypes?.includes('NUMERO') && (
                                <>
                                  {tamanhosCheckBox['NUMERO'].map((option, i) => (
                                    <>
                                      <div
                                        key={i + '_n'}
                                        style={{
                                          justifyContent: 'start',
                                          alignItems: 'center',
                                          display: 'flex',
                                          flexDirection: 'row',
                                          width: '100px',
                                        }}
                                      >
                                        <input
                                          id={i + '_n'}
                                          type='checkbox'
                                          checked={tamanhos.some((t) => t === option.value)}
                                          onClick={addTamanho}
                                          defaultValue={option.value}
                                        />
                                        <label
                                          htmlFor={i + '_n'}
                                          style={{
                                            marginLeft: 5,
                                            marginRight: 15,
                                            marginBottom: 0,
                                            fontSize: '0.80rem',
                                          }}
                                        >
                                          {' '}
                                          {option.label}{' '}
                                        </label>
                                      </div>
                                      {window.innerWidth > 425 &&
                                        (i + 1) % Math.ceil(tamanhosCheckBox['NUMERO'].length / 3) === 0 && (
                                          <div style={{ flexBasis: '100%', width: 0 }} />
                                        )}
                                      {window.innerWidth <= 425 &&
                                        (i + 1) % Math.ceil(tamanhosCheckBox['NUMERO'].length / 2) === 0 && (
                                          <div style={{ flexBasis: '100%', width: 0 }} />
                                        )}
                                    </>
                                  ))}
                                </>
                              )}
                            </div>
                          )}
                        </>
                      )}

                      <div className='group_50'>
                        <div
                          className='mt-4'
                          style={{
                            justifyContent: 'start',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <input
                            id='checkboxDiscontinued'
                            type='checkbox'
                            checked={discontinued === true}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              setDiscontinued(checked);
                            }}
                          />
                          <label
                            htmlFor='checkboxDiscontinued'
                            style={{ marginLeft: 5, marginRight: 15, marginBottom: 0, fontSize: '0.80rem' }}
                          >
                            {t('Discontinued')}
                          </label>
                        </div>

                        <div
                          className='mt-4'
                          style={{
                            justifyContent: 'start',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <input
                            id='complimentary_product_checkbox_size'
                            type='checkbox'
                            checked={cortesia === true}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              setCortesia(checked);
                              if (checked) setPreco(0);
                              else setPreco('');
                            }}
                          />
                          <label
                            htmlFor='complimentary_product_checkbox_size'
                            style={{ marginLeft: 5, marginRight: 15, marginBottom: 0, fontSize: '0.80rem' }}
                          >
                            {t('ComplimentaryProduct')}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        gap: '10px',
                      }}
                    >
                      {!sendSave && (
                        <button className='btn_card'>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {id ? t('ProductsManagerPage.Modal.ButtonEdit') : t('ProductsManagerPage.Modal.ButtonAdd')}
                        </button>
                      )}
                      {sendSave && (
                        <button className='btn_card' style={{ background: '#ccc', border: 'none' }} disabled>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t('SystemMessage.Loading.Processing')}
                          <Loading type='1' style={{ marginLeft: '2%' }} />
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </main>

            <Modal
              modalContentStyle={{ maxHeight: '200px' }}
              message={messageCategoryProduct}
              successMessage={successCategoryProduct}
              size={'sm'}
              show={modalCategoryProduct}
              handleClose={() => setModalCategoryProduct(!modalCategoryProduct)}
            >
              <form
                action=''
                onSubmit={saveCategoryProduct}
                method='post'
                className='form_modal form_modal_add_category_product'
              >
                <div className='d-flex flex-column w-100'>
                  <h3 className='modal_title text-center'>Adicionar categoria</h3>
                  <div className='d-flex align-items-start flex-column mt-4'>
                    <label>*Nome da categoria</label>
                    <Input
                      onChange={(e) => setTitleCategory(e.target.value)}
                      value={titleCategory}
                      required
                      className='form-control'
                      name='categoryProduct'
                      placeholder='Ex: Bebidas'
                    />
                  </div>
                  <div className='d-flex align-items-center justify-content-between mt-5 w-100'>
                    <ButtonRounded
                      className='w-100'
                      variant='outline'
                      color='black'
                      type='button'
                      isBig={true}
                      onClick={() => setModalCategoryProduct(false)}
                    >
                      Cancelar
                    </ButtonRounded>
                    <ButtonRounded className='w-100 ml-2' isLoading={sendSaveCategory} isBig={true}>
                      Salvar
                    </ButtonRounded>
                  </div>
                </div>
              </form>
            </Modal>

            <Modal
              modalContentStyle={{ maxHeight: '200px' }}
              message={messageTypeServiceProduct}
              successMessage={successTypeServiceProduct}
              show={modalTypeService}
              size={'sm'}
              handleClose={() => setModalTypeService(!modalTypeService)}
            >
              <form
                action=''
                onSubmit={saveTypeServiceProduct}
                method='post'
                className='form_modal form_modal_add_category_product'
              >
                <div className='d-flex flex-column w-100'>
                  <h3 className='modal_title text-center'>Adicionar tipo de serviço</h3>
                  <div className='d-flex align-items-start flex-column mt-4'>
                    <label className='card_details_label'>*Nome do serviço</label>
                    <Input
                      value={titleTypeService}
                      onChange={(e) => setTitleTypeService(e.target.value)}
                      required
                      className='form-control'
                      name='typeService'
                      placeholder='Ex: Ingresso para o evento ou curso...'
                    />
                  </div>
                  <div className='d-flex align-items-center justify-content-between mt-5 w-100'>
                    <ButtonRounded
                      variant='outline'
                      color='black'
                      className='w-100'
                      type='button'
                      isBig={true}
                      onClick={() => setModalTypeService(false)}
                    >
                      Cancelar
                    </ButtonRounded>
                    <ButtonRounded className='w-100 ml-2' isLoading={sendSaveTypeService} isBig={true}>
                      Salvar
                    </ButtonRounded>
                  </div>
                </div>
              </form>
            </Modal>

            <Modal
              modalContentStyle={{ maxHeight: '200px' }}
              show={modalDetailsProductStock}
              handleClose={() => setModalDetailsProductStock(!modalDetailsProductStock)}
            >
              <form
                action=''
                onSubmit={saveTypeServiceProduct}
                method='post'
                className='form_modal_add_category_product'
              >
                <div className='d-flex flex-column '>
                  <h3 className='modal_title text-center'>Detalhes produto/Serviço</h3>
                  <div
                    className='d-flex justify-content-start align-items-start flex-column flex-sm-row mt-4'
                    style={{ gap: '20px' }}
                  >
                    {productDetail && (
                      <>
                        <img
                          src={`${dirUsuFotoPrincipal}/${productDetail.fotoCapa}`}
                          style={{ borderRadius: 6, width: 280, height: 140, objectFit: 'cover' }}
                        />
                        <div className='d-flex align-items-start flex-column' style={{ gap: '15px' }}>
                          <span>
                            {' '}
                            <span style={{ color: 'var(--main-color)' }}> Nome: </span>
                            <b className='co'>{productDetail.titulo}</b>
                          </span>
                          <span>
                            {' '}
                            <span style={{ color: 'var(--main-color)' }}> Código: </span>
                            <b>{productDetail.cod_produto}</b>
                          </span>
                          <span>
                            {' '}
                            <span style={{ color: 'var(--main-color)' }}> Categoria: </span>
                            <b>{productDetail.category_products && productDetail.category_products.title}</b>
                          </span>
                        </div>
                      </>
                    )}
                  </div>

                  <div className='d-flex flex-column'>
                    <ProductsDetailsTable
                      productsDetails={productDetail.product_stock}
                      onClickStockAdjustment={showModalAdjustmentStock}
                    />
                  </div>
                  <div className='d-flex align-items-center justify-content-center mt-5 w-100'>
                    <ButtonRounded type='button' isBig={true} onClick={() => setModalDetailsProductStock(false)}>
                      Voltar
                    </ButtonRounded>
                  </div>
                </div>
              </form>
            </Modal>

            <Modal
              message={messageStockAdjustment}
              successMessage={successStockAdjustment}
              show={modalStockAdjusment}
              handleClose={() => setModalStockAdjustment(!modalStockAdjusment)}
            >
              <form
                action=''
                onSubmit={saveStockAdjustment}
                method='post'
                className='form_modal form_modal_add_stock_adjustment_reason'
              >
                <div className='d-flex flex-column w-100'>
                  <h3 className='modal_title text-center'>Ajustar estoque - {productDetail.titulo}</h3>
                  <div className='d-flex w-100' style={{ gap: 20 }}>
                    <img
                      src={`${dirUsuFotoPrincipal}/${productDetail.fotoCapa}`}
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        borderRadius: 6,
                        width: '50%',
                        objectFit: 'cover',
                      }}
                    />
                    <div className='d-flex flex-column w-100'>
                      <div className='d-flex align-items-start flex-column'>
                        <label className='card_details_label'>*Tamanho</label>
                        <Input
                          value={productStockAdjustment.size_product}
                          onChange={(e) => setTitleStockAdjustmentReason(e.target.value)}
                          className='form-control'
                          name='sizeProductStockAdjustument'
                          disabled
                          placeholder=''
                        />
                      </div>
                      <div className='d-flex align-items-start flex-column'>
                        <label className='card_details_label'>*Quantidade disponivel</label>
                        <Input
                          disabled
                          value={productStockAdjustment.amount}
                          className='form-control'
                          name='amountProductStockAdjustument'
                        />
                      </div>
                      <div className='d-flex align-items-start flex-column'>
                        <label className='card_details_label'>*Nova quantidade em mãos</label>
                        <Input
                          value={newQuantity}
                          type='number'
                          required
                          onChange={(e) => setNewQuantity(e.target.value)}
                          className='form-control'
                          name='newQuantityStockAdjustument'
                          placeholder='000'
                        />
                      </div>
                      <div className='d-flex align-items-start flex-column'>
                        <label className='card_details_label'>*Quantidade ajustada</label>
                        <Input
                          value={adjustedAmount}
                          required
                          onChange={(e) => setAdjustedAmount(e.target.value)}
                          className='form-control'
                          name='adjustedAmount'
                          placeholder='000'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex align-items-start flex-column'>
                    <div className='form_group'>
                      <label htmlFor='descript' className='card_details_label'>
                        Escolha um motivo
                      </label>
                      <Select
                        required
                        value={stockAdjustmentReasoniD}
                        onChange={(e) => {
                          if (e.target.value === 'add_stock_reason') {
                            setModalStockAdjusmentReason(true);
                            // setModalStockAdjustment(false);
                          } else {
                            setStockAdjustmentReasoniD(e.target.value);
                          }
                        }}
                        name='stockAdjustmentReason'
                      >
                        <option value={''}>Escolha um motivo</option>
                        {stockAdjustmentReason.map((typeService) => (
                          <option key={`e${typeService.id}`} value={typeService.id}>
                            {typeService.title}
                          </option>
                        ))}
                        <option style={{ color: 'var(--main-color)', fontSize: '18px' }} value='add_stock_reason'>
                          + Adicionar um motivo
                        </option>
                      </Select>
                    </div>
                  </div>
                  <div className='d-flex align-items-start flex-column'>
                    <label className='card_details_label'>*{t('Description')}</label>
                    <Textarea
                      required
                      value={descriptionAdjstumentStock}
                      onChange={(e) => setDescriptionAdjstumentStock(e.target.value)}
                      className='form-control'
                      name='adjustedAmount'
                      placeholder='Max 500 caracteres'
                    />
                  </div>
                  <div className='d-flex align-items-center justify-content-between mt-5 w-100'>
                    <ButtonRounded
                      variant='outline'
                      color='black'
                      className='w-100'
                      type='button'
                      isBig={true}
                      onClick={() => setModalStockAdjustment(false)}
                    >
                      Cancelar
                    </ButtonRounded>
                    <ButtonRounded className='w-100 ml-2' isBig={true}>
                      Salvar
                    </ButtonRounded>
                  </div>
                </div>
              </form>
            </Modal>

            <Modal
              modalContentStyle={{ maxHeight: '200px' }}
              message={messageStockAdjustmentReason}
              successMessage={successStockAdjustmentReason}
              size={'sm'}
              show={modalStockAdjusmentReason}
              handleClose={() => setModalStockAdjusmentReason(!modalStockAdjusmentReason)}
            >
              <form
                action=''
                onSubmit={saveStockAdjustmentReason}
                method='post'
                className='form_modal form_modal_add_stock_adjustment_reason'
              >
                <div className='d-flex flex-column w-100'>
                  <h3 className='modal_title text-center'>Adicionar motivo</h3>
                  <div className='d-flex align-items-start flex-column mt-4'>
                    <label className='card_details_label'>*Nome do motivo</label>
                    <Input
                      required
                      value={titleStockAdjustmentReason}
                      onChange={(e) => setTitleStockAdjustmentReason(e.target.value)}
                      className='form-control'
                      name='typeService'
                      placeholder='Ex: Roubo/Furto'
                    />
                  </div>
                  <div className='d-flex align-items-center justify-content-between mt-4 w-100'>
                    <ButtonRounded
                      variant='outline'
                      color='black'
                      type='button'
                      isBig={true}
                      onClick={() => setModalStockAdjusmentReason(false)}
                    >
                      Cancelar
                    </ButtonRounded>
                    <ButtonRounded isLoading={sendSaveStockAdjusmentReason} isBig={true}>
                      Salvar
                    </ButtonRounded>
                  </div>
                </div>
              </form>
            </Modal>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Products;
