import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import CKEditorDocument from '../../components/school/EditorRich';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import { useTranslation } from 'react-i18next';
import Message from '../../components/Message';
import { AddCustomContract } from '../../services/CustomContractService';
import Api from '../../utils/api';
import axios from 'axios';
import Loading from '../../components/Loading';
import Utils from '../../utils/utils';

const AddContractSchool = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState();
  const [data, setData] = useState('');
  const [error, setError] = useState(false);
  const [success, setSucess] = useState(false);
  const [school_id, setSchoolID] = useState('');
  const [process, setProcess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  const { scrollTop } = Utils();
  const modalScroll = useRef(null);

  const baseURL = Api();

  useEffect(() => {
    async function getSession() {
      let schoolSession = await localStorage.getItem('school');
      schoolSession = JSON.parse(schoolSession);
      setSchoolID(schoolSession.id);
      getContract(schoolSession.id);
    }

    getSession();
  }, []);

  const addCustomContract = async () => {
    if (data !== '' && data !== '<p></p>') {
      setError(false);
      setLoading(true);
      const response = await AddCustomContract(school_id, data);
      setLoading(false);
      if (response.status === 'SUCCESS') {
        setSucess(true);
        setError(false);
        setMessage(response.messageKey);
      } else {
        setError(true);
        setSucess(false);
        setMessage('SystemMessage.Fail.ContratEmpty');
      }
    } else {
      setMessage('SystemMessage.Fail.ContratEmpty');
      setError(true);
    }

    scrollTop(modalScroll);
  };

  async function getContract(school) {
    setProcess(true);
    const { data } = await axios.get(baseURL + `get-contract-custom/${school}`);
    if (data.customContract) {
      setData(data.customContract.html);
    }
    setProcess(false);
  }

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel' ref={modalScroll}>
              <div className='card'>
                <div className='card_header mb-2' style={{ alignItems: 'center', width: '100%' }}>
                  <h3>{t('StudentContractsPage.CustomContractCard.title')}</h3>
                  {success && (
                    <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message)} />
                  )}
                  {error && (
                    <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                  )}
                  {process && (
                    <p className='loading-card'>
                      <Loading type='1' />
                    </p>
                  )}
                  {!process && (
                    <>
                      <div className='w-100 d-flex justify-content-center mt-5' style={{ width: '100%' }}>
                        <CKEditorDocument initialValue={data} handleEditorData={(e) => setData(e)} />
                      </div>
                      <ButtonRounded
                        onClick={() => addCustomContract()}
                        type='submit'
                        style={{ width: '200px', height: '40px', marginTop: 10 }}
                      >
                        {loading && <Loading type='1' style={{ marginLeft: '2%' }} />}
                        {!loading && t('Save')}
                      </ButtonRounded>
                    </>
                  )}
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default AddContractSchool;
