import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRouter from './components/login/PrivateRouter';
import PrivateRouterAdmin from './components/login/PrivateRouterAdmin';
import { languages } from './i18nextConf';
import { useTranslation } from 'react-i18next';
import { getEnvironment } from './utils/api';

/**
 * Pages
 */
import Error404 from './pages/Error404';
import Error403 from './pages/Error403';
import Login from './pages/login/Login';
import RecoveryPassword from './pages/login/RecoveryPassword';
import RegisterUser from './pages/login/RegisterUser';
import RegisterCompleteUser from './pages/login/RegisterCompleteUser';
import HomeAthlete from './pages/athlete/HomeAthlete';
import SeletivasHome from './pages/athlete/SeletivasHome';
import EventsHome from './pages/athlete/EventsHome';
import Curriculo from './pages/athlete/Curriculo';
import AgendaAthlete from './pages/athlete/AgendaAthlete';
import DetalhesSeletivaEvento from './pages/athlete/DetalhesSeletivaEvento';
import PagamentoInscricao from './pages/athlete/PagamentoInscricao';
import Vestiario from './pages/athlete/Vestiario';
import Photosalbum from './pages/athlete/Photosalbum';
import Photos from './pages/athlete/Photos';
import SeuCarrinho from './pages/athlete/SeuCarrinho';
import PagamentoFotos from './pages/athlete/PagamentoFotos';
import PhotosAthlete from './pages/athlete/PhotosAthlete';
import Content from './pages/athlete/Content';
import Sign from './pages/athlete/Sign/';
import PagamentoAssinatura from './pages/athlete/PagamentoAssinatura';
import LoginSchool from './pages/school/Login';
import RecoveryPasswordSchool from './pages/school/RecoveryPassword';
import FinishRecoveryPasswordSchool from './pages/school/FinishPasswordRecovery';
import EditPasswordSchool from './pages/school/EditPassword';
import DetailEmployee from './pages/school/DetailEmployee';
import DetailStudent from './pages/school/DetailStudent';
import Employee from './pages/school/Employee';
import Supplier from './pages/school/Supplier';
import Student from './pages/school/Student';
import Class from './pages/school/classes/Class';
import DetailClass from './pages/school/DetailClass';
import Communication from './pages/school/Communication';
import ContractsStudent from './pages/school/ContractsStudent';
import ContractsAthlete from './pages/school/ContractsAthlete';
import Financial from './pages/school/Financial';
import Reports from './pages/school/Reports';
import ReportsNew from './pages/school/ReportsNew';
import Frequency from './pages/school/classes/Frequency';
import AdvantageClubHome from './pages/athlete/AdvantageClub/pages/home/Home';
import Rescue from './pages/athlete/AdvantageClub/pages/resgate/Resgate';
import Ransomed from './pages/athlete/AdvantageClub/pages/resgatados/Resgatados';
import Expire from './pages/athlete/AdvantageClub/pages/expirar/Expirar';
import Extract from './pages/athlete/AdvantageClub/pages/extrato/Extrato';
import HomeStudent from './pages/school/HomeStudent';
import FrequencyStudent from './pages/athlete/FrequencyStudent';
import FinancialStudent from './pages/school/FinancialStudent';
import DashboardClub from './pages/club/Dashboard';
import SchoolClub from './pages/club/School';
import DetailSchool from './pages/club/DetailSchool';
import ContractsSchool from './pages/club/ContractsSchool';
import FinancialSchool from './pages/club/Financial';
import ReportsClub from './pages/club/Reports';
import EvaluationClub from './pages/club/Evaluation';
import EvaluationSchool from './pages/school/Evaluation';
import EvaluationFormSchool from './pages/school/EvaluationForm';
import EvaluationListSchool from './pages/school/EvaluationList';
import EvaluationDetail from './pages/school/EvaluationDetail';
import EmployeeClub from './pages/club/Employee';
import EmployeeClubDetail from './pages/club/DetailEmployee';
import DocsSchool from './pages/school/DocsSchool';
import StudentLinkCreate from './pages/school/StudentLinkCreate';
import StudentShortLinkCreate from './pages/school/StudentShortLinkCreate';
import CommunicationClub from './pages/club/Communication';
import Products from './pages/school/Products';
import ClubProducts from './pages/club/Products';
import ClubPurchaseOrder from './pages/club/PurchaseOrder';
import ClubOrders from './pages/club/Orders';
import ClubStockAdjustment from './pages/club/StockAdjustment';

import ListProducts from './pages/athlete/Products';
import MyCart from './pages/athlete/MyCart';
import MyCartPayment from './pages/athlete/MyCartPayment';
import MyOrder from './pages/athlete/MyOrder';
import MyRequest from './pages/school/MyRequest';
import Questionnaire from './pages/club/Questionnaire';
import ViewControl from './pages/school/ViewControl';
import LoadingTheme from './components/LoadingTheme';
import Documents from './pages/athlete/Documents';
import HomeAdmin from './pages/admin/Home';
import AccountsAsaas from './pages/admin/AccountsAsaas';
import ClubList from './pages/admin/Club';
import CourseList from './pages/admin/Course';
import CoursesSchool from './pages/school/Courses';
import ExercisesSchool from './pages/school/Exercises';
import ExerciseList from './pages/admin/Exercise';
import ClassFrequencyReports from './pages/school/ClassFrequencyReports';
import FinancialReports from './pages/school/FinancialReports';
import MandatoryDocuments from './pages/school/MandatoryDocuments';
import SchoolDocuments from './pages/club/SchoolDocuments';
import CashFlowReports from './pages/school/CashFlowReports';
import Lesson from './pages/club/Lesson';
import SchoolLessons from './pages/school/classes/Lessons';
import SchoolLessonsPlanning from './pages/school/classes/LessonsPlanning';
import StudentLessons from './pages/athlete/Lessons';
import AddContractSchool from './pages/school/AddContractSchool';
import { Alerta } from './components/Alerta';
import CreateClass from './pages/school/classes/CreateClass';
import ClasseReport from './pages/school/ClasseReports';
import LogsView from './pages/admin/LogsView';
import SportsCategories from './pages/admin/SportsCategories';
import FormSelect from './pages/admin/Modals/SportCategories/FormSelect';
import SchoolSportsCategories from './pages/school/SchoolSportsCategories';
import Sidebar from './components/sidebar/Sidebar';

import 'react-toastify/dist/ReactToastify.css';
import CreateSports from './pages/admin/CreateSports';
import PurchaseOrder from './pages/school/PurchaseOrder';
import Users from './pages/admin/Users';
import StockAdjustment from './pages/school/StockAdjustment';
import NewStudent from './pages/Students/NewStudent';
import Details from './pages/Students/Details';
import InvoiceRevenue from './pages/school/Finacial/InvoiceRevenue';
import ContractMulti from './pages/school/Contracts/ContractMulti';
import GeneralFrequency from './pages/school/GeneralFrequency';
import MyOrderClub from './pages/school/MyOrderClub';
import ProductsList from './pages/school/ProductsList';
import InvoiceCredit from './pages/invoice/InvoiceCredit';

import SchoolCart from './pages/school/MyCart';
import SchoolCartPayment from './pages/school/MyCartPayment';
import Methodology from './pages/club/Methodology';
import Planning from './pages/club/Planning';
import Microcycle from './pages/school/classes/Microcycle';
import HomeBasic from './pages/school/HomeBasic';
import BuyCredit from './pages/invoice/BuyCredit';
import CreditsPage from './pages/admin/CreditsPage';
import Benefit from './pages/club/Benefits';
import SignatureCredit from './pages/signature/SignatureCredit';
import BuySignatureCredit from './pages/signature/BuySignatureCredit';
import List  from './pages/school/PricingPolicies/List';
import ContractForm from './pages/school/PricingPolicies/ContractForm';
import InvoiceRevenueForm from './pages/school/PricingPolicies/InvoiceRevenueForm';

import AdminLesson from './pages/admin/Lesson';
import AdminPlanning from './pages/admin/Planning';
import AdminMicrocycle from './pages/admin/Microcycle';

const Routes = () => {
  const { i18n } = useTranslation();

  return (
    <>
      <Alerta />
      <LoadingTheme />
      {getEnvironment() === 'DEV' && (
        <div style={{ position: 'fixed', top: 0, right: 0, zIndex: 2000, maxWidth: 95 }}>
          <select
            className='login_link select-link text-transform-none'
            value={i18n.resolvedLanguage}
            onChange={(e) => i18n.changeLanguage(e.target.value)}
            style={{ marginTop: '5px', color: 'white', backgroundColor: 'black' }}
          >
            {Object.keys(languages).map((language) => (
              <option key={language + '_options'} value={language}>
                {languages[language].nativeName}
              </option>
            ))}
          </select>
        </div>
      )}
      <Router>
        <Switch>
          <PrivateRouter path='/my-request' exact component={MyRequest} />
          <PrivateRouter path='/my-order' exact component={MyOrder} />
          <PrivateRouter path='/my-cart-payment' exact component={MyCartPayment} />
          <PrivateRouter path='/my-cart' exact component={MyCart} />
          <PrivateRouter path='/school-my-cart' exact component={SchoolCart} />
          <PrivateRouter path='/school-cart-payment' exact component={SchoolCartPayment} />
          <PrivateRouter path='/list-products' exact component={ListProducts} />
          <PrivateRouter path='/pagamento-assinatura' exact component={PagamentoAssinatura} />
          <PrivateRouter path='/home-athlete' exact component={HomeAthlete} />
          <PrivateRouter path='/seletivas-athlete' exact component={SeletivasHome} />
          <PrivateRouter path='/events-athlete' exact component={EventsHome} />
          <PrivateRouter path='/curriculo-athlete' exact component={Curriculo} />
          <PrivateRouter path='/agenda-athlete' exact component={AgendaAthlete} />
          <PrivateRouter path='/detalhes-seletiva-evento' exact component={DetalhesSeletivaEvento} />
          <PrivateRouter path='/pagamento-inscricao' exact component={PagamentoInscricao} />
          <PrivateRouter path='/seu-carrinho' exact component={SeuCarrinho} />
          <PrivateRouter path='/pagamento-fotos' exact component={PagamentoFotos} />
          <PrivateRouter path='/photos-athlete' exact component={PhotosAthlete} />
          <PrivateRouter path='/content-athlete' exact component={Content} />
          <PrivateRouter path='/home-school' component={ReportsNew} />
          <PrivateRouter path='/home-basic' component={HomeBasic} />
          <PrivateRouter path='/detail-employee' exact component={DetailEmployee} />
          <PrivateRouter path='/detail-student/:idStudent' exact component={DetailStudent} />
          <PrivateRouter path='/view-student/:id' exact component={Details} />
          <PrivateRouter path='/employee-school' exact component={Employee} />
          <PrivateRouter path='/supplier-school' exact component={Supplier} />
          <PrivateRouter path='/general-frequency-school' exact component={GeneralFrequency} />
          <PrivateRouter path='/student-school' exact component={Student} />
          <PrivateRouter path='/class-school' exact component={Class} />
          <PrivateRouter path='/school-lessons' exact component={SchoolLessons} />
          <PrivateRouter path='/school-lessons-planning' exact component={SchoolLessonsPlanning} />
          <PrivateRouter path='/detail-class' exact component={DetailClass} />
          <PrivateRouter path='/class-frequency-reports' exact component={ClassFrequencyReports} />
          <PrivateRouter path='/communication-school' exact component={Communication} />
          <PrivateRouter path='/communication-athlete-school' exact component={Communication} />

          <PrivateRouter path='/frequency-school' exact component={Frequency} />
          <PrivateRouter path='/contracts-student-school' exact component={ContractsStudent} />
          <PrivateRouter path='/financial-school' exact component={Financial} />
          <PrivateRouter path='/financial-school-reports' exact component={FinancialReports} />
          <PrivateRouter path='/cash-flow-reports' exact component={CashFlowReports} />
          <PrivateRouter path='/reports-school' exact component={Reports} />
          <PrivateRouter path='/sidebar' exact component={Sidebar} />
          <PrivateRouter path='/advantage-club' exact component={AdvantageClubHome} />
          <PrivateRouter path='/rescue' exact component={Rescue} />
          <PrivateRouter path='/ransomed' exact component={Ransomed} />
          <PrivateRouter path='/expire' exact component={Expire} />
          <PrivateRouter path='/extract' exact component={Extract} />
          <PrivateRouter path='/home-student-school' exact component={HomeStudent} />
          <PrivateRouter path='/frequency-student-school' exact component={FrequencyStudent} />
          <PrivateRouter path='/financial-student-school' exact component={FinancialStudent} />
          <PrivateRouter path='/home-club' exact component={DashboardClub} />
          <PrivateRouter path='/school-club' exact component={SchoolClub} />
          <PrivateRouter path='/detail-school' exact component={DetailSchool} />
          <PrivateRouter path='/contract-school' exact component={ContractsSchool} />
          <PrivateRouter path='/financial-club' exact component={FinancialSchool} />
          <PrivateRouter path='/report-club' exact component={ReportsClub} />
          <PrivateRouter path='/evaluation-club' exact component={EvaluationClub} />
          <PrivateRouter path='/evaluation-school' exact component={EvaluationSchool} />
          <PrivateRouter path='/evaluation-form-school' exact component={EvaluationFormSchool} />
          <PrivateRouter path='/evaluation-list-school' exact component={EvaluationListSchool} />
          <PrivateRouter path='/evaluation-detail' exact component={EvaluationDetail} />
          <PrivateRouter path='/employee-club' exact component={EmployeeClub} />
          <PrivateRouter path='/detail-employee-club' exact component={EmployeeClubDetail} />
          <PrivateRouter path='/docs-school' exact component={DocsSchool} />
          <PrivateRouter path='/products' exact component={Products} />
          <PrivateRouter path='/purchase-order' exact component={PurchaseOrder} />
          <PrivateRouter path='/stock-adjustment' exact component={StockAdjustment} />
          <PrivateRouter path='/communication-club' exact component={CommunicationClub} />
          <PrivateRouter path='/questionnaire-club' exact component={Questionnaire} />
          <PrivateRouter path='/view-control' exact component={ViewControl} />
          <PrivateRouter path='/documents-athlete' exact component={Documents} />
          <PrivateRouter path='/contracts-athlete' exact component={ContractsAthlete} />
          <PrivateRouter path='/courses-school' exact component={CoursesSchool} />
          <PrivateRouter path='/exercises-school' exact component={ExercisesSchool} />
          <PrivateRouter path='/mandatory-docs-school' exact component={MandatoryDocuments} />
          <PrivateRouter path='/club-school-documents' exact component={SchoolDocuments} />
          <PrivateRouter path='/club-lesson' exact component={Lesson} />
          <PrivateRouter path='/student-lessons' exact component={StudentLessons} />
          <PrivateRouter path='/add-contract-school' exact component={AddContractSchool} />
          <PrivateRouter path='/new-class-school' exact component={CreateClass} />
          <PrivateRouter path='/reports-class-school' exact component={ClasseReport} />
          <PrivateRouter path='/sport-categories-school' exact component={SchoolSportsCategories} />
          <PrivateRouter path='/student-create-wizard' exact component={NewStudent} />
          <PrivateRouter path='/student-edit/:id' exact component={NewStudent} />
          <PrivateRouter path='/finacial/create-invoice-revenue' exact component={InvoiceRevenue} />
          <PrivateRouter path='/contract/create-mass' exact component={ContractMulti} />

          <PrivateRouter path='/club-benefits' component={Benefit} />
          <PrivateRouter path='/club-products' exact component={ClubProducts} />
          <PrivateRouter path='/club-purchase-order' exact component={ClubPurchaseOrder} />
          <PrivateRouter path='/club-orders' exact component={ClubOrders} />
          <PrivateRouter path='/club-stock-adjustment' exact component={ClubStockAdjustment} />
          <PrivateRouter path='/school-purchase-orders-club' exact component={MyOrderClub} />
          <PrivateRouter path='/school-list-club-products' exact component={ProductsList} />
          <PrivateRouter path='/standardized-plans' exact component={List} />
          <PrivateRouter path='/standardized-plans/create-contract' exact component={ContractForm} />
          <PrivateRouter path='/standardized-plans/create-invoice-revenue' exact component={InvoiceRevenueForm} />

          <Route path='/student-create/:companie_id/:school_id' exact component={StudentLinkCreate} />
          <Route path='/student-create-short/:companie_id/:school_id' exact component={StudentShortLinkCreate} />
          <Route path='/sign' exact component={Sign} />
          <Route path='/Photosalbum' exact component={Photosalbum} />
          <Route path='/Photos/:id' exact component={Photos} />
          <Route path='/vestiario/:user_id' exact component={Vestiario} />
          <Route path='/school' exact component={LoginSchool} />
          <Route path='/login-school' exact component={LoginSchool} />
          <Route path='/recovery-password-school' component={RecoveryPasswordSchool} />
          <Route path='/finish-recovery-password-school/:user_id' component={FinishRecoveryPasswordSchool} />
          <Route path='/edit-password-school' exact component={EditPasswordSchool} />
          <Route path='/login' exact component={Login} />
          <Route path='/register-user' exact component={RegisterUser} />
          <Route path='/register-complete-user' exact component={RegisterCompleteUser} />
          <Route path='/recovery-password' exact component={RecoveryPassword} />
          <PrivateRouter path='/invoice-credit' exact component={InvoiceCredit} />
          <PrivateRouter path='/buy-invoice-credit' exact component={BuyCredit} />
          <PrivateRouter path='/signature-credit' exact component={SignatureCredit} />
          <PrivateRouter path='/buy-signature-credit' exact component={BuySignatureCredit} />
          <PrivateRouterAdmin path='/home-admin' exact component={HomeAdmin} />
          <PrivateRouterAdmin path='/account-asaas' exact component={AccountsAsaas} />
          <PrivateRouterAdmin path='/list-club' exact component={ClubList} />
          <PrivateRouterAdmin path='/credits' exact component={CreditsPage} />
          <PrivateRouterAdmin path='/list-courses' exact component={CourseList} />
          <PrivateRouterAdmin path='/list-exercises' exact component={ExerciseList} />
          <PrivateRouterAdmin path='/sports-categories' exact component={SportsCategories} />
          <PrivateRouterAdmin path='/sports-categories/append-sports' exact component={FormSelect} />
          <PrivateRouterAdmin path='/sports-categories/create-sports' exact component={CreateSports} />
          <PrivateRouterAdmin path='/show-logs' exact component={LogsView} />
          <PrivateRouterAdmin path='/list-users' exact component={Users} />
          <PrivateRouter path='/club-methodology' exact component={Methodology} />
          <PrivateRouter path='/club-planning' exact component={Planning} />
          <PrivateRouter path='/school-microcycle' exact component={Microcycle} />
          <PrivateRouterAdmin path='/admin-lesson' exact component={AdminLesson} />
          <PrivateRouterAdmin path='/admin-planning' exact component={AdminPlanning} />
          <PrivateRouterAdmin path='/admin-microcycle' exact component={AdminMicrocycle} />
          <Route path='/' exact component={Login} />
          <Route path='/nao-autorizado' exact component={Error403} />
          <Route path='*' component={Error404} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
