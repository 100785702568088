import React from 'react';
import { useTranslation } from 'react-i18next';

const Loading = ({ type = 1, style, ...props }) => {
  const { t } = useTranslation();

  if (type == 1) {
    return (
      <span {...props} style={style} className='spinner-border spinner-border-sm' role='status'>
        <span className='sr-only'>Loading...</span>
      </span>
    );
  } else if (type == 2) {
    return (
      <span {...props} style={style} className='spinner-grow spinner-grow-sm' role='status'>
        <span className='sr-only'>Loading...</span>
      </span>
    );
  } else if (type == 3) {
    return (
      <span {...props} style={style} className='spinner-border' role='status'>
        <span className='sr-only'>Loading...</span>
      </span>
    );
  } else if (type == 4) {
    return (
      <span {...props} style={style} className='spinner-grow' role='status'>
        <span className='sr-only'>Loading...</span>
      </span>
    );
  } else if (type == 5) {
    return (
      <span {...props} style={style} className='spinner-grow' role='status'>
        <span className='sr-only'>Loading...</span>
      </span>
    );
  } else if (type == 6) {
    return (
      <div
        style={{
          zIndex: 2,
          position: 'absolute',
          background: 'rgba(255, 255, 255, 0.8)',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', ...style }}>
          <p>{t('SystemMessage.Loading.Wait')}&nbsp;</p>
          <span {...props} className='spinner-border spinner-border-sm' role='status'>
            <span className='sr-only'>Loading...</span>
          </span>
        </div>
      </div>
    );
  }
};

export default Loading;
