import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from '@material-ui/core';

const HeaderMenu = ({ title, icon, options }) => {
  const HeaderMenuRef = useRef(null);

  useEffect(() => {
    if (HeaderMenuRef && HeaderMenuRef.current) {
      document.addEventListener('click', closeOnFocusOut);
      return () => {
        document.removeEventListener('click', closeOnFocusOut);
      };
    }
  }, []);

  const closeOnFocusOut = (event) => {
    if (!HeaderMenuRef.current.contains(event.target)) toggleDrop(false);
  };

  const [showDrop, setShowDrop] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const toggleDrop = (dropStatus) => {
    if (dropStatus) {
      setShowDrop(true);
      setTimeout(() => {
        setShowDrop((current) => {
          setShowOptions(current);
          return current;
        });
      }, 100);
    } else {
      setShowOptions(false);
      setTimeout(() => {
        setShowOptions((current) => {
          setShowDrop(current);
          return current;
        });
      }, 250);
    }
  };

  return (
    <div className='dropdown' ref={HeaderMenuRef}>
      <button
        className='btn_header'
        style={{ borderRadius: showDrop ? '15px 15px 0px 0px' : '50px', transition: 'all 0.1s linear' }}
        onClick={() => toggleDrop(!showDrop)}
      >
        {icon}
        {title}
      </button>

      <div
        className={`drop ${showOptions ? 'open' : 'close'}`}
        style={{ display: showDrop ? 'block' : 'none', paddingBottom: showOptions ? '' : 0, zIndex: 1 }}
        tabIndex='-1'
      >
        <Collapse in={showOptions}>
          {options.map((option, i) => (
            <div
              style={{ color: 'white', textDecoration: 'none' }}
              className='item_drop'
              key={`menu_drop_${title} + ${option.title || i}`}
              onClick={(e) => {
                option.onClick && option.onClick(e);
                toggleDrop(false);
              }}
            >
              {option.type === 'LINK' && (
                <Link style={{ color: 'var(--a)', textDecoration: 'none' }} to={`/${option.to}`} >
                  {option.icon}
                  {option.title}
                </Link>
              )}
              {option.type === 'SPAN' && (
                <>
                  {option.icon}
                  {option.title}
                </>
              )}
              {option.type === 'COMPONENT' && option.component}
            </div>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default HeaderMenu;
