import React from 'react';
import { getNestedPropByIndex } from '../../utils/utils';

const TextareaRounded = ({
  label,
  name,
  value,
  onChange,
  style,
  styleContainer,
  styleWrapper,
  className,
  classNameContainer,
  classNameWrapper,
  iconPrefix,
  placeholder,
  ...props
}) => {
  return (
    <div className={'form_group ' + (classNameContainer || '')} style={{ alignItems: 'center', ...styleContainer }}>
      <label className='card_details_label' style={{ fontSize: '1rem', margin: '0 15px' }}>
        {label}
      </label>
      <label
        className={`w-100 input-rounded position-relative ${classNameWrapper || ''}`}
        style={{ overflow: 'hidden', ...styleWrapper }}
      >
        {iconPrefix && (
          <span
            className='position-absolute d-flex align-items-center'
            style={{ marginRight: '8px', top: 0, bottom: 0 }}
          >
            {iconPrefix}
          </span>
        )}
        <textarea
          {...props}
          className={`form_control ${iconPrefix ? 'pl-4' : ''} ${className || ''}`}
          name={name}
          value={typeof value === 'object' && name ? getNestedPropByIndex(value, name) : value}
          onChange={onChange}
          style={{ border: 'none', marginTop: 0, ...style }}
        >
          {placeholder}
        </textarea>
      </label>
    </div>
  );
};

export default TextareaRounded;
