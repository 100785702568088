import React from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';

/**
 *
 * @param {{
 *  title: string;
 *  selectAllLabel?: string;
 *  checkboxLabel: string;
 *  isLoading: boolean;
 *  options: ({label: string} & Record<string, any>)[];
 *  selectedOptions: any[];
 *  disableSelectAll: boolean;
 *  checker: (checked: boolean, option: ({label: string} & Record<string, any>)) => boolean;
 *  fillSelectedOptions: (checked: boolean) => boolean;
 *  setDefaultValue?: (option: ({label: string} & Record<string, any>)) => boolean;
 *  onChangeSelection: (checked: boolean, option: ({label: string} & Record<string, any>)) => any;
 *  disableOption?: (option: ({label: string} & Record<string, any>)) => boolean;
 * }} props
 * @returns {JSX.Element}
 */
const TableWithSelection = ({
  title,
  selectAllLabel,
  checkboxLabel,
  isLoading,
  options,
  selectedOptions,
  disableSelectAll,
  checker,
  fillSelectedOptions,
  setDefaultValue,
  onChangeSelection,
  disableOption,
}) => {
  const listCSS = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    background: '#ffffff',
    boxShadow: '0px 5px 15px rgb(0 0 0 / 15%)',
    borderRadius: '0px 0px 10px 10px',
  };
  const headerCSS = {
    display: 'flex',
    width: '100%',
    boxShadow: '0px 0px 10px rgb(0 0 0 / 15%)',
    borderRadius: '10px 10px 0px 0px',
    borderBottom: 0,
    color: '#000',
  };
  const headerLabelCSS = {
    margin: 0,
    boxSizing: 'border-box',
    width: '-webkit-fill-available',
    padding: '15px',
    fontSize: '16px',
    borderRight: '1px solid #e5e5e5',
  };
  const headerActionCSS = {
    margin: 0,
    boxSizing: 'border-box',
    width: '220px',
    padding: '15px',
    fontSize: '16px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
  };
  const rowCSS = {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #e5e5e5',
    color: '#747272',
    justifyContent: 'start',
    alignItems: 'center',
  };
  const rowLabelCSS = {
    color: '#747272',
    boxSizing: 'border-box',
    background: 'none',
    width: '-webkit-fill-available',
    padding: '15px',
    borderRight: '1px solid #e5e5e5',
    display: 'flex',
    alignItems: 'center',
  };
  const rowActionCSS = {
    color: '#747272',
    boxSizing: 'border-box',
    background: 'none',
    width: '220px',
    padding: '15px',
    borderRight: '1px solid #e5e5e5',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  };

  const { t } = useTranslation();

  return (
    <div style={listCSS}>
      <div style={headerCSS}>
        <span style={headerLabelCSS}>{title}</span>
        <div style={headerActionCSS}>
          <label className='lowercase flex-center' style={{ margin: '0px', fontSize: '0.80rem' }}>
            <input
              type='checkbox'
              checked={options.length === selectedOptions.length}
              onChange={(e) => fillSelectedOptions(e.target.checked)}
              disabled={disableSelectAll}
              style={{ marginRight: '5px' }}
            />
            {selectAllLabel || t('MarkAll_question')}
          </label>
        </div>
      </div>
      {isLoading && (
        <p className='loading-card' style={{ margin: '5px' }}>
          <Loading type='1' />
        </p>
      )}
      {!isLoading &&
        options.map((option, i) => (
          <div key={`TableWithSelection_Option_${i}`} style={rowCSS}>
            <span style={rowLabelCSS}>{option.label}</span>
            <div style={rowActionCSS}>
              <label className='lowercase flex-center' style={{ margin: '0px', fontSize: '0.80rem' }}>
                <input
                  type='checkbox'
                  checked={checker(option)}
                  onChange={(e) => onChangeSelection(e.target.checked, option)}
                  defaultValue={setDefaultValue ? setDefaultValue(option) : undefined}
                  disabled={disableOption ? disableOption(option) : undefined}
                  style={{ marginRight: '5px' }}
                />
                {checkboxLabel}
              </label>
            </div>
          </div>
        ))}
    </div>
  );
};

export default TableWithSelection;
