import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { StudentFormContext } from '../../../Context/StudentFormContext';

const cycleValues = {
  YEARLY: 12,
  SEMIANNUALLY: 6,
  QUARTERLY: 3,
  MONTHLY: 1,
};

const insuranceValues = {
  NONE: 0,
  PLAN_A: 1.99,
  PLAN_B: 2.99,
  PLAN_C: 3.99,
};
// TODO: usar o intl para traduzir os textos
const Mensal = ({ title = 'Informações Aluno', useForm, id }) => {
  const context = React.useContext(StudentFormContext);

  const [services, setServices] = React.useState([]);

  const { t } = useTranslation();

  const { state, handleOnChange, setState } = useForm;

  React.useEffect(() => {
    let isMounted = true;
    const requestContracts = async () => {
      if (isMounted) {
        const contracts = await context.request(`/get-contract-services/${context.school.id}`);
        setServices(contracts.contractServices);
      }
    };
    requestContracts();
    return () => {
      isMounted = false;
    };
  }, []);

  React.useEffect(() => {
    const cycleToNumber = cycleValues[state.paymentCycle.value] || 1;
    const insuranceValue = insuranceValues[state.seguro.value] || 0;
    const valorComDesconto = cycleToNumber * state.valorOriginal.value + cycleToNumber * insuranceValue;
    setState({
      ...state,
      valorComSeguro: { value: valorComDesconto, error: '' },
      valor: { value: valorComDesconto, error: '' },
    });
  }, [state.paymentCycle.value, state.seguro.value, state.valorOriginal.value]);

  const isFaturado = () => state.faturado.value === 'S';

  return (
    <div className='col p-0 mx-3'>
      <div className='row'>
        <div className='col-md-4 col-sm-12 form_group'>
          <label className='card_details_label'>{t('ContractedService')} *</label>
          <select
            required
            value={state.service.value}
            className='form_control select form-control'
            onChange={handleOnChange}
            name='service'
          >
            <option value='' disabled></option>
            {services.map((item, i) => (
              <option key={i} value={item.id}>
                {item.nome}
              </option>
            ))}
          </select>
        </div>
        <div className='col-md-4 col-sm-12  form_group'>
          <label htmlFor='schedule' className='card_details_label'>
            {t('BillingCycle')} *
          </label>
          <select
            required
            className={`form_control select form-control`}
            value={state.paymentCycle.value}
            onChange={handleOnChange}
            name='paymentCycle'
            disabled={isFaturado()}
          >
            <option value='MONTHLY'>{t('Monthly')}</option>
            <option value='QUARTERLY'>{t('Quarterly')}</option>
            <option value='SEMIANNUALLY'>{t('Semiannually')}</option>
            <option value='YEARLY'>{t('Yearly')}</option>
          </select>
        </div>
        <div className='col-md-4 col-sm-12 form_group'>
          <label htmlFor='schedule' className='card_details_label'>
            {t('PaymentMethod')} *
          </label>
          <select
            required
            className={`form_control select form-control`}
            value={state.paymentMethod.value}
            onChange={handleOnChange}
            name='paymentMethod'
            disabled={isFaturado()}
          >
            <option value='' disabled></option>
            <option value='CREDIT_CARD'>{t('Card')}</option>
            <option value='BOLETO'>{t('Bill')}</option>
            <option value='MONEY'>{t('Money')}</option>
          </select>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4 col-sm-12 form_group'>
          <label className='card_details_label'>{t('MonthlyAmountWithoutDiscount')} *</label>
          <NumberFormat
            required
            className='form_control form-control'
            placeholder={t('PlaceholderMoneyZero')}
            displayType={'input'}
            name='valorOriginal'
            format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })}
            value={state.valorOriginal.value === '' ? '' : state.valorOriginal.value * 100}
            onChange={(e) => {
              e.target.value = +e.target.value.replace(/\D/g, '') / 100;
              handleOnChange(e);
            }}
            disabled={isFaturado()}
          />
        </div>
        <div className='col-md-4 col-sm-12 form_group'>
          <label className='card_details_label'>{t('FirstDueDate')} *</label>
          <input
            type='date'
            required
            name='dataVencimento'
            className='form_control form-control'
            value={state.dataVencimento.value}
            onChange={handleOnChange}
            disabled={isFaturado()}
          />
        </div>
        <div className='col-md-4 col-sm-12 form_group'>
          <label className='card_details_label'>{t('DiscountType')}</label>
          <select
            className={`form_control select form-control`}
            value={state.tipoDesconto.value}
            onChange={handleOnChange}
            name='tipoDesconto'
            disabled={isFaturado()}
          >
            <option value='NONE'>{t('NoDiscount')}</option>
            <option value='PERCENTAGE'>{t('Percentage')}</option>
            <option value='FIXED'>{t('Fixed')}</option>
          </select>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4 col-sm-12 form_group'>
          <label className='card_details_label'>{t('DiscountAmount')}</label>
          <NumberFormat
            required
            className='form_control'
            displayType={'input'}
            name='desconto'
            disabled={isFaturado()}
            placeholder={
              state.tipoDesconto.value === 'FIXED'
                ? t('PlaceholderMoneyZero')
                : state.tipoDesconto.value === 'PERCENTAGE'
                  ? t('PlaceholderPercentageZero')
                  : ''
            }
            format={(e) =>
              state.tipoDesconto.value === 'FIXED'
                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                : state.tipoDesconto.value === 'PERCENTAGE'
                  ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                  : ''
            }
            readOnly={state.tipoDesconto.value === 'NONE'}
            isAllowed={({ floatValue }) => (state.tipoDesconto.value === 'PERCENTAGE' ? floatValue < 10000 : true)}
            value={state.desconto.value * 100}
            onChange={(e) => {
              e.target.value = +e.target.value.replace(/\D/g, '') / 100;
              handleOnChange(e);
            }}
          />
        </div>
        <div className='col-sm-12 col-md-4 form_group'>
          <label className='card_details_label'>{t('LateFeeType')}</label>
          <select
            disabled={isFaturado()}
            className={`form_control select form-control`}
            value={state.tipoMulta.value}
            onChange={handleOnChange}
            name='tipoMulta'
          >
            <option value='NONE'>{t('NoLateFee')}</option>
            <option value='PERCENTAGE'>{t('Percentage')}</option>
            <option value='FIXED'>{t('Fixed')}</option>
          </select>
        </div>
        <div className='col-sm-12 col-md-4 form_group'>
          <label className='card_details_label'>{t('LateFeeAmount')}</label>
          <NumberFormat
            className='form_control form-control'
            displayType={'input'}
            name='valorMulta'
            disabled={isFaturado()}
            placeholder={
              state.tipoMulta.value === 'FIXED'
                ? t('PlaceholderMoneyZero')
                : state.tipoMulta.value === 'PERCENTAGE'
                  ? t('PlaceholderPercentageZero')
                  : ''
            }
            format={(e) =>
              state.tipoMulta.value === 'FIXED'
                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                : state.tipoMulta.value === 'PERCENTAGE'
                  ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                  : ''
            }
            readOnly={state.tipoMulta.value === 'NONE'}
            isAllowed={({ floatValue }) => (state.tipoMulta.value === 'PERCENTAGE' ? floatValue < 10000 : true)}
            value={state.valorMulta.value * 100}
            onChange={(e) => {
              e.target.value = +e.target.value.replace(/\D/g, '') / 100;
              handleOnChange(e);
            }}
          />
        </div>
      </div>

      <div className='row'>
        <div className='form_group col-md-4  col-sm-12 '>
          <label className='card_details_label'>{t('LateInterestType')}</label>
          <select
            className={`form_control select form-control`}
            value={state.tipoJuros.value}
            onChange={handleOnChange}
            name='tipoJuros'
            disabled={isFaturado()}
          >
            <option value='NONE'>{t('NoLateInterest')}</option>
            <option value='PERCENTAGE'>{t('Percentage')}</option>
            <option value='FIXED'>{t('Fixed')}</option>
          </select>
        </div>
        <div className='col-sm-12 col-md-4 form_group'>
          <label className='card_details_label'>{t('LateInterestAmount')}</label>
          <NumberFormat
            className='form_control'
            displayType={'input'}
            name='valorJuros'
            disabled={isFaturado()}
            placeholder={
              state.tipoJuros.value === 'FIXED'
                ? t('PlaceholderMoneyZero')
                : state.tipoJuros.value === 'PERCENTAGE'
                  ? t('PlaceholderPercentageZero')
                  : ''
            }
            format={(e) =>
              state.tipoJuros.value === 'FIXED'
                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                : state.tipoJuros.value === 'PERCENTAGE'
                  ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                  : ''
            }
            readOnly={state.tipoJuros.value === 'NONE'}
            isAllowed={({ floatValue }) => (state.tipoJuros.value === 'PERCENTAGE' ? floatValue < 10000 : true)}
            value={state.valorJuros.value * 100}
            onChange={(e) => {
              e.target.value = +e.target.value.replace(/\D/g, '') / 100;
              handleOnChange(e);
            }}
          />
        </div>

        <div className='col-md-4 col-sm-12 form_group'>
          <label className='card_details_label'>{t('TakeInsurance')}</label>
          <select
            required
            className={`form_control select form-control`}
            value={state.seguro.value}
            onChange={(e) => {
              handleOnChange(e);
            }}
            name='seguro'
            disabled={isFaturado()}
          >
            <option value='NONE'>{t('NoInsurance')}</option>
            <option value='PLAN_A'>{t('InsuranceTypeFormatted', { value: 1.99, coverage: 1000 })}</option>
            <option value='PLAN_B'>{t('InsuranceTypeFormatted', { value: 2.99, coverage: 2000 })}</option>
            <option value='PLAN_C'>{t('InsuranceTypeFormatted', { value: 3.99, coverage: 3000 })}</option>
          </select>
        </div>
      </div>

      <div className='row'>
        <div className='form_group col-md-4 col-sm-12 '>
          <label className='card_details_label'>{t('AmountInsurance')}</label>
          <NumberFormat
            required
            className='form_control'
            disabled={isFaturado()}
            placeholder={state.seguro.value === 'NONE' ? '' : t('PlaceholderMoneyZero')}
            displayType={'input'}
            name='valorComSeguro'
            format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })}
            value={state.valorComSeguro.value * 100}
            readOnly
          />
        </div>
        <div className='form_group col-md-4 col-sm-12 '>
          <label className='card_details_label'>{t('ContractStartDate')} *</label>
          <input
            type='date'
            required
            className='form_control'
            name='dataInicio'
            value={state.dataInicio.value}
            onChange={handleOnChange}
          />
        </div>
        <div className='form_group col-md-4 col-sm-12 '>
          <label className='card_details_label'>{t('ContractEndDate')}</label>
          <input
            type='date'
            className='form_control form-control'
            name='dataFim'
            value={state.dataFim.value || ''}
            onChange={handleOnChange}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 col-sm-12 form_group'>
          <label className='card_details_label'>{t('ContractStatus')}: *</label>
          <select
            required
            value={state.statusContrato.value}
            className='form_control select form-control'
            onChange={handleOnChange}
            name='statusContrato'
          >
            <option value='' disabled></option>
            <option value='Ativo'>{t('StatusType.Active')}</option>
            <option value='Inativo'>{t('StatusType.Inactive')}</option>
            <option value='Encerrado'>{t('StatusType.Closed')}</option>
          </select>
        </div>

        <div className='col-md-6 col-sm-12 form_group'>
          <label className='card_details_label'>
            {state.paymentMethod.value === 'MONEY' ? t('MonthlyWorkload') : t('Workload')}
          </label>
          <input
            type='time'
            placeholder='hh:mm'
            className='form_control form-control'
            value={state.cargaHora.value}
            onChange={handleOnChange}
            name='cargaHora'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 form_group'>
          <label className='card_details_label'>{t('Observations')}</label>
          <textarea
            name='observacao'
            value={state.observacao.value}
            className='form_control form-control'
            cols={30}
            rows={5}
            onChange={handleOnChange}
          ></textarea>
        </div>
      </div>
      {/*
            <div className='row'>
              <div className='col-md-12 p-4'>
                <DragDropUpload parentRef={parentRef} setState={setImages} />
                <p className='text-gray'>
                  *Apenas permitido arquivos no formato JPG, JPEG ou PNG de até no máximo 1mbs
                </p>
              </div>
            </div> */}
      {/*   <div className='row'>
          <div className='col-md-12 p-4'>
            <div className='form-check form-check-inline'>
              <BtnInfo title='Ao selecionar “sim”, um contrato será gerado automaticamente com base nas informações acima.' />
              <span className='ml-2'>Deseja criar o contrato?</span>{' '}
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='novoContrato'
                id='contrato1'
                value='1'
                onChange={handleOnChange}
              />
              <label className='form-check-label' htmlFor='contrato1'>
                {t('Yes')}
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='novoContrato'
                id='contrato2'
                value='0'
                checked={+state.novoContrato.value === 0}
                onChange={handleOnChange}
              />
              <label className='form-check-label' htmlFor='contrato2'>
                {t('Not')}
              </label>
            </div>
          </div>
        </div> */}
    </div>
  );
};

const stateSchema = {
  id: { value: 0, error: '' },
  dataVencimento: { value: '', error: '' },
  dataInicio: { value: '', error: '' },
  dataFim: { value: '', error: '' },
  paymentMethod: { value: '', error: '' },
  paymentCycle: { value: '', error: '' },
  service: { value: '', error: '' },
  seguro: { value: '', error: '' },
  tipoDesconto: { value: '', error: '' },
  desconto: { value: '', error: '' },
  valorOriginal: { value: '', error: '' },
  valorComSeguro: { value: '', error: '' },
  tipoJuros: { value: '', error: '' },
  valorJuros: { value: '', error: '' },
  tipoMulta: { value: '', error: '' },
  valorMulta: { value: '', error: '' },
  statusContrato: { value: '', error: '' },
  cargaHora: { value: '', error: '' },
  observacao: { value: '', error: '' },
  novoContrato: { value: 0, error: '' },
};

const validationStateSchema = {};

export default Mensal;
