const initialState = {
  payload: [],
  loading: false,
  selected: null,
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOAD_USER':
      return { ...state, loading: !state.loading };
    case 'SET_USER':
      return { ...state, selected: action.payload };
    case 'RESET_USER':
      return initialState;
    default:
      return state;
  }
}
