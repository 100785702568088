import React, { useState, useRef, useEffect, useContext } from 'react';
import Header from '../../../components/school/Header';
import Footer from '../../../components/school/Footer';
import { useTranslation } from 'react-i18next';
import Message from '../../../components/Message';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import Loading from '../../../components/Loading';
import { useReactToPrint } from 'react-to-print';
import { Link, useHistory } from 'react-router-dom';
import { handleOnChange } from '../../../utils/utils';
import { BsChevronDown, BsCreditCard, BsDownload, BsEye, BsFillPencilFill, BsUpload } from "react-icons/bs";
import { GiPadlock } from 'react-icons/gi';
import EditMicrocycle from './EditMicrocycle';
import { UserContext } from '../../../Context/UserContext';
import { createMicrocycle, getMicrocycleBySchoolId, updateMicrocycle, updateStatusMicrocycle } from '../../../services/MicrocycleService';
import { BiSolidCopy } from 'react-icons/bi';
import moment from 'moment';
import ViewAdminMicrocycle from './ViewAdminMicrocycle';
import PaySubscription from './PaySubscription';
import { checkSubscription } from '../../../services/MicrocyclePaymentsService';

const Microcycle = () => {
  const { t } = useTranslation();
  const { school, action, setContextData } = useContext(UserContext);
  const history = useHistory();

  const [filters, setFilters] = useState({
    name: '',
    source: 'M',
    status: 'A',
  });
  const [microcycles, setMicrocycles] = useState([])
  const [microcyclesFiltered, setMicrocyclesFiltered] = useState([])
  const [microcycle, setMicrocycle] = useState({
    id: undefined,
    name: '',
    status: 'A',
    classNumber: '',
    objective: '',
  });
  const [microcycleStartDate, setMicrocycleStartDate] = useState(null)
  const [dropDownStatus, setDropDownStatus] = useState([]);
  const [onlyView, setOnlyView] = useState(false)
  const [signatureStatus, setSignatureStatus] = useState()
  const [modalStartCopyMicrocycle, setModalStartCopyMicrocycle] = useState(false)
  const [showPaySubscriptionModal, setShowPaySubscriptionModal] = useState(false)
  const [onUpdate, setOnUpdate] = useState(false);

  // process
  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  // libraries
  const editMicrocycleRef = useRef();
  const microcyclePdfRef = useRef();
  const alertRef = useRef();
  const viewAdminMicrocycleRef = useRef();
  const microcycleAdminPdfRef = useRef();
  // const paySubscriptionRef = useRef();

  const ref = useOutsideClick(() => {
    setDropDownStatus(dropDownStatus?.map((value) => ({ ...value, status: false })));
  });

  const { MessageDangerMarginTop } = Message();

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      editMicrocycleRef.current.closeModal();
    }
  };

  const checkStatusSubscription = async () => {
    try {
      const status = await checkSubscription(school.id)
      setSignatureStatus(status ? 1 : 0)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchMicrocycles = async () => {
    clearMessage();
    setIsLoading(true);
    const { status, microcycles, messageKey } = await getMicrocycleBySchoolId(school.id);
    setIsLoading(false);
    if (status === 'SUCCESS') {
      setMicrocycles(microcycles);
      setMicrocyclesFiltered(microcycles);
      setDropDownStatus(microcycles?.map((microcycle) => ({ id: microcycle.id, status: false })));
      return microcycles;
    } else {
      setErrorMessage(messageKey);
    }
  };

  const handleSubmit = async (data) => {
    clearMessage();
    setIsLoading(true);

    const {
      messageKey,
      status,
      microcycles,
    } = data.id ? await updateMicrocycle(data) : await createMicrocycle(data);
    setIsLoading(false);

    if (status === 'SUCCESS') {
      setMicrocycles(microcycles);
      setMicrocyclesFiltered(microcycles);
      return {
        messageKey,
        status,
      };
    } else {
      return {
        messageKey,
        status,
      };
    }
  };

  const config = (value, copy) => {
    const classesSchool = []
    const aux = value?.dates?.length ? value?.dates?.forEach(date => {
      return date?.lessons?.filter((lesson) => lesson.school_id)?.forEach(lesson => {
        return classesSchool.push({
          ...lesson,
          date: date?.date,
          key: `${date?.date}-${lesson.id}`

        })
      })
    }) : [];
    const classesClub = []
    const aux2 = value?.dates?.length ? value?.dates?.forEach(date => {
      return date?.lessons?.filter((lesson) => lesson.company_id)?.forEach(lesson => {
        return classesClub.push({
          ...lesson,
          date: date?.date,
          key: `${date?.date}-${lesson.id}`

        })
      })
    }) : [];
    const classesAdmin = []
    const aux3 = value?.dates?.length ? value?.dates?.forEach(date => {
      return date?.lessons?.filter((lesson) => lesson.admin_id)?.forEach(lesson => {
        return classesAdmin.push({
          ...lesson,
          date: date?.date,
          key: `${date?.date}-${lesson.id}`
        })
      })
    }) : [];
    setMicrocycle({
      id: copy ? undefined : value.id,
      name: value.name,
      status: value.status,
      objective: value.objective,
      classNumber: value.classNumber,
      dates: value?.dates?.map((date) => ({ date: date?.date })),
      classes: value?.class_days,
      classesSchool,
      classesClub,
      classesAdmin
    });
  }

  const configAdmin = (value) => {
    const classes = []
    const aux = value?.days?.length ? value?.days?.forEach(day => {
      return day?.lessons?.filter((lesson) => lesson.admin_id)?.forEach(lesson => {
        return classes.push({
          ...lesson,
          day: day?.day,
          key: `${day?.day}-${lesson.id}`
        })
      })
    }) : [];
    setMicrocycle({
      id: value.id,
      name: value.name,
      status: value.status,
      objective: value.objective,
      classNumber: value.classNumber,
      classQuantity: value.classQuantity,
      days: value?.days?.map((day) => ({ day: day?.day })),
      classes
    });
  }

  const configCopyAdmin = (e) => {
    e.preventDefault()
    const startDate = new Date(microcycleStartDate)
    const dates = []
    for (let index = 0; index < microcycle.classQuantity; index++) {
      const date = moment(startDate.setDate(startDate.getDate() + 1)).format('YYYY-MM-DD');
      dates.push(date)
    }
    const classesAdmin = []
    const aux = microcycle?.classes?.length ? microcycle?.classes?.forEach((day) => {
      return classesAdmin.push({
        ...day,
        date: dates[day.day - 1],
        key: `${dates[day.day - 1]}-${day.id}`
      })
    }) : [];
    const valueCopy = {
      ...microcycle,
      id: undefined,
      dates: dates.map((date) => ({ date })),
      classes: [],
      classesAdmin
    }
    setMicrocycle(valueCopy)
  }

  const openEdit = (value, view = false, copy = false) => {
    config(value, copy);
    setOnlyView(view);
    editMicrocycleRef.current.showModal();
  };

  const openEditAdmin = (e) => {
    configCopyAdmin(e)
    closeCopyMicrocycle()
    setOnlyView(false);
    editMicrocycleRef.current.showModal();
  };

  const openView = (value) => {
    configAdmin(value);
    viewAdminMicrocycleRef.current.showModal();
  };

  const openAdd = () => {
    setMicrocycle({
      id: undefined,
      name: '',
      status: 'A',
      classNumber: '',
      objective: '',
    });
    editMicrocycleRef.current.showModal();
  };

  const setErrorMessage = (message) => {
    setMessage(message);
    setError(true);
  };

  const clearMessage = () => {
    setError(false);
    setMessage('');
  };

  const toPdf = (value) => {
    Promise.resolve(
      config(value, false)
    ).then(() => {
      handlePrint();
    })
  }

  const handlePrint = useReactToPrint({
    content: () => microcyclePdfRef.current
  })

  const toAdminPdf = (value) => {
    Promise.resolve(
      configAdmin(value)
    ).then(() => {
      handleAdminPrint();
    })
  }

  const handleAdminPrint = useReactToPrint({
    content: () => microcycleAdminPdfRef.current
  })

  const updateByStatus = async (values) => {
    const data = {
      id: values.id,
      status: values.status,
    }

    const {
      status,
      microcycles,
      message,
    } = await updateStatusMicrocycle(data);

    if (status === 'SUCCESS') {
      setMicrocycles(microcycles);
      setMicrocyclesFiltered(microcycles);
      setDropDownStatus(microcycles?.map((microcycle) => ({ id: microcycle.id, status: false })));
    } else {
      setErrorMessage(message);
    }
  }

  const search = (e) => {
    e && e.preventDefault();

    if (filters.source === 'T' || filters.source === 'A') {
      setContextData({
        action: {
          action: 'search',
          term: filters.name,
          status: filters.status
        }
      })
      filters.source === 'T' ? history.push('/school-lessons', { replace: true }) : history.push('/school-lessons-planning', { replace: true });
      return;
    }

    setMicrocyclesFiltered(
      microcycles.filter((microcycle) => filters.status === 'T' || microcycle.status === filters.status)
        .filter((microcycle) => microcycle.name?.toUpperCase()?.includes(filters.name.toUpperCase())));
  };

  const closeCopyMicrocycle = () => {
    setModalStartCopyMicrocycle(false);
  }

  const openStartCopyMicrocycle = (values) => {
    configAdmin(values)
    setModalStartCopyMicrocycle(true)
  }

  const openPaySubscription = (values) => {
    configAdmin(values)
    setShowPaySubscriptionModal(true)

  }

  const onChangeFilters = handleOnChange(setFilters);

  useEffect(() => {
    if (action?.action === 'search') {
      setFilters({
        concept: action.term,
        status: action.status
      });
      fetchMicrocycles().then((response) => {
        setMicrocyclesFiltered(
          response?.filter((microcycle) => action?.status === 'T' || microcycle.status === action?.status)
            .filter((microcycle) => microcycle.name?.toUpperCase()?.includes(action?.term?.toUpperCase())));
      }).catch((e) => console.log(e));
    } else {
      fetchMicrocycles();
    }
    action?.action === 'openAdd' && openAdd();
    setContextData({ action: {} });
    checkStatusSubscription();
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, [onUpdate]);

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <Header />
            <main className='content_panel'>
              <div className='d-flex flex-row flex-1'>
                <div className='d-flex flex-column card mr-3'>
                  <div className='card_header'>
                    <h2 className='card_title'>Tarefas</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar tarefa.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'school-lessons'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={() => {
                      setContextData({
                        action: {
                          action: 'openAdd'
                        }
                      })
                      history.push('/school-lessons', { replace: true });
                    }} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-column card mr-3'>
                  <div className='card_header'>
                    <h2 className='card_title'>Aulas</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar uma aula.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'school-lessons-planning'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={() => {
                      setContextData({
                        action: {
                          action: 'openAdd'
                        }
                      })
                      history.push('/school-lessons-planning', { replace: true });
                    }} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-column card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Microciclo</h2>
                    <p className='card_subtitle'>Clique no botão abaixo para adicionar um microciclo.</p>
                  </div>
                  <div className='d-flex flex-1 mt-4 align-items-end'>
                    <Link to={'school-microcycle'} className='btn-secondary-color rounded-pill mr-3'>
                      Ver
                    </Link>
                    <button onClick={openAdd} className='btn-main-color rounded-pill'>
                      Criar
                    </button>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card_header'>
                  <h2 className='card_title'>Pesquisar</h2>
                  <p className='card_subtitle'>Busque abaixo por tarefas, aulas ou microciclos.</p>
                </div>
                <form action='' method='post' className='form_card'>
                  <div className='d-flex flex-row align-items-center row'>
                    <div className='col-sm-3'>
                      <input
                        required
                        type='text'
                        className='form_control mr-3'
                        name='name'
                        placeholder='Nome'
                        value={filters.name}
                        onChange={onChangeFilters}
                      />
                    </div>
                    <div className='col-sm-3'>
                      <select
                        required
                        className='form_control select mr-3'
                        name='source'
                        value={filters.source}
                        onChange={onChangeFilters}
                      >
                        <option disabled={true}>Selecionar</option>
                        <option value='T'>Tarefas</option>
                        <option value='A'>Aulas</option>
                        <option value='M'>Microciclo</option>
                      </select>
                    </div>
                    <div className='col-sm-3'>
                      <select
                        required
                        className='form_control select mr-3'
                        name='status'
                        value={filters.status}
                        onChange={onChangeFilters}
                      >
                        <option disabled={true}>Status</option>
                        <option value='T'>Tudo</option>
                        <option value='A'>Ativo</option>
                        <option value='I'>Inativo</option>
                      </select>
                    </div>
                    <div className='col-sm-3 my-auto'>
                      <button className='btn-secondary-color rounded-pill' onClick={search}>
                        Buscar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Microciclos</h2>
                    <p className='card_subtitle'>Veja abaixo os microciclos cadastradas</p>
                    {error && <MessageDangerMarginTop ref={alertRef} title='Atenção! ' description={t(message)} />}
                  </div>
                  <div className='list_card'>
                    <div className='d-flex justify-content-between flex-1 font-weight-bold' style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                      <div className='d-flex'>
                        <div style={{ width: '360px', marginRight: '5px' }}>&nbsp;</div>
                        <div style={{ width: '240px' }}>&nbsp;</div>
                        <div style={{ width: '120px', textAlign: 'center' }}>Número</div>
                      </div>
                      <div style={{ width: '120px', textAlign: 'center' }}>Ações</div>
                    </div>
                    {isLoading && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!isLoading &&
                      microcyclesFiltered.map((microcycle) => {
                        const status = dropDownStatus?.find((value) => value.id === microcycle.id && value.status);
                        return (
                          <div className='card_list_turma' key={`${microcycle.id}`}>
                            <div className='content_card'>
                              <div className='card_details_alternative'>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <p className='card_details_label text_uppercase my-auto' style={{ width: '360px' }}>
                                    <span style={{ color: '#3b3b3b' }}>{microcycle.name}</span>
                                  </p>
                                  <div ref={ref} style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '120px' }}>
                                    {!microcycle.admin_id ?
                                      <>
                                        <div
                                          onClick={() => setDropDownStatus(dropDownStatus?.map((value) => (value.id === microcycle.id ? { ...value, status: !value.status } : { ...value })))}
                                          className={`${microcycle.status === 'A' ? 'badge badge-success' : 'badge badge-danger'} badge-button`}
                                          style={status ? { borderRadius: 8, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { borderRadius: 8, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
                                        >
                                          {microcycle.status === 'A' ? 'Ativo' : 'Inativo'}
                                          <div style={{ marginLeft: 10 }}><BsChevronDown color='#FFFFFF' /></div>
                                        </div>
                                        <div style={{ opacity: status ? 1 : 0 }} className='dropdown-shadow'>
                                          <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...microcycle, status: 'A' })}>Ativo</div>
                                          <div style={{ padding: '3px 15px' }} onClick={() => updateByStatus({ ...microcycle, status: 'I' })}>Inativo</div>
                                        </div>
                                      </> :
                                      "Prof. Fred"
                                    }
                                  </div>
                                  <div style={{ textAlign: 'center', width: '240px' }}>{microcycle?.classNumber}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 12, width: '120px', justifyContent: 'center' }}>
                                  {!microcycle.admin_id ?
                                    <>
                                      <span onClick={() => openEdit(microcycle)}><BsFillPencilFill /></span>
                                      <span onClick={() => openEdit(microcycle, false, true)}><BiSolidCopy /></span>
                                      <span onClick={() => openEdit(microcycle, true)}><BsEye /></span>
                                      <span onClick={() => toPdf(microcycle)}><BsDownload /></span>
                                    </>
                                    :
                                    <>
                                      <span><GiPadlock style={{ color: signatureStatus === 1 ? 'green' : signatureStatus === 2 ? 'yellow' : 'red' }} /></span>
                                      {signatureStatus === 1 ? (<span onClick={() => openStartCopyMicrocycle(microcycle)}><BsUpload /></span>) : <span onClick={() => openPaySubscription(microcycle)}><BsCreditCard /></span>}
                                      <span onClick={() => openView(microcycle, true)}><BsEye /></span>
                                      <span onClick={() => toAdminPdf(microcycle)}><BsDownload /></span>
                                    </>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      )}
                  </div>
                </div>
              </div>
              <EditMicrocycle ref={editMicrocycleRef} data={microcycle} isSubscriptiontActive={signatureStatus} onSubmit={handleSubmit} onlyView={onlyView} />
              <ViewAdminMicrocycle ref={viewAdminMicrocycleRef} data={microcycle} />
              <div style={showPaySubscriptionModal ? { display: 'flex', opacity: 1 } : { display: 'none', opacity: 0 }}>
                <PaySubscription onClose={setShowPaySubscriptionModal} microcycle={microcycle} updateMicrocycle={setOnUpdate} />
              </div>
              <div className='modal' style={modalStartCopyMicrocycle ? { display: 'flex', opacity: 1 } : undefined}>
                <div className='modal_content_flex' style={{ width: '400px', height: '175px' }}>
                  <div className='modal_close' onClick={closeCopyMicrocycle}>
                    X
                  </div>
                  <h3 className='modal_title'>
                    Microciclo - Data de Início
                  </h3>
                  <form onSubmit={handleSubmit} method='post' className='form_modal'>
                    <div className='d-flex flex-row align-items-center w-100'>
                      <input type='date' name='microcycleStartDate' className='form_control' value={microcycleStartDate} onChange={(e) => setMicrocycleStartDate(e.target.value)} />
                      <button className='btn-secondary-color ml-3' onClick={(e) => openEditAdmin(e)}>
                        Avançar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div style={{ display: 'none' }}>
                <div ref={microcyclePdfRef}>
                  {microcycle?.dates?.map((value) => {
                    return (
                      <div key={value?.id} className='m-5' style={{ height: '100vh' }}>
                        <div className='d-flex flex-1 flex-column'>
                          <img src="../../img/logo.png" width={252} height={36} alt="Marca" className='mx-auto' />
                          <h4 className='mt-3 mx-auto'>{microcycle?.name}</h4>
                          <h5 className='mt-1 mx-auto'>
                            {moment(value?.date).format('DD/MM/YYYY')}
                          </h5>
                          <div className='row py-3'>
                            <div className='col-sm-12'>
                              <div className='font-weight-bold'>Objetivo</div>
                              <div className=''>{microcycle?.objective}</div>
                            </div>
                          </div>
                          <div className='row pb-3'>
                            <div className='col-sm-6 text-left'>
                              <div className='font-weight-bold'>Nome da aula</div>
                              <div className=''>{microcycle?.name}</div>
                            </div>
                            <div className='col-sm-6'>
                              <div className='font-weight-bold'>Número da aula</div>
                              <div className=''>{microcycle?.classNumber}</div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 pb-3'>
                              <div className='font-weight-bold'>Turma(s) selecionada(as)</div>
                              <div className='resume'>
                                <ul>
                                  {microcycle?.classes?.map((c) => <li key={c.id}>{c.class}</li>)}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12'>
                              {microcycle?.classesSchool?.filter((c) => c.date === value.date)?.length > 0 && (
                                <div className='row pb-3'>
                                  <div className='col-sm-12'>
                                    <div className='font-weight-bold'>Aula da Escola</div>
                                    <div className='resume'>
                                      <ul>
                                        {microcycle?.classesSchool?.filter((c) => c.date === value.date)?.map((c) =>
                                          <li key={c.id}>
                                            <span className='font-weight-bold mr-1'>Nome da Aula: </span><span className='mr-2'>{c.name}</span>
                                            <span className='font-weight-bold mr-1'>Objetivo: </span><span className='mr-2'>{c.objective}</span>
                                            <span className='font-weight-bold mr-1'>Número da Aula: </span><span className='mr-2'>{c.classNumber}</span>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12'>
                              {microcycle?.classesClub?.filter((c) => c.date === value.date)?.length > 0 && (
                                <div className='row pb-3'>
                                  <div className='col-sm-12'>
                                    <div className='font-weight-bold'>Aula do Clube</div>
                                    <div className='resume'>
                                      <ul>
                                        {microcycle?.classesClub?.filter((c) => c.date === value.date)?.map((c) =>
                                          <li key={c.id}>
                                            <span className='font-weight-bold mr-1'>Nome da Aula: </span><span className='mr-2'>{c.name}</span>
                                            <span className='font-weight-bold mr-1'>Objetivo: </span><span className='mr-2'>{c.objective}</span>
                                            <span className='font-weight-bold mr-1'>Número da Aula: </span><span className='mr-2'>{c.classNumber}</span>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div style={{ display: 'none' }}>
                <div ref={microcycleAdminPdfRef}>
                  {microcycle?.days?.map((value) => {
                    return (
                      <div key={value?.id} className='m-5' style={{ height: '100vh' }}>
                        <div className='d-flex flex-1 flex-column'>
                          <img src="../../img/logo.png" width={252} height={36} alt="Marca" className='mx-auto' />
                          <h4 className='mt-3 mx-auto'>{microcycle?.name}</h4>
                          <h5 className='mt-1 mx-auto'>
                            {value?.day}
                          </h5>
                          <div className='row py-3'>
                            <div className='col-sm-12'>
                              <div className='font-weight-bold'>Objetivo</div>
                              <div className=''>{microcycle?.objective}</div>
                            </div>
                          </div>
                          <div className='row pb-3'>
                            <div className='col-sm-6 text-left'>
                              <div className='font-weight-bold'>Nome da aula</div>
                              <div className=''>{microcycle?.name}</div>
                            </div>
                            <div className='col-sm-6'>
                              <div className='font-weight-bold'>Número da aula</div>
                              <div className=''>{microcycle?.classNumber}</div>
                            </div>
                            <div className='col-sm-6'>
                              <div className='font-weight-bold'>Quantidade de aulas</div>
                              <div className=''>{microcycle?.classQuantity}</div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12'>
                              {microcycle?.classes?.filter((c) => c.day === value.day)?.length > 0 && (
                                <div className='row pb-3'>
                                  <div className='col-sm-12'>
                                    <div className='font-weight-bold'>Aula</div>
                                    <div className='resume'>
                                      <ul>
                                        {microcycle?.classes?.filter((c) => c.day === value.day)?.map((c) =>
                                          <li key={c.id}>
                                            <span className='font-weight-bold mr-1'>Nome da Aula: </span><span className='mr-2'>{c.name}</span>
                                            <span className='font-weight-bold mr-1'>Objetivo: </span><span className='mr-2'>{c.objective}</span>
                                            <span className='font-weight-bold mr-1'>Número da Aula: </span><span className='mr-2'>{c.classNumber}</span>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div >
    </main >
  );
};

export default Microcycle;
