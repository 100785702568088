import React, { useState } from 'react';
import Input from '../../components/inputs/Input';
import SignatureCreditService from '../../services/SignatureCreditService';
import Loading from '../../components/Loading';


const ChoosePlanPaymentTypeModal = ({ onCLose, plan }) => {
  const [loadingBuyPlan, setLoadingBuyPlan] = useState(false);

  const onBuyPlan = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      setLoadingBuyPlan(true);
      const response = await SignatureCreditService.buyPlan(plan, formData.get('billingType'));
      if (response.payment_link) {
        window.open(response.payment_link, 'noopener noreferrer');
      }
      onCLose();
    } finally {
      setLoadingBuyPlan(false);
    }
  };
  return <div className="modal" style={{ display: 'block', opacity: 1 }}>
    <div className="modal_content"
         style={{ height: 'min-content', margin: '0 auto', minHeight: 'min-content', paddingBottom: 0 }}>
      <div className="modal_close" onClick={onCLose}>
        X
      </div>
      <h3 className="modal_title">
        Método de pagamento
      </h3>
      <form className="form_modal" onSubmit={onBuyPlan}>
        <div className="form_group">
          <label for="billingType">
            Forma de pagamento
          </label>
          <select className="form_control select" name="billingType" id="billingType">
            <option value="BOLETO">BOLETO</option>
            <option value="CREDIT_CARD">Cartão de crédito</option>
          </select>
          <button className="btn_card" disabled={loadingBuyPlan}>Confirmar {loadingBuyPlan && <Loading />}</button>
        </div>
      </form>
    </div>
  </div>;
};

export default ChoosePlanPaymentTypeModal;