import api from './api';

export default {
  async getPlans() {
    const response = await api.get('get-invoice-plans');
    return response.data;
  },
  async getPlansAdmin() {
    const response = await api.get('get-invoice-plans-admin');
    return response.data;
  },

  async getCreditAmount() {
    const response = await api.get('/get-company-invoice-credit-amount');
    return response.data;
  },
  async buyPlan(plan) {
    const response = await api.post('/buy-invoice-credit', { planId: plan.id });
    return response.data;
  },

  async createPlan(plan) {
    const response = await api.post('create-invoice-plan-admin', plan);
    return response.data;
  },
  async updatePlan(plan) {
    const response = await api.put('update-invoice-plan-admin', plan);
    return response.data;
  },
  async deletePlan(plan) {
    const response = await api.delete('delete-invoice-plan-admin', { id: plan.id });
    return response.data;
  },
};
