import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/admin/Header';
import Footer from '../../components/admin/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Utils, { getErrorMessageFromFileResponse } from '../../../src/utils/utils';
import NumberFormat from 'react-number-format';
import { createClub, getAllClubs, updateClub } from '../../services/CompanieService';
import { uploadFolder } from '../../utils/api';
import { CirclePicker, SketchPicker } from 'react-color';
import { verifyEmail } from '../../services/SharedService';

function Club() {
  //form
  const [clubId, setClubId] = useState(undefined);
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [cnpjCompany, setCnpjCompany] = useState('');
  const [status, setStatus] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [phone, setPhone] = useState('');

  const [token, setToken] = useState('');
  const [cnpjAsaas, setCnpjAsaas] = useState('');
  const [percentParticipation, setPercentParticipation] = useState(undefined);
  const [participation, setParticipation] = useState(undefined);
  const [minimumParticipation, setMinimumParticipation] = useState(undefined);

  const [userId, setUserId] = useState(undefined);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');

  const [defaultColor, setDefaultColor] = useState('');
  const [logo, setLogo] = useState('');
  const [mainBackground, setMainBackground] = useState('');
  const [hasNewLogo, setHasNewLogo] = useState(undefined);
  const [hasNewBackground, setHasNewBackground] = useState(undefined);
  //filtros
  const [filterStatus, setFilterStatus] = useState('A');
  const [filterCompanyName, setFilterCompanyName] = useState('');
  const [filterCnpjCompany, setFilterCnpjCompany] = useState('');

  // carregamento
  const [clubs, setClubs] = useState([]);
  const [clubsFiltered, setClubsFiltered] = useState([]);

  //interface
  const [display, setDisplay] = useState({});
  const [isVisibleSketchPicker, setIsVisibleSketchPicker] = useState(false);
  const [schoolCustomColor, setSchoolCustomColor] = useState(false);

  // process
  const [isLoading, setIsLoading] = useState(false);
  const [isAwaitEmailCheck, setIsAwaitEmailCheck] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  // libraries
  const { scrollTop } = Utils();
  const uploadURL = uploadFolder();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  let modalScroll = useRef();
  const defaultCircleColors = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b',
  ];

  const { setThemeSchool, currencyFormatter } = Utils();

  useEffect(() => {
    setThemeSchool({
      defaultColor: '#ba8820',
      mainBackground: 'bg_login_qa.jpg',
    });

    getList();

    window.addEventListener('keydown', closeModalOnEscape);

    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') closeAdd();
  };

  const search = (e) => {
    e && e.preventDefault();

    setClubsFiltered(
      clubs
        .filter((club) => club.comStatus === filterStatus)
        .filter((club) =>
          filterCompanyName === ''
            ? true
            : club.comCompanie.toLowerCase().includes(filterCompanyName.toLowerCase().trim())
        )
        .filter((club) =>
          filterCnpjCompany === ''
            ? true
            : club.comCNPJ.replace(/\.|\//g, '').slice(0, filterCnpjCompany.length) === filterCnpjCompany
        )
    );
  };

  const openAdd = () => {
    clearMessage();

    setClubId(undefined);
    setCompanyName('');
    setEmail('');
    setCnpjCompany('');
    setStatus('');
    setSubdomain('');
    setPhone('');

    setToken('');
    setCnpjAsaas('');
    setPercentParticipation(undefined);
    setParticipation(undefined);
    setMinimumParticipation(undefined);

    setUserId(undefined);
    setUserName('');
    setUserEmail('');
    setUserPhone('');

    setDefaultColor(undefined);
    setLogo(undefined);
    setMainBackground(undefined);
    setIsVisibleSketchPicker(false);
    setSchoolCustomColor(false);

    setHasNewLogo(false);
    setHasNewBackground(false);
    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const edit = (e, club) => {
    clearMessage();

    setClubId(club.id);
    setCompanyName(club.comCompanie);
    setEmail(club.comEmail);
    setCnpjCompany(club.comCNPJ.replace(/\/|\.|-/g, ''));
    setStatus(club.comStatus);
    setSubdomain(club.subdomain);
    setPhone(club.comTelefone);
    setToken(club.comToken);
    setCnpjAsaas(club.comCnpjAsaas);
    setPercentParticipation(club.comPercentual || undefined);
    setParticipation(club.comValor || undefined);
    setMinimumParticipation(club.comValorMinimo);

    setUserId(club.userId);
    setUserName(club.userName);
    setUserEmail(club.userEmail);
    setUserPhone(club.userPhone);

    setDefaultColor(club.defaultColor || undefined);
    setLogo(club.comLogo);
    setMainBackground(club.mainBackground);

    setHasNewLogo(false);
    setHasNewBackground(false);

    if (!defaultCircleColors.includes(club.defaultColor)) setSchoolCustomColor(true);

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const closeAdd = () => {
    setDisplay({});
  };

  const getList = async () => {
    setIsLoading(true);
    const { clubs } = await getAllClubs();

    setClubs(clubs);
    setClubsFiltered(clubs);
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();

    // if (percentParticipation === undefined && participation === undefined) {
    //   return setErrorMessage('Deve preencher "Participação em %" ou "Participação em R$"');
    // }

    const formClub = {
      comCNPJ: cnpjCompany,
      comCnpjAsaas: cnpjAsaas,
      comCompanie: companyName,
      comEmail: email,
      comPercentual: percentParticipation,
      comStatus: status,
      comTelefone: phone,
      comToken: token,
      comValor: participation,
      comValorMinimo: minimumParticipation,
      defaultColor: defaultColor,
      subdomain: subdomain,

      userName: userName,
      userEmail: userEmail,
      userPhone: userPhone,

      comLogo: logo,
      hasNewLogo: hasNewLogo,
      mainBackground: mainBackground,
      hasNewBackground: hasNewBackground,

      clubId: clubId,
      userId: userId,
    };

    setIsLoading(true);
    const response = clubId === undefined ? await createClub(formClub) : await updateClub(formClub);
    setIsLoading(false);

    if (response.responseError)
      return setErrorMessage('Houve um erro no servidor. Tente novamente ou contate o suporte.');

    if (!response.success) {
      if (response.error.type === 'crud') {
        setErrorMessage(response.error.msg);

        if (response.club) {
          setClubId(response.club.id);
          setClubs(response.clubs);
          search(null);
        }
      } else {
        setClubs(response.clubs);
        search(null);
        setClubId(response.club.id);
        setUserId(response.user.id);

        if (response.comLogo !== null && hasNewLogo !== false) {
          setLogo(response.comLogo.arquivo);
          setHasNewLogo(false);
        }

        if (response.mainBackground !== null && hasNewBackground !== false) {
          setMainBackground(response.mainBackground.arquivo);
          setHasNewBackground(false);
        }

        if (response.error.type === 'validation') {
          setErrorMessage(response.error.msg);
        } else if (response.error.type === 'file') {
          if (response.error.data.erro) {
            const msgs = getErrorMessageFromFileResponse(response.erro);
            setErrorMessage(msgs);
          } else {
            setErrorMessage(response.error.msg);
          }
        }
      }
    } else {
      setSuccessMessage(clubId === undefined ? 'Clube criado com sucesso.' : 'Clube atualizado com sucesso');

      if (hasNewLogo !== false) {
        setLogo(response.comLogo.arquivo);
        setHasNewLogo(false);
      }

      if (hasNewBackground !== false) {
        setMainBackground(response.mainBackground.arquivo);
        setHasNewBackground(false);
      }

      setClubs(response.clubs);
      search(null);
      setClubId(response.club.id);
      setUserId(response.user.id);

      getList();
    }

    scrollTop(modalScroll);
  };

  const setErrorMessage = (message) => {
    setMessage(message);
    setError(true);
    scrollTop(modalScroll);
  };

  const setSuccessMessage = (message) => {
    setMessage(message);
    setSuccess(true);
    scrollTop(modalScroll);
  };

  const clearMessage = () => {
    setSuccess(false);
    setError(false);
    setMessage('');
  };

  const handleVerifyEmail = async (email) => {
    if (email !== '' && email.includes('@')) {
      setIsAwaitEmailCheck(true);
      const { error, message } = await verifyEmail({ email, id: userId });
      setIsAwaitEmailCheck(false);

      if (error) {
        setErrorMessage(message);
        setUserEmail('');
      } else {
        clearMessage();
      }
    }
  };

  const toggleSketchPicker = () => {
    setIsVisibleSketchPicker((prev) => !prev);
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <Header />

            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Adicionar clubes</h2>
                    <p className='card_subtitle'>Clique abaixo para adicionar um novo clube.</p>
                  </div>
                  <button onClick={openAdd} className='btn_card mx-auto mt-4 btn_open_modal'>
                    <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                    </svg>
                    Adicionar clube
                  </button>
                </div>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Procurar</h2>
                    <p className='card_subtitle'>Filtrar os clubes cadastrados.</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='tel' className='card_details_label'>
                        Status
                      </label>
                      <select
                        required
                        value={filterStatus}
                        className='form_control select'
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value='A'>Ativo</option>
                        <option value='I'>Inativo</option>
                      </select>
                      <div />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        Razão Social
                      </label>
                      <input
                        type='text'
                        required
                        className='form_control'
                        value={filterCompanyName}
                        onChange={(e) => setFilterCompanyName(e.target.value)}
                      />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        CNPJ*
                      </label>
                      <NumberFormat
                        className='form_control'
                        displayType={'input'}
                        placeholder='__.___.___/____-__'
                        format='##.###.###/####-##'
                        mask='_'
                        required
                        isNumericString
                        value={filterCnpjCompany}
                        onValueChange={(values) => setFilterCnpjCompany(values.value)}
                      />
                    </div>
                    <button className='btn_card mx-auto' onClick={search}>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      Buscar
                    </button>
                  </form>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Clubes</h2>
                    <p className='card_subtitle'>Veja abaixo a sua lista de clubes cadastrados. </p>
                  </div>

                  <div className='list_card'>
                    {isLoading && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!isLoading &&
                      clubsFiltered.map((club) => {
                        return (
                          <div className='card_list' key={`club_${club.id}`}>
                            <div className='img_list_user'>
                              <img
                                src={`${uploadURL}upload/companie/logo/${club.comLogo}`}
                                style={{
                                  width: 210,
                                }}
                                alt=''
                              />
                            </div>
                            <div className='content_card'>
                              <div className='card_details'>
                                <p className='card_details_title' title={club.comCompanie}>
                                  {club.comCompanie.length > 20
                                    ? club.comCompanie.substr(0, 20) + '...'
                                    : club.comCompanie}
                                </p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>CNPJ: </p>
                                <p className='card_details_text'>{club.comCNPJ}</p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Email: </p>
                                <p className='card_details_text' title={club.comEmail}>
                                  {club.comEmail.length > 20 ? club.comEmail.substr(0, 20) + '...' : club.comEmail}
                                </p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>Status: </p>
                                <p className='card_details_text'>
                                  {club.comStatus === 'A' && club.comStatus}
                                  {club.comStatus === 'I' && club.comStatus}
                                </p>
                              </div>
                            </div>
                            <span
                              style={{
                                backgroundColor: 'orange',
                                fontWeight: 500,
                                border: '1px solid orange',
                              }}
                              onClick={(e) => edit(e, club)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              Editar clube
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className='modal' style={display}>
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeAdd}>
                    X
                  </div>
                  <h3 className='modal_title'>{clubId === undefined ? 'Adicionar clube' : 'Atualizar clube'}</h3>
                  {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
                  {success && <MessageSuccessMarginTop title='Sucesso! ' description={message} />}
                  <form onSubmit={handleSubmit} method='post' className='form_modal'>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          Razão Social*
                        </label>
                        <input
                          type='text'
                          required
                          className='form_control'
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='email' className='card_details_label' style={{ flexDirection: 'row' }}>
                          E-mail*
                        </label>
                        <input
                          type='email'
                          required
                          className='form_control'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label'>
                            CNPJ do clube*
                          </label>
                          <NumberFormat
                            className='form_control'
                            displayType={'input'}
                            placeholder='__.___.___/____-__'
                            format='##.###.###/####-##'
                            mask='_'
                            required
                            isNumericString
                            value={cnpjCompany}
                            onValueChange={(values) => setCnpjCompany(values.value)}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            Status*
                          </label>
                          <select
                            required
                            value={status}
                            className='form_control select'
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value={undefined} hidden></option>
                            <option value='A'>Ativo</option>
                            <option value='I'>Inativo</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label'>
                            Telefone*
                          </label>
                          <NumberFormat
                            className='form_control'
                            displayType={'input'}
                            placeholder='(__) _ ____-____'
                            format='(##) # ####-####'
                            mask='_'
                            required
                            isNumericString
                            value={phone}
                            onValueChange={(values) => setPhone(values.value)}
                          />
                        </div>
                      </div>

                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label' style={{ flexDirection: 'row' }}>
                            Subdomínio*
                          </label>
                          <input
                            type='text'
                            required
                            className='form_control'
                            value={subdomain}
                            onChange={(e) => setSubdomain(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <h3 className='modal_title' style={{ marginBottom: 10, width: '100%' }}>
                      Configurações Asaas
                    </h3>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          Token*
                        </label>
                        <input
                          type='text'
                          readOnly
                          value={token}
                          className='form_control'
                          onChange={(e) => setToken(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          Wallet ID*
                        </label>
                        <input disabled value='0f29a0b1-34fe-4e65-9e90-f15eeb79e280' className='form_control' />
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label'>
                            CNPJ no ASAAS*
                          </label>
                          <NumberFormat
                            className='form_control'
                            displayType={'input'}
                            placeholder='__.___.___/____-__'
                            format='##.###.###/####-##'
                            mask='_'
                            isNumericString
                            readOnly
                            value={cnpjAsaas}
                            onValueChange={(values) => setCnpjAsaas(values.value)}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            Participação em %
                          </label>
                          <NumberFormat
                            className='form_control'
                            placeholder='%'
                            displayType={'input'}
                            suffix={'%'}
                            decimalSeparator=','
                            isNumericString
                            value={+percentParticipation}
                            onValueChange={(values) => {
                              setPercentParticipation(+values.floatValue || undefined);
                              values.floatValue && setParticipation(undefined);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            Participação em R$
                          </label>
                          <NumberFormat
                            className='form_control'
                            placeholder='R$'
                            displayType={'input'}
                            prefix={'R$ '}
                            isNumericString
                            format={currencyFormatter}
                            value={participation * 100}
                            onValueChange={(values) => {
                              setParticipation(values.floatValue / 100 || undefined);
                              values.floatValue && setPercentParticipation(undefined);
                            }}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            Participação Mínima em R$*
                          </label>
                          <NumberFormat
                            className='form_control'
                            placeholder='R$'
                            displayType={'input'}
                            prefix={'R$ '}
                            isNumericString
                            format={currencyFormatter}
                            value={minimumParticipation * 100}
                            onValueChange={(values) => setMinimumParticipation(values.floatValue / 100 || undefined)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='form_group'>
                      <label className='card_details_label'>Nome</label>
                      <input
                        type='text'
                        required
                        name='name'
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        className='form_control'
                      />
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='email' className='card_details_label' style={{ flexDirection: 'row' }}>
                          E-mail do usuário
                          {isAwaitEmailCheck && <Loading type='1' style={{ marginLeft: '2%' }} />}
                        </label>
                        <input
                          type='email'
                          required
                          name='name'
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)}
                          onBlur={(e) => handleVerifyEmail(e.target.value)}
                          className='form_control'
                        />
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>Telefone do usuário</label>
                        <NumberFormat
                          className='form_control'
                          displayType={'input'}
                          placeholder='(__) _ ____-____'
                          format='(##) # ####-####'
                          mask='_'
                          required
                          isNumericString
                          value={userPhone}
                          onValueChange={(values) => setUserPhone(values.value)}
                        />
                      </div>
                    </div>

                    <h3 className='modal_title' style={{ marginBottom: 10 }}>
                      Personalização
                    </h3>

                    <div className='form_group' />

                    <div className='group_50'>
                      <div className='form_group'>
                        <label style={{ marginBottom: '15px' }} className='card_details_label'>
                          Cor padrão
                        </label>
                        <CirclePicker
                          color={defaultColor}
                          onChange={(color) => {
                            setDefaultColor(color.hex);
                            setSchoolCustomColor(false);
                          }}
                        />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px' }}>
                          <span
                            style={{
                              height: '25px',
                              width: '25px',
                              borderRadius: '50%',
                              boxShadow: schoolCustomColor
                                ? `${defaultColor} 0px 0px 0px 3px inset`
                                : 'transparent 0px 0px 0px 3px inset',
                            }}
                          />
                          <small
                            className='on-hover on-hover-green'
                            onClick={toggleSketchPicker}
                            style={{ marginTop: '10px', marginLeft: '10px', fontWeight: 'bold', width: 'max-content' }}
                          >
                            Mais cores...
                          </small>
                        </div>
                        <div style={{ position: 'relative' }}>
                          <div
                            style={{
                              ...(isVisibleSketchPicker
                                ? { opacity: 1, pointerEvents: 'auto' }
                                : { opacity: 0, pointerEvents: 'none' }),
                              position: 'absolute',
                              bottom: 0,
                              left: '25%',
                              transition: 'all 0.2s ease-in-out 0s',
                            }}
                          >
                            <SketchPicker
                              color={defaultColor}
                              onChange={(color) => {
                                setDefaultColor(color.hex);
                                setSchoolCustomColor(true);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <div>
                            <div style={{ height: '126px', display: 'flex', justifyContent: 'center' }}>
                              {logo && !hasNewLogo && (
                                <a
                                  style={{ display: 'flex', justifyContent: 'center' }}
                                  href={`${uploadURL}upload/companie/logo/${logo}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <img src={`${uploadURL}upload/companie/logo/${logo}`} />
                                </a>
                              )}
                              {hasNewLogo && <img src={URL.createObjectURL(hasNewLogo)} />}
                              {!logo && !hasNewLogo && (
                                <div
                                  className='upload_file background-color-default'
                                  style={{ width: '100%', minHeight: '126px' }}
                                />
                              )}
                            </div>
                            <span
                              style={{
                                backgroundColor: '#AAAAAA',
                                fontWeight: 500,
                                border: '1px solid #AAAAAA',
                                height: '28px',
                                width: '190px',
                                minWidth: '150px',
                                marginTop: '10px',
                                fontSize: '0.8rem',
                              }}
                              className='btn_card'
                            >
                              Escolher logomarca
                              <input
                                type='file'
                                accept='.png, .jpg, .jpeg'
                                onChange={(e) => {
                                  setHasNewLogo(e.target.files[0]);
                                }}
                                style={{
                                  display: 'initial',
                                  opacity: 0,
                                  border: '1px solid #AAAAAA',
                                  height: '28px',
                                  width: '190px',
                                  minWidth: '150px',
                                  position: 'absolute',
                                  borderRadius: '50px',
                                  cursor: 'pointer',
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className='group_50'>
                        <div className='form_group'>
                          <div>
                            <div style={{ height: '126px', display: 'flex', justifyContent: 'center' }}>
                              {mainBackground && !hasNewBackground && (
                                <a
                                  style={{ display: 'flex', justifyContent: 'center' }}
                                  href={`${uploadURL}upload/companie/background/${mainBackground}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <img
                                    style={{ height: '100%' }}
                                    src={`${uploadURL}upload/companie/background/${mainBackground}`}
                                  />
                                </a>
                              )}
                              {hasNewBackground && <img src={URL.createObjectURL(hasNewBackground)} />}
                              {!mainBackground && !hasNewBackground && (
                                <div
                                  className='upload_file background-color-default'
                                  style={{ width: '100%', minHeight: '126px' }}
                                />
                              )}
                            </div>
                            <span
                              style={{
                                backgroundColor: '#AAAAAA',
                                fontWeight: 500,
                                border: '1px solid #AAAAAA',
                                height: '28px',
                                width: '190px',
                                minWidth: '150px',
                                marginTop: '10px',
                                fontSize: '0.8rem',
                              }}
                              className='btn_card'
                            >
                              Escolher plano de fundo
                              <input
                                type='file'
                                accept='.png, .jpg, .jpeg'
                                onChange={(e) => {
                                  setHasNewBackground(e.target.files[0]);
                                }}
                                style={{
                                  display: 'initial',
                                  opacity: 0,
                                  border: '1px solid #AAAAAA',
                                  height: '28px',
                                  width: '190px',
                                  minWidth: '150px',
                                  position: 'absolute',
                                  borderRadius: '50px',
                                  cursor: 'pointer',
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {!isLoading && (
                      <button className='btn_card mx-auto'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {clubId === undefined ? 'Adicionar clube' : 'Atualizar clube'}
                      </button>
                    )}
                    {isLoading && (
                      <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none' }} disabled>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        processando...
                        <Loading type='1' style={{ marginLeft: '2%' }} />
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Club;
