import React, { useState } from 'react';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

const MultiSelect = ({
  listKeyAux,
  label,
  options,
  optionsSelected,
  selectOption,
  deselectOption,
  customOptionLabel,
  CustomInput = null,
}) => {
  const timestamp = moment().valueOf();
  const [valueString, setValueString] = useState('');

  return (
    <>
      <div className='form_group'>
        <label htmlFor={timestamp + 'MultiSelect'} className='card_details_label'>
          {label}
        </label>
        {CustomInput ? (
          CustomInput
        ) : (
          <Autocomplete
            autoComplete
            renderInput={(params) => <TextField {...params} variant='outlined' />}
            options={options}
            getOptionLabel={(option) => (customOptionLabel ? `${customOptionLabel(option)}` : `${option.label}`)}
            value={null}
            onChange={(_, newValue) => {
              setValueString('');
              selectOption(newValue);
            }}
            inputValue={valueString}
            onInputChange={(_, newInputValue) => setValueString(newInputValue)}
          />
        )}
      </div>
      <div className='list_select'>
        {optionsSelected.map((optionSelected, i) => (
          <div className='list_item' key={timestamp + `MultiSelect_OptionSelected_${i}_${listKeyAux}`}>
            {console.log('optionSelected :>> ', optionSelected)}
            <p style={{ fontSize: 13, marginRight: 5 }}>{optionSelected.label}</p>
            <button className='remove' onClick={() => deselectOption(optionSelected)}>
              <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M8.53555 1.46445C7.59121 0.520117 6.33555 0 5 0C3.66445 0 2.40879 0.520117 1.46445 1.46445C0.520117 2.40879 0 3.66445 0 5C0 6.33555 0.520117 7.59121 1.46445 8.53555C2.40879 9.47988 3.66445 10 5 10C6.33555 10 7.59121 9.47988 8.53555 8.53555C9.47988 7.59121 10 6.33555 10 5C10 3.66445 9.47988 2.40879 8.53555 1.46445ZM7.1627 6.47207C7.35332 6.66289 7.35332 6.97188 7.1627 7.1627C7.06738 7.25801 6.94238 7.30566 6.81738 7.30566C6.69238 7.30566 6.56738 7.25801 6.47207 7.1627L5 5.69062L3.52793 7.1627C3.43262 7.25801 3.30762 7.30566 3.18262 7.30566C3.05762 7.30566 2.93262 7.25801 2.8373 7.1627C2.64668 6.97188 2.64668 6.66289 2.8373 6.47207L4.30937 5L2.8373 3.52793C2.64668 3.33711 2.64668 3.02813 2.8373 2.8373C3.02813 2.64648 3.33711 2.64648 3.52793 2.8373L5 4.30937L6.47207 2.8373C6.66289 2.64668 6.97188 2.64648 7.1627 2.8373C7.35352 3.02793 7.35352 3.33711 7.1627 3.52793L5.69062 5L7.1627 6.47207Z'
                  fill='#B2B1B1'
                />
              </svg>
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default MultiSelect;
