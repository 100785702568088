import React, { useState, useEffect } from 'react'
import Loading from '../../../../../components/Loading'
import Api from '../../../../../utils/api'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import SweetAlert from 'sweetalert2-react';

import './CardResgate.css'

const CardResgate = (props) => {
    const [process, setProcess] = useState(false)
    const [login, setLogin] = useState(false)
    const [home, setHome] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [alertTitle, setAlertTitle] = useState("")
    const [alertDescription, setAlertDescription] = useState("")
    const baseURL = Api()

    useEffect(() => {

        async function getSession (){
            const athlete = JSON.parse(await localStorage.getItem('athlete'))
   
            if ( athlete === null ) {
                setLogin(true)
            }
         }
   
         getSession()
    }, [])

    const handleRescue = async () => {
        if ( !process ) {
            const saldo = Number(await localStorage.getItem('saldo'))
            
            if ( saldo >= Number(props.points) ) {
                setProcess(true)
                const res = await axios.post(baseURL + 'save-rescue', {
                    experience_id: props.id,
                    athlete_id: props.athlete_id,
                    type: props.title,
                    points: props.points*-1,
                    date_valid:  null,
                    created_at: new Date().toISOString().substr(0,10)
                })
                setProcess(false)

                if ( res.data.create ) {
                    setOpenAlert(true)
                    setAlertTitle("PARABÉNS")
                    setAlertDescription("Resgate realizado com sucesso, aproveite :)")
                    await localStorage.setItem('saldo', (saldo - Number(props.points)))
                }
            } else {
                setOpenAlert(true)
                setAlertTitle("Atenção")
                setAlertDescription("Você não tem saldo suficiente para este resgate :(")
            }
        }
    }

    const goHome = () => {
        setOpenAlert(false)
        setHome(true)
    }

    if ( home ) {
        return <Redirect to="/advantage-club" />
    }

    if ( login ) {
        return <Redirect to="/" />
    }

    return (
        <article className="card_resgate" style={{borderTopColor: props.color}}>
            <h2 style={{color: props.color}}>{props.title}</h2>
            <div className="content_card">
                <div className="image">
                    <img style={{maxHeight: 350}} src={props.tumblr} alt="Concorra a essa experiência" />
                </div>
                <div className="text">
                    <div className="dados">
                        <h3>{props.subTitle}</h3>
                        <p>{props.descript}</p>
                    </div>
                    <span style={{color: props.color}}>{props.points} PTS</span>
                    <button className="card_btn" style={{backgroundColor: props.color, display: "flex", justifyContent: "center", alignItems: "center"}} onClick={handleRescue}>
                    {
                        process && 
                        <Loading type="1" style={{marginBottom: 0}} />
                    }
                    {
                        !process && 
                        <React.Fragment>Resgatar</React.Fragment>
                    }
                    </button>
                    <SweetAlert
                        show={openAlert}
                        title={alertTitle}
                        text={alertDescription}
                        onConfirm={() => goHome()}
                    />
                </div>
            </div>
        </article>
    )
}

export default CardResgate