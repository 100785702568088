import React from 'react';
import Loading from '../Loading';

export const CardIndicatorSimple = ({ doubleLine, className, isLoading, title, value }) => {
  return (
    <div className={`card ${className || ''}`}>
      <div className='card_header mx-auto align-items-center align-items-lg-start'>
        <span className='card_title' style={{ fontSize: '1rem', minHeight: doubleLine ? '50px' : undefined }}>
          {title}
        </span>
        {isLoading ? (
          <p className='loading-card sidebar_data' style={{ height: '95px' }}>
            <Loading type='1' />
          </p>
        ) : (
          <div className='sidebar_data w-100' style={{ fontSize: '48px' }}>
            {value}
          </div>
        )}
      </div>
    </div>
  );
};
