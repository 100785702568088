import React, { useEffect, useState } from 'react';
import PlanCard from '../../components/PlanCard';
import InvoiceCreditService from '../../services/InvoiceCreditService';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';

const BuyCredit = () => {
  const { push } = useHistory();
  const [plans, setPlans] = useState([]);
  const [loadingBuyPlan, setLoadingBuyPlan] = useState(false);
  useEffect(() => {
    InvoiceCreditService.getPlans().then(setPlans);
  }, []);

  const onBuyPlan = async (plan) => {
    try {
      setLoadingBuyPlan(true);
      await InvoiceCreditService.buyPlan(plan);
      push('invoice-credit')
    } finally {
      setLoadingBuyPlan(false);
    }
  };

  return (
    <div style={{ padding: '0 30px' }}>
      <div>
        <Link
          to='invoice-credit'
          style={{ display: 'flex', alignItems: 'center', gap: 10, border: 'none', color: 'black' }}
        >
          <FaArrowLeft /> Voltar
        </Link>
      </div>
      <div style={{ textAlign: 'center', marginBottom: 20, fontSize: '1.125rem' }}>
        Escolha um plano para prosseguir:
      </div>
      <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
        {plans.map((plan) => (
          <PlanCard key={plan.id} plan={plan} onBuyPlan={onBuyPlan} loading={loadingBuyPlan} />
        ))}
      </div>
    </div>
  );
};

export default BuyCredit;
