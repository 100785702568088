import moment from 'moment';

/**
 * Calcula o desconto para pagamento em dia
 * @param {number} value
 * @param {'NONE' | 'PERCENTAGE' | 'FIXED'} type
 * @param {number} discount
 * @returns {number}
 */
export const calcDiscount = (value, type = 'NONE', discount = null) => {
  if (type === 'PERCENTAGE') {
    return +value * (+discount / 100);
  } else if (type === 'FIXED') {
    return +discount;
  } else {
    return 0;
  }
};

/**
 * Calcula os juros por atraso
 * @param {number} value
 * @param {Date | string | null} paymentDate
 * @param {Date | string} dueDate
 * @param {'NONE' | 'PERCENTAGE' | 'FIXED'} type
 * @param {number} lateInterest
 * @returns {number}
 */
export const calcLateInterest = (value, paymentDate, dueDate, type, lateInterest) => {
  const diffDays = moment(paymentDate || undefined).diff(moment(dueDate), 'days');

  if (type === 'PERCENTAGE') {
    const lateInterestValue = (diffDays / 30) * (lateInterest / 100);

    return +value * lateInterestValue;
  } else if (type === 'FIXED') {
    const lateInterestValue = (diffDays / 30) * lateInterest;

    return +lateInterestValue;
  } else {
    return 0;
  }
};

/**
 * Calcula a multa por atraso
 * @param {number} value
 * @param {'NONE' | 'PERCENTAGE' | 'FIXED'} type
 * @param {number} lateFee
 * @returns {number}
 */
export const calcLateFee = (value, type, lateFee) => {
  if (type === 'PERCENTAGE') {
    return +value * (+lateFee / 100);
  } else if (type === 'FIXED') {
    return +lateFee;
  } else {
    return 0;
  }
};

/**
 * Calcula o valor final de um lançamento financeiro, considerando o desconto, os juros e a multa por atraso
 * @param {number} value Valor do lançamento
 * @param {boolean} itsPaid Se o valor foi recebido ou não
 * @param {import('moment').Moment | Date | string} paymentDate Se o valor foi recebido ou não
 * @param {import('moment').Moment | Date | string} dueDate Data de vencimento do lançamento
 * @param {object} discount
 * @param {'NONE' | 'PERCENTAGE' | 'FIXED'} discount.type O tipo de desconto (Percentual ou valor fixo)
 * @param {number} discount.value O valor do desconto
 * @param {{type: 'NONE' | 'PERCENTAGE' | 'FIXED'; value: number}} lateInterest
 * @param {{type: 'NONE' | 'PERCENTAGE' | 'FIXED'; value: number}} lateFee
 * @returns {number}
 */
export const calcFinanceValue = (value, itsPaid, paymentDate, dueDate, discount, lateInterest, lateFee) => {
  if (!dueDate || dueDate == '' || dueDate == null || dueDate == undefined) return undefined;

  const paidOnTime = paymentDate <= dueDate;
  const isNotOverdued = dueDate >= moment().format('YYYY-MM-DD');

  const withDiscount = itsPaid ? paidOnTime : isNotOverdued;

  if (withDiscount) {
    const discountCalculated = calcDiscount(value, discount.type, discount.value);
    return +value - discountCalculated;
  } else {
    const lateInterestCalculated = calcLateInterest(
      value,
      paymentDate || null,
      dueDate,
      lateInterest.type,
      lateInterest.value
    );
    const lateFeeCalculated = calcLateFee(value, lateFee.type, lateFee.value);
    return +value + lateInterestCalculated + lateFeeCalculated;
  }
};

export const discoverBillCase = (finance, callback, type) => {
  const prop = {
    SCHOOL: 'contract_id',
    CLUB: 'contract_id_club',
  };

  if (!prop[type]) throw new Error('Invalid type on function "discoverBillCase"');

  // Lançamento que ainda não foi salvo. Etapa de criação.
  if (!finance) callback('UNSAVED');
  //
  // Lançamento não faturado. No momento, só a despesa. Esse modo é o que permite a edição de quase todos os campos.
  else if (finance.type === 'Despesa') callback('UNBILLED');
  //
  // Lançamento vinculado a um contrato de aluno, contrato de escola ou venda de produto.
  // O lançamento ESTÁ integrado com o serviço de pagamento.
  else if ((!!finance[prop[type]] || !!finance.order_id) && !!finance.payment_id_asaas) callback('CONTRACT_ATTACHED');
  //
  // Lançamento vinculado a um contrato de aluno, contrato de escola ou venda de produto.
  // O lançamento NÃO está integrado com o serviço de pagamento.
  else if ((!!finance[prop[type]] || !!finance.order_id) && !finance.payment_id_asaas) callback('CONTRACT_DETACHED');
  //
  // Lançamento direto no financeiro (lançamento avulso).
  // O lançamento ESTÁ integrado com o serviço de pagamento.
  else if (!finance[prop[type]] && !!finance.payment_id_asaas) callback('FINANCE_ATTACHED');
  //
  // Lançamento direto no financeiro (lançamento avulso).
  // O lançamento NÃO está integrado com o serviço de pagamento.
  else if (!finance[prop[type]] && !finance.payment_id_asaas) callback('FINANCE_DETACHED');
  //
  // Nenhuma das situações anteriores foi identificada. Estado indeterminado.
  else throw new Error('The "discoverBillCase" is undefined');
};

/**
 *
 * @param {{ unsaved: boolean, unbilled: boolean, contractAttached: boolean, contractDetached: boolean, financeAttached: boolean, financeDetached: boolean }?} cases
 * @returns {boolean}
 */
export const onBillCase = (cases, watch) => {
  if (!cases && (watch === 'UNSAVED' || watch === 'UNBILLED')) return false;
  else if (!cases && watch !== 'UNSAVED' && watch !== 'UNBILLED') return true;

  const { unsaved, unbilled, contractAttached, contractDetached, financeAttached, financeDetached } = cases;

  switch (watch) {
    case 'UNSAVED':
      return unsaved;

    case 'UNBILLED':
      return unbilled;

    case 'CONTRACT_ATTACHED':
      return contractAttached;

    case 'CONTRACT_DETACHED':
      return contractDetached;

    case 'FINANCE_ATTACHED':
      return financeAttached;

    case 'FINANCE_DETACHED':
      return financeDetached;

    default:
      return undefined;
  }
};

export const calcValueToReceive = (fat) => ({
  ...fat,
  valueCalculated: Number(fat.valueReceived)
    ? +fat.valueReceived
    : calcFinanceValue(
        fat.value,
        fat.received === 'S',
        fat.datePayment,
        fat.dueDate,
        { type: fat.discount_type, value: fat.discount },
        { type: fat.late_interest_type, value: fat.late_interest },
        { type: fat.late_fee_type, value: fat.late_fee }
      ),
});

/**
 * Opções padrão para a conta de lançamento de lançamentos financeiros.
 * Estas opções são iguais para os clubes e para as escolas.
 * @returns string[]
 */
export const getFinancialAccountOptions = () => ['Tarifa', 'Seguro', 'Repasse', 'Contrato'];
