import React, { useEffect, useState, useRef } from 'react';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Utils, { correctImageOrientation } from '../../utils/utils';
import LoadingTheme from '../../components/LoadingTheme';
import { useTranslation } from 'react-i18next';
import {
  getStates,
  getPositionsSchool,
  getQuestionnaires,
  getCitysByStateSigla,
  verifyLogin,
  verifyCpfSchool,
  verifyEmailSchool,
  searchByZipCodeCountry,
} from '../../services/SharedService';
import { saveStudentLinkSchool, updateStudentLinkSchool, getThemeBySchool } from '../../services/StudentService';
import { languages } from '../../i18nextConf';
import { useQuery } from '../../utils/custom-react-hook';
import { APIError } from '../../services/ExceptionClass/APIError';
import CpfCnpjMask from '../../components/inputs/CpfCnpjMask';
import { validateCPF } from '../../utils/validators';
import NumberFormat from 'react-number-format';
import SweetAlert from 'react-bootstrap-sweetalert';

const StudentLinkCreate = (props) => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [cpf, setCPF] = useState('');
  const [birth, setBirth] = useState('');
  const [email, setEmail] = useState('');
  const [id, setID] = useState(null);
  const [school_id] = useState(props.match.params.school_id);
  const [companie_id] = useState(props.match.params.companie_id);
  const [foto, setFoto] = useState('');
  const [newPhoto, setNewPhoto] = useState(null);
  const [nomeRespFin, setNomeRespFin] = useState('');
  const [cpfRespFin, setCPFRespFin] = useState('');
  const [emailRespFin, setEmailRespFin] = useState('');
  const [celRespFin, setCelRespFin] = useState('');
  const [indication, setIndication] = useState('');

  // process
  const [sendSave, setSendSave] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendCPF, setSendCPF] = useState(false);
  const [schoolTheme, setSchoolTheme] = useState(null);

  // messages
  const [showMessage, setShowMessage] = useState(false)
  const [messageType, setMessageType] = useState('');
  const [message, setMessage] = useState('');

  // libraries
  const { scrollTop } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  let modalScroll = useRef(window);
  const query = useQuery();
  const classId = query.get('c');

  useEffect(() => {
    getThemeBySchool(school_id).then((res) => {
      setSchoolTheme(res);
    });

    URL.revokeObjectURL(foto);

    i18n.changeLanguage(query.get('l') || 'pt-BR');
  }, []);

  const verifyCPF = async (email) => {
    if (id == null) {
      setSendCPF(true);
      const res = await verifyCpfSchool({ cpf: cpf, schoolId: school_id });
      setSendCPF(false);

      if (res.error !== false) {
        setCPF('');
        setErrorMessage('SystemMessage.Fail.' + res.messageKey);
      } else {
        clearMessage();
        setCPF(email);
      }
    }
  };

  const verifyEmail = async (email) => {
    // Comentando validação de e-mail cadastrado na mesma escola.
    // Agora e-mail pode ser cadastrado para mais de um aluno na mesma escola
    //
    // if (id == null) {
    //   setSendEmail(true);
    //   const res = await verifyEmailSchool({ email: email, schoolId: school_id });
    //   setSendEmail(false);
    //   if (res.error !== false) {
    //     setEmail('');
    //     setErrorMessage('SystemMessage.Fail.' + res.messageKey);
    //   } else {
    //     clearMessage();
    //     setEmail(email);
    //   }
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();

    if (!validateCPF(cpf, i18n.language)) {
      setErrorMessage('StudentPage.SystemMessage.InvalidStudentCPF');
    } else if (!validateCPF(cpfRespFin, i18n.language)) {
      setErrorMessage('StudentPage.SystemMessage.InvalidResponsibleCPF');
    } else {
      const body = {
        school_id,
        name,
        login,
        usuCPF: cpf,
        usuBirth: birth,
        email: email,
        usuStatus: 'P',
        athNomeRespFin: nomeRespFin,
        athCPFRespFin: cpfRespFin,
        athPhoneRespFin: celRespFin,
        athEmailRespFin: emailRespFin,
        athTipoStudent: 'Regular',
        novaFoto: newPhoto,
        athIndication: indication,
        usuGender:""
      };

      try {
        setSendSave(true);

        const { student, messageKey, dir_usuFotoPrincipal } =
          id === null
            ? await saveStudentLinkSchool(
                {
                  companie_id,
                  role_id: 2,
                  usuStudent: 'S',
                  password: body.athEmail,
                  classId: classId,
                  ...body,
                },
                'full'
              )
            : await updateStudentLinkSchool(
                {
                  id,
                  ...body,
                },
                'short'
              );

        setSuccessMessage(messageKey);
        setID(student.user.id);

        if (newPhoto) {
          URL.revokeObjectURL(foto);
          setNewPhoto(null);
          setFoto(dir_usuFotoPrincipal + '/' + student.user.usuFotoPrincipal);
        }
      } catch (error) {
        if (error instanceof APIError) setErrorMessage(error.messageKey || error.message, error.extra);
        else setErrorMessage('SystemMessage.Fail.ErrorRetry');
      } finally {
        setSendSave(false);
      }
    }
  };

  const handleFoto = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const newPhoto = await correctImageOrientation(file);

      URL.revokeObjectURL(foto);
      setFoto(URL.createObjectURL(newPhoto));
      setNewPhoto(newPhoto);
    }
  };
  const handlerValidateLogin = async (e) => {
    e.preventDefault();

    const res = await verifyLogin({ login: e.target.value, id: id });
    if (res.error === true) {
      //e.target.setCustomValidity(res.messageKey)
      setErrorMessage('SystemMessage.Fail.' + res.messageKey);
    } else {
      //e.target.setCustomValidity('')
      clearMessage();
    }
  };

  const setErrorMessage = (message, extra = null) => {
    if (extra) setMessage({ text: message, extra });
    else setMessage(message);

    setMessageType('error')
    setShowMessage(true);
  };

  const setSuccessMessage = (message, extra = null) => {
    if (extra) setMessage({ text: message, extra });
    else setMessage(message);

    setMessageType('success')
    setShowMessage(true)
  };

  const clearMessage = () => {
    setShowMessage(false)
    setMessage('');
    setMessageType('')
  };

  return (
    <main>
      <LoadingTheme schoolTheme={schoolTheme} />
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <header className='col-12' style={{ paddingLeft: 0, marginBottom: 40 }}>
              <div className='row header' style={{ margin: 0, padding: 0 }}>
                <img
                  src='../../../img/clubes/fensor.svg'
                  className={`logo ${companie_id == 175 ? 'ml-auto mr-0' : ''}`}
                  alt=''
                />
              </div>
            </header>

            <main className='content_panel'>
              <div className='sidebar' style={{ flex: 1, maxWidth: 'none' }}>
                <div className='card'>
                  <div className='card_header' style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div>
                        <h2 className='card_title'>{t('StudentPage.ExternalLink.Title')}</h2>
                        <p className='card_subtitle'>{t('StudentPage.ExternalLink.Subtitle')}</p>
                      </div>

                      <div>
                        <select
                          className='login_link select-link text-transform-none'
                          value={i18n.resolvedLanguage}
                          onChange={(e) => i18n.changeLanguage(e.target.value)}
                          style={{ marginTop: '5px', width: 'auto' }}
                        >
                          {Object.keys(languages).map((language) => (
                            <option key={language} value={language}>
                              {languages[language].nativeName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <form action='' onSubmit={handleSubmit} method='post' className='form_modal'>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label flex-row required'>
                          {t('Name')}
                        </label>
                        <input
                          type='text'
                          required
                          className='form_control form-control'
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='cpf' className='card_details_label flex-row'>
                            {t('CPF')}
                            {sendCPF && <Loading type='1' style={{ marginLeft: '2%' }} />}
                          </label>
                          <CpfCnpjMask
                            className='form-control form_control'
                            value={cpf}
                            onChange={setCPF}
                            onBlur={() => verifyCPF(cpf)}
                            mode='CPF'
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='birth' className='card_details_label flex-row required'>
                            {t('BirthDate')}
                          </label>
                          <input
                            type='date'
                            required
                            className='form_control form-control'
                            value={birth}
                            onChange={(e) => setBirth(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='form_group'>
                        <label htmlFor='login' className='card_details_label flex-row required'>
                          {t('Login')}
                        </label>
                        <input
                          type='text'
                          required
                          className='form_control form-control'
                          value={login}
                          onChange={(e) => setLogin(e.target.value)}
                          onBlur={handlerValidateLogin}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='email' className='card_details_label'>
                          {t('Email')}
                          {sendEmail && <Loading type='1' style={{ marginLeft: '2%' }} />}
                        </label>
                        <input
                          type='email'
                          className='form_control form-control'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={(e) => verifyEmail(e.target.value)}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label flex-row required'>
                          {t('FinancialResponsibleName')}
                        </label>
                        <input
                          type='text'
                          className='form_control form-control'
                          value={nomeRespFin}
                          required
                          onChange={(e) => setNomeRespFin(e.target.value)}
                        />
                      </div>
                    </div>
                    {foto !== '' ? (
                      <div className='group_50' style={{ justifyContent: 'space-around' }}>
                        <img
                          style={{
                            borderRadius: 10,
                            marginBottom: 10,
                            width: '90%',
                          }}
                          src={foto}
                        />
                        <label
                          style={{
                            cursor: 'pointer',
                            padding: '12px 22px',
                            borderRadius: '50px',
                            border: '1px solid grey',
                            color: 'grey',
                            fontSize: '13px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                          }}
                        >
                          <input type='file' name='file[]' onChange={(e) => handleFoto(e)} />
                          Alterar foto
                        </label>
                      </div>
                    ) : (
                      <div className='group_50 upload_file'>
                        <label htmlFor='file'></label>
                        <input
                          type='file'
                          name='file[]'
                          id='file'
                          onChange={(e) => handleFoto(e)}
                          style={{
                            display: 'initial',
                            opacity: 0,
                            cursor: 'pointer',

                            position: 'relative',
                            top: '-52px',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </div>
                    )}
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='descript' className='card_details_label flex-row required'>
                          {t('FinancialResponsibleEmail')}
                        </label>
                        <input
                          required
                          type='email'
                          className='form_control form-control'
                          value={emailRespFin}
                          onChange={(e) => setEmailRespFin(e.target.value)}
                        ></input>
                      </div>
                      <div className='form_group'>
                        <label className='card_details_label flex-row'>{t('FinancialResponsibleCellPhone')}</label>
                        <NumberFormat
                          type='text'
                          name='athPhoneRespFin'
                          className='form-control form_control'
                          value={celRespFin}
                          onChange={(e) => setCelRespFin(e.target.value)}
                          format={t('Mask.CellPhone') || undefined}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='document' className='card_details_label flex-row required'>
                          {t('FinancialResponsibleCPF')}
                        </label>
                        <CpfCnpjMask
                          className='form-control form_control'
                          required
                          value={cpfRespFin}
                          onChange={setCPFRespFin}
                          mode='CPF'
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='indication' className='card_details_label flex-row'>
                          {t('Indication')}
                        </label>
                        <input
                          type='text'
                          className='form-control form_control'
                          value={indication}
                          onChange={(e) => setIndication(e.target.value)}
                        ></input>
                      </div>
                    </div>
                    {!sendSave && (
                      <button className='btn_card mx-auto'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {id === null && t('StudentPage.ExternalLink.ButtonAdd')}
                        {id !== null && t('StudentPage.ExternalLink.ButtonEdit')}
                      </button>
                    )}
                    {sendSave && (
                      <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none' }} disabled>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('SystemMessage.Loading.Processing')}
                        <Loading type='1' style={{ marginLeft: '2%' }} />
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </main>

            <footer className='footer'>
              <div className='comunicacao' style={{ backgroundColor: 'rgb(20, 47, 118, 0.5)' }}>
                {t('SystemFooter.Copyright')}
              </div>
            </footer>
          </div>
        </div>
      </div>
      <SweetAlert
        type={messageType}
        title=''
        show={showMessage}
        onConfirm={() => setShowMessage(false)}
      >
        {message.extra ? t(message.text, message.extra) : t(message)}
      </SweetAlert>
    </main>
  );
};

export default StudentLinkCreate;
