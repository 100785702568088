import { defaultApiObject, handleFail } from '../utils/utils-api';
import api from './api';

export const getAllUsersOnSystem = async (filters) => {
  try {
    const { data } = await api.post('/get-all-users-on-system', filters);

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const adminPasswordResetter = async (id) => {
  try {
    const { data } = await api.post('/admin-password-resetter', { id });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};
export const storeCategoriesSport = async (payload) => {
  //ainda tentando entender prq caralhas o servidor da Unauthorized mesmo com token valido
  const { data } = await api.post('/update-sport-school', payload);

  if (data.status === 'SUCCESS') return data;
  throw data;
};

export const updateUserStatus = async (id) => {
  try {
    const { data } = await api.post('/admin/update-user-status', { id });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getAllLessons = async () => {
  try {
    const { data } = await api.get('admin-get-all-lessons');
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const createLesson = async (lesson) => {
  try {
    const { data } = await api.post('admin-create-lesson', lesson);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateLesson = async (lesson) => {
  try {
    const { data } = await api.post('admin-update-lesson', lesson);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateStatus = async (lesson) => {
  try {
    const { data } = await api.patch('admin-update-lesson-status', lesson);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getAllActiveLessons = async () => {
  try {
    const { data } = await api.get('admin-get-all-active-lessons');
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getAllLessonsPlanning = async (onlyActive = 1, nested = 1) => {
  try {
    const { data } = await api.get(`admin-get-all-lessons-planning?onlyActive=${onlyActive}&nested=${nested}`);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const createLessonPlanning = async (lesson) => {
  try {
    const { data } = await api.post('admin-create-lesson-planning', lesson);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updateLessonPlanning = async (lesson) => {
  try {
    const { data } = await api.post('admin-update-lesson-planning', lesson);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const updatePlanningStatus = async (planning) => {
  try {
    const { data } = await api.patch('admin-update-planning-status', planning);
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};