import axios from 'axios';
import Api from '../utils/api';
import { buildFormData } from '../utils/utils';
import { defaultApiObject, handleFail } from '../utils/utils-api';
import api from './api';

const baseURL = Api();

export const getClassSchool = (schoolId, teacherId, isOnlyClass) =>
  new Promise((response, reject) => {
    const requestBody = {
      school_id: schoolId,
      ...(teacherId ? { teacherId } : undefined),
      isOnlyClass,
    };
    axios
      .post(baseURL + 'get-class-school', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

  export const getClasses = (schoolId) =>
  new Promise((response, reject) => {
    const requestBody = {
      schoolId: schoolId
    };
    axios
      .post(baseURL + 'get-classes', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  })

  

export const saveClassSchool = (classData) =>
  new Promise((response, reject) => {
    axios
      .post(baseURL + 'save-class-school', classData)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const updateClassSchool = (classData) =>
  new Promise((response, reject) => {
    axios
      .post(baseURL + 'update-class-school', classData)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const deleteDayClassSchool = ({ dayId, classId, hour, schoolId }) =>
  new Promise((response, reject) => {
    const requestBody = {
      day_id: dayId,
      class_id: classId,
      hour: hour,
      school_id: schoolId,
    };
    axios
      .post(baseURL + 'delete-day-class-school', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const getClassFrequencyPerDayPerSchool = async ({ classId, hour, year, month }) => {
  try {
    const { data } = await api.post('get-class-frequency-per-day-per-school', {
      class_id: classId,
      hour: hour,
      monthYear: `${year}-${`${month}`.padStart(2, '0')}`,
    });

    return data;
  } catch (error) {
    return { success: false, messageKey: 'SystemMessage.Fail.InternalError' };
  }
};

export const getMonthlyStudentFrequencyPerClass = async ({ classId, hour, year, month, withClass, userId = null }) => {
  try {
    const { data } = await api.post(`get-monthly-student-frequency-per-class`, {
      class_id: classId,
      hour,
      year,
      month,
      withClass,
      userId,
    });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const getDailyStudentFrequencyPerClass = async ({ classId, hour, date }) => {
  try {
    const { data } = await api.post(`get-daily-student-frequency-per-class`, {
      class_id: classId,
      hour,
      date,
    });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const downloadStudentFrequencyPerClass = async ({
  classId,
  schoolId,
  dayId,
  hour,
  year,
  month,
  withClass,
  userId = null,
}) => {
  try {
    const { data } = await api.get(`download-student-frequency-per-class`, {
      params: {
        class_id: classId,
        school_id: schoolId,
        day_id: dayId,
        hour,
        year,
        month,
        withClass,
        userId,
      },
      responseType: 'blob',
    });

    return data;
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const getDetailCardPerDayPerSchool = async ({ dayId, schoolId, userId, startTime, endTime }) => {
  try {
    const { data } = await api.post(`get-detail-card-per-day-per-school`, {
      day_id: dayId,
      school_id: schoolId,
      user_id: userId,
      startTime: startTime,
      endTime: endTime,
    });

    if (data.classs) {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};
export const getDetailCardPerAthelte = async ({ userId, schoolId }) => {
  try {
    const { data } = await api.post(`get-detail-card-per-athelete`, { user_id: userId, school_id: schoolId });

    if (data.classs) {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const getDays = async () => {
  try {
    const { data } = await api.get(`get-days`);

    if (Array.isArray(data)) {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const registerStudentFrequencies = async ({ class_day_id, frequencies, date }) => {
  try {
    const { data } = await api.post(`register-student-frequencies`, { class_day_id, frequencies, date });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const updateAbsenceJustification = async ({ classDaysFrequencyId, title, description, files }) => {
  try {
    const formData = buildFormData({
      class_days_frequency_id: classDaysFrequencyId,
      justification_title: title,
      justification_description: description,
      current_attachments: files.filter(({ id }) => id).map(({ id }) => id),
      attachments: files.filter(({ id }) => !id).map(({ blob }) => blob),
    });

    const { data } = await api.post(`/update-absence-justification`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};
export const updateAbsenceStudentJustification = async (
  { classDaysFrequencyId, title, description, files },
  userId
) => {
  try {
    const formData = buildFormData({
      userId: userId,
      class_days_frequency_id: classDaysFrequencyId,
      justification_title: title,
      justification_description: description,
      current_attachments: files.filter(({ id }) => id).map(({ id }) => id),
      attachments: files.filter(({ id }) => !id).map(({ blob }) => blob),
    });

    const { data } = await api.post(`/update-absence-justification`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

///get-employee-student-class
export const getEmployeeStudentsSchool = (/* int */ school_id) =>
  new Promise((response, reject) =>
    api
      .get(`/get-students-employeer-from-school/${school_id}`)
      .then(({ data }) => response(data))
      .catch((error) => reject(error))
  );

export const getClassReports = async ({ classIds, startDate, endDate }) => {
  try {
    const { data } = await api.post('/class/report', { classIds, startDate, endDate });
    if (data.status === 'SUCCESS') return data;

    throw new Error(data.error);
  } catch (error) {
    return { success: false, messageKey: 'SystemMessage.Fail.InternalError', error: error };
  }
};

export const exportExcelClassReport = async ({ classIds, startDate, endDate }) => {
  return api
    .post('/class/report/toexcel', { classIds, startDate, endDate }, { responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const filename = `relatorio_turmas-${new Date().toISOString().replaceAll(':', '')}.xlsx`;
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error('Ocorreu um erro ao baixar o arquivo:', error);
    });
};
