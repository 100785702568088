import { Collapse } from '@material-ui/core';
import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import { ModalWithMethods } from '../../components/modal/Modal';

const ModalAddClubSchoolDocument = ({ title, onSubmit, ...props }, ref) => {
  const { t } = useTranslation();
  const { MessageDangerMarginTop } = Message();

  const [isLoading, setIsLoading] = useState(false);

  const [newFile, setNewFile] = useState(null);
  const [newFileURL, setNewFileURL] = useState(null);

  const handleOnClose = () => {
    setNewFile(null);
    setNewFileURL((current) => {
      URL.revokeObjectURL(current);
      return null;
    });
  };

  const [message, setMessage] = useState({ text: '', type: null });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newFile) {
      return showErrorMessage('FileIsRequired');
    }

    e.preventDefault();
    clearMessage();
    setIsLoading(true);
    const { success, message } = await onSubmit({ file: newFile });
    setIsLoading(false);

    if (success) {
      ref.current.closeModal();
    } else {
      showErrorMessage(message);
    }
  };

  const showErrorMessage = (message) => {
    setMessage({ text: message, type: 'ERROR' });
  };

  const clearMessage = () => {
    setMessage({ text: '', type: null });
  };

  return (
    <ModalWithMethods {...props} ref={ref} size={'sm'} title={title} onClose={handleOnClose}>
      <div style={{ width: '100%' }}>
        <Collapse in={message.type === 'ERROR'}>
          <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message.text)} />
        </Collapse>
      </div>

      <form onSubmit={handleSubmit} className='form_modal' style={{ flex: 1 }}>
        <div className='form_group mt-3'>
          <label className='card_details_label'>Arquivo</label>
          <div className='d-flex flex-column'>
            <label
              htmlFor='newFile'
              className='btn_card'
              style={{
                backgroundColor: '#AAAAAA',
                fontWeight: 500,
                border: '1px solid #AAAAAA',
                height: '28px',
                width: 'auto',
                minWidth: '150px',
                marginTop: '10px',
                fontSize: '0.8rem',
                position: 'relative',
              }}
            >
              Escolher arquivo
              <input
                id='newFile'
                name='newFile'
                type='file'
                className='form_control'
                onChange={(e) => {
                  if (e.target.files.length) {
                    const file = e.target.files[0];
                    clearMessage();
                    setNewFileURL((current) => {
                      URL.revokeObjectURL(current);
                      return URL.createObjectURL(file);
                    });
                    setNewFile(file);
                  }
                }}
                style={{
                  border: 'none',
                  padding: 0,
                  margin: 0,
                  display: 'block',
                  position: 'absolute',
                  inset: 0,
                  pointerEvents: 'none',
                  opacity: 0,
                  width: 'auto',
                  height: 'auto',
                }}
              />
            </label>

            {newFile && (
              <a
                className='link-normal'
                target='_blank'
                rel='noopener noreferrer'
                href={newFileURL}
                style={{ margin: '13px 0px 0px 10px', cursor: 'pointer' }}
              >
                <span className='marker' />
                {newFile.name}
              </a>
            )}
          </div>
        </div>
        <div className='w-100 mt-auto mb-0'>
          {isLoading ? (
            <button className='btn_card mx-auto disabled' disabled>
              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
              </svg>
              {t('SystemMessage.Loading.Processing')}
              <Loading type='1' style={{ marginLeft: '2%' }} />
            </button>
          ) : (
            <button className='btn_card mx-auto'>
              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
              </svg>
              {t('Save')}
            </button>
          )}
        </div>
      </form>
    </ModalWithMethods>
  );
};

export default forwardRef(ModalAddClubSchoolDocument);
