import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ptBR from './assets/location/pt-BR';
import enUS from './assets/location/en-US';
import enUSArabic from './assets/location/en-US-Arabic';
import { getEnvironment } from './utils/api';

export const languages = {
  ...(getEnvironment() === 'DEV' || getEnvironment() === 'LOCALHOST'
    ? {
        dev: {
          name: 'development',
          nativeName: 'dev',
        },
      }
    : undefined),
  'pt-BR': {
    name: 'Portuguese',
    nativeName: 'Português',
    isReferenceLanguage: true,
    region: 'BR',
    currency: 'BRL',
  },
  'en-US': {
    name: 'English',
    nativeName: 'English',
    isReferenceLanguage: false,
    region: 'US',
    currency: 'USD',
  },
  'en-US-Arabic': {
    name: 'Arabic-English',
    nativeName: 'Arabic (English)',
    isReferenceLanguage: false,
    region: 'SA',
    currency: 'SAR',
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: getEnvironment() === 'DEV' || getEnvironment() === 'LOCALHOST' ? true : false,
    fallbackLng: getEnvironment() === 'DEV' || getEnvironment() === 'LOCALHOST' ? 'dev' : 'pt-BR',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      'pt-BR': ptBR,
      'en-US': enUS,
      'en-US-Arabic': enUSArabic,
    },
  });

export default i18next;
