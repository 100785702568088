import React, { useEffect, useState, useRef, useContext } from 'react';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Utils, { getUserPhoto } from '../../utils/utils';
import { Link } from 'react-router-dom';
import Api from '../../utils/api';
import Message from '../../components/Message';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-number-format';
import { UserContext } from '../../Context/UserContext';
import { SchoolRoleUtils } from '../../utils/role-utils';
import moment from 'moment';

const EvaluationForm = (props) => {
  console.log(props);
  const { t, i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const topRef = useRef(null);
  const [id, setId] = useState(props.location.state.id);
  const [action, setAction] = useState(props.location.state.action);
  const [process, setProcess] = useState(false);
  const [student, setStudent] = useState(props.location.state.student);
  const [date, setDate] = useState(props.location.state.date);
  const [countEvaluation, setCountEvaluation] = useState(props.location.state.countEvaluation);
  const [teacher, setTeacher] = useState(props.location.state.teacher);
  const [observation, setObservation] = useState('');
  const [height, setHeight] = useState(props.location.state.height);
  const [weight, setWeight] = useState(props.location.state.weight);
  const [imc, setImc] = useState(props.location.state.imc);
  const [bodyFatPercentage, setBodyFatPercentage] = useState(props.location.state.bodyFatPercentage);

  const [evaluation, setEvaluation] = useState({});
  const [evaluationAtributes, setEvaluationAtributes] = useState([]);
  const [bodyFatPercentageReferenceTable, setBodyFatPercentageReferenceTable] = useState(
    props.location.state.bodyFatPercentageReferenceTable
  );
  const [imcReferenceTable, setImcReferenceTable] = useState(props.location.state.imcReferenceTable);
  const [showErro, setShowErro] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { MessageDangerMarginTop, MessageSuccessMarginTop } = Message();
  const { formatTelefone, dateFormatText } = Utils();
  const baseURL = Api();

  useEffect(() => {
    if (action === 'ADD_EVALUATION') {
      generationEvaluationDefault();
    } else if (action === 'EDIT_EVALUATION') {
      setEvaluation(props.location.state.evaluation);
      setEvaluationAtributes(props.location.state.evaluation.atributes);
      setObservation(props.location.state.evaluation.observation);

      setHeight(props.location.state.evaluation.height);
      setWeight(props.location.state.evaluation.weight);
      setImc(props.location.state.evaluation.imc);
      setBodyFatPercentage(props.location.state.evaluation.bodyFatPercentage);
    }
  }, []);

  const generationEvaluationDefault = () => {
    let evaluationDefault = {
      id: null,
      school_id: props.location.state.school.id,
      user_id_professor: null,
      user_id_student: props.location.state.student.id,
      date: date,
      average: 0,
      observation: '',
      atributes: [],
    };
    props.location.state.evaluationDefault.map((item, i) => {
      evaluationDefault.atributes.push({
        id: null,
        evaluation_school_id: null,
        name: item.name,
        evaluation_school_atribute_items: [],
      });

      item.items.map((item2, y) => {
        evaluationDefault.atributes[i].evaluation_school_atribute_items.push({
          id: null,
          evaluation_school_atribute_id: null,
          name: item2.name,
          score: 0,
        });
      });
    });

    setEvaluation(evaluationDefault);
    setEvaluationAtributes(evaluationDefault.atributes);
  };

  const setTeacherEvaluation = (teacher) => {
    if (teacher) {
      setTeacher(teacher);
      setShowErro(false);
      let newObject = evaluation;
      newObject.user_id_professor = teacher.id;
      setEvaluation(newObject);
    }
  };

  const setDataEvaluation = (date) => {
    setDate(date);
    let newObject = evaluation;
    newObject.date = date;
    setEvaluation(newObject);
  };

  const setItemScore = (atribute_index, item_index, score) => {
    let newObject = evaluation;
    newObject.atributes[atribute_index].evaluation_school_atribute_items[item_index].score = score;

    let total_score = 0;
    newObject.atributes.map((data) => {
      data.evaluation_school_atribute_items.map((item) => {
        total_score += Number(item.score);
      });
    });

    newObject.average = total_score;
    setEvaluation({ ...evaluation, newObject });
    setEvaluationAtributes(newObject.atributes);
  };

  const setObservationEvalution = (value) => {
    setObservation(value);
    let newObject = evaluation;
    newObject.observation = value;
    setEvaluation(newObject);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    topRef.current.scrollIntoView({ behavior: 'smooth' });

    if (evaluation.user_id_professor === null) {
      setShowErro(true);
    } else {
      setShowErro(false);
      setProcess(true);

      const resp = await axios.post(baseURL + 'save-evaluation-student', {
        id: id,
        company: props.location.state.school.companie_id,
        school: evaluation.school_id,
        user_id_professor: evaluation.user_id_professor,
        user_id_student: evaluation.user_id_student,
        average: evaluation.average,
        date: date,
        observation: evaluation.observation,
        atributes: evaluation.atributes ? evaluation.atributes : evaluation.items,
        height,
        weight,
        imc,
        bodyFatPercentage,
      });

      if (id === null) {
        setCountEvaluation((prev) => prev + 1);
      }

      let newEvaluation = resp.data.evaluation;
      newEvaluation.atributes = resp.data.evaluation_atribute;
      newEvaluation.date = resp.data.evaluation.created_at.split(' ')[0];

      setId(resp.data.evaluation.id);
      setEvaluation(newEvaluation);
      setEvaluationAtributes(resp.data.evaluation_atribute);
      setDate(resp.data.evaluation.created_at.split(' ')[0]);
      setProcess(false);
      openSuccessMessage();
    }
  };

  const openSuccessMessage = () => {
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 4000);
  };

  const getBodyFatPercentageReferenceTable = ({ bodyFatPercentage, birthday, gender }) => {
    const age = moment().diff(birthday, 'years');

    if (age < 7 || age > 17) return t('AgeNotTabulated');

    const genderLabel = gender === 'M' ? t('Masculine') : t('Feminine');
    const table = bodyFatPercentageReferenceTable[gender === 'M' ? 'male' : 'female'];
    const rate = Object.keys(table)
      .sort((a, b) => a - b)
      .reduce((acc, current, index, src) => {
        if (index === 0) return +current;
        else return bodyFatPercentage > +src[index - 1] && bodyFatPercentage <= +src[index] ? +current : +acc;
      }, 0);

    return `${genderLabel} - ${table[rate]}`;
  };
  const getImcReferenceTable = ({ imc, birthday, gender }) => {
    const age = moment().diff(birthday, 'years');

    if (age < 6 || age > 15) return t('AgeNotTabulated');

    const genderLabel = gender === 'M' ? t('Masculine') : t('Feminine');

    const table = imcReferenceTable[gender === 'M' ? 'male' : 'female'][age];
    const rate = Object.keys(table)
      .sort((a, b) => a - b)
      .reduce((acc, current, index, src) => {
        if (index === src.length - 1) return imc >= +src[index] ? +current : +acc;
        else return imc >= +src[index] && imc < +src[index + 1] ? +current : +acc;
      }, 0);

    return `${genderLabel} - ${table[rate]}`;
  };

  const calcAverage = (values) => {
    // console.log(values);
    const sum = values.reduce((acc, { score }) => acc + +score, 0);

    let length = 0;
    for (let index = 0; index < values.length; index++) {
      if (Number(values[index].score) > 0) {
        length++;
      }
    }
    if (length > 0) {
      return (sum / length).toPrecision(3);
    } else {
      return 0;
    }
  };

  const calcSum = (values) => {
    const sum = values.reduce((acc, { score }) => acc + +score, 0);
    return sum;
  };

  const calcAverageTotal = (values, total) => {
    const atribbutes = values.map((attributItem) => {
      return attributItem.evaluation_school_atribute_items;
    });

    let totalAttributes = 0;
    for (let index = 0; index < atribbutes.length; index++) {
      for (let i = 0; i < atribbutes[index].length; i++) {
        if (Number(atribbutes[index][i].score) > 0) {
          totalAttributes++;
        }
      }
    }

    return (total / totalAttributes).toPrecision(3);
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='img_school'>
                    <img
                      src={getUserPhoto(
                        props.location.state.dirFotoPrincipalStudent + '/',
                        props.location.state.student.usuFotoPrincipal
                      )}
                      alt=''
                      style={{ borderRadius: '50%' }}
                    />
                  </div>
                  <h2 className='card_title mx-auto'>{props.location.state.student.name}</h2>
                  <div className='content_card'>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('Position')}: </p>
                      <p className='card_details_text'>{props.location.state.position}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('Birth')}: </p>
                      <p className='card_details_text'>
                        {dateFormatText(props.location.state.student.usuBirth.split(' ')[0])}
                      </p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('CellPhone')}: </p>
                      <p className='card_details_text'>
                        {formatTelefone(String(props.location.state.student.usuPhone))}
                      </p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('Email')}: </p>
                      <p className='card_details_text'>{props.location.state.student.email}</p>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>{t('RegisteredStudents')}</h2>
                    <div className='sidebar_data'>{props.location.state.countStudents}</div>
                    <Link to='/student-school' className='btn_card'>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('SeeAll')}
                    </Link>
                  </div>
                  <hr />
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>{t('StudentsEvaluated')}</h2>
                    <div className='sidebar_data'>{countEvaluation}</div>
                    <Link to='/evaluation-list-school' className='btn_card'>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('SeeAll')}
                    </Link>
                  </div>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header mx-auto' ref={topRef}>
                    <h2 className='card_title'>{t('AssessmentFormPage.PageTitle')}</h2>
                    <p className='card_subtitle'>
                      {t('AssessmentFormPage.PageSubtitle')}
                      {process && (
                        <React.Fragment>
                          <Loading type='2' style={{ color: 'green' }} />
                          <Loading type='2' style={{ color: 'green' }} />
                          <Loading type='2' style={{ color: 'green' }} />
                        </React.Fragment>
                      )}
                    </p>
                    {showSuccess && (
                      <MessageSuccessMarginTop
                        title={t('Success_exclamation') + ' '}
                        description={t('SystemMessage.Success.EvaluationSaved')}
                      />
                    )}
                    {showErro && (
                      <MessageDangerMarginTop
                        title={t('Attention_exclamation') + ' '}
                        description={t('AssessmentFormPage.SystemMessage.TeacherRequired')}
                      />
                    )}
                  </div>

                  <form className='content_avaliacao' onSubmit={handleSave}>
                    <div className='content_avaliacao_atributo'>
                      <h3 className='avaliacao_atributo_title'>{t('EmployeeModel.Occupations.Teacher')}</h3>
                      <Autocomplete
                        required={true}
                        id='option-teacher'
                        noOptionsText={t('NoTeacherForThisName')}
                        loadingText={t('SystemMessage.Loading.Loading')}
                        options={props.location.state.teachers}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                        value={teacher}
                        onChange={(event, newValue) => {
                          setTeacherEvaluation(newValue);
                        }}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <input type='text' {...params.inputProps} />
                          </div>
                        )}
                      />
                    </div>
                    <div className='content_avaliacao_atributo'>
                      <h3 className='avaliacao_atributo_title'>{t('Date')}</h3>
                      <input
                        type='date'
                        required
                        defaultValue={date}
                        onBlur={(e) => setDataEvaluation(e.target.value)}
                      />
                    </div>

                    {SchoolRoleUtils.isSchool(user.role_id) && (
                      <div className='d-flex flex-wrap my-3'>
                        <div className='content_avaliacao_atributo'>
                          <h3 className='avaliacao_atributo_title font-weight-normal'>{t('Height')}</h3>
                          <InputMask
                            isAllowed={({ floatValue }) => floatValue == null || floatValue / 100 <= 9.99}
                            format={(e) => (+e / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                            value={Number(height) ? height * 100 : ''}
                            onValueChange={({ floatValue }) => {
                              const height = Number(floatValue) ? floatValue / 100 : null;
                              setHeight(height);

                              if (height && weight) setImc(+(weight / (height * height)).toFixed(2));
                              else setImc(null);
                            }}
                          />
                        </div>
                        <div className='content_avaliacao_atributo'>
                          <h3 className='avaliacao_atributo_title font-weight-normal'>{t('Weight')}</h3>
                          <InputMask
                            isAllowed={({ floatValue }) => floatValue == null || floatValue / 100 <= 999.99}
                            format={(e) => (+e / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                            value={Number(weight) ? weight * 100 : ''}
                            onValueChange={({ floatValue }) => {
                              const weight = Number(floatValue) ? floatValue / 100 : null;
                              setWeight(weight);

                              if (height && weight) setImc(+(weight / (height * height)).toFixed(2));
                              else setImc(null);
                            }}
                          />
                        </div>
                        <div className='content_avaliacao_atributo'>
                          <h3 className='avaliacao_atributo_title font-weight-normal'>{t('IMC')}</h3>
                          <InputMask
                            readOnly
                            className='disabled'
                            placeholder={t('IMCPlaceholder')}
                            isAllowed={({ floatValue }) => floatValue == null || floatValue / 100 <= 99.99}
                            format={(e) =>
                              (+e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            }
                            value={imc}
                          />
                          <small>
                            {imc && imcReferenceTable
                              ? getImcReferenceTable({ imc, birthday: student.usuBirth, gender: student.usuGender })
                              : '--'}
                          </small>
                        </div>
                        <div className='content_avaliacao_atributo'>
                          <h3 className='avaliacao_atributo_title font-weight-normal'>{t('BodyFatPercentage')}</h3>
                          <InputMask
                            isAllowed={({ floatValue }) => floatValue == null || floatValue / 100 <= 99.99}
                            format={(e) =>
                              (+e / 100).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) + '%'
                            }
                            value={Number(bodyFatPercentage) ? bodyFatPercentage * 100 : ''}
                            onValueChange={({ floatValue }) =>
                              setBodyFatPercentage(Number(floatValue) ? floatValue / 100 : null)
                            }
                          />
                          <small>
                            {bodyFatPercentage && bodyFatPercentageReferenceTable
                              ? getBodyFatPercentageReferenceTable({
                                  bodyFatPercentage,
                                  birthday: student.usuBirth,
                                  gender: student.usuGender,
                                })
                              : '--'}
                          </small>
                        </div>
                      </div>
                    )}

                    <div className='content_avaliacao_atributo'>
                      <h3 className='avaliacao_atributo_title'>{t('Characteristics')}</h3>
                    </div>
                    <div className='' style={{ display: 'flex', gap: 10 }}>
                      <h3 className='avaliacao_atributo_title'>
                        {t('Average')}:{' '}
                        <small style={{ fontWeight: 600, fontSize: 16 }}>
                          {evaluationAtributes.length > 0
                            ? calcAverageTotal(evaluationAtributes, Number(evaluation.average).toFixed(0))
                            : 0}
                        </small>
                      </h3>
                      <h3 className='avaliacao_atributo_title'>
                        {t('Sum')}:{' '}
                        <small style={{ fontWeight: 600, fontSize: 16 }}>{Number(evaluation.average).toFixed(0)}</small>
                      </h3>
                    </div>

                    {evaluationAtributes.length > 0 && (
                      <div className='content_avaliacao_atributo_table'>
                        {evaluationAtributes.map((parent, i) => {
                          return (
                            <React.Fragment key={Math.random()}>
                              {parent.name !== '' && parent.evaluation_school_atribute_items.length > 0 && (
                                <>
                                  <h3 className='avaliacao_atributo_title'>
                                    <small style={{ fontWeight: 600, fontSize: 16 }}>
                                      {t(['EvaluationModel.' + parent.name, parent.name || ''], parent.name)} -{' '}
                                      {t('Average')}:{' '}
                                      <strong style={{ fontWeight: 600, fontSize: 16, color: '#000' }}>
                                        {parent.evaluation_school_atribute_items.length > 0
                                          ? calcAverage(parent.evaluation_school_atribute_items)
                                          : 0}
                                      </strong>
                                      {' - '}
                                      {t('Sum')}:{' '}
                                      <strong style={{ fontWeight: 600, fontSize: 16, color: '#000' }}>
                                        {parent.evaluation_school_atribute_items.length > 0
                                          ? calcSum(parent.evaluation_school_atribute_items)
                                          : 0}
                                      </strong>
                                    </small>
                                  </h3>
                                  {parent.evaluation_school_atribute_items.map((children, y) => {
                                    return (
                                      <div className='avaliacao_atributo_row' key={Math.random()}>
                                        <label>
                                          {t(
                                            ['EvaluationModel.AttributeName.' + children.name, children.name || ''],
                                            children.name
                                          )}
                                        </label>
                                        <input
                                          required
                                          type='number'
                                          defaultValue={Number(children.score)}
                                          onBlur={(e) => setItemScore(i, y, e.target.value)}
                                        />
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}
                    <div className='content_avaliacao_atributo_table'>
                      <h3 className='avaliacao_atributo_title'>{t('Observations')}</h3>
                      <textarea
                        value={observation}
                        onChange={(e) => setObservationEvalution(e.target.value)}
                      ></textarea>
                    </div>
                    <div
                      className='flex-column flex-sm-row'
                      style={{
                        marginTop: 20,
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <button className='btn_card mb-2 mb-sm-0' style={{ marginRight: 5 }}>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('AssessmentFormPage.SaveButton')}
                      </button>
                      {action === 'EDIT_EVALUATION' && (
                        <a
                          href={baseURL + `relatorio-default/pdf/${id}/.pdf?language=${i18n.resolvedLanguage}`}
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <button type='button' className='btn_card' style={{ marginRight: 5 }}>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              fill='white'
                              className='bi bi-file-earmark-post'
                              viewBox='0 0 16 16'
                            >
                              <path d='M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z' />
                              <path d='M4 6.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7zm0-3a.5.5 0 0 1 .5-.5H7a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1-.5-.5z' />
                            </svg>
                            {t('AssessmentFormPage.ExportButton')}
                          </button>
                        </a>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default EvaluationForm;
