import React, { useEffect, useState } from 'react'
import Loading from '../../../../../components/Loading'
import Api from '../../../../../utils/api'
import axios from 'axios'
import { Redirect } from 'react-router-dom'

import Nav from '../../components/nav/Nav'
import List from '../../components/list/List'
import CTA from '../../components/Buttons/btn-cta/BtnCTA'
import Footer from '../../components/footer/Footer'

import './index.css'
import '../app/App.css'

const Resgate = () => {
    const [experiences, setExperiences] = useState([])
    const [process, setProcess] = useState(false)
    const [athleteID, setAthleteID] = useState("")
    const [login, setLogin] = useState(false)
    const baseURL = Api()

    useEffect(() => {

        async function get (){
            const athlete = JSON.parse(await localStorage.getItem('athlete'))
   
            if ( athlete !== null ) {
                setAthleteID(athlete.id)

                setProcess(true)
                const res = await axios.post(baseURL + 'get-experiences', {athlete_id: athlete.id})
                setProcess(false)

                setExperiences(res.data.experiencias)
            } else {
                setLogin(true)
            }
         }
   
        get()
    }, [])

    if ( login ) {
        return <Redirect to="/" />
    }

    return (
        <React.Fragment>
            <Nav />
            <div className="mainContainer">
                <h1 className="title">resgates exclusivos assinantes fensor</h1>
                <p className="descript">
                    <b>Atenção:</b><br />
                        Verifique as regras de resgate de cada produto, serviço ou experiência. O benefício é limitado a 01 (um)
                        resgate por dia.
                        {
                            process && 
                            <React.Fragment><br /><br />carregando...<Loading type="3" /><br /><br /></React.Fragment>
                        }
                    </p>
                    
                    {
                        experiences.length > 0 && !process &&
                        <List data={experiences} athlete_id={athleteID} />

                    }
                    <p className="descript text-bold">
                        Garanta mais vantagens entrando no nosso Clube de Parceiros
                    </p>
                <CTA/>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Resgate

