import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { forwardRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import { ButtonRoundedDefault } from '../../components/buttons/ButtonRoundedDefault';
import Textarea from '../../components/inputs/Textarea';
import Loading from '../../components/Loading';
import { ModalWithMethods } from '../../components/modal/Modal';
import { fetchCertificateConfigurations, updateCertificateConfiguration } from '../../services/StudentService';
import { handleOnChange } from '../../utils/utils';
import { baseURL } from '../../utils/api';
import { tokenIdentifier } from '../../Context/UserContext';

const ModalConfigStudentCertificate = ({ ...props }, ref) => {
  const { t } = useTranslation();

  const [certificateConfiguration, setCertificateConfiguration] = useState(null);
  const [certificateConfigurations, setCertificateConfigurations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [preview, setPreview] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    level: null,
    title: '',
    subtitle: '',
    background_path_url: null,

    background_file: null,
  });

  const onChangeForm = handleOnChange(setFormData);

  const fetchCertificateConfiguration = async () => {
    setIsLoading(true);
    const { status, certificateConfigurations } = await fetchCertificateConfigurations();
    setIsLoading(false);

    if (status === 'SUCCESS') setCertificateConfigurations(certificateConfigurations);
    else showErrorMessage('SystemMessage.Fail.InternalError');
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    setIsSending(true);
    const { status, message, messageKey, certificateConfigurations } = await updateCertificateConfiguration(formData);
    setIsSending(false);

    if (status === 'SUCCESS') {
      setCertificateConfiguration(null);
      setFormData({});
      setCertificateConfigurations(certificateConfigurations);
      showSuccessMessage(messageKey ?? message);
    } else {
      showErrorMessage(messageKey ?? message ?? t('SystemMessage.Fail.InternalErrorTryAgain'));
    }
  };

  const handleSetFormData = (value) => {
    URL.revokeObjectURL(formData.background_path_url);

    setCertificateConfiguration(value);
    setFormData(value ?? {});
  };

  const setBackgroundFile = (event) => {
    event.preventDefault();
    
    const file = event.target.files[0];
    event.target.value = null;
    setPreview(null); //reseta o preview
    if (file.size > 2048000) {
      showErrorMessage('SystemMessage.File.Fail.MaximumSizeExceeded');
    } else {
      onChangeForm('background_file', file);

      setFormData(({ background_path_url, ...current }) => {
        URL.revokeObjectURL(background_path_url);
        return { ...current, background_path_url: URL.createObjectURL(file) };
      });
    }
  };

  const clearBackgroundFile = () => {
    onChangeForm('background_file', null);

    setFormData(({ background_path_url, ...current }) => {
      URL.revokeObjectURL(background_path_url);
      return { ...current, background_path_url: null };
    });
  };

  const showSuccessMessage = (message) => {
    ref.current.showSuccessMessage(message);
    ref.current.scrollTop();
    setTimeout(() => {
      ref.current.clearMessage();
    }, 5000);
  };

  const showErrorMessage = (message) => {
    ref.current.showErrorMessage(message);
    ref.current.scrollTop();
    setTimeout(() => {
      ref.current.clearMessage();
    }, 5000);
  };

  const openPreview = () => {
    const token = localStorage.getItem(tokenIdentifier);

    window.open(
      baseURL + `get-student-certificate-default/preview/${certificateConfiguration.level}?token=${token}`,
      '_blank',
      'noopener noreferrer'
    );
  };

  React.useEffect(()=>{
    setPreview(certificateConfiguration);
  },[certificateConfiguration]);

  return (
    <ModalWithMethods
      {...props}
      ref={ref}
      title={t('CertificatesModal.ModalTitle')}
      size={'lg'}
      onShow={fetchCertificateConfiguration}
    >
      {isLoading ? (
        <div className='d-flex mt-3 justify-content-center'>
          <Loading type='1' />
        </div>
      ) : (
        <div></div>
      )}

      <div className='col-12 mt-4'>
        <div className='form_group mb-0'>
          <label className='card_details_label'>{t('Level')}</label>
          <Autocomplete
            autoComplete
            renderInput={(params) => (
              <TextField
                {...params}
                required
                variant='outlined'
                placeholder={t('CertificatesModal.LevelPlaceholder')}
              />
            )}
            options={certificateConfigurations}
            getOptionLabel={(option) => `Nível ${option.level}`}
            value={certificateConfiguration}
            onChange={(_, value) => handleSetFormData(value)}
          />
        </div>
      </div>

      <div className='col-12'>
        <hr />
      </div>

      <div className='col-12'>
        <form action='' method='post' className='form_modal mt-0' onSubmit={handleOnSubmit}>
          {/* <Input required label={t('Title')} name='title' value={formData} onChange={onChangeForm} /> */}

          <Textarea
            required
            rows={2}
            className='h-auto'
            classNameContainer='mb-1'
            label={t('Subtitle')}
            name='subtitle'
            value={formData}
            onChange={onChangeForm}
          />
          <div className='alert alert-secondary py-0 w-100'>
            <small>
              <Trans i18nKey='CertificatesModal.CertificateHelpText'>
                <b>b</b>
              </Trans>
            </small>
            {/* <small>
              O texto entre chaves duplas <code>{'"{{}}"'}</code> será substituído pela informação correspondente.
              <span className='d-block mt-1'>
                <b>nivel_completo</b>: Esse é o nível que o aluno completou e está sendo certificado.
              </span>
              <span className='d-block'>
                <b>proximo_nivel</b>: Esse é o novo nível que o aluno tem acesso.
              </span>
              <span className='d-block mt-3'>
                <b>Exemplo</b>: Completou o {'{{nivel_completo}}'}
              </span>
              <span className='d-block'>
                <b>Resultado</b>: Completou o <b>nível 1</b>
              </span>
            </small> */}
          </div>

          <div className='form_group '>
            <label className='card_details_label flex-row'>
              {t('BackgroundImage')} ({t('NotRequired')})
              <small className='text-right' style={{ marginLeft: 'auto' }}>
                jpg, jpeg, png, svg | {t('MaxSize')}: 5 MB
              </small>
            </label>

            <div
              className='upload_file position-relative mb-1'
              style={{
                background: formData.background_path_url
                  ? `center center / cover url(${formData.background_path_url})`
                  : undefined,
              }}
            >
              <label htmlFor='background_path_url'></label>
              <input
                name='background_path_url'
                type='file'
                accept='.jpg, .jpeg, .png, .svg'
                onChange={(e) => e.target.files.length > 0 && setBackgroundFile(e)}
                style={{
                  display: 'initial',
                  opacity: 0,
                  cursor: 'pointer',

                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>

            <ButtonRounded
              type='button'
              className='ml-auto mr-0 m-0 bg-danger border-danger'
              onClick={clearBackgroundFile}
            >
              {t('Remove')}
            </ButtonRounded>
          </div>

          <div className='col-12'>
            <ButtonRoundedDefault isBig isLoading={isSending} type='submit' className='mx-auto'>
              {t('Save')}
            </ButtonRoundedDefault>
          </div>

          {preview && certificateConfiguration && (
            <div className='col-12'>
              <ButtonRounded
                type='button'
                isLoading={isSending}
                className='mx-auto bg-secondary border-secondary'
                onClick={openPreview}
              >
                {t('Preview')}
              </ButtonRounded>
            </div>
          )}
        </form>
      </div>
    </ModalWithMethods>
  );
};

export default forwardRef(ModalConfigStudentCertificate);
