import React from 'react';
import Table from 'react-bootstrap/Table';
import { BsClipboardCheck, BsPencil, BsTrash } from 'react-icons/bs';

const ProductsTable = ({ productsFilter, dirUsuFotoPrincipal, onEdit, onClickDetails, onClickDelete }) => {
    const defaultColHeaderStyle = {
        border: 'none',
        fontSize: '14px',
        width: '200px',
        color: '#3b3b3b',
        fontWeight: 600,
        justifyContent: 'left'
    };

    const defaultColRowStyle = {
        alignItems: 'center',
        width: '200px',
        height: '100%',
        border: 'none',
        justifyContent: 'left'
    };

    return (
        <Table responsive style={{ boxShadow: 'none', marginTop: '20px', minWidth: '625px' }}>
            <thead>
                <tr style={{ marginBottom: '5px' }}>
                    <th style={{ ...defaultColHeaderStyle }}>
                        image
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Nome
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Código
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Tipo
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Valor
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Estoque
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Ativo
                    </th>
                    <th style={{ ...defaultColHeaderStyle }}>
                        Açao
                    </th>
                </tr>
            </thead>
            <tbody>
                {productsFilter && productsFilter.map((item, i) => (
                    <tr key={'ProducsTable_tbody_row' + i} style={{
                        marginBottom: '12px',
                        borderRadius: '10px',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        height: '62px',
                        borderColor: 'var(--main-color)'
                    }}>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.fotoCapa !== "" &&
                                <img
                                    style={{
                                        width: 80,
                                        height: 51,
                                        borderRadius: '9px',
                                        objectFit: 'cover',
                                    }}
                                    src={dirUsuFotoPrincipal + '/' + item.fotoCapa}
                                    alt=''
                                />
                            }
                            {!item.fotoCapa &&
                                <div className='group_50 upload_file'></div>
                            }
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.titulo.length > 20 ? item.titulo.substr(0, 20) + '...' : item.titulo}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.cod_produto}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.type_service ? 'Serviço' : 'Produto'}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.preco}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.product_stock.reduce((acc, obj) => {
                                return acc + obj.amount
                            }, 0)}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            {item.ativo}
                        </td>
                        <td className='text-dark' style={{ ...defaultColRowStyle }}>
                            <div className='d-flex' style={{ gap: '15px', cursor: 'pointer' }}>
                                <BsPencil onClick={(e) => onEdit(e, item)} style={{ color: 'var(--main-color)' }} size={17} />
                                <BsTrash onClick={(e) => onClickDelete(item)} color='red' size={17} />
                                <BsClipboardCheck onClick={() => onClickDetails(item)} color='#00A4EA' size={17} />
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default ProductsTable;